const SquareUganda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path fill="#151515" fillRule="evenodd" d="M0 0H22V2.13333H0V0Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 4.2666H22V7.4666H0V4.2666Z" clipRule="evenodd" />
          <path fill="#151515" fillRule="evenodd" d="M0 7.4668H22V10.6668H0V7.4668Z" clipRule="evenodd" />
          <path
            fill="white"
            d="M10.4759 10.6666C12.2116 10.6666 13.6187 9.23391 13.6187 7.4666C13.6187 5.69929 12.2116 4.2666 10.4759 4.2666C8.74011 4.2666 7.33301 5.69929 7.33301 7.4666C7.33301 9.23391 8.74011 10.6666 10.4759 10.6666Z"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M10.4763 4.2666L9.42871 7.4666L10.4763 9.59993L11.5239 7.4666V10.6666L12.5716 7.4666L11.5239 6.39993L10.4763 7.4666V4.2666Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" d="M0 12.7998H22V15.9998H0z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareUganda;
