const BordBoxIcon = () => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7235 12.3903C16.1436 9.02145 19.0173 6.41464 22.4999 6.41464C25.9825 6.41464 28.8563 9.02145 29.2764 12.3903L15.7235 12.3903ZM12.2911 12.3903C12.7249 7.13212 17.1298 3 22.4999 3C27.87 3 32.275 7.13211 32.7088 12.3903L35.2444 12.3903C36.0847 12.3902 36.8504 12.3901 37.4632 12.4797C38.1371 12.5782 38.8548 12.8138 39.3896 13.4668C39.9187 14.1127 40.0173 14.861 39.9977 15.5414C39.9797 16.1699 39.8506 16.9392 39.707 17.7954L37.6248 30.2164L37.6248 30.2166L37.6248 30.2166C37.2958 32.1789 37.0298 33.7661 36.6637 35.007C36.2833 36.2964 35.7542 37.3651 34.802 38.1901C33.8459 39.0184 32.7207 39.3781 31.4064 39.5464C30.1489 39.7074 28.5775 39.7074 26.6462 39.7073H18.3538C16.4225 39.7074 14.8511 39.7074 13.5936 39.5464C12.2793 39.3781 11.1541 39.0184 10.198 38.1901C9.24579 37.3651 8.71669 36.2964 8.3363 35.007C7.9702 33.7661 7.70414 32.1788 7.37522 30.2165L5.30671 17.8773L5.293 17.7955C5.14936 16.9392 5.02033 16.1699 5.00227 15.5414C4.98273 14.861 5.08134 14.1127 5.61035 13.4668C6.1452 12.8138 6.86295 12.5782 7.53675 12.4797C8.14959 12.3901 8.91525 12.3902 9.7556 12.3903H9.75562L9.84132 12.3903L12.2911 12.3903Z"
        fill="url(#paint0_linear_6384_36508)"
      />
      <g filter="url(#filter0_bi_6384_36508)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.1582 19.5918C40.5309 18.9645 39.5875 18.6146 38.6561 18.4007C37.6939 18.1797 36.5924 18.0712 35.51 18.0263C33.342 17.9364 31.1266 18.0974 29.9943 18.2014C29.2876 18.2664 28.6352 18.5555 28.1099 19.0069C24.1878 22.3778 20.6173 25.8721 17.2012 29.6874C15.6381 31.4332 15.6215 34.0397 17.0291 35.8576C19.3504 38.8557 21.8943 41.3996 24.8924 43.7209C26.7103 45.1285 29.3168 45.1119 31.0626 43.5488C34.8779 40.1327 38.3722 36.5622 41.7431 32.6401C42.1945 32.1148 42.4836 31.4624 42.5486 30.7557C42.6526 29.6234 42.8136 27.408 42.7237 25.24C42.6788 24.1576 42.5703 23.0561 42.3493 22.094C42.1354 21.1625 41.7855 20.2191 41.1582 19.5918ZM28.1262 26.3982C27.4391 26.3982 26.8821 26.9553 26.8821 27.6424C26.8821 28.3296 27.4391 28.8866 28.1262 28.8866H28.1449C28.832 28.8866 29.3891 28.3296 29.3891 27.6424C29.3891 26.9553 28.832 26.3982 28.1449 26.3982H28.1262ZM28.1309 36.3518C27.4437 36.3518 26.8867 36.9088 26.8867 37.5959C26.8867 38.2831 27.4437 38.8401 28.1309 38.8401H28.1495C28.8366 38.8401 29.3937 38.2831 29.3937 37.5959C29.3937 36.9088 28.8366 36.3518 28.1495 36.3518H28.1309ZM21.9102 32.6192C21.9102 31.9321 22.4672 31.375 23.1544 31.375H33.1079C33.795 31.375 34.3521 31.9321 34.3521 32.6192C34.3521 33.3064 33.795 33.8634 33.1079 33.8634H23.1544C22.4672 33.8634 21.9102 33.3064 21.9102 32.6192ZM36.2178 22.6657C37.2486 22.6657 38.0841 23.5012 38.0841 24.532C38.0841 25.5627 37.2486 26.3982 36.2178 26.3982C35.1871 26.3982 34.3515 25.5627 34.3515 24.532C34.3515 23.5012 35.1871 22.6657 36.2178 22.6657Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_6384_36508"
          x="11.1278"
          y="13.1278"
          width="36.4944"
          height="36.4944"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.43609" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6384_36508" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6384_36508" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.24812" />
          <feGaussianBlur stdDeviation="4.22256" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6384_36508" />
        </filter>
        <linearGradient
          id="paint0_linear_6384_36508"
          x1="22.5"
          y1="3"
          x2="22.5303"
          y2="46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BordBoxIcon;
