const BordCopyIcon = () => {
  return (
    <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.258 4.83431C39.2332 4.83007 41.6326 4.82665 43.5198 5.07651C45.4788 5.33589 47.1329 5.8948 48.447 7.2025C49.7623 8.51132 50.3254 10.1606 50.5866 12.1139C50.8379 13.9934 50.8345 16.3824 50.8303 19.3417L50.8303 19.3418L50.8176 28.2459L50.8176 28.2459C50.8135 31.2054 50.8101 33.5944 50.5535 35.4744C50.2868 37.4286 49.7192 39.0766 48.4033 40.3861C47.0885 41.6944 45.4357 42.2578 43.4758 42.5227C41.5882 42.7778 39.1888 42.7812 36.2135 42.7854L33.797 42.7888C30.8218 42.793 28.4223 42.7964 26.5352 42.5466C24.5762 42.2872 22.9221 41.7283 21.608 40.4206C20.2927 39.1117 19.7295 37.4624 19.4683 35.5092C19.217 33.6297 19.2204 31.2406 19.2247 28.2813L19.2247 28.2812L19.2373 19.3772L19.2373 19.3772C19.2415 16.4177 19.2448 14.0287 19.5014 12.1486C19.7682 10.1945 20.3358 8.5465 21.6517 7.23701C22.9665 5.92863 24.6193 5.36531 26.5792 5.10038C28.4668 4.84522 30.8662 4.84187 33.8416 4.83772L36.258 4.83431Z"
        fill="url(#paint0_linear_6247_54350)"
      />
      <g filter="url(#filter0_bi_6247_54350)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.2796 9.93946C31.1639 9.65934 28.474 9.66318 25.1385 9.66794L22.4295 9.67176C19.0938 9.67641 16.4039 9.68016 14.2877 9.96622C12.0905 10.2632 10.2375 10.8948 8.76353 12.3616C7.28828 13.8296 6.65197 15.6772 6.35293 17.8679C6.06524 19.9756 6.06149 22.6539 6.05684 25.9718L6.04269 35.954L6.04269 35.954V35.954C6.03793 39.2717 6.0341 41.95 6.31584 44.0571C6.60865 46.2469 7.24002 48.0959 8.71453 49.5632C10.1878 51.0292 12.0422 51.6558 14.2384 51.9466C16.354 52.2267 19.044 52.2229 22.3795 52.2181L25.0887 52.2143C28.4242 52.2097 31.1141 52.2059 33.2303 51.9198C35.4275 51.6228 37.2804 50.9913 38.7544 49.5245C40.2297 48.0564 40.866 46.2089 41.165 44.0181C41.4527 41.9104 41.4564 39.2322 41.4611 35.9143L41.4752 25.9321C41.48 22.6144 41.4838 19.936 41.2021 17.829C40.9093 15.6392 40.2779 13.7902 38.8034 12.3229C37.3302 10.8568 35.4758 10.2303 33.2796 9.93946ZM17.6661 21.815C16.6432 21.8164 15.8152 22.6468 15.8166 23.6697C15.8181 24.6926 16.6485 25.5206 17.6713 25.5192L29.8691 25.502C30.892 25.5005 31.72 24.6702 31.7186 23.6473C31.7172 22.6244 30.8868 21.7963 29.8639 21.7978L17.6661 21.815ZM17.6524 31.5255C16.6295 31.527 15.8014 32.3573 15.8029 33.3802C15.8043 34.4031 16.6347 35.2312 17.6576 35.2297L23.7565 35.2211C24.7794 35.2197 25.6074 34.3893 25.606 33.3664C25.6045 32.3435 24.7741 31.5155 23.7512 31.5169L17.6524 31.5255Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_6247_54350"
          x="1.16932"
          y="4.79457"
          width="45.1794"
          height="52.2969"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.43609" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6247_54350" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6247_54350" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.24812" />
          <feGaussianBlur stdDeviation="4.22256" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6247_54350" />
        </filter>
        <linearGradient
          id="paint0_linear_6247_54350"
          x1="35.0275"
          y1="4.83325"
          x2="35.0633"
          y2="49.2966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BordCopyIcon;
