const SquareAntiguaAndBarbuda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#151515" rx="2" />
          <path fill="#151515" d="M0 0h22v16H0z" />
          <path
            fill="#FFDA2C"
            d="M11 9.59997c2.025 0 3.6667-1.67147 3.6667-3.73334 0-2.06186-1.6417-3.73333-3.6667-3.73333-2.02505 0-3.66667 1.67147-3.66667 3.73333 0 2.06187 1.64162 3.73334 3.66667 3.73334Z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M0 9.6001h22v6.4H0v-6.4Z"
            clipRule="evenodd"
          />
          <path
            fill="#3A99FF"
            fillRule="evenodd"
            d="M0 6.3999h22v3.2H0v-3.2Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M11.5238 16H0V0l11.5238 16Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M10.4762 16H22V0L10.4762 16Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAntiguaAndBarbuda;
