import {
  EmployeeDestinationModal,
  EmployeeDestinationModalInternalOrder
} from '../../../../../components/DestinationModals';
import { IorganizationOfficeData } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import { Iemployee } from '../../../../../types/global';
import { IshipmentGroupDetails, TshipmentDestinationData } from '../../../../../types/orders';
import { useTranslation } from 'react-i18next';
import { NudosEditOfficeAddressModal } from '../../../../../components/DesignSystem/NudosEditAddressModal';
import { NudosEditOfficeAddressModalInternalOrder } from '../../../../../components/DestinationModals/NudosEditOfficeAddressModal';
import './ModalUpdateShipmentInfoForm.scss';

const ModalUpdateShipmentInfoForm = ({
  employeeData,
  officeData,
  shipmentExistingData,
  advanceCallback,
  listOfCountries,
  activateAlternativeModals,
  shipmentInfoEditingIsAllowed,
  loadingUpdate
}: {
  employeeData?: Iemployee;
  officeData?: IorganizationOfficeData;
  shipmentExistingData: IshipmentGroupDetails;
  advanceCallback: (modalData: TshipmentDestinationData) => void;
  destinationIsEmployee?: boolean;
  listOfCountries: ICountryDetail[];
  activateAlternativeModals: boolean;
  shipmentInfoEditingIsAllowed: boolean;
  loadingUpdate: boolean;
}) => {
  const { t } = useTranslation();
  const { user, receiverIdDocument, destinationAddress, additionalReferences, coordinates, zipCode, city, shipments } =
    shipmentExistingData || {};
  const { reports, statusName } = shipments?.[0] || {}; // CAMBIAR-JC
  const i18nLanguageKey = 'nodi:orderDetails:orderShipmentCard:shipToOfficeModalFlux:';
  const destinationIsUser = !!employeeData;

  const employeeInformationIncludingShipmentData = employeeData
    ? {
        ...employeeData,
        personalId: receiverIdDocument || employeeData?.personalId,
        address: { address: destinationAddress, additionalReferences, coordinates, zipCode, city },
        phoneData: user?.dataPhone || undefined
      }
    : undefined;

  const customModalComponent = destinationIsUser ? (
    <>
      {activateAlternativeModals ? (
        <EmployeeDestinationModalInternalOrder
          shipmentExistingData={shipmentExistingData}
          employeeData={employeeInformationIncludingShipmentData}
          buttonText="Guardar"
          clickButtonCallback={advanceCallback}
          listOfCountries={listOfCountries || []}
          shipmentInfoEditingIsAllowed={shipmentInfoEditingIsAllowed}
          isButtonLoading={loadingUpdate}
        />
      ) : (
        <EmployeeDestinationModal
          shipmentExistingData={shipmentExistingData}
          employeeData={employeeInformationIncludingShipmentData}
          subtitle={t(`${i18nLanguageKey}subtitle`)}
          buttonText="Continuar"
          clickButtonCallback={advanceCallback}
          listOfCountries={listOfCountries || []}
        />
      )}
    </>
  ) : (
    <>
      {activateAlternativeModals ? (
        <NudosEditOfficeAddressModalInternalOrder
          giverOrReceiverSectionTitle={t(`${i18nLanguageKey}giverOrReceiverTitle`)}
          listOfCountries={listOfCountries || []}
          clickButtonCallback={advanceCallback}
          currentOfficeData={officeData}
          disableCountrySelection
          preselectedOfficeCountry={officeData?.country}
          shipmentInfoEditingIsAllowed={shipmentInfoEditingIsAllowed}
          reports={reports || []}
          recivedPhoneCode={shipmentExistingData?.country?.code} // CAMBIAR-JC este dato debería llegar de back específicamente para el teléfono de quien recibe
          isButtonLoading={loadingUpdate}
          statusName={statusName || ''}
        />
      ) : (
        <NudosEditOfficeAddressModal
          subtitle={t(`${i18nLanguageKey}subtitle`)}
          giverOrReceiverSectionTitle={t(`${i18nLanguageKey}giverOrReceiverTitle`)}
          buttonText={t(`${i18nLanguageKey}buttonText`)}
          listOfCountries={listOfCountries || []}
          clickButtonCallback={advanceCallback}
          currentOfficeData={officeData}
          disableCountrySelection
          preselectedOfficeCountry={officeData?.country}
        />
      )}
    </>
  );

  return customModalComponent;
};

export default ModalUpdateShipmentInfoForm;
