const SquareSanMarino = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path fill="#3A99FF" fillRule="evenodd" d="M0 8H22V16H0V8Z" clipRule="evenodd" />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M8.38086 4.2666H13.619V5.33327L10.9999 6.39994L8.38086 5.33327V4.2666Z"
          clipRule="evenodd"
        />
        <path
          fill="#249F58"
          fillRule="evenodd"
          d="M13.619 9.06657C13.619 7.59457 12.4456 6.3999 10.9999 6.3999C9.55419 6.3999 8.38086 7.59457 8.38086 9.06657C8.38086 10.5386 9.55419 11.7332 10.9999 11.7332C12.4456 11.7332 13.619 10.5386 13.619 9.06657ZM10.9999 10.6666C11.8673 10.6666 12.5713 9.7119 12.5713 8.53324C12.5713 7.35457 11.8673 6.3999 10.9999 6.3999C10.1325 6.3999 9.42848 7.35457 9.42848 8.53324C9.42848 9.7119 10.1325 10.6666 10.9999 10.6666Z"
          clipRule="evenodd"
        />
        <path
          fill="#D4AF2C"
          d="M11.0004 9.60013C11.2897 9.60013 11.5242 9.12257 11.5242 8.53346C11.5242 7.94436 11.2897 7.4668 11.0004 7.4668C10.7111 7.4668 10.4766 7.94436 10.4766 8.53346C10.4766 9.12257 10.7111 9.60013 11.0004 9.60013Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSanMarino;
