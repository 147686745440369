const RoundUruguay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#3ECBF8"
          d="M16 11.826H31.4488C31.0476 10.3377 30.4379 8.9351 29.6516 7.6521H16V11.826ZM6.04045 28.5216H25.96C27.4202 27.3586 28.6724 25.946 29.6518 24.3477H2.34863C3.32807 25.9459 4.58032 27.3586 6.04045 28.5216ZM16 0V3.47825H25.9598C23.2267 1.3015 19.7656 0 16 0Z"
        />
        <path
          fill="#3ECBF8"
          d="M16 11.826H31.4488C31.0476 10.3377 30.4379 8.9351 29.6516 7.6521H16V11.826ZM0 16C0 17.4442.1925 18.8431.551188 20.1739H31.4489C31.8075 18.8431 32 17.4442 32 16H0Z"
        />
        <path
          fill="#FFDA2C"
          d="M13.913 9.36382L11.9589 10.283L12.9994 12.1755L10.8776 11.7696L10.6087 13.9131L9.13082 12.3366L7.65282 13.9131L7.38401 11.7696L5.26213 12.1754L6.30263 10.2829L4.34863 9.36382L6.3027 8.44476L5.26213 6.55226L7.38394 6.95813L7.65288 4.8147L9.13082 6.3912L10.6088 4.8147L10.8776 6.95813L12.9995 6.55226L11.9589 8.44482L13.913 9.36382Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundUruguay;
