import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import usePlatform from '../../hooks/usePlatform';
import SecurityLogoutModal from './components/SecurityLogoutModal/SecurityLogoutModal';
import LogInImage from './components/LogInImage/LogInImage';
import LogInForm from './components/LogInForm/LogInForm';
import { isLocalHost } from '../../constants';
import './signIn.scss';

const SignIn = () => {
  const { push } = useHistory();
  const { isMobile } = usePlatform();

  const [showSecurityLogoutModal, setShowSecurityLogoutModal] = useState(false);

  const handleCloseModal = () => setShowSecurityLogoutModal(false);

  useEffect(() => {
    const tokenExpired = localStorage.getItem('tokenExpired');
    if (tokenExpired) setShowSecurityLogoutModal(true);
  }, []);

  useEffect(() => {
    if (!isLocalHost && isMobile) push('/mobile', { origin: 'signup' });
  }, [isMobile]);

  if (isMobile) return <></>;
  return (
    <div id="signIn" className="moduleWithoutSidebarOrHeader">
      {showSecurityLogoutModal && <SecurityLogoutModal closeModalCallback={handleCloseModal} />}
      {!isMobile && <LogInForm />}
      {!isMobile && <LogInImage />}
    </div>
  );
};

export default SignIn;
