import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordPhoneCallIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.0504 5c.9768.19057 1.8744.66826 2.5781 1.37194.7037.70367 1.1814 1.60132 1.3719 2.57806M15.0504 1c2.0293.22544 3.9216 1.13417 5.3663 2.57701 1.4446 1.44283 2.3557 3.334 2.5837 5.36299m-1 7.98v3c.0012.2785-.0559.5542-.1675.8093-.1115.2552-.2752.4843-.4804.6726-.2052.1882-.4475.3316-.7113.4208-.2639.0892-.5434.1224-.8208.0973-3.0771-.3344-6.033-1.3859-8.63-3.07-2.41615-1.5353-4.46464-3.5838-5.99998-6-1.69002-2.6088-2.74176-5.57901-3.07-8.67-.02499-.27653.00788-.55524.0965-.81838.08863-.26313.23107-.50493.41827-.71.18719-.20507.41504-.36891.66902-.4811.25399-.11219.52855-.17026.80621-.17052h3c.48531-.00478.95579.16708 1.32376.48353.36797.31646.60832.75592.67624 1.23647.12662.96007.36145 1.90273.7 2.81.13455.35792.16366.74691.08391 1.12088-.07976.37397-.26505.71723-.53391.98912l-1.27 1.27c1.42356 2.5035 3.49648 4.5764 5.99998 6l1.27-1.27c.2719-.2689.6152-.4542.9891-.5339.374-.0798.763-.0506 1.1209.0839.9073.3386 1.85.5734 2.81.7.4858.0685.9294.3132 1.2466.6875.3171.3743.4856.8521.4734 1.3425Z"
      />
    </svg>
  );
};

export default BordPhoneCallIcon;
