import useStateSubscriptions from '../../state/useStateSubscriptions';
import BordBadge from '../BordDesignSystem/BordBadge/BordBadge';

const BadgePlatinum = () => {
  const { stateSubscription } = useStateSubscriptions();
  const { isPrime } = stateSubscription;
  return <>{isPrime && <BordBadge variant="gradient" label="Platinum" />}</>;
};

export default BadgePlatinum;
