const SquareEthiopia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#FFDA2C"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 10.6665H22V15.9998H0V10.6665Z" clipRule="evenodd" />
          <path fill="#249F58" fillRule="evenodd" d="M0 0H22V5.33333H0V0Z" clipRule="evenodd" />
          <path
            fill="#1A47B8"
            d="M11.0004 12.8002C13.604 12.8002 15.7147 10.6512 15.7147 8.0002C15.7147 5.34923 13.604 3.2002 11.0004 3.2002C8.39679 3.2002 6.28613 5.34923 6.28613 8.0002C6.28613 10.6512 8.39679 12.8002 11.0004 12.8002Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M10.9981 9.33359L9.45807 10.1581L9.75141 8.41199L8.50684 7.17465L10.2281 6.92185L10.9981 5.33252L11.767 6.92185L13.4883 7.17465L12.2416 8.41199L12.537 10.1571"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEthiopia;
