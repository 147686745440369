const SquareUruguay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path
          fill="#3A99FF"
          fillRule="evenodd"
          d="M10.4762 3.2002H22V5.33353H10.4762V3.2002ZM10.4762 7.46686H22V9.6002H10.4762V7.46686ZM0 11.7335H22V13.8669H0V11.7335Z"
          clipRule="evenodd"
        />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M7.20808 7.33863C6.96713 8.66663 6.41189 9.59997 5.76237 9.59997C5.11285 9.59997 4.55761 8.6677 4.31666 7.33863C3.01237 7.0933 2.0957 6.52797 2.0957 5.86663C2.0957 5.2053 3.01132 4.63997 4.31666 4.39463C4.55761 3.06663 5.11285 2.1333 5.76237 2.1333C6.41189 2.1333 6.96713 3.06557 7.20808 4.39463C8.51237 4.63997 9.42904 5.2053 9.42904 5.86663C9.42904 6.52797 8.51342 7.0933 7.20808 7.33863Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M6.48208 6.59943C6.41504 8.3093 6.11856 9.59997 5.76237 9.59997C5.40618 9.59997 5.1097 8.3093 5.04266 6.59943C3.36332 6.53117 2.0957 6.2293 2.0957 5.86663C2.0957 5.50397 3.36332 5.2021 5.04266 5.13383C5.1097 3.42397 5.40618 2.1333 5.76237 2.1333C6.11856 2.1333 6.41504 3.42397 6.48208 5.13383C8.16142 5.2021 9.42904 5.50397 9.42904 5.86663C9.42904 6.2293 8.16142 6.53117 6.48208 6.59943Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          d="M5.76186 7.4666C6.62973 7.4666 7.33329 6.75026 7.33329 5.8666C7.33329 4.98295 6.62973 4.2666 5.76186 4.2666C4.89398 4.2666 4.19043 4.98295 4.19043 5.8666C4.19043 6.75026 4.89398 7.4666 5.76186 7.4666Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareUruguay;
