const SquareSeychelles = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 16L9.42857 0H0V16Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 16L16.7619 0H22V4.26667L0 16Z" clipRule="evenodd" />
          <path fill="white" fillRule="evenodd" d="M0 15.9999L22 9.59994V4.2666L0 15.9999Z" clipRule="evenodd" />
          <path fill="#249F58" fillRule="evenodd" d="M0 16.0001L22 9.6001V16.0001H0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSeychelles;
