import create from 'zustand';
import { IPrincipalBodyAlert, IEventsUnassignment } from '../types/requestLogisticsModule';

export interface iState {
  estimatedCollection?: string;
  setEstimatedCollection: (estimatedCollection?: string) => void;
  estimatedDelivery?: string;
  setEstimatedDelivery: (setEstimatedCollection?: string) => void;
  superiorAlert?: string | JSX.Element;
  setSuperiorAlert: (superiorAlert?: string | JSX.Element) => void;
  inferiorAlert?: IPrincipalBodyAlert;
  setInferiorAlert: (inferiorAlert?: IPrincipalBodyAlert) => void;
  stateEventsUnassignment?: IEventsUnassignment;
  setStateEvenstUnassignment: (IEventsUnassignment?: IEventsUnassignment) => void;
}

const useRequireLogisticsSummaryData = create<iState>(set => ({
  estimatedCollection: undefined,
  setEstimatedCollection: estimatedCollection => set({ estimatedCollection }),
  estimatedDelivery: undefined,
  setEstimatedDelivery: estimatedDelivery => set({ estimatedDelivery }),
  superiorAlert: undefined,
  setSuperiorAlert: superiorAlert => set({ superiorAlert }),
  inferiorAlert: undefined,
  setInferiorAlert: inferiorAlert => set({ inferiorAlert }),
  stateEventsUnassignment: undefined,
  setStateEvenstUnassignment: stateEventsUnassignment => set({ stateEventsUnassignment })
}));

export default useRequireLogisticsSummaryData;
