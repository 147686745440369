import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePlatform from '../../../hooks/usePlatform';
import BordSidebar from '../../BordDesignSystem/BordSidebar/BordSidebar';
import { IbordSidebarButtonProps } from '../../BordDesignSystem/BordSidebarButton/BordSidebarButton.types';
import { shouldHideSidebar, shouldShowSidebarClosed } from '../sidebar.utils';
import { handleClickForHelp } from '../../../utils/global.utils';
import useLogOutState from '../../../state/useLogOutState';
import LogoutModal from '../LogoutModal/LogoutModal';
import UpcomingReportsModuleHype from '../UpcomingReportsModuleHype/UpcomingReportsModuleHype';

const SaasSidebar = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { isLargerOrEqualThanCustomSize } = usePlatform();
  const { isLogoutModalOpen, setIsLogoutModalOpen } = useLogOutState();

  const sidebarIsAlwaysOpen = isLargerOrEqualThanCustomSize(1920);
  const hideSidebar = shouldHideSidebar(pathname);
  const closedSidebar = shouldShowSidebarClosed(pathname);
  const isSidebarOpen = sidebarIsAlwaysOpen || !closedSidebar;
  const translationKey = 'nodi:sidebar:sidebarModules:';

  const isSidebarModuleActive = (currentPath: string, modulePaths: string[]) => {
    const test = modulePaths.some(path => currentPath.includes(path));
    return test;
  };

  const redirectionCallback = (redirectionPath: string) => {
    push(redirectionPath);
  };

  const clickLogout = () => setIsLogoutModalOpen(true);

  const sidebarBaseModules: IbordSidebarButtonProps[] = [
    {
      buttonText: t(`${translationKey}home`),
      buttonIcon: 'menuSquare',
      isOpen: isSidebarOpen,
      isSelected: isSidebarModuleActive(pathname, ['dashboard']),
      onClick: () => redirectionCallback('/nodi/dashboard')
    },
    {
      buttonText: t(`${translationKey}employees`),
      buttonIcon: 'employee',
      isOpen: isSidebarOpen,
      isSelected: isSidebarModuleActive(pathname, ['employees']),
      onClick: () => redirectionCallback('/nodi/employees')
    },
    {
      buttonText: t(`${translationKey}inventory`),
      buttonIcon: 'inventory',
      isOpen: isSidebarOpen,
      isSelected: isSidebarModuleActive(pathname, ['tools', 'assignation', 'nodi/details', 'upload-external-tools']),
      onClick: () => redirectionCallback('/nodi/tools')
    },
    {
      buttonText: t(`${translationKey}orders`),
      buttonIcon: 'shoppingCartCheck2',
      isOpen: isSidebarOpen,
      isSelected: isSidebarModuleActive(pathname, ['orders']),
      onClick: () => redirectionCallback('/nodi/orders')
    },
    {
      buttonText: t(`${translationKey}logisticServices`),
      buttonIcon: 'trackingTruck',
      isOpen: isSidebarOpen,
      isSelected: isSidebarModuleActive(pathname, ['logistics-services']),
      onClick: () => redirectionCallback('/nodi/logistics-services')
    },
    {
      buttonText: t(`${translationKey}reports:moduleTitle`),
      buttonIcon: 'pieChart',
      isOpen: isSidebarOpen,
      disabled: true,
      hoverComponent: <UpcomingReportsModuleHype />
    }
  ];

  const sidebarAdditionalModules: IbordSidebarButtonProps[] = [
    {
      buttonText: t(`${translationKey}support`),
      buttonIcon: 'helpCircle',
      isOpen: isSidebarOpen,
      isSelected: false,
      onClick: handleClickForHelp
    },
    {
      buttonText: t(`${translationKey}settings`),
      buttonIcon: 'settings',
      isOpen: isSidebarOpen,
      isSelected: isSidebarModuleActive(pathname, ['nodi/account']),
      onClick: () => redirectionCallback('/nodi/account')
    },
    {
      buttonText: t(`${translationKey}logout`),
      buttonIcon: 'logout1',
      isOpen: isSidebarOpen,
      isSelected: false,
      onClick: clickLogout
    }
  ];

  if (hideSidebar) return <></>;

  return (
    <div className="saasSidebar">
      {isLogoutModalOpen && <LogoutModal />}
      <BordSidebar
        isOpen={isSidebarOpen}
        sidebarBaseModules={sidebarBaseModules}
        sidebarAdditionalModules={sidebarAdditionalModules}
        logoProps={{
          onClick: () => push('/nodi/dashboard'),
          style: { cursor: 'pointer' }
        }}
      />
    </div>
  );
};

export default SaasSidebar;
