const RoundStVincentAndTheGrenadines = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M23.6517 1.94544C21.3786 0.705188 18.7714 0 15.9996 0C13.2277 0 10.6206 0.705188 8.34737 1.94544L6.95605 16L8.34737 30.0546C10.6206 31.2948 13.2277 32 15.9996 32C18.7714 32 21.3786 31.2948 23.6517 30.0546L25.0431 16L23.6517 1.94544Z"
        />
        <path
          fill="#3ECBF8"
          d="M8.34781 30.0544V1.94531C3.37431 4.65906 0 9.93525 0 15.9999C0 22.0646 3.37431 27.3408 8.34781 30.0544Z"
        />
        <path
          fill="#6DA544"
          d="M32.0002 15.9999C32.0002 9.93525 28.6258 4.65906 23.6523 1.94531V30.0544C28.6258 27.3408 32.0002 22.0646 32.0002 15.9999ZM12.5219 20.1738 9.73926 15.9999 12.5219 11.8259 15.3046 15.9999 12.5219 20.1738ZM19.4789 20.1738 16.6963 15.9999 19.4789 11.8259 22.2615 15.9999 19.4789 20.1738ZM16.0004 25.7392 13.2178 21.5652 16.0004 17.3914 18.783 21.5652 16.0004 25.7392Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundStVincentAndTheGrenadines;
