import { FC } from 'react';
interface IItabs {
  title: string;
  handleChange?: () => void;
  isSelect: boolean;
}

const Tabs: FC<IItabs> = ({ title, handleChange, isSelect }: IItabs) => {
  const changeTab = () => {
    if (handleChange) {
      handleChange();
    }
  };
  return (
    <div id="accountTabs">
      <div className={`generalTab ${isSelect ? 'tabSelect' : 'tabNoSelect'}`} onClick={changeTab}>
        <div className={`textContainer ${isSelect ? 'whiteText' : 'grayText'}`}>{title}</div>
      </div>
    </div>
  );
};
export default Tabs;
