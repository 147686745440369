const RoundBhutan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M27.3137 27.3138c-6.2484 6.2484-16.379 6.2484-22.62748 0-6.24837-6.2484-6.24837-16.3791 0-22.6275.00225 0 14.37858-4.247937 20.62708 2.00044 6.2484 6.24836 2.0047 20.62266 2.0004 20.62706Z"
        />
        <path
          fill="#F93939"
          d="M27.3137 27.3138c6.2484-6.2484 6.2484-16.3791 0-22.62749-6.2484-6.24844-16.379-6.24838-22.62742 0L27.3137 27.3138Z"
        />
        <path
          fill="#FF9811"
          d="M20.1823 20.1838c-.6573.2581-1.421.2307-2.0835-.1411-1.1707-.657-1.5885-2.1441-.9313-3.3149l-.1995-.1119c2.0044-.4833 3.4873-2.3027 3.4613-4.4497-.0161-1.3348-.6125-2.52901-1.5455-3.34613L17.423 10.3167c.5523.4401.9103 1.1152.9195 1.8748.0163 1.3425-1.0626 2.4479-2.405 2.4641l.0028.2286c-1.4208-1.4941-3.7378-1.8687-5.5842-.7727-1.14783.6815-1.88371 1.795-2.12502 3.0116l2.02652.5168c.105-.6983.5106-1.346 1.1638-1.7338 1.1544-.6853 2.6512-.3035 3.3365.8508l.1967-.1166c-.5836 1.9773.2505 4.1713 2.1229 5.2223 1.1641.6533 2.4964.7339 3.6706.3346l-.5658-2.0134Zm-4.228-4.1372-.0002.0001.0002-.0001Z"
        />
        <path
          fill="#FFEACF"
          d="m21.9241 10.8363-1.1308 4.0357-3.3932-.6265-2.1841-4.50221-5.90042 2.09381-.46531-1.3113-1.54375-.1901 1.16319 3.278 4.06059-1.0385 1.1539 3.2518-2.807 4.1426 4.7636 4.0629-.903 1.0585.6072 1.4322 2.2574-2.6465-2.9296-2.9973 2.2392-2.6252 4.9911.3596 1.1368-6.1568 1.3682.2527.9368-1.2419-3.4208-.6315Zm-5.9253 5.2726v.0001-.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBhutan;
