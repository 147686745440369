const RoundArgentina = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#3ECBF8"
          d="M16 0C9.65662 0 4.17562 3.69156 1.58762 9.0435H30.4123C27.8244 3.69156 22.3433 0 16 0ZM16 32c6.3433 0 11.8244-3.6915 14.4124-9.0435H1.58762C4.17562 28.3085 9.65662 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="m20.7822 16-1.9541.9192 1.0406 1.8924-2.1219-.4059-.2689 2.1435L16 18.9727l-1.478 1.5765-.2688-2.1435-2.1219.4059 1.0405-1.8925L11.2178 16l1.9541-.9192-1.0406-1.8924 2.1218.4059.269-2.1435L16 13.0273l1.478-1.5765.2688 2.1435 2.1219-.4059-1.0405 1.8925 1.954.9191Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundArgentina;
