import { ComponentProps } from 'react';

const BordCircularWorldAvatar = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <rect width="47" height="47" x="0.5" y="0.5" fill="#FF6175" rx="23.5" />
      <rect width="47" height="47" x="0.5" y="0.5" stroke="#626C82" rx="23.5" />
      <path
        stroke="white"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M34.666 15.5986C33.4197 15.6884 31.8234 16.1709 30.7165 17.6037C28.7173 20.1914 26.718 20.4074 25.3852 19.5448C23.3859 18.2509 25.066 16.1551 22.7195 15.0162C21.1902 14.2739 20.977 12.2539 21.8281 10.6666M10.666 22.6666C11.6827 23.5495 13.1066 24.3575 14.7843 24.3575C18.2506 24.3575 18.9438 25.0198 18.9438 27.669C18.9438 30.3182 18.9438 30.3182 19.6371 32.3051C20.088 33.5975 20.2456 34.8898 19.3468 36M37.3327 25.9364C36.1499 25.2547 34.666 24.9743 33.1639 26.0539C30.2896 28.1197 28.3078 26.408 27.4153 28.1186C26.1013 30.6366 30.7936 31.4281 26.666 37.3333"
        opacity="0.4"
      />
      <path
        stroke="white"
        strokeWidth="1.5"
        d="M37.3327 24C37.3327 16.6362 31.3631 10.6667 23.9993 10.6667C16.6356 10.6667 10.666 16.6362 10.666 24C10.666 31.3638 16.6356 37.3334 23.9993 37.3334C31.3631 37.3334 37.3327 31.3638 37.3327 24Z"
      />
    </svg>
  );
};

export default BordCircularWorldAvatar;
