const RoundAntiguaAndBarbuda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M0 16c0 1.0959.1105 2.1661.320437 3.2L16 20.1739 31.6796 19.2c.21-1.0339.3204-2.1041.3204-3.2 0-1.0959-.1105-2.1661-.3204-3.2L16 11.826.320437 12.8C.1105 13.8339 0 14.9041 0 16Z"
        />
        <path
          fill="#fff"
          d="M31.6796 19.2H.320435C1.80287 26.5031 8.25943 32 16 32c7.7406 0 14.1971-5.4969 15.6796-12.8Z"
        />
        <path
          fill="#000"
          d="M.320435 12.8H31.6797C30.1971 5.49688 23.7407 0 16 0 8.25931 0 1.80287 5.49688.320435 12.8Z"
        />
        <path
          fill="#FFDA2C"
          d="M22.9565 12.8001H9.04349l2.84251-1.3372-1.5137-2.75278 3.0866.59032.3912-3.11807L16 8.47575l2.15-2.29338.391 3.11807 3.0867-.59032-1.5136 2.75298 2.8424 1.337Z"
        />
        <path
          fill="#A2001D"
          d="M0 16c0 8.8365 7.1635 16 16 16L3.19975 6.39941C1.19075 9.07373 0 12.3977 0 16ZM16 32c8.8365 0 16-7.1635 16-16 0-3.6023-1.1908-6.92627-3.1998-9.60059L16 32Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAntiguaAndBarbuda;
