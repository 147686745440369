const RoundDominicanRepublic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#F93939" d="M0.550781 20.1738C2.02816 25.6553 6.34422 29.9714 11.8257 31.4488V20.1738H0.550781Z" />
        <path
          fill="#1A47B8"
          d="M11.8257 0.55127C6.34422 2.02858 2.02809 6.34471 0.550781 11.8261H11.8257V0.55127H11.8257Z"
        />
        <path
          fill="#F93939"
          d="M31.4487 11.8261C29.9714 6.34471 25.6553 2.02858 20.1738 0.55127V11.8262H31.4487V11.8261Z"
        />
        <path fill="#1A47B8" d="M20.1738 31.4488C25.6553 29.9714 29.9714 25.6553 31.4488 20.1738H20.1738V31.4488Z" />
        <path
          fill="#496E2D"
          d="M20.174 16.0001C20.174 18.3054 18.3054 20.174 16.0001 20.174C13.6949 20.174 11.8262 18.3054 11.8262 16.0001C11.8262 13.6949 16.0001 11.8262 16.0001 11.8262C16.0001 11.8262 20.174 13.6949 20.174 16.0001Z"
        />
        <path
          fill="#1A47B8"
          d="M11.8262 16.0001C11.8262 13.6949 13.6949 11.8262 16.0001 11.8262C18.3054 11.8262 20.174 13.6949 20.174 16.0001"
        />
        <path
          fill="#F93939"
          d="M13.6523 13.9131V16.5218C13.6523 17.8185 14.7035 18.8696 16.0002 18.8696C17.2968 18.8696 18.348 17.8185 18.348 16.5218V13.9131H13.6523Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundDominicanRepublic;
