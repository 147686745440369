const SquareZimbabwe = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#249F58" rx="2" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 2.1333H22V13.8666H0V2.1333Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 4.2666H22V11.7333H0V4.2666Z" clipRule="evenodd" />
          <path fill="#151515" fillRule="evenodd" d="M0 6.3999H22V9.5999H0V6.3999Z" clipRule="evenodd" />
          <path fill="#151515" fillRule="evenodd" d="M1.04762 0L12.5714 8L1.04762 16H0V0" clipRule="evenodd" />
          <path fill="white" fillRule="evenodd" d="M0 0L11.5238 8L0 16V0Z" clipRule="evenodd" />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M4.71389 9.3335L3.17389 10.158L3.46723 8.4119L2.22266 7.17456L3.94389 6.92176L4.71389 5.3335L5.48389 6.92283L7.20513 7.17563L5.95847 8.41296L6.2518 10.158"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M3.14258 9.5999H6.28544V8.53324L5.23782 7.46657C5.23782 7.46657 5.23782 6.3999 4.1902 6.3999H3.14258L4.1902 7.46657L3.14258 9.5999Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareZimbabwe;
