const RoundDjibouti = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M14.609 17.3913S4.6959 4.68406 4.68652 4.68631C7.5819 1.79088 11.582 0 16.0003 0c8.8365 0 16 7.1635 16 16L14.609 17.3913Z"
        />
        <path
          fill="#6DA544"
          d="M14.609 16S4.6959 27.3159 4.68652 27.3137C7.5819 30.2091 11.582 32 16.0003 32c8.8365 0 16-7.1635 16-16H14.609Z"
        />
        <path
          fill="#fff"
          d="M4.68628 4.68628c-6.24837 6.24842-6.24837 16.37902 0 22.62752C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="#F93939"
          d="m6.4756 11.8262 1.03594 3.1885h3.35286l-2.71236 1.9707 1.03587 3.1886-2.71231-1.9706-2.71244 1.9706 1.03613-3.1886-2.71238-1.9707h3.35263l1.03606-3.1885Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundDjibouti;
