import { BordButton } from '../../../../../../../../components/BordDesignSystem';
import BordIlustrationIcon from '../../../../../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import { IlogisticServiceDetailedData } from '../../../../../../../../types/logisticsServicesModule.types';
import './ReviewLogisticQuoteModal.scss';

const ReviewLogisticQuoteModalContent = ({
  logisticServiceData,
  handleLsRejection,
  approveOrRejectLogisticQuote
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  handleLsRejection: () => void;
  approveOrRejectLogisticQuote: (approveOrReject: 'approved' | 'rejected') => void;
}) => {
  return (
    <div className="reviewLogisticQuoteModalContainer">
      <BordIlustrationIcon variant="box" standardSize={64} />
      <BordButton
        modeButton="tertiary"
        label="Revisar cotización"
        buttonIconTwo="download"
        onClick={() => window.open(logisticServiceData?.quoteDocumentUrl, 'blank')}
      />
      <div className="buttonsContainer">
        <BordButton label="Rechazar cotización" onClick={handleLsRejection} modeButton="tertiary" />
        <BordButton label="Aprobar cotización" onClick={() => approveOrRejectLogisticQuote('approved')} />
      </div>
    </div>
  );
};

export default ReviewLogisticQuoteModalContent;
