import { useTranslation } from 'react-i18next';
import { NudosHoverText } from '../../../../../../../components/NudosComponents';
import useStateSubscriptions from '../../../../../../../state/useStateSubscriptions';
import { IProductUpdate } from '../../../../../../../types/cart';
import { formatCost, nudosFormatCurrency } from '../../../../../../../utils/formatNumbers';
import { AddingSubtractingInputButton } from '../../../../../Components';
import { Link } from 'react-router-dom';
import { BordPhoto } from '../../../../../../../components/BordDesignSystem';
import { bordColorTheme } from '../../../../../../../styles/constants';
import { formatPills } from '../../../../../../../utils/productDetails';
import { DynamicsPills } from '../../../../../../../components';

import './CheckoutProductItemCard.scss';

/**
 * @property { IProductUpdate } itemData - an object with the data of the product, including the following properties productId (number), productName (string), unitPrice (number| string) brand (string), brandImage (string), image (string), pills ({[key: string]: string}) quantity (number), countryId (number), countryName (string), countryFlag (string)
 */
const CheckoutProductItemCard = ({ itemData }: { itemData: IProductUpdate }) => {
  const { productId, image, productName, brandImage, pills, unitPrice, quantity, prices } = itemData;
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const { isPrime } = stateSubscription;
  const { prices: productPrices } = itemData;
  const returnText = t('designSystemComponents:referenceOptions:checkoutRedirectionText');

  const pillsList = pills ? formatPills({ pillsList: pills, excludeItems: ['Cargo'] }) : undefined;

  const toolDetailsRedirectionData = {
    pathname: `/catalogue/product/${productId}`,
    state: {
      returnText: returnText,
      returnUrl: '/catalogue/checkout'
    }
  };

  const formatMainPrice = () => {
    if (productPrices) {
      const mainPrice = Number(isPrime ? productPrices?.priceWithPrime || 0 : productPrices?.priceWithoutPrime || 0);
      return `${nudosFormatCurrency({
        value: mainPrice * quantity,
        maximumFractionDigits: 2
      })} USD`;
    }
    return `${formatCost(+unitPrice * quantity)} USD`;
  };

  const formatSecondaryPrice = () => {
    if (productPrices) {
      const secondaryPrice = Number(
        isPrime ? productPrices.priceWithPrime || 0 : productPrices?.priceWithoutPrime || 0
      );
      return `${nudosFormatCurrency({ value: secondaryPrice, maximumFractionDigits: 2 })} USD`;
    }
    return `${nudosFormatCurrency({ value: Number(unitPrice || 0), maximumFractionDigits: 2 })} USD`;
  };

  const formatSecondaryGrayLightText = () => {
    if (prices) {
      const alternatePrice = Number(isPrime ? prices.priceWithoutPrime || 0 : prices.priceWithPrime || 0);
      return `${nudosFormatCurrency({ value: alternatePrice, maximumFractionDigits: 2 })} USD`;
    }
    return `${nudosFormatCurrency({ value: Number(unitPrice || 0), maximumFractionDigits: 2 })} USD`;
  };

  return (
    <div className="checkoutProductItemCard">
      <Link to={toolDetailsRedirectionData} className="generalActionable" />
      <div className="imageSection">
        <BordPhoto url={image} size="s56" />
        <div className="brandContainer">
          <BordPhoto
            variant="circle"
            url={brandImage}
            size="s32"
            oneToneIconProps={{ variant: 'bord', customWidth: '3.2rem', stroke: bordColorTheme.navyBlue[600] }}
            imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '65%', maxWidth: '65%' } }}
          />
        </div>
      </div>

      <div className="detailsSection">
        <div className="boxDetailsSection">
          <div className="nameSection">
            <NudosHoverText customClassName="nameHoverCustom" text={productName} charactersLimit={25} onlyIfTruncated />
          </div>
          <div className="pillsSection">
            {!!pillsList?.pills &&
              pillsList.pills.map(pill => {
                return (
                  <DynamicsPills
                    key={`check-out-pill${pill.value}`}
                    dataPills={{ hoverText: pill.name, text: pill.value }}
                    elementsNumber={pillsList?.pills?.length || 0}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <AddingSubtractingInputButton itemData={itemData} />
      <div className="priceSection">
        <div className="boxPriceSection">
          <div className="totalPrice">{formatMainPrice()}</div>
          <div className="unitPriceContainer">
            {Number(unitPrice || 0) >= 170 && isPrime && (
              <div className="containerExtraInfo">
                <div className="textGrayLine">{formatSecondaryGrayLightText()}</div>
              </div>
            )}
            <div className="textWhiteLine">
              {formatSecondaryPrice()} {'c/u'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckoutProductItemCardSkeletonLoader = () => {
  return (
    <div className="checkoutProductItemCard checkoutProductItemCardSkeletonLoader">
      <div className="imageSection animationLoader" />
      <div className="nameAndPillsSection">
        <div className="nameAndBrand animationLoader" />
        <div className="pillsSkeleton">
          <div className="pill animationLoader" />
          <div className="pill animationLoader" />
          <div className="pill animationLoader" />
        </div>
      </div>
      <div className="buttonSkeleton animationLoader" />
      <div className="priceSection">
        <div className="totalPrice animationLoader" />
        <div className="unitPrice animationLoader" />
      </div>
    </div>
  );
};

export { CheckoutProductItemCard, CheckoutProductItemCardSkeletonLoader };
