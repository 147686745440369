import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M1.75 12.25L1.75 2.1875" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.25 12.25L12.25 2.1875" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.75 11.4121L8.75 3.02669" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.25 11.4121L5.25 3.02669" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SerialIcon = memo(ForwardRef);
export default SerialIcon;
