import { useHistory } from 'react-router-dom';
import { NudosHoverText } from '../../../../../../components/NudosComponents';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { DTOlogisticsServiceInListingData } from '../../../../../../types/logisticsServicesModule.types';
import LogisticsStatusDisplay from '../../../Components/LogisticsStatusDisplay/LogisticsStatusDisplay';
import { useTranslation } from 'react-i18next';
import { BordFlag, BordOneToneIcon } from '../../../../../../components/BordDesignSystem';
import { getLocationIcon } from '../../../../../../utils/global.utils';
import { LOCATION_PLACE_TYPE } from '../../../../../../types/global';
import { bordColorTheme } from '../../../../../../styles/constants';
import './LogisticServiceCardInListing.scss';

const LogisticServiceCardInListing = ({ logisticsServiceData }: ILogisticsServiceCardInListing) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const {
    id,
    productsQuantity,
    status,
    statusStringId,
    createdAt,
    paymentData,
    originName,
    originPlace,
    originCity,
    originCountryCode,
    destinationName,
    destinationPlace,
    destinationCity,
    destinationCountryCode
  } = logisticsServiceData;

  const recurrentWords = 'recurrentWords:';
  const paymentMethodKey = 'services:paymentType:';
  const flagSize = 16;
  const citiesCharactersLimit = 20;
  const redirectToLogisticServiceDetails = () => push(`logistics-services/details/${id}`);

  const getLocationUI = (place?: string | null, locatioName?: string | null) => {
    const iconVariant = getLocationIcon({ place: place as LOCATION_PLACE_TYPE });
    return (
      <div className="locationContainer">
        <BordOneToneIcon variant={iconVariant} standardSize={12} stroke={bordColorTheme.content.default} />
        <NudosHoverText onlyIfTruncated customClassName="locationName" text={locatioName || ''} charactersLimit={17} />
      </div>
    );
  };

  return (
    <div className="logisticServiceCardInListing" onClick={redirectToLogisticServiceDetails}>
      <div className="dateAndId">
        <div className="logisticServiceId">SL Nº{id}</div>
        <div className="creationDate">{formatShortDate(createdAt || '')?.textWithTimezone}</div>
      </div>
      <div className="originAndDestination">
        <div className="locationsData">
          {getLocationUI(originPlace, originName)}
          <BordOneToneIcon variant="arrowRight" standardSize={12} stroke={bordColorTheme.content.default} />
          {getLocationUI(destinationPlace, destinationName)}
        </div>
        <div className="citiesAndFlags">
          <div className="location">
            <BordFlag variant="square" standardSize={flagSize} country={originCountryCode || 'zz'} />
            <NudosHoverText text={originCity || ''} onlyIfTruncated charactersLimit={citiesCharactersLimit} />
          </div>
          <BordOneToneIcon variant="arrowRight" standardSize={12} stroke={bordColorTheme.content.secondary} />
          <div className="location">
            <BordFlag variant="square" standardSize={flagSize} country={destinationCountryCode || 'zz'} />
            <NudosHoverText text={destinationCity || ''} onlyIfTruncated charactersLimit={citiesCharactersLimit} />
          </div>
        </div>
      </div>
      <div className="numberOfProductsSection">
        <div className="title">{t('recurrentWords:devices')}</div>
        <div className="value">{productsQuantity}</div>
      </div>
      <div className="paymentMethodSection">
        {!!paymentData?.paymentTypeNameStringId && (
          <div className="title">{t(`${recurrentWords}textPaymentMethod`)}</div>
        )}
        {!!paymentData?.paymentTypeNameStringId && (
          <div className="value">{t(`${paymentMethodKey}${paymentData?.paymentTypeNameStringId}`)}</div>
        )}
      </div>
      <LogisticsStatusDisplay statusName={status || ''} statusStringId={statusStringId || ''} />
      {
        <div className="goToDetailsIcon">
          <BordOneToneIcon variant="arrowRightCircle" stroke={bordColorTheme.content.default} customWidth="1.8rem" />
        </div>
      }
    </div>
  );
};

const LogisticServiceCardInListingSkeletonLoader = () => {
  return (
    <div className="logisticServiceCardInListingSkeletonLoader">
      <div className="dateAndId">
        <div className="animationLoader" />
      </div>
      <div className="originAndDestination">
        <div className="animationLoader" />
      </div>
      <div className="numberOfProductsSection">
        <div className="animationLoader" />
      </div>
      <div className="paymentMethodSection">
        <div className="animationLoader" />
      </div>
      <div className="logisticsStatusDisplay">
        <div className="animationLoader" />
      </div>
    </div>
  );
};

export { LogisticServiceCardInListing, LogisticServiceCardInListingSkeletonLoader };

interface ILogisticsServiceCardInListing {
  logisticsServiceData: DTOlogisticsServiceInListingData;
}
