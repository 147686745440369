import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconArrow,
  IconBuilding,
  IconEmployee,
  IconPin,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { InudoInfo, LOCATION_PLACE_TYPE } from '../../../../../types/global';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import NudosRedirectionIcon from '../../../../../components/DesignSystem/NudosRedirectionIcon/NudosRedirectionIcon';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import BordStatus from '../../../../../components/BordDesignSystem/BordStatus/BordStatus';
import { BordOneToneIcon, BordPhoto } from '../../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import { renderVarianStatus } from '../../constants';
import { statusVariants } from '../../../../../components/BordDesignSystem/BordStatus/BordStatus.type';
import { capitalize } from '../../../../../utils/unCamelCase';
import { getLocationIcon } from '../../../../../utils/global.utils';
import './ActiveLogisticsServiceCard.scss';

interface IActiveLogisticsServiceCard {
  dataLS?: InudoInfo;
  generalLoading?: boolean;
}

const ActiveLogisticsServiceCard: FC<IActiveLogisticsServiceCard> = ({ dataLS }: IActiveLogisticsServiceCard) => {
  const { push } = useHistory();

  const lastAssociatedLocation = dataLS?.oldAssignment
    ? dataLS?.oldAssignment?.find(location => !location.exitDate) || dataLS?.oldAssignment[0]
    : undefined;
  const lastAssigneeUserId = lastAssociatedLocation?.userId;
  const originLocationIsUser = dataLS?.place === LOCATION_PLACE_TYPE.USER;
  const lastLocationEntryDate = formatShortDate(lastAssociatedLocation?.entryDate || '')?.onlyDate || '-';
  const displayBuyAndHoldTag = !!dataLS?.isBuyAndHold;

  const generateOriginData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          profileImg: dataLS?.oldAssignment?.[0]?.photoProfile,
          name: dataLS?.locationUserName,
          dateTitle: 'Onboarding: '
        };
      case 'other':
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />,
          name: dataLS?.locationName || 'Otra dirección'
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${dataLS?.country?.name}`
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: '.bord Warehouse'
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca'
        };
      case 'diagnostic':
        return {
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          name: 'Diagnóstico'
        };
      default:
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />,
          name: dataLS?.country?.name
        };
    }
  };

  const generateDestinationData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          profileImg: dataLS?.currentAssignment?.[0]?.photoProfile,
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          name: dataLS?.destination?.locationUserName,
          details: dataLS?.currentAssignment?.[0]?.email || ''
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: '.bord Warehouse'
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${dataLS?.destination?.country?.name}`,
          details: dataLS?.destination?.address
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca'
        };
      case 'diagnostic':
        return {
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          name: 'Diagnóstico'
        };
      default:
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />
        };
    }
  };

  const originData = generateOriginData(dataLS?.place);

  const getRedirectToEmployeeDetails = (userId?: string | number) => {
    if (!userId) return {};
    return {
      pathname: `/nodi/employees/details/${userId}`,
      state: {
        originUrl: `/nodi/details/${dataLS?.productId}`,
        returnText: 'Herramienta'
      }
    };
  };

  const redirectToOriginEmployee = getRedirectToEmployeeDetails(lastAssigneeUserId);
  const redirectToDestinationEmployee = getRedirectToEmployeeDetails(dataLS?.userId || undefined);
  const urlUserImage = generateDestinationData(dataLS?.destination?.place ?? '')?.profileImg;
  const renderProfileUser = (
    <BordPhoto
      url={urlUserImage}
      oneToneIconProps={{
        variant: getLocationIcon({
          place: (dataLS?.destination?.place as LOCATION_PLACE_TYPE) ?? LOCATION_PLACE_TYPE.NUDOS
        }),
        standardSize: 24
      }}
      flagProps={{
        variant: 'circle',
        country: dataLS?.destination?.country?.code as TcountryCode,
        standardSize: 20
      }}
      style={{
        backgroundColor: urlUserImage ? '' : '#262B39'
      }}
      size="s48"
    />
  );

  return (
    <div id="activeLogisticsServiceCard">
      <div className="titleSL">Asignado / ubicado:</div>

      <div className="flex items-center gap-4 mt-12">
        <div className="nameSL">
          <div className="title">Servicio logístico</div>
        </div>
        <BordStatus
          variant={renderVarianStatus({ variant: dataLS?.logisticStatus as statusVariants }) as statusVariants}
          renderJSX={
            <div
              className="customBordStatusContainer"
              onClick={() => {
                push(
                  `/nodi/logistics-services/details/${dataLS?.logisticServiceId}?from=tools&id=${dataLS?.productId}`
                );
              }}
            >
              <span className="text-bord-sm font-normal">{capitalize(dataLS?.logisticStatus ?? '')}</span>
              <div className="w-1 h-16 bg-stroke ml-4 mr-4"></div>
              <span className="text-bord-caption">Ir</span>
              <BordOneToneIcon variant="arrowRight" standardSize={12} />
            </div>
          }
        />
      </div>

      <div className="containerActiveLogisticsServiceCard">
        <div className="boxActiveLogisticsServiceCard">
          <div className="flex items-center gap-20">
            <BordPhoto
              oneToneIconProps={{
                variant: getLocationIcon({
                  place: (dataLS?.place as LOCATION_PLACE_TYPE) ?? LOCATION_PLACE_TYPE.NUDOS
                }),
                standardSize: 24
              }}
              flagProps={{
                country: dataLS?.country?.code as TcountryCode,
                variant: 'circle',
                standardSize: 20
              }}
              style={{
                backgroundColor: '#262B39'
              }}
              size="s48"
            />
            <div className="originContainerPlace">
              <div className="generalPlace">
                <div className="titleContainer">
                  <div className="originTitle">{originData.name}</div>
                  {lastAssigneeUserId && originLocationIsUser && (
                    <NudosRedirectionIcon redirectionDataObject={redirectToOriginEmployee} />
                  )}
                </div>
                <div className="movementDates">
                  <span className="dateTitle">{originData?.dateTitle || 'Ingreso: '}</span>
                  <span className="date">{lastLocationEntryDate}</span>
                </div>
              </div>
            </div>
          </div>
          <BordOneToneIcon variant="arrowRight" customWidth="1.8rem" />
          <div className="flex items-center gap-20">
            <div>{renderProfileUser}</div>
            <div className="destinationContainerPlace">
              <div className="generalPlace">
                <div className="titleContainer">
                  <div className="originTitle">{generateDestinationData(dataLS?.destination?.place || '').name}</div>
                  {dataLS?.destination.place === 'user' && (
                    <NudosRedirectionIcon redirectionDataObject={redirectToDestinationEmployee} />
                  )}
                </div>
                {generateDestinationData(dataLS?.destination?.place || '').details && (
                  <div className="originSubtitle">
                    {generateDestinationData(dataLS?.destination?.place || '').details}
                  </div>
                )}
              </div>
            </div>
          </div>
          {displayBuyAndHoldTag && <div className="buyAndHold">Buy and Hold</div>}
        </div>
      </div>
    </div>
  );
};
export default ActiveLogisticsServiceCard;
