const SquareTanzania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#249F58" rx="2" />
          <path fill="#3ECBF8" fillRule="evenodd" d="M0 16H22V2.1333L0 13.8666V16Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M0 14.9331L22 3.19977V-1.06689L0 10.6664V14.9331Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M0 17.0669L22 5.33356V1.06689L0 12.8002V17.0669Z"
            clipRule="evenodd"
          />
          <path fill="#151515" fillRule="evenodd" d="M0 16L22 4.26667V0L0 11.7333V16Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTanzania;
