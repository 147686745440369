import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import noImg from '../../../assets/images/views/Admin/dashboard/noImg.png';
import { getDataNotifications } from '../../../services/dashboard';
import { IDataNotifications } from '../../../types/dashboard';
import {
  getOrganizationAccount,
  getPersonalAccount,
  getReleaseVideoInformation,
  putReleaseVideoSeen
} from '../../../services/account';
import { GenericLoader, NotificationCard } from './components';
import { segmentTrackEvent } from '../../../utils/segment';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { blueTutoriel, updateStatusTutoriel } from '../../../services/tutoriel';
import { getOrgData, getSessionLogin } from '../../../utils/getLocalStorageData';
import { Trans, useTranslation } from 'react-i18next';
import { BordButton, BordOneToneIcon, BordPhoto } from '../../../components/BordDesignSystem';
import BordEmptyState from '../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import BordReleaseVideoModal from '../../../components/BordReleaseVideoModal/BordReleaseVideoModal';
import BordWelcomeModal from '../../../components/BordWelcomeModal/BordWelcomeModal';
import { handleClickForHelp } from '../../../utils/global.utils';
import { NudosLinkTypeButton } from '../../../components/DesignSystem';
import { BordBettaBannerBg } from '../../../assets/images/components/Banners/indext';
import BordTextButton from '../../../components/BordDesignSystem/BordTextButton/BordTextButton';
import { bordColorTheme } from '../../../styles/constants';
import { DASHBOARD_ONBOARDING_TYPES } from '../../../types/account';
import './DashBoardModule.scss';

const DashBoardModule = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const orgData = getOrgData();
  const userLogin = getSessionLogin();

  const [organizationImg, setOrganizationImg] = useState<string>();

  const [notifiacations, setNotifications] = useState<IDataNotifications[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true);
  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState<boolean>(false);
  const [loadingBlueButton, setLoadingBlueButton] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState<boolean>(false);
  const [loadingReleasedVideo, setLoadingReleasedVideo] = useState<boolean>(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState<boolean>(false);
  const [showBordBetaModal, setShowBordBetaModal] = useState<boolean>(false);

  const dashboardLenguageText = 'nodi:dashboard:';
  const isFirstLogIn = +userLogin?.lastLoginAt - +userLogin?.createdAt < 21600000; // 6 hours
  const _activeWasShown = localStorage.getItem('activeWasShown');
  const activeWasShown = _activeWasShown && JSON.parse(_activeWasShown);
  const _personalData = localStorage.getItem('personalData');
  const personalData = _personalData && JSON.parse(_personalData);
  const isPersonalDataComplete = personalData?.firstName && personalData?.lastName && personalData?.phoneData?.phone;
  const displayCompleteInformation = !isPersonalDataComplete && !initialLoading;
  const displayBordBetaBanner = showBordBetaModal && !initialLoading;

  const displayNotificationCard = notifiacations && !!notifiacations.length;

  //blockedCompany 332
  const blockMarketPlace = orgData?.organizationId === 332;
  const userFullName = `${orgData?.firstName || ''} ${orgData?.lastName || ''}`.trim();
  const loginWelcomeBackMessage = `${t(`${dashboardLenguageText}recurrentUserWelcomingAlert`)}, ${userFullName}`.trim();
  const nodiCapacitationButtonLabel = showTutorial
    ? t(`${dashboardLenguageText}header:trainingNodi`)
    : t(`${dashboardLenguageText}header:nodiCapacitationButton`);

  const uploadInventory = () => push('/nodi/upload-external-tools?from=dashboard');

  const initialReleaseVideoInformation = async () => {
    if (!orgData) return;
    setLoadingReleasedVideo(true);
    try {
      const dataReleaseVideo = await getReleaseVideoInformation(
        orgData?.organizationId,
        DASHBOARD_ONBOARDING_TYPES?.RELEASEVIDEO2024
      );
      const dataBetaBanner = await getReleaseVideoInformation(
        orgData?.organizationId,
        DASHBOARD_ONBOARDING_TYPES?.BETAPHASEBANNER
      );
      setShowVideoTutorial(!dataReleaseVideo?.isCompleted);
      setShowBordBetaModal(!dataBetaBanner?.isCompleted);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingReleasedVideo(false);
    }
  };

  const redirectToCheckout = () => {
    if (orgData?.organizationState !== 'active') return;
    else push('/catalogue/home');
  };

  const redirectToWaitingScreen = () => {
    if (orgData?.organizationState === 'active') return;
    return push(`/state/inactive`);
  };

  const showNewOrganizationWelcomingAlert = () => {
    displaySuccessNotification({
      variant: 'success',
      customJSXMessage: (
        <div className="text-bord-subtitle font-bold">
          <Trans
            i18nKey={`${dashboardLenguageText}newOrganizationWelcome`}
            components={{ 1: orgData?.firstName || '' }}
          />
        </div>
      )
    });
  };

  const showFirstTimeInvitedUserWelcomingAlert = () =>
    displaySuccessNotification({
      customJSXMessage: <p>Bienvenid@, tu cuenta ha quedado asociada a {orgData.organizationName || 'tu empresa'}</p>
    });

  const showRecurrentUserWelcomingAlert = () =>
    displaySuccessNotification({
      variant: 'success',
      customJSXMessage: (
        <div>
          <div className="toastTitle">{loginWelcomeBackMessage}</div>
          <div className="toastDescription">{t(`${dashboardLenguageText}successfulLoginMessage`)}</div>
        </div>
      )
    });

  const showAppropiateWelcomingAlert = () => {
    if (isFirstLogIn && orgData?.organizationState === 'active' && isPersonalDataComplete)
      showNewOrganizationWelcomingAlert();
    if (isFirstLogIn && orgData?.organizationState === 'active' && !isPersonalDataComplete)
      showFirstTimeInvitedUserWelcomingAlert();
    if (!isFirstLogIn && orgData?.organizationState === 'active') showRecurrentUserWelcomingAlert();
    if (isFirstLogIn) segmentTrackEvent({ generalSignUpCompleted: { SignUpCreated: true } });
    localStorage.setItem('activeWasShown', JSON.stringify(true));
  };

  const getInfoCompany = async () => {
    const infoCompany = await getOrganizationAccount(orgData?.organizationId);
    setOrganizationImg(infoCompany?.image || '');
  };

  const getNotifications = async () => {
    try {
      const dataNotifications = await getDataNotifications(orgData.organizationId);
      setNotifications(dataNotifications);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingNotifications(false);
    }
  };

  const changeStatusTraining = async () => {
    if (showTutorial) return;
    setLoadingBlueButton(true);
    try {
      await updateStatusTutoriel(orgData.userId);
      displaySuccessNotification({
        customJSXMessage: <> {t(`${dashboardLenguageText}header:changeStatusTraining`)}</>
      });
      await getInfoTutoriel();
    } catch (error) {
      setLoadingBlueButton(false);
      displayErrorNotification();
    }
  };

  const getInfoTutoriel = async (initialLoading?: boolean) => {
    if (initialLoading) setInitialLoading(true);
    try {
      const blueData = await blueTutoriel(orgData.userId);
      setShowTutorial(blueData.showTutorial);
      setInitialLoading(false);
      setLoadingBlueButton(false);
    } catch (error) {
      setInitialLoading(false);
      displayErrorNotification();
    }
  };

  const validatePersonalData = async () => {
    if (localStorage.getItem('personalData')) {
      const myPersonalData = JSON.parse(localStorage.getItem('personalData') || '');
      if (!myPersonalData?.phoneData) {
        try {
          const dataPersonal = await getPersonalAccount(orgData.userId);
          dataPersonal && localStorage.setItem('personalData', JSON.stringify(dataPersonal));
        } catch (error) {
          displayErrorNotification();
        }
      }
    }
  };

  const changedReleaseVideoInformation = async (onboardingName: DASHBOARD_ONBOARDING_TYPES) => {
    if (!orgData) return;
    setLoadingReleasedVideo(true);
    try {
      const body = {
        onboardingName: onboardingName,
        lastStepSeen: 1,
        isCompleted: true
      };
      await putReleaseVideoSeen(orgData?.organizationId, body);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingReleasedVideo(false);
      setShowWelcomeModal(false);
    }
  };

  useLayoutEffect(() => {
    if (activeWasShown) return;
    showAppropiateWelcomingAlert();
  }, [activeWasShown, isFirstLogIn, orgData?.organizationState]);

  useEffect(() => {
    redirectToWaitingScreen();
    getInfoCompany();
    getNotifications();
    getInfoTutoriel(true);
    validatePersonalData();
    initialReleaseVideoInformation();
    segmentTrackEvent({ dashboardView: { EntryMode: isFirstLogIn ? 'Signup' : 'Login' } });
  }, []);

  if (loadingReleasedVideo)
    return (
      <div className="w-screen h-screen">
        <GenericLoader />
      </div>
    );

  return (
    <div id="dashBoardModule" className="moduleWithSidebar">
      {showVideoTutorial && (
        <BordReleaseVideoModal
          closeModalCallback={() => {
            setShowVideoTutorial(false);
            setShowWelcomeModal(true);
          }}
        />
      )}
      {showWelcomeModal && (
        <BordWelcomeModal
          closeModalCallback={() => setShowWelcomeModal(false)}
          changedReleaseVideoInformation={changedReleaseVideoInformation}
          loadingReleasedVideo={loadingReleasedVideo}
        />
      )}
      {displayCompleteInformation && (
        <div className="dashboardBanner" onClick={() => push('/nodi/account')}>
          <div className="dashboardMessageContainer">
            <BordOneToneIcon variant="user" customWidth="1.8rem" />
            <div>La información de tu registro está incompleta, ayúdanos completándola.</div>
          </div>
          <BordButton
            label="Completar perfil"
            onClick={() => push('/nodi/account')}
            modeButton="tertiary"
            buttonIconTwo="arrowRight"
            customClassName="completeDataButton"
            customFontSize="1rem"
          />
        </div>
      )}
      <div className="companyName">
        <BordPhoto size="s56" url={organizationImg || noImg} />
        <div className="titleHeader">{t(`${dashboardLenguageText}header:titleHeader`)},</div>
        <div className="exeptionName">{orgData?.organizationName || ''}</div>
        <BordButton
          customClassName="containerBlueButton"
          onClick={() => changeStatusTraining()}
          label={nodiCapacitationButtonLabel}
          buttonIconOne="star"
          customWidth="w-280"
          isLoading={initialLoading || loadingBlueButton}
        />
      </div>

      {displayBordBetaBanner && (
        <div className="dashboardBetaBanner" style={{ backgroundImage: `url(${BordBettaBannerBg})` }}>
          <div className="dashboardMessageContainer">
            <div className="dashboardMessageTitle">Dash.bord está en fase beta 🎉</div>
            <div> ¿Tienes comentarios?</div>
            <NudosLinkTypeButton
              text="Cuéntanos qué piensas"
              textProps={{ style: { fontSize: 16 } }}
              onClick={handleClickForHelp}
            />
          </div>
          <BordTextButton
            text="Cerrar"
            onClick={() => {
              setShowBordBetaModal(false);
              changedReleaseVideoInformation(DASHBOARD_ONBOARDING_TYPES.BETAPHASEBANNER);
            }}
            textProps={{ style: { color: bordColorTheme.content.secondary, fontSize: 10 } }}
            rightIconProps={{ variant: 'x', standardSize: 14, stroke: bordColorTheme.content.secondary }}
          />
        </div>
      )}

      {loadingNotifications && <div className="animationLoader notificationLoader" />}
      {!loadingNotifications && (
        <div className="notificationContainer">
          <div className="continerTitle">{t(`${dashboardLenguageText}body:notifications:title`)}</div>
          <div className="boxNotifiations">
            {displayNotificationCard &&
              notifiacations.map((item, index: number) => {
                return <NotificationCard dataNotification={item} key={`notification${index}`} />;
              })}
            {!displayNotificationCard && (
              <BordEmptyState
                customClassNameBordEmptyStateContainer="noNotifications"
                title="Todavía no tienes notificaciones"
                subTitle="En este espacio podrás consultar el estado de tus servicios logísticos y compras realizadas en la tienda"
                buttonOneProps={{
                  label: 'Cargar inventario',
                  onClick: uploadInventory,
                  modeButton: 'quaternary',
                  customWidth: 'w-280',
                  buttonIconTwo: 'monitor'
                }}
                buttonTwoProps={
                  (!blockMarketPlace && {
                    label: 'Ir a la tienda',
                    onClick: redirectToCheckout,
                    customWidth: 'w-280',
                    buttonIconTwo: 'shoppingCart'
                  }) ||
                  undefined
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default DashBoardModule;
