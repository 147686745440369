import { useTranslation } from 'react-i18next';
import { BordStatus } from '../../../../../components/BordDesignSystem';
import './OrderStatusDisplay.scss';

/**
 * @property {string} statusName - Required, the status of the order
 */
const OrderStatusDisplay = ({ statusName, translationKey }: { statusName: string; translationKey?: string }) => {
  const { t } = useTranslation();
  const statusNameTranslation = t(`services:statusName:${translationKey}`);
  const statusText = translationKey ? statusNameTranslation : statusName;

  const getStatusColor = (status?: string) => {
    switch (status) {
      case 'Pendiente de confirmación':
        return 'default';
      case 'Activo':
        return 'informative';
      case 'Activa':
        return 'informative';
      case 'Entregado':
        return 'success';
      case 'Entregada':
        return 'success';
      case 'Cancelado':
        return 'danger';
      case 'Cancelada':
        return 'danger';
      default:
        return 'default';
    }
  };
  return (
    <div className="orderStatusDisplay">
      <BordStatus variant={getStatusColor(statusText)} label={statusText} />
    </div>
  );
};
export default OrderStatusDisplay;
