import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NudosBreadcrumbButton, NudosLoaderModal } from '../../../components/NudosComponents';
import { getCountriesList } from '../../../services/countries.service';
import { createNewTool, uploadProducts } from '../../../services/uploadExternalTools.service';
import useLoadTools from '../../../state/useStateSuccessLoadTools';
import { IDTOCreateToolPetition, IUploadCountries } from '../../../types/uploadExternalTools.types';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { Step1SelectReferences, Step2AddInformation, Step3UploadTools, ToolsModal } from './Components';
import { useTranslation, Trans } from 'react-i18next';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { FREE_TOOLS_TO_UPLOAD_LIMIT } from '../../../utils/productDefinitions';
import BordStepper from '../../../components/BordDesignSystem/BordStepper/BordStepper';
import BordModal from '../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../components/BordDesignSystem/BordModal/components/ModalContent';
import useSidebar from '../../../hooks/useSidebar';
import { GenericLoader } from '../dashboard/components';

import './UploadExternalToolsModule.scss';

const UploadExternalToolsModule = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const { moduleSidebarStyles } = useSidebar();

  const fromWhereItCame = new URLSearchParams(search).get('from');
  const { setUploadedToolsData, selectedReferences, currentStep, setCurrentStep } = useLoadTools();
  const { stateSubscription } = useStateSubscriptions();
  const freePlatform = stateSubscription?.levelSubscription === 'Free';
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTool, setLoadingTool] = useState<boolean>(false);
  const [countries, setCountries] = useState<IUploadCountries[]>();
  const [modalTools, setModalTools] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:';
  const screenName = 'Tools Upload Searcher';

  const updateExteralTools = async () => {
    const orgId = getOrgData()?.organizationId;
    if (!orgId) return;
    setLoading(true);
    const referencesCopy = [...selectedReferences];
    const correctlyFormatedProducts = referencesCopy.map(reference => {
      const currentAdditionalData = [...reference.individualToolsData];
      const correctlyFormatedItems = currentAdditionalData.map(item => {
        const assigneeInfo = {
          userId: item?.assignedEmployee?.userId || undefined,
          firstName: item?.assignedEmployee?.firstName || undefined,
          lastName: item?.assignedEmployee?.lastName || undefined
        };
        const itemsWithCountryAndAssignment = {
          ...item,
          locationInfo: {
            place:
              item?.optionId === 1 ? 'office' : item?.optionId === 2 ? 'other' : item?.optionId === 3 ? 'user' : null,
            placeName: item.optionId === 2 ? item?.otherAddressDetail?.locationName : null
          },
          countryId: item.country?.id,
          userInfo:
            !!assigneeInfo?.userId && !!assigneeInfo?.firstName && !!assigneeInfo?.lastName ? assigneeInfo : undefined
        };
        delete itemsWithCountryAndAssignment.country;
        delete itemsWithCountryAndAssignment.assignedEmployee;
        return itemsWithCountryAndAssignment;
      });
      return { ...reference, pills: undefined, individualToolsData: correctlyFormatedItems };
    });
    try {
      const bodyForUploadProductsRequest = {
        organizationId: orgId,
        references: correctlyFormatedProducts
      };
      const responseToUploadProducts = await uploadProducts(bodyForUploadProductsRequest);
      if (!responseToUploadProducts) return;
      setUploadedToolsData(responseToUploadProducts);
      push('/nodi/upload-external-tools/summary');
    } catch (error) {
      displayErrorNotification({
        customJSXMessage: (
          <>Tuvimos un problema cargando las herramientas comunícate con soporte dando click en el chat</>
        )
      });
    } finally {
      setLoading(false);
    }
  };

  const activateModal = () => {
    setModalTools(true);
  };

  const uploadToolSteps = [
    {
      order: 0,
      showStep: true,
      customStepStyles: undefined,
      title: t(`${i18nLanguageKey}order0:title`),
      subtitle: t(`${i18nLanguageKey}order0:subtitle`),
      returnText: '',
      component: (
        <Step1SelectReferences
          activateModal={activateModal}
          freePlatform={freePlatform}
          maximumExternalUnits={FREE_TOOLS_TO_UPLOAD_LIMIT}
          screenName={screenName}
        />
      ),
      stepContent: (
        <>
          <div className="stepTextExternalTools">PASO 1</div>
          <div className="stepSubtitle">{t(`${i18nLanguageKey}progressBarContainer:text0`)}</div>
        </>
      )
    },
    {
      order: 1,
      title: t(`${i18nLanguageKey}order1:title`),
      subtitle: t(`${i18nLanguageKey}order1:subtitle`),
      returnText: t(`${i18nLanguageKey}order1:returnText`),
      customClassName: 'step2AddInformationContainer',
      component: <Step2AddInformation countriesData={countries} />,
      stepContent: (
        <>
          <div className="stepTextExternalTools">PASO 2</div>
          <div className="stepSubtitle">{t(`${i18nLanguageKey}progressBarContainer:text1`)}</div>
        </>
      )
    },
    {
      order: 2,
      title: t(`${i18nLanguageKey}order2:title`),
      returnText: t(`${i18nLanguageKey}order2:returnText`),
      component: <Step3UploadTools updateExteralTools={updateExteralTools} />,
      stepContent: (
        <>
          <div className="stepTextExternalTools">PASO 3</div>
          <div className="stepSubtitle">{t(`${i18nLanguageKey}progressBarContainer:text2`)}</div>
        </>
      )
    }
  ];

  const handleClickReturn = () => {
    if (currentStep === 2 || currentStep === 1) return setCurrentStep(currentStep - 1);
    if (fromWhereItCame === 'dashboard') return push('/nodi/dashboard');
    return push('/nodi/tools');
  };

  const getReturnText = () => {
    if (currentStep === 2 || currentStep === 1) return uploadToolSteps[currentStep].returnText;
    if (fromWhereItCame === 'dashboard') return 'Dashboard';
    return t(`nodi:returnTextBasedOnPreviousLocation:inventory`);
  };

  const createTool = async (data: IDTOCreateToolPetition) => {
    setLoadingTool(true);
    try {
      await createNewTool(data);
      setModalTools(false);
      setLoadingTool(false);
      displaySuccessNotification({
        variant: 'success',
        customJSXMessage: (
          <div className="text-sm">
            <Trans i18nKey={`${i18nLanguageKey}createTool`} components={{ 1: <div></div> }} />
          </div>
        )
      });
    } catch (error) {
      displayErrorNotification();
      setLoadingTool(false);
    }
  };

  const getCountries = async () => {
    try {
      const dataCountries = await getCountriesList();
      if (dataCountries) setCountries(dataCountries);
    } catch {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      {modalTools && (
        <BordModal
          modalContent={
            <ModalContent
              title={t(`${i18nLanguageKey}nudosModalContent`)}
              customHeight="48rem"
              customWidth="68rem"
              noPadding
              toCloseModal={() => {
                setModalTools(false);
              }}
              CustomModalComponent={<ToolsModal loading={loadingTool} createTool={createTool} />}
            />
          }
        />
      )}
      {loading && (
        <div className={`uploadExternalToolsModule ${moduleSidebarStyles}`}>
          <div className="loaderCenter">
            <div>
              <div className="titleLoader">Estamos creando tus herramientas</div>
              <GenericLoader />
            </div>
          </div>
        </div>
      )}
      <div className={`uploadExternalToolsModule ${moduleSidebarStyles}`}>
        <div className="boxUploadExternalTools">
          <NudosBreadcrumbButton returnText={getReturnText()} returnAction={handleClickReturn} />
          <div className="progressBarContainer">
            <BordStepper currentStep={currentStep} steps={uploadToolSteps} />
          </div>
          <div className="steperBox">{uploadToolSteps[currentStep]?.component}</div>
        </div>
      </div>
    </>
  );
};

export default UploadExternalToolsModule;
