const SquareTunisia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="white"
          d="M10.9997 11.7333C13.0247 11.7333 14.6663 10.0618 14.6663 7.99994C14.6663 5.93807 13.0247 4.2666 10.9997 4.2666C8.97463 4.2666 7.33301 5.93807 7.33301 7.99994C7.33301 10.0618 8.97463 11.7333 10.9997 11.7333Z"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M12.5713 7.4668H13.6189V8.53346H12.5713V7.4668Z"
          clipRule="evenodd"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M13.0951 9.60016C12.6174 10.2476 11.8569 10.6668 10.9999 10.6668C9.55419 10.6668 8.38086 9.47216 8.38086 8.00016C8.38086 6.52816 9.55419 5.3335 10.9999 5.3335C11.8569 5.3335 12.6174 5.7527 13.0951 6.40016H11.5237C10.6563 6.40016 9.95229 7.11696 9.95229 8.00016C9.95229 8.88336 10.6563 9.60016 11.5237 9.60016H13.0951Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTunisia;
