const SquareEquatorialGuinea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#F93939" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#00B731" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M11.0004 7.46657C11.2895 7.46657 11.5242 7.22764 11.5242 6.93324C11.5242 6.63884 11.2895 6.3999 11.0004 6.3999C10.7112 6.3999 10.4766 6.63884 10.4766 6.93324C10.4766 7.22764 10.7112 7.46657 11.0004 7.46657ZM13.0956 7.46657C13.3848 7.46657 13.6194 7.22764 13.6194 6.93324C13.6194 6.63884 13.3848 6.3999 13.0956 6.3999C12.8065 6.3999 12.5718 6.63884 12.5718 6.93324C12.5718 7.22764 12.8065 7.46657 13.0956 7.46657ZM15.1908 7.46657C15.48 7.46657 15.7147 7.22764 15.7147 6.93324C15.7147 6.63884 15.48 6.3999 15.1908 6.3999C14.9017 6.3999 14.667 6.63884 14.667 6.93324C14.667 7.22764 14.9017 7.46657 15.1908 7.46657Z"
            clipRule="evenodd"
          />
          <path
            fill="#00B731"
            fillRule="evenodd"
            d="M12.0472 8.53346C12.3364 8.53346 12.5711 8.29453 12.5711 8.00013C12.5711 7.70573 12.3364 7.4668 12.0472 7.4668C11.7581 7.4668 11.5234 7.70573 11.5234 8.00013C11.5234 8.29453 11.7581 8.53346 12.0472 8.53346ZM14.1425 8.53346C14.4316 8.53346 14.6663 8.29453 14.6663 8.00013C14.6663 7.70573 14.4316 7.4668 14.1425 7.4668C13.8533 7.4668 13.6187 7.70573 13.6187 8.00013C13.6187 8.29453 13.8533 8.53346 14.1425 8.53346Z"
            clipRule="evenodd"
          />
          <path
            fill="#AE6A3E"
            fillRule="evenodd"
            d="M12.5713 7.4668H13.6189V9.60013H12.5713V7.4668Z"
            clipRule="evenodd"
          />
          <path fill="#3A99FF" fillRule="evenodd" d="M0 0V16.0352L7.33333 8L0 0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEquatorialGuinea;
