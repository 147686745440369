const RoundStLucia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#F3F3F3" d="M10.0869 21.5651H21.913L16 7.6521L10.0869 21.5651Z" />
        <path fill="#333333" d="M12.1416 20.174L15.9997 11.3984L19.8577 20.174H12.1416Z" />
        <path fill="#FFDA2C" d="M10.0869 21.5652H21.913L16 16L10.0869 21.5652Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundStLucia;
