const RoundGuineaBissau = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M15.9998 0C14.5556 0 13.1566 0.1925 11.8258 0.551188L10.4346 16L11.8259 31.4488C13.1566 31.8075 14.5556 32 15.9998 32C24.8363 32 31.9998 24.8365 31.9998 16C31.9998 7.1635 24.8363 0 15.9998 0Z"
        />
        <path
          fill="#6DA544"
          d="M10.4346 16L11.8259 31.4488C13.1566 31.8075 14.5556 32 15.9998 32C24.8363 32 31.9998 24.8365 31.9998 16H10.4346Z"
        />
        <path
          fill="#F93939"
          d="M0 16.0001C0 22.0648 3.37438 27.3411 8.34781 30.0547V1.94556C3.37438 4.65918 0 9.93543 0 16.0001Z"
        />
        <path
          fill="#F93939"
          d="M0 16.0001C0 23.3925 5.01381 29.6129 11.8261 31.4489V0.55127C5.01381 2.38727 0 8.60765 0 16.0001Z"
        />
        <path
          fill="black"
          d="M6.04591 11.8262L7.08185 15.0147H10.4347L7.72235 16.9854L8.75823 20.174L6.04591 18.2034L3.33348 20.174L4.3696 16.9854L1.65723 15.0147H5.00991L6.04591 11.8262Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGuineaBissau;
