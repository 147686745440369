import { FC } from 'react';
import { NudosRedirectionIcon } from '../../../../../components/DesignSystem';
import { InudoInfo, LOCATION_PLACE_TYPE } from '../../../../../types/global';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import {
  IconBuilding,
  IconEmployee,
  IconPin,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { useHistory } from 'react-router-dom';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';

import { BordOneToneIcon, BordPhoto } from '../../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import BordStatus from '../../../../../components/BordDesignSystem/BordStatus/BordStatus';
import { renderVarianStatus } from '../../constants';
import { statusVariants } from '../../../../../components/BordDesignSystem/BordStatus/BordStatus.type';
import { getLocationIcon } from '../../../../../utils/global.utils';
import { capitalize } from '../../../../../utils/unCamelCase';
import './ActiveOrderCard.scss';

interface IActiveOrderCard {
  orderDetails?: InudoInfo;
  generalLoading?: boolean;
}

const ActiveOrderCard: FC<IActiveOrderCard> = ({ orderDetails }: IActiveOrderCard) => {
  const { push } = useHistory();
  const lastAssociatedLocation = orderDetails?.oldAssignment
    ? orderDetails?.oldAssignment?.find(location => !location.exitDate) || orderDetails?.oldAssignment[0]
    : undefined;
  const lastLocationEntryDate = formatShortDate(lastAssociatedLocation?.entryDate || '')?.onlyDate || '-';
  const generateDestinationData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          profileImg: orderDetails?.currentAssignment?.[0]?.photoProfile,
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          name: orderDetails?.assignedTo,
          details: 'Onboarding: '
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: '.bord Warehouse'
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${orderDetails?.destination?.country?.name}`,
          details: orderDetails?.destination?.address
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca'
        };
      case 'diagnostic':
        return {
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          name: 'Diagnóstico'
        };
      default:
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />
        };
    }
  };

  const getRedirectToEmployeeDetails = (userId?: string | number) => {
    if (!userId) return {};
    return {
      pathname: `/nodi/employees/details/${userId}`,
      state: {
        originUrl: `/nodi/details/${orderDetails?.productId}`,
        returnText: 'Herramienta'
      }
    };
  };

  const redirectToDestinationEmployee = getRedirectToEmployeeDetails(orderDetails?.userId || undefined);
  const urlUserImage = generateDestinationData(orderDetails?.destination?.place ?? '')?.profileImg;
  const renderProfileUser = (
    <BordPhoto
      url={generateDestinationData(orderDetails?.destination?.place ?? '')?.profileImg}
      oneToneIconProps={{ variant: 'user', standardSize: 24 }}
      flagProps={{
        variant: 'circle',
        country: orderDetails?.country?.code as TcountryCode,
        standardSize: 20
      }}
      size="s48"
      style={{
        backgroundColor: urlUserImage ? '' : '#262B39'
      }}
    />
  );

  return (
    <div id="activeOrderCard">
      <div className="titleSL">Asignado / ubicado:</div>
      <div className="flex items-center gap-4 mt-12">
        <div className="nameSL">
          <div className="title">Estado de la orden</div>
        </div>
        <BordStatus
          variant={
            renderVarianStatus({ variant: orderDetails?.shipmentStatusName as statusVariants }) as statusVariants
          }
          renderJSX={
            <div
              className="customBordStatusContainer"
              onClick={() => {
                push(`/nodi/orders/details/${orderDetails?.orderId}?from=tool-details&id=${orderDetails?.productId}`);
              }}
            >
              <span className="text-bord-sm font-normal">{capitalize(orderDetails?.shipmentStatusName ?? '')}</span>
              <div className="w-1 h-16 ml-4 mr-4 bg-stroke"></div>
              <span className="text-bord-caption">Ir</span>
              <BordOneToneIcon variant="arrowRight" standardSize={12} />
            </div>
          }
        />
      </div>

      <div className="containerActiveLogisticsServiceCard">
        <div className="boxActiveLogisticsServiceCard">
          <div className="flex items-center gap-20">
            <BordPhoto
              oneToneIconProps={{
                variant: getLocationIcon({
                  place: (orderDetails?.place as LOCATION_PLACE_TYPE) ?? LOCATION_PLACE_TYPE.NUDOS
                }),
                standardSize: 24
              }}
              flagProps={{
                country: orderDetails?.country?.code as TcountryCode,
                variant: 'circle',
                standardSize: 20
              }}
              style={{
                backgroundColor: '#262B39'
              }}
              size="s48"
            />
            <div className="originContainerPlace">
              <div className="generalPlace">
                <div className="titleContainer">
                  <div className="originTitle">.bord Store</div>
                </div>
                <div className="movementDates">
                  <span className="dateTitle">Adquirido: </span>
                  <span className="date">{formatShortDate(orderDetails?.acquisitionDate || '').onlyDate}</span>
                </div>
              </div>
            </div>
          </div>
          <BordOneToneIcon variant="arrowRight" customWidth="1.8rem" />
          <div className="flex items-center gap-20">
            {renderProfileUser}
            <div className="destinationContainerPlace">
              <div className="generalPlace">
                <div className="titleContainer">
                  <div className="originTitle">{generateDestinationData(orderDetails?.place || '').name}</div>
                  {orderDetails?.place === 'user' && (
                    <NudosRedirectionIcon redirectionDataObject={redirectToDestinationEmployee} />
                  )}
                </div>
                {generateDestinationData(orderDetails?.place || '').details && (
                  <div className="originSubtitle">{`${
                    generateDestinationData(orderDetails?.place || '').details
                  } ${lastLocationEntryDate}`}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveOrderCard;
