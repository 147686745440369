const RoundNorfolkIsland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M22.9567 1.58763C20.8532 0.5705 18.4933 0 16.0002 0C13.507 0 11.1472 0.5705 9.04366 1.58763L7.65234 16L9.04366 30.4124C11.1472 31.4295 13.507 32 16.0002 32C18.4933 32 20.8532 31.4295 22.9567 30.4124L24.348 16L22.9567 1.58763Z"
        />
        <path
          fill="#6DA544"
          d="M9.0435 1.58813C3.69156 4.17601 0 9.65657 0 15.9999 0 22.3433 3.69156 27.8239 9.0435 30.4118V1.58813ZM22.9561 1.58813V30.4118C28.308 27.8239 31.9996 22.3433 31.9996 15.9999 31.9996 9.65664 28.308 4.17601 22.9561 1.58813ZM20.174 20.8695 16.0001 7.6521 11.8262 20.8695H14.9566V24.3477H17.0436V20.8695H20.174Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNorfolkIsland;
