const RoundCroatia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M32 15.9999c0-1.9571-.3521-3.8319-.9952-5.5652L16 9.73901.995188 10.4346C.352063 12.168 0 14.0428 0 15.9999c0 1.9571.352063 3.8319.995188 5.5652L16 22.2608l15.0048-.6957c.6431-1.7333.9952-3.6081.9952-5.5652Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9999 32c6.8795 0 12.7442-4.342 15.0048-10.4348H.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#F93939"
          d="M.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0 9.12049 0 3.2558 4.342.995117 10.4348Z"
        />
        <path
          fill="#3ECBF8"
          d="M20.1733 11.1306h-2.7827l.4638-3.4783 1.855-1.39131 1.8551 1.39131v2.7826l-1.3912.6957ZM11.8258 11.1306h2.7826l-.4638-3.4783-1.855-1.39131-1.855 1.39131v2.7826l1.3912.6957Z"
        />
        <path
          fill="#1A47B8"
          d="M17.8548 11.1306h-3.7103V7.6523l1.8552-1.39131 1.8551 1.39131v3.4783Z"
        />
        <path
          fill="#fff"
          d="M10.4346 10.4348v7.6521c0 1.8221.8801 3.4425 2.2376 4.4583l.9739-.2437 1.229 1.2364c.3635.0749.7395.1143 1.1247.1143.3831 0 .7573-.0389 1.1188-.1131l1.403-1.1681.8057.1719c1.357-1.0157 2.2377-2.6344 2.2377-4.456v-7.6521H10.4346Z"
        />
        <path
          fill="#F93939"
          d="M10.4346 10.4348h2.226v2.2261h-2.226v-2.2261ZM14.8867 10.4348h2.2261v2.2261h-2.2261v-2.2261ZM19.3389 10.4348h2.226v2.2261h-2.226v-2.2261ZM12.6602 12.6609h2.226v2.2261h-2.226v-2.2261ZM17.1133 12.6609h2.226v2.2261h-2.226v-2.2261Z"
        />
        <path
          fill="#F93939"
          d="M10.4346 14.8862h2.226v2.2261h-2.226v-2.2261ZM12.6602 17.1123h2.226v2.2261h-2.226v-2.2261ZM14.8867 14.8862h2.2261v2.2261h-2.2261v-2.2261ZM19.3389 14.8862h2.226v2.2261h-2.226v-2.2261ZM17.1133 17.1123h2.226v2.2261h-2.226v-2.2261ZM14.8867 19.3384h2.2261v2.226h-2.2261v-2.226ZM12.6606 19.3386h-2.0825c.191.8275.5665 1.5847 1.0814 2.2261h1.0011v-2.2261ZM19.3389 21.5647h1.001c.515-.6414.8903-1.3986 1.0813-2.2261h-2.0823v2.2261ZM12.6602 21.5647v.9713c.6474.4872 1.404.8365 2.226 1.0041v-1.9754h-2.226ZM17.1133 21.5647v1.9754c.822-.1675 1.5786-.5169 2.226-1.0041v-.9713h-2.226Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCroatia;
