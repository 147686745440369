const SquareGeorgia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M9.42857 9.6H0V6.4H9.42857V0H12.5714V6.4H22V9.6H12.5714V16H9.42857V9.6Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M15.7142 11.7333H14.6666V12.8H15.7142V13.8666H16.7619V12.8H17.8095V11.7333H16.7619V10.6666H15.7142V11.7333ZM15.7142 3.19997H14.6666V4.26663H15.7142V5.3333H16.7619V4.26663H17.8095V3.19997H16.7619V2.1333H15.7142V3.19997ZM5.23805 3.19997H4.19043V4.26663H5.23805V5.3333H6.28567V4.26663H7.33329V3.19997H6.28567V2.1333H5.23805V3.19997ZM5.23805 11.7333H4.19043V12.8H5.23805V13.8666H6.28567V12.8H7.33329V11.7333H6.28567V10.6666H5.23805V11.7333Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareGeorgia;
