import { ComponentProps } from 'react';
import { BordOneToneIcon, BordTooltip } from '../../../../../../components/BordDesignSystem';
import { TtooltipPositions } from '../../../../../../components/BordDesignSystem/BordTooltip/BordTooltip.type';
import './LogisticServiceFeeLine.scss';

const LogisticServiceFeeLine = ({
  title,
  value,
  showStrikeThroughValue = false,
  strikeThroughValue,
  tooltipProps,
  className = '',
  ...rest
}: IlogisticServiceFeeLineProps) => {
  const classnames = `logisticServiceFeeLine ${className}`.trim();

  return (
    <div className={classnames} {...rest}>
      <div className="titleContainer">
        <div className="title">{title}</div>
        {tooltipProps && (
          <BordTooltip message={tooltipProps?.tooltipContent} position={tooltipProps?.tooltipPosition}>
            <BordOneToneIcon variant="info" standardSize={12} className="cursor-pointer" />
          </BordTooltip>
        )}
      </div>

      <div className="values">
        {!!strikeThroughValue && showStrikeThroughValue && (
          <div className="strikeThroughValue">{strikeThroughValue}</div>
        )}
        <div className="value">{value}</div>
      </div>
    </div>
  );
};

export default LogisticServiceFeeLine;

export interface IlogisticServiceFeeLineProps extends ComponentProps<'div'> {
  title: string;
  value: string;
  tooltipProps?: { tooltipContent?: JSX.Element; tooltipPosition?: TtooltipPositions };
  showStrikeThroughValue?: boolean;
  strikeThroughValue?: string;
}
