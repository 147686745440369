import { ItoolEssentialData } from '../../../../../../types/requestLogisticsModule';
import LogisticServiceToolCardMainSection, {
  LogisticServiceToolCardSkeletonLoader
} from './LogisticServiceToolCardMainSection/LogisticServiceToolCardMainSection';
import LsToolCardAdditionalServicesSection from './LsToolCardAdditionalServicesSection/LsToolCardAdditionalServicesSection';
import './LogisticServiceToolCard.scss';

const LogisticServiceToolCard = ({
  logisticServiceId,
  toolData,
  isLoading = false
}: {
  logisticServiceId?: number | null;
  toolData?: ItoolEssentialData;
  isLoading?: boolean;
}) => {
  const hasAdditionalServices = !!toolData?.logisticAdditionalServices?.length;
  if (isLoading) return <LogisticServiceToolCardSkeletonLoader />;

  return (
    <div className="logisticServiceToolCard">
      <LogisticServiceToolCardMainSection logisticServiceId={logisticServiceId} toolData={toolData} />
      {hasAdditionalServices && <div className="lsSectionDividerLine" />}
      <LsToolCardAdditionalServicesSection logisticAdditionalServices={toolData?.logisticAdditionalServices} />
    </div>
  );
};

export default LogisticServiceToolCard;
