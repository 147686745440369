const RoundFalklandIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="white"
          d="M15.957 16.0001H15.9998C15.9998 15.9857 15.9998 15.9717 15.9998 15.9573C15.9856 15.9716 15.9713 15.9858 15.957 16.0001Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.162 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0486 16H15.9572L16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51185 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0325L10.3152 8.3479H8.34766V8.34803L15.9997 16H15.9998C15.9998 16 15.9998 14.6433 15.9998 14.0325Z"
        />
        <path
          fill="#FFDA2C"
          d="M25.7391 18.7827V19.4783H20.1739V18.7827H18.0869V21.5653H19.4782V22.261H26.4348V21.5653H27.826V18.7827H25.7391Z"
        />
        <path
          fill="#3ECBF8"
          d="M18.0869 8.3479V16.0001C18.0869 19.7275 22.9565 20.8696 22.9565 20.8696C22.9565 20.8696 27.826 19.7275 27.826 16.0001V8.3479H18.0869Z"
        />
        <path
          fill="#F3F3F3"
          d="M22.9562 14.0557C21.7389 14.0557 21.7389 15.1687 20.5215 15.1687 19.3042 15.1687 19.3042 14.0557 18.0869 14.0557V16.0035C19.3042 16.0035 19.3042 17.1165 20.5215 17.1165 21.7389 17.1165 21.7389 16.0035 22.9562 16.0035 24.1736 16.0035 24.1736 17.1165 25.3911 17.1165 26.6086 17.1165 26.6085 16.0035 27.826 16.0035V14.0557C26.6086 14.0557 26.6086 15.1687 25.3911 15.1687 24.1736 15.1687 24.1736 14.0557 22.9562 14.0557ZM22.9562 10.1565C21.7389 10.1565 21.7389 11.2696 20.5215 11.2696 19.3042 11.2696 19.3042 10.1565 18.0869 10.1565V12.1043C19.3042 12.1043 19.3042 13.2174 20.5215 13.2174 21.7389 13.2174 21.7389 12.1043 22.9562 12.1043 24.1736 12.1043 24.1736 13.2174 25.3911 13.2174 26.6086 13.2174 26.6085 12.1043 27.826 12.1043V10.1565C26.6086 10.1565 26.6086 11.2696 25.3911 11.2696 24.1736 11.2696 24.1736 10.1565 22.9562 10.1565Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundFalklandIslands;
