const RoundBurkinaFaso = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M0 16C0 7.1635 7.1635 0 16 0c8.8365 0 16 7.1635 16 16-.6956 0-16 2.0869-16 2.0869L0 16Z"
        />
        <path
          fill="#6DA544"
          d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16"
        />
        <path
          fill="#FFDA2C"
          d="m16.0001 10.4348 1.2085 3.7199h3.9117l-3.1645 2.2991 1.2086 3.7201-3.1643-2.299-3.1645 2.299 1.2088-3.7201-3.1645-2.2991h3.9115l1.2087-3.7199Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBurkinaFaso;
