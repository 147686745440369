import { DateTime } from 'luxon';
import {
  IlogisticServiceDetailedData,
  LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS,
  LS_ADDITIONAL_SERVICE_STATUS,
  LS_ADDITIONAL_SERVICE_TYPE
} from '../../../../../../../types/logisticsServicesModule.types';
import { sortObjectWithDates } from '../../../../../../../utils/formatDatesAndHours';
import { NudosLinkTypeButton } from '../../../../../../../components/DesignSystem';
import { iconProblem3D } from '../../../../../../../assets/images/views/RequestLogistics';
import XSign from '../../../../../../../assets/images/components/NudosComponents/XSign';
import { nudosGray500 } from '../../../../../../../styles/constants';
import { handleClickForHelp } from '../../../../../../../utils/global.utils';
import './AdditionalServicesNotification.scss';

const AdditionalServicesNotification = ({
  logisticServiceData,
  setShowAdditionalServicesBanner
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  setShowAdditionalServicesBanner: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { tools } = logisticServiceData || {};
  const handleClickBanner = () => setShowAdditionalServicesBanner(false);

  const getToolsWithNotCompletedServices = () => {
    if (!tools?.length) return [];
    const toolsWithNotCompletedServices = [];

    for (const tool of tools) {
      const { logisticAdditionalServices } = tool;
      if (!logisticAdditionalServices) continue;

      const thisToolNotCompletedServices = [];
      let thisToolLastNotCompletedServiceDate = '';

      for (const additionalService of logisticAdditionalServices) {
        const { status } = additionalService || {};
        const { statusName, createdAt, statusViewedByUserId } = status || {};
        const isNotCompleted = statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED;
        if (!isNotCompleted || !!statusViewedByUserId) continue;

        const currentDate = DateTime.fromISO(thisToolLastNotCompletedServiceDate || '');
        const thisServiceDate = DateTime.fromISO(createdAt || '');
        if (isNotCompleted) thisToolNotCompletedServices.push(additionalService);
        if (thisServiceDate <= currentDate) continue;
        thisToolLastNotCompletedServiceDate = createdAt || '';
      }

      const tooWithNotCompletedServicesData = {
        date: thisToolLastNotCompletedServiceDate,
        thisToolNotCompletedServices
      };
      if (thisToolNotCompletedServices.length) toolsWithNotCompletedServices.push(tooWithNotCompletedServicesData);
    }

    const sortedToolsWithNotCompletedServices = sortObjectWithDates(toolsWithNotCompletedServices, 'desc');
    return sortedToolsWithNotCompletedServices;
  };

  const getReasonText = (reasonName: string) => {
    switch (reasonName) {
      case LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS.MDM:
        return 'el dispositivo tiene un MDM previamente configurado y no tenemos acceso al mismo.';
      case LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS.APPLE_ID:
        return 'el dispositivo está bloqueado por el Apple ID y no tenemos acceso al mismo.';
      case LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS.USER_BLOCKED:
        return 'el dispositivo está bloqueado y no tenemos acceso al mismo.';
      case LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS.NON_FUNCTIONAL:
        return 'el dispositivo no está en condiciones para aplicar el servicio.';
      default:
        return 'consultar en el servicio.';
    }
  };

  const toolsWithNotCompletedServices = getToolsWithNotCompletedServices();
  const [lastToolWithNotCompletedServices] = toolsWithNotCompletedServices || [];
  const { thisToolNotCompletedServices: notCompletedServicesToReport } = lastToolWithNotCompletedServices || {};
  const lastToolHasMultipleNotCompletedServices =
    !!notCompletedServicesToReport?.length && notCompletedServicesToReport?.length > 1;
  const firstNotCompletedService = notCompletedServicesToReport ? notCompletedServicesToReport[0] : undefined;
  const firstNotCompletedServiceReason = firstNotCompletedService?.status?.notCompletedReason;
  const allServicesHaveSameReasonForNotCompletion = !!notCompletedServicesToReport?.every(
    service => service.status?.notCompletedReason === firstNotCompletedServiceReason
  );

  const title = lastToolHasMultipleNotCompletedServices
    ? 'Servicios adicionales no realizados'
    : `Servicio de ${firstNotCompletedService?.name?.toLowerCase()} no realizado`;

  if (!notCompletedServicesToReport?.length) return <></>;

  return (
    <div className="additionalServicesNotification">
      <XSign className="xSign" onClick={handleClickBanner} fill={nudosGray500} />
      <div className="leftSection">
        <div className="title">{title}</div>

        {allServicesHaveSameReasonForNotCompletion && (
          <div className="notCompletedServiceReason">
            <span>Motivo: </span>
            <strong>{getReasonText(firstNotCompletedServiceReason || '')}</strong>
          </div>
        )}

        {!allServicesHaveSameReasonForNotCompletion &&
          notCompletedServicesToReport.map(service => {
            const connector = service?.name === LS_ADDITIONAL_SERVICE_TYPE.WIPING ? 'del' : 'de la';
            const reasonTitle = `Motivo ${connector} ${service?.name?.toLowerCase()}: `;
            return (
              <div key={service?.id} className="notCompletedServiceReason">
                <span>{reasonTitle}</span>
                <strong>{getReasonText(service?.status?.notCompletedReasonStringId || '')}</strong>
              </div>
            );
          })}

        <div className="contactSupportLine">
          <label>En caso de no saber cómo proceder,</label>
          <NudosLinkTypeButton text="contacta a soporte" onClick={handleClickForHelp} />
        </div>
      </div>
      <div className="rightSection">
        <div className="iconContainer">
          <img src={iconProblem3D} alt="x icon" />
        </div>
      </div>
    </div>
  );
};

export default AdditionalServicesNotification;
