const RoundCyprus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FCFCFC"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#6DA544"
          d="M25.0439 13.9131H22.957c0 3.842-3.1147 6.9565-6.9565 6.9565-3.8421 0-6.95653-3.1145-6.95653-6.9565H6.95703c0 4.1384 2.77975 7.6276 6.57417 8.7022-.416.7902-.3527 1.7852.2416 2.5267.7805-.6255 1.5885-1.2731 2.274-1.8224.6855.5494 1.4937 1.1969 2.2741 1.8224.6001-.7489.6592-1.7565.2294-2.5505 3.7528-1.101 6.4936-4.5694 6.4936-8.6784Z"
        />
        <path
          fill="#FFDA2C"
          d="M10.4346 13.2175s0 3.4782 3.4782 3.4782l.6956.6956h1.3914s.6956-2.0869 2.0869-2.0869c0 0 0-1.3913 1.3913-1.3913h2.0869s-.6956-2.7826 2.7827-4.86957l-1.3913-.69563s-4.8696 3.4783-8.3479 2.7826v1.3913h-1.3913l-.6956-.6956-2.0869 1.3913Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCyprus;
