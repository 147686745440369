import { TbordOneToneIcon } from '../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import { LOCATION_PLACE_TYPE } from '../types/global';
import { supportUrl } from './productDefinitions';

export const getLocationIcon = ({ place }: { place: LOCATION_PLACE_TYPE }): TbordOneToneIcon => {
  const defaultPlace: TbordOneToneIcon = 'archive';
  const renderPlaceIcons: Record<LOCATION_PLACE_TYPE, TbordOneToneIcon> = {
    [LOCATION_PLACE_TYPE.NUDOS]: 'archive',
    [LOCATION_PLACE_TYPE.OFFICE]: 'building3',
    [LOCATION_PLACE_TYPE.OTHER]: 'helpCircle',
    [LOCATION_PLACE_TYPE.USER]: 'helpCircle',
    [LOCATION_PLACE_TYPE.WARRANTY]: 'shield',
    [LOCATION_PLACE_TYPE.DIAGNOSTIC]: 'tool',
    [LOCATION_PLACE_TYPE.STORE]: 'bord'
  };

  return renderPlaceIcons[place] ?? defaultPlace;
};

export const handleClickForHelp = () => window.open(supportUrl);
