import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../../../components/BordDesignSystem';
import { ICountryDetail } from '../../../../../../types/countries';
import { INewEmployeeInfo } from '../../../../../../types/global';
import AssignToolsModal from '../../assignToolsModal';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import './EmployeeFormButtonsSection.scss';

const EmployeeFormButtonsSection = ({
  listOfCountriesWithLogistics,
  disableButton,
  submitButtonBody,
  selectedCountry,
  handleSubmitCustomCallback,
  customTextButton,
  isButtonLoading,
  navigationOrigin,
  setCreateAndAssign,
  setRequireLogistics
}: {
  listOfCountriesWithLogistics: ICountryDetail[];
  disableButton: () => boolean;
  submitButtonBody: Partial<INewEmployeeInfo>;
  selectedCountry?: ICountryDetail;
  handleSubmitCustomCallback: (employee: INewEmployeeInfo, createAndAssignParam?: boolean) => void;
  customTextButton?: string;
  isButtonLoading?: boolean;
  navigationOrigin?: string;
  setCreateAndAssign?: (boolean: boolean) => void;
  setRequireLogistics?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [showAssignToolsModal, setShowAssignToolsModal] = useState<boolean>(false);

  const originIsCreateEmployee = navigationOrigin && navigationOrigin === 'create-employee' ? true : false;
  const newEmployeeFormKey = 'nodi:employeeInternal:newEmployeeForm:employeeFormButtonsSection:';

  const handleSubmitCallback = async (createAndAssignParam?: boolean) => {
    if (disableButton()) return;
    setShowAssignToolsModal && setShowAssignToolsModal(false);
    setIsFormLoading(true);

    try {
      handleSubmitCustomCallback(submitButtonBody as INewEmployeeInfo, createAndAssignParam);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setIsFormLoading(false);
    }
  };

  const handleClickCreateAndAssign = async () => {
    const employeeCountryAllowsLogistics = listOfCountriesWithLogistics?.some(
      countryWithLogistics => countryWithLogistics?.code === selectedCountry?.code
    );
    if (!employeeCountryAllowsLogistics) {
      setRequireLogistics && setRequireLogistics(false);
      return handleSubmitCallback(true);
    }
    setCreateAndAssign && setCreateAndAssign(true);
    setShowAssignToolsModal(true);
  };

  const closeModalCallback = () => {
    setShowAssignToolsModal(false), setCreateAndAssign && setCreateAndAssign(false);
    displayErrorNotification({
      customJSXMessage: (
        <>{'Empleado no guardado. Selecciona Solo Guardar o completa la asignación de herramientas para guardar.'}</>
      )
    });
  };

  return (
    <div className="employeeFormButtonsSection">
      {showAssignToolsModal && (
        <AssignToolsModal
          submitModalCallback={handleSubmitCallback}
          navigationOrigin={navigationOrigin}
          closeModalCallback={closeModalCallback}
          setRequireLogistics={setRequireLogistics}
        />
      )}
      {originIsCreateEmployee && (
        <BordButton
          label={t(`${newEmployeeFormKey}saveAndOnboarding`)}
          customWidth="w-280"
          onClick={handleClickCreateAndAssign}
          isLoading={isFormLoading || isButtonLoading}
          disabled={disableButton()}
        />
      )}
      {originIsCreateEmployee && (
        <BordButton
          label={t(`${newEmployeeFormKey}justSave`)}
          onClick={() => handleSubmitCallback(false)}
          modeButton="secondary"
          customWidth="w-180"
          disabled={disableButton()}
          isLoading={isFormLoading || isButtonLoading}
          bordButtonStyle="outlined"
        />
      )}
      {!originIsCreateEmployee && (
        <BordButton
          label={customTextButton || t(`${newEmployeeFormKey}justSave`)}
          onClick={() => handleSubmitCallback(false)}
          customWidth="w-280"
          disabled={disableButton()}
          isLoading={isFormLoading || isButtonLoading}
        />
      )}
    </div>
  );
};

export default EmployeeFormButtonsSection;
