import { useTranslation } from 'react-i18next';
import BordBadge from '../../BordDesignSystem/BordBadge/BordBadge';
import ReportsBackgroundImage from './ReportsBackgroundImage';
import './UpcomingReportsModuleHype.scss';

const UpcomingReportsModuleHype = () => {
  const { t } = useTranslation();

  const translationKey = 'nodi:sidebar:sidebarModules:reports:hoverElement:';
  return (
    <div className="hoverElement upcomingReportsModuleHype">
      <div className="hoverElementHeader">
        <ReportsBackgroundImage />
      </div>
      <div className="hoverElementBody">
        <div className="titleSection">
          <div className="title">{t(`${translationKey}title`)}</div>
          <BordBadge variant="gradient" label={t(`${translationKey}upcommingModule`)} />
        </div>
        <div className="description">{t(`${translationKey}description`)}</div>
      </div>
    </div>
  );
};

export default UpcomingReportsModuleHype;
