import './ProductLocationCard.scss';

const LoaderProductLocationCard = () => {
  return (
    <div id="loaderProductLocationCard">
      <div className="titleLocationCard" />
      <div className="boxProductLocationCard">
        <div className="containerLocationCard animationLoader" />
      </div>
    </div>
  );
};

export default LoaderProductLocationCard;
