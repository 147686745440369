import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  DTOfindProductReferencesToUpload,
  DTOuploadProducts,
  DTOuploadProductsResponse,
  IBrands,
  IDTOCreateToolPetition,
  IExternalToolDetails
} from '../types/uploadExternalTools.types';
import { apiDelete, apiGet, apiPost, apiPut } from './api';
import { Tany } from '../types/global';

const { REACT_APP_CATALOG, REACT_APP_SUBSCRIPTION } = process.env;

export const findProductReferencesToUpload = async (query: string): Promise<DTOfindProductReferencesToUpload[]> => {
  return apiGet<AxiosRequestConfig, DTOfindProductReferencesToUpload[]>(
    `${REACT_APP_CATALOG}/catalog/product/search${query ? `?q=${query}` : ''}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const uploadProducts = async (body: DTOuploadProducts): Promise<DTOuploadProductsResponse> => {
  return apiPost<AxiosRequestConfig, DTOuploadProducts>(`${REACT_APP_SUBSCRIPTION}/product/external/upload`, body)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const validateExistingSerialNumber = async (serial: string): Promise<unknown> => {
  return apiGet<AxiosRequestConfig, unknown>(`${REACT_APP_SUBSCRIPTION}/product-external/validate-serial/${serial}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateProductSubscription = async (id: number | string, body: Tany): Promise<Tany> => {
  return apiPut<Tany, Tany>(`${REACT_APP_SUBSCRIPTION}/product/update/${id}`, body)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const createNewTool = async (body: IDTOCreateToolPetition): Promise<IDTOCreateToolPetition> => {
  return apiPost<AxiosRequestConfig, IDTOCreateToolPetition>(`${REACT_APP_CATALOG}/backoffice/tool-request`, body)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getBrandsDetail = async (id: string | number): Promise<IBrands> => {
  return apiGet<AxiosRequestConfig, IBrands>(`${REACT_APP_CATALOG}/brand?categoryId=${id}`)
    .then(resp => {
      const categoryList: IBrands = resp;
      return categoryList;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const deleteExternalProduct = async (productId: string | number): Promise<string> => {
  return apiDelete(`${REACT_APP_SUBSCRIPTION}/order/delete/tool/${productId}`)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getCounterExternalTools = async (orgId: number): Promise<IExternalToolDetails[]> => {
  return apiGet<AxiosRequestConfig, IExternalToolDetails[]>(
    `${REACT_APP_SUBSCRIPTION}/product-external/by-organization/${orgId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
