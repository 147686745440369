const RoundMaldives = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#6DA544" d="M5.56543 8.3479H26.435V23.6523H5.56543V8.3479Z" />
        <path
          fill="white"
          d="M18.5768 20.5218C16.0795 20.5218 14.055 18.4973 14.055 16.0001C14.055 13.5028 16.0795 11.4783 18.5768 11.4783C19.3554 11.4783 20.0881 11.6752 20.7277 12.0218C19.7243 11.0405 18.3521 10.4348 16.8376 10.4348C13.7641 10.4348 11.2725 12.9264 11.2725 16C11.2725 19.0736 13.7641 21.5652 16.8376 21.5652C18.3521 21.5652 19.7243 20.9595 20.7277 19.9782C20.0881 20.3249 19.3554 20.5218 18.5768 20.5218Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMaldives;
