const SquareMaldives = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#AF010D" rx="2" />
        <path fill="#249F58" fillRule="evenodd" d="M3 4H19V12H3V4Z" clipRule="evenodd" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M11.6547 10.5815C11.4451 10.638 11.2251 10.6668 10.9999 10.6668C9.55419 10.6668 8.38086 9.47216 8.38086 8.00016C8.38086 6.52816 9.55419 5.3335 10.9999 5.3335C11.2262 5.3335 11.4451 5.3623 11.6547 5.41883C10.9769 5.71216 10.4761 6.75643 10.4761 8.00016C10.4761 9.2439 10.9769 10.2871 11.6547 10.5815Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMaldives;
