const RoundPalau = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M12.5219 22.9565C16.3639 22.9565 19.4784 19.8419 19.4784 16C19.4784 12.158 16.3639 9.04346 12.5219 9.04346C8.67996 9.04346 5.56543 12.158 5.56543 16C5.56543 19.8419 8.67996 22.9565 12.5219 22.9565Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPalau;
