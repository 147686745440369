import ReviewLogisticQuoteModalContent from './ReviewLogisticQuoteModalContent';
import { IlogisticServiceDetailedData } from '../../../../../../../../types/logisticsServicesModule.types';
import BordModal from '../../../../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './ReviewLogisticQuoteModal.scss';

const ReviewLogisticQuoteModal = ({
  closeModalCallback,
  logisticServiceData,
  handleLsRejection,
  approveOrRejectLogisticQuote
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  closeModalCallback: () => void;
  handleLsRejection: () => void;
  approveOrRejectLogisticQuote: (approveOrReject: 'approved' | 'rejected') => void;
}) => {
  return (
    <div className="reviewLogisticQuoteModal">
      <BordModal
        closeModalCallback={closeModalCallback}
        clickOutsideCallback={closeModalCallback}
        modalContent={
          <ModalContent
            toCloseModal={closeModalCallback}
            customWidth="580px"
            customHeight="440px"
            title="Revisar cotización del servicio"
            subTitle="Aprueba o rechaza el servicio para poder continuar"
            CustomModalComponent={
              <ReviewLogisticQuoteModalContent
                approveOrRejectLogisticQuote={approveOrRejectLogisticQuote}
                logisticServiceData={logisticServiceData}
                handleLsRejection={handleLsRejection}
              />
            }
          />
        }
      />
    </div>
  );
};

export default ReviewLogisticQuoteModal;
