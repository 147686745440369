const RoundTuvalu = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M32 16C32 24.8365 24.8365 32 16.0001 32C7.1635 32 0 24.8365 0 16C0 16.0038 16.0001 0.00175 16.0001 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="#1A47B8"
          d="M7.65234 15.9998H13.0487L8.34797 10.4346 7.65234 12.5215V15.9998ZM15.9998 13.0485 10.4346 7.6521H15.9998L15.9998 13.0485Z"
        />
        <path
          fill="white"
          d="M15.957 15.9998H15.9998C15.9998 15.9855 15.9998 15.9715 15.9998 15.957C15.9856 15.9713 15.9713 15.9856 15.957 15.9998Z"
        />
        <path
          fill="white"
          d="M16.0001 8.34781C16.0001 5.5325 16.0001 3.68775 16.0001 0H15.9974C7.162 0.0015 0 7.16431 0 16H8.34787V11.2993L13.0486 16H15.9573C15.9716 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16.0001Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51185 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0322L10.3152 8.34766H8.34766V8.34778L15.9997 15.9998H15.9998C15.9998 15.9998 15.9998 14.6431 15.9998 14.0322Z"
        />
        <path
          fill="#FFDA2C"
          d="M17.4629 26.4346 17.8083 27.4974H18.9258L18.0217 28.1543 18.3671 29.2171 17.4629 28.5603 16.5588 29.2171 16.9042 28.1543 16 27.4974H17.1175L17.4629 26.4346ZM26.3633 19.478 26.7086 20.5409H27.8262L26.9221 21.1978 27.2676 22.2607 26.3633 21.6038 25.4593 22.2607 25.8046 21.1978 24.9004 20.5409H26.018L26.3633 19.478ZM28.5937 9.04346 28.9391 10.1063H30.0567L29.1526 10.7632 29.4979 11.826 28.5937 11.1692 27.6897 11.826 28.035 10.7632 27.1309 10.1063H28.2484L28.5937 9.04346ZM29.1456 16.6958 29.4909 17.7587H30.6084L29.7044 18.4156 30.0497 19.4784 29.1456 18.8216 28.2415 19.4784 28.5869 18.4156 27.6826 17.7587H28.8002L29.1456 16.6958ZM19.065 23.9351 20.0609 24.4425 20.8511 23.6523 20.6764 24.756 21.672 25.2634 20.5682 25.4383 20.3934 26.5421 19.8861 25.5463 18.7822 25.7212 19.5724 24.931 19.065 23.9351ZM19.065 19.7608 20.0609 20.2682 20.8511 19.478 20.6764 20.5818 21.672 21.0891 20.5682 21.264 20.3934 22.3678 19.8861 21.372 18.7822 21.5469 19.5724 20.7567 19.065 19.7608ZM22.5435 22.4366 23.5394 22.944 24.3296 22.1538 24.1549 23.2575 25.1506 23.7649 24.0467 23.9397 23.8719 25.0436 23.3646 24.0477 22.2607 24.2227 23.0509 23.4325 22.5435 22.4366ZM22.5435 13.5001 23.5394 14.0075 24.3296 13.2173 24.1549 14.321 25.1506 14.8283 24.0467 15.0032 23.8719 16.107 23.3646 15.1113 22.2607 15.2862 23.0509 14.496 22.5435 13.5001ZM25.9146 13.3932 26.9105 13.9005 27.7007 13.1104 27.5259 14.2141 28.5216 14.7214 27.4178 14.8963 27.243 16.0001 26.7357 15.0044 25.6318 15.1792 26.422 14.389 25.9146 13.3932Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTuvalu;
