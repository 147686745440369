import { v4 as uuid } from 'uuid';
import { FC } from 'react';

import './SkeletonProductEcommerceCard.scss';

interface ISkeletonProductEcommerceCard {
  numberCards?: number;
}

const SkeletonProductEcommerceCard: FC<ISkeletonProductEcommerceCard> = ({
  numberCards
}: ISkeletonProductEcommerceCard) => {
  const loaderCards = Array(numberCards || 16).fill('');

  return (
    <>
      {loaderCards.map(() => {
        return (
          <section id="skeletonProductEcommerceCard" key={uuid()}>
            <div className="containerProductCard">
              <div className="headerCard animationLoader">
                <div className="minBrandCard animationLoader" />
              </div>
              <div className="bodyCard">
                <div className="firstSection">
                  <div className="loaderName animationLoader" />
                  <div className="loaderPrice animationLoader" />
                </div>
                <div className="blueLine" />
                <div className="secondSection">
                  <div className="pillLoaderContainer animationLoader" />
                </div>
                <div className="thirdSection">
                  <div className="buttonsProductLoaderContainer">
                    <div className="addButtonLoaderContainer">
                      <div className="buttonRedireccionLoader animationLoader" />
                      <div className="iconRedirectionButtonLoader animationLoader" />
                    </div>
                    <div className="productDetailsButtonLoader animationLoader"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </>
  );
};
export default SkeletonProductEcommerceCard;
