import create from 'zustand';

export interface iState {
  temporalImg?: string;
  setTemporalImg: (id: string) => void;
}

const useProfileImage = create<iState>(set => ({
  temporalImg: '',
  setTemporalImg: temporalImg => set({ temporalImg })
}));

export default useProfileImage;
