import { useTranslation } from 'react-i18next';
import { NudosToolAdditionalServices } from '../../../../../../../components/DesignSystem/LogisticsAdditionalServices';
import { IadditionalServiceDataForToolLogistics } from '../../../../../../../types/requestLogisticsModule';
import './LsToolCardAdditionalServicesSection.scss';

const LsToolCardAdditionalServicesSection = ({
  logisticAdditionalServices
}: {
  logisticAdditionalServices?: IadditionalServiceDataForToolLogistics[] | null;
}) => {
  const { t } = useTranslation();

  if (!logisticAdditionalServices || !logisticAdditionalServices?.length) return <></>;

  const haveServicesOtherThanExternalOnes = logisticAdditionalServices?.some(
    service => service?.status?.isExternalService === false
  );

  const sectionTitle = haveServicesOtherThanExternalOnes
    ? t('services:logistics:additionalServices:sectionTitleTwo')
    : t('services:logistics:additionalServices:sectionTitle');

  return (
    <div className="lsToolCardAdditionalServicesSection">
      <div className="sectionTitle">{sectionTitle}</div>
      <NudosToolAdditionalServices
        styling="logisticServiceDetails"
        logisticAdditionalServices={logisticAdditionalServices}
      />
    </div>
  );
};

export default LsToolCardAdditionalServicesSection;
