import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IDataNotifications,
  IlogisticServiceNotificationData,
  IorderNotificationData
} from '../../../../types/dashboard';
import { NudosHoverText } from '../../../../components/NudosComponents';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../utils/segment';
import { BordFlag, BordOneToneIcon } from '../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import BordStatus from '../../../../components/BordDesignSystem/BordStatus/BordStatus';
import BordTextButton from '../../../../components/BordDesignSystem/BordTextButton/BordTextButton';
import { STATUS_VARIANTS } from '../../../../components/BordDesignSystem/BordStatus/BordStatus.type';
import { capitalize } from '../../../../utils/unCamelCase';
import { translateToolsRelocations } from '../../../../utils/translations.utils';
import './notificationCard.scss';

interface INotificationCard {
  dataNotification: IDataNotifications;
}

const NotificationCard: FC<INotificationCard> = ({ dataNotification }: INotificationCard) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const notificationCardLenguage = 'nodi:dashboard:body:notifications:';
  const isOrder = 'orderId' in dataNotification;
  const {
    orderId = undefined,
    numberOfShipments = undefined,
    shipmentsDestinationsCountriesFlags = [],
    message = undefined,
    statusName = 'Sin información del estado'
  } = isOrder ? (dataNotification as IorderNotificationData) : {};
  const {
    id: logisticsId = 0,
    title = undefined,
    originName = undefined,
    originCountry = undefined,
    destinationName = undefined,
    destinationCountry = undefined,
    status = '',
    logisticServiceId = undefined,
    textToShow = undefined
  } = !isOrder ? (dataNotification as IlogisticServiceNotificationData) : {};
  const addressIssueMsg = (dataNotification as IlogisticServiceNotificationData)?.addressIssueMsg;
  const reportLsMsg = (dataNotification as IlogisticServiceNotificationData)?.nameString;

  const handleSeeDetailsText = isOrder
    ? t(`${notificationCardLenguage}seeDetails:order`)
    : t(`${notificationCardLenguage}seeDetails:service`);

  const getNotificationText = () => {
    if (isOrder) return orderId ? `${t(`${notificationCardLenguage}getNotificationText`)} ${orderId}` : '';
    const logisticsTitle = translateToolsRelocations(title || '');
    return `${logisticsTitle || 'Servicio Logístico'} ${`- SL N${logisticsId || logisticServiceId}`}`;
  };

  const getNameOrAddressText = () => {
    if (orderId && numberOfShipments) return `${numberOfShipments} ${numberOfShipments === 1 ? 'envío' : 'envíos'}`;
    return 'Sin información de envíos';
  };

  const redirectToOrderDetails = () => {
    if (!orderId) push('/nodi/orders');
    else push(`/nodi/orders/details/${orderId}?from=dashboard`);
  };

  const redirectToLogisticsDetails = () => {
    //The logistic service Id is returned by backend with the names "logisticId" and "logisticServiceId" in different circunstances, despite being the same data
    if (!logisticsId && !logisticServiceId) push('/nodi/logistics-services');
    else push(`/nodi/logistics-services/details/${logisticsId || logisticServiceId}?from=dashboard`);
  };

  const statusIsActive = status === 'activo';
  const statusInProgress = status === 'en proceso';
  const trackReportEvent = !!reportLsMsg && (statusIsActive || statusInProgress);
  const reportReasonProperty = statusIsActive ? 'ReportReasonInDelayed' : 'ReportReasonInProcess';
  const reportProblemOpenEventName = statusIsActive
    ? 'dashboardSLProblemReportDelayedOpenClick'
    : 'dashboardSLProblemReportInProcessOpenClick';

  const handleSeeDetails = () => {
    if (isOrder) return redirectToOrderDetails();
    if (trackReportEvent)
      segmentTrackEvent({
        [reportProblemOpenEventName]: {
          SL: logisticsId || logisticServiceId || 0,
          [reportReasonProperty]: reportLsMsg || ''
        }
      });
    return redirectToLogisticsDetails();
  };

  const notificationStatusText = isOrder
    ? capitalize(statusName || 'Sin estatus')
    : capitalize(status || 'Sin estatus');

  const distinctFlags = [...new Set(shipmentsDestinationsCountriesFlags || [])];

  const getFlags = () => {
    const orderFlags = distinctFlags?.map((flag, i) => {
      return <div key={`flag${i}`} className="orderFlag" style={{ backgroundImage: `url(${flag})` }} />;
    });
    const firstFlags = orderFlags.slice(0, 3);
    const excessFlags = orderFlags.length > 3 ? orderFlags.length - 3 : 0;
    const moreFlagsIndicator = (
      <div key="moreFlagsIndicator" className="orderFlag moreFlags">
        +{excessFlags}
      </div>
    );
    if (orderFlags.length <= 4) return orderFlags;
    else return [firstFlags, moreFlagsIndicator];
  };

  const getBordStatusVariant = () => {
    if (['activo', 'Activa'].includes(isOrder ? statusName || '' : status || '')) return STATUS_VARIANTS.informative;
    if (['entregado', 'Entregada'].includes(isOrder ? statusName || '' : status || '')) return STATUS_VARIANTS.success;
    if (['cancelado', 'Cancelada'].includes(isOrder ? statusName || '' : status || '')) return STATUS_VARIANTS.danger;
    return STATUS_VARIANTS.default;
  };

  return (
    <div id="notificationCard" onClick={handleSeeDetails}>
      <div className="notificationPoint" />
      <div className="notificationTitle">{getNotificationText()}</div>
      <div className="notificationOriginAndDestinationData">
        {isOrder && (
          <div className="orderDestinationDetail">
            <div className="textNameOrAddress">{getNameOrAddressText()}</div>
            <div className="flagsContainer">{getFlags()}</div>
          </div>
        )}
        {!isOrder && !addressIssueMsg && !reportLsMsg && (
          <div className="logisticServiceOriginAndDestinationData">
            <NudosHoverText onlyIfTruncated text={originName || 'Origen'} charactersLimit={25} />
            <BordFlag country={(originCountry?.code || '') as TcountryCode} variant="circle" />
            <BordOneToneIcon variant="arrowRight" customWidth="1.2rem" />
            <NudosHoverText onlyIfTruncated text={destinationName || 'Destino'} charactersLimit={25} />
            <BordFlag country={(destinationCountry?.code || '') as TcountryCode} variant="circle" />
          </div>
        )}
        {!isOrder && (!!addressIssueMsg || !!reportLsMsg || !!textToShow?.es) && (
          <div className="addressIssueMsg">{textToShow?.es || addressIssueMsg || `⚠️ ${reportLsMsg}`}</div>
        )}
      </div>
      <div className="orderMessageText" dangerouslySetInnerHTML={{ __html: message || '' }} />

      <div className="statusAndRedirectionContainer">
        <BordStatus renderJSX={<>{notificationStatusText}</>} variant={getBordStatusVariant()} />
        <BordTextButton
          text={handleSeeDetailsText}
          className="handleSeeDetailsButton"
          onClick={handleSeeDetails}
          rightIconProps={{ variant: 'arrowRightCircle', standardSize: 12 }}
        />
      </div>
    </div>
  );
};
export default NotificationCard;
