const RoundSyria = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="black"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#6DA544"
          d="M9.56057 12.1738 10.4239 14.8308H13.2178L10.9575 16.4732 11.8209 19.1303 9.56057 17.4881 7.30026 19.1303 8.16363 16.4732 5.90332 14.8308H8.69726L9.56057 12.1738ZM22.4405 12.1738 23.3038 14.8308H26.0978L23.8374 16.4732 24.7008 19.1303 22.4405 17.4881 20.1801 19.1303 21.0435 16.4732 18.7832 14.8308H21.5771L22.4405 12.1738Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSyria;
