const RoundBermuda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16C0 16.0038 16 .00175 16 0c8.8365 0 16 7.1635 16 16Z"
        />
        <path
          fill="#1A47B8"
          d="M16 7.65222v5.39648l-5.5652-5.39648H16ZM8.34782 16h4.70068L8.34782 9.73914l-.69563 4.86956L8.34782 16Z"
        />
        <path fill="#fff" d="M15.9572 16H16v-.0428L15.9572 16Z" />
        <path
          fill="#fff"
          d="M16 8.34781V0h-.0027C7.16194.0015 0 7.16431 0 16h8.34781v-4.7007L13.0485 16h2.9087c.0143-.0142.0286-.0285.0429-.0428v-2.9086l-4.7009-4.70079H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09469 2.08691C5.59226 3.51185 3.51182 5.59223 2.08694 8.09466v8.60094h4.17394V6.26085H16.6956V2.08691H8.09469Z"
        />
        <path
          fill="#F93939"
          d="m16.6361 14.6683-6.3208-6.32052H8.34781v.00012l8.28809 8.2881h.0001c.0001 0 .0001-1.3567.0001-1.9677Z"
        />
        <path fill="#F3F3F3" d="M18.0869 8.34778v7.65212c0 3.7275 9.7392 3.7275 9.7392 0V8.34778h-9.7392Z" />
        <path
          fill="#6DA544"
          d="M18.0869 15.9999c0 3.7275 4.8696 4.8696 4.8696 4.8696s4.8696-1.1421 4.8696-4.8696h-9.7392Z"
        />
        <path fill="#A2001D" d="m22.9565 12.9391-2.2609.9739V16l2.2609 1.3913L25.2174 16v-2.087l-2.2609-.9739Z" />
        <path fill="#3ECBF8" d="M20.6956 11.826h4.5218v2.087h-4.5218v-2.087Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBermuda;
