import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NudosBreadcrumbButton } from '../../../components/NudosComponents';
import { getOrderDetails, regenerateInvoice } from '../../../services/orders';
import { IorderDetails, IshipmentDetails } from '../../../types/orders';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import {
  OrderDetailsHeader,
  OrderDetailsHeaderSkeletonLoader,
  OrderDetailsSummary,
  OrderDetailsSummarySkeletonLoader,
  OrderPaymentDetails,
  ShipmentsGroupsList
} from './Components';
import { useTranslation } from 'react-i18next';
import BordBillingDataSection from '../../../components/DesignSystem/BordBillingDataSection/BordBillingDataSection';
import useSidebar from '../../../hooks/useSidebar';
import { ShipmentsGroupsListSkeletonLoader } from './Components/ShipmentsGroupsList/ShipmentsGroupsList';
import './OrderDetails.scss';

const OrderDetails = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const { moduleSidebarStyles } = useSidebar();
  const { id: orderId } = useParams<{ id: string }>();

  const [orderData, setOrderData] = useState<IorderDetails>();
  const [loading, setLoading] = useState(true);
  const [dataWasUpdated, setDataWasUpdated] = useState(0);
  const [problemsWithAddresses, setProblemsWithAddresses] = useState<boolean>(false);
  const [addressesHaveBeenUpdated, setAddressesHaveBeenUpdated] = useState<boolean>(true);
  const [editShipments, setEditShipments] = useState<IshipmentDetails>();
  const [loadingRegenerateInvoice, setLoadingRegenerateInvoice] = useState<boolean>(false);

  const origin = new URLSearchParams(search).get('from');
  const originId = new URLSearchParams(search).get('id');
  const shipmentsGroupEntries = orderData?.shimpentsInfo ? Object.entries(orderData.shimpentsInfo) : [];
  const i18nLanguageKey = 'nodi:orderDetails:getReturnText:';
  const orderDataExists = !loading && orderData;

  const getReturnText = () => {
    switch (origin) {
      case 'dashboard':
        return 'Dashboard';
      case 'tools':
        return t(`${i18nLanguageKey}tools`);
      case 'tool-details':
        return t(`${i18nLanguageKey}tool-details`);
      case 'employee-details':
        return t(`${i18nLanguageKey}employee-details`);
      case 'employee-edit':
        return t(`${i18nLanguageKey}employee-edit`);
      default:
        return t(`${i18nLanguageKey}default`);
    }
  };

  const returnAction = () => {
    switch (origin) {
      case 'dashboard':
        return push('/nodi/dashboard');
      case 'tools':
        return push('/nodi/tools');
      case 'tool-details':
        return push(`/nodi/details/${originId}`);
      case 'employee-details':
        return push(`/nodi/employees/details/${originId}`);
      case 'employee-edit':
        return push(`/nodi/employees/edit/${originId}`);
      default:
        return push('/nodi/orders');
    }
  };

  const getOrderData = async (noLoading?: boolean) => {
    if (!orderId) return;
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const responseOrderData = await getOrderDetails(orderId);
      if (responseOrderData) setOrderData(responseOrderData);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const updateOrderData = () => setDataWasUpdated(dataWasUpdated + 1);
  const getOrderDatawithoutLoader = () => getOrderData(true);

  const verifyReportsAndDetails = () => {
    if (orderData && orderData.shimpentsInfo) {
      const shipmentsGroups = Object.values(orderData.shimpentsInfo).flat();
      const shipments = shipmentsGroups.map(sg => sg.shipments).flat();
      if (shipmentsGroups.length > 0) {
        const reportsFound = !!shipments.find(
          shipment =>
            shipment.reports &&
            shipment.reports.length > 0 &&
            shipment?.reports?.find(report => report.status === 'OPEN')
        );

        const searchEditShipments = shipments.find(
          shipment => shipment?.editShipments?.id && shipment?.editShipments?.platform === 'SOGA'
        );

        const searchForEditionsFromSoga = !!searchEditShipments;
        if (searchEditShipments?.editShipments?.id) setEditShipments(searchEditShipments);
        const hasBeenEdited = !reportsFound && searchForEditionsFromSoga;
        setAddressesHaveBeenUpdated(hasBeenEdited);
        setProblemsWithAddresses(reportsFound);
      }
    }
  };

  const regenerateNewInvoice = async () => {
    if (orderId) {
      setLoadingRegenerateInvoice(true);
      try {
        await regenerateInvoice(+orderId);
        getOrderDatawithoutLoader && getOrderDatawithoutLoader();
        displaySuccessNotification({
          customJSXMessage: (
            <>
              La factura fue generada con éxito, puedes descargarla <br />
              nuevamente.
            </>
          )
        });
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoadingRegenerateInvoice(false);
      }
    }
  };

  useEffect(() => {
    getOrderData();
  }, [orderId, dataWasUpdated]);

  useEffect(() => {
    verifyReportsAndDetails();
  }, [orderData]);

  return (
    <div id="OrderDetails" className={moduleSidebarStyles}>
      <div className="w-full max-w-1180 mx-auto">
        <NudosBreadcrumbButton returnText={getReturnText()} returnAction={returnAction} />
      </div>
      {orderDataExists ? <OrderDetailsHeader orderData={orderData} /> : <OrderDetailsHeaderSkeletonLoader />}
      <div className="orderInformation">
        <div className="leftContent">
          {orderDataExists ? (
            <OrderDetailsSummary
              orderData={orderData}
              problemsWithAddresses={problemsWithAddresses}
              addressesHaveBeenUpdated={addressesHaveBeenUpdated}
              editShipments={editShipments}
              getOrderDatawithoutLoader={getOrderDatawithoutLoader}
            />
          ) : (
            <OrderDetailsSummarySkeletonLoader />
          )}
          <OrderPaymentDetails
            updateOrderDataFunction={updateOrderData}
            loading={loading}
            setLoading={setLoading}
            orderData={orderData}
          />
          <BordBillingDataSection
            updateDataCallback={updateOrderData}
            billingData={orderData?.billingData || {}}
            orderOrLogisticId={orderData?.orderId || ''}
            loading={!orderDataExists}
            invoiceUrl={orderData?.invoiceUrl ?? ''}
            invoiceText="Factura de la orden"
            loadingRegenerateInvoice={loadingRegenerateInvoice}
            regenerateNewInvoice={regenerateNewInvoice}
            displayInvoice
          />
        </div>
        <div className="rightContent">
          {orderDataExists &&
            shipmentsGroupEntries?.map(([country, countryShipmentsGroup], i) => {
              const countryShipmentData = countryShipmentsGroup[0];
              const flag = countryShipmentData?.country?.flag || '';
              return (
                <ShipmentsGroupsList
                  key={`shipmentList${i}-${country}`}
                  shipmentsGroups={countryShipmentsGroup}
                  flag={flag}
                  countryName={country}
                  quantityShipments={countryShipmentsGroup?.length}
                  updateOrderDataFunction={updateOrderData}
                  getOrderDatawithoutLoader={getOrderDatawithoutLoader}
                  globalStatusOrder={orderData.globalStatus}
                />
              );
            })}
          {!orderDataExists && <ShipmentsGroupsListSkeletonLoader />}
          <div className="whiteSpaceAtTheBottom w-10 h-10" />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
