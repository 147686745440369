import { AxiosError, AxiosRequestConfig } from 'axios';
import { IrawSuggestionFilter } from '../types/global';
import { apiGet } from './api';
import { ICountryFilter } from '../types/filters';

const { REACT_APP_CATALOG } = process.env;

const getProfileType = async (): Promise<IrawSuggestionFilter[]> => {
  return apiGet<AxiosRequestConfig, IrawSuggestionFilter[]>(`${REACT_APP_CATALOG}/product-profile`)
    .then(resp => {
      const profileFilter: IrawSuggestionFilter[] = resp;
      return profileFilter;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

const getProductLine = async (): Promise<IrawSuggestionFilter[]> => {
  return apiGet<AxiosRequestConfig, IrawSuggestionFilter[]>(`${REACT_APP_CATALOG}/product-position`)
    .then(resp => {
      const categoryList: IrawSuggestionFilter[] = resp;
      return categoryList;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

const getExchangeCountries = async (organizationId: number): Promise<ICountryFilter[]> => {
  return apiGet<AxiosRequestConfig, ICountryFilter[]>(`${REACT_APP_CATALOG}/exchange/by-organization/${organizationId}`)
    .then(resp => {
      const categoryList: ICountryFilter[] = resp;
      return categoryList;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};
export { getProfileType, getProductLine, getExchangeCountries };
