const SquareSamoa = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#232C80" fillRule="evenodd" d="M0 0H11.5238V8.53333H0V0Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M5.23805 2.1333H6.28567V3.19997H5.23805V2.1333ZM7.33329 3.19997H8.38091V4.26663H7.33329V3.19997ZM6.28567 4.26663H7.33329V5.3333H6.28567V4.26663ZM5.23805 5.3333H6.28567V6.39997H5.23805V5.3333ZM4.19043 3.19997H5.23805V4.26663H4.19043V3.19997Z"
            clipRule="evenodd"
            opacity="0.5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSamoa;
