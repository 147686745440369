const RoundThailand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M31.0048 10.4346H0.995188C0.352 12.1679 0 14.0426 0 15.9998C0 17.9569 0.352 19.8316 0.995188 21.5649H31.0049C31.648 19.8316 32 17.9569 32 15.9998C32 14.0426 31.648 12.1679 31.0048 10.4346Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 0C11.1505 0 6.80528 2.15775 3.87109 5.56519H28.1287C25.1945 2.15775 20.8493 0 15.9999 0ZM28.1287 26.4346H3.87109C6.80528 29.842 11.1505 31.9998 15.9999 31.9998 20.8493 31.9998 25.1945 29.842 28.1287 26.4346Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundThailand;
