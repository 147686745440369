import { useHistory } from 'react-router-dom';
import { productsNumber } from '../../../../utils/cart';
import { getOrgData } from '../../../../utils/getLocalStorageData';
import { isRouteMyLocation } from '../../../../utils/getPathRoute';
import { BordButton } from '../../../BordDesignSystem';
import HeaderShoppingCartSummary from '../HeaderShoppingCartSummary/HeaderShoppingCartSummary';
import './ShoppingCartButton.scss';

const ShoppingCartButton = () => {
  const { push } = useHistory();
  const isCheckout = isRouteMyLocation('checkout');
  const productsNumberCart = productsNumber();
  const orgData = getOrgData();
  const hasItemsStyle = productsNumberCart ? 'hasItems' : '';

  const onClickCheckoutButton = () => {
    orgData?.organizationState === 'active' && push('/catalogue/checkout');
  };

  if (isCheckout) return <></>;
  return (
    <div className={`shoppingCartButton ${hasItemsStyle}`}>
      <HeaderShoppingCartSummary />
      <BordButton
        label={productsNumberCart ? `${productsNumberCart}` : ''}
        buttonIconOne="shoppingCart"
        modeButton="quaternary"
        onClick={onClickCheckoutButton}
      />
    </div>
  );
};

export default ShoppingCartButton;
