import { useEffect, useState } from 'react';
import {
  IIssueReported,
  ILogisticReports,
  IlogisticServiceDetailedData,
  LS_ADDITIONAL_SERVICE_STATUS,
  LS_MANUAL_QUOTE_STATUS,
  LS_STATUS
} from '../../../../../../types/logisticsServicesModule.types';
import LogisticServiceBanner from './LogisticServiceBanner/LogisticServiceBanner';
import AdditionalServicesNotification from './AdditionalServicesNotification/AdditionalServicesNotification';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import ApproveOrRejectLogisticNotificacion from './ApproveOrRejectLogisticNotificacion/ApproveOrRejectLogisticNotificacion';
import LogisticServiceNotificationState from './LogisticServiceNotificationState/LogisticServiceNotificationState';

const LogisticServiceNotifications = ({
  isLoading,
  logisticServiceData,
  getLogisticServiceDetailCallback
}: {
  isLoading?: boolean;
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceDetailCallback: () => void;
}) => {
  const { t } = useTranslation();

  const [openAddressIssue, setOpenAddressIssue] = useState<boolean>(true);
  const [showAdditionalServicesBanner, setShowAdditionalServicesBanner] = useState<boolean>(true);

  const { addressIssue, status, reports, isCancelledAutomatically, tools, id, quoteDocumentUrl, manualQuoteStatus } =
    logisticServiceData || {};

  const translationKey = 'nodi:logisticServices:logisticServiceNotifications:';
  const originIssueReported = addressIssue?.origin?.issueReported?.length;
  const destinationIssueReported = addressIssue?.destination?.issueReported?.length;

  const originIssueReportedData: IIssueReported | undefined =
    addressIssue?.origin.editedBy !== 'user'
      ? addressIssue?.origin?.issueReported?.[addressIssue?.origin?.issueReported?.length - 1]
      : undefined;

  const destinationIssueReportedData: IIssueReported | undefined =
    addressIssue?.destination?.editedBy !== 'user'
      ? addressIssue?.destination?.issueReported?.[addressIssue?.destination?.issueReported?.length - 1]
      : undefined;

  const originFilterIssuesOtherThanCity = originIssueReportedData?.wrongFields?.find(
    field => field.addressWrongField.name !== 'Número de teléfono'
  );

  const destinationFilterIssuesOtherThanCity = destinationIssueReportedData?.wrongFields?.find(
    field => field.addressWrongField.name !== 'Número de teléfono'
  );

  const originHasOnlyPhoneIssues = !destinationIssueReportedData && !originFilterIssuesOtherThanCity;
  const destinationHasOnlyePhoneIssues = !originIssueReportedData && !destinationFilterIssuesOtherThanCity;

  const bothHaveOnlyPhoneIssues =
    !!originIssueReportedData &&
    !!destinationIssueReportedData &&
    !originFilterIssuesOtherThanCity &&
    !destinationFilterIssuesOtherThanCity;

  const onlyPhoneIssue = bothHaveOnlyPhoneIssues || originHasOnlyPhoneIssues || destinationHasOnlyePhoneIssues;

  const originAddressEditedByUser = addressIssue?.origin?.editedBy === 'user';
  const destinationAddressEditedByUser = addressIssue?.destination?.editedBy === 'user';
  const originAddressChangedByOps = addressIssue?.origin?.editedBy === 'nudos';
  const destinationAddressChangedByOps = addressIssue?.destination?.editedBy === 'nudos';

  const originViewedChanges = addressIssue?.origin?.viewedChanges;
  const destinationViewedChanges = addressIssue?.destination?.viewedChanges;
  const originReportedAndNotEdited = !!originIssueReported && !originAddressEditedByUser;
  const destinationReportedAndNotEdited = !!destinationIssueReported && !destinationAddressEditedByUser;
  const addressReportedAndNotEdited = originReportedAndNotEdited || destinationReportedAndNotEdited;

  const originChangedNotViewed = originAddressChangedByOps && !originViewedChanges;
  const destinationChangedNotViewed = destinationAddressChangedByOps && !destinationViewedChanges;
  const addressChangedByOpsNotViewed = originChangedNotViewed || destinationChangedNotViewed;

  const canceledLogisticService = status === LS_STATUS.CANCELLED;
  const isToConfirmStatus = status === LS_STATUS?.TO_CONFIRM;
  const isCancelledAutomaticallyByReports = isCancelledAutomatically && !!reports?.length;

  const displayReportsBanner =
    reports?.some(report => report?.currentStatus === 'OPEN') || isCancelledAutomaticallyByReports;

  const sortedReports = reports?.sort((date1, date2) => {
    const getLastLogDate = (report: ILogisticReports) => new Date(report?.logs?.[report.logs.length - 1]?.date || 0);
    return getLastLogDate(date2).getTime() - getLastLogDate(date1).getTime();
  });

  const latestReport = sortedReports?.[0];
  const displayAddresReported = addressReportedAndNotEdited || addressChangedByOpsNotViewed;
  const displayBannerForAddres = !canceledLogisticService && displayAddresReported;

  const someToolHasFailedAdditionalServices = !!tools?.some(
    tool =>
      !!tool?.logisticAdditionalServices?.some(
        service => service?.status?.statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      )
  );

  const displayAdditionalServicesBanner = !showAdditionalServicesBanner && someToolHasFailedAdditionalServices;
  const displayOpenAddressIssue =
    (displayAddresReported && !isLoading && !openAddressIssue) ||
    (!openAddressIssue && displayReportsBanner && !isLoading);

  const displayLogisticBanner = (displayBannerForAddres || displayReportsBanner) && !isLoading && openAddressIssue;
  const displayAdditionalServicesNotification = !displayLogisticBanner && showAdditionalServicesBanner;

  const displayApproveOrRejectLSNotification =
    !!logisticServiceData?.manualQuote &&
    !isLoading &&
    !!quoteDocumentUrl &&
    isToConfirmStatus &&
    manualQuoteStatus !== LS_MANUAL_QUOTE_STATUS?.APPROVED;

  const displayOpenNotificationBanner = displayOpenAddressIssue || displayAdditionalServicesBanner;

  const latestReportName = latestReport?.reasonNameString;
  const statusIsActive = status === 'activo';
  const statusInProgress = status === 'en proceso';
  const trackReportEvent = !!latestReport && (statusIsActive || statusInProgress);
  const reportReasonProperty = statusIsActive ? 'ReportReasonInDelayed' : 'ReportReasonInProcess';

  const viewEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedView'
    : 'logisticServiceProblemReportInProcessView';
  const openClickEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedOpenClick'
    : 'logisticServiceProblemInProcessOpenClick';
  const closeClickEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedCloseClick'
    : 'logisticServiceProblemInProcessCloseClick';

  const openBanners = () => {
    setOpenAddressIssue(true);
    setShowAdditionalServicesBanner(true);
    trackReportEvent &&
      segmentTrackEvent({
        [openClickEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });
  };

  const closeAlertCallback = () => {
    setOpenAddressIssue(false);
    trackReportEvent &&
      segmentTrackEvent({
        [closeClickEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });
  };

  useEffect(() => {
    trackReportEvent &&
      segmentTrackEvent({
        [viewEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });
  }, [trackReportEvent]);

  return (
    <div className="logisticServiceNotifications">
      <div className="titleAndQuoteStatus">
        <div className="title">{t(`${translationKey}title`)}</div>
        <LogisticServiceNotificationState logisticServiceData={logisticServiceData} isLoading={isLoading} />
        {displayOpenNotificationBanner && (
          <div className="openNotificationBanner" onClick={openBanners}>
            {t(`${translationKey}openNotificationBanner`)}
          </div>
        )}
      </div>
      {displayLogisticBanner && (
        <LogisticServiceBanner
          onlyPhoneIssue={onlyPhoneIssue}
          closeAlertCallback={closeAlertCallback}
          logisticServiceData={logisticServiceData}
          getLogisticServiceCallback={getLogisticServiceDetailCallback}
        />
      )}
      {displayAdditionalServicesNotification && (
        <AdditionalServicesNotification
          logisticServiceData={logisticServiceData}
          setShowAdditionalServicesBanner={setShowAdditionalServicesBanner}
        />
      )}
      {displayApproveOrRejectLSNotification && (
        <ApproveOrRejectLogisticNotificacion
          logisticServiceData={logisticServiceData}
          getLogisticServiceDetailCallback={getLogisticServiceDetailCallback}
        />
      )}
    </div>
  );
};

export default LogisticServiceNotifications;
