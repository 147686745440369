const SquareKiribati = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#FFDA2C"
            d="M10.9997 11.7333C13.0247 11.7333 14.6663 10.0618 14.6663 7.99994C14.6663 5.93807 13.0247 4.2666 10.9997 4.2666C8.97463 4.2666 7.33301 5.93807 7.33301 7.99994C7.33301 10.0618 8.97463 11.7333 10.9997 11.7333Z"
          />
          <path fill="#232C80" fillRule="evenodd" d="M0 8.5332H22V15.9999H0V8.5332Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M0 8.5332H22V9.59987H0V8.5332ZM0 10.6665H22V11.7332H0V10.6665ZM0 12.7999H22V13.8665H0V12.7999Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M8.38086 2.1333L10.9999 3.19997L13.619 2.1333H8.38086Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareKiribati;
