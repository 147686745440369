import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import useSWR from 'swr';
import { useHistory, useLocation } from 'react-router-dom';
import useShopContext from '../../../state/useShopContext';
import useEcommerceControler from '../../ecommerce/ecommerce.controller';
import { getSubscriptions, getUsersOrganizationData } from '../../../services/services';
import { InudoInfo } from '../../../types/global';
import { GenericFetchError } from '../../../constants';
import { NudosBreadcrumbButton } from '../../../components/NudosComponents';
import BordSearchBar from '../../../components/BordDesignSystem/BordSearchBar/BordSearchBar';
import { NudosLoaderModal } from '../../../components/NudosComponents';
import useDebounce from '../../../hooks/useDebounce';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import useStore from '../../../state';
import { segmentTrackEvent } from '../../../utils/segment';
import urlParameter from '../../../utils/urlParameter';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { useTranslation } from 'react-i18next';
import { formatOrgData } from '../../../utils/orgFormatData';
import InventoryListingCard from '../../../components/InventoryListingCard/InventoryListingCard';
import InventoryListingCardSkeleton from '../../../components/InventoryListingCard/components/InventoryListingCardSkeleton/InventoryListingCardSkeleton';
import BordEmptyState from '../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import InventoryFilters from './InventoryFilters/InventoryFilters';
import './InventoryModule.scss';

const InventoryModule = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { employeeToAssign, setEmployeeToAssign } = useStore();
  const { boardFilters, setNudos, setBoardFilters, searchbarString, setSearchbarString } = useShopContext();
  const { user, orgData } = useEcommerceControler();
  const orgDetails = formatOrgData();

  const [localOrgData, setLocalOrgData] = useState(orgData);
  const [hasBeenReloaded, setHasBeenReloaded] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [toolsViewEventWasSent, setToolsViewEventWasSent] = useState(false);
  const [assignFromEmployeeWasHandled, setAssignFromEmployeeWasHandled] = useState(false);
  const [totalCountriesAvailable, setTotalCountriesAvailable] = useState<ICountryDetail[]>();
  const [listOfCountriesWithLogistics, setListOfCountriesWithLogistics] = useState<ICountryDetail[]>();
  const [downloadFilter, setDownLoadFilters] = useState<string>('');

  const urlSearchParams = new URLSearchParams(search);
  const previousLocation = urlSearchParams.get('from');
  const employeeToAssignUserId = urlSearchParams.get('cuId');
  const assignToEmployee = previousLocation === 'assignToEmployee';
  const reassignToEmployee = previousLocation === 'reassignToEmployee';
  const fromDashboard = previousLocation === 'dashboard';
  const employeeCountryCode = urlParameter('countryCode');
  const acceptLogisticService = urlParameter('acceptLogisticService');
  const i18nLanguageKey = 'nodi:main:';
  const debouncedSearchString = useDebounce(searchbarString, 500);
  const queryString = debouncedSearchString ? `q=${debouncedSearchString}` : '';
  const hasBoardFilters = useMemo(() => !!boardFilters?.length, [boardFilters?.length]);

  const queryParams = () => {
    if (boardFilters && boardFilters.length > 0 && queryString)
      return `?filters=${JSON.stringify(boardFilters)}&${queryString}`;
    if (boardFilters && boardFilters.length > 0 && !queryString) return `?filters=${JSON.stringify(boardFilters)}`;
    if ((!boardFilters || !(boardFilters.length > 0)) && queryString) return `?${queryString}`;
    return '';
  };

  const dataParams = queryParams();

  const { data, error, mutate } = useSWR(
    `/order/organization/${orgDetails?.organizationId}${queryParams()}`,
    getSubscriptions,
    {
      revalidateIfStale: !reassignToEmployee,
      revalidateOnFocus: !reassignToEmployee,
      revalidateOnReconnect: !reassignToEmployee
    }
  );

  const hasOrganizationSubscriptions = useMemo(() => !!data?.length, [data?.length]);
  const emptyInventory = data?.length === 0 && searchbarString?.length === 0;
  const getUserUpdatedData = async () => {
    if (!user) return;
    try {
      const userUpdatedData = await getUsersOrganizationData(user.uid);
      localStorage.setItem('orgData', JSON.stringify(userUpdatedData));
      setLocalOrgData(userUpdatedData);
      if (userUpdatedData.organizationState === 'active' || userUpdatedData.organizationState === 'rejected')
        location.reload();
    } catch {
      displayErrorNotification();
    }
  };

  const redirectWaitingScreen = () =>
    push(`/status-register/waiting${orgData?.organizationState === 'pending' ? '?pending=true' : '?rejected=true'}`);

  const isActiveOrganization = useMemo(
    () => orgData?.organizationState === 'active' && localOrgData?.organizationState === 'active',
    [orgData?.organizationState, localOrgData?.organizationState]
  );

  const renderShowAppropriateBackground = () => {
    if (!isActiveOrganization || (!hasOrganizationSubscriptions && !hasBoardFilters && !debouncedSearchString)) {
      if (orgData.organizationState === 'pending' || orgData.organizationState === 'rejected') {
        redirectWaitingScreen();
      } else
        return (
          <BordEmptyState
            title={'Todavía no tienes herramientas en inventario'}
            subTitle="Compra herramientas en .bord Store o carga tus propias herramientas para comenzar a gestionar tu inventario"
            ilustrationIconProps={{
              variant: 'specialMarketPlace3dFilledCart',
              customWidth: '10rem'
            }}
            buttonOneProps={{
              label: 'Cargar inventario',
              onClick: () => push('/nodi/upload-external-tools'),
              bordButtonStyle: 'outlined',
              customWidth: 'w-280',
              buttonIconTwo: 'monitor'
            }}
            buttonTwoProps={{
              label: 'Comprar en .bord Store',
              onClick: redirectToCheckout,
              modeButton: 'primary',
              customWidth: 'w-280',
              buttonIconTwo: 'shoppingCart'
            }}
            customClassNameBordEmptyStateContainer="unsuccessfulFilterSearchNodi"
          />
        );
    }
    return null;
  };

  const goBackToEmployee = () =>
    employeeToAssign?.userId ? push(`/nodi/employees/details/${employeeToAssign?.userId}`) : push(`/nodi/employees/`);

  const returnToEmployeeButton =
    assignToEmployee || reassignToEmployee ? (
      <div className="returnButtonContainer">
        <NudosBreadcrumbButton returnText="Volver al empleado" returnAction={goBackToEmployee} />
      </div>
    ) : (
      <></>
    );

  const updateCardListData = () => {
    mutate();
    topScreen();
  };

  const topScreen = () => window.scrollTo(0, 0);

  const redirectToCheckout = () => {
    if (orgData?.organizationState !== 'active') return;
    else push('/catalogue/home');
  };

  const displayEmptyState = data && !hasOrganizationSubscriptions && (hasBoardFilters || debouncedSearchString);

  useLayoutEffect(() => {
    if (reassignToEmployee) {
      displaySuccessNotification({
        customJSXMessage: (
          <div>
            <div>Estas visualizando todas las herramientas que tienes en </div>
            <div>inventario</div>
          </div>
        )
      });
    }
  }, []);

  useEffect(() => {
    if (assignToEmployee || fromDashboard) {
      setShowLoaderModal(true);
      let filters = [{ name: 'productAvailability', includes: 'in', values: ['Disponibles'] }];
      if (acceptLogisticService === 'true' && !!employeeCountryCode && listOfCountriesWithLogistics) {
        const countriesWithLogistics = listOfCountriesWithLogistics
          ? listOfCountriesWithLogistics?.map(countryWithLogistics => countryWithLogistics.name)
          : [];
        filters = [...filters, { name: 'country', includes: 'in', values: [...countriesWithLogistics] }];
      }
      setBoardFilters(filters);
    }
  }, [listOfCountriesWithLogistics]);

  useEffect(() => {
    if (orgData?.organizationState === 'pending' && !hasBeenReloaded) {
      getUserUpdatedData();
      setHasBeenReloaded(true);
    }
    return () => {
      if (assignToEmployee || reassignToEmployee || fromDashboard) setBoardFilters([]);
      setEmployeeToAssign(undefined);
    };
  }, []);

  useEffect(() => {
    if (error) return setShowLoaderModal(false);
    if (!data) return;
    setNudos(data);
    if (!toolsViewEventWasSent) {
      segmentTrackEvent({ toolsView: { NumberOfTools: data?.length } });
      setToolsViewEventWasSent(true);
    }
    if (!assignToEmployee && !fromDashboard) return setShowLoaderModal(false);

    const timerNoToolsAvailable = setTimeout(() => {
      if (data.length >= 1) return;
      if (assignToEmployee && employeeToAssign?.userId && !assignFromEmployeeWasHandled)
        push(`/nodi/employees/details/${employeeToAssign?.userId}?from=noToolsAvailable`);
      else setShowLoaderModal(false);
    }, 1500);

    const timerAvailableTools = setTimeout(() => {
      if (data.length < 1) return;
      setShowLoaderModal(false);
      !assignFromEmployeeWasHandled &&
        displaySuccessNotification({
          customJSXMessage: (
            <div>
              <div>Estas visualizando las herramientas disponibles para </div>
              <div>
                asignar
                {acceptLogisticService === 'true' ? ' en países donde tenemos servicios logísticos' : ''}.
              </div>
            </div>
          )
        });
      !assignFromEmployeeWasHandled && setAssignFromEmployeeWasHandled(true);
    }, 1500);

    return () => {
      clearTimeout(timerNoToolsAvailable);
      clearTimeout(timerAvailableTools);
    };
  }, [data]);

  useEffect(() => {
    setDownLoadFilters(dataParams);
  }, [boardFilters]);

  useEffect(() => {
    topScreen();
    getListOfCountriesByOfferedService(setTotalCountriesAvailable);
    getListOfCountriesByOfferedService(setListOfCountriesWithLogistics, 'logisticService');
  }, []);

  return (
    <div className="inventoryListingContainer">
      <BordSearchBar
        defaultValue={searchbarString}
        placeholder={t(`${i18nLanguageKey}searchbarContainer`)}
        handleChange={(inputText: string) => setSearchbarString(inputText)}
        hasDeleteButton
        customClassName="searchbarContainer"
        standardSize="w-580"
        isDeactivated={emptyInventory as boolean}
      />
      {!emptyInventory && <InventoryFilters isUploadToolsButtonActive={true} formatFilters={downloadFilter} />}
      {showLoaderModal && <NudosLoaderModal loaderText={t(`${i18nLanguageKey}loaderText`)} modalTopPosition={92} />}
      {!showLoaderModal && (
        <main id="inventoryModule" className="moduleWithSidebar">
          {data && renderShowAppropriateBackground()}
          {returnToEmployeeButton}
          {!data && error && <GenericFetchError />}
          {!error && !data && (
            <div id="inventoryListingContainer">
              {[0, 1, 2, 3, 4, 5, 6, 7].map(i => {
                return <InventoryListingCardSkeleton key={`skeleton-card-${i}`} />;
              })}
            </div>
          )}
          {displayEmptyState && (
            <BordEmptyState
              title={t('nodi:genericNoResults')}
              subTitle="Intenta probando con otros términos o adquiere nuevas herramientas en .bord Store"
              buttonOneProps={{
                label: 'Comprar en .bord Store',
                onClick: redirectToCheckout,
                modeButton: 'primary',
                customWidth: 'w-280',
                buttonIconTwo: 'shoppingCart'
              }}
              customClassNameBordEmptyStateContainer="unsuccessfulFilterSearchNodi"
            />
          )}
          {hasOrganizationSubscriptions && (
            <div id="inventoryListingContainer">
              {data?.map((productData: InudoInfo, index) => {
                return (
                  <InventoryListingCard
                    key={uuid()}
                    cardIndex={index}
                    dataCard={productData}
                    updateCardListData={updateCardListData}
                    listOfAllCountries={totalCountriesAvailable || []}
                    listOfCountriesWithLogistics={listOfCountriesWithLogistics || []}
                    employeeToAssignUserId={employeeToAssignUserId}
                  />
                );
              })}
            </div>
          )}
        </main>
      )}
    </div>
  );
};

export default InventoryModule;
