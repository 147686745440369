const RoundNepal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#1A47B8" d="M31.9058 17.7394L31.9054 17.7388L31.9053 17.7394H31.9058Z" />
        <path
          fill="#1A47B8"
          d="M31.9056 17.7392C31.9056 17.7391 31.9057 17.7389 31.9057 17.7387L14.3591 0.083252C13.5676 0.163939 12.793 0.302314 12.0398 0.494127C5.11863 2.25631 0 16.0001 0 16.0001C0 16.0001 22.3999 30.9898 24.9553 29.2603C25.4666 28.9143 25.9569 28.5395 26.4236 28.1383L16.0247 17.7392H31.9056Z"
        />
        <path
          fill="#F93939"
          d="M27.8261 15.9999L12.2653 0.439209C5.2305 2.12152 0 8.4494 0 15.9999C0 24.8364 7.1635 31.9999 16 31.9999C19.3518 31.9999 22.4623 30.9685 25.0331 29.2068L11.8261 15.9999H27.8261Z"
        />
        <path
          fill="white"
          d="M15.2168 23.6244 13.2627 22.7053 14.3033 20.8129 12.1814 21.2188 11.9125 19.0752 10.4346 20.6518 8.95666 19.0752 8.68772 21.2188 6.56591 20.8128 7.60647 22.7053 5.65234 23.6244 7.60647 24.5435 6.56591 26.436 8.68784 26.0301 8.95659 28.1736 10.4346 26.5971 11.9125 28.1736 12.1814 26.0301 14.3032 26.4361 13.2627 24.5436 15.2168 23.6244ZM13.9126 8.87394 12.4914 8.2055 13.2482 6.82906 11.7049 7.12431 11.5093 5.56519 10.4344 6.71187 9.35943 5.56519 9.16381 7.12431 7.62049 6.82906 8.37737 8.2055 6.95605 8.87394 10.4344 9.56969 13.9126 8.87394Z"
        />
        <path
          fill="white"
          d="M14.6086 8.87402C14.6086 11.1792 12.7399 13.048 10.4347 13.048C8.12949 13.048 6.26074 11.1792 6.26074 8.87402"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNepal;
