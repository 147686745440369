import { useState } from 'react';
import { ModuleFilters } from '../../../../../components/NudosComponents';
import {
  DTOgetModuleFiltersRequestFunction,
  TgetFiltersList
} from '../../../../../components/NudosComponents/ModuleFilters/ModuleFilters';
import { ImoduleFilter } from '../../../../../types/global';
import { useTranslation } from 'react-i18next';
import './OrdersFilters.scss';

interface IordersFiltersProps {
  ordersFilters: ImoduleFilter[];
  setOrdersFilters: (ImoduleFilter: ImoduleFilter[]) => void;
  getOrdersFiltersRequestFunction: (organizationId: number | string) => Promise<DTOgetModuleFiltersRequestFunction>;
}

/**
 * @property {ImoduleFilter[]} ordersFilters - The state variable where the currently selected filters for the order module will be stored
 * @property {(ImoduleFilter: ImoduleFilter[]) => void} setOrdersFilters - The updater function for the orderFilters state variable - would be used to add or remove filter options, or to reset the selected filters
 * @property {(organizationId: number | string) => Promise<DTOgetModuleFiltersRequestFunction>} getOrdersFiltersRequestFunction - The request function that returns the available filters for the orders module for a given organization
 */
const OrdersFilters = ({ ordersFilters, setOrdersFilters, getOrdersFiltersRequestFunction }: IordersFiltersProps) => {
  const { t } = useTranslation();
  const [openOrderStatus, setOpenOrderStatus] = useState<boolean>(false);
  const [openCountry, setOpenCountry] = useState<boolean>(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState<boolean>(false);
  const i18nLanguageKey = 'nodi:orderDetails:ordersFilters:';

  const catalogueFilterMapping = {
    status: 'status',
    country: 'country',
    paymentType: 'paymentType'
  };

  const getOrdersFiltersList: TgetFiltersList = (formModuleFilters, handleClickFilterOption, getFilterActiveStatus) => [
    {
      title: t(`${i18nLanguageKey}status`),
      openFunction: setOpenOrderStatus,
      openStatus: openOrderStatus,
      rawSelectOptions: formModuleFilters('status'),
      handleChangeSelectedOption: handleClickFilterOption('status', 'name'),
      isFilterActive: getFilterActiveStatus('status')
    },
    {
      title: t(`${i18nLanguageKey}country`),
      openFunction: setOpenCountry,
      openStatus: openCountry,
      rawSelectOptions: formModuleFilters('country'),
      handleChangeSelectedOption: handleClickFilterOption('country', 'name'),
      isFilterActive: getFilterActiveStatus('country')
    },
    {
      title: t(`${i18nLanguageKey}paymentType`),
      openFunction: setOpenPaymentMethod,
      openStatus: openPaymentMethod,
      rawSelectOptions: formModuleFilters('paymentType'),
      handleChangeSelectedOption: handleClickFilterOption('paymentType', 'name'),
      isFilterActive: getFilterActiveStatus('paymentType')
    }
  ];

  return (
    <div className="ordersFilters moduleWithSidebar">
      <ModuleFilters
        moduleFilters={ordersFilters}
        setModuleFilters={setOrdersFilters}
        getModuleFiltersRequestFunction={getOrdersFiltersRequestFunction}
        getFiltersList={getOrdersFiltersList}
        moduleFiltersTitlesMapping={catalogueFilterMapping}
      />
    </div>
  );
};

export default OrdersFilters;
