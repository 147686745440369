import { TcountryCode } from '../components/BordDesignSystem/BordFlag/BordFlag.types';
import { LOGISTIC_SERVICE_TYPE } from '../constants';
import { nudosGreen200, nudosRed100, nudosYellow200 } from '../styles/constants';
import { TstoragePlace } from './assignationFluxes';
import { IbankAccountInfo } from './cart';
import { IbillingData } from './checkout';
import { ICountryDetail } from './countries';
import { ICoordinates, IreceiverInformation, TnudosCare, TplatformSubscription } from './global';
import { IBillingMethod } from './orders';
import { ItoolEssentialData } from './requestLogisticsModule';

export interface DTOlogisticsServiceInListingData {
  organizationId?: number | null;
  id?: number | null;
  productsQuantity?: number | null;
  status?: TlogisticsStatus | null;
  statusStringId?: string | null;
  paymentData?: IlogisticServiceInListingPaymentData;
  createdAt?: string | null;
  updatedAt?: string | null;
  originName?: string | null;
  originPlace?: string | null;
  originFlag?: string | null;
  originCountryCode?: TcountryCode | null;
  originCity?: string | null;
  destinationName?: string | null;
  destinationPlace?: string | null;
  destinationFlag?: string | null;
  destinationCountryCode?: TcountryCode | null;
  destinationCity?: string | null;
}

export interface IlogisticServiceInListingPaymentData {
  paymentTypeId?: number | null;
  paymentTypeName?: string | null;
  paymentTypeNameStringId?: string | null;
}

export interface IdateRange {
  since?: string | null;
  until?: string | null;
}

export interface IlogisticsEstimatedDates {
  collect?: IdateRange;
  delivery?: IdateRange;
}

export interface IlogisticsCollectionOrDeliveryAdditionalInfo {
  name?: string | null;
  personalId?: string | null;
  phone?: string | null;
  phoneCountry: ICountryDetail | null;
}

export interface ILogisticServiceLocationUser {
  id?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  personalId?: string | null;
  phone?: string | null;
  phoneCountry: ICountryDetail | null;
  photoProfile?: string | null;
}

export interface IlogisticServiceLocationData {
  place?: string | null;
  locationName?: string | null;
  address?: string | null;
  zipCode?: string | null;
  additionalReferences?: string | null;
  coordinates?: ICoordinates | null;
  additionalInfo: IlogisticsCollectionOrDeliveryAdditionalInfo;
  country: ICountryDetail;
  city?: string;
  cityId?: number;
  userId?: number | null;
  locationUser?: ILogisticServiceLocationUser;
}

export interface IEditLocationData {
  locationId?: number | null;
  place?: string | null;
  locationName?: string | null;
  address?: string | null;
  zipCode?: string | null;
  additionalReferences?: string | null;
  additionalInfo?: IlogisticsCollectionOrDeliveryAdditionalInfo;
  country?: ICountryDetail | null;
  city?: string | null;
  cityId?: number | null;
  userId?: number | null;
  locationUser?: ILogisticServiceLocationUser | null;
  shipmentInfo?: IreceiverInformation | null;
  coordinates?: ICoordinates | null;
}

export enum LS_MANUAL_QUOTE_STATUS {
  APPROVED = 'approved'
}

export interface IlogisticServiceDetailedData {
  id?: number | null;
  organizationId?: number | null;
  productQuantity?: number | null;
  tools: ItoolEssentialData[];
  status?: TlogisticsStatus | null;
  trackingLink?: string | null;
  trackingCode?: string | null;
  invoiceUrl?: string | null;
  proofOfPaymentUrl?: string | null;
  documentUrl?: string | null;
  estimatedDates: IlogisticsEstimatedDates;
  deliveryDate?: string | null;
  chosenCollectionDate?: string | null;
  chosenDeliveryDate?: string | null;
  estimatedDeliveryTime?: IestimatedDeliveryTime | null;
  createdAt?: string | null;
  collection: IlogisticServiceLocationData;
  delivery: IlogisticServiceLocationData;
  isPaymentAgreement: boolean;
  isPaymentReceived: boolean;
  paymentAgreementDate: string;
  paymentLink: string;
  paymentDate: string;
  paymentType: string;
  deliveryDocumentUrl: string;
  receiptDocumentUrl: string;
  logisticsQuoteData?: ILogisticQuoteGeneralData;
  quoteDocumentUrl?: string;
  manualQuote?: boolean;
  manualQuoteStatus?: LS_MANUAL_QUOTE_STATUS;
  addressIssue?: IAddressIssue | null;
  billingData?: IbillingData;
  billingCountry?: ICountryDetail;
  reports?: ILogisticReports[];
  hasUpdatedDates?: boolean;
  reportNumberOfTries?: number;
  isCancelledAutomatically?: boolean;
  statusStringId?: string;
  isPaymentZero?: boolean;
  bankAccountInfo?: IbankAccountInfo;
  exchangeRate?: string;
  billingMethod?: IBillingMethod;
  paymentMethodStringId?: string;
  paymentMethod?: string;
}

export interface ILogisicReportLogs {
  date?: string;
  message?: string | null;
  status?: string;
}

export interface ILogisticReports {
  currentStatus?: string;
  logs?: ILogisicReportLogs[];
  message?: string;
  reason?: string;
  reasonGlobalStatus?: string;
  reasonNameStringId?: string;
  reasonNameString?: string;
  reasonType?: string;
  reasonTypeNameStringId?: string;
  reportId?: number;
}

export interface IestimatedDeliveryTime {
  since: string | null;
  until: string | null;
}

export interface IAproximateDays {
  id?: number | null;
  countryId?: number | null;
  collectGap?: number | null;
  deliverySince?: number | null;
  deliveryUntil?: number | null;
  deliveryGap?: number | null;
}

export type TlogisticServiceDocumentType = 'invoice' | 'proofOfPayment' | 'document';

export type TlogisticsStatus = 'entregado' | 'por confirmar' | 'confirmado' | 'en proceso' | 'cancelado' | 'activo';

export interface ISimpleToolInfo {
  productId?: number | null;
  additionalServicesIds?: (number | null | undefined)[];
}

export interface IFees {
  redistribution: number;
  exitStorage: number;
  entryStorage: number;
  basicConfiguration: number;
  wiping: number;
  subtotal: number;
  toolCareSavings?: number;
  redistributionWithoutBuyAndHoldDiscount: number;
  redistributionTypeBetweenLocations?: LOGISTIC_SERVICE_TYPE;
}

export interface IStorageDates {
  entryDate: string;
  exitDate: string;
}

export interface IstorageMonthlyDetails {
  startDate: string;
  endDate: string;
  subscriptionLevel: string;
  chargedFee: number;
  platinumSavings: number;
  buyAndHoldSavings: number;
}

export interface IStorageDetails {
  totalStorageMonths?: number;
  monthsToCharge?: number;
  totalStorageCosts?: number;
  platinumSavings?: number;
  buyAndHoldSavings?: number;
  monthlyFee?: number;
  storageDates: IStorageDates;
  storageMonthlyDetails: IstorageMonthlyDetails[];
}
export interface IToolQuoteInfo {
  name: string;
  sku: string;
  nudosCareName: TnudosCare;
  serial: string;
  category: string;
  chargedFees: IFees;
  feesWithoutDiscount: IFees;
  productId: number;
  isBuyAndHold?: boolean;
  storageDetails?: IStorageDetails;
}

export interface ILogisticQuote {
  organizationId: number;
  originId: number;
  destinationId: number;
  destinationCountryId: number;
  tools: ISimpleToolInfo[];
}

export interface ILogisticQuoteGeneralData {
  bordLogo?: string;
  date?: string;
  organizationName: string;
  organizationId: number;
  platformSubscription: TplatformSubscription;
  currency: string;
  originName: string;
  destinationName: string;
  type: string;
  movement: string;
  subtotal: number;
  subtotalWithDiscounts: number;
  careSavings: number;
  platinumSavings: number;
  buyAndHoldSavings: number;
  paymentCommission: number;
  paymentMethodName?: string;
  exchangeRate?: string;
  total: number;
  totalWithoutDiscounts: number;
  subtotalIfCareIsNotApplied: number;
  tools: IToolQuoteInfo[];
  paymentMethod?: string | null;
}

export interface IToolsRejected {
  sku: string;
  productId: number;
  productName: string;
  productBrand: string;
  serial: string;
  nudosCareName: TnudosCare;
  logisticAdditionalServicesIds?: number[];
  logisticAdditionalServices: string[] | null;
}

interface IOriginDestinationDetails {
  country: string;
  city: string;
  place: TstoragePlace;
}
export interface IRejectedLogisticService {
  organizationId: number;
  userId: number;
  origin: IOriginDestinationDetails;
  destination: IOriginDestinationDetails;
  rejectComments: string;
  rejectedAmount: number;
  tools: IToolsRejected[];
}

export interface ILogisticServiceQuotePdfResponse {
  url: string;
}

export interface IToolInfo {
  productId: number;
  logisticAdditionalServicesData?: TAdditionalServiceData[] | null;
}

export interface TAdditionalServiceData {
  id?: number | null;
  comments?: string | null;
}
export interface IIsAutomatable {
  sameCountryRelocation?: boolean;
  origin?: string;
  destination?: string;
  tools: IToolInfo[];
}

export interface IIsAutomatableData {
  canQuoteBeAutomated: boolean;
  reasonsQuoteCantBeAutomated: string;
}

export type TlogisticsPaymentTypes = 'Care' | 'Cash' | 'Agreement';

export interface IAddressWrongField {
  name: 'Número de teléfono' | 'Ciudad' | 'Dirección' | 'Indicaciones' | 'Código Postal';
  nameStringId: string;
}

export interface IWrongFields {
  logisticServiceAddressIssueReportId: number;
  addressWrongFieldId: number;
  addressWrongField: IAddressWrongField;
}
export interface IIssueReported {
  reportSent: string;
  wrongFields: IWrongFields[];
}

export interface IAddressIssue {
  origin: IOriginDestinationIssue;
  destination: IOriginDestinationIssue;
}

export interface IOriginDestinationIssue {
  issueReported?: IIssueReported[];
  addressEdited?: boolean;
  editedBy?: string;
  viewedChanges?: boolean;
}

export interface IUpdateLogisticServiceAddress {
  firstName?: string;
  lastName?: string;
  personalId?: string;
  countryPhoneId?: number;
  phone?: string;
  address?: {
    address?: string;
    additionalReferences?: string;
    contactInformation?: {
      name?: string;
      phone?: string;
      countryPhoneId?: number;
      personalId?: string;
    };
    city?: string;
    cityId?: number;
    zipCode?: string;
    country?: string;
    countryId?: number;
    countryCode?: string;
    coordinates?: ICoordinates | null;
  };
  countryId?: number;
}

export enum LS_ADDITIONAL_SERVICE_TYPE {
  WIPING = 'Wiping',
  BASIC_CONFIG = 'Configuración básica',
  ADVANCED_CONFIG = 'Configuración avanzada'
}

export enum LS_ADDITIONAL_SERVICE_STATUS {
  TO_COMPLETE = 'Por completar',
  NOT_COMPLETED = 'No completado',
  COMPLETED = 'Completado'
}

export const LS_ADDITIONAL_SERVICE_STATUS_COLOR = {
  [`${LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE}`]: nudosYellow200,
  [`${LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED}`]: nudosRed100,
  [`${LS_ADDITIONAL_SERVICE_STATUS.COMPLETED}`]: nudosGreen200
};

export enum LS_ADDITIONAL_SERVICE_NOT_COMPLETED_REASONS {
  MDM = 'MDM',
  APPLE_ID = 'Bloqueado por Apple ID',
  USER_BLOCKED = 'Bloqueado por el usuario',
  NON_FUNCTIONAL = 'Equipo no funcional',
  OTHER = 'Otras razones'
}

export enum LS_STATUS {
  TO_CONFIRM = 'por confirmar',
  CONFIRMED = 'confirmado',
  IN_PROCESS = 'en proceso',
  ACTIVE = 'activo',
  DELIVERED = 'entregado',
  CANCELLED = 'cancelado'
}

export enum TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS {
  OFFBOARDING = 'tool_relocation_offboarding_8eb7f286-0882-4f63-a4c7-7a371aeacf59',
  ONBOARDING = 'tool_relocation_onboarding_92c94b03-354f-43cd-8023-ba2e08a896e7',
  MOVEMENT = 'tool_relocation_movement_d9b7103f-85e6-456b-9c77-84dac1a6edd0',
  REASSIGNMENT = 'tool_relocation_reassignment_786cacee-5cee-4ed1-b318-dc4662672226',
  STORAGE = 'tool_relocation_storage_f250fbbb-01cb-4f93-8cd9-cbceb8126a01',
  WARRANTY = 'tool_relocation_warranty_7bc344d1-7657-419f-9145-fda7358162a3',
  DIAGNOSIS = 'tool_relocation_diagnosis_f7e11775-0dc4-42a2-93f3-e37eed23fc2b'
}
