import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordRadioIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.2398 7.75993c.5579.55724 1.0004 1.21897 1.3024 1.94736.3019.72841.4573 1.50911.4573 2.29761 0 .7885-.1554 1.5693-.4573 2.2977-.302.7284-.7445 1.3901-1.3024 1.9473m-8.47998-.01c-.55786-.5572-1.00041-1.2189-1.30236-1.9473-.30195-.7284-.45736-1.5092-.45736-2.2977 0-.7885.15541-1.5692.45736-2.29761.30195-.72839.7445-1.39012 1.30236-1.94736m11.30998-2.82c1.8747 1.87528 2.9279 4.41836 2.9279 7.06997 0 2.6517-1.0532 5.1948-2.9279 7.07m-14.13998 0c-1.87471-1.8752-2.92787-4.4183-2.92787-7.07 0-2.65161 1.05316-5.19469 2.92787-7.06997m9.06998 7.06997c0 1.1046-.8954 2-2 2s-1.99998-.8954-1.99998-2c0-1.1045.89538-1.99997 1.99998-1.99997s2 .89547 2 1.99997Z"
      />
    </svg>
  );
};

export default BordRadioIcon;
