const SquareOman = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#249F58" fillRule="evenodd" d="M6 11H22V16H6V11Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M5.99976 0H21.9998V5H5.99976V0ZM4.79366 4.1632C4.73604 4.25173 4.67004 4.33387 4.59461 4.41173C3.97966 5.0368 2.98442 5.0368 2.37156 4.41173C1.75766 3.78773 1.75766 2.7744 2.37156 2.1504C2.44699 2.07147 2.5287 2.00427 2.61566 1.9456C2.37994 2.3936 2.57375 3.1104 3.11223 3.6576C3.6507 4.20373 4.35366 4.40213 4.7947 4.16213L4.79366 4.1632Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M2.53913 4.1634C2.59675 4.25193 2.66275 4.33407 2.73818 4.41193C3.35313 5.037 4.34837 5.037 4.96123 4.41193C5.57513 3.78793 5.57513 2.7746 4.96123 2.1506C4.8858 2.07167 4.80409 2.00447 4.71713 1.9458C4.95285 2.3938 4.75904 3.1106 4.22056 3.6578C3.68209 4.20393 2.97913 4.40233 2.53809 4.16233L2.53913 4.1634Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareOman;
