import { useTranslation } from 'react-i18next';
import { ItoolEssentialData } from '../../../../../../../types/requestLogisticsModule';
import { BlueSmile, NoSmile } from '../../../../../../../assets/images/components/floatForm';
import { NudosCareTag, NudosHoverText } from '../../../../../../../components/NudosComponents';
import { BordOneToneIcon, BordPhoto } from '../../../../../../../components/BordDesignSystem';
import { NudosRedirectionIcon } from '../../../../../../../components/DesignSystem';
import ProductPills from '../../../../../../../components/ProductPills/ProductPills';
import LogisticsAdditionalServicesTag from '../../LogisticsAdditionalServicesTag/LogisticsAdditionalServicesTag';
import './LogisticServiceToolCardMainSection.scss';

const LogisticServiceToolCardMainSection = ({
  logisticServiceId,
  toolData
}: {
  logisticServiceId?: number | null;
  toolData?: ItoolEssentialData;
}) => {
  const { t } = useTranslation();

  const translationKey = 'nodi:logisticServices:logisticServiceToolCardMainSection:';
  const noNameText = t(`${translationKey}noNameText`);
  const noSerialText = t(`${translationKey}noSerialText`);
  const noBrandText = t(`${translationKey}noBrandText`);

  const image = toolData?.image || '';
  const name = toolData?.name || noNameText;
  const pills = typeof toolData?.pills === 'string' ? JSON.parse(toolData.pills) : toolData?.pills || {};
  const serial = toolData?.serial || noSerialText;
  const nudosCare = toolData?.nudosCare || 'Free';
  const logisticAdditionalServices = toolData?.newLogisticAdditionalServices || [];
  const redirectionsTranslationKey = `nodi:headBar:`;

  const referenceModelIcon =
    toolData?.referenceModel === 'ExternalProduct' ? (
      <NoSmile className="referenceModelIcon" />
    ) : (
      <BlueSmile className="referenceModelIcon" />
    );

  const toolDetailsRedirectionData = {
    pathname: `/nodi/details/${toolData?.productId}`,
    state: {
      customReturnPath: `/nodi/logistics-services${logisticServiceId ? `/details/${logisticServiceId}` : ''}`,
      customNavigationTitle: t(`${redirectionsTranslationKey}headerTextBold:logisticService`),
      customNavigationSubtitle: t(`${redirectionsTranslationKey}headerTextRegular:productDetails`)
    }
  };

  return (
    <div className="logisticServiceToolCardMainSection">
      <div className="col1">
        <section className="photoSection">
          <BordPhoto
            url={image}
            toolModelMarkerProps={{ customSize: 3.2, isBordTool: true }}
            imageProps={{ style: { width: '100%', borderRadius: '1.2rem' } }}
          />
        </section>
        <section className="nameBrandAndPillsSection">
          <div className="nameAndBrand">
            <div className="nameAndRedirection">
              <NudosHoverText customClassName="name" text={name} charactersLimit={40} />
              <NudosRedirectionIcon redirectionDataObject={toolDetailsRedirectionData} />
            </div>
          </div>
          <div className="pillsAndNudosCare">
            <ProductPills pills={pills} />
          </div>
        </section>
      </div>
      <div className="serialAndReferenceModel">
        <div className="containerCard">
          <NudosCareTag nudosCareType={nudosCare} />
        </div>
        {logisticAdditionalServices.length > 0 && (
          <div className="referenceModelAndAdditionalServices">
            <LogisticsAdditionalServicesTag additionalServices={logisticAdditionalServices} />
          </div>
        )}
        <div className="serial">
          <BordOneToneIcon variant="barCode2" standardSize={12} />
          <div className="serialText">{serial}</div>
        </div>
      </div>
    </div>
  );
};

export default LogisticServiceToolCardMainSection;

export const LogisticServiceToolCardSkeletonLoader = () => {
  return (
    <div className="logisticServiceToolCardSkeletonLoader">
      <div className="logisticServiceToolCardMainSection">
        <div className="photoSection animationLoader" />
        <div className="nameBrandAndPillsSection">
          <div className="nameAndBrand animationLoader" />
          <ProductPills pills={{}} isLoading />
        </div>
        <div className="serialAndReferenceModel">
          <div className="referenceModel animationLoader" />
          <div className="serial animationLoader" />
        </div>
      </div>
    </div>
  );
};
