import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { NudosBreadcrumbButton, NudosLoaderModal } from '../../../components/NudosComponents';
import { employeesFile } from '../../../services/employees.service';
import useEmployeesUploadingState from '../../../state/useEmployeesUploadingState';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import segment from '../../../utils/segment';
import useEcommerceControler from '../../ecommerce/ecommerce.controller';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import downLoadDoc from '../../../assets/docs/plantilla.xlsx';
import { useTranslation } from 'react-i18next';
import { BordButton, BordUploaderButton } from '../../../components/BordDesignSystem';
import useSidebar from '../../../hooks/useSidebar';
import { EmployeeDetail } from './Components';

import './employeesLoad.scss';

const EmployeesLoad = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const { orgData } = useEcommerceControler();
  const { moduleSidebarStyles } = useSidebar();
  const [myFile, setMyFile] = useState<File>();
  const [myNameFile, setMyFileName] = useState<string>();
  const [formatInvalid, setFormatInvalid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setUploadedEmployeesData } = useEmployeesUploadingState();
  const fromWhereItCame = new URLSearchParams(search).get('from');
  const i18nLanguageKey = 'nodi:employee:employeesLoad:';

  const employeesExample = [
    {
      employeInfo: {
        name: 'Alejandra',
        lastName: 'García',
        email: 'alejandra@empresa.com',
        lada: 'México',
        phone: '8812749661',
        area: 'Diseño',
        position: 'Service designer',
        country: 'México',
        city: 'Monterrey'
      },
      success: true
    },
    {
      employeInfo: {
        name: 'Alejandra',
        lastName: 'García',
        email: 'alejandraempresa',
        lada: 'México',
        phone: '88-12749661 ext. 123',
        area: 'Diseño',
        position: 'Service designer',
        country: 'México',
        city: 'Madrid'
      },
      success: false
    }
  ];

  const handleClickReturn = () => {
    if (fromWhereItCame === 'dashboard') return push('/nodi/dashboard');
    return push('/nodi/employees');
  };

  const getReturnText = () => {
    if (fromWhereItCame === 'dashboard') return 'Dashboard';
    return t(`${i18nLanguageKey}getReturnText`);
  };

  const downLoadDocument = () => {
    window.open(downLoadDoc, '_blank');
  };

  const handleFile = (file: File) => {
    const uploadedFile = file;
    if (uploadedFile) {
      const extension = uploadedFile.name.substring(uploadedFile.name.lastIndexOf('.')).toLowerCase();
      if (extension !== '.xlsx' && extension !== 'xls') {
        displayErrorNotification({ customJSXMessage: <>{t(`${i18nLanguageKey}invalidFormat`)}</> });
        setFormatInvalid(true);
      }
      setMyFile(uploadedFile);
      setMyFileName(uploadedFile?.name || '');
    }
  };

  const uploadEmployees = async () => {
    setLoading(true);
    const formData = new FormData();
    myFile && formData.append('file', myFile);
    orgData && orgData?.organizationId && formData.append('organizationId', orgData.organizationId);
    try {
      const data = await employeesFile(formData);
      setUploadedEmployeesData(data);
      push('/nodi/upload-employees/summary');
      setLoading(false);
      segment.sessionEndingEvent();
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  const resetFile = () => {
    setMyFile(undefined);
    setMyFileName(undefined);
    setFormatInvalid(false);
  };

  if (loading)
    return (
      <div id="employeesLoad" className={`${moduleSidebarStyles || ''}`}>
        <div className="boxEmployeesLoad">
          <NudosLoaderModal loaderText="Cargando archivo de empleados" />{' '}
        </div>
      </div>
    );

  return (
    <div id="employeesLoad" className={`${moduleSidebarStyles || ''}`}>
      <div className="boxEmployeesLoad">
        <div className="breadcrumbContainer">
          <NudosBreadcrumbButton
            customClassName="loadEmployeesReturnButton"
            returnText={getReturnText()}
            returnAction={handleClickReturn}
          />
        </div>
        <div className="containerItems">
          <div className="boxItems">
            <div className="titleLoad">{t(`${i18nLanguageKey}titleLoad`)}</div>
            <div className="subtitleLoad">{t(`${i18nLanguageKey}subtitleLoad`)}</div>
            <div className="lineSpace">
              <div className="blueLine" />
            </div>
            <div className="downLoadButtonContainer">
              <div className="optionsLoadText">1. Descarga la plantilla (Recomendamos abrirlo en drive):</div>
              <BordButton
                customHeight="42"
                customWidth="w-380"
                label={t(`${i18nLanguageKey}myButton`)}
                onClick={downLoadDocument}
              />
            </div>
            <div className="lineSpace">
              <div className="blueLine" />
            </div>
            <div className="tableExampleContainer">
              <div className="infoContainer">
                <div className="numberTitle optionsLoadText">2. Completa todos los datos de cada empleado. </div>
                <div className="generalInfo">
                  <span className="alertText"> Ten en cuenta los posibles errores que se pueden presentar: </span>
                  <br />- Ingresar correos electrónicos ya existentes en el sistema.
                  <br />- Añadir nuevas opciones en los campos de selección (por ejemplo, área, país del teléfono o
                  país).
                  <br />- Usar caracteres especiales en el campo de teléfono.
                </div>
              </div>
              <div className="exampleTable">
                <div className="titleExample">Ejemplo:</div>
                {employeesExample &&
                  employeesExample.length > 0 &&
                  employeesExample.map((item, index) => {
                    return (
                      <EmployeeDetail
                        employeInfo={item.employeInfo}
                        success={item.success}
                        key={`employe-item-${index}`}
                      />
                    );
                  })}{' '}
              </div>
            </div>
            <div className="lineSpace">
              <div className="blueLine" />
            </div>
            <div className="containerToLoadTemplate">
              <div className="optionsLoadText">3. Vuelve a esta pantalla y sube el archivo en formato .xls.</div>
              <BordUploaderButton
                defaultFile={{ documentName: myNameFile || '' }}
                emptyBordUploaderButtonTitle="la plantilla"
                emptyBordUploaderButtonSubtitle="Formatos aceptados: xls Peso máximo: 5 mb"
                acceptFileExtension={['.xls', '.xlsx']}
                handleChangeFile={e => handleFile(e)}
                isFilled={!!myFile && !!myNameFile}
                handleDeleteFile={resetFile}
              />
            </div>
          </div>
          <div className="containerFotterButton">
            <BordButton
              onClick={() => uploadEmployees()}
              isLoading={loading}
              disabled={!myFile || formatInvalid || loading}
              label="Crear empleados"
              customWidth="w-280"
            />
          </div>
          <div className="h-42" />
        </div>
      </div>
    </div>
  );
};

export default EmployeesLoad;
