const SquareGrenada = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M3.14258 3.2002H18.8569V12.8002H3.14258V3.2002Z"
          clipRule="evenodd"
        />
        <path
          fill="#249F58"
          fillRule="evenodd"
          d="M3.14258 3.2002L10.9997 8.0002L3.14258 12.8002V3.2002Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M4.19043 7.4668H5.23805V8.53346H4.19043V7.4668Z"
          clipRule="evenodd"
        />
        <path
          fill="#249F58"
          fillRule="evenodd"
          d="M18.8571 3.2002L11 8.0002L18.8571 12.8002V3.2002Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M7.33301 1.06689H8.38063V2.13356H7.33301V1.06689ZM10.4759 1.06689H11.5235V2.13356H10.4759V1.06689ZM13.6187 1.06689H14.6663V2.13356H13.6187V1.06689ZM7.33301 13.8669H8.38063V14.9336H7.33301V13.8669ZM10.4759 13.8669H11.5235V14.9336H10.4759V13.8669ZM13.6187 13.8669H14.6663V14.9336H13.6187V13.8669Z"
          clipRule="evenodd"
        />
        <path
          fill="#F93939"
          d="M10.9999 10.6668C12.4464 10.6668 13.619 9.47292 13.619 8.00016C13.619 6.5274 12.4464 5.3335 10.9999 5.3335C9.55345 5.3335 8.38086 6.5274 8.38086 8.00016C8.38086 9.47292 9.55345 10.6668 10.9999 10.6668Z"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M11 9.3335L9.46003 10.158L9.75336 8.4119L8.50879 7.17456L10.23 6.92176L11 5.3335L11.77 6.92283L13.4913 7.17563L12.2446 8.41296L12.5379 10.158"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareGrenada;
