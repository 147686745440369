const RoundEastTimor = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M16.0004 0C12.0831 0 8.49524 1.40856 5.71387 3.74587L22.9569 16L5.71387 28.2541C8.49524 30.5914 12.0831 32 16.0004 32C24.8369 32 32.0004 24.8365 32.0004 16C32.0004 7.1635 24.8369 0 16.0004 0Z"
        />
        <path
          fill="black"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="white"
          d="M4.43611 12.3347L6.87111 14.6392L9.81536 13.0352L8.3763 16.0633L10.8114 18.3679L7.48673 17.9349L6.04748 20.963L5.43205 17.6672L2.10742 17.2341L5.05155 15.6303L4.43611 12.3347Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEastTimor;
