import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path d="M1.5 10.5L1.5 1.875" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.5 10.5L10.5 1.875" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 9.78125L7.5 2.59375" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.5 9.78125L4.5 2.59375" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MinSerialIcon = memo(ForwardRef);
export default MinSerialIcon;
