const RoundCuba = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M15.9999 31.9999c8.8366 0 16-7.1634 16-16 0-8.83648-7.1634-15.9999-16-15.9999C7.16342 0 0 7.16342 0 15.9999c0 8.8366 7.16342 16 15.9999 16Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9999.00024414c-5.2345 0-9.88162 2.51393586-12.80068 6.39999586H28.8005C25.8815 2.51412 21.2343.00024414 15.9999.00024414ZM15.9999 32.0001c5.2345 0 9.8816-2.5139 12.8007-6.4H3.19922c2.919 3.8861 7.56618 6.4 12.80068 6.4ZM0 16c0 1.096.1105 2.1661.320437 3.2H31.6796c.21-1.0339.3204-2.104.3204-3.2 0-1.0959-.1105-2.166-.3204-3.2H.320437C.1105 13.834 0 14.9041 0 16Z"
        />
        <path
          fill="#F93939"
          d="M4.68623 4.68628c-6.24831 6.24832-6.24831 16.37902 0 22.62742C7.2683 24.7317 9.75155 22.2483 15.9999 16L4.68623 4.68628Z"
        />
        <path
          fill="#fff"
          d="m6.4756 11.8262 1.03588 3.1884h3.35292l-2.71236 1.9708 1.03587 3.1885-2.71231-1.9707-2.71244 1.9707 1.03613-3.1885-2.71238-1.9708h3.35263l1.03606-3.1884Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCuba;
