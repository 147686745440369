import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordDropdown } from '..';
import { TformComponentsStandardSizes } from '../bordDesignSystem.types';

const BordEmployeesCountDropdown = ({
  componentSize,
  updateCurrentValueCallback,
  currentValue,
  isDeactivated,
  showSkeletonLoader,
  errorText
}: {
  componentSize?: TformComponentsStandardSizes;
  updateCurrentValueCallback: (newValue?: IemployeesCountOption) => void;
  currentValue?: IemployeesCountOption;
  isFocused?: boolean;
  isDeactivated?: boolean;
  showSkeletonLoader?: boolean;
  errorText?: string;
}) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState<IemployeesCountOption>();

  const translationKey = 'designSystemComponents:bordEmployeesCountDropdown:';
  const employeesCountLabel = `${t(`${translationKey}label`)}*`;

  const rawEmployeesCountOptions = [
    { optionId: 1, value: '0-10' },
    { optionId: 2, value: '11-50' },
    { optionId: 3, value: '51-100' },
    { optionId: 4, value: '101-200' },
    { optionId: 5, value: '+ 200' }
  ];

  const getAreaOptionUI = (
    rawOption: IemployeesCountOption,
    clickOptionCallback?: (option: IemployeesCountOption) => void
  ) => {
    const handleSelectOption = () => {
      updateCurrentValueCallback(rawOption);
      clickOptionCallback && clickOptionCallback(rawOption);
    };
    return (
      <div className="optionContainer areaOption" key={`${rawOption.optionId}`} onClick={handleSelectOption}>
        {rawOption.value}
      </div>
    );
  };

  const formDropdownOptionsCallback = (
    options: IemployeesCountOption[],
    clickOptionCallback?: (option: IemployeesCountOption) => void
  ) => {
    const employeesCountOptions = options.map(rawBillingData => {
      return getAreaOptionUI(rawBillingData, clickOptionCallback);
    });
    return employeesCountOptions;
  };

  const formSelectedOptionUICallback = (selectedOption: IemployeesCountOption) =>
    getAreaOptionUI(selectedOption, undefined);

  const filterBySearchStringCallback = (search: string, rawOptions: IemployeesCountOption[]) => {
    return rawOptions.filter(rawAreaOption => {
      const test = rawAreaOption?.value?.toLowerCase().trim().includes(search.toLowerCase().trim());
      return test;
    });
  };

  useEffect(() => {
    if (initialData || !currentValue) return;
    setInitialData(currentValue);
  }, [currentValue]);

  return (
    <div className="employeesCountDropdown">
      <BordDropdown
        customClassname="bordEmployeesCountDropdown"
        label={employeesCountLabel}
        componentSize={componentSize}
        customPlaceholder={'0-10'}
        isFilled={!!currentValue}
        currentValue={currentValue}
        includeMagnifierIcon={false}
        rawOptions={rawEmployeesCountOptions}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
        isDeactivated={isDeactivated}
        showSkeletonLoader={showSkeletonLoader}
        errorText={errorText}
      />
    </div>
  );
};

export default BordEmployeesCountDropdown;

export interface IemployeesCountOption {
  optionId: number;
  value: string;
}

export const employeesCountMap = {
  1: { optionId: 1, value: '0-10' },
  2: { optionId: 2, value: '11-50' },
  3: { optionId: 3, value: '51-100' },
  4: { optionId: 4, value: '101-200' },
  5: { optionId: 5, value: '+ 200' }
};
