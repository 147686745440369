const RoundOman = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M0 16.0001C0 22.0648 3.37438 27.3411 8.34781 30.0547V1.94556C3.37438 4.65918 0 9.93543 0 16.0001Z"
        />
        <path
          fill="#F93939"
          d="M31.0049 21.5652C31.648 19.8319 32 17.9571 32 16C32 14.0429 31.648 12.1681 31.0049 10.4348H0.99525C0.352062 12.1681 0 14.0429 0 16C0 17.9571 0.352062 19.8319 0.99525 21.5652L16 22.9565L31.0049 21.5652Z"
        />
        <path
          fill="#6DA544"
          d="M15.9999 32C22.8794 32 28.744 27.658 31.0047 21.5652H0.995117C3.25574 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#F93939"
          d="M0 16.0001C0 23.3925 5.01381 29.6129 11.8261 31.4489V0.55127C5.01381 2.38727 0 8.60765 0 16.0001Z"
        />
        <path
          fill="white"
          d="M9.78814 7.04087L8.31245 5.56519L7.3287 6.54906L6.34495 5.56519L4.86927 7.04087L5.85308 8.02469L4.86914 9.00856L6.34483 10.4842L7.32864 9.50037L8.31245 10.4842L9.78814 9.00856L8.80433 8.02469L9.78814 7.04087Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundOman;
