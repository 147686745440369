import { useTranslation } from 'react-i18next';
import { IInvoicePaymentMethods } from '../../../types/cart';
import { removeAccents } from '../../../utils/string';
import { NudosHoverText } from '../../NudosComponents';
import { TformComponentsStandardSizes } from '../../BordDesignSystem/bordDesignSystem.types';
import { BordDropdown } from '../../BordDesignSystem';

import './NudosCurrencyDropdown.scss';

/**
 * @property {boolean} displayText: Optional, boolean indicating if the text should be displayed, default is TRUE.
 * @property {boolean} displayLabel: Optional, a label for the select input, default is TRUE.
 * @property {boolean} isDeactivated: Optional, boolean indicating if the dropdown input is deactivated. If a value is provided the input will behave as a read only field and styles will be accordingly.
 * @property {IInvoicePaymentMethods[]} currencyList: Optional, an array of all the possible values from which select.
 * @property {string} componentSize: Optional, one of the standard sizes for the components, corresponding to one of the following words: "extra-small", "small", "medium" or "large". If no value is passed the component will have the width of its parent container.
 * @property {number} charactersLimit: The number of characters that the text will have before it gets truncated.ƒ
 * @property {string} customPlaceholder: Optional, the desired text when the dropdown is closed and no option has been selected yet. Default is the text "Elige una opción"
 * @property {boolean} showSkeletonLoader: Optional, boolean indicating if the component is loading and therefore the skeleton loader is to be shown. Default is false.
 * @property {(newValue: IInvoicePaymentMethods) => void} updateCurrentValueCallback: Callback function that received a value from the currencyList.
 */

const NudosCurrencyDropdown = ({
  errorText,
  displayText = true,
  displayLabel = true,
  customLabel,
  currentValue,
  hideErrorText,
  isDeactivated,
  currencyList,
  componentSize,
  charactersLimit,
  customPlaceholder,
  showSkeletonLoader,
  updateCurrentValueCallback
}: {
  errorText?: string;
  customLabel?: string;
  displayText?: boolean;
  displayLabel?: boolean;
  currentValue?: IInvoicePaymentMethods;
  hideErrorText?: boolean;
  isDeactivated?: boolean;
  currencyList: IInvoicePaymentMethods[];
  componentSize?: TformComponentsStandardSizes;
  charactersLimit: number;
  customPlaceholder?: string;
  showSkeletonLoader?: boolean;
  updateCurrentValueCallback: (newValue: IInvoicePaymentMethods) => void;
}) => {
  const { t } = useTranslation();
  const translationKey = 'designSystemComponents:nudosCurrencyDropdown:';
  const customPlaceholderTranslation = customPlaceholder ? customPlaceholder : t(`${translationKey}customPlaceholder`);
  const countryLabel = customLabel ? customLabel : t(`${translationKey}customLabel`) + '*';
  const label = displayLabel ? countryLabel : undefined;
  const noOptionContainer = t(`${translationKey}noOptionContainer`);
  const noSearchOption = t(`${translationKey}noSearchOption`);

  const filterBySearchStringCallback = (search: string, currencyList: IInvoicePaymentMethods[]) => {
    return currencyList.filter(currency => {
      const cleanSearch = removeAccents(search.toLowerCase().trim());
      const currencyName = currency?.currency?.name?.toLowerCase().trim();
      return currencyName && removeAccents(currencyName)?.includes(cleanSearch);
    });
  };

  const formDropdownOptionsCallback = (
    currencyList: IInvoicePaymentMethods[],
    clickOptionCallback?: (option: IInvoicePaymentMethods) => void,
    searchString?: string
  ) => {
    const handleSelectOption = (option: IInvoicePaymentMethods) => {
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };

    if (currencyList.length === 0)
      return [
        <div className="noOptionContainer" key="loading">
          {searchString ? noSearchOption : noOptionContainer}
        </div>
      ];

    return currencyList.map(country => {
      const currencyValue = country?.currency?.name;
      const currencyKey = country?.currency?.nameStringId;
      const currencyName = currencyKey ? t(`services:currencies:${currencyKey}`) : currencyValue || 'Sin datos';

      return (
        <div
          className="optionContainer flex"
          key={`country-option-${country?.id}`}
          onClick={() => handleSelectOption(country)}
        >
          {!!country?.currency?.flag && (
            <div className="optionFlag" style={{ backgroundImage: `url(${country?.currency?.flag})` }} />
          )}
          {displayText && <NudosHoverText text={currencyName} charactersLimit={charactersLimit} onlyIfTruncated />}
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (selectedOption: IInvoicePaymentMethods) => {
    const currencyValue = selectedOption?.currency?.name;
    const currencyKey = selectedOption?.currency?.nameStringId;
    const currencyName = currencyKey ? t(`services:currencies:${currencyKey}`) : currencyValue || 'Sin datos';
    return (
      <div className="optionContainer flex gap-1">
        {!!selectedOption?.currency?.flag && (
          <div className="optionFlag" style={{ backgroundImage: `url(${selectedOption?.currency?.flag})` }} />
        )}
        {displayText && <NudosHoverText text={currencyName} charactersLimit={charactersLimit} onlyIfTruncated />}
      </div>
    );
  };

  return (
    <div className="NudosCurrencyDropdown">
      <BordDropdown
        customClassname="NudosCurrencyDropdownSearchbar"
        label={label}
        isFilled={!!currentValue}
        errorText={errorText}
        rawOptions={currencyList}
        currentValue={currentValue}
        hideErrorText={hideErrorText}
        componentSize={componentSize}
        isDeactivated={isDeactivated}
        customPlaceholder={customPlaceholderTranslation}
        showSkeletonLoader={showSkeletonLoader}
        includeMagnifierIcon={false}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
      />
    </div>
  );
};

export default NudosCurrencyDropdown;
