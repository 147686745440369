import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../../../components/BordDesignSystem';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { IorderDetails } from '../../../../../../types/orders';
import { requestOrderSummaryPDF } from '../../../../../../services/checkout';
import './OrderPaymentDocuments.scss';
import { PAYMENT_TYPES } from '../../../../../../types/checkout';

const OrderPaymentDocuments = ({ orderData }: { orderData?: IorderDetails }) => {
  const { t } = useTranslation();

  const [quotationPdfUrl, setQuotationPdfUrl] = useState('');
  const [loadingQuotation, setLoadingQuotation] = useState(false);

  const { orderId, proofOfPaymentUrl, paymentMethod } = orderData || {};

  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:';
  const isMxnMethod = paymentMethod === PAYMENT_TYPES.TRANSFER_MXN;
  const renderDisabledStyles = !proofOfPaymentUrl ? 'disabledButton' : '';
  const renderCustomDocumentButtonStyles = loadingQuotation ? 'w-172 h-42' : '';
  const downloadQuotation = async () => {
    if (quotationPdfUrl) window.open(quotationPdfUrl, 'blank');
    if (!orderId) return;
    setLoadingQuotation(true);
    try {
      const { url } = await requestOrderSummaryPDF(orderId);
      if (url) {
        setQuotationPdfUrl(url);
        window.open(url, 'blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingQuotation(false);
    }
  };

  const handleDownloadProofOfPayment = () => {
    const newWindow = window.open(proofOfPaymentUrl || undefined, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="orderPaymentDocuments">
      <div className="paymentInfoTitle paymentDocumentTitle">{t(`${i18nLanguageKey}paymentDocumentTitle`)}</div>
      <div className="documentsContainer">
        <BordButton
          label={t(`${i18nLanguageKey}orderSummary`)}
          buttonIconOne="download"
          modeButton="tertiary"
          onClick={downloadQuotation}
          isLoading={loadingQuotation}
          disabled={loadingQuotation || !orderId}
          customClassName={renderCustomDocumentButtonStyles}
        />
        {isMxnMethod && (
          <div className={`buttonContainer ${renderDisabledStyles}`}>
            <BordButton
              label="Comprobante"
              buttonIconOne="download"
              modeButton="tertiary"
              onClick={handleDownloadProofOfPayment}
              disabled={!proofOfPaymentUrl}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderPaymentDocuments;
