import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { TdestinationAddress } from '../../../../../types/assignationFluxes';
import { ICountryDetail } from '../../../../../types/countries';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { IcityDetails } from '../../../../../types/cities.types';
import { IaddressFromGoogleMaps } from '../../../../../types/global';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { addNewAddressOffice } from '../../../../../services/account';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import { BordButton, BordTextInput } from '../../../../../components/BordDesignSystem';
import { NudosAddressSearchBar } from '../../../../../components/NudosComponents';
import './LocationOfficeAddressModal.scss';

const LocationOfficeAddressModal = ({
  officePreSelection,
  officeAddressList,
  listOfAllCountries,
  refreshOfficeData,
  officeDirect,
  updateCardListData,
  handleChangeOption,
  closeLocationModal,
  organizationOfficeAddress,
  defaultCodeOfficeAddress,
  actionTemporalOption
}: {
  officePreSelection?: boolean;
  officeAddressList: TdestinationAddress[];
  listOfAllCountries: ICountryDetail[];
  refreshOfficeData: (officeCode: string) => void;
  officeDirect?: boolean;
  updateCardListData: (type?: string) => void;
  closeLocationModal: () => void;
  handleChangeOption: (option: number) => void;
  defaultCodeOfficeAddress: string;
  organizationOfficeAddress?: TdestinationAddress;
  actionTemporalOption: (temporalOption: number) => void;
}) => {
  const { t } = useTranslation();
  const orgInfo = formatOrgData();

  const [city, setCity] = useState<IcityDetails>();
  const [selectCountry, setSelectCountry] = useState<ICountryDetail>();
  const [postalCode, setPostalCode] = useState<string>();
  const [additionalReferences, setAdditionalReferences] = useState<string>();
  const [completeAddress, setCompleteAddress] = useState<IaddressFromGoogleMaps>();
  const [labelAddress, setLabelAdress] = useState<string>();
  const [saveLoadingAddress, setSaveLoadingAddress] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:tools:locationOfficeAddressModal:';

  const checkOfficeCountry = (code: string) => {
    if (!officePreSelection) return;
    if (code && officeAddressList && officeAddressList.length > 0) {
      const existCountry = officeAddressList.find(element => element.code === code);
      if (existCountry) {
        setPostalCode(existCountry?.zipCode || '');
        setAdditionalReferences(existCountry?.additionalReference || '');
        setLabelAdress(existCountry?.address || '');
        setCity({ name: existCountry?.city, id: existCountry?.cityId });
      } else {
        setPostalCode('');
        setAdditionalReferences('');
        setLabelAdress('');
      }
    }
  };

  const buttonDisabled = () => {
    if (completeAddress && selectCountry?.code !== completeAddress.country) return true;
    if (!additionalReferences) return true;
    return false;
  };

  const saveOfficeAddress = async () => {
    setSaveLoadingAddress(true);
    const fullAddress = {
      address: completeAddress?.address || '',
      city: city?.name || completeAddress?.city || '',
      cityId: city?.id,
      coordinates: { lat: completeAddress?.coordinates?.lat || '', lng: completeAddress?.coordinates?.lng || '' },
      countryId: Number(selectCountry?.id),
      additionalReferences: additionalReferences,
      zipCode: postalCode
    };
    const minAddress = {
      countryId: Number(selectCountry?.id),
      address: labelAddress,
      city: city?.name || '',
      cityId: city?.id,
      additionalReferences: additionalReferences,
      zipCode: postalCode,
      coordinates: organizationOfficeAddress?.coordinates
    };
    try {
      await addNewAddressOffice(Number(orgInfo?.organizationId), completeAddress ? fullAddress : minAddress);
      if (selectCountry?.code) {
        await refreshOfficeData(selectCountry?.code);
      }
      if (officeDirect) {
        await updateCardListData('officeEdit');
        closeLocationModal();
      } else {
        if (officePreSelection) {
          handleChangeOption(0);
          actionTemporalOption(1);
        } else {
          updateCardListData();
          closeLocationModal();
        }
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setSaveLoadingAddress(false);
    }
  };

  const initialCountry = () => {
    if (!officePreSelection) return;
    if (organizationOfficeAddress && officeAddressList && listOfAllCountries) {
      const filterCountry = listOfAllCountries.find(element => organizationOfficeAddress?.code === element.code);
      if (filterCountry) setSelectCountry(filterCountry);
      setPostalCode(organizationOfficeAddress?.zipCode || '');
      setAdditionalReferences(organizationOfficeAddress?.additionalReference || '');
      setLabelAdress(organizationOfficeAddress?.address || '');
    }
    if (!organizationOfficeAddress && officeDirect && defaultCodeOfficeAddress) {
      const filterCountry = listOfAllCountries.find(element => defaultCodeOfficeAddress === element.code);
      if (filterCountry) setSelectCountry(filterCountry);
    }
  };

  useEffect(() => {
    initialCountry();
  }, [organizationOfficeAddress]);

  return (
    <div id="locationOfficeAddressModal">
      <div className="modalTitle">{t(`nodi:tools:locationsSelectModal:office`)}</div>
      <div className="modalSubtitle">{t(`${i18nLanguageKey}modalSubtitle`)}</div>
      <div className="organizationName">{orgInfo?.organizationName}</div>
      <div className="countrySection">
        <NudosCountryDropdown
          componentSize="w-180"
          currentValue={selectCountry}
          charactersLimit={20}
          countriesList={listOfAllCountries}
          updateCurrentValueCallback={e => {
            setSelectCountry(e);
            checkOfficeCountry(e.code);
          }}
        />
        <BordTextInput
          label={t(`${i18nLanguageKey}postalCodeBox`)}
          currentText={postalCode}
          isFilled={!!postalCode}
          placeholder={t(`${i18nLanguageKey}postalCodeBox`)}
          setCurrentText={e => setPostalCode(e)}
          standardSize="w-180"
        />
      </div>
      <NudosAddressSearchBar
        label={t(`${i18nLanguageKey}addressBox:label`)}
        handleAddressSelection={e => setCompleteAddress(e)}
        customPlaceholder={t(`${i18nLanguageKey}addressBox:customPlaceholder`)}
        defaultValueAddressName={labelAddress}
        hideDidntFindAddressToolTip
        componentSize="w-380"
        regionBias={selectCountry?.code}
        errorText={
          completeAddress && selectCountry?.code !== completeAddress?.country
            ? `Esta dirección no pertenece a ${selectCountry?.name || 'este país'}`
            : ''
        }
      />
      <BordTextInput
        className="referencesBox"
        label={t(`${i18nLanguageKey}referencesBox:label`)}
        isFilled={!!additionalReferences}
        placeholder={t(`${i18nLanguageKey}referencesBox:placeholder`)}
        currentText={additionalReferences}
        setCurrentText={e => setAdditionalReferences(e)}
      />
      <BordButton
        isLoading={saveLoadingAddress}
        disabled={saveLoadingAddress || !postalCode || buttonDisabled()}
        onClick={() => {
          if (selectCountry?.code) saveOfficeAddress();
        }}
        label={t(`${i18nLanguageKey}nudosButton`)}
        customWidth="w-380"
      />
    </div>
  );
};

export default LocationOfficeAddressModal;
