const SquareDrCongo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#3A99FF" rx="2" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M4.71194 5.5787 3.17194 6.40324 3.46527 4.6571 2.2207 3.41977 3.94194 3.16697 4.71194 1.57764 5.48089 3.16697 7.20213 3.41977 5.95546 4.6571 6.25089 6.40217M0 14.9331 22 3.19977V-1.06689L0 10.6664V14.9331Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M0 17.0669L22 5.33356V1.06689L0 12.8002V17.0669Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 16L22 4.26667V0L0 11.7333V16Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareDrCongo;
