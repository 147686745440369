import { FC, useState } from 'react';
import './DynamicsPills.scss';
interface IDynamicsPillsDetails {
  text?: string;
  hoverText?: string;
}

interface IDynamicsPills {
  dataPills: IDynamicsPillsDetails;
  elementsNumber: number;
  disabledMaxWidth?: boolean;
}

const DynamicsPills: FC<IDynamicsPills> = ({ dataPills, elementsNumber, disabledMaxWidth }: IDynamicsPills) => {
  const maxWidth = 100 / elementsNumber;
  const [showOver, setShowOver] = useState<boolean>();
  return (
    <div
      id="dynamicsPills"
      onMouseEnter={() => {
        setShowOver(true);
      }}
      onMouseOver={() => {
        setShowOver(true);
      }}
      onMouseLeave={() => {
        setShowOver(false);
      }}
      style={{ maxWidth: maxWidth && !disabledMaxWidth ? `${maxWidth || 100}%` : '' }}
    >
      <div className={`normalTextPills truncate ${showOver ? 'hoverText' : ''}`}> {dataPills?.text}</div>
      {showOver && dataPills?.hoverText && <div className="hoverPills">{dataPills?.hoverText}</div>}
    </div>
  );
};

export default DynamicsPills;
