const BordDeviceIcon = () => {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6403 20.2268C45.6291 20.2268 43.9987 21.8645 43.9987 23.8847C43.9987 25.905 45.6291 27.5427 47.6403 27.5427H47.673C49.6842 27.5427 51.3146 25.905 51.3146 23.8847C51.3146 21.8645 49.6842 20.2268 47.673 20.2268H47.6403Z"
        fill="url(#paint0_linear_6604_3002)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4243 8.63886C27.6617 8.33805 30.4557 8.33821 33.6985 8.33839H61.6139C64.8566 8.33821 67.6507 8.33805 69.8881 8.63886C72.2859 8.96125 74.5844 9.68825 76.4488 11.5526C78.3131 13.4169 79.0401 15.7154 79.3625 18.1132C79.6633 20.3507 79.6631 23.1447 79.6629 26.3874L79.6629 54.063L79.6633 54.214C79.668 54.4016 79.6904 54.4942 79.772 54.6633L79.8087 54.7355L81.1839 57.411C83.8323 62.2984 85.6157 66.1903 86.4405 69.4215C87.2892 72.7466 87.1892 75.6431 85.7471 78.2625C84.1348 81.1908 81.2543 82.3325 78.0233 82.8382C74.9007 83.3269 70.7365 83.3268 65.6282 83.3267H29.6842C24.5759 83.3268 20.4117 83.3269 17.2891 82.8382C14.0581 82.3325 11.1775 81.1908 9.56533 78.2625C8.12317 75.6431 8.02313 72.7466 8.87191 69.4215C9.69673 66.1903 11.4801 62.2984 14.1285 57.411L15.5037 54.7355L15.5404 54.6633C15.622 54.4942 15.6444 54.4016 15.6491 54.214L15.6494 54.063L15.6494 26.3874C15.6493 23.1447 15.6491 20.3507 15.9499 18.1132C16.2723 15.7154 16.9993 13.4169 18.8636 11.5526C20.728 9.68825 23.0264 8.96125 25.4243 8.63886ZM22.9654 26.6282V50.0392C22.9654 51.0738 22.9654 51.5911 23.2868 51.9125C23.6082 52.234 24.1255 52.234 25.1601 52.234H70.1522C71.1868 52.234 71.7042 52.234 72.0256 51.9125C72.347 51.5911 72.347 51.0738 72.347 50.0392V26.6282C72.347 23.076 72.3392 20.7799 72.1118 19.0881C71.8984 17.5015 71.5541 17.0042 71.2756 16.7257C70.9971 16.4472 70.4998 16.1029 68.9132 15.8895C67.2214 15.6621 64.9253 15.6543 61.3731 15.6543H33.9393C30.3871 15.6543 28.0909 15.6621 26.3991 15.8895C24.8125 16.1029 24.3152 16.4472 24.0368 16.7257C23.7583 17.0042 23.4139 17.5015 23.2006 19.0881C22.9731 20.7799 22.9654 23.076 22.9654 26.6282Z"
        fill="url(#paint1_linear_6604_3002)"
      />
      <g filter="url(#filter0_bi_6604_3002)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.5942 33.3384C77.5332 33.3383 80.7067 33.3383 83.2019 33.6996C85.8037 34.0763 87.9556 34.8806 89.6453 36.7003C91.3155 38.4989 92.0359 40.7544 92.3765 43.4832C92.7082 46.1401 92.7082 49.5316 92.7082 53.7993V70.0254C92.7082 74.2931 92.7082 77.6847 92.3765 80.3416C92.0359 83.0704 91.3155 85.3259 89.6453 87.1245C87.9556 88.9441 85.8037 89.7485 83.2019 90.1252C80.7066 90.4865 77.5332 90.4864 73.5942 90.4864H73.2805C69.3415 90.4864 66.168 90.4865 63.6727 90.1252C61.071 89.7485 58.919 88.9441 57.2294 87.1245C55.5592 85.3259 54.8388 83.0704 54.4981 80.3416C54.1664 77.6846 54.1665 74.2932 54.1665 70.0254V53.7994C54.1665 49.5316 54.1664 46.1401 54.4981 43.4832C54.8388 40.7544 55.5592 38.4989 57.2294 36.7003C58.919 34.8806 61.071 34.0763 63.6727 33.6996C66.168 33.3383 69.3414 33.3383 73.2805 33.3384H73.2805H73.5942H73.5942ZM70.7793 38.6545C69.6783 38.6545 68.7858 39.547 68.7858 40.648C68.7858 41.749 69.6783 42.6415 70.7793 42.6415H76.0954C77.1964 42.6415 78.0889 41.749 78.0889 40.648C78.0889 39.547 77.1964 38.6545 76.0954 38.6545H70.7793ZM73.4255 79.8542C71.964 79.8542 70.7793 81.0442 70.7793 82.5122C70.7793 83.9802 71.964 85.1703 73.4255 85.1703H73.4492C74.9107 85.1703 76.0954 83.9802 76.0954 82.5122C76.0954 81.0442 74.9107 79.8542 73.4492 79.8542H73.4255Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_6604_3002"
          x="49.2943"
          y="28.4662"
          width="48.2859"
          height="66.8923"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.43609" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6604_3002" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6604_3002" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.24812" />
          <feGaussianBlur stdDeviation="4.22256" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6604_3002" />
        </filter>
        <linearGradient
          id="paint0_linear_6604_3002"
          x1="47.6562"
          y1="8.33838"
          x2="47.7125"
          y2="96.1817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6604_3002"
          x1="47.6562"
          y1="8.33838"
          x2="47.7125"
          y2="96.1817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BordDeviceIcon;
