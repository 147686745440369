import { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { BordButton } from '../../../../components/BordDesignSystem';
import { GenericFetchError } from '../../../../constants';
import {
  changeStatusUser,
  getEmployeeById,
  uploadAssignationDocumentOrUpdateAssignationDate
} from '../../../../services/employees.service';
import { Iemployee, Tany } from '../../../../types/global';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../utils/displayNudosStandardNotifications';
import segment, { segmentTrackEvent } from '../../../../utils/segment';
import EmployeeDetailsBody from './Components/EmployeeDetailsBody';
import EmployeeDetailsHeader from './Components/EmployeeDetailsHeader';
import NoAvailableToolsModal from './Components/NoAvailableToolsModal/NoAvailableToolsModal';
import useStore from '../../../../state';
import EmployeeDetailsSkeletonLoader from './EmployeeDetailsSkeletonLoader';
import { getListOfCountriesByOfferedService } from '../../../../utils/getCountries';
import { ICountryDetail } from '../../../../types/countries';
import { useTranslation, Trans } from 'react-i18next';
import BordModal from '../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './EmployeeDetails.scss';

const EmployeeDetails = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { id: userId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const { setEmployeeToAssign } = useStore();
  const previousLocation = new URLSearchParams(search).get('from');
  const i18nLanguageKey = 'nodi:employee:employeeDetails:';
  const [employee, setEmployee] = useState<Iemployee>();
  const [loading, setLoading] = useState(false);
  const [showNoToolsModal, setShowNoToolsModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [loaderFileCardId, setLoadingFileCardId] = useState<number>();
  const [listOfCountriesWithLogistics, setListOfCountriesWithLogistics] = useState<ICountryDetail[]>();
  const noTools = previousLocation === 'noToolsAvailable';

  const activeEmployee = !!employee?.active;

  const employeeToAssignData =
    employee?.id &&
    employee?.organizationId &&
    employee?.userId &&
    employee?.firstName &&
    employee?.lastName &&
    employee?.city &&
    employee?.area &&
    employee?.position
      ? {
          id: employee.id,
          organizationId: employee.organizationId,
          userId: employee.userId,
          firstName: employee.firstName,
          lastName: employee.lastName,
          city: employee.city,
          area: employee.area,
          position: employee.position
        }
      : undefined;

  const getEmployee = async (noLoading?: boolean, redirection?: boolean) => {
    if (!userId) return;
    if (!noLoading) {
      setLoading(true);
    }
    try {
      const response = await getEmployeeById(userId);
      if (!response) return;
      setEmployee(response);
      if (!redirection) return;
      setEmployeeToAssign(response);
      push(
        `/nodi/tools?from=assignToEmployee&countryCode=${response?.country?.code}&acceptLogisticService=true&cuId=${userId}`
      );
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingFileCardId(undefined);
      setLoading(false);
    }
  };

  const disabledEmployeeModal = () => {
    setShowStatusModal(true);
  };

  const updateActiveStatus = () => updateStatusUser(!!employee?.active);
  const closeModal = () => setShowStatusModal(false);

  const updateStatusUser = async (statusUser: boolean) => {
    setLoadingStatus(true);
    if (userId) {
      try {
        await changeStatusUser(userId);
        setShowStatusModal(false);
        setLoadingStatus(false);
        getEmployee();
        if (statusUser) {
          displaySuccessNotification({
            variant: 'success',
            customJSXMessage: <>{t(`${i18nLanguageKey}updateStatusUser:disabledEmployee`)}</>,
            hideAction: true
          });
        } else {
          displaySuccessNotification({
            variant: 'success',
            customJSXMessage: <>{t(`${i18nLanguageKey}updateStatusUser:enabledEmployee`)}</>,
            hideAction: true
          });
        }
      } catch (error) {
        setLoadingStatus(false);
        displayErrorNotification();
      }
    }
  };

  const updateFile = async (assignmentId: number, file: Tany) => {
    setLoadingFileCardId(assignmentId);
    try {
      const formData = new FormData();
      formData.append('file', file);
      await uploadAssignationDocumentOrUpdateAssignationDate([assignmentId], formData);
      await getEmployee(true);
      segment.sessionEndingEvent();
    } catch (error) {
      setLoadingFileCardId(undefined);
      displayErrorNotification();
    }
  };

  useEffect(() => {
    getEmployee();
  }, [userId]);

  useEffect(() => {
    if (!employee) return;
    const currentEquipment = [...(employee?.assignedTools || [])].filter(tool => {
      return !tool.assignedUntil;
    });
    segmentTrackEvent({
      employeesDetailView: {
        AssignedTools: currentEquipment?.length,
        EmployeeArea: employee?.area || '',
        EmployeeCity: employee?.city || '',
        EmployeeCountry: employee?.country?.name || 'Otro',
        EmployeeName: `${employee.firstName} ${employee.lastName}`
      }
    });
  }, [employee]);

  useEffect(() => {
    noTools && setShowNoToolsModal(true);
    getListOfCountriesByOfferedService(setListOfCountriesWithLogistics, 'logisticService');
  }, []);

  return (
    <div id="employeeDetails" className="moduleWithSidebar">
      {showStatusModal && (
        <BordModal
          modalContent={
            <ModalContent
              toCloseModal={() => {
                setShowStatusModal(false);
              }}
              title={
                activeEmployee ? t(`${i18nLanguageKey}modalTitleActive`) : t(`${i18nLanguageKey}modalTitleInactive`)
              }
              customWidth="58rem"
              customHeight="44rem"
              subTitle={
                activeEmployee
                  ? t(`${i18nLanguageKey}modalSubtitleActive`)
                  : t(`${i18nLanguageKey}modalSubtitleInactive`)
              }
              CustomModalComponent={
                <>
                  <section className="subtitles">
                    <div className="secondSubtitle">
                      {activeEmployee ? (
                        <>
                          <Trans i18nKey={t(`${i18nLanguageKey}secondSubtitleActive`)} components={{ 1: <br /> }} />
                        </>
                      ) : (
                        <>
                          <Trans i18nKey={t(`${i18nLanguageKey}secondSubtitleInactive`)} components={{ 1: <br /> }} />
                        </>
                      )}
                    </div>
                    <div className="thirdSubtitle">
                      {activeEmployee ? (
                        <>
                          <Trans i18nKey={t(`${i18nLanguageKey}thirdSubtitleActive`)} components={{ 1: <br /> }} />
                        </>
                      ) : (
                        <>
                          <Trans i18nKey={t(`${i18nLanguageKey}thirdSubtitleInactive`)} components={{ 1: <br /> }} />
                        </>
                      )}
                    </div>
                  </section>
                  <div className="containerButtons">
                    <div className="boxButtons">
                      <BordButton
                        customWidth="w-180"
                        modeButton="tertiary"
                        isLoading={loadingStatus}
                        customClassName="firstButton"
                        label={
                          activeEmployee
                            ? t(`${i18nLanguageKey}boxButtons:yesInactivateEmployee`)
                            : t(`${i18nLanguageKey}boxButtons:keepInactive`)
                        }
                        onClick={activeEmployee ? updateActiveStatus : closeModal}
                      />
                      <BordButton
                        customWidth="w-180"
                        isLoading={loadingStatus}
                        disabled={loadingStatus}
                        label={
                          activeEmployee
                            ? t(`${i18nLanguageKey}boxButtons:noKeepEmployee`)
                            : t(`${i18nLanguageKey}boxButtons:yesReactivateEmployee`)
                        }
                        onClick={activeEmployee ? closeModal : updateActiveStatus}
                      />
                    </div>
                  </div>
                </>
              }
            />
          }
        />
      )}
      {loading && <EmployeeDetailsSkeletonLoader />}
      {!loading && employee && (
        <>
          <EmployeeDetailsHeader employee={employee} disabledEmployee={disabledEmployeeModal} />
          <EmployeeDetailsBody
            updateFile={updateFile}
            loaderFileCardId={loaderFileCardId}
            employee={employee}
            listOfCountriesWithLogistics={listOfCountriesWithLogistics}
          />
        </>
      )}
      {!loading && !employee && <GenericFetchError />}
      {noTools && showNoToolsModal && employee?.country?.code && employee?.userId && (
        <BordModal
          modalContent={
            <ModalContent
              CustomModalComponent={
                <NoAvailableToolsModal
                  handleGoToEcommerce={() => push('/catalogue/home')}
                  handleGoToToolsListing={() => {
                    employeeToAssignData?.userId && setEmployeeToAssign(employeeToAssignData);
                    push(
                      `/nodi/tools?from=reassignToEmployee&countryCode=${employee?.country?.code}&acceptLogisticService=true&cuId=${employee?.userId}`
                    );
                  }}
                />
              }
              toCloseModal={() => setShowNoToolsModal(false)}
              modalContentTitle={t(`${i18nLanguageKey}noAvailableToolsModal`)}
              modalSize="lg"
            />
          }
        />
      )}
    </div>
  );
};

export default EmployeeDetails;
