import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="delete-square"
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g stroke="#ff664e">
      <path
        className="square"
        d="M2.276 14.67c.22 2.058 1.876 3.713 3.933 3.942a41.89 41.89 0 0 0 9.392 0c2.057-.23 3.713-1.884 3.933-3.942.162-1.52.3-3.08.3-4.67 0-1.59-.138-3.15-.3-4.67-.22-2.057-1.876-3.713-3.933-3.942a41.878 41.878 0 0 0-9.392 0c-2.057.23-3.712 1.885-3.933 3.943-.162 1.52-.3 3.079-.3 4.67 0 1.59.138 3.15.3 4.668Z"
        fill="#fff"
      />
      <path className="x" d="m13.43 7.475-5.05 5.05M13.43 12.525l-5.05-5.05" strokeLinecap="round" />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const DeleteSquare = memo(ForwardRef);
export default DeleteSquare;
