import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import NudosLogisticsEmployeeCard from '../NudosLogisticsEmployeeCard/NudosLogisticsEmployeeCard';
import { Iemployee } from '../../../types/global';
import {
  IlocationAddressForLogistics,
  IlogisticsLocations,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../types/requestLogisticsModule';
import NudosLogisticsWarehouseCard from '../NudosLogisticsWarehouseCard/NudosLogisticsWarehouseCard';
import NudosLogisticsOfficeOrOtherAddressCard from '../NudosLogisticsOfficeOrOtherAddressCard/NudosLogisticsOfficeOrOtherAddressCard';
import { TstoragePlace } from '../../../types/assignationFluxes';
import './NudosLogisticsOriginAndDestinationCardsContainer.scss';

const NudosLogisticsOriginAndDestinationCardsContainer = ({
  title,
  selectedOrigin,
  selectedDestination,
  originData,
  destinationData,
  updateOriginDataCallback,
  updateDestinationDataCalback,
  listOfCountries
}: {
  title: string;
  selectedOrigin?: TstoragePlace;
  selectedDestination?: TstoragePlace;
  originData?: TlogisticsOriginOrDestinationData;
  destinationData?: TlogisticsOriginOrDestinationData;
  updateOriginDataCallback: (originData: TlogisticsOriginOrDestinationData) => void;
  updateDestinationDataCalback: (destinationData: TlogisticsOriginOrDestinationData) => void;
  listOfCountries?: ICountryDetail[];
}) => {
  const [logisticsLocationData, setLogisticsLocationData] = useState<IlogisticsLocations>();

  const updateOrigin = (newOriginData: TlogisticsOriginOrDestinationData) =>
    setLogisticsLocationData({ ...logisticsLocationData, originLocation: newOriginData });

  const updateDestination = (newDestinationData: TlogisticsOriginOrDestinationData) => {
    setLogisticsLocationData({ ...logisticsLocationData, destinationLocation: newDestinationData });
  };

  const getVariableCard = (
    typeOfCard: 'origin' | 'destination',
    locationPlace: TstoragePlace,
    updaterCallback: (newLocationData: TlogisticsOriginOrDestinationData) => void,
    locationData?: TlogisticsOriginOrDestinationData
  ) => {
    if (!locationData) return;
    switch (locationPlace) {
      case 'user':
        return (
          <NudosLogisticsEmployeeCard
            updateLogisticsLocationDataCallback={updaterCallback}
            type={typeOfCard}
            existingEmployeeData={locationData ? (locationData as Iemployee) : undefined}
            listOfCountries={listOfCountries || []}
            disableCountryUpdating
          />
        );
      case 'office':
      case 'other':
      case 'ownLocation':
        return (
          <NudosLogisticsOfficeOrOtherAddressCard
            type={typeOfCard}
            listOfCountries={listOfCountries || []}
            updateLogisticsLocationDataCallback={updaterCallback}
            existingOfficeOrOtherAddressData={locationData ? (locationData as IlocationAddressForLogistics) : undefined}
            disableCountryUpdating
            selectedOriginOrDestination={locationPlace}
          />
        );
      default:
        return (
          <NudosLogisticsWarehouseCard
            type={typeOfCard}
            selectedOriginOrDestination={locationPlace}
            warehouseData={locationData as IwarehouseData}
          />
        );
    }
  };

  useEffect(() => {
    logisticsLocationData?.originLocation && updateOriginDataCallback(logisticsLocationData?.originLocation);
    logisticsLocationData?.destinationLocation &&
      updateDestinationDataCalback(logisticsLocationData?.destinationLocation);
  }, [logisticsLocationData]);

  return (
    <div className="NudosLogisticsOriginAndDestinationCardsContainer">
      <div className="cardsContainerTitle">{title}</div>
      <div className="cards">
        {getVariableCard('origin', selectedOrigin || '', updateOrigin, originData)}
        {getVariableCard('destination', selectedDestination || '', updateDestination, destinationData)}
      </div>
    </div>
  );
};

export default NudosLogisticsOriginAndDestinationCardsContainer;
