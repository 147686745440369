const RoundLebanon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0ZM16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32Z"
        />
        <path
          fill="#6DA544"
          d="M20.174 18.7826L16.0001 11.1304L11.8262 18.7826H14.9566V20.8695H17.0436V18.7826H20.174Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLebanon;
