const SquareLesotho = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M10.5809 6.18683L9.42848 8.5335H12.5713L11.419 6.18683C11.485 6.09723 11.5237 5.98736 11.5237 5.86683C11.5237 5.57243 11.2891 5.3335 10.9999 5.3335C10.7108 5.3335 10.4761 5.57243 10.4761 5.86683C10.4761 5.98736 10.5149 6.09723 10.5809 6.18683ZM13.619 9.60016C13.619 10.189 12.4456 10.6668 10.9999 10.6668C9.55419 10.6668 8.38086 10.189 8.38086 9.60016V8.5335H13.619V9.60016Z"
            clipRule="evenodd"
          />
          <path fill="#249F58" fillRule="evenodd" d="M0 12H22V16H0V12Z" clipRule="evenodd" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V4H0V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareLesotho;
