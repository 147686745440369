const SquareBarbados = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#FFDA2C"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M0 0h7v16H0V0Zm15 0h7v16h-7V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#AE6A3E"
            fillRule="evenodd"
            d="M10.4762 10.6668h1.0476v1.0666h-1.0476v-1.0666Zm2.0952-2.13335h1.0476v1.06666h-1.0476V8.53345Zm-4.19045 0h1.04762v1.06666H8.38095V8.53345Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M11.5238 8.53327V4.2666h-1.0476v4.26667H9.42857v1.06666h1.04763v1.06667h1.0476V9.59993h1.0476V8.53327h-1.0476Zm1.0476-3.2h1.0476v3.2h-1.0476v-3.2Zm-4.19045 0h1.04762v3.2H8.38095v-3.2Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBarbados;
