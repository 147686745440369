import SelectToolsForAssignmentModalCustomComponent from './SelectToolsForAssignmentModalCustomComponent';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import { DTOgetModuleFilters, IQueryTools } from '../../../../../types/DTO';
import BordModal from '../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './SelectToolsForAssignmentModal.scss';

const SelectToolsForAssignmentModal = ({
  closeModalCallback,
  toolsSelectionCallback,
  toolsAvailableToAssignAccordingToAppliedFilters,
  selectedToolsToAssign,
  filtersData,
  handleChangeExtraQueryData,
  refreshLoading,
  loadingFilters
}: {
  closeModalCallback: () => void;
  toolsSelectionCallback: (newToolsToAssign: ItoolEssentialData[]) => void;
  toolsAvailableToAssignAccordingToAppliedFilters?: ItoolEssentialData[];
  selectedToolsToAssign: ItoolEssentialData[];
  filtersData?: DTOgetModuleFilters;
  handleChangeExtraQueryData?: (query: IQueryTools) => void;
  refreshLoading?: boolean;
  loadingFilters?: boolean;
}) => {
  return (
    <div className="SelectToolsForAssignmentModal">
      <BordModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <ModalContent
            excludeHeader
            customWidth="98rem"
            customHeight="64rem"
            toCloseModal={closeModalCallback}
            withoutHeaderCustomClass="withoutHeaderCustomClass"
            CustomModalComponent={
              <SelectToolsForAssignmentModalCustomComponent
                toolsSelectionCallback={toolsSelectionCallback}
                toolsAvailableToAssignAccordingToAppliedFilters={toolsAvailableToAssignAccordingToAppliedFilters}
                selectedToolsToAssign={selectedToolsToAssign}
                closeModalCallback={closeModalCallback}
                filtersData={filtersData}
                handleChangeExtraQueryData={handleChangeExtraQueryData}
                selectLocationTool={
                  selectedToolsToAssign && selectedToolsToAssign.length > 0 ? selectedToolsToAssign[0] : undefined
                }
                refreshLoading={refreshLoading}
                loadingFilters={loadingFilters}
              />
            }
          />
        }
      />
    </div>
  );
};

export default SelectToolsForAssignmentModal;
