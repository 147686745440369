import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateExistingSerialNumber } from '../../../../../services/uploadExternalTools.service';
import {
  ILocationOptions,
  IUploadCountries,
  IuploadedItemData,
  TproductItemCondition
} from '../../../../../types/uploadExternalTools.types';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { IsharedDataForToolReferenceItems } from '../Step2AddInformation/Step2AddInformation';
import NudosEmployeeSearchBar from '../../../../../components/NudosComponents/NudosEmployeeSearchBar/NudosEmployeeSearchBar';
import { Iemployee } from '../../../../../types/global';
import { IListDefaultLocationAddress, ILocationAddress } from '../../../../../types/account';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { servicesDefaultLocationAddress } from '../../../../../services/account';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import { BordButton, BordDropdown, BordTextInput } from '../../../../../components/BordDesignSystem';
import LocationToolsFlux from '../../../logisticServices/LocationToolsFlux';

import './ProductItemDataForm.scss';

interface IproductItemDataFormProps {
  handleUpdateThisItemData: (updatedItemData: IuploadedItemData) => void;
  previousItemData: IuploadedItemData;
  uncheckSharedDataForToolReferenceItems: () => void;
  sharedDataForToolReferenceItemsIsChecked: boolean;
  sharedData?: IsharedDataForToolReferenceItems;
  serialIsRepeatedMessage?: string;
  countriesData?: IUploadCountries[];
  listDefaultAddress?: IListDefaultLocationAddress;
  refreshAddress?: () => void;
  index: number;
  itemFormIdentifier: string;
}

const ProductItemDataForm = (props: IproductItemDataFormProps) => {
  const {
    handleUpdateThisItemData,
    previousItemData,
    sharedData,
    sharedDataForToolReferenceItemsIsChecked,
    uncheckSharedDataForToolReferenceItems,
    serialIsRepeatedMessage,
    countriesData,
    listDefaultAddress,
    refreshAddress,
    index,
    itemFormIdentifier
  } = props;
  const { t } = useTranslation();
  const [itemCondition, setItemCondition] = useState<TproductItemCondition>();
  const [itemData, setItemData] = useState<IuploadedItemData>();
  const [serialAlreadyExistsError, setSerialAlreadyExistsError] = useState('');
  const [validatedSerial, setValidatedSerial] = useState('');
  const [itemCountry, setItemCountry] = useState<IUploadCountries>();
  const [selectedEmployee, setSelectedEmployee] = useState<Iemployee>();
  const [selectLocation, setSelectLocation] = useState<ILocationOptions>();

  const [openEditOfficeAddress, setOpenEditOfficeAddress] = useState<boolean>(false);
  const [openEditOtherAddress, setOpenEditOtherAddress] = useState<boolean>(false);

  const [selectOtherAddress, setSelectOtherAddress] = useState<ILocationAddress>();
  const [assingDefaultOtherAddress, setAssingDefaultOtherAddress] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:';

  const locationToolsOption: ILocationOptions[] = [
    { optionId: 1, label: t(`${i18nLanguageKey}locationToolsOption:optionId1`) },
    { optionId: 2, label: t(`${i18nLanguageKey}locationToolsOption:optionId2`) },
    { optionId: 3, label: t(`${i18nLanguageKey}locationToolsOption:optionId3`) },
    { optionId: 4, label: t(`${i18nLanguageKey}locationToolsOption:optionId4`) }
  ];

  const handleSelectLocation = (
    selectLocation: ILocationOptions,
    closeDropdown?: (option: ILocationOptions) => void
  ) => {
    closeDropdown && closeDropdown(selectLocation);

    if (selectLocation.optionId === 1 && !generateDefaultOfficeAddress()) {
      setSelectedEmployee(undefined);
      setSelectOtherAddress(undefined);
      setOpenEditOfficeAddress(true);
      handleUpdateOptionIdLocation(selectLocation.optionId);
    } else {
      setSelectedEmployee(undefined);
      setSelectOtherAddress(undefined);
      handleUpdateOptionIdLocation(selectLocation.optionId);
      setSelectLocation(selectLocation);
    }
  };

  const formDropdownOptionsCallbackOtherAddress = (
    rawOptions: ILocationAddress[],
    closeDropDown?: (option: ILocationAddress) => void
  ) => {
    if (rawOptions.length === 0)
      return [
        <div className="noOtherAddress" key="no-other-address" onClick={() => setOpenEditOtherAddress(true)}>
          <div>
            <div className="noAddressText">
              No tienes direcciones
              <br /> creadas
            </div>
            <div className="buttonContainerNoAddress">
              <BordButton
                label="Agregar"
                modeButton="tertiary"
                customHeight="32"
                customFontSize="1.2rem"
                buttonIconTwo="plus"
              />
            </div>
          </div>
        </div>
      ];
    return rawOptions.map((selectLocation, i) => {
      return (
        <Fragment key={`toolCondition${i}${selectLocation?.id}`}>
          <div className="optionContainer">
            <div className="customLocationItems">
              <div
                className="label"
                onClick={() => {
                  handleUpdateOtherAddress(selectLocation);
                  setSelectOtherAddress(selectLocation);
                  closeDropDown && closeDropDown(selectLocation);
                }}
              >
                {selectLocation?.locationName}
              </div>
            </div>
          </div>
          {rawOptions.length === i + 1 && (
            <div className="buttonContainerList" onClick={() => setOpenEditOtherAddress(true)}>
              <BordButton
                label="Agregar"
                modeButton="tertiary"
                customHeight="32"
                customFontSize="1.2rem"
                buttonIconTwo="plus"
              />
            </div>
          )}
        </Fragment>
      );
    });
  };

  const formSelectedOptionUICallbackOtherAddress = (selectLocation: ILocationAddress) => {
    return <>{selectLocation?.locationName}</>;
  };

  const filterBySearchStringCallbackOtherAddress = (search: string, rawOptions: ILocationAddress[]) => {
    return rawOptions.filter(selectLocation =>
      selectLocation?.locationName?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  const formDropdownOptionsCallback = (
    rawOptions: ILocationOptions[],
    closeDropDown?: (option: ILocationOptions) => void
  ) => {
    return rawOptions.map((selectLocation, i) => {
      return (
        <div className="optionContainer" key={`toolCondition${i}${selectLocation?.optionId}`}>
          <div className="customLocationItems">
            <div className="label" onClick={() => handleSelectLocation(selectLocation, closeDropDown)}>
              {selectLocation?.label}
            </div>
            <div className="aditionalLabel">
              {selectLocation.optionId !== 4 && selectLocation.optionId !== 1 && (
                <>
                  <div
                    className="editLabel"
                    onClick={() => {
                      handleUpdateOptionIdLocation(selectLocation.optionId);
                      setSelectLocation(selectLocation);
                      setSelectedEmployee(undefined);
                      setSelectOtherAddress(undefined);
                    }}
                  >
                    {t(`${i18nLanguageKey}selectLabel`)}
                  </div>
                </>
              )}
              {selectLocation.optionId === 1 && (
                <>
                  {generateDefaultOfficeAddress() ? (
                    <div
                      className="editLabel"
                      onClick={() => {
                        setOpenEditOfficeAddress(true);
                        handleUpdateOptionIdLocation(selectLocation.optionId);
                        setSelectOtherAddress(undefined);
                        setSelectLocation(selectLocation);
                        setSelectedEmployee(undefined);
                      }}
                    >
                      {t(`${i18nLanguageKey}editLabel`)}
                    </div>
                  ) : (
                    <div
                      className="editLabel"
                      onClick={() => {
                        setOpenEditOfficeAddress(true);
                        handleUpdateOptionIdLocation(selectLocation.optionId);
                        setSelectOtherAddress(undefined);
                        setSelectLocation(selectLocation);
                        setSelectedEmployee(undefined);
                      }}
                    >
                      {t(`${i18nLanguageKey}newAddBlue`)}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (selectLocation: ILocationOptions) => {
    return <>{selectLocation?.label}</>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ILocationOptions[]) => {
    return rawOptions.filter(selectLocation =>
      selectLocation?.label?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  const handleUpdateSerial = (inputText: string) => {
    const updatedData = {
      serial: inputText || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || 0,
      otherAddressDetail: itemData?.otherAddressDetail || selectOtherAddress
    };
    setItemData(updatedData);
  };

  const refreshNewLocation = async (type?: 'office' | 'other') => {
    if (type === 'other' || type === 'office') {
      const allList = await servicesDefaultLocationAddress(Number(formatOrgData()?.organizationId));
      if (allList && allList.others && type === 'other') {
        const lastOtherAddress = allList.others[0];
        return lastOtherAddress;
      }
      if (allList && allList.offices && type === 'office') {
        const lastOfficeAddress = allList.offices[0];
        return lastOfficeAddress;
      }
    }
  };
  const handleUpdateOptionIdLocation = async (
    optionId: number,
    address?: ILocationAddress,
    type?: 'office' | 'other'
  ) => {
    const newOtherAddress = await refreshNewLocation(type);
    const updatedData = {
      serial: itemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemCountry || itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: optionId,
      otherAddressDetail:
        type === 'other' ? itemData?.otherAddressDetail || selectOtherAddress || address || newOtherAddress : undefined
    };
    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();
    setItemData(updatedData);
  };

  const handleUpdateOtherAddress = (address: ILocationAddress) => {
    const updatedData = {
      serial: itemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || '',
      warranty: itemData?.warranty || '',
      country: itemCountry || itemData?.country || undefined,
      assignedEmployee: itemData?.assignedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || 0,
      otherAddressDetail: address
    };

    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();

    setItemData(updatedData);
  };

  const handleUpdatedAssignedEmployee = () => {
    const assignedEmployeeCountryData = {
      id: selectedEmployee?.country?.id || 0,
      flag: selectedEmployee?.country?.flag || '',
      code: selectedEmployee?.country?.code || '',
      name: selectedEmployee?.country?.name || ''
    };
    if (selectedEmployee) setItemCountry(assignedEmployeeCountryData);
    const updatedData = {
      serial: itemData?.serial || previousItemData?.serial || '',
      productCondition: itemCondition || itemData?.productCondition || previousItemData?.productCondition || '',
      warranty: itemData?.warranty || previousItemData?.warranty || '',
      country: assignedEmployeeCountryData?.id
        ? assignedEmployeeCountryData
        : itemData?.country || previousItemData?.country,
      assignedEmployee: selectedEmployee,
      optionId: itemData?.optionId || selectLocation?.optionId || previousItemData?.optionId || 0,
      otherAddressDetail: itemData?.otherAddressDetail || selectOtherAddress || previousItemData.otherAddressDetail
    };
    sharedDataForToolReferenceItemsIsChecked && uncheckSharedDataForToolReferenceItems();

    setItemData(updatedData);
  };

  const validateExistingSerial = async (serial: string) => {
    if (!serial) return;
    try {
      const response = await validateExistingSerialNumber(serial);
      if (response) {
        setValidatedSerial(serial);
        setSerialAlreadyExistsError('Este serial ya existe en tu base de datos');
      }
    } catch (error) {
      displayErrorNotification();
    }
  };

  const handleSerialBlur = (inputText: string) => {
    setValidatedSerial('');
    setSerialAlreadyExistsError('');
    if (!inputText) return;
    validateExistingSerial(inputText);
  };

  const errorPersists = validatedSerial === itemData?.serial;
  const getSerialError = () => {
    if (serialIsRepeatedMessage) return serialIsRepeatedMessage;
    if (!errorPersists) return undefined;
    if (serialAlreadyExistsError) return serialAlreadyExistsError;
  };

  const generateDefaultOfficeAddress = () => {
    const filterAddress = listDefaultAddress?.offices?.find(element => element.country?.code === itemCountry?.code);
    if (filterAddress) {
      return filterAddress?.address;
    }
    return null;
  };

  const defaultOtherAddress = () => {
    const filterAddress = listDefaultAddress?.others?.find(element => element.country?.code === itemCountry?.code);
    if (filterAddress && assingDefaultOtherAddress) {
      setSelectOtherAddress(filterAddress);
      setAssingDefaultOtherAddress(false);
    }
    return null;
  };

  const existLocationId = (locationId: number) => {
    if (listDefaultAddress && listDefaultAddress?.others?.length > 0 && locationId) {
      const otherAddress = listDefaultAddress?.others.find(element => element?.id === locationId);
      if (otherAddress) {
        setSelectOtherAddress(otherAddress);
        handleUpdateOtherAddress(otherAddress);
        handleUpdateOptionIdLocation(2, otherAddress);
      }
    }
  };

  const updateList = async () => {
    if (refreshAddress) {
      await refreshAddress();
    }
  };
  useEffect(() => {
    previousItemData && setItemData(previousItemData);
    previousItemData?.assignedEmployee && setSelectedEmployee(previousItemData.assignedEmployee);
    if (previousItemData && previousItemData?.country) {
      setItemCountry(previousItemData.country);
    }
    if (previousItemData && previousItemData?.optionId) {
      const myLocation = locationToolsOption.find(element => previousItemData.optionId === element.optionId);
      if (myLocation) {
        setSelectLocation(myLocation);
      }
    }

    if (previousItemData && previousItemData?.otherAddressDetail) {
      setSelectOtherAddress(previousItemData?.otherAddressDetail);
    }
    if (previousItemData?.country) {
      setItemCountry(previousItemData?.country);
    }
    if (previousItemData?.productCondition) {
      const condition = previousItemData?.productCondition as TproductItemCondition;
      setItemCondition(condition);
    }
  }, []);

  useEffect(() => {
    if (selectedEmployee) return;
    const dataWithShareDateAndCondition = {
      serial: itemData?.serial || previousItemData?.serial || '',
      productCondition: sharedData?.condition || itemData?.productCondition || previousItemData?.productCondition || '',
      warranty: itemData?.warranty || previousItemData?.warranty || '',
      country: sharedData?.country || itemData?.country || previousItemData?.country || undefined,
      optionId: sharedData?.optionId || itemData?.optionId || previousItemData?.optionId || 0
    };
    if (sharedData?.country) {
      setItemCountry(sharedData?.country);
    }
    if (sharedData?.optionId) {
      const myLocation = locationToolsOption.find(element => sharedData.optionId === element.optionId);
      if (myLocation) {
        if (index !== 0) {
          setSelectedEmployee(undefined);
          setSelectOtherAddress(undefined);
        }

        setSelectLocation(myLocation);
      }
    }
    if (sharedData?.condition) {
      const condition = sharedData?.condition as TproductItemCondition;
      setItemCondition(condition);
    }
    setItemData(dataWithShareDateAndCondition);
  }, [sharedData]);

  useEffect(() => {
    itemData && handleUpdateThisItemData(itemData);
  }, [itemData]);

  useEffect(() => {
    handleUpdatedAssignedEmployee();
  }, [selectedEmployee]);

  useEffect(() => {
    defaultOtherAddress();
  }, [listDefaultAddress]);

  return (
    <div className="productItemDataForm">
      {itemCountry && itemCountry.code && (openEditOfficeAddress || openEditOtherAddress) && (
        <LocationToolsFlux
          listOfAllCountries={[itemCountry]}
          closeLocationModal={() => {
            setOpenEditOfficeAddress(false);
            setOpenEditOtherAddress(false);
          }}
          updateCardListData={(e, existId) => {
            if (existId) {
              // edit
              existLocationId(Number(existId));
              setAssingDefaultOtherAddress(true);
              setSelectLocation({ optionId: 2, label: t(`${i18nLanguageKey}productItemDataForm:optionId2`) });
            } else {
              updateList();
              if (e === 'officeEdit') {
                setSelectLocation({
                  optionId: 1,
                  label: t(`${i18nLanguageKey}productItemDataForm:optionId1`)
                });
                handleUpdateOptionIdLocation(1, undefined, 'office');
              }
              if (e === 'otherAddressEdit') {
                setAssingDefaultOtherAddress(true);
                setSelectLocation({
                  optionId: 2,
                  label: t(`${i18nLanguageKey}productItemDataForm:optionId2`)
                });
                handleUpdateOptionIdLocation(2, undefined, 'other');
              }
            }
          }}
          defaultCountryCode={itemCountry.code}
          officeDirect={openEditOfficeAddress}
          otherAddressDirect={openEditOtherAddress}
        />
      )}
      <BordTextInput
        label="Serial*"
        placeholder="Escribe el serial de la herramienta"
        isFilled={!!itemData?.serial}
        standardSize="w-280"
        setCurrentText={handleUpdateSerial}
        currentText={previousItemData.serial}
        handleBlur={handleSerialBlur}
        errorText={getSerialError()}
      />

      {countriesData && (
        <NudosCountryDropdown
          charactersLimit={6}
          componentSize="w-80"
          currentValue={itemCountry}
          countriesList={countriesData || []}
          customPlaceholder={t('nodi:bordPhoneInputPlaceholder')}
          updateCurrentValueCallback={item => {
            setItemCountry(item);
            setSelectLocation(undefined);
          }}
        />
      )}
      {itemCountry && (
        <>
          <BordDropdown
            includeMagnifierIcon={false}
            rawOptions={locationToolsOption}
            formDropdownOptionsCallback={formDropdownOptionsCallback}
            formSelectedOptionUICallback={formSelectedOptionUICallback}
            filterBySearchStringCallback={filterBySearchStringCallback}
            label={t(`${i18nLanguageKey}productItemDataForm:toolLocation:label`)}
            isFilled={!!selectLocation}
            currentValue={selectLocation}
            componentSize="w-180"
            customPlaceholder={
              selectLocation?.label || t(`${i18nLanguageKey}productItemDataForm:toolLocation:placeholder`)
            }
          />

          {selectLocation?.optionId === 1 && !!generateDefaultOfficeAddress() && (
            <div className="containerOfficeText">
              <div className="limitOfficeText">{generateDefaultOfficeAddress()}</div>
            </div>
          )}
          {selectLocation?.optionId === 2 && (
            <BordDropdown
              componentSize="w-180"
              rawOptions={
                listDefaultAddress?.others?.filter(
                  element => element?.country?.code === itemCountry?.code && element?.locationName
                ) || []
              }
              label="Selecciona la dirección"
              currentValue={selectOtherAddress}
              isFilled={!!selectOtherAddress}
              customPlaceholder="Busca una dire..."
              formDropdownOptionsCallback={formDropdownOptionsCallbackOtherAddress}
              formSelectedOptionUICallback={formSelectedOptionUICallbackOtherAddress}
              filterBySearchStringCallback={filterBySearchStringCallbackOtherAddress}
            />
          )}
          {selectLocation?.optionId === 3 && (
            <div className="containerEmployeesSearch">
              <NudosEmployeeSearchBar
                customWidth={180}
                customHeight={40}
                customClassName="mediumHeight"
                uniqueIdentifier={itemFormIdentifier}
                selectedEmployee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
                customPlaceholder="Busca el empleado"
                label="Selecciona el empleado"
                countriesFilter={itemCountry?.name ? [itemCountry?.name] : undefined}
                excludeEmployeesWithoutLocation
                originDataForRedirection={{
                  origin: '/nodi/upload-external-tools',
                  returnTextKey: 'externalToolsRedirectionText',
                  createFromSpecificCountry: itemCountry?.name,
                  uniqueEmployeeSearchbarIdentifier: itemFormIdentifier
                }}
              />
            </div>
          )}

          {selectLocation?.optionId === 4 && itemCountry && (
            <div className="containerOfficeText">{itemCountry?.name}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductItemDataForm;
