const RoundMali = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M21.5656 0.995188C19.8323 0.352063 17.9576 0 16.0004 0C14.0433 0 12.1686 0.352063 10.4353 0.995188L9.04395 16L10.4353 31.0048C12.1686 31.648 14.0433 32 16.0004 32C17.9576 32 19.8323 31.648 21.5656 31.0048L22.9569 16L21.5656 0.995188Z"
        />
        <path
          fill="#F93939"
          d="M32.0002 16.0001C32.0002 9.12074 27.6582 3.25599 21.5654 0.995361V31.005C27.6582 28.7442 32.0002 22.8796 32.0002 16.0001Z"
        />
        <path
          fill="#6DA544"
          d="M0 16.0001C0 22.8796 4.342 28.7442 10.4348 31.005V0.995361C4.342 3.25599 0 9.12074 0 16.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMali;
