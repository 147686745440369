const RoundGeorgia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2927 0 14.5962 0.0465 13.9131 0.135438V13.9129V13.913H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2927 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z"
        />
        <path
          fill="#F93939"
          d="M9.73937 7.65219V5.56519H7.65243V7.65219H5.56543V9.73912H7.65243V11.8261H9.73937V9.73912H11.8263V7.65219H9.73937ZM24.3477 7.65219V5.56519H22.2608V7.65219H20.1738V9.73912H22.2608V11.8261H24.3477V9.73912H26.4347V7.65219H24.3477ZM9.73937 22.2608V20.1738H7.65243V22.2608H5.56543V24.3477H7.65243V26.4347H9.73937V24.3477H11.8263V22.2608H9.73937ZM24.3477 22.2608V20.1738H22.2608V22.2608H20.1738V24.3477H22.2608V26.4347H24.3477V24.3477H26.4347V22.2608H24.3477Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGeorgia;
