import { FC } from 'react';
import { ILocationAddress } from '../../../../../types/account';
import { useTranslation } from 'react-i18next';
import { BordFlag, BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import { truncateText } from '../../../../../utils/truncateText';
import './countryCard.scss';

interface IListAddressCard {
  cardData: ILocationAddress;
  editAddress?: () => void;
}
const CountryCard: FC<IListAddressCard> = ({ cardData, editAddress }: IListAddressCard) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:countryCard:';

  return (
    <div id="countryCard">
      <div className="boxCountryCard">
        <div>
          <BordFlag variant="circle" country={cardData?.country?.code as TcountryCode} standardSize={32} />
        </div>
        <div className="defaultAddress">
          <div>
            <div className="title">{t(`${i18nLanguageKey}title`)}</div>
            {cardData?.address ? (
              <div className="addressContainer">
                <div className="addressText">
                  {truncateText(`${cardData?.address}${cardData?.city ? ', ' : ''}${cardData?.city}`, 48)}
                </div>
                <div
                  className="editText"
                  onClick={() => {
                    if (editAddress) {
                      editAddress();
                    }
                  }}
                >
                  <span>{t(`${i18nLanguageKey}blueEditText`)}</span>
                  <BordOneToneIcon variant="edit" standardSize={12} />
                </div>
              </div>
            ) : (
              <div className="mt-8">
                <div
                  className="addNewAddressButton"
                  onClick={() => {
                    if (editAddress) {
                      editAddress();
                    }
                  }}
                >
                  <span>Agregar dirección de este país</span>
                  <BordOneToneIcon variant="plus" standardSize={12} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountryCard;
