import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Iemployee, TnudosCare } from '../../../types/global';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import {
  IlocationAddressForLogistics,
  IwarehouseData,
  TlogisticsOriginOrDestinationData,
  IassignmentInfoForAssignmentOrUnassignment,
  IadditionalServiceDataForToolLogistics
} from '../../../types/requestLogisticsModule';
import UnassignmentStep1SelectDestination from './UnassignmentStep1SelectDestination/UnassignmentStep1SelectDestination';
import UnassignmentStep2ConfirmAddresses from './UnassignmentStep2ConfirmAddresses/UnassignmentStep2ConfirmAddresses';
import UnassignmentStep3Tools from './UnassignmentStep3Tools/UnassignmentStep3Tools';
import UnassignmentStep4Dates from './UnassignmentStep4Dates/UnassignmentStep4Dates';
import { UnassignmentModuleStepper } from './Components';
import { NudosBreadcrumbButton } from '../../../components/NudosComponents';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import {
  aproximateDays,
  getWarehouseLocation,
  isAutomatable,
  rejectedLogisticService
} from '../../../services/logisticsServicesModule.services';
import { IDetailsQuote, ItoolDataForRequiringLogistics, TstoragePlace } from '../../../types/assignationFluxes';
import { formatDateFromDateRange } from '../../../utils/formatDateRange';
import useRequireLogisticsSummaryData from '../../../state/useRequireLogisticsSummaryData';
import {
  assignOrUnassign,
  formatCheckQuoteAndBillingDataTools,
  formatDateRange,
  getEmployeeDataByUserId,
  getNudosWarehousesList,
  isDataComplete,
  updateLocationsData
} from '../utils/requestNewLogisticsUtilFunctions';
import { GenericLoader } from '../../Admin/dashboard/components';
import { BordButton } from '../../../components/BordDesignSystem';
import LogisticsQuoteAndBillingStep from '../LogisticsQuoteAndBillingStep/LogisticsQuoteAndBillingStep';
import { rangeHorus } from '../../../utils/calculateDateRangeHours';
import { segmentTrackEvent } from '../../../utils/segment';
import { formatOrgData } from '../../../utils/orgFormatData';
import { IIsAutomatable, IToolsRejected } from '../../../types/logisticsServicesModule.types';
import useLogisticsServiceBillingState from '../../../state/useLogisticsQuoteAndBilling';
import { CancelLogisticServiceModal } from '../LogisticsQuoteAndBillingStep/LogisticServicePricingQuote/Components';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { locationFormat } from '../../../utils/locationFormat';
import { IInvoicePaymentMethods } from '../../../types/cart';
import UnassignmentStep5DataVerification from './UnassignmentStep5DataVerification/UnassignmentStep5DataVerification';
import useSidebar from '../../../hooks/useSidebar';
import './UnassignmentModule.scss';

interface IOutRange {
  startDate: Date;
  endDate: Date;
}

const UnassignmentModule = () => {
  const { t } = useTranslation();
  const { userId: originUserId } = useParams<{ userId: string }>();
  const orgData = formatOrgData();
  const { search } = useLocation();
  const { push } = useHistory();
  const { moduleSidebarStyles } = useSidebar();
  const {
    invoiceAdditionalEmail,
    billingMethodAndCountry,
    logisticsServiceBillingData,
    setLogisticsServiceBillingData,
    cleanLsBillingSate
  } = useLogisticsServiceBillingState();
  const { stateSubscription } = useStateSubscriptions();

  const { setEstimatedDelivery, setStateEvenstUnassignment } = useRequireLogisticsSummaryData();
  const [showTimeOutModal, setShowTimeOutModal] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [listOfCountries, setListOfCountries] = useState<ICountryDetail[]>();
  const [listOfCountriesOpenForLogistics, setListOfCountriesOpenForLogistics] = useState<ICountryDetail[]>();
  const [nudosWarehousesList, setNudosWarehousesList] = useState<IwarehouseData[]>([]);
  const [selectedDestination, setSelectedDestination] = useState<TstoragePlace>();
  const [originData, setOriginData] = useState<Iemployee>();
  const [destinationData, setDestinationData] = useState<TlogisticsOriginOrDestinationData>();
  const [toolsToUnassignData, setToolsToUnassignData] = useState<ItoolDataForRequiringLogistics[]>([]);
  const [collectionDetailsText, setCollectionDetailsText] = useState<string>();
  const [dateRange, setDateRange] = useState<IOutRange>();
  const [aproximateDay, setAproximateDay] = useState<number>(0);
  const [rangeDays, setRangeDays] = useState<string>('');
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingWarehousesList, setLoadingWarehousesList] = useState(true);
  const [loadingOrigin, setLoadingOrigin] = useState<boolean>(false);
  const [loadingDestination, setLoadingDestination] = useState<boolean>(false);
  const [existDateRange, setExistDateRange] = useState<boolean>(false);
  const [loadingHeaderSkeleton, setLoadingHeaderSkeleton] = useState<boolean>(true);
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false);
  const [errorQuote, setErrorQuote] = useState<boolean>(false);
  const [detailsQuote, setDetailsQuote] = useState<IDetailsQuote>();
  const [loadingRejected, setLoadingRejected] = useState<boolean>(false);
  const [rejectComments, setRejectComments] = useState<string>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [loaderWarehouse, setLoaderWareHouse] = useState<boolean>(false);
  const [showCanQuoteBeAutomated, setShowCanQuoteBeAutomated] = useState<boolean>(false);
  const [loadingShowBillingData, setLoadingShowBillingData] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IInvoicePaymentMethods>();

  const { levelSubscription, nudosCare } = stateSubscription;
  const eventsPlatform = levelSubscription === 'Lite' ? 'Prime' : 'Free';
  const searchParams = new URLSearchParams(search);
  const navigationOrigin = searchParams.get('origin');
  const navigationOriginId = searchParams.get('originId');
  const selectedToolsWhenFluxStartedJSON = searchParams?.get('tools');
  const predefinedDestination = searchParams?.get('destination');
  const selectedToolsWhenFluxStarted: number[] = selectedToolsWhenFluxStartedJSON
    ? JSON.parse(selectedToolsWhenFluxStartedJSON)
    : [];

  const originIsCreate = searchParams.get('from') === 'create';
  const { assignedTools } = originData || {};
  const selectedToolsWhenFluxStartedData = assignedTools?.filter(tool =>
    selectedToolsWhenFluxStarted.includes(+tool?.productId)
  );
  const multipleTools = selectedToolsWhenFluxStarted.length > 1;
  const warrantyIsPossibleForSelectedTools =
    !!selectedToolsWhenFluxStartedData &&
    selectedToolsWhenFluxStartedData.every(
      tool => tool?.referenceModel === 'CatalogProduct' || (tool?.nudosCareName && tool.nudosCareName !== 'Free')
    );

  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics: boolean = requireLogisticsJSON ? JSON.parse(requireLogisticsJSON) : false;
  const noLogistcLastStep = !requireLogistics && step === 3;
  const baseClassname = noLogistcLastStep ? 'unassignmentModuleNoSl' : 'unassignmentModule';
  const moduleClassnames = `${baseClassname} ${moduleSidebarStyles}`;

  const destinationAsLocation = destinationData as IlocationAddressForLogistics;
  const destinationDataLocationId = destinationData?.locationId || destinationAsLocation?.id || undefined;
  const destinationCountryData = destinationAsLocation?.countryData || destinationData?.country;
  const isLocalAssignation = originData?.country?.code === destinationCountryData?.code;
  const originIsEmployee = navigationOrigin === 'employee-details';
  const destinationAsUser = destinationData as Iemployee;
  const incompleteDestinationUser = selectedDestination === 'user' && !destinationAsUser?.address?.address;
  const incompleteDestinationDataForLogistics =
    requireLogistics && (!destinationDataLocationId || incompleteDestinationUser);
  const predefinedDestinationOmmitsStep1 = ['warranty', 'diagnostic'].includes(predefinedDestination || '');
  const showQuoteAndBillingData = showCanQuoteBeAutomated && requireLogistics;
  const validationToDisplaySecondBillingButton = showQuoteAndBillingData && step === 4;

  const getManualLogisticButtonsStyle = () => {
    if ((!showCanQuoteBeAutomated && requireLogistics && step === 4) || (!requireLogistics && step === 2)) {
      return {
        unasssigmentFloatCenterButton: 'centerOneButtonTools',
        buttonPosition: 'centerButton'
      };
    }
    return { unasssigmentFloatCenterButton: '', buttonPosition: '' };
  };

  const originUrl = `/nodi/request-logistics/unassignment/${originUserId}?tools=[${selectedToolsWhenFluxStarted}]&logistics=${requireLogisticsJSON}&origin=${navigationOrigin}&originId=${navigationOriginId}`;
  const i18nLanguageKey = 'services:unassignmentModule';
  const updateSelectedDestinationCallback = async (option: TstoragePlace) => {
    setDestinationData(undefined);
    setSelectedDestination(option);
  };

  const updateToolsToUnassignDataCallback = (newToolsData: ItoolDataForRequiringLogistics[]) =>
    setToolsToUnassignData(newToolsData);

  const updateCollectionDetailsCallback = (newValue?: string) => setCollectionDetailsText(newValue);

  const updateOriginDataCallback = (originData: TlogisticsOriginOrDestinationData) => {
    const originAsEmployee = originData as Iemployee;
    if (!originAsEmployee?.userId) return;
    setOriginData(originAsEmployee);
  };
  const updateDestinationDataCallback = (destinationData: TlogisticsOriginOrDestinationData) =>
    setDestinationData(destinationData);

  const getAproximateDay = async (countryCode: string) => {
    try {
      const dataDay = await aproximateDays(countryCode);
      setAproximateDay(dataDay.collectGap || 0);
      setRangeDays(`${dataDay.deliverySince}-${dataDay.deliveryUntil}`);
    } catch {
      displayErrorNotification();
      setAproximateDay(3);
    }
  };

  const advanceStep = () => setStep(step + 1);
  const returnStep = () => {
    setErrorQuote(false);
    setStep(step - 1);
  };

  const destinationContinue = () => {
    segmentTrackEvent({
      nodiLogisticServicesDestinationClick: {
        DestinationCountry: destinationData?.country?.name || '',
        LogisticServiceDestination: locationFormat(selectedDestination || '')
      }
    });
    advanceStep();
  };

  const checkShowQuoteAndBillingData = async () => {
    setLoadingShowBillingData(true);

    try {
      const body: IIsAutomatable = {
        sameCountryRelocation: isLocalAssignation,
        origin: 'user',
        destination: selectedDestination,
        tools: formatCheckQuoteAndBillingDataTools(toolsToUnassignData || [])
      };
      const checkIsAutomatable = await isAutomatable(body);
      if (checkIsAutomatable) {
        setShowCanQuoteBeAutomated(checkIsAutomatable?.canQuoteBeAutomated);
      }
      setLoadingShowBillingData(false);
      advanceStep();
    } catch (error) {
      displayErrorNotification();
      setLoadingShowBillingData(false);
    }
  };

  const confirmToolsContinue = async () => {
    const toolsCareName: { productId: number; nudosCareName: string }[] = [];
    if (toolsToUnassignData && toolsToUnassignData.length > 0) {
      for (let i = 0; i < toolsToUnassignData.length; i++) {
        const itemTool = toolsToUnassignData[i];
        const objItem = {
          productId: Number(itemTool?.productId),
          nudosCareName: itemTool?.nudosCareName || ''
        };
        if (objItem.productId) {
          toolsCareName.push(objItem);
        }
      }
    }

    segmentTrackEvent({ nodiLogisticServicesToolQuote: { NudosCare: toolsCareName.length > 0 } });
    await checkShowQuoteAndBillingData();
  };

  const datesContinue = async () => {
    segmentTrackEvent({ nodiLogisticServicesLocalDateClick: { Comment: !!collectionDetailsText } });
    advanceStep();
  };

  const backToOriginAction = () => {
    switch (navigationOrigin) {
      case 'employee-details':
        return push(navigationOriginId ? `/nodi/employees/details/${navigationOriginId}` : '/nodi/employees');
      case 'inventory':
        return push('/nodi/tools');
      case 'tool-details':
        return push(navigationOriginId ? `/nodi/details/${navigationOriginId}` : '/nodi/tools');
      default:
        return push('/nodi/tools');
    }
  };

  const getAlertWithRedirectButton = () => {
    switch (navigationOrigin) {
      case 'tool-details':
      case 'inventory':
        {
          displaySuccessNotification({
            customJSXMessage: <>{t(`${i18nLanguageKey}:getAlertWithRedirectButton:toolsWereSuccessfullyUnassigned`)}</>,
            button1Text: t(`${i18nLanguageKey}:getAlertWithRedirectButton:seeEmployee`),
            button1Action: () => push(`/nodi/employees/details/${originUserId}`)
          });
        }
        break;
      case 'employee-details':
        {
          displaySuccessNotification({
            customJSXMessage: <>{t(`${i18nLanguageKey}:getAlertWithRedirectButton:toolsWereSuccessfullyUnassigned`)}</>,
            button1Text: multipleTools
              ? t(`${i18nLanguageKey}:getAlertWithRedirectButton:seeDevices`)
              : t(`${i18nLanguageKey}:getAlertWithRedirectButton:seeDevice`),
            button1Action: () =>
              push(multipleTools ? '/nodi/tools' : `/nodi/details/${selectedToolsWhenFluxStarted[0]}`)
          });
        }
        break;
      default:
        break;
    }
  };

  const backToOriginText = () => {
    switch (navigationOrigin) {
      case 'employee-details':
        return t(`${i18nLanguageKey}:steps:navigationOrigin:employeeDetails`);
      case 'inventory':
        return t(`${i18nLanguageKey}:steps:navigationOrigin:inventory`);
      case 'tool-details':
        return t(`${i18nLanguageKey}:steps:navigationOrigin:toolDetails`);
      default:
        return t(`${i18nLanguageKey}:steps:goBack`);
    }
  };

  const getReturnText = () => {
    switch (step) {
      case 0:
        return backToOriginText();
      case 1:
        return t(`${i18nLanguageKey}:steps:destination`);
      case 2:
        if (!requireLogistics) return t(`${i18nLanguageKey}:steps:devices`);
        return t(`${i18nLanguageKey}:steps:address`);
      case 3:
        return t(`${i18nLanguageKey}:steps:devices`);
      case 4:
        return t(`${i18nLanguageKey}:steps:dates`);
      default:
        return t(`${i18nLanguageKey}:steps:goBack`);
    }
  };
  const returnAction = step === 0 ? backToOriginAction : returnStep;

  const handleCheckRange = (dateRange: IOutRange) => {
    if (dateRange) setDateRange(dateRange);
  };

  const getDestinationDeliveryData = () => {
    switch (selectedDestination) {
      case 'user':
        return {
          receiverName: `${destinationAsUser?.firstName || ''} ${destinationAsUser?.lastName || ''}`,
          receiverPersonalId: destinationAsUser?.personalId,
          receiverCountryIdPhone: destinationAsUser?.phoneData?.countryId,
          receiverPhone: destinationAsUser?.phoneData?.phone
        };
      case 'office':
        return {
          receiverName: destinationAsLocation?.receiverInformation?.receiverName,
          receiverPersonalId: destinationAsLocation?.receiverInformation?.receiverIdDocument,
          receiverCountryIdPhone: destinationAsLocation?.receiverInformation?.receiverPhoneCountry?.id,
          receiverPhone: destinationAsLocation?.receiverInformation?.receiverPhone
        };
      default:
        return {};
    }
  };

  const handleSuccessfulUnassignment = (logisticServiceId?: number | string) => {
    setLogisticsServiceBillingData(undefined);
    const stateEvent = {
      type: 'unassignment',
      Origin_country: originData?.country?.name,
      Logistic_Service_Origin: 'Employee',
      Destination_country: destinationData?.country?.name,
      Logistic_Service_Destination: selectedDestination,
      destinationCountry: destinationData?.country?.name || '',
      logisticServiceDestination: selectedDestination || '',
      logisticServiceOrigin: 'Employee',
      nudosCare: nudosCare,
      originCountry: originData?.country?.name || '',
      typeOfPlatform: eventsPlatform
    };
    setStateEvenstUnassignment(stateEvent);
    if (requireLogistics) {
      setEstimatedDelivery(`${rangeDays} días hábiles`);
      return push(
        `/nodi/request-logistics/success/${logisticServiceId}?origin=${navigationOrigin}&oldUserId=${originUserId}`
      );
    }
    backToOriginAction();
    return getAlertWithRedirectButton();
  };

  const handleUnssignment = () => {
    if (!originData?.locationId || !destinationCountryData?.id || !selectedDestination) return;
    if (incompleteDestinationDataForLogistics || !originData?.country?.id) return;
    const originAndDestinationData = {
      originLocationId: originData?.locationId,
      originCountryId: originData?.country?.id,
      destinationCountryId: destinationCountryData?.id,
      destinationLocationId: destinationDataLocationId,
      origin: 'user',
      destination: selectedDestination
    };
    const products =
      toolsToUnassignData?.reduce<number[]>((prev, tool) => {
        !!tool?.productId && prev.push(+tool?.productId);
        return prev;
      }, []) || [];
    let assignmentInfo: IassignmentInfoForAssignmentOrUnassignment | undefined;
    if (selectedDestination === 'user') {
      if (!destinationAsUser?.firstName || !destinationAsUser?.lastName) return;
      if (!destinationAsUser?.country?.id) return;
      assignmentInfo = {
        userId: destinationAsUser?.userId,
        firstName: destinationAsUser?.firstName,
        lastName: destinationAsUser?.lastName,
        products,
        address: destinationAsUser?.address?.address || undefined,
        countryId: destinationAsUser?.country?.id,
        destinationLocationId: destinationAsUser?.locationId || undefined
      };
    }
    if (selectedDestination !== 'user') {
      assignmentInfo = {
        destinationLocationId: destinationDataLocationId,
        products
      };
    }
    const collectionInfo = {
      chosenCollectionDate: formatDateFromDateRange(dateRange, originData?.country?.timeZone),
      collectionComment: collectionDetailsText,
      delivererName: `${originData?.firstName || ''} ${originData?.lastName || ''}`,
      delivererPersonalId: originData?.personalId,
      delivererCountryIdPhone: originData?.phoneData?.countryId,
      delivererPhone: originData?.phoneData?.phone
    };
    const deliveryInfo = getDestinationDeliveryData();
    segmentTrackEvent({ generalOffCompleted: { OffCompleted: true } });
    assignOrUnassign(
      false,
      requireLogistics,
      false,
      originAndDestinationData,
      logisticsServiceBillingData || null,
      billingMethodAndCountry?.billingCountryCode,
      toolsToUnassignData,
      assignmentInfo,
      collectionInfo,
      deliveryInfo,
      undefined,
      invoiceAdditionalEmail,
      selectedPaymentMethod?.name,
      setLoading,
      handleSuccessfulUnassignment
    );
  };

  const loadingLocations = (loading: boolean) => {
    setLoadingOrigin(loading);
    setLoadingDestination(loading);
  };

  const isValidRange = (status: boolean) => setExistDateRange(status);

  const checkIfCommentIsRequired = (tools: ItoolDataForRequiringLogistics[]) => {
    const requireComment = tools.filter(element => !!element?.newLogisticAdditionalServices?.length);
    if (requireComment && requireComment.length > 0) {
      const individualTools = requireComment.find(element =>
        element.newLogisticAdditionalServices?.find(
          element => (element.id === 2 || element.id == 3) && !element?.comments
        )
      );
      if (individualTools) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const resetSteps = () => {
    setShowTimeOutModal(false);
    setStep(0);
  };

  const checkDate = () => {
    const localDate = JSON.parse(localStorage.getItem('actualDateLogisticsService') || '');
    const saveDate: Date = new Date(localDate?.date || '');
    const hours = rangeHorus(saveDate, new Date());
    if (hours >= 24) {
      segmentTrackEvent({
        nodiLogisticServices6TimeOutView: {
          DestinationCountry: destinationData?.country?.name || '',
          Fee: detailsQuote?.fee || 0,
          LogisticServiceDestination: locationFormat(selectedDestination || ''),
          LogisticServiceOrigin: 'Employee',
          NudosCare: !!nudosCare,
          OriginCountry: originData?.country?.name || '',
          TypeOfPlatform: eventsPlatform
        }
      });
      setShowTimeOutModal(true);
    } else {
      segmentTrackEvent({
        nodiLogisticServicesAproveQuotationClick: {
          DestinationCountry: destinationData?.country?.name || '',
          Fee: detailsQuote?.fee || 0,
          LogisticServiceDestination: locationFormat(selectedDestination || ''),
          LogisticServiceOrigin: 'Employee',
          LogisticServiceTotalUsd: detailsQuote?.total || 0,
          NudosCare: nudosCare,
          OriginCountry: originData?.country?.name || '',
          TypeOfPlatform: eventsPlatform
        }
      });
      handleUnssignment();
    }
  };

  const getQuouteDetails = (details: IDetailsQuote) => setDetailsQuote(details);

  const eventRefusedService = async () => {
    segmentTrackEvent({
      nodiLogisticServicesRejectQuotationClick: {
        DestinationCountry: destinationData?.country?.name || '',
        Fee: detailsQuote?.fee || 0,
        LogisticServiceDestination: locationFormat(selectedDestination || ''),
        LogisticServiceOrigin: 'Employee',
        LogisticServiceTotalUsd: detailsQuote?.total || 0,
        NudosCare: nudosCare,
        OriginCountry: originData?.country?.name || '',
        TypeOfPlatform: eventsPlatform
      }
    });
  };

  const redirectRefusedService = async () => {
    const collectionName = `${originData?.firstName} ${originData?.lastName}`;
    const deliveryName = `${destinationAsUser?.firstName} ${destinationAsUser?.lastName}`;
    const originLocationData = {
      place: 'user',
      userName: collectionName
    };
    const destinationLocationData = {
      place: (destinationAsUser?.userId && 'user') || destinationAsLocation?.place || selectedDestination,
      countryName: destinationData?.country?.name,
      locationName: destinationAsLocation?.locationName,
      userName: deliveryName
    };
    const logisticServiceData = {
      products: toolsToUnassignData,
      estimatedDelivery: rangeDays,
      dateRange: selectedDatesRange,
      originLocationData: originLocationData,
      destinationLocationData: destinationLocationData
    };
    const redirectionDataObject = {
      pathname: `/nodi/request-logistics/rejected`,
      state: {
        originUrl: originUrl,
        logisticServiceData: logisticServiceData,
        flux: 'unassignment'
      }
    };
    push(redirectionDataObject);
  };

  const onlyNames = (additional: IadditionalServiceDataForToolLogistics[]) => {
    const names: string[] = [];
    const originalList = additional;
    for (let i = 0; i < originalList.length; i++) {
      const indexItem = originalList[i];
      if (indexItem.id) {
        names.push(
          indexItem.id === 1
            ? 'Wiping'
            : indexItem.id === 2
            ? 'configuración básica'
            : indexItem.id === 3
            ? 'configuración avanzada'
            : ''
        );
      }
    }
    return names;
  };

  const formatRefuseTools = (tools: ItoolDataForRequiringLogistics[]) => {
    const toolsList: IToolsRejected[] = [];
    const originalList = tools;
    for (let i = 0; i < originalList.length; i++) {
      const indexItem = originalList[i];
      const newItem: IToolsRejected = {
        sku: indexItem?.sku || '',
        productId: Number(indexItem?.productId),
        productName: indexItem?.productName || '',
        productBrand: indexItem?.productBrand || '',
        serial: indexItem?.serial || '',
        nudosCareName: indexItem?.nudosCareName as TnudosCare,
        logisticAdditionalServices: onlyNames(indexItem?.newLogisticAdditionalServices || [])
      };

      if (newItem?.productId) {
        toolsList.push(newItem);
      }
    }
    return toolsList;
  };

  const refuseLogisticService = async (sendComment?: boolean) => {
    setLoadingRejected(true);
    const destinationTool = destinationData as Iemployee;
    await eventRefusedService();
    const rejectedBody = {
      organizationId: Number(orgData?.organizationId),
      userId: Number(orgData?.userId),
      origin: {
        country: originData?.country?.name || '',
        city: originData?.city || '',
        place: 'user'
      },
      destination: {
        country: destinationTool?.country?.name || '',
        city: destinationTool?.city || '',
        place: selectedDestination as TstoragePlace
      },
      rejectComments: sendComment ? rejectComments || '' : '',
      rejectedAmount: Number(detailsQuote?.total),
      tools: formatRefuseTools(toolsToUnassignData)
    };
    try {
      await rejectedLogisticService(rejectedBody);
      await redirectRefusedService();
      sendComment &&
        displaySuccessNotification({
          customJSXMessage: (
            <>Gracias por tus comentarios, los tomaremos en cuenta para mejorar nuestros servicios para ti.</>
          )
        });
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingRejected(false);
    }
  };

  const selectedDatesRange = dateRange && formatDateRange(dateRange);

  const getSecondButtonOnlyShownForBillingStep = () => {
    if (!validationToDisplaySecondBillingButton) return <></>;
    return (
      <div
        className="billingButtonContainer"
        onClick={() => {
          setShowCancelModal(true);
          segmentTrackEvent({
            nodiLogisticServices512ModalResumeRejectedView: {
              DestinationCountry: destinationData?.country?.name || '',
              LogisticServiceDestination: locationFormat(selectedDestination || ''),
              LogisticServiceOrigin: 'Employee',
              TypeOfPlatform: eventsPlatform
            }
          });
        }}
      >
        <BordButton
          isLoading={loadingRejected}
          label="Rechazar"
          modeButton="primary"
          customWidth="w-280"
          bordButtonStyle="outlined"
          disabled={loadingQuote || errorQuote || loading}
        />
      </div>
    );
  };

  const handleLoadingQuote = (loading: boolean) => setLoadingQuote(loading);

  const handleErrorQuote = (therIsAnError: boolean) => setErrorQuote(therIsAnError);

  const unassignmentModuleSteps = [
    {
      buttonData: {
        text: t(`${i18nLanguageKey}:steps:next`),
        callback: destinationContinue
      },
      onlyWithLogistics: false,
      component: (
        <UnassignmentStep1SelectDestination
          originUrl={originUrl}
          originData={originData}
          destinationData={destinationData}
          selectedDestination={selectedDestination}
          updateSelectedDestinationCallback={updateSelectedDestinationCallback}
          updateDestinationDataCallback={updateDestinationDataCallback}
          nudosWarehousesList={nudosWarehousesList}
          requireLogistics={requireLogistics}
          loadingWarehousesList={loadingWarehousesList}
          showSkeletonLoader={loadingHeaderSkeleton}
          filterCountries={requireLogistics ? listOfCountriesOpenForLogistics : undefined}
          warrantyIsPossibleForSelectedTools={warrantyIsPossibleForSelectedTools}
        />
      )
    },
    {
      buttonData: {
        text: t(`${i18nLanguageKey}.steps.confirmLocation`),
        callback: () =>
          updateLocationsData(
            'user',
            originData,
            setOriginData as (newValue: TlogisticsOriginOrDestinationData) => void,
            selectedDestination,
            destinationData,
            setDestinationData,
            loadingLocations,
            advanceStep
          )
      },
      onlyWithLogistics: true,
      component: (
        <UnassignmentStep2ConfirmAddresses
          originData={originData}
          destinationData={destinationData}
          selectedDestination={selectedDestination}
          listOfCountries={listOfCountries}
          updateOriginDataCallback={updateOriginDataCallback}
          updateDestinationDataCallback={updateDestinationDataCallback}
        />
      )
    },
    {
      buttonData: {
        text: requireLogistics ? t(`${i18nLanguageKey}:steps:confirmTools`) : t(`${i18nLanguageKey}:steps:next`),
        callback: confirmToolsContinue
      },
      onlyWithLogistics: false,
      component: (
        <UnassignmentStep3Tools
          originData={originData}
          selectedToolsWhenFluxStarted={selectedToolsWhenFluxStarted}
          selectedDestination={selectedDestination}
          toolsToUnassignData={toolsToUnassignData}
          updateToolsToUnassignDataCallback={updateToolsToUnassignDataCallback}
          userCanSelectOtherTools={originIsEmployee && selectedDestination !== 'warranty'}
          destinationData={destinationData}
        />
      )
    },
    {
      buttonData: {
        text: t(`${i18nLanguageKey}.steps.next`),
        callback: datesContinue
      },
      onlyWithLogistics: true,
      component: (
        <UnassignmentStep4Dates
          updateCollectionDetailsCallback={updateCollectionDetailsCallback}
          collectionDetailsText={collectionDetailsText}
          handleCheckRange={handleCheckRange}
          aproximateDay={aproximateDay}
          rangeDays={rangeDays}
          defaultDateRange={dateRange}
          type={isLocalAssignation ? 'local' : 'international'}
          isValidRange={isValidRange}
        />
      )
    },
    {
      buttonData: {
        text: showQuoteAndBillingData
          ? 'Aprobar servicio'
          : requireLogistics
          ? 'Cotizar el servicio logístico'
          : 'Confirmar traslado',
        callback: showQuoteAndBillingData ? checkDate : handleUnssignment,
        loading: loading,
        isDisabled: requireLogistics && !selectedPaymentMethod
      },
      onlyWithLogistics: false,
      component: requireLogistics ? (
        <LogisticsQuoteAndBillingStep
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          originData={originData}
          destinationData={destinationData}
          logisticsType="unassignment"
          resetSteps={resetSteps}
          showTimeOutModal={showTimeOutModal}
          toolsData={toolsToUnassignData}
          place={selectedDestination}
          getQuouteDetails={getQuouteDetails}
          handleLoadingQuote={handleLoadingQuote}
          handleErrorQuote={handleErrorQuote}
          detailsQuote={detailsQuote}
          showQuoteAndBillingData={showQuoteAndBillingData}
        />
      ) : (
        <UnassignmentStep5DataVerification
          originData={originData}
          destinationData={destinationData}
          dateRange={dateRange}
          rangeDays={rangeDays}
          toolsToUnassignData={toolsToUnassignData}
          selectedDestination={selectedDestination}
          type={isLocalAssignation ? 'locale' : 'international'}
        />
      )
    }
  ];

  const unassignmentModulesAcordingToLogistics = requireLogistics
    ? unassignmentModuleSteps
    : unassignmentModuleSteps?.filter(step => !step?.onlyWithLogistics);

  const handleDisabledButton = () => {
    if (step === 0) {
      if (selectedDestination === 'user' && !!destinationData) return false;
      if (selectedDestination === 'office' && !!destinationData) return false;
      if (selectedDestination === 'nudos' && destinationData) return false;
      if (selectedDestination === 'warranty' && destinationData) return false;
      if (selectedDestination === 'diagnostic' && destinationData) return false;
    }
    if (step === 1) {
      if (!requireLogistics) return false;
      if (
        isDataComplete(selectedDestination || '', destinationData, listOfCountriesOpenForLogistics) &&
        isDataComplete('user', originData, listOfCountriesOpenForLogistics)
      )
        return false;
    }
    if (step === 2) {
      if (!!toolsToUnassignData && !!toolsToUnassignData.length) {
        const disabledForComment = checkIfCommentIsRequired(toolsToUnassignData);
        if (disabledForComment) {
          return true;
        }
        return false;
      }
      return true;
    }
    if (step === 3) {
      if (dateRange && existDateRange) {
        return false;
      }
    }
    if (step === 4) {
      if (requireLogistics && !selectedPaymentMethod) return true;
      if (showQuoteAndBillingData) return !logisticsServiceBillingData;
      return false;
    }

    return true;
  };

  const loadingDisabled = () => {
    if (
      loading ||
      loadingOrigin ||
      loadingDestination ||
      loadingRejected ||
      loadingQuote ||
      errorQuote ||
      loaderWarehouse
    ) {
      return true;
    }
    return false;
  };

  const assignRangeDays = async () => {
    const locale = originData?.country?.code === destinationCountryData?.code;
    if (originData?.country?.id && destinationCountryData?.id) {
      await getAproximateDay(locale ? originData?.country?.code : '1111');
    }
  };

  const warehouseLocation = async (countryId: number) => {
    if ((selectedDestination === 'warranty' || selectedDestination === 'diagnostic') && originData) {
      try {
        setLoaderWareHouse(true);
        const warehouseData = await getWarehouseLocation(Number(countryId), selectedDestination);
        if (warehouseData?.id) {
          setDestinationData({ ...warehouseData });
        }
        setLoaderWareHouse(false);
      } catch (error) {
        displayErrorNotification();
        setLoaderWareHouse(false);
      }
    }
  };

  useEffect(() => {
    if (originData) warehouseLocation(Number(originData?.country?.id));
  }, [selectedDestination, originData?.id]);

  useEffect(() => {
    getEmployeeDataByUserId(originUserId, setOriginData, setLoadingHeaderSkeleton);
  }, [originUserId]);

  useEffect(() => {
    setToolsToUnassignData([]);
  }, [selectedDestination]);

  useEffect(() => {
    if (destinationData && Object.values(destinationData).some(value => !!value)) return;
    if (!predefinedDestination || predefinedDestination === 'unassignment') return; // unassignment is the destination given in the redirection from the tool details for the flow of unassignment a tool, without a clear destination for it
    if (!nudosWarehousesList || !nudosWarehousesList.length || !originData) return;
    updateSelectedDestinationCallback(predefinedDestination);
  }, [predefinedDestination, nudosWarehousesList, originData]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setListOfCountries);
    getListOfCountriesByOfferedService(setListOfCountriesOpenForLogistics, 'logisticService');
    getNudosWarehousesList(setNudosWarehousesList, setLoadingWarehousesList);
    predefinedDestinationOmmitsStep1 && advanceStep();
    return () => cleanLsBillingSate();
  }, []);

  useEffect(() => {
    assignRangeDays();
  }, [originData?.country?.code, destinationCountryData?.code]);

  useEffect(() => {
    if (!originData) return;
    initialLoading && setInitialLoading(false);
  }, [originData]);

  useEffect(() => {
    if (originIsCreate) setSelectedDestination('user');
  }, [originIsCreate]);

  if (initialLoading)
    return (
      <div className={`${moduleClassnames} initialLoading`}>
        <GenericLoader />
      </div>
    );

  return (
    <div className={moduleClassnames}>
      {showCancelModal && (
        <CancelLogisticServiceModal
          updateValue={e => setRejectComments(e)}
          skipCancellation={() => refuseLogisticService(false)}
          continueCancellation={() => refuseLogisticService(true)}
          defaultValue={rejectComments}
          loading={loadingRejected}
          total={detailsQuote?.total}
        />
      )}
      <NudosBreadcrumbButton returnText={getReturnText()} returnAction={returnAction} />
      <UnassignmentModuleStepper
        currentStep={step}
        requireLogistics={requireLogistics}
        includeQuoteAndBillingStep={showQuoteAndBillingData}
      />
      {unassignmentModulesAcordingToLogistics[step]?.component}
      <div className={`${moduleSidebarStyles} fixedManagementModule`}>
        <div className={`floatContainerButtons ${getManualLogisticButtonsStyle()?.unasssigmentFloatCenterButton}`}>
          {getSecondButtonOnlyShownForBillingStep()}
          <BordButton
            customClassName={`buttonPosition ${getManualLogisticButtonsStyle()?.buttonPosition}`}
            disabled={handleDisabledButton() || loadingDisabled()}
            customWidth="w-380"
            isLoading={loading || loadingOrigin || loadingDestination || loadingShowBillingData || loaderWarehouse}
            label={unassignmentModulesAcordingToLogistics[step]?.buttonData?.text}
            onClick={unassignmentModulesAcordingToLogistics[step]?.buttonData?.callback}
          />
        </div>
      </div>
    </div>
  );
};

export default UnassignmentModule;
