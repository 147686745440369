const SquareMalta = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#AF010D" rx="2" />
        <path fill="white" fillRule="evenodd" d="M0 0H11.5238V16H0V0Z" clipRule="evenodd" />
        <path
          fill="#CBCBCB"
          fillRule="evenodd"
          d="M3.14332 3.19997H2.0957V4.26663H3.14332V5.3333H4.19094V4.26663H5.23856V3.19997H4.19094V2.1333H3.14332V3.19997Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMalta;
