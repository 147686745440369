import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IbillingData } from '../../../types/checkout';
import NudosBillingDataFormModalContent from './NudosBillingDataFormModalContent';
import { ICountryDetail } from '../../../types/countries';
import BordModal from '../../BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../BordDesignSystem/BordModal/components/ModalContent';
import './NudosBillingDataFormModal.scss';

const NudosBillingDataFormModal = ({
  initialBillingData,
  closeModalCallback,
  submitModalCallback,
  showSkeletonLoader,
  customZIndex,
  availableCountries
}: {
  initialBillingData?: IbillingData;
  closeModalCallback: () => void;
  customZIndex?: number;
  showSkeletonLoader?: boolean;
  submitModalCallback: (updatedData?: IbillingData) => void;
  availableCountries: ICountryDetail[];
}) => {
  const { t } = useTranslation();
  const [temporaryBillingData, setTemporaryBillingData] = useState<IbillingData>();
  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:';
  const modalContentTitle = t(`${i18nLanguageKey}modalContentTitle`);

  const getModalHeight = () => {
    const countryCode = temporaryBillingData?.documentCountry?.code || '';
    switch (countryCode) {
      case 'mx':
        return '540px';
      case 'co':
        return '514px';
      case 'pe':
        return '478px';
      case 'uy':
        return '560px';
      default:
        return '478px';
    }
  };

  return (
    <div className="NudosBillingDataFormModal">
      <BordModal
        closeModalCallback={closeModalCallback}
        customZIndex={customZIndex}
        modalContent={
          <ModalContent
            customWidth="68rem"
            customHeight="58rem"
            toCloseModal={closeModalCallback}
            title={modalContentTitle}
            customSubtitleClass="subtitleClass"
            subTitle="Revisa y confirma los datos de facturación"
            CustomModalComponent={
              <NudosBillingDataFormModalContent
                initialBillingData={initialBillingData}
                submitModalCallback={submitModalCallback}
                setTemporaryBillingData={setTemporaryBillingData}
                showSkeletonLoader={showSkeletonLoader}
                availableCountries={availableCountries}
              />
            }
          />
        }
      />
    </div>
  );
};

export default NudosBillingDataFormModal;
