import { ITwoToneIconProps } from '../BordTwoToneIcon.types';
import { bordColorTheme } from '../../../../styles/constants';

const BordTwoToneComputerIcon = (props: ITwoToneIconProps) => {
  const { colorTones } = props;
  const { tone1, tone2, opacity1, opacity2 } = colorTones || {};
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <path
        fill={tone1 || bordColorTheme.navyBlue[400]}
        d="m18.9127 28.7749-.599.4512.599-.4512Zm-.1784 1.0097c.2493.3308.7195.397 1.0504.1478.3308-.2492.397-.7194.1478-1.0503l-1.1982.9025Zm.2967-5.5203c.146-.3876-.0499-.8202-.4376-.9662-.3876-.146-.8202.0499-.9662.4376l1.4038.5286Zm-5.9436 4.5106-.5991-.4513.5991.4513Zm-1.0197.1072c-.2492.3309-.183.8011.1478 1.0503.3309.2492.8011.183 1.0503-.1478l-1.1981-.9025Zm2.3052-5.1464c-.146-.3877-.5785-.5836-.9662-.4376-.3876.146-.5835.5786-.4375.9662l1.4037-.5286Zm.2938-4.4857c-.4142 0-.75.3358-.75.75s.3358.75.75.75v-1.5Zm2.6667 1.5c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75v1.5Zm.9803 8.4761.4206.5585 1.1982-.9025-.4207-.5585-1.1981.9025Zm-.6865-5.4904c-.6816 1.8099-.4306 4.0074.6865 5.4904l1.1981-.9025c-.7796-1.035-.9987-2.6842-.4808-4.0593l-1.4038-.5286Zm-5.1389 4.5879-.4206.5585 1.1981.9025.4207-.5585-1.1982-.9025Zm.4809-4.0593c.5178 1.3751.2987 3.0243-.4809 4.0593l1.1982.9025c1.117-1.483 1.3681-3.6805.6864-5.4904l-1.4037.5286Zm1.6975-3.5143h2.6667v-1.5h-2.6667v1.5Z"
        opacity={opacity1 || '.4'}
      />
      <path
        stroke={tone2 || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M9.33341 29.3332H22.6667M13.3334 2.6665h5.3334c4.3723 0 6.5584 0 8.1081 1.08509.5733.40145 1.072.90012 1.4734 1.47346 1.0851 1.54966 1.0851 3.73581 1.0851 8.10815 0 4.3723 0 6.5584-1.0851 8.1081-.4014.5733-.9001 1.072-1.4734 1.4734-1.5497 1.0851-3.7358 1.0851-8.1081 1.0851h-5.3334c-4.37229 0-6.55844 0-8.10811-1.0851-.57333-.4014-1.072-.9001-1.47345-1.4734-1.08509-1.5497-1.08509-3.7358-1.08509-8.1081 0-4.37234 0-6.55849 1.08509-8.10815.40145-.57334.90012-1.07201 1.47345-1.47346C6.77496 2.6665 8.96111 2.6665 13.3334 2.6665Z"
        opacity={opacity2}
      />
    </svg>
  );
};

export default BordTwoToneComputerIcon;
