import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NudosBreadcrumbButton } from '../../../../../../components/NudosComponents';
import { signUpCollaborator } from '../../../../../../services/signUp.service';
import useLogin from '../../../../../../hooks/useLogin';
import { BordButton, BordTextInput } from '../../../../../../components/BordDesignSystem';
import { UserInfo } from '@firebase/auth';
import createFirebaseUser from '../../../../../../utils/createUser';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import TermsAndConditionsCheckmark from '../../../TermsAndConditionsCheckmark/TermsAndConditionsCheckmark';
import BordPasswordInput from '../../../../../../components/BordDesignSystem/BordPaswordInput/BordPasswordInput';
import useSignStore from '../../../../state/useSignStore';
import { getEmailError, getPasswordConfirmationError, getPasswordError } from '../../../../signUp.utils';

const SignUpFromInvitation = ({ emailParam, registrationInfoIdParam }: IsignUpFromInvitation) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { loginEmailAndPassWord } = useLogin();
  const { userPassword, userPasswordConfirmation, setUserPassword, setUserPasswordConfirmation } = useSignStore();

  const [emailExistError, setEmailExistError] = useState<string>();
  const [loading, setloading] = useState(false);
  const [firebasePasswordError, setFirebasePasswordError] = useState<string>();
  const [acceptTerms, setAcceptTems] = useState<boolean>(false);
  const [acceptPrivacyNotice, setAcceptPrivacyNotice] = useState<boolean>(false);

  const translationKey = 'authentication:SignUpForm:formFieldsStep3:';

  const emailError = getEmailError(emailParam) || emailExistError;
  const passwordError = getPasswordError(userPassword) || firebasePasswordError;
  const passwordConfirmationError = getPasswordConfirmationError(userPasswordConfirmation, userPassword);

  const handleSubmit = async () => {
    if (!emailParam || !userPassword) return;
    setloading(true);
    const body = {
      email: emailParam,
      registrationInfoId: registrationInfoIdParam ? +registrationInfoIdParam : undefined
    };
    try {
      const stringStoredUserFirebaseData = localStorage.getItem('userFirebaseData');
      const storedUserFirebaseData = stringStoredUserFirebaseData && JSON.parse(stringStoredUserFirebaseData);
      let firebaseData: UserInfo;
      if (!storedUserFirebaseData) {
        const userData: UserInfo = await createFirebaseUser(emailParam, userPassword);
        const stringUserData = JSON.stringify(userData);
        localStorage.setItem('userFirebaseData', stringUserData);
        firebaseData = userData;
      } else {
        firebaseData = storedUserFirebaseData;
      }
      const response = await signUpCollaborator({ ...body, uid: firebaseData.uid });
      if (response) {
        localStorage.removeItem('userFirebaseData');
        sessionStorage.removeItem('newRegisterInformation');
        await loginEmailAndPassWord(emailParam, userPassword, setFirebasePasswordError);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.error === 'auth/email-already-in-use') {
        setEmailExistError(t(`${translationKey}emailAlreadyRegistered`));
      } else if (err?.response?.data?.message === 'emailAlreadyRegistered') {
        setEmailExistError(t(`${translationKey}emailAlreadyRegistered`));
      } else {
        displayErrorNotification();
      }
    } finally {
      setloading(false);
    }
  };

  const isButtonDisabled = () => {
    if (!acceptTerms || !acceptPrivacyNotice) return true;
    if (!emailParam || !userPassword || !userPasswordConfirmation) return true;
    if (emailError || passwordError || passwordConfirmationError) return true;
    return false;
  };

  const handleClickReturn = () => push('/login');

  const changeTermsAcceptance = () => {
    setAcceptTems(!acceptTerms);
  };

  const changePrivacyNoticeAcceptance = () => {
    setAcceptPrivacyNotice(!acceptPrivacyNotice);
  };

  return (
    <div className="formStep formStep3">
      <BordTextInput
        className="formLine"
        label={t(`${translationKey}email`)}
        placeholder={'pedro@bord.co'}
        currentText={emailParam}
        disabled
        style={{ width: '40rem' }}
        errorText={emailError}
        onBlur={() => setEmailExistError(undefined)}
      />
      <BordPasswordInput
        passwordClassName="formLine"
        label={t(`${translationKey}passwordLabel`)}
        placeholder={t(`${translationKey}passwordPlaceholder`)}
        setCurrentValue={setUserPassword}
        caption={t(`${translationKey}passwordInstructions`)}
        style={{ width: '40rem' }}
        errorText={passwordError}
      />
      <BordPasswordInput
        passwordClassName="formLine"
        label={t(`${translationKey}passwordConfirmationLabel`)}
        placeholder={t(`${translationKey}passwordConfirmationPlaceholder`)}
        setCurrentValue={setUserPasswordConfirmation}
        style={{ width: '40rem' }}
        errorText={passwordConfirmationError}
      />
      <div className="termsAndConditionsContainer formLine">
        <TermsAndConditionsCheckmark checked={acceptTerms} type="terms" onChangeState={changeTermsAcceptance} />
        <TermsAndConditionsCheckmark
          checked={acceptPrivacyNotice}
          type="warning"
          onChangeState={changePrivacyNoticeAcceptance}
        />
      </div>
      <div className="buttonsContainer">
        <NudosBreadcrumbButton returnText={t(`${translationKey}goBackButton`)} returnAction={handleClickReturn} />
        <BordButton
          label={t(`${translationKey}continueButton`)}
          disabled={isButtonDisabled()}
          customWidth="w-280"
          isLoading={loading}
          type="submit"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default SignUpFromInvitation;

export interface IsignUpFromInvitation {
  emailParam: string;
  registrationInfoIdParam?: number | string | null;
}
