import { statusVariants } from '../../../components/BordDesignSystem/BordStatus/BordStatus.type';

export const VARIANT_STATUS = {
  toConfirm: 'Por confirmar',
  confirm: 'confirmado',
  inProgress: 'en proceso',
  active: 'activo',
  delivered: 'entregada',
  canceled: 'cancelado'
};

export const VARIANT_SERVICES_STATUS = {
  complete: 'Completado',
  notComplete: 'No completado',
  pending: 'Por completar'
};

export const renderVarianStatus = ({ variant }: { variant: statusVariants | undefined }) => {
  const defaultVariant = 'default';
  const varianStatus = {
    [VARIANT_STATUS.toConfirm]: 'default',
    [VARIANT_STATUS.confirm]: 'default',
    [VARIANT_STATUS.inProgress]: 'informative',
    [VARIANT_STATUS.active]: 'informative',
    [VARIANT_STATUS.delivered]: 'success',
    [VARIANT_STATUS.canceled]: 'success'
  };

  return varianStatus[variant ?? ''] ?? defaultVariant;
};

export const renderVariantServicesStatus = ({ variant }: { variant: statusVariants | undefined }) => {
  const defaultVariant = 'default';
  const varianStatus = {
    [VARIANT_SERVICES_STATUS.complete]: 'success',
    [VARIANT_SERVICES_STATUS.notComplete]: 'danger',
    [VARIANT_SERVICES_STATUS.pending]: 'informative'
  };

  return varianStatus[variant ?? ''] ?? defaultVariant;
};
