const RoundGuernsey = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2927 0 14.5962 0.0465 13.9131 0.135438V13.9131H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7073 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2927 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7073 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z"
        />
        <path
          fill="#FFDA2C"
          d="M20.5215 16.6956L21.5649 17.3913V14.6087L20.5215 15.3044H16.6954V11.4783L17.3911 10.4348H14.6084L15.3041 11.4783V15.3044H11.478L10.4346 14.6087V17.3913L11.478 16.6956H15.3041V20.5218L14.6084 21.5652H17.3911L16.6954 20.5218V16.6956H20.5215Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGuernsey;
