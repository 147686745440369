import { NudosHoverText } from '../../../../../../components/NudosComponents';
import NudosGenericTag from '../../../../../../components/NudosComponents/NudosGenericTag/NudosGenericTag';
import { nudosBlue, nudosLightBlue } from '../../../../../../styles/constants';
import { IadditionalServiceDataForToolLogistics } from '../../../../../../types/requestLogisticsModule';
import './LogisticsAdditionalServicesTag.scss';

const LogisticsAdditionalServicesTag = ({
  additionalServices
}: {
  additionalServices: IadditionalServiceDataForToolLogistics[];
}) => {
  const noDataText = 'N/A';
  const hasAtLeastOneAdditionalService = !!additionalServices && !!additionalServices.length;
  const hasMultipleServices = additionalServices && additionalServices.length > 1;
  const getText = () => {
    if (hasMultipleServices) return `${additionalServices.length} servicios`;
    const rawText = additionalServices[0]?.name;
    if (rawText === 'Configuración avanzada') return 'Conf. avanzada';
    if (rawText === 'Configuración básica') return 'Conf. básica';
    return rawText || noDataText;
  };

  const hoverListOfServices = (
    <div className="listOfServices">
      <ul>
        {additionalServices.map((service, i) => (
          <li key={`service${service?.id}-${i}`}>{service?.name}</li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="LogisticsAdditionalServicesTag">
      {hasAtLeastOneAdditionalService && (
        <NudosGenericTag
          text={<NudosHoverText onlyIfTruncated text={getText()} charactersLimit={16} />}
          fontColor={nudosBlue}
          backgroundColor={nudosLightBlue}
          customWidth="78px"
          customHeight="16px"
          hoverElement={hasMultipleServices ? hoverListOfServices : undefined}
        />
      )}
    </div>
  );
};

export default LogisticsAdditionalServicesTag;
