import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BordButton } from '../../../../components/BordDesignSystem';
import useShopStore from '../../../../state/useShopContext';
import { getBoardFilters } from '../../../../services/services';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { getDownLoadInventoryResume } from '../../../../services/dashboard';
import { formatOrgData } from '../../../../utils/orgFormatData';
import { useTranslation } from 'react-i18next';
import { ModuleFilters } from '../../../../components/NudosComponents';
import { TgetFiltersList } from '../../../../components/NudosComponents/ModuleFilters/ModuleFilters';
import './InventoryFilters.scss';

const InventoryFilters = ({
  isUploadToolsButtonActive,
  formatFilters
}: {
  isUploadToolsButtonActive: boolean;
  formatFilters?: string;
}) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const orgInfo = formatOrgData();
  const { boardFilters, setBoardFilters } = useShopStore();

  const [openProductType, setOpenProductType] = useState<boolean>(false);
  const [openAvailability, setOpenAvailability] = useState<boolean>(false);
  const [openCountry, setOpenCountry] = useState<boolean>(false);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [loadingDownLoad, setLoadingDownLoad] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:tools:filtersList:';

  const inventoryFilterMapping = {
    Tipo_de_producto: 'productCategory',
    Disponibilidad: 'productAvailability',
    País: 'country',
    Ubicación: 'location'
  };

  const getInventoryFiltersList: TgetFiltersList = (
    formModuleFilters,
    handleClickFilterOption,
    getFilterActiveStatus
  ) => [
    {
      title: t(`${i18nLanguageKey}title0`),
      openFunction: setOpenProductType,
      openStatus: openProductType,
      rawSelectOptions: formModuleFilters('Tipo_de_producto'),
      handleChangeSelectedOption: handleClickFilterOption('productCategory', 'name'),
      isFilterActive: getFilterActiveStatus('productCategory')
    },
    {
      title: t(`${i18nLanguageKey}title1`),
      openFunction: setOpenAvailability,
      openStatus: openAvailability,
      rawSelectOptions: formModuleFilters('Disponibilidad'),
      handleChangeSelectedOption: handleClickFilterOption('productAvailability', 'name'),
      isFilterActive: getFilterActiveStatus('productAvailability')
    },
    {
      title: t(`${i18nLanguageKey}title2`),
      openFunction: setOpenCountry,
      openStatus: openCountry,
      rawSelectOptions: formModuleFilters('País'),
      handleChangeSelectedOption: handleClickFilterOption('country', 'id'),
      isFilterActive: getFilterActiveStatus('country')
    },
    {
      title: t(`${i18nLanguageKey}title3`),
      openFunction: setOpenLocation,
      openStatus: openLocation,
      rawSelectOptions: formModuleFilters('Ubicación'),
      handleChangeSelectedOption: handleClickFilterOption('location', 'name'),
      isFilterActive: getFilterActiveStatus('location')
    }
  ];

  const downLoadAction = async () => {
    setLoadingDownLoad(true);
    try {
      const urlResume = await getDownLoadInventoryResume(Number(orgInfo?.organizationId), formatFilters || '');
      if (urlResume) window.open(urlResume, '_blank');
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingDownLoad(false);
    }
  };

  return (
    <div id="inventoryFilterHeader" className="moduleWithSidebar">
      <div className="inventoryFilters">
        <ModuleFilters
          moduleFilters={boardFilters}
          setModuleFilters={setBoardFilters}
          getModuleFiltersRequestFunction={getBoardFilters}
          getFiltersList={getInventoryFiltersList}
          moduleFiltersTitlesMapping={inventoryFilterMapping}
        />
      </div>
      <div className="containerButtonNewTool">
        <BordButton
          label="CSV"
          onClick={() => downLoadAction()}
          isLoading={loadingDownLoad}
          modeButton="tertiary"
          buttonIconTwo="download"
          customClassName="downLoadResume"
        />
        <BordButton
          label={t('nodi:tools:nudosButton')}
          onClick={() => push('/nodi/upload-external-tools')}
          modeButton="secondary"
          disabled={!isUploadToolsButtonActive}
          buttonIconTwo="plus"
        />
      </div>
    </div>
  );
};

export default InventoryFilters;
