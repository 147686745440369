import { AxiosError, AxiosRequestConfig } from 'axios';
import { orgAPI, usersAPI } from '../constants';
import {
  DTOOrganizationDocumentAlreadyExistResponse,
  DTOPayloadSignUp,
  DTORegisterFormFirstStepResponse,
  DTOResponseSignUp,
  DTOsendInviteCollaboratorEmail,
  DTOsendRegisterFormFirstStep,
  DTOupdateRegisterFormData,
  IsignUpCollaborator
} from '../types/SignUp';
import { apiPost, apiPut } from './api';

export const sendRegisterFormFirstStep = async (
  body: DTOsendRegisterFormFirstStep
): Promise<DTORegisterFormFirstStepResponse | DTOOrganizationDocumentAlreadyExistResponse> => {
  return apiPost<AxiosRequestConfig, DTOsendRegisterFormFirstStep | DTOOrganizationDocumentAlreadyExistResponse>(
    `${usersAPI}/registration-info`,
    body
  )
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateRegisterFormData = async (
  body: DTOupdateRegisterFormData,
  registrationInfoId: number | string
): Promise<DTORegisterFormFirstStepResponse> => {
  return apiPut<AxiosRequestConfig, DTOupdateRegisterFormData>(
    `${usersAPI}/registration-info/update/${registrationInfoId}`,
    body
  )
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const postOfSignUp = async (body: DTOPayloadSignUp): Promise<DTOResponseSignUp> => {
  return apiPost<AxiosRequestConfig, DTOPayloadSignUp>(`/organization`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const sendInviteCollaboratorEmail = async (body: DTOsendInviteCollaboratorEmail): Promise<unknown> => {
  return apiPost(`${orgAPI}/organization/request-mail`, body)
    .then(response => Promise.resolve(response || 200))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const signUpCollaborator = async (body: IsignUpCollaborator): Promise<unknown> => {
  return apiPost<AxiosRequestConfig, unknown>(`${orgAPI}/organization/collaborator`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
