const RoundMarshallIsland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M16 32C24.8365 32 32 24.8365 32 16C32 13.3148 31.3375 10.7846 30.1686 8.5625L15.9812 16.0187L23.4375 1.83138C21.2154 0.6625 18.6852 0 16 0C7.1635 0 0 7.1635 0 16C0 20.4183 1.79088 24.4183 4.68625 27.3137C7.58169 30.2092 11.5817 32 16 32Z"
        />
        <path
          fill="white"
          d="M30.1522 8.57995C29.4198 7.18595 28.4676 5.87714 27.2955 4.70501C26.1233 3.53289 24.8147 2.5807 23.4205 1.84839L4.66797 27.3325L30.1522 8.57995Z"
        />
        <path
          fill="#FF9811"
          d="M4.66797 27.3325L30.1522 8.58002C29.4198 7.18602 28.4676 5.8772 27.2955 4.70508L4.66797 27.3325Z"
        />
        <path
          fill="white"
          d="M13.8945 9.38275L11.9405 10.3019L12.981 12.1944L10.8591 11.7884L10.5903 13.9319L9.11227 12.3554L7.63427 13.9319L7.36552 11.7884L5.24364 12.1942L6.28408 10.3018L4.33008 9.38275L6.28414 8.46356L5.24364 6.57118L7.36539 6.977L7.63433 4.8335L9.11227 6.41006L10.5903 4.8335L10.8591 6.977L12.9811 6.57118L11.9405 8.46362L13.8945 9.38275Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMarshallIsland;
