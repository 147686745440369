import { OrderShipmentCardSkeletonLoader, OrderShipmentGroupCard } from '..';
import { IshipmentGroupDetails } from '../../../../../types/orders';
import './ShipmentsGroupsList.scss';

/**
 * @property {IshipmentGroupDetails[]} shipmentsGroups - The array of shipmentsGroups to be displayed
 * @property {string} flag - The flag of the country where the shipmentsGroups takes place
 * @property {string} countryName - The name of the country where the shipmentsGroups takes place
 * @property {number} quantityshipmentsGroups - The amount of shipmentsGroups in the list
 * @property {() => void} updateOrderDataFunction - A function to upload the OrderData
 */
const ShipmentsGroupsList = ({
  shipmentsGroups,
  countryName,
  updateOrderDataFunction,
  getOrderDatawithoutLoader,
  globalStatusOrder
}: {
  shipmentsGroups?: IshipmentGroupDetails[] | null;
  flag?: string | null;
  countryName?: string | null;
  quantityShipments?: number | null;
  updateOrderDataFunction: () => void;
  getOrderDatawithoutLoader: () => void;
  globalStatusOrder?: string | null | undefined;
}) => {
  return (
    <div className="shipmentsGroupsList">
      {Array.isArray(shipmentsGroups) &&
        shipmentsGroups.map((shipmentGroup, i) => {
          return (
            <OrderShipmentGroupCard
              key={`shipment${i}-${shipmentGroup.id}`}
              shipmentCardIndex={i}
              shipmentGroupData={shipmentGroup}
              updateOrderDataFunction={updateOrderDataFunction}
              countryName={countryName}
              getOrderDatawithoutLoader={getOrderDatawithoutLoader}
              globalStatusOrder={globalStatusOrder}
            />
          );
        })}
    </div>
  );
};

const ShipmentsGroupsListSkeletonLoader = () => {
  return (
    <div className="shipmentsGroupsListSkeletonLoader">
      {[1, 2, 3, 4, 5, 6, 7].map(i => {
        return <OrderShipmentCardSkeletonLoader key={`shipmentsGroupskeletonCard${i}`} />;
      })}
    </div>
  );
};

export { ShipmentsGroupsList, ShipmentsGroupsListSkeletonLoader };
