const RoundSaudiArabia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#6DA544"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M9.04297 19.1304C9.04297 20.2829 9.97734 21.2173 11.1299 21.2173H17.3908C17.3908 22.1778 18.1694 22.9564 19.1299 22.9564H21.2168C22.1773 22.9564 22.956 22.1778 22.956 21.2173V19.1304H9.04297ZM23.1296 9.04346V13.913C23.1296 14.6802 22.5055 15.3043 21.7383 15.3043V17.3913C23.6562 17.3913 25.2165 15.8309 25.2165 13.913V9.04346H23.1296ZM8.17354 13.913C8.17354 14.6802 7.54941 15.3043 6.78223 15.3043V17.3913C8.70016 17.3913 10.2605 15.8309 10.2605 13.913V9.04346H8.17354V13.913Z"
        />
        <path
          fill="white"
          d="M20 9.04346H22.0869V13.913H20V9.04346ZM16.8689 11.8261C16.8689 12.0179 16.7128 12.1739 16.5211 12.1739 16.3293 12.1739 16.1733 12.0178 16.1733 11.8261V9.04346H14.0863V11.8261C14.0863 12.0179 13.9303 12.1739 13.7385 12.1739 13.5468 12.1739 13.3907 12.0178 13.3907 11.8261V9.04346H11.3037V11.8261C11.3037 13.1686 12.396 14.2609 13.7385 14.2609 14.2556 14.2609 14.7351 14.0984 15.1298 13.8224 15.5245 14.0983 16.0041 14.2609 16.5211 14.2609 16.6253 14.2609 16.7276 14.2536 16.8283 14.2408 16.6805 14.8501 16.1317 15.3043 15.4776 15.3043V17.3913C17.3956 17.3913 18.9559 15.8309 18.9559 13.913V11.8261 9.04346H16.869V11.8261H16.8689Z"
        />
        <path fill="white" d="M11.3037 15.3044H14.4341V17.3914H11.3037V15.3044Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSaudiArabia;
