const RoundAlandIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M31.4488 20.174C31.8075 18.8431 32 17.4442 32 16c0-1.4441-.1925-2.843-.5512-4.1739L14.6087.0609131C11.4824.330288 8.61344 1.49841 6.26088 3.30598L.551188 11.8261C.1925 13.157 0 14.5559 0 16c0 1.4358.190188 2.827.544875 4.1509l5.715935 8.5432c2.35256 1.8076 5.22149 2.9758 8.34779 3.2451L31.4488 20.174Z"
        />
        <path
          fill="#1A47B8"
          d="M.551208 20.174c.925752 3.4349 2.966062 6.412 5.709692 8.5201V20.174H.551208ZM14.6087 31.9391c.4586.0395.9224.0609 1.3913.0609 7.3925 0 13.6128-5.0138 15.4488-11.826H14.6087v11.7651ZM31.4488 11.8261C29.6128 5.01381 23.3925 0 16 0c-.4689 0-.9327.021375-1.3913.060875V11.8261h16.8401ZM6.2609 3.30591C3.51727 5.41403 1.47696 8.3911.551208 11.826H6.2609V3.30591Z"
        />
        <path
          fill="#F93939"
          d="M31.8646 13.9131H12.5218V.380371C11.0431.708246 9.64131 1.23968 8.34781 1.9455v11.9676H.135438C.046375 14.5962 0 15.2927 0 16.0001c0 .7073.046375 1.4038.135438 2.0869H8.34781v11.9676c1.2935.7057 2.69529 1.2373 4.17399 1.5651V18.0871h19.3428c.0889-.6832.1354-1.3797.1354-2.087 0-.7074-.0465-1.4039-.1354-2.087Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAlandIslands;
