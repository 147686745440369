export const getDefaultLanguage = () => {
  if (localStorage.getItem('language')) {
    const defaultLanguage = JSON.parse(localStorage.getItem('language') || '{}');
    if (defaultLanguage?.language?.code && defaultLanguage.language.code === 'en') {
      return 'enUS';
    }
    return 'esMX';
  }
  return 'esMX';
};

export const userLanguagePreference = () => {
  if (localStorage.getItem('language')) {
    const defaultLanguage = JSON.parse(localStorage.getItem('language') || '{}');
    return defaultLanguage;
  }
  return undefined;
};
