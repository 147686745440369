import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BordButton } from '../../../../../../components/BordDesignSystem';
import useStore from '../../../../../../state';
import AssignedToolCard from '../AssignedToolCard';
import { IassignedTool, Iemployee, Tany } from '../../../../../../types/global';
import { laptopIcon } from '../../../../../../assets/images/views/Admin/employees';
import UnassignToolsFromEmployeeModal from '../UnassignToolsFromEmployeeModal';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { ICountryDetail } from '../../../../../../types/countries';
import { useTranslation } from 'react-i18next';
import AssignToolsModal from '../../../Components/assignToolsModal';
import ToolsInTransit from '../ToolsInTransit/ToolsInTransit';
import { formatOrgData } from '../../../../../../utils/orgFormatData';
import BordEmptyState from '../../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import './EmployeeDetailsBody.scss';

const EmployeeDetailsBody = ({
  employee,
  updateFile,
  loaderFileCardId,
  listOfCountriesWithLogistics
}: {
  employee: Iemployee;
  updateFile?: (assignmentId: number, file: Tany) => void;
  loaderFileCardId?: number;
  listOfCountriesWithLogistics?: ICountryDetail[];
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { setEmployeeToAssign } = useStore();
  const formatOrgInfo = formatOrgData();
  const [showEquipmentHistory, setShowEquipmentHistory] = useState(false);
  const [isPossibleToUnassign, setIsPossibleToUnassign] = useState(false);
  const [toolsToUnassign, setToolsToUnassign] = useState<IassignedTool[]>([]);
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  const [showAssignToolsModal, setShowAssignToolsModal] = useState<boolean>(false);
  const employeeDetailLanguageText = 'nodi:employeeInternal:';
  const recurrentWords = 'recurrentWords:';

  //blockedCompany 332
  const blockMarketPlace = formatOrgInfo?.organizationId === 332;

  const employeeToAssignData = {
    id: employee?.id,
    organizationId: employee?.organizationId,
    userId: employee?.userId,
    firstName: employee?.firstName,
    lastName: employee?.lastName,
    city: employee?.city,
    area: employee?.area,
    position: employee?.position,
    country: employee?.country,
    address: employee?.address
  };

  const handleEquipWithoutLogistics = () => {
    setEmployeeToAssign(employeeToAssignData);
    segmentTrackEvent({
      employeesDetailAssignClick: {
        EmployeeName: `${employee?.firstName} ${employee?.lastName}`,
        EmployeeCountry: employee?.country?.name || t(`${recurrentWords}other`)
      }
    });
    if (!employee?.userId) return;
    push(
      `/nodi/request-logistics/assignment/${employee?.userId}?logistics=false&origin=employee-details${
        employee?.userId ? `&originId=${employee?.userId}` : ''
      }`
    );
  };

  const currentEquipment = [...(employee?.assignedTools || [])].filter(tool => {
    return (
      !tool.assignedUntil && !(tool?.logisticStatus && !['cancelado', 'entregado'].includes(tool?.logisticStatus || ''))
    );
  });
  const equipmentHistory = [...(employee?.assignedTools || [])].filter(tool => {
    return (
      !!tool.assignedUntil &&
      !(tool?.logisticStatus && !['cancelado', 'entregado'].includes(tool?.logisticStatus || ''))
    );
  });

  const noCurrentEquipmentStyles =
    !currentEquipment || currentEquipment.length <= 0 ? { paddingTop: '66px' } : undefined;

  const inactiveAssignButtonStyles = currentEquipment?.length > 0 ? '' : 'inactive';

  const isProceedToUnassignButtonDisabled = !(toolsToUnassign?.length > 0);
  const handleClickRevertUnassignPossibility = () => {
    setIsPossibleToUnassign(false);
    setToolsToUnassign([]);
  };
  const handleProceedToUnassign = () => setShowUnassignModal(true);

  const toolsDataToUnassign = toolsToUnassign.map(tool => {
    return {
      sku: tool?.sku,
      productId: +tool?.productId,
      productName: tool?.productName,
      productBrand: tool?.brand,
      serial: tool?.serial || ''
    };
  });

  const logisticsCountryData =
    employee?.country?.id && employee?.country?.name && employee?.country?.flag && employee?.country?.code
      ? {
          id: employee.country.id,
          name: employee.country.name,
          flag: employee.country.flag,
          code: employee.country.code
        }
      : undefined;

  const handleClickAssignTool = (showModalForLogisticsSelection = true) => {
    if (!employee?.country?.code) {
      return;
    }
    const isEmployeeInACountryWithLogistics = listOfCountriesWithLogistics?.some(
      countryWithLogistics => countryWithLogistics.code === employee?.country?.code
    );
    segmentTrackEvent({
      employeesDetailAssignClick: {
        EmployeeCountry: employee?.country?.name || t(`${recurrentWords}other`),
        EmployeeName: `${employee?.firstName} ${employee?.lastName}`
      }
    });
    if (isEmployeeInACountryWithLogistics && showModalForLogisticsSelection) {
      return setShowAssignToolsModal(true);
    }
    return handleEquipWithoutLogistics();
  };

  if (
    employee?.toolsInTransit &&
    employee?.toolsInTransit?.length <= 0 &&
    currentEquipment.length <= 0 &&
    equipmentHistory.length <= 0
  ) {
    return (
      <>
        <BordEmptyState
          title={t(`${employeeDetailLanguageText}noHaventOnboarded`)}
          subTitle={t(`${employeeDetailLanguageText}purchaseDevices`)}
          ilustrationIconProps={{
            variant: 'device',
            customWidth: '10rem'
          }}
          buttonOneProps={{
            label: t(`${recurrentWords}onboardingFromStore`) || '',
            buttonIconTwo: 'monitor',
            onClick: () => {
              push('/catalogue/home');
            },
            bordButtonStyle: 'outlined',
            customWidth: 'w-280'
          }}
          buttonTwoProps={{
            label: t(`${recurrentWords}onboardingFromInventory`) || '',
            buttonIconTwo: 'shoppingCart',
            onClick: () => handleClickAssignTool(true),
            customWidth: 'w-280'
          }}
        />
        {showAssignToolsModal && (
          <AssignToolsModal
            employee={{ ...employee, country: logisticsCountryData }}
            navigationOrigin="employee-details"
            navigationOriginId={employee?.userId}
            closeModalCallback={setShowAssignToolsModal}
            logisticsOriginCountry={logisticsCountryData}
            listOfCountriesWithLogistics={listOfCountriesWithLogistics}
          />
        )}
      </>
    );
  }

  return (
    <div id="employeeDetailsBody">
      <ToolsInTransit
        toolsInTransit={employee?.toolsInTransit || []}
        updateFileCallback={updateFile}
        loaderFileCardId={loaderFileCardId}
      />
      <div className="titleAndEquipButton">
        <div className="title">{t(`${employeeDetailLanguageText}employeeDetailsBody:title`)}</div>
        <div className="buttonsContainer">
          <BordButton
            modeButton="tertiary"
            label={t(`${employeeDetailLanguageText}employeeDetailsBody:unassignButton`)}
            buttonIconTwo="userRemove"
            onClick={() => {
              segmentTrackEvent({
                employeesDetailUnassignClick: {
                  EmployeeCountry: employee?.country?.name || t(`${recurrentWords}other`),
                  EmployeeName: `${employee?.firstName} ${employee?.lastName}`
                }
              });
              setIsPossibleToUnassign(true);
            }}
            disabled={currentEquipment?.length <= 0}
            customClassName={`offBoardingButton ${inactiveAssignButtonStyles}`}
          />

          {!blockMarketPlace && (
            <BordButton
              modeButton="tertiary"
              disabled={!employee?.active}
              label={t(`${employeeDetailLanguageText}employeeDetailsBody:assignButton:buttonText`)}
              customClassName={`buyInMarketPlaceButton`}
              onClick={() => handleClickAssignTool(true)}
              buttonIconTwo="monitor"
            />
          )}

          <BordButton
            modeButton="tertiary"
            disabled={!employee?.active}
            label={t(`${employeeDetailLanguageText}employeeDetailsBody:buyInMarketPlace`)}
            customClassName={`onBoardingButton`}
            onClick={() => push('/catalogue/home')}
            buttonIconTwo="userPlus"
          />
        </div>
      </div>
      <div className={`employeeDetailsBodyContent`}>
        <div className="currentEquipment" style={noCurrentEquipmentStyles}>
          {currentEquipment && currentEquipment?.length > 0 && (
            <div className="cardsContainerEquipment">
              {currentEquipment &&
                currentEquipment.length > 0 &&
                currentEquipment.map((tool, i) => {
                  return (
                    <AssignedToolCard
                      key={`assignedTool${i}`}
                      assignedTool={tool}
                      toolsToUnassign={toolsToUnassign}
                      setToolsToUnassign={setToolsToUnassign}
                      isPossibleToUnassign={isPossibleToUnassign}
                      updateFile={updateFile}
                      loaderFileCardId={loaderFileCardId}
                    />
                  );
                })}
            </div>
          )}
          {(!currentEquipment || currentEquipment.length <= 0) && (
            <div className={`noAssignedTools`}>
              <div className="items">
                <div className="iconContainer">
                  <img className="limitsImgLaptop" src={laptopIcon} alt="Icon laptop" />
                </div>
                <div className="noToolText">{t(`${employeeDetailLanguageText}employeeDetailsBody:noToolText`)}</div>
              </div>
            </div>
          )}
        </div>
        {isPossibleToUnassign && currentEquipment && (
          <div className="revertOrProceedToUnassignButtons">
            <BordButton
              modeButton="tertiary"
              customClassName="cancelButton"
              label={t(`${recurrentWords}cancel`)}
              buttonIconTwo="x"
              onClick={handleClickRevertUnassignPossibility}
              standardSizeIcon={12}
            />
            <BordButton
              label={t(`${recurrentWords}continueToOffboarding`)}
              customClassName="proceedToUnassignButton"
              disabled={isProceedToUnassignButtonDisabled}
              onClick={handleProceedToUnassign}
              modeButton="tertiary"
              buttonIconTwo="arrowRightCircle"
            />
          </div>
        )}
        <div className={`equipmentHistory ${showEquipmentHistory ? 'expanded' : ''}`}>
          <BordButton
            modeButton="tertiary"
            label={t(`${employeeDetailLanguageText}employeeDetailsBody:equipmentHistory`)}
            onClick={() => setShowEquipmentHistory(!showEquipmentHistory)}
            customClassName="equipmentHistoryButton"
            buttonIconTwo={showEquipmentHistory ? 'arrowHeadUp' : 'arrowHeadDown'}
            disabled={equipmentHistory?.length <= 0}
            standardSizeIcon={12}
          />
          <div className="cardsContainerHistory">
            {showEquipmentHistory &&
              equipmentHistory &&
              equipmentHistory.length > 0 &&
              equipmentHistory.map((tool, i) => {
                return (
                  <AssignedToolCard
                    key={`assignedTool${i}`}
                    assignedTool={tool}
                    inactiveCard
                    toolsToUnassign={toolsToUnassign}
                    setToolsToUnassign={setToolsToUnassign}
                    updateFile={updateFile}
                    loaderFileCardId={loaderFileCardId}
                  />
                );
              })}
            <div className="marginAtBottom w-10 h-10" />
          </div>
        </div>
        {(!equipmentHistory || equipmentHistory.length <= 0) && showEquipmentHistory && (
          <div className="noHistoryText">{t(`${employeeDetailLanguageText}employeeDetailsBody:noHistoryText`)}</div>
        )}
      </div>
      {showUnassignModal && (
        <UnassignToolsFromEmployeeModal
          setShowUnassignModal={setShowUnassignModal}
          toolsToUnassign={toolsDataToUnassign}
          loadingInitialData={false}
          employee={{ ...employee, country: logisticsCountryData }}
          logisticsOriginCountry={logisticsCountryData}
          navigationOrigin="employee-details"
          navigationOriginId={employee?.userId}
        />
      )}

      {showAssignToolsModal && (
        <AssignToolsModal
          employee={{ ...employee, country: logisticsCountryData }}
          navigationOrigin="employee-details"
          navigationOriginId={employee?.userId}
          closeModalCallback={setShowAssignToolsModal}
          logisticsOriginCountry={logisticsCountryData}
          listOfCountriesWithLogistics={listOfCountriesWithLogistics}
        />
      )}
    </div>
  );
};

export default EmployeeDetailsBody;
