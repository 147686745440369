const RoundAfghanistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M22.9565 1.58763C20.853.5705 18.4931 0 16 0c-2.4932 0-4.853.5705-6.95653 1.58763L7.65216 16l1.39131 14.4124C11.147 31.4295 13.5068 32 16 32c2.4931 0 4.853-.5705 6.9565-1.5876L24.3478 16 22.9565 1.58763Z"
        />
        <path
          fill="#000"
          d="M9.0435 1.58813C3.69163 4.17607 0 9.65664 0 15.9999c0 6.3434 3.69163 11.8239 9.0435 14.4119V1.58813Z"
        />
        <path
          fill="#496E2D"
          d="M22.9565 1.58813V30.4118C28.3084 27.8238 32 22.3433 32 15.9999c0-6.34326-3.6916-11.82383-9.0435-14.41177Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 10.4348c-3.0736 0-5.5652 2.4916-5.5652 5.5652S12.9264 21.5652 16 21.5652 21.5652 19.0736 21.5652 16 19.0736 10.4348 16 10.4348Zm0 9.0435c-1.921 0-3.4782-1.5574-3.4782-3.4783S14.0791 12.5218 16 12.5218c1.9209 0 3.4783 1.5573 3.4783 3.4782 0 1.9209-1.5573 3.4783-3.4783 3.4783Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 13.9131c-.7684 0-1.3913.6229-1.3913 1.3913v2.0869h2.7826v-2.0869c0-.7684-.6228-1.3913-1.3913-1.3913Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAfghanistan;
