const RoundCzechRepublic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M14.609 16S4.6959 27.3159 4.68652 27.3137C7.5819 30.2091 11.582 32 16.0003 32c8.8365 0 16-7.1635 16-16H14.609Z"
        />
        <path
          fill="#1A47B8"
          d="M4.68628 4.68628c-6.24837 6.24842-6.24837 16.37902 0 22.62752C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCzechRepublic;
