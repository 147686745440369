import { IstorageMonthlyDetails, IToolQuoteInfo } from '../../../../../../../types/logisticsServicesModule.types';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import './StorageTooltip.scss';

const StorageTooltip = ({ toolData }: { toolData: IToolQuoteInfo }) => {
  const { storageDetails, isBuyAndHold } = toolData;
  const { storageMonthlyDetails, totalStorageCosts } = storageDetails || {};

  const getSubscriptionLevelCopy = (storageMonth?: IstorageMonthlyDetails) => {
    if (!storageMonth?.chargedFee && !!storageMonth?.buyAndHoldSavings) return 'Cobertura Buy & Hold';
    switch (storageMonth?.subscriptionLevel) {
      case 'Lite':
        return 'Precio Platinum';
      case 'Vip':
        return 'Precio VIP';
      case 'Free':
        return 'Precio Standard';
    }
  };

  return (
    <div className="tooltipContainer">
      <span className="tooltipTitle">Almacenamiento</span>
      <div className="tooltipDesciptionContainer">
        <div className="tooltipDesciption">
          Bord cobra primer mes de almacenamiento al momento de ingreso y meses restantes al momento de salida.
          {isBuyAndHold && <div className="mt-12">Buy & Hold te cubre 3 meses de almacenamiento gratis.</div>}
        </div>
        <div className="monthlyStorageContainer">
          {storageMonthlyDetails?.map((storageMonth, index) => (
            <div key={index} className="storageDatesContainer">
              <div className="w-150 mt-4">
                {`${formatShortDate(storageMonth?.startDate).onlyDate} - ${
                  formatShortDate(storageMonth?.endDate).onlyDate
                }`}
              </div>
              <div className="subscriptionLevel">{getSubscriptionLevelCopy(storageMonth)}</div>
              <div className="monthyPrice">${storageMonth?.chargedFee} USD</div>
            </div>
          ))}
        </div>
        <div className="mt-4 flex justify-between">
          <div>Total a pagar</div>
          <div className="font-bold text-content">${totalStorageCosts} USD</div>
        </div>
      </div>
    </div>
  );
};

export default StorageTooltip;
