const SquareVirginIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <mask id="c" width="22" height="16" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
            <path
              fill="white"
              d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
            />
          </mask>
          <g mask="url(#c)">
            <path
              fill="white"
              d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
            />
            <path
              fill="#3A99FF"
              fillRule="evenodd"
              d="M15.9763 9.6001L16.762 10.4001L14.4049 12.8001L13.6191 12.0001L15.9763 9.6001Z"
              clipRule="evenodd"
            />
            <path
              fill="#249F58"
              fillRule="evenodd"
              d="M7.07185 9.6001L6.28613 10.4001L8.64328 12.8001L9.42899 12.0001L7.07185 9.6001Z"
              clipRule="evenodd"
            />
            <path
              fill="#FFDA2C"
              fillRule="evenodd"
              d="M7.33375 9.6001H8.38137V10.6668L6.28613 11.7334L7.33375 9.6001Z"
              clipRule="evenodd"
            />
            <path
              fill="#3A99FF"
              fillRule="evenodd"
              d="M3.14258 7.4668H4.1902V10.6668H3.14258V7.4668ZM17.8092 7.4668H18.8569V10.6668H17.8092V7.4668Z"
              clipRule="evenodd"
            />
            <path
              fill="#FFDA2C"
              fillRule="evenodd"
              d="M11.5238 4.26686V3.2002H9.42852V4.26686L10.4761 5.33353V7.46686H11.5238V4.26686ZM4.19043 4.26686L8.38091 5.33353V8.53353L4.19043 5.33353V4.26686ZM17.8095 4.26686L13.619 5.33353V8.53353L17.8095 5.33353V4.26686ZM8.38091 11.7335L11 12.8002L13.619 11.7335L12.5714 10.6669H9.42852L8.38091 11.7335Z"
              clipRule="evenodd"
            />
            <path
              fill="#AF010D"
              fillRule="evenodd"
              d="M8.38086 6.3999H13.619V10.6666L10.9999 11.7332L8.38086 10.6666V6.3999Z"
              clipRule="evenodd"
            />
            <path
              fill="#232C80"
              fillRule="evenodd"
              d="M8.38086 5.3335H13.619V7.46683H8.38086V5.3335Z"
              clipRule="evenodd"
            />
            <path
              fill="white"
              fillRule="evenodd"
              d="M9.42871 7.4668H10.4763V10.6668H9.42871V7.4668ZM11.5239 7.4668H12.5716V10.6668H11.5239V7.4668Z"
              clipRule="evenodd"
            />
            <path
              fill="#FFDA2C"
              fillRule="evenodd"
              d="M14.6668 9.6001H13.6191V10.6668L15.7144 11.7334L14.6668 9.6001Z"
              clipRule="evenodd"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareVirginIslands;
