const RoundTaiwan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C5.49563 10.5044 10.1565 5.8435 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path fill="#1A47B8" d="M16 16C16 7.1635 16 6.12175 16 0C7.1635 0 0 7.1635 0 16H16Z" />
        <path
          fill="white"
          d="M13.913 9.36382L11.9589 10.2831L12.9994 12.1755L10.8776 11.7695L10.6087 13.9131L9.13082 12.3366L7.65282 13.9131L7.38401 11.7695L5.26213 12.1754L6.30263 10.2829L4.34863 9.36382L6.3027 8.44476L5.26213 6.55226L7.38394 6.9582L7.65288 4.8147L9.13082 6.3912L10.6088 4.8147L10.8776 6.9582L12.9995 6.55226L11.9589 8.44482L13.913 9.36382Z"
        />
        <path
          fill="#1A47B8"
          d="M9.13066 12.3419C10.7755 12.3419 12.109 11.0084 12.109 9.36357C12.109 7.71869 10.7755 6.38525 9.13066 6.38525C7.48578 6.38525 6.15234 7.71869 6.15234 9.36357C6.15234 11.0084 7.48578 12.3419 9.13066 12.3419Z"
        />
        <path
          fill="white"
          d="M9.13088 10.9509C8.25582 10.9509 7.54395 10.2389 7.54395 9.36386C7.54395 8.48879 8.25588 7.77686 9.13088 7.77686C10.0059 7.77686 10.7179 8.48879 10.7179 9.36386C10.7178 10.2389 10.0058 10.9509 9.13088 10.9509Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTaiwan;
