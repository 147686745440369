const RoundZambia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#F93939" d="M22.2609 16H16V32C17.8702 32 19.6651 31.6781 21.3333 31.0884L22.2609 16Z" />
        <path fill="#FF9811" d="M26.6668 27.9246C29.9398 24.9949 32.0001 20.7383 32.0001 16H25.7393L26.6668 27.9246Z" />
        <path fill="black" d="M21.333 16V31.0884C23.3203 30.3858 25.1274 29.3021 26.6664 27.9246V16H21.333Z" />
        <path
          fill="#FF9811"
          d="M29.5651 10.4348H25.3911C25.3911 9.66639 24.7682 9.04346 23.9998 9.04346C23.2314 9.04346 22.6085 9.66639 22.6085 10.4348H18.4346C18.4346 11.2032 19.1039 11.8261 19.8722 11.8261H19.8259C19.8259 12.5945 20.4487 13.2174 21.2172 13.2174C21.2172 13.9858 21.84 14.6087 22.6085 14.6087H25.3911C26.1595 14.6087 26.7824 13.9858 26.7824 13.2174C27.5508 13.2174 28.1738 12.5945 28.1738 11.8261H28.1274C28.8958 11.826 29.5651 11.2031 29.5651 10.4348Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundZambia;
