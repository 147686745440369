const RoundMoldova = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M22.9567 1.58763C20.8532 0.5705 18.4933 0 16.0002 0C13.507 0 11.1472 0.5705 9.04366 1.58763L7.65234 16L9.04366 30.4124C11.1472 31.4295 13.507 32 16.0002 32C18.4933 32 20.8532 31.4295 22.9567 30.4124L24.348 16L22.9567 1.58763Z"
        />
        <path
          fill="#1A47B8"
          d="M9.0435 1.58813C3.69163 4.17607 0 9.65664 0 15.9999C0 22.3433 3.69163 27.8238 9.0435 30.4118V1.58813Z"
        />
        <path
          fill="#F93939"
          d="M22.9561 1.58813V30.4118C28.3079 27.8238 31.9996 22.3433 31.9996 15.9999C31.9996 9.65664 28.3079 4.17607 22.9561 1.58813Z"
        />
        <path
          fill="#FF9811"
          d="M21.5649 12.5887H17.7389C17.7389 11.6282 16.9603 10.8496 15.9998 10.8496C15.0393 10.8496 14.2606 11.6282 14.2606 12.5887H10.4346C10.4346 13.5344 11.2584 14.301 12.2039 14.301H12.1469C12.1469 15.2467 12.9136 16.0134 13.8593 16.0134C13.8593 16.8507 14.4607 17.5465 15.2549 17.6952L13.9056 20.7416C14.5521 21.0044 15.2588 21.1505 15.9998 21.1505C16.7407 21.1505 17.4474 21.0044 18.0939 20.7416L16.7446 17.6952C17.5388 17.5465 18.1402 16.8507 18.1402 16.0134C19.0859 16.0134 19.8526 15.2467 19.8526 14.301H19.7955C20.7412 14.301 21.5649 13.5344 21.5649 12.5887Z"
        />
        <path fill="#1A47B8" d="M16.0001 14.9565L13.7393 16V18.087L16.0001 19.4783L18.261 18.087V16L16.0001 14.9565Z" />
        <path fill="#F93939" d="M13.7393 13.9131H18.261V16H13.7393V13.9131Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMoldova;
