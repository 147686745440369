import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" ref={ref} {...props}>
    <g fill={props?.fill || '#fff'} stroke={props?.stroke || '#383838'} strokeLinecap="round" strokeLinejoin="round">
      <path
        className="circle"
        d="M12 22.714c5.917 0 10.714-4.797 10.714-10.715 0-5.917-4.797-10.714-10.714-10.714C6.083 1.285 1.286 6.082 1.286 12c0 5.918 4.797 10.715 10.714 10.715Z"
      />
      <path
        className="employeeOutline"
        d="M12 12.994a3.749 3.749 0 1 0 0-7.498 3.749 3.749 0 0 0 0 7.498ZM18.35 20.63A10.667 10.667 0 0 1 12 22.714c-2.377 0-4.573-.774-6.35-2.084a6.57 6.57 0 0 1 12.7 0Z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconEmployee = memo(ForwardRef);
export default IconEmployee;
