const RoundFaroeIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M31.3044 15.9999L31.8644 13.913C31.7714 13.2017 31.6319 12.5049 31.4489 11.826H14.6087V0.060791C13.8987 0.121916 13.2022 0.229978 12.5218 0.380853L9.73918 2.08685L8.34787 1.94642C7.61568 2.34592 6.91812 2.80092 6.26093 3.30585V11.826H0.551242C0.368305 12.5049 0.228805 13.2016 0.135805 13.9129L0.69568 15.9999L0.135742 18.0869C0.228742 18.7982 0.368242 19.4949 0.55118 20.1738H6.26087V28.6939C6.91805 29.1989 7.61568 29.6539 8.3478 30.0534L10.4347 29.9129L12.5217 31.6189C13.2021 31.7697 13.8987 31.8778 14.6086 31.939V20.1739H31.4487C31.6317 19.495 31.7712 18.7982 31.8642 18.0869L31.3044 15.9999Z"
        />
        <path
          fill="#F93939"
          d="M31.8646 13.9131H12.5218H12.5218V0.380371C11.0431 0.708246 9.64131 1.23968 8.34781 1.9455V13.9131H0.135438C0.0465 14.5962 0 15.2927 0 16.0001C0 16.7074 0.0465 17.4039 0.135438 18.087H8.34775H8.34781V30.0546C9.64131 30.7603 11.0431 31.2919 12.5218 31.6197V18.0872V18.0871H31.8646C31.9535 17.4039 32 16.7074 32 16.0001C32 15.2927 31.9535 14.5962 31.8646 13.9131Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundFaroeIslands;
