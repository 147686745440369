const RoundComoros = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M31.9995 15.9536H14.6551c-3.4435 3.4436-5.52592 6.1022-7.65217 8.2285l9.04347.815 14.0334-1.3914c1.2247-2.2621 1.9203-4.8527 1.9203-7.6058 0-.0155-.0006-.0308-.0006-.0463Z"
        />
        <path
          fill="#FFDA2C"
          d="M7.00265 8.3015H30.0284C27.3073 3.3535 22.0454 0 15.9997 0 11.5931 0 7.6034 1.78194 4.70996 4.664l2.29269 3.6375Z"
        />
        <path
          fill="#1A47B8"
          d="M7.699 23.6057c-1.43837 1.4384-1.99425 2.6435-3.03494 3.6841 2.89719 2.9092 6.90624 4.7101 11.33634 4.7101 6.0835 0 11.3738-3.3953 14.0798-8.3942H7.699Z"
        />
        <path
          fill="#6DA544"
          d="M4.68628 4.63989c-6.24837 6.24841-6.24837 16.37911 0 22.62751C7.26834 24.6853 9.75166 22.202 16 15.9536L4.68628 4.63989Z"
        />
        <path
          fill="#fff"
          d="M4.22079 15.9538c0-2.3311 1.63831-4.2786 3.82606-4.7563-.33625-.0735-.68512-.1133-1.0435-.1133-2.68944 0-4.86956 2.1802-4.86956 4.8696s2.18012 4.8696 4.86956 4.8696c.35831 0 .70719-.0399 1.0435-.1133-2.18775-.4778-3.82606-2.4252-3.82606-4.7563Z"
        />
        <path
          fill="#fff"
          d="m7.9927 11.7798.25906.7971h.83812l-.67812.4927.25906.7971-.67812-.4926-.67813.4926.25906-.7971-.67812-.4927h.83812l.25907-.7971ZM7.9927 13.8667l.25906.7972h.83812l-.67812.4926.25906.7972-.67812-.4927-.67813.4927.25906-.7972-.67812-.4926h.83812l.25907-.7972ZM7.9927 15.9539l.25906.7971h.83812l-.67812.4927.25906.7971-.67812-.4927-.67813.4927.25906-.7971-.67812-.4927h.83812l.25907-.7971ZM7.9927 18.0408l.25906.7971h.83812l-.67812.4927.25906.7971-.67812-.4926-.67813.4926.25906-.7971-.67812-.4927h.83812l.25907-.7971Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundComoros;
