const rangeHorus = (saveDate: Date, actualDate: Date) => {
  if (saveDate && actualDate) {
    const diff = actualDate.getTime() - saveDate.getTime();
    const seconds = diff / 1000;
    const minuts = seconds / 60;
    const hours = minuts / 60;
    return Math.floor(hours);
  }
  return 0;
};

export { rangeHorus };
