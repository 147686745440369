const SquarePapuaNewGuinea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#AF010D" rx="2" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M12.5718 3.19997L14.667 5.3333C14.667 5.3333 12.5718 4.26663 11.5242 5.3333L10.4766 6.39997H13.6194L18.8575 9.59997L16.7623 6.39997H18.8575C18.8575 6.39997 18.8575 5.3333 17.8099 4.26663C16.7623 3.19997 15.7147 3.19997 15.7147 3.19997V2.1333L14.667 3.19997H12.5718Z"
            clipRule="evenodd"
          />
          <path fill="#151515" fillRule="evenodd" d="M0 0L22 16H0V0Z" clipRule="evenodd" />
          <path
            fill="white"
            d="M5.76209 8.53346C6.05138 8.53346 6.2859 8.29468 6.2859 8.00013 6.2859 7.70558 6.05138 7.4668 5.76209 7.4668 5.4728 7.4668 5.23828 7.70558 5.23828 8.00013 5.23828 8.29468 5.4728 8.53346 5.76209 8.53346ZM3.66639 10.6668C3.95568 10.6668 4.1902 10.428 4.1902 10.1334 4.1902 9.83888 3.95568 9.6001 3.66639 9.6001 3.3771 9.6001 3.14258 9.83888 3.14258 10.1334 3.14258 10.428 3.3771 10.6668 3.66639 10.6668ZM7.85682 10.6668C8.14611 10.6668 8.38063 10.428 8.38063 10.1334 8.38063 9.83888 8.14611 9.6001 7.85682 9.6001 7.56753 9.6001 7.33301 9.83888 7.33301 10.1334 7.33301 10.428 7.56753 10.6668 7.85682 10.6668ZM5.76209 13.8665C6.05138 13.8665 6.2859 13.6277 6.2859 13.3331 6.2859 13.0386 6.05138 12.7998 5.76209 12.7998 5.4728 12.7998 5.23828 13.0386 5.23828 13.3331 5.23828 13.6277 5.4728 13.8665 5.76209 13.8665ZM6.80994 12.8001C7.09923 12.8001 7.33375 12.5613 7.33375 12.2667 7.33375 11.9722 7.09923 11.7334 6.80994 11.7334 6.52065 11.7334 6.28613 11.9722 6.28613 12.2667 6.28613 12.5613 6.52065 12.8001 6.80994 12.8001Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquarePapuaNewGuinea;
