import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

const createFirebaseUser = async (email: string, password: string) => {
  const auth = getAuth();
  const { currentUser } = auth;
  const formatEmail = email.toLowerCase();
  if (!currentUser?.isAnonymous && currentUser?.email && formatEmail === currentUser?.email) {
    return currentUser;
  }
  return createUserWithEmailAndPassword(auth, email, password)
    .then(userCredential => {
      const user = userCredential.user;
      return user;
    })
    .catch(error => {
      const errorCode = error.code;
      const errorMessage = error.message;
      throw { error: errorCode, errorMessage };
    });
};

export default createFirebaseUser;
