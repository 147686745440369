const RoundAlgeria = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 0c8.8365 0 16 7.1635 16 16 0 8.8365-7.1635 16-16 16 0-.6956-2.0869-16-2.0869-16L16 0Z"
        />
        <path fill="#496E2D" d="M16 32C7.1635 32 0 24.8365 0 16 0 7.1635 7.1635 0 16 0" />
        <path
          fill="#F93939"
          d="M19.4377 12.9309 18.125 14.74l-2.1261-.6895 1.3149 1.8075-1.3126 1.809 2.1252-.6919 1.3149 1.8074-.0014-2.235 2.1253-.692-2.126-.6894-.0015-2.2352Z"
        />
        <path
          fill="#F93939"
          d="M17.3273 20.5217c-2.4972 0-4.5217-2.0245-4.5217-4.5218 0-2.4972 2.0245-4.5217 4.5217-4.5217.7786 0 1.5113.1969 2.151.5435-1.0035-.9813-2.3758-1.587-3.8901-1.587-3.0736 0-5.5652 2.4916-5.5652 5.5652 0 3.0735 2.4916 5.5652 5.5652 5.5652 1.5144 0 2.8866-.6057 3.8901-1.587-.6397.3467-1.3724.5436-2.151.5436Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAlgeria;
