const SquareSaintVicentAndTheGrenadines = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H6V16H0V0Z" clipRule="evenodd" />
          <path fill="#0A6A30" fillRule="evenodd" d="M16 0H22V16H16V0Z" clipRule="evenodd" />
          <path
            fill="#0A6A30"
            d="M9.2 5 8 7.33333 9.2 9.66667 10.4 7.33333 9.2 5ZM12.8 5 11.6 7.33333 12.8 9.66667 14 7.33333 12.8 5ZM11 7.33333 9.8 9.66667 11 12 12.2 9.66667 11 7.33333Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSaintVicentAndTheGrenadines;
