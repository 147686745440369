const RoundGambia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M15.9997 0C9.38505 0 3.7083 4.01413 1.27148 9.73913H30.728C28.2912 4.01413 22.6144 0 15.9997 0Z"
        />
        <path
          fill="#496E2D"
          d="M15.9997 32.0001C22.6144 32.0001 28.2912 27.986 30.728 22.261H1.27148C3.7083 27.986 9.38505 32.0001 15.9997 32.0001Z"
        />
        <path
          fill="#1A47B8"
          d="M31.4488 11.8262H0.551188C0.1925 13.157 0 14.5559 0 16.0001C0 17.4443 0.1925 18.8432 0.551188 20.174H31.4489C31.8075 18.8432 32 17.4443 32 16.0001C32 14.5559 31.8075 13.157 31.4488 11.8262Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGambia;
