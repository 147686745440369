import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getDefaultLanguage } from '../utils/getDefaultLanguage';
import esMX from './languages/es-MX.json';
import enUS from './languages/en-US.json';

const initI18n = () => {
  const locale = getDefaultLanguage();
  const loadDefault = locale === 'esMX' ? esMX : enUS;
  const settings = {
    lng: locale,
    resources: {
      [locale]: { ...loadDefault }
    },
    debug: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    }
  };
  i18next.use(initReactI18next).init(settings);
  return locale;
};

export { initI18n };
