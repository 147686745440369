import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ICountryDetail } from '../types/countries';
import { ICoordinates, IdeliveryAddress } from '../types/global';

const isEmailValid = (email: string) => {
  const regex =
    /^(([^<>()[\].,;:\s@áéíóúÁÉÍÓÚüÜñ"]+(\.[^<>()[\].,;:\s@áéíóúÁÉÍÓÚüÜñ"]+)*)|(".+"))@(([^<>()[\].,;:\s@áéíóúÁÉÍÓÚüÜñ"]+\.)+[^<>()[\].,;:\s@áéíóúÁÉÍÓÚüÜñ"]{2,})$/i;
  return regex.test(email);
};

const isPhoneValid = (phone?: string) => /^[0-9]{6,13}$/.test(phone || '');

const isPasswordValid = (password: string) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&.-=])([A-Za-z\d$@$!%*?&.-=]|[^ ]){8,15}$/;
  return regex.test(password);
};

const isRFCValid = (RFC: string) => {
  const regex = /^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3,4}$/i;
  return regex.test(RFC);
};

const exceedsMinLength = (inputString: string, minLength: number) => {
  return inputString.trim().length >= minLength;
};

const getAddressErrorText = (
  selectedCountryCode?: string,
  selectedCountryName?: string,
  addressCountryCode?: string,
  requireCoordinates?: boolean,
  coordinates?: ICoordinates | null
) => {
  if (requireCoordinates && !coordinates) {
    return i18next?.t('services:unassignmentModule:shipmentInfoForm:adressError');
  }
  if (!selectedCountryCode || !selectedCountryName || !addressCountryCode) return;
  if (selectedCountryCode !== addressCountryCode)
    return `Esta dirección no pertenece a ${selectedCountryName || 'este país'}`;
  return;
};

const addressExistInCorrectFormat = (employeeAddress?: IdeliveryAddress | null) =>
  !!employeeAddress &&
  !!employeeAddress?.address &&
  typeof employeeAddress?.address === 'string' &&
  !!employeeAddress?.country &&
  !!employeeAddress?.city;

const isTaxDocumentByCountryValid = (countryData?: ICountryDetail, taxDocument?: string) => {
  if (!countryData || !taxDocument) return false;
  const taxCodeRegEx = countryData?.documentRegEx ? new RegExp(countryData?.documentRegEx, 'i') : /^.{5,}$/;
  return !!taxCodeRegEx && taxCodeRegEx.test(taxDocument);
};

const getTaxCodeErrorByCountry = (countryData?: ICountryDetail, taxDocument?: string) => {
  if (!countryData || !taxDocument) return;
  const expectedTaxCodePattern = countryData?.documentExample;
  const taxCodeRegEx = countryData?.documentRegEx ? new RegExp(countryData?.documentRegEx, 'i') : /^.{5,}$/;
  const isTaxCodeValid = !!taxCodeRegEx && taxCodeRegEx.test(taxDocument);
  if (countryData?.documentRegEx && expectedTaxCodePattern && taxDocument.length > 0 && !isTaxCodeValid)
    return `${i18next?.t('authentication:SignUpForm:formErrors:taxCodeErrorCodePattern')}: ${expectedTaxCodePattern}`;
  if (!countryData?.documentRegEx && taxDocument?.length > 0 && !isTaxCodeValid)
    return i18next?.t('authentication:SignUpForm:formErrors:taxCodeErroLength');
  return;
};

const isValidLink = (link?: string) => {
  if (!link) return false;
  const linkRegex = /^https:\/{2}.*\..*$/g;
  return linkRegex.test(link);
};

const getIdDocumentData = (countryDetail?: ICountryDetail, personalId?: string) => {
  const { t } = useTranslation();
  const { code, name } = countryDetail || {};
  const brazileanDocumentRegex = /^(\d{3}\.){2}\d{3}-\d{2}$/g;
  const countryCode = name === 'Brasil' ? 'br' : code;

  const formatBrazilianIdDocument = (id: string) => {
    const cleanedId = id.replace(/\D/g, '');
    if (cleanedId.length <= 3) return cleanedId;
    if (cleanedId.length <= 6) return `${cleanedId.slice(0, 3)}.${cleanedId.slice(3)}`;
    if (cleanedId.length <= 9) return `${cleanedId.slice(0, 3)}.${cleanedId.slice(3, 6)}.${cleanedId.slice(6)}`;
    return `${cleanedId.slice(0, 3)}.${cleanedId.slice(3, 6)}.${cleanedId.slice(6, 9)}-${cleanedId.slice(9)}`;
  };

  const formattedId = formatBrazilianIdDocument(personalId || '');
  const personalIdIsValid = formattedId?.length === 14 && brazileanDocumentRegex.test(formattedId || '');

  const getIdDocumentErrorText = () => {
    if (code !== 'br') return '';
    if (!personalId) return t('recurrentWords:emptyIdDocumentCPFError');
    if (!personalIdIsValid) return t('recurrentWords:personalIdIsNotValid');
  };

  switch (countryCode) {
    case 'br':
      return {
        value: formattedId,
        label: t('recurrentWords:idDocumentCPFLabel'),
        placeholder: t('recurrentWords:idDocumentCPFPlaceholder'),
        caption: t('recurrentWords:confirmationCPFText'),
        errorText: getIdDocumentErrorText()
      };
    default:
      return {
        value: personalId,
        label: t('recurrentWords:idDocumentLabel'),
        placeholder: t('recurrentWords:idDocumentPlaceholder'),
        //No default caption and errorText because is not requiered, just requiered in br cases
        caption: undefined,
        errorText: undefined
      };
  }
};

export {
  isEmailValid,
  isPhoneValid,
  isPasswordValid,
  isRFCValid,
  addressExistInCorrectFormat,
  exceedsMinLength,
  getAddressErrorText,
  isTaxDocumentByCountryValid,
  getTaxCodeErrorByCountry,
  isValidLink,
  getIdDocumentData
};
