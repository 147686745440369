const RoundSlovakia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M16 32C24.8365 32 32 24.8365 32 16C32 14.0429 31.648 12.1681 31.0048 10.4348H0.995188C0.352063 12.1681 0 14.0429 0 16C0 24.8365 7.1635 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="white"
          d="M4.1377 9.04346V17.0251C4.1377 21.5653 10.069 22.9565 10.069 22.9565C10.069 22.9565 16.0003 21.5653 16.0003 17.0251V9.04346H4.1377Z"
        />
        <path
          fill="#F93939"
          d="M5.52832 9.04346V17.0251C5.52832 17.5581 5.6467 18.0615 5.8812 18.5334H14.2554C14.4899 18.0615 14.6083 17.5581 14.6083 17.0251V9.04346H5.52832Z"
        />
        <path
          fill="white"
          d="M12.8513 14.6087H10.7644V13.2174H12.1558V11.8261H10.7644V10.4348H9.37313V11.8261H7.98188V13.2174H9.37313V14.6087H7.28613V16H9.37313V17.3913H10.7644V16H12.8513V14.6087Z"
        />
        <path
          fill="#1A47B8"
          d="M7.77952 20.4757C8.67609 21.0392 9.59721 21.3663 10.0688 21.5118C10.5403 21.3664 11.4615 21.0392 12.358 20.4757C13.2621 19.9075 13.896 19.2576 14.2558 18.5336C13.8589 18.2527 13.3746 18.0871 12.8514 18.0871C12.6609 18.0871 12.4758 18.1097 12.2979 18.1511C11.9208 17.2944 11.0649 16.6958 10.0688 16.6958C9.07277 16.6958 8.21677 17.2944 7.83977 18.1511C7.66184 18.1097 7.47671 18.0871 7.28627 18.0871C6.76309 18.0871 6.27877 18.2527 5.88184 18.5336C6.24152 19.2576 6.87534 19.9074 7.77952 20.4757Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSlovakia;
