import { IdateRange, IestimatedDeliveryTime } from '../../../../../../types/logisticsServicesModule.types';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';

const noDataText = 'Sin datos';

export const getDateText = (dateRange?: IdateRange, timeZone?: number) => {
  const sinceDate = dateRange?.since ? formatShortDate(dateRange.since, timeZone)?.onlyDate : undefined;
  const untilDate = dateRange?.until ? formatShortDate(dateRange.until, timeZone)?.onlyDate : undefined;
  if (sinceDate && untilDate) return `${sinceDate || ''} - ${untilDate || ''}`;
  if (untilDate) return `${untilDate || ''}`;
  if (sinceDate) return `${sinceDate || ''}`;
  return noDataText;
};

export const getEstimatedDeliveryTimeText = (
  businessDays: string,
  estimatedDeliveryTime?: IestimatedDeliveryTime | null
) => {
  const estimatedDeliveryTimeText =
    estimatedDeliveryTime && estimatedDeliveryTime?.since
      ? `${estimatedDeliveryTime?.since}${
          estimatedDeliveryTime?.until ? ` - ${estimatedDeliveryTime?.until}` : ''
        } ${businessDays}`
      : noDataText;
  return estimatedDeliveryTimeText;
};
