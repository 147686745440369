import { FC } from 'react';
import './BodyAlert.scss';

interface IPrincipalList {
  titleList: string;
  subList?: string[];
}

interface IBodyAlert {
  title: string;
  principalList: IPrincipalList[];
}

interface IPrincipalBodyAlert {
  data: IBodyAlert;
}

const BodyAlert: FC<IPrincipalBodyAlert> = ({ data }: IPrincipalBodyAlert) => {
  return (
    <div id="bodyAlert">
      <div className="titleAlertList">{data?.title}</div>
      {data?.principalList && data?.principalList.length > 0 && (
        <div className="containerAlertList">
          {data?.principalList.map((item, index) => {
            return (
              <div className="boxAlertList" key={`principal-list-item-${item.titleList}-${index}`}>
                <div className="spacePoint">
                  <div className="minPoint" />
                </div>
                <div className="subtitleAlertList">
                  {item.titleList}
                  {item?.subList && item?.subList.length > 0 && (
                    <div className="containerSubList">
                      {item.subList.map((item, index) => {
                        return (
                          <div key={`item-sublist-${item}-index-${index}`} className="itemsSubList">
                            <div className="spacePoint">
                              <div className="minPoint" />
                            </div>
                            {item}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BodyAlert;
