import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import { additionalDaysForUnassignmentCollectionDate } from '../../../../../../utils/productDefinitions';
import { getDateText, getEstimatedDeliveryTimeText } from './LogisticServiceTrackingSection.utils';
import { NudosStaticAlertInComponent } from '../../../../../../components/DesignSystem';

const LogisticServiceInitialTrackingData = ({
  logisticServiceData
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
}) => {
  const { t } = useTranslation();
  const { chosenCollectionDate, chosenDeliveryDate, collection, delivery, estimatedDeliveryTime } =
    logisticServiceData || {};
  const translationKey = 'nodi:logisticServices:logisticServiceTrackingSection:';

  const estimatedUpperLimitForChosenCollectionDate = chosenCollectionDate
    ? DateTime.fromISO(chosenCollectionDate).plus({ days: additionalDaysForUnassignmentCollectionDate }).toISODate()
    : undefined;
  const estimatedUpperLimitForChosenDeliveryDate = chosenDeliveryDate
    ? DateTime.fromISO(chosenDeliveryDate).plus({ days: additionalDaysForUnassignmentCollectionDate }).toISODate()
    : undefined;

  const showOfficeAndOtherLocationsTimeAlert =
    ['office', 'other'].includes(collection?.place || '') &&
    ['office', 'other', 'user'].includes(delivery?.place || '');

  const defaultTimeAlertText = t(`${translationKey}defaultTimeAlertText`);
  const dataCollectionTitle = t(`${translationKey}dataCollectionTitle`);
  const dataDeliveryTitle = t(`${translationKey}dataDeliveryTitle`);

  const assignmentSummaryTimeAlertText = t(`${translationKey}assignmentSummaryTimeAlertText`);
  const collectionTimeZone = collection?.country?.timeZone || undefined;
  const deliveryTimeZone = delivery?.country?.timeZone || undefined;
  const businessDays = t(`recurrentWords:businessDays`);
  const estimatedDeliveryTimeText = getEstimatedDeliveryTimeText(businessDays, estimatedDeliveryTime);

  const officeAndOtherLocationsText = (
    <div className="assignmentSummaryTimeAlert">
      <div>{defaultTimeAlertText}</div>
      <div>{assignmentSummaryTimeAlertText}</div>
    </div>
  );

  const appropriateAlertText = showOfficeAndOtherLocationsTimeAlert
    ? officeAndOtherLocationsText
    : defaultTimeAlertText;

  return (
    <div className="logisticServiceInitialTrackingData">
      <section className="initialEstimatedDatesSection">
        {chosenCollectionDate && (
          <div className="dataField">
            <h3 className="dataTitle">{dataCollectionTitle}</h3>
            <div className="dataContent">
              {getDateText(
                chosenCollectionDate && estimatedUpperLimitForChosenCollectionDate
                  ? { since: chosenCollectionDate, until: estimatedUpperLimitForChosenCollectionDate }
                  : undefined,
                collectionTimeZone
              )}
            </div>
          </div>
        )}
        {chosenDeliveryDate && (
          <div className="dataField">
            <h3 className="dataTitle">{dataDeliveryTitle}</h3>
            <div className="dataContent">
              {getDateText(
                { since: chosenDeliveryDate, until: estimatedUpperLimitForChosenDeliveryDate },
                deliveryTimeZone
              )}
            </div>
          </div>
        )}
        {!chosenDeliveryDate && (
          <div className="dataField">
            <h3 className="dataTitle">{dataDeliveryTitle}</h3>
            <div className="dataContent">{estimatedDeliveryTimeText}</div>
          </div>
        )}
      </section>
      <NudosStaticAlertInComponent
        type="time"
        alertOptions={{
          title: appropriateAlertText
        }}
      />
    </div>
  );
};

export default LogisticServiceInitialTrackingData;
