import type { FC } from 'react';
import Circle from './components/Circle/Circle';
import { STATUS_VARIANTS, statusVariants } from './BordStatus.type';
import '../BordStatus/BordStatus.scss';

interface IBordStatusProps {
  label?: string;
  variant?: statusVariants;
  renderJSX?: JSX.Element;
}

const BordStatus: FC<IBordStatusProps> = ({ variant = 'default', label = 'Label', renderJSX }) => {
  const renderVariants = {
    [STATUS_VARIANTS.default]: 'border-disabled-fg-secondary',
    [STATUS_VARIANTS.informative]: 'border-informative-bg-primary',
    [STATUS_VARIANTS.success]: 'border-success',
    [STATUS_VARIANTS.danger]: 'border-danger'
  };

  const renderContent = renderJSX ? renderJSX : <span>{label}</span>;

  return (
    <div className={`bordStatusContainer ${renderVariants[variant]}`}>
      <Circle variant={variant} />
      {renderContent}
    </div>
  );
};

export default BordStatus;
