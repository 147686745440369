export type TbordOneToneIcon =
  | 'activity'
  | 'airplay'
  | 'alertCircleWarning'
  | 'alertOctagonWarning'
  | 'alertTriangleWarning'
  | 'alignCenter'
  | 'alignJustify'
  | 'alignLeft'
  | 'alignRight'
  | 'anchor'
  | 'aperture'
  | 'archive'
  | 'arrowDownCircle'
  | 'arrowDownLeft'
  | 'arrowDownRight'
  | 'arrowDown'
  | 'arrowLeftCircle'
  | 'arrowLeft'
  | 'arrowRightCircle'
  | 'arrowRight'
  | 'arrowUpCircle'
  | 'arrowUpLeft'
  | 'arrowUpRight'
  | 'arrowUp'
  | 'arrowHeadUp'
  | 'arrowHeadDown'
  | 'arrowHeadLeft'
  | 'arrowHeadRight'
  | 'atSign'
  | 'award'
  | 'barChart2'
  | 'barChart'
  | 'barCode2'
  | 'batteryCharging'
  | 'battery'
  | 'bluetooth'
  | 'bold'
  | 'bookOpen'
  | 'book'
  | 'bookmark'
  | 'bord'
  | 'box'
  | 'briefcase'
  | 'building3'
  | 'calendar'
  | 'cameraOff'
  | 'camera'
  | 'cast'
  | 'checkCircle'
  | 'checkSquare'
  | 'check'
  | 'chevronsDown'
  | 'chevronsLeft'
  | 'chevronsRight'
  | 'chevronsUp'
  | 'chrome'
  | 'circle'
  | 'clipboard'
  | 'clock'
  | 'cloudDrizzle'
  | 'cloudLightning'
  | 'cloudOff'
  | 'cloudRain'
  | 'cloudSnow'
  | 'cloud'
  | 'code'
  | 'codepen'
  | 'codesandbox'
  | 'coffee'
  | 'columns'
  | 'command'
  | 'compass'
  | 'copy'
  | 'cornerDownLeft'
  | 'cornerDownRight'
  | 'cornerLeftDown'
  | 'cornerLeftUp'
  | 'cornerRightDown'
  | 'cornerRightUp'
  | 'cornerUpLeft'
  | 'cornerUpRight'
  | 'cpu'
  | 'creditCard'
  | 'crop'
  | 'crosshair'
  | 'database'
  | 'delete'
  | 'disc'
  | 'dollarSign'
  | 'downloadCloud'
  | 'download'
  | 'droplet'
  | 'edit2'
  | 'edit3'
  | 'edit'
  | 'externalLink'
  | 'eyeOff'
  | 'eye'
  | 'facebook'
  | 'fastForward'
  | 'feather'
  | 'figma1'
  | 'figma'
  | 'fileMinus'
  | 'filePlus'
  | 'fileText'
  | 'file'
  | 'film'
  | 'filterMailCircle'
  | 'filter'
  | 'flag'
  | 'folderMinus'
  | 'folderPlus'
  | 'folder'
  | 'framer'
  | 'frown'
  | 'gift'
  | 'gitBranch'
  | 'gitCommit'
  | 'gitPullRequest'
  | 'github'
  | 'gitlab'
  | 'globe'
  | 'grid'
  | 'hardDrive'
  | 'hash'
  | 'headphones'
  | 'heart'
  | 'helpCircle'
  | 'hexagon'
  | 'home'
  | 'image'
  | 'inbox'
  | 'info'
  | 'instagram'
  | 'italic'
  | 'key'
  | 'layers'
  | 'layout'
  | 'lifeBuoy'
  | 'link2'
  | 'link'
  | 'linkedin'
  | 'list'
  | 'loader'
  | 'lock'
  | 'logIn'
  | 'logOut'
  | 'logoMaster'
  | 'mail'
  | 'mapPin'
  | 'map'
  | 'maximize2'
  | 'maximize'
  | 'meh'
  | 'menu'
  | 'messageCircle'
  | 'messageSquare'
  | 'micOff'
  | 'mic'
  | 'minimize2'
  | 'minimize'
  | 'minusCircle'
  | 'minusSquare'
  | 'minus'
  | 'monitor'
  | 'moon'
  | 'moreHorizontal'
  | 'moreVertical'
  | 'mousePointer'
  | 'move'
  | 'music'
  | 'navigation2'
  | 'navigation'
  | 'notificationOff'
  | 'notification'
  | 'octagon'
  | 'package'
  | 'paperclip'
  | 'pauseCircle'
  | 'pause'
  | 'penTool'
  | 'percent'
  | 'phoneCall'
  | 'phoneForwarded'
  | 'phoneIncoming'
  | 'phoneMissed'
  | 'phoneOff'
  | 'phoneOutgoing'
  | 'phone'
  | 'pieChart'
  | 'playCircle'
  | 'play'
  | 'plusCircle'
  | 'plusSquare'
  | 'plus'
  | 'pocket'
  | 'power'
  | 'printer'
  | 'radio'
  | 'refreshCcw'
  | 'refreshCw'
  | 'repeat'
  | 'rewind'
  | 'rotateCcw'
  | 'rotateCw'
  | 'rss'
  | 'save'
  | 'scissors'
  | 'search'
  | 'send'
  | 'server'
  | 'settings'
  | 'share2'
  | 'share'
  | 'shieldOff'
  | 'shield'
  | 'shoppingBag'
  | 'shoppingCart'
  | 'shuffle'
  | 'sidebar'
  | 'skipBack'
  | 'skipForward'
  | 'slack'
  | 'slash'
  | 'sliders'
  | 'smartphone'
  | 'smile'
  | 'speaker'
  | 'square'
  | 'star'
  | 'stopCircle'
  | 'sun'
  | 'sunrise'
  | 'sunset'
  | 'tablet'
  | 'tag'
  | 'target'
  | 'terminal'
  | 'thermometer'
  | 'thumbsDown'
  | 'thumbsUp'
  | 'toggleLeft'
  | 'toggleRight'
  | 'tool'
  | 'trash2'
  | 'trash'
  | 'trello'
  | 'trendingDown'
  | 'trendingUp'
  | 'triangle'
  | 'truck'
  | 'tv'
  | 'twitch'
  | 'twitter'
  | 'type'
  | 'umbrella'
  | 'underline'
  | 'unlock'
  | 'uploadCloud'
  | 'upload'
  | 'userAdd'
  | 'userCheck'
  | 'userMinus'
  | 'userPlus'
  | 'userRemove'
  | 'userX'
  | 'user'
  | 'users'
  | 'videoOff'
  | 'video'
  | 'voicemail'
  | 'volume1'
  | 'volume2'
  | 'volumeX'
  | 'volume'
  | 'watch'
  | 'wifiOff'
  | 'wifi'
  | 'wind'
  | 'wiping'
  | 'xCircle'
  | 'xOctagon'
  | 'xSquare'
  | 'x'
  | 'youtube'
  | 'zapOff'
  | 'zap'
  | 'zoomIn'
  | 'zoomOut';

export enum BORD_ONE_TONE_ICONS {
  'activity' = 'activity',
  'airplay' = 'airplay',
  'alertCircleWarning' = 'alertCircleWarning',
  'alertOctagonWarning' = 'alertOctagonWarning',
  'alertTriangleWarning' = 'alertTriangleWarning',
  'alignCenter' = 'alignCenter',
  'alignJustify' = 'alignJustify',
  'alignLeft' = 'alignLeft',
  'alignRight' = 'alignRight',
  'anchor' = 'anchor',
  'aperture' = 'aperture',
  'archive' = 'archive',
  'arrowDownCircle' = 'arrowDownCircle',
  'arrowDownLeft' = 'arrowDownLeft',
  'arrowDownRight' = 'arrowDownRight',
  'arrowDown' = 'arrowDown',
  'arrowLeftCircle' = 'arrowLeftCircle',
  'arrowLeft' = 'arrowLeft',
  'arrowRightCircle' = 'arrowRightCircle',
  'arrowRight' = 'arrowRight',
  'arrowUpCircle' = 'arrowUpCircle',
  'arrowUpLeft' = 'arrowUpLeft',
  'arrowUpRight' = 'arrowUpRight',
  'arrowHeadUp' = 'arrowHeadUp',
  'arrowHeadDown' = 'arrowHeadDown',
  'arrowHeadLeft' = 'arrowHeadLeft',
  'arrowHeadRight' = 'arrowHeadRight',
  'arrowUp' = 'arrowUp',
  'atSign' = 'atSign',
  'award' = 'award',
  'barChart2' = 'barChart2',
  'barChart' = 'barChart',
  'barCode2' = 'barCode2',
  'batteryCharging' = 'batteryCharging',
  'battery' = 'battery',
  'bluetooth' = 'bluetooth',
  'bold' = 'bold',
  'bookOpen' = 'bookOpen',
  'book' = 'book',
  'bookmark' = 'bookmark',
  'bord' = 'bord',
  'box' = 'box',
  'briefcase' = 'briefcase',
  'building3' = 'building3',
  'calendar' = 'calendar',
  'cameraOff' = 'cameraOff',
  'camera' = 'camera',
  'cast' = 'cast',
  'checkCircle' = 'checkCircle',
  'checkSquare' = 'checkSquare',
  'check' = 'check',
  'chevronsDown' = 'chevronsDown',
  'chevronsLeft' = 'chevronsLeft',
  'chevronsRight' = 'chevronsRight',
  'chevronsUp' = 'chevronsUp',
  'chrome' = 'chrome',
  'circle' = 'circle',
  'clipboard' = 'clipboard',
  'clock' = 'clock',
  'cloudDrizzle' = 'cloudDrizzle',
  'cloudLightning' = 'cloudLightning',
  'cloudOff' = 'cloudOff',
  'cloudRain' = 'cloudRain',
  'cloudSnow' = 'cloudSnow',
  'cloud' = 'cloud',
  'code' = 'code',
  'codepen' = 'codepen',
  'codesandbox' = 'codesandbox',
  'coffee' = 'coffee',
  'columns' = 'columns',
  'command' = 'command',
  'compass' = 'compass',
  'copy' = 'copy',
  'cornerDownLeft' = 'cornerDownLeft',
  'cornerDownRight' = 'cornerDownRight',
  'cornerLeftDown' = 'cornerLeftDown',
  'cornerLeftUp' = 'cornerLeftUp',
  'cornerRightDown' = 'cornerRightDown',
  'cornerRightUp' = 'cornerRightUp',
  'cornerUpLeft' = 'cornerUpLeft',
  'cornerUpRight' = 'cornerUpRight',
  'cpu' = 'cpu',
  'creditCard' = 'creditCard',
  'crop' = 'crop',
  'crosshair' = 'crosshair',
  'database' = 'database',
  'delete' = 'delete',
  'disc' = 'disc',
  'dollarSign' = 'dollarSign',
  'downloadCloud' = 'downloadCloud',
  'download' = 'download',
  'droplet' = 'droplet',
  'edit2' = 'edit2',
  'edit3' = 'edit3',
  'edit' = 'edit',
  'externalLink' = 'externalLink',
  'eyeOff' = 'eyeOff',
  'eye' = 'eye',
  'facebook' = 'facebook',
  'fastForward' = 'fastForward',
  'feather' = 'feather',
  'figma1' = 'figma1',
  'figma' = 'figma',
  'fileMinus' = 'fileMinus',
  'filePlus' = 'filePlus',
  'fileText' = 'fileText',
  'file' = 'file',
  'film' = 'film',
  'filterMailCircle' = 'filterMailCircle',
  'filter' = 'filter',
  'flag' = 'flag',
  'folderMinus' = 'folderMinus',
  'folderPlus' = 'folderPlus',
  'folder' = 'folder',
  'framer' = 'framer',
  'frown' = 'frown',
  'gift' = 'gift',
  'gitBranch' = 'gitBranch',
  'gitCommit' = 'gitCommit',
  'gitPullRequest' = 'gitPullRequest',
  'github' = 'github',
  'gitlab' = 'gitlab',
  'globe' = 'globe',
  'grid' = 'grid',
  'hardDrive' = 'hardDrive',
  'hash' = 'hash',
  'headphones' = 'headphones',
  'heart' = 'heart',
  'helpCircle' = 'helpCircle',
  'hexagon' = 'hexagon',
  'home' = 'home',
  'image' = 'image',
  'inbox' = 'inbox',
  'info' = 'info',
  'instagram' = 'instagram',
  'italic' = 'italic',
  'key' = 'key',
  'layers' = 'layers',
  'layout' = 'layout',
  'lifeBuoy' = 'lifeBuoy',
  'link2' = 'link2',
  'link' = 'link',
  'linkedin' = 'linkedin',
  'list' = 'list',
  'loader' = 'loader',
  'lock' = 'lock',
  'logIn' = 'logIn',
  'logOut' = 'logOut',
  'logoMaster' = 'logoMaster',
  'mail' = 'mail',
  'mapPin' = 'mapPin',
  'map' = 'map',
  'maximize2' = 'maximize2',
  'maximize' = 'maximize',
  'meh' = 'meh',
  'menu' = 'menu',
  'messageCircle' = 'messageCircle',
  'messageSquare' = 'messageSquare',
  'micOff' = 'micOff',
  'mic' = 'mic',
  'minimize2' = 'minimize2',
  'minimize' = 'minimize',
  'minusCircle' = 'minusCircle',
  'minusSquare' = 'minusSquare',
  'minus' = 'minus',
  'monitor' = 'monitor',
  'moon' = 'moon',
  'moreHorizontal' = 'moreHorizontal',
  'moreVertical' = 'moreVertical',
  'mousePointer' = 'mousePointer',
  'move' = 'move',
  'music' = 'music',
  'navigation2' = 'navigation2',
  'navigation' = 'navigation',
  'notificationOff' = 'notificationOff',
  'notification' = 'notification',
  'octagon' = 'octagon',
  'package' = 'package',
  'paperclip' = 'paperclip',
  'pauseCircle' = 'pauseCircle',
  'pause' = 'pause',
  'penTool' = 'penTool',
  'percent' = 'percent',
  'phoneCall' = 'phoneCall',
  'phoneForwarded' = 'phoneForwarded',
  'phoneIncoming' = 'phoneIncoming',
  'phoneMissed' = 'phoneMissed',
  'phoneOff' = 'phoneOff',
  'phoneOutgoing' = 'phoneOutgoing',
  'phone' = 'phone',
  'pieChart' = 'pieChart',
  'playCircle' = 'playCircle',
  'play' = 'play',
  'plusCircle' = 'plusCircle',
  'plusSquare' = 'plusSquare',
  'plus' = 'plus',
  'pocket' = 'pocket',
  'power' = 'power',
  'printer' = 'printer',
  'radio' = 'radio',
  'refreshCcw' = 'refreshCcw',
  'refreshCw' = 'refreshCw',
  'repeat' = 'repeat',
  'rewind' = 'rewind',
  'rotateCcw' = 'rotateCcw',
  'rotateCw' = 'rotateCw',
  'rss' = 'rss',
  'save' = 'save',
  'scissors' = 'scissors',
  'search' = 'search',
  'send' = 'send',
  'server' = 'server',
  'settings' = 'settings',
  'share2' = 'share2',
  'share' = 'share',
  'shieldOff' = 'shieldOff',
  'shield' = 'shield',
  'shoppingBag' = 'shoppingBag',
  'shoppingCart' = 'shoppingCart',
  'shuffle' = 'shuffle',
  'sidebar' = 'sidebar',
  'skipBack' = 'skipBack',
  'skipForward' = 'skipForward',
  'slack' = 'slack',
  'slash' = 'slash',
  'sliders' = 'sliders',
  'smartphone' = 'smartphone',
  'smile' = 'smile',
  'speaker' = 'speaker',
  'square' = 'square',
  'star' = 'star',
  'stopCircle' = 'stopCircle',
  'sun' = 'sun',
  'sunrise' = 'sunrise',
  'sunset' = 'sunset',
  'tablet' = 'tablet',
  'tag' = 'tag',
  'target' = 'target',
  'terminal' = 'terminal',
  'thermometer' = 'thermometer',
  'thumbsDown' = 'thumbsDown',
  'thumbsUp' = 'thumbsUp',
  'toggleLeft' = 'toggleLeft',
  'toggleRight' = 'toggleRight',
  'tool' = 'tool',
  'trash2' = 'trash2',
  'trash' = 'trash',
  'trello' = 'trello',
  'trendingDown' = 'trendingDown',
  'trendingUp' = 'trendingUp',
  'triangle' = 'triangle',
  'truck' = 'truck',
  'tv' = 'tv',
  'twitch' = 'twitch',
  'twitter' = 'twitter',
  'type' = 'type',
  'umbrella' = 'umbrella',
  'underline' = 'underline',
  'unlock' = 'unlock',
  'uploadCloud' = 'uploadCloud',
  'upload' = 'upload',
  'userAdd' = 'userAdd',
  'userCheck' = 'userCheck',
  'userMinus' = 'userMinus',
  'userPlus' = 'userPlus',
  'userRemove' = 'userRemove',
  'userX' = 'userX',
  'user' = 'user',
  'users' = 'users',
  'videoOff' = 'videoOff',
  'video' = 'video',
  'voicemail' = 'voicemail',
  'volume1' = 'volume1',
  'volume2' = 'volume2',
  'volumeX' = 'volumeX',
  'volume' = 'volume',
  'watch' = 'watch',
  'wifiOff' = 'wifiOff',
  'wifi' = 'wifi',
  'wind' = 'wind',
  'wiping' = 'wiping',
  'xCircle' = 'xCircle',
  'xOctagon' = 'xOctagon',
  'xSquare' = 'xSquare',
  'x' = 'x',
  'youtube' = 'youtube',
  'zapOff' = 'zapOff',
  'zap' = 'zap',
  'zoomIn' = 'zoomIn',
  'zoomOut' = 'zoomOut'
}

export interface IbordComponentColors {
  fill: string;
  stroke: string;
}
