import { Trans, useTranslation } from 'react-i18next';
import { CardIcon, TransferIcon } from '../assets/images/views/Ecommerce';
import { IInvoicePaymentMethods, IPaymentExtra } from '../types/cart';

interface IExtraData {
  [key: string]: IPaymentExtra;
}

export const getExtraPaymentMethods = (billingDataCountryCode: string, isLogisticFlux?: boolean) => {
  const { t } = useTranslation();

  const translationKey = 'nodi:billingDataSection:paymentMethodSection:';
  const transfMxnExtraBody = t(`${translationKey}transfMxnExtraBody`);
  const creditCardExtraBody = t(`${translationKey}creditCardExtraBody`);
  const internationalCardExtraBody = t(`${translationKey}internationalCardExtraBody`);
  const transfCopExtraBody = t(`${translationKey}transfCopExtraBody`);
  const transfPenArsExtraBody = t(`${translationKey}transfPenArsExtraBody`);
  const copCreditCardExtraBody = t(`${translationKey}copCreditCardExtraBody`);
  const arsBlueMessage = t(`${translationKey}arsBlueMessage`);
  const transferAlias = t(`${translationKey}transferAlias`);
  const nationalCreditCard = t(`${translationKey}nationalCreditCard`);
  const speiTransfer = t(`${translationKey}speiTransfer`);
  const internationalCreditCard = t(`${translationKey}internationalCreditCard`);
  const contryIsMx = billingDataCountryCode === 'mx';
  const transfUSDWithCLABEText = contryIsMx ? 'CLABE' : '';
  const transfUsdExtraBody = t(`${translationKey}transfUsdExtraBody`);

  const transfMxnExtraBodyLogistic =
    'Al aprobar la cotización del servicio podrás visualizar la cuenta CLABE  para realizar la transferencia';
  const transfExtraBodyLogistic =
    'Al aprobar la cotización del servicio podrás visualizar el número de cuenta para realizar la transferencia. ';
  const copCreditCardExtraBodyLogistic =
    'Al aprobar la cotización del servicio recibirás el link/ liga de pago a tu correo.';

  const extraDataList: IExtraData = {
    'Transf. MXN': {
      icon: <TransferIcon />,
      extraBody: isLogisticFlux ? transfMxnExtraBodyLogistic : transfMxnExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: speiTransfer
    },
    'Tarjeta crédito': {
      icon: <CardIcon />,
      extraBody: creditCardExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: nationalCreditCard
    },
    'Transf. USD': {
      icon: <TransferIcon />,
      extraBody: isLogisticFlux ? (
        transfExtraBodyLogistic
      ) : (
        <Trans i18nKey={transfUsdExtraBody} components={{ 1: transfUSDWithCLABEText }} />
      ),
      showCheckInvoice: true,
      needInvoice: false,
      alias: transferAlias
    },
    'Tarjeta internacional': {
      icon: <CardIcon />,
      extraBody: internationalCardExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: internationalCreditCard
    },
    'Transf. COP': {
      icon: <TransferIcon />,
      extraBody: isLogisticFlux ? transfExtraBodyLogistic : transfCopExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: transferAlias
    },
    'Transf. ARS': {
      icon: <TransferIcon />,
      extraBody: isLogisticFlux ? transfExtraBodyLogistic : transfPenArsExtraBody,
      showCheckInvoice: false,
      needInvoice: false,
      alias: transferAlias,
      blueMessage: arsBlueMessage
    },
    'Transf. PEN': {
      icon: <TransferIcon />,
      extraBody: transfPenArsExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: transferAlias
    },
    'Tarjeta crédito COP': {
      icon: <CardIcon />,
      extraBody: isLogisticFlux ? copCreditCardExtraBodyLogistic : copCreditCardExtraBody,
      showCheckInvoice: true,
      needInvoice: false,
      alias: nationalCreditCard
    }
  };

  return extraDataList;
};

export const formatMethodsList = (
  list: IInvoicePaymentMethods[],
  extraDataList: IExtraData,
  setPaymentMethodsList: React.Dispatch<React.SetStateAction<IInvoicePaymentMethods[] | undefined>>
) => {
  const newList = list;
  const formatList: IInvoicePaymentMethods[] = [];

  for (const item of newList) {
    const editMethod = { ...item };
    const extraData = extraDataList?.[item.name];
    editMethod.extraData = extraData;
    formatList.push(editMethod);
  }

  setPaymentMethodsList(formatList);
};
