const SquareGuatemala = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#3ECBF8" d="M16 0H22V16H16zM0 0H6V16H0z" />
          <path
            fill="#FFDA2C"
            d="M11.5 9C11.7761 9 12 8.55228 12 8C12 7.44772 11.7761 7 11.5 7C11.2239 7 11 7.44772 11 8C11 8.55228 11.2239 9 11.5 9Z"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M14 8.5C14 7.12 12.88 6 11.5 6C10.12 6 9 7.12 9 8.5C9 9.88 10.12 11 11.5 11C12.88 11 14 9.88 14 8.5ZM11.5 10C12.328 10 13 9.105 13 8C13 6.895 12.328 6 11.5 6C10.672 6 10 6.895 10 8C10 9.105 10.672 10 11.5 10Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareGuatemala;
