import BordModal from '../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import ModalPredefineLogistics from '../../../employees/employeeDetails/Components/UnassignToolsFromEmployeeModal/ModalPredefineLogistics';
import './ManageToolActionModal.scss';
const ManageToolActionModal = ({
  closeModalCallback,
  submitModalCallback,
  updateLogisticsPredefinedCallback
}: {
  closeModalCallback: () => void;
  submitModalCallback: () => void;
  updateLogisticsPredefinedCallback: (requireLogistics: boolean) => void;
}) => {
  return (
    <div className="manageToolActionModal">
      <BordModal
        modalContent={
          <ModalContent
            customHeight="44rem"
            customWidth="58rem"
            customSubtitleClass="customSubtitleClass"
            toCloseModal={closeModalCallback}
            title="Traslado de herramientas"
            subTitle="¿Requieres de los servicios logísticos de Bord para realizar la recolección y entrega de las herramientas?"
            CustomModalComponent={
              <ModalPredefineLogistics
                isAssignment={false}
                submitModalCallback={submitModalCallback}
                updateLogisticsPredefined={updateLogisticsPredefinedCallback}
              />
            }
          />
        }
      />
    </div>
  );
};

export default ManageToolActionModal;
