import algoliasearch from 'algoliasearch';
import { ALGOLIA_API_KEY, ALGOLIA_INDEX_NAME, ALOGLIA_APP_ID } from '../constants';

export const algoliaSearchService = () => {
  const client = algoliasearch(String(ALOGLIA_APP_ID), String(ALGOLIA_API_KEY));
  const search = async (q: string, countryFilter?: string) => {
    const index = await client.initIndex(String(ALGOLIA_INDEX_NAME));
    const hits = await index.search(q, countryFilter ? { filters: `countryCode:${countryFilter}` } : undefined);
    return hits.hits;
  };
  return { search };
};

export default algoliaSearchService;
