const RoundMontenegro = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M32 16.0001C32 10.5088 29.2332 5.66415 25.0179 2.78271H6.98206C2.76687 5.66415 0 10.5088 0 16.0001C0 21.4915 2.76687 26.336 6.98206 29.2175H25.0179C29.2332 26.336 32 21.4915 32 16.0001Z"
        />
        <path
          fill="#FFDA2C"
          d="M12.5225 11.826H19.479V9.04341L18.0876 9.73904 16.0007 7.6521 13.9138 9.73904 12.5225 9.04341V11.826ZM22.2611 15.9999H18.7842C18.9206 15.7641 18.9998 15.4911 18.9998 15.1991 18.9998 14.3124 18.281 13.5938 17.3944 13.5938 16.7819 13.5938 16.2499 13.937 15.9792 14.4414 15.7085 13.9369 15.1765 13.5938 14.5639 13.5938 13.6774 13.5938 12.9586 14.3124 12.9586 15.1991 12.9586 15.4912 13.0379 15.7641 13.1742 15.9999H9.73926C9.73926 17.0639 10.6659 17.9263 11.7298 17.9263H11.6657C11.6657 18.799 12.2461 19.5359 13.0419 19.7726L11.4784 21.3363 12.9541 22.8119 15.0474 20.7186C15.1294 20.7487 15.2142 20.7727 15.3019 20.7892L14.037 23.6451C14.6431 23.8916 15.3056 24.0284 16.0003 24.0284 16.6949 24.0284 17.3573 23.8916 17.9635 23.6451L16.6986 20.7892C16.7863 20.7727 16.8711 20.7487 16.953 20.7186L19.0463 22.8119 20.5219 21.3363 18.9583 19.7726C19.7541 19.5358 20.3346 18.7991 20.3346 17.9264H20.2704C21.3344 17.9263 22.2611 17.0639 22.2611 15.9999Z"
        />
        <path
          fill="#6DA544"
          d="M16.0001 17.0435L13.7393 18.0869V20.1739L16.0001 21.5651L18.261 20.1739V18.0869L16.0001 17.0435Z"
        />
        <path fill="#1A47B8" d="M13.7393 16H18.261V18.7826H13.7393V16Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMontenegro;
