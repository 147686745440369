const RoundCookIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="white"
          d="M15.957 16.0001H15.9998C15.9998 15.9857 15.9998 15.9717 15.9998 15.9573C15.9856 15.9716 15.9713 15.9858 15.957 16.0001Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.16194 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0485 16H15.9572C15.9715 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51179 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0325L10.3152 8.3479H8.34766V8.34803L15.9997 16H15.9998C15.9998 16 15.9998 14.6433 15.9998 14.0325Z"
        />
        <path
          fill="white"
          d="M21.5653 16 21.8615 16.911H22.8193L22.0444 17.474 22.3404 18.3851 21.5653 17.822 20.7904 18.3851 21.0864 17.474 20.3115 16.911H21.2693L21.5653 16ZM17.6302 17.63 18.4836 18.0648 19.161 17.3875 19.0111 18.3336 19.8646 18.7685 18.9185 18.9182 18.7687 19.8645 18.3338 19.0109 17.3877 19.1608 18.0651 18.4835 17.6302 17.63ZM16 21.5653 16.911 21.2693 16.9111 20.3115 17.474 21.0863 18.3851 20.7903 17.822 21.5653 18.3851 22.3403 17.474 22.0443 16.9111 22.8192V21.8614L16 21.5653ZM17.6302 25.5003 18.065 24.6468 17.3877 23.9696 18.3338 24.1194 18.7687 23.2659 18.9185 24.212 19.8646 24.3617 19.0111 24.7967 19.161 25.7429 18.4836 25.0654 17.6302 25.5003ZM21.5654 27.1304 21.2694 26.2194H20.3115L21.0865 25.6564 20.7905 24.7454 21.5654 25.3084 22.3405 24.7454 22.0445 25.6564 22.8193 26.2194H21.8615L21.5654 27.1304ZM25.5001 25.5003 24.6466 25.0656 23.9693 25.7429 24.1191 24.7967 23.2656 24.3619 24.2118 24.212 24.3616 23.2659 24.7964 24.1194 25.7426 23.9696 25.0651 24.6469 25.5001 25.5003ZM27.1302 21.5653 26.2191 21.8614V22.8192L25.6561 22.0443 24.7451 22.3403 25.3082 21.5653 24.7451 20.7903 25.6561 21.0863 26.2191 20.3115V21.2693L27.1302 21.5653ZM25.5001 17.6302 25.0653 18.4836 25.7426 19.1609 24.7964 19.0111 24.3616 19.8646 24.2118 18.9184 23.2656 18.7686 24.1191 18.3338 23.9693 17.3877 24.6466 18.0649 25.5001 17.6302Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCookIslands;
