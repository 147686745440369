import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} fill="none" ref={ref} {...props}>
    <g stroke="#fff" strokeLinecap="round" strokeLinejoin="round">
      <path
        className="path1"
        fill="#FF664E"
        fillRule="evenodd"
        d="m4.248 8.551 4.62-4.375c.769-.727.71-2.014-.13-2.84-.82-.805-2.07-.86-2.793-.123L1.45 5.801s.996.396 1.695 1.083c.7.688 1.103 1.667 1.103 1.667Z"
        clipRule="evenodd"
      />
      <path
        className="path2"
        fill="#fff"
        d="m1.089 9.367 3.156-.812s-.402-.98-1.1-1.668C2.444 6.2 1.45 5.802 1.45 5.802L.62 8.895c-.072.269.198.542.469.472Z"
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const EditPencilIcon = memo(ForwardRef);
export default EditPencilIcon;
