import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { NudosLoaderModal } from '../../../../components/NudosComponents';
import { getEmployeesListing } from '../../../../services/employees.service';
import { GenericFetchError } from '../../../../constants';
import { Iemployee } from '../../../../types/global';
import useDebounce from '../../../../hooks/useDebounce';
import { displaySuccessNotification } from '../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../utils/segment';
import useLanguagePlatformState from '../../../../state/useLenguageState';

import BordSearchBar from '../../../../components/BordDesignSystem/BordSearchBar/BordSearchBar';
import EmployeesListingHeader from './components/EmployeesListingHeader/EmployeesListingHeader';
import useEmployeeListingState from '../../../../state/useEmployeeListingState';
import BordEmptyState from '../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import EmployeeCard from '../Components/EmployeeCard';
import SkeletonEmployeeCard from './components/SkeletonEmployeeCard';
import './EmployeesListing.scss';

const EmployeesListing = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { search } = useLocation();
  const { setEmployeesCount, employeeSearchbarText, setEmployeeSearchbarText, employeesFilters } =
    useEmployeeListingState();
  const { platformLanguage } = useLanguagePlatformState();

  const JSONorgData = localStorage.getItem('orgData');
  const orgData = JSONorgData && JSON.parse(JSONorgData);
  if (!orgData) return null;

  const urlRedirectionFilter = new URLSearchParams(search).get('filter');

  const unequippedFilter = urlRedirectionFilter === 'unequipped';
  const i18nLanguageKey = 'nodi:employee:';
  const languageIsEnglish = platformLanguage && platformLanguage === 'enUS' ? true : false;
  const [data, setData] = useState<Iemployee[]>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [hasHadFilters, setHasHadFilters] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [unequippedAlertWasShown, setUnequippedAlertWasShown] = useState(false);
  const [employeesViewEventWasSent, setEmployeesViewEventWasSent] = useState(false);
  const filterDebounce = useDebounce(employeesFilters, 500);
  const debouncedEmployeeNameSearchString = useDebounce(employeeSearchbarText, 500);

  const hasFiltersApplied = !!filterDebounce?.length || !!debouncedEmployeeNameSearchString;

  const getData = async (nameSearchParam?: string) => {
    setLoading(true);
    try {
      const filtersApplied = employeesFilters && employeesFilters.length > 0;
      const filtersParam = filtersApplied && JSON.stringify(employeesFilters);
      const filtersQuery = filtersParam ? `?filters=${filtersParam}` : '';
      const dataEmployees = await getEmployeesListing(orgData.organizationId, filtersQuery, nameSearchParam);
      setEmployeesCount(dataEmployees?.length || 0);
      setData(dataEmployees);
      if (!employeesViewEventWasSent) {
        segmentTrackEvent({ employeesView: { NumberOfEmployees: dataEmployees?.length } });
        setEmployeesViewEventWasSent(true);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClickNewEmployeeButton = () => {
    segmentTrackEvent({ employeesNewClick: { NewEmployeeOrigin: 'Listing' } });
    push('/nodi/employees/create?origin=employeesList');
  };
  const loadEmployeesButtonAction = () => {
    push('/nodi/upload-employees');
  };

  useLayoutEffect(() => {
    if (unequippedFilter) {
      setShowLoaderModal(true);
    }
  }, []);

  useEffect(() => {
    if (!unequippedFilter || !showLoaderModal) return;
    const timerUnequippedEmployees = setTimeout(() => {
      setShowLoaderModal(false);
      if (!employeesFilters) return;
      !unequippedAlertWasShown &&
        displaySuccessNotification({ customJSXMessage: <>Estás visualizando los empleados sin equipar</> });
      !unequippedAlertWasShown && setUnequippedAlertWasShown(true);
    }, 2000);

    return () => {
      clearTimeout(timerUnequippedEmployees);
    };
  }, [data]);

  useEffect(() => {
    getData(debouncedEmployeeNameSearchString);
  }, [filterDebounce, debouncedEmployeeNameSearchString]);

  useEffect(() => {
    if (hasHadFilters) return;
    !!employeesFilters && setHasHadFilters(true);
  }, [employeesFilters]);

  return (
    <>
      <div className="searchbarContainer">
        <BordSearchBar
          defaultValue={employeeSearchbarText}
          placeholder={t(`${i18nLanguageKey}searchbarContainer`)}
          handleChange={(inputText: string) => setEmployeeSearchbarText(inputText)}
          componentSize={languageIsEnglish ? undefined : 'large'}
          hasDeleteButton
        />
      </div>
      {showLoaderModal && <NudosLoaderModal loaderText="Buscando empleados no equipados" modalTopPosition={92} />}
      {!showLoaderModal && <EmployeesListingHeader />}
      {!showLoaderModal && (
        <div id="employeesListing" className="moduleWithSidebar">
          {((!data && !error) || loading) && (
            <div className="listing">
              {[1, 2, 3, 4, 5, 6, 7, 8].map(element => {
                return <SkeletonEmployeeCard key={element} />;
              })}
            </div>
          )}
          {!loading && data && data.length > 0 && !error && (
            <div className="listing">
              {data.map(employee => {
                return <EmployeeCard key={uuid()} employee={employee} />;
              })}
            </div>
          )}
          {!loading && !data?.length && !hasFiltersApplied && !error && (
            <div className="noEmployees">
              <BordEmptyState
                title={t(`${i18nLanguageKey}hasNotEmployeeTitle`) || ''}
                subTitle={t(`${i18nLanguageKey}hasNotEmployeeSubTitle`) || ''}
                ilustrationIconProps={{
                  variant: 'bordy',
                  customWidth: '10rem'
                }}
                buttonOneProps={{
                  label: t(`${i18nLanguageKey}loadEmployeesButton`) || '',
                  buttonIconTwo: 'users',
                  onClick: loadEmployeesButtonAction,
                  bordButtonStyle: 'outlined',
                  customWidth: 'w-280'
                }}
                buttonTwoProps={{
                  label: t(`${i18nLanguageKey}newEmployeeButton`) || '',
                  buttonIconTwo: 'user',
                  onClick: handleClickNewEmployeeButton,
                  customWidth: 'w-280'
                }}
              />
            </div>
          )}
          {!loading && data?.length === 0 && !error && hasFiltersApplied && (
            <div className="noEmployees">
              <BordEmptyState
                title={t(`${i18nLanguageKey}notResultsTitle`) || ''}
                subTitle={t(`${i18nLanguageKey}hasNotEmployeeSubTitle`) || ''}
                ilustrationIconProps={{
                  variant: 'search',
                  customWidth: '10rem'
                }}
                buttonTwoProps={{
                  label: t(`${i18nLanguageKey}newEmployeeButton`) || '',
                  buttonIconTwo: 'user',
                  onClick: handleClickNewEmployeeButton,
                  customWidth: 'w-280'
                }}
              />
            </div>
          )}
          {error && <GenericFetchError />}
        </div>
      )}
    </>
  );
};

export default EmployeesListing;
