import { useTranslation } from 'react-i18next';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import AdditionalServiceStatusModalContent from './AdditionalServiceStatusModalContent';
import './NudosAdditionalServiceStatusModal.scss';
import BordModal from '../../../../BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../BordDesignSystem/BordModal/components/ModalContent';

const NudosAdditionalServiceStatusModal = ({
  additionalServiceData,
  closeModalCallback
}: {
  closeModalCallback: () => void;
  additionalServiceData: IadditionalServiceDataForToolLogistics;
}) => {
  const { t } = useTranslation();
  const { statusName } = additionalServiceData?.status || {};
  const translateKey = 'services:logistics:additionalServices:';
  const modalTitle =
    statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      ? t(`${translateKey}subsLassNotCompletedModal:title`)
      : t(`${translateKey}subsLassCompletedModal:title`);

  const modalSubtitle =
    statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      ? 'Conoce el motivo por el cual no pudimos completar el servicio solicitado'
      : 'Conoce el estado del servicio solicitado';

  const understoodText = t(`${translateKey}understood`);

  return (
    <div className="nudosAdditionalServiceStatusModal">
      <BordModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <ModalContent
            modalSize="lg"
            title={modalTitle}
            subTitle={modalSubtitle}
            toCloseModal={closeModalCallback}
            showButtonTwo
            buttonTwoProps={{
              label: understoodText,
              onClick: closeModalCallback
            }}
            CustomModalComponent={<AdditionalServiceStatusModalContent additionalServiceData={additionalServiceData} />}
          />
        }
      />
    </div>
  );
};

export default NudosAdditionalServiceStatusModal;
