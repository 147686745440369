import { useHistory } from 'react-router-dom';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../../components/BordDesignSystem';
import BordEmptyState from '../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';

import './AssignmentModuleStep1SelectTools.scss';

const NoToolsToAssignSection = ({
  openModalCallback,
  allToolsAvailableToAssignForThisOrganization,
  selectedToolsToAssign
}: {
  openModalCallback: () => void;
  allToolsAvailableToAssignForThisOrganization: ItoolEssentialData[];
  selectedToolsToAssign: ItoolEssentialData[];
}) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:';

  const uploadDevicesText = t(`${translationKey}uploadDevices`);
  const buyToolsText = t(`${translationKey}buyToolsText`);
  const { push } = useHistory();
  const organizationHasSomeAvailableToolsToAssign = allToolsAvailableToAssignForThisOrganization.length > 0;
  const haveSelectedToolsToAssign = selectedToolsToAssign.length > 0;

  const redirectToUploadTools = () => push(`/nodi/upload-external-tools`);
  const redirectToEcommerce = () => push(`/catalogue/home`);

  return (
    <div className="noToolsToAssignSection">
      <div className="centerElements">
        <BordEmptyState
          ilustrationIconProps={{ variant: 'search', customWidth: '10rem', standardSize: 100 }}
          title="No has seleccionado ninguna herramienta"
          subTitle="Selecciona herramientas de tu inventario o compra desde la tienda"
        />
        <div className="buttonsSection">
          {!organizationHasSomeAvailableToolsToAssign && (
            <>
              <BordButton
                customWidth="w-280"
                bordButtonStyle="outlined"
                label={buyToolsText}
                onClick={redirectToEcommerce}
              />
              <BordButton
                customWidth="w-280"
                modeButton="secondary"
                label={uploadDevicesText}
                onClick={redirectToUploadTools}
              />
            </>
          )}
          {organizationHasSomeAvailableToolsToAssign && !haveSelectedToolsToAssign && (
            <>
              <BordButton
                customWidth="w-280"
                bordButtonStyle="outlined"
                label={buyToolsText}
                onClick={redirectToEcommerce}
              />
              <BordButton
                customWidth="w-280"
                label="Buscar herramientas"
                customClassName="noSelectedToolsButton"
                onClick={openModalCallback}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NoToolsToAssignSection;
