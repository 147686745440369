import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferenceOptions } from '../ReferenceOptions';
import { ICartShipping, IProductShipping, IProductsCart } from '../../../../../../../types/cart';
import useCartState from '../../../../../../../state/useCartState';
import useShopStore from '../../../../../../../state/useShopContext';
import { Iemployee } from '../../../../../../../types/global';
import { IWarehouse, IorganizationOfficeData } from '../../../../../../../types/account';
import { ICountryDetail } from '../../../../../../../types/countries';
import { BordPhoto } from '../../../../../../../components/BordDesignSystem';
import { formatPills } from '../../../../../../../utils/productDetails';
import { DynamicsPills } from '../../../../../../../components';
import { bordColorTheme } from '../../../../../../../styles/constants';
import useStoreCountryState from '../../../../../../../state/useStoreCountryState';
import { IShippingOption } from '../ReferenceOptions/Components/ShippingOptions/ShippingOptions';
import { TbordOneToneIcon } from '../../../../../../../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import './ShippingReference.scss';

interface IShippingReference {
  openAddOfficeAddress: (getOfficeAddressCallback?: (data?: IorganizationOfficeData) => void) => Promise<void> | void;
  officeAddress?: IorganizationOfficeData;
  defaultOfficeAddress?: boolean;
  setEveryOneOffice?: (check: boolean) => void;
  referenceData: IProductsCart;
  countryName: string;
  everyOneOffice?: boolean;
  referenceInitialShipmentsData?: ICartShipping[];
  globalShippingCartWasPreloaded?: boolean;
  countryWarehouse?: IWarehouse;
  completeListOfCountries?: ICountryDetail[];
  callBackRequestForShippingCalculation?: (data: IProductShipping) => void;
}

const ShippingReference: FC<IShippingReference> = ({
  openAddOfficeAddress,
  officeAddress,
  defaultOfficeAddress,
  setEveryOneOffice,
  referenceData,
  countryName,
  everyOneOffice,
  referenceInitialShipmentsData,
  globalShippingCartWasPreloaded,
  countryWarehouse,
  completeListOfCountries,
  callBackRequestForShippingCalculation
}: IShippingReference) => {
  const { t } = useTranslation();
  const { storeCountry } = useStoreCountryState();
  const { globalShippingCart, setGlobalShippingCart } = useCartState();
  const { itemsCart } = useShopStore();
  const [shippings, setShippings] = useState<IProductsCart[]>();
  const [selectedOption, setSelectedOption] = useState<IShippingOption[]>();
  const pillsList = referenceData?.pills
    ? formatPills({ pillsList: referenceData?.pills, excludeItems: ['Cargo'] })
    : undefined;

  const shippingOptionsTranslationKey = 'nodi:checkout:step2ShippingCart:shippingOptions:';

  useEffect(() => {
    const optionsSelect = [
      {
        value: 2,
        name: 'Empleados',
        explanation: t(`${shippingOptionsTranslationKey}employee:explanation`),
        iconVariant: 'user' as TbordOneToneIcon
      },
      {
        value: 1,
        name: 'Oficinas',
        explanation: t(`${shippingOptionsTranslationKey}office:explanation`),
        iconVariant: 'building3' as TbordOneToneIcon
      }
    ];
    if (storeCountry?.storageEnabled) {
      optionsSelect.unshift({
        value: 3,
        name: '.bord Warehouse',
        explanation: t(`${shippingOptionsTranslationKey}warehouse:explanation`),
        iconVariant: 'bord' as TbordOneToneIcon
      });
    }
    setSelectedOption(optionsSelect);
  }, [storeCountry]);

  const associatedShippingCart = (
    index: number,
    type: 'employee' | 'office' | 'nudos',
    employeeInfo?: Iemployee,
    officeData?: IorganizationOfficeData,
    storeNudosData?: IWarehouse,
    manuallySelected?: boolean
  ) => {
    const myReferenceList = [...(globalShippingCart?.shipments || [])];
    const reduceList = myReferenceList.filter(
      element => element?.productId === referenceData?.productId && element?.countryId === referenceData?.countryId
    );
    const assignElement = reduceList[index];
    const referenceIndex = myReferenceList?.findIndex(
      element => element?.onlyShippingUid === assignElement?.onlyShippingUid
    );

    const employeeFullName =
      type === 'employee' && employeeInfo ? `${employeeInfo?.firstName} ${employeeInfo?.lastName}` : '';
    const employeePhone =
      type === 'employee' && employeeInfo
        ? `+${employeeInfo?.phoneData?.phoneCode} ${employeeInfo?.phoneData?.phone}`
        : '';
    const receiverPersonalId =
      type === 'employee' ? employeeInfo?.personalId : officeData?.shipmentInfo?.receiverIdDocument;
    const receiverName = type === 'employee' ? employeeFullName : officeData?.shipmentInfo?.receiverName;
    const receiverPhone = type === 'employee' ? employeePhone : officeData?.shipmentInfo?.receiverPhone;

    myReferenceList[referenceIndex] = {
      ...assignElement,
      destinationLocationId:
        type === 'employee'
          ? employeeInfo?.locationId || undefined
          : type === 'nudos'
          ? storeNudosData?.locationId
          : officeData?.locationId,
      employee: type === 'employee' ? employeeInfo : undefined,
      receiverInformation:
        receiverName && receiverPhone
          ? {
              personalId: receiverPersonalId,
              receiverName: receiverName,
              receiverPhone: receiverPhone
            }
          : undefined,
      destinationIsOffice: type === 'office'
    };
    if (itemsCart?.shoppingCartId) {
      const newShippingCart: IProductShipping = {
        shoppingCartId: itemsCart?.shoppingCartId,
        shipments: myReferenceList
      };
      setGlobalShippingCart(newShippingCart);
      if (callBackRequestForShippingCalculation && manuallySelected) {
        callBackRequestForShippingCalculation(newShippingCart);
      }
    }
  };

  const resetReference = (index: number) => {
    const myReferenceList = globalShippingCart?.shipments || [];
    const reduceList = myReferenceList.filter(
      element => element?.productId === referenceData?.productId && element?.countryId === referenceData?.countryId
    );
    const assignElement = reduceList[index];
    const referenceIndex = myReferenceList?.findIndex(
      element => element?.onlyShippingUid === assignElement?.onlyShippingUid
    );
    myReferenceList[referenceIndex] = {
      ...assignElement,
      destinationLocationId: undefined,
      employee: undefined,
      receiverInformation: undefined,
      destinationIsOffice: undefined
    };
    if (itemsCart?.shoppingCartId) {
      const newShippingCart: IProductShipping = {
        shoppingCartId: itemsCart?.shoppingCartId,
        shipments: myReferenceList
      };
      setGlobalShippingCart({ ...newShippingCart });
      callBackRequestForShippingCalculation && callBackRequestForShippingCalculation(newShippingCart);
    }
  };

  useEffect(() => {
    if (referenceData && referenceData?.quantity && referenceData.quantity > 0) {
      const items = Array(referenceData.quantity).fill({ referenceData });
      setShippings(items);
    }
  }, []);

  return (
    <div id="shippingReference">
      <div className="generalReferenceContainer">
        <div className="referenceInfo">
          <div className="referenceImg">
            <BordPhoto url={referenceData?.image || ''} size="s56" />
            <div className="brandContainer">
              <BordPhoto
                variant="circle"
                url={referenceData?.brandImage}
                size="s32"
                oneToneIconProps={{ variant: 'bord', customWidth: '3.2rem', stroke: bordColorTheme.navyBlue[600] }}
                imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '65%', maxWidth: '65%' } }}
              />
            </div>
          </div>
          <div className="nameAndPills">
            <div className="nameAndBrandBox">
              <div className="boxReferenceName">
                <div className="referenceName">{referenceData?.productName}</div>
              </div>
              <div className="referencePills">
                {!!pillsList?.pills &&
                  pillsList.pills.map(pill => {
                    return (
                      <DynamicsPills
                        key={`check-out-pill${pill.value}`}
                        dataPills={{ hoverText: pill.name, text: pill.value }}
                        elementsNumber={pillsList?.pills?.length || 0}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="unitsReference">
            {`${referenceData?.quantity}   ${
              referenceData?.quantity > 1 ? t('recurrentWords:units') : t('recurrentWords:unit')
            }`}
          </div>
        </div>
        <div className="referenceGrayLine" />
        {shippings &&
          shippings.length > 0 &&
          shippings.map((item, index) => {
            const referenceInitialData =
              referenceInitialShipmentsData && index < referenceInitialShipmentsData.length
                ? referenceInitialShipmentsData[index]
                : undefined;
            return (
              <ReferenceOptions
                key={`reference-shipping-${index}`}
                externalIndex={index}
                officeAddress={officeAddress}
                optionsSelect={selectedOption}
                openAddOfficeAddress={openAddOfficeAddress}
                defaultOfficeAddress={defaultOfficeAddress}
                setEveryOneOffice={setEveryOneOffice}
                handleShippingReference={(
                  type: 'employee' | 'office' | 'nudos',
                  employeeInfo?: Iemployee,
                  officeData?: IorganizationOfficeData,
                  storeNudosData?: IWarehouse,
                  manuallySelected?: boolean
                ) => {
                  associatedShippingCart(index, type, employeeInfo, officeData, storeNudosData, manuallySelected);
                }}
                productInfo={item}
                deleteShipping={() => resetReference(index)}
                countryName={countryName}
                everyOneOffice={everyOneOffice}
                referenceInitialData={referenceInitialData}
                globalShippingCartWasPreloaded={globalShippingCartWasPreloaded}
                countryWarehouse={countryWarehouse}
                refrenceProductId={referenceData.productId}
                completeListOfCountries={completeListOfCountries}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ShippingReference;
