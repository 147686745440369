import { SetStateAction, useState } from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { getOrgData } from '../utils/getLocalStorageData';
import {
  DTOgetTaxationRelatedDefinitionsOptions,
  IbillingData,
  ItaxationRelatedDataRequiredForBilling
} from '../types/checkout';
import { ICountryDetail } from '../types/countries';
import {
  countriesRequiringTaxSituationDocument,
  formatZipCode,
  getIsInvalidBusinessName,
  getIsInvalidZipCode,
  invalidBusinessName,
  invalidZipCodeText
} from '../utils/productDefinitions';
import { handleClickForHelp } from '../utils/global.utils';
import { displayErrorNotification, displaySuccessNotification } from '../utils/displayNudosStandardNotifications';
import { getTaxationRelatedDefinitionsOptions, postOrUpdateBillingDataByCountry } from '../services/checkout';
import { isEmailValid, isTaxDocumentByCountryValid } from '../utils/formValidations';
import { NudosUploadDownloadButton } from '../components/NudosComponents';
import { BordCheckBox, BordDropdown, BordOneToneIcon, BordTextInput } from '../components/BordDesignSystem';
import { NudosCountryDropdown, NudosLinkTypeButton } from '../components/DesignSystem';
import { TcomponentSize } from '../types/global';
import { capitalize } from '../utils/unCamelCase';
import BordPhoneInput from '../components/BordDesignSystem/BordPhoneInput/BordPhoneInput';

const useBillingData = (initialData: IbillingData) => {
  const { t } = useTranslation();
  const orgData = getOrgData();

  const [billingData, setBillingData] = useState<IbillingData | undefined>({ ...emptyBillingData, ...initialData });
  const [loadingBillingDataForm, setLoadingBillingDataForm] = useState(false);
  const [taxSituationDocument, setTaxSituationDocument] = useState<File>();

  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:';
  const invalidZipCode = !!getIsInvalidZipCode(billingData?.zipCode);
  const isInvalidBusinessName = !!getIsInvalidBusinessName(billingData?.businessName);
  const invalidTaxDocument = !isTaxDocumentByCountryValid(billingData?.documentCountry, billingData?.document || '');
  const organizationId = orgData?.organizationId;
  const businessHasSpecialNameType = ['uy'].includes(billingData?.documentCountry?.code || '');
  const isAMexicanOrganization = billingData?.documentCountry?.code === 'mx';
  const organizationIsUruguay = billingData?.documentCountry?.code === 'uy';
  const organizationIsColombia = billingData?.documentCountry?.code === 'co';
  const isOtherOrganization = !organizationIsUruguay && !isAMexicanOrganization && !organizationIsColombia;
  const thisBillingCountryRequiresTaxSituationDocument = countriesRequiringTaxSituationDocument.includes(
    billingData?.documentCountry?.code || ''
  );
  const zipCodeLabel = t(`${i18nLanguageKey}formFieldsLine:zipCode`);
  const americanAddressPlaceholder = t(`${i18nLanguageKey}useBillingData:billingAddressPlaceholder`);
  const addressPlaceholderDefault = t(`${i18nLanguageKey}useBillingData:billingAddressPlaceholderDefault`);
  const billingAddressPlaceholder = isAMexicanOrganization ? americanAddressPlaceholder : addressPlaceholderDefault;
  const countriesWithSpecialBusiness = billingData?.countryCode === 'uy';
  const specialBusinessId = billingData?.specialBusinessNameType?.id;
  const specialBusinessTypeTrading = specialBusinessId === 1;
  const specialBusinessTypeZone = specialBusinessId === 2;
  const specialBusinessText = specialBusinessTypeTrading
    ? 'La razón social es de Trading ¿Quieres cambiar el estado?'
    : ' La razón social está ubicada en Zona Franca ¿Quieres cambiar el estado?';

  const updatePhoneNumber = (newPhoneNumber?: string) =>
    setBillingData(prevData => ({ ...prevData, phone: newPhoneNumber }));

  const updateFantasyName = (newFantasyName: string) =>
    setBillingData(prevData => ({ ...prevData, fantasyName: newFantasyName }));

  const updatePhoneCountry = (newPhoneCountry?: ICountryDetail) =>
    setBillingData(prevData => ({ ...prevData, phoneCountryId: newPhoneCountry?.id, phoneCountry: newPhoneCountry }));

  const updateBusinessName = (newBusinessName: string) =>
    setBillingData(prevData => ({ ...prevData, businessName: newBusinessName }));

  const updateDocument = (newDocument: string) => setBillingData(prevData => ({ ...prevData, document: newDocument }));

  const updateAddress = (newAddress: string) => setBillingData(prevData => ({ ...prevData, address: newAddress }));

  const updateAdditionalReferences = (newAdditionalReferences: string) =>
    setBillingData(prevData => ({ ...prevData, additionalReferences: newAdditionalReferences }));

  const updateZipCode = (newZipCode: string) => setBillingData(prevData => ({ ...prevData, zipCode: newZipCode }));

  const updateCity = (newCity: string) => setBillingData(prevData => ({ ...prevData, city: newCity }));

  const updateState = (newState: string) => setBillingData(prevData => ({ ...prevData, state: newState }));

  const updateEmail = (newEmail: string) => setBillingData(prevData => ({ ...prevData, email: newEmail }));

  const updateDocumentCountry = (newDocumentCountry: ICountryDetail) =>
    setBillingData(prevData => ({ ...prevData, documentCountry: newDocumentCountry }));

  const updateTaxSituation = (newTaxSituation: ItaxationRelatedDataRequiredForBilling) =>
    setBillingData(prevData => ({ ...prevData, taxSituation: newTaxSituation, taxSituationId: newTaxSituation?.id }));

  const updateTaxUse = (newTaxUse: ItaxationRelatedDataRequiredForBilling) =>
    setBillingData(prevData => ({ ...prevData, taxUse: newTaxUse, taxUseId: newTaxUse?.id }));

  const updateTaxStatusDocumentToUpload = (file?: File) => setTaxSituationDocument(file);

  const updateLoadingStatus = (loading: boolean) => setLoadingBillingDataForm(loading);

  const getTaxDocumentButtonText = () => {
    if (billingData?.documentCountry?.code === 'mx') return t(`${i18nLanguageKey}useBillingData:taxDocumentButtonMx`);
    if (billingData?.documentCountry?.code === 'co') return 'RUT';
    if (billingData?.documentCountry?.code === 'uy') return 'Estatuto (opcional)';
    return t(`${i18nLanguageKey}useBillingData:taxDocumentButtonDefault`);
  };

  const emptyFormValues = Object.entries(billingData || {}).filter(
    ([key, value]) =>
      !value &&
      key !== 'taxSituationDocUrl' &&
      key !== 'countryId' &&
      key !== 'name' &&
      ((key !== 'taxSituation' && key !== 'taxUse') || isAMexicanOrganization)
  );
  const organizationHasStoredBillingData = initialData && Object.values(initialData).some(value => !!value);
  const maxEmptyFieldsForError = organizationHasStoredBillingData ? 10 : 2;
  const emptyFieldError = emptyFormValues.length && emptyFormValues.length <= maxEmptyFieldsForError ? ' ' : undefined; // " " passes an error string for the error styles but doesnt show an error text
  const businessNameTooltip = (
    <>
      {`El nombre o razón social del receptor de la factura debe coincidir con el del RFC registrado en el SAT; recuerda
      que con CFDI 4.0, debe ingresarse en mayúsculas y sin acentos, además ya no debes incluir el régimen societario
      (ej. "S.A. de C.V.")`}
    </>
  );

  const getFieldsThatCanBeEmpty = () => {
    const commonFields = [
      'colony',
      'taxSituationDocUrl',
      'deletedAt',
      'createdAt',
      'updatedAt',
      'number',
      'internalNumber',
      'street',
      'specialBusinessNameTypeId',
      'specialBusinessNameType'
    ];
    if (!organizationIsUruguay) return [...commonFields, 'phone', 'fantasyName', 'phoneCountryId', 'phoneCountry'];
    return commonFields;
  };

  const fieldsThatCanBeEmpty = getFieldsThatCanBeEmpty();

  const fieldsRequiredInMexico = ['taxUseId', 'taxSituationId', 'taxSituation', 'taxUse'];

  const isDataIncomplete = (validateTaxDocument?: boolean) => {
    const isAnyFieldIncomplete = Object.entries(billingData || emptyBillingData).some(([key, val]) => {
      const keyCantBeEmpty = !fieldsThatCanBeEmpty.includes(key);
      const firstCondition = !val && keyCantBeEmpty;
      const keyIsNotRequiredInMexico = !fieldsRequiredInMexico.includes(key);
      const secondCondition = keyIsNotRequiredInMexico || isAMexicanOrganization;
      return firstCondition && secondCondition;
    });
    if (isAnyFieldIncomplete || !organizationId || invalidZipCode || isInvalidBusinessName) return true;
    if (validateTaxDocument && invalidTaxDocument) return true;
    if (getEmailError()) return true;
    return false;
  };

  const hasBillingDataChange = (setBillingDataWasSubmited?: React.Dispatch<SetStateAction<boolean>>) => {
    if (!initialData) return false;
    const textFieldsHaveChange = Object.entries(billingData || emptyBillingData).some(
      ([key, val]) =>
        key !== 'taxSituationDocUrl' &&
        ((key !== 'taxSituationId' && key !== 'taxUseId') || isAMexicanOrganization) &&
        initialData[key as keyof IbillingData] !== val
    );
    if (textFieldsHaveChange || !!taxSituationDocument) {
      setBillingDataWasSubmited && setBillingDataWasSubmited(false); // To force the user that changes some field of the billing data to save the data before being able to create an order
      return true;
    }
    !loadingBillingDataForm && setBillingDataWasSubmited && setBillingDataWasSubmited(true); // If no field in the billing data has changed, and the billing data isnt currently loading, it isnt required to save (act as if data has already been saved)
    return false;
  };

  const formDropdownOptionsCallback = (
    rawOptions: ItaxationRelatedDataRequiredForBilling[],
    isTaxSituation: boolean,
    clickOptionCallback?: (option: ItaxationRelatedDataRequiredForBilling) => void
  ) => {
    return rawOptions.map((option, i) => {
      const handleSelectThisOption = () => {
        if (isTaxSituation) {
          updateTaxSituation(option);
        } else {
          updateTaxUse(option);
        }
        clickOptionCallback && clickOptionCallback(option);
      };
      return (
        <div className="customTaxOptions" key={`option-${option.code}-${i}`} onClick={handleSelectThisOption}>
          {option?.name || ''}
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (rawOption: ItaxationRelatedDataRequiredForBilling) => {
    return <div className="optionContainer selectTaxOption">{rawOption?.name}</div>;
  };

  const handleUpdateBillingData = async (updateDataCallback?: (newlyCreatedBillingData?: IbillingData) => void) => {
    if (isDataIncomplete() || !orgData?.organizationId) return;
    updateLoadingStatus(true);
    const body = {
      ...billingData,
      id: billingData?.id,
      countryId: billingData?.documentCountry?.id,
      name: billingData?.name || orgData?.organizationName,
      documentCountryId: billingData?.documentCountry?.id,
      countryCode: billingData?.documentCountry?.code,
      taxSituationId: billingData?.taxSituation?.id,
      taxUseId: billingData?.taxUse?.id
    };
    const formData = new FormData();
    const bodyJSON = JSON.stringify(body);
    formData.append('body', bodyJSON);
    taxSituationDocument && formData.append('file', taxSituationDocument);
    try {
      const newlyCreatedBillingData = await postOrUpdateBillingDataByCountry(orgData?.organizationId, formData);
      updateDataCallback && updateDataCallback(newlyCreatedBillingData);
      displaySuccessNotification({
        customJSXMessage: <>{t(`${i18nLanguageKey}handleSaveBillingData`)} </>
      });
      updateLoadingStatus(false);
    } catch (error) {
      const axiosError = (error as AxiosError)?.response?.data;
      const errorText =
        axiosError?.statusCode === 400 && typeof axiosError?.message === 'string' ? axiosError?.message : undefined;
      const customJSXMessage = errorText ? <>{errorText}</> : undefined;
      displayErrorNotification({ customJSXMessage });
      updateLoadingStatus(false);
    }
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ItaxationRelatedDataRequiredForBilling[]) => {
    return rawOptions.filter(taxData => taxData?.name?.toLowerCase().trim().includes(search.toLowerCase().trim()));
  };

  const getZipCodeErrorText = () => {
    if (!loadingBillingDataForm && !billingData?.zipCode) return emptyFieldError;
    if (getIsInvalidZipCode(billingData?.zipCode)) return invalidZipCodeText;
    return undefined;
  };

  const getBusinessNameError = () => {
    if (!loadingBillingDataForm && !billingData?.businessName?.trim()) return emptyFieldError;
    if (getIsInvalidBusinessName(billingData?.businessName)) return invalidBusinessName;
    return undefined;
  };

  const getEmailError = () => {
    if (!!billingData?.email && !isEmailValid(billingData?.email)) return t('recurrentWords:inputError:invalidEmail');
    return undefined;
  };

  const getFantasyNameError = () => {
    if (!loadingBillingDataForm && !billingData?.fantasyName?.trim()) return emptyFieldError;
    return undefined;
  };

  const getEmailInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={`${t(`${i18nLanguageKey}formFieldsLine:billingEmail`)}*`}
      isFilled={!!billingData?.email}
      errorText={getEmailError()}
      placeholder={t(`${i18nLanguageKey}formFieldsLine:billingEmail`)}
      standardSize="w-180"
      currentText={billingData?.email || undefined}
      setCurrentText={e => updateEmail(e)}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getCityInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={`${t(`${i18nLanguageKey}formFieldsLine:city`)}*`}
      isFilled={!!billingData?.city}
      errorText={!loadingBillingDataForm && !billingData?.city?.trim() ? emptyFieldError : undefined}
      placeholder={t(`${i18nLanguageKey}formFieldsLine:city`)}
      currentText={billingData?.city || undefined}
      setCurrentText={updateCity}
      standardSize="w-180"
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getPhoneNumberInput = (countriesList: ICountryDetail[], showSkeletonLoader?: boolean) => (
    <BordPhoneInput
      label={t(`${i18nLanguageKey}formFieldsLine:phoneNumber`)}
      placeholder={t(`${i18nLanguageKey}formFieldsLine:phoneNumber`)}
      countriesList={countriesList}
      personalClass="phoneInput"
      defaultCountryData={billingData?.phoneCountry || undefined}
      componentSize="w280"
      defaultPhoneNumber={billingData?.phone || ''}
      handlePhoneChange={updatePhoneNumber}
      handleCountryPhoneChange={updatePhoneCountry}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getFantasyNameInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={`${t(`${i18nLanguageKey}formFieldsLine:fantasyName`)}*`}
      isFilled={!!billingData?.fantasyName}
      placeholder={t(`${i18nLanguageKey}formFieldsLine:fantasyName`)}
      setCurrentText={updateFantasyName}
      currentText={billingData?.fantasyName || undefined}
      errorText={getFantasyNameError()}
      standardSize="w-280"
      className="fantasyNameInput"
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getNudosCountryDropwdown = (
    countriesList: ICountryDetail[],
    isDisabled?: boolean,
    showSkeletonLoader?: boolean,
    displayLabel?: boolean
  ) => (
    <NudosCountryDropdown
      displayLabel={displayLabel}
      currentValue={billingData?.documentCountry}
      countriesList={countriesList}
      isDeactivated={isDisabled}
      componentSize="w-80"
      charactersLimit={6}
      customPlaceholder={t(`${i18nLanguageKey}formFieldsLine:countryName`)}
      customLabel={t(`${i18nLanguageKey}formFieldsLine:countryName`)}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
      updateCurrentValueCallback={e => updateDocumentCountry(e)}
    />
  );

  const getZipCodeInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={zipCodeLabel}
      isFilled={!!billingData?.zipCode}
      errorText={getZipCodeErrorText()}
      handleBlur={newZipCode => newZipCode && updateZipCode(formatZipCode(newZipCode))}
      placeholder="Zip/ CP"
      currentText={billingData?.zipCode || undefined}
      setCurrentText={updateZipCode}
      standardSize="w-80"
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getBillingDataStateInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={`${t(`${i18nLanguageKey}formFieldsLine:state`)}*`}
      standardSize="w-180"
      placeholder={t(`${i18nLanguageKey}formFieldsLine:state`)}
      isFilled={!!billingData?.state}
      currentText={billingData?.state || undefined}
      setCurrentText={e => updateState(e)}
      errorText={!loadingBillingDataForm && !billingData?.state?.trim() ? emptyFieldError : undefined}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getTaxDocumentInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={t(`${i18nLanguageKey}formFieldsLine:taxDocument`)}
      isFilled={!!billingData?.document}
      errorText={!loadingBillingDataForm && !billingData?.document?.trim() ? emptyFieldError : undefined}
      standardSize="w-180"
      placeholder="RUT, CUIT, NIT"
      currentText={billingData?.document || undefined}
      setCurrentText={updateDocument}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
      labelProps={{ className: 'truncate' }}
    />
  );

  const getBusinessNameInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={t(`${i18nLanguageKey}formFieldsLine:businessNameContainer:label`)}
      standardSize="w-280"
      placeholder={t(`${i18nLanguageKey}formFieldsLine:businessNameContainer:placeholder`)}
      isFilled={!!billingData?.businessName}
      currentText={billingData?.businessName || undefined}
      setCurrentText={updateBusinessName}
      handleBlur={newBusinessName => newBusinessName && updateBusinessName(newBusinessName?.toUpperCase()?.trim())}
      errorText={getBusinessNameError()}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getBillingAddressInput = (showSkeletonLoader?: boolean, customSize?: TcomponentSize) => (
    <BordTextInput
      label={`${t(`${i18nLanguageKey}formFieldsLine:billingAddress`)}*`}
      errorText={!loadingBillingDataForm && !billingData?.address?.trim() ? emptyFieldError : undefined}
      isFilled={!!billingData?.address}
      placeholder={billingAddressPlaceholder}
      currentText={billingData?.address || undefined}
      setCurrentText={updateAddress}
      standardSize={'w-380'}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getAdditionalReferencesInput = (showSkeletonLoader?: boolean) => (
    <BordTextInput
      label={`${t(`${i18nLanguageKey}formFieldsLine:directions:label`)}*`}
      errorText={!loadingBillingDataForm && !billingData?.additionalReferences?.trim() ? emptyFieldError : undefined}
      isFilled={!!billingData?.additionalReferences}
      placeholder={t(`${i18nLanguageKey}formFieldsLine:directions:placeholder`)}
      currentText={billingData?.additionalReferences || undefined}
      setCurrentText={updateAdditionalReferences}
      standardSize="w-380"
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
    />
  );

  const getTaxRegimeDropdownWithSearchbar = (
    rawOptions?: ItaxationRelatedDataRequiredForBilling[],
    showSkeletonLoader?: boolean,
    isRequired?: boolean,
    setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <BordDropdown
      label={`${t(`${i18nLanguageKey}taxRegime`)}${isRequired ? '*' : ''}`}
      isFilled={!!billingData?.taxSituation}
      errorText={!loadingBillingDataForm && !billingData?.taxSituation ? emptyFieldError : undefined}
      rawOptions={rawOptions || []}
      currentValue={billingData?.taxSituation}
      customPlaceholder={t(`${i18nLanguageKey}taxRegime`)}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
      formDropdownOptionsCallback={(rawOptions, clickOptionCallback) => {
        return formDropdownOptionsCallback(rawOptions, true, e => {
          if (setIsEdit) {
            setIsEdit(true);
          }
          if (clickOptionCallback) {
            clickOptionCallback(e);
          }
        });
      }}
      formSelectedOptionUICallback={formSelectedOptionUICallback}
      filterBySearchStringCallback={filterBySearchStringCallback}
      dropdownStyles={{ paddingRight: '.8rem' }}
    />
  );

  const getTaxUseDropdownWithSearchbar = (
    rawOptions: ItaxationRelatedDataRequiredForBilling[],
    showSkeletonLoader?: boolean,
    isRequired?: boolean,
    setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <BordDropdown
      label={`${t(`${i18nLanguageKey}useInvoice`)}${isRequired ? '*' : ''}`}
      isFilled={!!billingData?.taxUse}
      errorText={!loadingBillingDataForm && !billingData?.taxUse ? emptyFieldError : undefined}
      rawOptions={rawOptions || []}
      currentValue={billingData?.taxUse}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
      customPlaceholder={t(`${i18nLanguageKey}useInvoice`)}
      formDropdownOptionsCallback={(rawOptions, clickOptionCallback) => {
        return formDropdownOptionsCallback(rawOptions, false, e => {
          if (setIsEdit) {
            setIsEdit(true);
          }
          if (clickOptionCallback) {
            clickOptionCallback(e);
          }
        });
      }}
      formSelectedOptionUICallback={formSelectedOptionUICallback}
      filterBySearchStringCallback={filterBySearchStringCallback}
      dropdownStyles={{ paddingRight: '.8rem' }}
    />
  );

  const getTaxDocumentUploadDownloadButton = (showSkeletonLoader?: boolean) => (
    <NudosUploadDownloadButton
      customWidth="w-380"
      currentFileUrl={billingData?.taxSituationDocUrl || ''}
      buttonEmptyText={getTaxDocumentButtonText()}
      buttonFilledText={getTaxDocumentButtonText()}
      downloadButtonText={t(`${i18nLanguageKey}formFieldsLine:downloadButtonText`)}
      showSkeletonLoader={showSkeletonLoader || loadingBillingDataForm}
      uploadedFileHandler={updateTaxStatusDocumentToUpload}
    />
  );

  const getSpecialBusinessNameType = () => {
    if (!countriesWithSpecialBusiness) return;
    const linkButtonText = capitalize(t('ecommerce:modalToSelectStoreCountry:contactSupportBlue')) || '';
    const linkButton = <NudosLinkTypeButton text={linkButtonText} onClick={handleClickForHelp} />;
    return specialBusinessTypeTrading || specialBusinessTypeZone ? (
      <div className="specialBusinessNameContainer">
        <BordCheckBox isButtonActive={true} isButtonDisabled={true} />
        {specialBusinessText} {linkButton}
      </div>
    ) : (
      <div className="specialBusinessNameContainer withAlert">
        <BordOneToneIcon variant="info" standardSize={14} /> Si tu razón social en Uruguay es de Trading o está en Zona
        Franca,
        {linkButton} antes de finalizar tu compra.
      </div>
    );
  };

  return {
    orgData,
    billingData,
    loadingBillingDataForm,
    isAMexicanOrganization,
    organizationIsUruguay,
    organizationIsColombia,
    isOtherOrganization,
    thisBillingCountryRequiresTaxSituationDocument,
    businessNameTooltip,
    businessHasSpecialNameType,
    setBillingData,
    getEmailInput,
    getCityInput,
    getZipCodeInput,
    isDataIncomplete,
    hasBillingDataChange,
    getFantasyNameInput,
    getTaxDocumentInput,
    getBusinessNameInput,
    getBillingAddressInput,
    getBillingDataStateInput,
    getAdditionalReferencesInput,
    getTaxRegimeDropdownWithSearchbar,
    getTaxUseDropdownWithSearchbar,
    getTaxDocumentUploadDownloadButton,
    handleUpdateBillingData,
    getNudosCountryDropwdown,
    getPhoneNumberInput,
    getSpecialBusinessNameType
  };
};

// This function is not included in the useBillingData hook since it is to be consumed by the parent of a component where the hook is to be consumed. Its an util function, and is included in this module to group the logic pertaining the billing data together.
const getBillingTaxDefinitionsOptions = async (
  setTaxationRelatedDefinitionsOptions: React.Dispatch<
    SetStateAction<DTOgetTaxationRelatedDefinitionsOptions | undefined>
  >
) => {
  try {
    const newBillingTaxDefinitionsOptions = await getTaxationRelatedDefinitionsOptions();
    newBillingTaxDefinitionsOptions && setTaxationRelatedDefinitionsOptions(newBillingTaxDefinitionsOptions);
  } catch (err) {
    displayErrorNotification();
  }
};

export { useBillingData, getBillingTaxDefinitionsOptions };

const emptyBillingData = {
  businessName: undefined,
  document: undefined,
  address: undefined,
  additionalReferences: undefined,
  zipCode: undefined,
  city: undefined,
  state: undefined,
  email: undefined,
  documentCountry: undefined,
  taxSituationDocUrl: undefined,
  taxSituation: undefined,
  taxUse: undefined,
  phone: undefined,
  phoneCountryId: undefined,
  fantasyName: undefined
};
