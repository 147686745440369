const RoundVenezuela = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M30.4124 22.9564C31.4296 20.8529 32 18.4931 32 15.9999C32 13.5067 31.4296 11.1469 30.4124 9.04341L16 7.6521L1.58763 9.04341C0.570438 11.1469 0 13.5067 0 15.9999C0 18.4931 0.570438 20.8529 1.58763 22.9564L16 24.3477L30.4124 22.9564Z"
        />
        <path
          fill="#F93939"
          d="M16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32Z"
        />
        <path
          fill="white"
          d="M27.7105 19.1408 26.8654 19.8012 27.2322 20.8087 26.343 20.2092 25.4979 20.8694 25.7934 19.8385 24.9043 19.2387 25.9761 19.2013 26.2719 18.1704 26.6387 19.1782 27.7105 19.1408ZM25.5467 15.3926 24.9785 16.3022 25.6679 17.1235 24.6272 16.8644 24.0589 17.7737 23.9839 16.7039 22.9434 16.4443 23.9379 16.0425 23.8631 14.9727 24.5524 15.7943 25.5467 15.3926ZM22.2312 12.6104 22.0083 13.6594 22.937 14.1957 21.8705 14.3081 21.6475 15.3569 21.2112 14.3772 20.1445 14.4892 20.9417 13.7715 20.5055 12.7919 21.4342 13.328 22.2312 12.6104ZM18.1641 11.1304 18.3136 12.1924 19.3696 12.3786 18.4057 12.8489 18.5551 13.9109 17.8101 13.1394 16.8459 13.6096 17.3496 12.6625 16.6045 11.8911 17.6607 12.0773 18.1641 11.1304ZM13.8363 11.1304 14.3399 12.0773 15.3959 11.891 14.651 12.6627 15.1544 13.6097 14.1905 13.1394 13.4454 13.9107 13.5948 12.8489 12.6309 12.3788 13.687 12.1924 13.8363 11.1304ZM9.76916 12.6104 10.5663 13.328 11.4949 12.7919 11.0588 13.7719 11.8557 14.4894 10.7892 14.3772 10.3528 15.3569 10.1301 14.3079 9.06348 14.1959 9.99223 13.6595 9.76916 12.6104ZM6.45395 15.3924 7.44851 15.7942 8.13763 14.9727 8.06307 16.0427 9.05732 16.4445 8.01676 16.7038 7.94182 17.7735 7.3737 16.8642 6.33301 17.1235 7.02238 16.302 6.45395 15.3924ZM4.29004 19.1407 5.36204 19.178 5.72866 18.1704 6.0246 19.2014 7.09629 19.2388 6.20716 19.8386 6.50266 20.8695 5.65766 20.2092 4.76848 20.8088 5.13529 19.801 4.29004 19.1407Z"
        />
        <path
          fill="#FFDA2C"
          d="M30.4126 9.0435C27.8246 3.69156 22.3436 0 16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundVenezuela;
