import { BordButton, BordModal, ModalContent } from '../BordDesignSystem';
import onboardingVideo from './video';
import { useRef } from 'react';
import './BordReleaseVideoModal.scss';

const BordReleaseVideoModal = ({ closeModalCallback }: { closeModalCallback?: () => void }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoClick = () => videoRef.current?.[videoRef.current.paused ? 'play' : 'pause']();

  return (
    <BordModal
      modalContent={
        <ModalContent
          toCloseModal={closeModalCallback}
          customHeight="50%"
          customWidth="100%"
          withoutHeaderCustomClass="bordReleaseVideoModalContent"
          excludeCloseButton
          noPadding
          excludeHeader
          CustomModalComponent={
            <div className="bordReleaseVideoModal">
              <video
                ref={videoRef}
                className="bordVideo"
                preload="auto"
                onClick={handleVideoClick}
                src={onboardingVideo}
                onEnded={closeModalCallback}
              />
              <BordButton
                label="Saltar"
                onClick={closeModalCallback}
                modeButton="tertiary"
                customFontSize="1.2rem"
                customClassName="closeVideoButton"
              />
            </div>
          }
        />
      }
    />
  );
};

export default BordReleaseVideoModal;
