import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g stroke="#CCC" strokeLinecap="round" strokeLinejoin="round">
      <path d="M.702 8.912v.815a1.63 1.63 0 0 0 1.63 1.63h7.336a1.63 1.63 0 0 0 1.63-1.63v-.815M8.832 3.455C8.276 2.433 7.449 1.501 6.383.857a.741.741 0 0 0-.767 0c-1.065.645-1.893 1.576-2.448 2.598M6 .857v7.09" />
    </g>
    <defs>
      <path fill="#fff" d="M0 0h12v12H0z" />
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const UploadIcon = memo(ForwardRef);
export default UploadIcon;
