import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordBordIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeWidth="1.5"
        d="M6.43416 18.7512h.00004C7.94607 19.9982 9.88605 20.75 12 20.75c2.7183 0 5.1481-1.2406 6.7512-3.1842C19.9982 16.0539 20.75 14.1139 20.75 12V5.53951c0-1.26501-1.0241-2.28853-2.2895-2.28951H12c-2.11329 0-4.05341.74958-5.56581 1.99879C4.49057 6.85187 3.25 9.28171 3.25 12c0 2.1135.74972 4.0538 1.99915 5.5662.35649.4322.75287.8285 1.18501 1.185Zm8.80044-4.06-.0002.0002c-.7761.9419-1.9498 1.5397-3.2636 1.5397-1.022 0-1.9587-.3617-2.69113-.9655l-.00079-.0007c-.20654-.1698-.39882-.3631-.57443-.5747-.60347-.7324-.9647-1.667-.9647-2.6902 0-1.3137.59782-2.48744 1.53974-3.26359l.00018-.00015c.73253-.60396 1.66743-.96553 2.69113-.96553 2.3351 0 4.2293 1.89422 4.2293 4.22927 0 1.022-.3616 1.9588-.9655 2.6912Z"
      />
    </svg>
  );
};

export default BordBordIcon;
