const SquareSintMaarten = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path fill="#F93939" d="M22 0H0V8H22V0Z" />
          <path fill="white" fillRule="evenodd" d="M0 0L10.4762 8L0 16V0Z" clipRule="evenodd" />
          <path
            fill="#AE6A3E"
            fillRule="evenodd"
            d="M1.04785 5.3335H3.14309L4.19071 6.40016L5.23833 5.3335H7.33357L5.23833 6.40016H3.14309L1.04785 5.3335Z"
            clipRule="evenodd"
          />
          <path
            fill="#3ECBF8"
            d="M4.1902 10.6666C4.76878 10.6666 5.23782 9.71144 5.23782 8.53324C5.23782 7.35503 4.76878 6.3999 4.1902 6.3999C3.61161 6.3999 3.14258 7.35503 3.14258 8.53324C3.14258 9.71144 3.61161 10.6666 4.1902 10.6666Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M3.14332 7.4668V9.60013H4.19094H5.23856V7.4668L6.28618 9.60013L5.23856 10.6668H3.14332L2.0957 9.60013L3.14332 7.4668Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSintMaarten;
