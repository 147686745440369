const RoundAndorra = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M9.04347 30.4124C11.147 31.4295 13.5068 32 16 32s4.853-.5705 6.9565-1.5876L24.3478 16 22.9565 1.58763C20.853.5705 18.4932 0 16 0s-4.853.5705-6.95653 1.58763L7.65216 16l1.39131 14.4124Z"
        />
        <path
          fill="#F93939"
          d="M22.9565 30.4118C28.3084 27.8239 32 22.3433 32 15.9999c0-6.34326-3.6916-11.82389-9.0435-14.41177V30.4118Z"
        />
        <path
          fill="#1A47B8"
          d="M9.0435 30.4118V1.58813C3.69156 4.17601 0 9.65657 0 15.9999c0 6.3434 3.69156 11.824 9.0435 14.4119Z"
        />
        <path
          fill="#F93939"
          d="M16 21.5652V16h4.1739v2.0869c0 .3626-.6956 1.6911-2.4127 2.7827-.6503.4135-1.3234.5465-1.7612.6956ZM11.826 11.826H16V16h-4.174v-4.174Z"
        />
        <path
          fill="#FF9811"
          d="M18.0869 10.4348c0-.76847-.6229-1.39134-1.3913-1.39134-.2537 0-.4908.069-.6956.18762-.2048-.11862-.4419-.18762-.6956-.18762-.7684 0-1.3913.62287-1.3913 1.39134h-3.4783v6.9564c0 2.5898 2.0566 4.0874 3.6695 4.8638-.1207.2061-.1912.4453-.1912.7015 0 .7685.6229 1.3913 1.3913 1.3913.2537 0 .4909-.069.6956-.1876.2048.1186.4419.1876.6956.1876.7684 0 1.3913-.6228 1.3913-1.3913 0-.2562-.0705-.4954-.1912-.7015 1.6128-.7764 3.6695-2.274 3.6695-4.8638v-6.9564h-3.4783Zm1.3914 6.9564c0 .3626 0 1.4658-1.717 2.5574-.6504.4135-1.3235.6816-1.7613.8307-.4377-.1491-1.1109-.4172-1.7612-.8307-1.717-1.0916-1.717-2.1948-1.717-2.5574v-4.8695h6.9565v4.8695Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAndorra;
