const BordWipingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <rect
        width="32.016"
        height="32.016"
        x="9.463"
        y=".6"
        fill="url(#a)"
        rx="4.8"
      />
      <path
        fill="url(#b)"
        d="M33.718 11.584c-.71-.072-1.341.434-1.398 1.12l-.551 6.736c-.031.377-.612.355-.611-.024l.005-9.737c0-.738-.608-1.344-1.374-1.37-.789-.026-1.449.57-1.471 1.33l-.261 8.799c-.015.497-.78.505-.806.008l-.512-9.912c-.038-.745-.685-1.326-1.46-1.31-.784.016-1.409.636-1.402 1.39l.086 9.672c.003.334-.509.364-.547.031l-.97-8.503c-.083-.73-.764-1.255-1.52-1.176-.743.078-1.286.71-1.227 1.426l1.12 13.556c.017.208-.27.294-.38.114a39.245 39.245 0 0 0-1.718-2.583c-.628-.872-1.86-1.105-2.786-.53l-.024.015a.777.777 0 0 0-.252 1.086 33.192 33.192 0 0 1 3.167 6.3c.998 2.337 2.199 3.787 3.283 4.656a2.784 2.784 0 0 1 1.052 2.172v4.941h9.819v-3.21c0-.557.023-1.113.068-1.669l1.803-22.016c.054-.661-.448-1.243-1.133-1.312Z"
      />
      <g filter="url(#c)">
        <g filter="url(#d)">
          <rect
            width="34.8"
            height="34.8"
            x="8.197"
            y="4.8"
            fill="#fff"
            fillOpacity=".2"
            rx="4.8"
          />
        </g>
        <g filter="url(#e)">
          <path
            fill="#fff"
            fillOpacity=".2"
            d="M34.563 16.74c-.771-.079-1.458.471-1.52 1.216l-.599 7.323c-.034.409-.665.385-.664-.026l.005-10.584c0-.803-.66-1.461-1.493-1.49-.857-.028-1.575.62-1.6 1.447l-.282 9.563c-.017.54-.848.549-.876.009l-.556-10.773c-.042-.81-.746-1.442-1.588-1.425-.852.017-1.531.691-1.524 1.512l.093 10.512c.003.364-.553.395-.594.034l-1.054-9.243c-.09-.792-.83-1.364-1.654-1.278-.806.085-1.396.771-1.332 1.55l1.217 14.735c.018.226-.293.32-.412.124a42.69 42.69 0 0 0-1.868-2.808c-.682-.947-2.023-1.201-3.028-.575l-.026.016a.844.844 0 0 0-.274 1.18 36.08 36.08 0 0 1 3.442 6.848c1.084 2.54 2.39 4.116 3.569 5.06a3.026 3.026 0 0 1 1.143 2.362v5.37h10.673V43.91c0-.605.024-1.21.074-1.814l1.96-23.93c.058-.719-.488-1.352-1.232-1.427Z"
          />
        </g>
        <path
          fill="#fff"
          d="m10.334 11.471 2.216-2.95a.575.575 0 0 1 1.034.308l.237 3.683c.01.167.094.322.228.423L17 15.15c.427.321.225 1-.309 1.034l-3.682.237a.576.576 0 0 0-.422.229l-2.217 2.95a.575.575 0 0 1-1.034-.309L9.1 15.61a.576.576 0 0 0-.229-.423l-2.952-2.215a.575.575 0 0 1 .309-1.034l3.682-.237a.575.575 0 0 0 .423-.229h.002ZM35.029 8.02l-.058-3.232a.504.504 0 0 1 .88-.343l2.147 2.416c.097.11.238.171.385.169l3.23-.058a.504.504 0 0 1 .345.88L39.542 10a.504.504 0 0 0-.169.385l.058 3.231a.504.504 0 0 1-.88.344l-2.147-2.416a.504.504 0 0 0-.385-.169l-3.232.06a.504.504 0 0 1-.343-.88l2.415-2.147a.504.504 0 0 0 .17-.386V8.02ZM37.77 31.987l.027-2.24a.35.35 0 0 1 .617-.22l1.438 1.719a.35.35 0 0 0 .263.125l2.241.027a.35.35 0 0 1 .22.618l-1.719 1.437a.35.35 0 0 0-.125.264l-.027 2.24a.35.35 0 0 1-.618.22l-1.437-1.719a.35.35 0 0 0-.264-.125l-2.241-.026a.35.35 0 0 1-.22-.617l1.719-1.438a.35.35 0 0 0 .125-.264Z"
        />
      </g>
      <defs>
        <filter
          id="c"
          width="40.91"
          height="46.725"
          x="3.888"
          y="2.474"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation=".9" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1398_13995"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1398_13995"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1.2" />
          <feGaussianBlur stdDeviation="1.56" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_1398_13995" />
        </filter>
        <filter
          id="d"
          width="38.401"
          height="38.4"
          x="6.397"
          y="3"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation=".9" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1398_13995"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1398_13995"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1.2" />
          <feGaussianBlur stdDeviation="1.56" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_1398_13995" />
        </filter>
        <filter
          id="e"
          width="24.6"
          height="39"
          x="12.999"
          y="10.2"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation=".9" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1398_13995"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1398_13995"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1.2" />
          <feGaussianBlur stdDeviation="1.56" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_1398_13995" />
        </filter>
        <linearGradient
          id="a"
          x1="25.471"
          x2="25.343"
          y1=".6"
          y2="29.201"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="25.195"
          x2="24.976"
          y1="7.224"
          y2="36.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BordWipingIcon;
