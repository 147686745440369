import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmployeePills } from '../../../../../components';
import { BordOneToneIcon, BordPhoto } from '../../../../../components/BordDesignSystem';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import EmployeeAssignedToolsSummary from './components/EmployeeAssignedToolsSummary/EmployeeAssignedToolsSummary';
import { Iemployee } from '../../../../../types/global';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import './EmployeeCard.scss';

const EmployeeCard = ({ employee }: IemployeeCard) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const i18nLanguageKey = 'nodi:employee:employeeCard:';
  const cityPillKey = t(`${i18nLanguageKey}employeePills:city`);
  const areaPillKey = t(`${i18nLanguageKey}employeePills:area`);
  const positionPillKey = t(`${i18nLanguageKey}employeePills:position`);

  const employeePills = {
    [cityPillKey]: employee.city || '',
    [areaPillKey]: employee.areaStringId ? t(`services:employeeArea:${employee.areaStringId}`) : employee.area || '',
    [positionPillKey]: employee.position || ''
  };

  const employeeFullName = `${employee.firstName} ${employee.lastName}`;
  const employeePhoto = employee?.photoProfile ? employee.photoProfile.replaceAll(' ', '%20') : undefined;

  const noAddress =
    !employee.address ||
    !employee.address.address ||
    typeof employee.address.address !== 'string' ||
    !employee.address.country ||
    !employee.address.city;
  const noAddressStyle = noAddress ? 'noAddress' : '';
  const addressExistsAndApply = !noAddress;
  const currentTools = employee?.assignedTools?.filter(tool => !tool?.assignedUntil);
  const isActive = !!employee?.active;

  const handleClickCard = () => {
    push(`/nodi/employees/details/${employee.userId}`);
  };

  return (
    <div className="employeeCard" onClick={handleClickCard} style={{ opacity: !isActive ? '.3' : '' }}>
      <div className="col1">
        <BordPhoto
          size="s56"
          url={employeePhoto}
          imageProps={{ style: { width: '100%', height: '100%', borderRadius: '1.1rem' } }}
          flagProps={{
            country: employee?.country?.code as TcountryCode,
            variant: 'circle',
            standardSize: 24,
            flagBottom: '-7%'
          }}
          avatarProps={{
            variant: 'rectangularBordMascot',
            customWidth: '5.6rem'
          }}
        />
        <div className="addressAndName">
          {employee.firstName && employee.lastName && (
            <NudosHoverText
              text={employeeFullName || ''}
              charactersLimit={20}
              customClassName={`name ${!isActive ? 'grayName' : ''}`}
            />
          )}
          <div className={`address ${noAddressStyle}`}>
            <BordOneToneIcon className={`bordOneToneIcon`} variant="home" standardSize={12} />
            {addressExistsAndApply && (
              <NudosHoverText
                text={employee?.address?.address || ''}
                charactersLimit={35}
                customClassName={`${!isActive ? 'grayName' : ''}`}
              />
            )}
            {noAddress && (
              <div className={`noAddressText ${!isActive ? 'secondGray' : ''}`}>
                {t(`${i18nLanguageKey}noAddressText`)}
              </div>
            )}
          </div>
        </div>
      </div>

      <EmployeePills pills={employeePills} isActive={!!employee?.active} />
      <EmployeeAssignedToolsSummary currentTools={currentTools} />
      {!employee?.active && (
        <div className="assignationPercentage">
          <div className="disabledText">{t(`${i18nLanguageKey}disabledText`)}</div>
        </div>
      )}
      <div className="threeDotsButton">
        <BordOneToneIcon variant="arrowRightCircle" standardSize={20} />
      </div>
    </div>
  );
};

export default EmployeeCard;

export interface IemployeeCard {
  employee: Iemployee;
}
