import create from 'zustand';
import { ImoduleFilter } from '../types/global';

export interface iState {
  employeesCount: number;
  employeesFilters: ImoduleFilter[];
  employeeSearchbarText?: string;
  setEmployeesFilters: (newFilter: ImoduleFilter[]) => void;
  setEmployeesCount: (employeesNumber: number) => void;
  setEmployeeSearchbarText: (employeeSearchbarText: string) => void;
}

const useEmployeeListingState = create<iState>(set => ({
  employeesCount: 0,
  employeesFilters: [],
  employeeSearchbarText: '',
  setEmployeesFilters: newFilters => set(() => ({ employeesFilters: newFilters })),
  setEmployeesCount: employeesCount => set({ employeesCount }),
  setEmployeeSearchbarText: employeeSearchbarText => set({ employeeSearchbarText })
}));

export default useEmployeeListingState;
