const RoundNauru = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9996 0C7.63186 0 .765734 6.42381.0605469 14.6087H31.9387C31.2335 6.42381 24.3674 0 15.9996 0ZM15.9996 32C24.3674 32 31.2335 25.5762 31.9387 17.3914H.0605469C.765734 25.5762 7.63186 32 15.9996 32Z"
        />
        <path
          fill="white"
          d="M13.2178 21.5652L11.4249 22.4086L12.3796 24.1449L10.4328 23.7725L10.1861 25.7392L8.83007 24.2927L7.47407 25.7392L7.22738 23.7725L5.28051 24.1449L6.23526 22.4085L4.44238 21.5652L6.23532 20.7219L5.28051 18.9857L7.22732 19.358L7.47413 17.3914L8.83007 18.8378L10.1862 17.3914L10.4328 19.358L12.3797 18.9857L11.425 20.722L13.2178 21.5652Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNauru;
