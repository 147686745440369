const SquareRwanda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path fill="#0A6A30" fillRule="evenodd" d="M0 12H22V16H0V12Z" clipRule="evenodd" />
          <path fill="#03BCDB" fillRule="evenodd" d="M0 0H22V7H0V0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M17.7819 4.2389C18.4062 4.1685 18.8567 3.96797 18.8567 3.7333C18.8567 3.49863 18.4062 3.2981 17.7819 3.2277C17.7127 2.59197 17.5158 2.1333 17.2853 2.1333C17.0548 2.1333 16.8579 2.59197 16.7887 3.2277C16.1643 3.2981 15.7139 3.49863 15.7139 3.7333C15.7139 3.96797 16.1643 4.1685 16.7887 4.2389C16.8579 4.87463 17.0548 5.3333 17.2853 5.3333C17.5158 5.3333 17.7127 4.87463 17.7819 4.2389Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareRwanda;
