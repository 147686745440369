import { apiGet, apiPost, apiPut } from './api';
import { subscriptionAPI, usersAPI } from '../constants';
import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  IAcquisitionRent,
  IAcquisitionSales,
  IAreasData,
  IDashboardInfo,
  IDataChartAdquisition,
  IDataChartEmployees,
  IDataChartTools,
  IDataNotifications,
  IToolsFilters
} from '../types/dashboard';

export const getDataDash = async (params: string): Promise<IDashboardInfo> => {
  return apiGet<IDashboardInfo, IDashboardInfo>(`${subscriptionAPI}/order/dashboard/${params}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const updateAlert = async (id: number | string): Promise<string> => {
  return apiPut<string, string>(`${usersAPI}/notifications/clicked/${id}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getChartTools = async (organizationId: number, countryfilter?: string, statusFilter?: string) => {
  const countryFiltersParam = countryfilter ? `countryFilter=${countryfilter}` : '';
  const statusFiltersParam = statusFilter ? `statusFilter=${statusFilter}` : '';
  const hasFilters = countryfilter || statusFilter;
  const hasBothFilters = countryfilter && statusFilter;
  const filters = hasFilters ? `?${countryFiltersParam}${hasBothFilters ? '&' : ''}${statusFiltersParam}` : '';
  return apiGet<IDataChartTools, IDataChartTools>(
    `${subscriptionAPI}/order/dashboard/tools/${organizationId}${filters}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getChartEmployees = async (organizationId: number, filter?: string) => {
  return apiGet<IDataChartEmployees, IDataChartEmployees>(
    `${subscriptionAPI}/order/dashboard/employees/${organizationId}${filter ? `?filter=${filter}` : ''}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getChartAdquisition = async (organizationId: number, filter?: number) => {
  return apiGet<IDataChartAdquisition, IDataChartAdquisition>(
    `${subscriptionAPI}/order/dashboard/acquisition/${organizationId}${filter ? `?month=${filter}` : ''}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getDataNotifications = async (organizationId: number) => {
  return apiGet<IDataNotifications[], IDataNotifications[]>(
    `${subscriptionAPI}/order/dashboard/notifications/${organizationId}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getFilterToolsValue = async (organizationId: number) => {
  return apiGet<IToolsFilters[], IToolsFilters[]>(
    `${subscriptionAPI}/order/dashboard/tools/filter-values/${organizationId}?order=name`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getAcquisitionSales = async (organizationId: number) => {
  return apiGet<IAcquisitionSales, IAcquisitionSales>(
    `${subscriptionAPI}/order/dashboard/acquisition/${organizationId}/sale`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getAcquisitionSubscription = async (organizationId: number) => {
  return apiGet<IAcquisitionRent, IAcquisitionRent>(
    `${subscriptionAPI}/order/dashboard/acquisition/${organizationId}/subscription`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getChartAreas = async (organizationId: number, filter?: string) => {
  return apiGet<IAreasData, IAreasData>(
    `${subscriptionAPI}/order/dashboard/areas-equipment/${organizationId}${filter ? `?countryFilter=${filter}` : ''}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getFilterAreaValue = async (organizationId: number) => {
  return apiGet<IToolsFilters[], IToolsFilters[]>(
    `${subscriptionAPI}/order/dashboard/positions/filter-values/${organizationId}?order=name`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const postMassiveToolsUploadRequest = async (
  organizationId: number | string,
  userId: number | string
): Promise<string> => {
  return apiPost<AxiosRequestConfig>(`${subscriptionAPI}/product-external/upload-request/${organizationId}/${userId}`);
};

export const getDownLoadInventoryResume = async (
  organizationId: number | string,
  filters?: string
): Promise<string> => {
  return apiGet<string, string>(`${subscriptionAPI}/order/download-inventory/${organizationId}${filters || ''}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};
