import { FC, useState } from 'react';
import NudosPopover from '../../../../../components/NudosComponents/NudosPopover/NudosPopover';
import { ILocationAddress } from '../../../../../types/account';
import { BordTextInput } from '../../../../../components/BordDesignSystem';
import { BordButton } from '../../../../../components/BordDesignSystem';
import { useTranslation } from 'react-i18next';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import { ICountryDetail } from '../../../../../types/countries';
import './modalAddressDetail.scss';

interface IModalAddressDetail {
  modalData?: ILocationAddress;
  modalLoading?: boolean;
  modalCallBack?: () => void;
  modalExit?: () => void;
}

const ModalAddressDetail: FC<IModalAddressDetail> = ({
  modalData,
  modalLoading,
  modalCallBack,
  modalExit
}: IModalAddressDetail) => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  return (
    <div id="modalAddressDetail">
      <div className="boxModal">
        <div className="flex flex-col gap-8 items-center">
          <span className="text-bord-h2 text-content font-bold">{t('recurrentWords:office')}</span>
          <span className="text-bord-sm text-content-secondary font-normal">
            Consulta la información de otra dirección
          </span>
        </div>
        <div className="flex items-center justify-start mt-40 mb-12">
          <span className="text-bord-subtitle text-content font-bold">{orgData?.organizationName}</span>
        </div>
        <div className="flex items-center gap-20">
          <NudosCountryDropdown
            charactersLimit={6}
            customLabel="País"
            currentValue={modalData?.country as ICountryDetail}
            countriesList={[]}
            customPlaceholder="País"
            componentSize="w-180"
            isDeactivated
          />
          <BordTextInput
            standardSize="w-180"
            label="Nombre de la dirección"
            isFilled
            currentText={modalData?.locationName || modalData?.address || ''}
            placeholder="Escribe un nombre"
            disabled
          />
        </div>
        <div className="mt-12 mb-12">
          <BordTextInput
            className="mr-20"
            standardSize="w-380"
            label="Dirección"
            isFilled
            placeholder="Busca la dirección de la empresa"
            disabled
            currentText={modalData?.address || ''}
          />
        </div>
        <div className="flex items-center gap-20 mt-12">
          <BordTextInput
            label="Código postal"
            placeholder="Escribe el código postal"
            currentText={modalData?.zipCode}
            standardSize="w-180"
            showSkeletonLoader={false}
            disabled
          />
          <BordTextInput
            standardSize="w-180"
            label="Indicaciones (opcional)"
            isFilled
            placeholder="Escribe referencias del lugar"
            disabled
            currentText={modalData?.additionalReferences || ''}
          />
        </div>
        <div className="detailButtons">
          <div className="firstButton">
            {openPopover && (
              <NudosPopover
                topPopover={-95}
                leftPopover={'0'}
                positionArrow="bottomLeft"
                firstButtonText={modalData?.active ? 'Deshabilitar' : 'Habilitar'}
                secondButtonText="Cancelar"
                callBackFirstButton={() => {
                  if (modalCallBack) {
                    setOpenPopover(false);
                    modalCallBack();
                  }
                }}
                callBackSecondButton={() => {
                  setOpenPopover(false);
                }}
              >
                ¿Estás seguro de deshabilitar <br />
                esta dirección para servicios
                <br /> logísticos?
              </NudosPopover>
            )}
            <BordButton
              isLoading={modalLoading}
              disabled={modalLoading}
              bordButtonStyle="outlined"
              label={modalData?.active ? 'Deshabilitar' : 'Habilitar'}
              onClick={() => {
                if (modalCallBack) setOpenPopover(true);
              }}
              customWidth="w-180"
            />
          </div>
          <BordButton
            onClick={() => {
              if (modalExit) modalExit();
            }}
            label="Entendido"
            customWidth="w-180"
          />
        </div>
      </div>
    </div>
  );
};

export default ModalAddressDetail;
