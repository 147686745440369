import { AxiosError, AxiosRequestConfig } from 'axios';
import { orgAPI } from '../constants';
import { ICountryDetail, IListType } from '../types/countries';
import { apiGet } from './api';

const getCountriesList = async (paramsList?: IListType): Promise<ICountryDetail[]> => {
  return apiGet<AxiosRequestConfig, ICountryDetail[]>(
    `${orgAPI}/country/listing${paramsList ? `?offeredService=${paramsList}` : ''}${
      !paramsList ? `?order=name` : '&order=name'
    }`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

const getCountryById = async (countryId: number | string): Promise<ICountryDetail> => {
  return apiGet<AxiosRequestConfig, ICountryDetail>(`${orgAPI}/country/${countryId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export { getCountriesList, getCountryById };
