import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICountryDetail } from '../../../types/countries';
import { NudosCountryDropdown } from '../../DesignSystem';
import { BordTextInput } from '..';
import './BordPhoneInput.scss';

/**
 * A react functional component corresponding to the nudos phone input
 * @property {string} placeholder - Required
 * @property {string} label - Optional, a label to display on top of the phone input
 * @property {string} errorText - Optional, the text to be shown if theres an error with the input form. If an error is passed the style of the component will change to reflect that.
 * @property {boolean} isDisabled - Optional, if a value is provided the input would not allowed new searches. Styles would reflect its status as disabled.
 * @property {string} componentSize - Optional, one of the standard sizes for the components, corresponding to one of the following words: "small", "medium" or "large". If no value is passed the default is small.
 * @property {function(phoneNumber: string): void} handlePhoneChange - Optional, a callback to execute upon changing the phoneNumber
 * @property {ICountryDetail} defaultCountryData - Optional, an object with the data of the default country
 * @property {string} defaultPhoneNumber - Optional, for cases when there is a default value. The phone number withount the country code
 * @property {funcion(): void} handleBlur - Optional, a callback to pass to the onBlur event for the input component (the text input, not the select one)
 * @property { string } personalClass - Optional, a string for customizing styles
 * @property { ICountryDetail[] } countriesList - The list of countries from which the user can select the phone country
 * @property { (newPhoneCountry: ICountryDetail | undefined) => void } handleCountryPhoneChange - The function that updates the countryData of the phone
 */

const BordPhoneInput = ({
  placeholder,
  label,
  errorText,
  isDisabled,
  componentSize,
  handlePhoneChange,
  defaultCountryData,
  defaultPhoneNumber,
  handleBlur,
  personalClass,
  showSkeletonLoader,
  countriesList,
  handleCountryPhoneChange,
  simpleChangeOfValues,
  inputCustomWidth
}: IbordPhoneInput) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [inputPhoneNumber, setInputPhoneNumber] = useState<string>('');
  const filledStyles = !!inputPhoneNumber && (selectedCountry || defaultCountryData) ? 'filled' : '';
  const errorStyles = errorText && errorText.length > 0 ? 'error' : '';
  const disabledStyles = isDisabled ? 'disabled' : '';
  const componentSizeStyles = componentSize ? componentSize : '';
  const labelClassnames = showSkeletonLoader ? 'animationLoader' : '';
  const recurrentWords = 'recurrentWords';
  const countryPlaceholder = t(`${recurrentWords}:country`);

  const updatePhoneText = (newPhone: string) => {
    const formattedPhone = newPhone.trim().replace(/[^0-9]/g, '');
    setInputPhoneNumber(formattedPhone);
    simpleChangeOfValues && simpleChangeOfValues();
  };

  const updatePhoneCountry = (newCountry: ICountryDetail) => {
    setSelectedCountry(newCountry);
    simpleChangeOfValues && simpleChangeOfValues();
  };

  const getTextInputStyles = () => {
    if (inputCustomWidth) return { width: inputCustomWidth };
    switch (componentSize) {
      case 'w180':
        return { width: '9.6rem' };
      case 'w240':
        return { width: '15.6rem' };
      case 'w280':
        return { width: '19.6rem' };
      case 'w380':
        return { width: '29.6rem' };
      case 'w-full':
        return { width: '100%' };
    }
  };

  const textInputStyles = getTextInputStyles();

  useEffect(() => {
    handlePhoneChange(inputPhoneNumber);
    handleCountryPhoneChange(selectedCountry);
  }, [selectedCountry, inputPhoneNumber]);

  useEffect(() => {
    defaultPhoneNumber && setInputPhoneNumber(defaultPhoneNumber);
  }, [defaultPhoneNumber]);

  useEffect(() => {
    if (!defaultCountryData || selectedCountry) return;
    setSelectedCountry(defaultCountryData);
  }, [defaultCountryData]);

  return (
    <div className={`bordPhoneInput ${errorStyles} ${disabledStyles} ${componentSizeStyles} ${filledStyles}`}>
      {label && <label className={labelClassnames}>{showSkeletonLoader ? '' : label}</label>}
      <div className="phoneInput">
        <NudosCountryDropdown
          charactersLimit={5}
          errorText={errorText}
          hideErrorText={true}
          displayLabel={false}
          currentValue={selectedCountry}
          countriesList={countriesList || []}
          isDeactivated={isDisabled}
          customPlaceholder={countryPlaceholder}
          isPhoneIndicative={true}
          showSkeletonLoader={showSkeletonLoader}
          updateCurrentValueCallback={updatePhoneCountry}
          componentSize="w-80"
        />
        <BordTextInput
          placeholder={placeholder}
          isFilled={!!inputPhoneNumber}
          errorText={errorText}
          hideErrorText={true}
          disabled={isDisabled}
          setCurrentText={updatePhoneText}
          currentText={inputPhoneNumber}
          handleBlur={handleBlur}
          className={personalClass || ''}
          showSkeletonLoader={showSkeletonLoader}
          inputProps={{ type: 'number' }}
          style={textInputStyles}
        />
      </div>
      {errorText && <div className="errorText">{errorText}</div>}
    </div>
  );
};

export default BordPhoneInput;

export interface IbordPhoneInput {
  placeholder: string;
  countriesList: ICountryDetail[];
  handleCountryPhoneChange: (newPhoneCountry: ICountryDetail | undefined) => void;
  handlePhoneChange: (phoneNumber: string) => void;
  label?: string;
  errorText?: string;
  isDisabled?: boolean;
  componentSize?: 'w180' | 'w240' | 'w280' | 'w380' | 'w-full';
  inputCustomWidth?: string;
  defaultCountryData?: ICountryDetail;
  defaultPhoneNumber?: string;
  handleBlur?: () => void;
  personalClass?: string;
  showSkeletonLoader?: boolean;
  simpleChangeOfValues?: () => void;
}
