import { AxiosError, AxiosRequestConfig } from 'axios';
import {
  DTOGetUsersOrgDataResponse,
  DTOPutAddress,
  DTOPostOrder,
  DTOGetNudos,
  DTOCatalog,
  DTOgetAssigneeInfo,
  DTOemployeeInfo,
  DTOunassignEmail,
  DTOputDaasContactForm,
  DTOgetModuleFilters,
  IDetermineSLBillingMethod,
  IDetermineSLBillingMethodBody
} from '../types/DTO';
import { apiGet, apiPost, apiPut } from './api';
import { usersAPI, orgAPI, catalogAPI, subscriptionAPI } from '../constants';
import {
  IassigneeInfo,
  IemployeeInfo,
  InudoInfo,
  IpostOrderResponse,
  IproductInfo,
  IproductList,
  Tany,
  valueKey
} from '../types/global';
import { formNudoObject, formProductList, formProductObject } from '../utils/formObjectFromDTO';
import { DTOProductList, IProductDetail } from '../types/catalogue';

export const getOf = async (): Promise<{ data: unknown[] }> => {
  return apiGet<AxiosRequestConfig, { data: unknown[] }>(`/`);
};

export const validateEmailExistence = async (email: string): Promise<unknown> => {
  return apiGet<AxiosRequestConfig, unknown>(`${usersAPI}/user/by-email/${email}`)
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
};

export const getUsersOrganizationData = async (userId: string): Promise<DTOGetUsersOrgDataResponse> => {
  return apiGet(`${orgAPI}/organization/login/${userId}`);
};
export const getCatalog = async (filters: string): Promise<IproductList> => {
  return apiGet<DTOCatalog, DTOCatalog>(`${catalogAPI}${filters}`)
    .then(catalog => {
      const newValues: IproductList = formProductList(catalog);
      return Promise.resolve(newValues);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getProductList = async (filters: string): Promise<DTOProductList> => {
  return apiGet<DTOProductList, DTOProductList>(`${catalogAPI}${filters}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
export const getProduct = async (organizationId: number, id: string): Promise<IproductInfo> => {
  return apiGet<DTOCatalog, DTOCatalog>(`${catalogAPI}/catalog/product-detail/${organizationId}/${id}`)
    .then(({ items }) => {
      const newVaule: IproductInfo = formProductObject(items[0]);
      return Promise.resolve(newVaule);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getProductDetails = async (urlProperties: string): Promise<IProductDetail> => {
  return apiGet<IProductDetail, IProductDetail>(`${catalogAPI}/catalog/item-detail/${urlProperties}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getCategories = async (): Promise<valueKey[]> => {
  return apiGet<
    { id: number; name: string; imageId: number; imageUrl?: string; nameStringId?: string }[],
    { id: number; name: string; imageId: number; imageUrl?: string; nameStringId?: string }[]
  >(`${catalogAPI}/filters/categories`)
    .then(values => {
      const newValues: valueKey[] = values.map(ma => {
        return { label: ma.name, value: `${ma.id}`, imageUrl: ma.imageUrl, nameStringId: ma.nameStringId };
      });
      return Promise.resolve(newValues);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};
export const getBrands = async (categoryFilter: string): Promise<valueKey[]> => {
  return apiGet<{ id: number; name: string; imageId: number }[], { id: number; name: string; imageId: number }[]>(
    `${catalogAPI}/filters/brands${categoryFilter}`
  )
    .then(values => {
      const newValues: valueKey[] = values.map(ma => {
        return { label: ma.name, value: ma.id };
      });
      return Promise.resolve(newValues);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};
export const putAddress = async (body: DTOPutAddress, organizationId: number): Promise<DTOPutAddress> => {
  return apiPut<AxiosRequestConfig, DTOPutAddress>(`${orgAPI}/organization/address/${organizationId}`, body)
    .then(values => Promise.resolve(values))
    .catch((error: AxiosError) => Promise.reject(error));
};
export const postOrder = async (body: DTOPostOrder): Promise<IpostOrderResponse> => {
  return apiPost<AxiosRequestConfig, DTOPostOrder>(`${catalogAPI}/send-slack-message`, body)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getUserAddress = async (organizationId: number): Promise<DTOPutAddress> => {
  return apiGet(`${orgAPI}/organization/address/${organizationId}`);
};

export const getBoardFilters = async (organizationId: number | string): Promise<DTOgetModuleFilters> => {
  return apiGet(`${subscriptionAPI}/filters/${organizationId}`)
    .then(response => Promise.resolve(response as DTOgetModuleFilters))
    .catch(error => Promise.reject(error));
};

export const getSubscriptions = async (organizationsSubs: string): Promise<InudoInfo[]> => {
  return apiGet<DTOGetNudos[], DTOGetNudos[]>(`${subscriptionAPI}${organizationsSubs}`)
    .then(values => {
      const nudos: InudoInfo[] = values.map(backend => formNudoObject(backend));
      return Promise.resolve(nudos);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getNodiProductById = async (productId: string): Promise<InudoInfo> => {
  return apiGet<DTOGetNudos, DTOGetNudos>(`${subscriptionAPI}/order/by-product/${productId}`)
    .then(value => {
      const nudo: InudoInfo = formNudoObject(value);
      return Promise.resolve(nudo);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getAcquisitionAssignationHistory = async (productId: string): Promise<IassigneeInfo[]> => {
  return apiGet<DTOgetAssigneeInfo[], DTOgetAssigneeInfo[]>(
    `${subscriptionAPI}/assignment/assignments-users/${productId}`
  )
    .then(value => {
      const assignationHistory: IassigneeInfo[] = value;
      return Promise.resolve(assignationHistory);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getOrganizationEmployees = async (organizationId: string, filter: string): Promise<IemployeeInfo[]> => {
  return apiGet<DTOemployeeInfo[], DTOemployeeInfo[]>(`${orgAPI}/organization/positions/${organizationId}${filter}`)
    .then(response => {
      const employees: IemployeeInfo[] = response;
      return Promise.resolve(employees);
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

export const postDetermineSLBillingMethod = async (
  data: IDetermineSLBillingMethodBody
): Promise<IDetermineSLBillingMethod> => {
  return apiPost<IDetermineSLBillingMethod, IDetermineSLBillingMethod>(
    `${subscriptionAPI}/billing-method/determine`,
    data
  )
    .then(response => Promise.resolve(response?.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putSendUnassignEmail = async (body: DTOunassignEmail): Promise<Tany> => {
  return apiPut<AxiosRequestConfig, DTOunassignEmail>(`${subscriptionAPI}/assignment/send-unassign-email`, body)
    .then(response => Promise.resolve(response || 200))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putDaasContactForm = async (body: DTOputDaasContactForm): Promise<Tany> => {
  return apiPost<AxiosRequestConfig, DTOputDaasContactForm>(`${subscriptionAPI}/daas-log/from-ecommerce`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const uploadProfilePhoto = async (userId: string | number, photoFileData: FormData): Promise<string> => {
  return apiPut<AxiosRequestConfig, FormData>(`${usersAPI}/user/profile-photo/${userId}`, photoFileData)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const uploadAssignationDocument = async (
  assignmentIds: (string | number)[],
  shouldSendEmail: number,
  documentFileData?: FormData
): Promise<Tany> => {
  const assignmentIdsParam = JSON.stringify(assignmentIds);
  return apiPut<AxiosRequestConfig, FormData>(
    `${subscriptionAPI}/product/upload/file-assignment/${assignmentIdsParam}?sendMail=${shouldSendEmail}`,
    documentFileData
  )
    .then(response => Promise.resolve(response ? response : 200))
    .catch((error: AxiosError) => Promise.reject(error));
};
