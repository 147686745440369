import create from 'zustand';
import { IbillingData } from '../types/checkout';
import { IDetermineSLBillingMethod } from '../types/DTO';

export interface logisticsServiceBillingState {
  invoiceAdditionalEmail?: string;
  setInvoiceAdditionalEmail: (invoiceAdditionalEmail: string) => void;
  logisticsServiceBillingData?: IbillingData;
  setLogisticsServiceBillingData: (logisticsServiceBillingData?: IbillingData) => void;
  billingMethodAndCountry?: IBillingMethodAndCountry;
  setBillingCountryCode: (billingMethodAndCountry?: IBillingMethodAndCountry) => void;
  cleanLsBillingSate: () => void;
}

interface IBillingMethodAndCountry {
  billingCountryCode?: string;
  billingMethod?: IDetermineSLBillingMethod;
}

const useLogisticsServiceBillingState = create<logisticsServiceBillingState>(set => ({
  invoiceAdditionalEmail: undefined,
  setInvoiceAdditionalEmail: invoiceAdditionalEmail => set({ invoiceAdditionalEmail }),
  logisticsServiceBillingData: undefined,
  setLogisticsServiceBillingData: logisticsServiceBillingData => set({ logisticsServiceBillingData }),
  billingMethodAndCountry: undefined,
  setBillingCountryCode: billingMethodAndCountry => set({ billingMethodAndCountry }),
  cleanLsBillingSate: () => {
    set({
      invoiceAdditionalEmail: undefined,
      logisticsServiceBillingData: undefined,
      billingMethodAndCountry: undefined
    });
  }
}));

export default useLogisticsServiceBillingState;
