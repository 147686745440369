const RoundIran = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M21.1964 11.826H19.103C19.1204 12.0561 19.1302 12.2882 19.1302 12.5216C19.1302 14.0694 18.7433 15.5696 18.0689 16.6374C17.8608 16.9668 17.5112 17.4319 17.0432 17.7457V11.8259H14.9562V17.7457C14.4883 17.4319 14.1386 16.9669 13.9305 16.6374C13.256 15.5696 12.8692 14.0694 12.8692 12.5216C12.8692 12.2882 12.8791 12.056 12.8964 11.826H10.803C10.7895 12.0549 10.7822 12.2868 10.7822 12.5216C10.7822 16.8126 13.074 20.1738 15.9996 20.1738C18.9252 20.1738 21.217 16.8126 21.217 12.5216C21.2171 12.2868 21.2098 12.0549 21.1964 11.826Z"
        />
        <path
          fill="#6DA544"
          d="M6.60895 7.65219H8.69589V9.0435H10.7828V7.65219H12.8698V9.0435H14.9567V7.65219H17.0436V9.0435H19.1306V7.65219H21.2175V9.0435H23.3045V7.65219H25.3914V9.0435H30.4125C27.8246 3.69156 22.3436 0 16.0003 0C9.65695 0 4.17589 3.69156 1.58789 9.0435H6.60895V7.65219Z"
        />
        <path
          fill="#F93939"
          d="M25.3916 22.9565V24.3479H23.3046V22.9565H21.2177V24.3479H19.1308V22.9565H17.0438V24.3479H14.9569V22.9565H12.87V24.3479H10.783V22.9565H8.69589V24.3479H6.60895V22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H25.3916Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIran;
