const RoundBrunei = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16.0006 31.9978c8.8352 0 15.9976-7.1624 15.9976-15.9977 0-8.83526-7.1624-15.99765859-15.9976-15.99765859C7.16532.00244141.00292969 7.16484.00292969 16.0001c0 8.8353 7.16239031 15.9977 15.99767031 15.9977Z"
        />
        <path
          fill="#000"
          d="M2.14554 8.00122C1.42346 9.25175.890828 10.5591.536133 11.8909L27.2903 27.3375c.976-.9731 1.842-2.0882 2.5639-3.3386L2.14554 8.00122Z"
        />
        <path
          fill="#FFDA2C"
          d="M23.9993 2.14561C17.5982-1.55004 9.70551-.318322 4.70996 4.66253L31.4642 20.1091c1.8158-6.8166-1.0639-14.26779-7.4649-17.96349ZM8.00138 29.8544c6.40102 3.6957 14.29382 2.464 19.28932-2.5168L.536408 11.8909C-1.27932 18.7076 1.60024 26.1588 8.00138 29.8544Z"
        />
        <path
          fill="#F93939"
          d="M20.5211 14.2613c0 2.4968-2.0242 4.5211-4.5211 4.5211-2.497 0-4.5211-2.0242-4.5211-4.5211 0-.7785.1968-1.5111.5434-2.1507-.9811 1.0033-1.5868 2.3754-1.5868 3.8895 0 3.0731 2.4913 5.5644 5.5645 5.5644s5.5644-2.4912 5.5644-5.5644c0-1.5141-.6056-2.8862-1.5868-3.8895.3467.6397.5435 1.3722.5435 2.1507Z"
        />
        <path
          fill="#F93939"
          d="M14.957 9.04468h2.0867V19.478H14.957V9.04468Z"
        />
        <path
          fill="#F93939"
          d="M19.4779 11.1313h-6.9554c0 .4802.4183.8695.8984.8695h-.029c0 .4802.3893.8695.8695.8695 0 .4802.3893.8694.8694.8694h1.7389c.4802 0 .8694-.3892.8694-.8694.4802 0 .8695-.3893.8695-.8695h-.0289c.48-.0001.8982-.3892.8982-.8695ZM11.1309 20.1736h9.7377v2.0867h-9.7377v-2.0867Z"
        />
        <path
          fill="#F93939"
          d="M18.0869 20.8691h4.1733v2.0867h-4.1733v-2.0867ZM9.74023 20.8691h4.17327v2.0867H9.74023v-2.0867Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBrunei;
