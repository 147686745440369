const SquareBosniaAndHerzegovina = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path
            fill="#FFDA2C"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="m0 0 22 14.9333-.1289 1.1734L0 16V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M1.04761 3.19995h1.04762v1.06667H1.04761V3.19995Zm3.14285 2.13333h1.04762v1.06667H4.19046V5.33328Zm3.14286 2.13334h1.04762v1.06666H7.33332V7.46662Zm3.14288 2.13333h1.0476v1.06665h-1.0476V9.59995Zm3.1428 2.13335h1.0477V12.8H13.619v-1.0667Zm3.1429 2.1333h1.0476v1.0667h-1.0476v-1.0667Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBosniaAndHerzegovina;
