import { useTranslation } from 'react-i18next';
import { InudoInfo, Tany } from '../../../../../../../../types/global';
import { useEffect, useState } from 'react';
import AdquisitionType from '../../../../../../../../components/productDetails/adquisitionType';
import { formatDateToPicker } from '../../../../../../../../utils/formatDatesAndHours';
import { BordButton, BordOneToneIcon, BordTextInput } from '../../../../../../../../components/BordDesignSystem';
import BordModal from '../../../../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import BordDatePicker from '../../../../../../../../components/BordDesignSystem/BordDatePicker/BordDatePicker';
import './EditAcquisitionDataModal.scss';

interface IProductCondition {
  value: number;
  name: string;
}

const ToolEditAcquisitionDataModal = ({
  closeModalCallback,
  searchedAcquisition,
  updateToolDataCallback
}: {
  closeModalCallback: () => void;
  searchedAcquisition: InudoInfo | undefined;
  updateToolDataCallback: (data: Tany) => void;
}) => {
  const { t } = useTranslation();
  const [toolEndDate, setToolEndDate] = useState<string>();
  const [toolWarranty, setToolWarranty] = useState<string>();
  const [toolSalesValue, setToolSalesValue] = useState<string>();
  const [toolMonthlyValue, setToolMonthlyValue] = useState<string>();
  const [toolAcquisitionDate, setToolAdquisitionDate] = useState<string>();
  const [toolAcquisitionType, setToolAcquisitionType] = useState<IProductCondition>();

  const isExternalTool = searchedAcquisition?.referenceModel === 'ExternalProduct';
  const toolTimeZone = searchedAcquisition?.country?.timeZone || undefined;
  const i18nLanguage = 'nodi:toolInternal:NodiDetailsHeader:';
  const isNumberRegex = /^[0-9]+(.[0-9]+)?$/;
  const invalidSaleValueError = toolSalesValue && !isNumberRegex.test(toolSalesValue) ? 'Solo números' : undefined;

  const isSale = toolAcquisitionType && toolAcquisitionType.value === 2;
  const isSubscripton = toolAcquisitionType && toolAcquisitionType.value === 1;

  const toolAcquisitionTypeList = [
    { icon: <BordOneToneIcon variant="shoppingCart" standardSize={14} />, value: 1, name: 'Renta' },
    { icon: <BordOneToneIcon variant="key" standardSize={14} />, value: 2, name: 'Compra' }
  ];

  const renderCustomButtonContainerStyles = isSubscripton || isSale ? 'lessBottom' : 'moreBottom';

  const assignAcquisitionType = (type: string) => {
    const acquisitionType = toolAcquisitionTypeList.find(element => element.name === type);
    if (acquisitionType) setToolAcquisitionType(acquisitionType);
  };

  const updateToolData = () => {
    const externalProduct = {
      endDate: toolEndDate || null,
      warranty: toolWarranty || null,
      monthlyValue: toolMonthlyValue ? parseFloat(toolMonthlyValue) : null,
      purchaseValue: toolSalesValue ? parseFloat(toolSalesValue) : null,
      acquisitionDate: toolAcquisitionDate || null,
      acquisitionType: toolAcquisitionType?.name || null
    };
    if (updateToolDataCallback) updateToolDataCallback(externalProduct);
  };

  useEffect(() => {
    searchedAcquisition?.endDay && setToolEndDate(searchedAcquisition?.endDay);
    searchedAcquisition?.warranty && setToolWarranty(searchedAcquisition?.warranty);
    searchedAcquisition?.monthlyValueUsd && setToolMonthlyValue(String(searchedAcquisition?.monthlyValueUsd));
    searchedAcquisition?.acquisitionType && assignAcquisitionType(searchedAcquisition?.acquisitionType);
    searchedAcquisition?.acquisitionDate && setToolAdquisitionDate(searchedAcquisition?.acquisitionDate);
    searchedAcquisition?.saleValueUsd && setToolSalesValue(String(searchedAcquisition?.saleValueUsd));
  }, [searchedAcquisition]);

  if (!isExternalTool) return <></>;

  return (
    <div className="relative">
      <BordModal
        modalContent={
          <ModalContent
            excludeHeader
            customWidth="48rem"
            customHeight="34rem"
            toCloseModal={closeModalCallback}
            CustomModalComponent={
              <div className="toolExternalEditAcquisitionDataContainer">
                <div className="customHeader">
                  <BordOneToneIcon variant="logoMaster" standardSize={12} />
                  <span className="customTitleHeader">{t(`${i18nLanguage}statusContainer:external`)}</span>
                </div>
                <div className="firstSection">
                  <BordDatePicker
                    label={t(`${i18nLanguage}floatForm:dateContainer`)}
                    isFilled={!!toolAcquisitionDate}
                    desiredTimeZone={toolTimeZone}
                    updateCurrentValueCallback={e => setToolAdquisitionDate(e)}
                    defaultValue={formatDateToPicker(toolAcquisitionDate)}
                    customWidth="18rem"
                  />
                  <div>
                    <div className="adquisitionTypeText">Tipo de adquisición</div>
                    <AdquisitionType
                      enabled
                      selectList={toolAcquisitionTypeList}
                      changeValue={e => setToolAcquisitionType(e)}
                      defaultValue={toolAcquisitionType}
                    />
                  </div>
                </div>
                <div className="secondSection">
                  {isSale && (
                    <>
                      <BordTextInput
                        label={t(`${i18nLanguage}floatForm:warrantyInput`)}
                        isFilled={!!toolWarranty}
                        placeholder="3 años"
                        standardSize={'w-180'}
                        currentText={toolWarranty}
                        setCurrentText={e => setToolWarranty(!e ? '' : String(e))}
                      />
                      <BordTextInput
                        label={t(`${i18nLanguage}floatForm:purchaseValue`)}
                        isFilled={!!toolSalesValue}
                        placeholder="$ 0.00 USD"
                        standardSize={'w-180'}
                        currentText={toolSalesValue}
                        setCurrentText={e => setToolSalesValue(String(e))}
                        className="editToolField toolCostTextInput"
                        errorText={invalidSaleValueError}
                      />
                    </>
                  )}

                  {isSubscripton && (
                    <>
                      <BordTextInput
                        label={t(`${i18nLanguage}floatForm:containerAdquisitionDate:title`)}
                        placeholder="$ 0.00 USD"
                        isFilled={!!toolMonthlyValue}
                        inputProps={{ style: { height: '2.8rem', paddingLeft: '1.1rem', paddingRight: '1.1rem' } }}
                        currentText={toolMonthlyValue}
                        setCurrentText={e => setToolMonthlyValue(String(e))}
                        standardSize={'w-180'}
                      />
                      <BordDatePicker
                        label={t(`${i18nLanguage}floatForm:containerAdquisitionDate:NudosDatePicker`)}
                        isFilled={!!toolEndDate}
                        defaultValue={toolEndDate}
                        desiredTimeZone={toolTimeZone}
                        updateCurrentValueCallback={e => setToolEndDate(e)}
                        customWidth="18rem"
                      />
                    </>
                  )}
                </div>
                <div className={`${renderCustomButtonContainerStyles} buttonContainer`}>
                  <BordButton
                    customWidth="w-80"
                    label="Guardar"
                    onClick={updateToolData}
                    disabled={!!invalidSaleValueError}
                    modeButton="secondary"
                  />
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
};

export default ToolEditAcquisitionDataModal;
