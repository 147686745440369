const RoundSudan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M1.58763 9.04341C0.5705 11.1469 0 13.5067 0 15.9999C0 18.4931 0.5705 20.8529 1.58763 22.9564L16 24.3477L30.4124 22.9564C31.4295 20.8529 32 18.4931 32 15.9999C32 13.5067 31.4295 11.1469 30.4124 9.04341L16 7.6521L1.58763 9.04341Z"
        />
        <path
          fill="black"
          d="M1.58789 22.9565C4.17577 28.3085 9.65633 32 15.9997 32C22.3431 32 27.8236 28.3085 30.4115 22.9565H1.58789Z"
        />
        <path
          fill="#F93939"
          d="M1.58789 9.0435H30.4115C27.8236 3.69156 22.3431 0 15.9997 0C9.65633 0 4.17577 3.69156 1.58789 9.0435Z"
        />
        <path
          fill="#496E2D"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSudan;
