import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUsersOrganizationData, uploadProfilePhoto } from '../../services/services';
import { useUserAuth } from '../../state/userAuthContext';
import useEcommerceControler from '../../views/ecommerce/ecommerce.controller';
import useProfileImage from '../../state/useProfileImage';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/displayNudosStandardNotifications';
import { Tany } from '../../types/global';
import BordProfileSummary from '../BordDesignSystem/BordProfileSummary/BordProfileSummary';
import isFileValid from '../../utils/validateFile';
import BadgePlatinum from '../BadgePlatinum';
const ProfileInfo = () => {
  const { push } = useHistory();
  const { user } = useUserAuth();
  const { orgData, personalData } = useEcommerceControler();
  const fullName = `${personalData?.firstName || ''} ${personalData?.lastName || ''}`;
  const [updatedProfilePicture, setUpdatedProfilePicture] = useState(0);
  const { temporalImg } = useProfileImage();
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:headBar:profileInfo:';

  const profilePicture =
    orgData?.photoProfile && orgData?.photoProfile.length > 0
      ? `${orgData.photoProfile.replaceAll(' ', '%20')}`
      : undefined;

  const getUserProfileType = () => {
    if (!personalData?.role || personalData?.role === 'admin') return t(`${i18nLanguageKey}profileType:admin`);
    return t(`${i18nLanguageKey}profileType:user`);
  };

  const isNameDataComplete = fullName && fullName.length > 1;

  const handleFileChange = async (event: Tany) => {
    const uploadedFile = event.target.files[0];
    const validFile = isFileValid(
      uploadedFile,
      ['image/png', 'image/jpeg'],
      2,
      'El documento debe estar en formato PNG o JPEG',
      'El documento no debe superar los 2MB'
    );
    if (!validFile || !user) return;

    const formData = new FormData();
    formData.append('file', uploadedFile);
    try {
      const response = await uploadProfilePhoto(orgData.userId, formData);
      if (response) {
        const userData = await getUsersOrganizationData(user.uid);
        localStorage.setItem('orgData', JSON.stringify(userData));
        setUpdatedProfilePicture(updatedProfilePicture + 1);
        displaySuccessNotification({ customJSXMessage: <>La imagen fue actualizada exitosamente</> });
      }
    } catch (error) {
      displayErrorNotification();
    }
  };

  return (
    <div id="profileInfo">
      <BadgePlatinum />
      <BordProfileSummary
        variant="circularWorld"
        imgUrl={profilePicture}
        title={isNameDataComplete ? fullName.toLowerCase() : 'Completa tu cuenta'}
        subTitle={getUserProfileType()}
      />
    </div>
  );
};

export default ProfileInfo;
