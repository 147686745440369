const SquareLiberia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M7 0H22V1.06667H7V0ZM7 2.13333H22V3.2H7V2.13333ZM7 4.26667H22V5.33333H7V4.26667ZM7 6.4H22V7.46667H7V6.4ZM0 8.53333H22V9.6H0V8.53333ZM0 10.6667H22V11.7333H0V10.6667ZM0 12.8H22V13.8667H0V12.8Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" d="M22 14.9333H0V16H22V14.9333Z" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H7V7.5H0V0Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M3.5 5.14547L1.95492 6L2.25 4.18966L1 2.91056L2.72746 2.64655L3.5 1L4.27254 2.64547L6 2.90948L4.75 4.19181L5.04508 6"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareLiberia;
