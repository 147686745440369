/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import { isProduction } from '../constants';
import usePlatform from '../hooks/usePlatform';

export const PrivateRoute = ({
  component,
  path
}: {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path?: string | string[];
}) => {
  const { replace } = useHistory();
  const { isMobile } = usePlatform();
  const isLocalHost = window.location.hostname.includes('localhost');
  const nudosPage = isProduction ? 'https://www.nudos.co' : 'https://landing.beta.nudos.co';
  const _user = localStorage.getItem('sessionLogin');
  if (!_user) replace('/login');
  if (isMobile && !isLocalHost) {
    localStorage.removeItem('sessionLogin');
    localStorage.removeItem('orgData');
    window.location.href = `${nudosPage}/warning-page`;
  }
  return <Route {...{ component, path }} />;
};

export const PublicRoute = ({
  component,
  path
}: {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  path?: string | string[];
}) => {
  const { replace } = useHistory();
  const _user = localStorage.getItem('sessionLogin');
  if (_user) replace('/nodi/dashboard');
  return <Route {...{ component, path }} />;
};
