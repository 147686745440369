const RoundBenin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="m10.4348 14.6086 1.3913 16.8402C13.1569 31.8075 14.5558 32 16 32c8.8365 0 16-7.1635 16-16l-21.5652-1.3914Z"
        />
        <path
          fill="#FFDA2C"
          d="M10.4348 16 11.8261.551188C13.1569.1925 14.5558 0 16 0c8.8365 0 16 7.1635 16 16H10.4348Z"
        />
        <path
          fill="#6DA544"
          d="M0 16c0 7.3924 5.01381 13.6128 11.8261 15.4488V.551147C5.01381 2.38715 0 8.60752 0 16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBenin;
