import NudosSpinnerLoader from '../NudosSpinnerLoader';
import './NudosLoaderModal.scss';

/**
 * IMPORTANT: ringColor and arcColor, if provided, must be valid values for the a CSS color
 * @property {string} loaderText - REQUIRED, the text to display upon the loader.
 * @property {number} loaderSize - Optional, the diameter of the spinner loader, in PIXELS. DEFAULT 88px.
 * @property {number} modalTopPosition - Optional, the top value for the modal, since its position is absolute. USE TO ALLOW VIEWING OF THE HEADER. DEFAULT 0px.
 * @property {number} borderWidth - Optional, the width of the rotating spinner in pixels, in other words the border width. DEFAULT 11px.
 * @property {number} ringColor - Optional, the color of the  rotating spinner. DEFAULT #E6E6E6.
 * @property {number} arcColor - Optional, the color of the  arc that marks a turn of the spinner. DEFAULT white.
 * @property {boolean} preventCenteringForLargeScreens - Optional, a boolean indicating if for screens over 1024px the left property should be 0 instead of calc(0.2 * (100vw - 1024px)).
 * @returns
 */
const NudosLoaderModal = ({
  loaderText,
  loaderSize,
  modalTopPosition,
  borderWidth,
  ringColor,
  arcColor = '#22CFAB',
  fullModal,
  preventCenteringForLargeScreens
}: InudosLoaderModalParams) => {
  return (
    <>
      {fullModal && <div className="nudosFullModal" />}
      <div
        className="nudosLoaderModal"
        style={{
          top: modalTopPosition,
          height: modalTopPosition ? `calc(100vh - ${modalTopPosition}px)` : undefined,
          left: preventCenteringForLargeScreens ? '0px' : undefined
        }}
      >
        <div className="centeringContainer">
          <div className="title">{loaderText}</div>
          <div className="loaderContainer" style={{ width: loaderSize, height: loaderSize }}>
            <NudosSpinnerLoader borderWidth={borderWidth} ringColor={ringColor} arcColor={arcColor} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NudosLoaderModal;

export interface InudosLoaderModalParams {
  loaderText: string;
  loaderSize?: number;
  modalTopPosition?: number;
  borderWidth?: number;
  ringColor?: string;
  arcColor?: string;
  fullModal?: boolean;
  preventCenteringForLargeScreens?: boolean;
}
