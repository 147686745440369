import { IprogressStepData } from '../BorderStepper.types';
import '../BordStepper.scss';

const ProgressBarSkeletonLoader = ({
  skeletonSteps,
  heightStyles,
  stepperConectorWidth
}: {
  skeletonSteps: IprogressStepData[];
  heightStyles?: number;
  stepperConectorWidth?: string;
}) => {
  const renderSkeletonStep = (skeletonStep: IprogressStepData, index: number) => {
    const isLastStep = index === skeletonSteps.length - 1;
    const widthStyles = skeletonStep.stepWidth ?? 30;
    const renderStepperConectorWidth = stepperConectorWidth ?? 'skeletonConector';

    return (
      <>
        <div
          key={`skeletonStep${skeletonStep.order}`}
          className="stepSkeleton animationLoader"
          style={{
            ...skeletonStep.customStepStyles,
            width: widthStyles,
            height: heightStyles ?? 30
          }}
        />
        {!isLastStep && <div className={`animationLoader ${renderStepperConectorWidth} h-2`}></div>}
      </>
    );
  };

  return <div className="bordProgressBarSkeletonLoader">{skeletonSteps.map(renderSkeletonStep)}</div>;
};

export default ProgressBarSkeletonLoader;
