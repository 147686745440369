import { useEffect, useState } from 'react';
import { IToolQuoteInfo } from '../../../../../../../types/logisticsServicesModule.types';
import LogisticServiceFeeLine from '../../LogisticServiceFeeLine/LogisticServiceFeeLine';
import ToolDataHeader from './ToolDataHeader/ToolDataHeader';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import { formatCost } from '../../../../../../../utils/formatNumbers';
import { TplatformSubscription } from '../../../../../../../types/global';
import StorageTooltip from './StorageTooltip';
import BuyAndHoldRedistributionTooltip from './BuyAndHoldRedistributionTooltip/BuyAndHoldRedistributionTooltip';
import { TtooltipPositions } from '../../../../../../../components/BordDesignSystem/BordTooltip/BordTooltip.type';
import './ToolQuoteCard.scss';

const ToolQuoteCard = ({
  platformSubscription,
  toolData
}: {
  platformSubscription: TplatformSubscription;
  toolData: IToolQuoteInfo;
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [overflow, setOverflow] = useState<string>('hidden');

  const { chargedFees, feesWithoutDiscount, storageDetails, isBuyAndHold, nudosCareName } = toolData;
  const { storageDates } = storageDetails || {};
  const { entryDate, exitDate } = storageDates || {};

  const hasPlatinum = platformSubscription && platformSubscription !== 'Free';
  const hasCare = !!nudosCareName && nudosCareName !== 'Free';

  const feesToShow = Object.entries(feesWithoutDiscount).reduce((feesToShow, currentFee) => {
    const fieldIsShown = !['redistributionWithoutBuyAndHoldDiscount'].includes(currentFee[0]);
    const hasValueToShow = !!currentFee[1];
    if (fieldIsShown && hasValueToShow) feesToShow += 1;
    return feesToShow;
  }, 0);

  const height = showDetails ? `${4.2 + feesToShow * 2.1 + 0.4}rem` : '4.2rem';

  const exitStorageTitle =
    entryDate && exitDate
      ? `Almacenamiento (${formatShortDate(entryDate).onlyDate} - ${formatShortDate(exitDate).onlyDate})`
      : undefined;

  const toogleDetails = () => setShowDetails(!showDetails);
  const formatFee = (value: number) => `$${formatCost(value)} USD`;

  const logisticServiceTooltipProps = {
    tooltipContent: (
      <BuyAndHoldRedistributionTooltip chargedFees={chargedFees} feesWithoutDiscount={feesWithoutDiscount} />
    ),
    tooltipPosition: 'bottomRight' as TtooltipPositions
  };

  useEffect(() => {
    const newOverflow = showDetails ? 'visible' : 'hidden';
    const delay = showDetails ? 500 : 0;
    const overflowTimeout = setTimeout(() => setOverflow(newOverflow), delay);

    return () => clearTimeout(overflowTimeout);
  }, [showDetails]);

  return (
    <div className="toolQuoteCard" style={{ height, overflow }}>
      <ToolDataHeader toolData={toolData} showDetails={showDetails} toogleDetails={toogleDetails} />

      <LogisticServiceFeeLine
        showStrikeThroughValue={hasPlatinum && feesWithoutDiscount.redistribution - chargedFees.redistribution > 0}
        title={`Servicio logístico`}
        value={formatFee(chargedFees.redistribution)}
        strikeThroughValue={formatFee(feesWithoutDiscount.redistributionWithoutBuyAndHoldDiscount)}
        tooltipProps={isBuyAndHold ? logisticServiceTooltipProps : undefined}
      />

      {!!feesWithoutDiscount?.exitStorage && !!exitStorageTitle && (
        <LogisticServiceFeeLine
          showStrikeThroughValue={feesWithoutDiscount.exitStorage - chargedFees.exitStorage > 0}
          title={exitStorageTitle}
          value={formatFee(chargedFees.exitStorage)}
          strikeThroughValue={formatFee(feesWithoutDiscount.exitStorage)}
          tooltipProps={{
            tooltipContent: <StorageTooltip toolData={toolData} />,
            tooltipPosition: 'bottomLeft'
          }}
        />
      )}

      {!!feesWithoutDiscount?.entryStorage && (
        <LogisticServiceFeeLine
          showStrikeThroughValue={hasPlatinum && feesWithoutDiscount.entryStorage - chargedFees.entryStorage > 0}
          title={`Almacenamiento`}
          value={formatFee(chargedFees.entryStorage)}
          strikeThroughValue={formatFee(feesWithoutDiscount.entryStorage)}
        />
      )}

      {!!feesWithoutDiscount?.basicConfiguration && (
        <LogisticServiceFeeLine
          showStrikeThroughValue={
            hasPlatinum && feesWithoutDiscount.basicConfiguration - chargedFees.basicConfiguration > 0
          }
          title={`Configuración básica`}
          value={formatFee(chargedFees.basicConfiguration)}
          strikeThroughValue={formatFee(feesWithoutDiscount.basicConfiguration)}
        />
      )}

      {!!feesWithoutDiscount?.wiping && (
        <LogisticServiceFeeLine
          showStrikeThroughValue={hasPlatinum && feesWithoutDiscount.wiping - chargedFees.wiping > 0}
          title={`Wiping`}
          value={formatFee(chargedFees.wiping)}
          strikeThroughValue={formatFee(feesWithoutDiscount.wiping)}
        />
      )}

      <LogisticServiceFeeLine
        className="subtotalLine"
        showStrikeThroughValue={hasPlatinum && feesWithoutDiscount.subtotal - chargedFees.subtotal > 0}
        title={`Subtotal del dispositivo`}
        value={formatFee(hasCare && chargedFees.toolCareSavings ? chargedFees.toolCareSavings : chargedFees.subtotal)}
        strikeThroughValue={formatFee(feesWithoutDiscount.subtotal)}
      />
    </div>
  );
};

export default ToolQuoteCard;
