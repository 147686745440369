export enum FORM_COMPONENTS_STANDARD_SIZES {
  XS = 'w-80',
  SM = 'w-180',
  MD = 'w-280',
  LG = 'w-380'
}

export type TformComponentsStandardSizes =
  | 'w-80'
  | 'w-160'
  | 'w-180'
  | 'w-240'
  | 'w-280'
  | 'w-380'
  | 'w-400'
  | 'w-480'
  | 'w-520'
  | 'w-580';
