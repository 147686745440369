import { ComponentProps } from 'react';

const BordRectangularMascotAvatar = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48">
      <rect width="47" height="47" x="0.5" y="0.5" fill="#052143" rx="7.5" />
      <rect width="47" height="47" x="0.5" y="0.5" stroke="#626C82" rx="7.5" />
      <path
        fill="url(#paint0_radial_101_27460)"
        d="M32.441 11.3334H23.9046C21.3499 11.3334 19.0078 12.2392 17.1812 13.7468C14.8316 15.686 13.334 18.6199 13.334 21.904C13.334 24.4588 14.2398 26.8009 15.7474 28.6275C16.1778 29.1492 16.6582 29.6297 17.1799 30.06C19.0078 31.5676 21.3499 32.4734 23.9034 32.4734C27.1876 32.4734 30.1214 30.9759 32.0606 28.6263C33.5682 26.7984 34.474 24.4563 34.474 21.9028V13.3677C34.474 12.2442 33.5632 11.3334 32.4385 11.3334H32.441Z"
      />
      <ellipse cx="22.0258" cy="19.3725" fill="#1B202F" rx="1.3071" ry="2.61419" />
      <ellipse cx="25.9477" cy="19.6993" fill="#1B202F" rx="1.3071" ry="2.28742" />
      <ellipse cx="18.4322" cy="23.6206" fill="#1B202F" rx="0.326775" ry="0.326774" />
      <ellipse cx="23.9882" cy="29.8293" fill="#1B202F" rx="5.22839" ry="1.3071" />
      <path
        fill="#22CFAB"
        stroke="#1B202F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M22.6298 36.6651C22.89 36.6978 23.1313 36.5238 23.1825 36.2665L24.3032 30.63C24.3534 30.378 24.2043 30.129 23.9584 30.0542L21.4177 29.2808C21.2693 29.2357 21.1084 29.2619 20.982 29.3519C20.8557 29.4419 20.7783 29.5854 20.7724 29.7404L20.6544 32.8898C19.1501 32.6898 18.1632 32.9404 17.6319 33.5747C17.0394 34.282 17.2189 35.2171 17.4586 35.7688C17.529 35.9308 17.6796 36.0436 17.8549 36.0656L22.6298 36.6651Z"
      />
      <path
        fill="#22CFAB"
        stroke="#1B202F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M26.155 36.8886C25.8979 36.9406 25.6444 36.7851 25.5741 36.5323L24.0359 30.9952C23.9671 30.7476 24.0972 30.4882 24.3367 30.3953L26.8127 29.4344C26.9573 29.3783 27.1198 29.3925 27.2525 29.4728C27.3852 29.5531 27.4731 29.6905 27.4904 29.8446L27.8432 32.9764C29.3284 32.6646 30.3312 32.8409 30.9084 33.4338C31.552 34.0949 31.4428 35.0408 31.2449 35.6088C31.1868 35.7756 31.045 35.8994 30.8719 35.9344L26.155 36.8886Z"
      />
      <path
        stroke="#1B202F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.9312 25.4704C21.6469 25.7941 23.7018 26.2062 26.1963 25.2655C28.6907 24.3247 29.8176 23.1235 30.0692 22.6404"
      />
      <defs>
        <radialGradient
          id="paint0_radial_101_27460"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="translate(30.18 16.9487) rotate(112.011) scale(16.7453 16.7453)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.665" stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1AA185" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default BordRectangularMascotAvatar;
