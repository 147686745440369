import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" ref={ref} {...props}>
    <path
      className="path1"
      fill="#fff"
      d="M1.111 5.793c.15.406.561.636.993.629l.215-.003c3.59-.057 7.226-.002 10.947.013l.616.002c.438.002.847-.244.997-.655.16-.443.262-.899.262-1.367 0-.491-.1-.973-.276-1.435-.142-.376-.514-.602-.915-.62a124.388 124.388 0 0 0-11.923 0c-.391.018-.754.233-.896.598a4.02 4.02 0 0 0-.276 1.457c0 .473.092.934.256 1.381Z"
    />
    <path
      className="path2"
      fill="#fff"
      d="M2.05 14.153c.085.505.512.876 1.022.92 1.51.133 3.092.31 4.72.31 1.643 0 3.227-.111 4.757-.281.493-.055.892-.428.972-.918a22.25 22.25 0 0 0 .315-3.596c0-1.244-.114-2.461-.31-3.616a1.076 1.076 0 0 0-.26-.54c-3.721-.015-7.357-.07-10.947-.013-.134.146-.228.33-.263.536a21.266 21.266 0 0 0-.308 3.633c0 1.23.11 2.417.302 3.565Z"
    />
    <path
      className="path3"
      stroke="#383838"
      d="M2.319 6.419c-.072 0-.143.002-.215.003-.432.007-.844-.223-.993-.629a3.987 3.987 0 0 1-.256-1.381c0-.499.095-.989.276-1.457.142-.365.505-.58.896-.6a124.388 124.388 0 0 1 11.923.001c.401.019.773.245.915.621.176.462.276.944.276 1.435 0 .468-.101.924-.262 1.367-.15.411-.56.657-.997.655-.206 0-.411 0-.617-.002M2.32 6.42c-.134.146-.228.33-.263.536a21.266 21.266 0 0 0-.308 3.633c0 1.23.11 2.417.302 3.565.085.505.512.876 1.022.92 1.51.133 3.092.31 4.72.31 1.643 0 3.227-.111 4.757-.281.493-.055.892-.428.972-.918a22.25 22.25 0 0 0 .315-3.596c0-1.244-.114-2.461-.31-3.616a1.076 1.076 0 0 0-.26-.54M2.319 6.42c3.59-.057 7.226-.002 10.947.013"
    />
    <path className="path4" stroke="#383838" strokeLinecap="round" strokeLinejoin="round" d="M6.145 9.77H9.44" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconReserveBox = memo(ForwardRef);
export default IconReserveBox;
