const SquareIndia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M10.9999 10.6668C12.4456 10.6668 13.619 9.47216 13.619 8.00016C13.619 6.52816 12.4456 5.3335 10.9999 5.3335C9.55419 5.3335 8.38086 6.52816 8.38086 8.00016C8.38086 9.47216 9.55419 10.6668 10.9999 10.6668ZM10.9999 9.60016C11.8673 9.60016 12.5713 8.88336 12.5713 8.00016C12.5713 7.11696 11.8673 6.40016 10.9999 6.40016C10.1325 6.40016 9.42848 7.11696 9.42848 8.00016C9.42848 8.88336 10.1325 9.60016 10.9999 9.60016Z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            d="M11.0004 8.53346C11.2897 8.53346 11.5242 8.29468 11.5242 8.00013C11.5242 7.70558 11.2897 7.4668 11.0004 7.4668C10.7111 7.4668 10.4766 7.70558 10.4766 8.00013C10.4766 8.29468 10.7111 8.53346 11.0004 8.53346Z"
          />
          <path fill="#249F58" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#FF6C2D" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareIndia;
