const RoundCapeVerde = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9999 32c4.8494 0 9.1946-2.1577 12.1288-5.5652H3.87109C6.80528 29.8423 11.1505 32 15.9999 32ZM32 16c0-8.8365-7.1635-16-16-16C7.1635 0 0 7.1635 0 16c0 1.4442.1925 2.8431.551188 4.1739H31.4489C31.8075 18.8431 32 17.4442 32 16Z"
        />
        <path
          fill="#F93939"
          d="M1.27148 22.261c.30844.7247.669 1.4217 1.07669 2.0869H29.6514c.4076-.6652.7681-1.3622 1.0767-2.0869H1.27148Z"
        />
        <path
          fill="#FFDA2C"
          d="m11.437 17.0435.259.7971h.8382l-.6781.4927.259.7971-.6781-.4927-.6782.4927.2591-.7971-.6781-.4927h.8382l.259-.7971ZM11.437 27.4783l.259.7972h.8382l-.6781.4926.259.7971-.6781-.4926-.6782.4926.2591-.7971-.6781-.4926h.8382l.259-.7972ZM6.57955 23.9492l.259.7971h.83818l-.67806.4926.259.7973-.67812-.4927-.67813.4927.25913-.7973-.67813-.4926h.83813l.259-.7971ZM8.43508 18.0869l.259.7972h.83812l-.678.4926.259.7972-.67812-.4927-.67813.4927.25913-.7972-.67819-.4926h.83819l.259-.7972ZM5.48242 21.3698h.838l.25906-.7973.25907.7973.83818-.0001-.67812.4927.25906.7972-.67819-.4927-.67806.4925.259-.7971-.678-.4925ZM7.75708 28.3693l.259-.797-.67819-.4926h.83819l.25906-.7972.25906.7971h.83813l-.67813.4926.259.7973-.67812-.4928-.678.4926ZM16.2924 23.9492l-.2589.7971h-.8382l.678.4926-.259.7973.6781-.4927.6782.4927-.2591-.7973.6781-.4926h-.8382l-.259-.7971ZM14.437 18.0869l-.259.7972h-.8382l.678.4926-.259.7972.6782-.4927.6781.4927-.2591-.7972.6782-.4926h-.8382l-.259-.7972ZM17.3896 21.3698h-.838l-.2591-.7973-.259.7973-.8382-.0001.6781.4927-.2591.7972.6782-.4927.6781.4925-.259-.7971.678-.4925ZM15.1151 28.3693l-.2589-.797.6781-.4926h-.8382l-.2591-.7972-.259.7971h-.8382l.6782.4926-.259.7973.6781-.4928.678.4926Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCapeVerde;
