import i18next from 'i18next';
import { ICountryDetail } from '../../types/countries';
import { getTaxCodeErrorByCountry, isEmailValid, isPasswordValid } from '../../utils/formValidations';

const translationKey = 'authentication:SignUpForm:formErrors:';

export const getOrganizationNameError = (organizationName?: string, showEmptyError?: boolean) => {
  if (!organizationName && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!organizationName) return;
  if ((organizationName || '').length < 2) return i18next?.t(`${translationKey}minLength`);
  if ((organizationName || '').length > 100) return i18next?.t(`${translationKey}maxLength`);
};

export const getOrganizationBusinessNameError = (organizationBusinessName?: string, showEmptyError?: boolean) => {
  if (!organizationBusinessName && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!organizationBusinessName) return;
  if ((organizationBusinessName || '').length < 2) return i18next?.t(`${translationKey}minLength`);
  if ((organizationBusinessName || '').length > 100) return i18next?.t(`${translationKey}maxLength`);
};

export const getOrganizationDocumentError = (
  organizationDocument?: string,
  organizationCountry?: ICountryDetail,
  showEmptyError?: boolean
) => {
  if (!organizationDocument && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!organizationDocument) return;
  return getTaxCodeErrorByCountry(organizationCountry, organizationDocument);
};

export const getUserNameError = (userName?: string, showEmptyError?: boolean) => {
  if (!userName && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!userName) return;
  if ((userName || '').length < 2) return i18next?.t(`${translationKey}minLength`);
  if ((userName || '').length > 100) return i18next?.t(`${translationKey}maxLength`);
};

export const getUserPhoneError = (userPhone?: string, userPhoneCountry?: ICountryDetail, showEmptyError?: boolean) => {
  if (!userPhone && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!userPhone) return;
  if (!userPhoneCountry) return i18next?.t(`${translationKey}phoneCountry`);
  if (userPhone.length < 6) return i18next?.t(`${translationKey}phoneMinLength`);
  if (userPhone.length > 15) return i18next?.t(`${translationKey}phoneMaxLength`);
};

export const getEmailError = (email?: string, showEmptyError?: boolean) => {
  if (!email && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!email) return;
  if (!isEmailValid(email)) return i18next?.t(`${translationKey}email`);
};

export const getPasswordError = (password?: string, showEmptyError?: boolean) => {
  if (!password && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!password) return;
  if (!isPasswordValid(password)) return i18next?.t(`${translationKey}password`);
};

export const getPasswordConfirmationError = (
  passwordConfirmation?: string,
  originalPassword?: string,
  showEmptyError?: boolean
) => {
  if (!passwordConfirmation && showEmptyError) return i18next?.t(`${translationKey}requiredField`);
  if (!passwordConfirmation || !originalPassword) return;
  if (originalPassword && originalPassword !== passwordConfirmation)
    return i18next?.t(`${translationKey}confirmPassword`);
};

export const getNewRegisteredData = () => {
  const stringNewRegisterData = sessionStorage.getItem('newRegisterInformation');
  const newRegisterData = !!stringNewRegisterData && JSON.parse(stringNewRegisterData);
  return newRegisterData;
};
