const RoundSouthSudan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="black"
          d="M30.7515 9.79706C28.3285 4.04138 22.6365 0 15.9997 0C11.5813 0 7.58177 1.79119 4.68652 4.68681L8.6934 9.79706H30.7515Z"
        />
        <path
          fill="#496E2D"
          d="M8.73884 22.145L4.68652 27.3132C7.58177 30.2088 11.5813 32 15.9997 32C22.6588 32 28.3671 27.9316 30.7762 22.145H8.73884Z"
        />
        <path
          fill="#A2001D"
          d="M3.47852 11.7681V20.1738H31.4491C31.808 18.8431 32.0003 17.4441 32.0003 15.9999C32.0003 14.5348 31.8029 13.1161 31.434 11.7681H3.47852Z"
        />
        <path
          fill="#1A47B8"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="#FFDA2C"
          d="M5.2112 12.022L7.16402 14.747L10.3595 13.7317L8.37133 16.4313L10.3241 19.1565L7.14239 18.0998L5.15414 20.7993L5.17602 17.4466L1.99414 16.3898L5.18939 15.3745L5.2112 12.022Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSouthSudan;
