const RoundColombia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M0 16C0 7.1635 7.1635 0 16 0c8.8365 0 16 7.1635 16 16l-16 1.3913L0 16Z"
        />
        <path
          fill="#F93939"
          d="M2.14062 24c2.76657 4.7823 7.93678 8 13.85898 8 5.9222 0 11.0925-3.2177 13.859-8l-13.859-1.0435L2.14062 24Z"
        />
        <path
          fill="#1A47B8"
          d="M29.859 24C31.2204 21.6466 32 18.9144 32 16H0c0 2.9144.779625 5.6466 2.141 8h27.718Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundColombia;
