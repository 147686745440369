const RoundSenegal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M22.9567 1.58763C20.8532 0.5705 18.4933 0 16.0002 0C13.507 0 11.1472 0.5705 9.04366 1.58763L7.65234 16L9.04366 30.4124C11.1472 31.4295 13.507 32 16.0002 32C18.4933 32 20.8532 31.4295 22.9567 30.4124L24.348 16L22.9567 1.58763Z"
        />
        <path
          fill="#496E2D"
          d="M15.9999 10.4348 17.3812 14.6861H21.8517L18.2352 17.3138 19.6165 21.5652 15.9999 18.9377 12.3834 21.5652 13.7649 17.3138 10.1484 14.6861H14.6186L15.9999 10.4348ZM9.0435 1.58813C3.69163 4.17607 0 9.65664 0 15.9999 0 22.3433 3.69163 27.8238 9.0435 30.4118V1.58813Z"
        />
        <path
          fill="#F93939"
          d="M22.9561 1.58813V30.4118C28.3079 27.8238 31.9996 22.3433 31.9996 15.9999C31.9996 9.65664 28.3079 4.17607 22.9561 1.58813Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSenegal;
