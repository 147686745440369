import { useEffect, useState } from 'react';

const usePlatform = (): {
  isMobile: boolean;
  isTablet: boolean;
  screenWidth: number;
  isLessOrEqualThanCustomSize: (customSize: number) => boolean;
  isLargerOrEqualThanCustomSize: (customSize: number) => boolean;
} => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const isMobile = screenWidth < 768;
  const isTablet = screenWidth >= 768 && screenWidth < 1024;

  const isLessOrEqualThanCustomSize = (customSize?: number) => {
    return typeof customSize === 'number' ? screenWidth <= customSize : false;
  };

  const isLargerOrEqualThanCustomSize = (customSize?: number) => {
    return typeof customSize === 'number' ? screenWidth >= customSize : false;
  };

  useEffect(() => {
    const updateScreenWidth = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', updateScreenWidth);
    updateScreenWidth();

    return () => window.removeEventListener('resize', updateScreenWidth);
  }, []);

  return {
    isMobile,
    isTablet,
    isLessOrEqualThanCustomSize,
    isLargerOrEqualThanCustomSize,
    screenWidth
  };
};

export default usePlatform;
