import { getShoppingCart } from '../services/cart.services';
import useShopStore from '../state/useShopContext';
import { ICartInfo, IProductsCart } from '../types/cart';
import { displayErrorNotification } from './displayNudosStandardNotifications';

const totalItems = (items: IProductsCart[]) => {
  let totalAllItems = 0;
  for (const item of items) {
    totalAllItems = totalAllItems + item?.quantity || 0;
  }
  return totalAllItems;
};

const infoProducts = () => {
  const { itemsCart } = useShopStore();
  if (itemsCart?.products) {
    const lengthProducts = itemsCart.products.length;
    if (lengthProducts > 0) {
      const products = itemsCart.products;
      let myProductList: IProductsCart[] = [];
      for (const product of products) {
        const countryList = product;
        myProductList = myProductList.concat(countryList);
      }
      return { productList: myProductList, productNumber: totalItems(myProductList) };
    }
    return { productList: [], productNumber: 0 };
  }
  return { productList: [], productNumber: 0 };
};

const productsOnly = () => {
  const productsList = infoProducts().productList;
  return productsList;
};

const productsNumber = () => {
  const productsListNumber = infoProducts().productNumber;
  return productsListNumber;
};

const createNewProductInExistCountry = (
  product: IProductsCart,
  itemsCart: ICartInfo,
  setCart: (itemsCart: ICartInfo) => void
) => {
  const newItemsCart = itemsCart;
  if (newItemsCart?.products) {
    newItemsCart.products = [...newItemsCart.products, product];
  }
  setCart({ ...newItemsCart });
};

const editQuantity = (product: IProductsCart, itemsCart: ICartInfo, setCart: (itemsCart: ICartInfo) => void) => {
  const newProducts = { ...itemsCart };
  const countryProductList = itemsCart?.products ? [...itemsCart.products] : [];
  const productIndex = countryProductList?.findIndex(element => element.productId === product.productId);
  if (productIndex >= 0) {
    countryProductList[productIndex] = product;
  } else {
    countryProductList.push(product);
  }
  newProducts.products = countryProductList;
  setCart(newProducts);
};

const addProductCart = (product: IProductsCart, itemsCart: ICartInfo, setCart: (itemsCart: ICartInfo) => void) => {
  if (itemsCart && itemsCart?.products) {
    const existProduct = itemsCart?.products?.find(element => element.productId === product.productId);
    if (existProduct) {
      editQuantity(product, itemsCart, setCart);
    } else {
      createNewProductInExistCountry(product, itemsCart, setCart);
    }
  }
};

const removeProductCard = (
  productId: number,
  quantity: number,
  itemsCart: ICartInfo,
  setCart: (itemsCart: ICartInfo) => void
) => {
  const newProducts = { ...itemsCart };
  const countryList = itemsCart?.products ? [...itemsCart.products] : [];
  const productIndex = countryList?.findIndex(element => element.productId === productId);
  if (productIndex >= 0) {
    countryList[productIndex].quantity = quantity;
  }
  newProducts.products = countryList;
  setCart(newProducts);
};

const deleteProduct = (productId: number, itemsCart: ICartInfo, setCart: (itemsCart: ICartInfo) => void) => {
  const newProducts = itemsCart;
  const countryList = itemsCart.products;
  const excludeList = countryList?.filter(element => element.productId !== productId);
  newProducts.products = excludeList && excludeList?.length > 0 ? [...excludeList] : [];
  setCart({ ...newProducts });
};

const productQuantity = (productId: number, itemsCart: ICartInfo) => {
  const items = itemsCart?.products;
  if (items && Array.isArray(items)) {
    const findItem = items?.find(item => Number(item.productId) === productId);
    return findItem?.quantity || 0;
  }
  return 0;
};

const editInputQuantity = (
  productId: number,
  itemsCart: ICartInfo,
  quantity: number,
  setCart: (itemsCart: ICartInfo) => void
) => {
  removeProductCard(productId, quantity, itemsCart, setCart);
};

const firstProduct = async (
  setCart: (itemsCart: ICartInfo) => void,
  userId: string | number,
  organizationId: string | number
) => {
  try {
    const newCart: ICartInfo = await getShoppingCart(userId, organizationId);
    setCart({ ...newCart });
    localStorage.setItem('bagItems', JSON.stringify(newCart));
  } catch (error) {
    displayErrorNotification();
  }
};

const formatStorageCart = () => {
  if (localStorage.getItem('bagItems')) {
    const bagItems: ICartInfo = JSON.parse(localStorage.getItem('bagItems') || '');
    return bagItems;
  }
  return undefined;
};

export {
  productsOnly,
  productsNumber,
  infoProducts,
  addProductCart,
  productQuantity,
  removeProductCard,
  deleteProduct,
  editInputQuantity,
  firstProduct,
  formatStorageCart
};
