export function unCamelCase(value: string) {
  return (
    value
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
}

export function camelize(value: string) {
  if (value !== null && value !== undefined) {
    const _value = value.toLowerCase();
    return _value
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  }
  return value;
}

export function capitalize(word: string) {
  if (word !== null && word !== undefined) {
    const value = word.toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}

function convertToCamelCase(wordsArr: string[]) {
  const firstWord = wordsArr[0];
  const otherWords = wordsArr.slice(1);
  const tranformedWords = [firstWord, ...otherWords.map(word => word[0]?.toUpperCase() + word.slice(1))];
  return tranformedWords.join('');
}
function convertToPascalCase(wordsArr: string[]) {
  return wordsArr.map(word => word[0]?.toUpperCase() + word.slice(1)).join('');
}
function convertToKebabCase(wordsArr: string[]) {
  return wordsArr.map(word => word[0]?.toLowerCase() + word.slice(1)).join('-');
}
function convertToSnakeCase(wordsArr: string[]) {
  return wordsArr.map(word => word[0]?.toLowerCase() + word.slice(1)).join('_');
}
function getWordsFromCamelOrPascalCase(word: string) {
  return word.split(/(?=[A-Z])/).map(word => word.toLowerCase());
}
function getWordsFromKebabCase(word: string) {
  return word.split('-').map(word => word.toLowerCase());
}
function getWordsFromSnakeCase(word: string) {
  return word.split('_').map(word => word.toLowerCase());
}
type TcaseNamingConvention = 'camel' | 'pascal' | 'kebab' | 'snake';
const wordFormerByCase = {
  camel: convertToCamelCase,
  pascal: convertToPascalCase,
  kebab: convertToKebabCase,
  snake: convertToSnakeCase
};
const wordGetterByCase = {
  camel: getWordsFromCamelOrPascalCase,
  pascal: getWordsFromCamelOrPascalCase,
  kebab: getWordsFromKebabCase,
  snake: getWordsFromSnakeCase
};
export function changeNamingConvention(
  currentName: string,
  originalCase: TcaseNamingConvention,
  newCase: TcaseNamingConvention
) {
  if (currentName.length === 0) return '';
  const getWordsFromName = wordGetterByCase[originalCase];
  const formNewName = wordFormerByCase[newCase];
  const words = getWordsFromName(currentName);
  return formNewName(words);
}
