const RoundEgypt = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="black"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#FF9811"
          d="M21.5651 14.2609H17.3911C17.3911 13.4926 16.7682 12.8696 15.9998 12.8696C15.2314 12.8696 14.6085 13.4926 14.6085 14.2609H10.4346C10.4346 15.0294 11.1039 15.6523 11.8723 15.6523H11.8259C11.8259 16.4207 12.4488 17.0436 13.2172 17.0436C13.2172 17.812 13.8401 18.4349 14.6085 18.4349H17.3911C18.1596 18.4349 18.7824 17.812 18.7824 17.0436C19.5509 17.0436 20.1738 16.4207 20.1738 15.6523H20.1274C20.8958 15.6523 21.5651 15.0294 21.5651 14.2609Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEgypt;
