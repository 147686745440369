import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BordButton } from '../../../../../../components/BordDesignSystem';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import EmployeeFilters from '../EmployeesFilters/EmployeesFilters';
import './EmployeesListingHeader.scss';

const EmployeesListingHeader = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const i18nLanguageKey = 'nodi:employee:';

  const handleClickNewEmployeeButton = () => {
    segmentTrackEvent({ employeesNewClick: { NewEmployeeOrigin: 'Listing' } });
    push('/nodi/employees/create?origin=employeesList');
  };

  const loadEmployeesButtonAction = () => push('/nodi/upload-employees');

  return (
    <div id="employeesListingHeader" className="moduleWithSidebar">
      <EmployeeFilters />
      <div className="buttonsContainer">
        <BordButton
          customClassName="employeeButton loadEmployeesButton"
          onClick={() => loadEmployeesButtonAction()}
          buttonIconTwo="users"
          label={t(`${i18nLanguageKey}loadEmployeesButton`)}
          modeButton="tertiary"
        />
        <BordButton
          customClassName="employeeButton newEmployeeButton "
          label={t(`${i18nLanguageKey}newEmployeeButton`)}
          buttonIconTwo="plus"
          onClick={handleClickNewEmployeeButton}
          modeButton="secondary"
        />
      </div>
    </div>
  );
};

export default EmployeesListingHeader;
