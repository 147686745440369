const SquareTimorLeste = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path fill="#FFDA2C" fillRule="evenodd" d="M0 0L14.6667 8L0 16V0Z" clipRule="evenodd" />
        <path fill="#151515" fillRule="evenodd" d="M0 0L10.4762 8L0 16V0Z" clipRule="evenodd" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M3.66702 9.3335L2.12702 10.158L2.42035 8.4119L1.17578 7.17456L2.89702 6.92176L3.66702 5.3335L4.43702 6.92283L6.15826 7.17563L4.91159 8.41296L5.20492 10.158"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTimorLeste;
