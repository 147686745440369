import './NudosLink.scss';

/**
 * @property { string } inactiveLinkText: The text to show when no linkUrl is passed
 * @property { string } linkUrl: The redirection url
 * @property { string } activeLinkText: Optional, the text to show when the linkUrl value is passed, corresponds to the text of the active link. If no value is provided by default the url is shown
 * @property { string } maxWidth : Optional, the max length for the link before being truncated, must be a valid value for the max-width property, for example "200px"
 * @property { boolean } underlineActiveLink : Optional, a boolean indicating if the text of the ACTIVE link is underlineActiveLink. Default is true
 * @property { boolean } underlineInactiveLink : Optional, a boolean indicating if the text of the INACTIVE link is underlineActiveLink. Default is true
 * @property { boolean } blankTarget : Optional, a boolean for the anchor tag target property. Default is true (opens on a new tab)
 * @property { { [key: string]: string  } } customStyles : Optional,
 */
const NudosLink = ({
  inactiveLinkText,
  linkUrl,
  activeLinkText,
  maxWidth,
  underlineActiveLink = true,
  underlineInactiveLink = true,
  blankTarget = true,
  customStyles = {}
}: InudosLink) => {
  const target = blankTarget ? 'blank' : undefined;
  const componentStyles = { ...customStyles, maxWidth: maxWidth };
  const disabledStyles = !linkUrl ? 'disabled' : '';
  const activestyles = linkUrl ? 'active' : '';
  const underlineStylesActiveLink = underlineActiveLink ? 'underlinedLink' : '';
  const underlineStylesInactiveLink = underlineInactiveLink ? 'underlinedLink' : '';
  const filledLinkText = activeLinkText || linkUrl;

  return (
    <div className={`nudosLink ${disabledStyles} ${activestyles}`} style={componentStyles}>
      {!linkUrl && <div className={`disabledLink ${underlineStylesInactiveLink}`}>{inactiveLinkText}</div>}
      {linkUrl && (
        <a className={`activeLink ${underlineStylesActiveLink}`} href={linkUrl} target={target}>
          {filledLinkText}
        </a>
      )}
    </div>
  );
};

export default NudosLink;

interface InudosLink {
  inactiveLinkText: string;
  linkUrl?: string;
  activeLinkText?: string;
  maxWidth?: string;
  underlineActiveLink?: boolean;
  underlineInactiveLink?: boolean;
  blankTarget?: boolean;
  customStyles?: { [key: string]: string };
}
