const RoundRomania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M21.5656 0.995125C19.8323 0.352063 17.9576 8.31859e-09 16.0004 8.31859e-09C14.0433 -6.24917e-05 12.1686 0.352063 10.4353 0.995125L9.04395 15.9999L10.4353 31.0047C12.1685 31.648 14.0433 32 16.0004 32C17.9575 32 19.8323 31.648 21.5656 31.0047L22.9569 16L21.5656 0.995125Z"
        />
        <path
          fill="#F93939"
          d="M32.0002 15.9999C32.0002 9.12055 27.6582 3.25574 21.5654 0.995117V31.0049C27.6582 28.744 32.0002 22.8794 32.0002 15.9999Z"
        />
        <path
          fill="#1A47B8"
          d="M0 16C0 22.8796 4.342 28.7442 10.4347 31.005L10.4347 0.995361C4.342 3.25599 0 9.12061 0 16H0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundRomania;
