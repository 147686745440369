const RoundItaly = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M32.0002 15.9999C32.0002 9.12049 27.6582 3.2558 21.5654 0.995117V31.0048C27.6582 28.7441 32.0002 22.8794 32.0002 15.9999Z"
        />
        <path
          fill="#6DA544"
          d="M0 15.9999C0 22.8794 4.342 28.7441 10.4348 31.0047V0.995117C4.342 3.2558 0 9.12049 0 15.9999Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundItaly;
