const RoundRwanda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M0 16C0 18.7718 0.705188 21.379 1.94544 23.6521L16 25.0435L30.0546 23.6521C31.2948 21.379 32 18.7718 32 16L16 14.6086L0 16Z"
        />
        <path
          fill="#3ECBF8"
          d="M30.0546 8.34781C27.3409 3.37431 22.0647 0 16 0C9.93531 0 4.65912 3.37431 1.94544 8.34781C0.705188 10.621 0 13.2281 0 16H32C32 13.2281 31.2948 10.621 30.0546 8.34781Z"
        />
        <path
          fill="#496E2D"
          d="M15.9999 31.9999C22.0646 31.9999 27.3408 28.6256 30.0544 23.6521H1.94531C4.659 28.6256 9.93519 31.9999 15.9999 31.9999Z"
        />
        <path
          fill="#FFDA2C"
          d="M18.0869 9.36382L20.041 10.283L19.0005 12.1755L21.1224 11.7696L21.3912 13.9131L22.8691 12.3366L24.3471 13.9131L24.6159 11.7696L26.7378 12.1754L25.6973 10.2829L27.6513 9.36382L25.6972 8.44476L26.7378 6.55226L24.616 6.95813L24.347 4.8147L22.8691 6.3912L21.3912 4.8147L21.1224 6.95813L19.0004 6.55226L20.041 8.44482L18.0869 9.36382Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundRwanda;
