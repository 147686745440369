import { useLocation } from 'react-router-dom';
import { shouldShowSidebarClosed } from '../components/SideBar/sidebar.utils';
import usePlatform from './usePlatform';

const useSidebar = () => {
  const { isLargerOrEqualThanCustomSize } = usePlatform();
  const { pathname } = useLocation();

  const sidebarIsAlwaysOpen = isLargerOrEqualThanCustomSize(1920);
  const closedSidebar = shouldShowSidebarClosed(pathname);
  const isSidebarOpen = sidebarIsAlwaysOpen || !closedSidebar;
  const moduleSidebarStyles = isSidebarOpen ? 'moduleWithSidebar' : 'moduleWithClosedSidebar';

  return { moduleSidebarStyles, isSidebarOpen };
};

export default useSidebar;
