import { FC } from 'react';
import { GenericLoader } from '../../../dashboard/components';
import BordEmptyState from '../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import './NoLocationLeft.scss';

interface INoLocationLeft {
  actionAddLocation?: () => void;
  generalLoading?: boolean;
}

const NoLocationLeft: FC<INoLocationLeft> = ({ actionAddLocation, generalLoading }: INoLocationLeft) => {
  if (generalLoading) return <GenericLoader sizeLoader={60} />;
  return (
    <BordEmptyState
      title="Esta herramienta no tiene ubicación"
      subTitle="Agrega una ubicación para comenzar a gestionar"
      buttonOneProps={{
        label: 'Agregar ubicación',
        onClick: () => {
          if (actionAddLocation) {
            actionAddLocation();
          }
        }
      }}
      ilustrationIconProps={{
        variant: 'pin',
        standardSize: 100
      }}
    />
  );
};

export default NoLocationLeft;
