import { segmentTrackEvent } from '../../../../utils/segment';
import { IDetailNewCard } from '../../InventoryListingCard';
import { IassigneeInfo, InudoInfo } from '../../../../types/global';
import { getAcquisitionAssignationHistory, getNodiProductById } from '../../../../services/services';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { BordButton, BordTooltip } from '../../../BordDesignSystem';
import {
  disableToolManagementBeforeDeliveryAlertText,
  logisticServiceInCourseAlertText
} from '../../../../utils/productDefinitions';
import { useHistory } from 'react-router-dom';
import { CTA_TYPE } from '../../../BordDesignSystem/BordTooltip/BordTooltip.type';
import './InventoryListingCardManageButton.scss';

const InventoryListingCardManageButton = ({
  currentAssignee,
  setShowUnassignToolModal,
  setCurrentAssignee,
  setSearchedAcquisition,
  setLoadingAssigneeData,
  onGoToAssignation,
  disabledLogisticServiceInProcess,
  toolHasntBeenDelivered,
  blockedCountry,
  acceptLogisticService,
  dataCard,
  assignedTo
}: {
  currentAssignee?: IassigneeInfo;
  setShowUnassignToolModal: (boolean: boolean) => void;
  setCurrentAssignee: (assignee?: IassigneeInfo) => void;
  setSearchedAcquisition: (assignee?: InudoInfo) => void;
  setLoadingAssigneeData: (assignee: boolean) => void;
  onGoToAssignation: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabledLogisticServiceInProcess: boolean;
  toolHasntBeenDelivered: boolean;
  blockedCountry: boolean;
  acceptLogisticService: boolean;
  dataCard: IDetailNewCard;
  assignedTo?: string | null;
}) => {
  const { push } = useHistory();

  const { country, commercialName, statusId, referenceModel, orderId, logisticServiceId } = dataCard;

  const displayDisabledOnboardingButton =
    statusId === 1 || statusId === 4 || statusId === 7 || (!statusId && referenceModel !== 'ExternalProduct');

  const displayDisabledOffbardingButton =
    assignedTo && (disabledLogisticServiceInProcess || blockedCountry || toolHasntBeenDelivered);

  const displayOffboardginButton =
    assignedTo && !(disabledLogisticServiceInProcess || blockedCountry || toolHasntBeenDelivered);

  const displayDisabledAssignButton =
    disabledLogisticServiceInProcess || toolHasntBeenDelivered || (acceptLogisticService && blockedCountry);

  const getAssigneeAndTool = async () => {
    try {
      const response = await getAcquisitionAssignationHistory(`${dataCard.productId}`);
      if (response) {
        const currAssignee = response.find(assignee => !assignee.unAssignmentDate);
        setCurrentAssignee(currAssignee);
        const responseSearchedAcquisition = await getNodiProductById(`${dataCard.productId}`);
        if (responseSearchedAcquisition) {
          setSearchedAcquisition(responseSearchedAcquisition);
          setLoadingAssigneeData(false);
        }
      }
    } catch (error) {
      displayErrorNotification();
      setLoadingAssigneeData(false);
    }
  };

  const sendToolsManagementSegmentEvent = (ManagementType: string) => {
    segmentTrackEvent({
      toolsManagementClick: {
        CountryTool: country?.name || 'Sin información de País',
        ManagementType: ManagementType,
        ProductName: commercialName || ''
      }
    });
  };

  const handleClickUnassign = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event?.stopPropagation();
    if (!dataCard.productId) return;
    sendToolsManagementSegmentEvent('Unassign');
    if (currentAssignee) setShowUnassignToolModal(true);
    if (!currentAssignee) {
      setShowUnassignToolModal(true);
      getAssigneeAndTool();
    }
  };

  const getToolTipData = () => {
    if (blockedCountry)
      return {
        message: 'Esta herramienta se encuentra en un país no habilitado para servicios logísticos',
        ctaType: CTA_TYPE?.noCta
      };
    if (toolHasntBeenDelivered)
      return {
        message: disableToolManagementBeforeDeliveryAlertText,
        ctaType: CTA_TYPE?.oneCta,
        ctaLabel: 'Ver Orden',
        ctaButtonOneAction: (event?: React.ChangeEvent<HTMLInputElement>) => {
          event?.stopPropagation();
          push(orderId ? `/nodi/orders/details/${orderId}?from=tools` : '');
        }
      };
    if (disabledLogisticServiceInProcess)
      return {
        message: logisticServiceInCourseAlertText,
        ctaType: CTA_TYPE?.oneCta,
        ctaLabel: 'Ver servicio',
        ctaButtonOneAction: (event?: React.ChangeEvent<HTMLInputElement>) => {
          event?.stopPropagation();
          push(logisticServiceId ? `/nodi/logistics-services/details/${logisticServiceId}?from=tools-listing` : '');
        }
      };
    return { message: undefined, ctaType: CTA_TYPE?.noCta };
  };

  return (
    <div className="inventoryListingCardManageButton">
      <BordTooltip
        message={getToolTipData()?.message}
        position="bottom"
        ctaType={getToolTipData()?.ctaType}
        ctaLabel={getToolTipData()?.ctaLabel}
        ctaAction={(e?: React.ChangeEvent<HTMLInputElement>) => getToolTipData()?.ctaButtonOneAction?.(e)}
      >
        {!assignedTo && (
          <BordButton
            label="Onboarding"
            customWidth="w-180"
            buttonIconTwo="userPlus"
            onClick={e => onGoToAssignation(e)}
            disabled={displayDisabledAssignButton || displayDisabledOnboardingButton}
          />
        )}
        {(displayOffboardginButton || displayDisabledOffbardingButton) && (
          <BordButton
            label="Offboarding"
            disabled={!!displayDisabledOffbardingButton}
            customWidth="w-180"
            buttonIconTwo="userRemove"
            onClick={e => handleClickUnassign(e)}
          />
        )}
      </BordTooltip>
    </div>
  );
};

export default InventoryListingCardManageButton;
