const SquareEcuador = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#1A47B8"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 12H22V16H0V12Z" clipRule="evenodd" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 0H22V8H0V0Z" clipRule="evenodd" />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M6.28613 4.2666H9.42899L11.0004 5.33327L12.5718 4.2666H15.7147L12.5718 5.33327L11.5242 6.39994H10.4766L9.42899 5.33327L6.28613 4.2666Z"
            clipRule="evenodd"
          />
          <path
            fill="#3ECBF8"
            d="M11.0001 10.6666C11.868 10.6666 12.5716 9.71144 12.5716 8.53324C12.5716 7.35503 11.868 6.3999 11.0001 6.3999C10.1323 6.3999 9.42871 7.35503 9.42871 8.53324C9.42871 9.71144 10.1323 10.6666 11.0001 10.6666Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M8.38063 7.4668V9.60013L10.4759 10.6668H11.5235L13.6187 9.60013V7.4668L14.6663 9.60013L13.6187 10.6668L11.5235 11.7335H10.4759L8.38063 10.6668L7.33301 9.60013L8.38063 7.4668Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEcuador;
