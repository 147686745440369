const RoundBotswana = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M.755561 11.1303c-.490125 1.5356-.75556245 3.1714-.75549995 4.8696-.0000625 1.698.26537495 3.3339.75556295 4.8695l15.244476.6958 15.2443-.6958c.4901-1.5356.7556-3.1715.7556-4.8695 0-1.6981-.2654-3.334-.7556-4.8696L16 10.4347l-15.244439.6956Z"
        />
        <path
          fill="#000"
          d="M32 16c0-.9492-.0831-1.879-.2417-2.7827H.24175C.083125 14.121 0 15.0507 0 15.9999c0 .9493.0831875 1.8789.24175 2.7826H31.7583C31.9168 17.8787 32 16.9491 32 16Z"
        />
        <path
          fill="#3ECBF8"
          d="M16.0001 32c7.1385 0 13.1841-4.6752 15.2443-11.1305H.755615C2.81593 27.3248 8.86162 31.9999 16.0001 32ZM16.0001.00012207C8.86162.00012207 2.81599 4.67531.755554 11.1304l30.488746.0001C29.1841 4.67531 23.1384-.00000293 16.0001.00012207Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBotswana;
