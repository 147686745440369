const RoundCocosIsland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#6DA544"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#FFDA2C"
          d="m24.5612 22.9565.439.9181.9915-.2291-.444.9157.7973.6322-.9927.2238.0028 1.0177-.7939-.6367-.7939.6367.0027-1.0177-.9927-.2238.7974-.6322-.444-.9157.9914.2291.4391-.9181ZM20.4762 13.2175l.4391.9181.9915-.2292-.444.9158.7973.6322-.9927.2238.0027 1.0176-.7939-.6366-.7939.6366.0027-1.0176-.9926-.2238.7973-.6322-.4441-.9158.9916.2292.439-.9181ZM24.5611 7.65234l.4391.91807.9915-.22925-.444.91575.7973.63231-.9928.22368.0027 1.0177-.7938-.6367-.7939.6367.0028-1.0177-.9927-.22368.7973-.63231-.444-.91575.9914.22925.4391-.91807ZM28.1285 11.8262l.4391.918.9916-.2292-.4441.9158.7973.6322-.9927.2238.0028 1.0177-.794-.6367-.7938.6367.0027-1.0177-.9927-.2238.7973-.6322-.444-.9158.9915.2292.439-.918ZM25.5771 16.6958l.3455 1.0629h1.1175l-.9042.6569.3455 1.0628-.9043-.6568-.904.6568.3453-1.0628-.9041-.6569h1.1175l.3453-1.0629Z"
        />
        <path
          fill="#FFDA2C"
          d="M17.7389 20.5218c-2.4973 0-4.5218-2.0245-4.5218-4.5217 0-2.4973 2.0245-4.5218 4.5218-4.5218.7787 0 1.5114.197 2.151.5435-1.0035-.9814-2.3756-1.587-3.8902-1.587-3.0735 0-5.5651 2.4916-5.5651 5.5652s2.4916 5.5652 5.5651 5.5652c1.5145 0 2.8867-.6056 3.8901-1.587-.6395.3467-1.3722.5436-2.1509.5436ZM10.0611 6.26094c-.48129-.8315-1.37973-1.39131-2.40948-1.39131s-1.92818.55981-2.40943 1.39131h4.81891ZM10.0704 6.2771 7.6517 8.69579 5.23302 6.2771c-.23119.40569-.36388.87487-.36388 1.37525 0 1.53681 1.24581 2.78265 2.78263 2.78265 1.53681 0 2.78263-1.24578 2.78263-2.78265-.0001-.50038-.1328-.96956-.364-1.37525Z"
        />
        <path
          fill="#A2001D"
          d="M6.95605 8v2.3471c.22232.0572.45544.0876.69563.0876.24019 0 .47331-.0304.69562-.0876V8H6.95605Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCocosIsland;
