const RoundCaymanIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16C0 16.0038 16 .00175 16 0c8.8365 0 16 7.1635 16 16Z"
        />
        <path
          fill="#fff"
          d="M15.957 16.0001h.0428v-.0428c-.0142.0143-.0285.0285-.0428.0428Z"
        />
        <path
          fill="#fff"
          d="M16 8.34781V0h-.0027C7.16194.0015 0 7.16431 0 16h8.34781v-4.7007L13.0485 16h2.9087c.0143-.0142.0286-.0285.0429-.0428v-2.9086l-4.7009-4.70079H16Z"
        />
        <path
          fill="#6DA544"
          d="M22.9565 11.8262c1.5368 0 2.7826-1.2458 2.7826-2.78259 0-1.5368-1.2458-2.78262-2.7826-2.78262s-2.7827 1.24582-2.7827 2.78262c0 1.53679 1.2459 2.78259 2.7827 2.78259Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51179 5.59223 2.08691 8.09466V16h4.17394V6.26085H16V2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="m15.9998 14.0325-5.6846-5.6846H8.34766v.00013L15.9997 16h.0001v-1.9675ZM18.0869 8.3479h9.7391v4.1739h-9.7391V8.3479Z"
        />
        <path
          fill="#FFDA2C"
          d="M18.0869 18.7827v2.7826h1.464c.8778.8603 2.0794 1.3914 3.4056 1.3914s2.5277-.5311 3.4055-1.3914h1.464v-2.7826h-9.7391Z"
        />
        <path
          fill="#3ECBF8"
          d="M18.0869 11.8262v4.1739c0 3.7274 4.8696 4.8696 4.8696 4.8696s4.8695-1.1422 4.8695-4.8696v-4.1739h-9.7391Z"
        />
        <path
          fill="#F3F3F3"
          d="M22.9562 14.0557c-1.2173 0-1.2173 1.113-2.4347 1.113-1.2173 0-1.2173-1.113-2.4346-1.113v1.9478c1.2173 0 1.2173 1.113 2.4346 1.113 1.2174 0 1.2174-1.113 2.4347-1.113 1.2174 0 1.2174 1.113 2.4349 1.113 1.2175 0 1.2174-1.113 2.4349-1.113v-1.9478c-1.2174 0-1.2174 1.113-2.4349 1.113-1.2175 0-1.2175-1.113-2.4349-1.113ZM22.9562 10.1565c-1.2173 0-1.2173 1.1131-2.4347 1.1131-1.2173 0-1.2173-1.1131-2.4346-1.1131v1.9478c1.2173 0 1.2173 1.1131 2.4346 1.1131 1.2174 0 1.2174-1.1131 2.4347-1.1131 1.2174 0 1.2174 1.1131 2.4349 1.1131 1.2175 0 1.2174-1.1131 2.4349-1.1131v-1.9478c-1.2174 0-1.2174 1.1131-2.4349 1.1131-1.2175 0-1.2175-1.1131-2.4349-1.1131Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCaymanIslands;
