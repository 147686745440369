const RoundDenmark = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M12.5225 13.9131h19.3428C30.8426 6.06262 24.1299 0 16.0007 0c-1.1947 0-2.3582.132062-3.4782.380312V13.9131ZM8.34812 13.9131V1.94556C3.9548 4.34268.80968 8.73962.135742 13.9132H8.34812v-.0001ZM8.34805 18.0869H.135742C.80968 23.2605 3.9548 27.6574 8.34812 30.0545l-.00007-11.9676ZM12.5225 18.087v13.5327c1.12.2482 2.2835.3803 3.4782.3803 8.1292 0 14.8419-6.0626 15.8646-13.9131H12.5225v.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundDenmark;
