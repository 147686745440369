const SquareDominica = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#249F58" rx="2" />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M10.4762 7.46667H0V8.53333H10.4762V16H11.5238V8.53333H22V7.46667H11.5238V0H10.4762V7.46667Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M9.42857 6.4V7.46667H10.4762V0H9.42857V6.4H0V7.46667H10.4762V6.4H9.42857ZM10.4762 7.46667H22V6.4H9.42857V7.46667H10.4762V6.4H9.42857V16H10.4762V7.46667Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M12.5714 9.6V8.53333H11.5238V16H12.5714V9.6H22V8.53333H11.5238V9.6H12.5714ZM0 8.53333H13.619V9.6H0V8.53333ZM11.5238 0H12.5714V9.6H11.5238V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            d="M10.9997 11.7333C13.0247 11.7333 14.6663 10.0618 14.6663 7.99994C14.6663 5.93807 13.0247 4.2666 10.9997 4.2666C8.97463 4.2666 7.33301 5.93807 7.33301 7.99994C7.33301 10.0618 8.97463 11.7333 10.9997 11.7333Z"
          />
          <path
            fill="#0A6A30"
            fillRule="evenodd"
            d="M9.42871 6.3999H10.4763V7.46657H9.42871V6.3999ZM11.5239 6.3999H12.5716V7.46657H11.5239V6.3999ZM11.5239 8.53324H12.5716V9.5999H11.5239V8.53324ZM9.42871 8.53324H10.4763V9.5999H9.42871V8.53324Z"
            clipRule="evenodd"
          />
          <path
            fill="#955DCC"
            fillRule="evenodd"
            d="M10.4766 6.3999H11.5242V8.53324H10.4766V6.3999Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareDominica;
