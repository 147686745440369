import { Link, useLocation } from 'react-router-dom';
import { NewLogisticsVerifyData } from '../Components';
import { BordButton } from '../../../components/BordDesignSystem';
import { IconXInCircle } from '../../../assets/DesignSystem/SVGComponents';
import { ItoolEssentialData } from '../../../types/requestLogisticsModule';
import { useEffect } from 'react';
import { segmentTrackEvent } from '../../../utils/segment';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { locationFormat } from '../../../utils/locationFormat';

import './LogisticServiceRejectedView.scss';

interface IlocationData {
  place?: string;
  countryName?: string;
  locationName?: string;
  userName?: string;
}
interface IlogisticServiceData {
  products?: ItoolEssentialData[];
  estimatedDelivery: string;
  dateRange: string;
  originLocationData: IlocationData;
  destinationLocationData: IlocationData;
}

interface ILogisticServiceRejectedView {
  originUrl: string;
  logisticServiceData: IlogisticServiceData;
  flux: string;
}

const LogisticServiceRejectedView = () => {
  const { state }: { state: ILogisticServiceRejectedView } = useLocation();
  const { stateSubscription } = useStateSubscriptions();
  const type = state?.flux === 'assignment' || state?.flux === 'management' ? 'assignment' : 'unassignment';
  const tools = state?.logisticServiceData?.products || [];
  const noDataText = 'Sin información';
  const deliveryText = state?.logisticServiceData?.estimatedDelivery;

  const estimatedDelivery = deliveryText ? `${deliveryText} días hábiles` : noDataText;
  const selectedDatesRange = state?.logisticServiceData?.dateRange || noDataText;

  const getFormattedName = (locationData: IlocationData) => {
    if (!locationData) return noDataText;
    switch (locationData.place) {
      case 'office':
        return `Oficinas ${locationData?.countryName || ''}`;
      case 'user':
        return locationData?.userName || noDataText;
      case 'warranty':
        return 'Marca';
      case 'nudos':
        return `.bord Warehouse ${locationData?.countryName || ''}`;
      case 'diagnostic':
        return 'Diagnóstico';
      default:
        return `${locationData?.locationName || noDataText}`;
    }
  };

  const deliveryName = getFormattedName(state?.logisticServiceData?.destinationLocationData);
  const collectionName = getFormattedName(state?.logisticServiceData?.originLocationData);

  const rejectedEvent = async () => {
    segmentTrackEvent({
      nodiLogisticServices52ResumeRejectedView: {
        DestinationCountry: state?.logisticServiceData?.destinationLocationData?.countryName || '',
        LogisticServiceDestination: locationFormat(state?.logisticServiceData.destinationLocationData.place || ''),
        LogisticServiceOrigin: 'Employee',
        NudosCare: !!stateSubscription?.nudosCare,
        OriginCountry: state?.logisticServiceData?.originLocationData?.countryName || '',
        TypeOfPlatform: stateSubscription?.levelSubscription === 'Lite' ? 'Prime' : 'Basic'
      }
    });
  };

  useEffect(() => {
    rejectedEvent();
  }, []);

  return (
    <div className="LogisticServiceRejectedView">
      <IconXInCircle className="rejectedIcon" />
      <div className="title">El servicio logístico fue rechazado</div>
      <div className="subtitle">Puedes iniciar nuevamente tu servicio logístico</div>
      {state?.logisticServiceData && (
        <NewLogisticsVerifyData
          type={type}
          tools={tools}
          deliveryName={deliveryName}
          collectionName={collectionName}
          rejectedLayout={true}
          estimatedDelivery={estimatedDelivery}
          selectedDatesRange={selectedDatesRange}
          logisticServiceStatus="cancelado"
        />
      )}
      <div className="floatContainerButtosServiceSummary">
        <div className="boxButtonsContainer">
          <Link to={'/dashboard'}>
            <BordButton customClassName="button" label="Volver al inicio" modeButton="secondary" />
          </Link>
          {state?.originUrl && (
            <Link
              to={`${state?.originUrl}`}
              onClick={() => {
                segmentTrackEvent({ nodiLogisticServicesRejectQuoteRepeatClick: null });
              }}
            >
              <BordButton customClassName="button" label="Repetir servicio logístico" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogisticServiceRejectedView;
