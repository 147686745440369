import { COUNTRY_CODES } from '../BordFlag.types';
import RoundAfghanistan from './RoundAfghanistan';
import RoundAlandIslands from './RoundAlandIslands';
import RoundAlbania from './RoundAlbania';
import RoundAlgeria from './RoundAlgeria';
import RoundAmericanSamoa from './RoundAmericanSamoa';
import RoundAndorra from './RoundAndorra';
import RoundAngola from './RoundAngola';
import RoundAnguilla from './RoundAnguilla';
import RoundAntiguaAndBarbuda from './RoundAntiguaAndBarbuda';
import RoundArgentina from './RoundArgentina';
import RoundArmenia from './RoundArmenia';
import RoundAruba from './RoundAruba';
import RoundAustralia from './RoundAustralia';
import RoundAustria from './RoundAustria';
import RoundAzerbaijan from './RoundAzerbaijan';
import RoundBahamas from './RoundBahamas';
import RoundBahrain from './RoundBahrain';
import RoundBangladesh from './RoundBangladesh';
import RoundBarbados from './RoundBarbados';
import RoundBelarus from './RoundBelarus';
import RoundBelgium from './RoundBelgium';
import RoundBelize from './RoundBelize';
import RoundBenin from './RoundBenin';
import RoundBermuda from './RoundBermuda';
import RoundBhutan from './RoundBhutan';
import RoundBolivia from './RoundBolivia';
import RoundBonaire from './RoundBonaire';
import RoundBosniaAndHerzegovina from './RoundBosniaAndHerzegovina';
import RoundBotswana from './RoundBotswana';
import RoundBrazil from './RoundBrazil';
import RoundBritishIndianOceanTerritory from './RoundBritishIndianOceanTerritory';
import RoundBritishVirginIslands from './RoundBritishVirginIslands';
import RoundBrunei from './RoundBrunei';
import RoundBulgaria from './RoundBulgaria';
import RoundBurkinaFaso from './RoundBurkinaFaso';
import RoundBurundi from './RoundBurundi';
import RoundCambodia from './RoundCambodia';
import RoundCameroon from './RoundCameroon';
import RoundCanada from './RoundCanada';
import RoundCapeVerde from './RoundCapeVerde';
import RoundCaymanIslands from './RoundCaymanIslands';
import RoundCentralAfricanRepublic from './RoundCentralAfricanRepublic';
import RoundChad from './RoundChad';
import RoundChile from './RoundChile';
import RoundChina from './RoundChina';
import RoundCocosIsland from './RoundCocosIsland';
import RoundColombia from './RoundColombia';
import RoundComoros from './RoundComoros';
import RoundCookIslands from './RoundCookIslands';
import RoundCostaRica from './RoundCostaRica';
import RoundCroatia from './RoundCroatia';
import RoundCuba from './RoundCuba';
import RoundCuracao from './RoundCuracao';
import RoundCyprus from './RoundCyprus';
import RoundCzechRepublic from './RoundCzechRepublic';
import RoundDemocraticRepublicOfCongo from './RoundDemocraticRepublicOfCongo';
import RoundDenmark from './RoundDenmark';
import RoundDjibouti from './RoundDjibouti';
import RoundDominica from './RoundDominica';
import RoundDominicanRepublic from './RoundDominicanRepublic';
import RoundEastTimor from './RoundEastTimor';
import RoundEcuador from './RoundEcuador';
import RoundEgypt from './RoundEgypt';
import RoundElSalvador from './RoundElSalvador';
import RoundEquatorialGuinea from './RoundEquatorialGuinea';
import RoundEritrea from './RoundEritrea';
import RoundEstonia from './RoundEstonia';
import RoundEthiopia from './RoundEthiopia';
import RoundEuropeanUnion from './RoundEuropeanUnion';
import RoundFalklandIslands from './RoundFalklandIslands';
import RoundFaroeIslands from './RoundFaroeIslands';
import RoundFiji from './RoundFiji';
import RoundFinland from './RoundFinland';
import RoundFrance from './RoundFrance';
import RoundFrenchPolynesia from './RoundFrenchPolynesia';
import RoundGabon from './RoundGabon';
import RoundGambia from './RoundGambia';
import RoundGeorgia from './RoundGeorgia';
import RoundGermany from './RoundGermany';
import RoundGhana from './RoundGhana';
import RoundGibraltar from './RoundGibraltar';
import RoundGreece from './RoundGreece';
import RoundGreenland from './RoundGreenland';
import RoundGrenada from './RoundGrenada';
import RoundGuam from './RoundGuam';
import RoundGuatemala from './RoundGuatemala';
import RoundGuernsey from './RoundGuernsey';
import RoundGuinea from './RoundGuinea';
import RoundGuineaBissau from './RoundGuineaBissau';
import RoundGuyana from './RoundGuyana';
import RoundHaiti from './RoundHaiti';
import RoundHonduras from './RoundHonduras';
import RoundHongKong from './RoundHongKong';
import RoundHungary from './RoundHungary';
import RoundIceland from './RoundIceland';
import RoundIndia from './RoundIndia';
import RoundIndonesia from './RoundIndonesia';
import RoundIran from './RoundIran';
import RoundIraq from './RoundIraq';
import RoundIreland from './RoundIreland';
import RoundIsleOfMan from './RoundIsleOfMan';
import RoundIsrael from './RoundIsrael';
import RoundItaly from './RoundItaly';
import RoundIvoryCoast from './RoundIvoryCoast';
import RoundJamaica from './RoundJamaica';
import RoundJapan from './RoundJapan';
import RoundJersey from './RoundJersey';
import RoundJordan from './RoundJordan';
import RoundKazakhstan from './RoundKazakhstan';
import RoundKenya from './RoundKenya';
import RoundKiribati from './RoundKiribati';
import RoundKuwait from './RoundKuwait';
import RoundKyrgyzstan from './RoundKyrgyzstan';
import RoundLaos from './RoundLaos';
import RoundLatvia from './RoundLatvia';
import RoundLebanon from './RoundLebanon';
import RoundLesotho from './RoundLesotho';
import RoundLiberia from './RoundLiberia';
import RoundLibya from './RoundLibya';
import RoundLiechtenstein from './RoundLiechtenstein';
import RoundLithuania from './RoundLithuania';
import RoundLuxembourg from './RoundLuxembourg';
import RoundMacao from './RoundMacao';
import RoundMadagascar from './RoundMadagascar';
import RoundMalawi from './RoundMalawi';
import RoundMalaysia from './RoundMalaysia';
import RoundMaldives from './RoundMaldives';
import RoundMali from './RoundMali';
import RoundMalta from './RoundMalta';
import RoundMarshallIsland from './RoundMarshallIsland';
import RoundMartinique from './RoundMartinique';
import RoundMauritania from './RoundMauritania';
import RoundMauritius from './RoundMauritius';
import RoundMexico from './RoundMexico';
import RoundMicronesia from './RoundMicronesia';
import RoundMissingCountry from './RoundMissingCountry';
import RoundMoldova from './RoundMoldova';
import RoundMonaco from './RoundMonaco';
import RoundMongolia from './RoundMongolia';
import RoundMontenegro from './RoundMontenegro';
import RoundMontserrat from './RoundMontserrat';
import RoundMorocco from './RoundMorocco';
import RoundMozambique from './RoundMozambique';
import RoundMyanmar from './RoundMyanmar';
import RoundNamibia from './RoundNamibia';
import RoundNauru from './RoundNauru';
import RoundNepal from './RoundNepal';
import RoundNetherlands from './RoundNetherlands';
import RoundNewZealand from './RoundNewZealand';
import RoundNicaragua from './RoundNicaragua';
import RoundNiger from './RoundNiger';
import RoundNigeria from './RoundNigeria';
import RoundNiue from './RoundNiue';
import RoundNorfolkIsland from './RoundNorfolkIsland';
import RoundNorthernMarianasIslands from './RoundNorthernMarianasIslands';
import RoundNorthKorea from './RoundNorthKorea';
import RoundNorway from './RoundNorway';
import RoundOman from './RoundOman';
import RoundPakistan from './RoundPakistan';
import RoundPalau from './RoundPalau';
import RoundPalestine from './RoundPalestine';
import RoundPanama from './RoundPanama';
import RoundPapuaNewGuinea from './RoundPapuaNewGuinea';
import RoundParaguay from './RoundParaguay';
import RoundPeru from './RoundPeru';
import RoundPhilippines from './RoundPhilippines';
import RoundPitcairnIslands from './RoundPitcairnIslands';
import RoundPoland from './RoundPoland';
import RoundPortugal from './RoundPortugal';
import RoundPuertoRico from './RoundPuertoRico';
import RoundQatar from './RoundQatar';
import RoundRepublicOfTheCongo from './RoundRepublicOfTheCongo';
import RoundRomania from './RoundRomania';
import RoundRussia from './RoundRussia';
import RoundRwanda from './RoundRwanda';
import RoundSahrawiArabDemocraticRepublic from './RoundSahrawiArabDemocraticRepublic';
import RoundSamoa from './RoundSamoa';
import RoundSanMarino from './RoundSanMarino';
import RoundSaoTomeAndPrince from './RoundSaoTomeAndPrince';
import RoundSaudiArabia from './RoundSaudiArabia';
import RoundSenegal from './RoundSenegal';
import RoundSerbia from './RoundSerbia';
import RoundSeychelles from './RoundSeychelles';
import RoundSierraLeone from './RoundSierraLeone';
import RoundSingapore from './RoundSingapore';
import RoundSintMaarten from './RoundSintMaarten';
import RoundSlovakia from './RoundSlovakia';
import RoundSlovenia from './RoundSlovenia';
import RoundSolomonIslands from './RoundSolomonIslands';
import RoundSomalia from './RoundSomalia';
import RoundSouthAfrica from './RoundSouthAfrica';
import RoundSouthKorea from './RoundSouthKorea';
import RoundSouthSudan from './RoundSouthSudan';
import RoundSpain from './RoundSpain';
import RoundSriLanka from './RoundSriLanka';
import RoundStBarts from './RoundStBarts';
import RoundStLucia from './RoundStLucia';
import RoundStVincentAndTheGrenadines from './RoundStVincentAndTheGrenadines';
import RoundSudan from './RoundSudan';
import RoundSuriname from './RoundSuriname';
import RoundSwaziland from './RoundSwaziland';
import RoundSweden from './RoundSweden';
import RoundSwitzerland from './RoundSwitzerland';
import RoundSyria from './RoundSyria';
import RoundTaiwan from './RoundTaiwan';
import RoundTajikistan from './RoundTajikistan';
import RoundTanzania from './RoundTanzania';
import RoundThailand from './RoundThailand';
import RoundTogo from './RoundTogo';
import RoundTokelau from './RoundTokelau';
import RoundTonga from './RoundTonga';
import RoundTrinidadAndTobago from './RoundTrinidadAndTobago';
import RoundTunisia from './RoundTunisia';
import RoundTurkey from './RoundTurkey';
import RoundTurkmenistan from './RoundTurkmenistan';
import RoundTurksAndCaicos from './RoundTurksAndCaicos';
import RoundTuvalu from './RoundTuvalu';
import RoundUganda from './RoundUganda';
import RoundUkraine from './RoundUkraine';
import RoundUnitedArabEmirates from './RoundUnitedArabEmirates';
import RoundUnitedKingdom from './RoundUnitedKingdom';
import RoundUnitedStates from './RoundUnitedStates';
import RoundUruguay from './RoundUruguay';
import RoundUzbekistan from './RoundUzbekistan';
import RoundVanuatu from './RoundVanuatu';
import RoundVaticanCity from './RoundVaticanCity';
import RoundVenezuela from './RoundVenezuela';
import RoundVietnam from './RoundVietnam';
import RoundVirginIslands from './RoundVirginIslands';
import RoundYemen from './RoundYemen';
import RoundZambia from './RoundZambia';
import RoundZimbabwe from './RoundZimbabwe';

export const roundedFlags: { [key in COUNTRY_CODES]: JSX.Element } = {
  af: <RoundAfghanistan />,
  ax: <RoundAlandIslands />,
  al: <RoundAlbania />,
  dz: <RoundAlgeria />,
  as: <RoundAmericanSamoa />,
  ad: <RoundAndorra />,
  ao: <RoundAngola />,
  ai: <RoundAnguilla />,
  aq: <RoundMissingCountry />,
  ag: <RoundAntiguaAndBarbuda />,
  ar: <RoundArgentina />,
  am: <RoundArmenia />,
  aw: <RoundAruba />,
  au: <RoundAustralia />,
  at: <RoundAustria />,
  az: <RoundAzerbaijan />,
  bs: <RoundBahamas />,
  bh: <RoundBahrain />,
  bd: <RoundBangladesh />,
  bb: <RoundBarbados />,
  by: <RoundBelarus />,
  be: <RoundBelgium />,
  bz: <RoundBelize />,
  bj: <RoundBenin />,
  bm: <RoundBermuda />,
  bt: <RoundBhutan />,
  bo: <RoundBolivia />,
  bq: <RoundBonaire />,
  ba: <RoundBosniaAndHerzegovina />,
  bw: <RoundBotswana />,
  bv: <RoundMissingCountry />,
  br: <RoundBrazil />,
  io: <RoundBritishIndianOceanTerritory />,
  bn: <RoundBrunei />,
  bg: <RoundBulgaria />,
  bf: <RoundBurkinaFaso />,
  bi: <RoundBurundi />,
  cv: <RoundCapeVerde />,
  kh: <RoundCambodia />,
  cm: <RoundCameroon />,
  ca: <RoundCanada />,
  ky: <RoundCaymanIslands />,
  cf: <RoundCentralAfricanRepublic />,
  td: <RoundChad />,
  cl: <RoundChile />,
  cn: <RoundChina />,
  cx: <RoundMissingCountry />,
  cc: <RoundCocosIsland />,
  co: <RoundColombia />,
  km: <RoundComoros />,
  cd: <RoundDemocraticRepublicOfCongo />,
  cg: <RoundRepublicOfTheCongo />,
  ck: <RoundCookIslands />,
  cr: <RoundCostaRica />,
  ci: <RoundIvoryCoast />,
  hr: <RoundCroatia />,
  cu: <RoundCuba />,
  cw: <RoundCuracao />,
  cy: <RoundCyprus />,
  cz: <RoundCzechRepublic />,
  dk: <RoundDenmark />,
  dj: <RoundDjibouti />,
  dm: <RoundDominica />,
  do: <RoundDominicanRepublic />,
  ec: <RoundEcuador />,
  eg: <RoundEgypt />,
  sv: <RoundElSalvador />,
  gq: <RoundEquatorialGuinea />,
  er: <RoundEritrea />,
  ee: <RoundEstonia />,
  eu: <RoundEuropeanUnion />,
  sz: <RoundSwaziland />,
  et: <RoundEthiopia />,
  fk: <RoundFalklandIslands />,
  fo: <RoundFaroeIslands />,
  fj: <RoundFiji />,
  fi: <RoundFinland />,
  fr: <RoundFrance />,
  gf: <RoundMissingCountry />,
  pf: <RoundFrenchPolynesia />,
  tf: <RoundMissingCountry />,
  ga: <RoundGabon />,
  gm: <RoundGambia />,
  ge: <RoundGeorgia />,
  de: <RoundGermany />,
  gh: <RoundGhana />,
  gi: <RoundGibraltar />,
  gr: <RoundGreece />,
  gl: <RoundGreenland />,
  gd: <RoundGrenada />,
  gp: <RoundMissingCountry />,
  gu: <RoundGuam />,
  gt: <RoundGuatemala />,
  gg: <RoundGuernsey />,
  gn: <RoundGuinea />,
  gw: <RoundGuineaBissau />,
  gy: <RoundGuyana />,
  ht: <RoundHaiti />,
  hm: <RoundMissingCountry />,
  va: <RoundVaticanCity />,
  hn: <RoundHonduras />,
  hk: <RoundHongKong />,
  hu: <RoundHungary />,
  is: <RoundIceland />,
  in: <RoundIndia />,
  id: <RoundIndonesia />,
  ir: <RoundIran />,
  iq: <RoundIraq />,
  ie: <RoundIreland />,
  im: <RoundIsleOfMan />,
  il: <RoundIsrael />,
  it: <RoundItaly />,
  jm: <RoundJamaica />,
  jp: <RoundJapan />,
  je: <RoundJersey />,
  jo: <RoundJordan />,
  kz: <RoundKazakhstan />,
  ke: <RoundKenya />,
  ki: <RoundKiribati />,
  kp: <RoundNorthKorea />,
  kr: <RoundSouthKorea />,
  kw: <RoundKuwait />,
  kg: <RoundKyrgyzstan />,
  la: <RoundLaos />,
  lv: <RoundLatvia />,
  lb: <RoundLebanon />,
  ls: <RoundLesotho />,
  lr: <RoundLiberia />,
  ly: <RoundLibya />,
  li: <RoundLiechtenstein />,
  lt: <RoundLithuania />,
  lu: <RoundLuxembourg />,
  mo: <RoundMacao />,
  mg: <RoundMadagascar />,
  mw: <RoundMalawi />,
  my: <RoundMalaysia />,
  mv: <RoundMaldives />,
  ml: <RoundMali />,
  mt: <RoundMalta />,
  mh: <RoundMarshallIsland />,
  mq: <RoundMartinique />,
  mr: <RoundMauritania />,
  mu: <RoundMauritius />,
  yt: <RoundMissingCountry />,
  mx: <RoundMexico />,
  fm: <RoundMicronesia />,
  md: <RoundMoldova />,
  mc: <RoundMonaco />,
  mn: <RoundMongolia />,
  me: <RoundMontenegro />,
  ms: <RoundMontserrat />,
  ma: <RoundMorocco />,
  mz: <RoundMozambique />,
  mm: <RoundMyanmar />,
  na: <RoundNamibia />,
  nr: <RoundNauru />,
  np: <RoundNepal />,
  nl: <RoundNetherlands />,
  nc: <RoundMissingCountry />,
  nz: <RoundNewZealand />,
  ni: <RoundNicaragua />,
  ne: <RoundNiger />,
  ng: <RoundNigeria />,
  nu: <RoundNiue />,
  nf: <RoundNorfolkIsland />,
  mk: <RoundMissingCountry />,
  mp: <RoundNorthernMarianasIslands />,
  no: <RoundNorway />,
  om: <RoundOman />,
  pk: <RoundPakistan />,
  pw: <RoundPalau />,
  ps: <RoundPalestine />,
  pa: <RoundPanama />,
  pg: <RoundPapuaNewGuinea />,
  py: <RoundParaguay />,
  pe: <RoundPeru />,
  ph: <RoundPhilippines />,
  pn: <RoundPitcairnIslands />,
  pl: <RoundPoland />,
  pt: <RoundPortugal />,
  pr: <RoundPuertoRico />,
  qa: <RoundQatar />,
  re: <RoundMissingCountry />,
  ro: <RoundRomania />,
  ru: <RoundRussia />,
  rw: <RoundRwanda />,
  bl: <RoundStBarts />,
  sh: <RoundMissingCountry />,
  kn: <RoundMissingCountry />,
  lc: <RoundStLucia />,
  mf: <RoundMissingCountry />,
  pm: <RoundMissingCountry />,
  vc: <RoundStVincentAndTheGrenadines />,
  ws: <RoundSamoa />,
  sm: <RoundSanMarino />,
  st: <RoundSaoTomeAndPrince />,
  sa: <RoundSaudiArabia />,
  sn: <RoundSenegal />,
  rs: <RoundSerbia />,
  sc: <RoundSeychelles />,
  sl: <RoundSierraLeone />,
  sg: <RoundSingapore />,
  sx: <RoundSintMaarten />,
  sk: <RoundSlovakia />,
  si: <RoundSlovenia />,
  sb: <RoundSolomonIslands />,
  so: <RoundSomalia />,
  za: <RoundSouthAfrica />,
  gs: <RoundMissingCountry />,
  ss: <RoundSouthSudan />,
  es: <RoundSpain />,
  lk: <RoundSriLanka />,
  sd: <RoundSudan />,
  sr: <RoundSuriname />,
  sj: <RoundMissingCountry />,
  se: <RoundSweden />,
  ch: <RoundSwitzerland />,
  sy: <RoundSyria />,
  tw: <RoundTaiwan />,
  tj: <RoundTajikistan />,
  tz: <RoundTanzania />,
  th: <RoundThailand />,
  tl: <RoundEastTimor />,
  tg: <RoundTogo />,
  tk: <RoundTokelau />,
  to: <RoundTonga />,
  tt: <RoundTrinidadAndTobago />,
  tn: <RoundTunisia />,
  tr: <RoundTurkey />,
  tm: <RoundTurkmenistan />,
  tc: <RoundTurksAndCaicos />,
  tv: <RoundTuvalu />,
  ug: <RoundUganda />,
  ua: <RoundUkraine />,
  ae: <RoundUnitedArabEmirates />,
  gb: <RoundUnitedKingdom />,
  um: <RoundMissingCountry />,
  us: <RoundUnitedStates />,
  uy: <RoundUruguay />,
  uz: <RoundUzbekistan />,
  vu: <RoundVanuatu />,
  ve: <RoundVenezuela />,
  vn: <RoundVietnam />,
  vg: <RoundBritishVirginIslands />,
  vi: <RoundVirginIslands />,
  wf: <RoundMissingCountry />,
  eh: <RoundSahrawiArabDemocraticRepublic />,
  ye: <RoundYemen />,
  zm: <RoundZambia />,
  zw: <RoundZimbabwe />,
  zz: <RoundMissingCountry />
};
