const RoundAmericanSamoa = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#1A47B8"
          d="M23.7623 2.007C21.4633.729 18.8169 0 16 0 7.16413 0 .001125 7.16238 0 15.9981l11.1304-5.5633L23.7623 2.007ZM0 16.0018C.001125 24.8376 7.16413 32 16 32c2.8169 0 5.4633-.7289 7.7623-2.007l-12.6319-8.4279L0 16.0018Z"
        />
        <path
          fill="#F93939"
          d="M25.0956 2.83633c-.4297-.29744-.875-.57407-1.3339-.82913L0 15.9981v.0037l23.7617 13.9908c.4589-.2551.9042-.5316 1.334-.8291L2.73887 16 25.0956 2.83633Z"
        />
        <path
          fill="#A2001D"
          d="M27.8261 15.4033h-1.9059c.4955-.595.465-1.4802-.0933-2.0384.5913-.5913.5913-1.5501 0-2.1416l-.0357.0358c.5914-.5913.627-1.58588.0357-2.17719l-8.566 8.56599c.5913.5914 1.5333.5844 2.1247-.007l.1648-.1507 4.1018-.3729v1.7642h1.3913v-1.8907l2.0869-.1896.6957-1.3979Z"
        />
        <path fill="#FFDA2C" d="M17.3918 19.4782 16 18.7826l1.3918-.6957h9.0434v1.3913h-9.0434Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAmericanSamoa;
