const RoundSahrawiArabDemocraticRepublic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M1.58763 9.04341C0.5705 11.1469 0 13.5067 0 15.9999C0 18.4931 0.5705 20.8529 1.58763 22.9564L16 24.3477L30.4124 22.9564C31.4295 20.8529 32 18.4931 32 15.9999C32 13.5067 31.4295 11.1469 30.4124 9.04341L16 7.6521L1.58763 9.04341Z"
        />
        <path
          fill="#496E2D"
          d="M1.58789 22.9565C4.17577 28.3085 9.65639 32 15.9997 32C22.343 32 27.8236 28.3085 30.4115 22.9565H1.58789Z"
        />
        <path
          fill="black"
          d="M1.58789 9.0435H30.4115C27.8236 3.69156 22.3431 0 15.9997 0C9.65633 0 4.17577 3.69156 1.58789 9.0435Z"
        />
        <path
          fill="#F93939"
          d="M4.68628 4.68628C-1.56209 10.9347-1.56209 21.0653 4.68628 27.3138 7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628ZM20.1739 15.9999C20.1739 13.6688 21.8122 11.7214 24 11.2436 23.6637 11.1702 23.3149 11.1304 22.9565 11.1304 20.267 11.1304 18.0869 13.3105 18.0869 15.9999 18.0869 18.6894 20.267 20.8695 22.9565 20.8695 23.3148 20.8695 23.6637 20.8297 24 20.7562 21.8122 20.2785 20.1739 18.3311 20.1739 15.9999Z"
        />
        <path
          fill="#F93939"
          d="M24.205 12.8696L24.8959 14.9955H27.1309L25.3227 16.3091L26.0134 18.4349L24.205 17.1211L22.3969 18.4349L23.0877 16.3091L21.2793 14.9955H23.5144L24.205 12.8696Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSahrawiArabDemocraticRepublic;
