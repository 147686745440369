import { IconWarning } from '../../../assets/DesignSystem/SVGComponents';
import { BordButton } from '../../BordDesignSystem';

import BordModal from '../../BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../BordDesignSystem/BordModal/components/ModalContent';
import './NudosConfirmActionModal.scss';

const NudosConfirmActionModal = ({
  secondButtonAction,
  firstButtonAction,
  firstButtonText,
  secondButtonText,
  modalText
}: {
  firstButtonAction: () => void;
  secondButtonAction: () => void;
  firstButtonText: string;
  secondButtonText: string;
  modalText?: string;
}) => {
  const ModalData = () => {
    return (
      <div className="nudosConfirmActionModal">
        <div className="iconContainer">
          <IconWarning className="iconWarning" />
        </div>
        <div className="modalText">{modalText}</div>
        <div className="buttonsContainer">
          <BordButton
            label={firstButtonText}
            onClick={firstButtonAction}
            customClassName="nudosButton"
            modeButton="secondary"
          />
          <BordButton label={secondButtonText} onClick={secondButtonAction} customClassName="nudosButton" />
        </div>
      </div>
    );
  };
  return (
    <div>
      <BordModal
        modalContent={
          <ModalContent
            customWidth="466px"
            customHeight="220px"
            excludeHeader={true}
            modalSize="md"
            CustomModalComponent={<ModalData />}
          />
        }
      />
    </div>
  );
};

export default NudosConfirmActionModal;
