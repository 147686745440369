import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Iemployee, IaddressFromGoogleMaps } from '../../../types/global';
import { NudosAddressSearchBar, NudosHoverText } from '../../NudosComponents';
import { BordOneToneIcon, BordPhoto, BordTextInput } from '../../BordDesignSystem';
import { BordButton } from '../../BordDesignSystem';
import { getAddressErrorText, getIdDocumentData, isPhoneValid } from '../../../utils/formValidations';
import { ICountryDetail } from '../../../types/countries';
import { formatZipCode, getIsInvalidZipCode, invalidZipCodeText, supportUrl } from '../../../utils/productDefinitions';
import { DTOupdateEmployeeData } from '../../../types/DTO';
import { IshipmentGroupDetails } from '../../../types/orders';
import { NudosCityDropdown } from '../../DesignSystem';
import { IcityDetails } from '../../../types/cities.types';
import { segmentTrackEvent } from '../../../utils/segment';
import BordPhoneInput from '../../BordDesignSystem/BordPhoneInput/BordPhoneInput';
import { TcountryCode } from '../../BordDesignSystem/BordFlag/BordFlag.types';
import { truncateText } from '../../../utils/truncateText';
import './EmployeeDestinationModalInternalOrder.scss';

/**
 * IMPORTANT: Use this modal as the prop for the CustomModalComponent prop of the NudosModalContent, which in turn must be the modalContent prop of the NudosGenericModal
 * @property {Iemployee} employeeData -The employee whose address is the destination, this is the initial data for the modal
 * @property {string} subtitle - The text to show as a modal subtitle
 * @property {string} buttonText - The text for the modal button
 * @property {(modalData: DTOupdateEmployeeData) => void | Promise<void>} clickButtonCallbac - a function to execute upon clickin the modal button, receives as param the data of the modal
 * @property { boolean } isButtonLoading - A boolean indicating if the modal submit data button is loading
 */

interface IReportsErrors {
  phoneNumber: undefined | string;
  address: undefined | string;
  additionalReferences: undefined | string;
  zipCode: undefined | string;
}

const EmployeeDestinationModalInternalOrder = ({
  employeeData,
  shipmentExistingData,
  buttonText,
  clickButtonCallback,
  isButtonLoading,
  listOfCountries,
  shipmentInfoEditingIsAllowed
}: {
  employeeData?: Iemployee;
  shipmentExistingData?: IshipmentGroupDetails;
  buttonText: string;
  clickButtonCallback: (modalData: DTOupdateEmployeeData) => void | Promise<void>;
  isButtonLoading?: boolean;
  listOfCountries?: ICountryDetail[];
  shipmentInfoEditingIsAllowed: boolean;
}) => {
  const { t } = useTranslation();

  const [updatedPersonalId, setUpdatedPersonalId] = useState<string>();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>();
  const [updatedPhoneCountry, setUpdatedPhoneCountry] = useState<ICountryDetail>();
  const [updatedZipCode, setUpdatedZipCode] = useState<string>();
  const [updatedAddress, setUpdatedAddress] = useState<IaddressFromGoogleMaps>();
  const [updatedAddressAditionalReferences, setUpdatedAddressAditionalReferences] = useState<string>();
  const [editFieldsList, setEditFieldsList] = useState<string[]>([]);
  const [city, setCity] = useState<IcityDetails>();
  const [cityErrorReport, setCityErrorReport] = useState<string | undefined>();
  const [wasEdited, setWasEdited] = useState<boolean>(false);
  const [reportErrors, setReportErrors] = useState<IReportsErrors>({
    phoneNumber: undefined,
    address: undefined,
    additionalReferences: undefined,
    zipCode: undefined
  });

  const { firstName, lastName, photoProfile, country, phoneData, personalId, address } = employeeData || {};
  const { shipments } = shipmentExistingData || {};
  const { reports, statusName } = shipments?.[0] || {}; // CAMBIAR-JC
  const translations = 'nodi:orderDetails:updateOrderBillingDataFlux:employeeDestinationModal:';
  const translationsErrors = 'nodi:orderDetails:updateOrderBillingDataFlux:errorsDestinationModal:';

  const employeeIsFromBrazil = country?.code === 'br';
  const employeeFullName = shipmentExistingData?.receiverName || `${firstName || ''} ${lastName || ''}`;
  const employeeNameMaxLength = employeeIsFromBrazil ? 18 : 40;

  const additionalReferencesErrorText =
    updatedAddress && !updatedAddressAditionalReferences ? t(`${translationsErrors}completeTheInstructions`) : '';

  const idDocumentData = getIdDocumentData(country || undefined, updatedPersonalId);

  const defaultPhoneCountryData = listOfCountries?.find(
    countriesList => Number(countriesList.phoneCode) === Number(phoneData?.phoneCode)
  );

  const updatedCompleteAddress = {
    ...updatedAddress,
    address: updatedAddress?.address || '',
    zipCode: updatedZipCode || '',
    additionalReferences: updatedAddressAditionalReferences || ''
  };

  const cityDetails: IcityDetails = {
    id: employeeData?.cityId,
    name: employeeData?.city,
    country: employeeData?.country
  };

  const getZipCodeErrorText = () => {
    if (!updatedZipCode) return ' ';
    if (getIsInvalidZipCode(updatedZipCode)) return invalidZipCodeText;
  };

  const getInvalidPhoneError = () => {
    if (!updatedPhoneNumber) return ' ';
    if (!isPhoneValid(updatedPhoneNumber))
      return `${t(`${translationsErrors}invalidPhoneCountry`)} ${country?.name || 'este país'}`;
  };

  const defaultAddressErrors = getAddressErrorText(
    country?.code,
    country?.name,
    updatedAddress?.country || undefined,
    true,
    updatedAddress?.coordinates
  );

  const addressError = !updatedAddress?.address ? ' ' : defaultAddressErrors || reportErrors?.address;

  const isButtonDisabled = () => {
    const addressIsIncomplete = Object.entries(updatedCompleteAddress).some(
      ([key, value]) => !['coordinates'].includes(key) && !value
    );
    if (!wasEdited) return true;
    if (!updatedPhoneNumber || !updatedPhoneCountry || !updatedZipCode) return true;
    if (!updatedAddress || !updatedAddressAditionalReferences) return true;
    if (addressIsIncomplete) return true;
    if (idDocumentData?.errorText) return true;
    if (defaultAddressErrors) return true;
    if (!isPhoneValid(updatedPhoneNumber)) return true;
    if (getIsInvalidZipCode(updatedZipCode)) return true;
    if (!updatedPhoneCountry?.id || !employeeData?.country?.id) return true;
    if (!updatedAddressAditionalReferences) return true;
    if (
      reportErrors?.additionalReferences ||
      reportErrors?.address ||
      !!cityErrorReport ||
      reportErrors?.phoneNumber ||
      reportErrors?.zipCode
    ) {
      return true;
    }
    return false;
  };

  const addEditFieldEditList = (field: string) => {
    const searchField = editFieldsList.find(fieldList => field === fieldList);
    if (!searchField) {
      editFieldsList.push(field);
      setEditFieldsList([...editFieldsList]);
    }
  };

  const sendEvents = () => {
    if (shipmentInfoEditingIsAllowed) {
      segmentTrackEvent({
        nodiOrderEditModalAvailableClick: {
          Country: country?.name || '',
          ModalType: 'Employee',
          OrderStatus: statusName || '',
          FieldsEdited: editFieldsList.toString()
        }
      });
    } else {
      segmentTrackEvent({
        nodiOrderEditModalNotAvailableClick: { Country: country?.name || '', ModalType: 'Employee' }
      });
    }
  };

  const handleClickModalButton = () => {
    if (isButtonDisabled() || !updatedPhoneCountry?.id || !employeeData?.country?.id) return;
    sendEvents();
    const modalData = {
      firstName: employeeData?.firstName || undefined,
      lastName: employeeData?.lastName || undefined,
      personalId: updatedPersonalId || undefined,
      phone: updatedPhoneNumber || employeeData?.phoneData?.phone,
      countryId: employeeData?.country?.id,
      city: city?.name || undefined,
      cityId: city?.id || undefined,
      area: employeeData?.area || undefined,
      position: employeeData?.position || undefined,
      address: updatedCompleteAddress || employeeData?.address,
      countryPhoneId: updatedPhoneCountry?.id
    };
    clickButtonCallback(modalData);
  };

  const intialErrors = () => {
    const openReports = reports?.find(report => report?.status === 'OPEN');
    if (reports && reports?.length > 0 && openReports) {
      const initialReportErrors = reports[0]?.wrongAttributes;
      const findAdditionalReferences = initialReportErrors?.find(error => error === 'additionalReferences');
      const findCity = initialReportErrors?.find(error => error === 'city');
      const findPhoneNumber = initialReportErrors?.find(error => error === 'phoneNumber');
      const findAddress = initialReportErrors?.find(error => error === 'address');
      const findZipCode = initialReportErrors?.find(error => error === 'zipCode');
      setReportErrors({
        additionalReferences: findAdditionalReferences ? t(`${translationsErrors}indications`) : undefined,
        address: findAddress ? t(`${translationsErrors}address`) : undefined,
        phoneNumber: findPhoneNumber ? t(`${translationsErrors}phone`) : undefined,
        zipCode: findZipCode ? t(`${translationsErrors}zipCode`) : undefined
      });
      setCityErrorReport(findCity ? t(`${translationsErrors}city`) : undefined);
    }
  };

  const openSupportWindow = () => window.open(supportUrl);

  const cancelLsContainerAction = () => {
    segmentTrackEvent({
      nodiOrderEditModalNotAvailableSupportClick: { Country: country?.name || '', ModalType: 'Employee' }
    });
    openSupportWindow();
  };
  useEffect(() => {
    if (!updatedPhoneNumber) setUpdatedPhoneNumber(phoneData?.phone || undefined);
    if (!updatedZipCode) setUpdatedZipCode(formatZipCode(address?.zipCode) || undefined);
    if (!updatedPersonalId) setUpdatedPersonalId(personalId || undefined);
    if (!city) setCity(cityDetails);
    if (!updatedAddress)
      setUpdatedAddress({
        ...employeeData?.address,
        country: employeeData?.country?.code || employeeData?.address?.country || '',
        city: cityDetails?.name || employeeData?.city || employeeData?.address?.city || ''
      });
    if (!updatedAddressAditionalReferences)
      setUpdatedAddressAditionalReferences(employeeData?.address?.additionalReferences || undefined);
  }, [employeeData]);

  useEffect(() => {
    intialErrors();
  }, [shipmentExistingData]);

  return (
    <div className="employeeDestinationModalInternalOrder">
      <div className="photoAndName">
        <BordPhoto
          url={photoProfile || ''}
          size="s56"
          flagProps={{
            country: country?.code as TcountryCode,
            variant: 'circle',
            standardSize: 24
          }}
          avatarProps={{
            variant: 'rectangularBordMascot',
            customWidth: '5.6rem'
          }}
        />
        <NudosHoverText
          customClassName="name"
          text={employeeFullName}
          charactersLimit={employeeNameMaxLength}
          onlyIfTruncated
        />
      </div>
      <div className="firstLineFields">
        <BordTextInput
          standardSize="w-280"
          label={idDocumentData?.label}
          placeholder={idDocumentData?.placeholder}
          isFilled={!!idDocumentData?.value}
          setCurrentText={e => {
            setUpdatedPersonalId(e);
            setWasEdited(true);
            addEditFieldEditList('EmployeeID');
          }}
          currentText={idDocumentData?.value}
          errorText={idDocumentData?.errorText}
          caption={idDocumentData?.caption}
          disabled={!shipmentInfoEditingIsAllowed}
        />
        <BordPhoneInput
          componentSize="w240"
          label={`${t(`${translations}phone`)}*`}
          placeholder="Ej: 3000000001"
          defaultCountryData={defaultPhoneCountryData}
          defaultPhoneNumber={phoneData?.phone || undefined}
          errorText={getInvalidPhoneError() || reportErrors?.phoneNumber}
          handlePhoneChange={setUpdatedPhoneNumber}
          countriesList={listOfCountries || []}
          handleCountryPhoneChange={setUpdatedPhoneCountry}
          simpleChangeOfValues={() => {
            setReportErrors({ ...reportErrors, ...{ phoneNumber: undefined } });
            setWasEdited(true);
            addEditFieldEditList('EmployeePhone');
          }}
        />
      </div>
      <div className="secondLineFields">
        <div className="textWrap">
          <BordTextInput
            standardSize="w-80"
            label={truncateText(`${t(`${translations}cp`)}*`, 13)}
            placeholder="CP"
            isFilled={!!updatedZipCode}
            setCurrentText={e => {
              setUpdatedZipCode(e);
              setReportErrors({ ...reportErrors, ...{ zipCode: undefined } });
              setWasEdited(true);
            }}
            handleBlur={newZipCode => setUpdatedZipCode(formatZipCode(newZipCode))}
            currentText={updatedZipCode || undefined}
            errorText={getZipCodeErrorText() || reportErrors?.zipCode}
            disabled={!shipmentInfoEditingIsAllowed}
          />
        </div>
        <NudosCityDropdown
          countryId={country?.id}
          countryCode={country?.code}
          currentValue={city}
          componentSize="w-180"
          isDeactivated={!shipmentInfoEditingIsAllowed}
          updateCurrentValueCallback={e => {
            setCity(e);
            setCityErrorReport(undefined);
            setWasEdited(true);
          }}
          errorText={cityErrorReport}
        />
        <BordTextInput
          style={{
            width: '100%'
          }}
          label={`${t(`${translations}indications`)} (opcional)`}
          errorText={additionalReferencesErrorText || reportErrors?.additionalReferences}
          placeholder="Ej: Torre, Apto, Casa"
          isFilled={!!updatedAddressAditionalReferences}
          setCurrentText={e => {
            setUpdatedAddressAditionalReferences(e);
            setReportErrors({ ...reportErrors, ...{ additionalReferences: undefined } });
            setWasEdited(true);
            addEditFieldEditList('Directions');
          }}
          currentText={updatedAddressAditionalReferences || undefined}
          disabled={!shipmentInfoEditingIsAllowed}
        />
      </div>
      <div className="thirdLineFields">
        <NudosAddressSearchBar
          componentSize="w-full"
          label={`${t(`${translations}address`)}*`}
          regionBias={country?.code}
          handleAddressSelection={e => {
            setUpdatedAddress(e);
            setReportErrors({ ...reportErrors, ...{ address: undefined } });
            setWasEdited(true);
            addEditFieldEditList('Address');
          }}
          errorText={addressError}
          defaultValueAddressName={address?.address || undefined}
          didntFindAddressTooltipTopPosition={6.8}
          didntFindAddressTooltipRightPosition={0}
          isDisabled={!shipmentInfoEditingIsAllowed}
        />
      </div>
      <div className="alert">
        <BordOneToneIcon variant="alertTriangleWarning" standardSize={12} />
        {t(`${translations}yellowAlert`)}
      </div>
      {!shipmentInfoEditingIsAllowed && (
        <div className="cancelLsContainer">
          <BordOneToneIcon variant="info" standardSize={14} />
          <div className="cancelLsTextContainer">
            <div>{t(`${translationsErrors}orderInDeliveryProcess`)} </div>
            <div
              className="cancelLsPrimaryDefaultText"
              onClick={() => {
                cancelLsContainerAction();
              }}
            >
              {t(`${translationsErrors}contactSupport`)}
            </div>
          </div>
        </div>
      )}
      <div className="destinationButtonContainer">
        <BordButton
          label={buttonText}
          disabled={isButtonDisabled()}
          onClick={handleClickModalButton}
          isLoading={isButtonLoading}
          customWidth="w-380"
        />
      </div>
    </div>
  );
};

export default EmployeeDestinationModalInternalOrder;
