import { FC } from 'react';
import '../BordBadge/BordBadge.scss';

export interface IBordBadgeProps {
  label?: string;
  variant?: 'default' | 'gradient';
  Jsx?: JSX.Element;
}

enum BADGE_VARIANTS {
  default = 'default',
  gradient = 'gradient'
}

const BordBadge: FC<IBordBadgeProps> = ({ label = 'Label', Jsx, variant = 'default' }) => {
  const renderVariants = {
    [BADGE_VARIANTS.default]: {
      styles: 'defaultStyles',
      component: <span>{Jsx ? Jsx : label}</span>
    },
    [BADGE_VARIANTS.gradient]: {
      styles: 'gradientStyles',
      component: (
        <div className="gradientComponentStyles">
          <span>{Jsx ? Jsx : label}</span>
        </div>
      )
    }
  };
  return <div className={`bordBadge ${renderVariants[variant]?.styles}`}>{renderVariants[variant]?.component}</div>;
};

export default BordBadge;
