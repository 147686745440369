const truncateText = (text?: string, limit?: number) => {
  if (typeof text !== 'string' || typeof limit !== 'number') return '';
  if (text.length > limit) {
    return `${text.slice(0, limit - 2)}...`;
  } else return text;
};

const isTruncated = (text?: string, limit?: number) => {
  if (typeof text !== 'string' || typeof limit !== 'number') return false;
  if (text.length > limit) return true;
  return false;
};

export { truncateText, isTruncated };
