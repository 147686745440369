import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordUserRemoveIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill={bordColorTheme.navyBlue[400]}
        d="m5.18007 15.2964-.50568-.8627.50568.8627Zm9.63983 0 .5057-.8627-.5057.8627ZM2.63348 19.422l-.68407.7294.68407-.7294Zm14.73302 0-.684-.7294.684.7294Zm5.3406-14.21489c.3905-.39053.3905-1.02369 0-1.41422-.3905-.39052-1.0237-.39052-1.4142 0l1.4142 1.41422Zm-6.4142 3.58578c-.3905.39053-.3905 1.02369 0 1.41421.3905.3905 1.0237.3905 1.4142 0l-1.4142-1.41421Zm5 1.41421c.3905.3905 1.0237.3905 1.4142 0 .3905-.39052.3905-1.02368 0-1.41421l-1.4142 1.41421Zm-3.5858-6.41421c-.3905-.39052-1.0237-.39052-1.4142 0-.3905.39053-.3905 1.02369 0 1.41422l1.4142-1.41422ZM5.68574 16.1591c2.63672-1.5455 5.99176-1.5455 8.62856 0l1.0113-1.7254c-3.2613-1.9116-7.38995-1.9116-10.65121 0l1.01135 1.7254ZM6.08077 22h7.83843v-2H6.08077v2Zm-2.76323-3.3074c-.34972-.328-.31769-.4837-.31635-.4958.00847-.0763.07649-.26.37474-.5473.61699-.5945 1.57244-1.0582 2.30981-1.4904l-1.01135-1.7254c-.5202.3049-1.84217.9623-2.68616 1.7755-.43223.4165-.89058 1.0082-.97482 1.7669-.091379.8229.2816 1.5616.936 2.1753l1.36813-1.4588Zm10.99676-2.5335c.7373.4322 1.6928.8959 2.3098 1.4904.2982.2873.3662.471.3747.5473.0013.0121.0334.1678-.3163.4958l1.3681 1.4588c.6544-.6137 1.0274-1.3524.936-2.1753-.0843-.7587-.5426-1.3504-.9748-1.7669-.844-.8132-2.166-1.4706-2.6862-1.7755l-1.0113 1.7254Zm2.3682 2.5335C15.7935 19.5262 14.9347 20 13.9192 20v2c1.7319 0 3.0583-.8422 4.1314-1.8486l-1.3681-1.4588ZM1.94941 20.1514C3.02252 21.1578 4.34887 22 6.08077 22v-2c-1.01544 0-1.87429-.4738-2.76323-1.3074l-1.36813 1.4588ZM13 7c0 1.65685-1.3431 3-3 3v2c2.7614 0 5-2.23858 5-5h-2Zm-3 3c-1.65685 0-3-1.34315-3-3H5c0 2.76142 2.23858 5 5 5v-2ZM7 7c0-1.65685 1.34315-3 3-3V2C7.23858 2 5 4.23858 5 7h2Zm3-3c1.6569 0 3 1.34315 3 3h2c0-2.76142-2.2386-5-5-5v2Zm11.2929-.20711-2.5 2.5 1.4142 1.41422 2.5-2.5-1.4142-1.41422Zm-2.5 2.5-2.5 2.5 1.4142 1.41421 2.5-2.49999-1.4142-1.41422Zm3.9142 2.5-2.5-2.5-1.4142 1.41422 2.5 2.49999 1.4142-1.41421Zm-2.5-2.5-2.5-2.5-1.4142 1.41422 2.5 2.5 1.4142-1.41422Z"
      />
    </svg>
  );
};

export default BordUserRemoveIcon;
