import { COUNTRY_CODES } from '../BordFlag.types';
import SquareAfghanistan from './SquareAfghanistan';
import SquareAlbania from './SquareAlbania';
import SquareAlgeria from './SquareAlgeria';
import SquareAndorra from './SquareAndorra';
import SquareAngola from './SquareAngola';
import SquareAntiguaAndBarbuda from './SquareAntiguaAndBarbuda';
import SquareArgentina from './SquareArgentina';
import SquareArmenia from './SquareArmenia';
import SquareAruba from './SquareAruba';
import SquareAustralia from './SquareAustralia';
import SquareAustria from './SquareAustria';
import SquareAzerbaijan from './SquareAzerbaijan';
import SquareBahamas from './SquareBahamas';
import SquareBahrain from './SquareBahrain';
import SquareBangladesh from './SquareBangladesh';
import SquareBarbados from './SquareBarbados';
import SquareBelarus from './SquareBelarus';
import SquareBelgium from './SquareBelgium';
import SquareBelize from './SquareBelize';
import SquareBenin from './SquareBenin';
import SquareBhutan from './SquareBhutan';
import SquareBolivia from './SquareBolivia';
import SquareBosniaAndHerzegovina from './SquareBosniaAndHerzegovina';
import SquareBotswana from './SquareBotswana';
import SquareBouvetIsland from './SquareBouvetIsland';
import SquareBrazil from './SquareBrazil';
import SquareBruneiDarussalam from './SquareBruneiDarussalam';
import SquareBulgaria from './SquareBulgaria';
import SquareBurkinaFaso from './SquareBurkinaFaso';
import SquareBurundi from './SquareBurundi';
import SquareCambodia from './SquareCambodia';
import SquareCameroon from './SquareCameroon';
import SquareCanada from './SquareCanada';
import SquareCapeVerde from './SquareCapeVerde';
import SquareCentralAfricanRepublic from './SquareCentralAfricanRepublic';
import SquareChad from './SquareChad';
import SquareChile from './SquareChile';
import SquareChina from './SquareChina';
import SquareColombia from './SquareColombia';
import SquareComoros from './SquareComoros';
import SquareCongo from './SquareCongo';
import SquareCostaRica from './SquareCostaRica';
import SquareCroatia from './SquareCroatia';
import SquareCuba from './SquareCuba';
import SquareCuracao from './SquareCuracao';
import SquareCyprus from './SquareCyprus';
import SquareCzechRepublic from './SquareCzechRepublic';
import SquareDenmark from './SquareDenmark';
import SquareDjibouti from './SquareDjibouti';
import SquareDominica from './SquareDominica';
import SquareDrCongo from './SquareDrCongo';
import SquareEcuador from './SquareEcuador';
import SquareEgypt from './SquareEgypt';
import SquareElSalvador from './SquareElSalvador';
import SquareEquatorialGuinea from './SquareEquatorialGuinea';
import SquareEritrea from './SquareEritrea';
import SquareEstonia from './SquareEstonia';
import SquareEswatini from './SquareEswatini';
import SquareEthiopia from './SquareEthiopia';
import SquareEuropeanUnion from './SquareEuropeanUnion';
import SquareFiji from './SquareFiji';
import SquareFinland from './SquareFinland';
import SquareFrance from './SquareFrance';
import SquareGabon from './SquareGabon';
import SquareGambia from './SquareGambia';
import SquareGeorgia from './SquareGeorgia';
import SquareGermany from './SquareGermany';
import SquareGhana from './SquareGhana';
import SquareGreece from './SquareGreece';
import SquareGrenada from './SquareGrenada';
import SquareGuatemala from './SquareGuatemala';
import SquareGuinea from './SquareGuinea';
import SquareGuineaBissau from './SquareGuineaBissau';
import SquareGuyana from './SquareGuyana';
import SquareHaiti from './SquareHaiti';
import SquareHonduras from './SquareHonduras';
import SquareHongKong from './SquareHongKong';
import SquareHungary from './SquareHungary';
import SquareIceland from './SquareIceland';
import SquareIndia from './SquareIndia';
import SquareIndonesia from './SquareIndonesia';
import SquareIran from './SquareIran';
import SquareIraq from './SquareIraq';
import SquareIreland from './SquareIreland';
import SquareIsrael from './SquareIsrael';
import SquareItaly from './SquareItaly';
import SquareIvoryCoast from './SquareIvoryCoast';
import SquareJamaica from './SquareJamaica';
import SquareJapan from './SquareJapan';
import SquareJordan from './SquareJordan';
import SquareKazakhstan from './SquareKazakhstan';
import SquareKenya from './SquareKenya';
import SquareKiribati from './SquareKiribati';
import SquareKuwait from './SquareKuwait';
import SquareKyrgyzstan from './SquareKyrgyzstan';
import SquareLaos from './SquareLaos';
import SquareLatvia from './SquareLatvia';
import SquareLebanon from './SquareLebanon';
import SquareLesotho from './SquareLesotho';
import SquareLiberia from './SquareLiberia';
import SquareLibya from './SquareLibya';
import SquareLiechtenstein from './SquareLiechtenstein';
import SquareLithuania from './SquareLithuania';
import SquareLuxembourg from './SquareLuxembourg';
import SquareMadagascar from './SquareMadagascar';
import SquareMalawi from './SquareMalawi';
import SquareMalaysia from './SquareMalaysia';
import SquareMaldives from './SquareMaldives';
import SquareMali from './SquareMali';
import SquareMalta from './SquareMalta';
import SquareMarshallIslands from './SquareMarshallIslands';
import SquareMauritania from './SquareMauritania';
import SquareMauritius from './SquareMauritius';
import SquareMexico from './SquareMexico';
import SquareMicronesia from './SquareMicronesia';
import SquareMissingCountry from './SquareMissingCountry';
import SquareMoldova from './SquareMoldova';
import SquareMonaco from './SquareMonaco';
import SquareMongolia from './SquareMongolia';
import SquareMontenegro from './SquareMontenegro';
import SquareMorocco from './SquareMorocco';
import SquareMozambique from './SquareMozambique';
import SquareMyanmar from './SquareMyanmar';
import SquareNamibia from './SquareNamibia';
import SquareNauru from './SquareNauru';
import SquareNepal from './SquareNepal';
import SquareNetherlands from './SquareNetherlands';
import SquareNewZealand from './SquareNewZealand';
import SquareNicaragua from './SquareNicaragua';
import SquareNiger from './SquareNiger';
import SquareNigeria from './SquareNigeria';
import SquareNorthKorea from './SquareNorthKorea';
import SquareNorway from './SquareNorway';
import SquareOman from './SquareOman';
import SquarePakistan from './SquarePakistan';
import SquarePalau from './SquarePalau';
import SquarePalestine from './SquarePalestine';
import SquarePanama from './SquarePanama';
import SquarePapuaNewGuinea from './SquarePapuaNewGuinea';
import SquareParaguay from './SquareParaguay';
import SquarePeru from './SquarePeru';
import SquarePhilippines from './SquarePhilippines';
import SquarePoland from './SquarePoland';
import SquarePortugal from './SquarePortugal';
import SquareQatar from './SquareQatar';
import SquareRomania from './SquareRomania';
import SquareRussianFederation from './SquareRussianFederation';
import SquareRwanda from './SquareRwanda';
import SquareSaintLucia from './SquareSaintLucia';
import SquareSaintVicentAndTheGrenadines from './SquareSaintVicentAndTheGrenadines';
import SquareSamoa from './SquareSamoa';
import SquareSanMarino from './SquareSanMarino';
import SquareSaoTomeAndPrincipe from './SquareSaoTomeAndPrincipe';
import SquareSaudiArabia from './SquareSaudiArabia';
import SquareSenegal from './SquareSenegal';
import SquareSerbia from './SquareSerbia';
import SquareSeychelles from './SquareSeychelles';
import SquareSierraLeone from './SquareSierraLeone';
import SquareSingapore from './SquareSingapore';
import SquareSintMaarten from './SquareSintMaarten';
import SquareSlovakia from './SquareSlovakia';
import SquareSlovenia from './SquareSlovenia';
import SquareSolomonIslands from './SquareSolomonIslands';
import SquareSomalia from './SquareSomalia';
import SquareSouthAfrica from './SquareSouthAfrica';
import SquareSouthSudan from './SquareSouthSudan';
import SquareSpain from './SquareSpain';
import SquareSriLanka from './SquareSriLanka';
import SquareSudan from './SquareSudan';
import SquareSuriname from './SquareSuriname';
import SquareSweden from './SquareSweden';
import SquareSwitzerland from './SquareSwitzerland';
import SquareSyrianArabRepublic from './SquareSyrianArabRepublic';
import SquareTajikistan from './SquareTajikistan';
import SquareTanzania from './SquareTanzania';
import SquareThailand from './SquareThailand';
import SquareTimorLeste from './SquareTimorLeste';
import SquareTogo from './SquareTogo';
import SquareTonga from './SquareTonga';
import SquareTrinidadAndTobago from './SquareTrinidadAndTobago';
import SquareTunisia from './SquareTunisia';
import SquareTurkey from './SquareTurkey';
import SquareTurkmenistan from './SquareTurkmenistan';
import SquareTuvalu from './SquareTuvalu';
import SquareUganda from './SquareUganda';
import SquareUkraine from './SquareUkraine';
import SquareUnitedArabEmirates from './SquareUnitedArabEmirates';
import SquareUnitedKingdom from './SquareUnitedKingdom';
import SquareUnitedStatesOfAmerica from './SquareUnitedStatesOfAmerica';
import SquareUruguay from './SquareUruguay';
import SquareUzbekistan from './SquareUzbekistan';
import SquareVanuatu from './SquareVanuatu';
import SquareVaticanCity from './SquareVaticanCity';
import SquareVenezuela from './SquareVenezuela';
import SquareVietNam from './SquareVietNam';
import SquareVirginIslands from './SquareVirginIslands';
import SquareYemen from './SquareYemen';
import SquareZambia from './SquareZambia';
import SquareZimbabwe from './SquareZimbabwe';

export const squareFlags: { [key in COUNTRY_CODES]: JSX.Element } = {
  af: <SquareAfghanistan />,
  ax: <SquareMissingCountry />,
  al: <SquareAlbania />,
  dz: <SquareAlgeria />,
  as: <SquareMissingCountry />,
  ad: <SquareAndorra />,
  ao: <SquareAngola />,
  ai: <SquareMissingCountry />,
  aq: <SquareMissingCountry />,
  ag: <SquareAntiguaAndBarbuda />,
  ar: <SquareArgentina />,
  am: <SquareArmenia />,
  aw: <SquareAruba />,
  au: <SquareAustralia />,
  at: <SquareAustria />,
  az: <SquareAzerbaijan />,
  bs: <SquareBahamas />,
  bh: <SquareBahrain />,
  bd: <SquareBangladesh />,
  bb: <SquareBarbados />,
  by: <SquareBelarus />,
  be: <SquareBelgium />,
  bz: <SquareBelize />,
  bj: <SquareBenin />,
  bm: <SquareMissingCountry />,
  bt: <SquareBhutan />,
  bo: <SquareBolivia />,
  bq: <SquareMissingCountry />,
  ba: <SquareBosniaAndHerzegovina />,
  bw: <SquareBotswana />,
  bv: <SquareBouvetIsland />,
  br: <SquareBrazil />,
  io: <SquareMissingCountry />,
  bn: <SquareBruneiDarussalam />,
  bg: <SquareBulgaria />,
  bf: <SquareBurkinaFaso />,
  bi: <SquareBurundi />,
  cv: <SquareCapeVerde />,
  kh: <SquareCambodia />,
  cm: <SquareCameroon />,
  ca: <SquareCanada />,
  ky: <SquareMissingCountry />,
  cf: <SquareCentralAfricanRepublic />,
  td: <SquareChad />,
  cl: <SquareChile />,
  cn: <SquareChina />,
  cx: <SquareMissingCountry />,
  cc: <SquareMissingCountry />,
  co: <SquareColombia />,
  km: <SquareComoros />,
  cd: <SquareDrCongo />,
  cg: <SquareCongo />,
  ck: <SquareMissingCountry />,
  cr: <SquareCostaRica />,
  ci: <SquareIvoryCoast />,
  hr: <SquareCroatia />,
  cu: <SquareCuba />,
  cw: <SquareCuracao />,
  cy: <SquareCyprus />,
  cz: <SquareCzechRepublic />,
  dk: <SquareDenmark />,
  dj: <SquareDjibouti />,
  dm: <SquareDominica />,
  do: <SquareMissingCountry />,
  ec: <SquareEcuador />,
  eg: <SquareEgypt />,
  sv: <SquareElSalvador />,
  gq: <SquareEquatorialGuinea />,
  er: <SquareEritrea />,
  ee: <SquareEstonia />,
  eu: <SquareEuropeanUnion />,
  sz: <SquareEswatini />,
  et: <SquareEthiopia />,
  fk: <SquareMissingCountry />,
  fo: <SquareMissingCountry />,
  fj: <SquareFiji />,
  fi: <SquareFinland />,
  fr: <SquareFrance />,
  gf: <SquareMissingCountry />,
  pf: <SquareMissingCountry />,
  tf: <SquareMissingCountry />,
  ga: <SquareGabon />,
  gm: <SquareGambia />,
  ge: <SquareGeorgia />,
  de: <SquareGermany />,
  gh: <SquareGhana />,
  gi: <SquareMissingCountry />,
  gr: <SquareGreece />,
  gl: <SquareMissingCountry />,
  gd: <SquareGrenada />,
  gp: <SquareMissingCountry />,
  gu: <SquareMissingCountry />,
  gt: <SquareGuatemala />,
  gg: <SquareMissingCountry />,
  gn: <SquareGuinea />,
  gw: <SquareGuineaBissau />,
  gy: <SquareGuyana />,
  ht: <SquareHaiti />,
  hm: <SquareMissingCountry />,
  va: <SquareVaticanCity />,
  hn: <SquareHonduras />,
  hk: <SquareHongKong />,
  hu: <SquareHungary />,
  is: <SquareIceland />,
  in: <SquareIndia />,
  id: <SquareIndonesia />,
  ir: <SquareIran />,
  iq: <SquareIraq />,
  ie: <SquareIreland />,
  im: <SquareMissingCountry />,
  il: <SquareIsrael />,
  it: <SquareItaly />,
  jm: <SquareJamaica />,
  jp: <SquareJapan />,
  je: <SquareMissingCountry />,
  jo: <SquareJordan />,
  kz: <SquareKazakhstan />,
  ke: <SquareKenya />,
  ki: <SquareKiribati />,
  kp: <SquareNorthKorea />,
  kr: <SquareMissingCountry />,
  kw: <SquareKuwait />,
  kg: <SquareKyrgyzstan />,
  la: <SquareLaos />,
  lv: <SquareLatvia />,
  lb: <SquareLebanon />,
  ls: <SquareLesotho />,
  lr: <SquareLiberia />,
  ly: <SquareLibya />,
  li: <SquareLiechtenstein />,
  lt: <SquareLithuania />,
  lu: <SquareLuxembourg />,
  mo: <SquareMissingCountry />,
  mg: <SquareMadagascar />,
  mw: <SquareMalawi />,
  my: <SquareMalaysia />,
  mv: <SquareMaldives />,
  ml: <SquareMali />,
  mt: <SquareMalta />,
  mh: <SquareMarshallIslands />,
  mq: <SquareMissingCountry />,
  mr: <SquareMauritania />,
  mu: <SquareMauritius />,
  yt: <SquareMissingCountry />,
  mx: <SquareMexico />,
  fm: <SquareMicronesia />,
  md: <SquareMoldova />,
  mc: <SquareMonaco />,
  mn: <SquareMongolia />,
  me: <SquareMontenegro />,
  ms: <SquareMissingCountry />,
  ma: <SquareMorocco />,
  mz: <SquareMozambique />,
  mm: <SquareMyanmar />,
  na: <SquareNamibia />,
  nr: <SquareNauru />,
  np: <SquareNepal />,
  nl: <SquareNetherlands />,
  nc: <SquareMissingCountry />,
  nz: <SquareNewZealand />,
  ni: <SquareNicaragua />,
  ne: <SquareNiger />,
  ng: <SquareNigeria />,
  nu: <SquareMissingCountry />,
  nf: <SquareMissingCountry />,
  mk: <SquareMissingCountry />,
  mp: <SquareMissingCountry />,
  no: <SquareNorway />,
  om: <SquareOman />,
  pk: <SquarePakistan />,
  pw: <SquarePalau />,
  ps: <SquarePalestine />,
  pa: <SquarePanama />,
  pg: <SquarePapuaNewGuinea />,
  py: <SquareParaguay />,
  pe: <SquarePeru />,
  ph: <SquarePhilippines />,
  pn: <SquareMissingCountry />,
  pl: <SquarePoland />,
  pt: <SquarePortugal />,
  pr: <SquareMissingCountry />,
  qa: <SquareQatar />,
  re: <SquareMissingCountry />,
  ro: <SquareRomania />,
  ru: <SquareRussianFederation />,
  rw: <SquareRwanda />,
  bl: <SquareMissingCountry />,
  sh: <SquareMissingCountry />,
  kn: <SquareMissingCountry />,
  lc: <SquareSaintLucia />,
  mf: <SquareMissingCountry />,
  pm: <SquareMissingCountry />,
  vc: <SquareSaintVicentAndTheGrenadines />,
  ws: <SquareSamoa />,
  sm: <SquareSanMarino />,
  st: <SquareSaoTomeAndPrincipe />,
  sa: <SquareSaudiArabia />,
  sn: <SquareSenegal />,
  rs: <SquareSerbia />,
  sc: <SquareSeychelles />,
  sl: <SquareSierraLeone />,
  sg: <SquareSingapore />,
  sx: <SquareSintMaarten />,
  sk: <SquareSlovakia />,
  si: <SquareSlovenia />,
  sb: <SquareSolomonIslands />,
  so: <SquareSomalia />,
  za: <SquareSouthAfrica />,
  gs: <SquareMissingCountry />,
  ss: <SquareSouthSudan />,
  es: <SquareSpain />,
  lk: <SquareSriLanka />,
  sd: <SquareSudan />,
  sr: <SquareSuriname />,
  sj: <SquareMissingCountry />,
  se: <SquareSweden />,
  ch: <SquareSwitzerland />,
  sy: <SquareSyrianArabRepublic />,
  tw: <SquareMissingCountry />,
  tj: <SquareTajikistan />,
  tz: <SquareTanzania />,
  th: <SquareThailand />,
  tl: <SquareTimorLeste />,
  tg: <SquareTogo />,
  tk: <SquareMissingCountry />,
  to: <SquareTonga />,
  tt: <SquareTrinidadAndTobago />,
  tn: <SquareTunisia />,
  tr: <SquareTurkey />,
  tm: <SquareTurkmenistan />,
  tc: <SquareMissingCountry />,
  tv: <SquareTuvalu />,
  ug: <SquareUganda />,
  ua: <SquareUkraine />,
  ae: <SquareUnitedArabEmirates />,
  gb: <SquareUnitedKingdom />,
  um: <SquareMissingCountry />,
  us: <SquareUnitedStatesOfAmerica />,
  uy: <SquareUruguay />,
  uz: <SquareUzbekistan />,
  vu: <SquareVanuatu />,
  ve: <SquareVenezuela />,
  vn: <SquareVietNam />,
  vg: <SquareMissingCountry />,
  vi: <SquareVirginIslands />,
  wf: <SquareMissingCountry />,
  eh: <SquareMissingCountry />,
  ye: <SquareYemen />,
  zm: <SquareZambia />,
  zw: <SquareZimbabwe />,
  zz: <SquareMissingCountry />
};
