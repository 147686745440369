const RoundGuam = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M6.98242 29.2175C9.55017 30.9728 12.6552 32.0001 16.0004 32.0001 19.3455 32.0001 22.4505 30.9728 25.0183 29.2175L16.0004 27.8262 6.98242 29.2175ZM25.0183 2.78262C22.4505 1.02731 19.3455 0 16.0004 0 12.6552 0 9.55017 1.02731 6.98242 2.78262L16.0004 4.17394 25.0183 2.78262Z"
        />
        <path
          fill="#1A47B8"
          d="M32 16.0001C32 10.7057 28.8302 5.38884 25.0179 2.78271H6.98206C2.76687 5.66421 0 10.5088 0 16.0001C0 21.4915 2.76687 26.336 6.98206 29.2175H25.0179C29.2332 26.336 32 21.4915 32 16.0001Z"
        />
        <path
          fill="#F93939"
          d="M15.0947 26.0998C14.8761 25.9123 9.73926 21.4468 9.73926 16C9.73926 10.5531 14.876 6.08759 15.0947 5.90015L16.0001 5.12402L16.9056 5.90009C17.1242 6.08759 22.261 10.5531 22.261 16C22.261 21.4468 17.1243 25.9124 16.9056 26.0998L16.0001 26.8759L15.0947 26.0998Z"
        />
        <path
          fill="#3ECBF8"
          d="M20.87 16C20.87 11.1305 16.0004 6.95654 16.0004 6.95654C16.0004 6.95654 11.1309 11.1306 11.1309 16C11.1309 17.4897 11.5869 18.914 12.2195 20.174H19.7813C20.4139 18.914 20.87 17.4897 20.87 16Z"
        />
        <path
          fill="#1A47B8"
          d="M20.87 16C20.87 17.4897 20.4139 18.914 19.7813 20.1739L16.0004 20.8696L12.2195 20.1739C11.5869 18.9141 11.1309 17.4897 11.1309 16H20.87Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9996 25.0434C15.9996 25.0434 18.3451 23.0323 19.7805 20.1738H12.2188C13.6541 23.0323 15.9996 25.0434 15.9996 25.0434Z"
        />
        <path fill="#6DA544" d="M12.8691 13.2173H19.13L15.9996 16.3477L12.8691 13.2173Z" />
        <path fill="#A2001D" d="M14.9561 15.3044H17.043V21.5653H14.9561V15.3044Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGuam;
