import create from 'zustand';
import { IUploadEmployeesResponse } from '../types/employes';

export interface iState {
  uploadedEmployeesData: IUploadEmployeesResponse | undefined;
  setUploadedEmployeesData: (body: IUploadEmployeesResponse | undefined) => void;
}

const useEmployeesUploadingState = create<iState>(set => ({
  uploadedEmployeesData: undefined,
  setUploadedEmployeesData: uploadedEmployeesData => set({ uploadedEmployeesData })
}));

export default useEmployeesUploadingState;
