import { FC, useState } from 'react';
import {
  CareAndPlatinumSavingsInformation,
  LogisticServiceToolsQuoteData,
  LogisticServiceQuoteTotals
} from '../../LogisticsQuoteAndBillingStep/LogisticServicePricingQuote/Components';
import { ILogisticQuoteGeneralData } from '../../../../types/logisticsServicesModule.types';
import { formatCost } from '../../../../utils/formatNumbers';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import { BordOneToneIcon } from '../../../../components/BordDesignSystem';
import './TotalsDropdown.scss';

interface ITotalsDropDown {
  quoteData: ILogisticQuoteGeneralData;
  originPlace?: TstoragePlace;
  destinationPlace?: TstoragePlace;
}

const TotalsDropdown: FC<ITotalsDropDown> = ({ quoteData, originPlace, destinationPlace }: ITotalsDropDown) => {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);

  const currencyToShow = quoteData?.currency === 'ARS' ? 'USD' : quoteData?.currency;

  return (
    <div id="totalsDropdown">
      <div className="boxPrimaryContainer" onClick={() => setOpenDropDown(!openDropDown)}>
        <div className="primaryContainer">
          <div className="blackTitle">Total a pagar </div>
          <div className="orangeTitle">
            &nbsp;
            {`$${formatCost(Number(quoteData.total || 0))} ${currencyToShow}`}
          </div>
          <BordOneToneIcon
            className="arrowSize"
            standardSize={14}
            variant={openDropDown ? 'arrowHeadUp' : 'arrowHeadDown'}
          />
        </div>
      </div>
      {openDropDown && (
        <div className="boxSecondaryContainer">
          <div className="secondaryContainer">
            <div className="containerToolsDetails">
              <LogisticServiceToolsQuoteData
                logisticServiceQuoteData={quoteData}
                originPlace={originPlace}
                destinationPlace={destinationPlace}
              />
            </div>
            <LogisticServiceQuoteTotals logisticServiceQuoteData={quoteData} />
            <CareAndPlatinumSavingsInformation logisticServiceQuoteData={quoteData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalsDropdown;
