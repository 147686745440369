import { useTranslation } from 'react-i18next';
import { IassignedTool } from '../../../../../../types/global';
import AssignedToolCard from '../AssignedToolCard';
import './ToolsInTransit.scss';

const ToolsInTransit = ({
  toolsInTransit,
  updateFileCallback,
  loaderFileCardId
}: {
  toolsInTransit?: IassignedTool[] | null;
  updateFileCallback?: (assignmentId: number, file: File) => void;
  loaderFileCardId?: number;
}) => {
  const { t } = useTranslation();
  const translationKey = 'nodi:employeeInternal:';

  if (!toolsInTransit || toolsInTransit.length <= 0) return <></>;
  return (
    <div className="ToolsInTransit">
      <div className="toolsInTransitTitle">{t(`${translationKey}employeeDetailsBody:inTransitEquipment`)}</div>
      <div className="cardsContainer">
        {toolsInTransit.map((tool, i) => {
          return (
            <AssignedToolCard
              key={`assignedTool${i}`}
              assignedTool={tool}
              isPossibleToUnassign={false}
              updateFile={updateFileCallback}
              loaderFileCardId={loaderFileCardId}
              toolInTransit
            />
          );
        })}
      </div>
    </div>
  );
};

export default ToolsInTransit;
