import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g>
      <path
        className="circle"
        d="M8.6582 15C12.5242 15 15.6582 11.866 15.6582 8C15.6582 4.13401 12.5242 1 8.6582 1C4.79221 1 1.6582 4.13401 1.6582 8C1.6582 11.866 4.79221 15 8.6582 15Z"
        fill="#383838"
        stroke="white"
      />
      <path
        className="dot"
        d="M5.61546 9C6.17855 9 6.57271 8.56863 6.57271 8C6.57271 7.41176 6.17855 7 5.61546 7C5.07114 7 4.6582 7.41176 4.6582 8C4.6582 8.56863 5.07114 9 5.61546 9Z"
        fill="white"
      />
      <path
        className="dot"
        d="M8.6582 9C9.22129 9 9.61546 8.56863 9.61546 8C9.61546 7.41176 9.22129 7 8.6582 7C8.11388 7 7.70095 7.41176 7.70095 8C7.70095 8.56863 8.11388 9 8.6582 9Z"
        fill="white"
      />
      <path
        className="dot"
        d="M11.7009 9C12.264 9 12.6582 8.56863 12.6582 8C12.6582 7.41176 12.264 7 11.7009 7C11.1566 7 10.7437 7.41176 10.7437 8C10.7437 8.56863 11.1566 9 11.7009 9Z"
        fill="white"
      />
    </g>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const BlackPopoverIcon = memo(ForwardRef);
export default BlackPopoverIcon;
