const RoundAzerbaijan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M32 16c0-1.9571-.352-3.8319-.9952-5.5652L16 9.73914.995188 10.4348C.352063 12.1681 0 14.0429 0 16s.352063 3.8319.995188 5.5652L16 22.2609l15.0048-.6956C31.648 19.8319 32 17.9571 32 16Z"
        />
        <path
          fill="#6DA544"
          d="M16 32c6.8794 0 12.7441-4.342 15.0048-10.4348H.995186C3.25587 27.658 9.12056 32 16 32Z"
        />
        <path
          fill="#3ECBF8"
          d="M.995186 10.4348H31.0049C28.7441 4.342 22.8794 0 16 0 9.12056 0 3.25587 4.342.995186 10.4348Z"
        />
        <path
          fill="#fff"
          d="M16.3478 20.5217c-2.4972 0-4.5217-2.0245-4.5217-4.5218 0-2.4972 2.0245-4.5217 4.5217-4.5217.7786 0 1.5113.1969 2.151.5435-1.0035-.9813-2.3758-1.587-3.8901-1.587-3.0736 0-5.5652 2.4916-5.5652 5.5652 0 3.0735 2.4916 5.5652 5.5652 5.5652 1.5144 0 2.8866-.6057 3.8901-1.587-.6397.3467-1.3724.5436-2.151.5436Z"
        />
        <path
          fill="#fff"
          d="m19.8261 12.8695.5989 1.6844 1.6146-.7676-.7675 1.6146 1.6844.599-1.6844.599.7675 1.6147-1.6146-.7677-.5989 1.6845-.5989-1.6845-1.6147.7677.7676-1.6147-1.6845-.599 1.6845-.599-.7676-1.6146 1.6147.7676.5989-1.6844Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAzerbaijan;
