const RoundZimbabwe = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M30.4683 9.16271C29.6554 7.44558 28.546 5.89665 27.206 4.58133L15.9998 3.47827 4.7935 4.5814C4.75775 4.61646 4.72287 4.65233 4.6875 4.68777L9.1625 9.16277 15.9998 9.37158 30.4683 9.16271ZM4.6875 27.3123C4.74637 27.3712 4.80512 27.4301 4.86487 27.4881L15.9999 28.5216 27.1349 27.4881C28.4887 26.1756 29.6117 24.6266 30.436 22.9067L9.739 22.2607C7.95819 24.0416 5.90419 26.0955 4.6875 27.3123Z"
        />
        <path
          fill="black"
          d="M31.8413 13.744L13.2178 13.2173L16.0004 15.9999C15.1762 16.8241 13.9458 18.0545 13.2178 18.7825L31.8315 18.3254C31.9421 17.5661 32.0004 16.7898 32.0004 15.9999C32.0004 15.234 31.9454 14.4812 31.8413 13.744Z"
        />
        <path
          fill="#6DA544"
          d="M16.0002 0C11.6359 0 7.68057 1.74813 4.79395 4.58138H27.2064C24.3198 1.74813 20.3644 0 16.0002 0Z"
        />
        <path
          fill="#F93939"
          d="M13.7435 13.744H31.8403C31.612 12.1265 31.1422 10.5869 30.468 9.1626H9.16211L13.7435 13.744ZM9.09277 22.9066H30.4358C31.1179 21.4833 31.5951 19.9435 31.8308 18.3252H13.6741C11.9 20.0994 10.3963 21.603 9.09277 22.9066Z"
        />
        <path
          fill="#6DA544"
          d="M16.0002 32.0001C20.3286 32.0001 24.2549 30.2806 27.1351 27.4883H4.86523C7.74548 30.2806 11.6717 32.0001 16.0002 32.0001Z"
        />
        <path
          fill="black"
          d="M5.71371 3.74585C5.35802 4.04472 5.01484 4.35804 4.68652 4.68622L16.0003 16L4.68652 27.3137C5.01477 27.642 5.35802 27.9552 5.71371 28.2541L17.9678 16L5.71371 3.74585Z"
        />
        <path
          fill="#F93939"
          d="M6.43257 11.8262L7.46851 15.0147H10.8213L8.10894 16.9854L9.14495 20.174L6.43257 18.2034L3.72013 20.174L4.75626 16.9854L2.04395 15.0147H5.39657L6.43257 11.8262Z"
        />
        <path
          fill="#FFDA2C"
          d="M9.28213 16.2611L6.58226 15.3046C6.58226 15.3046 6.38276 13.4332 6.3707 13.3697C6.27907 12.887 5.85488 12.522 5.34551 12.522C4.76926 12.522 4.30201 12.9892 4.30201 13.5655C4.30201 13.661 4.31595 13.7531 4.34001 13.8411L3.58301 14.6032H4.92901C4.92901 16.0002 3.88651 16.0002 3.88651 17.3915L4.46482 18.7828H7.94307L8.52282 17.3915H8.52257C8.58063 17.258 8.61588 17.1203 8.63045 16.9828C9.1302 16.7807 9.28213 16.2611 9.28213 16.2611Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundZimbabwe;
