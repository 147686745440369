const SquareMalaysia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path
          fill="#AF010D"
          d="M0 0H22V1.14872H0zM0 2.29736H22V3.44608H0zM0 4.59473H22V5.74345H0zM0 6.89209H22V8.04081H0zM0 9.18994H22V10.33866H0zM0 11.4873H22V12.63602H0zM0 13.7847H22V14.93342H0z"
        />
        <path fill="#1A47B8" fillRule="evenodd" d="M0 0H11.5238V9.19467H0V0Z" clipRule="evenodd" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M7.33301 4.2666H8.38063V5.33327H7.33301V4.2666Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M7.85686 6.39997C7.37915 7.04743 6.61858 7.46663 5.76163 7.46663C4.31591 7.46663 3.14258 6.27197 3.14258 4.79997C3.14258 3.32797 4.31591 2.1333 5.76163 2.1333C6.61858 2.1333 7.37915 2.5525 7.85686 3.19997H6.28544C5.41801 3.19997 4.71401 3.91677 4.71401 4.79997C4.71401 5.68317 5.41801 6.39997 6.28544 6.39997H7.85686Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMalaysia;
