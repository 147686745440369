const SquareChina = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M5.75979 7.7122L4.21979 8.53673L4.51313 6.7906L3.26855 5.55326L4.98979 5.30046L5.75979 3.71113L6.52875 5.30046L8.24998 5.55326L7.00332 6.7906L7.29875 8.53566L5.75979 7.7122ZM9.42856 3.2002H10.4762V4.26686H9.42856V3.2002ZM10.4762 5.33353H11.5238V6.4002H10.4762V5.33353ZM10.4762 7.46686H11.5238V8.53353H10.4762V7.46686ZM9.42856 9.6002H10.4762V10.6669H9.42856V9.6002Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareChina;
