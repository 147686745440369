const SquareHongKong = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M10.6387 8.01501C7.79753 6.79047 9.05676 3.01874 11.7753 3.20647C11.2243 3.49447 11.1447 4.03207 11.459 4.52914C11.811 5.08807 11.4275 5.98514 10.9519 6.20381C10.1704 6.56114 10.0143 7.51581 10.6376 8.01501H10.6387ZM10.8765 7.88487C11.1415 4.75634 15.0565 4.80967 15.7165 7.49874C15.2786 7.05607 14.7527 7.14461 14.3839 7.60327C13.9722 8.11527 13.0157 8.01927 12.6658 7.62781C12.0896 6.98354 11.1468 7.12647 10.8765 7.88594V7.88487ZM10.9729 8.19741C13.99 7.54141 15.0837 11.3675 12.7507 12.799C13.0398 12.2411 12.8031 11.7537 12.2646 11.5297C11.6632 11.2779 11.4726 10.319 11.7387 9.86354C12.1766 9.11367 11.7659 8.23901 10.9739 8.19634L10.9729 8.19741ZM7.07153 11.7121C7.67915 11.8187 8.06153 11.4379 8.10448 10.8481C8.15372 10.1867 8.98972 9.70674 9.49781 9.82407C10.3338 10.0161 11.0242 9.34834 10.8178 8.56754C12.3641 11.2875 9.128 13.5275 7.07048 11.711L7.07153 11.7121ZM6.4461 5.86674C6.56657 6.48327 7.05162 6.70941 7.60896 6.53874C8.23229 6.34887 8.97295 6.97181 9.04734 7.49874C9.17095 8.36061 10.03 8.77661 10.6711 8.30301C8.73305 10.7457 5.51686 8.47367 6.4461 5.86567V5.86674Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareHongKong;
