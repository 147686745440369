const RoundRepublicOfTheCongo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M8.67339 30.2277L20.4274 20.4273L30.2279 8.67321C28.7042 5.72022 26.288 3.30278 23.3362 1.7771L11.5729 11.5728L1.77734 23.336C3.3029 26.2879 5.72034 28.704 8.67339 30.2277Z"
        />
        <path
          fill="#F93939"
          d="M27.3137 27.3142C32.3615 22.2662 33.3306 14.6852 30.2228 8.66357L8.66309 30.2233C14.6847 33.331 22.2658 32.362 27.3137 27.3142Z"
        />
        <path
          fill="#6DA544"
          d="M4.68582 4.68557C-0.362049 9.73343 -1.33105 17.3145 1.77663 23.3362L23.3364 1.77645C17.3148 -1.33136 9.73368 -0.362235 4.68582 4.68557Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundRepublicOfTheCongo;
