const SquareHonduras = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#3ECBF8"
            fillRule="evenodd"
            d="M0 11H22V16H0V11ZM7.33333 6.4H8.38095V7.46667H7.33333V6.4ZM7.33333 8.53333H8.38095V9.6H7.33333V8.53333ZM13.619 6.4H14.6667V7.46667H13.619V6.4ZM13.619 8.53333H14.6667V9.6H13.619V8.53333ZM10.4762 7.46667H11.5238V8.53333H10.4762V7.46667ZM0 0H22V5H0V0Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareHonduras;
