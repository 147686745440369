import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosModalSelectBetweenTwoOptions } from '../../../../../../components/DesignSystem';
import { TfunctionOneToVoid } from '../../../../../../types/global';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { BordTwoToneIcon } from '../../../../../../components/BordDesignSystem';
import './ModalPredefineLogistics.scss';

const ModalPredefineLogistics = ({
  isAssignment = false,
  isButtonLoading = false,
  submitModalCallback,
  closeModalCallback,
  updateLogisticsPredefined,
  navigationOrigin
}: {
  subtitle?: string;
  isAssignment?: boolean;
  isButtonLoading?: boolean;
  closeModalCallback?: TfunctionOneToVoid;
  submitModalCallback: () => void;
  updateLogisticsPredefined: (newValue: boolean) => void;
  navigationOrigin?: string;
}) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:assignToolsSelectedOptionsModal:';
  const optionOneTitle = t(`${translationKey}options:optionOneTitle`);
  const optionTwoTitle = t(`${translationKey}options:optionTwoTitle`);
  const clarification = t(`${translationKey}options:clarification`);
  const indicateViewTools = t(`${translationKey}options:indicateViewTools`);
  const [requireLogistics, setRequireLogistics] = useState<boolean>();
  const hasntSelectAnyOption = requireLogistics === undefined;

  const optionOneDescription = () => {
    return (
      <div className="logisticsOptionDescription">
        <div className="description">Nos encargamos de recoger el producto y llevarlo al destino</div>
        <div className="clarification">{clarification}</div>
      </div>
    );
  };
  const getOptionTwoDescription = () => {
    return <>{indicateViewTools}</>;
  };

  const optionsCards = [
    {
      title: <>{optionOneTitle}</>,
      description: optionOneDescription(),
      icon: <BordTwoToneIcon variant="trackingTruck" standardSize={32} />,
      isSelected: requireLogistics === true,
      action: () => setRequireLogistics(true),
      isRecommended: true,
      cardStyles: {}
    },
    {
      title: <>{optionTwoTitle}</>,
      description: getOptionTwoDescription(),
      icon: <BordTwoToneIcon variant="settings" standardSize={32} />,
      isSelected: requireLogistics === false,
      action: () => setRequireLogistics(false),
      isRecommended: false,
      cardStyles: {}
    }
  ];

  useEffect(() => {
    if (requireLogistics !== undefined) updateLogisticsPredefined(requireLogistics);
  }, [requireLogistics]);

  const segmentEvents = () => {
    if (!isAssignment) {
      segmentTrackEvent({
        nodiLogisticServices0ChooseView: {
          ScreenName: navigationOrigin === 'inventory' ? 'Inventory listing' : 'Device internal Screen'
        }
      });
    }
  };

  useEffect(() => {
    segmentEvents();
  }, []);

  return (
    <div className="modalPredefineLogistics">
      <NudosModalSelectBetweenTwoOptions
        optionsCards={optionsCards}
        closeModalCallback={closeModalCallback}
        isButtonDisabled={hasntSelectAnyOption}
        isButtonLoading={isButtonLoading}
        submitModalCallback={submitModalCallback}
      />
    </div>
  );
};

export default ModalPredefineLogistics;
