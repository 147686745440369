import { useState } from 'react';
import XSign from '../../../../../../../assets/images/components/NudosComponents/XSign';
import {
  addressIssueIcon,
  addressEditedByOps,
  lsEmployeeReport,
  failureToMeetDeadlinesIcon,
  externalProblemIcon,
  awaitResponseCalendar,
  errorPhone
} from '../../../../../../../assets/images/views/RequestLogistics';
import { BordButton } from '../../../../../../../components/BordDesignSystem';

import { checkAddressChanged } from '../../../../../../../services/logisticsServicesModule.services';
import {
  ILogisticReports,
  IlogisticServiceDetailedData
} from '../../../../../../../types/logisticsServicesModule.types';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { useTranslation, Trans } from 'react-i18next';
import { reportReason } from '../../../../../../../constants/logisticServices.constants';
import { segmentTrackEvent } from '../../../../../../../utils/segment';
import { supportUrl } from '../../../../../../../utils/productDefinitions';
import BordIlustrationIcon from '../../../../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import './LogisticServiceBanner.scss';

const LogisticServiceBanner = ({
  closeAlertCallback,
  logisticServiceData,
  getLogisticServiceCallback,
  onlyPhoneIssue
}: {
  closeAlertCallback: () => void;
  logisticServiceData?: IlogisticServiceDetailedData;
  getLogisticServiceCallback?: () => void;
  onlyPhoneIssue?: boolean;
}) => {
  const { t } = useTranslation();

  const [checkAddressLoading, setCheckAddressLoading] = useState<boolean>(false);

  const translationKey = 'nodi:logisticServices:logisticServiceBanner:';
  const originTranslation = t('recurrentWords:origin');
  const destinationTranslation = t('recurrentWords:destination');
  const collectionTranslation = t('recurrentWords:collection');
  const deliveryTranslation = t('recurrentWords:delivery');
  const awaitReportTitle = t(`${translationKey}bannerTitle:awaitReportTitle`);
  const holidayReportTitle = t(`${translationKey}bannerTitle:holidayReportTitle`);
  const employeeNotCooperateTitle = t(`${translationKey}bannerTitle:employeeNotCooperateTitle`);
  const failureToMeetDeadlinesTitle = t(`${translationKey}bannerTitle:failureToMeetDeadlinesTitle`);
  const withoutReasonReportTitle = t(`${translationKey}bannerTitle:withoutReasonReportTitle`);
  const addressChangedNotViewedTitle = t(`${translationKey}bannerTitle:addressChangedNotViewedTitle`);
  const addressIssueTitle = t(`${translationKey}bannerTitle:addresIssueTitle`);
  const awaitReportSubtitle = t(`${translationKey}bannerSubtitle:awaitReportSubtitle`);
  const holidayReportSubtitle = t(`${translationKey}bannerSubtitle:holidayReportSubtitle`);
  const employeeNotCooperateSubtitle = t(`${translationKey}bannerSubtitle:employeeNotCooperateSubtitle`);
  const withoutReasonReportSubtitle = t(`${translationKey}bannerSubtitle:withoutReasonReportSubtitle`);
  const canceledSLSubtitle = t(`${translationKey}bannerSubtitle:canceledSLSubtitle`);
  const canceledAttemptsNote = t(`${translationKey}bannerSubtitle:canceledAttemptsNote`);
  const attemptsNoteTranslation = t(`${translationKey}bannerSubtitle:attemptsNoteTranslation`);

  const { addressIssue, id, reports, isCancelledAutomatically, status } = logisticServiceData || {};
  const destinationIssueReported = addressIssue?.destination?.issueReported?.length;
  const originIssueReported = addressIssue?.origin?.issueReported?.length;
  const originAddressEditedByUser = addressIssue?.origin?.editedBy === 'user';

  const originAddressChangedByOps = addressIssue?.origin?.editedBy === 'nudos';
  const originViewedChanges = addressIssue?.origin?.viewedChanges;

  const destinationAddressChangedByOps = addressIssue?.destination?.editedBy === 'nudos';
  const destinationViewedChanges = addressIssue?.destination?.viewedChanges;
  const originChangedNotViewed = originAddressChangedByOps && !originViewedChanges;
  const destinationAddressEditedByUser = addressIssue?.destination?.editedBy === 'user';

  const originDestinationValue = originChangedNotViewed ? 'origin' : 'destination';
  const destinationChangedNotViewed = destinationAddressChangedByOps && !destinationViewedChanges;
  const displayCheckAddressButton = originChangedNotViewed || destinationChangedNotViewed;
  const addressChangedByOps = originChangedNotViewed || destinationChangedNotViewed;
  const addressChangedByOpsNotViewed = originChangedNotViewed || destinationChangedNotViewed;

  const displayDestinationIssueText = !!destinationIssueReported && !destinationAddressEditedByUser;
  const displayOriginIssueText = !!originIssueReported && !originAddressEditedByUser;
  const displayAddressIssue = displayOriginIssueText || displayDestinationIssueText;
  const originDestinationIssueValue =
    displayOriginIssueText && displayDestinationIssueText
      ? 'de origen y el punto de destino'
      : !!originIssueReported && !originAddressEditedByUser
      ? originTranslation
      : destinationTranslation;

  const displayAddressIssueOnlyPhone = displayAddressIssue && onlyPhoneIssue;

  const placeValue = originAddressChangedByOps || displayOriginIssueText ? originTranslation : destinationTranslation;

  const findReportByReason = (reasons: string | string[]) =>
    reports?.find(report => {
      if (!report?.reason) return;
      const reasonMatch = Array.isArray(reasons) ? reasons?.includes(report?.reason) : report?.reason === reasons;
      return reasonMatch;
    });

  const withoutReasonReport = findReportByReason(reportReason?.WITHOUT_REASON);
  const employeeNotWillingReport = findReportByReason(reportReason?.EMPLOYEE_NOT_WILLING);
  const failureToMeetDeadlines = findReportByReason([
    reportReason?.EMPLOYEE_NOT_DATE_DELIVERY,
    reportReason?.EMPLOYEE_NOT_DATE_RECEIVER
  ]);
  const employeeDoesNotCooperate = findReportByReason(reportReason?.EMPLOYEE_DOES_NOT_COOPERATE);
  const holidayReport = findReportByReason(reportReason?.HOLIDAY_REPORT);
  const externalProblem = findReportByReason([
    reportReason?.ORIGIN_POINT_ADVERSE,
    reportReason?.DESTINATION_POINT_ADVERSE
  ]);
  const awaitResponseReport = findReportByReason(reportReason?.AWAIT_RESPONSE);
  const employeeDoesNotAnswerReport = findReportByReason([
    reportReason?.UNRESPONSIVE_DELIVERY_EMPLOYEE,
    reportReason?.UNRESPONSIVE_RECEIVING_EMPLOYEE
  ]);

  const sortedReports = reports?.sort((date1, date2) => {
    const getLastLogDate = (report: ILogisticReports) => new Date(report?.logs?.[report.logs.length - 1]?.date || 0);
    return getLastLogDate(date2).getTime() - getLastLogDate(date1).getTime();
  });

  const latestReport = sortedReports?.[0];
  const displaySupportSection = !latestReport || latestReport !== withoutReasonReport;
  const customStyles =
    addressChangedByOps || (latestReport && latestReport === withoutReasonReport) ? 'changedByOps' : '';
  const externalProblemOriginDestination =
    externalProblem?.reason === reportReason?.ORIGIN_POINT_ADVERSE ? originTranslation : destinationTranslation;
  const employeeNotAnswerOriginDestination =
    employeeDoesNotAnswerReport?.reason === reportReason?.UNRESPONSIVE_DELIVERY_EMPLOYEE
      ? originTranslation
      : destinationTranslation;
  const failureToMeetDeadlinesOriginDestination =
    failureToMeetDeadlines?.reason === reportReason?.EMPLOYEE_NOT_DATE_DELIVERY
      ? originTranslation
      : destinationTranslation;
  const failureToMeetDeadlinesPlace =
    failureToMeetDeadlines?.reason === reportReason?.EMPLOYEE_NOT_DATE_DELIVERY
      ? collectionTranslation
      : deliveryTranslation;
  const externalProblemPlace =
    externalProblem?.reason === reportReason?.ORIGIN_POINT_ADVERSE ? deliveryTranslation : collectionTranslation;

  const statusIsActive = status === 'activo';
  const statusInProgress = status === 'en proceso';
  const latestReportName = latestReport?.reasonNameString;
  const reportReasonProperty = statusIsActive ? 'ReportReasonInDelayed' : 'ReportReasonInProcess';
  const trackReportEvent = !!latestReport && (statusIsActive || statusInProgress);
  const viewEventTitle = statusIsActive
    ? 'logisticServiceProblemDelayedSupportClick'
    : 'logisticServiceProblemInProcessSupportClick';
  const styleUpdateAddress = addressChangedByOpsNotViewed ? 'updateAddress' : '';

  const addressIssueSubtitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerSubtitle:addresIssueSubtitle`)}
      components={{ 1: originDestinationIssueValue }}
    />
  );
  const failureToMeetDeadlinesSubtitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerSubtitle:failureToMeetDeadlinesSubtitle`)}
      components={{ 1: failureToMeetDeadlinesPlace, 2: failureToMeetDeadlinesOriginDestination }}
    />
  );
  const externalProblemSubtitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerSubtitle:externalProblemSubtitle`)}
      components={{ 1: externalProblemPlace, 2: externalProblemOriginDestination }}
    />
  );
  const employeeNotAnswerSubtitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerSubtitle:employeeNotAnswerSubtitle`)}
      components={{ 1: employeeNotAnswerOriginDestination }}
    />
  );
  const externalProblemTitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerTitle:externalProblemTitle`)}
      components={{ 1: externalProblemOriginDestination }}
    />
  );
  const employeeDoesNotAnswerTitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerTitle:employeeDoesNotAnswerTitle`)}
      components={{ 1: employeeNotAnswerOriginDestination }}
    />
  );
  const addressChangedNotViewedSubtitle = (
    <Trans
      i18nKey={t(`${translationKey}bannerSubtitle:addressChangedNotViewedSubtitle`)}
      components={{ 1: placeValue }}
    />
  );

  const getBannerTitle = () => {
    if (latestReport) {
      switch (latestReport) {
        case awaitResponseReport:
          return awaitReportTitle;
        case failureToMeetDeadlines:
          return failureToMeetDeadlinesTitle;
        case employeeDoesNotCooperate:
        case employeeNotWillingReport:
          return employeeNotCooperateTitle;
        case holidayReport:
          return holidayReportTitle;
        case externalProblem:
          return externalProblemTitle;
        case withoutReasonReport:
          return withoutReasonReportTitle;
        case employeeDoesNotAnswerReport:
          return employeeDoesNotAnswerTitle;
      }
    }
    if (addressChangedByOpsNotViewed) return addressChangedNotViewedTitle;
    if (displayAddressIssueOnlyPhone) return 'Problemas en el número de teléfono';
    if (displayAddressIssue) return addressIssueTitle;
  };

  const displayAttemptsNote =
    latestReport &&
    (latestReport === failureToMeetDeadlines ||
      latestReport === employeeDoesNotCooperate ||
      latestReport === employeeNotWillingReport);
  const attemptsNote = isCancelledAutomatically ? canceledAttemptsNote : attemptsNoteTranslation;
  const attemptsNotesStyles = isCancelledAutomatically ? 'attemptsNote' : 'attemptsNote font-bold';

  const getBannerSubtitle = () => {
    if (latestReport) {
      switch (latestReport) {
        case withoutReasonReport:
          return withoutReasonReportSubtitle;
        case awaitResponseReport:
          return awaitReportSubtitle;
        case employeeDoesNotAnswerReport:
          return employeeNotAnswerSubtitle;
        case failureToMeetDeadlines:
          return isCancelledAutomatically ? canceledSLSubtitle : failureToMeetDeadlinesSubtitle;
        case employeeDoesNotCooperate:
        case employeeNotWillingReport:
          return isCancelledAutomatically ? canceledSLSubtitle : employeeNotCooperateSubtitle;
        case holidayReport:
          return holidayReportSubtitle;
        case externalProblem:
          return externalProblemSubtitle;
      }
    }
    if (addressChangedByOpsNotViewed) return addressChangedNotViewedSubtitle;
    if (displayAddressIssueOnlyPhone)
      return `Verifica y corrige el número de teléfono del ${originDestinationIssueValue} para poder avanzar con tu servicio.`;
    if (displayAddressIssue) return addressIssueSubtitle;
  };

  const handleClickForHelp = () => {
    !latestReport && segmentTrackEvent({ logiticServiceEditSupportClick: null });
    trackReportEvent &&
      segmentTrackEvent({
        [viewEventTitle]: {
          SL: id || 0,
          [reportReasonProperty]: latestReportName
        }
      });

    window.open(supportUrl);
  };

  const checkAddressHadChanged = async () => {
    if (!id) return;
    try {
      setCheckAddressLoading(true);
      await checkAddressChanged(id, originDestinationValue);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setCheckAddressLoading(false);
      getLogisticServiceCallback && getLogisticServiceCallback();
    }
  };

  return (
    <div className={`logisticServiceBanner ${customStyles} ${styleUpdateAddress}`}>
      <div className="logisticServiceAddressContainer">
        <div className="title">{getBannerTitle()}</div>
        <div>{getBannerSubtitle()}</div>
        <div className={attemptsNotesStyles}>{displayAttemptsNote && attemptsNote}</div>
        {displaySupportSection && (
          <div className="informationContainer">
            <div>{t(`${translationKey}moreInformationText`)}</div>
            <div className="contactSupport" onClick={() => handleClickForHelp()}>
              {t(`${translationKey}contactSupportText`)}
            </div>
          </div>
        )}
        {(displayCheckAddressButton || addressChangedByOpsNotViewed) && (
          <BordButton
            label={t('recurrentWords:understoodButton')}
            customClassName="checkAddressButton"
            onClick={checkAddressHadChanged}
            isLoading={checkAddressLoading}
            modeButton="tertiary"
          />
        )}
      </div>
      <BordIlustrationIcon variant={addressChangedByOpsNotViewed ? 'pin' : 'warning'} standardSize={64} />
      <div className="closeAlert" onClick={closeAlertCallback}>
        <XSign fill={'#B3B3B3'} />
      </div>
    </div>
  );
};

export default LogisticServiceBanner;
