const SquareTurkmenistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#249F58" rx="2" />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M2.0957 0H3.14332V16H2.0957V0ZM4.19094 0H5.23856V16H4.19094V0ZM6.28618 0H7.3338V16H6.28618V0Z"
          clipRule="evenodd"
        />
        <path
          fill="#AE6A3E"
          fillRule="evenodd"
          d="M5.23856 0L7.3338 1.6L5.23856 3.2V0ZM5.23856 3.2L7.3338 4.8L5.23856 6.4V3.2ZM5.23856 6.4L7.3338 8L5.23856 9.6V6.4ZM5.23856 9.6L7.3338 11.2L5.23856 12.8V9.6ZM5.23856 12.8L7.3338 14.4L5.23856 16V12.8ZM4.19094 0L2.0957 1.6L4.19094 3.2V0ZM4.19094 3.2L2.0957 4.8L4.19094 6.4V3.2ZM4.19094 6.4L2.0957 8L4.19094 9.6V6.4ZM4.19094 9.6L2.0957 11.2L4.19094 12.8V9.6ZM4.19094 12.8L2.0957 14.4L4.19094 16V12.8ZM4.19094 0H5.23856V2.13333H4.19094V0ZM4.19094 13.8667H5.23856V16H4.19094V13.8667ZM4.19094 5.33333H5.23856V7.46667H4.19094V5.33333ZM4.19094 3.2H5.23856V4.26667H4.19094V3.2ZM4.19094 11.7333H5.23856V12.8H4.19094V11.7333ZM4.19094 8.53333H5.23856V10.6667H4.19094V8.53333Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          fillRule="evenodd"
          d="M11.7712 3.21513C11.8623 3.20446 11.9545 3.2002 12.0478 3.2002C13.4935 3.2002 14.6668 4.39486 14.6668 5.86686C14.6668 7.33886 13.4935 8.53353 12.0478 8.53353C10.602 8.53353 9.42871 7.33886 9.42871 5.86686C9.42871 5.77086 9.43395 5.67806 9.44338 5.58526C9.5649 6.64553 10.4491 7.46686 11.5239 7.46686C12.6816 7.46686 13.6192 6.5122 13.6192 5.33353C13.6192 4.2402 12.8125 3.33886 11.7712 3.21513ZM10.4763 4.26686H11.5239V5.33353H10.4763V4.26686Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          fillRule="evenodd"
          d="M8.38086 4.26663H9.42848V5.3333H8.38086V4.26663ZM10.4761 2.1333H11.5237V3.19997H10.4761V2.1333Z"
          clipRule="evenodd"
          opacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTurkmenistan;
