import { useState } from 'react';
import { DeleteSquare, TextOnPaper } from '../../../../../assets/images/views/Admin/main';
import { ICountryDetail } from '../../../../../types/countries';
import useNewEmployeeFormController from './NewEmployeeForm.controller';

interface INewEmployeeSchema {
  countriesList?: ICountryDetail[];
}

const useNewEmployeeFormSchema = ({ countriesList }: INewEmployeeSchema) => {
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [selectedArea, setSelectedArea] = useState<string | undefined>(undefined);
  const { countryFormOptions } = useNewEmployeeFormController();
  const onChangeSelectedCountry = (newSelectedCountry: ICountryDetail) => {
    setSelectedCountry(newSelectedCountry);
    setOpenCountrySelect(!openCountrySelect);
  };

  const assignationDocumentPlaceholder = (buttonText: string) => {
    return (
      <div className="uploader-placeholder-container">
        <span>{buttonText === 'Documento de asignación' ? <TextOnPaper /> : <DeleteSquare />}</span>
        <p>{buttonText}</p>
      </div>
    );
  };

  const countryOptions = countryFormOptions(onChangeSelectedCountry, 'country', countriesList);

  return {
    selectedCountry,
    selectedArea,
    assignationDocumentPlaceholder,
    setSelectedCountry,
    countryOptions,
    setSelectedArea
  };
};

export default useNewEmployeeFormSchema;
