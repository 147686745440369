import create from 'zustand';
export interface lenguageState {
  platformLanguage: 'esMX' | 'enUS';
  setPlatformLanguage: (lenguage: 'esMX' | 'enUS') => void;
}

const useLanguagePlatformState = create<lenguageState>(set => ({
  platformLanguage: 'esMX',
  setPlatformLanguage: platformLanguage => set({ platformLanguage })
}));

export default useLanguagePlatformState;
