const RoundSriLanka = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 31.9999C24.8365 31.9999 31.9999 24.8365 31.9999 16C31.9999 7.16343 24.8365 0 16 0C7.16343 0 0 7.16343 0 16C0 24.8365 7.16343 31.9999 16 31.9999Z"
        />
        <path
          fill="#FF9811"
          d="M12.5223 2.78271H6.98262C6.73712 2.95053 6.49687 3.12522 6.26143 3.30603L4.87012 16.0001L6.26143 28.6941C6.49687 28.875 6.73712 29.0496 6.98262 29.2174H12.5223V2.78271Z"
        />
        <path
          fill="#6DA544"
          d="M0 16C0 21.1716 2.45426 25.7692 6.26089 28.694V3.30591C2.45426 6.23079 0 10.8284 0 16Z"
        />
        <path fill="#FFDA2C" d="M25.7393 9.75391V10.1542L25.7541 10.1565L25.7393 9.75391Z" />
        <path
          fill="#A2001D"
          d="M30.7244 20.174H27.7098L26.4345 21.5653V24.3479H23.6519V22.9566H25.0432V20.174H18.7823V21.9131H17.391V18.5987C16.9641 18.2166 16.6953 17.6615 16.6953 17.0436V8.0001C16.6953 6.84754 17.6297 5.91316 18.7823 5.91316V18.7827H21.5649L22.5197 17.9871C22.3529 17.5899 22.2605 17.1537 22.2605 16.6958V14.6089H20.1736V10.435H24.3475C24.3475 9.73935 25.391 9.04367 25.391 9.04367C25.391 9.04367 26.4345 9.73929 26.4345 10.435V11.4785V14.6089C27.2084 14.6181 28.4381 14.5981 29.7665 14.6089C29.4175 13.9989 29.2171 13.2751 29.2171 12.522C29.2171 11.2944 29.7471 10.1908 30.5907 9.42704C29.3747 6.73235 27.4343 4.43472 25.0176 2.78271H14.6084V29.2175H25.0177C27.9564 27.2087 30.1907 24.2455 31.2747 20.7744L30.7244 20.174Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSriLanka;
