import { BordPhoto } from '../../../../../../components/BordDesignSystem';
import { TbordAvatarsVariant } from '../../../../../../components/BordDesignSystem/BordAvatar/BordAvatar.types';
import { TbordOneToneIcon } from '../../../../../../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import { NudosHoverText } from '../../../../../../components/NudosComponents';
import useLocationData from '../../../../../../hooks/useLocationData';
import { bordColorTheme } from '../../../../../../styles/constants';
import { LOCATION_PLACE_TYPE } from '../../../../../../types/global';
import { IShipmentsData } from '../../../../../../types/orders';

const FirstShipmentData = ({ firstShipment }: { firstShipment: IShipmentsData }) => {
  const { destinationPlace, destinationCountry, receiverName } = firstShipment || {};
  const { oneToneIconVariant, locationText, circularAvatarVariant } = useLocationData({
    place: destinationPlace as LOCATION_PLACE_TYPE,
    countryData: destinationCountry,
    locationName: receiverName
  });

  const userDestination = destinationPlace === 'user';
  const backgroundColor = userDestination ? undefined : bordColorTheme.layout.level2;

  const photoPlaceholder =
    destinationPlace === 'user'
      ? {
          avatarProps: {
            variant: circularAvatarVariant as TbordAvatarsVariant,
            standardSize: 48 as const
          }
        }
      : {
          oneToneIconProps: {
            variant: oneToneIconVariant as TbordOneToneIcon,
            standardSize: 24 as const
          }
        };

  return (
    <div className="firstShipmentData">
      <BordPhoto size="s48" variant="circle" style={{ backgroundColor }} {...photoPlaceholder} />
      <NudosHoverText text={locationText || ''} charactersLimit={18} onlyIfTruncated />
    </div>
  );
};

export default FirstShipmentData;
