import { NudosToolTip } from '../../../../components/NudosComponents';
import { NudosKeepInMindInfo } from '../../../../components/DesignSystem';
import { TbordOneToneIcon } from '../../../../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';

import './NudosLogisticsKeepInMind.scss';

const paymentAgreementToolTip = (
  <NudosToolTip
    type="question"
    tooltipContent={<>Acuerdo validado a través de un pagaré, donde tienes un día específico para hacer los pagos. </>}
    tooltipContentCustomStyles={{ width: '158px' }}
  />
);

const nudosCareToolTip = (
  <NudosToolTip
    type="question"
    tooltipContent={<>Tienes asegurado tu producto con uno de los planes de Care ( Basic, Logistic, Full)</>}
    tooltipContentCustomStyles={{ width: '158px' }}
  />
);

const bulletPoints = [
  {
    icon: 'clock' as TbordOneToneIcon,
    textContent: (
      <div>Las fechas del servicio son estimativas, pueden variar dependiendo de la ubicación del destino.</div>
    )
  },
  {
    icon: 'dollarSign' as TbordOneToneIcon,
    textContent: <div>El servicio logístico inicia una vez realizado el pago.</div>
  },
  {
    icon: 'bord' as TbordOneToneIcon,
    textContent: (
      <div className="WithToolTipIcon">
        Si tienes Acuerdo de Pago {paymentAgreementToolTip} o Care {nudosCareToolTip} se inicia el proceso de manera
        automática
      </div>
    )
  },
  {
    icon: 'coffee' as TbordOneToneIcon,
    textContent: <div>Los días hábiles para este servicio no incluyen los fines de semana ni los días feriados.</div>
  }
];

const NudosLogisticsKeepInMind = () => {
  return (
    <div className="NudosLogisticsKeepInMind">
      <NudosKeepInMindInfo bewareTitle="Ten en cuenta que:" bulletPoints={bulletPoints} />
    </div>
  );
};

export default NudosLogisticsKeepInMind;
