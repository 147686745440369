const GenericFetchError = () => {
  return (
    <div className="unsuccessful">
      <p>
        Ha habido un problema cargando los datos, por favor revisa tu internet, si el problema persiste comunícate con
        soporte para obtener ayuda
      </p>
    </div>
  );
};

export default GenericFetchError;
