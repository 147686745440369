import create from 'zustand';

export interface iState {
  isLogoutModalOpen: boolean;
  setIsLogoutModalOpen: (isLogoutModalOpen: boolean) => void;
  openMinMenu: boolean;
}

const useLogOutState = create<iState>(set => ({
  isLogoutModalOpen: false,
  setIsLogoutModalOpen: isLogoutModalOpen => set({ isLogoutModalOpen }),
  openMinMenu: false
}));

export default useLogOutState;
