import { FC } from 'react';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import { TstoragePlace } from '../../../../../../types/assignationFluxes';
import ToolQuoteCard from './ToolQuoteCard/ToolQuoteCard';
import './LogisticServiceToolsQuoteData.scss';

interface ILogisticServiceToolsQuoteData {
  logisticServiceQuoteData: ILogisticQuoteGeneralData;
  destinationPlace?: TstoragePlace;
  originPlace?: TstoragePlace;
}

const LogisticServiceToolsQuoteData: FC<ILogisticServiceToolsQuoteData> = ({
  logisticServiceQuoteData
}: ILogisticServiceToolsQuoteData) => {
  return (
    <div id="LogisticServiceToolsQuoteData">
      {!!logisticServiceQuoteData?.tools.length &&
        logisticServiceQuoteData.tools.map((tool, index) => {
          return (
            <ToolQuoteCard
              platformSubscription={logisticServiceQuoteData?.platformSubscription}
              toolData={tool}
              key={`toolQuoteCard-${tool.productId}${index}`}
            />
          );
        })}
    </div>
  );
};

export default LogisticServiceToolsQuoteData;
