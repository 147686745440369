import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ToolLogisticsOrigenAndDestinationTag from '../../../../../components/ToolLogisticsOrigenAndDestinationTag/ToolLogisticsOrigenAndDestinationTag';
import { NudosCareTag } from '../../../../../components/NudosComponents';
import { BordCheckBox, BordFlag, BordOneToneIcon, BordPhoto } from '../../../../../components/BordDesignSystem';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import { NudosWipingStatusMarker } from '../../../../../components/DesignSystem/LogisticsAdditionalServices';
import { LS_ADDITIONAL_SERVICE_TYPE } from '../../../../../types/logisticsServicesModule.types';
import { LOCATION_PLACE_TYPE, PRODUCT_CATEGORY } from '../../../../../types/global';
import { bordColorTheme } from '../../../../../styles/constants';
import { formatPills } from '../../../../../utils/productDetails';
import { DynamicsPills } from '../../../../../components';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';

import './SelectProductCard.scss';

const SelectProductCard: FC<ISelectProductCard> = ({
  toolData,
  actionSelect,
  isSelected,
  actionDeselected
}: ISelectProductCard) => {
  const { t } = useTranslation();

  const { pills, logisticAdditionalServices, category } = toolData;

  const wipingService = logisticAdditionalServices?.find(service => service.name === LS_ADDITIONAL_SERVICE_TYPE.WIPING);
  const i18nLanguageKey = 'nodi:tools:';
  const toolIsLocatedInNudosWarehouse = ['nudos', 'warranty', 'diagnostic'].includes(toolData?.place || '');
  const noDataText = 'Sin datos';

  const pillsList = pills ? formatPills({ pillsList: pills, excludeItems: ['Cargo', 'Gama', 'Perfil'] }) : undefined;

  const toolCategorySupportWiping = [PRODUCT_CATEGORY.LAPTOP, PRODUCT_CATEGORY.PC].includes(
    (category || '') as PRODUCT_CATEGORY
  );
  const showWipingStatusMarker = toolCategorySupportWiping && toolData?.place === LOCATION_PLACE_TYPE.NUDOS;

  const toolOriginData = {
    place: toolData?.place,
    address: toolData?.address,
    city: toolData?.city,
    country: toolData?.country,
    locationName: toolData?.locationName
  };

  const getToolCity = () => {
    if (toolIsLocatedInNudosWarehouse) return toolData?.country?.name || '-';
    if (toolData?.city && toolData?.address) return toolData?.city;
    if (!toolData?.city || !toolData?.address) return t(`${i18nLanguageKey}noLocation`);
    return t(`${i18nLanguageKey}noLocation`);
  };

  const handleSelectTool = () => actionSelect && actionSelect();

  const handleClickCheckbox = isSelected ? actionDeselected : handleSelectTool;

  return (
    <div className="selectProductCard" onClick={handleClickCheckbox}>
      <div className="nudosSelectButton">
        <BordCheckBox isButtonActive={isSelected} />
      </div>

      <div className="productImgContainer">
        <BordPhoto url={toolData.imageUrl || ''} size="s56" />
        <div className="floatBrand">
          <BordPhoto
            variant="circle"
            url={toolData?.brandImage || ''}
            size="s32"
            oneToneIconProps={{ variant: 'bord', customWidth: '3.2rem', stroke: bordColorTheme.navyBlue[600] }}
            imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '65%', maxWidth: '65%' } }}
          />
        </div>
      </div>

      <div className="productDetailsContainer">
        <div className="superiorDetail">
          <div className="productName">{toolData?.commercialName || noDataText}</div>
        </div>
        <div className="inferiorDetail">
          {pillsList?.pills?.map((item, index) => {
            return (
              <DynamicsPills
                key={`product-description-${index}-ls`}
                dataPills={{ hoverText: item.name || '', text: item?.value || '' }}
                elementsNumber={pillsList?.pills?.length || 0}
                disabledMaxWidth
              />
            );
          })}
          {/*    <div className="serialContainer">
            <MinSerialIcon className="mr-1" />
            <div className="fontSerial truncate">{toolData?.serial || '-'}</div>
          </div> */}
        </div>
      </div>

      <div className="wipingSection">
        {showWipingStatusMarker && <NudosWipingStatusMarker wipingData={wipingService} />}
      </div>

      <div className="nudosCareInfo">
        <NudosCareTag nudosCareType={toolData?.nudosCareName} />
        <div className="serialContainer">
          <BordOneToneIcon standardSize={12} variant="barCode2" />
          <div className="serialText">{toolData?.serial || '-'}</div>
        </div>
      </div>

      <div className="actualLocationContainer">
        <div className="generalStylesLocation">
          <ToolLogisticsOrigenAndDestinationTag
            origin={toolOriginData}
            logisticServiceIsConcluded={true}
            isBuyAndHold={toolData?.isBuyAndHold}
          />
        </div>
        <div className="citySelectTool">
          <div className="containerFlag">
            <BordFlag variant="circle" country={toolData?.country?.code as TcountryCode} standardSize={12} />
          </div>
          <div className="cityName">{getToolCity()}</div>
        </div>
      </div>
    </div>
  );
};

export default SelectProductCard;

interface ISelectProductCard {
  toolData: ItoolEssentialData;
  actionSelect: () => void;
  isSelected?: boolean;
  actionDeselected: () => void;
}
