import type { FC } from 'react';
import { IBordToastProps, TOAST_VARIANTS } from './BordToast.type';
import BordOneToneIcon from '../BordOneToneIcon/BordOneToneIcon';
import BordButton from '../BordButton/BordButton';
import '../BordToast/BordToast.scss';

const BordToast: FC<IBordToastProps> = ({
  variant = 'informative',
  title = '',
  description = '',
  onClose,
  action,
  actionLabelTwo,
  actionTwo,
  hideAction = true,
  actionLabel = 'action',
  customJSXMessage = <></>
}) => {
  const className = `toastContainer ${variant}`;

  const twoButtons = (
    <div className="twoButtonsContainer">
      <BordButton label={actionLabelTwo} modeButton="tertiary" customFontSize="1.2rem" onClick={actionTwo} />
      <BordButton label={actionLabel} modeButton="tertiary" customFontSize="1.2rem" onClick={action} />
    </div>
  );
  const oneButton = <BordButton label={actionLabel} modeButton="tertiary" customFontSize="1.2rem" onClick={action} />;

  const renderButtons = actionLabelTwo ? twoButtons : oneButton;
  const renderItemsPosition = actionLabelTwo ? 'items-start' : 'items-center';
  const renderStyles = actionLabelTwo ? 'flex items-center gap-4' : 'flex items-center gap-4';

  return (
    <div className={className}>
      <div className="flex justify-start items-center gap-16">
        <div className="h-full w-24 mb-auto">
          <BordOneToneIcon variant={TOAST_VARIANTS[variant]} customWidth="24px" />
        </div>
        <div className={`toastData ${renderItemsPosition}`}>
          <div className={renderStyles}>
            {!!customJSXMessage && customJSXMessage}
            {!customJSXMessage && (
              <div>
                <div className="toastTitle">{title}</div>
                <div className="toastDescription">{description}</div>
              </div>
            )}
            {!hideAction && renderButtons}
          </div>
          <div className="cursor-pointer close-icon" onClick={onClose}>
            <BordOneToneIcon variant="x" customWidth="1.8rem" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BordToast;
