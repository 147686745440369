import { FC, useEffect, useState } from 'react';
import useCartState from '../../../../../state/useCartState';
import { useHistory } from 'react-router-dom';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { requestOrderSummaryPDF } from '../../../../../services/checkout';
import { PAYMENT_TYPES } from '../../../../../types/checkout';
import { NudosTransfDetails } from '../../../../../components/DesignSystem';
import { BordButton, BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import { segmentTrackEvent } from '../../../../../utils/segment';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { Trans, useTranslation } from 'react-i18next';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import './Step4FinishCart.scss';

interface IStep4FinishCart {
  deliveryDayRange?: string;
}

const Step4FinishCart: FC<IStep4FinishCart> = ({ deliveryDayRange }: IStep4FinishCart) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { dataFinishCart, setDataFinishCart } = useCartState();
  const { stateSubscription } = useStateSubscriptions();
  const { storeCountry } = useStoreCountryState();

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [transfMX, setTransfMX] = useState<boolean>();
  const [transfCOP, setTransfCOP] = useState<boolean>();
  const [transfUSD, setTransfUSD] = useState<boolean>();
  const [creditCardCOP, setCreditCardCOP] = useState<boolean>();
  const [nationalCreditCard, setNationalCreditCard] = useState<boolean>();
  const [internationalCreditCard, setInternationalCreditCard] = useState<boolean>();
  const [loadingOrderSummary, setLoadingOrderSummary] = useState(false);
  const [orderSummaryPDFUrl, setOrderSummaryPDFUrl] = useState('');

  const { isPrime } = stateSubscription;
  const { currencyCode, orderId, shippings, summary, paymentType } = dataFinishCart || {};
  const translationKey = 'nodi:billingDataSection:step4FinishCart:';
  const containerTitle = t(`${translationKey}containerTitle`);
  const graySubtitle = t(`${translationKey}graySubtitle`);
  const nudosButtonText = t(`${translationKey}nudosButtonText`);
  const orangeButtonText = t(`${translationKey}orangeButtonText`);

  const paymentMethodIsStripe = currencyCode === 'MXN' && paymentType === PAYMENT_TYPES?.CARD_MXN;
  const transfPEN = paymentMethod === PAYMENT_TYPES?.TRANSFER_PEN;
  const transfARS = paymentMethod === PAYMENT_TYPES?.TRANSFER_ARS;
  const displayQuotationButton = !paymentMethodIsStripe && !transfCOP && !transfPEN;
  const displayTransfDetails = transfMX || transfCOP || transfPEN || transfUSD;

  const orderText = t(`${translationKey}containerOrderId`);
  const withWord = t(`nodi:dynamicBanner:with`);
  const shipmentsWord = t(`recurrentWords:shipments`);

  const destinationCountries = summary?.shippingsList?.map(item => item?.countryName);
  const orderTextId = `${orderText} ${orderId} ${withWord} ${shippings} ${shipmentsWord}`;

  const resetStates = () => localStorage.setItem('savedStep', '0');

  const initialPaymentMethod = () => {
    if (paymentType) {
      setPaymentMethod(paymentType);
      resetStates();
    }

    if (!dataFinishCart) {
      resetStates();
      push('/catalogue/home');
    }
  };

  const downloadOrderSummary = async () => {
    if (!orderId) return;
    setLoadingOrderSummary(true);
    try {
      const { url } = await requestOrderSummaryPDF(orderId);
      if (url) {
        setOrderSummaryPDFUrl(url);
        window.open(url, 'blank');
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOrderSummary(false);
    }
  };

  const handleClickRequestQuotation = () => {
    if (orderSummaryPDFUrl) window.open(orderSummaryPDFUrl, 'blank');
    else downloadOrderSummary();
  };
  const setPaymentmethod = () => {
    if (paymentMethod === PAYMENT_TYPES?.TRANSFER_MXN) setTransfMX(true);
    if (paymentMethod === PAYMENT_TYPES?.TRANSFER_USD) setTransfUSD(true);
    if (paymentMethod === PAYMENT_TYPES?.CARD_MXN) setNationalCreditCard(true);
    if (paymentMethod === PAYMENT_TYPES?.INTERNATIONAL_CARD) setInternationalCreditCard(true);
    if (paymentMethod === PAYMENT_TYPES?.CARD_COP) setCreditCardCOP(true);
    if (paymentMethod === PAYMENT_TYPES?.TRANSFER_COP) setTransfCOP(true);
  };

  useEffect(() => {
    setPaymentmethod();
  }, [paymentMethod]);

  useEffect(() => {
    initialPaymentMethod();
  }, [dataFinishCart]);

  useEffect(() => {
    return () => setDataFinishCart(undefined);
  }, []);

  useEffect(() => {
    segmentTrackEvent({
      [!isPrime ? 'free4CheckoutResumeView' : 'prime4CheckoutResumeView']: {
        PaymentMethods: paymentType,
        DestinationCountry: destinationCountries
      }
    });
  }, []);

  return (
    <div id="step4FinishCart">
      <div className="boxFinishCart">
        <div className="containerInfo">
          <div className="containerTitle">{containerTitle}</div>
          <div className="containerOrderId" style={{ marginBottom: internationalCreditCard ? '32px' : '' }}>
            {orderTextId}
          </div>
          {nationalCreditCard && (
            <div className="confirmationSubBlack">
              <Trans i18nKey={t(`${translationKey}confirmationSubBlack`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(internationalCreditCard || creditCardCOP) && (
            <div className="confirmationSub">
              <Trans i18nKey={t(`${translationKey}confirmationSub`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(transfMX || transfCOP) && (
            <div className="containerInfoAccount">
              <Trans i18nKey={t(`${translationKey}containerInfoAccountMx`)} components={{ 1: <br /> }} />
            </div>
          )}
          {(transfUSD || transfARS) && (
            <>
              <div className="containerInfoAccount">
                <Trans i18nKey={t(`${translationKey}containerInfoAccountArs`)} components={{ 2: <br /> }} />
              </div>
            </>
          )}
          <div className="dateContainer">
            <div className="dateBoxContainer">
              <BordOneToneIcon standardSize={14} variant="calendar" />
              <div className="blackTitle">Entrega estimada: </div>

              {storeCountry && (
                <div className="flagContainer">
                  <div className="flagBg" style={{ backgroundImage: `url("${storeCountry?.countryFlag}")` }} />
                </div>
              )}
              <div className="flagAndDaysContianer">
                {`${storeCountry ? storeCountry?.countryName : '📆'}: ${deliveryDayRange} días hábiles`}{' '}
              </div>
            </div>
          </div>
          {displayTransfDetails && (
            <div className="boxAccount">
              <NudosTransfDetails specialData={dataFinishCart} />
            </div>
          )}
          {!transfARS && (
            <div className="blueAlertBox">
              <div className="blueAlertItemsContainer">
                <div className="iconSubtitle">
                  <BordOneToneIcon variant="info" standardSize={14} />
                </div>
                <div className="orangeSubtitle">
                  Realiza el pago para confirmar tu orden. En caso de tener acuerdo de pago, se confirma automáticamente
                </div>
              </div>
            </div>
          )}

          <div className="containerGraySubtitle">
            <div className="graySubtitleBox">
              <div className="graySubtitle">{graySubtitle}</div>
            </div>
          </div>
          {displayQuotationButton && (
            <BordButton
              modeButton="tertiary"
              label={nudosButtonText}
              buttonIconTwo="download"
              customClassName="requestOrderSummaryButton"
              onClick={handleClickRequestQuotation}
              isLoading={loadingOrderSummary}
              disabled={loadingOrderSummary}
              customFontSize="1.4rem"
            />
          )}
          <div className="dividerBox">
            <div className="blueDivider" />
          </div>
          <div className="containerButtons">
            <BordButton
              customWidth="w-380"
              customHeight="42"
              onClick={() => {
                if (orderId) push(`/nodi/orders/details/${orderId}`);
              }}
              label={orangeButtonText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4FinishCart;
