import { useTranslation } from 'react-i18next';
import BordStatus from '../../../../../components/BordDesignSystem/BordStatus/BordStatus';

/**
 * @property {string} statusStringId - Required, the status string id of the order status translation
 * @property {string} statusName - Required, the status name of the order
 */
const LogisticsStatusDisplay = ({ statusStringId, statusName }: { statusStringId?: string; statusName: string }) => {
  const { t } = useTranslation();

  const translationKey = 'services:logisticServiceStatus:';
  const statusText = statusStringId ? t(`${translationKey}${statusStringId}`) : statusName;

  const getStatusVariant = (status?: string) => {
    switch (status) {
      case 'por confirmar':
      case 'confirmado':
      case 'confirmada':
        return 'default';
      case 'activo':
      case 'activa':
      case 'en proceso':
        return 'informative';
      case 'entregado':
      case 'entregada':
        return 'success';
      case 'cancelado':
      case 'cancelada':
        return 'danger';
      default:
        return 'default';
    }
  };
  return (
    <div className="logisticsStatusDisplay">
      <BordStatus variant={getStatusVariant(statusName)} label={statusText} />
    </div>
  );
};
export default LogisticsStatusDisplay;
