import { useEffect, useState } from 'react';
import { IbillingData, SPECIAL_BUSINESS_NAME_TYPES } from '../../../types/checkout';
import { getOrganizationAccount } from '../../../services/account';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { NudosHoverText } from '../../NudosComponents';
import { BordButton, BordPhoto } from '../../BordDesignSystem';
import { useTranslation } from 'react-i18next';
import NudosStaticAlertInComponent from '../NudosStaticAlertInComponent/NudosStaticAlertInComponent';
import useStoreCountryState from '../../../state/useStoreCountryState';
import { useLocation } from 'react-router-dom';
import SelectOtherBillingCountryForLogisticService from '../../../views/RequestLogistics/LogisticsQuoteAndBillingStep/LogisticServiceBillingData/Components/SelectOtherBillingCountryForLogisticService/SelectOtherBillingCountryForLogisticService';
import { TlogisticsOriginOrDestinationData } from '../../../types/requestLogisticsModule';
import { TcountryCode } from '../../BordDesignSystem/BordFlag/BordFlag.types';

import './NudosBillingDataSummaryComponent.scss';

/**
 * @param {Object} props - The properties object.
 * @param {IbillingData} props.billingData - The billing data to be displayed.
 * @param {TlogisticsOriginOrDestinationData} props.detinationData - The destination data related to logistics.
 * @param {Function} props.openBillingDataModal - Function to open the billing data modal.
 * @param {Function} props.cleanUpdatedBillingData - Function to clean the updated billing data.
 * @returns {JSX.Element} The rendered component.
 */

const NudosBillingDataSummaryComponent = ({
  billingData,
  originData,
  detinationData,
  openBillingDataModal,
  cleanUpdatedBillingData
}: {
  billingData?: IbillingData;
  originData?: TlogisticsOriginOrDestinationData;
  detinationData?: TlogisticsOriginOrDestinationData;
  openBillingDataModal?: () => void;
  cleanUpdatedBillingData?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const orgData = getOrgData();
  const { storeCountry } = useStoreCountryState();
  const { pathname } = useLocation();

  const [organizationImg, setOrganizationImg] = useState<string>();

  const { documentCountry, businessName, specialBusinessNameType, document, email } = billingData || {};
  const countryCode = documentCountry?.code as TcountryCode;
  const billingBusinessName = businessName || '';
  const translationKey = 'designSystemComponents:billingDataSummaryComponent:';
  const isCheckoutFlux = pathname.includes('catalogue');
  const isLogisticFLux = pathname.includes('nodi');

  const displaySpecialBusinessAlert =
    specialBusinessNameType?.id === SPECIAL_BUSINESS_NAME_TYPES?.FREE_ZONE &&
    storeCountry?.countryCode === 'uy' &&
    isCheckoutFlux;

  const getInfoCompany = async () => {
    const infoCompany = await getOrganizationAccount(orgData.organizationId);
    setOrganizationImg(infoCompany?.image || '');
  };

  useEffect(() => {
    getInfoCompany();
  }, []);

  return (
    <div className="NudosBillingDataSummaryComponent">
      <div className="billingDataSummaryContainer">
        <div className="userInformation">
          <div className="photoAndFlag">
            <BordPhoto
              url={organizationImg}
              variant="square"
              size="s56"
              avatarProps={{ variant: 'rectangularBordMascot', customWidth: '7rem' }}
              flagProps={{
                country: countryCode,
                variant: 'circle',
                standardSize: 24
              }}
              imageProps={{
                style: {
                  borderRadius: '1.1rem',
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                  objectPosition: 'center center'
                }
              }}
            />
          </div>
          <div className="billingNameAndEmailContainer">
            <NudosHoverText
              onlyIfTruncated
              customClassName="userName"
              text={billingBusinessName}
              charactersLimit={15}
            />
            <div className="userDocument">{document}</div>
          </div>
        </div>
        <div className="billingRightSection">
          <div className="userEmail">{t(`${translationKey}billingEmail`)}</div>
          <div className="userDataEmail"> {email}</div>
        </div>
      </div>
      <div className="buttonContainerBilling">
        <BordButton
          label={t(`${translationKey}nudosButtonText`)}
          onClick={openBillingDataModal}
          customHeight="42"
          customWidth="w-180"
          modeButton="primary"
          bordButtonStyle="outlined"
        />
      </div>
      <div className="changeBillingDataButton" onClick={cleanUpdatedBillingData}>
        {t(`${translationKey}changeBillingDataButton`)}
      </div>
      {displaySpecialBusinessAlert && (
        <NudosStaticAlertInComponent
          customClassname="specialBusinessAlert"
          type="information"
          color="blue"
          alertOptions={{
            title: 'Tu razón social fue marcada como Zona Franca, por tanto no cobraremos el 22% de impuestos'
          }}
        />
      )}
      {isLogisticFLux && (
        <>
          <div className="blueLineContainer">
            <div className="blueLine" />
          </div>
          <SelectOtherBillingCountryForLogisticService
            billingData={billingData}
            detinationData={detinationData}
            originData={originData}
          />
        </>
      )}
    </div>
  );
};

export default NudosBillingDataSummaryComponent;
