const SquareKyrgyzstan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M10.6919 10.1175C10.7935 10.1282 10.8962 10.1335 10.9999 10.1335C11.1047 10.1335 11.2063 10.1282 11.3079 10.1175L10.9999 9.3335L10.6919 10.1175ZM9.27134 9.53616C8.72343 9.09456 8.38086 8.4503 8.38086 7.7335C8.38086 6.88763 8.85857 6.14416 9.57934 5.7175L10.4761 8.00016L9.27134 9.53616ZM12.4205 5.7175C13.1412 6.14416 13.619 6.88763 13.619 7.7335C13.619 8.45136 13.2753 9.09563 12.7285 9.53616L11.5237 8.00016L12.4205 5.7175ZM12.3157 5.65776C11.9281 5.45083 11.4797 5.3335 10.9999 5.3335C10.5201 5.3335 10.0707 5.45083 9.6841 5.65776L10.9999 7.33243L12.3157 5.65776Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M11.0004 12.8002C13.6038 12.8002 15.7147 10.6509 15.7147 8.0002C15.7147 5.34953 13.6038 3.2002 11.0004 3.2002C8.39709 3.2002 6.28613 5.34953 6.28613 8.0002C6.28613 10.6509 8.39709 12.8002 11.0004 12.8002ZM11.0004 11.7335C13.0255 11.7335 14.6671 10.0621 14.6671 8.0002C14.6671 5.93833 13.0255 4.26686 11.0004 4.26686C8.97537 4.26686 7.33375 5.93833 7.33375 8.0002C7.33375 10.0621 8.97537 11.7335 11.0004 11.7335Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareKyrgyzstan;
