import { useEffect, useState } from 'react';
import { DTOpostManualAdjustmentAndComments } from '../../../../../../../types/tools.types';
import { BordTextArea, ModalContent, BordModal } from '../../../../../../../components/BordDesignSystem';
import './ManualAdjustmentModal.scss';

const ManualAdjustmentModal = ({
  closeModalCallback,
  locationLogId,
  preloadedData,
  loadingInManualAdjustmentModal,
  modalSubmitCallback
}: {
  closeModalCallback: () => void;
  locationLogId?: string | number;
  preloadedData?: string;
  loadingInManualAdjustmentModal?: boolean;
  modalSubmitCallback: (locationLogId: string | number, body: DTOpostManualAdjustmentAndComments) => Promise<void>;
}) => {
  const [comments, setComments] = useState('');

  const handleClickContinue = () => {
    if (!locationLogId) return;
    const body = {
      comments,
      manualAdjustment: true
    };
    modalSubmitCallback(locationLogId, body);
  };

  return (
    <BordModal
      modalContent={
        <ModalContent
          title="Ajuste manual"
          subTitle="Agrega alguna nota sobre el cambio manual"
          CustomModalComponent={
            <ManualAdjustmentForm
              preloadedData={preloadedData}
              locationLogId={locationLogId}
              loadingInManualAdjustmentModal={loadingInManualAdjustmentModal}
              comments={comments}
              setComments={setComments}
              handleClickContinue={handleClickContinue}
            />
          }
          toCloseModal={closeModalCallback}
          showButtonTwo
          buttonTwoProps={{
            label: 'Guardar',
            onClick: handleClickContinue,
            isLoading: loadingInManualAdjustmentModal,
            disabled: !locationLogId
          }}
        />
      }
    />
  );
};

export default ManualAdjustmentModal;

const ManualAdjustmentForm = ({
  preloadedData,
  comments,
  setComments
}: {
  locationLogId?: string | number;
  preloadedData?: string;
  loadingInManualAdjustmentModal?: boolean;
  comments: string;
  setComments: React.Dispatch<React.SetStateAction<string>>;
  handleClickContinue: () => void;
}) => {
  useEffect(() => {
    if (preloadedData && !comments) setComments(preloadedData);
  }, [preloadedData]);

  return (
    <div className="manualAdjustmentForm">
      <h2 className="subtitle">Comentarios</h2>
      <BordTextArea
        placeholder="Escribe un comentario que te ayude a identificar por que el ajuste manual."
        value={comments}
        setInputValue={setComments}
        maxLength={500}
      />
    </div>
  );
};
