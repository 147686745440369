import { useEffect } from 'react';
import useSWR from 'swr';
import { getLogisticsServices } from '../../../../services/logisticsServicesModule.services';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../../utils/getLocalStorageData';
import LogisticsServicesFilters from './Components/OrdersFilters/LogisticsServicesFilters';
import { useLogisticsServicesModuleState } from '../../../../state/useLogisticsServicesModuleState';
import {
  LogisticServiceCardInListing,
  LogisticServiceCardInListingSkeletonLoader
} from './Components/LogisticServiceCardInListing/LogisticServiceCardInListing';
import ZeroLogisticServices from './Components/ZeroLogisticServices/ZeroLogisticServices';
import './LogisticsServicesListing.scss';

const LogisticsServicesListing = () => {
  const { logisticsServicesFilters, setLogisticsServicesNumber } = useLogisticsServicesModuleState();
  const orgData = getOrgData();
  const logisticsFiltersString =
    logisticsServicesFilters && logisticsServicesFilters.length ? JSON.stringify(logisticsServicesFilters) : '';
  const filtersQuery = logisticsFiltersString ? `?filters=${logisticsFiltersString}` : '';
  const requestKey = `${orgData?.organizationId || ''}${filtersQuery}`;
  const { data: logisticsServices, error, isValidating } = useSWR(requestKey, getLogisticsServices);

  const noResults = !logisticsServices || !logisticsServices?.length;
  const isLoading = noResults && isValidating;
  const noDataToShow = noResults && !isValidating;
  const hasFiltersApplied = !!logisticsServicesFilters?.length;

  useEffect(() => {
    error && displayErrorNotification();
  }, [error]);

  useEffect(() => {
    logisticsServices && setLogisticsServicesNumber(logisticsServices?.length);
  }, [logisticsServices]);

  return (
    <div className="logisticsServicesListing moduleWithSidebar">
      <LogisticsServicesFilters />
      <div className="logisticsServicesList">
        {noDataToShow && <ZeroLogisticServices hasFiltersApplied={hasFiltersApplied} />}
        {isLoading &&
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(i => {
            return <LogisticServiceCardInListingSkeletonLoader key={`skeletonCard${i}`} />;
          })}
        {logisticsServices?.map((logisticsService, i) => {
          return (
            <LogisticServiceCardInListing
              key={`logisticsService${i}${logisticsService.id}`}
              logisticsServiceData={logisticsService}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LogisticsServicesListing;
