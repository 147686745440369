import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BordEmptyState from '../../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import { handleClickForHelp } from '../../../../../../utils/global.utils';
import { IBordIlustrationIconProps } from '../../../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustration.types';
import { IBordButton } from '../../../../../../components/BordDesignSystem/BordButton/BordButton.types';
import './ZeroLogisticServices.scss';

const ZeroLogisticServices = ({ hasFiltersApplied }: { hasFiltersApplied: boolean }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const recurrentWords = `recurrentWords:`;
  const translationKey = 'nodi:logisticServices:logisticServicesListing:zeroLogisticServices:';

  const title = hasFiltersApplied ? t(`${recurrentWords}noSearchOption`) : t(`${translationKey}noPreviousLsTitle`);
  const labelButton1 = hasFiltersApplied ? t(`${recurrentWords}supportButton`) : t(`${recurrentWords}goToInventory`);
  const subtitle = hasFiltersApplied
    ? t(`${recurrentWords}noFilteredResultsSubtitle`)
    : t(`${translationKey}noPreviousLsSubtitle`);

  const buttonIcon = hasFiltersApplied ? 'helpCircle' : 'monitor';
  const ilustrationIconProps = hasFiltersApplied
    ? {}
    : { ilustrationIconProps: { variant: 'pin', standardSize: 100 } as IBordIlustrationIconProps };

  const goToEmployees = () => push('/nodi/employees');
  const goToInventory = () => push('/nodi/tools');

  const secondButtonProps = hasFiltersApplied
    ? {}
    : {
        buttonTwoProps: {
          label: t(`${recurrentWords}goToEmployees`),
          onClick: goToEmployees,
          customWidth: 'w-280',
          buttonIconTwo: 'user'
        } as IBordButton
      };

  const onClickButton1 = hasFiltersApplied ? handleClickForHelp : goToInventory;

  return (
    <BordEmptyState
      customClassNameBordEmptyStateContainer="zeroLogisticServices"
      title={title}
      subTitle={subtitle}
      buttonOneProps={{
        label: labelButton1,
        onClick: onClickButton1,
        modeButton: 'primary',
        bordButtonStyle: 'outlined',
        customWidth: 'w-280',
        buttonIconTwo: buttonIcon
      }}
      {...ilustrationIconProps}
      {...secondButtonProps}
    />
  );
};

export default ZeroLogisticServices;
