import { FC } from 'react';
import { IProductUpdate } from '../../../../../../../types/cart';
import { CheckoutProductItemCard } from '../CheckoutProductItemCard/CheckoutProductItemCard';

import './ListOfProductsInTheCheckout.scss';

interface IDataCard {
  referencesList: IProductUpdate[];
}
const ListOfProductsInTheCheckout: FC<IDataCard> = ({ referencesList }: IDataCard) => {
  return (
    <div className="listOfProductsInTheCheckout">
      {referencesList?.length > 0 &&
        referencesList.map((item, i) => {
          return <CheckoutProductItemCard key={`country${item.countryId}Item${item.productId}${i}`} itemData={item} />;
        })}
    </div>
  );
};

export { ListOfProductsInTheCheckout };
