import { isTruncated, truncateText } from '../../../utils/truncateText';
import './NudosHoverText.scss';

/**
 * @property {string} text - Required, the text that will be shwon when hovered
 * @property {number} charactersLimit - Required, the limit of characters after which the text will be truncated with ellipsis
 * @property {boolean} onlyIfTruncated - Optional, a boolean indicating if the hover should only applied if the text is truncated. Default is FALSE.
 * @property {string} customClassName -Optional, the className to personalize the styles of the text element
 */
const NudosHoverText = ({
  text,
  charactersLimit,
  onlyIfTruncated,
  customClassName
}: {
  text: string;
  charactersLimit: number;
  onlyIfTruncated?: boolean;
  customClassName?: string;
}) => {
  return (
    <div className={`nudosHoverText ${customClassName || ''}`}>
      {truncateText(text, charactersLimit)}
      {onlyIfTruncated && isTruncated(text, charactersLimit) && <div className="textHover">{text}</div>}
      {!onlyIfTruncated && <div className="textHover">{text}</div>}
    </div>
  );
};

export default NudosHoverText;
