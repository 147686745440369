import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import './ProductTags.scss';

interface IFormatTags {
  name: string;
  value?: string;
}
export interface IProductTags {
  tagsProfiles?: IFormatTags[];
}

const ProductTags: FC<IProductTags> = ({ tagsProfiles }: IProductTags) => {
  const { t } = useTranslation();
  const translationKey = 'nodi:checkout:';

  return (
    <div id="productTags">
      <div className="title">Tags:</div>
      <div className="tagsContainer">
        <div className="tagsBox">
          <div className="tagIndividualItem">{t(`${translationKey}product`)}</div>
          <div className="tagIndividualItem">{t(`${translationKey}tech`)}</div>
          {tagsProfiles &&
            tagsProfiles?.length > 0 &&
            tagsProfiles.map((item, index) => {
              if (index === 1 || index === 2)
                return (
                  <div key={`tag-${item.name}-${index}`} className="tagIndividualItem">
                    {item?.value}
                  </div>
                );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductTags;
