import { ReactNode } from 'react';
import { TbordOneToneIcon } from '../BordOneToneIcon/BordOneToneIcon.types';

export enum CTA_TYPE {
  noCta = 'noCta',
  oneCta = 'oneCta',
  twoCta = 'twoCta'
}

export type TtooltipPositions = 'top' | 'bottom' | 'left' | 'right' | 'bottomRight' | 'bottomLeft';

export interface IBordTooltipProps {
  message?: string | JSX.Element;
  position?: TtooltipPositions;
  children: ReactNode;
  ctaType?: 'noCta' | 'oneCta' | 'twoCta';
  ctaLabel?: string;
  oneCtaVariant?: TbordOneToneIcon | undefined;
  ctaAction?: () => void;
  ctaButtonOneLabel?: string;
  ctaButtonOneAction?: () => void;
  ctaButtonTwoLabel?: string;
  ctaButtonTwoAction?: () => void;
  customStyles?: string;
}
