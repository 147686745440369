import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordLogoMaster = (props: ComponentProps<'svg'>) => {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
      <path
        fill={fill || bordColorTheme.navyBlue[400]}
        d="M16.0423.28125H9.00074c-2.1069 0-4.03904.74665-5.54624 1.99172C1.51639 3.87152.28125 6.29217.28125 9.00075c0 2.10695.74664 4.03905 1.99172 5.54625.35545.4309.75062.8261 1.18153 1.1815 1.5072 1.2431 3.43934 1.9918 5.54624 1.9918 2.70856 0 5.12926-1.2352 6.72776-3.1733 1.2431-1.5072 1.9917-3.4393 1.9917-5.54625V1.95923c0-.92736-.7506-1.67798-1.6799-1.67798h.002Zm-1.6462 8.7195c0 1.31065-.4647 2.51395-1.2392 3.45325-.9948 1.2074-2.502 1.9758-4.18792 1.9758-1.31061 0-2.51399-.4646-3.45325-1.2391-.26808-.2204-.51432-.4686-.73673-.7367-.77445-.9393-1.23911-2.1407-1.23911-3.45325 0-1.68591.76849-3.19311 1.97584-4.18798.93926-.77445 2.14065-1.23912 3.45325-1.23912 2.99652 0 5.42712 2.43058 5.42712 5.4271Z"
      />
    </svg>
  );
};

export default BordLogoMaster;
