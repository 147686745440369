const RoundKyrgyzstan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M23.8244 15.9999L20.6272 17.5038L22.3298 20.6005L18.8577 19.9361L18.418 23.4434L15.9996 20.864L13.5813 23.4434L13.1414 19.9361L9.66962 20.6003L11.3721 17.5036L8.1748 15.9999L11.3721 14.4961L9.66962 11.3993L13.1412 12.0637L13.5814 8.5564L15.9996 11.1358L18.4181 8.5564L18.8577 12.0637L22.3299 11.3993L20.6273 14.4963L23.8244 15.9999Z"
        />
        <path
          fill="#F93939"
          d="M16.0004 20.8695C18.6898 20.8695 20.87 18.6893 20.87 15.9999C20.87 13.3105 18.6898 11.1304 16.0004 11.1304C13.311 11.1304 11.1309 13.3105 11.1309 15.9999C11.1309 18.6893 13.311 20.8695 16.0004 20.8695Z"
        />
        <path
          fill="#FFDA2C"
          d="M13.5652 16C13.4479 16 13.3321 16.006 13.2178 16.0174 13.222 16.6918 13.466 17.3091 13.869 17.7887 14.084 17.2101 14.405 16.6826 14.8098 16.2311 14.4231 16.0824 14.0037 16 13.5652 16ZM15.0645 18.6207C15.357 18.7252 15.6719 18.7825 16.0004 18.7825 16.3289 18.7825 16.6438 18.7252 16.9363 18.6207 16.7769 17.9949 16.4477 17.4363 16.0004 16.9973 15.5531 17.4363 15.2239 17.9949 15.0645 18.6207ZM18.4102 14.609C17.9291 13.7774 17.0305 13.2173 16.0005 13.2173 14.9705 13.2173 14.0719 13.7773 13.5908 14.609 14.4679 14.6135 15.2904 14.852 16.0005 15.2638 16.7106 14.852 17.5329 14.6135 18.4102 14.609ZM17.1904 16.2311C17.5952 16.6825 17.9162 17.2101 18.1312 17.7887 18.5342 17.3091 18.7782 16.6918 18.7824 16.0174 18.6681 16.0059 18.5522 16 18.435 16 17.9966 15.9999 17.5772 16.0823 17.1904 16.2311Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundKyrgyzstan;
