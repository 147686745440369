import './NudosGenericTag.scss';

/**
 * @property { string | JSX.Element } text - the text to shown in the tag
 * @property { string } backgroundColor - a valid value for the background-color property
 * @property { string } customClassName - a classname for customizing the styles
 * @property { string } fontColor - a valid value for the color property. DEFAULT is #383838.
 * @property { string } customWidth - a valid value for the width property. DEFAULT is 60px.
 * @property { string } customHeight - a valid value for the height property. DEFAULT is 18px.
 * @property { string } customFontSize - a valid value for the font-size property. DEFAULT is 8px.
 * @property { JSX.Element } hoverElement - a JSX.Element to show in when hovered. The styles of the hoverElement must be managed in its parent component scss file
 */
const NudosGenericTag = ({
  text,
  backgroundColor,
  customClassName,
  fontColor,
  customWidth,
  customHeight,
  customFontSize,
  hoverElement
}: InudosGenericTag) => {
  const nudosGenericTagStyles = {
    backgroundColor: backgroundColor,
    color: fontColor,
    width: customWidth,
    height: customHeight,
    fontSize: customFontSize
  };

  return (
    <div className={`NudosGenericTag ${customClassName || ''}`} style={nudosGenericTagStyles}>
      {text}
      {hoverElement && <div className="hoverElement">{hoverElement}</div>}
    </div>
  );
};

export default NudosGenericTag;

interface InudosGenericTag {
  text: string | JSX.Element;
  backgroundColor: string;
  customClassName?: string;
  fontColor?: string;
  customWidth?: string;
  customHeight?: string;
  customFontSize?: string;
  hoverElement?: JSX.Element;
}
