const RoundPanama = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#1A47B8" d="M0 16C0 24.8365 7.1635 32 16 32C16 25.9153 16 16 16 16C16 16 5.56519 16 0 16Z" />
        <path fill="#F93939" d="M16 0C24.8365 0 32 7.1635 32 16C25.9153 16 16 16 16 16C16 16 16 5.56519 16 0Z" />
        <path
          fill="#1A47B8"
          d="M9.52449 5.56519L10.5606 8.75381H13.9132L11.2009 10.7244L12.2369 13.9131L9.52449 11.9424L6.81212 13.9131L7.84812 10.7244L5.13574 8.75381H8.48843L9.52449 5.56519Z"
        />
        <path
          fill="#F93939"
          d="M22.4757 18.0869L23.5117 21.2755H26.8644L24.152 23.2462L25.188 26.4348L22.4757 24.4641L19.7633 26.4348L20.7993 23.2462L18.0869 21.2755H21.4396L22.4757 18.0869Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPanama;
