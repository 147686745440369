import { FC } from 'react';
import { BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import './EmployeeDetail.scss';

interface IEmployeeDetail {
  employeInfo: {
    name: string;
    lastName: string;
    email: string;
    lada: string;
    phone: string;
    area: string;
    position: string;
    country: string;
    city: string;
  };
  success: boolean;
}

const EmployeeDetail: FC<IEmployeeDetail> = ({ employeInfo, success }: IEmployeeDetail) => {
  return (
    <div id="employeeDetail">
      <div className={`successContainer ${success ? 'greenIcon' : 'redIcon'}`}>
        <BordOneToneIcon standardSize={14} variant={success ? 'checkCircle' : 'xCircle'} />
      </div>
      <div className={`tableNameEmployee sucessBorder`}>{employeInfo?.name}</div>
      <div className={`tableLastNameEmployee sucessBorder`}>{employeInfo?.lastName}</div>
      <div className={`tableEmailEmployee ${success ? 'sucessBorder' : 'errorBorder'} `}>{employeInfo?.email}</div>
      <div className={`tableLadaEmployee sucessBorder`}>{employeInfo?.lada}</div>
      <div className={`tablePhoneEmployee ${success ? 'sucessBorder' : 'errorBorder'} `}>{employeInfo?.phone}</div>
      <div className={`tableAreaEmployee sucessBorder`}>{employeInfo?.area}</div>
      <div className={`tablePositionEmployee sucessBorder`}>{employeInfo?.position}</div>
      <div className={`tableCountryEmployee sucessBorder`}>{employeInfo?.country}</div>
      <div className={`tableCityEmployee ${success ? 'sucessBorder' : 'errorBorder'}`}>{employeInfo?.city}</div>
    </div>
  );
};

export default EmployeeDetail;
