export const modulesWithoutSidebar = ['catalogue/checkout', 'catalogue/product', 'catalogue/search'];

export const modulesWithClosedSidebar = [
  'upload-external-tools',
  'employees/create',
  'employees/edit',
  'orders/details',
  'logistics-services/details',
  'request-logistics',
  'not-found',
  'upload-employees',
  'nodi/details'
];

export const pathIncludeSomeValueInArray = (routes: string[], path: string) => {
  return routes.some(arrayValue => path.includes(arrayValue));
};

export const shouldShowSidebarClosed = (path: string) => pathIncludeSomeValueInArray(modulesWithClosedSidebar, path);
export const shouldHideSidebar = (path: string) => pathIncludeSomeValueInArray(modulesWithoutSidebar, path);
