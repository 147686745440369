const RoundBonaire = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#FFDA2C"
          d="M4.68636 4.68637C1.2573 8.11544-.28939 12.7138.0451726 17.1979L17.1979.0452489C12.7137-.289376 8.11542 1.25731 4.68636 4.68637Z"
        />
        <path
          fill="#1A47B8"
          d="M5.71313 28.2544c6.28627 5.2889 15.68377 4.9761 21.60067-.9407 5.9168-5.9168 6.2296-15.3143.9407-21.60057L5.71313 28.2544Z"
        />
        <path
          fill="#000"
          d="m15.943 15.3556 1.3788-.7469-1.3788-.7468c-.322-2.0914-1.9743-3.7438-4.0657-4.06567l-.7469-1.37887-.7469 1.37881c-2.09122.32183-3.7436 1.97433-4.0656 4.06563l-1.37887.7469 1.37881.7469c.32194 2.0913 1.97431 3.7438 4.06566 4.0656l.7469 1.3789.7469-1.3788c2.0914-.3219 3.7437-1.9744 4.0657-4.0657Zm-4.8125 2.0358c-1.53685 0-2.78266-1.2458-2.78266-2.7827 0-1.5368 1.24575-2.7826 2.78266-2.7826 1.5368 0 2.7826 1.2458 2.7826 2.7826 0 1.5369-1.2458 2.7827-2.7826 2.7827Z"
        />
        <path
          fill="#F93939"
          d="m11.1304 12.5217.6025 1.0435h1.2049l-.6025 1.0435.6025 1.0435h-1.2049l-.6025 1.0434-.6024-1.0434H9.32312l.60244-1.0435-.60244-1.0435H10.528l.6024-1.0435Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBonaire;
