const calculateLowerMultiple = (price: number) => {
  const discountValue = price / 0.97;
  const calcPriceValue = inferiorMat(discountValue, 5);
  return calcPriceValue;
};

const inferiorMat = (a: number, b: number) => {
  const result = Math.floor(a / b) * b;
  return result;
};

export { calculateLowerMultiple };
