import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import useCheckoutStore from './state';
import { ObjectKey } from '../../../types/global';
import { ModalInvalidCart } from './Components';
import useCheckoutController from './checkout.controller';
import { Step1ShoppingCart } from './Components/Step1ShoppingCart';
import CheckoutProgressBar from './Components/CheckoutProgressBar';
import { Step2ShippingCart } from './Components/Step2ShippingCart';
import { Step3InvoiceCart } from './Components/Step3InvoiceCart';
import { Step4FinishCart } from './Components/Step4FinishCart';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { getShoppingCart } from '../../../services/cart.services';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import useShopStore from '../../../state/useShopContext';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { IconXInSquare } from '../../../assets/DesignSystem/SVGComponents';
import { GenericLoader } from '../../Admin/dashboard/components';
import { putProcessOrder, putVerifyStripePaymentStatus } from '../../../services/checkout';
import useCartState from '../../../state/useCartState';
import { IbillingData, STRIPE_PAYMENT_PROVIDERS } from '../../../types/checkout';
import { getEstimatedDeliveredDate } from '../../../services/catalogue.services';
import useStoreCountryState from '../../../state/useStoreCountryState';
import { stripeMX, stripeUSAC, stripeUSAL } from '../../../constants';
import { productsNumber } from '../../../utils/cart';
import BordEmptyState from '../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';

import './CheckoutModule.scss';

const stripePromiseMX = loadStripe(`${stripeMX || ''}`);
const stripePromiseUC = loadStripe(`${stripeUSAC || ''}`);
const stripePromiseUL = loadStripe(`${stripeUSAL || ''}`);

function CheckoutModule() {
  const { search } = useLocation();
  const { step, user, setStep, setPaymentSession, paymentSession } = useCheckoutStore();
  const [selectStripeProvider, setSelectStripeProvider] = useState<STRIPE_PAYMENT_PROVIDERS>();
  const { setCart } = useShopStore();
  const { push } = useHistory();

  const { storeCountry } = useStoreCountryState();
  const { setDataFinishCart } = useCartState();
  const { getInfo, cancelStripeCheckoutPaymentSession, deleteAndCreateNewCart, resetPaymentSession } =
    useCheckoutController();
  const { clientSecret, orderId } = paymentSession || {};
  const orgInfo = getOrgData();
  const urlSearchParams = new URLSearchParams(search);
  const submittedSessionId = urlSearchParams.get('session_id');
  const totalItems = productsNumber();
  const [listOfCountriesOpenForBuying, setListOfCountriesOpenForBuying] = useState<ICountryDetail[]>();
  const [completeListOfCountries, setCompleteListOfCountries] = useState<ICountryDetail[]>();
  const [paymentSessionIsLoading, setPaymentSessionIsLoading] = useState(false);
  const [billingDataByCountry, setBillingDataByCountry] = useState<IbillingData>();
  const [billingDataWasSubmited, setBillingDataWasSubmited] = useState(false);
  const [showBillingData, setShowBillingData] = useState<boolean>(true);
  const [selectedBillingCountry, setSelectedBillingCountry] = useState<ICountryDetail>();
  const [openInvalidCard, setOpenInvalidCard] = useState<boolean>(false);
  const [deliveryDayRange, setDeliveryDayRange] = useState<string>('4-10');

  const options = clientSecret ? { clientSecret } : undefined;

  const getUserCart = async () => {
    if (!orgInfo) return;
    try {
      const dataCart = await getShoppingCart(orgInfo?.userId, orgInfo?.organizationId);
      setCart(dataCart);
      localStorage.setItem('bagItems', JSON.stringify(dataCart));
    } catch (error) {
      displayErrorNotification();
    }
  };

  const expirePaymentSession = async () => {
    await cancelStripeCheckoutPaymentSession(setPaymentSessionIsLoading);
  };

  const openModalInvalidCart = () => {
    setOpenInvalidCard(true);
  };

  const handleChangeSelectPromiseStripe = (stripeProvider: STRIPE_PAYMENT_PROVIDERS) => {
    setSelectStripeProvider(stripeProvider);
  };

  const steps: ObjectKey<JSX.Element> = {
    0: <Step1ShoppingCart openModalInvalidCart={openModalInvalidCart} />,
    1: (
      <Step2ShippingCart
        listOfCountriesOpenForBuying={listOfCountriesOpenForBuying}
        completeListOfCountries={completeListOfCountries || []}
        openModalInvalidCart={openModalInvalidCart}
      />
    ),
    2: (
      <Step3InvoiceCart
        listOfCountriesOpenForBuying={listOfCountriesOpenForBuying}
        completeListOfCountries={completeListOfCountries}
        billingDataByCountry={billingDataByCountry}
        setBillingDataByCountry={setBillingDataByCountry}
        billingDataWasSubmited={billingDataWasSubmited}
        setBillingDataWasSubmited={setBillingDataWasSubmited}
        showBillingData={showBillingData}
        setShowBillingData={setShowBillingData}
        selectedBillingCountry={selectedBillingCountry}
        setSelectedBillingCountry={setSelectedBillingCountry}
        openModalInvalidCart={openModalInvalidCart}
        handleChangeSelectPromiseStripe={handleChangeSelectPromiseStripe}
      />
    ),
    3: <Step4FinishCart deliveryDayRange={deliveryDayRange} />
  };

  const resetStepsCheckout = () => {
    if (localStorage.getItem('resetCheckoutSteps')) {
      setStep(0);
      localStorage.setItem('savedStep', '0');
    }
  };

  const getUnsubmittedPaymentSession = () => {
    if (paymentSession) return;
    const paymentSessionJson = localStorage.getItem('paymentSession');
    const savedPaymentSession = paymentSessionJson ? JSON.parse(paymentSessionJson) : undefined;
    setPaymentSession(savedPaymentSession);
  };

  const getOrderFinishData = () => {
    const dataFinishCartJson = localStorage.getItem('dataFinishCart');
    const dataFinishCart = dataFinishCartJson ? JSON.parse(dataFinishCartJson) : undefined;
    setDataFinishCart(dataFinishCart);
  };

  const getSessionPaymentStatus = async () => {
    if (!submittedSessionId || !orderId) return;
    try {
      setPaymentSessionIsLoading(true);
      const paymentSessionStatus = await putVerifyStripePaymentStatus({
        paymentSessionId: submittedSessionId,
        stripeProvider: paymentSession?.stripeProvider
      });
      if (paymentSessionStatus?.isPaid) {
        await putProcessOrder(orderId);
        await deleteAndCreateNewCart();
      }
      if (paymentSessionStatus?.isConcluded) {
        resetPaymentSession();
        return setStep(3);
      }
      displayErrorNotification({ customJSXMessage: <>El pago no pudo ser completado, inténtalo nuevamente</> });
      return setStep(2);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setPaymentSessionIsLoading(false);
    }
  };

  const dataEstimatedDelivered = async () => {
    if (step === 2 && storeCountry) {
      const dataDeliveredDate = await getEstimatedDeliveredDate(storeCountry.countryCode);
      if (dataDeliveredDate) {
        setDeliveryDayRange(`${dataDeliveredDate.minDeliverTime}-${dataDeliveredDate.maxDeliverTime}`);
      }
    }
  };

  const handleSelectPromiseStripe = (stripeProvider: STRIPE_PAYMENT_PROVIDERS) => {
    switch (stripeProvider) {
      case 1: {
        return stripePromiseMX;
      }
      case 2: {
        return stripePromiseUC;
      }
      case 3: {
        return stripePromiseUL;
      }
      default:
        return null;
    }
  };

  const backStore = () => {
    resetStepsCheckout();
    push('/catalogue/home');
  };

  useEffect(() => {
    if (!user) getInfo();
    resetStepsCheckout();
    getUserCart();
  }, []);

  useEffect(() => {
    getOrderFinishData();
    getUnsubmittedPaymentSession();
    if (listOfCountriesOpenForBuying) return;
    getListOfCountriesByOfferedService(setCompleteListOfCountries);
    getListOfCountriesByOfferedService(setListOfCountriesOpenForBuying, 'buy');
  }, []);

  useEffect(() => {
    getSessionPaymentStatus();
  }, [submittedSessionId, orderId]);

  useEffect(() => {
    dataEstimatedDelivered();
  }, [step]);

  if ((!totalItems || (totalItems && totalItems === 0)) && step !== 3)
    return (
      <div className="noProductsInTheCart">
        <BordEmptyState
          title="Tu carrito está vacío"
          subTitle="Agrega productos desde la tienda para continuar con tu compra"
          ilustrationIconProps={{ variant: 'marketPlace', standardSize: 100 }}
          buttonOneProps={{
            label: 'Volver a la tienda',
            onClick: backStore,
            customWidth: 'w-280'
          }}
        />
      </div>
    );
  return (
    <div className="CheckoutModule">
      {openInvalidCard && <ModalInvalidCart />}
      <CheckoutProgressBar currentStep={step} />
      {paymentSessionIsLoading && (
        <div className="checkoutModuleLoader">
          <GenericLoader />
        </div>
      )}
      {!paymentSessionIsLoading && !paymentSession && steps[step]}
      {!paymentSessionIsLoading && options && !submittedSessionId && (
        <EmbeddedCheckoutProvider
          stripe={selectStripeProvider ? handleSelectPromiseStripe(selectStripeProvider) : null}
          options={options}
        >
          <div className="stripeCheckoutOuterContainer">
            <div className="stripeCheckoutInnerContainer">
              <EmbeddedCheckout />
              <IconXInSquare className="expirePaymentSessionButton" onClick={expirePaymentSession} />
            </div>
          </div>
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}

export default CheckoutModule;
