import create from 'zustand';
import { IusersOrgData } from '../../../../types/global';
import { IbillingData, IstripeCheckoutPaymentSessionData } from '../../../../types/checkout';

export interface IcheckoutState {
  checkoutBillingData?: IbillingData;
  invoiceAdditionalEmail?: string;
  step: number;
  country: string;
  user?: IusersOrgData;
  paymentSession?: IstripeCheckoutPaymentSessionData;
  loadStep: () => void;
  addSteps: (n: number) => void;
  setStep: (step: number) => void;
  setCountry: (country: string) => void;
  setUser: (user: IusersOrgData) => void;
  setPaymentSession: (paymentSession?: IstripeCheckoutPaymentSessionData) => void;
  setCheckoutBillingData: (checkoutBillingData?: IbillingData) => void;
  setInvoiceAdditionalEmail: (invoziceAdditionalEmail: string) => void;
}

const useCheckoutStore = create<IcheckoutState>(set => ({
  checkoutBillingData: undefined,
  invoiceAdditionalEmail: undefined,
  step: 0,
  country: 'mx',
  user: undefined,
  paymentSession: undefined,
  loadStep: () =>
    set(() => {
      const savedStep = localStorage.getItem('savedStep');
      if (savedStep) {
        const loadedStep = JSON.parse(savedStep) as number;
        return { step: loadedStep };
      }
      return { step: 0 };
    }),
  addSteps: n =>
    set(state => {
      const newStep = state.step + n;
      localStorage.setItem('savedStep', `${newStep}`);
      return { step: newStep };
    }),
  setStep: step =>
    set(() => {
      localStorage.setItem('savedStep', `${step}`);
      return { step };
    }),
  setCountry: country => set({ country }),
  setUser: user => set({ user }),
  setPaymentSession: paymentSession => set({ paymentSession }),
  setCheckoutBillingData: checkoutBillingData => set({ checkoutBillingData }),
  setInvoiceAdditionalEmail: invoiceAdditionalEmail => set({ invoiceAdditionalEmail })
}));

export default useCheckoutStore;
