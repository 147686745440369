import { FC, Fragment, useEffect, useState } from 'react';

import { ILocationAddress } from '../../../../../types/account';
import { useTranslation } from 'react-i18next';
import { Tany } from '../../../../../types/global';
import { BordDropdown, BordFlag } from '../../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import './listAddressCard.scss';

interface IListAddressCard {
  cardData: ILocationAddress;
  selectData?: Tany;
  openModalAddressDetail?: (item: ILocationAddress) => void;
}

interface ISelectData {
  value: number;
  name: string;
  active?: boolean;
}

const ListAddressCard: FC<IListAddressCard> = ({ cardData, selectData, openModalAddressDetail }: IListAddressCard) => {
  const { t } = useTranslation();
  const [addressList, setAddressList] = useState<ILocationAddress[]>();
  const [formatList, setFormatList] = useState<ILocationAddress[]>();
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:listAddressCard:';

  const formatSelectList = (list: ILocationAddress[]) => {
    const newList: ISelectData[] = [];
    for (const item of list) {
      if (item.id && (item.locationName || item.address)) {
        const newObjet = {
          value: item.id,
          name: item.locationName || item.address || '',
          active: item.active || false
        };
        newList.push(newObjet);
      }
    }
    setFormatList([...newList]);
  };

  const countryAddressList = () => {
    if (selectData && cardData?.country) {
      if (selectData[cardData?.country?.code]) {
        setAddressList(selectData[cardData?.country?.code]);
        formatSelectList(selectData[cardData?.country?.code]);
      }
    }
  };

  const formDropdownOptionsCallbackOtherAddress = (
    rawOptions: ILocationAddress[],
    closeDropDown?: (option: ILocationAddress) => void
  ) => {
    return rawOptions.map((selectLocation, i) => {
      return (
        <Fragment key={`toolCondition${i}${selectLocation?.id}`}>
          <div className="optionContainer">
            <div className="customLocationItems">
              <div
                className="flex items-center w-full justify-between label"
                onClick={() => {
                  closeDropDown && closeDropDown(selectLocation);
                  if (openModalAddressDetail) {
                    openModalAddressDetail(selectLocation as ILocationAddress);
                  }
                }}
              >
                <span>{selectLocation?.locationName}</span>
                <span className="text-bord-sm text-primary-bg-default">Ver</span>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });
  };

  const formSelectedOptionUICallbackOtherAddress = (selectLocation: ILocationAddress) => {
    return (
      <div className="flex items-center w-full justify-between">
        <span>{selectLocation?.locationName}</span>
        <span className="text-bord-sm text-primary-bg-default">Ver</span>
      </div>
    );
  };

  const filterBySearchStringCallbackOtherAddress = (search: string, rawOptions: ILocationAddress[]) => {
    return rawOptions.filter(selectLocation =>
      selectLocation?.locationName?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  useEffect(() => {
    countryAddressList();
  }, []);

  return (
    <div id="listAddressCard">
      <div className="boxOtherAddressCard">
        <div>
          <BordFlag variant="circle" country={cardData?.country?.code as TcountryCode} standardSize={32} />
        </div>
        <div className="otherAddressText">{t(`${i18nLanguageKey}otherAddressText`)}</div>
        <BordDropdown
          componentStyles={{
            width: '100%'
          }}
          rawOptions={addressList?.filter(element => element?.locationName) || []}
          customPlaceholder={
            formatList ? t(`${i18nLanguageKey}nudosSimpleSelect`) : t(`${i18nLanguageKey}nudosSimpleSelectDefault`)
          }
          formDropdownOptionsCallback={formDropdownOptionsCallbackOtherAddress}
          formSelectedOptionUICallback={formSelectedOptionUICallbackOtherAddress}
          filterBySearchStringCallback={filterBySearchStringCallbackOtherAddress}
          includeMagnifierIcon={false}
          isDeactivated={!formatList}
        />
      </div>
    </div>
  );
};
export default ListAddressCard;
