const RoundBosniaAndHerzegovina = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 0C10.3269 0 5.34375 2.95269 2.50275 7.40494L24.2002 29.7406C28.8716 26.9467 32 21.8389 32 16c0-8.8365-7.1635-16-16-16Z"
        />
        <path
          fill="#1A47B8"
          d="M32 16c0-4.1514-1.5811-7.93311-4.174-10.77673V26.7768C30.4189 23.9331 32 20.1514 32 16ZM19.9868 30.117l1.676-.001.517-1.5943.5188 1.5937.8406-.0005c.5155-.276 1.0141-.5792 1.4938-.9081L2.79306 6.96692c-.3665.53475-.70075 1.09312-1.00131 1.67187l.19762.607 1.67594-.00106-1.35525.98597.51888 1.5937-1.3565-.9843-.809065.5885C.232063 12.8771 0 14.4114 0 16c0 8.8365 7.1635 16 16 16 1.8682 0 3.6614-.3208 5.3281-.9095l-1.3413-.9735ZM3.56869 15.3044l.51687-1.5943-1.35637-.9844 1.67594-.001.51693-1.5943.51888 1.5936 1.67594-.001-1.35532.9859.51888 1.5937-1.3565-.9842-1.35525.986Zm3.45156 3.4782.51688-1.5942-1.35638-.9844 1.67594-.0011.51693-1.5943.51888 1.5937 1.6759-.0011-1.35521.986.51887 1.5937-1.3565-.9843-1.35531.986Zm3.45155 3.4783.5168-1.5943-1.35635-.9844 1.67595-.001.5169-1.5943.5189 1.5936 1.6759-.001-1.3552.9859.5189 1.5937-1.3565-.9844-1.3553.9862Zm4.8068 2.4923-1.3553.9859.5168-1.5942-1.3564-.9844 1.676-.0011.517-1.5943.5189 1.5937 1.6759-.0011-1.3553.986.5189 1.5937-1.3565-.9842Zm2.0962 4.4642.5169-1.5943-1.3564-.9844 1.676-.001.5169-1.5943.5189 1.5936 1.6759-.001-1.3552.9859.5188 1.5937-1.3565-.9844-1.3553.9862Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBosniaAndHerzegovina;
