import { useTranslation } from 'react-i18next';
import './ConfigurationCommentsModal.scss';

const ConfigurationCommentsModalContent = ({ comments }: { comments: string }) => {
  const { t } = useTranslation();
  const translateKey = 'services:logistics:additionalServices:configurationModal';
  const configurationDetailsText = t(`${translateKey}:configurationDetails`);

  return (
    <div className="configurationCommentsModalContainer">
      <div className="configurationCommentsTitle">{configurationDetailsText}</div>
      <div className="commentTextStyles">{comments}</div>
    </div>
  );
};

export default ConfigurationCommentsModalContent;
