import { FC, useState } from 'react';
import { ModalCountryChangeAlert, ModalToSelectStoreCountry } from '../../views/ecommerce/Components';
import { productsNumber } from '../../utils/cart';
import useStoreCountryState from '../../state/useStoreCountryState';
import { segmentTrackEvent } from '../../utils/segment';

interface IFlowToChangeCountryStore {
  closeModals: () => void;
}

const FlowToChangeCountryStore: FC<IFlowToChangeCountryStore> = ({ closeModals }: IFlowToChangeCountryStore) => {
  const totalProductsCart = productsNumber();
  const [openSelectStoreCountryModal, setOpenSelectStoreCountryModal] = useState<boolean>(false);
  const { storeCountry } = useStoreCountryState();

  return (
    <>
      {totalProductsCart > 0 && !openSelectStoreCountryModal && (
        <ModalCountryChangeAlert
          firstAction={() => {
            segmentTrackEvent({ catalogueChangeCountryNoClick: null });
            closeModals();
          }}
          secondAction={() => {
            setOpenSelectStoreCountryModal(true);
          }}
        />
      )}
      {(totalProductsCart <= 0 || openSelectStoreCountryModal) && (
        <ModalToSelectStoreCountry
          closeModalExternally={closeModals}
          countryName={storeCountry?.countryName}
          origin="flowToChangeCountryStore"
        />
      )}{' '}
    </>
  );
};

export default FlowToChangeCountryStore;
