const RoundNiue = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9998 7.6521V13.0485L10.4346 7.6521H15.9998ZM8.34797 15.9999H13.0487L8.34797 9.73901 7.65234 14.6086 8.34797 15.9999Z"
        />
        <path
          fill="white"
          d="M15.957 16.0001H15.9998C15.9998 15.9857 15.9998 15.9717 15.9998 15.9573C15.9856 15.9716 15.9713 15.9858 15.957 16.0001Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.16194 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0485 16H15.9572C15.9715 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0325L10.3152 8.3479H8.34766V8.34803L15.9997 16H15.9998C15.9998 16 15.9998 14.6433 15.9998 14.0325Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C7.47785 2.43816 6.88691 2.8296 6.32491 3.25691H6.98141L5.24629 4.51779L5.90891 6.5576L4.17379 5.29698L3.98916 5.43116C3.26873 6.24929 2.62998 7.14104 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#1A47B8"
          d="M6.83533 2.88452C6.66277 3.00533 6.49264 3.12952 6.32508 3.25696L5.24664 4.51783L4.17414 5.29696L3.98795 5.43221C3.59508 5.87846 3.22664 6.34671 2.88477 6.83502C3.27445 7.02415 3.71189 7.13046 4.17414 7.13046C5.80702 7.13046 7.13064 5.80683 7.13064 4.17396C7.13077 3.71158 7.02452 3.27427 6.83533 2.88452Z"
        />
        <path
          fill="#FFDA2C"
          d="M3.9873 5.43233 4.17349 5.29708 5.90862 6.55777 5.24593 4.51796 6.98093 3.25708H6.3243C5.47468 3.90327 4.69174 4.63227 3.9873 5.43233ZM11.0587 2.78271 11.4041 3.84553H12.5216L11.6175 4.50247 11.9627 5.56528 11.0587 4.9084 10.1546 5.56528 10.4998 4.50247 9.5957 3.84553H10.7133L11.0587 2.78271ZM4.17387 9.73926 4.51925 10.8021H5.63675L4.73275 11.4591 5.07794 12.5219 4.17387 11.8649 3.26975 12.5219 3.61494 11.4591 2.71094 10.8021H3.82844L4.17387 9.73926Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNiue;
