const RoundGuinea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M21.5654 0.99525C19.832 0.352062 17.9572 0 16 0C14.0429 0 12.1682 0.352063 10.4349 0.995125L9.73926 16L10.4349 31.0049C12.1682 31.648 14.0429 32 16 32C17.9573 32 19.832 31.648 21.5654 31.0047L22.261 16L21.5654 0.99525Z"
        />
        <path fill="#F93939" d="M0 16C0 22.8794 4.342 28.7441 10.4348 31.0047V0.995117C4.342 3.25587 0 9.12049 0 16Z" />
        <path
          fill="#6DA544"
          d="M32.0002 16C32.0002 9.12049 27.6582 3.25587 21.5654 0.995117V31.0048C27.6582 28.7441 32.0002 22.8794 32.0002 16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGuinea;
