import { v4 as uuid } from 'uuid';

import './skeletonFilters.scss';

const SkeletonFilters = () => {
  const generateFilterItems = () => {
    const items = Array(6).fill('');
    return items.map(() => {
      return <div key={uuid()} className="itemFilter animationLoader" />;
    });
  };
  return (
    <div id="skeletonFilters" className="newStore">
      {generateFilterItems()}
    </div>
  );
};

export default SkeletonFilters;
