const RoundHonduras = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#3ECBF8"
          d="M16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32ZM16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0ZM9.84672 10.4348 10.3647 12.0291H12.041L10.6849 13.0144 11.203 14.6087 9.84672 13.6234 8.49047 14.6087 9.00853 13.0144 7.65234 12.0291H9.32866L9.84672 10.4348ZM9.84672 17.3914 10.3647 18.9857H12.041L10.6849 19.971 11.203 21.5652 9.84672 20.5799 8.49047 21.5652 9.00853 19.971 7.65234 18.9857H9.32866L9.84672 17.3914ZM22.1534 10.4348 22.6714 12.0291H24.3477L22.9915 13.0144 23.5096 14.6087 22.1534 13.6234 20.7971 14.6087 21.3152 13.0144 19.959 12.0291H21.6354L22.1534 10.4348ZM22.1534 17.3914 22.6714 18.9857H24.3477L22.9915 19.971 23.5096 21.5652 22.1534 20.5799 20.7971 21.5652 21.3152 19.971 19.959 18.9857H21.6354L22.1534 17.3914ZM16 13.9131 16.5181 15.5075H18.1944L16.8382 16.4927 17.3563 18.087 16 17.1016 14.6438 18.087 15.1619 16.4927 13.8057 15.5075H15.482L16 13.9131Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundHonduras;
