const SquareCambodia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M8.38094 5.33325h.52381l.52381 1.06667H8.38094V5.33325Zm1.04762 1.06667h1.04764v1.06667H9.42856V6.39992ZM8.38094 7.46659h1.04762v1.06666H8.38094V7.46659ZM7.33332 8.53325h1.04762v1.06667H7.33332V8.53325Zm0 1.06667h1.04762v1.06668H7.33332V9.59992Zm-.5238 0h.5238v1.06668H6.28571l.52381-1.06668Zm2.61904-1.06667h2.09524v2.13335H9.42856V8.53325Zm4.19044-3.2h-.5238l-.5238 1.06667h1.0476V5.33325Zm-1.0476 1.06667h-1.0476v1.06667h1.0476V6.39992Zm1.0476 1.06667h-1.0476v1.06666h1.0476V7.46659Zm1.0477 1.06666H13.619v1.06667h1.0477V8.53325Zm0 1.06667H13.619v1.06668h1.0477V9.59992Zm.5238 0h-.5238v1.06668h1.0476l-.5238-1.06668Zm-2.6191-1.06667h-1.0476v2.13335h1.0476V8.53325Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M9.42855 7.46657h1.04765v1.06667H9.42855V7.46657ZM8.38093 8.53324h1.04762v2.13336H8.38093V8.53324Zm0-2.13334h1.04762v1.06667H8.38093V6.3999Zm-.52381 1.06667h.52381v1.06667H7.33331l.52381-1.06667Zm4.71428 0h-1.0476v1.06667h1.0476V7.46657Zm1.0476 1.06667h-1.0476v2.13336h1.0476V8.53324Zm0-2.13334h-1.0476v1.06667h1.0476V6.3999Zm.5238 1.06667h-.5238v1.06667h1.0476l-.5238-1.06667Zm-2.619-1.06667h-1.0476v2.13334h1.0476V6.3999Z"
            clipRule="evenodd"
            opacity=".7"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M10.4762 5.33325h1.0476v1.06667h-1.0476V5.33325Z"
            clipRule="evenodd"
            opacity=".7"
          />
          <path
            fill="#232C80"
            fillRule="evenodd"
            d="M0 0h22v4H0V0Zm0 12h22v4H0v-4Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareCambodia;
