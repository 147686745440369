import Bulb from '../assets/images/components/newCard/bulb';
import Cart from '../assets/images/components/newCard/cart';
import { updateProductCart } from '../services/cart.services';
import { IStoreCountry } from '../state/useStoreCountryState';
import { IProductsUpdate } from '../types/cart';
import { IHandleCountryChange } from '../types/storeByCountry';
import { displayErrorNotification, displaySuccessNotification } from './displayNudosStandardNotifications';

export const statusCard = (status: number, isSubscription?: boolean) => {
  switch (status) {
    case 1:
      return {
        textColor: '#B3B3B3',
        icon: isSubscription ? <Bulb stroke="#B3B3B3" /> : <Cart />,
        title: 'Pago en proceso'
      };
    case 5:
      return {
        textColor: '#383838',
        icon: isSubscription ? <Bulb stroke="#383838" /> : <Cart stroke="#383838" />,
        title: 'Confirmado'
      };
    case 2:
      return {
        textColor: '#383838',
        icon: isSubscription ? <Bulb stroke="#383838" /> : <Cart stroke="#383838" />,
        title: 'Proceso de entrega'
      };
    case 3:
      return {
        textColor: '#81BC3F',
        icon: isSubscription ? <Bulb stroke="#81BC3F" /> : <Cart stroke="#81BC3F" />,
        title: 'Entregado'
      };
    case 6:
      return {
        textColor: '#81BC3F',
        icon: isSubscription ? <Bulb /> : <Cart stroke="#81BC3F" />,
        title: 'Activo'
      };
    case 7:
      return {
        textColor: '#B3B3B3',
        icon: isSubscription ? <Bulb stroke="#B3B3B3" /> : <Cart stroke="#B3B3B3" />,
        title: 'Inactivo'
      };
    case 4:
      return {
        textColor: '#B3B3B3',
        icon: isSubscription ? <Bulb stroke="#B3B3B3" /> : <Cart />,
        title: 'Cancelado'
      };
    default:
      return {
        textColor: '#B3B3B3',
        icon: isSubscription ? <Bulb stroke="#B3B3B3" /> : <Cart />,
        title: 'Pago en proceso'
      };
  }
};

export const handleChangeCountryStore = async ({
  countryCode,
  selectedCountryInformation,
  setLoadingChangeCountry,
  orgDetails,
  itemsCart,
  productsTotalNumber,
  setCart,
  setGlobalShippingCart,
  setStoreCountry,
  resetCheckoutStep,
  setInitialCountryTooltip,
  closeModalExternally,
  origin
}: IHandleCountryChange) => {
  const setNewLanguageOnThePlatform = (selectedCountryInformation: IStoreCountry) => {
    setStoreCountry({
      countryCode: selectedCountryInformation.countryCode,
      countryFlag: selectedCountryInformation.countryFlag,
      countryName: selectedCountryInformation.countryName,
      id: selectedCountryInformation.id,
      nameStringId: selectedCountryInformation?.nameStringId || '',
      storageEnabled: selectedCountryInformation.storageEnabled
    });
    resetCheckoutStep();
    if (origin === 'flowToChangeCountryStore') {
      displaySuccessNotification({ customJSXMessage: <>Cambiaste el país de compra con éxito</> });
    }
    if (origin === 'ecommerce') {
      setInitialCountryTooltip && setInitialCountryTooltip(true);
    }
    if (closeModalExternally) closeModalExternally();
  };

  const createCartBody: IProductsUpdate = {
    userId: Number(orgDetails?.userId),
    havePrime: true,
    organizationId: Number(orgDetails?.organizationId),
    products: [],
    countryCode: countryCode
  };
  try {
    if (itemsCart?.shoppingCartId && productsTotalNumber > 0) {
      const resetedCart = {
        shoppingCartId: null,
        userId: Number(orgDetails?.userId),
        organizationId: Number(orgDetails?.organizationId),
        references: null,
        numberOfProducts: null,
        total: null,
        products: null,
        totalDiscountValue: null,
        totalWithoutPrime: null
      };
      setCart(resetedCart);
      setGlobalShippingCart(undefined);
      localStorage.setItem('bagItems', JSON.stringify(resetedCart));
      localStorage.removeItem('currentOrderShippingData');
      localStorage.setItem('resetCheckoutSteps', 'true');
      await updateProductCart(createCartBody);
      setNewLanguageOnThePlatform(selectedCountryInformation);
    } else {
      await updateProductCart(createCartBody);
      setNewLanguageOnThePlatform(selectedCountryInformation);
    }
  } catch (error) {
    displayErrorNotification();
  } finally {
    setLoadingChangeCountry && setLoadingChangeCountry(undefined);
  }
};
