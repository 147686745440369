const SquareSpain = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 4.2666H22V11.7333H0V4.2666Z" clipRule="evenodd" />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M9.42852 6.63677V9.32477C9.42852 10.0714 8.72452 10.6688 7.8571 10.6688H5.76186C4.89652 10.6666 4.19043 10.0661 4.19043 9.32264V6.63464C4.19043 6.02451 4.65976 5.51464 5.3051 5.34931C5.49995 4.79464 6.09919 5.29171 6.80948 5.29171C7.52395 5.29171 8.119 4.79784 8.31386 5.35037C8.9571 5.51997 9.42852 6.03091 9.42852 6.63677Z"
            clipRule="evenodd"
          />
          <path
            fill="#CBCBCB"
            fillRule="evenodd"
            d="M9.42829 7.4668H10.4759V10.6668H9.42829V7.4668ZM3.14258 7.4668H4.1902V10.6668H3.14258V7.4668Z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M9.42829 9.6001H10.4759V10.6668H9.42829V9.6001ZM3.14258 9.6001H4.1902V10.6668H3.14258V9.6001Z"
            clipRule="evenodd"
          />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M9.42829 6.3999H10.4759V7.46657H9.42829V6.3999ZM3.14258 6.3999H4.1902V7.46657H3.14258V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#AF010D"
            fillRule="evenodd"
            d="M5.23828 6.3999H6.2859V7.9999H5.23828V6.3999ZM7.33352 8.53324H8.38114V10.1332H7.33352V8.53324Z"
            clipRule="evenodd"
          />
          <path
            fill="#AE6A3E"
            fillRule="evenodd"
            d="M7.33301 6.3999H8.38063V7.9999H7.33301V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M5.23828 8.5332H6.2859V10.1332H5.23828V8.5332Z"
            clipRule="evenodd"
          />
          <path
            fill="#AF010D"
            fillRule="evenodd"
            d="M6.2859 6.40016L5.23828 5.3335H8.38114L7.33352 6.40016H6.2859Z"
            clipRule="evenodd"
          />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M6.28613 4.2666H7.33375V5.33327H6.28613V4.2666Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSpain;
