import { Suspense, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Loading } from './components';
import Auth from './routes/Auth';
import { PrivateRoute, PublicRoute } from './routes/navigation';
import { UserAuthContextProvider } from './state/userAuthContext';
import { ReactNotifications } from 'react-notifications-component';
import createApiInstance from './services/api';
import { responsiveConfig } from './utils/activeNotify';
import Ecommerce from './routes/Ecommerce/Ecommerce';
import Admin from './routes/Admin/Admin';
import anonymousUser from './utils/anonymousUser';
import StatusRegister from './routes/StatusRegister';
import { Crisp } from 'crisp-sdk-web';
import { crispKey } from './constants';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-notifications-component/dist/theme.css';

function App() {
  const initializeCrisp = () => {
    if (crispKey) {
      Crisp.configure(crispKey);
    }
  };

  useLayoutEffect(() => {
    createApiInstance('', `${process.env.REACT_APP_API}`);
    anonymousUser();
    initializeCrisp();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <UserAuthContextProvider>
        <>
          <ReactNotifications {...responsiveConfig} />
          <Router>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY as string} libraries={['places']}>
              <Switch>
                <PrivateRoute path="/catalogue" component={Ecommerce} />
                <PrivateRoute path="/nodi" component={Admin} />
                <PrivateRoute path="/state" component={StatusRegister} />
                <PublicRoute path="" component={Auth} />
                <Redirect from="*" to="/login" />
              </Switch>
            </Wrapper>
          </Router>
        </>
      </UserAuthContextProvider>
    </Suspense>
  );
}

export default App;
