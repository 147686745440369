import { useState } from 'react';
import { InudoInfo, Tany } from '../../../../../../../types/global';
import ToolEditAcquisitionDataModal from './EditAcquisitionDataModal/EditAcquisitionDataModal';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import { BordOneToneIcon } from '../../../../../../../components/BordDesignSystem';
import BordEmptyState from '../../../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import { bordColorTheme } from '../../../../../../../styles/constants';
import './ToolAcquisitionData.scss';

const ToolAcquisitionData = ({
  searchedAcquisition,
  updateToolDataCallback
}: {
  searchedAcquisition: InudoInfo | undefined;
  updateToolDataCallback: (data: Tany) => void;
}) => {
  const [showEditAcquisitionModal, setShowEditAcquisitionModal] = useState<boolean>(false);
  const [showExpandedSection, setShowExpandedSection] = useState(false);

  const isExternalTool = searchedAcquisition?.referenceModel === 'ExternalProduct';
  const toolTimeZone = searchedAcquisition?.country?.timeZone || undefined;
  const formatToolDate = formatShortDate(searchedAcquisition?.acquisitionDate, toolTimeZone).onlyDate;
  const formatToolendDay = formatShortDate(searchedAcquisition?.endDay, toolTimeZone).onlyDate;

  const toolSaleValue = searchedAcquisition?.saleValueUsd && `$${searchedAcquisition?.saleValueUsd} USD`;
  const expandOrContractButtonText = showExpandedSection ? 'Ver menos' : 'Ver más';
  const expandOrContractButtonIcon = showExpandedSection ? 'arrowHeadUp' : 'arrowHeadDown';

  const clickExpandOrContractButton = () => setShowExpandedSection(!showExpandedSection);

  const hasAcquistionData =
    searchedAcquisition?.acquisitionType ||
    searchedAcquisition?.acquisitionDate ||
    searchedAcquisition?.cost ||
    searchedAcquisition?.warranty ||
    searchedAcquisition?.monthlyValue ||
    searchedAcquisition?.endDay;

  return (
    <div className="acquisitionData">
      <div className="acquisitionDataTitle">Adquisición</div>
      {!hasAcquistionData && (
        <BordEmptyState
          customClassNameBordEmptyStateContainer="noAcquisitionDataContainer"
          customClassNameTitle="noAcquisitionDataTitle"
          customClassNameSubTitle="noAcquisitionDataDescription"
          ilustrationIconProps={{
            variant: 'box',
            customWidth: '4.8rem'
          }}
          title="Completa los detalles de tu herramienta"
          subTitle="En este espacio podrás visualizar el tipo de adquisición, precio de compra, tiempo de garantía, fecha de compra y estado del producto"
          buttonOneProps={{
            bordButtonStyle: 'outlined',
            label: 'Completar información',
            buttonIconTwo: 'arrowRightCircle',
            onClick: () => setShowEditAcquisitionModal(true),
            style: {
              backgroundColor: 'transparent',
              border: 'none',
              color: bordColorTheme.primary.bgDefault
            }
          }}
        />
      )}

      {hasAcquistionData && (
        <div className="acquisitionDataContainer">
          <div className="property">
            <div className="left">Tipo de adquisición</div>
            <div className="w-full flex items-center gap-4">
              {searchedAcquisition?.acquisitionType === 'Renta' && (
                <div className="keyIconContainer">
                  <BordOneToneIcon variant="key" standardSize={12} />
                </div>
              )}
              {searchedAcquisition?.acquisitionType === 'Compra' && (
                <BordOneToneIcon variant="shoppingCart" standardSize={12} />
              )}
              <div>{searchedAcquisition?.acquisitionType || 'Sin información'}</div>
            </div>
            {isExternalTool && (
              <div className="editIconContainer" onClick={() => setShowEditAcquisitionModal(true)}>
                <BordOneToneIcon variant="edit" standardSize={12} />
              </div>
            )}
          </div>

          <div className="property">
            <div className="left">
              {searchedAcquisition?.acquisitionType === 'Renta' ? 'Fecha de adquisición' : 'Fecha de compra'}
            </div>
            <div className="w-full">{formatToolDate || 'Sin información'}</div>
          </div>

          {searchedAcquisition?.acquisitionType === 'Renta' && isExternalTool && (
            <div className="property">
              <div className="left">Fecha fin de renta </div>
              <div className="w-full">{formatToolendDay || 'Sin información'}</div>
            </div>
          )}

          {searchedAcquisition?.acquisitionType === 'Renta' && !isExternalTool && showExpandedSection && (
            <div className="property">
              <div className="left">Plazo</div>
              <div className="w-full">{searchedAcquisition?.duration || 'Sin información'}</div>
            </div>
          )}

          {searchedAcquisition?.acquisitionType === 'Renta' && !isExternalTool && showExpandedSection && (
            <div className="property">
              <div className="left">Precio renta </div>
              <div className="w-full">{searchedAcquisition?.rentValueUsd || 'Sin información'}</div>
            </div>
          )}

          {searchedAcquisition?.acquisitionType === 'Renta' && showExpandedSection && (
            <div className="property">
              <div className="left">Pago mensual </div>
              <div className="w-full">{searchedAcquisition?.monthlyValueUsd || 'Sin información'}</div>
            </div>
          )}

          {searchedAcquisition?.acquisitionType !== 'Renta' && showExpandedSection && (
            <div className="property">
              <div className="left">Valor de la compra </div>
              <div className="w-full">{toolSaleValue || 'Sin información'}</div>
            </div>
          )}

          {searchedAcquisition?.acquisitionType !== 'Renta' && showExpandedSection && (
            <div className="property">
              <div className="left">Garantía</div>
              <div className="w-full">{searchedAcquisition?.warranty || 'Sin información'}</div>
            </div>
          )}

          <div className="seeMoreContainer" onClick={clickExpandOrContractButton}>
            <span>{expandOrContractButtonText}</span>
            <BordOneToneIcon variant={expandOrContractButtonIcon} standardSize={12} />
          </div>
        </div>
      )}

      {showEditAcquisitionModal && isExternalTool && (
        <ToolEditAcquisitionDataModal
          searchedAcquisition={searchedAcquisition}
          closeModalCallback={() => setShowEditAcquisitionModal(false)}
          updateToolDataCallback={updateToolDataCallback}
        />
      )}
    </div>
  );
};

export default ToolAcquisitionData;
