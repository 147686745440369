import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import { getAdditionalServiceDisplayElements } from '../../utils';
import ConfigurationCommentsModal from '../../../../../views/Admin/LogisticsServicesModule/LogisticsServiceDetail/Components/ConfigurationCommentsModal/ConfigurationCommentsModal';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { markAdditionalServiceNotCompletedAlertViewed } from '../../../../../services/logisticsServicesModule.services';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import BordStatus from '../../../../BordDesignSystem/BordStatus/BordStatus';
import { BordOneToneIcon, BordTooltip } from '../../../../BordDesignSystem';

import { statusVariants } from '../../../../BordDesignSystem/BordStatus/BordStatus.type';
import { renderVariantServicesStatus } from '../../../../../views/Admin/NodiInternalProductDetails/constants';
import './NudosAdditionalServiceStatus.scss';

const NudosAdditionalServiceStatus = ({
  additionalServiceData,
  callToActionCallback
}: {
  additionalServiceData: IadditionalServiceDataForToolLogistics;
  callToActionCallback: () => void;
}) => {
  const { t } = useTranslation();
  const [showConfigurationCommentsModal, setShowConfigurationCommentsModal] = useState(false);

  const { id: additionalServiceProductId, comments, status: additionalServiceStatus } = additionalServiceData || {};
  const { statusName, statusViewedByUserId } = additionalServiceStatus || {};
  const { text, icon, status } = getAdditionalServiceDisplayElements(additionalServiceData);

  const serviceIsToBeCompleted = statusName === LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE;
  const serviceWasNotCompleted = statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED;
  const checkServiceEvidenceModalIsDisabled = !statusName || serviceIsToBeCompleted;

  const markEvidenceViewed = async () => {
    if (!additionalServiceProductId || statusViewedByUserId) return;
    try {
      await markAdditionalServiceNotCompletedAlertViewed(additionalServiceProductId);
    } catch (error) {
      displayErrorNotification();
    }
  };

  const handleCheckServiceEvidenceModal = () => {
    if (checkServiceEvidenceModalIsDisabled) return;
    if (serviceWasNotCompleted) markEvidenceViewed();
    callToActionCallback();
  };

  const openConfigurationCommentsModal = () => setShowConfigurationCommentsModal(true);
  const closeConfigurationCommentsModal = () => setShowConfigurationCommentsModal(false);

  const renderExplainingWithTooltip = (
    <BordTooltip position="bottom" message={t('services:logistics:additionalServices:explainingHover')}>
      <span className="text-bord-caption">Ver</span>
    </BordTooltip>
  );
  const renderExplainingWithoutTooltip = <span className="text-bord-caption">Ver</span>;
  const renderSeeText = serviceIsToBeCompleted ? renderExplainingWithTooltip : renderExplainingWithoutTooltip;

  return (
    <div className="nudosAdditionalServiceStatus">
      <div className="flex items-center gap-1">
        {icon}
        <div className="serviceName">{text}</div>
      </div>
      {!!comments && (
        <div className="commentsSection cursor-pointer" onClick={openConfigurationCommentsModal}>
          <BordTooltip position="bottom" message={`${t('recurrentWords:view')} ${t('recurrentWords:configuration')}`}>
            <BordOneToneIcon variant="messageCircle" standardSize={12} />
          </BordTooltip>
        </div>
      )}
      <BordStatus
        variant={renderVariantServicesStatus({ variant: status as statusVariants }) as statusVariants}
        renderJSX={
          <div className="customBordStatusConatiner" onClick={() => handleCheckServiceEvidenceModal()}>
            <span className="text-bord-sm font-normal">{status}</span>
            <div className="divider" />
            {renderSeeText}
            <BordOneToneIcon variant="arrowRight" standardSize={12} />
          </div>
        }
      />
      {showConfigurationCommentsModal && comments && (
        <ConfigurationCommentsModal closeModalCallback={closeConfigurationCommentsModal} comments={comments} />
      )}
    </div>
  );
};

export default NudosAdditionalServiceStatus;
