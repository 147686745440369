const RoundSweden = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M12.5225 13.9131H31.8653C30.8426 6.06262 24.1299 0 16.0007 0 14.8061 0 13.6424.132062 12.5225.380312V13.9131H12.5225ZM8.34812 13.9131V1.94556C3.95474 4.34268.80968 8.73962.135742 13.9132H8.34812V13.9131ZM8.34805 18.0869H.135742C.80968 23.2605 3.95474 27.6574 8.34812 30.0545L8.34805 18.0869ZM12.5225 18.087V31.6197C13.6424 31.8679 14.8061 32 16.0007 32 24.1299 32 30.8426 25.9374 31.8653 18.0869H12.5225V18.087Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSweden;
