import { useState } from 'react';
import { IadditionalServiceDataForToolLogistics } from '../../../../types/requestLogisticsModule';
import NudosAdditionalServiceStatus from './NudosAdditionalServiceStatus/NudosAdditionalServiceStatus';
import NudosAdditionalServiceStatusModal from './NudosAdditionalServiceStatusModal/NudosAdditionalServiceStatusModal';
import './NudosToolAdditionalServices.scss';

const NudosToolAdditionalServices = ({
  styling,
  logisticAdditionalServices
}: {
  styling: 'toolDetails' | 'logisticServiceDetails' | 'toolHistoryCardAdditionalServices';
  logisticAdditionalServices: IadditionalServiceDataForToolLogistics[];
}) => {
  const [statusModalData, setStatusModalData] = useState<IadditionalServiceDataForToolLogistics>();

  const closeModalCallback = () => setStatusModalData(undefined);

  if (!logisticAdditionalServices?.length) return <></>;
  return (
    <div className={`nudosToolAdditionalServices ${styling}`}>
      {logisticAdditionalServices.map(additionalService => {
        const openAdditionalServiceStatusModal = () => setStatusModalData(additionalService);
        return (
          <NudosAdditionalServiceStatus
            key={`${additionalService.name}-${additionalService.id}`}
            additionalServiceData={additionalService}
            callToActionCallback={openAdditionalServiceStatusModal}
          />
        );
      })}
      {statusModalData && (
        <NudosAdditionalServiceStatusModal
          closeModalCallback={closeModalCallback}
          additionalServiceData={statusModalData}
        />
      )}
    </div>
  );
};

export default NudosToolAdditionalServices;
