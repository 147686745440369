import { FC, useCallback, useEffect, useRef } from 'react';
import { BordButton } from '../../../../../../components/BordDesignSystem';
import { IElement } from '../../../../../../types/global';

import './RemoveTooltip.scss';

interface IRemoveTooltip {
  message: string;
  cancel: () => void;
  confirm: () => void;
}

const RemoveTooltip: FC<IRemoveTooltip> = ({ message, cancel, confirm }: IRemoveTooltip) => {
  const removeTooltipRef = useRef<HTMLDivElement>(null);

  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (removeTooltipRef.current && !removeTooltipRef.current.contains(e.target)) {
        cancel && cancel();
      }
    },
    [removeTooltipRef]
  );

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div id="removeTooltip" ref={removeTooltipRef}>
      <div className="tooltipMessage">{message}</div>
      <div className="blueLine" />
      <div className="buttonsContainer">
        <BordButton onClick={cancel} label="No, mantener" customClassName="saveButton" modeButton="tertiary" />
        <BordButton onClick={confirm} label="Sí, eliminar" customClassName="saveButton" modeButton="quaternary" />
      </div>
    </div>
  );
};

export default RemoveTooltip;
