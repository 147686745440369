import create from 'zustand';
import { ICountryDetail } from '../../../types/countries';
import { Iuser } from '../../../types/global';
import { IemployeesCountOption } from '../../../components/BordDesignSystem/BordEmployeesCountDropdown/BordEmployeesCountDropdown';
import { DTOOrganizationDocumentAlreadyExistResponse } from '../../../types/SignUp';

export interface iState {
  step: number;
  phone?: string;
  newUser?: Iuser;
  email?: string;
  organizationName?: string;
  organizationBusinessName?: string;
  organizationDocument?: string;
  organizationCountry?: ICountryDetail;
  employeesCount?: IemployeesCountOption;
  userArea?: string;
  userFirstName?: string;
  userLastName?: string;
  userPhone?: string;
  userPhoneCountry?: ICountryDetail;
  userEmail?: string;
  userPassword?: string;
  userPasswordConfirmation?: string;
  existingOrganizationData?: DTOOrganizationDocumentAlreadyExistResponse;
  organizationDocumentAlreadyRegistered?: boolean;
  clearAll: () => void;
  setState: (state: iState) => void;
  setStep: (step: number) => void;
  setUser: (user: Iuser) => void;
  setPhone: (phone: string) => void;
  setEmail: (email: string) => void;
  setOrganizationName: (organizationName: string) => void;
  setOrganizationBusinessName: (organizationBusinessName: string) => void;
  setOrganizationDocument: (organizationDocument: string) => void;
  setOrganizationCountry: (organizationCountry?: ICountryDetail) => void;
  setExistingOrganizationData: (existingOrganizationData: DTOOrganizationDocumentAlreadyExistResponse) => void;
  setOrganizationDocumentAlreadyRegistered: (organizationDocumentAlreadyRegistered: boolean) => void;
  setEmployeesCount: (employeesCount?: IemployeesCountOption) => void;
  setUserArea: (userArea?: string) => void;
  setUserFirstName: (userFirstName: string) => void;
  setUserLastName: (userLastName: string) => void;
  setUserPhone: (userPhone: string) => void;
  setUserPhoneCountry: (userPhoneCountry?: ICountryDetail) => void;
  setUserEmail: (userEmail: string) => void;
  setUserPassword: (userPassword: string) => void;
  setUserPasswordConfirmation: (userPasswordConfirmation: string) => void;
  addSteps: (n?: number) => void;
}

const useSignStore = create<iState>(set => ({
  step: 0,
  clearAll: () => set({ step: 0, newUser: undefined }),
  setState: currentState => set(currentState),
  setStep: step => set({ step }),
  setUser: user => set({ newUser: user }),
  setPhone: phone => set({ phone }),
  setEmail: email => set({ email }),
  setOrganizationName: organizationName => set({ organizationName }),
  setOrganizationBusinessName: organizationBusinessName => set({ organizationBusinessName }),
  setOrganizationDocument: organizationDocument => set({ organizationDocument }),
  setOrganizationCountry: organizationCountry => set({ organizationCountry }),
  setEmployeesCount: employeesCount => set({ employeesCount }),
  setUserArea: userArea => set({ userArea }),
  setUserFirstName: userFirstName => set({ userFirstName }),
  setUserLastName: userLastName => set({ userLastName }),
  setUserPhone: userPhone => set({ userPhone }),
  setUserPhoneCountry: userPhoneCountry => set({ userPhoneCountry }),
  setUserEmail: userEmail => set({ userEmail }),
  setUserPassword: userPassword => set({ userPassword }),
  setUserPasswordConfirmation: userPasswordConfirmation => set({ userPasswordConfirmation }),
  setExistingOrganizationData: existingOrganizationData => set({ existingOrganizationData }),
  setOrganizationDocumentAlreadyRegistered: organizationDocumentAlreadyRegistered =>
    set({ organizationDocumentAlreadyRegistered }),
  addSteps: (n = 1) => set(state => ({ step: state.step + n }))
}));

export default useSignStore;

export interface IsignUpSegmentData {
  organizationName: string;
  userEmail: string;
  userPhone: string;
  userName: string;
  userLastName: string;
}
