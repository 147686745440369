const RoundBelize = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16c0-5.4913-2.7668-10.3359-6.9821-13.2174L16 2.08691l-9.01794.69563C2.76687 5.6641 0 10.5087 0 16c0 5.4914 2.76687 10.3359 6.98206 13.2174L16 29.913l9.0179-.6956C29.2332 26.3359 32 21.4914 32 16Z"
        />
        <path
          fill="#A2001D"
          d="M25.0179 2.78262C22.4501 1.02725 19.3452 0 16 0c-3.3452 0-6.45013 1.02725-9.01794 2.78262H25.0179ZM6.98206 29.2174C9.54981 30.9728 12.6548 32 16 32c3.3452 0 6.4501-1.0272 9.0179-2.7826H6.98206Z"
        />
        <path
          fill="#fff"
          d="M16 23.6522c4.2262 0 7.6522-3.426 7.6522-7.6522 0-4.2262-3.426-7.65222-7.6522-7.65222-4.2262 0-7.65219 3.42602-7.65219 7.65222S11.7738 23.6522 16 23.6522Z"
        />
        <path
          fill="#6DA544"
          d="M16 22.2609c3.4578 0 6.2609-2.8031 6.2609-6.2609S19.4578 9.73914 16 9.73914 9.73914 12.5422 9.73914 16 12.5422 22.2609 16 22.2609Z"
        />
        <path
          fill="#fff"
          d="M16 20.1739c2.3052 0 4.1739-1.8687 4.1739-4.1739 0-2.3052-1.8687-4.174-4.1739-4.174-2.3052 0-4.174 1.8688-4.174 4.174s1.8688 4.1739 4.174 4.1739Z"
        />
        <path fill="#1A47B8" d="M16 14.9565 13.7391 16v1.7392L16 19.1305l2.2609-1.3913V16L16 14.9565Z" />
        <path fill="#FFDA2C" d="M13.7391 13.9131h4.5218V16h-4.5218v-2.0869Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBelize;
