const SquareBhutan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FF6C2D" rx="2" />
          <path
            fill="#FF6C2D"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M0 16 22 0H0v16Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m3.14285 10.6666.52381 1.6L5.7619 12.8l.52381-1.0667 4.19049.5333-1.04763-2.66665h2.09523l3.1429 1.06665 1.0476-1.06665-3.1429-1.06667 1.0476-1.06666h3.1429l.5238-1.06667h-2.619V5.33328h2.0952l1.0476-1.06666-4.1905-1.06667-2.0952 2.13333-1.0476 1.06667 1.0476 1.06667-3.14285-.53334-2.09524 3.73332-1.04762 1.0667-2.09524-1.0667Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBhutan;
