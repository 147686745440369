import { useTranslation } from 'react-i18next';
import { NudosLogisticsOriginAndDestinationCardsContainer } from '../../../../components/DesignSystem';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import { ICountryDetail } from '../../../../types/countries';
import { Iemployee } from '../../../../types/global';
import { TlogisticsOriginOrDestinationData } from '../../../../types/requestLogisticsModule';
import './UnassignmentStep2ConfirmAddresses.scss';

const UnassignmentStep2ConfirmAddresses = ({
  originData,
  destinationData,
  selectedDestination,
  listOfCountries,
  updateOriginDataCallback,
  updateDestinationDataCallback
}: {
  originData?: Iemployee;
  destinationData?: TlogisticsOriginOrDestinationData;
  selectedDestination?: TstoragePlace;
  listOfCountries?: ICountryDetail[];
  updateOriginDataCallback: (originData: TlogisticsOriginOrDestinationData) => void;
  updateDestinationDataCallback: (destinationData: TlogisticsOriginOrDestinationData) => void;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'services:unassignmentModule';
  return (
    <div className="UnassignmentStep2ConfirmAddresses">
      <NudosLogisticsOriginAndDestinationCardsContainer
        title={t(`${i18nLanguageKey}:nudosLogisticsOriginAndDestinationCardsContainer.title`)}
        listOfCountries={listOfCountries}
        originData={originData}
        destinationData={destinationData}
        selectedOrigin={'user'}
        selectedDestination={selectedDestination}
        updateOriginDataCallback={updateOriginDataCallback}
        updateDestinationDataCalback={updateDestinationDataCallback}
      />
    </div>
  );
};

export default UnassignmentStep2ConfirmAddresses;
