import { BordOneToneIcon } from '../../BordDesignSystem';
import './NudosDownloadDocumentButton.scss';

/**
 * @property {string} buttonText - Required, the text to show on the download button
 * @property {string} downloadLink - The downloadLink to be used as href for the anchor tag. If no value or an empty string is passed the button will be inactive, including inactive styles
 * @property {number} width - Optional, the width of the button in px. Default is max-content
 * @property {number} height - Optional, the width of the button in px. Default is auto
 */
const NudosDownloadDocumentButton = ({
  buttonText,
  downloadLink,
  width,
  height
}: {
  buttonText: string;
  downloadLink?: string;
  width?: number;
  height?: number;
}) => {
  const activeStyles = downloadLink ? 'active' : '';
  const customStyles = { width: width ? `${width}px` : undefined, height: height ? `${height}px` : undefined };
  return (
    <a
      className={`nudosDownloadDocumentButton ${activeStyles}`}
      href={downloadLink || undefined}
      target="blank"
      style={customStyles}
    >
      {buttonText}
      <BordOneToneIcon variant="download" standardSize={12} />
    </a>
  );
};

export default NudosDownloadDocumentButton;
