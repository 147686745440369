import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import usePlatform from '../../hooks/usePlatform';
import LogInImage from '../signIn/components/LogInImage/LogInImage';
import SignUpForm from './components/SignUpForm/SignUpForm';
import { isLocalHost } from '../../constants';
import './SignUp.scss';

const SignUp = () => {
  const { isMobile } = usePlatform();
  const { push } = useHistory();

  useEffect(() => {
    if (!isLocalHost && isMobile) push('/mobile', { origin: 'signup' });
  }, [isMobile]);

  if (isMobile) return <></>;
  return (
    <div id="signUp" className="moduleWithoutSidebarOrHeader">
      <SignUpForm />
      <LogInImage />
    </div>
  );
};

export default SignUp;
