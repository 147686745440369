const SpecialMarketPlace3dFilledCart = () => {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.183 5.16797H53.9438C50.3691 5.16794 47.5089 5.16791 45.2232 5.40883C42.8705 5.65683 40.8692 6.18017 39.1241 7.40209C37.9436 8.22865 36.9169 9.25538 36.0903 10.4358C34.8684 12.1809 34.3451 14.1822 34.0971 16.535C33.8561 18.8207 33.8562 21.6809 33.8562 25.2555V25.2555V25.5104V25.5104C33.8562 29.085 33.8561 31.9452 34.0971 34.2309C34.3451 36.5837 34.8684 38.585 36.0903 40.3301C36.9169 41.5105 37.9436 42.5372 39.1241 43.3638C40.8692 44.5857 42.8705 45.1091 45.2232 45.3571C46.565 45.4985 48.1047 45.5569 49.8708 45.581C51.9171 45.6089 53.7762 47.2245 53.0126 49.1233C52.8453 49.5393 52.7616 49.7473 52.508 49.9188C52.2543 50.0902 51.9784 50.0902 51.4267 50.0902H47.3337C46.0932 50.0902 45.0876 51.0958 45.0876 52.3363C45.0876 53.5768 46.0932 54.5824 47.3337 54.5824H69.7948C71.0353 54.5824 72.0409 53.5768 72.0409 52.3363C72.0409 51.0958 71.0353 50.0902 69.7948 50.0902H65.7017C65.15 50.0902 64.8741 50.0902 64.6204 49.9188C64.3667 49.7473 64.2831 49.5393 64.1158 49.1233C63.3522 47.2245 65.2113 45.6089 67.2576 45.581C69.023 45.5569 70.5622 45.4985 71.9036 45.3571C74.2563 45.1091 76.2576 44.5857 78.0027 43.3638C79.1832 42.5372 80.2099 41.5105 81.0365 40.3301C82.2584 38.585 82.7817 36.5837 83.0297 34.2309C83.2707 31.9452 83.2706 29.085 83.2706 25.5104V25.2555C83.2706 21.6809 83.2707 18.8207 83.0297 16.535C82.7817 14.1822 82.2584 12.1809 81.0365 10.4358C80.2099 9.25538 79.1832 8.22865 78.0027 7.40209C76.2576 6.18017 74.2563 5.65683 71.9036 5.40883C69.6179 5.16791 66.7577 5.16794 63.183 5.16797Z"
        fill="url(#paint0_linear_6800_678)"
      />
      <g filter="url(#filter0_bi_6800_678)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.26672 9.42812C3.26672 7.0753 5.17406 5.16797 7.52687 5.16797L11.5248 5.16797C17.3893 5.16797 22.5013 9.15926 23.9236 14.8487L23.9433 14.9273L26.1428 25.4037L79.7298 25.4037C81.876 25.4034 83.8512 25.4031 85.4292 25.6536C87.1962 25.9342 89.0382 26.6103 90.3414 28.4036C91.5957 30.1297 91.756 32.0815 91.633 33.8685C91.5172 35.5519 91.1008 37.6195 90.6265 39.9744L90.5863 40.1741C88.8588 48.7549 87.1586 56.8466 83.1608 62.6816C81.0894 65.7048 78.3945 68.1545 74.8092 69.8182C71.2604 71.4651 67.0057 72.2654 61.9025 72.2654H35.8713C35.6582 72.2982 35.4399 72.3152 35.2176 72.3152H32.9537C31.5607 72.3152 30.0428 73.0291 28.7627 74.5344C28.0114 75.4178 27.4327 76.4779 27.0815 77.5972L40.9338 77.5972C41.1566 77.5817 41.3816 77.5739 41.6085 77.5739C41.8353 77.5739 42.0603 77.5817 42.2831 77.5972H70.7538C70.9767 77.5817 71.2017 77.5739 71.4285 77.5739C76.7224 77.5739 81.0138 81.8654 81.0138 87.1592C81.0138 92.453 76.7224 96.7445 71.4285 96.7445C66.1347 96.7445 61.8432 92.453 61.8432 87.1592C61.8432 86.8072 61.8621 86.4597 61.8991 86.1175H51.1378C51.1748 86.4597 51.1938 86.8072 51.1938 87.1592C51.1938 92.453 46.9023 96.7445 41.6085 96.7445C36.3146 96.7445 32.0231 92.453 32.0231 87.1592C32.0231 86.8072 32.0421 86.4597 32.0791 86.1175H24.1899C20.7069 86.1175 18.177 83.2272 18.177 80.0316C18.177 75.9393 19.751 71.9792 22.2721 69.0147C23.3044 67.8007 24.5395 66.7072 25.9407 65.8385C25.9077 65.7274 25.879 65.6141 25.8548 65.4986L15.6409 16.8501C15.1445 14.9883 13.4572 13.6883 11.5248 13.6883H7.52687C5.17406 13.6883 3.26672 11.7809 3.26672 9.42812ZM41.6085 83.9641C39.8438 83.9641 38.4133 85.3946 38.4133 87.1592C38.4133 88.9238 39.8438 90.3543 41.6085 90.3543C43.3731 90.3543 44.8036 88.9238 44.8036 87.1592C44.8036 85.3946 43.3731 83.9641 41.6085 83.9641ZM68.2334 87.1592C68.2334 85.3946 69.6639 83.9641 71.4285 83.9641C73.1931 83.9641 74.6236 85.3946 74.6236 87.1592C74.6236 88.9238 73.1931 90.3543 71.4285 90.3543C69.6639 90.3543 68.2334 88.9238 68.2334 87.1592Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_6800_678"
          x="-1.60546"
          y="0.295788"
          width="98.1449"
          height="101.321"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.43609" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6800_678" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6800_678" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.24812" />
          <feGaussianBlur stdDeviation="4.22256" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6800_678" />
        </filter>
        <linearGradient
          id="paint0_linear_6800_678"
          x1="58.5634"
          y1="5.16797"
          x2="58.3663"
          y2="49.3117"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SpecialMarketPlace3dFilledCart;
