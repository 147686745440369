import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={13} fill="none" ref={ref} {...props}>
    <g stroke="#383838">
      <path
        fill="#fff"
        d="M.822 9.302a2.684 2.684 0 0 0 2.36 2.365c1.896.212 3.74.212 5.635 0a2.684 2.684 0 0 0 2.36-2.365c.098-.912.18-1.848.18-2.802s-.082-1.89-.18-2.802a2.684 2.684 0 0 0-2.36-2.365 25.128 25.128 0 0 0-5.635 0 2.684 2.684 0 0 0-2.36 2.365C.725 4.61.642 5.546.642 6.5s.083 1.89.18 2.802Z"
      />
      <path strokeLinecap="round" d="m7.515 4.985-3.03 3.03m3.03 0-3.03-3.03" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconXInSquare = memo(ForwardRef);
export default IconXInSquare;
