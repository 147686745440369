const RoundArmenia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16c0-1.9571-.352-3.8319-.9952-5.5652L16 9.73914.995188 10.4348C.352063 12.1681 0 14.0429 0 16s.352063 3.8319.995188 5.5652L16 22.2609l15.0048-.6956C31.648 19.8319 32 17.9571 32 16Z"
        />
        <path
          fill="#FF9811"
          d="M16 32c6.8794 0 12.7441-4.342 15.0048-10.4348H.995178C3.25587 27.658 9.12055 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M.995178 10.4348H31.0049C28.7441 4.342 22.8794 0 16 0 9.12055 0 3.25587 4.342.995178 10.4348Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundArmenia;
