import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LS_ADDITIONAL_SERVICE_STATUS } from '../../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import NudosLinkTypeButton from '../../../NudosLinkTypeButton/NudosLinkTypeButton';
import { getAdditionalServiceDisplayElements } from '../../utils';
import BordSettingLaptopIcon from '../../../../BordDesignSystem/BordIlustrationIcon/Icons/BordSettingLaptopIcon';
import { renderVariantServicesStatus } from '../../../../../views/Admin/NodiInternalProductDetails/constants';
import { statusVariants } from '../../../../BordDesignSystem/BordStatus/BordStatus.type';
import BordStatus from '../../../../BordDesignSystem/BordStatus/BordStatus';
import { BordButton, BordPhoto } from '../../../../BordDesignSystem';
import './NudosAdditionalServiceStatusModal.scss';

const AdditionalServiceStatusDataTable = ({
  additionalServiceData
}: {
  additionalServiceData: IadditionalServiceDataForToolLogistics;
}) => {
  const { t } = useTranslation();
  const [expandedText, setExpandedText] = useState(false);
  const { notCompletedReason, otherReasonComment, imageUrl, createdAt } = additionalServiceData?.status || {};
  const { text, status } = getAdditionalServiceDisplayElements(additionalServiceData);

  const isImage =
    imageUrl?.toLowerCase().includes('.jpg') ||
    imageUrl?.toLowerCase().includes('.png') ||
    imageUrl?.toLowerCase().includes('.jpeg');

  const reason = otherReasonComment || notCompletedReason;
  const wasntCompleted = status === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED;
  const completedMargin = wasntCompleted ? undefined : { marginTop: '20px' };
  const notCompletedImageMargin = wasntCompleted && isImage ? undefined : { marginTop: '16px' };
  const specialMargin = completedMargin || notCompletedImageMargin;

  const textExceedsLargeLimit = reason?.length && reason.length > 210;
  const truncatedText = textExceedsLargeLimit ? reason?.slice(0, 210).concat('...') : reason;

  const imageData = {
    imageClassname: isImage ? 'includeImage' : 'placeholderImage',
    imageStyles: imageUrl && isImage ? { backgroundImage: `url("${imageUrl.replaceAll(' ', '%20')}")` } : undefined
  };

  const translateKey = 'services:logistics:additionalServices:';
  const requestedServiceText = t(`${translateKey}requestedService`);
  const statusText = t(`${translateKey}status`);
  const dateText = t(`${translateKey}date`);
  const viewEvidenceText = t(`${translateKey}viewEvidence`);

  const showTextButtonCopy = expandedText ? t('recurrentWord:viewMore') : t('recurrentWord:viewLess');
  const renderNotAllowedStyles = !imageUrl ? 'cursor-not-allowed' : 'cursor-not-allowed';

  const downloadDocument = () => imageUrl && window.open(imageUrl, 'blank');

  const expandOrContractText = () => setExpandedText(!expandedText);

  return (
    <div className="additionalServiceStatusDataTable" style={specialMargin}>
      <div className="textSection">
        <div className="fieldContainer">
          <div className="contentLabel">{requestedServiceText}</div>
          <div className="content">
            <div>{text}</div>
          </div>
        </div>
        <div className="fieldContainer">
          <div className="contentLabel">{statusText}</div>
          <BordStatus
            variant={renderVariantServicesStatus({ variant: status as statusVariants }) as statusVariants}
            label={status}
          />
        </div>
        <div className="fieldContainer">
          <div className="contentLabel">{dateText}</div>
          <div className="content">{formatShortDate(createdAt || '')?.onlyDate}</div>
        </div>
        {wasntCompleted && (
          <div className="fieldContainer">
            <div className="contentLabel">Motivo</div>
            <div className={`content reasonItWasntCompleted ${!expandedText ? 'truncatedText' : ''}`}>
              {expandedText ? reason : truncatedText}
            </div>
            {textExceedsLargeLimit && <NudosLinkTypeButton text={showTextButtonCopy} onClick={expandOrContractText} />}
          </div>
        )}
      </div>
      <div className="imageSection">
        {imageData?.imageStyles && (
          <div className="mt-10">
            <BordPhoto
              variant="square"
              url={imageUrl ?? ''}
              style={{
                background: '#1b202f',
                width: '22rem'
              }}
            />
          </div>
        )}
        {!imageData.imageStyles && (
          <div className="mt-17 settingIconCustomStyles" style={{ width: '6.4rem', height: '6.4rem' }}>
            <BordSettingLaptopIcon />
          </div>
        )}
        <BordButton
          customClassName={`${renderNotAllowedStyles} mt-18`}
          modeButton="tertiary"
          label={viewEvidenceText}
          onClick={downloadDocument}
          buttonIconTwo="download"
          disabled={!imageUrl}
        />
      </div>
    </div>
  );
};

export default AdditionalServiceStatusDataTable;
