const RoundPalestine = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="black"
          d="M9.73884 10.4348H31.0045C28.7438 4.342 22.8791 0 15.9997 0C11.5813 0 7.58177 1.79119 4.68652 4.68681L9.73884 10.4348Z"
        />
        <path
          fill="#6DA544"
          d="M9.73884 21.5652H31.0045C28.7438 27.658 22.8791 32 15.9997 32C11.5813 32 7.58177 30.2088 4.68652 27.3132L9.73884 21.5652Z"
        />
        <path
          fill="#F93939"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPalestine;
