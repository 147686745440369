import { BordOneToneIcon } from '../../../../../../../../components/BordDesignSystem';
import { NudosCareTag, NudosHoverText } from '../../../../../../../../components/NudosComponents';
import { bordColorTheme } from '../../../../../../../../styles/constants';
import { IToolQuoteInfo } from '../../../../../../../../types/logisticsServicesModule.types';
import { formatCost } from '../../../../../../../../utils/formatNumbers';
import './ToolDataHeader.scss';

const ToolDataHeader = ({
  toolData,
  showDetails,
  toogleDetails
}: {
  toolData: IToolQuoteInfo;
  showDetails: boolean;
  toogleDetails: () => void;
}) => {
  const { name, serial, nudosCareName, chargedFees } = toolData || {};
  const { subtotal, toolCareSavings } = chargedFees;

  const hasCare = !!nudosCareName && nudosCareName !== 'Free';
  const arrowVariant = showDetails ? 'arrowHeadDown' : 'arrowHeadDown';

  return (
    <div className="toolDataHeader" onClick={toogleDetails}>
      <div className="left">
        <NudosHoverText onlyIfTruncated customClassName="toolName" charactersLimit={28} text={name} />

        {!!serial && (
          <div className="serialContainer">
            <BordOneToneIcon variant="barCode2" standardSize={12} stroke={bordColorTheme.content.secondary} />
            <NudosHoverText onlyIfTruncated customClassName="toolSerial" charactersLimit={18} text={serial} />
          </div>
        )}
        <NudosCareTag nudosCareType={nudosCareName} />
      </div>

      <div className="right">
        <div className="toolSubtotal">${formatCost(hasCare && toolCareSavings ? toolCareSavings : subtotal)} USD</div>
        <BordOneToneIcon variant={arrowVariant} customWidth={'1.8rem'} iconDivProps={{ onClick: toogleDetails }} />
      </div>
    </div>
  );
};

export default ToolDataHeader;
