import { FlowToChangeCountryStore } from '../FlowToChangeCountryStore';
import useStoreCountryState from '../../state/useStoreCountryState';
import { Trans, useTranslation } from 'react-i18next';
import { getCountryName } from '../../utils/getCountries';
import { BordFlag, BordOneToneIcon } from '../BordDesignSystem';
import { TcountryCode } from '../BordDesignSystem/BordFlag/BordFlag.types';
import './CountryButton.scss';

interface ICountryButton {
  handleChangeStatusModal: (status: boolean) => void;
  openModal: boolean;
}

const CountryButton = ({ openModal, handleChangeStatusModal }: ICountryButton) => {
  const { t } = useTranslation();
  const translations = 'ecommerce:countryButton:';
  const { storeCountry } = useStoreCountryState();

  const countryCode = storeCountry?.countryCode as TcountryCode;

  const translatedCountryName = storeCountry
    ? getCountryName({
        code: storeCountry.countryCode,
        flag: storeCountry.countryFlag,
        id: storeCountry.id,
        name: storeCountry.countryName,
        nameStringId: storeCountry.nameStringId
      })
    : t(`${translations}countryNotSelected`);

  const closeModal = () => {
    handleChangeStatusModal(false);
  };

  return (
    <div id="countryButton">
      {openModal && <FlowToChangeCountryStore closeModals={closeModal} />} <div></div>
      {storeCountry ? (
        <>
          <BordFlag
            onClick={() => handleChangeStatusModal(true)}
            country={countryCode}
            variant="circle"
            standardSize={16}
          />
          <div className="storeText">
            <Trans
              i18nKey={`${translations}title`}
              components={{
                1: <span className="countryName" onClick={() => handleChangeStatusModal(true)}></span>,
                2: translatedCountryName
              }}
            />
          </div>
        </>
      ) : (
        <>
          <BordOneToneIcon
            className="cursor-pointer"
            variant="mapPin"
            standardSize={16}
            onClick={() => handleChangeStatusModal(true)}
          />
          <div className="noCountryText" onClick={() => handleChangeStatusModal(true)}>
            Escoge el país de compra
          </div>
        </>
      )}
    </div>
  );
};

export default CountryButton;
