import { SVGProps, forwardRef, memo } from 'react';
import { changeLanguage } from '../../utils/changeLanguage';

const SvgComponent = (props: IlanguageSelectionButton) => {
  const { width, currentLanguage } = props;

  const selectSpanish = () =>
    changeLanguage({
      language: {
        label: 'Spanish (Latin America)',
        code: 'es'
      }
    });
  const selectEnglish = () => changeLanguage({ language: { label: 'English (US)', code: 'en' } });

  const activelanguages = {
    rectangleFill: '#fff',
    rectangleStroke: '#1B202F',
    textFill: '#1B202F',
    textStroke: undefined
  };

  const inactiveLanguages = {
    rectangleFill: '#1B202F',
    rectangleStroke: '#1B202F',
    textFill: '#fff',
    textStroke: undefined
  };

  const spanishColors = currentLanguage === 'esMX' ? activelanguages : inactiveLanguages;
  const englishColors = currentLanguage === 'enUS' ? activelanguages : inactiveLanguages;

  return (
    <div className="languageSelectionButton" style={{ width }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 43 20">
        <g className="languageSelectionButton">
          <g className="spanish cursor-pointer" onClick={selectSpanish}>
            <path
              className="Rectangle 4930"
              fill={spanishColors?.rectangleFill || '#1B202F'}
              stroke={spanishColors?.rectangleStroke || '#1B202F'}
              d="M4 .5h17.5v19H4C2.067 19.5.5 17.933.5 16V4C.5 2.067 2.067.5 4 .5Z"
            />
            <g className="ES" fill={spanishColors?.textFill || '#fff'}>
              <path d="M4.25564 13.944h4.776c.18 0 .3-.108.324-.288l.132-1.032c.012-.18-.084-.288-.264-.288h-3.3l.192-1.488h3.048c.18 0 .3-.108.324-.288l.12-1.032c.024-.18-.072-.288-.252-.288h-3.048l.18-1.488h3.3c.18 0 .29996-.108.32396-.288l.132-1.032c.024-.18-.072-.288-.25196-.288h-4.776c-.18 0-.3.108-.324.288l-.888 7.224c-.024.18.072.288.252.288ZM13.3172 14.088c1.872 0 3.252-.852 3.456-2.496.204-1.644-1.764-2.148-2.292-2.352-.552-.204-1.584-.396-1.512-.936.036-.336.336-.54.9-.54.756 0 1.38.36 1.668.672.108.12.228.156.396.048l1.008-.624c.156-.096.24-.228.156-.384-.336-.744-1.428-1.476-2.796-1.476-1.56 0-3.156.66-3.372 2.388-.216 1.752 1.752 2.16 2.292 2.364.528.204 1.62.408 1.56.924-.036.348-.24.648-1.044.648-.876 0-1.608-.564-1.92-.96-.096-.144-.228-.168-.384-.06l-1.056.696c-.156.096-.228.24-.156.384.36.864 1.404 1.704 3.096 1.704Z" />
            </g>
          </g>
          <g className="english cursor-pointer" onClick={selectEnglish}>
            <path
              className="Rectangle 4931"
              fill={englishColors?.rectangleFill || '#fff'}
              stroke={englishColors?.rectangleStroke || '#1B202F'}
              d="M21.5.5H39c1.933 0 3.5 1.567 3.5 3.5v12c0 1.933-1.567 3.5-3.5 3.5H21.5V.5Z"
            />
            <g
              className="EN"
              fill={englishColors?.textFill || '#1B202F'}
              stroke={englishColors?.textStroke || undefined}
            >
              <path d="M25.2556 13.8h4.776c.18 0 .3-.108.324-.288l.132-1.032c.012-.18-.084-.288-.264-.288h-3.3l.192-1.488h3.048c.18 0 .3-.108.324-.288l.12-1.032c.024-.18-.072-.288-.252-.288h-3.048l.18-1.488h3.3c.18 0 .3-.108.324-.288l.132-1.032c.024-.18-.072-.288-.252-.288h-4.776c-.18 0-.3.108-.324.288l-.888 7.224c-.024.18.072.288.252.288ZM31.5781 13.8h1.188c.18 0 .3-.108.324-.288l.54-4.38 2.292 4.476c.072.132.168.192.324.192h1.2c.18 0 .3-.108.324-.288l.888-7.224c.024-.18-.072-.288-.252-.288h-1.188c-.18 0-.3.108-.324.288l-.54 4.392-2.304-4.488c-.072-.132-.168-.192-.324-.192h-1.188c-.18 0-.3.108-.324.288l-.888 7.224c-.024.18.072.288.252.288Z" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
const ForwardRef = forwardRef(SvgComponent);
const LanguageSelectionButton = memo(ForwardRef);
export default LanguageSelectionButton;

interface IlanguageSelectionButton extends SVGProps<SVGSVGElement> {
  width: string;
  currentLanguage: 'esMX' | 'enUS';
}
