import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordCrosshairIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M22 12c0 5.5228-4.4772 10-10 10m10-10c0-5.52285-4.4772-10-10-10m10 10h-4m-6 10C6.47715 22 2 17.5228 2 12m10 10v-4M2 12C2 6.47715 6.47715 2 12 2M2 12h4m6-10v4"
      />
    </svg>
  );
};

export default BordCrosshairIcon;
