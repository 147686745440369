const RoundGuyana = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#6DA544"
          d="M16.0003 0C13.1715 0 10.5147 0.735125 8.20898 2.02325V29.9767C10.5148 31.2649 13.1715 32 16.0003 32C24.8368 32 32.0003 24.8365 32.0003 16C32.0003 7.1635 24.8368 0 16.0003 0Z"
        />
        <path
          fill="white"
          d="M8.25538 1.99683C7.24882 2.55464 6.30895 3.21764 5.45026 3.97139C5.53707 3.89526 5.62476 3.82001 5.71313 3.74576L17.9672 15.9999L5.71307 28.254C5.62463 28.1798 5.53701 28.1045 5.4502 28.0284C6.30888 28.7821 7.24882 29.4451 8.25532 30.003L31.9996 15.9999L8.25538 1.99683Z"
        />
        <path
          fill="#FFDA2C"
          d="M6.90416 2.83643C6.41623 3.1743 5.9476 3.53786 5.50104 3.92643L15.9998 16.0001L5.50098 28.0737C5.94754 28.4622 6.41616 28.8259 6.9041 29.1637L29.2609 16.0001L6.90416 2.83643Z"
        />
        <path
          fill="black"
          d="M5.71371 3.74585C5.35802 4.04472 5.01477 4.35804 4.68652 4.68622L14.609 16L4.68652 27.3137C5.01477 27.642 5.35796 27.9554 5.71371 28.2541L17.9678 16L5.71371 3.74585Z"
        />
        <path
          fill="#F93939"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGuyana;
