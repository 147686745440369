import { BordButton, BordModal } from '../../../../../components/BordDesignSystem';
import BordIlustrationIcon from '../../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import './ModalLeaveFlow.scss';

interface IModalLeaveFlow {
  title: string;
  subtitle?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  firstButtonAction?: () => void;
  secondButtonAction?: () => void;
}

const ModalLeaveFlow = ({
  subtitle,
  title,
  secondButtonText,
  firstButtonText,
  firstButtonAction,
  secondButtonAction
}: IModalLeaveFlow) => {
  return (
    <BordModal
      modalContent={
        <div id="modalLeaveFlow">
          <div className="headerIconContainer">
            <BordIlustrationIcon variant="warning" standardSize={64} />
          </div>
          <div className="titleContainer">{title}</div>
          {subtitle && (
            <div className="subtitleContainer">
              <div className="subtitleModalText"> {subtitle}</div>
            </div>
          )}
          {(firstButtonText || secondButtonText) && (
            <div className="butonsContainer">
              {firstButtonText && (
                <BordButton
                  modeButton="primary"
                  bordButtonStyle="outlined"
                  customWidth="w-180"
                  customHeight="42"
                  label={firstButtonText}
                  onClick={() => {
                    if (firstButtonAction) firstButtonAction();
                  }}
                />
              )}
              {secondButtonText && (
                <BordButton
                  modeButton="primary"
                  customWidth="w-180"
                  customHeight="42"
                  label={secondButtonText}
                  onClick={() => {
                    if (secondButtonAction) secondButtonAction();
                  }}
                />
              )}
            </div>
          )}{' '}
        </div>
      }
    />
  );
};

export default ModalLeaveFlow;
