import { Link } from 'react-router-dom';
import { BordOneToneIcon } from '../../BordDesignSystem';
import { bordColorTheme } from '../../../styles/constants';
import { IbordOneToneIconProps } from '../../BordDesignSystem/BordOneToneIcon/BordOneToneIcon';
import './NudosRedirectionIcon.scss';

const NudosRedirectionIcon = ({
  redirectionDataObject,
  showHoverTooltip,
  isDeactivated,
  toolTipComponent,
  bordOneToneIconProps
}: {
  redirectionDataObject: { [key: string]: unknown };
  showHoverTooltip?: boolean;
  isDeactivated?: boolean;
  toolTipComponent?: JSX.Element;
  bordOneToneIconProps?: IbordOneToneIconProps;
}) => {
  const inactiveStyles = isDeactivated ? 'inactive' : '';
  return (
    <div className={`nudosRedirectionIcon ${inactiveStyles}`}>
      {!isDeactivated && (
        <Link to={redirectionDataObject}>
          <BordOneToneIcon
            variant="externalLink"
            standardSize={14}
            stroke={bordColorTheme.primary.bgDefault}
            {...bordOneToneIconProps}
          />
        </Link>
      )}
      {isDeactivated && (
        <BordOneToneIcon
          variant="externalLink"
          standardSize={14}
          stroke={bordColorTheme.content.secondary}
          {...bordOneToneIconProps}
        />
      )}
      {showHoverTooltip && toolTipComponent && <div className="tooltip">{toolTipComponent}</div>}
    </div>
  );
};

export default NudosRedirectionIcon;
