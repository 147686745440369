const SquareAlgeria = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#fff" rx="2" />
          <path
            fill="#fff"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M0 0h11v16H0V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M13.6735 10.9472C12.8124 12.0746 11.4651 12.8 9.95238 12.8c-2.60333 0-4.71428-2.1494-4.71428-4.80005 0-2.65067 2.11095-4.8 4.71428-4.8 1.51272 0 2.86002.72533 3.72112 1.8528-.6652-.4928-1.5023-.78613-2.4116-.78613-2.16856 0-3.92856 1.67146-3.92856 3.73333 0 2.06185 1.76 3.73335 3.92856 3.73335.9093 0 1.7464-.2933 2.4116-.7861Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="m13.619 7.9999 1.0477-.53333.5238-1.06667.5238 1.06667 1.0476.53333-1.0476.53334-.5238 1.06666-.5238-1.06666-1.0477-.53334Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAlgeria;
