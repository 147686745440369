import create from 'zustand';
import { ImoduleFilter } from '../types/global';

export interface iState {
  numberOrders: number;
  ordersFilters: ImoduleFilter[];
  setOrdersFilters: (newFilter: ImoduleFilter[]) => void;
  setOrderNumbers: (orders: number) => void;
}

const useStateOrders = create<iState>(set => ({
  numberOrders: 0,
  ordersFilters: [],
  setOrdersFilters: newFilters => set(() => ({ ordersFilters: newFilters })),
  setOrderNumbers: numberOrders => set({ numberOrders })
}));

export default useStateOrders;
