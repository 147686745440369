import { useCallback, useEffect, useRef } from 'react';
import { Tany } from '../../../types/global';
import './NudosGenericSelect.scss';

const NudosGenericSelect = ({
  selectOptions,
  changeSelectedOption,
  defaultValue,
  selectClassName,
  changeOpenStatusFunction,
  absolutePositionElement
}: {
  selectOptions: JSX.Element[];
  changeSelectedOption: React.Dispatch<React.SetStateAction<Tany>>;
  defaultValue: Tany;
  selectClassName?: string;
  changeOpenStatusFunction?: () => void;
  absolutePositionElement?: JSX.Element;
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        changeSelectedOption(defaultValue);
        changeOpenStatusFunction && changeOpenStatusFunction();
      }
    },
    [refContainer]
  );

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div ref={refContainer} className={`nudosGenericSelect ${selectClassName ? selectClassName : ''}`}>
      <div className="containerAbsolute">{selectOptions}</div>
      {absolutePositionElement}
    </div>
  );
};

export default NudosGenericSelect;

export interface IElement {
  target: Tany;
}
