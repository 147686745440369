import { useTranslation } from 'react-i18next';
import { NudosToolTip } from '../../../../../components/NudosComponents';
import { BordButton } from '../../../../../components/BordDesignSystem';
import { ICountryDetail } from '../../../../../types/countries';
import { DTOgetTaxationRelatedDefinitionsOptions, IbillingData } from '../../../../../types/checkout';
import { useBillingData } from '../../../../../hooks/useBillingData';
import './BillingDataByCountryForm.scss';

const BillingDataByCountryForm = ({
  initialBillingData = {},
  showSkeletonLoader,
  updateDataCallback,
  listOfAvailableCountries,
  taxationRelatedDefinitionsOptions,
  isNewCountry = false
}: {
  initialBillingData?: IbillingData;
  showSkeletonLoader?: boolean;
  isNewCountry?: boolean;
  updateDataCallback?: () => void;
  listOfAvailableCountries?: ICountryDetail[];
  taxationRelatedDefinitionsOptions?: DTOgetTaxationRelatedDefinitionsOptions;
}) => {
  const {
    loadingBillingDataForm,
    hasBillingDataChange,
    isDataIncomplete,
    isAMexicanOrganization,
    thisBillingCountryRequiresTaxSituationDocument,
    businessNameTooltip,
    organizationIsUruguay,
    organizationIsColombia,
    isOtherOrganization,
    businessHasSpecialNameType,
    getEmailInput,
    getCityInput,
    getZipCodeInput,
    getBillingDataStateInput,
    getTaxDocumentInput,
    getBusinessNameInput,
    getBillingAddressInput,
    getAdditionalReferencesInput,
    getTaxRegimeDropdownWithSearchbar,
    getTaxUseDropdownWithSearchbar,
    getTaxDocumentUploadDownloadButton,
    getNudosCountryDropwdown,
    handleUpdateBillingData,
    getFantasyNameInput,
    getPhoneNumberInput,
    getSpecialBusinessNameType
  } = useBillingData(initialBillingData);
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:';

  const saveButton = (
    <BordButton
      modeButton="tertiary"
      customClassName="saveButton"
      label={t(`${i18nLanguageKey}saveButton`)}
      onClick={() => handleUpdateBillingData(updateDataCallback)}
      disabled={isDataIncomplete() || !hasBillingDataChange()}
      isLoading={(!isDataIncomplete() && loadingBillingDataForm) || showSkeletonLoader}
    />
  );
  return (
    <>
      <div className="billingDataByCountryForm">
        {showSkeletonLoader && <div className="countryTitleSkeletonLoader animationLoader" />}
        {isOtherOrganization && (
          <>
            <div className="formFieldsLine firstLine">
              {isNewCountry && (
                <div className="countryDropwdown">
                  {getNudosCountryDropwdown(
                    listOfAvailableCountries || [],
                    !!initialBillingData?.documentCountry?.id,
                    showSkeletonLoader
                  )}
                </div>
              )}
              <div className="businessNameContainer">{getBusinessNameInput(showSkeletonLoader)}</div>
              {getTaxDocumentInput(showSkeletonLoader)}
              {getEmailInput(showSkeletonLoader)}
              {getZipCodeInput(showSkeletonLoader)}
              {getCityInput(showSkeletonLoader)}
              {getBillingDataStateInput(showSkeletonLoader)}

              {thisBillingCountryRequiresTaxSituationDocument && getTaxDocumentUploadDownloadButton(showSkeletonLoader)}
            </div>
            <div className="formFieldsLine secondLine">
              {getBillingAddressInput(showSkeletonLoader)}
              {getAdditionalReferencesInput(showSkeletonLoader)}
              {saveButton}
            </div>
          </>
        )}
        {organizationIsUruguay && (
          <>
            <div className="formFieldsLine firstLine">
              {isNewCountry && (
                <div className="countryDropwdown">
                  {getNudosCountryDropwdown(
                    listOfAvailableCountries || [],
                    !!initialBillingData?.documentCountry?.id,
                    showSkeletonLoader
                  )}
                </div>
              )}
              <div className="businessNameContainer">{getBusinessNameInput(showSkeletonLoader)}</div>
              {getFantasyNameInput(showSkeletonLoader)}
              {getTaxDocumentInput(showSkeletonLoader)}
              {getEmailInput(showSkeletonLoader)}
              {getZipCodeInput(showSkeletonLoader)}
            </div>
            <div className="formFieldsLine secondLine">
              <div className="businessNumberContainer">{getPhoneNumberInput(listOfAvailableCountries || [])}</div>
              {getCityInput(showSkeletonLoader)}
              {getBillingDataStateInput(showSkeletonLoader)}
              {getAdditionalReferencesInput(showSkeletonLoader)}
            </div>
            <div className="formFieldsLine thirdLine">
              {getBillingAddressInput(showSkeletonLoader)}
              {saveButton}
            </div>
          </>
        )}

        {isAMexicanOrganization && (
          <>
            <div className="formFieldsLine firstLine">
              <div className="businessNameContainer">{getBusinessNameInput(showSkeletonLoader)} </div>
              {getTaxDocumentInput(showSkeletonLoader)}
              {getEmailInput(showSkeletonLoader)}
              {getZipCodeInput(showSkeletonLoader)}
              {getCityInput(showSkeletonLoader)}
              {getBillingDataStateInput(showSkeletonLoader)}
            </div>

            <div className="formFieldsLine secondLine">
              {getTaxRegimeDropdownWithSearchbar(
                taxationRelatedDefinitionsOptions?.taxSituation || [],
                showSkeletonLoader
              )}
              {getTaxUseDropdownWithSearchbar(taxationRelatedDefinitionsOptions?.taxUse || [], showSkeletonLoader)}
              {getAdditionalReferencesInput(showSkeletonLoader)}
            </div>
            <div className="formFieldsLine thirdLine">
              {getBillingAddressInput(showSkeletonLoader)}
              {thisBillingCountryRequiresTaxSituationDocument && getTaxDocumentUploadDownloadButton(showSkeletonLoader)}
              {saveButton}
            </div>
          </>
        )}
        {organizationIsColombia && (
          <>
            <div className="formFieldsLine firstLine">
              <div className="businessNameContainer">{getBusinessNameInput(showSkeletonLoader)}</div>
              {getTaxDocumentInput(showSkeletonLoader)}
              {getEmailInput(showSkeletonLoader)}
              {getZipCodeInput(showSkeletonLoader)}
              {getCityInput(showSkeletonLoader)}
              {getBillingDataStateInput(showSkeletonLoader)}
            </div>
            <div className="formFieldsLine secondLine">
              {getAdditionalReferencesInput(showSkeletonLoader)}
              {getBillingAddressInput(showSkeletonLoader)}
            </div>
            <div className="formFieldsLine thirdLine">
              {thisBillingCountryRequiresTaxSituationDocument && getTaxDocumentUploadDownloadButton(showSkeletonLoader)}
              {saveButton}
            </div>
          </>
        )}
      </div>
      {getSpecialBusinessNameType()}
      <div className="divider" />
    </>
  );
};

export default BillingDataByCountryForm;
