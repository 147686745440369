const SquarePanama = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="white"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M6.25843 5.3058C6.18929 5.94153 5.99233 6.4002 5.76186 6.4002C5.53138 6.4002 5.33443 5.94153 5.26529 5.3058C4.64091 5.2354 4.19043 5.03486 4.19043 4.8002C4.19043 4.56553 4.64091 4.365 5.26529 4.2946C5.33443 3.65886 5.53138 3.2002 5.76186 3.2002C5.99233 3.2002 6.18929 3.65886 6.25843 4.2946C6.88281 4.365 7.33329 4.56553 7.33329 4.8002C7.33329 5.03486 6.88281 5.2354 6.25843 5.3058Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M16.735 12.7721C16.6658 13.4078 16.4689 13.8665 16.2384 13.8665C16.0079 13.8665 15.811 13.4078 15.7418 12.7721C15.1175 12.7017 14.667 12.5012 14.667 12.2665C14.667 12.0318 15.1175 11.8313 15.7418 11.7609C15.811 11.1252 16.0069 10.6665 16.2384 10.6665C16.4689 10.6665 16.6658 11.1252 16.735 11.7609C17.3594 11.8313 17.8098 12.0308 17.8098 12.2665C17.8098 12.5012 17.3594 12.7017 16.735 12.7721Z"
            clipRule="evenodd"
          />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 8H11V16H0V8Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M11 0H22V8H11V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquarePanama;
