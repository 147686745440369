const SquareSomalia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#3A99FF" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M11.0019 9.95193L8.81237 11.1253L9.23141 8.64313L7.46094 6.88633L9.90818 6.52367L11.0019 4.2666L12.0967 6.52367L14.5428 6.88633L12.7724 8.64313L13.1914 11.1242"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSomalia;
