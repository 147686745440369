import create from 'zustand';
import { IemployeeInfo } from '../../../../types/global';

export interface IassignationState {
  step: number;
  wasUnassigned: boolean;
  selectedEmployee?: IemployeeInfo;
  setStep: (step: number) => void;
  setWasUnassigned: (wasUnassigned: boolean) => void;
  addSteps: (n?: number) => void;
  setSelectedEmployee: (selectedEmoloyee: IemployeeInfo) => void;
}

const useAssignationState = create<IassignationState>(set => ({
  step: 0,
  wasUnassigned: false,
  selectedEmployee: undefined,
  setStep: step => set({ step }),
  setWasUnassigned: wasUnassigned => set({ wasUnassigned }),
  addSteps: n => set(state => ({ step: n ? state.step + n : state.step + 1 })),
  setSelectedEmployee: selectedEmployee => set({ selectedEmployee })
}));

export default useAssignationState;
