import { FC } from 'react';
import { BordButton, BordTextArea, ModalContent, BordModal } from '../../../../../../components/BordDesignSystem';
import { MenuFaqsIcon } from '../../../../../../assets/images/components';
import XSign from '../../../../../../assets/images/components/NudosComponents/XSign';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import './CancelLogisticServiceModal.scss';

interface ICancelLogisticServiceModal {
  defaultValue?: string;
  updateValue?: (value: string) => void;
  continueCancellation?: () => void;
  skipCancellation?: () => void;
  loading?: boolean;
  total?: number;
}

const CancelLogisticServiceModal: FC<ICancelLogisticServiceModal> = ({
  defaultValue,
  updateValue,
  continueCancellation,
  skipCancellation,
  loading,
  total
}: ICancelLogisticServiceModal) => {
  return (
    <BordModal
      modalContent={
        <ModalContent
          CustomModalComponent={
            <div id="cancelLogisticServiceModal">
              <div className="boxCancelLogisticServiceModal">
                <div
                  className="floatCloseX"
                  onClick={() => {
                    if (skipCancellation && !loading) {
                      skipCancellation();
                      segmentTrackEvent({
                        nodiLogisticServicesRejectQuoteModalCloseClick: {
                          Comment: !!defaultValue,
                          LogisticServiceTotalUsd: total || 0
                        }
                      });
                    }
                  }}
                >
                  <XSign fill="#B3B3B3" />
                </div>
                <div className="iconCancelModalLine">
                  <div className="iconContainer">
                    <div className="colorIconCancel">
                      <MenuFaqsIcon stroke="#54A1FC" className="scaleQuestionIcon" />
                    </div>
                  </div>
                </div>
                <div className="titleCancelModalLine">
                  <div className="orangeCancelModalText">Ayúdanos a mejorar</div>
                </div>
                <div className="subtitleCancelModalLine">
                  Tu servicio logístico fue rechazado. Sin embargo, nos encantaría
                  <br /> conocer los motivos para poder ofrecerte un mejor servicio.
                </div>
                <div className="textAreaCancelModalLine">
                  <div className="containerTextArea">
                    <BordTextArea
                      label="Comentarios"
                      value={defaultValue || ''}
                      maxLength={1000}
                      setInputValue={e => {
                        if (updateValue) {
                          updateValue(e);
                        }
                      }}
                      placeholder="Cuéntanos las razones por las cuales rechazaste tu servicio"
                    />
                  </div>
                </div>
                <div className="firstButtonCancelModalLine">
                  <BordButton
                    isLoading={loading}
                    customWidth="w-280"
                    label="Continuar"
                    disabled={!defaultValue}
                    onClick={() => {
                      if (continueCancellation) {
                        segmentTrackEvent({
                          nodiLogisticServicesRejectQuoteModalContinueClick: {
                            Comment: !!defaultValue,
                            LogisticServiceTotalUsd: total || 0
                          }
                        });
                        continueCancellation();
                      }
                    }}
                  />
                </div>
                <div className="secondButtonCancelModalLine">
                  <button
                    disabled={loading}
                    className="whiteButton"
                    onClick={() => {
                      if (skipCancellation) {
                        segmentTrackEvent({
                          nodiLogisticServicesRejectQuoteModalContinueSkipClick: {
                            Comment: !!defaultValue,
                            LogisticServiceTotalUsd: total || 0
                          }
                        });
                        skipCancellation();
                      }
                    }}
                  >
                    Omitir
                  </button>
                </div>
              </div>
            </div>
          }
        />
      }
    />
  );
};

export default CancelLogisticServiceModal;
