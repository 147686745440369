const SquareSaintLucia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#3ECBF8" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M6.28613 13.8666L11.0004 2.1333L15.7147 13.8666H6.28613Z"
          clipRule="evenodd"
        />
        <path
          fill="#151515"
          fillRule="evenodd"
          d="M7.33301 13.8666L10.9997 4.2666L14.6663 13.8666H7.33301Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M6.28613 13.8665L11.0004 8.5332L15.7147 13.8665H6.28613Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSaintLucia;
