import { TbordilustrationIcon } from '../../BordDesignSystem/BordIlustrationIcon/BordIlustration.types';
import BordIlustrationIcon from '../../BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import './NudosLogisticsGeneralContainer.scss';

/**
 * IMPORTANT: to show the dividing line include where needed, in the children, a div with the classname "divider"
 * @property { string } title - The title for the container
 * @property { React.ReactNode } children - The children
 */
const NudosLogisticsGeneralContainer = ({
  title,
  children,
  customStyles,
  customClassname,
  showIlustrationByVariantName
}: {
  title?: string;
  children: React.ReactNode;
  customStyles?: { [key: string]: string };
  customClassname?: string;
  showIlustrationByVariantName?: TbordilustrationIcon;
}) => {
  return (
    <div className={`NudosLogisticsGeneralContainer ${customClassname || ''}`} style={customStyles}>
      {!!showIlustrationByVariantName && (
        <div className="centerIlustrationIcon">
          <BordIlustrationIcon variant={showIlustrationByVariantName} customWidth="5.8rem" />
        </div>
      )}
      {title && <div className="generalContainerTitle">{title}</div>}
      {children}
    </div>
  );
};

export default NudosLogisticsGeneralContainer;
