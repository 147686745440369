import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordPhoneOffIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#a)">
        <path
          stroke={stroke || bordColorTheme.navyBlue[400]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M5.19 12.81c-1.68414-2.597-2.73564-5.55283-3.07-8.63-.02499-.27653.00787-.55524.0965-.81838.08863-.26313.23107-.50493.41827-.71.18719-.20507.41503-.36891.66902-.4811.25399-.11219.52855-.17026.80621-.17052h3c.48531-.00478.95579.16708 1.32376.48353.36797.31646.60832.75592.67624 1.23647.12662.96007.36145 1.90273.7 2.81.13454.35792.16366.74691.08391 1.12088-.07976.37397-.26505.71723-.53391.98912L8.09 9.91M23 1 1 23m9.68-9.69c1.0149 1.0157 2.1618 1.8903 3.41 2.6l1.27-1.27c.2719-.2689.6152-.4542.9891-.5339.374-.0798.763-.0506 1.1209.0839.9073.3386 1.8499.5734 2.81.7.4806.0679.92.3083 1.2365.6762.3164.368.4883.8385.4835 1.3238v3c.0011.2785-.0559.5542-.1675.8093-.1116.2552-.2752.4843-.4804.6726-.2052.1882-.4475.3316-.7114.4208-.2638.0892-.5433.1224-.8207.0973-3.0772-.3344-6.033-1.3859-8.63-3.07-1.20473-.7649-2.32147-1.6604-3.33-2.67l2.82-2.84Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BordPhoneOffIcon;
