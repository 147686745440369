const RoundSingapore = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#F93939" d="M0 16.0001C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16.0001" />
        <path
          fill="white"
          d="M9.73934 8.34777C9.73934 6.01665 11.3777 4.06927 13.5654 3.59146 13.2292 3.51808 12.8803 3.47827 12.5219 3.47827 9.83247 3.47827 7.65234 5.6584 7.65234 8.34783 7.65234 11.0373 9.83247 13.2174 12.5219 13.2174 12.8802 13.2174 13.2291 13.1776 13.5654 13.1041 11.3777 12.6263 9.73934 10.679 9.73934 8.34777ZM16 3.82617 16.3454 4.88898H17.4629L16.5587 5.54592 16.9041 6.60873 16 5.95192 15.0959 6.60873 15.4412 5.54592 14.5371 4.88898H15.6546L16 3.82617Z"
        />
        <path
          fill="white"
          d="M13.2892 5.91309 13.6345 6.97596H14.752L13.8479 7.63284 14.1933 8.69565 13.2892 8.03884 12.3849 8.69565 12.7304 7.63284 11.8262 6.97596H12.9437L13.2892 5.91309ZM18.7109 5.91309 19.0564 6.97596H20.1739L19.2697 7.63284 19.6151 8.69565 18.7109 8.03884 17.8068 8.69565 18.1522 7.63284 17.248 6.97596H18.3655L18.7109 5.91309ZM17.668 9.04346 18.0133 10.1063H19.1308L18.2267 10.7632 18.5721 11.826 17.668 11.1692 16.7638 11.826 17.1092 10.7632 16.2051 10.1063H17.3225L17.668 9.04346ZM14.3331 9.04346 14.6784 10.1063H15.796L14.8918 10.7632 15.2372 11.826 14.3331 11.1692 13.4289 11.826 13.7742 10.7632 12.8701 10.1063H13.9877L14.3331 9.04346Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSingapore;
