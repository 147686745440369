import { createMachine } from 'xstate';
import { Tany } from '../../../types/global';

export function onScroll(e: React.UIEvent<HTMLDivElement>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) {
  const event: Tany = e.target;
  const bottom = event.scrollHeight - event.scrollTop === event.clientHeight;
  if (bottom) {
    setLoading(true);
  }
}

export const productListMachine = createMachine({
  id: 'productList',
  initial: 'pending',
  states: {
    pending: { on: { RESOLVE: { target: 'resolved' }, REJECT: { target: 'rejected' }, MISS: 'missed' } },
    resolved: {
      on: { DATA_CHANGE: { target: 'pending' } }
    },
    rejected: { on: { DATA_CHANGE: { target: 'pending' } } },
    missed: { on: { DATA_CHANGE: { target: 'pending' } } }
  }
});
