import { ITwoToneIconProps } from '../BordTwoToneIcon.types';
import { bordColorTheme } from '../../../../styles/constants';

const BordTwoToneMenuSquareIcon = (props: ITwoToneIconProps) => {
  const { colorTones } = props;
  const { tone1, tone2, opacity1, opacity2 } = colorTones || {};
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g stroke={tone1 || bordColorTheme.navyBlue[400]} strokeWidth="1.5" opacity={opacity1 || '.4'}>
        <path d="M2 18c0-1.5404 0-2.3107.34673-2.8765.19401-.3166.4602-.5828.7768-.7768C3.68934 14 4.45956 14 6 14c1.54044 0 2.31066 0 2.87647.3467.3166.194.58279.4602.7768.7768C10 15.6893 10 16.4596 10 18s0 2.3107-.34673 2.8765c-.19401.3166-.4602.5828-.7768.7768C8.31066 22 7.54044 22 6 22c-1.54044 0-2.31066 0-2.87647-.3467-.3166-.194-.58279-.4602-.7768-.7768C2 20.3107 2 19.5404 2 18ZM14 6c0-1.54044 0-2.31066.3467-2.87647.194-.3166.4602-.58279.7768-.7768C15.6893 2 16.4596 2 18 2s2.3107 0 2.8765.34673c.3166.19401.5828.4602.7768.7768C22 3.68934 22 4.45956 22 6c0 1.54044 0 2.31066-.3467 2.87647-.194.3166-.4602.58279-.7768.7768C20.3107 10 19.5404 10 18 10s-2.3107 0-2.8765-.34673c-.3166-.19401-.5828-.4602-.7768-.7768C14 8.31066 14 7.54044 14 6Z" />
      </g>
      <path
        stroke={tone2 || bordColorTheme.navyBlue[400]}
        strokeWidth="1.5"
        d="M14 18c0-1.5404 0-2.3107.3467-2.8765.194-.3166.4602-.5828.7768-.7768C15.6893 14 16.4596 14 18 14s2.3107 0 2.8765.3467c.3166.194.5828.4602.7768.7768C22 15.6893 22 16.4596 22 18s0 2.3107-.3467 2.8765c-.194.3166-.4602.5828-.7768.7768C20.3107 22 19.5404 22 18 22s-2.3107 0-2.8765-.3467c-.3166-.194-.5828-.4602-.7768-.7768C14 20.3107 14 19.5404 14 18ZM2 6c0-1.54044 0-2.31066.34673-2.87647.19401-.3166.4602-.58279.7768-.7768C3.68934 2 4.45956 2 6 2c1.54044 0 2.31066 0 2.87647.34673.3166.19401.58279.4602.7768.7768C10 3.68934 10 4.45956 10 6c0 1.54044 0 2.31066-.34673 2.87647-.19401.3166-.4602.58279-.7768.7768C8.31066 10 7.54044 10 6 10c-1.54044 0-2.31066 0-2.87647-.34673-.3166-.19401-.58279-.4602-.7768-.7768C2 8.31066 2 7.54044 2 6Z"
        opacity={opacity2}
      />
    </svg>
  );
};

export default BordTwoToneMenuSquareIcon;
