const BordDangerIcon = () => {
  return (
    <svg viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon">
        <circle id="Ellipse 1416" cx="26.6492" cy="25.0086" r="24.6841" fill="url(#paint0_linear_6638_2530)" />
        <g id="Ellipse 1417" filter="url(#filter0_bi_6638_2530)">
          <ellipse cx="25.3504" cy="25.6581" rx="24.6841" ry="24.6841" fill="white" fillOpacity="0.2" />
        </g>
        <g id="Group 23">
          <g id="Vector">
            <path
              d="M16.3942 13.811C16.9016 13.3037 17.7241 13.3037 18.2315 13.811L37.5231 33.1026C38.0304 33.61 38.0304 34.4326 37.5231 34.9399L34.3078 38.1552C33.8005 38.6625 32.9779 38.6625 32.4705 38.1552L13.1789 18.8636C12.6716 18.3562 12.6716 17.5337 13.1789 17.0263L16.3942 13.811Z"
              fill="#FF6175"
            />
            <path
              d="M13.1792 34.9399C12.6718 34.4325 12.6718 33.6099 13.1792 33.1026L32.4707 13.811C32.9781 13.3036 33.8007 13.3036 34.308 13.811L37.5233 17.0263C38.0307 17.5336 38.0307 18.3562 37.5233 18.8636L18.2317 38.1551C17.7244 38.6625 16.9018 38.6625 16.3944 38.1551L13.1792 34.9399Z"
              fill="#FF6175"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_bi_6638_2530"
          x="-2.3337"
          y="-2.026"
          width="55.3682"
          height="55.3682"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6638_2530" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_6638_2530" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.835294 0 0 0 0 0.847059 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_6638_2530" />
        </filter>
        <linearGradient
          id="paint0_linear_6638_2530"
          x1="24.3757"
          y1="26.6325"
          x2="1.96515"
          y2="16.2392"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0719773" stopColor="#1B202F" />
          <stop offset="1" stopColor="#FF6175" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BordDangerIcon;
