const RoundPakistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M0 16.0001C0 22.0648 3.37431 27.341 8.34781 30.0547V1.94556C3.37431 4.65924 0 9.93543 0 16.0001Z"
        />
        <path
          fill="#496E2D"
          d="M15.9998 0C13.228 0 10.6208 0.705188 8.34766 1.94544V30.0545C10.6208 31.2948 13.228 32 15.9998 32C24.8363 32 31.9998 24.8365 31.9998 16C31.9998 7.1635 24.8363 0 15.9998 0Z"
        />
        <path
          fill="white"
          d="M22.8413 18.6461C20.8171 20.1087 17.9904 19.6534 16.5279 17.6293 15.0653 15.6051 15.5206 12.7785 17.5448 11.316 18.1759 10.86 18.8851 10.5904 19.6065 10.4967 18.2186 10.2892 16.7514 10.6019 15.5241 11.4887 13.0328 13.2889 12.4724 16.7677 14.2724 19.2591 16.0725 21.7502 19.5514 22.3107 22.0428 20.5105 23.2702 19.6237 24.0278 18.3291 24.2664 16.9461 23.951 17.6014 23.4724 18.1901 22.8413 18.6461ZM22.7545 10.4351 23.8948 11.6639 25.4158 10.9594 24.5993 12.4234 25.7395 13.6523 24.0948 13.3282 23.2784 14.7924 23.0783 13.128 21.4336 12.8039 22.9547 12.0994 22.7545 10.4351Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPakistan;
