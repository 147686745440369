import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NudosAddressSearchBar } from '../../../../../components/NudosComponents';
import { BordFlag, BordOneToneIcon, BordTextInput } from '../../../../../components/BordDesignSystem';
import validateFile from '../../../../../utils/validateFile';
import useNewEmployeeFormSchema from './NewEmployeeForm.schema';
import { IaddressFromGoogleMaps, Iemployee, INewEmployeeInfo, Tany } from '../../../../../types/global';
import { EmployeePills } from '../../../../../components';
import { ICountryDetail } from '../../../../../types/countries';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../utils/displayNudosStandardNotifications';
import {
  getAddressErrorText,
  getIdDocumentData,
  isEmailValid,
  isPhoneValid
} from '../../../../../utils/formValidations';
import { formatZipCode, getIsInvalidZipCode, invalidZipCodeText } from '../../../../../utils/productDefinitions';
import { getActiveOrdersByEmployee } from '../../../../../services/orders';
import { NudosCityDropdown, NudosCountryDropdown } from '../../../../../components/DesignSystem';
import { IcityDetails } from '../../../../../types/cities.types';
import BordPhoneInput from '../../../../../components/BordDesignSystem/BordPhoneInput/BordPhoneInput';
import EmployeeFormButtonsSection from './EmployeeFormButtonsSection/EmployeeFormButtonsSection';
import BordEmployeeAreaDropdown from '../../../../../components/DesignSystem/BordEmployeeAreaDropdown/BordEmployeeAreaDropdown';
import BordPhotoUploader from '../../../../../components/BordDesignSystem/BordPhotoUploader/BordPhotoUploader';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import usePlatform from '../../../../../hooks/usePlatform';
import './NewEmployeeForm.scss';

const NewEmployeeForm = ({
  handleSubmitCustomCallback,
  customTextButton,
  isButtonLoading,
  navigationOrigin,
  setCreateAndAssign,
  setRequireLogistics,
  currentEmployeeData,
  logisticsCountry,
  countriesList,
  listOfCountriesWithLogistics,
  showSkeletonLoader
}: {
  handleSubmitCustomCallback: (employee: INewEmployeeInfo, createAndAssignParam?: boolean) => void;
  customTextButton?: string;
  isButtonLoading?: boolean;
  navigationOrigin?: string;
  setCreateAndAssign?: (boolean: boolean) => void;
  setRequireLogistics?: React.Dispatch<React.SetStateAction<boolean>>;
  currentEmployeeData?: Iemployee;
  logisticsCountry?: string;
  countriesList?: ICountryDetail[];
  listOfCountriesWithLogistics?: ICountryDetail[];
  showSkeletonLoader?: boolean;
}) => {
  const { selectedArea, selectedCountry, setSelectedCountry, setSelectedArea } = useNewEmployeeFormSchema({
    countriesList: countriesList
  });
  const orgData = getOrgData();
  const { push } = useHistory();
  const { t } = useTranslation();
  const { state }: { state: { createFromSpecificCountry: string; requireAllFields: boolean } } = useLocation();
  const { screenWidth } = usePlatform();

  const [uploadedPhoto, setUploadedPhoto] = useState<File>();
  const [userAddress, setUserAddress] = useState<IaddressFromGoogleMaps | undefined>();
  const [userAdditionalReferences, setUserAdditionalReferences] = useState<string>();
  const [, setIsUploadedPhotoValid] = useState<boolean>();
  const [temporaryPhotoUrl, setTemporaryPhotoUrl] = useState<string>();
  const [emailAlreadyRegisteredError, setEmailAlreadyRegisteredError] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();
  const [phoneAlreadyRegisteredError, setPhoneAlreadyRegisteredError] = useState<string>();
  const [personalId, setPersonalId] = useState<string>();
  const [position, setPosition] = useState<string>();
  const [city, setCity] = useState<IcityDetails>();
  const [missingRequiredCityId, setMissingRequiredCityId] = useState(true);
  const [zipCode, setZipCode] = useState<string>();

  const { createFromSpecificCountry, requireAllFields } = state || {};
  const predefinedCountry = logisticsCountry || createFromSpecificCountry;
  const blockCountrySelection = !!predefinedCountry && !!selectedCountry;
  const newEmployeeForm = 'nodi:employeeInternal:newEmployeeForm:';
  const recurrentWords = 'recurrentWords:';
  const cityPillKey = t(`${newEmployeeForm}nameAndPills:employeePills:city`);
  const areaPillKey = t(`${newEmployeeForm}nameAndPills:employeePills:area`);
  const positionPillKey = t(`${newEmployeeForm}nameAndPills:employeePills:position`);
  const additionalReferencesErrorText = userAddress && !userAdditionalReferences ? 'Completa las indicaciones' : '';
  const currentName = firstName && lastName ? `${firstName} ${lastName}` : undefined;
  const idDocumentData = getIdDocumentData(selectedCountry || undefined, personalId);

  const alertEmployeeActiveOrders = async (employeeId?: number | string | null) => {
    if (!employeeId) return;
    try {
      const employeeActiveOrders = await getActiveOrdersByEmployee(employeeId);
      if (employeeActiveOrders?.length <= 0 || !employeeActiveOrders[0] || !employeeActiveOrders[0].orderId) return;
      const redirection = () =>
        push(`/nodi/orders/details/${employeeActiveOrders[0].orderId}?from=employee-edit&id=${employeeId}`);
      displaySuccessNotification({
        customJSXMessage: (
          <div>
            <div>Este empleado tiene una orden activa. Si editas datos en el</div>
            <div>perfil no se actualiza la orden, solo se actualizará el perfil.</div>
          </div>
        ),
        button1Text: 'Ver orden',
        button1Action: redirection
      });
    } catch {
      displayErrorNotification();
    }
  };

  const uploadedFileHandler = (newUploadedPhoto: Tany) => {
    if (!newUploadedPhoto) return;
    setUploadedPhoto(newUploadedPhoto);
    const isFileValid = validateFile(
      newUploadedPhoto,
      ['image/png', 'image/jpeg'],
      2,
      t(`recurrentWords:isImageFileValid:format`),
      t(`recurrentWords:isImageFileValid:size`)
    );
    setIsUploadedPhotoValid(isFileValid);
    isFileValid && setTemporaryPhotoUrl(URL.createObjectURL(newUploadedPhoto));
  };

  const employeePills = {
    [cityPillKey]: city?.name || `${t(`${newEmployeeForm}nameAndPills:employeePills:city`)}`,
    [areaPillKey]: selectedArea || `${t(`${newEmployeeForm}nameAndPills:employeePills:area`)}`,
    [positionPillKey]: position || `${t(`${newEmployeeForm}nameAndPills:employeePills:position`)}`
  };

  const defaultPhoneCountryData =
    currentEmployeeData?.country?.id &&
    currentEmployeeData?.country?.name &&
    currentEmployeeData?.country?.code &&
    currentEmployeeData?.country?.flag &&
    currentEmployeeData?.phoneData?.phoneCode
      ? {
          id: currentEmployeeData?.country?.id,
          name: currentEmployeeData?.country?.name,
          code: currentEmployeeData?.country?.code,
          flag: currentEmployeeData?.country?.flag,
          phoneCode: currentEmployeeData?.phoneData?.phoneCode
        }
      : undefined;

  const findCountryData = (countryCode?: string) => {
    if (!countryCode) return;
    return countriesList?.find(element => countryCode === element?.code);
  };
  const disableButton = () => {
    if (!orgData.organizationId || getPhoneError() || getEmailError()) return true;
    if (!firstName || !lastName || !city?.name || !email || !phoneNumber || !phoneCountry || !userAddress || !zipCode)
      return true;
    if (!selectedArea || !position) return true;
    if (!selectedCountry || missingRequiredCityId) return true;
    if (logisticsCountry && userAddress?.country !== logisticsCountry) return true;
    if ((logisticsCountry || requireAllFields) && !userAddress) return true;
    if (requireAllFields && !zipCode) return true;
    if (userAddress && selectedCountry && userAddress.country !== selectedCountry?.code) return true;
    if (idDocumentData?.errorText) return true;
    if (getIsInvalidZipCode(zipCode)) return true;
    if (userAddress && !userAdditionalReferences) return true;
    return false;
  };
  const addressErrorText = getAddressErrorText(
    selectedCountry?.code,
    selectedCountry?.name,
    userAddress?.country || undefined,
    !!userAddress?.country,
    userAddress?.coordinates
  );

  const getEmailError = () => {
    if (!!email && !isEmailValid(email)) return t('recurrentWords:inputError:invalidEmail');
    return emailAlreadyRegisteredError;
  };

  const getPhoneError = () => {
    if (phoneNumber && phoneNumber.length > 3 && !isPhoneValid(phoneNumber))
      return `${t('recurrentWords:inputError:invalidPhone')} ${
        phoneCountry?.name || t(`${newEmployeeForm}firstLineInputs:phone`)
      }`;
    return phoneAlreadyRegisteredError;
  };

  const getZipCodeErrorText = () => {
    if (requireAllFields && !zipCode && userAdditionalReferences) return ' ';
    return getIsInvalidZipCode(zipCode) ? invalidZipCodeText : undefined;
  };

  const registerInitialData = () => {
    if (!firstName) setFirstName(currentEmployeeData?.firstName || undefined);
    if (!lastName) setLastName(currentEmployeeData?.lastName || undefined);
    if (!email) setEmail(currentEmployeeData?.mail || undefined);
    if (!city?.name) setCity({ name: currentEmployeeData?.city || undefined, id: currentEmployeeData?.cityId });
    if (!personalId) setPersonalId(currentEmployeeData?.personalId || undefined);
    if (!position) setPosition(currentEmployeeData?.position || undefined);
    if (!zipCode) setZipCode(formatZipCode(currentEmployeeData?.address?.zipCode) || undefined);
    if (!selectedArea) setSelectedArea(currentEmployeeData?.area || undefined);
  };

  const completeAddress = {
    address: userAddress?.address || '',
    coordinates: userAddress?.coordinates,
    additionalReferences: userAdditionalReferences,
    country: userAddress?.country,
    zipCode,
    city: city?.name?.trim()
  };

  const submitButtonBody = {
    organizationId: orgData?.organizationId,
    firstName: firstName?.trim(),
    lastName: lastName?.trim(),
    personalId: personalId?.trim(),
    email: email?.trim(),
    phone: phoneNumber?.trim(),
    city: city?.name?.trim(),
    cityId: city?.id,
    area: selectedArea?.trim(),
    position: position?.trim(),
    country: selectedCountry,
    countryPhone: phoneCountry,
    uploadedPhoto,
    address: completeAddress
  };

  useEffect(() => {
    // This effect handles country preselection in the cases where an specific country is required
    if (!predefinedCountry || !countriesList?.length) return;
    const countryFromWhichToCreate = countriesList?.find(country => country?.name === createFromSpecificCountry);
    const predefinedCountryCode = logisticsCountry ? logisticsCountry : countryFromWhichToCreate?.code;
    const newCountry = findCountryData(predefinedCountryCode);
    newCountry && setSelectedCountry(newCountry);
  }, [predefinedCountry, countriesList]);

  useEffect(() => {
    if (currentEmployeeData?.address?.address && currentEmployeeData?.address?.country) {
      setUserAddress({
        address: currentEmployeeData?.address?.address,
        country: currentEmployeeData?.address?.country,
        city: currentEmployeeData?.address?.city || '',
        coordinates: currentEmployeeData?.address?.coordinates || undefined
      });
    }
    if (currentEmployeeData?.country) setSelectedCountry(currentEmployeeData?.country);
    setUserAdditionalReferences(currentEmployeeData?.address?.additionalReferences || '');
  }, [currentEmployeeData]);

  useEffect(() => {
    registerInitialData();
    currentEmployeeData && alertEmployeeActiveOrders(currentEmployeeData?.userId);
  }, [currentEmployeeData]);

  useEffect(() => {
    phoneAlreadyRegisteredError && setPhoneAlreadyRegisteredError(undefined);
  }, [phoneNumber]);

  useEffect(() => {
    emailAlreadyRegisteredError && setEmailAlreadyRegisteredError(undefined);
  }, [email]);

  return (
    <div className="newAssigneeForm">
      <section className="generalInfoSection">
        <div className="profilePhotoSection">
          <div className="photoContainer">
            <BordPhotoUploader
              uploadedFileHandler={uploadedFileHandler}
              selectedImageUrl={temporaryPhotoUrl || currentEmployeeData?.photoProfile || ''}
              showSkeletonLoader={showSkeletonLoader}
            />
          </div>
          <div className="employeeSummary">
            <div className={`nameAndFlag ${currentName ? 'filled' : ''}`}>
              <div className="name">{currentName || t(`${newEmployeeForm}nameAndPills:noName`)}</div>
              {selectedCountry?.code && (
                <BordFlag variant="circle" country={selectedCountry?.code as TcountryCode} standardSize={20} />
              )}
            </div>
            <div className={`emailSection ${email ? 'filled' : ''}`}>
              <BordOneToneIcon standardSize={12} variant="mail" />
              <div className="emailText">{email || t(`${recurrentWords}emailPlaceholder`)}</div>
            </div>
            <EmployeePills pills={employeePills} isActive={true} />
          </div>
        </div>
        <div className="sectionTitle">{t(`${newEmployeeForm}sectionTitle`)}</div>
        <div className="generalInfoForm">
          <div className="lineContainer">
            <BordTextInput
              label={t(`${newEmployeeForm}firstLineInputs:name`)}
              placeholder={t(`${newEmployeeForm}firstLineInputs:namePlaceholder`)}
              isFilled={!!firstName}
              standardSize="w-280"
              setCurrentText={setFirstName}
              currentText={currentEmployeeData?.firstName || undefined}
              showSkeletonLoader={showSkeletonLoader}
            />
            <BordTextInput
              label={t(`${newEmployeeForm}firstLineInputs:lastName`)}
              placeholder={t(`${newEmployeeForm}firstLineInputs:lastNamePlaceholder`)}
              isFilled={!!lastName}
              standardSize="w-280"
              setCurrentText={setLastName}
              currentText={currentEmployeeData?.lastName || undefined}
              showSkeletonLoader={showSkeletonLoader}
            />
            <BordTextInput
              label={t(`${newEmployeeForm}firstLineInputs:email`)}
              placeholder={t(`${newEmployeeForm}firstLineInputs:emailPlaceholder`)}
              isFilled={!!email}
              standardSize="w-280"
              disabled={!!currentEmployeeData?.mail || false}
              errorText={getEmailError()}
              setCurrentText={setEmail}
              currentText={currentEmployeeData?.mail || undefined}
              showSkeletonLoader={showSkeletonLoader}
            />
            <BordPhoneInput
              componentSize="w280"
              label={t(`${newEmployeeForm}firstLineInputs:phone`)}
              handleCountryPhoneChange={setPhoneCountry}
              handlePhoneChange={setPhoneNumber}
              defaultPhoneNumber={currentEmployeeData?.phoneData?.phone || undefined}
              defaultCountryData={defaultPhoneCountryData || undefined}
              personalClass={'countryInput'}
              placeholder={t(`${newEmployeeForm}firstLineInputs:phonePlaceholder`)}
              errorText={getPhoneError()}
              countriesList={countriesList || []}
              showSkeletonLoader={showSkeletonLoader}
            />
          </div>
          <div className="lineContainer">
            <BordTextInput
              label={idDocumentData?.label}
              placeholder={idDocumentData?.placeholder}
              isFilled={!!idDocumentData?.value}
              setCurrentText={setPersonalId}
              standardSize="w-280"
              className="idDocumentInput"
              currentText={idDocumentData?.value}
              errorText={idDocumentData?.errorText}
              caption={idDocumentData?.caption}
              showSkeletonLoader={showSkeletonLoader}
            />
            <BordEmployeeAreaDropdown
              componentSize="w-280"
              currentValue={selectedArea}
              showSkeletonLoader={showSkeletonLoader}
              updateCurrentValueCallback={setSelectedArea}
            />
            <BordTextInput
              label={t(`${newEmployeeForm}secondLineInputs:postiton`)}
              placeholder={t(`${newEmployeeForm}secondLineInputs:postitonPlaceholder`)}
              isFilled={!!position}
              setCurrentText={setPosition}
              standardSize="w-280"
              currentText={currentEmployeeData?.position || undefined}
              showSkeletonLoader={showSkeletonLoader}
            />
          </div>
        </div>
      </section>
      <section className="addressInfoSection">
        <div className="sectionTitle">{t(`${newEmployeeForm}addressInfoSection:sectionTitle`)}</div>
        <div className="addressFields">
          <div className="lineContainer">
            <NudosCountryDropdown
              charactersLimit={38}
              currentValue={selectedCountry}
              countriesList={countriesList || []}
              updateCurrentValueCallback={setSelectedCountry}
              isDeactivated={blockCountrySelection}
              showSkeletonLoader={showSkeletonLoader}
              componentSize="w-280"
            />
            <NudosCityDropdown
              countryId={selectedCountry?.id}
              componentSize={'w-280'}
              updateCurrentValueCallback={setCity}
              currentValue={city}
              isFocused={true}
              setMissingRequiredCityId={setMissingRequiredCityId}
              showSkeletonLoader={showSkeletonLoader}
            />
            <BordTextInput
              label={t(`${newEmployeeForm}addressInfoSection:firstLineInputs:zipCode`)}
              placeholder={t(`${newEmployeeForm}addressInfoSection:firstLineInputs:zipCodePlaceholder`)}
              isFilled={!!zipCode}
              setCurrentText={setZipCode}
              handleBlur={newZipCode => setZipCode(formatZipCode(newZipCode))}
              standardSize="w-180"
              currentText={zipCode || formatZipCode(currentEmployeeData?.address?.zipCode) || undefined}
              errorText={getZipCodeErrorText()}
              showSkeletonLoader={showSkeletonLoader}
            />
            <BordTextInput
              label={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:directions`)}
              placeholder={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:directionsPlaceholder`)}
              standardSize="w-380"
              isFilled={!!userAdditionalReferences}
              setCurrentText={(inputText: string) => setUserAdditionalReferences(inputText)}
              currentText={currentEmployeeData?.address?.additionalReferences || undefined}
              errorText={additionalReferencesErrorText}
              showSkeletonLoader={showSkeletonLoader}
            />
          </div>
          <div className="lineContainer">
            <NudosAddressSearchBar
              label={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:address`)}
              componentSize="w572"
              handleAddressSelection={selectedAddress => setUserAddress(selectedAddress)}
              defaultValueAddressName={
                currentEmployeeData && currentEmployeeData?.address?.country
                  ? currentEmployeeData.address?.address || undefined
                  : undefined
              }
              errorText={addressErrorText}
              regionBias={selectedCountry?.code}
              didntFindAddressTooltipTopPosition={6.8 + (addressErrorText ? 1.2 : 0)}
              didntFindAddressTooltipRightPosition={0}
              customPlaceholder={t(`${newEmployeeForm}addressInfoSection:secondLineInputs:addressCustomPlaceholder`)}
              showSkeletonLoader={showSkeletonLoader}
            />
          </div>
        </div>
      </section>
      <div className={screenWidth >= 1920 ? 'fixedButtonsAtBottom' : ''}>
        <EmployeeFormButtonsSection
          disableButton={disableButton}
          submitButtonBody={submitButtonBody}
          listOfCountriesWithLogistics={listOfCountriesWithLogistics || []}
          handleSubmitCustomCallback={handleSubmitCustomCallback}
          customTextButton={customTextButton}
          isButtonLoading={isButtonLoading}
          navigationOrigin={navigationOrigin}
          setCreateAndAssign={setCreateAndAssign}
          setRequireLogistics={setRequireLogistics}
        />
      </div>
    </div>
  );
};

export default NewEmployeeForm;
