import { getAuth, signInWithEmailAndPassword, User } from '@firebase/auth';
import { useHistory } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { getUsersOrganizationData } from '../services/services';
import { getPersonalAccount } from '../services/account';
import segment from '../utils/segment';
import { displayErrorNotification } from '../utils/displayNudosStandardNotifications';
import { getShoppingCart } from '../services/cart.services';
import useShopStore from '../state/useShopContext';
import { getInfoSubscription } from '../services/subscriptions.services';
import useStateSubscriptions from '../state/useStateSubscriptions';
import { getUserLanguagePreference } from '../services/account';
import { getDefaultLanguage } from '../utils/getDefaultLanguage';
import useStoreCountryState, { IStoreCountry } from '../state/useStoreCountryState';
import { useTranslation } from 'react-i18next';

const useLogin = () => {
  const { t } = useTranslation();
  const { setCart } = useShopStore();
  const { setStoreCountry } = useStoreCountryState();
  const { replace } = useHistory();
  const { setStateSubscription } = useStateSubscriptions();

  const signInTranslation = `authentication:signIn:`;

  const getUserData = async (user: User) => {
    if (!user?.uid) return;
    const userData = await getUsersOrganizationData(user?.uid);
    try {
      localStorage.setItem('orgData', JSON.stringify(userData));
      return userData;
    } catch {
      displayErrorNotification();
    }
  };

  const loginEmailAndPassWord = async (
    email: string,
    password: string,
    setPasswordError: React.Dispatch<React.SetStateAction<string | undefined>>,
    redirectionPath?: string
  ) => {
    const auth = getAuth();
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await signInWithEmailAndPassword(auth, email, password);
      const { user } = result;
      const orgData = await getUserData(user);
      if (orgData) {
        const personalData = await getPersonalAccount(orgData.userId);
        const myCart = await getShoppingCart(orgData.userId, orgData.organizationId);
        const stateInfoSubscription = await getInfoSubscription(orgData.organizationId);
        const languagePreference = await getUserLanguagePreference(orgData?.userId);
        const { levelSubscription } = stateInfoSubscription;
        if (languagePreference && languagePreference?.language?.code !== getDefaultLanguage().substring(0, 2))
          window.location.reload();
        setCart(myCart);
        if (myCart?.country) {
          const formartStoreCountry: IStoreCountry = {
            countryCode: myCart?.country?.code,
            countryFlag: myCart?.country?.flag,
            countryName: myCart?.country?.name,
            id: myCart?.country.id,
            nameStringId: myCart?.country?.nameStringId || '',
            storageEnabled: myCart.country?.storage
          };
          setStoreCountry(formartStoreCountry);
        }
        setStateSubscription({
          ...stateInfoSubscription,
          levelSubscription: levelSubscription === 'Vip' ? 'Lite' : levelSubscription,
          isPrime: levelSubscription === 'Lite' || levelSubscription === 'Vip' ? true : false
        });
        localStorage.setItem('bagItems', JSON.stringify(myCart));
        localStorage.setItem('sessionLogin', JSON.stringify(user));
        localStorage.setItem('orgData', JSON.stringify(orgData));
        languagePreference && localStorage.setItem('language', JSON.stringify(languagePreference));
        personalData && localStorage.setItem('personalData', JSON.stringify(personalData));
        segment.identifyEvent({
          data: {
            email: personalData?.email || '',
            phone: personalData?.phone || ''
          }
        });
      }
      if (orgData?.organizationState === 'active') {
        replace(redirectionPath ? redirectionPath : '/nodi/dashboard');
      } else {
        replace('/inactive');
      }
    } catch (err) {
      if (err instanceof FirebaseError) {
        if (err.code === 'auth/wrong-password') {
          setPasswordError(t(`${signInTranslation}passwordError`));
        } else {
          displayErrorNotification();
        }
      }
    }
  };

  return {
    getUserData,
    loginEmailAndPassWord
  };
};
export default useLogin;
