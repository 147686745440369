import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { getBrands } from '../../../services/services';
import useShopStore from '../../../state/useShopContext';
import { getProductLine, getProfileType } from '../../../services/filters';
import { IrawSuggestionFilter, valueKey } from '../../../types/global';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { shouldHideSidebar } from '../sidebar.utils';
import BordSidebarWithFilters from '../../BordDesignSystem/BordSidebarWithFilters/BordSidebarWithFilters';
import {
  IsidebarFilter,
  IsidebarFilterValue
} from '../../BordDesignSystem/BordSidebarWithFilters/BordSidebarWithFilters.types';
import { segmentTrackEvent } from '../../../utils/segment';
import './EcommerceSidebar.scss';

function EcommerceSidebar() {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { ecommerceFilters, pillsFilters, categoryFilters, setEcommerceFilters, loadingFilterPills } = useShopStore();

  const [rawProfileFilters, setRawProfileFilters] = useState<IrawSuggestionFilter[]>([]);
  const [rawLineFilters, setRawLineFilters] = useState<IrawSuggestionFilter[]>([]);
  const [loadingSuggestionFilters, setLoadingSuggestionsFilters] = useState<boolean>(true);

  const filteredCategory = ecommerceFilters.find(filter => filter.name === 'category')?.values[0];
  const brandsByCategory = filteredCategory ? `?categoryId=${JSON.stringify(filteredCategory)}` : '';
  const { data: brands, error: brandsError, isValidating: loadingBrands } = useSWR(brandsByCategory, getBrands);

  const suggestionsFiltersTranslationKey = 'nodi:checkout:';
  const equipmentConsultingText = t(`${suggestionsFiltersTranslationKey}equipmentConsulting`);
  const profileFilterTitle = t(`${suggestionsFiltersTranslationKey}profile`);
  const lineFilterTitle = t(`${suggestionsFiltersTranslationKey}positions`);
  const cleanFiltersText = t('recurrentWords:cleanFilters');
  const brandFilterText = t('nodi:checkout:brand');

  const areFiltersActive = ecommerceFilters.some(filter => filter.name !== 'category');
  const loadingDynamicFilters = !categoryFilters || (!brands?.length && loadingBrands) || loadingFilterPills;
  const hideSidebar = shouldHideSidebar(pathname);

  const isFilterActive = (filterIdentifier: string, filterValue?: number | string) => {
    return ecommerceFilters.some(filter => {
      return filter.name === filterIdentifier && filter.values.some(value => `${value}` === `${filterValue}`);
    });
  };

  const formatFilterValues = (filterIdentifier: string, filterValues: valueKey[]) => {
    return filterValues.map(filterValue => {
      return {
        label: filterValue?.label || '',
        value: filterValue?.value || '',
        isSelected: isFilterActive(filterIdentifier, filterValue?.value),
        id: filterValue?.id
      } as IsidebarFilterValue;
    });
  };

  const formatPillsFilters: () => IsidebarFilter[] = () => {
    return (
      pillsFilters?.map(pf => {
        const [filterName, filterValues] = Object.entries(pf)[0];
        return {
          filterIdentifier: filterName.toLowerCase(),
          filterName,
          filterValues: formatFilterValues(filterName.toLowerCase(), filterValues)
        };
      }) || []
    );
  };

  const resetFilters = () => {
    if (areFiltersActive) {
      const newFilters = ecommerceFilters.filter(filter => filter.name === 'category');
      newFilters.length > 0 ? setEcommerceFilters(newFilters) : setEcommerceFilters([]);
    }
  };

  const formatBrandsFilter = () => {
    const brandFilterIdentifier = 'marca';
    return {
      filterIdentifier: brandFilterIdentifier,
      filterName: brandFilterText,
      filterValues: formatFilterValues(brandFilterIdentifier, brands || [])
    };
  };

  const formatSuggestionFilter = (
    filterIdentifier: string,
    filterName: string,
    rawSuggestionFilters: IrawSuggestionFilter[]
  ): IsidebarFilter => {
    const formattedFilterValues: IsidebarFilterValue[] = [];

    for (const rawFilter of rawSuggestionFilters) {
      const newProfile = {
        isSelected: isFilterActive(filterIdentifier, rawFilter?.name),
        label: t(`services:filters:name:${rawFilter?.nameStringId}`),
        value: rawFilter?.name,
        id: rawFilter?.id,
        description: t(`services:filters:description:${rawFilter?.descriptionStringId}`)
      };
      formattedFilterValues.push(newProfile);
    }
    return {
      filterIdentifier: filterIdentifier,
      filterName: filterName,
      filterValues: formattedFilterValues
    };
  };

  const getSegmentData = (filterName: string) => {
    switch (filterName) {
      case 'memoria':
        return { event: 'storeFilterMemory', property: 'MemoryType' };
      case 'procesador':
        return { event: 'storeFilterProcessor', property: 'ProcessorType' };
      case 'almacenamiento':
        return { event: 'storeFilterStorage', property: 'StorageType' };
      case 'disponibilidad':
        return { event: 'storeFilterCountry', property: 'Country' };
      case 'marca':
        return { event: 'storeFilterBrand', property: 'BrandType' };
    }
  };

  const handleChangeFilters = (filterName: string, filterValue: string | number, filterLabel: string) => {
    const searchedFilter = ecommerceFilters.find(filter => filter.name === filterName);
    const selectedOptions = searchedFilter?.values || [];
    const optionIndex = selectedOptions.findIndex(option => `${option}` === `${filterValue}`);
    const segmentData = getSegmentData(filterName);

    if (segmentData) {
      const { event, property } = segmentData;
      const filterIsBrand = filterName === 'marca';
      segmentTrackEvent({
        [event]: {
          [property]: filterIsBrand ? filterLabel : filterValue
        }
      });
    }

    if (optionIndex >= 0) {
      selectedOptions.splice(optionIndex, 1);
    } else {
      selectedOptions.push(`${filterValue}`);
    }

    const newOrUpdatedFilter = {
      name: filterName,
      includes: 'in',
      values: selectedOptions
    };

    // When there are no previous filters, set the new one
    if (ecommerceFilters.length <= 0) return setEcommerceFilters([newOrUpdatedFilter]);

    const index = ecommerceFilters.findIndex(param => param.name === filterName);

    // If there are filters, but not of the type added, then updated the filters to include the new one
    if (index < 0) {
      ecommerceFilters.push(newOrUpdatedFilter);
      return setEcommerceFilters(ecommerceFilters);
    }

    // If there are already some options selected for this filter, handle them accordingly
    if (selectedOptions.length === 0) {
      ecommerceFilters.splice(index, 1);
    } else {
      ecommerceFilters[index] = newOrUpdatedFilter;
    }
    setEcommerceFilters(ecommerceFilters);
  };

  const getLineAndProfileFilters = async () => {
    setLoadingSuggestionsFilters(true);
    try {
      const profile = await getProfileType();
      const line = await getProductLine();
      setRawProfileFilters(profile);
      setRawLineFilters(line);
      setLoadingSuggestionsFilters(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingSuggestionsFilters(false);
    }
  };

  const formattedProfileFilters = formatSuggestionFilter('profile', profileFilterTitle, rawProfileFilters);
  const formattedLineFilters = formatSuggestionFilter('line', lineFilterTitle, rawLineFilters);
  const formattedPillsFilters = formatPillsFilters();
  const formattedBrandFilters = formatBrandsFilter();

  useEffect(() => {
    getLineAndProfileFilters();
  }, []);

  useEffect(() => {
    if (brandsError) displayErrorNotification();
  }, [brandsError]);

  if (hideSidebar) return <></>;
  return (
    <BordSidebarWithFilters
      id="ecommerceSidebar"
      title={t(`recurrentWords:filters`)}
      dynamicFilters={[formattedBrandFilters, ...formattedPillsFilters]}
      suggestionFilters={[formattedProfileFilters, formattedLineFilters]}
      updateFiltersCallback={handleChangeFilters}
      sidebarSuggestionFiltersTitle={equipmentConsultingText}
      cleanFiltersButtonProps={{ text: cleanFiltersText, onClick: resetFilters }}
      loadingDynamicFilters={loadingDynamicFilters}
      loadingSuggestionFilters={loadingSuggestionFilters}
      ecommerceFilters={ecommerceFilters}
      logoProps={{
        onClick: () => push('/nodi/dashboard'),
        style: { cursor: 'pointer' }
      }}
    />
  );
}

export default EcommerceSidebar;
