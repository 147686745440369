import { FC, useEffect, useState } from 'react';
import { BordButton, BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import useProfileImage from '../../../../../state/useProfileImage';
import { IPersonal } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { useTranslation, Trans } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import terms from '../../../../../assets/docs/terms.pdf';
import { changeLanguage } from '../../../../../utils/changeLanguage';
import { Tany } from '../../../../../types/global';
import { putUserLanguagePreference, getUserLanguagePreference } from '../../../../../services/account';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { userLanguagePreference } from '../../../../../utils/getDefaultLanguage';
import resetPassword from '../../../../../utils/resetPassword';
import { BordDropdown, BordTextInput } from '../../../../../components/BordDesignSystem';
import BordModal from '../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';

import BordPhoneInput from '../../../../../components/BordDesignSystem/BordPhoneInput/BordPhoneInput';
import BordPhotoUploader from '../../../../../components/BordDesignSystem/BordPhotoUploader/BordPhotoUploader';
import BordBadge from '../../../../../components/BordDesignSystem/BordBadge/BordBadge';
import { isPhoneValid } from '../../../../../utils/formValidations';
import './PersonalForm.scss';

interface IPersonalInfo {
  firstName: string;
  lastName: string;
  phone: string;
  countryPhoneId: number;
}
interface IPersonalForm {
  handleFileChange: Tany;
  personalData?: IPersonal;
  updatePersonalInfo?: (personalData: IPersonalInfo) => void;
  setIsPersonalEdit: (flag: boolean) => void;
  isPersonalEdit: boolean;
  countriesList?: ICountryDetail[];
}
interface Language {
  name: string;
  english: string;
  key: 'es' | 'en';
}

const PersonalForm: FC<IPersonalForm> = ({
  handleFileChange,
  personalData,
  updatePersonalInfo,
  setIsPersonalEdit,
  isPersonalEdit,
  countriesList
}: IPersonalForm) => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const userId = orgData?.userId;
  const { temporalImg } = useProfileImage();
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState<ICountryDetail>();
  const [countryPhone, setCountryPhone] = useState<ICountryDetail>();
  const [languageModal, setLanguageModal] = useState<boolean>(false);
  const [passwordResetEmailWasAttempted, setPasswordResetEmailWasAttempted] = useState(false);
  const i18nLanguageKey = 'nodi:account:personalForm:';
  const [languageKey, setLanguageKey] = useState<'es' | 'en'>('es');
  const [currentLanguageKey, setCurrentLanguageKey] = useState<string>();
  const userLanguageData = userLanguagePreference();
  const removeAccents = (string: string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  const disabledResetPasswordStyles = passwordResetEmailWasAttempted ? 'disabled' : '';
  const languages: Language[] = [
    {
      name: 'Español (Latinoamérica)',
      english: 'Spanish (Latin America)',
      key: 'es'
    },
    { name: 'Inglés (EEUU)', english: 'English (US)', key: 'en' }
  ];

  const changeUserLanguagePreference = async () => {
    try {
      await putUserLanguagePreference(userId, languageKey);
      const languagePreference = await getUserLanguagePreference(userId);
      setLanguageModal(false);
      changeLanguage(languagePreference);
      window.location.href = '/nodi/dashboard';
    } catch (error) {
      displayErrorNotification();
    }
  };

  const recoveryPassword = async () => {
    if (!personalData?.email || passwordResetEmailWasAttempted) return;
    setPasswordResetEmailWasAttempted(true);
    resetPassword(personalData?.email);
  };

  const ChangeLanguageModal = () => {
    return (
      <div className="changeLanguageModal">
        <BordOneToneIcon variant="settings" customWidth="6.4rem" />
        <span className="title mt-30">Estás a punto de cambiar el idioma</span>
        <p className="subTitle mt-8">{`${t(`${i18nLanguageKey}changeLanguageModal:title`)} ${t(
          `${i18nLanguageKey}changeLanguageModal:${currentLanguageKey}`
        )}?`}</p>
      </div>
    );
  };

  const filterBySearchStringCallback = (search: string, rawOptions: Language[]) => {
    return rawOptions.filter(
      option =>
        removeAccents(option.name.toLowerCase().trim()).includes(removeAccents(search.toLowerCase().trim())) ||
        removeAccents(option.english.toLowerCase().trim()).includes(removeAccents(search.toLowerCase().trim()))
    );
  };

  const languageOptions = (language: Language[]) => {
    return language.map(language => {
      const getOnClickFunction = () => {
        if (userLanguageData?.language?.code !== language.key) {
          setLanguageModal(true);
          setLanguageKey(language.key);
          setCurrentLanguageKey(language.key);
        }
      };

      return (
        <div className="customOptions" key={language.name} onClick={() => getOnClickFunction()}>
          <p className="font-extrabold">{language.name}</p>
          <p className="mb-1">{language.english}</p>
        </div>
      );
    });
  };
  const formSelectedOption = (rawSelectedOption: Language) => (
    <div className="selectedOption">{rawSelectedOption?.name}</div>
  );

  const userIsAdmin = personalData?.role?.toLowerCase() === 'admin';
  const userRoleInOrganizationText = (
    <Trans
      i18nKey={t(`${i18nLanguageKey}userRoleInOrganizationText:i18nKey`)}
      components={{
        1: `${
          userIsAdmin
            ? t(`${i18nLanguageKey}userRoleInOrganizationText:admin`)
            : t(`${i18nLanguageKey}userRoleInOrganizationText:user`)?.toLowerCase()
        }`
      }}
    />
  );
  const userImage = orgData?.photoProfile ? orgData?.photoProfile.replaceAll(' ', '%20') : undefined;

  const handleChange = (state: string, value: string) => {
    if (state === 'name') {
      setName(value);
    } else if (state === 'lastName') {
      setLastName(value);
    }
    setIsPersonalEdit(true);
  };

  const editPersonalInfo = () => {
    const isPersonalInfo = {
      firstName: name,
      lastName,
      countryPhoneId: countryPhone?.id || 0,
      phone
    };
    if (updatePersonalInfo) {
      updatePersonalInfo(isPersonalInfo);
    }
  };

  const enabledButton = () => {
    if (isPersonalEdit) {
      if (name && lastName && countryPhone && phone) {
        return true;
      }
      return false;
    }
    return false;
  };

  const handleExternalCountry = (country?: ICountryDetail) => setCountryPhone(country);
  const handleChangeExternalPhone = (phone?: string) => setPhone(phone || '');

  const defaultCountry = () => {
    if (personalData?.phoneData?.phoneCode && countriesList) {
      const myDefaultCountry = countriesList.find(
        element => element.phoneCode?.toString() === personalData?.phoneData?.phoneCode
      );
      setDefaultPhoneCountry(myDefaultCountry);
    }
  };

  const openTerms = () => window.open(terms, '_blank');
  const phoneEmpty = !phone || phone?.length === 0 || phone?.length <= 3;
  const phoneCountryEmpty = !countryPhone || countryPhone === undefined;
  const phoneAlreadyRegisteredError = phoneEmpty || phoneCountryEmpty;
  const getPhoneError = () => {
    if ((phone && phone.length > 3 && !isPhoneValid(phone)) || phoneAlreadyRegisteredError)
      return `${t(`${i18nLanguageKey}missingInformation`)}`;
    return undefined;
  };
  useEffect(() => {
    setName(personalData?.firstName || '');
    setLastName(personalData?.lastName || '');
    if (personalData?.phoneData?.phone) {
      setPhone(personalData?.phoneData?.phone || '');
    }
  }, [personalData]);

  useEffect(() => {
    defaultCountry();
  }, [countriesList, personalData]);

  useEffect(() => {
    if (!countryPhone || !phone) return;
    if (countryPhone?.code !== personalData?.phoneData?.country) setIsPersonalEdit(true);
    if (personalData?.phoneData?.phone !== phone) setIsPersonalEdit(true);
  }, [countryPhone, phone]);

  return (
    <div id="personalForm">
      <div className="titleFormAccount">{t(`${i18nLanguageKey}titleFormAccount`)}</div>
      <section className="profileSection">
        <div className="photoContainer">
          <BordPhotoUploader
            uploadedFileHandler={handleFileChange}
            selectedImageUrl={temporalImg || userImage}
            avatarPropsVariant="rectangularWorld"
            bordPhotoSize="s56"
          />
        </div>
        <div className="detailsImg">
          <div>
            <div className="titleImg">{t(`${i18nLanguageKey}titleImg`)}</div>
            <div className="subtitleImg">{t(`${i18nLanguageKey}subtitleImg`)}</div>
          </div>
        </div>
      </section>
      <section className="firstSection">
        <div className="nameInput">
          <BordTextInput
            label={`${t(`${i18nLanguageKey}inputsSection:name`)}*`}
            placeholder={t(`${i18nLanguageKey}inputsSection:name`)}
            currentText={name}
            isFilled={!!name}
            setCurrentText={(value: string) => handleChange('name', value)}
            errorText={name?.length === 0 ? t(`${i18nLanguageKey}missingInformation`) : undefined}
            style={{
              width: '100%'
            }}
          />
        </div>
        <div className="nameInput">
          <BordTextInput
            label={`${t(`${i18nLanguageKey}inputsSection:surname`)}*`}
            placeholder={t(`${i18nLanguageKey}inputsSection:surname`)}
            currentText={lastName}
            isFilled={!!lastName}
            setCurrentText={(value: string) => handleChange('lastName', value)}
            errorText={lastName?.length === 0 ? t(`${i18nLanguageKey}missingInformation`) : undefined}
            style={{
              width: '100%'
            }}
          />
        </div>

        <div className="nameInput">
          <BordPhoneInput
            label={`${t(`${i18nLanguageKey}inputsSection:phone`)}*`}
            handleCountryPhoneChange={handleExternalCountry}
            handlePhoneChange={handleChangeExternalPhone}
            defaultPhoneNumber={personalData?.phoneData?.phone || undefined}
            defaultCountryData={defaultPhoneCountry}
            personalClass={'countryInput'}
            placeholder={t(`${i18nLanguageKey}inputsSection:phone`)}
            errorText={getPhoneError()}
            countriesList={countriesList || []}
            componentSize="w-full"
          />
        </div>
      </section>
      <BordButton
        customClassName="saveButton"
        customWidth="w-280"
        disabled={!enabledButton()}
        onClick={() => editPersonalInfo()}
        label={t(`${i18nLanguageKey}nudosButton`)}
      />
      <div className="lineForm" />
      <section>
        <div className="titleFormAccount languageTitle">{t(`${i18nLanguageKey}languageSection:languageTitle`)}</div>
        <BordDropdown
          filterBySearchStringCallback={filterBySearchStringCallback}
          formSelectedOptionUICallback={formSelectedOption}
          formDropdownOptionsCallback={languageOptions}
          includeMagnifierIcon={false}
          customPlaceholder={
            (userLanguageData?.language?.label &&
              t(`services:languageButtonDropdown:${userLanguageData?.language?.label}`)) ||
            'Español (Latinoamérica)'
          }
          rawOptions={languages}
          label={t(`${i18nLanguageKey}languageSection:label`)}
          isFilled={true}
          componentSize="w-380"
        />
        {languageModal && (
          <BordModal
            modalContent={
              <ModalContent
                modalSize="sm"
                CustomModalComponent={<ChangeLanguageModal />}
                showButtonOne
                showButtonTwo
                textButtonOne={t(`${i18nLanguageKey}changeLanguageModal:nudosButtonCancel`)}
                buttonOneProps={{
                  modeButton: 'primary',
                  bordButtonStyle: 'outlined',
                  onClick: () => {
                    setLanguageModal(false);
                    setLanguageKey(userLanguageData?.language?.code);
                  }
                }}
                textButtonTwo={t(`${i18nLanguageKey}changeLanguageModal:nudosButtonUpdate`)}
                buttonTwoProps={{
                  onClick: () => changeUserLanguagePreference()
                }}
              />
            }
          />
        )}
      </section>
      <div className="lineForm" />
      <section className="accessInformationSection">
        <div className="accessInformationTitle mb-16">
          <span className="titleFormAccount">{t(`${i18nLanguageKey}accessInformationTitle`)}</span>
          <BordBadge variant="gradient" Jsx={<div className="text-bord-caption">{userRoleInOrganizationText}</div>} />
        </div>
        <div className="emailSection">
          <BordTextInput
            standardSize="w-280"
            placeholder="lucia@bord.co"
            label={t(`${i18nLanguageKey}inputsSection:entryEmail`)}
            disabled
            currentText={personalData?.email || ''}
            isFilled={false}
          />
          <div className={`textChangePassword ${disabledResetPasswordStyles}`} onClick={recoveryPassword}>
            {t(`${i18nLanguageKey}textChangePassword`)}
            <BordOneToneIcon variant="arrowRightCircle" standardSize={12} />
          </div>
        </div>
      </section>
      <div className="lineForm" />
      <div className="titleFormAccount mb-16">{t(`${i18nLanguageKey}applicationPolicies`)}</div>
      <BordButton
        modeButton="primary"
        bordButtonStyle="outlined"
        onClick={() => openTerms()}
        buttonIconTwo="fileText"
        label={t(`${i18nLanguageKey}buttonTerms`)}
      />
    </div>
  );
};

export default PersonalForm;
