const RoundNorthKorea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9999 0C11.1505 0 6.80528 2.15775 3.87109 5.56519H28.1287C25.1945 2.15775 20.8493 0 15.9999 0ZM28.1287 26.4348H3.87109C6.80528 29.8423 11.1505 32 15.9999 32 20.8493 32 25.1945 29.8423 28.1287 26.4348Z"
        />
        <path
          fill="#F93939"
          d="M29.6516 7.6521H2.34838C0.859063 10.0824 0 12.9407 0 15.9999C0 19.0591 0.859063 21.9175 2.34838 24.3477H29.6516C31.1409 21.9175 32 19.0591 32 15.9999C32 12.9407 31.1409 10.0824 29.6516 7.6521Z"
        />
        <path
          fill="white"
          d="M9.84302 22.1565C13.2432 22.1565 15.9995 19.4001 15.9995 16C15.9995 12.5999 13.2432 9.84351 9.84302 9.84351C6.44288 9.84351 3.68652 12.5999 3.68652 16C3.68652 19.4001 6.44288 22.1565 9.84302 22.1565Z"
        />
        <path
          fill="#F93939"
          d="M9.84372 9.84351L11.225 14.0948H15.7024L12.0788 16.7224L13.471 20.9864L9.84372 18.3463L6.22197 20.9815L7.60859 16.7224L3.98828 14.0948H8.46234L9.84372 9.84351Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNorthKorea;
