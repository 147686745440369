const RoundGuatemala = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#3ECBF8"
          d="M31.9996 16C31.9996 9.65665 28.308 4.17565 22.9561 1.58765V30.4123C28.308 27.8244 31.9996 22.3433 31.9996 16ZM0 16C0 22.3433 3.69156 27.8244 9.0435 30.4124V1.58765C3.69156 4.17565 0 9.65665 0 16Z"
        />
        <path
          fill="#ACABB1"
          d="M20.1807 18.7056L17.4753 16.0002L20.0505 13.4249L19.9338 12.0648L19.1965 11.3274L15.9995 14.5244L12.8025 11.3274L12.0653 12.0648L11.9486 13.4249L14.5238 16.0002L11.8184 18.7056L13.2941 20.1812L15.9995 17.4759L18.705 20.1812L20.1807 18.7056Z"
        />
        <path
          fill="#6DA544"
          d="M19.9349 12.0647L18.4593 13.5404C19.0887 14.1698 19.4781 15.0394 19.4781 15.9999C19.4781 17.9209 17.9208 19.4781 15.9998 19.4781C14.0789 19.4781 12.5215 17.9209 12.5215 15.9999C12.5215 15.0394 12.9109 14.1698 13.5403 13.5404L12.0646 12.0647C11.0574 13.0717 10.4346 14.463 10.4346 15.9999C10.4346 19.0735 12.9262 21.5651 15.9998 21.5651C19.0733 21.5651 21.5649 19.0734 21.5649 15.9999C21.5649 14.4631 20.9421 13.0718 19.9349 12.0647Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGuatemala;
