const RoundMontserrat = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="white"
          d="M15.957 16.0001H15.9998C15.9998 15.9857 15.9998 15.9717 15.9998 15.9573C15.9856 15.9716 15.9713 15.9858 15.957 16.0001Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.16194 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0485 16H15.9572C15.9715 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51179 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0325L10.3152 8.3479H8.34766V8.34803L15.9997 16H15.9998C15.9998 16 15.9998 14.6433 15.9998 14.0325Z"
        />
        <path
          fill="#3ECBF8"
          d="M18.0869 8.3479V16C18.0869 19.7275 22.9565 20.8696 22.9565 20.8696C22.9565 20.8696 27.826 19.7275 27.826 16V8.3479H18.0869Z"
        />
        <path
          fill="#A2001D"
          d="M18.0869 16C18.0869 19.7275 22.9565 20.8696 22.9565 20.8696C22.9565 20.8696 27.826 19.7275 27.826 16H18.0869Z"
        />
        <path
          fill="black"
          d="M25.0431 11.8262H23.6517V10.4348H22.2604V11.8262H20.8691V13.2174H22.2604V17.3913H23.6517V13.2174H25.0431V11.8262Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMontserrat;
