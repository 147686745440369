import { useUserAuth } from '../../../state/userAuthContext';
import segment from '../../../utils/segment';
import useLogOutState from '../../../state/useLogOutState';
import BordModal from '../../BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../BordDesignSystem/BordModal/components/ModalContent';
import LogoutModalContent from './LogoutModalContent';
import './LogoutModal.scss';

const LogoutModal = () => {
  const { isLogoutModalOpen, setIsLogoutModalOpen } = useLogOutState();
  const { logOut } = useUserAuth();

  const handleClickLogout = () => {
    if (!logOut) return;
    segment.resetEvent();
    logOut();
  };

  const closeLogoutModal = () => setIsLogoutModalOpen(false);

  if (!isLogoutModalOpen || !logOut) return <></>;
  return (
    <div className="logoutModal">
      <BordModal
        modalContent={
          <ModalContent
            CustomModalComponent={
              <LogoutModalContent onClickLogout={handleClickLogout} closeLogoutModal={closeLogoutModal} />
            }
            customWidth="48rem"
            customHeight="34rem"
            toCloseModal={closeLogoutModal}
            excludeHeader
          />
        }
      />
    </div>
  );
};
export default LogoutModal;
