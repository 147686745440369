import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handleClickForHelp } from '../../utils/global.utils';
import BordEmptyState from '../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import './ProductNotFoundView.scss';

const ProductNotFoundView = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const translationKey = 'nodi:checkout:productNotFoundView:';
  const toHome = () => {
    push('/catalogue/home');
  };

  return (
    <div className="productNotFoundView">
      <BordEmptyState
        title={t(`${translationKey}notFoundTitle`)}
        subTitle={t(`${translationKey}notFoundSubtitle`)}
        ilustrationIconProps={{
          variant: 'search',
          customWidth: '10rem',
          standardSize: 100
        }}
        buttonOneProps={{
          label: t(`${translationKey}supportButton`),
          buttonIconTwo: 'helpCircle',
          onClick: handleClickForHelp,
          modeButton: 'quaternary',
          customWidth: 'w-280'
        }}
        buttonTwoProps={{
          label: t(`${translationKey}notFoundButton`),
          buttonIconTwo: 'shoppingCart',
          onClick: toHome,
          customWidth: 'w-280'
        }}
        customClassNameSubTitle="customSubtitle"
      />
    </div>
  );
};

export default ProductNotFoundView;
