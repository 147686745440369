import { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUsersOrganizationData } from '../../../../services/services';
import { useUserAuth } from '../../../../state/userAuthContext';
import { getPersonalData } from '../../../../utils/getLocalStorageData';
import { segmentTrackEvent } from '../../../../utils/segment';
import BordLogo from '../../../../components/BordDesignSystem/BordLogo/BordLogo';
import BordEmptyState from '../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import { TbordilustrationIcon } from '../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustration.types';
import { handleClickForHelp } from '../../../../utils/global.utils';
import './PendingOrRejectedView.scss';

const PendingOrRejectedView = () => {
  const { t } = useTranslation();
  const { replace, push } = useHistory();
  const { state }: { state: IpendingViewTexts } = useLocation();

  const { user } = useUserAuth();
  const stringOrgData = localStorage.getItem('orgData');
  const storedOrgData = stringOrgData && JSON.parse(stringOrgData);
  const signUpSegmentData = getPersonalData();
  const stringSessionLogin = localStorage.getItem('sessionLogin');
  const sessionLogin = stringSessionLogin && JSON.parse(stringSessionLogin);
  const [orgData, setOrgData] = useState(storedOrgData);
  const pending = orgData?.organizationState === 'pending';
  const translationKey = 'nodi:pendingOrRejectedView:';

  const title = pending ? t(`${translationKey}pendingTitle`) : t(`${translationKey}rejectedTitle`);
  const subtitle = pending ? t(`${translationKey}pendingSubtitle`) : t(`${translationKey}rejectedSubtitle`);
  const buttonLabel = t(`${translationKey}buttonLabel`);
  const ilustrationVariant: TbordilustrationIcon = pending ? 'success' : 'danger';

  const verifyStatus = async () => {
    if (user?.uid) {
      const userData = await getUsersOrganizationData(user?.uid);
      if (userData.organizationState === 'active') {
        setOrgData(userData);
        localStorage.setItem('orgData', JSON.stringify(userData));
        push('/nodi/dashboard');
      }
      if (userData.organizationState === 'rejected') {
        setOrgData(userData);
        localStorage.setItem('orgData', JSON.stringify(userData));
      }
    }
  };

  useLayoutEffect(() => {
    if (state?.origin === 'accessRequest') return;
    if (orgData?.organizationState === 'active') push('/nodi/dashboard');
    verifyStatus();
  }, []);

  useEffect(() => {
    if (state?.origin === 'accessRequest') return;
    if (!sessionLogin || !orgData) replace(`/login`);
  }, [orgData, sessionLogin]);

  useEffect(() => {
    if (!orgData || state?.origin === 'accessRequest') return;

    if (orgData?.organizationState === 'pending') {
      segmentTrackEvent({
        signupWaitingView: {
          ContactNumber:
            signUpSegmentData?.phone ||
            `${signUpSegmentData?.phoneData?.phoneCode} ${signUpSegmentData?.phoneData?.phone}`,
          Name: signUpSegmentData?.firstName || orgData?.firstName,
          RoleName: '',
          Surname: signUpSegmentData?.lastName || orgData?.lastName
        }
      });
    }
    if (orgData?.organizationState === 'rejected') {
      segmentTrackEvent({
        signupRejectedView: {
          ContactNumber:
            signUpSegmentData?.phone ||
            `${signUpSegmentData?.phoneData?.phoneCode} ${signUpSegmentData?.phoneData?.phone}`,
          Name: signUpSegmentData?.firstName || orgData?.firstName,
          RoleName: '',
          Surname: signUpSegmentData?.lastName || orgData?.lastName
        }
      });
    }
  }, [orgData]);

  return (
    <div className="pendingOrRejectedView">
      <div className="content">
        <BordEmptyState
          title={state?.title || title}
          customClassNameTitle="pendingOrRejectedViewEmptyStateTitle"
          customClassNameSubTitle="pendingOrRejectedViewEmptyStateSubtitle"
          subTitle={state?.subtitle || subtitle}
          ilustrationIconProps={{
            variant: state?.ilustrationVariant || ilustrationVariant,
            customWidth: '10rem'
          }}
          buttonOneProps={{
            label: buttonLabel,
            buttonIconTwo: 'helpCircle',
            onClick: handleClickForHelp,
            customWidth: 'w-280'
          }}
        />
        <BordLogo customWidth="15rem" />
      </div>
    </div>
  );
};

export default PendingOrRejectedView;

export interface IpendingViewTexts {
  origin: string;
  title: string;
  subtitle: string;
  ilustrationVariant: TbordilustrationIcon;
  buttonLabel: string;
}
