const SquareHaiti = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V8H0V0Z" clipRule="evenodd" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M6.28613 5.3335H15.0163V10.6668H6.28613V5.3335Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M9.77832 7.11133H11.5244V10.6669H9.77832V7.11133Z"
          clipRule="evenodd"
        />
        <path
          fill="#249F58"
          fillRule="evenodd"
          d="M8.03223 8.00016C8.03223 6.52816 9.19508 5.3335 10.6513 5.3335C12.097 5.3335 13.2703 6.5175 13.2703 8.00016V10.6668H8.03223V8.00016ZM9.77826 8.00905C9.77826 7.51127 10.1833 7.11127 10.6513 7.11127C11.1332 7.11127 11.5243 7.49172 11.5243 8.00905V10.6668H9.77826V8.00905Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareHaiti;
