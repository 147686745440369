const RoundLiberia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M3.65723 5.81824H28.3427C27.431 4.71418 26.3735 3.73512 25.2004 2.90918H6.79954C5.62648 3.73499 4.56904 4.71412 3.65723 5.81824ZM0 15.9999C0 16.4903.0233125 16.9752.0665 17.4545H31.9335C31.9767 16.9752 32 16.4903 32 15.9999 32 15.5095 31.9767 15.0246 31.9335 14.5454H.0665C.0233125 15.0246 0 15.5095 0 15.9999H0ZM1.74479 23.2728H30.2553C30.7264 22.3511 31.1107 21.378 31.3975 20.3638H.602539C.889352 21.378 1.27373 22.3512 1.74479 23.2728ZM6.79954 29.0909H25.2004C26.3734 28.265 27.4309 27.2859 28.3427 26.1819H3.65723C4.56904 27.2858 5.62648 28.265 6.79954 29.0909ZM.602539 11.6364H31.3977C31.1108 10.6221 30.7265 9.64886 30.2554 8.72729H1.74485C1.27379 9.64886.889414 10.6221.602539 11.6364Z"
        />
        <path
          fill="#F93939"
          d="M7.65234 2.90906H25.2006C22.5981 1.07669 19.425 0 16.0002 0 13.9132 0 10.2548 1.07669 7.65234 2.90906ZM15.3047 8.72742H30.2556C29.7235 7.68661 29.0809 6.71167 28.3431 5.81836H15.3047V8.72742ZM15.3047 14.5455H31.9338C31.8438 13.5467 31.6629 12.5739 31.3979 11.6365H15.3047V14.5455ZM.602406 20.3637H31.3974C31.6625 19.4263 31.8434 18.4535 31.9334 17.4546H.0664062C.156406 18.4535.337281 19.4262.602406 20.3637ZM3.65762 26.1818H28.3431C29.0809 25.2883 29.7236 24.3135 30.2556 23.2727H1.74512C2.27718 24.3135 2.9198 25.2885 3.65762 26.1818ZM16.0002 31.9999C19.4251 31.9999 22.5982 30.9232 25.2007 29.0908H6.7998C9.4023 30.9232 12.5754 31.9999 16.0002 31.9999Z"
        />
        <path
          fill="#1A47B8"
          d="M0.602344 11.6364C0.337219 12.5739 0.156406 13.5466 0.0664062 14.5454H15.9999V0C8.67616 0 2.50184 4.92075 0.602344 11.6364Z"
        />
        <path
          fill="white"
          d="M9.52443 4.17383L10.5604 7.36227H13.9132L11.2009 9.33302L12.2367 12.5216L9.52443 10.551L6.81199 12.5216L7.84812 9.33302L5.13574 7.36227H8.48843L9.52443 4.17383Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLiberia;
