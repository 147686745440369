const RoundHongKong = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M17.652 12.1042C17.2883 13.6166 16.6423 13.3282 16.3252 14.6472 14.4575 14.1981 13.3073 12.32 13.7563 10.4522 14.2052 8.58448 16.0835 7.43423 17.9513 7.88329 17.3171 10.5212 17.9773 10.7515 17.652 12.1042ZM12.8056 13.2249C14.1317 14.038 13.6577 14.5634 14.814 15.2726 13.8098 16.9102 11.6682 17.4236 10.0306 16.4194 8.39304 15.4152 7.87947 13.2735 8.88372 11.636 11.1966 13.0542 11.6194 12.4975 12.8056 13.2249ZM12.3742 18.1807C13.5574 17.1708 13.9105 17.7839 14.9423 16.9033 16.1895 18.3644 16.016 20.5599 14.555 21.8071 13.0939 23.0543 10.8984 22.8806 9.65137 21.4196 11.7147 19.6582 11.3159 19.0839 12.3742 18.1807ZM16.9537 20.1226C16.3587 18.6853 17.0509 18.5388 16.5322 17.2855 18.3072 16.551 20.3417 17.3943 21.0763 19.1693 21.8109 20.9442 20.9674 22.9787 19.1924 23.7133 18.1551 21.2065 17.4858 21.4083 16.9537 20.1226ZM20.2154 16.3673C18.6645 16.489 18.739 15.7852 17.3868 15.8913 17.2366 13.9764 18.6675 12.302 20.5825 12.1517 22.4975 12.0017 24.1719 13.4325 24.322 15.3476 21.6172 15.5597 21.6023 16.2586 20.2154 16.3673Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundHongKong;
