const SquareTuvalu = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#3A99FF" rx="2" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M14.6666 5.3335V6.40016H15.7142V5.3335H14.6666ZM17.8094 5.3335V6.40016H18.857V5.3335H17.8094ZM12.5713 8.5335V9.60016H13.619V8.5335H12.5713ZM16.7618 9.60016V10.6668H17.8094V9.60016H16.7618ZM12.5713 11.7335V12.8002H13.619V11.7335H12.5713ZM8.38086 10.6668V11.7335H9.42848V10.6668H8.38086Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          d="M9.4288 1.06689H2.09547C1.51689 1.06689 1.04785 1.54446 1.04785 2.13356V7.4669C1.04785 8.056 1.51689 8.53356 2.09547 8.53356H9.4288C10.0074 8.53356 10.4764 8.056 10.4764 7.4669V2.13356C10.4764 1.54446 10.0074 1.06689 9.4288 1.06689Z"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M5.23856 5.3333H2.0957V4.26663H5.23856V2.1333H6.28618V4.26663H9.42904V5.3333H6.28618V7.46663H5.23856V5.3333ZM7.85761 6.39997V7.46663H9.42904V6.39997H7.85761ZM7.85761 2.1333V3.19997H9.42904V2.1333H7.85761ZM2.0957 6.39997V7.46663H3.66713V6.39997H2.0957ZM2.0957 2.1333V3.19997H3.66713V2.1333H2.0957Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTuvalu;
