const RoundEthiopia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M0 16C0 17.9572 0.352062 19.832 0.99525 21.5653L16 22.9565L31.0047 21.5653C31.648 19.832 32 17.9571 32 16C32 14.0696 31.6581 12.2192 31.0316 10.5059L16 9.04346L0.968375 10.506C0.341938 12.2192 0 14.0696 0 16H0Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#6DA544"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9998 23.6523C20.226 23.6523 23.652 20.2263 23.652 16.0001C23.652 11.7739 20.226 8.3479 15.9998 8.3479C11.7737 8.3479 8.34766 11.7739 8.34766 16.0001C8.34766 20.2263 11.7737 23.6523 15.9998 23.6523Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.998 10.0745L17.3794 14.3258H21.8567L18.2331 16.9534L19.6253 21.2174L15.998 18.5773L12.3763 21.2125L13.7629 16.9534L10.1426 14.3258H14.6166L15.998 10.0745Z"
        />
        <path
          fill="#FFDA2C"
          d="M21.5077 17.0582L17.1254 15.6342L19.8337 11.9067L18.7083 11.0889L15.9998 14.8166L13.2916 11.0889L12.166 11.9067L14.8742 15.6345L10.4922 17.0582L10.9221 18.3815L15.3043 16.9577V21.5653H16.6956V16.9576L21.0776 18.3815L21.5077 17.0582Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEthiopia;
