const RoundEritrea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M14.609 14.6086C14.609 14.6086 4.6959 27.316 4.68652 27.3136C7.5819 30.2091 11.582 32 16.0003 32C24.8368 32 32.0003 24.8365 32.0003 16L14.609 14.6086Z"
        />
        <path
          fill="#6DA544"
          d="M14.609 16C14.609 16 4.6959 4.68406 4.68652 4.68631C7.5819 1.79088 11.582 0 16.0003 0C24.8368 0 32.0003 7.1635 32.0003 16H14.609Z"
        />
        <path
          fill="#F93939"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C4.68584 27.3163 32 16 32 16L4.68628 4.68628Z"
        />
        <path
          fill="#FFDA2C"
          d="M8.34741 9.39136C5.27873 9.39136 2.78223 11.8879 2.78223 14.9565V17.0435C2.78223 20.1122 5.27879 22.6087 8.34741 22.6087C11.416 22.6087 13.9126 20.1121 13.9126 17.0435V14.9565C13.9127 11.8879 11.4161 9.39136 8.34741 9.39136ZM11.8257 17.0435C11.8257 18.598 10.8005 19.9174 9.39085 20.3617V18.087L10.8674 16.6105L9.39166 15.1349L9.39085 14.6097V13.9132H7.30398V16L5.82848 17.4755L7.30398 18.9509V20.3617C5.89429 19.9174 4.86916 18.5981 4.86916 17.0436V14.9567C4.86916 13.0387 6.42954 11.4784 8.34741 11.4784C10.2653 11.4784 11.8257 13.0388 11.8257 14.9567V17.0435Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEritrea;
