import { AxiosError, AxiosRequestConfig } from 'axios';
import { orgAPI, usersAPI } from '../constants';
import {
  IOrganization,
  IPersonal,
  IListLocationAddress,
  ILocationCountryAddress,
  IaddLogisticAddress,
  IListDefaultLocationAddress,
  ICountryLocations,
  ILanguagePreferences,
  DTOgetBillingDataByOrganizationResponse,
  ILastBillingData,
  IReleaseVideoInformation,
  IReleaseVideoBody,
  DASHBOARD_ONBOARDING_TYPES
} from '../types/account';
import { apiGet, apiPut } from './api';
import { Tany } from '../types/global';

const { REACT_APP_API, REACT_APP_SUBSCRIPTION, REACT_APP_API_USERS } = process.env;

export const getPersonalAccount = async (id: number | string): Promise<IPersonal> => {
  return apiGet<IPersonal, IPersonal>(`${orgAPI}/account/personal/${id}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getReleaseVideoInformation = async (
  id: number | string,
  route: DASHBOARD_ONBOARDING_TYPES
): Promise<IReleaseVideoInformation> => {
  return apiGet<AxiosRequestConfig, IReleaseVideoInformation>(`${orgAPI}/onboarding/${id}/${route}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getOrganizationAccount = async (id: number | string): Promise<IOrganization> => {
  return apiGet<IOrganization, IOrganization>(`${orgAPI}/account/organization/${id}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const updatePersonalData = async (id: number | string, body: FormData): Promise<FormData> => {
  return apiPut<FormData, FormData>(`${usersAPI}/user/${id}`, body)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const updateOrganizationData = async (id: number | string, body: FormData): Promise<FormData> => {
  return apiPut<FormData, FormData>(`${REACT_APP_API}/organization/logo/${id}`, body)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const servicesLocationAddress = async (organizationId: string | number) => {
  return apiGet<IListLocationAddress, IListLocationAddress>(
    `${REACT_APP_SUBSCRIPTION}/product-location/offices/organization/${organizationId}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const servicesDefaultLocationAddress = async (organizationId: string | number) => {
  return apiGet<IListDefaultLocationAddress, IListDefaultLocationAddress>(
    `${REACT_APP_SUBSCRIPTION}/product-location/organization-locations/${organizationId}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const addOtherLogisticsAddress = async (orgId: string | number, body: IaddLogisticAddress): Promise<Tany> => {
  return apiPut<AxiosRequestConfig, IaddLogisticAddress>(
    `${REACT_APP_SUBSCRIPTION}/product-location/other-address/${orgId}`,
    body
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateStatusAddress = async (locationId: number): Promise<string> => {
  return apiPut<string, string>(`${REACT_APP_SUBSCRIPTION}/product-location/active/${locationId}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const addNewAddressOffice = async (
  organizationId: number,
  address: ILocationCountryAddress
): Promise<IaddLogisticAddress> => {
  return apiPut<ILocationCountryAddress, ILocationCountryAddress>(
    `${REACT_APP_SUBSCRIPTION}/product-location/office-address/${organizationId}`,
    address
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getOfficeByCountry = async (
  organizationId: number | string,
  countryId: number | string
): Promise<ICountryLocations> => {
  return apiGet<AxiosRequestConfig, ICountryLocations>(
    `${REACT_APP_SUBSCRIPTION}/product-location/by-country?organizationId=${organizationId}&countryId=${countryId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getOrganizationBillingDataForAllItsCountries = async (organizationId: number | string) => {
  return apiGet<AxiosRequestConfig, DTOgetBillingDataByOrganizationResponse>(
    `${REACT_APP_API}/organization/billing-data/by-organization/${organizationId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getOrganizationLastBillingData = async (organizationId: number | string, countryCode: string) => {
  return apiGet<AxiosRequestConfig, ILastBillingData>(
    `${REACT_APP_SUBSCRIPTION}/order/last-billing-data/${organizationId}/${countryCode}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getUserLanguagePreference = async (userId: number) => {
  return apiGet<AxiosRequestConfig, ILanguagePreferences>(`${REACT_APP_API_USERS}/user-preference/${userId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putUserLanguagePreference = async (userId: number, languague: string) => {
  return apiPut(`${REACT_APP_API_USERS}/user-preference/languague/${userId}?languague=${languague}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const putReleaseVideoSeen = async (organizationId: number | string, body: IReleaseVideoBody) => {
  return apiPut(`${orgAPI}/onboarding/${organizationId}`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
