const RoundSouthKorea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M21.5649 16C21.5649 17.3913 19.0733 20.8696 15.9998 20.8696C12.9262 20.8696 10.4346 17.3913 10.4346 16C10.4346 12.9264 12.9262 10.4348 15.9998 10.4348C19.0733 10.4348 21.5649 12.9264 21.5649 16Z"
        />
        <path
          fill="#1A47B8"
          d="M21.5649 16C21.5649 19.0736 19.0733 21.5652 15.9998 21.5652C12.9262 21.5652 10.4346 19.0736 10.4346 16"
        />
        <path
          fill="black"
          d="M21.8984 20.9192 23.3739 19.4437 24.3576 20.4274 22.8821 21.9028 21.8984 20.9192ZM19.4385 23.3784 20.9139 21.903 21.8976 22.8866 20.4221 24.3621 19.4385 23.3784ZM24.8496 23.8701 26.3251 22.3947 27.3087 23.3783 25.8333 24.8538 24.8496 23.8701ZM22.3896 26.3296 23.8651 24.8541 24.8488 25.8378 23.3733 27.3132 22.3896 26.3296ZM23.374 22.3948 24.8495 20.9193 25.8331 21.903 24.3577 23.3784 23.374 22.3948ZM20.915 24.854 22.3905 23.3785 23.3742 24.3622 21.8987 25.8377 20.915 24.854ZM24.8564 11.0835 20.9219 7.14891 21.9055 6.16525 25.8401 10.0998 24.8564 11.0835ZM20.9209 10.1001 19.4454 8.62463 20.429 7.64102 21.9045 9.11648 20.9209 10.1001ZM23.3799 12.5598 21.9044 11.0844 22.888 10.1007 24.3635 11.5762 23.3799 12.5598ZM23.8711 7.14868 22.3956 5.67322 23.3793 4.68956 24.8548 6.16502 23.8711 7.14868ZM26.332 9.60889 24.8566 8.13342 25.8402 7.14976 27.3157 8.62523 26.332 9.60889ZM5.67188 22.3921 9.60646 26.3267 8.6228 27.3103 4.68822 23.3757 5.67188 22.3921ZM9.60645 23.3748 11.0819 24.8502 10.0983 25.8338 8.62283 24.3584 9.60645 23.3748ZM7.14746 20.9163 8.62293 22.3917 7.63927 23.3754 6.1638 21.8999 7.14746 20.9163ZM8.62305 19.4404 12.5576 23.375 11.574 24.3587 7.63939 20.4241 8.62305 19.4404ZM9.60645 5.67017 5.67186 9.60475 4.6882 8.6211 8.62279 4.68651 9.60645 5.67017ZM11.082 7.14551 7.14744 11.0801 6.16379 10.0964 10.0984 6.16185 11.082 7.14551ZM12.5586 8.62183 8.62401 12.5564 7.64039 11.5728 11.575 7.63821 12.5586 8.62183Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSouthKorea;
