const RoundSlovenia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M31.0048 10.4349H13.9131V6.26099H5.56519V10.4349H0.995188C0.352063 12.1682 0 14.043 0 16.0001C0 17.9572 0.352063 19.832 0.995188 21.5653L16 22.9566L31.0048 21.5653C31.6479 19.832 32 17.9572 32 16.0001C32 14.043 31.6479 12.1682 31.0048 10.4349Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="white"
          d="M5.56543 10.4349V11.8261C5.56543 15.0212 9.73937 16.0001 9.73937 16.0001C9.73937 16.0001 13.9133 15.0212 13.9133 11.8261V10.4349L12.522 11.8262L9.73937 9.73926L6.95674 11.8262L5.56543 10.4349Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSlovenia;
