import { TstoragePlace } from '../types/assignationFluxes';
import { DTOCatalog, DTOGetNudos, DTOProduct } from '../types/DTO';
import { InudoInfo, IproductInfo, IproductList, TnudoState, TnudoType } from '../types/global';

export const formNudoObject = function (backend: DTOGetNudos): InudoInfo {
  return {
    nudosId: `${backend.subscriptionId ? backend.subscriptionId : backend.saleId}`,
    nudoType: backend.saleId ? TnudoType.Compra : TnudoType.Suscripción,
    cost: backend.subscriptionId && backend.monthlyValue ? backend.monthlyValue : backend.saleValue,
    duration: backend.subscriptionId && backend.period ? backend.period : 1,
    uid: backend.serial,
    productId: `${backend.productId}`,
    category: backend?.category,
    orderId: backend.orderId,
    commercialName: backend.name,
    brand: backend.brand,
    imageUrl: backend.image,
    productDescription: backend.description,
    nudoState: Object.keys(TnudoState)[
      Object.values(TnudoState).indexOf(backend.statusName)
    ] as keyof typeof TnudoState,
    deliveryDate: backend.deliveryDate,
    endDate: backend.subscriptionId ? backend.endDay : undefined,
    endDay: backend.endDay ? backend.endDay : undefined,
    software: backend.software,
    assignedTo: backend.assignedTo,
    assignmentId: backend.assignmentId,
    userId: backend.userId,
    pills: backend.pills,
    brandImage: backend.brandImage,
    statusId: backend.statusId,
    subscriptionId: backend?.subscriptionId || undefined,
    NID: backend.NID,
    numberOfAssignment: backend.numberOfAssignment,
    createdAt: backend?.createdAt,
    saleValue: backend?.saleValue || 0,
    saleValueUsd: backend?.saleValueUsd || 0,
    period: backend?.period || undefined,
    startDay: backend?.startDay,
    statusName: backend?.statusName,
    serial: backend?.serial,
    city: backend?.city,
    country: backend?.country,
    place: backend?.place as TstoragePlace,
    address: backend?.address,
    additionalReferences: backend?.additionalReferences,
    currentAssignment: backend?.currentAssignment,
    oldAssignment: backend?.oldAssignment,
    sku: backend?.sku,
    acquisitionDate: backend?.acquisitionDate,
    productCondition: backend?.productCondition,
    warranty: backend?.warranty,
    acqusitionType: backend?.acqusitionType,
    acquisitionType: backend?.acquisitionType,
    referenceModel: backend?.referenceModel,
    nudosCareName: backend?.nudosCareName,
    monthlyValue: backend?.monthlyValue || undefined,
    monthlyValueUsd: backend?.monthlyValueUsd || 0,
    logisticStatus: backend?.logisticStatus,
    locationName: backend?.locationName,
    locationUserName: backend?.locationUserName,
    locationId: backend?.locationId,
    comments: backend?.comments,
    destination: backend?.destination,
    logisticServiceId: backend?.logisticServiceId,
    rentValueUsd: backend?.rentValueUsd,
    financingMethod: backend?.financingMethod,
    shipmentStatusId: backend?.shipmentStatusId,
    shipmentStatusName: backend?.shipmentStatusName,
    isKit: backend?.isKit,
    isBuyAndHold: backend?.isBuyAndHold,
    logisticAdditionalServices: backend?.logisticAdditionalServices
  };
};

export const formProductObject = function (backend: DTOProduct): IproductInfo {
  return {
    itemId: backend?.itemId,
    productId: `${backend.productId}`,
    commercialName: backend.commercialName,
    sku: backend.sku,
    categoryId: backend.categoryId,
    categoryName: backend.categoryName,
    brandId: backend.brandId,
    brandName: backend.brandName,
    brandImage: backend.brandImage,
    imageId: backend.imageId,
    image: backend.imageUrl,
    alternativeImages: backend.secondaryImages ? JSON.parse(backend.secondaryImages) : [],
    prices: backend.prices,
    descriptions: backend.descriptions,
    description: backend.description,
    longDescription: backend.longDescription,
    productTechnicalDetails: backend.specs,
    dataSheet: backend.dataSheet ? backend.dataSheet : '',
    pills: typeof backend.pills === 'string' ? JSON.parse(backend.pills) : backend.pills,
    rentValue: backend.rentValue,
    usdLowerPrice: backend.usdLowerPrice,
    profiles: backend?.profiles || [],
    availableCountries: backend?.availableCountries || []
  };
};

export const formProductList = function (backend: DTOCatalog): IproductList {
  return {
    products: backend.items.map(item => {
      return {
        itemId: item?.itemId,
        productId: `${item.productId}`,
        commercialName: item.commercialName,
        sku: item.sku,
        categoryId: item.categoryId,
        categoryName: item.categoryName,
        brandId: item.brandId,
        brandName: item.brandName,
        brandImage: item.brandImage,
        imageId: item.imageId,
        image: item.imageUrl,
        alternativeImages: item.secondaryImages ? JSON.parse(item.secondaryImages) : [],
        prices: item.prices,
        descriptions: item.descriptions,
        description: item.description,
        longDescription: item.longDescription,
        productTechnicalDetails: item.specs,
        dataSheet: item.dataSheet ? item.dataSheet : '',
        pills: typeof item.pills === 'string' ? JSON.parse(item.pills) : item.pills,
        rentValue: item.rentValue,
        usdLowerPrice: item.usdLowerPrice,
        profiles: item?.profiles || []
      };
    }),
    pillsFilters: backend.pillsFilters,
    priceFilters: backend.priceFilters
  };
};
