import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" ref={ref} {...props}>
    <path
      className="buildingOutline"
      fill="#fff"
      stroke="#383838"
      d="M18.793 21.945c.822-.08 1.46-.723 1.51-1.517.175-2.73.268-5.391.268-8.427 0-3.037-.093-5.698-.268-8.428-.05-.794-.689-1.437-1.51-1.517-4.724-.455-8.862-.455-13.586 0-.822.08-1.46.723-1.51 1.517-.175 2.73-.268 5.391-.268 8.428 0 3.036.093 5.697.268 8.427.05.794.688 1.438 1.51 1.517 4.724.455 8.862.455 13.586 0Z"
    />
    <path className="buildingDoor" stroke="#383838" d="M8.571 22.287v-4.142a1 1 0 0 1 1-1h4.858a1 1 0 0 1 1 1v4.142" />
    <path
      className="buildingWindows"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.571 7.715v-.857M12 7.715v-.857M15.429 7.715v-.857M8.571 12.855v-.857M12 12.855v-.857M15.429 12.855v-.857"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconBuilding = memo(ForwardRef);
export default IconBuilding;
