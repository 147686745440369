const RoundTogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#496E2D"
          d="M16.0003 0C15.9887.000125 15.3047 6.4 15.3047 6.4H28.8011C25.882 2.51394 21.2348 0 16.0003 0ZM15.9999 32.0001C21.2344 32.0001 25.8816 29.4862 28.8006 25.6001H3.19922C6.11822 29.4862 10.7654 32.0001 15.9999 32.0001ZM31.6799 12.7998H15.3047L16.0003 19.1998H31.6799C31.8899 18.1659 32.0003 17.0957 32.0003 15.9998 32.0003 14.9039 31.8898 13.8337 31.6799 12.7998Z"
        />
        <path
          fill="#F93939"
          d="M16 19.2C16 15.8609 16 3.7565 16 0C7.1635 0 0 7.1635 0 16C0 17.0959 0.1105 18.1661 0.320375 19.2H16Z"
        />
        <path
          fill="white"
          d="M8.82808 7.6521L9.86408 10.8405H13.2169L10.5046 12.8113L11.5405 15.9999L8.82808 14.0293L6.1157 15.9999L7.15177 12.8113L4.43945 10.8405H7.79214L8.82808 7.6521Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTogo;
