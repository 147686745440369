import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalPredefineLogistics from '../../employeeDetails/Components/UnassignToolsFromEmployeeModal/ModalPredefineLogistics';
import { useHistory } from 'react-router-dom';
import { GenericLoader } from '../../../dashboard/components';
import { ICountryDetail } from '../../../../../types/countries';
import { IemployeeDataToUnassign } from '../../../../../types/assignationFluxes';
import { getListOfCountriesByOfferedService } from '../../../../../utils/getCountries';
import BordModal from '../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './AssignToolsModal.scss';

const AssignToolsModal: FC<IAssignToolsModal> = props => {
  const {
    closeModalCallback,
    employee,
    logisticsOriginCountry,
    navigationOriginId,
    navigationOrigin,
    listOfCountriesWithLogistics,
    submitModalCallback,
    setRequireLogistics
  } = props;
  const { push } = useHistory();
  const { t } = useTranslation();

  const [logisticsPredefined, setLogisticsPredefined] = useState(false);
  const [originCountryIsOpenForLogistics, setOriginCountryIsOpenForLogistics] = useState<boolean>(false);
  const [countriesWithLogistics, setCountriesWithLogistics] = useState<ICountryDetail[]>();

  const translationKey = 'services:logistics:assignToolsSelectedOptionsModal:';
  const subtitleText = t(`${translationKey}subtitle`);

  const pendingFindingIfLogisticsIsPossible = originCountryIsOpenForLogistics === undefined;

  const handleCloseModal = () => closeModalCallback(false);

  const startAssignmentFluxCallback = () => {
    if (submitModalCallback) return submitModalCallback();
    if (!employee?.userId) return;
    const navigationParam = `&origin=${navigationOrigin}${navigationOriginId ? `&originId=${navigationOriginId}` : ''}`;
    const params = `?logistics=${logisticsPredefined || false}${navigationParam}`;
    push(`/nodi/request-logistics/assignment/${employee?.userId}${params}`);
  };

  const customModalContent = pendingFindingIfLogisticsIsPossible ? (
    <GenericLoader />
  ) : (
    <ModalPredefineLogistics
      subtitle={subtitleText}
      isAssignment={true}
      submitModalCallback={startAssignmentFluxCallback}
      updateLogisticsPredefined={newValue => {
        setLogisticsPredefined(newValue), setRequireLogistics && setRequireLogistics(newValue);
      }}
    />
  );

  useEffect(() => {
    if (!countriesWithLogistics || countriesWithLogistics.length < 1 || !logisticsOriginCountry) return;
    const isOpenForLogistics = countriesWithLogistics?.some(
      countryWithLogistics => countryWithLogistics?.code === logisticsOriginCountry?.code
    );
    setOriginCountryIsOpenForLogistics(isOpenForLogistics);
  }, [countriesWithLogistics, logisticsOriginCountry]);

  useEffect(() => {
    if (listOfCountriesWithLogistics && listOfCountriesWithLogistics.length > 1) {
      setCountriesWithLogistics(listOfCountriesWithLogistics);
    } else getListOfCountriesByOfferedService(setCountriesWithLogistics, 'logisticService');
  }, []);

  return (
    <div id="assignToolsModal">
      <BordModal
        modalContent={
          <ModalContent
            customHeight="44rem"
            customWidth="58rem"
            customSubtitleClass="customSubtitleClass"
            customHeaderClass="customHeaderClass"
            withoutHeaderCustomClass="withoutHeaderCustomClass"
            title="Onboarding de herramientas"
            subTitle="¿Requieres de los servicios logísticos de .bord para realizar la entrega de las herramientas de los empleados?"
            toCloseModal={handleCloseModal}
            CustomModalComponent={customModalContent}
          />
        }
      />
    </div>
  );
};

export default AssignToolsModal;

interface IAssignToolsModal {
  employee?: IemployeeDataToUnassign;
  navigationOrigin?: string;
  navigationOriginId?: string | number;
  logisticsOriginCountry?: ICountryDetail;
  listOfCountriesWithLogistics?: ICountryDetail[];
  submitModalCallback?: () => void;
  setRequireLogistics?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModalCallback: React.Dispatch<React.SetStateAction<boolean>>;
}
