const SquareParaguay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M12.96 8C12.9867 8.10778 13 8.21889 13 8.33333C13 9.25333 12.104 10 11 10C9.896 10 9 9.25333 9 8.33333C9 8.21889 9.01333 8.10778 9.04 8C9.22667 8.50667 10.032 8.88889 11 8.88889C11.968 8.88889 12.7733 8.50667 12.96 8Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            d="M11 7C11.5523 7 12 6.77614 12 6.5C12 6.22386 11.5523 6 11 6C10.4477 6 10 6.22386 10 6.5C10 6.77614 10.4477 7 11 7Z"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareParaguay;
