import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BordModal, ModalContent } from '../../../../../../../../../components/BordDesignSystem';
import { v4 as uuid } from 'uuid';
import { IModuleList } from '../../../../../../../../../types/global';
import BordDangerIcon from '../../../../../../../../../components/BordDesignSystem/BordIlustrationIcon/Icons/BordDangerIcon';
import './ModalDeleteSerial.scss';

interface IModalDeleteSerial {
  title?: string;
  subtitle?: string;
  textFirstButton?: string;
  textSecondButton?: string;
  firstLoading?: boolean;
  secondLoading?: boolean;
  listModal?: IModuleList[];
  firstAction?: () => void;
  secondAction?: () => void;
  handleClose?: () => void;
}

const ModalDeleteSerial: FC<IModalDeleteSerial> = ({
  title,
  subtitle,
  textFirstButton,
  textSecondButton,
  firstLoading,
  secondLoading,
  listModal,
  secondAction,
  handleClose
}: IModalDeleteSerial) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:toolInternal:ModalDeleteSerial:';

  return (
    <BordModal
      clickOutsideCallback={handleClose}
      modalContent={
        <ModalContent
          customHeight="44rem"
          excludeHeader
          showButtonOne
          showButtonTwo
          textButtonOne={textFirstButton ?? t(`${i18nLanguageKey}firstButton`)}
          textButtonTwo={textSecondButton ?? t(`${i18nLanguageKey}secondButton`)}
          onClickButtonOne={secondAction}
          toCloseModal={handleClose}
          buttonOneProps={{
            isLoading: secondLoading,
            onClick: handleClose,
            modeButton: 'primary',
            bordButtonStyle: 'outlined'
          }}
          buttonTwoProps={{
            onClick: handleClose,
            isLoading: secondLoading
          }}
          CustomModalComponent={
            <GenericSerialModal
              firstLoading={firstLoading}
              secondLoading={secondLoading}
              listModal={listModal}
              title={title}
              subtitle={subtitle}
            />
          }
        />
      }
    />
  );
};

export default ModalDeleteSerial;

const GenericSerialModal: FC<IModalDeleteSerial> = ({ listModal, title, subtitle }: IModalDeleteSerial) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:toolInternal:ModalDeleteSerial:';

  return (
    <div id="modalDeleteSerial">
      <div className="deleteSerialIconContainer">
        <BordDangerIcon />
      </div>
      <span className="deleteSerialTitle">{title ?? t(`${i18nLanguageKey}titleContainer`)}</span>
      <span className="deleteSerialSubtitle">{subtitle ?? t(`${i18nLanguageKey}subtitle`)}</span>
      {listModal && listModal.length > 0 && (
        <ul className="bodyList">
          {listModal.map(item => {
            return (
              <li className="textList" key={uuid()}>
                {`${item.title}: `}
                <span className="font-normal">{item.subtitle}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
