import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation, Trans } from 'react-i18next';
import { sendInviteCollaboratorEmail } from '../../services/signUp.service';
import { BordButton } from '../BordDesignSystem';
import { DTOOrganizationDocumentAlreadyExistResponse } from '../../types/SignUp';
import { isEmailValid } from '../../utils/formValidations';
import segment, { segmentTrackEvent } from '../../utils/segment';
import { displayErrorNotification, displaySuccessNotification } from '../../utils/displayNudosStandardNotifications';
import { BordTextInput } from '../BordDesignSystem';
import BordIlustrationIcon from '../BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';

const InviteCollaboratorCustomModalComponent = ({
  existingOrganizationData,
  setOpenInviteCollaboratorModal,
  isAnInvitation
}: IinviteCollaboratorCustomModalParams) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const [inputEmail, setInputEmail] = useState<string>();
  const [emailExistError, setEmailExistError] = useState<string>();
  const [submittedEmail, setSubmittedEmail] = useState<string>();
  const [loading, setLoading] = useState(false);

  const organization = existingOrganizationData?.name ? existingOrganizationData.name : '';
  const translationKey = 'nodi:account:companyForm:inviteCollaborator:';
  const emailLabel = t(`${translationKey}emailLabel`);
  const emailPlaceholder = t(`${translationKey}emailPlaceholder`);
  const classnames = `inviteCollaboratorCustomModalComponent ${
    isAnInvitation ? 'invitation' : 'registeredOrganization'
  }`;

  const title = isAnInvitation
    ? t(`${translationKey}inviteCollaboratorTitle`)
    : t(`${translationKey}registeredOrganizationTitle`);

  const subtitle = isAnInvitation ? (
    <Trans i18nKey={t(`${translationKey}inviteCollaboratorSubtitle`)} components={{ 1: `${organization} ` }} />
  ) : (
    t(`${translationKey}registeredOrganizationSubtitle`)
  );

  const buttonLabel = isAnInvitation
    ? t(`${translationKey}inviteCollaboratorButton`)
    : t(`${translationKey}requestAccessButton`);

  const disabled = () => {
    if (!inputEmail) return true;
    if (inputEmail.length < 6) return true;
    return !isEmailValid(inputEmail);
  };

  const updateInputEmail = (inputText: string) => {
    setInputEmail(inputText);
    if (emailExistError && inputText !== inputEmail) setEmailExistError(undefined);
  };

  const emailError = () => {
    if (submittedEmail === inputEmail && emailExistError) return emailExistError;
    return '';
  };

  const handleSendEmail = async () => {
    if (!inputEmail) return;
    setSubmittedEmail(inputEmail);
    setLoading(true);
    const body = {
      email: inputEmail,
      organizationId: existingOrganizationData.organizationId,
      registrationInfoId: existingOrganizationData.id,
      isInvite: isAnInvitation
    };
    try {
      const response = await sendInviteCollaboratorEmail(body);
      if (!response) return displayErrorNotification();

      if (!body.isInvite) {
        push('/access-request', {
          origin: 'accessRequest',
          title: t(`${translationKey}pendingIvitationResponseTitle`),
          subtitle: t(`${translationKey}pendingIvitationResponseSubtitle`),
          ilustrationVariant: 'success'
        });
        sessionStorage.removeItem('newRegisterInformation');
      }

      if (body.isInvite) {
        displaySuccessNotification({
          variant: 'success',
          customJSXMessage: (
            <p>
              La invitación para {` ${existingOrganizationData?.email || inputEmail || 'el correo'} `} fue enviada
              exitosamente
            </p>
          )
        });
        body.isInvite && segment.sessionEndingEvent();
      }

      sessionStorage.removeItem('newRegisterInformation');
      setOpenInviteCollaboratorModal(false);
    } catch (error: unknown) {
      const errorData = error as AxiosError;
      if (errorData?.response?.data?.message === 'emailAlreadyRegistered') {
        setEmailExistError('Este correo ya está registrado, intenta con otro');
      } else {
        displayErrorNotification();
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!existingOrganizationData?.collaboratorEmail) return;
    setInputEmail(existingOrganizationData.collaboratorEmail);
  }, [existingOrganizationData]);

  useEffect(() => {
    if (isAnInvitation) return;
    segmentTrackEvent({
      signupNewColaboratorView: {
        CompanyName: existingOrganizationData?.organizationName || '',
        CompanyDocument: existingOrganizationData?.rfc || '',
        CountryName: existingOrganizationData?.country?.name || 'Sin información del país',
        ContactMail: existingOrganizationData?.email || ''
      }
    });
  }, []);

  return (
    <div className={classnames}>
      {!isAnInvitation && <BordIlustrationIcon variant="search" customWidth="6.4rem" />}
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <BordTextInput
        standardSize="w-380"
        label={emailLabel}
        placeholder={emailPlaceholder}
        isFilled={!!inputEmail}
        setCurrentText={updateInputEmail}
        errorText={emailError()}
        className="invitation"
        currentText={existingOrganizationData?.collaboratorEmail}
      />
      <BordButton
        label={buttonLabel}
        disabled={disabled()}
        isLoading={loading}
        onClick={handleSendEmail}
        customWidth="w-380"
      />
    </div>
  );
};

export default InviteCollaboratorCustomModalComponent;

export interface IinviteCollaboratorCustomModalParams {
  setOpenInviteCollaboratorModal: React.Dispatch<React.SetStateAction<boolean>>;
  isAnInvitation: boolean;
  existingOrganizationData: DTOOrganizationDocumentAlreadyExistResponse;
}
