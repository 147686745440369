import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <circle cx={6} cy={6.672} r={6} fill={props.fill || '#F0F0F0'} />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const Dot = memo(ForwardRef);
export default Dot;
