import {
  CheckoutEvents,
  DashboardEvents,
  EmployeesEvents,
  InventoryEvents,
  OnboardingEvents,
  PlatformPrimeAndFreeEvents,
  RegisterEvents,
  UnassignEvents,
  ActiveOrdersEvents,
  LogisticsServiceReportEvents,
  SideBarEvents,
  EcommerceEvents,
  LogisticsServicesEvents
} from '../types/events';

const searchEventAction = (eventKey: string) => {
  const registerKey = eventKey as keyof typeof RegisterEvents;
  const eventFoundInRegisterList = RegisterEvents[registerKey];

  const onboardingKey = eventKey as keyof typeof OnboardingEvents;
  const eventFoundInOnboardingList = OnboardingEvents[onboardingKey];

  const platformPrimeAndFreeKey = eventKey as keyof typeof PlatformPrimeAndFreeEvents;
  const eventFoundInPlatformPrimeAndFreeList = PlatformPrimeAndFreeEvents[platformPrimeAndFreeKey];

  const unassignKey = eventKey as keyof typeof UnassignEvents;
  const eventFoundInUnassignList = UnassignEvents[unassignKey];

  const dashboardKey = eventKey as keyof typeof DashboardEvents;
  const eventFoundInDashboardList = DashboardEvents[dashboardKey];

  const sideBarKey = eventKey as keyof typeof SideBarEvents;
  const eventFoundInSideBarList = SideBarEvents[sideBarKey];

  const employeesKey = eventKey as keyof typeof EmployeesEvents;
  const eventFoundInEmployeesList = EmployeesEvents[employeesKey];

  const inventoryKey = eventKey as keyof typeof InventoryEvents;
  const eventFoundInInventoryList = InventoryEvents[inventoryKey];

  const checkoutKey = eventKey as keyof typeof CheckoutEvents;
  const eventFoundInCheckoutList = CheckoutEvents[checkoutKey];

  const activeOrdersKey = eventKey as keyof typeof ActiveOrdersEvents;
  const eventFoundInActiveOrdersList = ActiveOrdersEvents[activeOrdersKey];

  const logiticServiceKeys = eventKey as keyof typeof LogisticsServiceReportEvents;
  const eventFoundInLogiticServiceList = LogisticsServiceReportEvents[logiticServiceKeys];

  const ecommerceEventsKeys = eventKey as keyof typeof EcommerceEvents;
  const eventFoundInEcommerceList = EcommerceEvents[ecommerceEventsKeys];

  const logisticsServicesEventsKeys = eventKey as keyof typeof LogisticsServicesEvents;
  const eventFoundInLogisticsServicesList = LogisticsServicesEvents[logisticsServicesEventsKeys];

  const foundEvent =
    eventFoundInRegisterList ||
    eventFoundInOnboardingList ||
    eventFoundInPlatformPrimeAndFreeList ||
    eventFoundInUnassignList ||
    eventFoundInDashboardList ||
    eventFoundInEmployeesList ||
    eventFoundInInventoryList ||
    eventFoundInCheckoutList ||
    eventFoundInActiveOrdersList ||
    eventFoundInLogiticServiceList ||
    eventFoundInSideBarList ||
    eventFoundInEcommerceList ||
    eventFoundInLogisticsServicesList;

  if (foundEvent) {
    return foundEvent;
  }
};

export { searchEventAction };
