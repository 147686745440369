const RoundLesotho = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M0 16.0001C0 18.4932 0.570438 20.8531 1.58763 22.9566L16 23.6523L30.4124 22.9566C31.4296 20.8531 32 18.4932 32 16.0001C32 13.507 31.4296 11.1471 30.4124 9.04359L16 8.3479L1.58763 9.04353C0.570438 11.1471 0 13.507 0 16.0001H0Z"
        />
        <path
          fill="#6DA544"
          d="M16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32Z"
        />
        <path
          fill="#1A47B8"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0Z"
        />
        <path
          fill="black"
          d="M17.0431 15.6522V11.8262H14.9562V15.6522L12.4355 18.1729C13.1686 19.3729 14.4905 20.174 15.9997 20.174C17.5088 20.174 18.8307 19.3729 19.5638 18.1729L17.0431 15.6522Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLesotho;
