import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMachine } from '@xstate/react';
import useShopStore from '../../../state/useShopContext';
import { GenericFetchError } from '../../../constants';
import { productListMachine } from '../productsList/productList.controller';
import { SkeletonProductEcommerceCard, ProductEcommerceCard } from '../productsList/Components';
import { supportUrl } from '../../../utils/productDefinitions';
import { BordButton, BordOneToneIcon } from '../../../components/BordDesignSystem';
import BordEmptyState from '../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';

const SearchResult = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { searchResult, searchString, setSearchResult, setSearchString } = useShopStore();
  const resultLength = searchResult?.length || 0;
  const showHelpButton = resultLength <= 4;
  const [message, setMessage] = useState('');
  const [stateMachine, send] = useMachine(productListMachine);

  const translationKey = `ecommerce:searchProduct`;

  const returnAction = () => push('/catalogue/home');
  const clearButton = () => {
    returnAction();
    setSearchResult([]);
    setSearchString('');
    setMessage('clear');
  };

  useEffect(() => {
    send('DATA_CHANGE');
    if (searchResult) {
      if (searchResult.length === 0) {
        if (message === 'clear') {
          setMessage('a');
        } else {
          setMessage('');
        }
        send('MISS');
      } else {
        send('RESOLVE');
      }
    }
  }, [searchResult]);

  return (
    <section id="searchResults">
      <div className="resultsContainer">
        <section className="searchGeneralContiner">
          {stateMachine.matches('resolved') && (
            <>
              {searchString ? (
                <section onClick={() => clearButton()} className="searchDelete">
                  <div className="titleDelete">{t(`${translationKey}:resultsCopy`)}</div>
                  <div className="searchText">{` ${searchString === undefined ? '' : searchString}`}</div>
                  <span className="deleteCirlce">
                    <BordOneToneIcon variant="xCircle" standardSize={14} />
                  </span>
                </section>
              ) : (
                <div className="searchDelete" />
              )}
            </>
          )}
          {stateMachine.matches('pending') && (
            <div className="containerMyProductList additionalSearcher">
              <SkeletonProductEcommerceCard />
            </div>
          )}
          {stateMachine.matches('resolved') && (
            <>
              {searchResult && searchResult?.length > 0 && (
                <div className={`containerMyProductList additionalSearcher ${showHelpButton ? 'showHelpButton' : ''}`}>
                  {searchResult?.map((item, index) => {
                    return (
                      <ProductEcommerceCard
                        dataProductCard={item}
                        key={`product-ecommerce-card-${item?.productId}-index${index}`}
                      />
                    );
                  })}
                </div>
              )}
              {showHelpButton && (
                <div className="containerHelpButton">
                  <div className="blueLineContainer">
                    <div className="blueLine" />
                  </div>
                  <div className="infoContiner">
                    <div className="boxItems">
                      <div className="helpTitle">{t(`${translationKey}:didntFindProduct`)}</div>
                      <div className="helpSubtitle">{t(`${translationKey}:productToObtain`)}</div>
                      <div className="helpButton">
                        <BordButton
                          modeButton="primary"
                          bordButtonStyle="outlined"
                          customWidth="w-280"
                          buttonIconTwo="plus"
                          label={t(`${translationKey}:requestProductButton`)}
                          onClick={() => window.open(supportUrl, '_blank')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {stateMachine.matches('missed') && (
            <>
              {searchString ? (
                <section onClick={() => clearButton()} className="searchDelete">
                  <div className="titleDelete">{t(`${translationKey}:resultsCopy`)}</div>
                  <div className="searchText">{`${searchString === undefined ? '' : searchString}`}</div>
                  <span className="deleteCirlce">
                    <BordOneToneIcon variant="xCircle" standardSize={14} />
                  </span>
                </section>
              ) : (
                <div className="searchDelete" />
              )}
              <div className="missedResults">
                <BordEmptyState
                  title={t(`${translationKey}:noResultsForSearch`)}
                  subTitle={t(`${translationKey}:productToObtain`)}
                  ilustrationIconProps={{
                    variant: 'search',
                    customWidth: '10rem',
                    standardSize: 100
                  }}
                  buttonOneProps={{
                    customWidth: 'w-280',
                    label: t(`${translationKey}:requestProductButton`),
                    buttonIconTwo: 'plus',
                    onClick: () => window.open(supportUrl, '_blank')
                  }}
                  customClassNameSubTitle="text-base"
                />
              </div>
            </>
          )}
          {stateMachine.matches('rejected') && <GenericFetchError />}
        </section>
      </div>
    </section>
  );
};

export default SearchResult;
