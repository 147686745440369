import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../components/BordDesignSystem';
import BordIlustrationIcon from '../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import './SecurityLogoutModal.scss';

const SecurityLogoutModalContent = ({ closeModalCallback }: { closeModalCallback: () => void }) => {
  const { t } = useTranslation();
  const translationKey = 'authentication:signIn:securitylogoutmodal:';

  const submitModal = () => {
    localStorage.removeItem('tokenExpired');
    closeModalCallback();
  };

  return (
    <div className="SecurityLogoutModalContent">
      <BordIlustrationIcon variant="warning" customWidth="6.4rem" />
      <div className="modalTitle">{t(`${translationKey}title`)}</div>
      <div className="modalSubtitle">{t(`${translationKey}subtitle`)}</div>
      <BordButton label={t(`${translationKey}button`)} onClick={submitModal} customWidth="w-380" />
    </div>
  );
};

export default SecurityLogoutModalContent;
