import { useTranslation } from 'react-i18next';
import { TlogisticsStatus } from '../../../../../../types/logisticsServicesModule.types';
import usePlatform from '../../../../../../hooks/usePlatform';
import BordStepper from '../../../../../../components/BordDesignSystem/BordStepper/BordStepper';
import { BordToast } from '../../../../../../components/BordDesignSystem';
import './LogisticServiceStepper.scss';

const LogisticServiceStepper = ({
  currentStep = 'por confirmar',
  loading,
  manualQuote
}: {
  currentStep?: TlogisticsStatus | null;
  loading?: boolean;
  manualQuote?: boolean;
}) => {
  const { t } = useTranslation();

  const translationKey = 'nodi:logisticServices:logisticServiceStepper:';
  const recurrentWords = 'recurrentWords:';
  const confirmedStatus = t(`${translationKey}confirmedStatus`);
  const inProcessStatus = t(`${translationKey}inProcessStatus`);
  const activeStatus = t(`${translationKey}activeStatus`);
  const deliveredStatus = t(`${translationKey}deliveredStatus`);
  const toBeConfirmedStatus = t(`${translationKey}toBeConfirmedStatus`);
  const cancelledStatus = t(`${translationKey}cancelledStatus`);

  const confirmedHoverText = t(`${translationKey}confirmedHoverText`);
  const inProcessHoverText = t(`${translationKey}inProcessHoverText`);
  const activeHoverText = t(`${translationKey}activeHoverText`);
  const deliveredHoverText = t(`${translationKey}deliveredHoverText`);
  const defaultHoverText = t(`${translationKey}defaultHoverText`);
  const defaultManualHoverText = t(`${translationKey}defaultManualHoverText`);
  const confirmedManualHoverText = t(`${translationKey}confirmedManualHoverText`);
  const serviceCancelled = t(`${translationKey}serviceCancelled`);
  const { screenWidth } = usePlatform();
  const canceledStyles = currentStep === 'cancelado' ? 'canceled' : '';
  const isLargerScreenStyle = screenWidth >= 1919 ? 'w-full min-w-195' : 'w-full min-w-110';
  const getStepAdditionalData = (step: TlogisticsStatus) => {
    switch (step) {
      case 'confirmado':
        return {
          order: 1,
          hoverText: manualQuote ? confirmedManualHoverText : confirmedHoverText
        };
      case 'en proceso':
        return {
          order: 2,
          hoverText: inProcessHoverText
        };
      case 'activo':
        return {
          order: 3,
          hoverText: activeHoverText
        };
      case 'entregado':
        return { order: 5, hoverText: deliveredHoverText };
      default:
        return {
          order: 0,
          hoverText: manualQuote ? defaultManualHoverText : defaultHoverText
        };
    }
  };

  const cancelSteps = [
    {
      order: 0,
      title: '',
      subTitle: cancelledStatus
    }
  ];

  const progressSteps = [
    {
      order: 0,
      isTooltipMessage: true,
      tooltipMessage: getStepAdditionalData('por confirmar')?.hoverText,
      title: `${t(`${recurrentWords}step`)} 1`,
      subTitle: toBeConfirmedStatus
    },
    {
      order: 1,
      isTooltipMessage: true,
      tooltipMessage: getStepAdditionalData('confirmado')?.hoverText,
      title: `${t(`${recurrentWords}step`)} 2`,
      subTitle: confirmedStatus
    },
    {
      order: 2,
      isTooltipMessage: true,
      tooltipMessage: getStepAdditionalData('en proceso')?.hoverText,
      title: `${t(`${recurrentWords}step`)} 3`,
      subTitle: inProcessStatus
    },
    {
      order: 3,
      isTooltipMessage: true,
      tooltipMessage: getStepAdditionalData('activo')?.hoverText,
      title: `${t(`${recurrentWords}step`)} 4`,
      subTitle: activeStatus
    },
    {
      order: 4,
      isTooltipMessage: true,
      tooltipMessage: getStepAdditionalData(canceledStyles ? 'cancelado' : 'entregado')?.hoverText,
      title: `${t(`${recurrentWords}step`)} 5`,
      subTitle: canceledStyles ? cancelledStatus : deliveredStatus
    }
  ];
  return (
    <div className="LogisticServiceStepper">
      {currentStep !== 'cancelado' ? (
        <BordStepper
          steps={canceledStyles ? cancelSteps : progressSteps}
          currentStep={getStepAdditionalData(currentStep || 'por confirmar')?.order}
          skeletonSteps={progressSteps}
          loading={loading}
          isStepContent={false}
          stepperConectorWidth={isLargerScreenStyle}
        />
      ) : (
        <BordToast variant="danger" customJSXMessage={<p>{serviceCancelled}</p>} />
      )}
    </div>
  );
};

export default LogisticServiceStepper;
