import './LoaderProductDetails.scss';

const LoaderProductDetails = () => {
  return (
    <div id="loaderProductDetails">
      <div className="leftItems">
        <div className="breadcrumbLoader animationLoader" />
        <div className="titleLoader animationLoader" />
        <div className="pillsLoaderContainer">
          <div className="pillLoader animationLoader" />
          <div className="pillLoader animationLoader" />
          <div className="pillLoader animationLoader" />
        </div>
        <div className="tagsContainer animationLoader" />
        <div className="descContainer animationLoader" />
        <div className="specsContainer animationLoader" />
        <div className="blueLine" />
        <div className="pricesLoader animationLoader" />
      </div>
      <div className="rightItems">
        <div className="boxImgesLoaderContainer">
          <div className="bigImgLoader animationLoader" />
          <div className="minImgListLoaderContainer">
            <div className="itemLoader animationLoader" />
            <div className="itemLoader animationLoader" />
            <div className="itemLoader animationLoader" />
          </div>
        </div>
        <div className="flex w-full justify-end">
          <div className="totalsContainerLoader">
            <div className="buttonContainerLoader animationLoader" />
            <div className="totalsInfoLoader animationLoader" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoaderProductDetails;
