const RoundStBarts = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#ACABB1"
          d="M28.568 12.8695H23.3043C23.3043 11.909 22.5256 11.1304 21.5652 11.1304L20.1738 13.913C20.1738 13.913 21.6579 18.0869 21.6115 18.0869H23.3506C24.3112 18.0869 25.0897 17.3083 25.0897 16.3478 26.0503 16.3478 26.8289 15.5692 26.8289 14.6087H26.7709C27.7314 14.6086 28.568 13.83 28.568 12.8695ZM3.43164 12.8695H8.6954C8.6954 11.909 9.47403 11.1304 10.4345 11.1304L11.8258 13.913C11.8258 13.913 10.3417 18.0869 10.3882 18.0869H8.64904C7.68851 18.0869 6.90993 17.3083 6.90993 16.3478 5.94939 16.3478 5.17082 15.5692 5.17082 14.6087H5.22874C4.2682 14.6086 3.43164 13.83 3.43164 12.8695Z"
        />
        <path
          fill="#FFDA2C"
          d="M21.5654 22.2607V22.9564H10.4349V22.2607H7.65234V25.0433H10.4349V25.739H21.5654V25.0433H24.348V22.2607H21.5654Z"
        />
        <path
          fill="#1A47B8"
          d="M10.4346 11.1304V18.0869C10.4346 22.3468 15.9998 23.6522 15.9998 23.6522C15.9998 23.6522 21.565 22.3468 21.565 18.0869V11.1304L15.9998 10.4348L10.4346 11.1304Z"
        />
        <path fill="#F93939" d="M10.4346 13.9133H21.565V18.0873H10.4346V13.9133Z" />
        <path
          fill="#FFDA2C"
          d="M18.7824 7.65207V8.69555L18.0867 9.0434L17.3911 8.3477V6.26074H14.6085V8.3477L13.9129 9.0434L13.2172 8.69555V7.65207H10.4346V11.1303H21.565V7.65207H18.7824Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundStBarts;
