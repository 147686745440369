import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordCommandIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 3c-.7956 0-1.5587.31607-2.1213.87868C15.3161 4.44129 15 5.20435 15 6v12c0 .7956.3161 1.5587.8787 2.1213C16.4413 20.6839 17.2044 21 18 21c.7956 0 1.5587-.3161 2.1213-.8787C20.6839 19.5587 21 18.7956 21 18c0-.7956-.3161-1.5587-.8787-2.1213C19.5587 15.3161 18.7956 15 18 15H6c-.79565 0-1.55871.3161-2.12132.8787C3.31607 16.4413 3 17.2044 3 18c0 .7956.31607 1.5587.87868 2.1213C4.44129 20.6839 5.20435 21 6 21s1.55871-.3161 2.12132-.8787C8.68393 19.5587 9 18.7956 9 18V6c0-.79565-.31607-1.55871-.87868-2.12132C7.55871 3.31607 6.79565 3 6 3s-1.55871.31607-2.12132.87868C3.31607 4.44129 3 5.20435 3 6s.31607 1.55871.87868 2.12132C4.44129 8.68393 5.20435 9 6 9h12c.7956 0 1.5587-.31607 2.1213-.87868C20.6839 7.55871 21 6.79565 21 6s-.3161-1.55871-.8787-2.12132C19.5587 3.31607 18.7956 3 18 3Z"
      />
    </svg>
  );
};

export default BordCommandIcon;
