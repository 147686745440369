const RoundSanMarino = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0.695625 16 16 13.9131 16 13.9131L32 16Z"
        />
        <path fill="white" d="M0 16C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16" />
        <path
          fill="#6DA544"
          d="M22.3508 11.0403L15.9998 17.3913L9.64878 11.0403C8.82747 12.2595 8.34766 13.7269 8.34766 15.3043V17.3913C8.34766 20.7423 10.513 23.5968 13.5178 24.63C13.1167 25.416 13.1843 26.397 13.772 27.1304C14.5525 26.5048 15.3606 25.8573 16.0461 25.308C16.7316 25.8573 17.5397 26.5049 18.3202 27.1304C18.9147 26.3884 18.9775 25.3928 18.5606 24.6022C21.5243 23.5465 23.6519 20.7129 23.6519 17.3913V15.3044C23.652 13.7269 23.1721 12.2595 22.3508 11.0403Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9998 22.9566C12.9311 22.9566 10.4346 20.46 10.4346 17.3914V15.3044C10.4346 12.2358 12.9311 9.73926 15.9998 9.73926C19.0684 9.73926 21.5649 12.2358 21.5649 15.3044V17.3914C21.5649 20.46 19.0684 22.9566 15.9998 22.9566Z"
        />
        <path
          fill="#3ECBF8"
          d="M19.478 17.3914V15.3044C19.478 13.3865 17.9176 11.8262 15.9997 11.8262C14.0819 11.8262 12.5215 13.3865 12.5215 15.3044V17.3914L15.9997 18.087L19.478 17.3914Z"
        />
        <path
          fill="#6DA544"
          d="M12.5215 17.3914C12.5215 19.3093 14.0819 20.8696 15.9997 20.8696C17.9176 20.8696 19.478 19.3092 19.478 17.3914H12.5215Z"
        />
        <path
          fill="#FFDA2C"
          d="M20.174 9.73919C20.174 8.58663 19.2396 7.65225 18.087 7.65225C17.5524 7.65225 17.065 7.8535 16.6957 8.18407V6.95657H17.3914V5.56525H16.6957V4.86963H15.3044V5.56525H14.6088V6.95657H15.3044V8.18407C14.9351 7.8535 14.4477 7.65225 13.9131 7.65225C12.7605 7.65225 11.8262 8.58663 11.8262 9.73919C11.8262 10.3571 12.095 10.9121 12.5218 11.2943V12.5218H19.4783V11.2943C19.9052 10.9121 20.174 10.3571 20.174 9.73919Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSanMarino;
