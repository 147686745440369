const SquareSerbia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path fill="white" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M0 0H22V5.41667H0V0ZM8.38095 10.8333V5.41667H4.19048V10.8333C4.19048 12.0304 5.1281 13 6.28571 13C7.44333 13 8.38095 12.0304 8.38095 10.8333Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M5.23805 5.33353L4.19043 4.26686C4.19043 4.26686 5.23805 3.2002 6.28567 3.2002C7.33329 3.2002 8.38091 4.26686 8.38091 4.26686L7.33329 5.33353H5.23805Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M5.23805 6.3999L4.19043 7.46657L6.28567 8.53324L5.23805 10.6666L6.28567 12.7999L7.33329 10.6666L6.28567 8.53324L8.38091 7.46657L7.33329 6.3999L6.28567 7.46657L5.23805 6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M5.23805 6.3999H4.19043V7.46657L5.23805 6.3999ZM7.33329 6.3999H8.38091V7.46657L7.33329 6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#A75835"
            fillRule="evenodd"
            d="M6.2859 3.2002L5.23828 4.26686L6.2859 5.33353L7.33352 4.26686L6.2859 3.2002Z"
            clipRule="evenodd"
          />
          <path
            fill="#88605F"
            fillRule="evenodd"
            d="M6.28567 8.5332L4.19043 9.59987H8.38091L6.28567 8.5332Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSerbia;
