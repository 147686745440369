import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton, BordDropdown } from '../../../../../components/BordDesignSystem';
import { IDTOCreateToolPetition } from '../../../../../types/uploadExternalTools.types';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { BordTextInput } from '../../../../../components/BordDesignSystem';
import { ModalUploadTools } from '../../../../../assets/images/views/Admin/employees';

import './toolsModal.scss';

interface ISelectTools {
  value: number;
  name: string;
}
interface IFormdata {
  toolName?: string;
  toolBrand?: string;
  toolSku?: string;
  toolCategory?: string;
  storage?: string;
  ram?: string;
  processor?: string;
  screen?: string;
  aditionalReference?: string;
  frequency?: string;
  resolution?: string;
  hd?: string;
  toolDescription?: string;
}

interface IToolsModal {
  loading: boolean;
  createTool: (data: IDTOCreateToolPetition) => void;
}
const ToolsModal: FC<IToolsModal> = ({ loading, createTool }: IToolsModal) => {
  const { t } = useTranslation();
  const { orgData, personalData } = useEcommerceControler();
  const [selectItem, setSelectItem] = useState<ISelectTools>();
  const [generateForm, setGenerateForm] = useState<IFormdata>();
  const [startModal, setStartModal] = useState<boolean>(false);
  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:ToolsModal:';
  const toolsList: ISelectTools[] = [
    { value: 1, name: 'Laptops' },
    { value: 2, name: t(`${i18nLanguageKey}toolsList:value2`) },
    { value: 3, name: 'PCs' },
    { value: 4, name: t(`${i18nLanguageKey}toolsList:value4`) }
  ];

  const handleSelectNewCategory = (selectCategory: ISelectTools, closeDropdown?: (option: ISelectTools) => void) => {
    setSelectItem(selectCategory);
    setGenerateForm({ ...generateForm, ...{ toolCategory: selectCategory.name } });
    closeDropdown && closeDropdown(selectCategory);
  };

  const formDropdownOptionsCallback = (rawOptions: ISelectTools[], closeDropDown?: (option: ISelectTools) => void) => {
    return rawOptions.map((toolCategory, i) => {
      return (
        <div
          className="optionContainer"
          key={`toolCondition${i}${toolCategory?.name}`}
          onClick={() => handleSelectNewCategory(toolCategory, closeDropDown)}
        >
          {toolCategory?.name}
        </div>
      );
    });
  };

  const formSelectedOptionUICallback = (toolCategory: ISelectTools) => {
    return <>{toolCategory.name}</>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ISelectTools[]) => {
    return rawOptions.filter(toolCondition =>
      toolCondition?.name?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  const generatePills = (value?: number) => {
    if (value) {
      if (value === 1) {
        return [
          {
            Almacenamiento: generateForm?.storage || ''
          },
          { Memoria: generateForm?.ram || '' },
          { Procesador: generateForm?.processor || '' },
          { Pantalla: generateForm?.screen || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      if (value === 2) {
        return [
          {
            Frecuencia: generateForm?.frequency || ''
          },
          { Pantalla: generateForm?.screen || '' },
          { Resolucion: generateForm?.resolution || '' },
          { HD: generateForm?.hd || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      if (value === 3) {
        return [
          {
            Almacenamiento: generateForm?.storage || ''
          },
          { Memoria: generateForm?.ram || '' },
          { Procesador: generateForm?.processor || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      if (value === 4) {
        return [
          { 'Tipo de Producto': generateForm?.toolDescription || '' },
          { 'Referencia Adicional': generateForm?.aditionalReference || '' }
        ];
      }
      return '';
    }
    return '';
  };

  const sendForm = () => {
    const body = {
      organizationName: orgData?.organizationName || '',
      firstName: personalData?.firstName || '',
      lastName: personalData?.lastName || '',
      email: personalData?.email || '',
      phone: personalData?.phone || '',
      toolCategory: generateForm?.toolCategory || '',
      toolSku: generateForm?.toolSku || '',
      toolBrand: generateForm?.toolBrand,
      toolName: generateForm?.toolName,
      additionalReference: generateForm?.aditionalReference,
      toolDescription: generateForm?.toolDescription || null,
      toolSpecs: generatePills(selectItem?.value)
    };
    createTool(body);
  };

  const isEnabledButton = () => {
    if (selectItem?.value === 1) {
      if (
        generateForm?.toolName &&
        generateForm?.toolBrand &&
        generateForm?.toolCategory &&
        generateForm?.storage &&
        generateForm?.ram &&
        generateForm?.processor &&
        generateForm?.screen
      ) {
        return true;
      }
      return false;
    } else if (selectItem?.value === 2) {
      if (
        generateForm?.toolCategory &&
        generateForm?.toolBrand &&
        generateForm?.toolName &&
        generateForm?.frequency &&
        generateForm?.screen &&
        generateForm?.resolution &&
        generateForm?.hd
      ) {
        return true;
      }
      return false;
    } else if (selectItem?.value === 3) {
      if (
        generateForm?.toolCategory &&
        generateForm?.toolBrand &&
        generateForm?.toolName &&
        generateForm?.storage &&
        generateForm?.ram &&
        generateForm?.processor
      ) {
        return true;
      }
      return false;
    } else if (selectItem?.value === 4) {
      if (
        generateForm?.toolCategory &&
        generateForm?.toolDescription &&
        generateForm?.toolBrand &&
        generateForm.toolName
      ) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  return (
    <div id="toolsModal">
      <div className="boxToolsModal">
        {!startModal && (
          <div className="containerWelcomeModal">
            <div className="initialText">Proceso de creación: </div>
            <div className="listModal">
              1. Llena el formulario <br /> 2. Una vez recibida tu solicitud, crearemos la herramienta (plazo de
              creación un día hábil) <br />
              3. Te notificaremos por correo cuando esté lista
              <br /> 4. Ingresa y carga la herramienta a tu inventario{' '}
            </div>
            <div className="detailsImgContainer">
              <div className="imgTools">
                <img className="imgLimits" src={ModalUploadTools} />
              </div>
            </div>
            <div className="bottomSpace" />
          </div>
        )}

        {startModal && <div className="superiorTitle">Los campos con (*) son obligatorios</div>}

        {startModal && (
          <div className={`containerSelect ${selectItem ? 'marginSelectCategory' : 'marginNoSelectCategory'}`}>
            <BordDropdown
              customPlaceholder="Selecciona la categoría de la herramienta"
              label="Categoría*"
              rawOptions={toolsList}
              formDropdownOptionsCallback={formDropdownOptionsCallback}
              formSelectedOptionUICallback={formSelectedOptionUICallback}
              filterBySearchStringCallback={filterBySearchStringCallback}
              currentValue={selectItem}
              isFilled={!!selectItem?.name}
            />
          </div>
        )}
        {selectItem && (
          <div className="formToolsModal">
            <div className="firstLine">
              <BordTextInput
                setCurrentText={e => setGenerateForm({ ...generateForm, ...{ toolSku: e } })}
                isFilled={false}
                label={t(`${i18nLanguageKey}formToolsModal:sku`)}
                placeholder="ABC123D4"
                standardSize="w-80"
                labelProps={{ className: 'truncate' }}
              />
              {selectItem?.value === 4 && (
                <BordTextInput
                  standardSize="w-180"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ toolDescription: e } })}
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:productQuestion:label`)}
                  placeholder={t(`${i18nLanguageKey}formToolsModal:productQuestion:placeholder`)}
                />
              )}
              <BordTextInput
                label={t(`${i18nLanguageKey}formToolsModal:brand`)}
                placeholder="Apple"
                standardSize="w-180"
                isFilled={false}
                setCurrentText={e => setGenerateForm({ ...generateForm, ...{ toolBrand: e } })}
              />
              {selectItem?.value !== 4 && (
                <BordTextInput
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ toolName: e } })}
                  isFilled={false}
                  standardSize="w-280"
                  label={t(`${i18nLanguageKey}formToolsModal:nameOfProduct:label`)}
                  placeholder="Macbook Pro"
                />
              )}
            </div>
            <div className="secondLine">
              {selectItem?.value === 4 && (
                <BordTextInput
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:nameOfProduct:label`)}
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ toolName: e } })}
                  placeholder={t(`${i18nLanguageKey}formToolsModal:nameOfProduct:placeholder`)}
                  standardSize="w-280"
                />
              )}
              {(selectItem?.value === 1 || selectItem?.value === 3) && (
                <BordTextInput
                  standardSize="w-80"
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:storage`)}
                  placeholder="250 GB"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ storage: e } })}
                  labelProps={{ className: 'truncate' }}
                />
              )}
              {(selectItem?.value === 1 || selectItem?.value === 3) && (
                <BordTextInput
                  isFilled={false}
                  standardSize="w-80"
                  label={t(`${i18nLanguageKey}formToolsModal:memory`)}
                  placeholder="16 GB"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ ram: e } })}
                  labelProps={{ className: 'truncate' }}
                />
              )}
              {(selectItem?.value === 1 || selectItem?.value === 3) && (
                <BordTextInput
                  isFilled={false}
                  standardSize="w-80"
                  label={t(`${i18nLanguageKey}formToolsModal:processor`)}
                  placeholder="M2"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ processor: e } })}
                  labelProps={{ className: 'truncate' }}
                />
              )}
              {selectItem?.value === 2 && (
                <BordTextInput
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:frequency`)}
                  placeholder="60 Hz"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ frequency: e } })}
                  standardSize="w-80"
                  labelProps={{ className: 'truncate' }}
                />
              )}
              {(selectItem?.value === 1 || selectItem?.value === 2) && (
                <BordTextInput
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:screenSize`)}
                  placeholder="16’’"
                  standardSize="w-80"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ screen: e } })}
                  labelProps={{ className: 'truncate' }}
                />
              )}
              {selectItem?.value === 2 && (
                <BordTextInput
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:resolution`)}
                  placeholder="1920x1080"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ resolution: e } })}
                  standardSize="w-80"
                  labelProps={{ className: 'truncate' }}
                />
              )}
              {selectItem?.value === 2 && (
                <BordTextInput
                  isFilled={false}
                  label={t(`${i18nLanguageKey}formToolsModal:typeOfHd`)}
                  placeholder="4K"
                  setCurrentText={e => setGenerateForm({ ...generateForm, ...{ hd: e } })}
                  standardSize="w-80"
                  labelProps={{ className: 'truncate' }}
                />
              )}
              <BordTextInput
                isFilled={false}
                label={t(`${i18nLanguageKey}formToolsModal:additionalReference:label`)}
                placeholder={t(`${i18nLanguageKey}formToolsModal:additionalReference:placeholder`)}
                setCurrentText={e => setGenerateForm({ ...generateForm, ...{ aditionalReference: e } })}
                standardSize="w-180"
                labelProps={{ className: 'truncate' }}
              />
            </div>
          </div>
        )}

        {!!startModal && <div className="bottomSpace" />}
        <div className="containerFloatButton">
          <BordButton
            customWidth="w-380"
            disabled={!startModal ? false : !isEnabledButton() || loading}
            isLoading={loading}
            label={
              !startModal
                ? t(`${i18nLanguageKey}containerFloatButton:start`)
                : t(`${i18nLanguageKey}containerFloatButton:save`)
            }
            onClick={() => {
              if (!startModal) {
                setStartModal(true);
              } else {
                sendForm();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ToolsModal;
