import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Iemployee } from '../../../../../types/global';
import ExampleEmailModal from '../../../Components/ExampleEmailModal/ExampleEmailModal';
import {
  BordModal,
  ModalContent,
  BordTextArea,
  BordUploaderButton,
  BordOneToneIcon
} from '../../../../../components/BordDesignSystem';

import './ToolsManagementStep5SendEmail.scss';

interface IDocumentDetail {
  documentName: string;
  documentFile: File;
}
interface IToolsManagementStep5SendEmail {
  destinationEmployeeData?: Iemployee;
  handleEmployeeMessage?: (message: string) => void;
  defaultEmployeeMessage?: string;
  handleChangeOptionalFile?: (documentDetails: IDocumentDetail | undefined) => void;
  defaultFile?: IDocumentDetail;
}

const ToolsManagementStep5SendEmail: FC<IToolsManagementStep5SendEmail> = ({
  destinationEmployeeData,
  handleEmployeeMessage,
  defaultEmployeeMessage,
  handleChangeOptionalFile,
  defaultFile
}: IToolsManagementStep5SendEmail) => {
  const { t } = useTranslation();

  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);

  const translationKey = 'services:logistics:email:';

  const titleText = t(`${translationKey}emailExampleModal:title`);
  const description = t(`${translationKey}emailExampleModal:description`);
  const alertOptionText = t(`${translationKey}alertOptionText`);

  const employeeName = `${destinationEmployeeData?.firstName || ''} ${destinationEmployeeData?.lastName || ''}`;
  const closeExampleModal = () => setShowExampleModal(false);

  const handleChangeEmployeeMessage = (message: string) => {
    if (handleEmployeeMessage) handleEmployeeMessage(message);
  };

  return (
    <>
      {showExampleModal && (
        <BordModal
          clickOutsideCallback={() => setShowExampleModal(false)}
          closeModalCallback={closeExampleModal}
          modalContent={
            <ModalContent
              title={titleText}
              subTitle={description}
              toCloseModal={closeExampleModal}
              customHeight="48rem"
              customWidth="68rem"
              CustomModalComponent={<ExampleEmailModal closeModal={closeExampleModal} />}
            />
          }
        />
      )}
      <div className="ToolsManagementStep5SendEmail">
        <div className="boxAssignmentModuleStep4SendEmail">
          <div className="titleSendEmail">
            Establece un correo para informar a {employeeName} sobre su herramienta asignada.
          </div>
          <div className="firstBlueLineContinar">
            <div className="blueLine" />
          </div>
          <div className="textAreaSendMail">
            <div className="boxTextAreaSendMail">
              <BordTextArea
                label="Mensaje al empleado"
                customWidth="w-480"
                value={defaultEmployeeMessage || ''}
                maxLength={1000}
                showZeroValue
                placeholder={`Escribe el mensaje que quieres enviar a ${employeeName}`}
                setInputValue={e => handleChangeEmployeeMessage(e)}
                bordTextAreaContentClass="bordTextAreaContentClass"
                bordTextAreaLabelCustomClass="text-xs"
                bordTextAreaCaptionCustomClass="bordTextAreaCaptionCustomClass"
                bordTextAreaValueCustomClass="bordTextAreaValueCustomClass"
                bordTextAreaInputCustomClass="bordTextAreaInputCustomClass"
              />
            </div>
          </div>
          <div className="fileSendEmail">
            <BordUploaderButton
              label="Sube el documento (opcional)"
              defaultFile={{ documentName: 'carta para firmar el recibo', documentFile: defaultFile?.documentFile }}
              isFilled={!!defaultFile?.documentFile}
              emptyBordUploaderButtonTitle="la carta para firmar recibido"
              emptyBordUploaderButtonSubtitle="Formatos aceptados:PDF Peso maximo:2MB"
              handleChangeFile={file => {
                if (file && handleChangeOptionalFile) {
                  handleChangeOptionalFile({ documentFile: file, documentName: '' });
                }
              }}
              handleDeleteFile={() => {
                if (handleChangeOptionalFile) handleChangeOptionalFile(undefined);
              }}
              acceptFileExtension={['.pdf']}
              customWidth="w-480"
            />
          </div>

          <div className="inferiorAlertEmail">
            <div className="boxAlertEmail">
              <div className="blueIconContainer">
                <BordOneToneIcon variant="info" standardSize={16} />
              </div>
              <div className="textBlueContainer">{alertOptionText}</div>
            </div>
          </div>
          <div className="containerEmailExample">
            <div className="boxEmailExample" onClick={() => setShowExampleModal(true)}>
              <div className="infoText">¿No sabes qué escribir? Mira un</div>
              <div className="blueText">&nbsp;ejemplo del correo</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolsManagementStep5SendEmail;
