const SquareAfghanistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M0 0h7v16H0V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M15 0h7v16h-7V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M11 10.6666c1.4457 0 2.619-1.19468 2.619-2.66668S12.4457 5.33325 11 5.33325c-1.44572 0-2.61905 1.19467-2.61905 2.66667S9.55428 10.6666 11 10.6666Zm0-1.06668c.8674 0 1.5714-.95467 1.5714-2.13333 0-1.17867-.704-2.13334-1.5714-2.13334s-1.57143.95467-1.57143 2.13334c0 1.17866.70403 2.13333 1.57143 2.13333Zm0-1.06667c.2891 0 .5238-.47786.5238-1.06666S11.2891 6.39992 11 6.39992s-.5238.47787-.5238 1.06667.2347 1.06666.5238 1.06666Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAfghanistan;
