import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordDropdown } from '../../BordDesignSystem';
import { TformComponentsStandardSizes } from '../../BordDesignSystem/bordDesignSystem.types';

const BordEmployeeAreaDropdown = ({
  componentSize,
  updateCurrentValueCallback,
  currentValue,
  isDeactivated,
  showSkeletonLoader,
  errorText,
  customAreaLabel,
  customAreaPlaceholder,
  customClassname = ''
}: {
  componentSize?: TformComponentsStandardSizes;
  updateCurrentValueCallback: (newValue?: string) => void;
  currentValue?: string;
  isFocused?: boolean;
  isDeactivated?: boolean;
  showSkeletonLoader?: boolean;
  errorText?: string;
  customAreaLabel?: string;
  customAreaPlaceholder?: string;
  customClassname?: string;
}) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState<string>();

  const recurrentWords = 'recurrentWords:';
  const translationKey = 'designSystemComponents:bordEmployeeAreaDropdown:';
  const areaLabel = customAreaLabel || `${t(`${recurrentWords}employeeArea`)}*`;
  const placeholder = customAreaPlaceholder || t(`${translationKey}placeholder`);

  const rawAreaOptions = [
    'Comercial',
    'Operaciones',
    'Compras',
    'Diseño',
    'Tecnologia',
    'Producto',
    'Recursos humanos',
    'Marketing',
    'Comunicaciones',
    'Fuerza de ventas',
    'Contabilidad',
    'Finanzas',
    'Producción',
    'Innovación',
    'Servicio al cliente',
    'Soporte',
    'Directivos',
    'TI',
    'Legal',
    'Otra'
  ];

  const getAreaOptionUI = (rawArea: string, clickOptionCallback?: (option: string) => void) => {
    const handleSelectOption = () => {
      updateCurrentValueCallback(rawArea);
      clickOptionCallback && clickOptionCallback(rawArea);
    };
    return (
      <div className="optionContainer areaOption" key={`${rawArea}`} onClick={handleSelectOption}>
        {rawArea}
      </div>
    );
  };

  const formDropdownOptionsCallback = (options: string[], clickOptionCallback?: (option: string) => void) => {
    const areaOptions = options.map(rawBillingData => {
      return getAreaOptionUI(rawBillingData, clickOptionCallback);
    });
    return areaOptions;
  };

  const formSelectedOptionUICallback = (selectedOption: string) => getAreaOptionUI(selectedOption, undefined);

  const filterBySearchStringCallback = (search: string, rawOptions: string[]) => {
    return rawOptions.filter(rawAreaOption => {
      const test = rawAreaOption?.toLowerCase().trim().includes(search.toLowerCase().trim());
      return test;
    });
  };

  useEffect(() => {
    if (initialData || !currentValue) return;
    setInitialData(currentValue);
  }, [currentValue]);

  return (
    <div className={`${customClassname} bordEmployeeAreaDropdown`}>
      <BordDropdown
        customClassname="BordEmployeeAreaDropdown"
        label={areaLabel}
        componentSize={componentSize}
        customPlaceholder={placeholder}
        isFilled={!!currentValue}
        currentValue={currentValue}
        includeMagnifierIcon={false}
        rawOptions={rawAreaOptions}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
        isDeactivated={isDeactivated}
        showSkeletonLoader={showSkeletonLoader}
        errorText={errorText}
      />
    </div>
  );
};

export default BordEmployeeAreaDropdown;
