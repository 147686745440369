import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import {
  BordButton,
  BordFlag,
  BordOneToneIcon,
  BordPhoto,
  BordTooltip
} from '../../../../../../components/BordDesignSystem';
import { EmployeePills } from '../../../../../../components';
import { NudosHoverText } from '../../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../../types/global';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { TcountryCode } from '../../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import './EmployeeDetailsHeader.scss';

const EmployeeDetailsHeader = ({
  employee,
  disabledEmployee
}: {
  employee: Iemployee;
  disabledEmployee?: () => void;
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const currentEquipment = [...(employee?.assignedTools || [])].filter(tool => {
    return !tool.assignedUntil;
  });
  const employeeFullName = `${employee.firstName} ${employee.lastName}`.trim();
  const employeeDetailsLanguage = 'nodi:employeeInternal:';
  const recurrentWords = 'recurrentWords:';
  const cityPillKey = t(`${employeeDetailsLanguage}newEmployeeForm:nameAndPills:employeePills:city`);
  const areaPillKey = t(`${employeeDetailsLanguage}newEmployeeForm:nameAndPills:employeePills:area`);
  const positionPillKey = t(`${employeeDetailsLanguage}newEmployeeForm:nameAndPills:employeePills:position`);

  const employeePills = {
    [cityPillKey]: employee.city || '',
    [areaPillKey]: employee['employeeArea.nameStringId']
      ? t(`services:employeeArea:${employee['employeeArea.nameStringId']}`)
      : employee.area || '',
    [positionPillKey]: employee.position || ''
  };
  const noAddress =
    !employee.address ||
    !employee.address.address ||
    typeof employee.address.address !== 'string' ||
    !employee.address.country ||
    !employee.address.city;

  const noAddressStyle = noAddress ? 'noAddress' : '';
  const hasEmployeeIndications = !!employee?.address?.additionalReferences;
  const hasPersonalID = !!employee?.personalId;
  const hasPhone = !!employee?.phoneData?.phone;
  const phoneFull = `+${employee?.phoneData?.phoneCode || ''} ${employee?.phoneData?.phone || ''}`;

  const employeeIsDisabled = employee?.active === 0;
  const employeeHasNoDevices = currentEquipment.length === 0 && employee?.active === 1;
  const employeeHasDevices = currentEquipment.length > 0 && employee?.active === 1;
  const updateUserState = () => {
    if (disabledEmployee) {
      disabledEmployee();
      segmentTrackEvent({
        employeesDetailDisableClick: {
          EmployeeCountry: employee?.country?.name || 'Otro',
          EmployeeName: employeeFullName
        }
      });
    }
  };
  const handleClickEditData = () => {
    return push(`/nodi/employees/edit/${employee.userId}`);
  };

  return (
    <>
      <div id="employeeDetailsHeader">
        <section className="sectionUserInfo">
          <div className="imageSection">
            <BordPhoto
              size="s100"
              url={employee?.photoProfile?.replaceAll(' ', '%20')}
              avatarProps={{ variant: 'rectangularBordMascot', customWidth: '10rem' }}
              imageProps={{ style: { width: '100%', borderRadius: '1.2rem' } }}
            />
          </div>
          <div className="namePillsAddressSection">
            {!!employeeFullName && (
              <div className="containerName">
                <NudosHoverText
                  text={employeeFullName || ''}
                  charactersLimit={20}
                  customClassName={`name`}
                  onlyIfTruncated
                />
                <BordFlag variant="circle" standardSize={20} country={employee?.country?.code as TcountryCode} />
              </div>
            )}
            {employee?.mail && (
              <div className="containerMail">
                <BordOneToneIcon variant="mail" standardSize={12} />
                <NudosHoverText text={employee?.mail || ''} charactersLimit={40} customClassName={`mail`} />
              </div>
            )}
            <EmployeePills pills={employeePills} isActive={true} />
          </div>
        </section>
        <div className="buttonsSection">
          <div className="statusContainer">
            {employee?.active ? (
              <>
                <div className="text">{t(`${employeeDetailsLanguage}buttonsSection:active`)}</div>
                <div className="circle" />
              </>
            ) : (
              <>
                <div className="text">{t(`${employeeDetailsLanguage}buttonsSection:inactive`)}</div>
                <div className="grayCircle" />
              </>
            )}
          </div>
          <div className="disableButtonContainer">
            {currentEquipment && currentEquipment.length > 0 ? (
              <div className="disableButton">{t(`${employeeDetailsLanguage}buttonsSection:inactivate`)}</div>
            ) : (
              <div
                className="enabledButton"
                onClick={() => {
                  updateUserState();
                }}
              >
                {employee?.active
                  ? t(`${employeeDetailsLanguage}buttonsSection:inactive`)
                  : t(`${employeeDetailsLanguage}buttonsSection:active`)}
              </div>
            )}
            <div className="contentIcon">
              <BordOneToneIcon variant="info" standardSize={12} />
              <BordTooltip message={''}>
                <div className="tooltip">
                  {employeeIsDisabled && (
                    <Trans i18nKey={`${employeeDetailsLanguage}buttonsSection:tooltip:disabled`} />
                  )}
                  {employeeHasNoDevices && (
                    <Trans i18nKey={`${employeeDetailsLanguage}buttonsSection:tooltip:noDevices`} />
                  )}
                  {employeeHasDevices && (
                    <Trans i18nKey={`${employeeDetailsLanguage}buttonsSection:tooltip:wDevices`} />
                  )}
                </div>
              </BordTooltip>
            </div>
          </div>
        </div>
        <section className="employeeOtherInfo">
          <div className="col1">
            <div className={`containerInfo ${noAddressStyle}`}>
              <span>{t(`${recurrentWords}address`)}</span>
              <div className="containerText">
                <BordOneToneIcon variant="home" standardSize={12} />
                {noAddress ? (
                  <p className="noAddressText">{t(`${employeeDetailsLanguage}namePillsAddressSection:noAddress`)}</p>
                ) : (
                  <NudosHoverText
                    text={employee?.address?.address || ''}
                    charactersLimit={40}
                    customClassName={`informationText top-2`}
                  />
                )}
              </div>
            </div>
            <div className={`containerInfo`}>
              <span>{t(`${recurrentWords}indications`)}</span>
              <div className="containerText">
                <BordOneToneIcon variant="flag" standardSize={12} />
                {hasEmployeeIndications ? (
                  <NudosHoverText
                    text={employee?.address?.additionalReferences || ''}
                    charactersLimit={40}
                    customClassName={`informationText`}
                  />
                ) : (
                  <p className="noInformation">{t(`${recurrentWords}noInformation`)}</p>
                )}
              </div>
            </div>
          </div>
          <div className="col2">
            <div className={`containerInfo`}>
              <span className="uppercase">{t(`${recurrentWords}id`)}</span>
              <div className="containerText">
                <BordOneToneIcon variant="fileText" standardSize={12} />
                {hasPersonalID ? (
                  <NudosHoverText
                    text={employee?.personalId || ''}
                    charactersLimit={40}
                    customClassName={`informationText`}
                  />
                ) : (
                  <p className="noInformation">{t(`${recurrentWords}noInformation`)}</p>
                )}
              </div>
            </div>
            <div className={`containerInfo`}>
              <span>{t(`${recurrentWords}phone`)}</span>
              <div className="containerText">
                <BordOneToneIcon variant="phone" standardSize={12} />
                {hasPhone ? (
                  <NudosHoverText text={phoneFull || ''} charactersLimit={40} customClassName={`informationText`} />
                ) : (
                  <p className="noInformation">{t(`${recurrentWords}noInformation`)}</p>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="actions">
          <BordButton
            modeButton="tertiary"
            label={t(`${recurrentWords}editOrViewDetails`)}
            onClick={handleClickEditData}
            buttonIconTwo="edit"
          />
        </section>
      </div>
    </>
  );
};

export default EmployeeDetailsHeader;
