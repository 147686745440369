const SquareSaoTomeAndPrincipe = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path
            fill="#00B731"
            fillRule="evenodd"
            d="M0 11.7333V16H22V11.7333H0ZM0 0V4.26667H22V0H0Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 0V16.0352L7.33333 8L0 0Z" clipRule="evenodd" />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M10.9998 8.98137L9.80024 9.62457L10.0307 8.26457L9.05957 7.30137L10.4005 7.10403L10.9998 5.8667L11.599 7.10403L12.94 7.30137L11.9699 8.26457L12.2003 9.62457L10.9998 8.98137ZM17.2855 8.98137L16.086 9.62457L16.3164 8.26457L15.3453 7.30137L16.6862 7.10403L17.2855 5.8667L17.8847 7.10403L19.2257 7.30137L18.2556 8.26457L18.486 9.62457"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSaoTomeAndPrincipe;
