const RoundPhilippines = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M16.0003 0V16L4.68652 27.3137C7.58196 30.2091 11.582 32 16.0003 32C24.8368 32 32.0003 24.8365 32.0003 16C32.0003 7.1635 16.0003 0 16.0003 0Z"
        />
        <path
          fill="#1A47B8"
          d="M16.0003 0C11.582 0 7.58196 1.79088 4.68652 4.68625C8.61215 8.61188 10.0057 10.0055 16.0003 16H32.0003C32.0003 7.1635 24.8368 0 16.0003 0Z"
        />
        <path
          fill="#FFDA2C"
          d="M10.956 16.0001 9.00198 15.081 10.0425 13.1886 7.92066 13.5945 7.65179 11.4509 6.17379 13.0274 4.69585 11.4509 4.42691 13.5945 2.3051 13.1885 3.34566 15.081 1.3916 16.0001 3.34566 16.9192 2.3051 18.8117 4.4271 18.4058 4.69579 20.5493 6.17379 18.9728 7.65179 20.5493 7.92066 18.4058 10.0424 18.8117 9.00191 16.9193 10.956 16.0001ZM4.26711 6.26099 4.91736 7.16992 5.98267 6.83249 5.31905 7.73174 5.9693 8.64067 4.90892 8.28749 4.24536 9.18667 4.25367 8.06917 3.19336 7.71592 4.25886 7.37849 4.26711 6.26099ZM4.26711 22.8135 4.91736 23.7224 5.98267 23.3849 5.31905 24.2842 5.9693 25.1932 4.90892 24.84 4.24536 25.7392 4.25367 24.6217 3.19336 24.2684 4.25886 23.931 4.26711 22.8135ZM13.5417 14.5374 12.8915 15.4463 11.8262 15.1087 12.4898 16.008 11.8395 16.917 12.8999 16.5638 13.5635 17.463 13.5552 16.3455 14.6155 15.9923 13.55 15.6549 13.5417 14.5374Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPhilippines;
