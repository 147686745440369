import { useState } from 'react';
import { BordUploaderButton } from '../../BordDesignSystem';
import { TformComponentsStandardSizes } from '../../BordDesignSystem/bordDesignSystem.types';
import './NudosUploadDownloadButton.scss';

/**
 * @property { (file?: File | undefined) => void } uploadedFileHandler - REQUIRED, The callback function that accepts the file as a param and execute the desired action with it
 * @property { string } currentFileUrl -  Optional, the current dabase stored url for the document to be uploaded. If passed the download button is active (blue and clickable) and the document can be downloaded
 * @property { string } buttonEmptyText - Optional, the text to show on the upload button when it is EMPTY (no file has been selected)
 * @property { string } buttonFilledText - Optional, the text to show on the upload button when it is FILLED (a file has been selected)
 * @property { string } downloadButtonText -  Optional, the text to show on the download button
 * @property { boolean } isDisabled -  Optional, a boolean indicating if the upload button is disabled and consequently wont serve its upload function
 * @property {boolean} showSkeletonLoader - Optional, boolean indicating if the input is loading and therefore the skeleton loader is to be shown instead of the input field. Default is false.
 * @property { string } customWidth -  Optional, a string with a valid value for the width css property ("120px"), default is max-content
 * @property { string } customHeight -  Optional, a string with a valid value for the height css property ("40px"), default is 28px
 * @property { string } customClassName -  Optional, a css class for customizing styles
 * @property { boolean } shouldAttractAttention -  Optional, a boolean indicating if the upload button should display an animation attracting attention
 * @property { boolean } resetSelectionWhenClickingWhenFilled -  Optional, a boolean indicating if the button is expected to be reseet if its clicked after having selected a file. If this is enabled the button will only look active if a file has been selected, and not if a value for the currentFileUrl property is passed. Default is FALSE
 */
const NudosUploadDownloadButton = ({
  uploadedFileHandler,
  currentFileUrl,
  buttonEmptyText,
  buttonFilledText,
  downloadButtonText,
  isDisabled,
  showSkeletonLoader = false,
  customWidth,
  customHeight,
  customClassName,
  shouldAttractAttention,
  resetSelectionWhenClickingWhenFilled = false
}: {
  uploadedFileHandler: (file?: File | undefined) => void;
  currentFileUrl?: string;
  buttonEmptyText?: string;
  buttonFilledText?: string;
  downloadButtonText?: string;
  isDisabled?: boolean;
  showSkeletonLoader?: boolean;
  customWidth?: TformComponentsStandardSizes;
  customHeight?: string;
  customClassName?: string;
  shouldAttractAttention?: boolean;
  resetSelectionWhenClickingWhenFilled?: boolean;
}) => {
  const [fileToUpload, setFileToUpload] = useState<File>();

  const isFilled = !!fileToUpload || (!resetSelectionWhenClickingWhenFilled && !!currentFileUrl);
  const buttonText = isFilled ? buttonFilledText : buttonEmptyText;
  const activeLinkStyle = currentFileUrl ? 'active' : '';
  const customClassNameStyles = customClassName || '';

  const customStyles = { width: customWidth, height: customHeight };

  const handleUploadFile = (file: File) => {
    setFileToUpload(file);
    uploadedFileHandler(file);
  };

  const getSkeletonLoader = () => {
    return (
      <div className="nudosUploadDownloadButtonSkeletonLoader">
        <div className="fileInputSkeletonLoader animationLoader" style={customStyles}>
          {buttonText || 'Cargar documento'}
        </div>
        <div className="uploadSkeletonLoader animationLoader">{downloadButtonText || 'Descargar documento'}</div>
      </div>
    );
  };

  return (
    <>
      {showSkeletonLoader && getSkeletonLoader()}
      {!showSkeletonLoader && (
        <div className={`nudosUploadDownloadButton ${customClassNameStyles}`}>
          <BordUploaderButton
            label="RUT"
            handleChangeFile={handleUploadFile}
            customWidth={customWidth}
            defaultFile={{ documentName: '', url: currentFileUrl ?? '' }}
            emptyBordUploaderButtonTitle="El RUT"
            emptyBordUploaderButtonSubtitle="Formatos aceptados: [.pdf] Peso máximo: 5mb"
            defaultWidthFull
            acceptFileExtension={['.pdf']}
          />
        </div>
      )}
    </>
  );
};

export default NudosUploadDownloadButton;
