import './InventoryListingCardSkeleton.scss';

const InventoryListingCardSkeleton = () => {
  return (
    <div className="inventoryListingCardSkeleton animationLoader">
      <div className="boxCard">
        <div className={`containerImg`}>
          <div className="boxImg">
            <img className="limitImg" />
          </div>
        </div>
        <div className={`containerInfo`}>
          <div className="titleAndBrand">
            <div className="titleContainer" />
          </div>
          <div className="pills">
            {[0, 1, 2, 3].map(i => {
              return <div key={`pill-${i}`} className="pill" />;
            })}
          </div>
        </div>
        <div className="wipingSection">
          <div className="skeleton animationLoader" />
        </div>
        <div className="containerModelAndSerial">
          <div className="subContainer" />
          <div className="subContainer" />
        </div>
        <div className="containerName">
          <div className="subContainer" />
          <div className="subContainer" />
        </div>
        <div className="containerButton" />
      </div>
    </div>
  );
};

export default InventoryListingCardSkeleton;
