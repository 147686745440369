import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './AlertTextComponent.scss';

interface IAlertTextComponent {
  changeStatusBanners: () => void;
  showSeeMore: boolean;
  problemsWithAddresses: boolean;
  addressesHaveBeenUpdated: boolean;
}

const AlertTextComponent: FC<IAlertTextComponent> = ({
  changeStatusBanners,
  showSeeMore,
  problemsWithAddresses
}: IAlertTextComponent) => {
  const transtlations = 'nodi:orderDetails:orderDetailsSummary:alertTextComponent';
  const { t } = useTranslation();

  return (
    <div className={`${showSeeMore ? 'mt-15' : ''} flex flex-col items-end`}>
      <div id="alertTextComponent">
        <>
          <div className="graySeparator" />
          <div className="noticeText">
            {t(`${transtlations}:${problemsWithAddresses ? 'addressProblem' : 'updateAddress'}`)}
          </div>
        </>
      </div>
      {showSeeMore && (
        <div className="seeMoreItem" onClick={changeStatusBanners}>
          {t(`${transtlations}:button`)}
        </div>
      )}
    </div>
  );
};

export default AlertTextComponent;
