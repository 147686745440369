import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NudosBreadcrumbButton } from '../../../../../../components/NudosComponents';
import { BordButton, BordTextInput } from '../../../../../../components/BordDesignSystem';
import { ICountryDetail } from '../../../../../../types/countries';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { NudosCountryDropdown } from '../../../../../../components/DesignSystem';
import useSignStore from '../../../../state/useSignStore';
import {
  getNewRegisteredData,
  getOrganizationBusinessNameError,
  getOrganizationDocumentError,
  getOrganizationNameError
} from '../../../../signUp.utils';
import BordEmployeesCountDropdown, {
  employeesCountMap
} from '../../../../../../components/BordDesignSystem/BordEmployeesCountDropdown/BordEmployeesCountDropdown';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { sendRegisterFormFirstStep, updateRegisterFormData } from '../../../../../../services/signUp.service';

const SignUpFormStep1 = ({
  setFormStep,
  countriesOpenForRegistration,
  setOpenInviteCollaboratorModal
}: IsignUpFormStep1Params) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const {
    organizationName,
    setOrganizationName,
    organizationBusinessName,
    setOrganizationBusinessName,
    organizationDocument,
    organizationDocumentAlreadyRegistered,
    employeesCount,
    setOrganizationDocument,
    organizationCountry,
    setOrganizationCountry,
    setEmployeesCount,
    setExistingOrganizationData,
    setOrganizationDocumentAlreadyRegistered
  } = useSignStore();
  const [loading, setloading] = useState(false);

  const newRegisterData = getNewRegisteredData();
  const translationKey = 'authentication:SignUpForm:formFieldsStep1:';

  const organizationDocumentAlreadyRegisteredError = organizationDocumentAlreadyRegistered
    ? t('authentication:SignUpForm:formErrors:organizationDocumentAlreadyRegistered')
    : undefined;
  const organizationNameError = getOrganizationNameError(organizationName);
  const organizationBusinessNameError = getOrganizationBusinessNameError(organizationBusinessName);
  const organizationDocumentError =
    getOrganizationDocumentError(organizationDocument, organizationCountry) ||
    organizationDocumentAlreadyRegisteredError;

  const handleClickReturn = () => {
    localStorage.removeItem('userFirebaseData');
    sessionStorage.removeItem('newRegisterInformation');
    push('/login');
  };

  const updateOrganizationDocument = (newDocument: string) => {
    setOrganizationDocumentAlreadyRegistered(false);
    setOrganizationDocument(newDocument);
  };

  const isButtonDisabled = () => {
    if (!organizationName || !organizationBusinessName || !organizationCountry || !organizationDocument) return true;
    if (!employeesCount?.optionId) return true;
    if (organizationNameError || organizationBusinessNameError || organizationDocumentError) return true;
    return false;
  };

  const handleAdvanceStep = async () => {
    if (isButtonDisabled()) return;
    segmentTrackEvent({
      signupFirstStepClick: {
        CountryName: organizationCountry?.name || '',
        EmployeeRange: employeesCount?.value || ''
      }
    });
    setloading(true);
    try {
      const body = {
        organizationName: organizationName || '',
        organizationBusinessName: organizationBusinessName || '',
        rfc: organizationDocument || '',
        numberOfEmployees: employeesCount?.optionId as number,
        countryId: organizationCountry?.id
      };
      const response =
        newRegisterData && newRegisterData.id && newRegisterData.rfc === organizationDocument
          ? await updateRegisterFormData(body, newRegisterData.id)
          : await sendRegisterFormFirstStep(body);
      if (response) {
        if (response.exist) {
          setExistingOrganizationData({
            ...response,
            country: organizationCountry
          });
          setOpenInviteCollaboratorModal(true);
          setOrganizationDocumentAlreadyRegistered(true);
        } else {
          const newRegisterInformation = { ...response, country: organizationCountry };
          const newRegisterInformationString = JSON.stringify(newRegisterInformation);
          sessionStorage.setItem('newRegisterInformation', newRegisterInformationString);
          setFormStep(1);
        }
      } else {
        const updatedRegisterInformation = { ...newRegisterData, ...body, country: organizationCountry };
        const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
        sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
        setFormStep(1);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setloading(false);
    }
  };
  useEffect(() => {
    if (!newRegisterData?.countryId || organizationCountry) return;
    const previouslySelectedCountry = countriesOpenForRegistration.find(
      countryInList => countryInList.id === newRegisterData.countryId
    );
    previouslySelectedCountry && setOrganizationCountry(previouslySelectedCountry);
  }, [newRegisterData]);

  useEffect(() => {
    segmentTrackEvent({ signupFirstStepView: null });
  }, []);

  useEffect(() => {
    const newRegisterData = getNewRegisteredData();
    const previousEmployeesCount =
      employeesCountMap[newRegisterData?.numberOfEmployees as keyof typeof employeesCountMap];

    setOrganizationName(newRegisterData?.organizationName || '');
    setOrganizationBusinessName(newRegisterData?.organizationBusinessName || '');
    setOrganizationDocument(newRegisterData?.rfc || '');
    setOrganizationCountry(newRegisterData?.country || undefined);
    setEmployeesCount(previousEmployeesCount || undefined);
  }, []);

  return (
    <div className="formStep formStep1">
      <BordTextInput
        className="formLine"
        label={t(`${translationKey}organizationName`)}
        style={{ width: '40rem' }}
        placeholder={'Bord'}
        currentText={organizationName}
        setCurrentText={setOrganizationName}
        errorText={organizationNameError}
      />
      <BordTextInput
        className="formLine"
        label={t(`${translationKey}organizationBusinessName`)}
        style={{ width: '40rem' }}
        placeholder={'Bord S.A. de C.V.'}
        currentText={organizationBusinessName}
        setCurrentText={setOrganizationBusinessName}
        errorText={organizationBusinessNameError}
      />
      <div className="formLine organizationBusinessNameAndCountry">
        <NudosCountryDropdown
          countriesList={countriesOpenForRegistration}
          charactersLimit={12}
          currentValue={organizationCountry}
          updateCurrentValueCallback={setOrganizationCountry}
          componentSize="w-160"
        />
        <BordTextInput
          className="formLine"
          label={t(`${translationKey}organizationDocumentLabel`)}
          style={{ width: '22.4rem' }}
          placeholder={t(`${translationKey}organizationDocumentPlaceholder`)}
          currentText={organizationDocument}
          setCurrentText={updateOrganizationDocument}
          errorText={organizationDocumentError}
        />
      </div>
      <BordEmployeesCountDropdown
        componentSize="w-400"
        currentValue={employeesCount}
        updateCurrentValueCallback={setEmployeesCount}
      />
      <div className="buttonsContainer firstStep">
        <NudosBreadcrumbButton returnText={t(`${translationKey}goBackButton`)} returnAction={handleClickReturn} />
        <BordButton
          label={t(`${translationKey}continueButton`)}
          disabled={isButtonDisabled()}
          onClick={handleAdvanceStep}
          customWidth="w-280"
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default SignUpFormStep1;

export interface IsignUpFormStep1Params {
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  setOpenInviteCollaboratorModal: React.Dispatch<React.SetStateAction<boolean>>;
  countriesOpenForRegistration: ICountryDetail[];
  setBusinessNameAlreadyRegisteredError: React.Dispatch<React.SetStateAction<string | undefined>>;
  businessNameAlreadyRegisteredError?: string;
}
