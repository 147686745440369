import { useHistory } from 'react-router-dom';
import { CartIcon } from '../../../../../assets/images/views/Ecommerce';
import BordModal from '../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import { BordButton } from '../../../../../components/BordDesignSystem';
import './ModalInvalidCart.scss';

const ModalInvalidCart = () => {
  const { push } = useHistory();

  return (
    <BordModal
      modalContent={
        <ModalContent
          CustomModalComponent={
            <div id="modalInvalidCart">
              <div className="iconContainer">
                <div className="iconBox">
                  <CartIcon style={{ transform: 'scale(1.5)' }} stroke="#54A1FC" fill="white" />
                </div>
              </div>
              <div className="infoContainer">
                Tu carrito está vacío, vuelve a seleccionar tus productos para continuar con tu compra
              </div>
              <div className="buttonContainer">
                <BordButton
                  customWidth="w-180"
                  label="Ir a la tienda"
                  onClick={() => {
                    push('/catalogue/home');
                  }}
                />
              </div>
            </div>
          }
        />
      }
    />
  );
};

export default ModalInvalidCart;
