import { IShipmentsData } from '../../../../../../types/orders';
import FirstShipmentData from './FirstShipmentData';
import MultipleShipmentsSummary from './MultipleShipmentsSummary';
import './ShipmentsData.scss';

const ShipmentsData = ({ shipmentsData = [] }: { shipmentsData?: IShipmentsData[] }) => {
  const shipmentsCount = shipmentsData?.length;
  const firstShipment = shipmentsData[0];

  return (
    <div className="shipmentsData">
      {shipmentsCount === 1 && <FirstShipmentData firstShipment={firstShipment} />}
      {shipmentsCount > 1 && <MultipleShipmentsSummary shipmentsData={shipmentsData} />}
    </div>
  );
};

export default ShipmentsData;
