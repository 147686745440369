import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosAddressSearchBar } from '../../../../../components/NudosComponents';
import { BordButton } from '../../../../../components/BordDesignSystem';
import { BordTextInput } from '../../../../../components/BordDesignSystem';
import { ILocationCountryAddress } from '../../../../../types/account';
import { IaddressFromGoogleMaps } from '../../../../../types/global';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import { ICountryDetail } from '../../../../../types/countries';
import { getIsInvalidZipCode } from '../../../../../utils/productDefinitions';
import './modalAddOfficeAddress.scss';

interface IModalAddOfficeAddress {
  validCountryList?: ICountryDetail[];
  handleNewCountry?: (data: ILocationCountryAddress) => void;
  loadingNewAddres?: boolean;
}

const ModalAddOfficeAddress: FC<IModalAddOfficeAddress> = ({
  validCountryList,
  handleNewCountry,
  loadingNewAddres
}: IModalAddOfficeAddress) => {
  const { t } = useTranslation();
  const { orgData } = useEcommerceControler();
  const [myAddress, setMyAddress] = useState<IaddressFromGoogleMaps>();
  const [optionalText, setOptionalText] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [zipCode, setZipCode] = useState('');
  const i18nLanguageKey = 'nodi:account:organizationAddressesTab:modalEditAddress:';

  const saveAddres = () => {
    if (!selectedCountry?.id) return;
    const body = {
      address: myAddress?.address || '',
      additionalReferences: optionalText || '',
      coordinates: myAddress?.coordinates || undefined,
      city: myAddress?.city || '',
      countryId: selectedCountry.id,
      zipCode
    };
    if (handleNewCountry) {
      handleNewCountry(body);
    }
  };

  const showAlertSearchBar = () => {
    if (myAddress && selectedCountry && myAddress?.country !== selectedCountry?.code) {
      return `Esta dirección no pertenece a ${selectedCountry?.name || 'este país'}`;
    }
    return undefined;
  };

  return (
    <div id="modalAddOfficeAddress">
      <div className="boxModalAddOfficeAddress">
        <div className="containerModalOfficeAddress">
          <div className="flex flex-col gap-8 items-center">
            <span className="text-bord-h2 text-content font-bold">{t('recurrentWords:office')}</span>
            <span className="text-bord-sm text-content-secondary font-normal">
              {t(`${i18nLanguageKey}orangeEditAddress`)}
            </span>
          </div>
          <div className="flex items-center justify-start mt-40 mb-12">
            <span className="text-bord-subtitle text-content font-bold">{orgData?.organizationName}</span>
          </div>
          <div className="flex items-center gap-20">
            <NudosCountryDropdown
              charactersLimit={20}
              customLabel="País"
              currentValue={selectedCountry}
              countriesList={validCountryList || []}
              customPlaceholder="País"
              updateCurrentValueCallback={setSelectedCountry}
              componentSize="w-180"
            />
            <BordTextInput
              label="Código postal "
              placeholder="Escribe el código postal"
              setCurrentText={e => {
                setZipCode(e);
              }}
              className="zipCodeInput"
              currentText={zipCode}
              standardSize="w-180"
              showSkeletonLoader={false}
              errorText={getIsInvalidZipCode(zipCode) ? 'Código postal inválido' : undefined}
            />
          </div>
          <div className="mt-12">
            <NudosAddressSearchBar
              handleAddressSelection={e => setMyAddress(e)}
              isDisabled={!selectedCountry}
              label={t(`${i18nLanguageKey}address`)}
              errorText={showAlertSearchBar()}
              regionBias={selectedCountry?.code}
              hideDidntFindAddressToolTip
              componentSize="w-380"
            />
          </div>
          <div className="mt-12">
            <BordTextInput
              isFilled={!!optionalText}
              placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
              label={`${t(`${i18nLanguageKey}directions:label`)}`}
              setCurrentText={e => setOptionalText(e)}
              standardSize="w-380"
            />
          </div>
          <div className="containerButtonModalOfficeAddress">
            <BordButton
              onClick={() => saveAddres()}
              isLoading={loadingNewAddres}
              disabled={
                !optionalText ||
                loadingNewAddres ||
                !zipCode ||
                !selectedCountry ||
                !myAddress ||
                !!showAlertSearchBar() ||
                !!getIsInvalidZipCode(zipCode)
              }
              label={t(`${i18nLanguageKey}nudosButton`)}
              customWidth="w-380"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalAddOfficeAddress;
