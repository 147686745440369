import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" viewBox="0 0 13 13" ref={ref} {...props}>
    <path
      stroke={props?.stroke || '#383838'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.493 4.45c1.294 1.58 2.084 2.37 3.6 3.6 1.517-1.23 2.306-2.02 3.6-3.6"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconDropdownArrow = memo(ForwardRef);
export default IconDropdownArrow;
