import { BordButton } from '../../../../../components/BordDesignSystem';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import {
  ICartInfo,
  IProductPrices,
  IShipmentGroupedByDestinationLocationAndProductId,
  ISummaryItem
} from '../../../../../types/cart';
import { ICountryDetail } from '../../../../../types/countries';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import {
  costForPaidShipments,
  lowerLimitForFreeShipping,
  minimumShippingCost
} from '../../../../../utils/productDefinitions';
import { truncateText } from '../../../../../utils/truncateText';
import { useTranslation } from 'react-i18next';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import { ICalculateOfShipment } from '../../../../../types/orders';
import PlatinumDiscountTooltip from '../../../../../components/NudosPrimeOrFree/DiscountPlatinum/PlatinumDiscountTooltip';
import './CheckoutSummary.scss';

const CheckoutSummary = ({
  firstSectionListingElements,
  showShipmentsSection,
  secondSectionListingElements,
  secondSectionListItems,
  boldAndLargeTextInSecondLine,
  buttonText,
  handleClickButton,
  isButtonDisabled,
  customClassName,
  shipmentsList,
  shipmentsPricingDataByDestination = {},
  listOfCountriesOpenForBuying = [],
  referencesList,
  loadingButton,
  checkoutStep,
  heightLimitProductList,
  thirdSectionListItems,
  totalSaved,
  itemsCart,
  dataCalculation
}: IcheckoutSummaryProps) => {
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();

  const { isPrime } = stateSubscription;
  const summaryHasShipmentsList = shipmentsList && shipmentsList.length > 0;
  const isStepOne = checkoutStep === 1;
  const isStepTwo = checkoutStep === 2;
  const isStepThree = checkoutStep === 3;
  const summaryHasReferencesListForStep3 =
    (isStepThree || isStepOne || isStepTwo) && referencesList && referencesList.length > 0;
  const translationKey = 'nodi:orderDetails:orderDetailsHeader:';

  const referencesPrices = (prices: IProductPrices) => {
    if (prices) {
      const referencePrice = Number(isPrime ? prices.subTotalWithPrime || 0 : prices.subTotalWithoutPrime || 0);
      return `${nudosFormatCurrency({ value: referencePrice, maximumFractionDigits: 2 })} USD`;
    }
    return '$0 USD';
  };

  const getSectionLines = (sectionListingElements: { [key: string]: string | number }, customClassName?: string) => {
    return Object.entries(sectionListingElements).map(([key, value], i) => {
      const customKeyStyles = key.toLowerCase().replaceAll(' ', '').trim() || '';
      return (
        <div className={`sectionLine ${customClassName || ''} ${customKeyStyles}`} key={`sectionLine${i}-${key}`}>
          <div className="left">{key}</div>
          <div className={`right ${value === t('recurrentWords:toBeCalculated') ? 'calcText' : ''}`}>{value}</div>
        </div>
      );
    });
  };

  const getListSectionLines = (sectionListingElements: ISummaryItem[], customClassName?: string) => {
    if (sectionListingElements && sectionListingElements?.length > 0) {
      const renderDiscontPlatinum =
        (!totalSaved && Number(totalSaved) === 0) ||
        (!itemsCart?.totalDiscountValue && Number(itemsCart?.totalDiscountValue) === 0);
      return (
        <>
          {sectionListingElements.map((item, index) => {
            const customKeyStyles = item?.name.toLowerCase().replaceAll(' ', '').trim() || '';
            const renderCustomStyles = item?.name === 'Total a pagar';

            if (!item?.hidden) {
              return (
                <div
                  className={`sectionLine ${customClassName || ''} ${customKeyStyles} ${
                    item?.isBold ? 'boldAndLargeTextInSecondLine' : ''
                  }`}
                  key={`sectionLine${index}-${item.name}-other-list`}
                >
                  <div className="flex items-center gap-2">
                    <span className={`${renderCustomStyles ? 'font-bold text-bord-subtitle text-content' : ''}`}>
                      {item?.name}
                    </span>
                    {item?.tooltip ? <div className="extraTooltipContainers">{item?.tooltip || <></>}</div> : <></>}
                  </div>
                  <div className={`right flex ${item?.toCalculate ? 'calcText' : ''}`}>
                    {isPrime && item?.showSecondaruValue && !renderDiscontPlatinum && (
                      <div className="secondaryValue">
                        <div className={`secondaryValueType `}>{item?.secondaryValue || `$0 USD`}</div>
                      </div>
                    )}
                    <div className={item.isBold ? 'mainValue' : ''}>{item?.mainValue}</div>
                  </div>
                </div>
              );
            }
          })}
          {isPrime && !renderDiscontPlatinum && (
            <>
              <div className="divider w-full h-1 bg-stroke flex mt-12 mb-12" />
              <PlatinumDiscountTooltip
                textDiscount="Descuento total de"
                totalSaved={
                  nudosFormatCurrency({
                    value: Number(totalSaved ?? itemsCart?.totalDiscountValue ?? ''),
                    maximumFractionDigits: 2
                  }) as number
                }
                showFontBold
                badgeValue="3% off"
              />
            </>
          )}
        </>
      );
    }
    return <></>;
  };

  const getShipmentsDataToDisplay = (shipmentData: IShipmentGroupedByDestinationLocationAndProductId) => {
    const countryFlag =
      listOfCountriesOpenForBuying.find(country => country?.id === shipmentData?.countryId)?.flag || '';
    const employeeName = `${shipmentData?.employee?.firstName || ''} ${shipmentData?.employee?.lastName || ''}`;
    const shipmentText = shipmentData?.employee
      ? truncateText(employeeName, 20)
      : shipmentData?.destinationIsOffice
      ? t('nodi:toolInternal:NodiDetailsHeader:offices')
      : t('nodi:toolInternal:NodiDetailsHeader:nudosWarehouse');
    const totalPriceData = shipmentData.unitPrice;
    const shipmentsForThisDestinationTotalValue =
      shipmentsPricingDataByDestination[shipmentData?.destinationLocationId || 0];
    const shipmentPriceText =
      totalPriceData >= lowerLimitForFreeShipping ? `${minimumShippingCost} USD` : `${costForPaidShipments}  USD`;
    const shipmentPriceTextOnlyValue =
      totalPriceData >= lowerLimitForFreeShipping ? `${minimumShippingCost}` : `${costForPaidShipments}`;
    const shipmentPriceValue =
      shipmentsForThisDestinationTotalValue < lowerLimitForFreeShipping ? `${costForPaidShipments}` : `0`;
    return { countryFlag, shipmentText, shipmentPriceText, shipmentPriceValue, shipmentPriceTextOnlyValue };
  };

  const locationPlace = (place: string, receiverName?: string) => {
    if (place === 'user' && receiverName) return receiverName || 'Empleado';
    if (place === 'office') return 'Oficinas';
    return '.bord Warehouse';
  };

  const getShipmentsListUI = () => {
    return dataCalculation?.map((item, index) => {
      return (
        <div className="sectionLine shipmentsInfo" key={`shipment-${index}-${item?.locationId}`}>
          <div className="left">
            {t(`${translationKey}shipmentsCount`)} {index + 1}
          </div>
          <div className="right">
            <div className="shipmentText calcText">{locationPlace(item.locationPlace, item?.receiverName)} - </div>
            <div className="shipmentPrice calcText">{item.shipmentValue} USD</div>
          </div>
        </div>
      );
    });
    /* 
    return shipmentsList?.map((item, index) => {
      const { shipmentText, shipmentPriceText, shipmentPriceTextOnlyValue } = getShipmentsDataToDisplay(item);
      return (
        <div className="sectionLine shipmentsInfo" key={`shipment-${index}-${item?.onlyShippingUid}`}>
          <div className="left">
            {t(`${translationKey}shipmentsCount`)} {index + 1}
          </div>
          <div className="right">
            <div className="shipmentText calcText">{shipmentText} - </div>
            <div className="shipmentPrice calcText">
              {isPrime && shipmentPriceTextOnlyValue === '10' ? '0 USD' : shipmentPriceText}{' '}
            </div>
          </div>
        </div>
      );
    });*/
  };
  const getCalcShipments = () => {
    return (
      <div className="sectionLine shipmentsInfo">
        <div className="left">{t(`${translationKey}otherShipments`)} </div>
        <div className="right">
          <div className="shipmentPrice">{t('recurrentWords:toBeCalculated')}</div>
        </div>
      </div>
    );
  };
  const getReferencesListForStep3 = () => {
    return (
      <div className="secondSection" style={{ maxHeight: heightLimitProductList ? `${heightLimitProductList}px` : '' }}>
        <div
          className={heightLimitProductList ? 'extraForHeigthLimits' : ''}
          style={{ maxHeight: heightLimitProductList ? `${heightLimitProductList - 16}px` : '' }}
        >
          <div className="headerReferences">
            <div className={`referencesName ${isStepOne || isStepTwo ? 'step1ReferencesName' : ''}`}>
              {t('recurrentWords:referencesName')}
            </div>
            <div className={`referencesQuantity ${isStepOne || isStepTwo ? 'step1ReferencesQuantity' : ''}`}>
              {t('recurrentWords:quantity')}
            </div>
            <div
              className={`referencesTotal ${isStepOne || isStepTwo ? 'step1ReferencesTotal' : ''} ${
                isStepThree ? 'longReferenceTotal' : ''
              }`}
            >
              Total
            </div>
          </div>
          {referencesList?.map((item, i) => {
            const subTotalWithPrimeLength = item?.subTotal?.toLocaleString()?.length ?? 0;

            const renderTooltipCrossedOutSubTotalWithoutPrime = (
              <NudosHoverText
                onlyIfTruncated
                customClassName="line-through leading-normal font-normal text-content-secondary text-navy-blue-600 mr-4  flex items-center content-center justify-end"
                text={`${nudosFormatCurrency({
                  value: Number(item?.prices?.subTotalWithoutPrime || 0),
                  maximumFractionDigits: 2
                })} USD`}
                charactersLimit={subTotalWithPrimeLength >= 7 ? 10 : 15}
              />
            );

            return (
              <div className="bodyReference" key={`referenceList${item.productId}${i}`}>
                <div className="boxBodyReference">
                  <div className="bodyName">{item.productName}</div>
                  <div className="bodyQuantity">
                    <div className="containerTextQuatity">
                      <div className="quantityText">{item.quantity}</div>
                    </div>
                  </div>
                  <div className="bodyTotal">
                    {Number(item?.prices?.unitPrice || 0) >= 170 &&
                      isPrime &&
                      renderTooltipCrossedOutSubTotalWithoutPrime}
                    <div className="text-content">
                      {Number(item?.subTotal || 0) >= 170 ? (
                        <div className="limitsNewTotal">{item?.prices ? referencesPrices(item.prices) : '$0 USD'}</div>
                      ) : (
                        <>
                          {`${nudosFormatCurrency({ value: Number(item?.subTotal || 0), maximumFractionDigits: 2 })}`}{' '}
                          USD
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={`checkoutSummary ${customClassName || ''}`}>
      <div className="firstSection">{getSectionLines(firstSectionListingElements)}</div>
      {summaryHasReferencesListForStep3 && getReferencesListForStep3()}
      {showShipmentsSection && (
        <div className="numberOfShipmentsSection">
          <div className="titleSection">{t('recurrentWords:shipments')}</div>
          <div className="textBlueSection">{t('recurrentWords:toBeCalculated')}</div>
        </div>
      )}
      <div className="secondSection">
        {(isStepTwo || isStepThree) && summaryHasShipmentsList && getShipmentsListUI()}
        {isStepTwo && isButtonDisabled && getCalcShipments()}
        {!isStepThree && secondSectionListingElements && getSectionLines(secondSectionListingElements)}
        {!isStepThree && secondSectionListItems && getListSectionLines(secondSectionListItems)}
        {boldAndLargeTextInSecondLine && getSectionLines(boldAndLargeTextInSecondLine, 'boldAndLargeTextInSecondLine')}
      </div>
      {thirdSectionListItems && thirdSectionListItems.length > 0 && (
        <div className="secondSection">
          {(isStepTwo || isStepThree) && thirdSectionListItems && getListSectionLines(thirdSectionListItems)}
        </div>
      )}

      <BordButton
        isLoading={loadingButton}
        label={buttonText}
        onClick={handleClickButton}
        customHeight="42"
        disabled={isButtonDisabled || loadingButton}
        customClassName="w-480"
      />
    </div>
  );
};

export default CheckoutSummary;

interface IcheckoutSummaryProps {
  firstSectionListingElements: { [key: string]: string | number };
  showShipmentsSection?: boolean;
  secondSectionListingElements?: { [key: string]: string | number };
  secondSectionListItems?: ISummaryItem[];
  thirdSectionListItems?: ISummaryItem[];
  boldAndLargeTextInSecondLine?: { [key: string]: string | number };
  shipmentsList?: IShipmentGroupedByDestinationLocationAndProductId[];
  shipmentsPricingDataByDestination?: { [key: string]: number };
  listOfCountriesOpenForBuying?: ICountryDetail[];
  referencesList?: IReferences[];
  buttonText: string;
  handleClickButton: () => void;
  isButtonDisabled: boolean;
  customClassName?: string;
  loadingButton?: boolean;
  checkoutStep: 1 | 2 | 3;
  itemsCart?: ICartInfo;
  heightLimitProductList?: number;
  totalSaved?: number;
  totalShippingDiscount?: number;
  dataCalculation?: ICalculateOfShipment[];
}

interface IReferences {
  productId: number;
  productName: string;
  quantity: number;
  countryFlag: string;
  subTotal?: string | number;
  unitPrice?: string | number;
  prices?: IProductPrices | null | undefined;
}
