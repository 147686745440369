import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10.422 6.256c-.936-.936-.987-1.938.936-3.861a.16.16 0 0 0-.033-.255c-1.728-.958-3.415-.737-4.837.685-1.334 1.335-1.611 2.903-.848 4.52a.15.15 0 0 1-.036.174A48.147 48.147 0 0 0 3.59 9.396a48.135 48.135 0 0 0-1.878 2.014c-.71.796-.862 2.035-.197 2.869.105.13.215.256.334.376.12.119.246.23.376.334.834.665 2.073.512 2.868-.198a48.151 48.151 0 0 0 2.014-1.877A48.242 48.242 0 0 0 8.985 10.9a.15.15 0 0 1 .174-.036c1.617.763 3.185.486 4.52-.849 1.376-1.376 1.627-3 .774-4.67a.106.106 0 0 0-.17-.025c-1.923 1.923-2.925 1.872-3.86.936Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconToolWrench = memo(ForwardRef);
export default IconToolWrench;
