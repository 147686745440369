import BordSettingIcon from '../../../../BordDesignSystem/BordIlustrationIcon/Icons/BordSettingIcon';
import './NudosToolAdditionalServicesZeroState.scss';

const NudosToolAdditionalServicesZeroState = ({ hasBeenInWarehouse }: { hasBeenInWarehouse: boolean }) => {
  const renderIlustration = !hasBeenInWarehouse && <BordSettingIcon />;
  const explanationTitle = hasBeenInWarehouse
    ? 'No se aplicaron servicios adicionales'
    : 'No se han aplicado servicios adicionales';
  const explanationText =
    'En este espacio podrás visualizar los servicios adicionales, como el wiping y las configuraciones, que posee esta herramienta.';
  const renderExplanationText = !hasBeenInWarehouse && explanationText;

  return (
    <div className="nudosToolAdditionalServicesZeroState">
      {renderIlustration}
      <div className={`${hasBeenInWarehouse ? 'explanationTitleDisable' : 'explanationTitleEnable'} `}>
        {explanationTitle}
      </div>
      <div className="explanationText">{renderExplanationText}</div>
    </div>
  );
};

export default NudosToolAdditionalServicesZeroState;
