import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BordButton,
  BordOneToneIcon,
  BordUploaderButton,
  BordStatus,
  BordCheckBox,
  BordPhoto
} from '../../../../../../components/BordDesignSystem';
import { NudosCareTag, NudosHoverText } from '../../../../../../components/NudosComponents';
import ProductPills from '../../../../../../components/ProductPills/ProductPills';
import { IassignedTool, Tany } from '../../../../../../types/global';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import CantManageTooltip from '../../../../../../components/CantManageTooltip/CantManageTooltip';
import {
  disableToolManagementBeforeDeliveryAlertText,
  logisticServiceInCourseAlertText
} from '../../../../../../utils/productDefinitions';
import { ToolSerial } from '../../../../../../components';
import './AssignedToolCard.scss';

export interface IassignedToolCardProps {
  assignedTool: IassignedTool;
  toolsToUnassign?: IassignedTool[];
  setToolsToUnassign?: React.Dispatch<React.SetStateAction<IassignedTool[]>>;
  inactiveCard?: boolean;
  isPossibleToUnassign?: boolean;
  updateFile?: (assignmentId: number, file: Tany) => void;
  loaderFileCardId?: number;
  toolInTransit?: boolean;
}

const AssignedToolCard = ({
  assignedTool,
  inactiveCard,
  toolsToUnassign,
  setToolsToUnassign,
  isPossibleToUnassign,
  updateFile,
  loaderFileCardId,
  toolInTransit
}: IassignedToolCardProps) => {
  const { t } = useTranslation();
  const { id: employeeId } = useParams<{ id: string }>();
  const [checked, setChecked] = useState(false);
  const [openCard, setOpenCard] = useState<boolean>(false);
  const AssignedToolCardLanguage = 'nodi:employeeInternal:employeeDetailsBody:assignedToolCard:';
  const pills = typeof assignedTool?.pills === 'string' ? JSON.parse(assignedTool?.pills) : assignedTool?.pills;
  const toolTimeZone = assignedTool?.country?.timeZone || undefined;
  const redirectionTranslationKey = `nodi:headBar:`;
  const recurrentWords = `recurrentWords:`;

  const toolHasntBeenDelivered =
    assignedTool?.referenceModel === 'CatalogProduct' &&
    (assignedTool?.shipmentStatusName || assignedTool?.statusName) !== 'Entregado';

  const logisticServiceInCourse =
    assignedTool?.logisticStatus &&
    assignedTool?.logisticStatus !== 'entregado' &&
    assignedTool?.logisticStatus !== 'cancelado';

  const orderDeliveryStatusActive = ['Confirmado', 'Proceso de entrega'].includes(
    assignedTool?.shipmentStatusName || ''
  );

  const [documentUrl, setDocumentUrl] = useState<string | null>(null);

  const orderToolInShippingProcess = orderDeliveryStatusActive && toolInTransit;

  const disabledCheckButtonStyles = logisticServiceInCourse || toolHasntBeenDelivered ? 'inactive' : '';
  const hasUnassignCheckbox = !inactiveCard && isPossibleToUnassign;

  const disabledToolManagementRedirectionText = toolHasntBeenDelivered && assignedTool?.orderId ? 'Ver Orden' : '';
  const disabledToolManagementRedirectionUrl =
    toolHasntBeenDelivered && assignedTool?.orderId
      ? `/nodi/orders/details/${assignedTool?.orderId}?from=employee-details&id=${employeeId}`
      : '';

  const toolAssignmentIsCompleted = !!assignedTool?.assignedSince && !logisticServiceInCourse;

  const assignedOrRequestAssignmentDateTitle = toolAssignmentIsCompleted
    ? t(`${AssignedToolCardLanguage}assignmentDate`)
    : t(`${AssignedToolCardLanguage}assignmentRequestDate`);

  const assignedOrRequestAssignmentDate = toolAssignmentIsCompleted
    ? assignedTool?.assignedSince
    : assignedTool?.movementRequestDate || '';

  const getDisableToolManagementAlertText = () => {
    if (toolHasntBeenDelivered) return disableToolManagementBeforeDeliveryAlertText;
    if (!toolHasntBeenDelivered && logisticServiceInCourse) return logisticServiceInCourseAlertText;
    return '';
  };

  const handleCheckUnassign = (event?: React.ChangeEvent<HTMLInputElement>) => {
    if (event) event.preventDefault();
    if (event) event.stopPropagation();
    if (!toolsToUnassign || !setToolsToUnassign) return;
    if (checked) {
      const newToolsToUnassign = [...toolsToUnassign].filter(tool => tool?.assignmentId !== assignedTool?.assignmentId);
      setToolsToUnassign(newToolsToUnassign);
    } else {
      const newToolsToUnassign = [...toolsToUnassign, assignedTool];
      setToolsToUnassign(newToolsToUnassign);
    }
    setChecked(!checked);
  };

  const downLoadDocument = (url: string) => window.open(url, '_blank');

  const logisticDetailsRedirection = {
    pathname: `/nodi/logistics-services/details/${assignedTool.logisticServiceId}`,
    state: {
      returnText: 'Empleado'
    }
  };

  const orderDetailsRedirection = `/nodi/orders/details/${assignedTool?.orderId}?from=employee-details&id=${employeeId}`;

  const toolDetailsRedirection = {
    pathname: `/nodi/details/${assignedTool.productId}`,
    state: {
      customReturnPath: `/nodi/employees/details/${employeeId}`,
      customNavigationTitle: t(`${redirectionTranslationKey}headerTextBold:employee`),
      customNavigationSubtitle: t(`${redirectionTranslationKey}headerTextRegular:productDetails`)
    }
  };

  const getStatusServicesColor = (status: string) => {
    switch (status) {
      case 'en proceso':
        return 'informative';
      case 'activo':
        return 'success';
      case 'cancelado':
        return 'danger';
      default:
        return 'default';
    }
  };

  const getStatusServicesText = (status: string) => {
    switch (status) {
      case 'en proceso':
        return t(`${AssignedToolCardLanguage}inProcess`);
      case 'activo':
        return t(`${AssignedToolCardLanguage}active`);
      case 'cancelado':
        return t(`${AssignedToolCardLanguage}cancelled`);
      default:
        return t(`${AssignedToolCardLanguage}toBeConfirm`);
    }
  };


  useEffect(() => {
    if (!toolsToUnassign) return;
    const shouldBeChecked = toolsToUnassign.some(tool => tool?.assignmentId === assignedTool?.assignmentId);
    shouldBeChecked && setChecked(true);
    !shouldBeChecked && setChecked(false);
  }, [toolsToUnassign]);

  useEffect(() => {
    if (assignedTool?.assngedDocumentUrl) setDocumentUrl(assignedTool?.assngedDocumentUrl);
  }, [assignedTool?.assngedDocumentUrl]);

  useEffect(() => {
    if (assignedTool?.assngedDocumentUrl) setDocumentUrl(assignedTool?.assngedDocumentUrl);
  }, [assignedTool?.assngedDocumentUrl]);

  return (
    <>
      <div
        className={`assignedToolCard ${openCard ? 'openExtraStyle' : ''} `}
        onClick={() => setOpenCard(prevState => !prevState)}
      >
        <section className="imageSection">
          {assignedTool.image && (
            <BordPhoto
              url={assignedTool.image}
              toolModelMarkerProps={{ customSize: 3.2, isBordTool: true }}
              imageProps={{ style: { width: '100%', borderRadius: '1.2rem' } }}
            />
          )}
        </section>
        <section className="productDetailsSection">
          <div className="nameAndBrand">
            <div className="name">
              <NudosHoverText text={assignedTool?.productName} charactersLimit={24} />
            </div>
          </div>
          <div className="pillsAndSerial">
            <ProductPills pills={pills} />
          </div>
        </section>
        <section className="acquisitionInfoSection">
          <div className="topText">
            <NudosCareTag nudosCareType={assignedTool?.nudosCareName} />
          </div>
          <div className="downText">
            <ToolSerial
              serial={assignedTool?.serial || t(`${recurrentWords}withoutSerial`)}
              charactersLimitForHover={18}
            />
          </div>
        </section>
        {!assignedTool?.logisticServiceId && orderToolInShippingProcess && (
          <section className="sectionStatusService">
            <p className="title">{t(`${AssignedToolCardLanguage}orderStatus`)}</p>
            <BordStatus
              variant={getStatusServicesColor(assignedTool?.shipmentStatusName ?? '')}
              renderJSX={
                <div className="contentStatus">
                  <span>{getStatusServicesText(assignedTool?.shipmentStatusName ?? '')}</span>
                  <span className="pipe">|</span>
                  <Link className="linkGo" to={orderDetailsRedirection}>
                    {t(`${recurrentWords}go`)}
                    <BordOneToneIcon variant="arrowRight" standardSize={12} />
                  </Link>
                </div>
              }
            />
          </section>
        )}
        {assignedTool?.logisticStatus && (
          <section className="sectionStatusService">
            <p className="title">{t(`${recurrentWords}statusService`)}</p>
            <BordStatus
              variant={getStatusServicesColor(assignedTool?.logisticStatus)}
              renderJSX={
                <div className="contentStatus">
                  <span>{getStatusServicesText(assignedTool?.logisticStatus)}</span>
                  <span className="pipe">|</span>
                  <Link className="linkGo" to={logisticDetailsRedirection}>
                    {t(`${recurrentWords}go`)}
                    <BordOneToneIcon variant="arrowRight" standardSize={12} />
                  </Link>
                </div>
              }
            />
          </section>
        )}
        <Link className="checkToolButton" to={toolDetailsRedirection}>
          {t(`${AssignedToolCardLanguage}containerMinFormCard:checkToolButton`)}
        </Link>

        <div className="containerIcon">
          {!hasUnassignCheckbox && (
            <BordButton
              label=""
              modeButton="tertiary"
              buttonIconOne={openCard ? 'arrowHeadUp' : 'arrowHeadDown'}
              onClick={() => setOpenCard(prevState => !prevState)}
              customClassName="unassignButton"
            />
          )}
        </div>
        <div className="checkboxButtonContainer">
          <div className="disabledManagementTooltipContainer">
            {hasUnassignCheckbox && (
              <BordCheckBox
                isButtonActive={checked}
                handleClickButton={(e?: React.ChangeEvent<HTMLInputElement>) => handleCheckUnassign(e)}
                isButtonDisabled={!!disabledCheckButtonStyles}
              />
            )}
            {!!disabledCheckButtonStyles && (
              <CantManageTooltip
                toolTipText={getDisableToolManagementAlertText()}
                toolTipStyles={{ top: '20px', right: '2px', width: '160px' }}
                redirectionText={disabledToolManagementRedirectionText}
                redirectionUrl={disabledToolManagementRedirectionUrl}
              />
            )}
          </div>
        </div>
      </div>
      {openCard && (
        <div className="containerUploadFile" onClick={() => setOpenCard(prevState => !prevState)}>
          {loaderFileCardId === assignedTool?.assignmentId ? (
            <>
              <div className="containerMinFormCard">
                <div className="containerInputFileCard">
                  <div className="titleLoader mb-1 animationLoader rounded" style={{ height: '10px' }} />
                  <div className="titleLoader animationLoader rounded" style={{ height: '38px' }} />
                </div>
              </div>
              <div className="containerMinButton animationLoader rounded" />
            </>
          ) : (
            <>
              <div className="containerMinFormCard">
                <div className="assignationDateSection">
                  {inactiveCard && assignedTool?.assignedUntil && (
                    <div className="unassignmentDate">
                      {t(`${AssignedToolCardLanguage}unassignmentDate`)}
                      <span>{formatShortDate(assignedTool.assignedUntil, toolTimeZone)?.textWithTimezone}</span>
                    </div>
                  )}
                  <div className="assignmentDate">
                    {assignedOrRequestAssignmentDateTitle}
                    <span>{`${formatShortDate(assignedOrRequestAssignmentDate, toolTimeZone)?.textWithTimezone}`}</span>
                  </div>
                </div>
                <div className="containerInputFileCard">
                  <BordUploaderButton
                    isFilled={!!assignedTool?.assngedDocumentUrl}
                    label={t(`${recurrentWords}assignmentDocument`)}
                    defaultFile={{
                      documentName: documentUrl ? t(`${recurrentWords}assignmentDocument`) : ''
                    }}
                    handleChangeFile={(file: File) => {
                      if (updateFile && assignedTool?.assignmentId) {
                        updateFile(assignedTool?.assignmentId, file);
                      }
                    }}
                    handleDeleteFile={() => {
                      if (updateFile && assignedTool?.assignmentId) {
                        setDocumentUrl(null);
                      }
                    }}
                    emptyBordUploaderButtonTitle={t(`${recurrentWords}theDocument`)}
                    emptyBordUploaderButtonSubtitle={t(`${recurrentWords}acceptedFormat`) + ' .pdf, .jpg'}
                    acceptFileExtension={['.pdf', '.jpg']}
                  />
                </div>
              </div>
              <div className="containerMinButton">
                <BordButton
                  onClick={() => {
                    if (assignedTool?.assngedDocumentUrl) downLoadDocument(assignedTool?.assngedDocumentUrl);
                  }}
                  customClassName="myMinButton"
                  disabled={!documentUrl}
                  label={t(`${AssignedToolCardLanguage}myMinButton`)}
                  buttonIconTwo="download"
                  modeButton="tertiary"
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AssignedToolCard;
