import { FormEvent } from 'react';
import { Tany } from '../../../types/global';
import BordPhoto from '../BordPhoto/BordPhoto';
import BordOneToneIcon from '../BordOneToneIcon/BordOneToneIcon';
import { bordColorTheme } from '../../../styles/constants';
import { TBordPhotoSize } from '../BordPhoto/BordPhoto.types';
import { TbordAvatarsVariant } from '../BordAvatar/BordAvatar.types';
import './BordPhotoUploader.scss';

const BordPhotoUploader = ({
  uploadedFileHandler,
  selectedImageUrl,
  isDisabled,
  showSkeletonLoader = false,
  bordPhotoSize = 's100',
  avatarPropsVariant = 'rectangularBordMascot'
}: IbordPhotoUploader) => {
  const handleChange = (event: FormEvent) => {
    const { files } = event.target as HTMLInputElement;
    if (!files) return;
    const uploadedFile = files[0];
    uploadedFileHandler(uploadedFile);
  };

  return (
    <div className={`bordPhotoUploader ${isDisabled ? 'disabled' : ''}`}>
      {!isDisabled && !showSkeletonLoader && <input className="fileInput" type={'file'} onChange={handleChange} />}
      {!showSkeletonLoader && (
        <BordOneToneIcon
          className="editIcon"
          stroke={bordColorTheme.content.default}
          variant="edit"
          standardSize={12}
        />
      )}
      <BordPhoto
        url={selectedImageUrl}
        variant="square"
        size={bordPhotoSize}
        avatarProps={{ variant: avatarPropsVariant, customWidth: '10rem' }}
        imageProps={{
          style: {
            borderRadius: '1.1rem',
            objectFit: 'cover',
            height: '100%',
            width: '100%',
            objectPosition: 'center center'
          }
        }}
        showSkeletonLoader={showSkeletonLoader}
      />
    </div>
  );
};

export default BordPhotoUploader;

export interface IbordPhotoUploader {
  uploadedFileHandler: TfunctionOneToVoid;
  selectedImageUrl: string;
  isDisabled?: boolean;
  showSkeletonLoader?: boolean;
  bordPhotoSize?: TBordPhotoSize;
  avatarPropsVariant?: TbordAvatarsVariant;
}

export type TfunctionOneToVoid = (param: Tany) => void;
