const SquareAngola = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M0 8h22v8H0V8Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M13.0439 10.2251c.4547-.62077.7229-1.3909.7229-2.22504 0-1.5648-.946-2.90453-2.287-3.46027l-.6338.90347c1.0832.32747 1.8731 1.34827 1.8731 2.5568 0 .6336-.2168 1.216-.5793 1.67254L8.52866 7.46673 11.353 10.3425c-.3719.2069-.7993.3242-1.2529.3242-.88001 0-1.65839-.4416-2.13296-1.11997l-.63381.90347c.67152.7861 1.66152 1.2832 2.76677 1.2832.7438 0 1.4373-.2261 2.0156-.6144l.6034.6144 1.0477-1.0667-.7229-.4416ZM8.52866 5.33339h1.04762v1.06667H8.52866V5.33339Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAngola;
