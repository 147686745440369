const SquarePakistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#0A6A30" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M16.7347 6.37227C16.6655 7.008 16.4686 7.46667 16.2381 7.46667C16.0076 7.46667 15.8107 7.008 15.7415 6.37227C15.1171 6.30187 14.6667 6.10133 14.6667 5.86667C14.6667 5.632 15.1171 5.43147 15.7415 5.36107C15.8107 4.72533 16.0076 4.26667 16.2381 4.26667C16.4686 4.26667 16.6655 4.72533 16.7347 5.36107C17.359 5.43147 17.8095 5.632 17.8095 5.86667C17.8095 6.10133 17.359 6.30187 16.7347 6.37227ZM0 0H6V16H0V0Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          fillRule="evenodd"
          d="M18.8259 7.43273C18.8468 7.6194 18.8573 7.8082 18.8573 8.0002C18.8573 10.6509 16.7463 12.8002 14.143 12.8002C11.5397 12.8002 9.42871 10.6509 9.42871 8.0002C9.42871 5.34953 11.5397 3.2002 14.143 3.2002C14.3316 3.2002 14.517 3.21086 14.7003 3.2322C12.9068 3.47753 11.5239 5.04233 11.5239 6.93353C11.5239 8.9954 13.1656 10.6669 15.1906 10.6669C17.0491 10.6669 18.5849 9.25886 18.8259 7.43273Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquarePakistan;
