import BordSidebarButton from '../../../BordSidebarButton/BordSidebarButton';
import { IbordSidebarModulesList } from '../../BordSidebar.types';
import './SidebarModulesList.scss';

const SidebarModulesList = ({ isOpen, modulesList, className = '' }: IbordSidebarModulesList) => {
  const classnames = `sidebarModulesList ${className}`;

  return (
    <div className={classnames}>
      {modulesList.map(sidebarModule => {
        const { buttonText } = sidebarModule;
        return <BordSidebarButton key={`${buttonText}`} isOpen={isOpen} {...sidebarModule} />;
      })}
    </div>
  );
};

export default SidebarModulesList;
