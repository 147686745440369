import { DTOGetUsersOrgDataResponse } from '../types/DTO';

export const getOrgData = () => {
  const _orgData = localStorage.getItem('orgData');
  const orgData = _orgData && JSON.parse(_orgData);
  return orgData as DTOGetUsersOrgDataResponse;
};

export const getSessionLogin = () => {
  const _userLogin = localStorage.getItem('sessionLogin');
  const userLogin = _userLogin && JSON.parse(_userLogin);
  return userLogin;
};

export const getPersonalData = () => {
  const _peronalData = localStorage.getItem('personalData');
  const peronalData = _peronalData && JSON.parse(_peronalData);
  return peronalData;
};

export const getLogisticsRequesterData = () => {
  const personalData = getPersonalData();
  const orgData = getOrgData();
  if (!personalData || !orgData) return;
  const logisticsRequesterData = {
    userId: orgData.userId,
    firstName: personalData.firstName,
    lastName: personalData.lastName,
    email: personalData.email,
    phoneData: {
      phone: personalData.phoneData.phone,
      phoneCode: personalData.phoneData.phoneCode
    }
  };
  return logisticsRequesterData;
};

const _orgData = localStorage.getItem('orgData');
export const orgData = _orgData && JSON.parse(_orgData);

export const getCurrentOrderShippingData = () => {
  const _currentOrderShippingData = localStorage.getItem('currentOrderShippingData');
  const currentOrderShippingData = _currentOrderShippingData && JSON.parse(_currentOrderShippingData);
  return currentOrderShippingData || undefined;
};
