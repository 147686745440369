import { BordButton } from '../../../../../../components/BordDesignSystem';
import './NoAvailableToolsModal.scss';

const NoAvailableToolsModal = ({
  handleGoToEcommerce,
  handleGoToToolsListing
}: {
  handleGoToEcommerce: () => void;
  handleGoToToolsListing: () => void;
}) => {
  return (
    <div className="noAvailableToolsModal">
      <div className="textContent">
        <p>No cuentas con herramientas disponibles para</p>
        <p>asignar. Puedes ir a la tienda a comprar una herramienta</p>
        <p>o reasignar alguna que estés usando.</p>
      </div>
      <BordButton customWidth="w-280" label="Ir a la tienda" onClick={handleGoToEcommerce} />
      <BordButton
        customWidth="w-280"
        modeButton="secondary"
        label="Ir a herramientas"
        onClick={handleGoToToolsListing}
      />
    </div>
  );
};

export default NoAvailableToolsModal;
