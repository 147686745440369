import { useTranslation } from 'react-i18next';
import { LOCATION_PLACE_TYPE } from '../types/global';

interface GetStringByPlaceParams {
  name: string;
  place: string;
}

export const getNameByPlace = ({ name, place }: GetStringByPlaceParams): string => {
  const { t } = useTranslation();
  const defaultName = name ?? 'Sin datos';
  const warehouseNudos = t('nodi:orderDetails:orderShipmentCard:warehouseNudos');
  const office = t('recurrentWords:office');

  const nameMap: { [key: string]: string } = {
    [LOCATION_PLACE_TYPE.NUDOS]: warehouseNudos,
    [LOCATION_PLACE_TYPE.WARRANTY]: warehouseNudos,
    [LOCATION_PLACE_TYPE.DIAGNOSTIC]: warehouseNudos,
    [LOCATION_PLACE_TYPE.OFFICE]: office,
    [LOCATION_PLACE_TYPE.USER]: defaultName,
    [LOCATION_PLACE_TYPE.OTHER]: defaultName,
    store: defaultName
  };
  if (place === 'nudos') {
    return nameMap[place] || defaultName;
  } else {
    return nameMap[name] || defaultName;
  }
};
