import { AxiosError, AxiosRequestConfig } from 'axios';
import { ISubscriptions, subscriptionSlackMessageBody } from '../types/subscriptions';
import { apiGet, apiPost } from './api';
import { orgAPI } from '../constants';

const getInfoSubscription = async (organizationId: number): Promise<ISubscriptions> => {
  return apiGet<AxiosRequestConfig, ISubscriptions>(`${orgAPI}/organization/check-subscriptions/${organizationId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

const sendSubscriptionSlackMessage = async (data: subscriptionSlackMessageBody): Promise<string> => {
  return apiPost(`${orgAPI}/platform-subscription/request-info`, data)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export { getInfoSubscription, sendSubscriptionSlackMessage };
