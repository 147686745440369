import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      stroke={props?.color || '#383838'}
      strokeLinejoin="round"
      d="m1.93 11.977 4.514-9.214c.836-1.707 3.269-1.707 4.105 0l4.513 9.214c.198.403.355.856-.047 1.177-1.894 1.51-11.144 1.51-13.037 0-.403-.321-.247-.77-.047-1.177Z"
    />
    <path
      stroke={props?.color || '#FB0'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.5 5.714V8.57m0 2.571v.286"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconWarningm = memo(ForwardRef);
export default IconWarningm;
