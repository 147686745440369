import { useEffect, useState } from 'react';
import { NudosBreadcrumbButton } from '../../../../../../components/NudosComponents';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { ICountryDetail } from '../../../../../../types/countries';
import { useTranslation } from 'react-i18next';
import { BordButton, BordTextInput } from '../../../../../../components/BordDesignSystem';
import BordPhoneInput from '../../../../../../components/BordDesignSystem/BordPhoneInput/BordPhoneInput';
import useSignStore from '../../../../state/useSignStore';
import { getNewRegisteredData, getUserNameError, getUserPhoneError } from '../../../../signUp.utils';
import BordEmployeeAreaDropdown from '../../../../../../components/DesignSystem/BordEmployeeAreaDropdown/BordEmployeeAreaDropdown';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';
import { updateRegisterFormData } from '../../../../../../services/signUp.service';

const SignUpFormStep2 = ({ countriesList = [], setFormStep }: IsignUpFormStep2Params) => {
  const { t } = useTranslation();
  const {
    userFirstName,
    userLastName,
    userPhone,
    userPhoneCountry,
    userArea,
    setUserFirstName,
    setUserLastName,
    setUserPhone,
    setUserPhoneCountry,
    setUserArea
  } = useSignStore();

  const [loading, setloading] = useState(false);
  const translationKey = 'authentication:SignUpForm:formFieldsStep2:';

  const newRegisterData = getNewRegisteredData();
  const firstNameError = getUserNameError(userFirstName);
  const lastNameError = getUserNameError(userLastName);
  const userPhoneError = getUserPhoneError(userPhone, userPhoneCountry);

  const returnToPreviousStep = () => setFormStep(0);
  const isButtonDisabled = () => {
    if (!userFirstName || !userLastName || !userPhone || !userPhoneCountry || !userArea) return true;
    if (firstNameError || lastNameError || userPhoneError) return true;
    return false;
  };

  const handleAdvanceStep = async () => {
    if (isButtonDisabled()) return;
    segmentTrackEvent({
      signupSecondStepClick: {
        ContactNumber: `${userPhoneCountry?.phoneCode || ''} ${userPhone || ''}`,
        Name: userFirstName || '',
        RoleName: userArea || '',
        Surname: userLastName || ''
      }
    });
    setloading(true);
    const body = {
      firstName: userFirstName,
      lastName: userLastName,
      position: userArea,
      phone: userPhone,
      countryPhoneId: userPhoneCountry?.id
    };
    try {
      const response = await updateRegisterFormData(body, newRegisterData.id);
      if (response) {
        const newRegisterInformation = JSON.stringify(response);
        sessionStorage.setItem('newRegisterInformation', newRegisterInformation);
        setFormStep(2);
      } else {
        const updatedRegisterInformation = { ...newRegisterData, ...body };
        const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
        sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
        setFormStep(2);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    segmentTrackEvent({ signupSecondStepView: null });
  }, []);

  return (
    <div>
      <BordTextInput
        className="formLine"
        label={t(`${translationKey}firstName`)}
        style={{ width: '40rem' }}
        placeholder={'Pedro'}
        currentText={userFirstName}
        setCurrentText={setUserFirstName}
        errorText={firstNameError}
      />
      <BordTextInput
        className="formLine"
        label={t(`${translationKey}lastName`)}
        style={{ width: '40rem' }}
        placeholder={'Pérez'}
        currentText={userLastName}
        setCurrentText={setUserLastName}
        errorText={lastNameError}
      />
      <BordEmployeeAreaDropdown
        customClassname="formLine"
        componentSize="w-400"
        currentValue={userArea}
        updateCurrentValueCallback={setUserArea}
        customAreaLabel={t(`${translationKey}areaOfWork`)}
        customAreaPlaceholder="RRHH"
      />
      <BordPhoneInput
        personalClass="phoneInputStyles"
        inputCustomWidth="31.6rem"
        label={t(`${translationKey}phone`)}
        placeholder={t(`${translationKey}phone`)}
        countriesList={countriesList}
        handlePhoneChange={setUserPhone}
        defaultCountryData={userPhoneCountry}
        defaultPhoneNumber={userPhone}
        handleCountryPhoneChange={setUserPhoneCountry}
        errorText={userPhoneError}
      />
      <div className="buttonsContainer step2">
        <NudosBreadcrumbButton returnText={t(`${translationKey}goBackButton`)} returnAction={returnToPreviousStep} />
        <BordButton
          label={t(`${translationKey}continueButton`)}
          disabled={isButtonDisabled()}
          onClick={handleAdvanceStep}
          customWidth="w-280"
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default SignUpFormStep2;

export interface IsignUpFormStep2Params {
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  countriesList: ICountryDetail[];
  updatePhoneCountryCallback: (e: ICountryDetail | undefined) => void;
  phoneCountry?: ICountryDetail;
}
