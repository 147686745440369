import create from 'zustand';
import { ImoduleFilter } from '../types/global';

export interface IlogisticsServicesModuleState {
  logisticsServicesFilters: ImoduleFilter[];
  logisticsServicesNumber?: number;
  setLogisticsServicesFilters: (logisticsServicesFilters: ImoduleFilter[]) => void;
  setLogisticsServicesNumber: (logisticsServicesNumber: number) => void;
}

export const useLogisticsServicesModuleState = create<IlogisticsServicesModuleState>(set => ({
  logisticsServicesFilters: [],
  logisticsServicesNumber: undefined,
  setLogisticsServicesFilters: logisticsServicesFilters => set({ logisticsServicesFilters }),
  setLogisticsServicesNumber: logisticsServicesNumber => set({ logisticsServicesNumber })
}));
