import { useTranslation } from 'react-i18next';
import { NudosBillingCountryDropdown, NudosBillingDataFormModal } from '../../../../../../../components/DesignSystem';
import { BordButton } from '../../../../../../../components/BordDesignSystem';

import { IbillingData } from '../../../../../../../types/checkout';
import {
  getOrganizationBillingDataForAllItsCountries,
  getOrganizationLastBillingData
} from '../../../../../../../services/account';
import { useEffect, useState } from 'react';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../../../../../utils/getLocalStorageData';
import { ICountryDetail } from '../../../../../../../types/countries';
import useCheckoutStore from '../../../../state';
import { useBillingData } from '../../../../../../../hooks/useBillingData';
import NudosBillingDataSummaryComponent from '../../../../../../../components/DesignSystem/BillingDataSummary/NudosBillingDataSummaryComponent';
import { TbilingDataByCountryMap } from '../../../../../../../types/account';
import useStoreCountryState from '../../../../../../../state/useStoreCountryState';
import './BillingDataSection.scss';

function BillingDataSection({ completeListOfCountries }: { completeListOfCountries?: ICountryDetail[] }) {
  const { t } = useTranslation();
  const orgData = getOrgData();
  const { storeCountry } = useStoreCountryState();
  const { checkoutBillingData, setCheckoutBillingData } = useCheckoutStore();
  const { isDataIncomplete, billingData, setBillingData } = useBillingData({});

  const [showBillingDataModal, setShowBillingDataModal] = useState(false);
  const [organizationBillingData, setOrganizationBillingData] = useState<IbillingData[]>([]);
  const [billingDataByCountryMap, setBillingDataByCountryMap] = useState<TbilingDataByCountryMap>(new Map());
  const [loadingOrgBillingData, setLoadingOrgBillingData] = useState(true);
  const [loadingBillingDataByCountry, setLoadingBillingDataByCountry] = useState(true);
  const [loadingLastBillingData, setLoadingLastBillingData] = useState(true);
  const [openBillingCountryDropdown, setOpenBillingCountryDropdown] = useState<boolean>(false);
  const [billingDataId, setBillingDataId] = useState<number>();
  const [billingDataForAllCountries, setBillingDataForAllCountries] = useState<IbillingData[]>([]);

  const translationKey = 'nodi:billingDataSection:';
  const invoiceTitle = t(`${translationKey}billing`);
  const addBillingButtonText = t(`${translationKey}addBillingButtonText`);
  const billingDataDropdownText = t(`${translationKey}billingDataDropdownText`);
  const validateBillingDataText = t(`${translationKey}validateBillingDataText`);
  const closeBillingDataModalText = t(`${translationKey}closeBillingDataModalText`);

  const hasBillingDataForSomeCountry = organizationBillingData && !!organizationBillingData.length;

  const showBillingDataDropdown =
    !loadingOrgBillingData &&
    !checkoutBillingData &&
    hasBillingDataForSomeCountry &&
    !loadingBillingDataByCountry &&
    !loadingLastBillingData;
  const showAddBillingButton =
    !checkoutBillingData &&
    !hasBillingDataForSomeCountry &&
    !loadingOrgBillingData &&
    !loadingBillingDataByCountry &&
    !loadingLastBillingData;
  const showBillinDataSummary =
    checkoutBillingData && !loadingOrgBillingData && !loadingBillingDataByCountry && !loadingLastBillingData;
  const incompleteBillingData = !!billingData && !!checkoutBillingData && isDataIncomplete();
  const displayLoader = loadingOrgBillingData || loadingBillingDataByCountry || loadingLastBillingData;

  const availableCountries = completeListOfCountries?.filter(country => {
    const thisCountryBillingData = billingDataByCountryMap.get(country.code);
    return !thisCountryBillingData || !!thisCountryBillingData?.canCreateNew;
  });

  const getBillingDataByCountryId = (countryId: number) => {
    const organizationByCountryId = organizationBillingData?.find(
      organization => organization?.countryId === countryId
    );
    if (!organizationByCountryId) return;
    updateBillingCountryDropdown(organizationByCountryId);
  };

  const submitModalCallback = (newData?: IbillingData) => {
    if (!newData || !newData?.documentCountry?.id) return;
    getOrganizationBillingData();
    getBillingDataByCountryId(newData?.documentCountry?.id);
    setBillingDataId(newData?.documentCountry?.id);
    setShowBillingDataModal(false);
  };

  const setBillingDataWhenJustOneExist = () => {
    if (organizationBillingData && organizationBillingData?.length === 1)
      updateBillingCountryDropdown(organizationBillingData[0]);
  };

  const getOrganizationBillingData = async () => {
    if (!orgData?.organizationId) return;
    setLoadingOrgBillingData(true);
    try {
      const response = await getOrganizationBillingDataForAllItsCountries(orgData?.organizationId);
      const organizationBillingDataEntries = Object.entries(response);
      const countriesBillingDataMap = new Map(organizationBillingDataEntries);
      const billingDataEntitiesByCountry = Object.values(response).map(
        countryBillingData => countryBillingData.billingEntities
      );
      const allOrganizationBillingEntities = billingDataEntitiesByCountry.flat();
      setOrganizationBillingData(allOrganizationBillingEntities);
      setBillingDataByCountryMap(countriesBillingDataMap);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOrgBillingData(false);
    }
  };

  const cleanUpdatedBillingDataFunction = () => {
    setCheckoutBillingData(undefined);
    setOpenBillingCountryDropdown(true);
  };

  const updateBillingCountryDropdown = (newValue: IbillingData) => {
    setBillingData(newValue);
    setCheckoutBillingData(newValue);
  };

  const validateBillingData = () => {
    if (incompleteBillingData) {
      setShowBillingDataModal(true);
      displayErrorNotification({ customJSXMessage: <>{validateBillingDataText}</> });
    }
  };

  const closeBillingDataModalCallback = () => {
    if (incompleteBillingData) {
      displayErrorNotification({
        customJSXMessage: <>{closeBillingDataModalText}</>
      });
      cleanUpdatedBillingDataFunction();
    }
    setShowBillingDataModal(false);
  };

  const getLastBillingData = async () => {
    if (!storeCountry?.countryCode) return;
    setLoadingLastBillingData(true);
    try {
      const reponse = await getOrganizationLastBillingData(orgData?.organizationId, storeCountry?.countryCode);
      if (!reponse) return;
      const lastBillingData = billingDataForAllCountries?.find(
        billingData => billingData?.id === reponse?.billingDataId
      );
      if (!lastBillingData) return;
      setBillingData(lastBillingData);
      setCheckoutBillingData(lastBillingData);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingLastBillingData(false);
    }
  };

  const getAllOrganizationBillingDataByCountry = async () => {
    if (!orgData?.organizationId) return;
    setLoadingBillingDataByCountry(true);
    try {
      const response = await getOrganizationBillingDataForAllItsCountries(orgData?.organizationId);
      const billingDataEntitiesByCountry = Object.values(response).map(
        countryBillingData => countryBillingData.billingEntities
      );
      const allOrganizationBillingEntities = billingDataEntitiesByCountry.flat();
      setBillingDataForAllCountries(allOrganizationBillingEntities);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingBillingDataByCountry(false);
    }
  };

  useEffect(() => {
    getLastBillingData();
  }, [billingDataForAllCountries]);

  useEffect(() => {
    getAllOrganizationBillingDataByCountry();
  }, []);

  useEffect(() => {
    validateBillingData();
  }, [billingData, checkoutBillingData]);

  useEffect(() => {
    if (billingDataId) getBillingDataByCountryId(billingDataId);
  }, [organizationBillingData, billingDataId]);

  useEffect(() => {
    getOrganizationBillingData();
    setBillingDataWhenJustOneExist();

    return () => setCheckoutBillingData(undefined);
  }, []);

  return (
    <div className="billingDataSection">
      <div className="invoiceTitle">{invoiceTitle}</div>
      {displayLoader && (
        <div className="billingDataSectionSkeleton">
          <div className="content animationLoader" />
        </div>
      )}
      {showBillingDataModal && (
        <NudosBillingDataFormModal
          customZIndex={79}
          initialBillingData={checkoutBillingData}
          closeModalCallback={closeBillingDataModalCallback}
          submitModalCallback={submitModalCallback}
          availableCountries={availableCountries || []}
        />
      )}
      {showAddBillingButton && (
        <BordButton
          label={addBillingButtonText}
          buttonIconOne="arrowRight"
          onClick={() => setShowBillingDataModal(true)}
          customClassName="invoiceButton"
        />
      )}
      {showBillingDataDropdown && (
        <NudosBillingCountryDropdown
          openDropdown={openBillingCountryDropdown}
          componentSize="w-380"
          customPlaceholder={billingDataDropdownText}
          countriesList={completeListOfCountries || []}
          currentValue={checkoutBillingData}
          billingDataForAllCountriesProp={organizationBillingData}
          updateCurrentValueCallback={updateBillingCountryDropdown}
          addNewBillingCountryCallback={() => setShowBillingDataModal(true)}
        />
      )}
      {showBillinDataSummary && (
        <NudosBillingDataSummaryComponent
          billingData={checkoutBillingData}
          openBillingDataModal={() => setShowBillingDataModal(true)}
          cleanUpdatedBillingData={cleanUpdatedBillingDataFunction}
        />
      )}
    </div>
  );
}

export default BillingDataSection;
