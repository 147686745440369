const SquareMorocco = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#AF010D" rx="2" />
        <path
          fill="#249F58"
          fillRule="evenodd"
          d="M11 9.58945L9.46003 10.4108L9.75336 8.66678L8.50879 7.42945L10.23 7.17558L11 5.58838L11.77 7.17451L13.4913 7.43051L12.2446 8.66571L12.5379 10.4108"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMorocco;
