import { v4 as uuid } from 'uuid';
import { truncateText } from '../../utils/truncateText';
import './EmployeePills.scss';

const EmployeePills = ({ pills, isActive }: { pills: { [key: string]: string }; isActive: boolean }) => {
  const pillsArr = Object.entries(pills);
  return (
    <div className="employeePills">
      {pillsArr &&
        pillsArr.length > 0 &&
        pillsArr.map(([key, value], i) => {
          return (
            <div key={uuid()} className={`pill pill${i + 1} ${!isActive ? 'disabledUser' : ''}`}>
              <div className="pillText">{truncateText(value, 14)}</div>
              <div className="hoverContainer">
                <div className="hoverKey">{key ? key : 'Propiedad'}:</div>
                <div className="hoverValue">{value ? value : 'Valor'}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default EmployeePills;
