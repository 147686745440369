import { AxiosError, AxiosRequestConfig } from 'axios';
import { apiGet, apiPost, apiPut } from './api';
import { fileGeneratorAPI, subscriptionAPI, usersAPI } from '../constants';
import {
  DTOlogisticsServiceInListingData,
  IAproximateDays,
  IIsAutomatable,
  IIsAutomatableData,
  ILogisticQuote,
  ILogisticQuoteGeneralData,
  ILogisticServiceQuotePdfResponse,
  IRejectedLogisticService,
  IUpdateLogisticServiceAddress,
  IlogisticServiceDetailedData,
  TlogisticServiceDocumentType
} from '../types/logisticsServicesModule.types';
import {
  DTOpostAssignmentOrUnassignmentBody,
  IlocationAddressForLogistics,
  IwarehouseData
} from '../types/requestLogisticsModule';
import { TstoragePlace } from '../types/assignationFluxes';
import { IBillingDataId } from '../types/checkout';

/**
 * @param requestKey The request key used for useSWR in the form "orgId?filters=[]" -> "123?filters=[{"name":"status","includes":"in","values":["entregado"]},{"name":"origin","includes":"in","values":["mx"]},{"name":"destination","includes":"in","values":["mx"]}]""
 * @returns The history of logistics services for the organization (as identified by the organizationId passed in the key), filtered according to the filteres passed as part of the key. If no filters are passed it corresponds to the complete history of logistics services requested by the organization
 */
export const getLogisticsServices = async (requestKey?: string) => {
  return apiGet<AxiosRequestConfig, DTOlogisticsServiceInListingData[]>(
    `${subscriptionAPI}/logistic-service/by-organization/${requestKey}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

/**
 * @param logisticServiceId - The id of the logistic service whose data will be obtained
 * @returns The detailed data of the logistic service with the corresponding id
 */
export const getLogisticServiceDetailsById = async (logisticServiceId: number | string) => {
  return apiGet<AxiosRequestConfig, IlogisticServiceDetailedData>(
    `${subscriptionAPI}/logistic-service/by-id/${logisticServiceId}`
  )
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
};

export const updateLogisticServiceAddress = async (
  logisticServiceId: number | string,
  location: string,
  body: IUpdateLogisticServiceAddress
) => {
  return apiPut<AxiosRequestConfig, IUpdateLogisticServiceAddress>(
    `${subscriptionAPI}/logistic-service/edit-address/${logisticServiceId}/${location}`,
    body
  )
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
};

export const updateLogisticServiceBillingData = async (logisticServiceId: number | string, formData: FormData) => {
  return apiPut<AxiosRequestConfig, FormData>(
    `${subscriptionAPI}/logistic-service/billing-data/${logisticServiceId}`,
    formData
  )
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
};

export const requestLogisticServiceCancelation = async (logisticServiceId: number | string) => {
  return apiPut<AxiosRequestConfig, number | string>(
    `${subscriptionAPI}/logistic-service/cancellation-request/${logisticServiceId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

/**
 * @param logisticServiceId - The id of the logistic service to which the uploaded document belongs to
 * @param typeOfDocument - The type of the document that will be uploaded
 * @param formData - A form data with the file property incuding the document File object
 */
export const uploadLogisticServiceDocument = async (
  logisticServiceId: number | string,
  typeOfDocument: TlogisticServiceDocumentType,
  formData: FormData
) => {
  return apiPut<AxiosRequestConfig, FormData>(
    `${subscriptionAPI}/logistic-service/save-document/${logisticServiceId}/${typeOfDocument}`,
    formData
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getNudosWarehousesListIncludingAdditionalServices = async (
  orgId: number | string,
  countryId?: number | string
) => {
  const specificWarehouseQuery = countryId ? `?countryId=${countryId}` : '';
  return apiGet<AxiosRequestConfig, IwarehouseData[]>(
    `${subscriptionAPI}/logistic-service/warehouse/info/${orgId}${specificWarehouseQuery}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const aproximateDays = async (countryCode: string) => {
  return apiGet<AxiosRequestConfig, IAproximateDays>(`${subscriptionAPI}/logistic-approximate-time/${countryCode}`)
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
};

export const getProductLocationById = async (locationId: string | number) => {
  return apiGet<AxiosRequestConfig, IlocationAddressForLogistics>(
    `${subscriptionAPI}/product-location/by-id/${locationId} `
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getLastBillingDataIdUsedInLS = async ({
  orgId,
  countryCode
}: {
  orgId: string | number;
  countryCode: string;
}) => {
  return apiGet<AxiosRequestConfig, IBillingDataId>(
    `${subscriptionAPI}/logistic-service/last-billing-data/${orgId}?countryCode=${countryCode}`
  )
    .then(response => Promise.resolve(response?.billingDataId))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const postAssignmentOrUnassignment = async (
  isAssignment: boolean,
  requiresLogistics: boolean,
  sendMail: boolean,
  body: DTOpostAssignmentOrUnassignmentBody,
  File?: File
): Promise<number> => {
  const bodyJson = JSON.stringify(body);
  const formData = new FormData();
  formData.append('body', bodyJson);
  File && formData.append('file', File);
  const isAssignmentParam = `isAssignment=${isAssignment}`;
  const requiresLogisticsParam = `requiresLogistics=${requiresLogistics}`;
  const sendMailParam = `sendMail=${Number(sendMail)}`;
  return apiPost<AxiosRequestConfig, FormData>(
    `${subscriptionAPI}/product-management?${isAssignmentParam}&${requiresLogisticsParam}&${sendMailParam}`,
    formData
  )
    .then(response => Promise.resolve(response?.id ? response?.id : response?.data?.id))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateNudosTypeGetMoreInformation = async (
  userId: string | number,
  nudosType: string
): Promise<string> => {
  return apiPut<string, string>(`${usersAPI}/user/tutorial/button/${userId}/${nudosType}`) //CAMBIAR JD - POR LA RUTA QUE BACK ESCOJA
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const generateLogisticsQuoteData = async (body: ILogisticQuote): Promise<ILogisticQuoteGeneralData> => {
  return apiPost<ILogisticQuote, ILogisticQuote>(`${subscriptionAPI}/product-management/logistic-service-quote`, body)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const rejectedLogisticService = async (body: IRejectedLogisticService): Promise<IRejectedLogisticService> => {
  return apiPut<IRejectedLogisticService, IRejectedLogisticService>(
    `${subscriptionAPI}/logistic-service/rejected`,
    body
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const approveOrRejectLogisticQuote = async (
  logisticServiceId: number | string,
  approveOrReject: 'approved' | 'rejected'
) => {
  return apiGet<AxiosRequestConfig, AxiosRequestConfig>(
    `${subscriptionAPI}/logistic-service/manual-quote/approval/${logisticServiceId}/${approveOrReject}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const generateLogisticsQuoteDataPdf = async (
  body: ILogisticQuoteGeneralData
): Promise<ILogisticServiceQuotePdfResponse> => {
  return apiPost<AxiosRequestConfig, ILogisticQuoteGeneralData>(
    `${fileGeneratorAPI}/generator/pdf/logistic-service-quote`,
    body
  )
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const getWarehouseLocation = async (countryId: number, place: TstoragePlace) => {
  return apiGet<AxiosRequestConfig, IlocationAddressForLogistics>(
    `${subscriptionAPI}/product-location/warehouse/?place=${place}&countryId=${countryId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const isAutomatable = async (body: IIsAutomatable): Promise<IIsAutomatableData> => {
  return apiPost<ILogisticQuote, IIsAutomatable>(`${subscriptionAPI}/product-management/is-automatable`, body)
    .then(response => {
      return Promise.resolve(response.data);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export const checkAddressChanged = async (logisticServiceId: number, location: string) => {
  return apiPut<AxiosRequestConfig, string>(
    `${subscriptionAPI}/logistic-service/address-change-viewed/${logisticServiceId}/${location}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const markAdditionalServiceNotCompletedAlertViewed = async (
  logisticAdditionalServiceProductId: number | string
) => {
  return apiPut<AxiosRequestConfig, void>(
    `${subscriptionAPI}/logistic-additional-service/status-log/viewed/${logisticAdditionalServiceProductId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
