import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BordEmptyState from '../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import { handleClickForHelp } from '../../../../../utils/global.utils';
import { IBordIlustrationIconProps } from '../../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustration.types';
import './ZeroOrders.scss';

const ZeroOrders = ({ hasFiltersApplied }: { hasFiltersApplied: boolean }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const recurrentWords = `recurrentWords:`;
  const translationKey = 'nodi:orderDetails:orderZeroState:';

  const title = hasFiltersApplied ? t(`${recurrentWords}noSearchOption`) : t(`${translationKey}noPreviousOrders`);
  const labelButton1 = hasFiltersApplied ? t(`${recurrentWords}supportButton`) : t(`${recurrentWords}goToStore`);
  const subtitle = hasFiltersApplied
    ? t(`${recurrentWords}noFilteredResultsSubtitle`)
    : t(`${translationKey}makeFirstOrder`);

  const bordButtonStyle = hasFiltersApplied ? 'outlined' : 'filled';
  const buttonIcon = hasFiltersApplied ? 'helpCircle' : 'shoppingCart';
  const ilustrationIconProps = hasFiltersApplied
    ? {}
    : { ilustrationIconProps: { variant: 'marketPlace', standardSize: 100 } as IBordIlustrationIconProps };

  const noFiltersOnClicked = () => push('/catalogue/home');
  const onClickButton1 = hasFiltersApplied ? handleClickForHelp : noFiltersOnClicked;

  return (
    <BordEmptyState
      customClassNameBordEmptyStateContainer="zeroOrders"
      title={title}
      subTitle={subtitle}
      buttonOneProps={{
        label: labelButton1,
        onClick: onClickButton1,
        modeButton: 'primary',
        bordButtonStyle,
        customWidth: 'w-280',
        buttonIconTwo: buttonIcon
      }}
      {...ilustrationIconProps}
    />
  );
};

export default ZeroOrders;
