import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import { TproductTechnicalDetails } from '../../../../../types/global';
import { useTranslation } from 'react-i18next';

import './ProductSpecs.scss';

interface IProductSpecs {
  specs?: TproductTechnicalDetails | null;
}

const ProductSpecs: FC<IProductSpecs> = ({ specs }: IProductSpecs) => {
  const { t } = useTranslation();

  if (!specs) {
    return <></>;
  }

  return (
    <div id="productSpecs">
      <div className="technicalSpecificationsContainer">
        <div className="firstColumn">
          {specs &&
            Object.entries(specs).length > 0 &&
            Object.entries(specs).map(([key, value], i) => {
              if (value && i < 4)
                return (
                  <span key={uuid()} className="detailContainer">
                    <p className="detailName">{t(`services:specs:${key}`)}</p>
                    <p className="detailValue">{value}</p>
                  </span>
                );
            })}
        </div>
        <div className="spaceLine" />
        <div className="secondColumn">
          {specs &&
            Object.entries(specs).length > 0 &&
            Object.entries(specs).map(([key, value], i) => {
              if (value && i >= 4)
                return (
                  <span key={uuid()} className="detailContainer">
                    <p className="detailName">{t(`services:specs:${key}`)}</p>
                    <p className="detailValue">{value}</p>
                  </span>
                );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductSpecs;
