const SquareSlovenia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path fill="#F93939" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="white" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
          <path
            fill="#F93939"
            d="M6.80985 3.2002H4.71461C4.13603 3.2002 3.66699 3.67776 3.66699 4.26686V6.93353C3.66699 7.52263 4.13603 8.0002 4.71461 8.0002H6.80985C7.38843 8.0002 7.85747 7.52263 7.85747 6.93353V4.26686C7.85747 3.67776 7.38843 3.2002 6.80985 3.2002Z"
          />
          <path
            fill="#1A47B8"
            d="M6.28567 3.2002H5.23805C4.65946 3.2002 4.19043 3.67776 4.19043 4.26686V6.4002C4.19043 6.9893 4.65946 7.46686 5.23805 7.46686H6.28567C6.86425 7.46686 7.33329 6.9893 7.33329 6.4002V4.26686C7.33329 3.67776 6.86425 3.2002 6.28567 3.2002Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M5.23828 4.2666H6.2859V5.33327H5.23828V4.2666Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M4.19043 6.40003L5.76186 5.8667L7.33329 6.40003L6.28567 7.4667H5.23805L4.19043 6.40003Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSlovenia;
