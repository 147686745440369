import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NudosEmployeeSearchBar, NudosSelectDropdown } from '../../../../../../../components/NudosComponents';
import { Iemployee } from '../../../../../../../types/global';
import { ICartShipping, IProductsCart } from '../../../../../../../types/cart';
import { ShipToEmployeeModalFlux } from '..';
import { isEmployeeDataComplete } from '../../../../../../../utils/productDefinitions';
import { IWarehouse, IorganizationOfficeData } from '../../../../../../../types/account';
import { checkIfOfficeAddressDataIsComplete } from '../../../../checkout.utils';
import { getEmployeeById } from '../../../../../../../services/employees.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../../../../utils/displayNudosStandardNotifications';
import useStateSubscriptions from '../../../../../../../state/useStateSubscriptions';
import { ICountryDetail } from '../../../../../../../types/countries';
import { ShippingOptions } from './Components';
import { IShippingOption } from './Components/ShippingOptions/ShippingOptions';
import { BordOneToneIcon } from '../../../../../../../components/BordDesignSystem';
import { bordColorTheme } from '../../../../../../../styles/constants';
import './ReferenceOptions.scss';

interface IReferenceOptions {
  optionsSelect?: IShippingOption[];
  openAddOfficeAddress: (getOfficeAddressCallback?: (data?: IorganizationOfficeData) => void) => Promise<void> | void;
  externalIndex: number;
  defaultOfficeAddress?: boolean;
  setEveryOneOffice?: (check: boolean) => void;
  officeAddress?: IorganizationOfficeData;
  productInfo?: IProductsCart;
  handleShippingReference?: (
    type: 'employee' | 'office' | 'nudos',
    employeeInfo?: Iemployee,
    officeData?: IorganizationOfficeData,
    storeNudosData?: IWarehouse,
    manuallySelected?: boolean
  ) => void;
  deleteShipping?: () => void;
  countryName: string;
  everyOneOffice?: boolean;
  referenceInitialData?: ICartShipping;
  globalShippingCartWasPreloaded?: boolean;
  countryWarehouse?: IWarehouse;
  refrenceProductId: number;
  completeListOfCountries?: ICountryDetail[];
}

const ReferenceOptions: FC<IReferenceOptions> = ({
  optionsSelect,
  openAddOfficeAddress,
  officeAddress,
  defaultOfficeAddress,
  externalIndex,
  setEveryOneOffice,
  productInfo,
  handleShippingReference,
  deleteShipping,
  countryName,
  everyOneOffice,
  referenceInitialData,
  globalShippingCartWasPreloaded,
  countryWarehouse,
  refrenceProductId,
  completeListOfCountries
}: IReferenceOptions) => {
  const { state }: { state: { userId: number; referenceInitialData: ICartShipping } } = useLocation();
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const [shippingSelect, setShippingSelect] = useState<IShippingOption>();
  const [selectedEmployee, setSelectedEmployee] = useState<Iemployee | undefined>();
  const [showShipToEmployeeModalFlux, setShowShipToEmployeeModalFlux] = useState(false);
  const [closeOutside, setCloseOutside] = useState<boolean>(false);
  const [newlyCreatedEmployeeWasPreloadedIfRequired, setNewlyCreatedEmployeeWasPreloadedIfRequired] = useState(false);

  const { userId: newEmployeeId, referenceInitialData: dataBeforeEmployeeCreation } = state || {};
  const translationKey = 'designSystemComponents:referenceOptions:';
  const shippingName = shippingSelect?.name;

  const selectDropdownPlaceholder = shippingName ? (
    <div className="shipmentDestinationSelectedPlaceholder">
      <BordOneToneIcon variant={shippingSelect.iconVariant} standardSize={14} stroke={bordColorTheme.content.default} />
      <span>{shippingName}</span>
    </div>
  ) : (
    t(`${translationKey}shipTo`)
  );

  const handleChangeOptionOffice = (
    shipmentOption: IShippingOption,
    type: 'employee' | 'office',
    employeeData?: Iemployee,
    officeData?: IorganizationOfficeData,
    manuallySelected?: boolean
  ) => {
    if (shipmentOption && setEveryOneOffice) {
      setShippingSelect(shipmentOption);
      setEveryOneOffice(false);
      setSelectedEmployee(undefined);
      if (handleShippingReference) {
        handleShippingReference(type, employeeData, officeData, undefined, manuallySelected);
      }
    }
  };

  const handleChangeOptionStoreNudos = (shipmentOption: IShippingOption, manuallySelected?: boolean) => {
    if (shipmentOption && setEveryOneOffice) {
      setShippingSelect(shipmentOption);
      setEveryOneOffice(false);
      setSelectedEmployee(undefined);
      if (handleShippingReference && countryWarehouse) {
        handleShippingReference('nudos', undefined, undefined, countryWarehouse, manuallySelected);
      }
    }
  };

  const handleChangeOptionEmployee = (e: IShippingOption) => {
    if (e && setEveryOneOffice && deleteShipping) {
      setShippingSelect(e);
      setEveryOneOffice(false);
      deleteShipping();
      displaySuccessNotification({
        customJSXMessage: <>{t(`${translationKey}employeeOptionNotification`)}</>
      });
    }
  };

  const assignEmployeeAddress = (newlySelectedEmployee?: Iemployee) => {
    setSelectedEmployee(newlySelectedEmployee);
    if (newlySelectedEmployee && isEmployeeDataComplete(newlySelectedEmployee)) {
      handleShippingReference && handleShippingReference('employee', newlySelectedEmployee, undefined, undefined, true);
      return displaySuccessNotification({
        customJSXMessage: <>{t(`${translationKey}assignEmployeeAddressText`)}</>
      });
    }
    if (newlySelectedEmployee) return;
    deleteShipping && deleteShipping();
  };

  const selectNewlyCreatedEmployee = async (userId: number) => {
    if (!userId) return;
    try {
      setNewlyCreatedEmployeeWasPreloadedIfRequired(true);
      setShippingSelect(optionsSelect?.find(option => option.value === SHIPPING_OPTIONS_VALUES.OFFICE));
      setEveryOneOffice && setEveryOneOffice(false);
      const employeeData = await getEmployeeById(userId);
      if (employeeData?.country?.id !== referenceInitialData?.countryId) {
        displayErrorNotification({
          customJSXMessage: <>{t(`${translationKey}wrongCountryAlert`)}</>
        });
        return window.history.replaceState({}, '');
      }
      assignEmployeeAddress(employeeData);
      setNewlyCreatedEmployeeWasPreloadedIfRequired(true);
      window.history.replaceState({}, '');
    } catch (error) {
      displayErrorNotification();
    }
  };

  const officeDataIsComplete = checkIfOfficeAddressDataIsComplete(officeAddress);
  const callbackFunctionForOfficeDestinationModal = (item: IShippingOption, modalData?: IorganizationOfficeData) => {
    checkIfOfficeAddressDataIsComplete(modalData) && handleChangeOptionOffice(item, 'office', undefined, modalData);
    displaySuccessNotification({
      customJSXMessage: <>{t(`${translationKey}officeDataIsComplete`)}</>
    });
  };

  const handleChangeShipmentOption = (item: IShippingOption, manuallySelected?: boolean) => {
    if (item.value === 1 && !officeDataIsComplete) {
      openAddOfficeAddress(
        () => (modalData: IorganizationOfficeData) => callbackFunctionForOfficeDestinationModal(item, modalData)
      );
    } else if (item.value === 1 && officeDataIsComplete) {
      handleChangeOptionOffice(item, 'office', undefined, officeAddress, manuallySelected);
      displaySuccessNotification({
        customJSXMessage: <>{t(`${translationKey}officeDataIsComplete`)}</>
      });
    } else if (item.value === 2) {
      handleChangeOptionEmployee(item);
    }
  };

  const destinationOptions =
    optionsSelect &&
    optionsSelect.length > 0 &&
    optionsSelect.map((optionSelect, index) => {
      return (
        <ShippingOptions
          key={`shipping-option-${optionSelect.value}-${index}-external-${externalIndex}-productId-${productInfo?.productId}`}
          handleChangeOptionStoreNudos={e => {
            handleChangeOptionStoreNudos(e, true);
          }}
          handleChangeShipmentOption={e => {
            handleChangeShipmentOption(e, true);
          }}
          optionSelect={optionSelect || []}
          setCloseOutside={setCloseOutside}
          shippingSelect={shippingSelect}
          hasAnOfficeAddress={!!officeAddress?.address}
        />
      );
    });

  const finishEmployeeDestinationFluxCallbackFn = async (employeeUserId: string | number) => {
    if (!employeeUserId) return;
    const updatedEmployeeData = await getEmployeeById(`${employeeUserId}`);
    setSelectedEmployee(updatedEmployeeData);
    handleShippingReference && handleShippingReference('employee', updatedEmployeeData);
    displaySuccessNotification({
      customJSXMessage: <>{t(`${translationKey}finishEmployeeDestination`)}</>
    });
    setShowShipToEmployeeModalFlux(false);
  };

  const changeCloseOutside = (status: boolean) => {
    setCloseOutside(status);
  };

  useEffect(() => {
    if (
      handleShippingReference &&
      officeAddress?.address &&
      defaultOfficeAddress &&
      optionsSelect &&
      optionsSelect.length > 0
    ) {
      setShippingSelect(optionsSelect[0]);
      handleShippingReference('office', undefined, officeAddress);
    }
  }, [defaultOfficeAddress]);

  useEffect(() => {
    if (
      shippingSelect?.name === t(`${translationKey}employee`) &&
      selectedEmployee &&
      !isEmployeeDataComplete(selectedEmployee)
    )
      setShowShipToEmployeeModalFlux(true);
  }, [selectedEmployee]);

  useEffect(() => {
    if (showShipToEmployeeModalFlux) return;
    if (
      shippingSelect?.name === t(`${translationKey}employee`) &&
      selectedEmployee &&
      !isEmployeeDataComplete(selectedEmployee)
    )
      setSelectedEmployee(undefined);
  }, [showShipToEmployeeModalFlux]);

  useEffect(() => {
    if (everyOneOffice) setSelectedEmployee(undefined);
  }, [everyOneOffice]);

  useEffect(() => {
    if (shippingSelect || !referenceInitialData?.destinationLocationId || !globalShippingCartWasPreloaded) return;
    if (referenceInitialData?.destinationIsOffice) {
      const officeOption = optionsSelect?.find(option => option.value === SHIPPING_OPTIONS_VALUES.OFFICE);
      officeOption && handleChangeOptionOffice(officeOption, 'office', undefined, officeAddress);
    }
    if (!referenceInitialData?.destinationIsOffice && referenceInitialData?.employee) {
      const employeeOption = optionsSelect?.find(option => option.value === SHIPPING_OPTIONS_VALUES.EMPLOYEE);
      setSelectedEmployee(referenceInitialData.employee);
      handleShippingReference && handleShippingReference('employee', referenceInitialData.employee);
      setShippingSelect(employeeOption);
    }
    if (
      !referenceInitialData?.destinationIsOffice &&
      !referenceInitialData?.employee &&
      !!referenceInitialData.destinationLocationId
    ) {
      const warehouseOption = optionsSelect?.find(option => option.value === SHIPPING_OPTIONS_VALUES.WAREHOUSE);
      warehouseOption && handleChangeOptionStoreNudos(warehouseOption);
    }
  }, [referenceInitialData, globalShippingCartWasPreloaded]);

  useEffect(() => {
    // This effect handles the preselection of a newly created employee when the user goes from the employee search dropdown directly to the create employee module and returns
    if (newlyCreatedEmployeeWasPreloadedIfRequired || !dataBeforeEmployeeCreation || !newEmployeeId) return;
    const isOtherTool = dataBeforeEmployeeCreation?.onlyShippingUid !== referenceInitialData?.onlyShippingUid;
    const isSameEmployee = dataBeforeEmployeeCreation?.employee?.userId === newEmployeeId;
    const employeeWasSelected = referenceInitialData?.employee?.userId === newEmployeeId;
    if (isOtherTool || isSameEmployee || employeeWasSelected || referenceInitialData?.destinationLocationId) return;
    selectNewlyCreatedEmployee(newEmployeeId);
  }, [newEmployeeId, dataBeforeEmployeeCreation, referenceInitialData]);

  const handleCheckDataEmployee = () => {
    setShowShipToEmployeeModalFlux(false);
    const employeeDataComplete = selectedEmployee ? isEmployeeDataComplete(selectedEmployee) : undefined;
    if (selectedEmployee && !employeeDataComplete) {
      setSelectedEmployee(undefined);
    }
  };

  return (
    <div id="referenceOptions">
      <div className="referenceGeneral">
        <div className="containerNumber">
          <div className="numberText">{`${externalIndex + 1}.`}</div>
        </div>
        <div
          className="containerSelect"
          id={`item-product-${refrenceProductId}-country-${countryName}-index-${externalIndex}`}
        >
          <NudosSelectDropdown
            closesOnChangeSelection={stateSubscription?.levelSubscription === 'Lite'}
            heightDropDown={40}
            isFilled={!!shippingSelect}
            selectOptions={destinationOptions || []}
            currentValueOrplaceholder={selectDropdownPlaceholder}
            closeOutside={stateSubscription?.levelSubscription === 'Free' && closeOutside}
            changeCloseOutside={changeCloseOutside}
            paddingLeft={16}
          />
        </div>
        {shippingSelect?.value === 1 && officeAddress && (
          <div className="containerOfficeAddress">
            <div className="officeTitle">{t(`${translationKey}officeTitle`)}</div>
            <div className="addressOfficeInfo limitAddress">{`${officeAddress?.address || ''}`}&nbsp;</div>
            <div className="addressOfficeInfo limitUserName">
              {`/ ${officeAddress?.shipmentInfo?.receiverName || ''} `}&nbsp;
            </div>
            <div className="addressOfficeInfo limitPhone">{`- ${
              officeAddress?.shipmentInfo?.receiverPhone || ''
            }`}</div>
          </div>
        )}
        {shippingSelect?.value === 1 && !officeAddress && (
          <div className="shipmentInfoSkeletonLoader animationLoader" />
        )}
        {shippingSelect?.value === 2 && (
          <div className="containerEmployeeAddress">
            <div className={`employeeTitle ${selectedEmployee ? 'active' : ''}`}>
              {t(`${translationKey}officeTitle`)}
            </div>
            <div className="containerSelectEmployees">
              <NudosEmployeeSearchBar
                customWidth={240}
                customHeight={40}
                customClassName="mediumHeight"
                customPlaceholder="Busca el empleado"
                setSelectedEmployee={assignEmployeeAddress}
                selectedEmployee={selectedEmployee}
                customShowAddressDataButtonTextCompleteEmployee={t(`${translationKey}completeEmployee`)}
                customShowAddressDataButtonTextIncompleteEmployee={t(`${translationKey}incompleteEmployee`)}
                showAddressData
                seeAddressCallback={() => setShowShipToEmployeeModalFlux(true)}
                countriesFilter={countryName ? [countryName] : undefined}
                originDataForRedirection={{
                  origin: '/catalogue/checkout',
                  returnTextKey: 'checkoutRedirectionText',
                  referenceInitialData,
                  createFromSpecificCountry: countryName
                }}
              />
            </div>
          </div>
        )}
        {shippingSelect?.value === 3 && (
          <div className="containerOfficeAddress">
            <div className="officeTitle ">{t(`${translationKey}officeTitle`)}</div>
            <div className="addressOfficeInfo">
              {t(`${translationKey}addressOfficeInfo`)}
              <span className="bordService"> {t(`${translationKey}warehouseMonths`)}</span>
            </div>
          </div>
        )}
      </div>
      {showShipToEmployeeModalFlux && selectedEmployee && (
        <ShipToEmployeeModalFlux
          setShowShipToEmployeeModalFlux={handleCheckDataEmployee}
          employeeData={selectedEmployee}
          finishEmployeeDestinationFluxCallbackFn={finishEmployeeDestinationFluxCallbackFn}
          completeListOfCountries={completeListOfCountries}
        />
      )}
    </div>
  );
};

export default ReferenceOptions;

export enum SHIPPING_OPTIONS_VALUES {
  OFFICE = 1,
  EMPLOYEE,
  WAREHOUSE
}
