import { getAuth, signInAnonymously, onAuthStateChanged, Auth } from 'firebase/auth';
import { genericErrorMessage } from '../constants';
import { displayErrorNotification } from './displayNudosStandardNotifications';

const anonymousUser = async () => {
  const auth = getAuth();
  onAuthStateChanged(auth, user => {
    if (!user) {
      anonymousLogin(auth);
    }
  });
};

const anonymousLogin = (auth: Auth) => {
  signInAnonymously(auth).catch(error =>
    displayErrorNotification({ customJSXMessage: <>{error?.errorMessage || genericErrorMessage}</> })
  );
};

export default anonymousUser;
