import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import SimpleArrow from '../../../assets/images/components/simpleSelect/SimpleArrow';
import { Tany } from '../../../types/global';
import './adquisitionType.scss';

interface IElement {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: Tany;
}

interface ISelectData {
  value: number;
  name: string;
  icon?: React.ReactNode;
}

interface ISimpleSelect {
  enabled?: boolean;
  selectList?: ISelectData[];
  defaultValue?: ISelectData;
  changeValue?: (item: ISelectData) => void;
  isGray?: boolean;
}

const AdquisitionType: FC<ISimpleSelect> = ({
  enabled,
  selectList,
  defaultValue,
  changeValue,
  isGray
}: ISimpleSelect) => {
  const refContainer = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setOpen(false);
      }
    },
    [refContainer]
  );

  const handleChange = (item: ISelectData) => {
    if (changeValue) {
      changeValue(item);
    }
  };

  const generateItems = () => {
    return selectList?.map(item => {
      return (
        <div
          key={uuid()}
          className="itemSelect"
          onClick={() => {
            handleChange(item);
            setOpen(false);
          }}
        >
          {item?.icon && <div className="containerIcon">{item.icon}</div>}
          {item.name}
        </div>
      );
    });
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div id="adquisitionType" ref={refContainer}>
      <div
        className="selectButton"
        style={{ border: !enabled || isGray ? '1px solid #B3B3B3' : '', background: !enabled ? '#FAFAFA' : '' }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="nameButton" style={{ color: !enabled || isGray ? '#B3B3B3' : '' }}>
          {defaultValue?.icon && <div className="containerIcon">{defaultValue?.icon}</div>}
          {defaultValue?.name || 'Seleccionar'}
        </div>
        <SimpleArrow fill={!enabled || isGray ? '#B3B3B3' : ''} />
      </div>
      {enabled && open && selectList && selectList.length > 0 && (
        <div className="floatSelect">{selectList && selectList.length > 0 && generateItems()}</div>
      )}
    </div>
  );
};

export default AdquisitionType;
