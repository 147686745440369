import { FC, Fragment } from 'react';
import { IAvailableCountrie } from '../../../../../types/catalogue';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { GenericLoader } from '../../../../Admin/dashboard/components';
import { getCountryName } from '../../../../../utils/getCountries';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import { segmentTrackEvent } from '../../../../../utils/segment';
import useShopStore from '../../../../../state/useShopContext';
import { productsNumber } from '../../../../../utils/cart';
import { IStoreCountry } from '../../../../../state/useStoreCountryState';
import { BordOneToneIcon } from '../../../../../components/BordDesignSystem';

import './ListOfAvailableCountries.scss';

interface IListOfAvailableCountries {
  availableCountries: IAvailableCountrie[];
  showSpecialElements?: boolean;
  isPrime?: boolean;
  skuId?: string | null;
  productName?: string;
  loadingSpecificCountry?: number;
  updateStoreCountryEmptyCart?: (country: IStoreCountry) => void;
  openCountryChangeAlertModal?: (country: IStoreCountry) => void;
}

const ListOfAvailableCountries: FC<IListOfAvailableCountries> = ({
  availableCountries,
  showSpecialElements,
  isPrime,
  skuId,
  productName,
  loadingSpecificCountry,
  updateStoreCountryEmptyCart,
  openCountryChangeAlertModal
}: IListOfAvailableCountries) => {
  const { t } = useTranslation();
  const { itemsCart } = useShopStore();
  const totalItemsCart = productsNumber();
  const translationsEcommerce = 'ecommerce:productDetail:';

  const handleUpdateStoreCountryEmptyCart = (country: IStoreCountry) => {
    if (updateStoreCountryEmptyCart) updateStoreCountryEmptyCart(country);
  };

  const handleOpenCountryChangeAlertModal = (country: IStoreCountry) => {
    if (openCountryChangeAlertModal) openCountryChangeAlertModal(country);
  };

  return (
    <div id="listOfAvailableCountries">
      <div className="titleListContainer">{t(`${translationsEcommerce}priceOnOtherCountries`)}</div>
      <div className="flagListBox">
        {availableCountries.map(countryAvailable => {
          return (
            <Fragment key={uuid()}>
              <div
                className="countryListContainer"
                onClick={() => {
                  segmentTrackEvent({
                    internalProductStoresClick: {
                      CountryName: countryAvailable?.countryName || '',
                      SKUId: `${skuId}`,
                      ProductName: productName || ''
                    }
                  });
                  if (itemsCart?.shoppingCartId && totalItemsCart > 0 && !loadingSpecificCountry) {
                    handleOpenCountryChangeAlertModal(countryAvailable);
                  } else {
                    handleUpdateStoreCountryEmptyCart(countryAvailable);
                  }
                }}
              >
                {!!loadingSpecificCountry && loadingSpecificCountry === countryAvailable.id ? (
                  <div className="w-full h-full flex items-center content-center justify-center">
                    <GenericLoader sizeLoader={10} borderWidth={2} />
                  </div>
                ) : (
                  <>
                    <div className="flagList">
                      <div
                        className="flagBoxList"
                        style={{
                          backgroundImage: `url("${countryAvailable?.countryFlag}")`
                        }}
                      />
                    </div>
                    {countryAvailable && (
                      <div className="countryName">
                        {getCountryName({
                          code: countryAvailable?.countryCode,
                          flag: countryAvailable?.countryFlag,
                          id: countryAvailable?.id,
                          name: countryAvailable?.countryName,
                          nameStringId: countryAvailable?.nameStringId
                        })}
                      </div>
                    )}
                    {showSpecialElements ? (
                      <>
                        {isPrime ? (
                          <>
                            <div className="firstPricePrime">{`${nudosFormatCurrency({
                              value: Number(countryAvailable?.prices?.pricePrimeUsd),
                              maximumFractionDigits: 2
                            })} USD`}</div>
                            <div className="secondPricePrime">{`${nudosFormatCurrency({
                              value: Number(countryAvailable?.prices?.priceWithoutPrimeUsd),
                              maximumFractionDigits: 2
                            })} USD`}</div>
                          </>
                        ) : (
                          <div className="firstPrice">{`${nudosFormatCurrency({
                            value: Number(countryAvailable?.prices?.priceWithoutPrimeUsd),
                            maximumFractionDigits: 2
                          })} USD`}</div>
                        )}
                      </>
                    ) : (
                      <div className="firstPrice">{`${nudosFormatCurrency({
                        value: Number(countryAvailable?.prices?.pricePrimeUsd),
                        maximumFractionDigits: 2
                      })} USD`}</div>
                    )}
                    <div className="redirectionArrowContainer">
                      <div className="boxArrowContainer">
                        <div className="blueArrowText">{t(`${translationsEcommerce}goToStore`)}</div>
                        <div className="blueArrow">
                          <BordOneToneIcon variant="arrowRight" standardSize={12} />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="grayLine" />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ListOfAvailableCountries;
