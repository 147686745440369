import { FC, useEffect, useState } from 'react';
import { ButtonToEditQuantities, DynamicsPills } from '../../../../../components';
import { useHistory } from 'react-router-dom';
import { isRouteMyLocation } from '../../../../../utils/getPathRoute';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { IProductItemList } from '../../../../../types/catalogue';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import { BordButton } from '../../../../../components/BordDesignSystem';
import BordBadge from '../../../../../components/BordDesignSystem/BordBadge/BordBadge';
import './ProductEcommerceCard.scss';

export interface ObjectKey<T> {
  [key: string]: T;
}

interface IFormatPills {
  name: string;
  value?: string;
}

interface ICardData {
  dataProductCard?: IProductItemList;
}

const ProductEcommerceCard: FC<ICardData> = ({ dataProductCard }: ICardData) => {
  const { push } = useHistory();
  const { storeCountry } = useStoreCountryState();
  const { stateSubscription } = useStateSubscriptions();

  const [formatPills, setFormatPills] = useState<IFormatPills[]>();

  const { countryCode } = storeCountry || {};
  const priceLimit = Number(dataProductCard?.prices?.pricePrimeUsd) >= 170;
  const { levelSubscription, isPrime } = stateSubscription;

  const mainPrice = isPrime || !priceLimit;
  const discountPrice = priceLimit && isPrime;
  const freePlatform = levelSubscription === 'Free';
  const originSearcher = !!isRouteMyLocation('search', 2);
  const countryCodeParam = `?countryCode=${countryCode}`;
  const productDetailsURL = `/catalogue/product/${dataProductCard?.productId}${countryCodeParam}${
    originSearcher ? '&originSeeker=true' : ''
  }`;

  const generatePills = () => {
    const pills =
      typeof dataProductCard?.pills === 'string' ? JSON.parse(dataProductCard?.pills) : dataProductCard?.pills;
    if (pills) {
      const myKeys = Object.keys(pills);
      const myPillsList = [];
      if (myKeys.length > 0) {
        for (const key of myKeys) {
          if (!key.includes('Perfil') && !key.includes('Gama')) {
            const objetPill = { name: key, value: pills?.[key] };
            if (key === 'Cargo') {
              myPillsList.unshift(objetPill);
            } else {
              myPillsList.push(objetPill);
            }
          }
        }
        if (myPillsList && myPillsList.length > 0) {
          setFormatPills([...myPillsList]);
        }
      }
    }
  };

  const goToProductDetails = () => {
    segmentTrackEvent({
      catalogueProductCardClick: {
        CategoryName: `${dataProductCard?.categoryName}`,
        CountryName: storeCountry?.countryName || '',
        SKUId: `${dataProductCard?.sku}`,
        TypeOfPlatform: freePlatform ? 'Free' : 'Prime',
        ProductName: dataProductCard?.productName || ''
      }
    });
    if (dataProductCard?.productId) push(productDetailsURL);
  };

  useEffect(() => {
    generatePills();
  }, [dataProductCard]);

  return (
    <section id="productEcommerceCard">
      <div className="containerProductCard">
        <div className="headerProductCard" onClick={goToProductDetails}>
          <div className="productBrandContainer">
            <div className="brandImgBox bgBrand" style={{ backgroundImage: `url("${dataProductCard?.brandImage}")` }} />
          </div>
          <div className="referenceImgContainer">
            <img className="maxImgContainer" src={dataProductCard?.imageUrl || ''} />
          </div>
        </div>
        <div className="bodyProductCard">
          <div className="descriptionItemsContainer" onClick={goToProductDetails}>
            <div className="priceAndNameContainer">
              <div className="productName">{dataProductCard?.productName}</div>
              <div className="pricesContainer">
                <div className="principalPrice">{`${nudosFormatCurrency({
                  value: Number(
                    mainPrice ? dataProductCard?.prices?.pricePrimeUsd : dataProductCard?.prices?.priceWithoutPrimeUsd
                  ),
                  maximumFractionDigits: 2
                })} USD`}</div>
                {discountPrice && (
                  <>
                    <div className="secondaryPrice">
                      {`${nudosFormatCurrency({
                        value: Number(dataProductCard?.prices?.priceWithoutPrimeUsd),
                        maximumFractionDigits: 2
                      })} USD`}
                    </div>
                    <BordBadge variant="gradient" label="3% off" />
                  </>
                )}
              </div>
            </div>
            <div className="blueLine" />
            <div className="pillsContainer">
              {formatPills &&
                formatPills.length > 0 &&
                formatPills.map(element => {
                  return (
                    <DynamicsPills
                      key={`product-pull-${element.name}`}
                      elementsNumber={formatPills.length}
                      dataPills={{ text: element?.value, hoverText: element.name }}
                    />
                  );
                })}
            </div>
          </div>
          <div className="buttonToEditQuantitiesContainer">
            <div className="buttonsProductContainer">
              <div className="productDetailsButton">
                <BordButton
                  onClick={goToProductDetails}
                  label="Conocer más"
                  modeButton="secondary"
                  customHeight="auto"
                  customFontSize="1.2rem"
                  customClassName="h-32"
                  buttonIconTwo="arrowRightCircle"
                  standardSizeIcon={12}
                />
              </div>
              <div className="addButtonContainer">
                {dataProductCard && <ButtonToEditQuantities dataProductCard={dataProductCard} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductEcommerceCard;
