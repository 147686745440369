const SquareEuropeanUnion = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path
            fill="#1A47B8"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#FFFF00"
            fillRule="evenodd"
            d="M10.9999 2L11.2761 2.61976L11.9509 2.69098L11.4469 3.14524L11.5877 3.80902L10.9999 3.47L10.4121 3.80902L10.5529 3.14524L10.0488 2.69098L10.7236 2.61976L10.9999 2ZM6.27614 7.61976L5.99988 7L5.72363 7.61976L5.04883 7.69098L5.55289 8.14524L5.4121 8.80902L5.99988 8.47L6.58767 8.80902L6.44688 8.14524L6.95094 7.69098L6.27614 7.61976ZM16.2761 7.61976L15.9999 7L15.7236 7.61976L15.0488 7.69098L15.5529 8.14524L15.4121 8.80902L15.9999 8.47L16.5877 8.80902L16.4469 8.14524L16.9509 7.69098L16.2761 7.61976ZM11.2761 12.6198L10.9999 12L10.7236 12.6198L10.0488 12.691L10.5529 13.1452L10.4121 13.809L10.9999 13.47L11.5877 13.809L11.4469 13.1452L11.9509 12.691L11.2761 12.6198ZM7.49988 10.5L7.77614 11.1198L8.45094 11.191L7.94688 11.6452L8.08767 12.309L7.49988 11.97L6.9121 12.309L7.05289 11.6452L6.54883 11.191L7.22363 11.1198L7.49988 10.5ZM7.77614 4.11976L7.49988 3.5L7.22363 4.11976L6.54883 4.19098L7.05289 4.64524L6.9121 5.30902L7.49988 4.97L8.08767 5.30902L7.94688 4.64524L8.45094 4.19098L7.77614 4.11976ZM14.4999 10.5L14.7761 11.1198L15.4509 11.191L14.9469 11.6452L15.0877 12.309L14.4999 11.97L13.9121 12.309L14.0529 11.6452L13.5488 11.191L14.2236 11.1198L14.4999 10.5ZM14.7761 4.11976L14.4999 3.5L14.2236 4.11976L13.5488 4.19098L14.0529 4.64524L13.9121 5.30902L14.4999 4.97L15.0877 5.30902L14.9469 4.64524L15.4509 4.19098L14.7761 4.11976Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEuropeanUnion;
