const RoundMadagascar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#6DA544"
          d="M10.4346 16.0001V31.0049C12.1678 31.6481 14.0426 32.0001 15.9998 32.0001C24.8363 32.0001 31.9998 24.8366 31.9998 16.0001C31.9998 7.16359 10.4346 16.0001 10.4346 16.0001Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 0C14.0426 0 12.1678 0.352063 10.4346 0.995188V16H31.9998C31.9998 7.1635 24.8363 0 15.9998 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMadagascar;
