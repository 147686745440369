import { FC, useEffect, useState } from 'react';
import NudosGenericSearchBar from '../../../../../components/NudosComponents/NudosGenericSearchBar';
import { BordButton, BordCheckBox, BordTextInput } from '../../../../../components/BordDesignSystem';
import { ProductDescription } from '../../../../../components/uploadExternalProducts';
import useDebounce from '../../../../../hooks/useDebounce';
import { findProductReferencesToUpload } from '../../../../../services/uploadExternalTools.service';
import {
  DTOfindProductReferencesToUpload,
  IuploadedItemData,
  IuploadedProductReferenceData
} from '../../../../../types/uploadExternalTools.types';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { useTranslation } from 'react-i18next';
import useLoadTools from '../../../../../state/useStateSuccessLoadTools';

import './Step1SelectReferences.scss';
import BordIlustrationIcon from '../../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';

export interface Istep1SelectReferences {
  activateModal: () => void;
  freePlatform?: boolean;
  numberOfExternalReferences?: number;
  maximumExternalUnits?: number;
  screenName: string;
}

const Step1SelectReferences: FC<Istep1SelectReferences> = (props: Istep1SelectReferences) => {
  const { t } = useTranslation();
  const { activateModal } = props;
  const { selectedReferences, setSelectedReferences, setCurrentStep } = useLoadTools();

  const [searchString, setSearchString] = useState('');
  const [showReferencesSelect, setShowReferencesSelect] = useState(false);
  const [searchMatchingReferences, setSearchMatchingReferences] = useState<DTOfindProductReferencesToUpload[]>();
  const [loading, setLoading] = useState(true);

  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:Step1SelectReferences:';
  const searchQuery = useDebounce(searchString, 500);

  const createEmptyadditionalDataArray = (n: number) => {
    const emptyDataObject = { serial: '', productCondition: 'Bien', warranty: '' };
    const newArray = [];
    for (let i = 0; i < n; i++) newArray.push(emptyDataObject);
    return newArray as IuploadedItemData[];
  };

  const addNewReference = (
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: DTOfindProductReferencesToUpload
  ) => {
    const referenceAlreadyExists = totalReferences.findIndex(reference => reference.sku === searchedReference.sku) >= 0;
    const newTotalReferences = [...totalReferences];
    if (!referenceAlreadyExists) {
      const newReference = {
        sku: searchedReference.sku,
        name: searchedReference.name,
        pills: searchedReference.pills,
        category: searchedReference?.category,
        quantity: 1,
        individualToolsData: createEmptyadditionalDataArray(1)
      };
      newTotalReferences.push(newReference);
      setSelectedReferences(newTotalReferences);
    }
  };

  const removeExistingReference = (
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: DTOfindProductReferencesToUpload
  ) => {
    const searchedReferenceIndex = totalReferences.findIndex(reference => reference.sku === searchedReference.sku);
    const newTotalReferences = [...totalReferences];
    if (searchedReferenceIndex >= 0) {
      newTotalReferences.splice(searchedReferenceIndex, 1);
      setSelectedReferences(newTotalReferences);
    }
  };

  const setUnits = (
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: IuploadedProductReferenceData,
    newUnits: number
  ) => {
    const previousUnits = searchedReference.individualToolsData?.length;
    const previousAdditionalData = [...searchedReference.individualToolsData];
    const searchedReferenceIndex = totalReferences.findIndex(reference => reference.sku === searchedReference.sku);
    const newTotalReferences = [...totalReferences];
    if (newUnits < 0) return;
    const hasSomeAdditionalData =
      searchedReference.individualToolsData?.length > 0 &&
      searchedReference.individualToolsData.some(reference => {
        return !!reference.serial || !!reference.productCondition;
      });
    let newIndividualToolsData: IuploadedItemData[] = [];
    if (!hasSomeAdditionalData) newIndividualToolsData = createEmptyadditionalDataArray(newUnits);
    if (hasSomeAdditionalData && newUnits === 0) newIndividualToolsData = [];
    if (hasSomeAdditionalData && newUnits > 0 && newUnits < previousUnits)
      newIndividualToolsData = previousAdditionalData.splice(0, newUnits);
    if (hasSomeAdditionalData && newUnits > 0 && newUnits === previousUnits)
      newIndividualToolsData = previousAdditionalData;
    if (hasSomeAdditionalData && newUnits > 0 && newUnits > previousUnits) {
      const unitsInExcess = createEmptyadditionalDataArray(newUnits - previousUnits);
      newIndividualToolsData = [...searchedReference.individualToolsData, ...unitsInExcess];
    }
    const updatedReference = {
      sku: searchedReference.sku,
      name: searchedReference.name,
      pills: searchedReference.pills,
      quantity: newUnits,
      category: searchedReference?.category,
      individualToolsData: newIndividualToolsData
    };
    newTotalReferences[searchedReferenceIndex] = updatedReference;
    setSelectedReferences(newTotalReferences);
  };

  const onChangeSelectedReferences = (newSelectedReference: DTOfindProductReferencesToUpload) => {
    if (!newSelectedReference) return;
    const isCurrentlySelected = selectedReferences?.some(reference => reference?.sku === newSelectedReference?.sku);
    if (!isCurrentlySelected) addNewReference(selectedReferences, newSelectedReference);
    if (isCurrentlySelected) removeExistingReference(selectedReferences, newSelectedReference);
  };

  const formPossibleReferencesUI = (rawPossibleReferences: DTOfindProductReferencesToUpload[]): JSX.Element[] => {
    return rawPossibleReferences.map((reference: DTOfindProductReferencesToUpload, i: number) => {
      const isCurrentlySelected = selectedReferences.some(savedReference => savedReference.sku === reference.sku);
      return (
        <div
          key={`reference-${i}-${reference?.sku}`}
          className="referenceOption"
          onClick={() => onChangeSelectedReferences({ ...reference })}
        >
          <BordCheckBox isButtonActive={isCurrentlySelected} />
          <ProductDescription
            name={reference?.name}
            pillsDataList={reference.pills}
            sku={reference?.sku}
            customProductNameClass="customProductName"
            nameCharacterLimit={30}
          />
        </div>
      );
    });
  };

  const handleClickAddNewReference = () => activateModal();

  const addANewReferenceButton = (
    <div className="containerAddReferenceButton" onClick={handleClickAddNewReference}>
      <BordButton
        customHeight="42"
        label="No encuentro una herramienta"
        buttonIconTwo="helpCircle"
        standardSizeIcon={14}
      />
    </div>
  );

  const getSearchedReferences = async () => {
    setLoading(true);
    try {
      const findProductReferencesResponse = await findProductReferencesToUpload(searchQuery);
      if (findProductReferencesResponse && findProductReferencesResponse?.length > 0)
        setSearchMatchingReferences(findProductReferencesResponse);
      if (findProductReferencesResponse && !(findProductReferencesResponse?.length > 0)) {
        setSearchMatchingReferences(undefined);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const handleChangeUnitsOfGivenReference = (
    units: string,
    totalReferences: IuploadedProductReferenceData[],
    searchedReference: IuploadedProductReferenceData
  ) => {
    const value = units;
    setUnits(totalReferences, searchedReference, +value);
  };

  const isButtonDisabled =
    selectedReferences.length <= 0 ||
    !selectedReferences.some(reference => reference.quantity > 0) ||
    selectedReferences.some(reference => !/^\d*[1-9]\d*$/.test(`${reference.quantity}`));

  const handleClickContinue = () => {
    const referencesWithItems = [...selectedReferences].filter(reference => +reference.quantity > 0);
    setSelectedReferences(referencesWithItems);
    setCurrentStep(1);
  };

  useEffect(() => {
    searchQuery && getSearchedReferences();
  }, [searchQuery]);

  useEffect(() => {
    segmentTrackEvent({ toolsUploadView: null });
  }, []);

  return (
    <div className="step1SelectReferences">
      <div className="boxStep1">
        <div className="searchBarBox">
          <section className="searchBarSection">
            <div className="searchBarContainer">
              <NudosGenericSearchBar
                searchString={searchString}
                setSearchString={setSearchString}
                setIsSelectOpen={setShowReferencesSelect}
                searchedOptions={
                  searchMatchingReferences ? formPossibleReferencesUI(searchMatchingReferences) : undefined
                }
                handleChangeSelectedOption={onChangeSelectedReferences}
                defaultValue={undefined}
                isSelectOpen={showReferencesSelect}
                noResultsMessage={
                  <em key="no-results-option">
                    <div className="noResultContainer">
                      <div className="iconNoResultContainer">
                        <BordIlustrationIcon variant="search" standardSize={64} />
                      </div>{' '}
                      <div className="textNoResults">
                        No hay resultados para tu búsqueda, prueba con otros <br />
                        términos o solicita la creación de la herramienta
                      </div>
                    </div>
                  </em>
                }
                customPlaceholder="Busca y selecciona tus herramientas (busca por nombre, marca o SKU)"
                absolutePositionElement={addANewReferenceButton}
                isLoading={loading}
              />
            </div>
          </section>
        </div>
        <div className="textCreateTool" onClick={activateModal}>
          ¿No encuentras una herramienta?
          <span className="underlineText">&nbsp;Solicita la creación aquí</span>
        </div>
        <div className="containerBlueLine">
          <div className="blueLine" />
        </div>
        <section className="selectedReferencesSection">
          <div className="boxSelectedReferences">
            <div className="selectedReferecesListBox">
              <div className="referencesTitles">
                <div className="selectTools">{t(`${i18nLanguageKey}textTitle`)}</div>
                <div className="unitsTools">{t(`${i18nLanguageKey}unitsTitle`)}</div>
              </div>
              <div className="referencesProducts">
                {selectedReferences?.length > 0 &&
                  selectedReferences.map((reference, i) => {
                    const handleClickDelete = () => removeExistingReference(selectedReferences, reference);
                    return (
                      <div key={`reference-${i}-${reference?.sku}`} className="referenceContainer">
                        <div className="productWrapper">
                          <BordCheckBox isButtonActive handleClickButton={handleClickDelete} />
                          <ProductDescription
                            name={reference?.name}
                            sku={reference?.sku}
                            pillsDataList={reference?.pills}
                            nameCharacterLimit={40}
                          />
                        </div>
                        <BordTextInput
                          standardSize="w-80"
                          isFilled={!!reference?.quantity}
                          placeholder="0"
                          setCurrentText={units =>
                            handleChangeUnitsOfGivenReference(units, selectedReferences, reference)
                          }
                          step={1}
                          min={0}
                          currentText={String(reference?.quantity || '')}
                          inputProps={{ type: 'number' }}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="containerStep1Button">
        <BordButton
          customHeight="42"
          label={t(`${i18nLanguageKey}nudosButton`)}
          customWidth="w-280"
          disabled={isButtonDisabled}
          onClick={handleClickContinue}
        />
      </div>
    </div>
  );
};

export default Step1SelectReferences;
