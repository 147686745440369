const SquareKazakhstan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#03BCDB" rx="2" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M2.0957 2.6613C2.0957 2.3701 2.33875 2.1333 2.61951 2.1333C2.90866 2.1333 3.14332 2.3637 3.14332 2.6613V4.8053C3.14332 5.0965 2.90027 5.3333 2.61951 5.3333C2.33037 5.3333 2.0957 5.1029 2.0957 4.8053V2.6613ZM3.14332 4.79463C3.14332 4.50343 3.38637 4.26663 3.66713 4.26663C3.95627 4.26663 4.19094 4.49703 4.19094 4.79463V6.93863C4.19094 7.22983 3.94789 7.46663 3.66713 7.46663C3.37799 7.46663 3.14332 7.23623 3.14332 6.93863V4.79463ZM3.14332 9.0613C3.14332 8.7701 3.38637 8.5333 3.66713 8.5333C3.95627 8.5333 4.19094 8.7637 4.19094 9.0613V11.2053C4.19094 11.4965 3.94789 11.7333 3.66713 11.7333C3.37799 11.7333 3.14332 11.5029 3.14332 11.2053V9.0613ZM2.0957 11.1946C2.0957 10.9034 2.33875 10.6666 2.61951 10.6666C2.90866 10.6666 3.14332 10.897 3.14332 11.1946V13.3386C3.14332 13.6298 2.90027 13.8666 2.61951 13.8666C2.33037 13.8666 2.0957 13.6362 2.0957 13.3386V11.1946ZM2.0957 6.9333C2.0957 6.6389 2.33875 6.39997 2.61951 6.39997C2.90866 6.39997 3.14332 6.64743 3.14332 6.9333C3.14332 7.2277 2.90027 7.46663 2.61951 7.46663C2.33037 7.46663 2.0957 7.21917 2.0957 6.9333ZM3.14332 2.66663C3.14332 2.37223 3.38637 2.1333 3.66713 2.1333C3.95627 2.1333 4.19094 2.38077 4.19094 2.66663C4.19094 2.96103 3.94789 3.19997 3.66713 3.19997C3.37799 3.19997 3.14332 2.9525 3.14332 2.66663ZM2.0957 7.99997C2.0957 7.70557 2.33875 7.46663 2.61951 7.46663C2.90866 7.46663 3.14332 7.7141 3.14332 7.99997C3.14332 8.29437 2.90027 8.5333 2.61951 8.5333C2.33037 8.5333 2.0957 8.28583 2.0957 7.99997ZM3.14332 13.3333C3.14332 13.0389 3.38637 12.8 3.66713 12.8C3.95627 12.8 4.19094 13.0474 4.19094 13.3333C4.19094 13.6277 3.94789 13.8666 3.66713 13.8666C3.37799 13.8666 3.14332 13.6192 3.14332 13.3333ZM2.0957 9.06663C2.0957 8.77223 2.33875 8.5333 2.61951 8.5333C2.90866 8.5333 3.14332 8.78077 3.14332 9.06663C3.14332 9.36103 2.90027 9.59997 2.61951 9.59997C2.33037 9.59997 2.0957 9.3525 2.0957 9.06663Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          d="M12.0478 9.59994C13.4942 9.59994 14.6668 8.40603 14.6668 6.93327C14.6668 5.46051 13.4942 4.2666 12.0478 4.2666C10.6013 4.2666 9.42871 5.46051 9.42871 6.93327C9.42871 8.40603 10.6013 9.59994 12.0478 9.59994Z"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M8.38086 7.4668L9.42848 9.60013L12.0475 10.6668L14.6666 9.60013L15.7142 7.4668V10.6668L12.0475 11.7335L8.38086 10.6668V7.4668Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareKazakhstan;
