import AdditionalServicesCommentsModalContent from './AdditionalServicesCommentsModalContent';
import { useTranslation } from 'react-i18next';
import BordModal from '../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './AdditionalServicesCommentsModal.scss';

const AdditionalServicesCommentsModal = ({
  closeModalCallback,
  submitModalCallback,
  currentComment
}: {
  closeModalCallback: () => void;
  submitModalCallback: (modalData: string) => void;
  currentComment?: string;
}) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:additionalServicesCommentsModalContent:';
  const subtitle = t(`${translationKey}subtitle`);
  return (
    <div className="AdditionalServicesCommentsModal">
      <BordModal
        closeModalCallback={closeModalCallback}
        modalContent={
          <ModalContent
            title="Configuraciones"
            subTitle={subtitle}
            customSubtitleClass="max-w-480"
            customWidth="58rem"
            customHeight="44rem"
            toCloseModal={!currentComment ? closeModalCallback : undefined}
            CustomModalComponent={
              <AdditionalServicesCommentsModalContent
                submitModalCallback={submitModalCallback}
                currentComment={currentComment}
              />
            }
          />
        }
      />
    </div>
  );
};

export default AdditionalServicesCommentsModal;
