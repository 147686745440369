import { memo, useEffect } from 'react';
import { TtoolCategories, valueKey } from '../../types/global';
import useShopStore from '../../state/useShopContext';
import { NudosToolTip } from '../NudosComponents';
import { segmentTrackEvent } from '../../utils/segment';
import { useTranslation } from 'react-i18next';
import { CtosTooltip, DepTooltip, GradientCategoryFilter, BgTooltip } from '../../assets/images/views/Ecommerce';
import { BordTwoToneIcon } from '../BordDesignSystem';
import { bordColorTheme } from '../../styles/constants';
import './horizontalFilters.scss';

export interface ICategoryDetails {
  value?: string | number | undefined;
  name?: TtoolCategories;
  nameStringId?: string;
}

function _HorizontalFilters({
  filters,
  name,
  resetPage,
  takeNameFromCategory
}: {
  filters: valueKey[];
  name: string;
  resetPage?: () => void;
  takeNameFromCategory?: ({ name, nameStringId }: ICategoryDetails) => void;
}) {
  const { t } = useTranslation();
  const { ecommerceFilters, setEcommerceFilters, categoryFilters, setPillsFilters } = useShopStore();
  const _filter = ecommerceFilters.find(item => item.name === name);

  const translationKey = 'nodi:checkout:';
  const activeButtonIcons = {
    tone1: bordColorTheme.primary.bgDefault,
    opacity1: '1',
    tone2: bordColorTheme.white.default,
    opacity2: '1'
  };
  const inActiveButtonIcons = {
    tone1: bordColorTheme.content.secondary,
    opacity1: '.4',
    tone2: bordColorTheme.content.secondary,
    opacity2: '1'
  };

  function setFilter({ value: _newValue }: ICategoryDetails) {
    const filtersToPreserve = ecommerceFilters.filter(filter =>
      ['disponibilidad', 'line', 'profile'].includes(filter.name)
    );
    const newParam = {
      name,
      includes: 'in',
      values: [_newValue]
    };
    setEcommerceFilters([...filtersToPreserve, newParam]);
    setPillsFilters([]);
  }

  const getFilterTooltip = (value: string) => {
    const filterIsLaptopDEP = value === '9';
    const tooltipTitle = filterIsLaptopDEP ? `Laptops ${t('nodi:dynamicBanner:with')} DEP` : 'Laptops CTOs';
    const tooltipDescription = filterIsLaptopDEP
      ? t(`${translationKey}filterIsLaptopDEP`)
      : t(`${translationKey}filterIsLaptopCTO`);
    const imgTooltip = filterIsLaptopDEP ? DepTooltip : CtosTooltip;
    const tooltipContent = (
      <div className="tooltipContent">
        <div className="tooltipHeader">
          <div className="containerImage" style={{ backgroundImage: `url("${BgTooltip}")` }}>
            <div className="imageBox">
              <img className="imgSize" src={imgTooltip} />
            </div>
          </div>
        </div>
        <div className="tooltipBody">
          <div className="tooltipTitle">
            <div className="text"> {tooltipTitle}</div>
            <div className="serviceBord">Servicio .bord</div>
          </div>
          <div className="tooltipDesc">{tooltipDescription}</div>
        </div>
      </div>
    );
    return (
      <div className="filtersTooltip">
        <NudosToolTip
          tooltipContent={tooltipContent}
          tooltipContentCustomStyles={{ width: '28rem', height: '27.6rem', padding: '0rem', borderRadius: '1.2rem' }}
        />
      </div>
    );
  };

  const variantIcon = (name: TtoolCategories) => {
    switch (name) {
      case 'Laptops':
        return 'laptopCharging';
      case 'Laptops con DEP':
        return 'macLaptopSettings';
      case 'Laptops CTOs':
        return 'laptopSettings';
      case 'Monitores':
        return 'computer';
      case 'Mobiliario':
        return 'computerDesk';
      default:
        return 'headset';
    }
  };

  useEffect(() => {
    if (name === 'category' && !ecommerceFilters.find(filter => filter.name === 'category') && categoryFilters) {
      const laptops = categoryFilters.find(filter => (filter.label = 'Laptops'));
      typeof laptops !== undefined || null
        ? setFilter({
            value: laptops?.value,
            name: laptops?.label as TtoolCategories,
            nameStringId: laptops?.nameStringId
          })
        : setFilter({
            value: categoryFilters[0]?.value,
            name: categoryFilters[0]?.label as TtoolCategories,
            nameStringId: categoryFilters[0]?.nameStringId
          });
      if (laptops && takeNameFromCategory) {
        takeNameFromCategory({
          name: laptops.label as TtoolCategories,
          nameStringId: laptops?.nameStringId || ''
        });
      }
    }
  }, [categoryFilters]);

  return (
    <div id="horizontal-filters-container">
      {filters.map(({ label, value, nameStringId }, index) => {
        const role = _filter?.values[0] === value ? 'active' : 'normal';
        const activeButton = role === 'active';

        const showToolTip = value === '9' || value === '10';
        const _setFilter = () => {
          setFilter({ value, name: label as TtoolCategories, nameStringId });
          if (resetPage) {
            resetPage();
          }
          if (takeNameFromCategory) {
            takeNameFromCategory({ name: label as TtoolCategories, nameStringId: nameStringId || '', value });
          }
          segmentTrackEvent({
            storeFilterCategory: {
              CategoryName: `${label}`
            }
          });
        };

        return (
          <button
            onClick={_setFilter}
            role={role}
            key={`${label}-${value}`}
            style={{
              marginLeft: `${index === 0 ? '0px' : ''}`,
              backgroundImage: role === 'active' ? ` url("${GradientCategoryFilter}")` : ''
            }}
          >
            <div className="containerImg">
              <div className="boxImg">
                <BordTwoToneIcon
                  variant={variantIcon(label as TtoolCategories)}
                  customWidth="32rem"
                  colorTones={activeButton ? activeButtonIcons : inActiveButtonIcons}
                />
              </div>
            </div>
            <div className="containerText">
              <div className="styleText"> {t(`services:filters:category:${nameStringId}`)}</div>
              {showToolTip && getFilterTooltip(value)}
            </div>
          </button>
        );
      })}
    </div>
  );
}

const HorizontalFilters = memo(_HorizontalFilters);
export default HorizontalFilters;
