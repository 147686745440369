const RoundTrinidadAndTobago = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M8.66318 1.77661C7.2308 2.51592 5.88624 3.48499 4.68549 4.68574C3.48468 5.88655 2.51562 7.23111 1.77637 8.66349L12.0643 19.9353L23.3361 30.2232C24.7686 29.4839 26.1131 28.5149 27.3139 27.3142C28.5147 26.1134 29.4837 24.7688 30.223 23.3364L19.9351 12.0645L8.66318 1.77661Z"
        />
        <path
          fill="black"
          d="M27.3138 27.3141C27.985 26.6429 28.5836 25.9267 29.1106 25.1755L6.82405 2.88892C6.07286 3.41585 5.35667 4.01448 4.68548 4.68567C4.0143 5.35685 3.41567 6.07304 2.88867 6.82429L25.1752 29.1108C25.9264 28.5839 26.6426 27.9853 27.3138 27.3141Z"
        />
        <path
          fill="#F93939"
          d="M4.68575 27.3143C9.73368 32.3622 17.3147 33.3312 23.3364 30.2233L1.77668 8.66357C-1.33113 14.6852-.361942 22.2663 4.68575 27.3143ZM27.3137 4.68583C22.2658-.36205 14.6847-1.33105 8.66309 1.77664L30.2228 23.3365C33.3306 17.3149 32.3616 9.7337 27.3137 4.68583Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTrinidadAndTobago;
