const RoundMorocco = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M25.4559 13.1307H18.2325L16.0004 6.26099L13.7683 13.1307H6.54492L12.3887 17.3765L10.1565 24.2464L16.0004 20.0006L21.8443 24.2465L19.6121 17.3765L25.4559 13.1307ZM14.0243 16.845L14.7791 14.522H17.2217L17.9765 16.845V16.8451L16.0004 18.2808L14.0244 16.8451L14.0243 16.845ZM16.7697 13.1307H15.2312L16.0004 10.7632L16.7697 13.1307ZM19.16 15.9852L18.6845 14.522H21.1739L19.16 15.9852ZM13.3162 14.522L12.8408 15.9852L10.8269 14.522H13.3162ZM12.803 20.6039L13.5722 18.2365L14.8169 19.1407L12.803 20.6039ZM17.1839 19.1408L18.4286 18.2365L19.1979 20.604L17.1839 19.1408Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMorocco;
