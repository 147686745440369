const RoundAustralia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16C0 16.0037 16 .0018125 16 0c8.8365 0 16 7.1635 16 16Z"
        />
        <path
          fill="#fff"
          d="M16.0007 0c-.0011 0-.0023.00007813-.0034.00007813L16.0007 0ZM15.9572 16H16v-.0428L15.9572 16Z"
        />
        <path
          fill="#fff"
          d="M16 8.34793V.00012207h-.0027C7.162.00155957 0 7.16437 0 16.0001h8.34781v-4.7008l4.70079 4.7008h2.9086c.0144-.0143.0286-.0285.0429-.0429v-2.9085l-4.7009-4.70077H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09469 2.08691C5.59226 3.51185 3.51188 5.59223 2.08694 8.09466V16h4.17394V6.26085H16V2.08691H8.09469Z"
        />
        <path fill="#F93939" d="m16 14.0324-5.6846-5.68447H8.34789c0-.00006 0 0 0 0L15.9999 16H16v-1.9676Z" />
        <path
          fill="#fff"
          d="m9.64969 18.7827.87811 1.8361 1.983-.4583-.888 1.8314 1.5946 1.2646-1.9855.4475.0056 2.0352-1.58781-1.2733-1.58775 1.2733.00556-2.0352-1.9855-.4475 1.59469-1.2646-.88813-1.8314 1.98307.4583.87806-1.8361ZM23.9553 22.2609l.439.918.9915-.2292-.444.9157.7973.6323-.9927.2238.0027 1.0176-.7938-.6367-.7939.6367.0027-1.0176-.9927-.2238.7974-.6323-.444-.9157.9914.2292.4391-.918ZM19.8708 12.5219l.439.9181.9915-.2293-.444.9157.7973.6323-.9927.2238.0027 1.0176-.7938-.6367-.7939.6367.0027-1.0176-.9927-.2238.7974-.6323-.444-.9157.9915.2293.439-.9181ZM23.9553 6.95654l.439.91813.9915-.22919-.444.91569.7973.63225-.9927.22381.0027 1.01767-.7938-.63673-.7939.63673.0027-1.01767-.9927-.22381.7973-.63225-.4439-.91569.9914.22919.4391-.91813ZM27.523 11.1305l.439.9181.9915-.2292-.444.9157.7973.6323-.9927.2238.0027 1.0175-.7938-.6366-.7939.6366.0027-1.0175-.9927-.2238.7973-.6323-.444-.9157.9915.2292.4391-.9181ZM24.9719 16l.3453 1.0629h1.1176l-.9042.6569.3455 1.0628-.9042-.6569-.9041.6569.3453-1.0628-.9042-.6569h1.1176L24.9719 16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAustralia;
