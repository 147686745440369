const SquareBolivia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#FFDA2C"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#03BCDB"
            fillRule="evenodd"
            d="M9.42856 7.46655h1.04764v1.06667H9.42856V7.46655Zm2.09524 0h1.0476v1.06667h-1.0476V7.46655Z"
            clipRule="evenodd"
          />
          <path
            fill="#AF010D"
            fillRule="evenodd"
            d="M12.5714 7.46657h1.0476V9.5999h-1.0476V7.46657ZM11.5238 6.3999h1.0476v1.06667h-1.0476V6.3999Zm-2.09523 0h1.04763v1.06667H9.42857V6.3999ZM8.38095 7.46657h1.04762V9.5999H8.38095V7.46657Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M10.4762 8.53345h1.0476v1.06666h-1.0476V8.53345Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M10.4762 6.3999h1.0476v1.06667h-1.0476V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M11.5238 8.53345h1.0476v1.06666h-1.0476V8.53345Zm-2.09524 0h1.04764v1.06666H9.42856V8.53345Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M10.4762 7.46655h1.0476v1.06667h-1.0476V7.46655ZM0 10.6667h22v5.3334H0v-5.3334Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M0 0h22v5.33333H0V0Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBolivia;
