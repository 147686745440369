const SquareGreece = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path
          fill="#1A47B8"
          fillRule="evenodd"
          d="M0 0H4.19048V4.26667H0V0ZM6.28571 0H10.4762V4.26667H6.28571V0ZM0 6.4H4.19048V10.6667H0V6.4ZM6.28571 6.4H10.4762V10.6667H6.28571V6.4ZM10.4762 0H22V2.13333H10.4762V0ZM10.4762 4.26667H22V6.4H10.4762V4.26667Z"
          clipRule="evenodd"
        />
        <path
          fill="#1A47B8"
          fillRule="evenodd"
          d="M0 8.5332H22V10.6665H0V8.5332ZM4.19048 8.5332H6.28571V10.6665H4.19048V8.5332ZM0 12.7999H22V14.9332H0V12.7999Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareGreece;
