import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import tems from '../../../../assets/docs/terms.pdf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import privacy from '../../../../assets/docs/privacy.pdf';
import BordCheckBoxButton from '../../../../components/BordDesignSystem/BordCheckBox/BordCheckBoxButton';
import './TermsAndConditionsCheckmark.scss';

interface ICustomCheck {
  checked: boolean;
  onChangeState: () => void;
  type: string;
}

const TermsAndConditionsCheckmark: FC<ICustomCheck> = ({ checked, onChangeState, type }: ICustomCheck) => {
  const { t } = useTranslation();
  const translationKey = 'authentication:SignUpForm:formFieldsStep3:';
  const redirectionLinkStyles = 'text-primary-bg-default cursor-pointer underline';
  const isTermsAndConditions = type === 'terms';
  const termsText = t(
    `${translationKey}${isTermsAndConditions ? 'termsAndConditionsCheckmark' : 'privacyPolicyCheckmark'}`
  );

  const changeState = () => onChangeState();
  const openTerms = () => window.open(tems, '_blank');
  const openSecondTerms = () => window.open(privacy, '_blank');
  const handleClick = isTermsAndConditions ? openTerms : openSecondTerms;

  return (
    <div className="termsAndConditionsCheckmark">
      <BordCheckBoxButton
        isActive={checked ? 'active' : undefined}
        handleClickButton={changeState}
        isButtonActive={checked}
      />
      <div className="textCheck" onClick={handleClick}>
        <Trans i18nKey={termsText} components={{ 1: <span className={`${redirectionLinkStyles}`} /> }} />
      </div>
    </div>
  );
};

export default TermsAndConditionsCheckmark;
