const RoundLatvia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M15.9996 0C8.60716 0 2.38678 5.01381.550781 11.8261H31.4485C29.6124 5.01381 23.392 0 15.9996 0ZM15.9996 31.9999C23.392 31.9999 29.6124 26.9861 31.4484 20.1738H.550781C2.38678 26.986 8.60716 31.9999 15.9996 31.9999Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLatvia;
