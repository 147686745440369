import { AxiosError } from 'axios';
import { apiPut } from './api';
import { usersAPI } from '../constants';

interface ICheckInfo {
  phone: string | number;
  email: string;
}

export const verifyPhoneAndEmail = async (body: ICheckInfo): Promise<string> => {
  return apiPut(`${usersAPI}/user/validate-email-phone`, body).then(response =>
    Promise.resolve(response).catch((error: AxiosError) => Promise.reject(error))
  );
};
