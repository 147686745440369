const SquareEritrea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#3A99FF" rx="2" />
          <path
            fill="#3A99FF"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#249F58" fillRule="evenodd" d="M0 0H22V8H0V0Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 0L22 8L0 16V0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M9.42829 7.99994C9.42829 6.52794 8.25496 5.33328 6.80925 4.79994C5.36353 5.33328 4.1902 6.52794 4.1902 7.99994C4.1902 9.29061 5.09115 10.3658 6.28544 10.6133V6.93114C6.28544 6.63781 6.52848 6.39994 6.80925 6.39994C7.09839 6.39994 7.33305 6.62928 7.33305 6.93114V10.6133C8.52734 10.3658 9.42829 9.29061 9.42829 7.99994ZM10.4759 7.99994C10.4759 5.93808 8.83429 4.26661 6.80925 4.79994C4.7842 4.26661 3.14258 5.93808 3.14258 7.99994C3.14258 10.0618 4.7842 11.7333 6.80925 11.7333C8.83429 11.7333 10.4759 10.0618 10.4759 7.99994Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEritrea;
