import { useTranslation } from 'react-i18next';
import BordIlustrationIcon from '../../../../BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import './NoItemsInCartSummary.scss';

const NoItemsInCartSummary = () => {
  const { t } = useTranslation();

  const translationKey = 'nodi:headBar:headerShoppingCartSummary:';
  const title = t(`${translationKey}textEmptyCart`);
  const subtitle = t(`${translationKey}verifyAddedProductsSection`);

  return (
    <div className="noItemsInCartSummary">
      <BordIlustrationIcon variant="specialMarketPlace3dFilledCart" customWidth="4.8rem" />
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
    </div>
  );
};

export default NoItemsInCartSummary;
