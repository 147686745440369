const RoundGreece = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#3ECBF8"
          d="M16 11.826H31.4488C31.0476 10.3377 30.4379 8.9351 29.6516 7.6521H16V11.826ZM6.04045 28.5218H25.96C27.4202 27.3588 28.6724 25.9462 29.6518 24.3479H2.34863C3.32807 25.9462 4.58032 27.3588 6.04045 28.5218ZM5.56545 3.87109C4.30757 4.95428 3.22013 6.22984 2.34863 7.65209H5.56545V3.87109ZM16 16.0001C16 14.3704 16 13.0064 16 11.8262H9.73913V16.0001H5.56519V11.8262H.551188C.1925 13.157 0 14.5559 0 16.0001 0 17.4443.1925 18.8432.551188 20.174H31.4489C31.8075 18.8432 32 17.4443 32 16.0001H16Z"
        />
        <path
          fill="#3ECBF8"
          d="M16.0001 0C13.7783 0 11.6624 0.453312 9.73926 1.27175V7.65219H16.0001C16.0001 6.19519 16.0001 4.956 16.0001 3.47825H25.9599C23.2268 1.3015 19.7657 0 16.0001 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGreece;
