import './NudosSpinnerLoader.scss';

/**
 * IMPORTANT: ringColor and arcColor, if provided, must be valid values for the a CSS color
 * @property {number} borderWidth - Optional, the width of the rotating spinner in pixels, in other words the border width. DEFAULT 11px.
 * @property {number} ringColor - Optional, the color of the  rotating spinner. DEFAULT #E6E6E6.
 * @property {number} arcColor - Optional, the color of the  arc that marks a turn of the spinner. DEFAULT white.
 * @returns
 */
const NudosSpinnerLoader = ({
  borderWidth,
  ringColor,
  arcColor,
  percentageSpinner
}: {
  borderWidth?: number;
  ringColor?: string;
  arcColor?: string;
  percentageSpinner?: number;
}) => {
  return (
    <div
      className="nudosSpinnerLoader"
      style={{
        width: percentageSpinner ? `${percentageSpinner}%` : '',
        height: percentageSpinner ? `${percentageSpinner}%` : ''
      }}
    >
      <div className="spinner" style={{ borderWidth: borderWidth, borderColor: ringColor, borderTopColor: arcColor }} />
    </div>
  );
};

export default NudosSpinnerLoader;
