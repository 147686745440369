import create from 'zustand';
import { IExistingEmployeeInfo } from '../types/global';

export interface iState {
  items: number;
  employeeToAssign?: IExistingEmployeeInfo;
  increaseItems: () => void;
  removeAllItems: () => void;
  setEmployeeToAssign: (employeeToAssign?: IExistingEmployeeInfo) => void;
}
const useStore = create<iState>(set => ({
  items: 0,
  employeeToAssign: undefined,
  increaseItems: () => set(state => ({ items: state.items + 1 })),
  removeAllItems: () => set({ items: 0 }),
  setEmployeeToAssign: employeeToAssign => set({ employeeToAssign })
}));

export default useStore;
