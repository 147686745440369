import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import LogisticServiceDefinitiveTrackingData from './LogisticServiceDefinitiveTrackingData';
import LogisticServiceInitialTrackingData from './LogisticServiceInitialTrackingData';
import './LogisticServiceTrackingSection.scss';

const LogisticServiceTrackingSection = ({
  logisticServiceData,
  isLoading = false,
  haveSomeDeliveredData
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  isLoading?: boolean;
  haveSomeDeliveredData?: boolean;
}) => {
  const showInitialLogisticsData = ['por confirmar', 'confirmado', 'en proceso'].includes(
    logisticServiceData?.status || ''
  );
  const serviceIsCanceled = logisticServiceData?.status === 'cancelado';
  if (isLoading || !logisticServiceData) return <LogisticServiceTrackingSectionSkeletonLoader />;

  return (
    <div className="logisticServiceTrackingSection">
      {showInitialLogisticsData && <LogisticServiceInitialTrackingData logisticServiceData={logisticServiceData} />}
      {!showInitialLogisticsData && !serviceIsCanceled && haveSomeDeliveredData && (
        <LogisticServiceDefinitiveTrackingData logisticServiceData={logisticServiceData} />
      )}
    </div>
  );
};

export default LogisticServiceTrackingSection;

const LogisticServiceTrackingSectionSkeletonLoader = () => {
  return (
    <div className="logisticServiceTrackingSectionSkeletonLoader">
      <div className="initialEstimatedDatesSection">
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
      </div>
      <div className="trackingDataSection">
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
        <div className="dataField">
          <div className="dataTitle animationLoader" />
          <div className="dataContent animationLoader" />
        </div>
      </div>
    </div>
  );
};
