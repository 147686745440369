import BordActionButton from '../../../../../components/BordDesignSystem/BordActionButton/BordActionButton';
import { TbordOneToneIcon } from '../../../../../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import './ToolManagementActions.scss';

const ToolManagementActionsCard = ({
  cardOptions,
  clickCardCallback,
  showSkeletonLoader
}: {
  cardOptions: ImanagementActionCard;
  clickCardCallback: () => void;
  showSkeletonLoader?: boolean;
}) => {
  const { icon, isActive, hoverComponent, titleFirstLine, titleSecondLine, handleSelectThisDestination } = cardOptions;

  const handleClickCard = () => {
    if (!isActive) return;
    handleSelectThisDestination();
    return clickCardCallback();
  };

  if (showSkeletonLoader) return <div className="ToolManagementActionsCard animationLoader skeletonLoader" />;
  return (
    <div className="showHover">
      {!isActive && hoverComponent}
      <BordActionButton
        disabled={!isActive}
        variant={icon as TbordOneToneIcon}
        title={titleFirstLine}
        subTitle={titleSecondLine}
        onClick={handleClickCard}
      />
    </div>
  );
};

export default ToolManagementActionsCard;

export interface ImanagementActionCard {
  order: number;
  showCard: boolean;
  isActive: boolean;
  icon: string;
  hoverComponent: JSX.Element;
  titleFirstLine: string;
  titleSecondLine: string;
  handleSelectThisDestination: () => void;
}
