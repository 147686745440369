const RoundMyanmar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#6DA544"
          d="M31.0048 21.5651C31.648 19.8319 32 17.9571 32 16C32 14.0428 31.648 12.168 31.0048 10.4348L16 9.04346L0.995188 10.4348C0.352063 12.168 0 14.0428 0 16C0 17.9571 0.352063 19.8319 0.995188 21.5651L16 22.9565L31.0048 21.5651Z"
        />
        <path
          fill="#FFDA2C"
          d="M31.0047 10.4348C28.7441 4.342 22.8794 0 15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0047Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="white"
          d="M26.9719 13.5366H18.5903L16.0001 5.56519L13.41 13.5366H5.02832L11.8093 18.4632L9.21913 26.4348L16.0001 21.5652L22.7811 26.4347L20.1911 18.4632L26.9719 13.5366Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMyanmar;
