import { useTranslation } from 'react-i18next';
import { IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import { convertDateToLocaleString } from '../../../../../../utils/formatDatesAndHours';
import LogisticsStatusDisplay from '../../../Components/LogisticsStatusDisplay/LogisticsStatusDisplay';
import './LogisticsServiceDetailsHeader.scss';

/**
 * @property {IorderDetails} logisticServiceData - Required, the information of the logistic service with the specified id. This component required the properties id, productQuantity, createdAt, status of the logisticServiceData to correctly display its information.
 * @property {boolean} isLoading - Optional, a boolean indicating if the component is loading and consequently must show the skeleton loader. Default is false
 */
const LogisticsServiceDetailsHeader = ({
  logisticServiceData,
  isLoading = false
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const translationKey = 'nodi:logisticServices:logisticsServiceDetailsHeader:';
  const createdAtText = t(`${translationKey}createdAtText`);

  const { id, productQuantity, createdAt, statusStringId, status } = logisticServiceData || {};
  const logisticServiceIdText = `SL Nº ${id || '---'}`;
  const toolsCountText = `${productQuantity || '---'} ${
    productQuantity === 1 ? t('recurrentWords:device') : t('recurrentWords:devices')
  }`;
  const creationDateText = `${createdAtText} ${
    createdAt ? convertDateToLocaleString(createdAt)?.textWithTimezone : '---'
  }`;

  if (isLoading) return <LogisticsServiceDetailsHeaderSkeletonLoader />;
  return (
    <div className="logisticsServiceDetailsHeader">
      <div className="logisticServiceId">{logisticServiceIdText}</div>
      <div className="toolsCount">{toolsCountText}</div>
      <div className="creationDate">{creationDateText}</div>
      <LogisticsStatusDisplay statusName={status || ''} statusStringId={statusStringId || ''} />
    </div>
  );
};

const LogisticsServiceDetailsHeaderSkeletonLoader = () => {
  return (
    <div className="logisticsServiceDetailsHeaderSkeletonLoader">
      <div className="logisticServiceId">
        <div className="animationLoader" />
      </div>
      <div className="toolsCount">
        <div className="animationLoader" />
      </div>
      <div className="creationDate">
        <div className="animationLoader" />
      </div>
      <div className="logisticsStatusDisplaySkeleton animationLoader" />
    </div>
  );
};

export { LogisticsServiceDetailsHeader, LogisticsServiceDetailsHeaderSkeletonLoader };
