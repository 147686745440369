const RoundMauritius = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M1.94531 8.34781L15.9999 9.73913L30.0544 8.34781C27.3408 3.37431 22.0646 0 15.9999 0C9.93519 0 4.659 3.37431 1.94531 8.34781Z"
        />
        <path
          fill="#FFDA2C"
          d="M1.94544 23.6521L16 25.0435L30.0546 23.6521C31.2948 21.379 32 18.7718 32 16L16 14.6086L0 16C0 18.7718 0.705188 21.379 1.94544 23.6521Z"
        />
        <path
          fill="#1A47B8"
          d="M1.94544 8.3479C0.705188 10.6211 0 13.2282 0 16.0001H32C32 13.2282 31.2948 10.6211 30.0546 8.3479H16H1.94544Z"
        />
        <path
          fill="#6DA544"
          d="M15.9999 31.9999C22.0646 31.9999 27.3408 28.6256 30.0544 23.6521H1.94531C4.659 28.6256 9.93519 31.9999 15.9999 31.9999Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMauritius;
