const RoundBelgium = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M21.5652.995188C19.8319.352063 17.9571 0 16 0s-3.8319.352063-5.5652.995188L9.04349 16l1.39131 15.0048C12.1681 31.648 14.0429 32 16 32s3.8319-.352 5.5652-.9952L22.9565 16 21.5652.995188Z"
        />
        <path
          fill="#F93939"
          d="M32 16C32 9.12061 27.658 3.25586 21.5652.995239V31.0049C27.658 28.7441 32 22.8795 32 16Z"
        />
        <path fill="#000" d="M0 16c0 6.8795 4.342 12.7441 10.4348 15.0049V.995239C4.342 3.25586 0 9.12062 0 16Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBelgium;
