import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isRouteMyLocation } from '../../../utils/getPathRoute';
import useEcommerceControler from '../../../views/ecommerce/ecommerce.controller';
import EcommerceSearchProduct from './EcommerceSearchProduct/EcommerceSearchProduct';
import useCheckoutStore from '../../../views/ecommerce/checkout/state';
import HeaderBreadcrumbsButton from '../HeaderBreadcrumbsButton/HeaderBreadcrumbsButton';
import { CountryButton } from '../../CountryButton';
import BordMarketplaceButton from '../../BordDesignSystem/BordMarketplaceButton/BordMarketplaceButton';
import ShoppingCartButton from './ShoppingCartButton/ShoppingCartButton';
import { BordButton } from '../../BordDesignSystem';
import BadgePlatinum from '../../BadgePlatinum';
import './EcommerceHeader.scss';

function EcommerceHeader() {
  const { push } = useHistory();
  const { orgData } = useEcommerceControler();
  const { step: checkoutStep } = useCheckoutStore();
  const isCheckout = isRouteMyLocation('checkout');
  const isCheckoutLastStep = isCheckout && checkoutStep === 3;
  const isEcommerceHome = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('home', 2);
  const isEcommerceSearch = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('search', 2);
  const isEcommercProductDetails = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('product', 2);
  const isAccount = isRouteMyLocation('nodi', 1) && isRouteMyLocation('account', 2);
  const accountModuleStyles = isAccount ? 'accountModule' : '';
  const [openModal, setOpenModal] = useState<boolean>(false);
  const displaySearchProduct = isEcommerceHome || isEcommerceSearch || isEcommercProductDetails;
  const showEcommerceButton = isCheckout && !isCheckoutLastStep;
  const storeButtonText = '.bord Store';
  const isPathCatalogue = isRouteMyLocation('catalogue', 1) && isRouteMyLocation('checkout', 2);
  const redirectToPath = (path: string) => {
    if (orgData?.organizationState !== 'active') return;
    else push(path);
  };

  const goToCatalogue = () => {
    redirectToPath('/catalogue/home');
  };

  const goToSaas = () => redirectToPath('/nodi/dashboard');

  const handleChangeStatusModal = (status: boolean) => {
    setOpenModal(status);
  };
  const ContentTitleHeader = () => {
    return (
      <div className="contentTitleHeader">
        <HeaderBreadcrumbsButton />
        <BadgePlatinum />
      </div>
    );
  };

  return (
    <header
      id="ecommerceHeader"
      className={`${accountModuleStyles} ${openModal ? 'checkoutModalOpen' : ''} ${
        isPathCatalogue ? 'isPathCatalogue' : ''
      }`}
    >
      <section className="headerLeftSection">
        <ContentTitleHeader />

        {displaySearchProduct && <EcommerceSearchProduct />}
      </section>
      <section id="headerRight" className="headerRightSection">
        <div className="navigationButtonsContainer">
          {showEcommerceButton && (
            <BordMarketplaceButton buttonText={storeButtonText} buttonColor="dark" onClick={goToCatalogue} />
          )}
          {(!isCheckout || isCheckoutLastStep) && (
            <BordButton modeButton="quaternary" buttonIconOne="bord" label="Dash.bord" onClick={goToSaas} />
          )}
          <ShoppingCartButton />
        </div>
        <CountryButton openModal={openModal} handleChangeStatusModal={handleChangeStatusModal} />
      </section>
    </header>
  );
}

export default EcommerceHeader;
