const SquareBahrain = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 0h7.33333v16H0V0Zm7.33333 0 2.09524 1.6-2.09524 1.6V0Zm0 3.2 2.09524 1.6-2.09524 1.6V3.2Zm0 3.2L9.42857 8 7.33333 9.6V6.4Zm0 3.2 2.09524 1.6-2.09524 1.6V9.6Zm0 3.2 2.09524 1.6L7.33333 16v-3.2Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBahrain;
