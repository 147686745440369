const RoundIsrael = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M22.0247 12.5217H18.0083L16.0001 9.04346 13.9921 12.5217H9.97559L11.984 16 9.97559 19.4782H13.9921L16.0001 22.9565 18.0083 19.4782H22.0247L20.0163 16 22.0247 12.5217ZM18.4673 16 17.2338 18.1367H14.7666L13.533 16 14.7666 13.8632H17.2338L18.4673 16ZM16.0001 11.7264 16.4593 12.5216H15.5411L16.0001 11.7264ZM12.2993 13.8632H13.2175L12.7584 14.6585 12.2993 13.8632ZM12.2993 18.1367 12.7585 17.3415 13.2176 18.1367H12.2993ZM16.0001 20.2735 15.5411 19.4782H16.4593L16.0001 20.2735ZM19.701 18.1367H18.7828L19.242 17.3415 19.701 18.1367ZM18.7828 13.8632H19.701L19.2419 14.6585 18.7828 13.8632ZM25.9601 3.47827H6.04045C4.58026 4.64127 3.32801 6.0539 2.34863 7.65221H29.6519C28.6724 6.05396 27.4202 4.64127 25.9601 3.47827ZM6.04045 28.5218H25.96C27.4202 27.3588 28.6724 25.9462 29.6518 24.3479H2.34863C3.32807 25.9462 4.58032 27.3588 6.04045 28.5218Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIsrael;
