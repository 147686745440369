const RoundKazakhstan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M25.0431 16.1738H6.95605C6.95605 17.4225 8.04362 18.4347 9.29218 18.4347H9.21693C9.21693 19.6834 10.2291 20.6956 11.4778 20.6956C11.4778 21.9443 12.49 22.9565 13.7387 22.9565H18.2604C19.5091 22.9565 20.5213 21.9443 20.5213 20.6956C21.77 20.6956 22.7822 19.6834 22.7822 18.4347H22.7069C23.9554 18.4347 25.0431 17.4225 25.0431 16.1738Z"
        />
        <path
          fill="#3ECBF8"
          d="M22.261 13.2173C22.261 16.675 19.4579 19.4782 16.0001 19.4782C12.5423 19.4782 9.73926 16.675 9.73926 13.2173"
        />
        <path
          fill="#FFDA2C"
          d="M20.7821 13.2174L18.8281 14.1366L19.8686 16.0291L17.7468 15.6232L17.4779 17.7667L16 16.1901L14.522 17.7667L14.2531 15.6232L12.1313 16.029L13.1718 14.1365L11.2178 13.2174L13.1718 12.2983L12.1313 10.4058L14.2531 10.8117L14.522 8.66821L16 10.2448L17.478 8.66821L17.7468 10.8117L19.8687 10.4058L18.8281 12.2983L20.7821 13.2174Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundKazakhstan;
