import create from 'zustand';
import {
  ImoduleFilter,
  IdeliveryAddress,
  InudoInfo,
  IpriceInformation,
  IproductInfo,
  ObjectKey,
  valueKey,
  IParam
} from '../types/global';
import { ICartInfo } from '../types/cart';
import { IProductItemList } from '../types/catalogue';

type IShopProductPick = Pick<IproductInfo, 'productId' | 'commercialName'>;
export interface IShopProduct extends IShopProductPick {
  quantity: number;
  duration: number;
  currentPriceInfo: IpriceInformation;
  priceRanges: IpriceInformation[];
  brandImage: string;
  brandName: string;
  pills?: ObjectKey<string>;
  category: string;
  categoryId: number;
}
export interface IShopState {
  items: IShopProduct[];
  nudos: InudoInfo[];
  ecommerceFilters: IParam[];
  boardFilters: ImoduleFilter[];
  deliveryCost: number;
  addressCost: number;
  address?: IdeliveryAddress;
  priceFilters?: valueKey[];
  pillsFilters?: ObjectKey<valueKey[]>[];
  categoryFilters?: valueKey[];
  searchResult?: IProductItemList[];
  searchString?: string;
  totalCost?: number;
  totalProducts?: number;
  loadingFilterPills?: boolean;
  searchbarString?: string;
  setSearchbarString: (searchbarString: string) => void;
  setEcommerceFilters: (newFilter: IParam[]) => void;
  setBoardFilters: (newFilter: ImoduleFilter[]) => void;
  addOrUpdateProduct: (product: IShopProduct) => void;
  removeProduct: (product: IShopProduct) => void;
  removeAllItems: () => void;
  fillItems: () => void;
  setNudos: (filteredNudos: InudoInfo[]) => void;
  setDeliveryCost: (newCost: number) => void;
  setAddressCost: (newCost: number) => void;
  setAddress: (newAddress: IdeliveryAddress) => void;
  setPriceFilters: (priceFilters: valueKey[]) => void;
  setPillsFilters: (pillsFilters: ObjectKey<valueKey[]>[]) => void;
  setCategoryFilters: (categoryFilters: valueKey[]) => void;
  setSearchResult: (newSearchResult: IProductItemList[]) => void;
  setSearchString: (newSetSearchString: string) => void;
  setTotalCost: (newTotalCost: number) => void;
  setTotalProducts: (newTotalProducts: number) => void;
  setLoadingFilterPills: (loadingFilterPills: boolean) => void;

  itemsCart: ICartInfo | undefined;
  setCart: (itemsCart: ICartInfo | undefined) => void;
}

const useShopStore = create<IShopState>(set => ({
  items: [],
  nudos: [],
  ecommerceFilters: [],
  boardFilters: [],
  deliveryCost: 0,
  addressCost: 0,
  address: undefined,
  priceFilters: undefined,
  pillsFilters: undefined,
  categoryFilters: undefined,
  searchResult: [],
  totalCost: undefined,
  totalProducts: undefined,
  loadingFilterPills: false,
  itemsCart: undefined,
  searchbarString: undefined,
  addOrUpdateProduct: product =>
    set(state => {
      const newItems = state.items;
      const index = newItems.findIndex(
        item => item.productId === product.productId && item.duration === product.duration
      );
      if (index >= 0) {
        newItems[index] = product;
      } else {
        newItems.push(product);
      }
      localStorage.setItem('bagItems', JSON.stringify(newItems));
      return { items: newItems };
    }),
  removeProduct: product =>
    set(state => {
      const newItems = state.items;
      const index = newItems.findIndex(
        item => item.productId === product.productId && item.duration === product.duration
      );
      if (index >= 0) {
        newItems.splice(index, 1);
      }
      localStorage.setItem('bagItems', JSON.stringify(newItems));
      return { items: newItems };
    }),
  removeAllItems: () =>
    set(() => {
      localStorage.removeItem('bagItems');
      return { items: [] };
    }),
  fillItems: () =>
    set(() => {
      const bagItem = localStorage.getItem('bagItems');
      if (bagItem) {
        const newBagItem = JSON.parse(bagItem);
        const filterBag =
          newBagItem && newBagItem.length > 0 ? newBagItem.filter((item: IShopProduct) => item.priceRanges) : [];
        if (newBagItem && newBagItem.length) {
          localStorage.setItem('bagItems', JSON.stringify(filterBag));
        }
        return { items: filterBag };
      }
      return { items: [] };
    }),
  setNudos: filteredNudos => set(() => ({ nudos: filteredNudos })),
  setEcommerceFilters: newFilters => set(() => ({ ecommerceFilters: newFilters })),
  setBoardFilters: newFilters => set(() => ({ boardFilters: newFilters })),
  setDeliveryCost: newCost => set(() => ({ deliveryCost: newCost })),
  setAddressCost: newCost => set(() => ({ deliveryCost: newCost })),
  setAddress: newAddress => set(() => ({ address: newAddress })),
  setPriceFilters: priceFilters => set({ priceFilters: priceFilters }),
  setCategoryFilters: categoryFilters => set({ categoryFilters }),
  setSearchResult: newSearchResult => set({ searchResult: newSearchResult }),
  setSearchString: newSearchString => set({ searchString: newSearchString }),
  setPillsFilters: pillsFilters => set({ pillsFilters }),
  setTotalCost: newTotalCost => set({ totalCost: newTotalCost }),
  setTotalProducts: newTotalProducts => set({ totalProducts: newTotalProducts }),
  setLoadingFilterPills: loadingFilterPills => set({ loadingFilterPills }),
  setCart: itemsCart => set({ itemsCart }),
  setSearchbarString: searchbarString => set({ searchbarString })
}));

export default useShopStore;
