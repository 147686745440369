import { bordSignInImage } from '../../../../assets/images/views/signIn';
import './LogInImage.scss';

const LogInImage = () => {
  return (
    <div className="logInImage">
      <div className="imageContainer">
        <img src={bordSignInImage} />
      </div>
    </div>
  );
};

export default LogInImage;
