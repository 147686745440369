import type { ComponentProps, FC } from 'react';
import PlatinumLevel from './components/PlatinumLevel';
import StandardLevel from './components/StandardLevel';

interface BordPlatformLevelProps extends ComponentProps<'div'> {
  platformLevel?: boolean;
}

const BordPlatformLevel: FC<BordPlatformLevelProps> = ({ platformLevel = false, ...rest }) => {
  const renderPlatformLevel = platformLevel ? <PlatinumLevel /> : <StandardLevel />;
  return (
    <div className="BordPlatformLevel" {...rest}>
      {renderPlatformLevel}
    </div>
  );
};

export default BordPlatformLevel;
