const RoundTurkmenistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M16 0C14.8054 0 13.6417.132063 12.5218.380313L11.8262 15.9999 12.5218 31.6196C13.6417 31.8679 14.8054 32 16 32 24.8365 32 32 24.8365 32 15.9999 31.9999 7.16344 24.8365 0 16 0V0ZM4.17387 5.22314C1.58112 8.06664 0 11.8485 0 15.9999 0 20.1513 1.58119 23.9331 4.17394 26.7768L4.86956 15.9999 4.17387 5.22314Z"
        />
        <path
          fill="white"
          d="M23.4009 12.0685C24.1881 9.87437 23.3037 7.48806 21.4058 6.29956 21.7472 6.344 22.0889 6.42437 22.4262 6.54531 24.9576 7.45356 26.2735 10.2418 25.3652 12.7732 24.457 15.3047 21.6687 16.6205 19.1373 15.7122 18.8 15.5912 18.485 15.4359 18.1934 15.2532 20.414 15.5424 22.6137 14.2626 23.4009 12.0685ZM19.0358 5.69824 18.3517 6.58212 17.2999 6.20468 17.9291 7.1283 17.2451 8.01205 18.3179 7.69918 18.9472 8.62268 18.9809 7.50568 20.0538 7.19274 19.002 6.81543 19.0358 5.69824Z"
        />
        <path
          fill="white"
          d="M20.8825 8.57812 20.1985 9.46194 19.1466 9.0845 19.7758 10.0081 19.0918 10.8919 20.1646 10.5789 20.7939 11.5026 20.8277 10.3855 21.9005 10.0725 20.8487 9.69512 20.8825 8.57812ZM15.779 6.74707 15.0949 7.63082 14.0431 7.25351 14.6723 8.17707 13.9883 9.06088 15.061 8.74794 15.6903 9.67151 15.7242 8.55451 16.797 8.24157 15.7452 7.86413 15.779 6.74707ZM15.7038 10.0459 15.0198 10.9297 13.968 10.5523 14.5972 11.476 13.9131 12.3597 14.986 12.0468 15.6152 12.9703 15.649 11.8533 16.7219 11.5404 15.67 11.163 15.7038 10.0459ZM18.8425 11.1724 18.1584 12.0561 17.1066 11.6787 17.7359 12.6024 17.0518 13.4861 18.1247 13.1732 18.7539 14.0968 18.7877 12.9797 19.8606 12.6668 18.8086 12.2894 18.8425 11.1724Z"
        />
        <path
          fill="#F93939"
          d="M4.17383 5.22325V26.7768C6.35383 29.1677 9.24945 30.8943 12.5216 31.6196V0.380371C9.24939 1.10575 6.35389 2.83231 4.17383 5.22325Z"
        />
        <path
          fill="white"
          d="M7.35948 8.42413L5.96191 7.383V5.91069L7.35948 4.86963H9.33604L10.7336 5.91069V7.383L9.33604 8.42413H7.35948Z"
        />
        <path
          fill="#FF9811"
          d="M8.34779 4.86963H7.35948L5.96191 5.91069V6.6305H8.34779V4.86963ZM8.34766 8.42402H9.33591L10.7335 7.3829V6.63184H8.34766V8.42402Z"
        />
        <path
          fill="white"
          d="M7.35948 27.1302L5.96191 26.0891V24.6168L7.35948 23.5757H9.33604L10.7336 24.6168V26.0891L9.33604 27.1302H7.35948Z"
        />
        <path
          fill="#FF9811"
          d="M8.34779 23.5757H7.35948L5.96191 24.6168V25.3366H8.34779V23.5757ZM8.34766 27.13H9.33591L10.7335 26.0889V25.3379H8.34766V27.13Z"
        />
        <path
          fill="#496E2D"
          d="M7.35948 17.7772 5.96191 16.736V15.2638L7.35948 14.2227H9.33604L10.7336 15.2638V16.736L9.33604 17.7772H7.35948ZM10.7826 20.0677H10.1738V19.459H9.10415L8.34777 18.7026 7.59146 19.459H6.52177V20.0677H5.91309V21.2851H6.52177V21.8938H7.5914L8.34784 22.6502 9.10415 21.8938H10.1738V21.2851H10.7826V20.0677ZM10.7826 10.7147H10.1738V10.106H9.10415L8.34777 9.34961 7.59146 10.106H6.52177V10.7147H5.91309V11.9321H6.52177V12.5408H7.5914L8.34784 13.2973 9.10415 12.5408H10.1738V11.9321H10.7826V10.7147Z"
        />
        <path
          fill="#F93939"
          d="M7.65234 15.3042H9.04366V16.6955H7.65234V15.3042ZM8.34797 12.0187C8.73215 12.0187 9.04359 11.7072 9.04359 11.3231 9.04359 10.9389 8.73215 10.6274 8.34797 10.6274 7.96379 10.6274 7.65234 10.9389 7.65234 11.3231 7.65234 11.7072 7.96379 12.0187 8.34797 12.0187ZM8.34797 21.3722C8.73215 21.3722 9.04359 21.0608 9.04359 20.6766 9.04359 20.2924 8.73215 19.981 8.34797 19.981 7.96379 19.981 7.65234 20.2924 7.65234 20.6766 7.65234 21.0608 7.96379 21.3722 8.34797 21.3722Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTurkmenistan;
