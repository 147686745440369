const RoundPortugal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#6DA544"
          d="M0 15.9999C0 22.8793 4.342 28.744 10.4347 31.0047L11.8261 15.9999L10.4347 0.995117C4.342 3.25587 0 9.12049 0 15.9999Z"
        />
        <path
          fill="#F93939"
          d="M31.9999 16C31.9999 7.1635 24.8364 0 15.9999 0C14.0428 0 12.1679 0.352063 10.4346 0.995188V31.0048C12.1679 31.648 14.0428 32 15.9999 32C24.8364 32 31.9999 24.8365 31.9999 16Z"
        />
        <path
          fill="#FFDA2C"
          d="M10.4343 21.5652C13.5079 21.5652 15.9995 19.0736 15.9995 16C15.9995 12.9264 13.5079 10.4348 10.4343 10.4348C7.36076 10.4348 4.86914 12.9264 4.86914 16C4.86914 19.0736 7.36076 21.5652 10.4343 21.5652Z"
        />
        <path
          fill="#F93939"
          d="M7.30469 13.2173V16.6955C7.30469 18.4244 8.70619 19.826 10.4351 19.826C12.1641 19.826 13.5656 18.4245 13.5656 16.6955V13.2173H7.30469Z"
        />
        <path
          fill="white"
          d="M10.4351 17.7392C9.85973 17.7392 9.3916 17.2711 9.3916 16.6957V15.3044H11.4785V16.6958C11.4785 17.2711 11.0104 17.7392 10.4351 17.7392Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPortugal;
