const RoundTokelau = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M25.7391 7.65219C25.5956 8.34188 25.5304 9.07644 25.5304 9.80869C25.5304 14.0296 28.0466 17.6828 31.6526 19.3273C31.8796 18.2538 32 17.141 32 16C32 7.1635 24.8365 0 16 0C7.1635 0 0 7.1635 0 16C0 24.8365 7.1635 32 16 32C21.4801 32 26.3161 29.2443 29.1998 25.0435H9.0435V22.9566H30.4124C30.6311 22.5043 30.8286 22.04 31.0049 21.5653H9.0435L25.7391 7.65219Z"
        />
        <path
          fill="white"
          d="M11.7549 8.34766 12.1003 9.41053H13.2178L12.3137 10.0675 12.6591 11.1303 11.7549 10.4735 10.8509 11.1303 11.1962 10.0675 10.292 9.41053H11.4096L11.7549 8.34766ZM7.21528 16 7.64703 17.3287H9.04391L7.91372 18.1496 8.34547 19.4783 7.21528 18.6571 6.08516 19.4783 6.51684 18.1496 5.38672 17.3287H6.78359L7.21528 16ZM7.21528 4.86963 7.64703 6.19832H9.04391L7.91372 7.01925 8.34547 8.34788 7.21528 7.52675 6.08516 8.34788 6.51684 7.01925 5.38672 6.19832H6.78359L7.21528 4.86963ZM3.73677 11.1304 4.16852 12.4591H5.56533L4.4352 13.28 4.86695 14.6086 3.73677 13.7875 2.60664 14.6086 3.03833 13.28 1.9082 12.4591H3.30502L3.73677 11.1304Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTokelau;
