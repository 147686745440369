import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordCheckBox, BordDropdown } from '../../../../../components/BordDesignSystem';
import {
  IadditionalServiceDataForToolLogistics,
  IlogisticAdditionalService
} from '../../../../../types/requestLogisticsModule';
import { LS_ADDITIONAL_SERVICE_TYPE } from '../../../../../types/logisticsServicesModule.types';
import { NudosWipingStatusHoverData } from '../../../../../components/DesignSystem/LogisticsAdditionalServices';
import './WarehouseAdditionalServicesDropdown.scss';

/**
 * @property { (clickedService: number) => void } clickSelectOptionCallback - The callback function to execute upon selecting an option, it should remove or add the id of the clicked additional service to the array of additional services for the current tool
 * @property { IlogisticAdditionalService[] } currentWarehouseAdditionalServices - The list of additional services offered by the warehouse selected as destination
 * @property { number[] } thisToolAdditionalServices - The list of additional services Ids that the user wants to ask for the current tool
 */
const WarehouseAdditionalServicesDropdown = ({
  clickSelectOptionCallback,
  currentWarehouseAdditionalServices,
  thisToolAdditionalServices,
  thisToolPreviousAdditionalServices,
  thisToolConfigurationComment,
  dropdownCloseCallback,
  openModalCallback,
  hasConfigurationAsAdditionalService,
  destinationIsWarehouse
}: {
  clickSelectOptionCallback: (clickedService: number) => void;
  currentWarehouseAdditionalServices: IlogisticAdditionalService[];
  thisToolAdditionalServices: number[];
  thisToolPreviousAdditionalServices: IadditionalServiceDataForToolLogistics[];
  thisToolConfigurationComment?: string;
  dropdownCloseCallback?: (...args: (number | string)[]) => void;
  openModalCallback?: () => void;
  hasConfigurationAsAdditionalService?: boolean;
  destinationIsWarehouse?: boolean;
}) => {
  const { t } = useTranslation();
  const [showPreviousWiping, setShowPreviousWiping] = useState(false);
  const [previouAdditionalServicesMap, setPreviouAdditionalServicesMap] =
    useState<Map<LS_ADDITIONAL_SERVICE_TYPE, IadditionalServiceDataForToolLogistics>>();

  const selectedOptionsNamesText = t('services:logistics:selectedOptionsNamesText');

  const servicesRawOptions: IadditionalServiceOption[] = currentWarehouseAdditionalServices?.map(offeredService => {
    const isSelected = thisToolAdditionalServices.includes(offeredService?.id);
    return { ...offeredService, isSelected };
  });

  const selectedOptionsNames = currentWarehouseAdditionalServices
    ?.filter(option => thisToolAdditionalServices?.includes(option?.id))
    ?.map(filteredOption => filteredOption?.name);

  const hasSelectedAtLeastOneAdditionalService = thisToolAdditionalServices?.length > 0;
  const additionalServicesPlaceholder = hasSelectedAtLeastOneAdditionalService
    ? selectedOptionsNames?.join(', ')
    : selectedOptionsNamesText;

  const previousWipingData = previouAdditionalServicesMap?.get(LS_ADDITIONAL_SERVICE_TYPE.WIPING);
  const previousWipingStyles = previousWipingData && showPreviousWiping ? 'showPreviousWiping' : '';

  const hidePreviousWipingHover = () => setShowPreviousWiping(false);
  const showPreviousWipingHover = () => setShowPreviousWiping(true);

  const formDestinationDropdownUIOptions = (rawOptions: IadditionalServiceOption[]) => {
    return rawOptions.map((option, i) => {
      const { fee } = option;
      const priceText = fee ? `$${fee}` : t('recurrentWords:toQuote');
      const optionIsWiping = option?.name === LS_ADDITIONAL_SERVICE_TYPE.WIPING;
      const hasValidPreviousWipping =
        !!previousWipingData?.logisticServiceStatus && previousWipingData?.logisticServiceStatus !== 'cancelado';
      const disabledWipingStyles =
        hasValidPreviousWipping && optionIsWiping && !destinationIsWarehouse ? 'wipingDisabled' : '';
      const optionsClassnames = `optionContainer additionalServiceOption ${disabledWipingStyles}`;
      const showPreviousWipingHover = () => !!disabledWipingStyles && setShowPreviousWiping(true);

      return (
        <div
          className={optionsClassnames}
          key={`option${option?.id}${i}`}
          onMouseEnter={showPreviousWipingHover}
          onMouseLeave={hidePreviousWipingHover}
          onClick={() => clickSelectOptionCallback(option?.id)}
        >
          <div className="selectButtonContainer">
            <BordCheckBox isButtonActive={option?.isSelected} isButtonDisabled={!!disabledWipingStyles} />
          </div>
          <div className="titleAndDescription">
            <div className="optionTitleContainer">
              <div className="optionTitle">{t(`services:logistics:${option?.nameStringId}`)}</div>
              {!disabledWipingStyles && optionIsWiping && (
                <div className="recommendationTag">{t('recurrentWords:favorite')}</div>
              )}
            </div>
            <div className="optionDescription">{t(`services:logistics:${option?.descriptionStringId}`)}</div>
          </div>
          <div className="priceSection">
            <div className="priceTitle">{t('recurrentWords:price')}</div>
            <div className="priceValue">{priceText}</div>
          </div>
        </div>
      );
    });
  };

  const formSelectedOption = (rawSelectedOption: IadditionalServiceOption) => {
    return <div className="selectedOption">{rawSelectedOption?.name}</div>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: IadditionalServiceOption[]) => {
    return rawOptions.filter(option => {
      return (
        option?.name?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        option?.description?.toLowerCase().trim().includes(search.toLowerCase().trim())
      );
    });
  };

  const enhancedCloseDropdownCallback = () => {
    hidePreviousWipingHover();
    dropdownCloseCallback && dropdownCloseCallback();
  };

  useEffect(() => {
    if (!thisToolPreviousAdditionalServices?.length) return;
    const newMap = new Map();
    for (const additionalService of thisToolPreviousAdditionalServices) {
      newMap.set(additionalService?.name, additionalService);
    }
    setPreviouAdditionalServicesMap(newMap);
  }, [thisToolPreviousAdditionalServices?.length]);

  useEffect(() => {
    return () => setShowPreviousWiping(false);
  }, []);

  return (
    <div className={`WarehouseAdditionalServicesDropdown ${previousWipingStyles}`}>
      <BordDropdown
        componentSize={'w-380'}
        customPlaceholder={additionalServicesPlaceholder}
        rawOptions={servicesRawOptions}
        formDropdownOptionsCallback={formDestinationDropdownUIOptions}
        formSelectedOptionUICallback={formSelectedOption}
        filterBySearchStringCallback={filterBySearchStringCallback}
        isFilled={hasSelectedAtLeastOneAdditionalService}
        closesOnChangeSelection={false}
        dropdownCloseCallback={enhancedCloseDropdownCallback}
        errorText={!thisToolConfigurationComment && hasConfigurationAsAdditionalService ? 'agregar comentario' : ''}
        hideErrorText
        closesOnClickOutside={!showPreviousWiping}
        customCurrentValueOrplaceholder="customCurrentValueOrplaceholder"
      />
      {!destinationIsWarehouse && (
        <div
          className="nudosWipingStatusHoverDataContainer"
          onMouseEnter={showPreviousWipingHover}
          onMouseLeave={hidePreviousWipingHover}
        >
          <NudosWipingStatusHoverData wipingData={previousWipingData} />
        </div>
      )}
      {!!thisToolConfigurationComment && (
        <div className="editAndCancelButtons">
          <button className="editButton" onClick={openModalCallback}>
            Ver detalles de la configuración
          </button>
        </div>
      )}
      {!thisToolConfigurationComment && hasConfigurationAsAdditionalService && (
        <div className="editAndCancelButtons">
          <button className="editButton" onClick={openModalCallback} style={{ color: '#F00000' }}>
            Agrega detalles de la configuración
          </button>
        </div>
      )}
    </div>
  );
};

export default WarehouseAdditionalServicesDropdown;

interface IadditionalServiceOption {
  id: number;
  fee?: string | null;
  name: string;
  nameStringId?: string | null;
  description: string;
  descriptionStringId?: string | null;
  isSelected: boolean;
}
