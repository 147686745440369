import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" ref={ref} {...props}>
    <path
      className="path1"
      fill="#FF664E"
      d="M8.002 8.905c0-.719.585-1.16 1.617-1.849.956-.64 1.46-1.468 1.236-2.597A2.963 2.963 0 0 0 8.57 2.174C6.799 1.82 5.094 3.22 5.094 5.027"
    />
    <path
      className="path2"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8.002 8.905c0-.719.585-1.16 1.617-1.849.956-.64 1.46-1.468 1.236-2.597A2.963 2.963 0 0 0 8.57 2.174C6.799 1.82 5.094 3.22 5.094 5.027M8 13.88v-.497"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconQuestion = memo(ForwardRef);
export default IconQuestion;
