const RoundSamoa = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C5.49563 10.5044 10.1565 5.8435 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path fill="#1A47B8" d="M16 16C16 7.1635 16 6.12175 16 0C7.1635 0 0 7.1635 0 16H16Z" />
        <path
          fill="white"
          d="M12.8159 10.4348 13.075 11.232H13.9131L13.235 11.7246 13.4941 12.5218 12.8159 12.0291 12.1378 12.5218 12.3969 11.7246 11.7188 11.232H12.5569L12.8159 10.4348ZM8.60597 4.17383 9.03766 5.50252H10.4347L9.30441 6.32345 9.73622 7.65208 8.60597 6.83089 7.47578 7.65208 7.90753 6.32345 6.77734 5.50252H8.17422L8.60597 4.17383ZM12.7798 5.56519 13.2115 6.89394H14.6084L13.4782 7.71487 13.9099 9.0435 12.7798 8.22231 11.6496 9.0435 12.0814 7.71487 10.9512 6.89394H12.348L12.7798 5.56519ZM9.48097 11.1304 9.91272 12.4591H11.3096L10.1794 13.28 10.6112 14.6086 9.48097 13.7875 8.35078 14.6086 8.78253 13.28 7.65234 12.4591H9.04928L9.48097 11.1304ZM5.82368 7.6521 6.25543 8.98079H7.65237L6.52212 9.80172 6.95387 11.1304 5.82368 10.3092 4.69355 11.1304 5.12524 9.80172 3.99512 8.98079H5.39199L5.82368 7.6521Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSamoa;
