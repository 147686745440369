import { NudosAddressSearchBar } from '../../../NudosComponents';
import { BordButton, BordOneToneIcon, BordPhoto } from '../../../BordDesignSystem';
import {
  IIssueReported,
  IUpdateLogisticServiceAddress,
  IlogisticServiceDetailedData
} from '../../../../types/logisticsServicesModule.types';
import { getAddressErrorText, getIdDocumentData, isPhoneValid } from '../../../../utils/formValidations';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../../types/countries';
import { getCountriesList } from '../../../../services/countries.service';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../utils/displayNudosStandardNotifications';
import {
  formatZipCode,
  getIsInvalidZipCode,
  invalidZipCodeText,
  supportUrl
} from '../../../../utils/productDefinitions';
import { updateLogisticServiceAddress } from '../../../../services/logisticsServicesModule.services';
import { IcityDetails } from '../../../../types/cities.types';
import NudosCityDropdown from '../../NudosCityDropdown/NudosCityDropdown';
import { capitalize } from '../../../../utils/unCamelCase';
import { IaddressFromGoogleMaps } from '../../../../types/global';
import { segmentTrackEvent } from '../../../../utils/segment';
import { BordTextInput } from '../../../BordDesignSystem';
import BordPhoneInput from '../../../BordDesignSystem/BordPhoneInput/BordPhoneInput';
import { TcountryCode } from '../../../BordDesignSystem/BordFlag/BordFlag.types';
import './NudosEditUserAddressModal.scss';

const NudosEditUserAddressModal = ({
  originDestination,
  closeModalCallback,
  logisticServiceData,
  requestCancelLSCallback,
  getLogisticServiceDetailCallback,
  issueReported,
  updateEditFieldList
}: {
  originDestination: 'origin' | 'destination';
  closeModalCallback: () => void;
  logisticServiceData?: IlogisticServiceDetailedData;
  requestCancelLSCallback: () => void;
  getLogisticServiceDetailCallback: () => void;
  issueReported?: IIssueReported;
  updateEditFieldList?: (field: string) => void;
}) => {
  const { t } = useTranslation();

  const [city, setCity] = useState<IcityDetails>();
  const [zipCode, setZipCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [userAddress, setUserAddress] = useState<IaddressFromGoogleMaps>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [selectedCountry, setSelectedCountry] = useState<ICountryDetail>();
  const [personalDocument, setPersonalDocument] = useState<string>();
  const [loadingUpdateData, setLoadingUpdateData] = useState(false);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [additionalReferences, setAdditionalReferences] = useState<string>();
  // ERRORS
  const [phoneError, setPhoneError] = useState<string>();
  const [cityError, setCityError] = useState<string>();
  const [addressError, setAddressError] = useState<string>();
  const [zipCodeError, setZipCodeError] = useState<string>();
  const [indicationsError, setIndicationsError] = useState<string>();

  const { manualQuote, status, id, collection, delivery } = logisticServiceData || {};
  const originCityId = collection?.cityId;
  const destinationCityId = delivery?.cityId;
  const isLogisticSameCity = originCityId === destinationCityId;
  const isOriginCard = originDestination === 'origin';
  const locationData = isOriginCard ? collection : delivery;
  const formTranslationKey = 'nodi:employeeInternal:newEmployeeForm:';
  const employeeLocation = locationData?.locationUser;
  const activeStatusName = status === 'activo';
  const locationCountry = countriesList?.find(country => country?.code === locationData?.country?.code);
  const editingPoint = originDestination === 'origin' ? 'Origin' : 'Destination';
  const logServiceStatus = logisticServiceData?.status === 'por confirmar' ? 'To be confirmed' : 'Confirmed';
  const employeeId = employeeLocation?.personalId;
  const employeeFirstName = employeeLocation?.firstName;
  const employeeLastName = employeeLocation?.lastName;
  const employeeName = `${employeeFirstName} ${employeeLastName}`;
  const employeeCountryCode = employeeLocation?.phoneCountry?.code;
  const isDeactivated = manualQuote || activeStatusName || loadingUpdateData;
  const employeePhotoProfile = locationData?.locationUser?.photoProfile;
  const hasProfilePicture = employeePhotoProfile && employeePhotoProfile?.length > 0;
  const cleanProfilePictureUrl = `${employeePhotoProfile?.replaceAll(' ', '%20')}`;
  const profilePicture = hasProfilePicture ? cleanProfilePictureUrl : undefined;

  const idDocumentData = getIdDocumentData(selectedCountry || undefined, personalDocument);
  const addressErrorText = getAddressErrorText(selectedCountry?.code, selectedCountry?.name, undefined);
  const displayCancelLsContainer = isLogisticSameCity || !!manualQuote;

  const originTranslation = t('recurrentWords:origin');
  const destinationTranslation = t('recurrentWords:destination');
  const originDestinationValue = originDestination === 'origin' ? originTranslation : destinationTranslation;
  const additionalReferencesErrorText =
    !additionalReferences && userAddress ? t(`${formTranslationKey}completeTheInstructions`) : '';
  const alertText = t(`${formTranslationKey}alertText`);
  const addressPlaceholder = t(`${formTranslationKey}addressInfoSection:secondLineInputs:addressCustomPlaceholder`);
  const zipCodeLabelAndPlaceholder = t(`${formTranslationKey}addressInfoSection:firstLineInputs:zipCode`);

  const cancelLsBlueText =
    manualQuote || activeStatusName
      ? t(`${formTranslationKey}contactSupport`)
      : t(`${formTranslationKey}requestCancellation`);
  const cancelLsMainText = manualQuote
    ? t(`${formTranslationKey}cantEditInformation`)
    : activeStatusName
    ? t(`${formTranslationKey}cantEditWhileActive`)
    : t(`${formTranslationKey}cancelToChangeData`);

  const cityDetails: IcityDetails = {
    id: locationData?.cityId,
    name: locationData?.city,
    country: selectedCountry
  };

  const phoneEmpty = !phoneNumber || phoneNumber?.length === 0 || phoneNumber?.length <= 3;
  const phoneCountryEmpty = !phoneCountry || phoneCountry?.code === undefined;
  const phoneAlreadyRegisteredError = phoneEmpty || phoneCountryEmpty;
  const getPhoneError = () => {
    if ((phoneNumber && phoneNumber.length > 3 && !isPhoneValid(phoneNumber)) || phoneAlreadyRegisteredError)
      return `${t('recurrentWords:inputError:invalidPhone')}`;
    return undefined;
  };

  const disableUpdateButton =
    loadingUpdateData || !!getPhoneError() || !!addressErrorText || !!idDocumentData?.errorText;

  const setInitialData = () => {
    const initialAddress = {
      address: locationData?.address,
      coordinates: locationData?.coordinates,
      country: locationData?.country?.code,
      city: locationData?.city
    };
    if (loadingCountries) return;
    if (!selectedCountry) setSelectedCountry(locationCountry);
    if (!city) setCity(cityDetails);
    if (!phoneNumber) setPhoneNumber(locationData?.additionalInfo?.phone || undefined);
    if (!phoneCountry) setPhoneCountry(locationData?.additionalInfo?.phoneCountry || undefined);
    if (!additionalReferences) setAdditionalReferences(locationData?.additionalReferences || undefined);
    if (!userAddress) setUserAddress(initialAddress);
    if (!zipCode) setZipCode(locationData?.zipCode || undefined);
    if (!personalDocument) setPersonalDocument(employeeId || undefined);
  };

  const openSupportWindow = () => {
    segmentTrackEvent({
      logisticServicesSupportManualModalClick: {
        Country: locationCountry?.name || '',
        EditingPoint: editingPoint,
        LogServiceStatus: logServiceStatus,
        ModalType: 'Employee'
      }
    });
    window.open(supportUrl);
  };

  const cancelLsContainerAction = () => {
    if (manualQuote) return openSupportWindow();
    return requestCancelLSCallback();
  };

  const getCountries = async () => {
    try {
      setLoadingCountries(true);
      const dataCountries = await getCountriesList();
      setCountriesList(dataCountries);
    } catch {
      displayErrorNotification();
    } finally {
      setLoadingCountries(false);
    }
  };

  const notificationText = (
    <Trans
      i18nKey={t(`${formTranslationKey}notificationText`)}
      components={{ 1: capitalize(originDestinationValue) }}
    />
  );

  const updateLogisticServiceAddressData = async () => {
    if (!id) return;
    setLoadingUpdateData(true);
    try {
      const newDestinationData: IUpdateLogisticServiceAddress = {
        firstName: employeeFirstName || '',
        lastName: employeeLastName || '',
        personalId: personalDocument || undefined,
        countryId: selectedCountry?.id,
        countryPhoneId: phoneCountry?.id,
        phone: phoneNumber,
        address: {
          address: userAddress?.address || '',
          additionalReferences: additionalReferences || '',
          coordinates: userAddress?.coordinates,
          city: city?.name || '',
          cityId: city?.id || undefined,
          zipCode: zipCode || '',
          country: selectedCountry?.code || '',
          contactInformation: {
            name: employeeName,
            phone: phoneNumber,
            countryPhoneId: phoneCountry?.id,
            personalId: personalDocument || undefined
          }
        }
      };
      await updateLogisticServiceAddress(id, originDestination, newDestinationData);
      displaySuccessNotification({
        customJSXMessage: <>{notificationText}</>
      });
      closeModalCallback();
      getLogisticServiceDetailCallback();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingUpdateData(false);
    }
  };

  const checkErrorsToDisableButton = () => {
    if (!!phoneError || !!zipCodeError || !!cityError || !!indicationsError || !!addressError) {
      return true;
    }
    return false;
  };

  const findAndShowErrors = () => {
    if (issueReported) {
      const phoneReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Número de teléfono'
      );
      const cityReport = issueReported?.wrongFields.find(report => report.addressWrongField.name === 'Ciudad');
      const zipCodeReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Código Postal'
      );
      const indicationsReport = issueReported?.wrongFields.find(
        report => report.addressWrongField.name === 'Indicaciones'
      );
      const AddressReport = issueReported?.wrongFields.find(report => report.addressWrongField.name === 'Dirección');

      if (phoneReport) {
        setPhoneError('Corrige el número de teléfono para continuar con el servicio');
      }
      if (cityReport) {
        setCityError('Corrige la ciudad para continuar con el servicio');
      }
      if (zipCodeReport) {
        setZipCodeError('Corrige el CP');
      }
      if (indicationsReport) {
        setIndicationsError('Corrige las indicaciones para continuar con el servicio');
      }
      if (AddressReport) {
        setAddressError('Corrige la dirección para continuar con el servicio ');
      }
    }
  };
  const updateEditFieldListUser = (field: string) => {
    if (updateEditFieldList) {
      updateEditFieldList(field);
    }
  };

  useEffect(() => {
    setInitialData();
  }, [locationData, loadingCountries]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    findAndShowErrors();
  }, [issueReported]);

  return (
    <div className="nudosEditDirectionModalContent">
      <div className="employeeHeaderContainer">
        <div className="imgContainer">
          <BordPhoto
            size="s48"
            url={profilePicture}
            imageProps={{ style: { width: '100%', height: '100%', borderRadius: '1.1rem' } }}
            flagProps={{
              country: employeeCountryCode as TcountryCode,
              variant: 'circle',
              standardSize: 24
            }}
            avatarProps={{
              variant: 'rectangularBordMascot',
              customWidth: '5.6rem'
            }}
          />
        </div>
        <div className="employeeName">{employeeName}</div>
      </div>
      <div className="inputsContainer">
        <div className="row1">
          <BordTextInput
            label={idDocumentData?.label}
            isFilled={!!idDocumentData?.value}
            placeholder={idDocumentData?.label}
            setCurrentText={e => {
              setPersonalDocument(e);
              updateEditFieldListUser('EmployeeID');
            }}
            className="idDocumentInput"
            currentText={idDocumentData?.value}
            showSkeletonLoader={loadingCountries}
            disabled={isDeactivated}
            errorText={idDocumentData?.errorText}
            caption={idDocumentData?.caption}
          />
          <BordPhoneInput
            label={t(`${formTranslationKey}firstLineInputs:phone`)}
            handleCountryPhoneChange={setPhoneCountry}
            handlePhoneChange={setPhoneNumber}
            defaultPhoneNumber={phoneNumber}
            defaultCountryData={phoneCountry}
            personalClass="phoneInputStyles"
            placeholder={t(`${formTranslationKey}firstLineInputs:phone`)}
            errorText={getPhoneError() || phoneError}
            countriesList={countriesList || []}
            isDisabled={loadingUpdateData}
            showSkeletonLoader={loadingCountries}
            simpleChangeOfValues={() => {
              setPhoneError(undefined);
              updateEditFieldListUser('EmployeePhone');
            }}
          />
        </div>

        <div className="row2">
          <BordTextInput
            label={zipCodeLabelAndPlaceholder}
            isFilled={!!zipCode}
            errorText={zipCodeError || getIsInvalidZipCode(zipCode) ? invalidZipCodeText : undefined}
            placeholder={zipCodeLabelAndPlaceholder}
            handleBlur={newZipCode => setZipCode(formatZipCode(newZipCode))}
            setCurrentText={e => {
              setZipCode(e);
              setZipCodeError(undefined);
            }}
            className="zipCodeInput"
            currentText={(zipCode && formatZipCode(zipCode)) || undefined}
            standardSize="w-80"
            showSkeletonLoader={loadingCountries}
            disabled={manualQuote || isLogisticSameCity || activeStatusName || loadingUpdateData}
          />
          <NudosCityDropdown
            countryId={selectedCountry?.id}
            currentValue={city}
            componentSize="w-180"
            isDeactivated={manualQuote || isLogisticSameCity || activeStatusName || loadingUpdateData}
            showSkeletonLoader={loadingCountries}
            updateCurrentValueCallback={e => {
              setCity(e);
              setCityError(undefined);
            }}
            errorText={cityError}
          />
          <BordTextInput
            label={t(`${formTranslationKey}addressInfoSection:secondLineInputs:directions`)}
            isFilled={!!additionalReferences}
            errorText={additionalReferencesErrorText || indicationsError}
            placeholder={t(`${formTranslationKey}addressInfoSection:secondLineInputs:directionsPlaceholder`)}
            standardSize="w-280"
            className="indicationsInput"
            setCurrentText={(inputText: string) => {
              setAdditionalReferences(inputText);
              setIndicationsError(undefined);
              updateEditFieldListUser('Directions');
            }}
            currentText={additionalReferences}
            disabled={isDeactivated}
            showSkeletonLoader={loadingCountries}
          />
        </div>
        <div className="row3">
          <NudosAddressSearchBar
            label={t(`${formTranslationKey}addressInfoSection:secondLineInputs:address`)}
            errorText={addressErrorText || addressError}
            regionBias={selectedCountry?.code}
            isDisabled={isDeactivated}
            componentSize="extraLarge"
            customPlaceholder={addressPlaceholder}
            showSkeletonLoader={loadingCountries}
            handleAddressSelection={e => {
              setUserAddress(e);
              updateEditFieldListUser('Address');
              setAddressError(undefined);
            }}
            defaultValueAddressName={userAddress?.address || undefined}
            didntFindAddressTooltipTopPosition={6.8 + (addressErrorText ? 1.2 : 0)}
            didntFindAddressTooltipRightPosition={0}
          />
        </div>
      </div>
      <div className="alertModal">
        <BordOneToneIcon variant="alertTriangleWarning" standardSize={14} />
        {alertText}
      </div>
      {displayCancelLsContainer && (
        <div className="cancelLsContainer">
          <BordOneToneIcon variant="info" standardSize={14} />
          <div className="cancelLsTextContainer">
            <div>{cancelLsMainText}</div>
            <div className="cancelLsBlueText" onClick={() => cancelLsContainerAction()}>
              {cancelLsBlueText}
            </div>
          </div>
        </div>
      )}
      <BordButton
        label={t(`${formTranslationKey}saveButton`)}
        onClick={updateLogisticServiceAddressData}
        customClassName="saveButton"
        isLoading={loadingUpdateData}
        disabled={disableUpdateButton || loadingCountries || checkErrorsToDisableButton()}
      />
    </div>
  );
};

export default NudosEditUserAddressModal;
