/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosResponse } from 'axios';
import { auth } from '../state/firebase';
import { logoutDueToErrorInAuthToken } from '../utils/auth';
import { sendSentryError } from '../utils/sentryErrors';

const axiosApiInstance = axios.create();
const doNotExecuteErrorFunction = window?.location?.hostname?.includes('localhost');

function handleRequestErrors(error: AxiosError) {
  const { response } = error as AxiosError;
  const statusError = response?.status;
  if (statusError === 401 && !doNotExecuteErrorFunction) logoutDueToErrorInAuthToken();
  if ((statusError === 404 || statusError === 403) && !doNotExecuteErrorFunction) {
    window.open('/nodi/not-found', '_self');
  }
}

export default async function createApiInstance(accessToken: string, baseUrl: string) {
  axiosApiInstance.interceptors.request.use(
    async config => {
      const context = '';
      config.baseURL = `${baseUrl}${context}`;
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
}

export const getToken = async (): Promise<string> => {
  const user = auth.currentUser;
  let token = '';
  if (user) token = await user.getIdToken();
  return `Bearer ${token}`;
};

export async function apiGet<T, U>(url: string, options?: T): Promise<U> {
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token
  };
  try {
    const { data } = await axiosApiInstance.get<U>(url, { ...options, headers });
    return data;
  } catch (err) {
    handleRequestErrors(err as AxiosError);
    sendSentryError({ data: undefined, error: err as AxiosError, requestURL: url, requestMethod: 'GET' });
    throw err;
  }
}

export async function apiPut<T, U = any, V = any>(url: string, body?: U, options?: T): Promise<V> {
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token
  };
  try {
    const { data }: any = await axiosApiInstance.put(url, body, { ...options, headers });
    return data;
  } catch (err) {
    sendSentryError({ data: body, error: err as AxiosError, requestURL: url, requestMethod: 'PUT' });
    handleRequestErrors(err as AxiosError);
    throw err;
  }
}

export async function apiPost<T, U = any, V = any>(url: string, body?: U, options?: T): Promise<V | AxiosResponse> {
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token
  };
  try {
    const response = await axiosApiInstance.post(url, body, { ...options, headers });
    return response;
  } catch (err) {
    sendSentryError({ data: body, error: err as AxiosError, requestURL: url, requestMethod: 'POST' });
    handleRequestErrors(err as AxiosError);
    throw err;
  }
}

export async function apiDelete<T = any, V = any>(url: string, options?: T): Promise<V> {
  const token = await getToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: token
  };
  try {
    const { data }: any = await axiosApiInstance.delete(url, { ...options, headers });
    return data;
  } catch (err) {
    sendSentryError({ data: undefined, error: err as AxiosError, requestURL: url, requestMethod: 'DELETE' });
    handleRequestErrors(err as AxiosError);
    throw err;
  }
}
