import create from 'zustand';

export interface iState {
  onlyDevelopers: boolean;
  setOnlyDevelopers: (onlyDevelopers: boolean) => void;
}

const useOnlyDevelopers = create<iState>(set => ({
  onlyDevelopers: false,
  setOnlyDevelopers: onlyDevelopers => set({ onlyDevelopers })
}));

export default useOnlyDevelopers;
