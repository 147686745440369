const RoundPapuaNewGuinea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="black"
          d="M27.3138 27.3137C21.0654 33.5621 10.9348 33.5621 4.68627 27.3137C-1.5621 21.0653 -1.5621 10.9346 4.68634 4.68618C10.9347 -1.56219 20.0815 -0.578378 26.3299 5.67006C32.5782 11.9184 33.5621 21.0653 27.3138 27.3137Z"
        />
        <path
          fill="#A2001D"
          d="M4.68652 4.68623C10.9349 -1.56215 21.0655 -1.56202 27.314 4.68629C33.5623 10.9347 33.5623 21.0653 27.314 27.3137"
        />
        <path
          fill="white"
          d="M12.228 21.5652 12.487 22.3624H13.3252L12.6471 22.855 12.9062 23.6522 12.228 23.1595 11.5499 23.6522 11.8089 22.855 11.1309 22.3624H11.969L12.228 21.5652ZM10.0864 13.9131 10.5183 15.2417H11.9151L10.7849 16.0628 11.2166 17.3914 10.0864 16.5702 8.95631 17.3914 9.38806 16.0628 8.25781 15.2417H9.65481L10.0864 13.9131ZM10.0864 23.6523 10.5181 24.981H11.9151L10.7848 25.802 11.2166 27.1306 10.0864 26.3094 8.95631 27.1306 9.38794 25.802 8.25781 24.981H9.65462L10.0864 23.6523ZM14.1713 17.3914 14.6031 18.72H16L14.8698 19.541 15.3015 20.8696 14.1713 20.0484 13.0412 20.8696 13.473 19.541 12.3428 18.72H13.7397L14.1713 17.3914ZM6.00245 17.3914 6.43427 18.72H7.83108L6.70095 19.541 7.13258 20.8696 6.00245 20.0484 4.87227 20.8696 5.30408 19.541 4.17383 18.72H5.57077L6.00245 17.3914Z"
        />
        <path
          fill="#FFDA2C"
          d="M26.7838 11.8475C26.0688 11.1324 25.1397 10.71 24.1412 10.6374L27.13 8.31288C25.9397 7.12257 24.3443 6.585 22.7875 6.69869C22.6012 6.02894 22.2458 5.39632 21.7192 4.86963L20.5386 7.23075C20.4612 7.08963 20.3631 6.95682 20.2435 6.83725C19.5642 6.15807 18.4631 6.15807 17.784 6.83725C17.1048 7.51644 17.1047 8.61757 17.784 9.29675C17.9035 9.41632 18.0364 9.51438 18.1775 9.59188L15.8164 10.7724C16.3432 11.2993 16.9763 11.6549 17.6464 11.8411C17.5323 13.3979 18.0693 14.993 19.2597 16.1834L21.871 12.826C22.0674 12.6326 22.3268 12.5258 22.6028 12.5258C22.8816 12.5258 23.1436 12.6343 23.3407 12.8314C23.5378 13.0285 23.6463 13.2906 23.6463 13.5693C23.6463 13.848 23.5378 14.11 23.3407 14.3071L24.3245 15.291C24.7843 14.8311 25.0377 14.2197 25.0377 13.5693C25.0377 13.0372 24.8679 12.5313 24.5545 12.1131C25.0112 12.235 25.4427 12.4739 25.8002 12.8314C26.8852 13.9164 26.8852 15.6818 25.8002 16.7666L26.7841 17.7505C27.5724 16.9621 28.0066 15.914 28.0066 14.7991C28.0064 13.684 27.5722 12.6359 26.7838 11.8475Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPapuaNewGuinea;
