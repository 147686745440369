const SquareAlbania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M11 4.26662 9.42856 3.19995 8.38094 4.26662h1.04762L8.38094 5.33328 6.28571 4.26662v5.33333l3.14285-1.06667-1.04762 2.13332H7.33332l1.04762 1.0667 1.04762-1.0667L11 12.8V4.26662Zm1.5714-1.06667 1.0476 1.06667h-1.0476l1.0476 1.06666 2.0953-1.06666v5.33333l-3.1429-1.06667 1.0476 2.13332h1.0477l-1.0477 1.0667-1.0476-1.0667L11 12.8V4.26662l1.5714-1.06667Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAlbania;
