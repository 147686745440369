import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../../components/BordDesignSystem';
import './addCountryAddress.scss';

interface IAddCountryAddress {
  blockedAddButton?: boolean;
  setOpenModalNewAddress?: (state: boolean) => void;
}

const AddCountryAddress: FC<IAddCountryAddress> = ({
  blockedAddButton,
  setOpenModalNewAddress
}: IAddCountryAddress) => {
  const blocked = blockedAddButton;
  const { t } = useTranslation();
  return (
    <BordButton
      id="addCountryAddressId"
      label={t('nodi:account:organizationAddressesTab:boxTitleAdd')}
      disabled={blocked}
      onClick={() => {
        if (setOpenModalNewAddress) {
          setOpenModalNewAddress(true);
        }
      }}
      modeButton="secondary"
      buttonIconTwo="plusCircle"
      customWidth="w-280"
    />
  );
};

export default AddCountryAddress;
