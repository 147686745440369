const RoundBelarus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FCFCFC"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path fill="#A2001D" d="m6.60869 14.8405-1.73912-3.1552 1.73912-3.10571 1.73913 3.10571-1.73913 3.1552Z" />
        <path
          fill="#A2001D"
          d="m3.13045 14.8405-1.73912-3.1552 1.73912-3.10571 1.73913 3.10571-1.73913 3.1552ZM6.60869 23.4203l-1.73912-3.1552 1.73912-3.1057 1.73913 3.1057-1.73913 3.1552Z"
        />
        <path
          fill="#A2001D"
          d="m3.13045 23.4203-1.73912-3.1552 1.73912-3.1057 1.73913 3.1057-1.73913 3.1552ZM8.34783 3.10569l-.49238-.87925c-.88312.52325-1.71143 1.12906-2.47462 1.80681L6.6087 6.26082l1.73913-3.15513ZM6.6087 25.739l-1.24087 2.216c.7625.6786 1.59025 1.2854 2.473 1.8097l.50706-.9199L6.6087 25.739Z"
        />
        <path
          fill="#6DA544"
          d="M9.73914 20.174v10.5539C11.6622 31.5465 13.778 32 16 32c6.8794 0 12.7441-4.342 15.0048-10.4348L9.73914 20.174Z"
        />
        <path
          fill="#A2001D"
          d="M31.0048 21.5652C31.648 19.8319 32 17.9571 32 16c0-8.8365-7.1635-16-16-16-2.2218 0-4.3378.453312-6.26086 1.27175V21.5653H31.0048v-.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBelarus;
