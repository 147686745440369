const SquareNepal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path fill="#003495" fillRule="evenodd" d="M0 0L13.619 8.53333H6.28571L13.619 16H0V0Z" clipRule="evenodd" />
          <path
            fill="#DF0A37"
            fillRule="evenodd"
            d="M1.04791 2.1333L9.42887 7.46663H3.14315L10.4765 14.9333H1.04786C1.04785 14.4 1.04791 14.4469 1.04791 13.8645V2.1333Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M5.14951 5.3335C5.20713 5.4167 5.23856 5.5063 5.23856 5.60016C5.23856 6.04176 4.53456 6.40016 3.66713 6.40016C2.7997 6.40016 2.0957 6.04176 2.0957 5.60016C2.0957 5.5063 2.12713 5.4167 2.18475 5.3335C2.40056 5.48923 2.98304 5.60016 3.66713 5.60016C4.35123 5.60016 4.93475 5.48923 5.14951 5.3335Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            d="M3.66713 13.8665C4.53501 13.8665 5.23856 13.1502 5.23856 12.2665C5.23856 11.3828 4.53501 10.6665 3.66713 10.6665C2.79926 10.6665 2.0957 11.3828 2.0957 12.2665C2.0957 13.1502 2.79926 13.8665 3.66713 13.8665Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareNepal;
