import { AxiosError, AxiosRequestConfig } from 'axios';
import { apiGet } from './api';
import { orgAPI } from '../constants';
import { IcityDetails } from '../types/cities.types';

/**
 * @param countryId The id of the country in the organization MS
 * @returns The list of defined cities for that country
 */
export const getCitiesByCountry = async (countryId: string | number) => {
  return apiGet<AxiosRequestConfig, IcityDetails[]>(`${orgAPI}/city/by-country/${countryId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
