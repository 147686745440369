export const AVAILABLE_BILLING_COUNTRIES_FOR_LS = ['co', 'pe', 'ar', 'mx', 'uy', 'br', 'cl'];

export const LOGISTIC_SERVICE_DEFAULT_BILLING_COUNTRY = 'us';

export const PAYMENTS_PROVIDER = 'Stripe';

export enum reportReason {
  UNRESPONSIVE_DELIVERY_EMPLOYEE = 'UNRESPONSIVE_DELIVERY_EMPLOYEE',
  UNRESPONSIVE_RECEIVING_EMPLOYEE = 'UNRESPONSIVE_RECEIVING_EMPLOYEE',
  ORIGIN_POINT_ADVERSE = 'ORIGIN_POINT_ADVERSE',
  DESTINATION_POINT_ADVERSE = 'DESTINATION_POINT_ADVERSE',
  AWAIT_RESPONSE = 'AWAIT_RESPONSE',
  EMPLOYEE_DOES_NOT_COOPERATE = 'EMPLOYEE_DOES_NOT_COOPERATE',
  HOLIDAY_REPORT = 'HOLIDAY',
  EMPLOYEE_NOT_DATE_DELIVERY = 'EMPLOYEE_NOT_DATE_DELIVERY',
  EMPLOYEE_NOT_DATE_RECEIVER = 'EMPLOYEE_NOT_DATE_RECEIVER',
  EMPLOYEE_NOT_WILLING = 'EMPLOYEE_NOT_WILLING',
  WITHOUT_REASON = 'WITHOUT_REASON'
}
