import { searchEventAction } from '../utils/segmentEventsAction';
import { Tany } from '../types/global';
import { IGeneralEventsType } from '../types/segment/generalEvents';
import { getPersonalData } from '../utils/getLocalStorageData';
const Source = 'nodi';

interface IEvent {
  event: string;
  data?: object;
}

const getOrgData = () => {
  const JSONorgData = localStorage.getItem('orgData');
  const orgData = JSONorgData && JSON.parse(JSONorgData);
  if (!orgData) return undefined;
  return orgData;
};

const getScreenSize = () =>
  `width ${window && window.innerWidth !== undefined && window.innerWidth}px, height ${
    window && window.innerHeight !== undefined && window.innerHeight
  }px`;

const generateUuid = () =>
  `${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}-${Date.now()}`;

const basicEventData = {
  Source,
  ScreenSize: getScreenSize()
};

const getSessionId = () => {
  const uniqSessionIdLS = localStorage.getItem('uniqSessionId');
  if (uniqSessionIdLS) return JSON.parse(uniqSessionIdLS).id;

  const uniqId = generateUuid();
  localStorage.setItem('uniqSessionId', JSON.stringify({ id: uniqId }));
  return uniqId;
};

const deletePreviousSessionId = () => localStorage.removeItem('uniqSessionId');

const identifyEvent = (data?: Tany) => {
  const orgData = getOrgData();
  const userId = orgData?.userId;
  const userData = {
    CompanyName: orgData?.organizationName,
    CompanyId: orgData?.organizationId,
    UserId: userId
  };
  if (!userId) return;
  const dataToSend = {
    ...basicEventData,
    ...userData,
    SessionId: getSessionId(),
    ...data
  };
  window.analytics.identify(`userId-${userId}`, dataToSend);
  window.analytics.track('Session_Start', { ...basicEventData, SessionId: getSessionId() });
};

const trackEvent = ({ event: eventName, data }: IEvent) => {
  const uniqSessionIdLS = localStorage.getItem('uniqSessionId');
  const personalData = getPersonalData();
  if (!uniqSessionIdLS) identifyEvent();
  const dataToSend = {
    ...basicEventData,
    SessionId: getSessionId(),
    CompanyId: getOrgData()?.organizationId || 'Not available',
    CompanyName: getOrgData()?.organizationName || 'Not available',
    userType: personalData?.role === 'admin' ? 'ADM' : 'Usuario',
    ...data
  };
  window.analytics.track(eventName, dataToSend);
};

export const segmentTrackEvent = async (objetEvent: IGeneralEventsType) => {
  const eventContent = objetEvent;
  const objetKey = Object.keys(eventContent)[0];
  const searchEvent = searchEventAction(objetKey);
  if (searchEvent) {
    const eventNameInSegment = searchEvent;
    const dataSentToSegment = Object.values(eventContent)?.[0] || {};
    trackEvent({ event: `${eventNameInSegment}`, data: dataSentToSegment });
  } else {
    const dataSentToSegment = Object.values(eventContent)?.[0] || {};
    console.error({ unregisteredEvents: objetKey });
    window.analytics.track('unregisteredEvents', { unregisteredEventName: objetKey, ...dataSentToSegment });
  }
};

const resetEvent = () => {
  window.analytics.reset();
  deletePreviousSessionId();
};

const sessionEndingEvent = () => {
  deletePreviousSessionId();
};

export const deleteAndCreateNewID = () => {
  deletePreviousSessionId();
  getSessionId();
};

export default {
  generateUuid,
  identifyEvent,
  resetEvent,
  sessionEndingEvent
};
