import { useTranslation } from 'react-i18next';
import BordStepper from '../../../../../components/BordDesignSystem/BordStepper/BordStepper';

import './CheckoutProgressBar.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 */
const CheckoutProgressBar = ({ currentStep }: { currentStep: number }) => {
  const { t } = useTranslation();

  const progressSteps = [
    {
      order: 0,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <div className="stepText">{t('recurrentWords:cart')}</div>
        </div>
      ),
      stepWidth: currentStep === 0 ? 68 : 24,
      customStepStyles: undefined
    },
    {
      order: 1,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <div className="stepText">{t('recurrentWords:shipments')}</div>
        </div>
      ),
      stepWidth: currentStep === 1 ? 68 : 24,
      customStepStyles: undefined
    },
    {
      order: 2,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <div className="stepText">{t('recurrentWords:payments')}</div>
        </div>
      ),
      stepWidth: currentStep === 2 ? 68 : 24,
      customStepStyles: undefined
    },
    {
      order: 3,
      stepContent: (
        <div className="checkoutProgressStepContent">
          <div className="stepText">{t('recurrentWords:summary')}</div>
        </div>
      ),
      stepWidth: currentStep === 3 ? 68 : 24,
      customStepStyles: undefined
    }
  ];

  return (
    <div className="checkoutProgressBar">
      <BordStepper steps={progressSteps} currentStep={currentStep} />
    </div>
  );
};

export default CheckoutProgressBar;
