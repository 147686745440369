import { useEffect, useState } from 'react';
import { BordButton, BordOneToneIcon, BordUploaderButton } from '../../BordDesignSystem';

import { getOrgData } from '../../../utils/getLocalStorageData';
import { isTaxDocumentByCountryValid } from '../../../utils/formValidations';
import { ICountryDetail } from '../../../types/countries';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { updateOrderBillingData } from '../../../services/orders';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import {
  countriesRequiringTaxSituationDocument,
  formatZipCode,
  getIsInvalidBusinessName,
  getIsInvalidZipCode,
  invalidBusinessName,
  invalidZipCodeText
} from '../../../utils/productDefinitions';
import {
  DTOgetTaxationRelatedDefinitionsOptions,
  IbillingData,
  ItaxationRelatedDataRequiredForBilling
} from '../../../types/checkout';
import { getTaxationRelatedDefinitionsOptions } from '../../../services/checkout';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { updateLogisticServiceBillingData } from '../../../services/logisticsServicesModule.services';
import { BordTextInput } from '../../BordDesignSystem';
import BordPhoneInput from '../../BordDesignSystem/BordPhoneInput/BordPhoneInput';
import NudosCountryDropdown from '../NudosCountryDropdown/NudosCountryDropdown';
import { useBillingData } from '../../../hooks/useBillingData';
import { truncateText } from '../../../utils/truncateText';
import '../NudosBillingDataFormModal/NudosBillingDataFormModal.scss';
import './BordUpdateBillingDataModal.scss';

const BordUpdateBillingDataModalContent = ({
  initialData,
  billingCountryData,
  closeModalCallback,
  updateBillingData,
  orderOrLogisticId
}: {
  initialData: IbillingData;
  billingCountryData?: ICountryDetail;
  closeModalCallback: React.Dispatch<React.SetStateAction<boolean>>;
  updateBillingData: () => void;
  orderOrLogisticId: number | string;
}) => {
  const { t } = useTranslation();
  const orgData = getOrgData();
  const { pathname } = useLocation();

  const { getTaxUseDropdownWithSearchbar, getTaxRegimeDropdownWithSearchbar } = useBillingData(initialData);

  const [loading, setLoading] = useState(false);
  const [businessName, setBusinessName] = useState<string>();
  const [taxNumber, setTaxNumber] = useState<string>();
  const [city, setCity] = useState<string>();
  const [addressAditionalReferences, setAddressAditionalReferences] = useState<string>();
  const [zipCode, setZipCode] = useState<string>();
  const [billingAddress, setBillingAddress] = useState<string>();
  const [countryRegionOrState, setCountryRegionOrState] = useState<string>();
  const [billingOrganizationCountry, setBillingOrganizationCountry] = useState<ICountryDetail>();
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();
  const [billingEmail, setBillingEmail] = useState<string>();
  const [taxationRelatedDefinitionsOptions, setTaxationRelatedDefinitionsOptions] =
    useState<DTOgetTaxationRelatedDefinitionsOptions>();
  const [newTaxSituation, setTaxSituation] = useState<ItaxationRelatedDataRequiredForBilling>();
  const [newTaxUse, setTaxUse] = useState<ItaxationRelatedDataRequiredForBilling>();
  const [taxSituationDocument, setTaxSituationDocument] = useState<File>();
  const [fantasyName, setFantasyName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [phoneCountry, setPhoneCountry] = useState<ICountryDetail>();
  const [isEdit, setIsEdit] = useState(false);

  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:';
  const i18nLanguageKeyBilling = 'nodi:account:billingDataByCountryForm:';
  const alertText = t(`${i18nLanguageKeyBilling}alertText`);
  const organizationId = orgData?.organizationId;
  const isLogisticFLux = pathname.includes('logistics-services');
  const isAMexicanOrganization = !!billingOrganizationCountry?.code && billingOrganizationCountry?.code === 'mx';
  const organizationIsUruguay = !!billingOrganizationCountry?.code && billingOrganizationCountry?.code === 'uy';
  const requiresTaxDocument = countriesRequiringTaxSituationDocument.includes(billingOrganizationCountry?.code || '');
  const taxNumberErrorText =
    taxNumber && !isTaxDocumentByCountryValid(billingCountryData, taxNumber) ? 'Documento inválido' : '';

  const getBillingTaxDefinitionsOptions = async () => {
    if (!isAMexicanOrganization) return;
    try {
      const newBillingTaxDefinitionsOptions = await getTaxationRelatedDefinitionsOptions();
      newBillingTaxDefinitionsOptions && setTaxationRelatedDefinitionsOptions(newBillingTaxDefinitionsOptions);
    } catch (err) {
      displayErrorNotification();
    }
  };

  const handleSelectTaxStatusDocumentToUpload = (file?: File) => setTaxSituationDocument(file);

  const getTaxDocumentButtonText = () => {
    if (isAMexicanOrganization) return 'Constancia de situación fiscal';
    if (initialData?.documentCountry?.code === 'co') return 'RUT';
    return 'Documento Fiscal de la empresa';
  };

  const getBusinessNameError = () => {
    if (!businessName?.trim()) return ' ';
    if (getIsInvalidBusinessName(businessName)) return invalidBusinessName;
    return undefined;
  };

  const isButtonDisable = () => {
    if (!organizationId) return true;
    if (!businessName || !taxNumber || !billingAddress || !countryRegionOrState || !city) return true;
    if (getIsInvalidBusinessName(businessName)) return true;
    if (!addressAditionalReferences || !zipCode || !isTaxDocumentByCountryValid(billingCountryData, taxNumber))
      return true;
    if (!billingOrganizationCountry) return true;
    if (!billingEmail) return true;
    if (getIsInvalidZipCode(zipCode)) return true;
    if (isAMexicanOrganization && (!newTaxSituation || !newTaxUse)) return true;
    if (organizationIsUruguay && (!fantasyName || !phoneNumber || !phoneCountry)) return true;
    if (!isEdit) return true;
    return false;
  };

  const handleUpdateBillingData = async () => {
    if (!organizationId || !orderOrLogisticId) return;
    if (!businessName || !taxNumber || !billingAddress || !countryRegionOrState || !city) return;
    if (!addressAditionalReferences || !zipCode || !isTaxDocumentByCountryValid(billingCountryData, taxNumber)) return;
    setLoading(true);
    const body: IbillingData = {
      id: initialData?.id,
      organizationId: initialData?.organizationId,
      name: initialData?.name,
      businessName,
      document: taxNumber,
      documentCountryCode: billingOrganizationCountry?.code || '',
      documentCountry: billingOrganizationCountry,
      address: billingAddress,
      additionalReferences: addressAditionalReferences,
      zipCode,
      city,
      fantasyName,
      phone: phoneNumber,
      phoneCountry,
      state: countryRegionOrState,
      email: billingEmail,
      taxSituationDocUrl: initialData?.taxSituationDocUrl || '',
      taxSituation: newTaxSituation,
      taxUse: newTaxUse
    };
    const formData = new FormData();
    formData.append('billingData', JSON.stringify(body));
    taxSituationDocument && formData.append('file', taxSituationDocument);
    try {
      if (isLogisticFLux) {
        await updateLogisticServiceBillingData(orderOrLogisticId, formData);
      } else await updateOrderBillingData(orderOrLogisticId, formData);

      updateBillingData();
      displaySuccessNotification({
        customJSXMessage: <>{t(`${i18nLanguageKey}displaySuccessNotification`)}</>
      });
      closeModalCallback(false);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const setFormFieldsInitialValues = (initialData?: IbillingData) => {
    if (!initialData) return;
    if (!businessName && initialData?.businessName) setBusinessName(initialData?.businessName);
    if (!taxNumber && initialData?.document) setTaxNumber(initialData?.document);
    if (!city && initialData?.city) setCity(initialData?.city);
    if (!addressAditionalReferences && initialData?.additionalReferences)
      setAddressAditionalReferences(initialData?.additionalReferences);
    if (!zipCode && initialData?.zipCode) setZipCode(formatZipCode(initialData?.zipCode));
    if (!billingAddress && initialData?.address) setBillingAddress(initialData?.address);
    if (!countryRegionOrState && initialData?.state) setCountryRegionOrState(initialData?.state);
    if (!newTaxSituation && initialData?.taxSituation) setTaxSituation(initialData?.taxSituation);
    if (!newTaxUse && initialData?.taxUse) setTaxUse(initialData?.taxUse);
    if (initialData?.documentCountry) setBillingOrganizationCountry(initialData?.documentCountry);
    if (initialData?.email) setBillingEmail(initialData?.email);
    if (!phoneCountry && initialData?.phoneCountry) setPhoneCountry(initialData?.phoneCountry);
    if (!phoneNumber && !!initialData?.phone) setPhoneNumber(initialData?.phone);
    if (!fantasyName && initialData?.fantasyName) setFantasyName(initialData?.fantasyName);
  };

  useEffect(() => {
    initialData && setFormFieldsInitialValues(initialData);
    getBillingTaxDefinitionsOptions();
  }, [initialData]);

  useEffect(() => {
    isAMexicanOrganization && getBillingTaxDefinitionsOptions();
  }, [isAMexicanOrganization]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setCountriesList);
  }, []);

  return (
    <div className="updateOrderBillingDataModal">
      <div className="orderBillingDataForm">
        <div className="formFieldsLine firstLine">
          <NudosCountryDropdown
            customLabel={t(`${i18nLanguageKey}updateOrderBillingDataModal:idCountry`)}
            charactersLimit={4}
            currentValue={billingOrganizationCountry}
            countriesList={countriesList || []}
            updateCurrentValueCallback={e => {
              setIsEdit(true);
              setBillingOrganizationCountry(e);
            }}
            isDeactivated={!!billingOrganizationCountry}
            showSkeletonLoader={loading}
            componentSize="w-80"
          />
          <BordTextInput
            label={t(`${i18nLanguageKey}updateOrderBillingDataModal:denomination`)}
            standardSize="w-280"
            placeholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:legalName`)}
            isFilled={!!businessName}
            currentText={businessName}
            setCurrentText={e => {
              setIsEdit(true);
              setBusinessName(e);
            }}
            errorText={getBusinessNameError()}
          />
          <BordTextInput
            label={truncateText(t(`${i18nLanguageKey}updateOrderBillingDataModal:taxDocument`), 30)}
            standardSize="w-180"
            placeholder="RUT, CUIT, NIT"
            isFilled={!!taxNumber}
            currentText={taxNumber}
            setCurrentText={e => {
              setIsEdit(true);
              setTaxNumber(e);
            }}
            errorText={taxNumberErrorText}
          />
        </div>
        {organizationIsUruguay && (
          <div className="formFieldsLine secondLine">
            <BordPhoneInput
              label="Número de teléfono"
              placeholder="Número de teléfono"
              countriesList={countriesList || []}
              personalClass="phoneInput"
              componentSize="w280"
              defaultPhoneNumber={phoneNumber}
              defaultCountryData={phoneCountry}
              handlePhoneChange={e => {
                setIsEdit(true);
                setPhoneNumber(e);
              }}
              handleCountryPhoneChange={setPhoneCountry}
            />
            <BordTextInput
              label="Nombre fantasía*"
              isFilled={!!fantasyName}
              currentText={fantasyName}
              placeholder="Nombre fantasía"
              setCurrentText={e => {
                setIsEdit(true);
                setFantasyName(e);
              }}
              className="fantasyNameInput"
            />
          </div>
        )}
        <div className="formFieldsLine secondLine">
          <BordTextInput
            className="billingAddress"
            label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:billingEmail`)}*`}
            standardSize="w-180"
            placeholder={t(`${i18nLanguageKey}updateOrderBillingDataModal:billingEmail`)}
            isFilled={!!billingEmail}
            currentText={billingEmail}
            setCurrentText={e => {
              setIsEdit(true);
              setBillingEmail(e);
            }}
          />
          <BordTextInput
            label={truncateText('Código postal*', 13)}
            standardSize="w-80"
            placeholder="43221"
            isFilled={!!zipCode}
            currentText={zipCode}
            setCurrentText={e => {
              setIsEdit(true);
              setZipCode(e);
            }}
            handleBlur={() => setZipCode(formatZipCode(zipCode))}
            errorText={getIsInvalidZipCode(zipCode) ? invalidZipCodeText : undefined}
          />
          <BordTextInput
            label={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:city`)}*`}
            standardSize="w-280"
            placeholder={`${t(`${i18nLanguageKey}updateOrderBillingDataModal:city`)}*`}
            isFilled={!!city}
            currentText={city}
            setCurrentText={e => {
              setIsEdit(true);
              setCity(e);
            }}
          />
        </div>
        <div className="formFieldsLine thirdLine">
          <BordTextInput
            label="Estado/Provincia/Departamento*"
            standardSize="w-180"
            placeholder="Estado/Provincia/Dpto"
            isFilled={!!countryRegionOrState}
            currentText={countryRegionOrState}
            setCurrentText={e => {
              setIsEdit(true);
              setCountryRegionOrState(e);
            }}
          />
          <BordTextInput
            label={t(`${i18nLanguageKey}directions:label`)}
            standardSize="w-380"
            placeholder={t(`${i18nLanguageKey}directions:placeholder`)}
            isFilled={!!addressAditionalReferences}
            currentText={addressAditionalReferences}
            setCurrentText={e => {
              setIsEdit(true);
              setAddressAditionalReferences(e);
            }}
          />
        </div>
        <div className="mt-12">
          <BordTextInput
            className="billingAddress"
            label={t(`${i18nLanguageKey}billingAddress:label`)}
            standardSize="w-580"
            placeholder={t(`${i18nLanguageKey}billingAddress:placeholder`)}
            isFilled={!!billingAddress}
            currentText={billingAddress}
            setCurrentText={e => {
              setIsEdit(true);
              setBillingAddress(e);
            }}
          />
        </div>
        {requiresTaxDocument && (
          <div className="formFieldsLine fifthLine">
            <BordUploaderButton
              label="RUT"
              handleChangeFile={handleSelectTaxStatusDocumentToUpload}
              defaultFile={{ documentName: '', url: initialData?.taxSituationDocUrl ?? '' }}
              emptyBordUploaderButtonSubtitle={getTaxDocumentButtonText()}
              emptyBordUploaderButtonTitle={getTaxDocumentButtonText()}
              defaultWidthFull
            />
          </div>
        )}
        {isAMexicanOrganization && (
          <div className="formFieldsLine fourthLine">
            <div className="w-280">
              {getTaxRegimeDropdownWithSearchbar(
                taxationRelatedDefinitionsOptions?.taxSituation ?? [],
                false,
                true,
                setIsEdit
              )}
            </div>
            <div className="w-280">
              {getTaxUseDropdownWithSearchbar(taxationRelatedDefinitionsOptions?.taxUse || [], false, true, setIsEdit)}
            </div>
          </div>
        )}
        <div className="alert">
          <BordOneToneIcon variant="alertTriangleWarning" standardSize={12} /> {alertText}
        </div>
        <div className="pt-32 mb-40" />
        <div className="buttonContainer">
          <BordButton
            label={t(`${i18nLanguageKey}nudosButton`)}
            onClick={handleUpdateBillingData}
            disabled={isButtonDisable()}
            isLoading={loading}
            customWidth="w-380"
          />
        </div>
      </div>
    </div>
  );
};

export default BordUpdateBillingDataModalContent;
