const RoundPuertoRico = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 0C10.7654 0 6.11822 2.51394 3.19922 6.4H28.8006C25.8816 2.51394 21.2344 0 15.9999 0ZM15.9999 32.0001C21.2344 32.0001 25.8816 29.4862 28.8006 25.6001H3.19922C6.11822 29.4862 10.7654 32.0001 15.9999 32.0001ZM0 16C0 17.096.1105 18.1661.320437 19.2H31.6797C31.8896 18.1661 32 17.096 32 16 32 14.9041 31.8895 13.834 31.6796 12.8H.320437C.1105 13.834 0 14.9041 0 16H0Z"
        />
        <path
          fill="#1A47B8"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="white"
          d="M6.4756 11.8262L7.51154 15.0147H10.8644L8.15204 16.9854L9.18791 20.174L6.4756 18.2034L3.76316 20.174L4.79929 16.9854L2.08691 15.0147H5.43954L6.4756 11.8262Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPuertoRico;
