const RoundGermany = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M0.995117 21.5651C3.2558 27.6578 9.12049 31.9999 15.9999 31.9999C22.8794 31.9999 28.7441 27.6578 31.0047 21.5651L15.9999 20.1738L0.995117 21.5651Z"
        />
        <path
          fill="black"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348L15.9999 11.8261L31.0047 10.4347C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="#F93939"
          d="M0.995188 10.4348C0.352063 12.1681 0 14.0429 0 16C0 17.9571 0.352063 19.8319 0.995188 21.5652H31.0049C31.648 19.8319 32 17.9571 32 16C32 14.0429 31.648 12.1681 31.0048 10.4348H0.995188Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGermany;
