const RoundCuracao = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9997 32c5.25 0 9.9093-2.5286 12.8268-6.4343H3.17285C6.09035 29.4714 10.7497 32 15.9997 32ZM32 16c0-8.8365-7.1635-16-16-16C7.1635 0 0 7.1635 0 16c0 1.8921.329313 3.707.93225 5.3918H31.0677C31.6707 19.707 32 17.8921 32 16Z"
        />
        <path
          fill="#fff"
          d="m10.9511 10.2615.8633 2.657h2.794l-2.2603 1.6423.8633 2.6572-2.2603-1.6423-2.26028 1.6423.86344-2.6572-2.26031-1.6423h2.79385l.8633-2.657ZM6.15323 7.47876l.518 1.59425h1.67644l-1.35619.98539.518 1.5943-1.35625-.9854-1.35618.9854.51806-1.5943-1.35613-.98539h1.67638l.51787-1.59425Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCuracao;
