import { Trans, useTranslation } from 'react-i18next';
import { BordButton, BordTextInput } from '../../../../components/BordDesignSystem';
import BordLogo from '../../../../components/BordDesignSystem/BordLogo/BordLogo';
import BordTextButton from '../../../../components/BordDesignSystem/BordTextButton/BordTextButton';
import BordPasswordInput from '../../../../components/BordDesignSystem/BordPaswordInput/BordPasswordInput';
import { FORM_COMPONENTS_STANDARD_SIZES } from '../../../../components/BordDesignSystem/bordDesignSystem.types';
import { segmentTrackEvent } from '../../../../utils/segment';
import { useEffect, useState } from 'react';
import { isEmailValid } from '../../../../utils/formValidations';
import resetPassword from '../../../../utils/resetPassword';
import { useHistory } from 'react-router-dom';
import useSignInController from '../../signIn.controller';
import LanguageSelectionButton from '../../../../components/SelectLanguageButton/SelectLanguageButton';
import { getDefaultLanguage } from '../../../../utils/getDefaultLanguage';
import './LogInForm.scss';

const LogInForm = () => {
  const { onSubmit, verifyEmail } = useSignInController();
  const { t } = useTranslation();
  const { push } = useHistory();
  const [loginStep, setLoginStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string>();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string>();

  const recurrentWords = 'recurrentWords:';
  const translationKey = 'authentication:signIn:';
  const buttonText = 'buttonStep1';
  const language = getDefaultLanguage();

  const disableButton = () => {
    if (loginStep === 0) return !isEmailValid(email);
    if (loginStep === 1) return !password;
  };

  const recoveryPassword = () => {
    segmentTrackEvent({ loginRecoverPasswordClick: { ContactMail: email } });
    resetPassword(email);
  };

  const handleContinueOnFirstStep = () => {
    segmentTrackEvent({ loginClick: null });
    verifyEmail(email, setLoading, setLoginStep, setEmailError);
  };

  const handleSuccessfullSubmit = () => {
    const data = { email, password };
    segmentTrackEvent({ loginPasswordClick: { ContactMail: data.email } });
    setPasswordError(undefined);
    onSubmit(data, setLoading, setPasswordError);
  };

  const handleGoToRegister = () => {
    segmentTrackEvent({ loginAccessRequestClick: null });
    push('/signup');
  };

  const handleClickButton = loginStep === 1 ? handleSuccessfullSubmit : handleContinueOnFirstStep;

  const updateEmail = (newEmail: string) => {
    emailError && setEmailError(undefined);
    setEmail(newEmail);
  };

  useEffect(() => {
    if (loginStep === 0) {
      segmentTrackEvent({ loginView: null });
    }
  }, [loginStep]);

  useEffect(() => {
    if (loginStep === 1) {
      segmentTrackEvent({ nodiLoginPasswordView: { ContactMail: email } });
    }
  }, [loginStep]);

  return (
    <div className="logInForm">
      <div className="contentsContainer">
        <LanguageSelectionButton width="4.4rem" currentLanguage={language} />
        <BordLogo customWidth="12rem" />
        <div className="title">
          <Trans i18nKey={`${translationKey}title`} components={{ 1: <span className="text-bord-red" /> }} />
        </div>
        <div className="subtitle">{t(`${translationKey}subtitle`)}</div>

        <div className="loginFormFields">
          <BordTextInput
            className="emailInput"
            label={t(`${recurrentWords}inputs:emailLabel`)}
            placeholder={t(`${recurrentWords}inputs:emailPlaceholder`)}
            isFilled={false}
            standardSize="w-380"
            setCurrentText={updateEmail}
            disabled={loginStep === 1}
            errorText={emailError}
          />
          {loginStep === 1 && (
            <div className="passwordField">
              <BordPasswordInput
                label={t(`${recurrentWords}inputs:passwordLabel`)}
                placeholder={t(`${recurrentWords}inputs:passwordPlaceholder`)}
                standardSize={FORM_COMPONENTS_STANDARD_SIZES.LG}
                setCurrentValue={setPassword}
                errorText={passwordError}
              />
              <div className="forgottenPassword">
                <div className="question">{t(`${translationKey}forgottenPasswordQuestion`)}</div>
                <BordTextButton text={t(`${translationKey}forgottenPasswordButton`)} onClick={recoveryPassword} />
              </div>
            </div>
          )}
        </div>

        <BordButton
          label={t(`${translationKey}${buttonText}`)}
          customWidth="w-380"
          isLoading={loading}
          disabled={disableButton()}
          onClick={handleClickButton}
        />

        <div className="registerInformation">
          <div className="question">{t(`${translationKey}registrationQuestion`)}</div>
          <BordTextButton text={t(`${translationKey}registrationButton`)} onClick={handleGoToRegister} />
        </div>
      </div>
    </div>
  );
};

export default LogInForm;
