const RoundBurundi = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M25.7423 3.3064c-5.7282-4.40787-13.7562-4.40787-19.48449-.00006l9.74229 9.74226 9.7422-9.7422ZM15.9999 18.9515l-9.74209 9.7422c5.72829 4.4078 13.75629 4.4078 19.48449 0l-9.7422-9.7423-.0002.0001Z"
        />
        <path
          fill="#6DA544"
          d="M13.0481 16.0001 3.30591 6.25781c-4.40788 5.72829-4.40788 13.75629 0 19.48449l9.74219-9.7422ZM18.9512 16.0001l9.7421 9.7422c4.4079-5.7282 4.4079-13.7562 0-19.48449l-9.7421 9.74229Z"
        />
        <path
          fill="#fff"
          d="M16.0004 22.9565c3.842 0 6.9565-3.1146 6.9565-6.9565 0-3.842-3.1145-6.95654-6.9565-6.95654-3.8419 0-6.95645 3.11454-6.95645 6.95654 0 3.8419 3.11455 6.9565 6.95645 6.9565Z"
        />
        <path
          fill="#F93939"
          d="m16.0007 11.1304.6024 1.0435h1.2049l-.6025 1.0434.6025 1.0435h-1.2049l-.6024 1.0435-.6025-1.0435h-1.2048l.6024-1.0435-.6024-1.0434h1.2048l.6025-1.0435ZM12.9382 16l.6025 1.0435h1.2048l-.6024 1.0434.6024 1.0435h-1.2048l-.6025 1.0435-.6025-1.0435h-1.2048l.6024-1.0435-.6024-1.0434h1.2048L12.9382 16ZM19.0623 16l.6024 1.0435h1.2049l-.6025 1.0434.6025 1.0435h-1.2049l-.6024 1.0435-.6025-1.0435h-1.2049l.6024-1.0435-.6024-1.0434h1.2049L19.0623 16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBurundi;
