import { useTranslation } from 'react-i18next';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import { IshipmentDetails } from '../../../../../types/orders';
import ShipmentProductCard from '../ShipmentProductCard/ShipmentProductCard';
import { ShipmentStatusTracker } from '../ShipmentStatusTracker/ShipmentStatusTracker';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import './OrderShipmentCard.scss';

const OrderShipmentCard = ({
  shipmentData,
  totalProducts = 0
}: {
  shipmentData: IshipmentDetails;
  totalProducts: number | null;
}) => {
  const { t } = useTranslation();
  const {
    shipmentTrackingCode,
    shipmentTrackingUrl,
    estimatedDeliveryDate,
    deliveryDate,
    countryTimeZone,
    statusName,
    productsInfo
  } = shipmentData || {};
  const i18nLanguageKey = 'nodi:orderDetails:orderShipmentCard:';
  const activeDeliveryDateStyle = deliveryDate || estimatedDeliveryDate ? 'active' : '';
  const isTrackingUrlAValidLink = !!shipmentTrackingUrl?.toLowerCase()?.includes('https');
  const activeCodeStyles = shipmentTrackingCode ? 'active' : 'disableStyle';
  const totalUnits = productsInfo?.length || 0;
  const getShipmentTrackingLinkText = () => {
    if (isTrackingUrlAValidLink)
      return (
        <a className="trackingUrl" href={shipmentTrackingUrl || undefined} target="blank">
          {shipmentTrackingUrl}
        </a>
      );
    if (!isTrackingUrlAValidLink && shipmentTrackingUrl)
      return (
        <NudosHoverText
          customClassName="trackingInstructions"
          onlyIfTruncated
          text={shipmentTrackingUrl}
          charactersLimit={40}
        />
      );
    if (!shipmentTrackingUrl)
      return <div className="pendingTrackingUrl">{t(`${i18nLanguageKey}pendingTrackingUrl`)}</div>;
  };

  const getDeliveryOrEstimatedDeliveryDate = () => {
    const deliveryDateText = `Entregado: ${
      formatShortDate(deliveryDate || undefined, countryTimeZone || undefined)?.textWithTimezone || '-'
    }`;
    const estimatedDeliveryDateText = `${t(`${i18nLanguageKey}estimatedDeliveryDateText`)} : ${
      formatShortDate(estimatedDeliveryDate || undefined, countryTimeZone || undefined)?.textWithTimezone || '-'
    }`;
    if (deliveryDate) return deliveryDateText;
    if (estimatedDeliveryDate) return estimatedDeliveryDateText;
    return `${t(`${i18nLanguageKey}estimatedDeliveryDateText`)} -`;
  };
  return (
    <div className="orderShipmentCard">
      <div className="productsCount">
        <span className="text">{t(`${i18nLanguageKey}productsText`)}</span>{' '}
        <span className="count">{totalProducts}</span> {' - '}
        <span className="count mr-5 font-normal">{totalUnits}</span>
        <span className="text font-normal">Unid</span>
      </div>
      <div className="trackingInformation">
        <div className="flex items-center gap-2">
          <div className="truck">🚛</div>
          {getShipmentTrackingLinkText()}
        </div>
        <NudosHoverText
          customClassName={`trackingCode ${activeCodeStyles}`}
          text={`${t(`${i18nLanguageKey}trackingCode`)} ${shipmentTrackingCode || '-'}`}
          charactersLimit={22}
          onlyIfTruncated
        />
        <div className={`estimatedDeliveryDate ${activeDeliveryDateStyle}`}>{getDeliveryOrEstimatedDeliveryDate()}</div>
      </div>
      <ShipmentStatusTracker shipmentStatus={statusName} />

      <div className={`shipmentProducts`}>
        {productsInfo?.map((product, i) => {
          return (
            <ShipmentProductCard
              key={`product${product?.serial || ''}-${i}`}
              productData={product}
              shipmentData={shipmentData}
            />
          );
        })}
      </div>
    </div>
  );
};

export default OrderShipmentCard;
