import InProgressStepperIcon from './components/InProgressStepperIcon';
import WaitingStepperIcon from './components/WaitingStepperIcon';
import { SuccessStepperIcon } from './components/SuccessStepperIcon';
import ProgressBarSkeletonLoader from './components/ProgressBarSkeletonLoader';
import { IprogressStepData, RenderTypeStepProps, STEPPER_STATE } from './BorderStepper.types';
import BordOneToneIcon from '../BordOneToneIcon/BordOneToneIcon';
import BordTooltip from '../BordTooltip/BordTooltip';
import { bordColorTheme } from '../../../styles/constants';
import './BordStepper.scss';

const BordStepper = ({
  steps,
  currentStep,
  stepperConectorWidth,
  customHeight,
  customStepperClassname,
  skeletonSteps = [],
  loading,
  isStepContent = true,
  titleStepperCustomClass,
  subTitleCustomClass
}: {
  steps: IprogressStepData[];
  currentStep: number;
  stepperConectorWidth?: string;
  customHeight?: number;
  customWidth?: string;
  customStepperClassname?: string;
  skeletonSteps?: IprogressStepData[];
  loading?: boolean;
  isStepContent?: boolean;
  titleStepperCustomClass?: string;
  subTitleCustomClass?: string;
}) => {
  const heightStyles = customHeight ?? undefined;
  const renderStepperConectorWidth = stepperConectorWidth ?? 'min-w-190 w-full';

  const renderTypeStep = ({ currentStep, step }: RenderTypeStepProps): STEPPER_STATE => {
    if (currentStep === step) return STEPPER_STATE.inProgress;
    if (currentStep > step) return STEPPER_STATE.success;
    if (currentStep < step) return STEPPER_STATE.waiting;
    return STEPPER_STATE.inProgress;
  };

  if (loading)
    return (
      <ProgressBarSkeletonLoader
        skeletonSteps={skeletonSteps}
        stepperConectorWidth={stepperConectorWidth}
        heightStyles={heightStyles}
      />
    );

  return (
    <div className={`bordStepsProgressBar ${customStepperClassname ?? ''}`}>
      {steps.map(
        (
          {
            order = 0,
            customStepStyles,
            stepContent,
            title = 'title',
            subTitle,
            isTooltipMessage = false,
            tooltipMessage = 'message'
          },
          index
        ) => {
          const isLastStep = index === steps.length - 1;
          const stepCurrent = renderTypeStep({ currentStep, step: index });

          const renderConector = {
            [STEPPER_STATE.inProgress]: (
              <div className="inProgressConectorContainer">
                <div className="inProgressConectorFirst"></div>
                <div className="inProgressConectorSecond"></div>
              </div>
            ),
            [STEPPER_STATE.success]: <div className="successConector" />,
            [STEPPER_STATE.waiting]: <div className="waitingConector"></div>
          };

          const renderIcon = {
            [STEPPER_STATE.inProgress]: <InProgressStepperIcon />,
            [STEPPER_STATE.success]: <SuccessStepperIcon />,
            [STEPPER_STATE.waiting]: <WaitingStepperIcon />
          };

          const renderTooltip = isTooltipMessage ? (
            <BordTooltip message={tooltipMessage}>
              <BordOneToneIcon variant="info" customWidth="1.2rem" stroke={bordColorTheme.content.default} />
            </BordTooltip>
          ) : (
            <></>
          );
          const renderStepContent = isStepContent ? (
            stepContent ?? <></>
          ) : (
            <>
              <div className={`titleStepper ${titleStepperCustomClass || ''}`}>{title}</div>
              {subTitle && (
                <div className="subTitleContainer">
                  <span className={`subTitle ${subTitleCustomClass || ''}`}>{subTitle}</span>
                  {renderTooltip}
                </div>
              )}
            </>
          );

          return (
            <div key={`progressStep${order}`} className={`progressStep`} style={customStepStyles}>
              <div className="stepConectorAndStatusContainer">
                {renderIcon[stepCurrent]}
                {!isLastStep && (
                  <div className={`${renderStepperConectorWidth} mb-3`}>{renderConector[stepCurrent]}</div>
                )}
              </div>
              <div className="titleAndSubtitleContainer">{renderStepContent}</div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default BordStepper;
