const RoundBrazil = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#6DA544"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9996 6.26099 29.217 16.0001l-13.2174 9.7391-13.21737-9.7391L15.9996 6.26099Z"
        />
        <path
          fill="#fff"
          d="M15.9998 21.5652c3.0735 0 5.5651-2.4916 5.5651-5.5652s-2.4916-5.5652-5.5651-5.5652c-3.0736 0-5.5652 2.4916-5.5652 5.5652s2.4916 5.5652 5.5652 5.5652Z"
        />
        <path
          fill="#1A47B8"
          d="M13.2173 15.6521c-.9677 0-1.9017.1472-2.7808.4203.039 3.04 2.5142 5.4928 5.5635 5.4928 1.8855 0 3.5507-.9386 4.5574-2.373-1.7223-2.1561-4.3724-3.5401-7.3401-3.5401ZM21.4619 17.0663c.067-.3453.1032-.7015.1032-1.0663 0-3.0736-2.4917-5.5652-5.5652-5.5652-2.2934 0-4.2622 1.3876-5.1142 3.3687.7532-.1561 1.5329-.2382 2.3316-.2382 3.2323-.0001 6.1564 1.3435 8.2446 3.501Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBrazil;
