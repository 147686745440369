import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { getCountryById } from '../../../services/countries.service';
import { NudosHoverText } from '../../NudosComponents';
import { BordButton, BordOneToneIcon } from '../../BordDesignSystem';

import { IbillingData } from '../../../types/checkout';
import BordUpdateBillingDataModal from '../BordUpdateBillingDataModal/BordUpdateBillingDataModal';
import { bordColorTheme } from '../../../styles/constants';
import './BordBillingDataSection.scss';

const BordBillingDataSection = ({
  billingData,
  updateDataCallback: updateDataCallback,
  disableEditingOfBillingData,
  billingCountryId = 3, // México in production
  loading,
  orderOrLogisticId,
  invoiceUrl,
  invoiceText,
  regenerateNewInvoice,
  loadingRegenerateInvoice,
  displayInvoice,
  showRenegerateInvoice = true,
  modeButtonDownload = 'secondary'
}: {
  billingData: IbillingData;
  updateDataCallback: () => void;
  disableEditingOfBillingData?: boolean;
  billingCountryId?: number;
  loading?: boolean;
  orderOrLogisticId?: string | number;
  invoiceUrl?: string;
  invoiceText?: string;
  regenerateNewInvoice?: () => void;
  loadingRegenerateInvoice?: boolean;
  displayInvoice?: boolean;
  showRenegerateInvoice?: boolean;
  modeButtonDownload?: 'secondary' | 'tertiary';
  customBackgroundButton?: string;
}) => {
  const { t } = useTranslation();

  const [showUpdateOrderBillingDataModal, setShowUpdateOrderBillingDataModal] = useState(false);
  const [billingCountryData, setBillingCountryData] = useState<ICountryDetail>();

  const { businessName, state, document, city, address, additionalReferences, zipCode } = billingData;
  const i18nLanguageKey = 'nodi:orderDetails:orderBillingData:';
  const noDataText = 'Sin datos';

  const handleOpenUpdateBillingDataModal = () => setShowUpdateOrderBillingDataModal(true);

  const getCountryBillingData = async () => {
    try {
      const newCountryBillingData = await getCountryById(billingCountryId);
      setBillingCountryData(newCountryBillingData);
    } catch (error) {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    billingCountryId && getCountryBillingData();
  }, [billingCountryId]);

  if (loading)
    return (
      <div className="orderBillingDataSkeletonLoader">
        <div className="sectionTitle animationLoader" />
        <div className="sectionBody animationLoader" />
        <div className="whiteSpaceAtTheBottom w-10 h-10" />
      </div>
    );

  return (
    <div className="bordBillingDataSection">
      {showUpdateOrderBillingDataModal && orderOrLogisticId && (
        <BordUpdateBillingDataModal
          initialData={billingData}
          billingCountryData={billingCountryData}
          closeModalCallback={setShowUpdateOrderBillingDataModal}
          updateBillingData={updateDataCallback}
          orderOrLogisticId={orderOrLogisticId}
        />
      )}
      <h2 className="sectionTitle">{t(`${i18nLanguageKey}sectionTitle`)}</h2>
      <div className="sectionBody">
        <h6 className="title">{t(`${i18nLanguageKey}companyName`)}</h6>
        <div className="billingDataLine line1">
          <div className="billingDataField businessName">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}businessName`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={businessName || noDataText}
              charactersLimit={25}
            />
          </div>
          <div className="billingDataField taxDocument relative">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}businessId`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={document || noDataText}
              charactersLimit={12}
            />
          </div>
        </div>
        <div className="billingDataLine line2">
          <div className="billingDataField regionOrState">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}regionOrState`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={state || noDataText}
              charactersLimit={32}
            />
          </div>
          <div className="billingDataField city">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}city`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={city || noDataText}
              charactersLimit={32}
            />
          </div>
        </div>
        <div className="billingDataLine line3">
          <div className="billingDataField zipCode">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}zipCode`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={zipCode || noDataText}
              charactersLimit={25}
            />
          </div>
          <div className="billingDataField addressAdditionalReferences">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}addressAdditionalReferences`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={additionalReferences || noDataText}
              charactersLimit={25}
            />
          </div>
        </div>
        <div className="billingDataLine line4">
          <div className="billingDataField address">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}address1`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={billingData?.email || noDataText}
              charactersLimit={55}
            />
          </div>
        </div>
        <div className="billingDataLine line4">
          <div className="billingDataField address">
            <span className="billingDataLineTitle">{t(`${i18nLanguageKey}address2`)}</span>
            <NudosHoverText
              customClassName="billingDataLineValue"
              onlyIfTruncated
              text={address || noDataText}
              charactersLimit={55}
            />
          </div>
        </div>
        <div className="editButtonContainer">
          <BordButton
            customClassName="buttonEdit"
            modeButton="tertiary"
            bordButtonStyle="outlined"
            onClick={handleOpenUpdateBillingDataModal}
            label={t(`${i18nLanguageKey}nudosButton`)}
            disabled={disableEditingOfBillingData}
            customWidth="w-180"
          />
        </div>
        <div className="w-full border border-solid border-stroke mt-8 mb-8" />
        <span className="invoiceText">{invoiceText}</span>
        {invoiceUrl && displayInvoice && (
          <div className="buttonsContainer">
            <BordButton
              onClick={() => {
                const newWindow = window.open(invoiceUrl, '_blank', 'noopener,noreferrer');
                if (newWindow) newWindow.opener = null;
              }}
              label="Descargar factura"
              buttonIconTwo="download"
              customWidth="w-280"
              modeButton={modeButtonDownload}
              disabled={!invoiceUrl}
              style={{
                backgroundColor: bordColorTheme.disabled.bgSecondary
              }}
            />

            {invoiceUrl && showRenegerateInvoice && (
              <div className="generateInvoiceCustomStyles" onClick={regenerateNewInvoice}>
                <span>{loadingRegenerateInvoice ? 'Generando... ' : 'Volver a generar'}</span>
                <BordOneToneIcon variant="refreshCw" standardSize={12} />
              </div>
            )}
          </div>
        )}
        {!invoiceUrl && (
          <div className="emptyInvoiceText">Podrás consultar tu factura en esta sección cuando esté disponible</div>
        )}
      </div>
    </div>
  );
};

export default BordBillingDataSection;
