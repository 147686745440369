const RoundSwaziland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M30.4124 22.9566C31.4295 20.8531 32 18.4932 32 16.0001C32 13.507 31.4295 11.147 30.4124 9.04359L16 8.3479L1.58763 9.04353C0.5705 11.147 0 13.507 0 16.0001C0 18.4932 0.5705 20.8531 1.58763 22.9566L16 23.6523L30.4124 22.9566Z"
        />
        <path
          fill="black"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0Z"
        />
        <path
          fill="#1A47B8"
          d="M16.0003 32C9.65689 32 4.17589 28.3085 1.58789 22.9565H30.4126C27.8246 28.3085 22.3436 32 16.0003 32Z"
        />
        <path
          fill="#A2001D"
          d="M31.2444 11.1304H0.755562C0.265437 12.6659 0 14.3019 0 15.9999C0 17.698 0.265437 19.3339 0.755562 20.8695H31.2444C31.7346 19.3339 32 17.698 32 15.9999C32 14.3019 31.7346 12.6659 31.2444 11.1304Z"
        />
        <path
          fill="#FFDA2C"
          d="M5.56543 15.3044H26.435V16.6958H5.56543V15.3044ZM8.34766 12.5217H23.652V13.913H8.34766V12.5217Z"
        />
        <path
          fill="white"
          d="M23.7517 16.0001C23.7517 16.0001 20.174 20.174 16.0001 20.174C11.8262 20.174 11.8262 16.0001 11.8262 16.0001C11.8262 16.0001 11.8262 11.8262 16.0001 11.8262C20.174 11.8262 23.7517 16.0001 23.7517 16.0001Z"
        />
        <path
          fill="black"
          d="M15.9996 20.174C11.8257 20.174 8.24805 16.0001 8.24805 16.0001C8.24805 16.0001 11.8257 11.8262 15.9996 11.8262"
        />
        <path fill="white" d="M13.2178 14.6086H14.6091V17.3913H13.2178V14.6086Z" />
        <path fill="black" d="M17.3916 14.6086H18.7829V17.3913H17.3916V14.6086Z" />
        <path
          fill="#1A47B8"
          d="M5.56514 17.3913C6.33354 17.3913 6.95645 16.7684 6.95645 16 6.95645 15.2316 6.33354 14.6086 5.56514 14.6086 4.79674 14.6086 4.17383 15.2316 4.17383 16 4.17383 16.7684 4.79674 17.3913 5.56514 17.3913ZM26.4343 17.3913C27.2027 17.3913 27.8256 16.7684 27.8256 16 27.8256 15.2316 27.2027 14.6086 26.4343 14.6086 25.6659 14.6086 25.043 15.2316 25.043 16 25.043 16.7684 25.6659 17.3913 26.4343 17.3913Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSwaziland;
