const SquareMicronesia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#3A99FF" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M10.4764 4.26686H9.94733C9.654 4.26686 9.42876 4.5058 9.42876 4.8002C9.42876 5.08606 9.66133 5.33353 9.94733 5.33353H10.4764V5.8722C10.4764 6.17086 10.711 6.4002 11.0002 6.4002C11.2809 6.4002 11.524 6.1634 11.524 5.8722V5.33353H12.053C12.3464 5.33353 12.5716 5.0946 12.5716 4.8002C12.5716 4.51433 12.339 4.26686 12.053 4.26686H11.524V3.7282C11.524 3.42953 11.2893 3.2002 11.0002 3.2002C10.7194 3.2002 10.4764 3.437 10.4764 3.7282V4.26686ZM14.6669 7.46686H14.1378C13.8445 7.46686 13.6192 7.7058 13.6192 8.0002C13.6192 8.28606 13.8518 8.53353 14.1378 8.53353H14.6669V9.0722C14.6669 9.37086 14.9015 9.6002 15.1907 9.6002C15.4714 9.6002 15.7145 9.3634 15.7145 9.0722V8.53353H16.2435C16.5369 8.53353 16.7621 8.2946 16.7621 8.0002C16.7621 7.71433 16.5295 7.46686 16.2435 7.46686H15.7145V6.9282C15.7145 6.62953 15.4798 6.4002 15.1907 6.4002C14.9099 6.4002 14.6669 6.637 14.6669 6.9282V7.46686ZM10.4764 10.6669H9.94733C9.654 10.6669 9.42876 10.9058 9.42876 11.2002C9.42876 11.4861 9.66133 11.7335 9.94733 11.7335H10.4764V12.2722C10.4764 12.5709 10.711 12.8002 11.0002 12.8002C11.2809 12.8002 11.524 12.5634 11.524 12.2722V11.7335H12.053C12.3464 11.7335 12.5716 11.4946 12.5716 11.2002C12.5716 10.9143 12.339 10.6669 12.053 10.6669H11.524V10.1282C11.524 9.82953 11.2893 9.6002 11.0002 9.6002C10.7194 9.6002 10.4764 9.837 10.4764 10.1282V10.6669ZM6.2859 7.46686H5.75685C5.46352 7.46686 5.23828 7.7058 5.23828 8.0002C5.23828 8.28606 5.47085 8.53353 5.75685 8.53353H6.2859V9.0722C6.2859 9.37086 6.52057 9.6002 6.80971 9.6002C7.09047 9.6002 7.33352 9.3634 7.33352 9.0722V8.53353H7.86257C8.1559 8.53353 8.38114 8.2946 8.38114 8.0002C8.38114 7.71433 8.14857 7.46686 7.86257 7.46686H7.33352V6.9282C7.33352 6.62953 7.09885 6.4002 6.80971 6.4002C6.52895 6.4002 6.2859 6.637 6.2859 6.9282V7.46686Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMicronesia;
