import BordModal from '../../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import ModalShareInvoiceContent from './ModalShareInvoiceContent';

function ModalShareInvoice({
  emailShareInvoice,
  closeModalCallback,
  setEmailShareInvoice
}: {
  emailShareInvoice?: string;
  closeModalCallback: () => void;
  setEmailShareInvoice?: (email: string) => void;
}) {
  return (
    <div>
      <div className="ModalShareInvoice">
        <BordModal
          closeModalCallback={closeModalCallback}
          modalContent={
            <ModalContent
              excludeHeader
              customWidth="58rem"
              customHeight="44rem"
              toCloseModal={closeModalCallback}
              modalContentTitle="Revisa y confirma los datos de facturación"
              customModalContent="block"
              CustomModalComponent={
                <ModalShareInvoiceContent
                  emailShareInvoice={emailShareInvoice}
                  closeModalCallback={closeModalCallback}
                  setEmailShareInvoice={setEmailShareInvoice}
                />
              }
              noPadding
            />
          }
        />
      </div>
    </div>
  );
}

export default ModalShareInvoice;
