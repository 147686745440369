const SquareNewZealand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path
            fill="#1A47B8"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M15.7144 4.2666V5.33327H16.762V4.2666H15.7144ZM17.8096 6.39994V7.4666H18.8572V6.39994H17.8096ZM13.6191 7.4666V8.53327H14.6668V7.4666H13.6191ZM15.7144 11.7333V12.7999H16.762V11.7333H15.7144Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            d="M9.4288 1.06689H2.09547C1.51689 1.06689 1.04785 1.54446 1.04785 2.13356V7.4669C1.04785 8.056 1.51689 8.53356 2.09547 8.53356H9.4288C10.0074 8.53356 10.4764 8.056 10.4764 7.4669V2.13356C10.4764 1.54446 10.0074 1.06689 9.4288 1.06689Z"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M5.23856 5.3333H2.0957V4.26663H5.23856V2.1333H6.28618V4.26663H9.42904V5.3333H6.28618V7.46663H5.23856V5.3333ZM7.85761 6.39997V7.46663H9.42904V6.39997H7.85761ZM7.85761 2.1333V3.19997H9.42904V2.1333H7.85761ZM2.0957 6.39997V7.46663H3.66713V6.39997H2.0957ZM2.0957 2.1333V3.19997H3.66713V2.1333H2.0957Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareNewZealand;
