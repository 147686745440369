const SquareCyprus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path
          fill="#FF6C2D"
          fillRule="evenodd"
          d="M5.23828 6.4002C5.23828 6.4002 6.2859 9.60019 9.42876 9.60019C12.5716 9.60019 15.7145 6.4002 15.7145 6.4002H13.6192L16.7621 3.2002L10.4764 5.33353L8.38114 3.2002L7.33352 5.33353L5.23828 6.4002Z"
          clipRule="evenodd"
        />
        <path
          fill="#0A6A30"
          fillRule="evenodd"
          d="M5.23805 9.6001L10.4761 11.7334V12.8001L4.19043 10.6668L5.23805 9.6001ZM16.7619 9.6001L11.5238 11.7334V12.8001L17.8095 10.6668L16.7619 9.6001ZM10.4761 11.7334H11.5238V13.8668H10.4761V11.7334Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareCyprus;
