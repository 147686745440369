import { FC } from 'react';
import { DynamicsPills } from '../../../../../components';
import './ProductPills.scss';

interface IFormatPills {
  name: string;
  value?: string;
}

interface IProductPills {
  pillsData?: IFormatPills[];
}

const ProductPills: FC<IProductPills> = ({ pillsData }: IProductPills) => {
  return (
    <div id="productPills">
      {pillsData &&
        pillsData.length > 0 &&
        pillsData.map(element => {
          return (
            <DynamicsPills
              key={`product-pull-${element.name}`}
              elementsNumber={pillsData.length}
              dataPills={{ text: element?.value, hoverText: element.name }}
            />
          );
        })}
    </div>
  );
};

export default ProductPills;
