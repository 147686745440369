const SquareAzerbaijan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M0 11h22v5H0v-5Z"
            clipRule="evenodd"
          />
          <path
            fill="#3ECBF8"
            fillRule="evenodd"
            d="M0 0h22v5H0V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M12.5714 7.46655h1.0476v1.06667h-1.0476V7.46655Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M13.0952 9.59992C12.6175 10.2474 11.857 10.6666 11 10.6666c-1.44572 0-2.61905-1.19468-2.61905-2.66668S9.55428 5.33325 11 5.33325c.857 0 1.6175.4192 2.0952 1.06667h-1.5714c-.8674 0-1.57142.7168-1.57142 1.6 0 .8832.70402 1.6 1.57142 1.6h1.5714Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAzerbaijan;
