const SquareSingapore = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path fill="#F93939" fillRule="evenodd" d="M0 0H22V10H0V0Z" clipRule="evenodd" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M6.28618 8.38717C5.98237 8.48317 5.65761 8.5333 5.31923 8.5333C3.53827 8.5333 2.0957 7.10077 2.0957 5.3333C2.0957 3.56583 3.53932 2.1333 5.31923 2.1333C5.65656 2.1333 5.98132 2.1845 6.28618 2.27943C4.97875 2.68797 4.02961 3.90077 4.02961 5.3333C4.02961 6.76583 4.97875 7.97863 6.28618 8.38717ZM8.38142 3.19997H9.42904V4.26663H8.38142V3.19997ZM10.4767 4.26663H11.5243V5.3333H10.4767V4.26663ZM9.42904 6.39997H10.4767V7.46663H9.42904V6.39997ZM6.28618 4.26663H7.3338V5.3333H6.28618V4.26663ZM7.3338 6.39997H8.38142V7.46663H7.3338V6.39997Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSingapore;
