import { getUsersOrganizationData } from '../../../services/services';
import { useUserAuth } from '../../../state/userAuthContext';
import useShopStore from '../../../state/useShopContext';
import useCheckoutStore from './state';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { PAYMENT_TYPES } from '../../../types/checkout';
import useCartState from '../../../state/useCartState';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { finishedCart } from '../../../services/cart.services';
import { putExpireStripePaymentSession } from '../../../services/checkout';

const useCheckoutController = () => {
  const { user: loggedUser } = useUserAuth();
  const { setUser, addSteps, setStep, setPaymentSession, paymentSession } = useCheckoutStore();
  const { setGlobalShippingCart } = useCartState();
  const { items, removeAllItems, setCart, itemsCart } = useShopStore();

  const genericOnContinue = () => {
    addSteps(1);
  };

  const onAdvanceCart = () => addSteps(1);

  const getUserOrg = async () => loggedUser && getUsersOrganizationData(loggedUser?.uid || '');
  const getInfo = async () => {
    if (!loggedUser) return;
    try {
      const _user = await getUserOrg();
      if (_user) {
        setUser(_user);
      }
    } catch (error) {
      displayErrorNotification();
    }
  };

  const deleteAllData = (paymentMethod: PAYMENT_TYPES | string) => {
    removeAllItems();
    localStorage.removeItem('currentOrderAddress');
    localStorage.removeItem('currentOrderMultipleAddresses');
    localStorage.removeItem('currentOrderDefaultAddress');
    localStorage.removeItem('currentOrderBillingData');
    localStorage.setItem('currentOrderPaymentMethod', JSON.stringify(paymentMethod));
  };

  const nextSteep = () => {
    setStep(4);
  };

  const getCategoriesSummary = () => {
    return items.reduce<
      { categoryName: string; categoryId: number; totalProductsOfThisCategory: number }[] | undefined
    >((prev, curr) => {
      if (!prev || prev.length === 0) {
        return [
          {
            categoryName: curr.category,
            categoryId: curr.categoryId,
            totalProductsOfThisCategory: curr.quantity
          }
        ];
      } else {
        const existingCategoryIndex = prev.findIndex(category => category?.categoryId === curr.categoryId);
        if (existingCategoryIndex < 0) {
          prev.push({
            categoryName: curr.category,
            categoryId: curr.categoryId,
            totalProductsOfThisCategory: curr.quantity
          });
          return prev;
        }
        if (existingCategoryIndex >= 0) {
          prev[existingCategoryIndex] = {
            categoryName: curr.category,
            categoryId: curr.categoryId,
            totalProductsOfThisCategory: prev[existingCategoryIndex]?.totalProductsOfThisCategory + curr.quantity
          };
          return prev;
        }
      }
    }, []);
  };

  function getSubTotal() {
    let subtotalValue = 0;
    items.forEach(product => {
      const value = product.quantity * product.currentPriceInfo.price;
      subtotalValue = subtotalValue + value;
    });
    return +subtotalValue.toFixed(2);
  }
  function getTotalTax() {
    let totalTax = 0;
    items.forEach(product => {
      const value = product.quantity * product.currentPriceInfo.ivaAmount;
      totalTax += value;
    });
    return +totalTax.toFixed(2);
  }
  function getTotalCost() {
    return +(getTotalTax() + getSubTotal()).toFixed(2);
  }
  function getTotalProducts() {
    return items.reduce((previous, current) => {
      return previous + current.quantity;
    }, 0);
  }

  function resetPaymentSession() {
    localStorage.removeItem('paymentSession');
    localStorage.removeItem('dataFinishCart');
    setPaymentSession(undefined);
  }

  async function cancelStripeCheckoutPaymentSession(handleLoading: (loading: boolean) => void) {
    if (!paymentSession?.paymentSessionId) return;
    try {
      handleLoading(true);
      const body = {
        paymentSessionId: paymentSession?.paymentSessionId,
        stripeProvider: paymentSession?.stripeProvider
      };
      resetPaymentSession();
      await putExpireStripePaymentSession(body);
    } catch (error) {
      displayErrorNotification();
    } finally {
      handleLoading(false);
    }
  }

  async function deleteAndCreateNewCart() {
    const orgInfo = getOrgData();
    if (!itemsCart || !itemsCart?.shoppingCartId || !orgInfo) return;
    try {
      await finishedCart(itemsCart?.shoppingCartId);
      const resetedCart = {
        shoppingCartId: null,
        userId: orgInfo?.userId || 0,
        organizationId: orgInfo?.organizationId || 0,
        references: null,
        numberOfProducts: null,
        total: null,
        products: null,
        totalDiscountValue: null,
        totalWithoutPrime: null
      };
      setCart(resetedCart);
      setGlobalShippingCart(undefined);
      localStorage.setItem('bagItems', JSON.stringify(resetedCart));
      localStorage.removeItem('currentOrderShippingData');
      localStorage.setItem('resetCheckoutSteps', 'true');
    } catch (error) {
      displayErrorNotification();
    }
  }

  return {
    genericOnContinue,
    onAdvanceCart,
    getInfo,
    getCategoriesSummary,
    getTotalCost,
    getTotalTax,
    getSubTotal,
    getTotalProducts,
    deleteAllData,
    nextSteep,
    resetPaymentSession,
    cancelStripeCheckoutPaymentSession,
    deleteAndCreateNewCart
  };
};

export default useCheckoutController;
