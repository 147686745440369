const ReportsBackgroundImage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 262 92">
      <g opacity="0.3">
        <g clipPath="url(#a)">
          <path fill="#070F21" d="M1.10211.66559H112.22911V18.79939H1.10211z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M1.10211.66559H112.22911V18.79939H1.10211z" />
          <rect width="89.2739" height="3.71975" x="12.0288" y="7.87256" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M1.10211 18.7994H112.22911V36.9332H1.10211z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M1.10211 18.7994H112.22911V36.9332H1.10211z" />
          <rect width="34.4076" height="3.71975" x="12.0288" y="26.0063" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M1.10211 36.9332H112.22911V55.067H1.10211z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M1.10211 36.9332H112.22911V55.067H1.10211z" />
          <rect width="19.0637" height="3.71975" x="12.0288" y="44.1401" fill="#1B202F" rx="1.85987" />
          <rect width="42.3121" height="3.71975" x="34.8125" y="44.1401" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M1.10211 55.067H112.22911V73.2008H1.10211z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M1.10211 55.067H112.22911V73.2008H1.10211z" />
          <rect width="73.9299" height="3.71975" x="12.0288" y="62.2739" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M1.10211 73.2007H112.22911V91.3345H1.10211z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M1.10211 73.2007H112.22911V91.3345H1.10211z" />
          <rect width="19.0637" height="3.71975" x="12.0288" y="80.4077" fill="#1B202F" rx="1.85987" />
          <rect width="42.3121" height="3.71975" x="34.8125" y="80.4077" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M112.23.66559H203.3638V18.79939H112.23z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M112.23.66559H203.3638V18.79939H112.23z" />
          <rect width="74.8599" height="3.71975" x="123.156" y="7.87256" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M112.23 18.7994H203.3638V36.9332H112.23z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M112.23 18.7994H203.3638V36.9332H112.23z" />
          <rect width="34.4076" height="3.71975" x="123.156" y="26.0063" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M112.23 36.9332H203.3638V55.067H112.23z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M112.23 36.9332H203.3638V55.067H112.23z" />
          <rect width="19.0637" height="3.71975" x="123.156" y="44.1401" fill="#1B202F" rx="1.85987" />
          <rect width="42.3121" height="3.71975" x="145.94" y="44.1401" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M112.23 55.067H203.3638V73.2008H112.23z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M112.23 55.067H203.3638V73.2008H112.23z" />
          <rect width="73.9299" height="3.71975" x="123.156" y="62.2739" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M112.23 73.2007H203.3638V91.3345H112.23z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M112.23 73.2007H203.3638V91.3345H112.23z" />
          <rect width="19.0637" height="3.71975" x="123.156" y="80.4077" fill="#1B202F" rx="1.85987" />
          <rect width="42.3121" height="3.71975" x="145.94" y="80.4077" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M203.363.66559H260.898V18.79939H203.363z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M203.363.66559H260.898V18.79939H203.363z" />
          <rect width="74.8599" height="3.71975" x="214.29" y="7.87256" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M203.363 18.7994H260.898V36.9332H203.363z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M203.363 18.7994H260.898V36.9332H203.363z" />
          <rect width="34.4076" height="3.71975" x="214.29" y="26.0063" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M203.363 36.9332H260.898V55.067H203.363z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M203.363 36.9332H260.898V55.067H203.363z" />
          <rect width="19.0637" height="3.71975" x="214.29" y="44.1401" fill="#1B202F" rx="1.85987" />
          <rect width="42.3121" height="3.71975" x="237.074" y="44.1401" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M203.363 55.067H260.898V73.2008H203.363z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M203.363 55.067H260.898V73.2008H203.363z" />
          <rect width="73.9299" height="3.71975" x="214.29" y="62.2739" fill="#1B202F" rx="1.85987" />
          <path fill="#070F21" d="M203.363 73.2007H260.898V91.3345H203.363z" />
          <path stroke="#262B39" strokeWidth="0.464968" d="M203.363 73.2007H260.898V91.3345H203.363z" />
          <rect width="19.0637" height="3.71975" x="214.29" y="80.4077" fill="#1B202F" rx="1.85987" />
          <rect width="42.3121" height="3.71975" x="237.074" y="80.4077" fill="#1B202F" rx="1.85987" />
        </g>
        <rect
          width="259.796"
          height="90.6688"
          x="1.10211"
          y="0.66559"
          stroke="#262B39"
          strokeWidth="0.464968"
          rx="5.34713"
        />
      </g>
      <circle cx="124.143" cy="52.8572" r="29.1429" fill="url(#b)" />
      <g filter="url(#c)">
        <path
          fill="white"
          fillOpacity="0.2"
          d="M156.152 51.8483C158.644 51.3682 159.889 51.1282 160.316 51.5606C160.366 51.6115 160.397 51.6489 160.438 51.7075C160.787 52.2054 160.421 53.1437 159.69 55.0204C155.459 65.8776 144.902 73.5711 132.548 73.5711C116.466 73.5711 103.429 60.5339 103.429 44.4518C103.429 32.0977 111.122 21.5405 121.979 17.3097C123.856 16.5785 124.794 16.2129 125.292 16.5613C125.351 16.6023 125.388 16.6335 125.439 16.6838C125.871 17.1107 125.631 18.3562 125.151 20.8472C124.734 23.0126 124.735 25.5494 124.736 27.8546C124.736 29.3435 124.736 30.8325 124.736 32.3215C124.736 33.0311 124.736 33.7407 124.736 34.4504L124.736 34.669C124.735 37.0893 124.735 39.4973 125.004 41.5015C125.311 43.7833 126.068 46.4635 128.302 48.6974C130.536 50.9312 133.216 51.6883 135.498 51.9951C137.501 52.2644 139.908 52.2642 142.327 52.2639C142.401 52.2639 142.475 52.2639 142.549 52.2639L148.887 52.2639C148.972 52.2639 149.058 52.2639 149.144 52.264C151.45 52.2648 153.987 52.2657 156.152 51.8483Z"
        />
      </g>
      <g filter="url(#d)">
        <path
          fill="white"
          fillOpacity="0.2"
          d="M143.237 16.8587L143.052 16.7822C141.266 16.0417 139.603 15.3527 138.062 15.1047C136.266 14.8157 134.626 15.1078 132.92 16.1611C132.782 16.2465 132.577 16.3831 132.446 16.4782C130.701 17.7368 129.863 19.3815 129.483 21.3569C129.142 23.126 129.142 25.3232 129.142 27.8529L129.142 34.2268C129.142 36.8424 129.142 39.0196 129.374 40.7472C129.619 42.5691 130.157 44.2083 131.474 45.5248C132.79 46.8413 134.429 47.3794 136.251 47.6243C137.979 47.8566 140.156 47.8565 142.772 47.8565L149.146 47.8565C151.675 47.8567 153.873 47.8569 155.642 47.5159C157.617 47.1353 159.262 46.2977 160.52 44.5529C160.615 44.4211 160.752 44.2166 160.837 44.0784C161.891 42.3727 162.183 40.7323 161.894 38.9363C161.646 37.3952 160.957 35.7327 160.216 33.9462L160.14 33.7615C158.57 29.9722 156.27 26.5292 153.37 23.629C150.469 20.7288 147.026 18.4283 143.237 16.8587Z"
        />
      </g>
      <defs>
        <filter
          id="c"
          width="63.3145"
          height="63.3145"
          x="100.343"
          y="13.3425"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.54286" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4438_8131" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_4438_8131" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2.05714" />
          <feGaussianBlur stdDeviation="2.67429" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_4438_8131" />
        </filter>
        <filter
          id="d"
          width="39.0284"
          height="39.0284"
          x="126.056"
          y="11.9138"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.54286" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4438_8131" />
          <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_4438_8131" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="2.05714" />
          <feGaussianBlur stdDeviation="2.67429" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_4438_8131" />
        </filter>
        <linearGradient id="b" x1="124.143" x2="124.143" y1="23.7144" y2="82.0001" gradientUnits="userSpaceOnUse">
          <stop stopColor="#070F21" />
          <stop offset="1" stopColor="#22CFAB" />
        </linearGradient>
        <clipPath id="a">
          <rect width="260.261" height="91.1338" x="0.869629" y="0.433105" fill="white" rx="5.57962" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReportsBackgroundImage;
