const SquareZambia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#249F58" rx="2" />
          <path
            fill="#FF6C2D"
            fillRule="evenodd"
            d="M19.9043 6.3999H21.9995V15.9999H19.9043V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M17.8096 6.3999H19.9048V15.9999H17.8096V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M15.7139 6.3999H17.8091V15.9999H15.7139V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#FF6C2D"
            fillRule="evenodd"
            d="M14.667 3.19997L17.8098 4.26663L18.8575 5.3333L19.9051 4.26663L23.0479 3.19997H18.8575L17.8098 2.1333V3.19997H14.667Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareZambia;
