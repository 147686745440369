const SquareJapan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="white" rx="2" />
        <path
          fill="#F93939"
          d="M10.9997 11.7333C13.0247 11.7333 14.6663 10.0618 14.6663 7.99994C14.6663 5.93807 13.0247 4.2666 10.9997 4.2666C8.97463 4.2666 7.33301 5.93807 7.33301 7.99994C7.33301 10.0618 8.97463 11.7333 10.9997 11.7333Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareJapan;
