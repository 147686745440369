const RoundCanada = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M31.9996 16c0-6.34335-3.6916-11.82435-9.0435-14.41235V30.4123C28.308 27.8244 31.9996 22.3434 31.9996 16ZM0 16c0 6.3434 3.69156 11.8244 9.0435 14.4124V1.58765C3.69156 4.17565 0 9.65665 0 16ZM18.7824 18.0869l2.7825-1.3913-1.3912-.6957v-1.3913l-2.7826 1.3913 1.3913-2.7826h-1.3913l-1.3913-2.0869-1.3914 2.0869h-1.3913l1.3913 2.7826-2.7826-1.3913v1.3913l-1.3912.6957 2.7825 1.3913-.6956 1.3913h2.7826v2.0869h1.3913v-2.0869h2.7826l-.6956-1.3913Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCanada;
