import { BordOneToneIcon } from '../../BordDesignSystem';
import { TbordOneToneIcon } from '../../BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';

import './NudosKeepInMindInfo.scss';

/**
 * @property { string } bewareTitle - Title
 * @property { { icon: JSX.Element; textContent: JSX.Element }[] } bulletPoints - Array with objects, each object has icons and text
 **/
const NudosKeepInMindInfo = ({ bewareTitle, bulletPoints }: INudosKeepInMindInfo) => {
  return (
    <div className="NudosKeepInMindInfo">
      <div className="BewareTitle">{bewareTitle}</div>
      <div className="bulletPointsContainer">
        {bulletPoints?.map((point, index) => (
          <div className="BulletPoint" key={index}>
            <BordOneToneIcon className="mt-2" variant={point.icon} standardSize={14} />
            <div className="BulletPointText">{point.textContent}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NudosKeepInMindInfo;

interface INudosKeepInMindInfo {
  bewareTitle?: string;
  bulletPoints?: { icon: TbordOneToneIcon; textContent: JSX.Element }[];
}
