import { AxiosError } from 'axios';
import { apiDelete, apiGet, apiPost, apiPut } from './api';
import { ICartInfo, IProductsUpdate } from '../types/cart';
import { cartAPI } from '../constants';

const getShoppingCart = async (userId: number | string, organizationId: number | string): Promise<ICartInfo> => {
  return apiGet<ICartInfo, ICartInfo>(`${cartAPI}/shopping-cart?userId=${userId}&organizationId=${organizationId}`)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

const updateProductCart = async (productUpdate: IProductsUpdate): Promise<IProductsUpdate> => {
  return apiPost(`${cartAPI}/shopping-cart`, productUpdate)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => {
      Promise.reject(error);
    });
};

const deleteProductCart = async (
  shoppingCartId: string | number,
  productId: string | number,
  countryId: string | number
): Promise<IProductsUpdate> => {
  return apiDelete(
    `${cartAPI}/shopping-cart-product/delete/${shoppingCartId}?productId=${productId}&countryId=${countryId}`
  )
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => {
      Promise.reject(error);
    });
};

const finishedCart = async (cartId: number): Promise<string> => {
  return apiPut(`${cartAPI}/shopping-cart/finished/${cartId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export { getShoppingCart, updateProductCart, deleteProductCart, finishedCart };
