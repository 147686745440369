const SquareMongolia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path fill="#F93939" fillRule="evenodd" d="M0 0H7.33333V16H0V0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M2.0957 7.4668H3.14332V11.7335H2.0957V7.4668ZM5.23856 7.4668H6.28618V11.7335H5.23856V7.4668Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            d="M4.1902 11.7334C4.76878 11.7334 5.23782 11.2559 5.23782 10.6668 5.23782 10.0777 4.76878 9.6001 4.1902 9.6001 3.61161 9.6001 3.14258 10.0777 3.14258 10.6668 3.14258 11.2559 3.61161 11.7334 4.1902 11.7334ZM4.1902 8.53324C4.76878 8.53324 5.23782 8.05567 5.23782 7.46657 5.23782 6.87747 4.76878 6.3999 4.1902 6.3999 3.61161 6.3999 3.14258 6.87747 3.14258 7.46657 3.14258 8.05567 3.61161 8.53324 4.1902 8.53324Z"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M4.1902 3.2002L5.23782 5.33353H3.14258L4.1902 3.2002Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M14.667 0H22.0003V16H14.667V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMongolia;
