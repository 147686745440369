import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordWindIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.59 4.58998c.23231-.23368.5188-.40635.8339-.50266.3151-.0963.6491-.11325.9724-.04935.3232.06391.6257.20669.8805.41566.2547.20896.4539.47764.5798.78215.1259.3045.1747.63539.1419.96327-.0328.32787-.1461.64256-.3298.91609-.1838.27354-.4322.49744-.7233.65181-.2912.15437-.6159.23442-.9454.23303H2M12.59 19.41c.2323.2337.5188.4063.8339.5026.3151.0963.6491.1133.9724.0494.3232-.0639.6257-.2067.8805-.4157.2547-.2089.4539-.4776.5798-.7821.1259-.3045.1747-.6354.1419-.9633-.0328-.3279-.1461-.6425-.3298-.9161-.1838-.2735-.4322-.4974-.7233-.6518-.2912-.1544-.6159-.2344-.9454-.233H2m15.73-8.27002c.2908-.29011.6487-.50408 1.0419-.62299.3933-.1189.8097-.13907 1.2126-.05872.4029.08034.7797.25873 1.0972.51938.3176.26065.566.59553.7233.97502.1573.3795.2186.79191.1786 1.20076-.04.40887-.1801.80157-.408 1.14337-.2279.3418-.5365.6222-.8985.8163-.362.1942-.7663.2961-1.1771.2969H2"
      />
    </svg>
  );
};

export default BordWindIcon;
