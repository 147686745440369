const RoundChina = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#FFDA2C"
          d="m8.75625 9.7373 1.38125 4.25h4.4687l-3.6125 2.6313 1.3813 4.25-3.61875-2.625-3.61875 2.625 1.3875-4.25-3.61875-2.6313H7.375l1.38125-4.25ZM18.9689 24.7812l-1.0562-1.3-1.5625.6062.9062-1.4062-1.0562-1.3063 1.6187.4313.9125-1.4063.0875 1.675 1.625.4313-1.5687.6.0937 1.675ZM21.0689 20.9687l.5-1.6-1.3687-.9688 1.675-.025.4937-1.6.5438 1.5875 1.675-.0187-1.3438 1 .5375 1.5875-1.3687-.9688-1.3438 1.0063ZM23.8998 11.7437l-.7375 1.5063 1.2 1.1687-1.6562-.2375-.7375 1.5-.2875-1.65-1.6625-.2375 1.4875-.7812-.2875-1.6563 1.2 1.1688 1.4812-.7813ZM19.0129 7.18115l-.125 1.66875 1.5562.63125-1.6312.4-.1188 1.67505-.8812-1.425-1.6313.4 1.0813-1.2813-.8875-1.41875 1.5562.63125 1.0813-1.28125Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundChina;
