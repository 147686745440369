import { useState } from 'react';
import { IProductDetail } from '../../../../types/catalogue';
import BordPhoto from '../../../../components/BordDesignSystem/BordPhoto/BordPhoto';

import './SecondaryImages.scss';

const SecondaryImages = ({ product }: { product: IProductDetail }) => {
  const { imageUrl, secondaryImages } = product;
  const [mainImage, setMainImage] = useState<string>(imageUrl || '');
  const [firstAlternativeImage, setFirstAlternativeImage] = useState<string>(
    secondaryImages && secondaryImages[0] ? secondaryImages[0] : ''
  );
  const [secondAlternativeImage, setSecondAlternativeImage] = useState<string>(
    secondaryImages && secondaryImages[1] ? secondaryImages[1] : ''
  );
  const [thirdAlternativeImage, setThirdAlternativeImage] = useState<string>(
    secondaryImages && secondaryImages[2] ? secondaryImages[2] : ''
  );
  const AlternativeImages = [firstAlternativeImage, secondAlternativeImage, thirdAlternativeImage];
  const imagesUpdaters = [setFirstAlternativeImage, setSecondAlternativeImage, setThirdAlternativeImage];
  const updateMainImage = (updater: React.Dispatch<React.SetStateAction<string>>, newMainImage: string) => {
    const tempImg = mainImage;
    setMainImage(newMainImage);
    updater(tempImg);
  };
  return (
    <section className="alternativeImagesComponent">
      <div className="mainImage">
        <BordPhoto
          url={mainImage}
          variant="square"
          style={{ width: '44.8rem', height: '28rem', minHeight: '28rem', minWidth: '44.8rem' }}
          imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '90%', maxWidth: '90%' } }}
        />
      </div>
      {product?.secondaryImages && product?.secondaryImages?.length > 0 && (
        <div className="alternativeImagesContainer">
          {AlternativeImages.map((altImg, i) => {
            const onUpdateMainImage = () => {
              updateMainImage(imagesUpdaters[i], altImg);
            };
            if (altImg) {
              return (
                <div key={`img-${altImg}-${i}`} className="alternativeImage" onClick={onUpdateMainImage}>
                  <BordPhoto
                    variant="square"
                    url={altImg}
                    style={{
                      width: '8rem',
                      height: '8rem',
                      minWidth: '8rem',
                      maxWidth: '8rem',
                      minHeight: '8rem',
                      maxHeight: '8rem'
                    }}
                    imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '80%', maxWidth: '80%' } }}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
    </section>
  );
};

export default SecondaryImages;
