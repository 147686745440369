const RoundGreenland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M0 16C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16C31.3044 16 16 18.0869 16 18.0869L0 16Z"
        />
        <path fill="#F93939" d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16" />
        <path
          fill="white"
          d="M11.1307 23.6523C15.3569 23.6523 18.7829 20.2263 18.7829 16.0001C18.7829 11.7739 15.3569 8.3479 11.1307 8.3479C6.90452 8.3479 3.47852 11.7739 3.47852 16.0001C3.47852 20.2263 6.90452 23.6523 11.1307 23.6523Z"
        />
        <path
          fill="#F93939"
          d="M3.47852 16.0001C3.47852 11.774 6.90452 8.3479 11.1307 8.3479C15.357 8.3479 18.7829 11.774 18.7829 16.0001"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGreenland;
