const RoundMauritania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9998 18.4843C13.3356 18.4843 11.11 16.6119 10.5639 14.1116C10.4801 14.496 10.4346 14.8946 10.4346 15.3042C10.4346 18.3779 12.9261 20.8694 15.9998 20.8694C19.0734 20.8694 21.5649 18.3778 21.5649 15.3042C21.5649 14.8946 21.5194 14.496 21.4356 14.1116C20.8895 16.612 18.6639 18.4843 15.9998 18.4843Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 11.1304L16.518 12.7247H18.1944L16.8382 13.71L17.3562 15.3043L16 14.3189L14.6438 15.3043L15.1619 13.71L13.8057 12.7247H15.482L16 11.1304Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMauritania;
