const SquareComoros = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 0H22V4H0V0Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 8H22V12H0V8Z" clipRule="evenodd" />
          <path fill="#232C80" fillRule="evenodd" d="M0 12H22V16H0V12Z" clipRule="evenodd" />
          <path fill="#00B731" fillRule="evenodd" d="M0 0L12.5714 8L0 16V0Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M6.28618 11.5626C5.98237 11.6735 5.65761 11.7333 5.31923 11.7333C3.53827 11.7333 2.0957 10.0618 2.0957 7.99994C2.0957 5.93807 3.53932 4.2666 5.31923 4.2666C5.65656 4.2666 5.98132 4.32633 6.28618 4.43727C4.97875 4.913 4.02961 6.32847 4.02961 7.99994C4.02961 9.6714 4.97875 11.0869 6.28618 11.5626ZM6.28618 5.33327H7.3338V6.39994H6.28618V5.33327ZM6.28618 7.4666H7.3338V8.53327H6.28618V7.4666ZM6.28618 9.59994H7.3338V10.6666H6.28618V9.59994Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareComoros;
