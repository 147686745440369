import { Link } from 'react-router-dom';
import { IassigneeInfo } from '../../../../../../../types/global';
import { formatShortDate } from '../../../../../../../utils/formatDatesAndHours';
import { useTranslation } from 'react-i18next';
import ManualAdjustmentSection from '../ManualAdjustmentSection/ManualAdjustmentSection';
import { NudosHoverText } from '../../../../../../../components/NudosComponents';
import { NudosRedirectionIcon } from '../../../../../../../components/DesignSystem';
import { BordPhoto } from '../../../../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import './PreviousAssignmentCard.scss';

const PreviousAssignmentCard = ({
  assignee,
  updateDataCallback
}: {
  assignee: IassigneeInfo;
  updateDataCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const { firstName, lastName, photoProfile, country, logisticServiceId } = assignee;
  const { entryDate, exitDate } = assignee;
  const i18nLanguageKey = 'nodi:toolInternal:assignedCard:';

  const employeeFullName = `${firstName} ${lastName}`;
  const previousAssigneeStyles = 'previousAssignment';
  const toolTimeZone = assignee?.country?.timeZone || undefined;
  const formattedUnassignmentDate = exitDate ? formatShortDate(exitDate, toolTimeZone)?.onlyDate : '';
  const formattedAssignmentDate = entryDate ? formatShortDate(entryDate, toolTimeZone)?.onlyDate : '';

  const redirectionDataObject = {
    pathname: `/nodi/logistics-services/details/${logisticServiceId}`,
    state: { returnText: 'Herramienta' }
  };

  const seeLogisticServiceButton = logisticServiceId ? (
    <Link to={redirectionDataObject} className="seeLogisticLink">
      Ver servicio
    </Link>
  ) : null;

  const employeeDetailsRedirectionData = {
    pathname: `/nodi/employees/details/${assignee.userId}`,
    state: {
      returnText: 'Herramienta'
    }
  };

  const renderProfileUser = (
    <BordPhoto
      variant="square"
      size="s48"
      url={photoProfile}
      oneToneIconProps={{ variant: 'user', standardSize: 24 }}
      flagProps={{
        variant: 'circle',
        country: country?.code as TcountryCode
      }}
      style={{
        background: photoProfile ? '' : '#070F21'
      }}
    />
  );

  return (
    <div className={`PreviousAssignmentCard ${previousAssigneeStyles}`}>
      <div className="flex items-center gap-20">
        {renderProfileUser}
        <div className="assigneeInfoSection">
          <div className="nameAndRedirection">
            <NudosHoverText onlyIfTruncated customClassName="name" text={employeeFullName} charactersLimit={14} />
            <NudosRedirectionIcon redirectionDataObject={employeeDetailsRedirectionData} />
          </div>
          {seeLogisticServiceButton}
        </div>
      </div>
      <div className="managementDatesSection">
        {!!exitDate && (
          <div className="unassignmentDate">
            <span>{t(`${i18nLanguageKey}unassignmentDate`)}</span>
            <span className="font-medium">{formattedUnassignmentDate}</span>
          </div>
        )}
        <div className="assignmentDate">
          <span>{t(`${i18nLanguageKey}assignmentDate`)}</span>
          <span className="font-medium">{formattedAssignmentDate}</span>
        </div>
      </div>
      <ManualAdjustmentSection assignee={assignee} updateDataCallback={updateDataCallback} />
    </div>
  );
};

export default PreviousAssignmentCard;
