const SquareTurkey = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M14.6687 9.22868L13.4514 9.87934L13.684 8.50014L12.6992 7.52201L14.0611 7.31934L14.6687 6.06494L15.2764 7.32041L16.6383 7.52307L15.6535 8.49907L15.8861 9.87827"
          clipRule="evenodd"
        />
        <path
          fill="white"
          fillRule="evenodd"
          d="M9.9528 11.7333C11.9778 11.7333 13.6195 10.0618 13.6195 7.99994C13.6195 5.93807 11.9778 4.2666 9.9528 4.2666C7.92775 4.2666 6.28613 5.93807 6.28613 7.99994C6.28613 10.0618 7.92775 11.7333 9.9528 11.7333ZM11.0004 10.6666C12.4461 10.6666 13.6195 9.47194 13.6195 7.99994C13.6195 6.52794 12.4461 5.33327 11.0004 5.33327C9.5547 5.33327 8.38137 6.52794 8.38137 7.99994C8.38137 9.47194 9.5547 10.6666 11.0004 10.6666Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTurkey;
