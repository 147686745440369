import { IorganizationOfficeData } from '../../../types/account';
import { IShipmentGroupedByDestinationLocationAndProductId, IProductShipping } from '../../../types/cart';
import { countriesRequiringPersonalId } from '../../../utils/productDefinitions';

export const checkIfOfficeAddressDataIsComplete = (officeAddress?: IorganizationOfficeData) => {
  if (!officeAddress) return false;
  const receiverName = officeAddress?.shipmentInfo?.receiverName;
  const receiverPhone = officeAddress?.shipmentInfo?.receiverPhone;
  const receiverPersonalId = officeAddress?.shipmentInfo?.receiverIdDocument;
  const requiredCoordinatesAreComplete = !!officeAddress?.coordinates;

  return (
    requiredCoordinatesAreComplete &&
    !!officeAddress?.address &&
    !!receiverName &&
    !!receiverPhone &&
    (!countriesRequiringPersonalId.includes(officeAddress?.country?.code || '') || !!receiverPersonalId)
  );
};

/**
 * Takes all the individual items of the globalShippingCart (ie, the items including shipment data) and groups them if they are to be delivered at exactly the same location and corresponds to the same productId (ie, are products of the same sku)
 * @returns an object with the distinct shipments for the current order and its total number
 */
export const groupShipmentsByLocationAndProductId = (globalShippingCart?: IProductShipping) => {
  const allItemsInCart = globalShippingCart?.shipments || [];
  const groupedShipments: IShipmentGroupedByDestinationLocationAndProductId[] = [];
  for (const item of allItemsInCart) {
    if (!item?.destinationLocationId) continue;
    const foundItemIndex = groupedShipments.findIndex(
      element =>
        element?.productId === item?.productId &&
        element?.destinationLocationId === item?.destinationLocationId &&
        item?.countryId === element?.countryId
    );
    if (foundItemIndex < 0 && !!item?.destinationLocationId) {
      const newShipment = { ...item, numberOfItemsInShipment: 1 };
      groupedShipments.push(newShipment);
    } else {
      const currentNumberOfItemsInThisShipment = groupedShipments[foundItemIndex]?.numberOfItemsInShipment || 0;
      groupedShipments[foundItemIndex].numberOfItemsInShipment = currentNumberOfItemsInThisShipment + 1;
    }
  }
  return { totalShippings: groupedShipments?.length || 0, shippingsList: groupedShipments || [] };
};

/**
 * Takes the shipments data and returns a new object with the shipments destinationLocationIds as keys and as the value for each key the total cost of the products send to the corresponding destinationLocationId
 * @param shipmentsList an object with the distinct shipments for the current order and its total number
 * @returns an object with the destinationLocaationIds of the shipments in this order as the keys, and the total cost of the products to that destination as the values
 */
export const getShipmentsPricingDataByDestination = (
  shipmentsList: IShipmentGroupedByDestinationLocationAndProductId[]
) =>
  (shipmentsList || []).reduce<{
    [key: string]: number;
  }>((prev, curr) => {
    const previousCostOfProductsForThisDestination = prev[`${curr?.destinationLocationId || ''}`];
    const thisShipmentTotalCost = (curr?.numberOfItemsInShipment || 0) * (curr?.unitPrice || 0);
    if (!previousCostOfProductsForThisDestination) {
      prev[`${curr?.destinationLocationId}`] = thisShipmentTotalCost;
    } else {
      const newCost = previousCostOfProductsForThisDestination + thisShipmentTotalCost;
      prev[`${curr?.destinationLocationId}`] = newCost;
    }
    return prev;
  }, {});
