import { IOrgInfo } from '../types/orgType';

const formatOrgData = () => {
  if (localStorage.getItem('orgData')) {
    const format = JSON.parse(localStorage.getItem('orgData') || '');
    const orgData: IOrgInfo = format;
    return orgData;
  }
  return undefined;
};

export { formatOrgData };
