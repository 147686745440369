import { v4 as uuid } from 'uuid';

import './loaderOrderCard.scss';

const LoaderCard = () => {
  const cards = Array(20).fill('');

  const generateLoaderCards = () => {
    return (
      <div id="loaderOrderCard" key={uuid()}>
        <div className="loaderDateAndOrderNumber">
          <div className="fakeData animationLoader" />
        </div>
        <div className="shipmentsDataSkeleton">
          <div className="fakeData animationLoader" />
        </div>
        <div className="shipmentsCountSkeleton">
          <div className="loadShipping animationLoader" />
        </div>
        <div className="orderCountrySkeleton">
          <div className="fakeData animationLoader" />
        </div>
        <div className="paymentMethodSectionSkeleton">
          <div className="fakeData animationLoader" />
        </div>
        <div className="totalAmountSkeleton">
          <div className="fakeData animationLoader" />
        </div>
        <div className="paymentStatusSkeleton">
          <div className="fakeData animationLoader" />
        </div>
        <div className="orderStatusSkeleton">
          <div className="fakeData animationLoader" />
        </div>
      </div>
    );
  };

  return (
    <>
      {cards &&
        cards.length > 0 &&
        cards.map(() => {
          return generateLoaderCards();
        })}
    </>
  );
};
export default LoaderCard;
