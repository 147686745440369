const RoundBahamas = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#3ECBF8"
          d="M9.73913 10.4348H31.0047C28.7441 4.342 22.8794 0 16 0 11.5816 0 7.58206 1.79119 4.68681 4.68681l5.05232 5.74799ZM9.73913 21.5652H31.0047C28.7441 27.658 22.8794 32 16 32c-4.4184 0-8.41794-1.7912-11.31319-4.6868l5.05232-5.748Z"
        />
        <path
          fill="#000"
          d="M4.68625 4.68628c-6.24837 6.24842-6.24837 16.37902 0 22.62752C7.26831 24.7317 9.75162 22.2484 16 16L4.68625 4.68628Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBahamas;
