import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import EmployeeCard from './UploadedEmployeCard/UploadedEmployeCard';
import useEmployeesUploadingState from '../../../../state/useEmployeesUploadingState';
import { ICountryDetail } from '../../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../../utils/getCountries';
import { BordButton } from '../../../../components/BordDesignSystem';
import useSidebar from '../../../../hooks/useSidebar';
import BordEmptyState from '../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';

import './UploadEmployeesSummary.scss';

const UploadEmployeesSummary = () => {
  const { push } = useHistory();
  const { uploadedEmployeesData, setUploadedEmployeesData } = useEmployeesUploadingState();
  const { moduleSidebarStyles } = useSidebar();

  const [infoCountriesList, setInfoCountriesList] = useState<ICountryDetail[]>();

  const { totalRegistries = 0, registryWithErrors = 0, errors } = uploadedEmployeesData || {};
  const totalSuccessfullyLoadedEmployees = totalRegistries - registryWithErrors;
  const allRecordsCompleted = registryWithErrors === 0;

  const noEmployeeCharged = registryWithErrors > 0 && registryWithErrors === totalRegistries;
  const titleNoEmployeeCharged = noEmployeeCharged
    ? 'Los empleados no fueron cargados'
    : 'Algunos empleados no cargaron correctamente';

  const loadedRecords = `${totalSuccessfullyLoadedEmployees}/${totalRegistries}`;

  const goToEmployeeList = () => {
    push('/nodi/employees');
  };

  const goUploadEmployees = () => {
    setUploadedEmployeesData(undefined);
    push('/nodi/upload-employees');
  };

  useEffect(() => {
    getListOfCountriesByOfferedService(setInfoCountriesList);
    if (!uploadedEmployeesData) push('/nodi/dashboard');
  }, []);

  return (
    <div id="uploadEmployeesSummary" className={`${moduleSidebarStyles || ''}`}>
      {allRecordsCompleted ? (
        <div className="allRecordsCompleted">
          <BordEmptyState
            title="Los empleados se cargaron correctamente"
            ilustrationIconProps={{ variant: 'success', customWidth: '8rem' }}
            subTitle={loadedRecords}
            buttonOneProps={{
              label: 'Ir a empleados',
              customWidth: 'w-280',
              customHeight: '42',
              onClick: goToEmployeeList
            }}
          />
        </div>
      ) : (
        <div className="centeringContainer">
          <div className="headerState">
            <BordEmptyState
              customClassNameBordEmptyStateContainer="customEmptyContainer"
              title={titleNoEmployeeCharged}
              customClassNameTitle="customEmptyTitle"
              ilustrationIconProps={{ variant: `${noEmployeeCharged ? 'danger' : 'warning'}`, customWidth: '8rem' }}
              subTitle={loadedRecords}
            />
          </div>
          <div className="warningTextContainer">
            <span className="font-bold">Estos empleados no se pudieron cargar</span>
            <br /> Revisa los campos en rojo o con ⚠️, corrígelos y vuelve a cargar
          </div>
          {registryWithErrors > 0 && errors && errors?.length > 0 && (
            <div className="containerEmployeesLoad">
              <div className="containerNoLoadEmployees">
                <div className="headerList">
                  <div className="nameList generalListLines">Nombre</div>
                  <div className="lastNameList generalListLines">Apellido</div>
                  <div className="emailList generalListLines">Correo</div>
                  <div className="ladaList generalListLines">Indica.</div>
                  <div className="phoneList generalListLines">Número celular</div>
                  <div className="areaList generalListLines">Área</div>
                  <div className="positionList generalListLines">Cargo</div>
                  <div className="countryList generalListLines">País</div>
                  <div className="nameList generalListLines" style={{ borderRight: 'none' }}>
                    Ciudad
                  </div>
                </div>
                <div className="bodyList">
                  {errors &&
                    errors.length > 0 &&
                    errors.map(item => {
                      return <EmployeeCard infoCountriesList={infoCountriesList} data={item} key={uuid()} />;
                    })}
                </div>
              </div>
            </div>
          )}
          <div className="buttonsUploadEmployeesContainer">
            <BordButton
              customWidth="w-280"
              modeButton="primary"
              bordButtonStyle="outlined"
              customHeight="42"
              label="Ir a empleados"
              onClick={goToEmployeeList}
            />
            <BordButton customWidth="w-280" customHeight="42" label="Volver a intentarlo" onClick={goUploadEmployees} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadEmployeesSummary;
