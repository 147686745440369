const RoundGhana = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M0 15.9999C0 17.957 0.352063 19.8318 0.995188 21.5651L16 22.2608L31.0048 21.5651C31.648 19.8318 32 17.957 32 15.9999C32 14.0428 31.648 12.168 31.0048 10.4347L16 9.73901L0.995188 10.4346C0.352063 12.168 0 14.0428 0 15.9999H0Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="#496E2D"
          d="M31.0047 21.5652H0.995117C3.2558 27.6579 9.12049 32 15.9999 32C22.8794 32 28.7441 27.6579 31.0047 21.5652Z"
        />
        <path
          fill="black"
          d="M15.9999 10.4348L17.3812 14.6861H21.8517L18.2353 17.3138L19.6165 21.5652L15.9999 18.9377L12.3834 21.5652L13.7649 17.3138L10.1484 14.6861H14.6187L15.9999 10.4348Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGhana;
