import { TCurrencyCountryCode } from '../types/checkout';

interface IFormatCurrency {
  value: number;
  decimals?: number;
  currencyCountryCode?: TCurrencyCountryCode;
  maximumFractionDigits?: number;
}

/**
 * IMPORTANT: The formated number would have at least the desired number of decimals, but NOT LESS than the number actually has. If a rounded up number with the desired decimals is expected use the formatRoundedUpNumber function instead
 * @param {number} value - Required, the value to format
 * @param {number} decimals - Optional, a positive integer. The desired number of decimals in the formated number, DEFAULT 2. If negative values are passed the function returns -1.
 * @returns the formated value to the es-mx number format with the desired number of decimals (DEFAULT 2)
 */
export const formatCost = (value?: number, decimals?: number, currencyCountryCode?: TCurrencyCountryCode) => {
  if (decimals && decimals < 0) return -1;
  const countryCode = currencyCountryCode?.toLocaleLowerCase() === 'cop' ? 'es-co' : 'es-mx';
  return (
    typeof value === 'number' &&
    new Intl.NumberFormat(countryCode, {
      minimumFractionDigits: typeof decimals === 'number' ? Math.ceil(decimals) : 2
    }).format(value)
  );
};

export const nudosFormatCurrency = ({
  value,
  decimals,
  currencyCountryCode,
  maximumFractionDigits
}: IFormatCurrency) => {
  if (decimals && decimals < 0) return -1;
  const countryCode = currencyCountryCode?.toLocaleLowerCase() === 'cop' ? 'es-co' : 'es-mx';
  return `$${
    typeof value === 'number' &&
    new Intl.NumberFormat(countryCode, {
      minimumFractionDigits: typeof decimals === 'number' ? Math.ceil(decimals) : 2,
      maximumFractionDigits: maximumFractionDigits
    }).format(value)
  }`;
};

/**
 * @param {number} value - Required, the value to round up
 * @param {number} decimals - Optional, a positive integer. The desired number of decimals for the roundup, DEFAULT 1. If negative values are passed the function returns -1.
 * @returns the value rounded up to the desired number of decimals (default 1 decimal)
 */
export const roundUpNumber = (value: number, decimals?: number) => {
  if (decimals && decimals < 0) return -1;
  const multiple = Math.pow(10, typeof decimals === 'number' ? Math.ceil(decimals) : 1);
  if (multiple === 0) return Math.ceil(value);
  return Math.ceil(value * multiple) / multiple;
};

/**
 * @param {number} value - Required, the value to round up and format
 * @param {number} decimals - Required, a positive integer. The desired number of decimals for the formated roundup number. If negative values are passed the function returns -1.
 * @returns the value rounded up and formatted to the desired number of decimals
 */
export const formatRoundedUpNumber = (value: number, decimals: number, currencyCountryCode?: TCurrencyCountryCode) => {
  if (decimals < 0) return -1;
  return formatCost(roundUpNumber(value, Math.ceil(decimals)), Math.ceil(decimals), currencyCountryCode);
};
