import { TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS } from '../types/logisticsServicesModule.types';
import i18n from 'i18next';

export const translateToolsRelocations = (text?: string) => {
  const translationKeys = [
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.ONBOARDING,
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.OFFBOARDING,
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.MOVEMENT,
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.REASSIGNMENT,
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.STORAGE,
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.WARRANTY,
    TOOL_MOVEMENTS_TYPES_NAME_STRING_IDS.DIAGNOSIS
  ];
  translationKeys.forEach(translationKey => {
    text = text?.replaceAll(translationKey, i18n.t(`services:toolMovements:${translationKey}`));
  });
  return text;
};
