import { ComponentProps } from 'react';

const BordBodyIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="71" height="85" viewBox="0 0 71 85" fill="none">
      <path
        d="M64.1762 0.333008H35.7654C27.2626 0.333008 19.4676 3.34772 13.3882 8.36531C5.56828 14.8195 0.583984 24.584 0.583984 35.5144C0.583984 44.0172 3.59869 51.8122 8.61627 57.8916C10.0487 59.628 11.6477 61.2269 13.3841 62.6593C19.4676 67.6769 27.2626 70.6916 35.7612 70.6916C46.6916 70.6916 56.4561 65.7074 62.9103 57.8874C67.9279 51.8039 70.9426 44.0089 70.9426 35.5102V7.10362C70.9426 3.36438 67.9112 0.333008 64.1678 0.333008H64.1762Z"
        fill="url(#paint0_radial_3111_8165)"
      />
      <path
        d="M32.8289 32.5271C32.8289 36.7316 30.3943 30.3519 27.391 30.3519C24.3877 30.3519 21.9531 36.7316 21.9531 32.5271C21.9531 28.3225 24.3877 24.9141 27.391 24.9141C30.3943 24.9141 32.8289 28.3225 32.8289 32.5271Z"
        fill="#1B202F"
      />
      <path
        d="M48.0554 32.5271C48.0554 36.7316 45.6208 29.2644 42.6176 29.2644C39.6143 29.2644 37.1797 36.7316 37.1797 32.5271C37.1797 28.3225 39.6143 24.9141 42.6176 24.9141C45.6208 24.9141 48.0554 28.3225 48.0554 32.5271Z"
        fill="#1B202F"
      />
      <ellipse cx="36.0399" cy="61.8913" rx="17.4012" ry="4.3503" fill="#1B202F" />
      <path
        d="M37.4946 82.6577C37.2327 82.6721 37.0042 82.4817 36.9711 82.2215L34.5614 63.2473C34.5291 62.9924 34.6952 62.7544 34.9456 62.697L43.561 60.7205C43.7122 60.6858 43.8709 60.7232 43.9907 60.8218C44.1104 60.9204 44.1776 61.069 44.1726 61.224L43.7895 73.0136C49.5011 72.4264 52.5618 73.5206 53.9285 75.4057C55.3373 77.349 54.7574 79.8684 53.9032 81.5099C53.8217 81.6666 53.6635 81.7686 53.4871 81.7783L37.4946 82.6577Z"
        fill="#22CFAB"
        stroke="#1B202F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9041 81.8397C24.6446 81.8015 24.4587 81.5693 24.4782 81.3077L25.9052 62.2344C25.9244 61.9782 26.1347 61.7782 26.3916 61.7719L35.2281 61.5552C35.3832 61.5514 35.5312 61.6198 35.6289 61.7403C35.7265 61.8608 35.7627 62.0198 35.7268 62.1707L32.9976 73.6464C38.7115 74.2114 41.4921 75.8947 42.4549 78.0147C43.4475 80.2001 42.3762 82.553 41.2115 83.9909C41.1003 84.1282 40.9249 84.1966 40.7502 84.1709L24.9041 81.8397Z"
        fill="#22CFAB"
        stroke="#1B202F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5106 49.0837C27.5934 50.3373 32.0552 51.3682 37.5211 50.8928C41.2531 50.5682 44.2458 49.8754 46.5516 49.0837"
        stroke="#1B202F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3111_8165"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56.651 19.022) rotate(112.011) scale(55.7319 55.7319)"
        >
          <stop offset="0.665" stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1AA185" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default BordBodyIcon;
