const SquareCentralAfricanRepublic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="white"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#249F58" fillRule="evenodd" d="M0 9H22V12H0V9Z" clipRule="evenodd" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V6H0V0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M3.14258 2.1333H4.1902V3.19997H3.14258V2.1333Z"
            clipRule="evenodd"
          />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M2.0957 2.13356H3.14332V3.20023H2.0957V2.13356ZM4.19094 2.13356H5.23856V3.20023H4.19094V2.13356ZM3.14332 3.20023H4.19094V4.26689H3.14332V3.20023ZM3.14332 1.06689H4.19094V2.13356H3.14332V1.06689Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M9 0H13V16H9V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareCentralAfricanRepublic;
