const SquareSyrianArabRepublic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#151515" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#AF010D" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M6.8096 9.3335L5.2696 10.158L5.56293 8.4119L4.31836 7.17456L6.0396 6.92176L6.8096 5.3335L7.5796 6.92283L9.30084 7.17563L8.05417 8.41296L8.3475 10.158L6.8096 9.3335ZM15.1905 9.3335L13.6505 10.158L13.9439 8.4119L12.6993 7.17456L14.4205 6.92176L15.1905 5.3335L15.9605 6.92283L17.6818 7.17563L16.4351 8.41296L16.7285 10.158"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSyrianArabRepublic;
