import { useEffect, useState } from 'react';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { NudosHoverText } from '../../NudosComponents';
import { IcityDetails } from '../../../types/cities.types';
import { getCitiesByCountry } from '../../../services/cities.services';
import { useTranslation } from 'react-i18next';
import { BordDropdown, BordTextInput } from '../../BordDesignSystem';
import { TformComponentsStandardSizes } from '../../BordDesignSystem/bordDesignSystem.types';
import './NudosCityDropdown.scss';

const NudosCityDropdown = ({
  countryId,
  componentSize,
  updateCurrentValueCallback,
  currentValue,
  requestNewCityCallback,
  isDeactivated,
  showSkeletonLoader,
  errorText,
  countryCode,
  setMissingRequiredCityId
}: {
  countryId?: number;
  countryCode?: string;
  componentSize?: TformComponentsStandardSizes;
  updateCurrentValueCallback: (newValue?: IcityDetails) => void;
  currentValue?: IcityDetails;
  isFocused?: boolean;
  requestNewCityCallback?: () => void;
  isDeactivated?: boolean;
  showSkeletonLoader?: boolean;
  errorText?: string;
  setMissingRequiredCityId?: (isMissingCityId: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [initialData, setInitialData] = useState<IcityDetails>();
  const [loadingCities, setLoadingCities] = useState<boolean>(true);
  const [availableCities, setAvailableCities] = useState<IcityDetails[]>([]);
  const [cityText, setCityText] = useState('');

  const countryHasDefinedCities = !loadingCities && availableCities && availableCities?.length > 0;
  const cityPlaceholderForTextInput = !countryId ? 'Escoge primero el país' : 'Escribe la ciudad';
  const cityPlaceholderForDropdown = !countryId ? 'Escoge primero el país' : 'Escoge la ciudad';
  const cityLabel = `${t('designSystemComponents:nudosCityDropdown:label')}*`;
  const cityErrorTextForTextInput = countryId && !currentValue?.name ? 'Escribe la ciudad' : undefined;
  const cityErrorTextForDropdown =
    countryId && (!currentValue?.name || !currentValue?.id) ? 'Debes escoger la ciudad' : undefined;

  const getAvailableCitiesByCountry = async () => {
    if (!countryId && !countryCode) return;
    setLoadingCities(true);
    try {
      const response = await getCitiesByCountry(countryCode || countryId || '');
      setAvailableCities(response);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingCities(false);
    }
  };

  const getCityOptionUI = (
    isActive: boolean,
    rawBillingData: IcityDetails,
    clickOptionCallback?: (option: IcityDetails) => void
  ) => {
    const { id, name } = rawBillingData;
    const handleSelectOption = (option: IcityDetails) => {
      if (!isActive) return;
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };
    return (
      <div
        className="optionContainer cityOption"
        key={`cityOption${id}`}
        onClick={() => handleSelectOption(rawBillingData)}
      >
        <NudosHoverText
          onlyIfTruncated
          customClassName={!name ? 'empty' : undefined}
          text={name || 'Escoge la ciudad'}
          charactersLimit={50}
        />
      </div>
    );
  };

  const formDropdownOptionsCallback = (
    rawOptions: IcityDetails[],
    clickOptionCallback?: (option: IcityDetails) => void,
    searchString?: string
  ) => {
    const addNewCityOption = requestNewCityCallback ? (
      <div key="addOther" className="optionContainer addNewCityOption" onClick={requestNewCityCallback}>
        Solicitar agregar ciudad
      </div>
    ) : undefined;
    const noResultsOption = (
      <div className="optionContainer" key="loading">
        {searchString ? 'No hay resultados para tu búsqueda' : 'Cargando ...'}
      </div>
    );
    if (rawOptions.length === 0) {
      return addNewCityOption ? [noResultsOption, addNewCityOption] : [noResultsOption];
    }
    const cityOptions = rawOptions.map(rawBillingData => {
      return getCityOptionUI(true, rawBillingData, clickOptionCallback);
    });
    return addNewCityOption ? [...cityOptions, addNewCityOption] : cityOptions;
  };

  const formSelectedOptionUICallback = (selectedOption: IcityDetails) =>
    getCityOptionUI(false, selectedOption, undefined);

  const filterBySearchStringCallback = (search: string, rawOptions: IcityDetails[]) => {
    return rawOptions.filter(rawBillingData => {
      return rawBillingData?.name?.toLowerCase().trim().includes(search.toLowerCase().trim());
    });
  };

  useEffect(() => {
    if (initialData?.name || !currentValue?.name) return;
    setInitialData(currentValue);
  }, [currentValue?.name]);

  useEffect(() => {
    // Updates the city selection when user writes in the text input for countries without defined cities
    if (cityText && !initialData?.name) return setInitialData({ name: cityText });
    if (!initialData?.name || countryHasDefinedCities) return; // To prevent deleting the initial data before the user modifies it
    updateCurrentValueCallback({ name: cityText });
  }, [cityText]);

  useEffect(() => {
    getAvailableCitiesByCountry();
    if (!initialData?.name) return; // To prevent deleting the initial data before the user modifies it
    // Reset the city selection upon changing countries
    updateCurrentValueCallback(undefined);
    setCityText('');
  }, [countryId]);

  useEffect(() => {
    if (!setMissingRequiredCityId || loadingCities || !currentValue || showSkeletonLoader) return;
    if (!countryHasDefinedCities || !!currentValue?.id) return setMissingRequiredCityId(false);
    setMissingRequiredCityId(true);
  }, [currentValue, loadingCities, setMissingRequiredCityId, countryHasDefinedCities, showSkeletonLoader]);

  return (
    <div className="NudosCityDropdown">
      {countryHasDefinedCities && (
        <BordDropdown
          customClassname="NudosCityDropdownSearchbar"
          label={cityLabel}
          componentSize={componentSize}
          customPlaceholder={cityPlaceholderForDropdown}
          isFilled={!!currentValue}
          currentValue={currentValue?.id && currentValue?.name ? currentValue : undefined}
          includeMagnifierIcon={false}
          rawOptions={availableCities}
          errorText={cityErrorTextForDropdown || errorText}
          formDropdownOptionsCallback={formDropdownOptionsCallback}
          formSelectedOptionUICallback={formSelectedOptionUICallback}
          filterBySearchStringCallback={filterBySearchStringCallback}
          isDeactivated={isDeactivated}
          showSkeletonLoader={showSkeletonLoader}
        />
      )}
      {!countryHasDefinedCities && (
        <BordTextInput
          label={cityLabel}
          showSkeletonLoader={showSkeletonLoader}
          standardSize={componentSize}
          placeholder={cityPlaceholderForTextInput}
          isFilled={!!currentValue?.name}
          setCurrentText={setCityText}
          currentText={currentValue?.name || undefined}
          errorText={cityErrorTextForTextInput}
          disabled={!countryId || isDeactivated}
        />
      )}
    </div>
  );
};

export default NudosCityDropdown;
