import { bordColorTheme } from '../../../styles/constants';
import { ItoolEssentialData } from '../../../types/requestLogisticsModule';
import { formatPills } from '../../../utils/productDetails';
import { BordCheckBox, BordOneToneIcon, BordPhoto } from '../../BordDesignSystem';
import DynamicsPills from '../../DynamicsPills/DynamicsPills';
import { NudosCareTag, NudosHoverText } from '../../NudosComponents';
import './NudosLogisticsToolCard.scss';

/**
 *@property { ItoolEssentialData } toolData
 *@property { boolean } isSelected - a boolean indicating if the tool is currently selected
 *@property { () => void } handleClickSelectButton - a callback function to execute upon clicking the tool select button
 *@property { boolean } toolHasSelectButton - a boolean indicating if the tool has or not a select button
 */
const NudosLogisticsToolCard = ({
  toolData,
  isSelected,
  handleClickSelectButton,
  toolHasSelectButton,
  totalTools
}: {
  toolData: ItoolEssentialData;
  isSelected: boolean;
  handleClickSelectButton?: () => void;
  toolHasSelectButton: boolean;
  totalTools?: number;
}) => {
  const image = toolData?.image || toolData?.imageUrl || '';
  const name = toolData?.productName || toolData?.commercialName || 'Sin nombre';
  const brandImage = toolData?.brandImage || '';
  const serial = toolData?.serial || 'Sin serial';

  const pillsList = toolData?.pills
    ? formatPills({ pillsList: toolData?.pills, excludeItems: ['Cargo', 'Gama', 'Perfil'] })
    : undefined;

  const opacityStyle = isSelected ? '' : 'hasOpacity';
  return (
    <div
      className={`NudosLogisticsToolCard ${opacityStyle}`}
      onClick={toolHasSelectButton && totalTools !== 1 ? handleClickSelectButton : undefined}
    >
      {totalTools === 1 && <div className="w-16" />}
      {toolHasSelectButton && totalTools !== 1 && <BordCheckBox isButtonActive={isSelected} />}
      <div className="toolImageContainer">
        <BordPhoto url={image} size="s56" />
        <div className="floatBrand">
          <BordPhoto
            variant="circle"
            url={brandImage}
            size="s32"
            oneToneIconProps={{ variant: 'bord', customWidth: '3.2rem', stroke: bordColorTheme.navyBlue[600] }}
            imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '65%', maxWidth: '65%' } }}
          />
        </div>
      </div>
      <div className="nameAndPillsSection">
        <div className="productNameContainer">
          <NudosHoverText customClassName="nameTruncate" onlyIfTruncated text={name} charactersLimit={42} />
        </div>
        <div className="pillsContainerSl">
          {pillsList?.pills?.map((item, index) => {
            return (
              <DynamicsPills
                key={`product-description-${index}-ls`}
                dataPills={{ hoverText: item.name || '', text: item?.value || '' }}
                elementsNumber={pillsList?.pills?.length || 0}
                disabledMaxWidth
              />
            );
          })}
        </div>
      </div>
      <div className="nudoscareAndReferenceModel">
        <NudosCareTag customClass="mb-8" nudosCareType={toolData?.nudosCareName} />
        <div className="serialToolContainer">
          <BordOneToneIcon variant="barCode2" standardSize={12} />
          <div className="textSerial">{serial}</div>
        </div>
      </div>
    </div>
  );
};

export default NudosLogisticsToolCard;
