import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M4 4.66602H13.3333L12 9.66602H5.33333L4 4.66602Z"
      stroke={props?.stroke || 'white'}
      strokeLinejoin="round"
    />
    <path d="M4 4.66634L3.66667 3.33301H2" stroke={props?.stroke || 'white'} strokeLinejoin="round" />
    <circle cx="5.66666" cy="12.333" r="1" stroke={props?.stroke || 'white'} strokeLinejoin="round" />
    <circle cx="11.6667" cy="12.333" r="1" stroke={props?.stroke || 'white'} strokeLinejoin="round" />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CartIcon = memo(ForwardRef);
export default CartIcon;
