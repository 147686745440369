import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useStateSubscriptions from '../../../../../../../../../state/useStateSubscriptions';
import { TbordOneToneIcon } from '../../../../../../../../../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import { BordOneToneIcon } from '../../../../../../../../../components/BordDesignSystem';
import { bordColorTheme } from '../../../../../../../../../styles/constants';
import './ShippingOptions.scss';

const ShippingOptions: FC<IShippingOptionsProps> = ({
  optionSelect,
  shippingSelect,
  handleChangeShipmentOption,
  setCloseOutside,
  handleChangeOptionStoreNudos,
  hasAnOfficeAddress
}) => {
  const { stateSubscription } = useStateSubscriptions();
  const { t } = useTranslation();
  const isPrime = stateSubscription?.isPrime;
  const showBoardService = isPrime && optionSelect?.value === 3;

  const selectThisOption = () => {
    if (optionSelect.value === 3) {
      handleChangeOptionStoreNudos(optionSelect);
    } else {
      handleChangeShipmentOption(optionSelect);
    }
    setCloseOutside(true);
  };

  if (shippingSelect?.value === optionSelect.value) return <></>;
  return (
    <div className="shippingOption" onClick={selectThisOption}>
      <BordOneToneIcon variant={optionSelect.iconVariant} standardSize={14} stroke={bordColorTheme.content.default} />
      <div className="titleAndExplanation">
        <div className="title">
          {optionSelect.name}
          {!hasAnOfficeAddress && optionSelect?.value === 1 && (
            <div className="noOfficeContainer">
              <div className="noOffice">{t('recurrentWords:add')}</div>
            </div>
          )}
          {showBoardService && <div className="bordService">Buy & Hold</div>}
        </div>
        <div className="explanation">{optionSelect.explanation}</div>
      </div>
    </div>
  );
};

export default ShippingOptions;

export interface IShippingOption {
  value: number;
  name: string;
  iconVariant: TbordOneToneIcon;
  explanation: string;
}

interface IShippingOptionsProps {
  optionSelect: IShippingOption;
  shippingSelect?: IShippingOption;
  handleChangeShipmentOption: (item: IShippingOption) => void;
  setCloseOutside: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeOptionStoreNudos: (shipmentOption: IShippingOption) => void;
  hasAnOfficeAddress: boolean;
}
