import React, { FC, useCallback, useEffect, useRef } from 'react';
import { IElement } from '../../../types/global';
import './nudosPopover.scss';
interface INudosPopover {
  children: React.ReactNode;
  positionArrow?: 'bottomLeft' | 'bottomRigth' | 'topRight' | 'topLeft';
  firstButtonText?: string;
  secondButtonText?: string;
  redFirstButton?: boolean;
  callBackFirstButton?: () => void;
  callBackSecondButton?: () => void;
  topPopover?: number | string;
  rigthPopover?: number | string;
  leftPopover?: number | string;
  bottomPopover?: number | string;
  widthPopover?: number | string;
  defaultWidthFirstButton?: string | number;
  defaultWidthSecondButton?: string | number;
  changeOpenStatusFunction?: () => void;
}

const NudosPopover: FC<INudosPopover> = ({
  children,
  firstButtonText,
  secondButtonText,
  redFirstButton,
  callBackFirstButton,
  callBackSecondButton,
  topPopover,
  rigthPopover,
  leftPopover,
  bottomPopover,
  widthPopover,
  defaultWidthFirstButton,
  defaultWidthSecondButton,
  changeOpenStatusFunction
}: INudosPopover) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  const onHandleClickAway = useCallback(
    async (e: IElement) => {
      if (popoverRef.current && !popoverRef.current.contains(e.target)) {
        changeOpenStatusFunction && changeOpenStatusFunction();
      }
    },
    [popoverRef]
  );

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div
      id="nudosPopover"
      ref={popoverRef}
      style={{
        top: topPopover && !bottomPopover ? `${topPopover}px` : '',
        right: rigthPopover && !leftPopover ? `${rigthPopover}px` : '',
        left: leftPopover ? `${leftPopover}px` : '',
        bottom: bottomPopover ? `${bottomPopover}px` : '',
        width: widthPopover ? `${widthPopover}px` : ''
      }}
    >
      <div className="boxNudosPopover">
        <div className="textPopover">{children}</div>

        {(firstButtonText || secondButtonText) && (
          <div className="boxButtonsPopover">
            {firstButtonText && (
              <button
                className="secondPopoverButton"
                style={{ width: defaultWidthFirstButton ? `${defaultWidthFirstButton}px` : '' }}
                onClick={() => {
                  if (callBackFirstButton) {
                    callBackFirstButton();
                  }
                }}
              >
                <span style={{ color: redFirstButton ? '#FF0000' : '' }}>{firstButtonText}</span>
              </button>
            )}
            {secondButtonText && (
              <button
                style={{ width: defaultWidthSecondButton ? `${defaultWidthSecondButton}px` : '' }}
                onClick={() => {
                  if (callBackSecondButton) {
                    callBackSecondButton();
                  }
                }}
                className="firsPopoverButton"
              >
                {secondButtonText}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NudosPopover;
