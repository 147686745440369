const RoundAustria = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M31.0048 21.5651C31.648 19.8319 32 17.9571 32 16c0-1.9572-.352-3.8319-.9952-5.5652L16 9.04346.995188 10.4348C.352063 12.1681 0 14.0428 0 16c0 1.9571.352063 3.8319.995188 5.5651L16 22.9565l15.0048-1.3914Z"
        />
        <path
          fill="#F93939"
          d="M16 32c6.8794 0 12.7441-4.342 15.0048-10.4348H.995132C3.25588 27.658 9.12051 32 16 32ZM16 0C9.12051 0 3.25588 4.342.995132 10.4348H31.0048C28.7441 4.342 22.8794 0 16 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAustria;
