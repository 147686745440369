const RoundPitcairnIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="white"
          d="M15.957 16.0001H15.9998C15.9998 15.9857 15.9998 15.9717 15.9998 15.9573C15.9856 15.9716 15.9713 15.9858 15.957 16.0001Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.16194 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0485 16H15.9572C15.9715 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51179 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0325L10.3152 8.3479H8.34766V8.34803L15.9997 16H15.9998C15.9998 16 15.9998 14.6433 15.9998 14.0325Z"
        />
        <path
          fill="#ACABB1"
          d="M25.0434 9.73924L22.9564 10.4349L20.8695 9.73924L20.1738 8.00011L20.8695 6.26099H25.0434L24.3477 8.00011L25.0434 9.73924Z"
        />
        <path
          fill="#3ECBF8"
          d="M18.0869 9.73901V17.3911C18.0869 21.1186 22.9565 22.2608 22.9565 22.2608C22.9565 22.2608 27.826 21.1186 27.826 17.3911V9.73901H18.0869Z"
        />
        <path
          fill="#6DA544"
          d="M18.5264 19.2042C19.7234 21.5022 22.957 22.2609 22.957 22.2609C22.957 22.2609 26.1906 21.5022 27.3876 19.2042L22.957 11.8262L18.5264 19.2042Z"
        />
        <path
          fill="#FFDA2C"
          d="M27.826 17.3138L22.9565 9.73901L18.0869 17.3139V17.3911C18.0869 18.081 18.254 18.6821 18.5259 19.204L22.9565 12.3118L27.3871 19.204C27.6589 18.682 27.826 18.081 27.826 17.3911V17.3138Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundPitcairnIslands;
