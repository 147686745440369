import BordStepper from '../../../../../components/BordDesignSystem/BordStepper/BordStepper';
import './ToolsManagementStepper.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 * @property  { boolean } requireLogistics - A boolean indicating if the step is only shown when logistics by nudos is required
 * @property  { boolean } includeEmailStep -  A boolean indicating if the email step should be shown, ie when the destination is an user
 * @property  { boolean } includeDestinationStep -  A boolean indicating if the destination step, iet the first, should be shown, ie when the destination is an user
 */
const ToolsManagementStepper = ({
  currentStep,
  requireLogistics,
  includeEmailStep,
  includeDestinationStep
}: {
  currentStep: number;
  requireLogistics: boolean;
  includeEmailStep: boolean;
  includeDestinationStep: boolean;
  includeQuoteAndBillingStep: boolean;
}) => {
  const orderModifierAccordingToFirstStep = includeDestinationStep ? 0 : 1;
  const toolsStepOrderNoLs = requireLogistics ? 2 : 1;
  const emailStepOrderNoLs = requireLogistics ? 4 : 2;
  const quoteAndBillingtStep = includeEmailStep ? 5 : 4;
  const resumeStepOrder = includeEmailStep ? 3 : 2;

  const progressSteps = [
    {
      order: 0,
      showStep: includeDestinationStep,
      title: `Paso 1`,
      subTitle: 'Destino',
      customStepStyles: undefined
    },
    {
      order: 1 - orderModifierAccordingToFirstStep,
      title: `Paso ${1 - orderModifierAccordingToFirstStep + 1}`,
      subTitle: 'Direcciones',
      showStep: requireLogistics,
      customStepStyles: undefined
    },
    {
      order: toolsStepOrderNoLs - orderModifierAccordingToFirstStep,
      title: `Paso  ${toolsStepOrderNoLs - orderModifierAccordingToFirstStep + 1}`,
      subTitle: 'Herramientas',
      showStep: true,
      customStepStyles: undefined
    },
    {
      order: 3 - orderModifierAccordingToFirstStep,
      title: `Paso ${3 - orderModifierAccordingToFirstStep + 1}`,
      subTitle: 'Fechas',
      showStep: requireLogistics,
      customStepStyles: undefined
    },
    {
      order: emailStepOrderNoLs - orderModifierAccordingToFirstStep,
      title: `Paso ${emailStepOrderNoLs - orderModifierAccordingToFirstStep + 1}`,
      subTitle: 'Correo',
      showStep: includeEmailStep,
      customStepStyles: undefined
    },
    {
      order: quoteAndBillingtStep - orderModifierAccordingToFirstStep,
      title: `Paso ${quoteAndBillingtStep - orderModifierAccordingToFirstStep + 1}`,
      subTitle: 'Facturación',
      showStep: requireLogistics,
      customStepStyles: undefined
    },
    {
      order: resumeStepOrder,
      title: `Paso ${resumeStepOrder + 1}`,
      subTitle: 'Resumen',
      showStep: !requireLogistics,
      customStepStyles: undefined
    }
  ];

  const definitiveSteps = progressSteps.filter(step => !!step?.showStep);

  return (
    <div className="ToolsManagementStepper">
      <BordStepper
        subTitleCustomClass="subTitleCustomClass"
        titleStepperCustomClass="titleStepperCustomClass"
        stepperConectorWidth="w-170"
        steps={definitiveSteps}
        currentStep={currentStep}
        isStepContent={false}
      />
    </div>
  );
};

export default ToolsManagementStepper;
