import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IorderInListing } from '../../../../../types/orders';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import { BordFlag, BordOneToneIcon, BordStatus } from '../../../../../components/BordDesignSystem';
import { bordColorTheme } from '../../../../../styles/constants';
import ShipmentsData from './ShipmentsData/ShipmentsData';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import './OrderListingCard.scss';

interface IOrderListingCard {
  dataOrderCard: IorderInListing;
}

const OrderListingCard: FC<IOrderListingCard> = ({ dataOrderCard }: IOrderListingCard) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { currency, orderId, paymentType, paymentTypeStringId, statusName, statusNameStringId } = dataOrderCard || {};
  const { committedPaymentDate, paymentDate, shipments = [], total, totalUsd, proofOfPaymentUrl } = dataOrderCard || {};

  const i18nLanguageKey = 'nodi:orderDetails:orderListingCard:';
  const recurrentWordsKey = 'recurrentWords:';
  const paymentTransfUSD = paymentType === 'Transf. USD';
  const paymentTransfCOP = paymentType === 'Transf. COP';
  const requiresProofOfPayment =
    paymentTransfUSD ||
    paymentType === 'Tarjeta internacional' ||
    paymentTransfCOP ||
    paymentType === 'Tarjeta crédito COP';

  const statusNameKey = statusNameStringId;
  const statusNameTranslation = t(`services:statusName:${statusNameKey}`);
  const orderStatus = statusNameKey ? statusNameTranslation : statusName;
  const statusNameText = orderStatus.split(' ')[0];
  const paymentMethodKey = paymentTypeStringId;
  const paymentMethodTranslation = t(`services:paymentType:${paymentMethodKey}`);
  const textPaymentMethod = paymentMethodKey ? paymentMethodTranslation : paymentType || 'Sin datos';
  const currencyARS = currency === 'ARS';

  const properCurrencyTotal = currency === 'USD' || currencyARS ? totalUsd : total || 0;
  const properCurrency = currencyARS ? 'USD' : currency || 'Sin datos';
  const formattedTotalAmount = `${new Intl.NumberFormat().format(properCurrencyTotal)} ${properCurrency}`;

  const shipmentCountries = [...new Set(shipments.map(shipment => shipment.destinationCountry.code as TcountryCode))];
  const countriesLimit = 3;
  const shipmentCountriesToShow = shipmentCountries.slice(0, countriesLimit);
  const excessCountries = shipmentCountries.length - countriesLimit;
  const additionalCountries = excessCountries > 0 ? excessCountries : 0;

  const goToDetails = () => {
    if (typeof orderId === 'number') push(`/nodi/orders/details/${orderId}`);
  };

  const assignStatusColor = (status?: string) => {
    switch (status) {
      case 'Pendiente de confirmación':
        return 'default';
      case 'Activa':
        return 'informative';
      case 'Entregada':
        return 'success';
      case 'Cancelada':
        return 'danger';
      default:
        return 'default';
    }
  };

  const getPaymentStatus = () => {
    if (committedPaymentDate && paymentDate) {
      return (
        <div className="paymentStatus text-content">
          <span>{formatShortDate(paymentDate).onlyDate}</span>
          <BordOneToneIcon variant="checkCircle" stroke={bordColorTheme.content.default} standardSize={12} />
        </div>
      );
    }
    if (committedPaymentDate) {
      return (
        <div className="paymentStatus text-yellow-600">
          {formatShortDate(committedPaymentDate).onlyDate}
          <BordOneToneIcon variant="alertTriangleWarning" stroke={bordColorTheme.yellow[600]} standardSize={12} />
        </div>
      );
    }
    if (requiresProofOfPayment && proofOfPaymentUrl) {
      return (
        <div className="paymentStatus text-content">
          {t(`${i18nLanguageKey}uploaded`)}
          <BordOneToneIcon variant="checkCircle" stroke={bordColorTheme.content.default} standardSize={12} />
        </div>
      );
    }
    if (requiresProofOfPayment)
      return (
        <div className="paymentStatus text-yellow-600">
          {t(`${i18nLanguageKey}pending`)}
          <BordOneToneIcon variant="alertTriangleWarning" stroke={bordColorTheme.yellow[600]} standardSize={12} />
        </div>
      );
  };

  const paymentStatus = getPaymentStatus();

  return (
    <div id="orderListingCard">
      <div className="clickableCard" onClick={goToDetails}>
        <div className="contentOrderCard">
          <div className="dateAndOrderNumber">
            <div className="orderNumber">
              {t(`${i18nLanguageKey}textNumber`)} N°{dataOrderCard?.orderId}
            </div>
            <div className="orderDate">{formatShortDate(dataOrderCard?.createdAt)?.textWithTimezone}</div>
          </div>

          <ShipmentsData shipmentsData={shipments} />

          <div className="shipmentsCount">
            <div className="title">{t(`${i18nLanguageKey}textDeliveryNumber`)}</div>
            <div className="value">{shipments?.length}</div>
          </div>

          <div className="orderCountry">
            <div className="title">{t(`${recurrentWordsKey}country`)}</div>
            <div className="flags">
              {shipmentCountriesToShow.map(country => {
                return <BordFlag key={country} variant="circle" country={country} standardSize={16} />;
              })}
              {!!additionalCountries && <div className="additionalCountriesText">{`+${additionalCountries}`}</div>}
            </div>
          </div>

          <div className="paymentMethod">
            <div className="title">{t(`${i18nLanguageKey}textPaymentMethod`)}</div>
            <div className="value">{textPaymentMethod}</div>
          </div>

          <div className="totalAmount">
            <div className="title">Total: </div>
            <div className="value">{formattedTotalAmount}</div>
          </div>

          <div className="paymentStatusSection">
            {paymentStatus && <div className="title">{t(`${i18nLanguageKey}proofOfPayment`)}</div>}
            {!!paymentStatus && paymentStatus}
          </div>

          <div className="orderStatus">
            <BordStatus variant={assignStatusColor(statusName)} label={statusNameText} />
          </div>

          <div className="redirectionIcon">
            <BordOneToneIcon
              className="goToDetailsButton"
              variant="arrowRightCircle"
              stroke={bordColorTheme.content.default}
              customWidth="1.8rem"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListingCard;
