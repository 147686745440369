import { useHistory } from 'react-router-dom';
import HeaderBreadcrumbsButton from '../HeaderBreadcrumbsButton/HeaderBreadcrumbsButton';
import { getOrgData } from '../../../utils/getLocalStorageData';
import BordMarketplaceButton from '../../BordDesignSystem/BordMarketplaceButton/BordMarketplaceButton';
import ProfileInfo from '../../profileInfo';
import useSidebar from '../../../hooks/useSidebar';
import './SaasHeader.scss';

function SaasHeader() {
  const { push } = useHistory();
  const { isSidebarOpen } = useSidebar();

  const orgData = getOrgData();

  const storeButtonText = '.bord Store';
  const blockMarketPlace = orgData?.organizationId === 332; //blockedCompany 332
  const closedSidebarStyles = isSidebarOpen ? '' : 'closedSidebar';

  const redirectToPath = (path: string) => {
    if (orgData?.organizationState !== 'active') return;
    else push(path);
  };

  const handleGoToCatalogue = () => {
    redirectToPath('/catalogue/home');
  };

  return (
    <header id="saasHeader" className={`${closedSidebarStyles}`}>
      <HeaderBreadcrumbsButton />
      <div className="rightSection">
        {!blockMarketPlace && (
          <BordMarketplaceButton buttonText={storeButtonText} buttonColor="dark" onClick={handleGoToCatalogue} />
        )}
        <ProfileInfo />
      </div>
    </header>
  );
}

export default SaasHeader;
