import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BodyAlert, NewLogisticsVerifyData } from '../../Components';
import { Iemployee } from '../../../../types/global';
import {
  IlocationAddressForLogistics,
  TlogisticsOriginOrDestinationData
} from '../../../../types/requestLogisticsModule';
import { ItoolDataForRequiringLogistics, TstoragePlace } from '../../../../types/assignationFluxes';
import useRequireLogisticsSummaryData from '../../../../state/useRequireLogisticsSummaryData';
import './UnassignmentStep5DataVerification.scss';

interface IOutRange {
  startDate: Date;
  endDate: Date;
}
interface IUnassignmentStep5DataVerification {
  originData?: Iemployee;
  destinationData?: TlogisticsOriginOrDestinationData;
  dateRange?: IOutRange;
  toolsToUnassignData?: ItoolDataForRequiringLogistics[];
  rangeDays?: string;
  selectedDestination?: TstoragePlace;
  type?: 'locale' | 'international';
}

interface IPrincipalList {
  titleList: string;
  subList?: string[];
}

interface IBodyAlert {
  title: string;
  principalList: IPrincipalList[];
}

interface IPrincipalBodyAlert {
  data: IBodyAlert;
}

const UnassignmentStep5DataVerification: FC<IUnassignmentStep5DataVerification> = ({
  originData,
  destinationData,
  dateRange,
  toolsToUnassignData,
  rangeDays,
  selectedDestination,
  type
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics = requireLogisticsJSON === 'false' ? false : true;
  const destinationDataFormat = destinationData as IlocationAddressForLogistics;
  const { setEstimatedCollection, setSuperiorAlert, setInferiorAlert } = useRequireLogisticsSummaryData();
  const [alternateAlertText, setAlternateAlertText] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<IPrincipalBodyAlert>();
  const i18nLanguageKey = 'services:unassignmentModule';

  const formatDateRange = () => {
    if (dateRange) {
      const startDate = dateRange.startDate;
      const formatStartDay = startDate.getDate();
      const startDay = formatStartDay <= 9 ? `0${formatStartDay}` : formatStartDay;
      const formatStartMonth = startDate.getMonth() + 1;
      const startMonth = formatStartMonth <= 9 ? `0${formatStartMonth}` : formatStartMonth;
      const startYear = startDate.getFullYear().toString();
      const endDate = dateRange.endDate;
      const formatEndDay = endDate.getDate();
      const endDay = formatEndDay <= 9 ? `0${formatEndDay}` : formatEndDay;
      const formatEndMonth = endDate.getMonth() + 1;
      const endMonth = formatEndMonth <= 9 ? `0${formatEndMonth}` : formatEndMonth;
      const endYear = endDate.getFullYear().toString();
      const formatDate = `${startDay}/${startMonth}/${startYear.substring(
        2,
        4
      )} - ${endDay}/${endMonth}/${endYear.substring(2, 4)}`;
      return formatDate;
    }
  };
  const getCustomContainerTitle = () => {
    return requireLogistics ? 'Revisa los datos del servicio para proceder a cotizar' : 'Resumen del offboarding';
  };
  const alertText = () => {
    if (type === 'locale' && (selectedDestination === 'user' || selectedDestination === 'office')) {
      const myComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            {
              titleList: 'Las herramientas deben ser embaladas en cajas selladas.'
            },
            {
              titleList: 'Las cajas no serán manipuladas ni abiertas durante el servicio.'
            },
            {
              titleList: 'Quienes entregan y reciben las herramientas deben:',
              subList: [
                'Completar un formulario especificando los elementos entregados y recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };

      setAlertMessage(myComponent);
      setInferiorAlert(myComponent);
    }

    if (type === 'international' && (selectedDestination === 'user' || selectedDestination === 'office')) {
      const myComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Por razones de seguridad, se inspeccionan las cajas antes de enviarlas al país de destino.' },
            {
              titleList: 'Quienes entregan y reciben las herramientas deben:',
              subList: [
                'Completar un formulario especificando los elementos entregados y recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(myComponent);
      setInferiorAlert(myComponent);
    }
    if (
      type === 'locale' &&
      (selectedDestination === 'diagnostic' || selectedDestination === 'nudos' || selectedDestination === 'warranty')
    ) {
      const myComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Los productos entregados serán validados en bodega por seguridad.' },
            {
              titleList: 'El empleado que entrega las herramientas debe:',
              subList: [
                'Completar un formulario especificando los elementos entregados.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            },
            { titleList: 'Al llegar a bodega se realiza la notificación de estado del dispositivo.' }
          ]
        }
      };
      setAlertMessage(myComponent);
      setInferiorAlert(myComponent);
    }

    if (
      type === 'international' &&
      (selectedDestination === 'diagnostic' || selectedDestination === 'nudos' || selectedDestination === 'warranty')
    ) {
      const myComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Por razones de seguridad, se inspeccionan las cajas antes de enviarlas al país de destino.' },
            {
              titleList: 'El empleado que entrega las herramientas debe:',
              subList: [
                'Completar un formulario especificando los elementos entregados.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            },
            { titleList: 'Al llegar a bodega se realiza la notificación de estado del dispositivo.' }
          ]
        }
      };
      setAlertMessage(myComponent);
      setInferiorAlert(myComponent);
    }
  };

  const superiorAlertText = () => {
    if (type === 'locale') {
      const estimatedDays = rangeDays;
      const countryName = originData?.country?.name || '';
      const simpleText = t(`${i18nLanguageKey}.superiorAlertText.localeDinamic`, {
        estimatedDays,
        countryName
      });
      setAlternateAlertText(alternateAlertText);
      setSuperiorAlert(simpleText);
    } else {
      const simpleText = t(`${i18nLanguageKey}.superiorAlertText.international`);
      setSuperiorAlert(simpleText);
      setAlternateAlertText(simpleText);
    }
  };

  const getDeliveryName = () => {
    switch (selectedDestination) {
      case 'office':
        return `Oficinas ${destinationDataFormat?.country?.name || ''}`;
      case 'user':
        return `${destinationDataFormat?.firstName || ''} ${destinationDataFormat?.lastName || ''}`;
      case 'warranty':
        return 'Marca';
      case 'nudos':
        return `Bodegas Nudos ${destinationDataFormat?.country?.name || ''}`;
      case 'diagnostic':
        return 'Diagnóstico';
    }
  };

  useEffect(() => {
    superiorAlertText();
    alertText();
  }, []);

  useEffect(() => {
    setEstimatedCollection(formatDateRange());
  }, []);

  return (
    <div className="UnassignmentStep5DataVerification">
      <NewLogisticsVerifyData
        type="unassignment"
        tools={toolsToUnassignData || []}
        collectionName={`${originData?.firstName} ${originData?.lastName}`}
        selectedDatesRange={formatDateRange()}
        deliveryName={getDeliveryName()}
        customContainerTitle={getCustomContainerTitle()}
        estimatedDelivery={`${rangeDays} días hábiles`}
        bodyAlertComponent={alertMessage && <BodyAlert data={alertMessage.data} />}
        timeAlertText={alternateAlertText}
      />
    </div>
  );
};

export default UnassignmentStep5DataVerification;
