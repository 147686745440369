import { BordPhoto } from '../../../../components/BordDesignSystem';
import './EmployeeDetailsSkeletonLoader.scss';

const EmployeeDetailsSkeletonLoader = () => {
  return (
    <div className="employeeDetailsSkeletonLoader">
      <div className="skeletonEmployeeDetailsHeader">
        <div className="skeletonProfileImage animationLoader" />
        <div className="skeletonEmployeeDataSection">
          <div className="employeeData animationLoader" />
          <div className="employeeData animationLoader" />
          <div className="employeeData animationLoader" />
        </div>
        <div className="skeletonActiveStatusSection">
          <div className="activeStatus animationLoader" />
          <div className="textStatus animationLoader" />
        </div>
        <div className="skeletonInformationSection">
          <div className="col1">
            <div className="skeletonInformationTitle animationLoader" />
            <div className="skeletonInformation animationLoader" />
            <div className="skeletonInformationTitle animationLoader" />
            <div className="skeletonInformation animationLoader" />
          </div>
          <div className="col2">
            <div className="skeletonInformationTitle animationLoader" />
            <div className="skeletonInformation animationLoader" />
            <div className="skeletonInformationTitle animationLoader" />
            <div className="skeletonInformation animationLoader" />
          </div>
        </div>
        <div className="skeletonButtonEditSection">
          <div className="button animationLoader" />
        </div>
      </div>
      <div className="sectionCards">
        <div className="title animationLoader"></div>
        {[1, 2, 3, 4, 5, 6].map(cardNumber => {
          return (
            <div key={`skeletonCard${cardNumber}`} className="skeletonCard">
              <div className="col1">
                <BordPhoto size="s56" showSkeletonLoader />
                <div className="nameProduct">
                  <div className="name animationLoader"></div>
                  <div className="badges">
                    <div className="badge animationLoader"></div>
                    <div className="badge animationLoader"></div>
                  </div>
                </div>
              </div>
              <div className="statusAndId">
                <div className="status animationLoader"></div>
                <div className="id animationLoader"></div>
              </div>
              <div className="col3">
                <div className="button animationLoader"></div>
                <div className="arrow animationLoader"></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmployeeDetailsSkeletonLoader;
