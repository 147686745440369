import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 6.233a4.662 4.662 0 0 0-.64-2.39 4.727 4.727 0 0 0-1.779-1.738 4.79 4.79 0 0 0-4.82.092 4.72 4.72 0 0 0-1.71 1.805 4.66 4.66 0 0 0 .22 4.766c.827 1.255 1.317 1.716 1.317 3.21a.52.52 0 0 0 .155.37.53.53 0 0 0 .373.152h4.268a.53.53 0 0 0 .373-.153.52.52 0 0 0 .155-.37c0-1.529.548-1.983 1.37-3.284.465-.738.714-1.59.718-2.46Z"
    />
    <path stroke="#383838" strokeLinecap="round" strokeLinejoin="round" d="M6.5 14.5h3" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconLightbulb = memo(ForwardRef);
export default IconLightbulb;
