import { AxiosError } from 'axios';
import { IEstimatedDeliveredDate } from '../types/catalogue';
import { apiGet } from './api';
import { catalogAPI } from '../constants';

const getEstimatedDeliveredDate = async (countryCode: string) => {
  return apiGet<IEstimatedDeliveredDate, IEstimatedDeliveredDate>(
    `${catalogAPI}/estimated-delivered-date/by-country-code?code=${countryCode}`
  )
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export { getEstimatedDeliveredDate };
