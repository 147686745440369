import { AxiosError, AxiosRequestConfig } from 'axios';
import { orgAPI, usersAPI, subscriptionAPI } from '../constants';
import { DTOupdateEmployeeData } from '../types/DTO';
import { IUploadEmployeesResponse } from '../types/employes';
import { Iemployee, Tany } from '../types/global';
import { apiGet, apiPost, apiPut } from './api';
import { DTOgetModuleFiltersRequestFunction } from '../components/NudosComponents/ModuleFilters/ModuleFilters';

export const getEmployeesListing = async (
  organizationId: number,
  filters?: string,
  employeeNameQuery?: string
): Promise<Iemployee[]> => {
  const searchQueryParam =
    filters && employeeNameQuery
      ? `&querySearch=${employeeNameQuery}`
      : `${employeeNameQuery ? `?querySearch=${employeeNameQuery}` : ''}`;
  return apiGet<AxiosRequestConfig, Iemployee[]>(
    `${orgAPI}/organization/employees/${organizationId}${filters || ''}${searchQueryParam}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getEmployeeById = async (userId: string | number): Promise<Iemployee> => {
  return apiGet<AxiosRequestConfig, Iemployee>(`${orgAPI}/organization/employee/${userId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const createNewEmployee = async (formData: FormData): Promise<Tany> => {
  return apiPost<AxiosRequestConfig, Tany>(`${orgAPI}/organization/assignment-user`, formData)
    .then(response => Promise.resolve(response?.data ? response?.data : response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getFiltersEmployees = async (
  organizationId: number | string
): Promise<DTOgetModuleFiltersRequestFunction> => {
  return apiGet<AxiosRequestConfig, DTOgetModuleFiltersRequestFunction>(
    `${orgAPI}/organization/employee/filters/${organizationId}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateUserProfilePhoto = async (
  userId: number | string,
  formData: FormData
): Promise<{ [key: string]: string }> => {
  return apiPut<AxiosRequestConfig, FormData>(`${usersAPI}/user/profile-photo/${userId}`, formData)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.resolve(error));
};

export const updateEmployeeData = async (userId: number | string, body: DTOupdateEmployeeData): Promise<Tany> => {
  return apiPut<AxiosRequestConfig, Tany>(`${orgAPI}/organization/assignment-user/${userId}`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const uploadAssignationDocumentOrUpdateAssignationDate = async (
  assignmentIds: (string | number)[],
  formData: FormData
): Promise<unknown> => {
  const assignmentIdsParam = JSON.stringify(assignmentIds);
  return apiPut<AxiosRequestConfig, FormData>(
    `${subscriptionAPI}/product/upload/file-assignment/${assignmentIdsParam}?sendMail=0`,
    formData
  )
    .then(response => Promise.resolve(response || 200))
    .catch((error: AxiosError) => {
      Promise.resolve(error);
      throw error;
    });
};

export const changeStatusUser = async (userId: number | string): Promise<string> => {
  return apiPut<AxiosRequestConfig, string>(`${orgAPI}/organization/employee/disable/${userId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.resolve(error));
};

export const employeesFile = async (formData: FormData): Promise<IUploadEmployeesResponse> => {
  return apiPost(`${orgAPI}/organization/upload/employees-file`, formData)
    .then(response => Promise.resolve(response.data))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const changeAssignedDate = async (assignmentId: number, date: string): Promise<string> => {
  return apiPut<AxiosRequestConfig, string>(
    `${subscriptionAPI}/assignment/update-assignment-date/${assignmentId}/${date}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => {
      Promise.resolve(error);
      throw error;
    });
};
