const RoundSeychelles = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M26.8001 4.19606C23.9539 1.59031 20.1625 0 15.9995 0C15.318 0 14.6465 0.0429375 13.9874 0.125625L6.26042 9.0435L0.505859 20.0082C0.877609 21.4495 1.44598 22.8116 2.1793 24.0655L15.9995 16L26.8001 4.19606Z"
        />
        <path
          fill="#6DA544"
          d="M29.321 24.865L6.93555 29.185C9.51236 30.9601 12.6347 32.0001 16.0004 32.0001C21.5579 32.0001 26.4529 29.1662 29.321 24.865Z"
        />
        <path
          fill="#F93939"
          d="M26.8024 4.19824L2.18848 24.0811C2.83229 25.1791 3.6031 26.1935 4.48104 27.1041L31.9996 16.0001C31.9996 11.3276 29.9964 7.12337 26.8024 4.19824Z"
        />
        <path
          fill="#1A47B8"
          d="M0 16.0001C0 17.3843 0.175875 18.7274 0.506312 20.0083L13.9878 0.125732C6.10112 1.11536 0 7.84511 0 16.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSeychelles;
