const SquareCapeVerde = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#1A47B8" rx="2" />
        <path fill="white" fillRule="evenodd" d="M0 8.5332H22V9.59987H0V8.5332Z" clipRule="evenodd" />
        <path fill="#F93939" fillRule="evenodd" d="M0 9.6001H22V10.6668H0V9.6001Z" clipRule="evenodd" />
        <path fill="white" fillRule="evenodd" d="M0 10.6665H22V11.7332H0V10.6665Z" clipRule="evenodd" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M5.23805 7.46657H6.28567V8.53324H5.23805V7.46657ZM7.33329 6.3999H8.38091V7.46657H7.33329V6.3999ZM9.42852 7.46657H10.4761V8.53324H9.42852V7.46657ZM10.4761 9.5999H11.5238V10.6666H10.4761V9.5999ZM4.19043 9.5999H5.23805V10.6666H4.19043V9.5999ZM9.42852 11.7332H10.4761V12.7999H9.42852V11.7332ZM7.33329 12.7999H8.38091V13.8666H7.33329V12.7999ZM5.23805 11.7332H6.28567V12.7999H5.23805V11.7332Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareCapeVerde;
