const RoundIreland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FF9811"
          d="M32.0002 16.0001C32.0002 9.12074 27.6582 3.25599 21.5654 0.995361V31.005C27.6582 28.7442 32.0002 22.8796 32.0002 16.0001Z"
        />
        <path
          fill="#6DA544"
          d="M0 16.0001C0 22.8796 4.342 28.7442 10.4348 31.005V0.995361C4.342 3.25599 0 9.12074 0 16.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIreland;
