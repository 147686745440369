import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useHeaderNavigationRoutes from './useHeaderNavigationRoutes';
import BordBreadCrumb from '../../BordDesignSystem/BordBreadCrumb/BordBreadCrumb';
import './HeaderBreadcrumbsButton.scss';

interface IHeaderBreadcrumbsButton {
  customMarginLeft?: number;
}

const HeaderBreadcrumbsButton: FC<IHeaderBreadcrumbsButton> = () => {
  const { pathname, state }: { pathname: string; state: IcustomHeaderBreadcrumbsNavigationState } = useLocation();
  const { headerNavigationRoutes } = useHeaderNavigationRoutes();
  const { customReturnPath, customNavigationTitle, customNavigationSubtitle } = state || {};

  const pathWithoutIds = pathname.replace(/\/[0-9]+/, '');
  const currentRouteNavigationData = headerNavigationRoutes[pathWithoutIds];
  const headerNavigationTitle = customNavigationTitle || currentRouteNavigationData?.title || '';
  const headerNavigationSubtitle = customNavigationSubtitle || currentRouteNavigationData?.subtitle || '';
  const returnPath = customReturnPath || currentRouteNavigationData?.returnPath;

  return (
    <div className="headerBreadcrumbsButton">
      {returnPath && (
        <Link className="navigationLink" to={returnPath}>
          <BordBreadCrumb
            headerNavigationTitle={headerNavigationTitle}
            headerNavigationSubtitle={headerNavigationSubtitle}
          />
        </Link>
      )}
      {!returnPath && (
        <BordBreadCrumb
          headerNavigationTitle={headerNavigationTitle}
          headerNavigationSubtitle={headerNavigationSubtitle}
        />
      )}
    </div>
  );
};

export default HeaderBreadcrumbsButton;

export interface IcustomHeaderBreadcrumbsNavigationState {
  customReturnPath?: string;
  customNavigationTitle?: string;
  customNavigationSubtitle?: string;
}
