const RoundEuropeanUnion = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 6.26099 16.5181 7.8553H18.1944L16.8382 8.84061 17.3562 10.4349 16 9.44955 14.6438 10.4349 15.1619 8.84061 13.8057 7.8553H15.482L16 6.26099ZM9.11364 9.11346 10.6074 9.87446 11.7927 8.68921 11.5304 10.3448 13.0241 11.1059 11.3684 11.3681 11.1061 13.0239 10.3451 11.5302 8.68945 11.7925 9.87477 10.6072 9.11364 9.11346ZM6.26074 16 7.85505 15.482V13.8057L8.8403 15.1619 10.4347 14.6439 9.44924 16 10.4347 17.3562 8.8403 16.8382 7.85505 18.1944V16.5181L6.26074 16ZM9.11364 22.8867 9.8747 21.3929 8.68945 20.2076 10.3451 20.47 11.1061 18.9763 11.3684 20.632 13.024 20.8943 11.5305 21.6553 11.7927 23.3109 10.6074 22.1256 9.11364 22.8867ZM16 25.7392 15.4819 24.1449H13.8057L15.1619 23.1596 14.6438 21.5654 16 22.5507 17.3562 21.5654 16.8382 23.1596 18.1944 24.1449H16.518L16 25.7392ZM22.8869 22.8867 21.3932 22.1257 20.2078 23.311 20.4701 21.6553 18.9766 20.8943 20.6322 20.632 20.8944 18.9763 21.6554 20.47 23.3111 20.2076 22.1257 21.3931 22.8869 22.8867ZM25.7392 16 24.1449 16.5181V18.1944L23.1596 16.8382 21.5654 17.3562 22.5507 16 21.5654 14.6439 23.1597 15.1619 24.1449 13.8057V15.482L25.7392 16ZM22.8869 9.1134 22.1258 10.6071 23.3111 11.7925 21.6554 11.5301 20.8944 13.0238 20.6322 11.3681 18.9766 11.1058 20.4701 10.3448 20.2078 8.68921 21.3932 9.87446 22.8869 9.1134Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEuropeanUnion;
