import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getNodiProductById } from '../../../services/services';
import {
  ActiveLogisticsServiceCard,
  ActiveOrderCard,
  HeaderNodiInternalProductDetails,
  NoLocationLeft,
  ProductLocationCard,
  ToolManagementActions
} from './Components';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { InudoInfo, TtoolCategories } from '../../../types/global';
import ToolDetailsRightSection from './Components/ToolDetailsRIghtSection/ToolDetailsRightSection';
import ToolLocationsHistory from './Components/ToolLocationsHistory/ToolLocationsHistory';
import LocationToolsFlux from '../logisticServices/LocationToolsFlux';
import useSidebar from '../../../hooks/useSidebar';
import './NodiInternalProductDetails.scss';

const NodiInternalProductDetails = () => {
  const { id: productId } = useParams<{ id: string }>();
  const { moduleSidebarStyles } = useSidebar();
  const [generalLoading, setGeneralLoading] = useState<boolean>(true);
  const [productData, setProductData] = useState<InudoInfo>();
  const [listOfCountriesOpenForLogistics, setListOfCountriesOpenForLogistics] = useState<ICountryDetail[]>();
  const [listOfCountriesWithWarehouses, setListOfCountriesWithWarehouses] = useState<ICountryDetail[]>();
  const [toolNoLocation, setToolNoLocation] = useState<boolean>(false);
  const [totalCountriesAvailable, setTotalCountriesAvailable] = useState<ICountryDetail[]>();
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);

  const getGeneralProductData = async (generalLoading?: boolean) => {
    if (generalLoading) setGeneralLoading(true);
    try {
      const productData = await getNodiProductById(productId);
      setProductData(productData);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setGeneralLoading(false);
      setOpenLocationModal(false);
    }
  };
  const currentToolCountryAllowsLogistics = listOfCountriesOpenForLogistics
    ? listOfCountriesOpenForLogistics?.some(country => country?.code === productData?.country?.code)
    : false;

  const currentUserId =
    productData?.currentAssignment && productData?.currentAssignment?.length
      ? productData?.currentAssignment[0]?.userId
      : undefined;

  const toolHasLogisticServicePending =
    !!productData?.logisticStatus && !['entregado', 'cancelado'].includes(productData?.logisticStatus || '');

  const toolHasntBeenDelivered =
    productData?.referenceModel === 'CatalogProduct' && productData?.shipmentStatusName !== 'Entregado';

  const showToolManagementActionsSkeletonLoder =
    !listOfCountriesOpenForLogistics || generalLoading || !productData?.productId;

  const toolManagementRequiresLogistics = ['nudos', 'warranty', 'diagnostic'].includes(productData?.place || '');
  const toolSupportsWarrantyOrDiagnosticManagement = !['warranty', 'diagnostic'].includes(productData?.place || '');
  const toolCountryHasNudosWarehouse =
    listOfCountriesWithWarehouses?.some(warehouseCountry => warehouseCountry?.code === productData?.country?.code) ||
    false;

  const toolAllowsWarrantyManagement =
    (productData?.nudosCareName && productData?.nudosCareName !== 'Free') ||
    productData?.referenceModel === 'CatalogProduct';

  const toolLocationHistory = productData?.oldAssignment
    ? productData?.oldAssignment.filter(location => location.exitDate)
    : [];

  const orderDeliveryStatusInProgress = ['Proceso de entrega', 'Confirmado'].includes(
    productData?.shipmentStatusName || ''
  );

  const orderToolInDeliveryProcess =
    orderDeliveryStatusInProgress && !toolHasLogisticServicePending && productData?.place === 'user';

  const checkLocation = () => {
    if (productData) {
      const invalidvalidLocation = !['user', 'office', 'nudos', 'warranty', 'diagnostic', 'other'].includes(
        productData?.place || ''
      );
      const toolInLimbo = productData?.place === 'user' && !productData.assignedTo && !toolHasLogisticServicePending;
      setToolNoLocation(invalidvalidLocation || toolInLimbo);
    }
  };

  const updateDetailInfo = async () => await getGeneralProductData(true);

  useEffect(() => {
    getListOfCountriesByOfferedService(setListOfCountriesOpenForLogistics, 'logisticService');
    getListOfCountriesByOfferedService(setTotalCountriesAvailable);
    getListOfCountriesByOfferedService(setListOfCountriesWithWarehouses, 'storage');
    getGeneralProductData(true);
  }, []);

  useEffect(() => {
    checkLocation();
  }, [productData]);

  return (
    <>
      {productData && openLocationModal && totalCountriesAvailable && totalCountriesAvailable.length > 0 && (
        <LocationToolsFlux
          listOfAllCountries={totalCountriesAvailable || []}
          defaultCountryCode={productData?.country?.code}
          closeLocationModal={() => setOpenLocationModal(false)}
          updateCardListData={() => updateDetailInfo()}
          productId={productId}
        />
      )}
      <div className={`NodiInternalProductDetails ${moduleSidebarStyles}`}>
        <div className={`${toolNoLocation ? 'justify-between' : 'gap-60'} generalContainerProductDetails`}>
          <div>
            <HeaderNodiInternalProductDetails dataHeader={productData} generalLoading={generalLoading} />
            <div className="toolBodyBox">
              {toolNoLocation ? (
                <NoLocationLeft actionAddLocation={() => setOpenLocationModal(true)} generalLoading={generalLoading} />
              ) : (
                <>
                  <ToolManagementActions
                    orderId={productData?.orderId}
                    toolProductId={productData?.productId}
                    toolManagementRequiresLogistics={toolManagementRequiresLogistics}
                    toolCountryAllowsLogistics={currentToolCountryAllowsLogistics}
                    toolCountryHasNudosWarehouse={toolCountryHasNudosWarehouse}
                    toolSupportsWarrantyOrDiagnosticManagement={toolSupportsWarrantyOrDiagnosticManagement}
                    showSkeletonLoader={showToolManagementActionsSkeletonLoder}
                    isCurrentlyAssigned={!!productData?.assignedTo}
                    currentUserId={currentUserId}
                    toolHasLogisticServicePending={toolHasLogisticServicePending}
                    toolAllowsWarrantyManagement={toolAllowsWarrantyManagement}
                    toolHasntBeenDelivered={toolHasntBeenDelivered}
                    categoryName={(productData?.category as TtoolCategories) || ''}
                    toolIsKit={!!productData?.isKit}
                    productCountry={productData?.country?.name}
                  />
                  {toolHasLogisticServicePending ? (
                    <ActiveLogisticsServiceCard dataLS={productData} generalLoading={generalLoading} />
                  ) : orderToolInDeliveryProcess ? (
                    <ActiveOrderCard orderDetails={productData} generalLoading={generalLoading} />
                  ) : (
                    <ProductLocationCard dataLocationCard={productData} generalLoading={generalLoading} />
                  )}
                </>
              )}
              <ToolLocationsHistory
                updateDataCallback={updateDetailInfo}
                previousAssignmentHistory={toolLocationHistory}
                orderId={Number(productData?.orderId)}
                showSkeletonLoader={generalLoading}
              />
            </div>
          </div>
          <ToolDetailsRightSection
            showSkeletonLoader={generalLoading}
            searchedAcquisition={productData}
            reloadDataCallback={() => getGeneralProductData(true)}
          />
        </div>
      </div>
    </>
  );
};

export default NodiInternalProductDetails;
