import { BordOneToneIcon } from '../../BordDesignSystem';
import './NudosBreadcrumbButton.scss';

/**
 * React functional component corresponding to the Nudos Breadcrumb return button
 * @property {string} returnText - The text to be displayed by the button.
 * @property {function(): void} returnAction - The callback that executes the return on clicking the component.
 * @property {string} customClassName - Optional
 */
const NudosBreadcrumbButton = ({
  returnText,
  returnAction,
  customClassName,
  smallHeader,
  extraBoldText
}: InudosBreadCrumbButton) => {
  return (
    <div
      className={`nudosBreadcrumbButton ${customClassName || ''}`}
      onClick={() => {
        returnAction();
      }}
    >
      <div className="returnIcon">
        <BordOneToneIcon standardSize={12} variant="arrowHeadLeft" />
      </div>
      <div
        className={`returnText ${extraBoldText ? 'font-bold' : ''}`}
        style={{
          color: smallHeader ? '#383838' : '',
          fontSize: smallHeader ? '12px' : '',
          lineHeight: smallHeader ? '12px' : ''
        }}
      >
        {returnText}
      </div>
    </div>
  );
};

export default NudosBreadcrumbButton;

export interface InudosBreadCrumbButton {
  returnText: string;
  returnAction: () => void;
  customClassName?: string;
  smallHeader?: boolean;
  extraBoldText?: boolean;
}
