import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordAtSignIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M16 8.00011v4.99999c0 .7957.3161 1.5587.8787 2.1213.5626.5626 1.3257.8787 2.1213.8787.7957 0 1.5587-.3161 2.1213-.8787.5626-.5626.8787-1.3256.8787-2.1213v-1c-.0001-2.25696-.7638-4.44751-2.1667-6.21546-1.403-1.76794-3.3627-3.0093-5.5607-3.52223-2.1979-.51293-4.50466-.26726-6.54524.69707-2.04059.96432-3.69495 2.59059-4.69409 4.61435-.99914 2.02377-1.28429 4.32597-.80909 6.53237.4752 2.2064 1.68281 4.1871 3.42646 5.6202 1.74365 1.433 3.9208 2.2341 6.17746 2.2729 2.2566.0388 4.46-.6869 6.2519-2.0592m-2.08-7.94c0 2.2092-1.7908 4-4 4-2.20913 0-3.99999-1.7908-3.99999-4 0-2.20913 1.79086-3.99999 3.99999-3.99999 2.2092 0 4 1.79086 4 3.99999Z"
      />
    </svg>
  );
};

export default BordAtSignIcon;
