const RoundNewZealand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="white"
          d="M15.957 16.0001H15.9998C15.9998 15.9857 15.9998 15.9717 15.9998 15.9573C15.9856 15.9716 15.9713 15.9858 15.957 16.0001Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.162 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0486 16H15.9572C15.9715 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51185 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0325 10.3152 8.3479C10.3152 8.3479 8.34766 8.34803 8.34766 8.3479V8.34803L15.9997 16H15.9998C15.9998 16 15.9998 14.6433 15.9998 14.0325ZM27.7081 11.8723 28.0534 12.9352H29.171L28.2669 13.5921 28.6122 14.6549 27.7081 13.9981 26.8039 14.6549 27.1493 13.5921 26.2451 12.9352H27.3627L27.7081 11.8723ZM23.7129 19.5247 24.231 21.119H25.9073L24.5511 22.1043 25.0692 23.6986 23.7129 22.7133 22.3567 23.6986 22.8748 22.1043 21.5186 21.119H23.1949L23.7129 19.5247ZM23.8637 7.00293 24.2955 8.33162H25.6924L24.5622 9.15255 24.9939 10.4812 23.8637 9.65999 22.7336 10.4812 23.1653 9.15255 22.0352 8.33162H23.432L23.8637 7.00293ZM20.0205 11.8259 20.5385 13.4203H22.2149L20.8587 14.4056 21.3768 15.9999 20.0205 15.0146 18.6643 15.9999 19.1824 14.4056 17.8262 13.4203H19.5025L20.0205 11.8259Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNewZealand;
