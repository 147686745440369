import { useEffect, useState } from 'react';
import { Iemployee, IaddressFromGoogleMaps } from '../../../types/global';
import { NudosAddressSearchBar, NudosHoverText } from '../../NudosComponents';
import { BordButton, BordOneToneIcon, BordPhoto } from '../../BordDesignSystem';
import { BordTextInput } from '../../BordDesignSystem';
import { getAddressErrorText, getIdDocumentData, isPhoneValid } from '../../../utils/formValidations';
import { ICountryDetail } from '../../../types/countries';
import { formatZipCode, getIsInvalidZipCode, invalidZipCodeText } from '../../../utils/productDefinitions';
import { DTOupdateEmployeeData } from '../../../types/DTO';
import { IshipmentGroupDetails } from '../../../types/orders';
import BordPhoneInput from '../../BordDesignSystem/BordPhoneInput/BordPhoneInput';
import { TcountryCode } from '../../BordDesignSystem/BordFlag/BordFlag.types';

import './EmployeeDestinationModal.scss';

/**
 * IMPORTANT: Use this modal as the prop for the CustomModalComponent prop of the NudosModalContent, which in turn must be the modalContent prop of the NudosGenericModal
 * @property {Iemployee} employeeData -The employee whose address is the destination, this is the initial data for the modal
 * @property {string} subtitle - The text to show as a modal subtitle
 * @property {string} buttonText - The text for the modal button
 * @property {(modalData: DTOupdateEmployeeData) => void | Promise<void>} clickButtonCallbac - a function to execute upon clickin the modal button, receives as param the data of the modal
 * @property { boolean } isButtonLoading - A boolean indicating if the modal submit data button is loading
 */
const EmployeeDestinationModal = ({
  employeeData,
  shipmentExistingData,
  subtitle,
  buttonText,
  clickButtonCallback,
  isButtonLoading,
  listOfCountries
}: {
  employeeData?: Iemployee;
  shipmentExistingData?: IshipmentGroupDetails;
  subtitle?: string;
  buttonText: string;
  clickButtonCallback: (modalData: DTOupdateEmployeeData) => void | Promise<void>;
  isButtonLoading?: boolean;
  listOfCountries?: ICountryDetail[];
}) => {
  const { firstName, lastName, photoProfile, country, phoneData, personalId, address } = employeeData || {};

  const [updatedPersonalId, setUpdatedPersonalId] = useState<string>();
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState<string>();
  const [updatedPhoneCountry, setUpdatedPhoneCountry] = useState<ICountryDetail>();
  const [updatedZipCode, setUpdatedZipCode] = useState<string>();
  const [updatedAddress, setUpdatedAddress] = useState<IaddressFromGoogleMaps>();
  const [updatedAddressAditionalReferences, setUpdatedAddressAditionalReferences] = useState<string>();

  const employeeIsFromBrazil = country?.code === 'br';
  const employeeFullName = shipmentExistingData?.receiverName || `${firstName || ''} ${lastName || ''}`;
  const employeeNameMaxLength = employeeIsFromBrazil ? 18 : 40;
  const idDocumentData = getIdDocumentData(country || undefined, updatedPersonalId);

  const additionalReferencesErrorText =
    updatedAddress && !updatedAddressAditionalReferences ? 'Completa las indicaciones' : '';

  const defaultPhoneCountryData =
    country && phoneData?.phoneCode
      ? { ...country, phoneCode: phoneData?.phoneCode }
      : listOfCountries?.find(countryInList => countryInList?.code === country?.code);

  const updatedCompleteAddress = {
    ...updatedAddress,
    address: updatedAddress?.address || '',
    zipCode: updatedZipCode || '',
    additionalReferences: updatedAddressAditionalReferences || ''
  };

  const addressError = !updatedAddress?.address
    ? ' '
    : getAddressErrorText(
        country?.code,
        country?.name,
        updatedAddress?.country || undefined,
        true,
        updatedAddress?.coordinates
      );

  const getZipCodeErrorText = () => {
    if (!updatedZipCode) return ' ';
    if (getIsInvalidZipCode(updatedZipCode)) return invalidZipCodeText;
  };

  const getInvalidPhoneError = () => {
    if (!updatedPhoneNumber) return ' ';
    if (!isPhoneValid(updatedPhoneNumber)) return `Ingresa un teléfono válido para ${country?.name || 'este país'}`;
  };

  const isButtonDisabled = () => {
    const addressIsIncomplete = Object.entries(updatedCompleteAddress).some(
      ([key, value]) => !['coordinates'].includes(key) && !value
    );
    if (!updatedPhoneNumber || !updatedPhoneCountry || !updatedZipCode) return true;
    if (!updatedAddress || !updatedAddressAditionalReferences) return true;
    if (addressIsIncomplete) return true;
    if (idDocumentData?.errorText) return true;
    if (
      getAddressErrorText(
        country?.code,
        country?.name,
        updatedAddress?.country || undefined,
        true,
        updatedAddress?.coordinates
      )
    )
      return true;
    if (!isPhoneValid(updatedPhoneNumber)) return true;
    if (getIsInvalidZipCode(updatedZipCode)) return true;
    if (!updatedPhoneCountry?.id || !employeeData?.country?.id) return true;
    if (!updatedAddressAditionalReferences) return true;
    return false;
  };

  const handleClickModalButton = () => {
    if (isButtonDisabled() || !updatedPhoneCountry?.id || !employeeData?.country?.id) return;
    const modalData = {
      firstName: employeeData?.firstName || undefined,
      lastName: employeeData?.lastName || undefined,
      personalId: idDocumentData?.value,
      phone: updatedPhoneNumber || employeeData?.phoneData?.phone,
      countryId: employeeData?.country?.id,
      city: employeeData?.city || undefined,
      cityId: employeeData?.cityId || undefined, // CAMBIAR-JC: Cambiar si alguna vez diseño implemente un select de ciudad en este modal
      area: employeeData?.area || undefined,
      position: employeeData?.position || undefined,
      address: updatedCompleteAddress || employeeData?.address,
      countryPhoneId: updatedPhoneCountry?.id
    };
    clickButtonCallback(modalData);
  };

  useEffect(() => {
    if (!updatedPhoneNumber) setUpdatedPhoneNumber(phoneData?.phone || undefined);
    if (!updatedZipCode) setUpdatedZipCode(formatZipCode(address?.zipCode) || undefined);
    if (!updatedPersonalId) setUpdatedPersonalId(personalId || undefined);
    if (!updatedAddress)
      setUpdatedAddress({
        ...employeeData?.address,
        country: employeeData?.country?.code || employeeData?.address?.country || '',
        city: employeeData?.city || employeeData?.address?.city || ''
      });
    if (!updatedAddressAditionalReferences)
      setUpdatedAddressAditionalReferences(employeeData?.address?.additionalReferences || undefined);
  }, [employeeData]);

  return (
    <div className="employeeDestinationModal">
      {subtitle && <h2 className="subtitle">{subtitle}</h2>}{' '}
      <div className="photoAndName">
        <BordPhoto
          url={photoProfile || ''}
          size="s56"
          flagProps={{
            country: country?.code as TcountryCode,
            variant: 'circle',
            standardSize: 24
          }}
          avatarProps={{
            variant: 'rectangularBordMascot',
            customWidth: '5.6rem'
          }}
        />
        <NudosHoverText
          customClassName="name"
          text={employeeFullName}
          charactersLimit={employeeNameMaxLength}
          onlyIfTruncated
        />
        {employeeIsFromBrazil && (
          <em className="personalIdWarningText">
            El CPF es obligatorio para entregas en Brasil. En caso de que este dato no este correcto la entrega podría
            retrasarse o no entregarse en los tiempos estipulados.
          </em>
        )}
      </div>
      <div className="firstLineFields">
        <BordTextInput
          standardSize="w-280"
          label={idDocumentData?.label}
          placeholder={idDocumentData?.placeholder}
          isFilled={!!idDocumentData?.value}
          setCurrentText={setUpdatedPersonalId}
          currentText={idDocumentData?.value}
          errorText={idDocumentData?.errorText}
          caption={idDocumentData?.caption}
        />
        <BordPhoneInput
          componentSize="w280"
          label="Teléfono"
          placeholder="Ej: 3000000001"
          defaultCountryData={defaultPhoneCountryData}
          defaultPhoneNumber={phoneData?.phone || undefined}
          errorText={getInvalidPhoneError()}
          handlePhoneChange={setUpdatedPhoneNumber}
          countriesList={listOfCountries || []}
          handleCountryPhoneChange={setUpdatedPhoneCountry}
        />
      </div>
      <div className="secondLineFields">
        <BordTextInput
          standardSize="w-80"
          label="Código postal"
          placeholder="CP"
          isFilled={!!updatedZipCode}
          setCurrentText={setUpdatedZipCode}
          handleBlur={newZipCode => setUpdatedZipCode(formatZipCode(newZipCode))}
          currentText={updatedZipCode || undefined}
          errorText={getZipCodeErrorText()}
        />
        <BordTextInput
          standardSize="w-280"
          label="Indicaciones"
          errorText={additionalReferencesErrorText}
          placeholder="Ej: Torre, Apto, Casa"
          isFilled={!!updatedAddressAditionalReferences}
          setCurrentText={setUpdatedAddressAditionalReferences}
          currentText={updatedAddressAditionalReferences || undefined}
        />
      </div>
      <div className="thirdLineFields">
        <NudosAddressSearchBar
          componentSize="w-580"
          label="Dirección"
          regionBias={country?.code}
          handleAddressSelection={setUpdatedAddress}
          errorText={addressError}
          defaultValueAddressName={address?.address || undefined}
          didntFindAddressTooltipRightPosition={0}
          didntFindAddressTooltipTopPosition={6.6}
        />
      </div>
      <div className="quarterLineField">
        <div className="employeeAlert">
          {' '}
          <BordOneToneIcon variant="alertTriangleWarning" standardSize={14} />
          Al actualizar la información, esta se guardará en el perfil del empleado
        </div>
      </div>
      <BordButton
        label={buttonText}
        customWidth={'w-380'}
        customHeight="42"
        disabled={isButtonDisabled()}
        onClick={handleClickModalButton}
        isLoading={isButtonLoading}
        customClassName="nudosButton"
      />
    </div>
  );
};

export default EmployeeDestinationModal;
