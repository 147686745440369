import { useEffect, useState } from 'react';
import { ICountryDetail } from '../../../types/countries';
import { IdeliveryAddress, Iemployee } from '../../../types/global';
import NudosLogisticServiceUserHeader from '../NudosLogisticServiceUserHeader/NudosLogisticServiceUserHeader';
import NudosLogisticsCardAddressForm from '../NudosLogisticsCardAddressForm/NudosLogisticsCardAddressForm';
import { NudosHoverText } from '../../NudosComponents';
import { useTranslation } from 'react-i18next';
import { getIdDocumentData, isPhoneValid } from '../../../utils/formValidations';
import { verifyPhoneAndEmail } from '../../../services/register';
import { emailAndPhoneValidationResponseTexts } from '../../../utils/backendConstants';
import { IcityDetails } from '../../../types/cities.types';
import { BordTextInput } from '../../BordDesignSystem';
import BordPhoneInput from '../../BordDesignSystem/BordPhoneInput/BordPhoneInput';
import './NudosLogisticsEmployeeCard.scss';

/**
 * @property { 'origin' | 'destination' } type  - The type of the card, will be used in the title of the card
 * @property { Iemployee } existingEmployeeData  - The initial data for the employee, will be used to preload the card
 * @property { ICountryDetail[] } listOfCountries  - The list of countries available on the Nodi platform, will be used to display the options for the phone and address country dropdowns. Will also be used to obtain the data for the initial phone and address country starting from the codes stored in the existingEmployeeData
 * @property { (newOriginData: Iemployee) => void } updateLogisticsLocationDataCallback  - The callback function to update the logistics location data in the parent component (for example, if the card is used to update the origin or destination information, that data should be stored in the parent component and a function to updated it should be pass in this prop)
 * @property { boolean } disableCountryUpdating  - A boolean indicating if it is possible to update the country of the employee, or only the address within the initial country
 */
const NudosLogisticsEmployeeCard = ({
  type,
  existingEmployeeData,
  listOfCountries,
  updateLogisticsLocationDataCallback,
  disableCountryUpdating
}: InudosLogisticsEmployeeCardProps) => {
  const { t } = useTranslation();

  const { firstName, lastName, mail, photoProfile } = existingEmployeeData || {};
  const [updatedEmployeeAddress, setUpdatedEmployeeAddress] = useState<IdeliveryAddress>();
  const [addressCountry, setAddressCountry] = useState<ICountryDetail>();
  const [originalPhone, setOriginalPhone] = useState<string>();
  const [updatedCity, setUpdatedCity] = useState<IcityDetails>();
  const [updatedPhone, setUpdatedPhone] = useState<string>();
  const [phoneAlreadyExistsError, setPhoneAlreadyExistsError] = useState<string>();
  const [updatedPhoneCountry, setUpdatedPhoneCountry] = useState<ICountryDetail>();
  const [updatedPersonalId, setUpdatedPersonalId] = useState<string>();

  const i18nLanguageKey = 'designSystemComponents:nudosLogisticsEmployeeCard';
  const translationKeyByRecurrentWords = 'recurrentWords:';
  const generalInformationText = t(`${translationKeyByRecurrentWords}generalInformation`);
  const nameText = t(`${translationKeyByRecurrentWords}name`);
  const and = t(`${translationKeyByRecurrentWords}and`);
  const surnameText = t(`${translationKeyByRecurrentWords}surname`);
  const corporateEmailText = t(`${translationKeyByRecurrentWords}corporateEmail`);
  const phoneNumberText = t(`${translationKeyByRecurrentWords}phoneNumber`);
  const phoneText = t(`${translationKeyByRecurrentWords}phone`);

  const getInvalidPhoneError = () => {
    if (!updatedPhone || !updatedPhoneCountry) return t(`${i18nLanguageKey}:invalidPhoneError`);
    if (!isPhoneValid(updatedPhone))
      return `${t('recurrentWords:inputError:invalidPhone')} ${updatedPhoneCountry?.name || 'este país'}`;
    return phoneAlreadyExistsError;
  };

  const maxNumberOfCharacters = 29;
  const employeeFullName = `${firstName || ''} ${lastName || ''}`;
  const idDocumentData = getIdDocumentData(addressCountry || undefined, updatedPersonalId);

  const updateAddress = (newAddress?: IdeliveryAddress) => setUpdatedEmployeeAddress(newAddress);
  const updateAddressCountry = (newCountry?: ICountryDetail) => setAddressCountry(newCountry);
  const updatePhone = (newPhone?: string) => setUpdatedPhone(newPhone);
  const updatePhoneCountry = (newCountry?: ICountryDetail) => setUpdatedPhoneCountry(newCountry);
  const updatePersonalId = (newId?: string) => setUpdatedPersonalId(newId);

  const validatePhone = async () => {
    if (!updatedPhone || getInvalidPhoneError() || originalPhone === updatedPhone) return;
    const response = await verifyPhoneAndEmail({ phone: updatedPhone, email: '-validationNotRequired-' });
    if (response === emailAndPhoneValidationResponseTexts.phoneExists)
      setPhoneAlreadyExistsError(t(`${i18nLanguageKey}:phoneAlreadyExistsError`));
  };

  useEffect(() => {
    if (!existingEmployeeData || !existingEmployeeData?.phoneData) return;
    const newPhoneData = {
      country: updatedPhoneCountry?.code || existingEmployeeData?.phoneData?.country,
      countryId: updatedPhoneCountry?.id || existingEmployeeData?.phoneData?.countryId,
      flag: updatedPhoneCountry?.flag || existingEmployeeData?.phoneData?.flag,
      phoneCode: updatedPhoneCountry?.phoneCode || existingEmployeeData?.phoneData?.phoneCode,
      phone: updatedPhone
    };

    const newEmployeeData = {
      ...existingEmployeeData,
      address: updatedEmployeeAddress || existingEmployeeData?.address,
      phoneData: newPhoneData,
      personalId: idDocumentData?.value || '',
      country: addressCountry || existingEmployeeData?.country,
      city: updatedCity?.name || existingEmployeeData.address?.city,
      cityId: updatedCity?.id || existingEmployeeData?.cityId
    };
    updateLogisticsLocationDataCallback(newEmployeeData);
  }, [updatedEmployeeAddress, addressCountry, updatedPhone, updatedPhoneCountry, idDocumentData?.value]);

  useEffect(() => {
    if (!existingEmployeeData || addressCountry || updatedPhoneCountry) return;
    const initialAddressCountry = listOfCountries.find(
      country =>
        country.code === existingEmployeeData?.address?.country || country.code === existingEmployeeData?.country?.code
    );

    const initialPhoneCountry = listOfCountries.find(
      country => country.code === existingEmployeeData?.phoneData?.country
    );
    if (!updatedEmployeeAddress || !updatedEmployeeAddress?.address)
      setUpdatedEmployeeAddress({ ...existingEmployeeData?.address, city: existingEmployeeData.city });
    if (!updatedPhone) {
      setUpdatedPhone(existingEmployeeData?.phoneData?.phone || undefined);
      setOriginalPhone(existingEmployeeData?.phoneData?.phone || undefined);
    }
    if (!updatedPersonalId && existingEmployeeData?.personalId) setUpdatedPersonalId(existingEmployeeData?.personalId);
    if (!addressCountry && initialAddressCountry) setAddressCountry(initialAddressCountry);
    if (!updatedPhoneCountry && initialPhoneCountry) setUpdatedPhoneCountry(initialPhoneCountry);
  }, [existingEmployeeData, listOfCountries]);

  useEffect(() => {
    phoneAlreadyExistsError && setPhoneAlreadyExistsError(undefined);
  }, [updatedPhone]);

  return (
    <div className="nudosLogisticsEmployeeCard">
      <NudosLogisticServiceUserHeader
        type={type}
        place="user"
        name={employeeFullName}
        flagCountryCode={addressCountry?.code}
        photoUrl={photoProfile || ''}
      />
      <div className="cardBody">
        <div className="generalInformation">
          <h1 className="sectionTitle">{generalInformationText}</h1>
          <div className="fieldLine firstLine">
            <div className="field fullname">
              <div className="fieldTitle">{`${nameText} ${and} ${surnameText}`}</div>
              <NudosHoverText onlyIfTruncated text={employeeFullName} charactersLimit={maxNumberOfCharacters} />
            </div>
            <div className="field email">
              <div className="fieldTitle">{corporateEmailText}</div>
              <NudosHoverText onlyIfTruncated text={mail || ''} charactersLimit={maxNumberOfCharacters} />
            </div>
          </div>
          <div className="fieldLine secondLine">
            <BordPhoneInput
              componentSize="w240"
              label={`${phoneNumberText}*`}
              placeholder={phoneText}
              defaultCountryData={updatedPhoneCountry}
              defaultPhoneNumber={updatedPhone}
              errorText={getInvalidPhoneError()}
              handlePhoneChange={updatePhone}
              handleBlur={validatePhone}
              countriesList={listOfCountries || []}
              handleCountryPhoneChange={updatePhoneCountry}
            />
            <BordTextInput
              style={{ minWidth: '24rem', width: '24rem' }}
              className="idDocumentClassName"
              label={idDocumentData?.label}
              placeholder={idDocumentData?.placeholder}
              isFilled={!!idDocumentData?.value}
              setCurrentText={updatePersonalId}
              currentText={idDocumentData?.value}
              errorText={idDocumentData?.errorText}
              caption={idDocumentData?.caption}
            />
          </div>
        </div>
        <NudosLogisticsCardAddressForm
          cityUpdaterCallback={setUpdatedCity}
          addressUpdaterCallback={updateAddress}
          addressCountryUpdaterCallback={updateAddressCountry}
          listOfCountries={listOfCountries || []}
          initialAddressData={updatedEmployeeAddress}
          currentCity={updatedCity}
          initialAddressCountry={addressCountry}
          disableCountryUpdating={disableCountryUpdating}
          cityId={existingEmployeeData?.cityId || undefined}
        />
      </div>
    </div>
  );
};

export default NudosLogisticsEmployeeCard;

interface InudosLogisticsEmployeeCardProps {
  type: 'origin' | 'destination';
  existingEmployeeData?: Iemployee;
  listOfCountries: ICountryDetail[];
  updateLogisticsLocationDataCallback: (newOriginData: Iemployee) => void;
  disableCountryUpdating?: boolean;
  veryfyDestinationEmployeeData?: (isComplete: boolean) => void;
}
