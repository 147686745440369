import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NudosGenericSearchBar from '../NudosGenericSearchBar';
import useDebounce from '../../../hooks/useDebounce';
import { getEmployeesListing } from '../../../services/employees.service';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { Iemployee } from '../../../types/global';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import NudosHoverText from '../NudosHoverText/NudosHoverText';
import { isEmployeeDataComplete } from '../../../utils/productDefinitions';
import { IconWarning } from '../../../assets/DesignSystem/SVGComponents';
import { ICartShipping } from '../../../types/cart';
import { BordButton, BordOneToneIcon, BordPhoto } from '../../BordDesignSystem';

import './NudosEmployeeSearchBar.scss';

/**
 * @property { number } customWidth - Required, the width of the component (in pixels)
 * @property { React.Dispatch<React.SetStateAction<Iemployee | undefined>> } setSelectedEmployee - A state updateder function for setting the selected employee
 * @property { Iemployee } selectedEmployee - The currently selected employee
 * @property { string } customPlaceholder - A custom placeholder to show in the search input, default is "Buscar"
 * @property { string } label - The label for the employee search bar. If no label is passed only the searchbar will be displayed
 * @property { number } customHeight - the desired height of the component (in pixels), default is 28px
 * @property { number } customNameCharacterLimit - the limit of characters after which the employee name will be truncated, default is 15
 * @property { boolean } showAddressData - a boolean indicating if the employees address data should be displayed in both the closed search bar, after being selected, and the employees options of the open search bar
 * @property { string } customShowAddressDataButtonTextCompleteEmployee - a string, default is employeeAddress ? 'Ver' : 'Ver - Sin dirección'. Use this property along with customShowAddressDataButtonTextIncompleteEmployee for situations where the seeAddressCallback executes when the employee data is incomplete.
 * @property { string } customShowAddressDataButtonTextIncompleteEmployee - a string, default is employeeAddress ? 'Ver' : 'Ver - Sin dirección'. Use this property along with customShowAddressDataButtonTextCompleteEmployee for situations where the seeAddressCallback executes when the employee data is incomplete.
 * @property { (seeAddressCallback) => void } seeAddressCallback - a function to execute upon clicking the see address button on the address data of the employees options or the selected employee option
 * @property { number } addressCharacterLimit - the limit of characters after which the employee address will be truncated in the closed searchbar, after the address has already been selected, default is 20
 * @property { string[] } countriesFilter - An array of country names to which the employees in the select must belong
 * @property { IEmployeeSearchbarOriginDataForRedirection } originDataForRedirection - An object with the require properties for the navigation between the view where the NudosEmployeeSearchBar is located and the create employee module
 * @property { string } customClassName - a classname for custom styles
 * @property { string } uniqueIdentifier - an string identifying this component instance to use in navigation logic
 */
const NudosEmployeeSearchBar = ({
  customWidth,
  setSelectedEmployee,
  selectedEmployee,
  customPlaceholder,
  label,
  customHeight,
  customNameCharacterLimit,
  showAddressData,
  customShowAddressDataButtonTextCompleteEmployee,
  customShowAddressDataButtonTextIncompleteEmployee,
  seeAddressCallback,
  countriesFilter,
  excludeEmployeesWithoutLocation,
  originDataForRedirection,
  customClassName,
  uniqueIdentifier
}: InudosEmployeeSearchBarProps) => {
  const { t } = useTranslation();
  const orgData = getOrgData();
  const { state }: { state: InudosEmployeeSearchBarNavigationState } = useLocation();
  const { newEmployeeUserId, uniqueEmployeeSearchbarIdentifier } = state || {};

  const [employeeNameSearchString, setEmployeeNameSearchString] = useState('');
  const [showEmployeeSelect, setShowEmployeeSelect] = useState(false);
  const [searchedEmployeeNotFound, setSearchedEmployeeNotFound] = useState('');
  const [employeesList, setEmployeesList] = useState<Iemployee[]>();
  const [loading, setLoading] = useState(false);

  const debouncedEmployeeNameSearchString = useDebounce(employeeNameSearchString, 500);
  const translationKey = 'designSystemComponents:referenceOptions:';
  const openSelectStyles = showEmployeeSelect ? 'open' : '';

  const countriesFilterParam = countriesFilter
    ? `?filters=[{"name":"country","includes":"in","values":${JSON.stringify(countriesFilter)}}] `
    : undefined;

  const getEmployeesData = async (nameSearchParam?: string) => {
    setLoading(true);
    try {
      const dataEmployees = await getEmployeesListing(orgData.organizationId, countriesFilterParam, nameSearchParam);
      setEmployeesList(dataEmployees);
      if (dataEmployees.length === 0 && nameSearchParam)
        setSearchedEmployeeNotFound(t(`${translationKey}noEmployeesFound`));
      if (dataEmployees.length === 0 && !nameSearchParam && countriesFilterParam)
        setSearchedEmployeeNotFound(t(`${translationKey}noEmployeesFoundInCountry`));
      if (dataEmployees.length === 0 && !nameSearchParam && !countriesFilterParam)
        setSearchedEmployeeNotFound(t(`${translationKey}noEmployeesCreated`));
    } catch {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const selectedEmployeeFullName = `${selectedEmployee?.firstName || ''} ${selectedEmployee?.lastName || ''}`;

  const sizeStyles = customWidth || customHeight ? { width: customWidth, height: customHeight } : undefined;
  const activeStyles = selectedEmployee ? 'active' : '';

  const handleDeleteEmployeeSelection = () => {
    setEmployeeNameSearchString('');
    setSelectedEmployee(undefined);
    setShowEmployeeSelect(false);
  };

  const formEmployeeOption = (employee: Iemployee) => {
    const employeeFullName = `${employee?.firstName || ''} ${employee?.lastName || ''}`;
    const employeeAddress = employee?.address?.address;
    const noLocation = !employee?.locationId && excludeEmployeesWithoutLocation ? 'noLocation' : '';
    const seeAddressButtonText = employeeAddress ? 'Ver' : 'Ver - Sin dirección';
    const customizedSeeAddressText = isEmployeeDataComplete(employee)
      ? customShowAddressDataButtonTextCompleteEmployee
      : customShowAddressDataButtonTextIncompleteEmployee;
    const employeeDataComplete = isEmployeeDataComplete(employee);
    const handleSeeAddressData = () =>
      employee?.id && seeAddressCallback ? seeAddressCallback(employee.id) : undefined;
    const handleSelectEmployee = () => {
      if (noLocation && excludeEmployeesWithoutLocation) return;
      setSelectedEmployee(employee);
      if (!employeeDataComplete) {
        handleSeeAddressData();
      }
    };
    const editEmployeeRedirectionData = {
      pathname: `/nodi/employees/edit/${employee?.userId}`,
      state: {
        returnText: t(`${translationKey}${originDataForRedirection?.returnTextKey || ''}`),
        returnAction: originDataForRedirection?.origin
      }
    };
    return (
      <div className={`employeeOption ${noLocation}`} key={`employee${employee.id}`} onClick={handleSelectEmployee}>
        <div className="employeePhoto">
          <BordPhoto
            size="s20"
            avatarProps={{ variant: 'circularBordMascot', customWidth: '2rem' }}
            variant="circle"
            url={employee?.photoProfile || ''}
            imageProps={{
              style: {
                objectFit: 'cover',
                height: '100%',
                width: '100%',
                objectPosition: 'center center'
              }
            }}
          />
        </div>

        <div className={`employeeName ${showAddressData ? 'showAlsoAddress' : ''}`}>{employeeFullName}</div>
        {!!noLocation && excludeEmployeesWithoutLocation && <IconWarning className="IconWarning" />}
        {showAddressData && (
          <button className="seeAddressButton" onClick={handleSeeAddressData}>
            {customizedSeeAddressText || seeAddressButtonText}
          </button>
        )}
        {!!noLocation && excludeEmployeesWithoutLocation && (
          <div className="cantSelectTooltip">
            <div>{t(`${translationKey}cantSelectEmployee`)}</div>
            <Link className="completeEmployeeData" to={editEmployeeRedirectionData}>
              {t(`${translationKey}completeEmployeeRedirection`)}
            </Link>
          </div>
        )}
      </div>
    );
  };

  const getEmployeesOptions = () => {
    if (!employeesList?.length) return;
    const employeeOptions = employeesList.map(employee => {
      return formEmployeeOption(employee);
    });
    employeeOptions.push(<div key="marginAtBottom" className="marginAtBottom w-full h-55" />);
    return employeeOptions;
  };

  const employeesOptions = getEmployeesOptions();

  const createEmployeeRedirectionObject = {
    pathname: `/nodi/employees/create`,
    state: {
      originText: t(`${translationKey}${originDataForRedirection?.returnTextKey || ''}`),
      originUrl: originDataForRedirection?.origin,
      referenceInitialData: originDataForRedirection?.referenceInitialData,
      createFromSpecificCountry: originDataForRedirection?.createFromSpecificCountry,
      requireAllFields: true,
      uniqueEmployeeSearchbarIdentifier: originDataForRedirection?.uniqueEmployeeSearchbarIdentifier
    }
  };

  const noEmployeesFoundMessage = (
    <div key="noEmployeesFoundText" className="noEmployeesFoundText">
      {searchedEmployeeNotFound}
    </div>
  );

  const createNewEmployeeButton = (
    <Link className="createNewEmployeeButton" to={createEmployeeRedirectionObject}>
      <BordButton
        modeButton="tertiary"
        label={t(`${translationKey}createEmploye`)}
        customClassName="createEmployeeButton"
        customFontSize="1.2rem"
      />
    </Link>
  );

  useEffect(() => {
    getEmployeesData(debouncedEmployeeNameSearchString);
  }, [debouncedEmployeeNameSearchString]);

  useEffect(() => {
    if (!showEmployeeSelect && !selectedEmployee) setEmployeeNameSearchString('');
  }, [showEmployeeSelect]);

  useEffect(() => {
    // This effect handles the preselection of a newly created employee when the user goes from the employee search bar directly to the create employee module and returns
    const sameSearchbar =
      !!uniqueEmployeeSearchbarIdentifier &&
      !!uniqueIdentifier &&
      uniqueEmployeeSearchbarIdentifier === uniqueIdentifier;
    if (!newEmployeeUserId || !employeesList?.length || !sameSearchbar) return;
    window.history.replaceState({}, '');
    const newEmployee = employeesList?.find(employee => employee?.userId === newEmployeeUserId);
    if (!newEmployee) return;
    const validCountry = countriesFilter ? countriesFilter?.includes(newEmployee?.country?.name || '') : true;
    if (!validCountry) {
      return displayErrorNotification({ customJSXMessage: <>{t(`${translationKey}:wrongCountryAlert`)}</> });
    }
    setSelectedEmployee(newEmployee);
  }, [newEmployeeUserId, uniqueEmployeeSearchbarIdentifier, employeesList]);

  return (
    <div className={`nudosEmployeeSearchBar ${activeStyles} ${customClassName || ''}`}>
      {label && <div className="label">{label}</div>}
      {selectedEmployee && (
        <div className="closedEmployeeSearchBar" style={sizeStyles}>
          <div className="employeePhoto">
            <BordPhoto
              size="s20"
              avatarProps={{ variant: 'circularBordMascot', customWidth: '2rem' }}
              variant="circle"
              url={selectedEmployee?.photoProfile || ''}
              imageProps={{
                style: {
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%',
                  objectPosition: 'center center'
                }
              }}
            />
          </div>
          <NudosHoverText
            customClassName="employeeName"
            onlyIfTruncated
            text={selectedEmployeeFullName}
            charactersLimit={customNameCharacterLimit || 20}
          />
          <div className="deleteSquare" onClick={handleDeleteEmployeeSelection}>
            <BordOneToneIcon variant="x" standardSize={14} />
          </div>
        </div>
      )}
      {!selectedEmployee && (
        <NudosGenericSearchBar
          customClassName={`openEmployeeSearchBar ${openSelectStyles}`}
          searchString={employeeNameSearchString}
          setSearchString={setEmployeeNameSearchString}
          setIsSelectOpen={setShowEmployeeSelect}
          isSelectOpen={showEmployeeSelect}
          customPlaceholder={customPlaceholder}
          customWidth={`${customWidth}px`}
          customHeight={`${customHeight}px`}
          searchedOptions={employeesOptions}
          handleChangeSelectedOption={setSelectedEmployee}
          noResultsMessage={noEmployeesFoundMessage}
          absolutePositionElement={createNewEmployeeButton}
          isLoading={loading}
          showSelectOptionsOnFocus
        />
      )}
    </div>
  );
};

export default NudosEmployeeSearchBar;

interface InudosEmployeeSearchBarProps {
  customWidth: number;
  setSelectedEmployee:
    | React.Dispatch<React.SetStateAction<Iemployee | undefined>>
    | ((selectedEmployee?: Iemployee) => void);
  selectedEmployee?: Iemployee;
  customPlaceholder?: string;
  label?: string;
  customHeight?: number;
  customNameCharacterLimit?: number;
  showAddressData?: boolean;
  customShowAddressDataButtonTextCompleteEmployee?: string;
  customShowAddressDataButtonTextIncompleteEmployee?: string;
  seeAddressCallback?: (employeeId?: number | string) => void;
  addressCharacterLimit?: number;
  countriesFilter?: string[];
  excludeEmployeesWithoutLocation?: boolean;
  originDataForRedirection?: IEmployeeSearchbarOriginDataForRedirection;
  customClassName?: string;
  uniqueIdentifier?: string;
}

interface IEmployeeSearchbarOriginDataForRedirection {
  origin: string;
  returnTextKey: string;
  referenceInitialData?: ICartShipping;
  createFromSpecificCountry?: string;
  uniqueEmployeeSearchbarIdentifier?: string;
}

interface InudosEmployeeSearchBarNavigationState {
  newEmployeeUserId?: number;
  uniqueEmployeeSearchbarIdentifier?: string;
}
