import { FC } from 'react';
import { InudoInfo, LOCATION_PLACE_TYPE } from '../../../../../types/global';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import {
  IconBuilding,
  IconEmployee,
  IconPin,
  IconReserveBox,
  IconShield,
  IconToolWrench
} from '../../../../../assets/DesignSystem/SVGComponents';
import { useHistory } from 'react-router-dom';
import LoaderProductLocationCard from './LoaderProductLocationCard';
import { NudosRedirectionIcon } from '../../../../../components/DesignSystem';
import { formatShortDate } from '../../../../../utils/formatDatesAndHours';
import BordStatus from '../../../../../components/BordDesignSystem/BordStatus/BordStatus';
import { BordOneToneIcon, BordPhoto } from '../../../../../components/BordDesignSystem';
import { renderVarianStatus } from '../../constants';
import { statusVariants } from '../../../../../components/BordDesignSystem/BordStatus/BordStatus.type';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import { capitalize } from '../../../../../utils/unCamelCase';
import { getLocationIcon } from '../../../../../utils/global.utils';
import { truncateText } from '../../../../../utils/truncateText';
import './ProductLocationCard.scss';

interface IProductLocationCard {
  dataLocationCard?: InudoInfo;
  generalLoading?: boolean;
}

const ProductLocationCard: FC<IProductLocationCard> = ({ dataLocationCard, generalLoading }: IProductLocationCard) => {
  const { push } = useHistory();

  const isBuyAndHold = !!dataLocationCard?.isBuyAndHold;
  const nudosToolLocationText = isBuyAndHold ? 'Buy and hold' : 'Almacenamiento';
  const currentAssignment = dataLocationCard?.currentAssignment ? dataLocationCard?.currentAssignment[0] : undefined;
  const lastLocationData = dataLocationCard?.oldAssignment?.find(location => !location?.exitDate);
  const entryDate = formatShortDate(currentAssignment?.entryDate || '')?.onlyDate;
  const lastLocationEntryDate = entryDate || formatShortDate(lastLocationData?.entryDate || '')?.onlyDate || '-';
  const currentMovementLogisticsId = currentAssignment?.logisticServiceId || lastLocationData?.logisticServiceId;
  const currentLocationMovementHadLogistics =
    currentMovementLogisticsId && currentMovementLogisticsId === dataLocationCard?.logisticServiceId;
  const currentLocationMovementLogisticsStatus = currentLocationMovementHadLogistics
    ? dataLocationCard?.logisticStatus
    : undefined;
  const limitOfAddressDetailsText = 78;

  const generateDetailData = (place?: TstoragePlace) => {
    switch (place) {
      case 'user':
        return {
          profileImg: currentAssignment?.photoProfile || '',
          icon: <IconEmployee style={{ transform: 'scale(.75)' }} />,
          name: dataLocationCard?.assignedTo,
          countryName: dataLocationCard?.country?.name || '',
          addressDetails: dataLocationCard?.address,
          dateTitle: 'Onboarding: '
        };
      case 'nudos':
        return {
          icon: <IconReserveBox />,
          name: '.bord Warehouse',
          countryName: dataLocationCard?.country?.name || ''
        };
      case 'office':
        return {
          icon: <IconBuilding style={{ transform: 'scale(0.8)' }} />,
          name: `Oficinas ${dataLocationCard?.country?.name}`,
          countryName: dataLocationCard?.country?.name || '',
          addressDetails: dataLocationCard?.address
        };
      case 'other':
        return {
          icon: <IconPin style={{ transform: 'scale(0.56)' }} />,
          name: dataLocationCard?.locationName || 'Otra dirección',
          countryName: dataLocationCard?.country?.name || '',
          addressDetails: dataLocationCard?.address
        };
      case 'warranty':
        return {
          icon: <IconShield style={{ transform: 'scale(1.13)' }} />,
          name: 'Garantia con la marca',
          countryName: dataLocationCard?.country?.name || ''
        };
      case 'diagnostic':
        return {
          name: 'Diagnóstico',
          icon: <IconToolWrench style={{ transform: 'scale(1.13)' }} />,
          countryName: dataLocationCard?.country?.name || ''
        };
      default:
        return { icon: <IconPin style={{ transform: 'scale(0.56)' }} /> };
    }
  };

  const redirectToEmployeeDetails = {
    pathname: `/nodi/employees/details/${dataLocationCard?.userId}`,
    state: {
      originUrl: `/nodi/details/${dataLocationCard?.productId}`,
      returnText: 'Herramienta'
    }
  };

  const detailData = generateDetailData(dataLocationCard?.place);

  const urlUserImage = generateDetailData(dataLocationCard?.place ?? '')?.profileImg;

  const renderProfileUser = (
    <BordPhoto
      url={urlUserImage}
      oneToneIconProps={{
        variant: getLocationIcon({
          place: (dataLocationCard?.place as LOCATION_PLACE_TYPE) ?? LOCATION_PLACE_TYPE.NUDOS
        }),
        standardSize: 24
      }}
      flagProps={{
        variant: 'circle',
        country: (dataLocationCard?.country?.code ?? '') as TcountryCode,
        standardSize: 20
      }}
      size="s48"
      style={{
        backgroundColor: urlUserImage ? '' : '#262B39'
      }}
    />
  );

  if (generalLoading) return <LoaderProductLocationCard />;

  return (
    <div id="productLocationCard">
      <div className="titleLocationCard">Asignado / ubicado:</div>
      {currentLocationMovementHadLogistics && (
        <div className="flex items-center gap-4 mt-12">
          <div className="nameSL">
            <div className="title">Servicio logístico</div>
          </div>

          <BordStatus
            variant={
              renderVarianStatus({ variant: dataLocationCard?.logisticStatus as statusVariants }) as statusVariants
            }
            renderJSX={
              <div
                className="customBordStatusContainer"
                onClick={() => {
                  push(
                    `/nodi/logistics-services/details/${currentMovementLogisticsId}?from=tools&id=${dataLocationCard?.productId}`
                  );
                }}
              >
                <span className="text-bord-sm font-normal">
                  {capitalize(currentLocationMovementLogisticsStatus ?? '')}
                </span>
                <div className="w-1 h-16 bg-stroke"></div>
                <span className="text-bord-caption">Ir</span>
                <BordOneToneIcon variant="arrowRight" standardSize={12} />
              </div>
            }
          />
        </div>
      )}
      <div className="boxProductLocationCard">
        <div className="containerLocationCard">
          <div className="detailsContainerProduct">
            <div className="flex items-center gap-20 w-full">
              {renderProfileUser}
              <div className="contaierLocationName">
                <div className="centerItems">
                  <div className="locationNameContainer">
                    <div className="locationNameText">{detailData?.name}</div>
                    {dataLocationCard?.place === 'user' && (
                      <NudosRedirectionIcon redirectionDataObject={redirectToEmployeeDetails} />
                    )}
                  </div>
                  <div className="movementDates">
                    <span className="dateTitle">{detailData?.dateTitle || 'Ingreso: '}</span>
                    <span className="date">{lastLocationEntryDate}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="containerAddress">
              <div className="centerItems">
                <div className="countryName">{detailData?.countryName}</div>
                {detailData?.addressDetails && (
                  <div className="detailAddress">
                    {truncateText(detailData?.addressDetails, limitOfAddressDetailsText)}
                  </div>
                )}
              </div>
            </div>
            {dataLocationCard?.place === 'nudos' && <div className="nudosLocationText">{nudosToolLocationText}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductLocationCard;
