import { InudoInfo, LOCATION_PLACE_TYPE } from '../../../../../../../types/global';
import ToolConditionDropdown from '../ToolConditionDropdown/ToolConditionDropdown';
import ToolStatusAdditionalServicesSection from '../ToolStatusAdditionalServicesSection/ToolStatusAdditionalServicesSection';
import './ToolStatusSection.scss';

const ToolStatusSection = ({ toolData }: { toolData?: InudoInfo }) => {
  const { logisticAdditionalServices, productId, productCondition, oldAssignment, place, destination } = toolData || {};

  const toolLocationHistory = oldAssignment || [];
  const currentLocationIsWarehouse = place === LOCATION_PLACE_TYPE.NUDOS;
  const activeServiceDestinationIsWarehouse = destination?.place === LOCATION_PLACE_TYPE.NUDOS;
  const hasBeenInWarehouseBefore = toolLocationHistory.some(location => location?.place === LOCATION_PLACE_TYPE.NUDOS);
  const hasBeenInWarehouse =
    hasBeenInWarehouseBefore || currentLocationIsWarehouse || activeServiceDestinationIsWarehouse;

  return (
    <div className="toolStatusSection">
      <div className="title">Estado de la herramienta</div>
      <div className="statusSectionContent">
        <div className="toolStatusSubsection">
          <div className="statusSectionSubtitle">Estado físico</div>
          <ToolConditionDropdown productId={productId} currentCondition={productCondition} />
        </div>
        <div className="sectionDivision" />
        <ToolStatusAdditionalServicesSection
          logisticAdditionalServices={logisticAdditionalServices || []}
          hasBeenInWarehouse={hasBeenInWarehouse}
        />
      </div>
    </div>
  );
};

export default ToolStatusSection;
