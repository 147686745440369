const LoaderPersonal = () => {
  return (
    <div id="personalForm">
      <div className="titleFormAccount">Información personal</div>
      <div className="profileSectionSkeleton">
        <div className="profileSectionPhotoSkeleton animationLoader" />
        <div>
          <div className="profileSectionTitleSkeleton animationLoader" />
          <div className="profileSectionSubTitleSkeleton animationLoader" />
        </div>
      </div>
      <div className="firstSection">
        <div className="nameInput">
          <div className="textInputSkeleton">Nombre</div>
          <div className="w-full h-38 animationLoader rounded-md" />
        </div>
        <div className="nameInput">
          <div className="textInputSkeleton">Apellido</div>
          <div className="w-full h-38 animationLoader rounded-md" />
        </div>
        <div className="nameInput">
          <div className="textInputSkeleton">Teléfono</div>
          <div className="w-full h-38 animationLoader rounded-md" />
        </div>
      </div>
      <div className="flex justify-end">
        <div className="inputSkeleton animationLoader" />
      </div>
      <div className="lineForm" />
      <div>
        <div className="titleFormAccount mt-6">Idioma y región</div>
        <div className="nameInput mt-16">
          <div className="textInputSkeleton">Idioma de la plataforma</div>
          <div className="inputSkeleton animationLoader" />
        </div>
      </div>
      <div className="lineForm" />
      <div className="titleFormAccount mt-6">Información de acceso</div>
      <div className="secondSectionSkeleton mt-16">
        <div className="nameInput">
          <div className="textInputSkeleton">Correo de ingreso</div>
          <div className="inputSkeleton animationLoader" />
        </div>
        <div className="flex items-center gap-4">
          <div className="textInputSkeleton">Cambiar contraseña</div>
          <div className="animationLoader w-12 h-12 rounded-full"></div>
        </div>
      </div>
      <div className="lineForm" />
      <div className="titleFormAccount mt-6">Políticas del aplicativo</div>
      <div className="buttonTermsSkeleton animationLoader" />
    </div>
  );
};

export default LoaderPersonal;
