const RoundQatar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#751A46"
          d="M31.9999 16C31.9999 7.1635 24.8364 0 15.9999 0C12.8699 0 9.95024 0.899687 7.48412 2.45319L10.9912 3.94325L6.26074 5.95312L10.9912 7.96294L6.26074 9.97269L10.9912 11.9824L6.26074 13.9918L10.9912 16.0011L6.26074 18.0109L10.9912 20.0205L6.26074 22.0299L10.9912 24.0396L6.26074 26.0493L10.9912 28.0588L7.48568 29.548C9.95149 31.1007 12.8705 32 15.9999 32C24.8364 32 31.9999 24.8365 31.9999 16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundQatar;
