import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { BordButton } from '../../../../../components/BordDesignSystem';
import { ListDescription, ProductDescription } from '../../../../../components/uploadExternalProducts';
import useLoadTools from '../../../../../state/useStateSuccessLoadTools';
import { segmentTrackEvent } from '../../../../../utils/segment';
import './Step3UploadTools.scss';

interface ISteep3 {
  updateExteralTools: () => void;
}

const Step3UploadTools: FC<ISteep3> = ({ updateExteralTools }) => {
  const { t } = useTranslation();
  const { setSelectedReferences, selectedReferences } = useLoadTools();

  useEffect(() => {
    segmentTrackEvent({ toolsConfirmInformationView: null });
  }, []);

  return (
    <div className="step3UploadTools">
      <div className="boxStep3">
        <div className="bodyItems">
          {selectedReferences &&
            selectedReferences.length > 0 &&
            selectedReferences.map(reference => {
              return (
                <div className="containerItems" key={uuid()}>
                  <ProductDescription
                    customHeaderDescriptionClass="ml-0"
                    name={reference.name}
                    sku={reference.sku}
                    pillsDataList={reference.pills}
                    nameCharacterLimit={70}
                    limitSku={40}
                  />
                  {reference &&
                    reference?.individualToolsData &&
                    reference.individualToolsData.length > 0 &&
                    reference.individualToolsData.map((item, index) => {
                      const itemLocationOrAssignment =
                        item?.assignedEmployee?.firstName && item?.assignedEmployee?.lastName
                          ? `${item?.assignedEmployee?.firstName} ${item?.assignedEmployee?.lastName}`
                          : item?.country?.name || undefined;
                      return (
                        <ListDescription
                          key={uuid()}
                          index={index + 1}
                          state={item?.productCondition}
                          serial={item?.serial}
                          itemLocationOrAssignment={
                            item.otherAddressDetail
                              ? item.otherAddressDetail?.locationName || ''
                              : item.optionId === 1
                              ? `Oficinas ${itemLocationOrAssignment}`
                              : itemLocationOrAssignment
                          }
                        />
                      );
                    })}
                </div>
              );
            })}
        </div>
      </div>
      <div className="containerStep2Button">
        <BordButton
          customWidth="w-280"
          customHeight="42"
          onClick={() => {
            setSelectedReferences(selectedReferences);
            updateExteralTools();
          }}
          label={t('nodi:UploadExternalToolsModule:uploadToolSteps:nudosButton')}
        />
      </div>
    </div>
  );
};

export default Step3UploadTools;
