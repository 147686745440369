const RoundTanzania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="black"
          d="M4.68627 27.3138C5.35746 27.985 6.07359 28.5835 6.82477 29.1105L29.1104 6.82484C28.5835 6.07365 27.9849 5.35752 27.3137 4.68634C26.6425 4.01515 25.9264 3.41665 25.1752 2.88965L2.88965 25.1753C3.41652 25.9263 4.01515 26.6425 4.68627 27.3138Z"
        />
        <path
          fill="#6DA544"
          d="M4.68661 4.68653C-0.361017 9.73428 -1.33008 17.315 1.77761 23.3364L23.3365 1.77759C17.315 -1.33009 9.7343 -0.360968 4.68661 4.68653Z"
        />
        <path
          fill="#3ECBF8"
          d="M27.3139 27.3137C32.3615 22.2661 33.3305 14.6853 30.2229 8.66382L8.66406 30.2227C14.6854 33.3304 22.2662 32.3613 27.3139 27.3137Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTanzania;
