const RoundIraq = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="black"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#496E2D"
          d="M12.1742 14.9566C12.1654 14.9566 12.1569 14.9572 12.1481 14.9573V14.9566H9.08955C9.24462 14.3575 9.78824 13.9131 10.4351 13.9131V11.8262C8.51712 11.8262 6.9568 13.3865 6.9568 15.3044V17.0088 17.0436H12.1481 12.1742C12.366 17.0436 12.522 17.1997 12.522 17.3914V18.087H5.56543V20.174H14.6089V17.3914C14.6089 16.0489 13.5167 14.9566 12.1742 14.9566ZM17.3916 18.087V11.8262H15.3047V20.174H18.7829V18.087H17.3916ZM24.3481 18.0871V11.8262H22.2611V18.0871H21.5655V16.0002H19.4785V20.1741H25.7394V18.0871H24.3481Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIraq;
