const RoundAnguilla = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16C0 16.0038 16 .00175 16 0c8.8365 0 16 7.1635 16 16Z"
        />
        <path fill="#fff" d="M15.9572 16H16v-.0428L15.9572 16Z" />
        <path
          fill="#fff"
          d="M16 8.34781V0h-.0027C7.16194.0015 0 7.16431 0 16h8.34781v-4.7007L13.0485 16h2.9087c.0143-.0142.0286-.0285.0429-.0428v-2.9086l-4.7009-4.70079H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09469 2.08691C5.59226 3.51185 3.51182 5.59223 2.08694 8.09466V16h4.17394V6.26085H16V2.08691H8.09469Z"
        />
        <path fill="#F93939" d="m16 14.0325-5.6847-5.6846H8.34781v.00013L15.9999 16H16v-1.9675Z" />
        <path
          fill="#F3F3F3"
          d="M27.0587 18.3421c.4601-.633.7674-1.406.7674-2.3421V9.04346c-.5814.43675-1.3039.69563-2.087.69563-1.1378 0-2.148-.54644-2.7826-1.39119-.6346.84475-1.6448 1.39119-2.7826 1.39119-.7831 0-1.5056-.25894-2.087-.6955V16c0 .9361.3074 1.7091.7674 2.3421h8.2044Z"
        />
        <path
          fill="#FF9811"
          d="M25.6115 14.7094c.2215-.5404.3938-1.3302.3938-1.7232 0-.6316-.8234-1.1435-.8234-1.1435s-.8234.5119-.8234 1.1435c0 .3931.1724 1.1829.3937 1.7232l-.4753 1.0732c.2794.1136.5848.1767.9051.1767.3203 0 .6256-.0631.905-.1767l-.4755-1.0732ZM22.3934 11.2344c-.5785.0784-1.3487.3244-1.6891.5209-.5469.3158-.5787 1.2847-.5787 1.2847s.8551.4571 1.402.1413c.3405-.1965.9383-.7405 1.2953-1.2025l1.1673-.125c-.0413-.2987-.1396-.5947-.2995-.872-.1603-.2774-.3675-.5104-.6056-.6955l-.6917.9481ZM20.9932 15.7591c.3571.4617.955 1.0057 1.2955 1.2023.547.3158 1.402-.1412 1.402-.1412s-.0318-.969-.5787-1.2849c-.3405-.1965-1.1106-.4422-1.6892-.5205l-.6917-.9483c-.2381.1852-.4453.4183-.6055.6953-.1601.2775-.2581.5734-.2996.8722l1.1672.1251Z"
        />
        <path
          fill="#3ECBF8"
          d="M18.6823 18.0872c1.2992 2.0846 4.2743 2.7825 4.2743 2.7825s2.975-.6979 4.2743-2.7825h-8.5486Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAnguilla;
