const SquareUzbekistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path fill="#00B731" fillRule="evenodd" d="M0 12H22V16H0V12Z" clipRule="evenodd" />
          <path fill="#03BCDB" fillRule="evenodd" d="M0 0H22V4H0V0Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M0 5.44444H22V11H0V5.44444ZM5.2381 4.23889C5.07467 4.3 4.89762 4.33333 4.71429 4.33333C3.84686 4.33333 3.14286 3.58667 3.14286 2.66667C3.14286 1.74667 3.84686 1 4.71429 1C4.89762 1 5.07467 1.03333 5.2381 1.09444C4.62733 1.32333 4.19048 1.94111 4.19048 2.66667C4.19048 3.39222 4.62733 4.01111 5.2381 4.23889Z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M6 2H7.2V3H6V2ZM7.2 1H8.4V2H7.2V1ZM8.4 2H9.6V3H8.4V2ZM9.6 1H10.8V2H9.6V1ZM10.8 2H12V3H10.8V2ZM9.6 3H10.8V4H9.6V3ZM7.2 3H8.4V4H7.2V3Z"
            clipRule="evenodd"
            opacity="0.5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareUzbekistan;
