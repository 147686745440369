import { IadditionalServiceDataForToolLogistics } from '../../../../../types/requestLogisticsModule';
import AdditionalServiceStatusDataTable from './AdditionalServiceStatusDataTable';
import './NudosAdditionalServiceStatusModal.scss';

const AdditionalServiceStatusModalContent = ({
  additionalServiceData
}: {
  additionalServiceData: IadditionalServiceDataForToolLogistics;
}) => {
  return (
    <div className="additionalServiceStatusModalContent">
      <div className="additionalServiceDataSection">
        <AdditionalServiceStatusDataTable additionalServiceData={additionalServiceData} />
      </div>
    </div>
  );
};

export default AdditionalServiceStatusModalContent;
