interface ICalcPercentage {
  total: number;
  percentage: number;
}

const calculatePercentage = ({ total, percentage }: ICalcPercentage) => {
  const onePercent = total / 100;
  const resultPercent = percentage * onePercent;
  return resultPercent;
};

export { calculatePercentage };
