const RoundMicronesia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#3ECBF8"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M15.9991 6.95654 16.6897 9.08229H18.9249L17.1167 10.3961 17.8073 12.5218 15.9991 11.208 14.1907 12.5218 14.8816 10.3961 13.0732 9.08229H15.3084L15.9991 6.95654ZM6.95605 16.0002 9.0818 15.3095V13.0742L10.3956 14.8826 12.5213 14.1919 11.2076 16.0002 12.5213 17.8083 10.3956 17.1176 9.0818 18.9259V16.6908L6.95605 16.0002ZM15.9992 25.0435 15.3085 22.9178H13.0732L14.8816 21.604 14.1909 19.4783 15.9992 20.792 17.8074 19.4783 17.1166 21.604 18.9249 22.9178H16.6898L15.9992 25.0435ZM25.0428 16 22.917 16.6907V18.9259L21.6032 17.1176 19.4775 17.8083 20.7913 16 19.4775 14.1918 21.6032 14.8826 22.917 13.0742V15.3094L25.0428 16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMicronesia;
