const SquareMauritania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#249F58" rx="2" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M10.9981 7.73349L9.45807 8.55802L9.75141 6.81189L8.50684 5.57456L10.2281 5.32176L10.9981 3.73242L11.767 5.32176L13.4883 5.57456L12.2416 6.81189L12.537 8.55696"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M15.678 6.33407C15.7021 6.53034 15.7147 6.73087 15.7147 6.93354C15.7147 9.58421 13.6038 11.7335 11.0004 11.7335C8.39709 11.7335 6.28613 9.58421 6.28613 6.93354C6.28613 6.73087 6.2987 6.53034 6.3228 6.33301C6.61299 8.17621 8.59613 9.60021 11.0004 9.60021C13.4047 9.60021 15.3878 8.17514 15.678 6.33301V6.33407Z"
            clipRule="evenodd"
          />
          <path fill="#AF010D" d="M0 0H22V2.13333H0zM0 13.8667H22V16.00003H0z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMauritania;
