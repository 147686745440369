import { apiGet, apiPut } from './api';
import { subscriptionAPI } from '../constants';
import {
  DTOpostManualAdjustmentAndComments,
  DTOupdateToolCondition,
  ItoolConditionSelectOption
} from '../types/tools.types';
import { AxiosError, AxiosRequestConfig } from 'axios';

export const postManualAdjustmentAndComments = async (
  locationLogId: string | number,
  body: DTOpostManualAdjustmentAndComments
) => {
  return apiPut<AxiosRequestConfig, DTOpostManualAdjustmentAndComments>(
    `${subscriptionAPI}/location-log/${locationLogId}`,
    body
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getToolConditionDropdownOptions = async () => {
  return apiGet<AxiosRequestConfig, ItoolConditionSelectOption[]>(`${subscriptionAPI}/product/product-condition/list`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateToolCondition = async (
  productId: number | string,
  body: DTOupdateToolCondition
): Promise<string> => {
  return apiPut<AxiosRequestConfig, DTOupdateToolCondition>(
    `${subscriptionAPI}/product/product-condition/update/${productId}`,
    body
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
