const RoundJamaica = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#6DA544"
          d="M25.7409 3.3075C23.0429 1.23369 19.6657 0 15.9999 0C12.334 0 8.95673 1.23375 6.25879 3.3075L15.9999 13.0485L25.7409 3.3075Z"
        />
        <path
          fill="black"
          d="M3.30744 6.25903C1.23375 8.95697 0 12.3342 0 16C0 19.6659 1.23375 23.0432 3.3075 25.7412L13.0486 16L3.30744 6.25903Z"
        />
        <path
          fill="#6DA544"
          d="M6.25879 28.6924C8.95679 30.7662 12.334 31.9999 15.9999 31.9999C19.6657 31.9999 23.0429 30.7662 25.7409 28.6924L15.9999 18.9514L6.25879 28.6924Z"
        />
        <path
          fill="black"
          d="M28.6922 25.741C30.766 23.0431 31.9997 19.6659 31.9997 16C31.9997 12.3342 30.766 8.95697 28.6922 6.25903L18.9512 16L28.6922 25.741Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundJamaica;
