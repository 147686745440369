import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordToolIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.7003 6.29998c-.1832.18693-.2858.43825-.2858.7s.1026.51307.2858.7l1.6 1.6c.187.18323.4383.28586.7.28586.2618 0 .5131-.10263.7-.28586l3.77-3.77c.5029 1.11119.6551 2.34923.4365 3.54915-.2186 1.19987-.7977 2.30467-1.6602 3.16717-.8624.8624-1.9672 1.4415-3.1671 1.6601-1.1999.2187-2.438.0664-3.5492-.4364l-6.90996 6.91c-.39782.3978-.93739.6213-1.5.6213s-1.10217-.2235-1.5-.6213c-.39782-.3978-.62132-.9374-.62132-1.5s.2235-1.1022.62132-1.5l6.90996-6.91c-.5028-1.11121-.65504-2.34925-.4364-3.54917.2186-1.19991.7977-2.30469 1.6602-3.16713.8624-.86243 1.9672-1.44155 3.1671-1.66018 1.1999-.21862 2.438-.06638 3.5491.43646l-3.77 3.77Z"
      />
    </svg>
  );
};

export default BordToolIcon;
