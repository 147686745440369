import { FC } from 'react';
import { IProductDetail } from '../../../../../types/catalogue';
import useStoreCountryState from '../../../../../state/useStoreCountryState';

import './ProductEstimatedDelivery.scss';
import { BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import useLanguagePlatformState from '../../../../../state/useLenguageState';
import { useTranslation } from 'react-i18next';

interface IProductEstimatedDelivery {
  productDetailData: IProductDetail;
}

const ProductEstimatedDelivery: FC<IProductEstimatedDelivery> = ({ productDetailData }: IProductEstimatedDelivery) => {
  const { storeCountry } = useStoreCountryState();
  const { platformLanguage } = useLanguagePlatformState();
  const { t } = useTranslation();
  const translationsEcommerce = 'ecommerce:productDetail:';
  const spanishLanguage = platformLanguage === 'esMX';
  const translationKey = 'nodi:checkout:';

  return (
    <div id="productEstimatedDelivery">
      <div className="calendarIcon">
        <BordOneToneIcon variant="calendar" standardSize={14} />
      </div>
      <div className="grayText">{t(`${translationsEcommerce}estimatedDelivery`)}</div>
      <div className="minFlagContainer">
        <div
          className="minFlagBox"
          style={{
            backgroundImage: `url("${storeCountry?.countryFlag}")`
          }}
        />
      </div>
      <div className="textDays">
        {`${storeCountry?.countryName}: ${productDetailData?.estimatedDeliveryDate?.minDeliverTime}-${
          productDetailData?.estimatedDeliveryDate?.maxDeliverTime
        } ${!spanishLanguage ? t(`${translationsEcommerce}businessDays`) : ''}  ${t(
          `${translationKey}${productDetailData?.estimatedDeliveryDate.deliveryPeriod}`
        )} ${spanishLanguage ? t(`${translationsEcommerce}businessDays`) : ''}`}
      </div>
    </div>
  );
};

export default ProductEstimatedDelivery;
