const SquareSolomonIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 16H22V0L0 16Z" clipRule="evenodd" />
          <path fill="#249F58" fillRule="evenodd" d="M2.0957 16H22.0005V2.1333L2.0957 16Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M5.36551 6.03517L4.71808 6.39997L4.8417 5.6277L4.31789 5.08157L5.04075 4.9685L5.36551 4.26663L5.69028 4.9685L6.41313 5.08157L5.88932 5.6277L6.01294 6.39997L5.36551 6.03517ZM3.27028 3.90183L2.62285 4.26663L2.74647 3.49437L2.22266 2.94823L2.94551 2.83517L3.27028 2.1333L3.59504 2.83517L4.31789 2.94823L3.79408 3.49437L3.9177 4.26663L3.27028 3.90183ZM7.46075 3.90183L6.81332 4.26663L6.93694 3.49437L6.41313 2.94823L7.13599 2.83517L7.46075 2.1333L7.78551 2.83517L8.50837 2.94823L7.98456 3.49437L8.10818 4.26663L7.46075 3.90183ZM7.46075 8.1685L6.81332 8.5333L6.93694 7.76103L6.41313 7.2149L7.13599 7.10183L7.46075 6.39997L7.78551 7.10183L8.50837 7.2149L7.98456 7.76103L8.10818 8.5333L7.46075 8.1685ZM3.27028 8.1685L2.62285 8.5333L2.74647 7.76103L2.22266 7.2149L2.94551 7.10183L3.27028 6.39997L3.59504 7.10183L4.31789 7.2149L3.79408 7.76103L3.9177 8.5333L3.27028 8.1685Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSolomonIslands;
