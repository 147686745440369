import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../../components/BordDesignSystem';
import BillingDataByCountryForm from '../BillingDataByCountryForm/BillingDataByCountryForm';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import { getOrganizationBillingDataForAllItsCountries } from '../../../../../services/account';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { DTOgetTaxationRelatedDefinitionsOptions } from '../../../../../types/checkout';
import { getListOfCountriesByOfferedService } from '../../../../../utils/getCountries';
import { ICountryDetail } from '../../../../../types/countries';
import { getBillingTaxDefinitionsOptions } from '../../../../../hooks/useBillingData';
import BillingDataByCountry from '../BillingDataByCountry/BillingDataByCountry';
import { DTOgetBillingDataByOrganizationResponse } from '../../../../../types/account';
import BordEmptyState from '../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import './OrganizationBillingData.scss';

const OrganizationBillingData = () => {
  const { t } = useTranslation();
  const [addNewCountry, setAddNewCountry] = useState(false);
  const [organizationBillingData, setOrganizationBillingData] = useState<DTOgetBillingDataByOrganizationResponse>({});
  const [loadingOrganizationBillingCountries, setLoadingOrganizationBillingCountries] = useState(false);
  const [listOfAvailableCountries, setListOfAvailableCountries] = useState<ICountryDetail[]>();
  const [taxationRelatedDefinitionsOptions, setTaxationRelatedDefinitionsOptions] =
    useState<DTOgetTaxationRelatedDefinitionsOptions>();

  const i18nLanguageKey = 'nodi:account:billingDataByCountryForm:organizationBillingData:';
  const orgData = getOrgData();
  const organizationBillingDataEntries = Object.entries(organizationBillingData);

  const someCountryHasBillingEntities = Object.values(organizationBillingData)?.some(
    countryBillingData => countryBillingData.billingEntities.length > 0
  );
  const hasExistingData = organizationBillingData && someCountryHasBillingEntities;
  const addCountryButtonText = addNewCountry
    ? t(`${i18nLanguageKey}addCountryButtonText`)
    : t(`${i18nLanguageKey}addCountryButtonTextDefault`);

  const countriesBillingDataMap = new Map(organizationBillingDataEntries);

  const availableCountries = listOfAvailableCountries?.filter(country => {
    const thisCountryBillingData = countriesBillingDataMap.get(country.code);
    return !thisCountryBillingData || !!thisCountryBillingData?.canCreateNew;
  });

  const handleClickAddNewCountryButton = () => setAddNewCountry(prevState => !prevState);

  const getOrganizationBillingData = async () => {
    if (!orgData?.organizationId) return;
    setLoadingOrganizationBillingCountries(true);
    try {
      const response = await getOrganizationBillingDataForAllItsCountries(orgData?.organizationId);
      setOrganizationBillingData(response);
      setLoadingOrganizationBillingCountries(false);
      setAddNewCountry(false);
    } catch (error) {
      displayErrorNotification();
      setLoadingOrganizationBillingCountries(false);
    }
  };

  useEffect(() => {
    getOrganizationBillingData();
    getListOfCountriesByOfferedService(setListOfAvailableCountries);
    getBillingTaxDefinitionsOptions(setTaxationRelatedDefinitionsOptions);
  }, []);
  if (!loadingOrganizationBillingCountries && !hasExistingData && !addNewCountry) {
    return (
      <div className="containerEmptyStatus">
        <BordEmptyState
          title={t(`${i18nLanguageKey}titleEmptyStatus`)}
          subTitle={t(`${i18nLanguageKey}subtitleEmptyStatus`)}
          ilustrationIconProps={{
            variant: 'device',
            customWidth: '10rem'
          }}
          buttonOneProps={{
            label: t(`${i18nLanguageKey}registerBillingData`) || '',
            buttonIconTwo: 'shoppingCart',
            onClick: handleClickAddNewCountryButton,
            customWidth: 'w-280'
          }}
        />
      </div>
    );
  }
  return (
    <div className="organizationBillingData">
      {loadingOrganizationBillingCountries ? (
        <div className="animationLoader tabTitle w-280 h-20 rounded" />
      ) : (
        <h1 className="tabTitle">{t(`${i18nLanguageKey}tabTitle`)}</h1>
      )}

      {loadingOrganizationBillingCountries && (
        <>
          <div className="addCountryButtonSkeletonLoader animationLoader" />
          {[0, 1, 2, 3].map(i => {
            return (
              <BillingDataByCountryForm key={`loader${i}`} showSkeletonLoader={loadingOrganizationBillingCountries} />
            );
          })}
        </>
      )}
      {!loadingOrganizationBillingCountries && hasExistingData && (
        <BordButton
          label={addCountryButtonText}
          onClick={handleClickAddNewCountryButton}
          buttonIconTwo="plusCircle"
          modeButton="secondary"
        />
      )}
      <div className="divider" />
      {addNewCountry && (
        <BillingDataByCountryForm
          isNewCountry={addNewCountry}
          updateDataCallback={getOrganizationBillingData}
          showSkeletonLoader={loadingOrganizationBillingCountries}
          listOfAvailableCountries={availableCountries}
          taxationRelatedDefinitionsOptions={taxationRelatedDefinitionsOptions}
        />
      )}
      {!loadingOrganizationBillingCountries &&
        hasExistingData &&
        organizationBillingDataEntries.map(([country, data]) => {
          return (
            <>
              <BillingDataByCountry
                key={country}
                countryBillingData={data}
                getOrganizationBillingData={getOrganizationBillingData}
                taxationRelatedDefinitionsOptions={taxationRelatedDefinitionsOptions}
                loadingOrganizationBillingCountries={loadingOrganizationBillingCountries}
              />
            </>
          );
        })}
      <div className="whiteSpaceAtTheBottom w-10 h-10" />
    </div>
  );
};

export default OrganizationBillingData;
