import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import useEcommerceControler from '../views/ecommerce/ecommerce.controller';
import { adminRoutes } from './routes';

const switchRoutes = (
  <Switch>
    {adminRoutes.map((prop, key) => {
      if (prop.layout === 'state') {
        return <Route path={`/${prop.layout}${prop.path}`} component={prop.component} key={key} exact />;
      }
      return null;
    })}
  </Switch>
);

const StatusRegister = () => {
  const { user } = useEcommerceControler();
  const testing = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', testing);
    return window.removeEventListener('beforeunload', testing);
  }, []);
  return <div className="approvalStatusLayout">{user && switchRoutes}</div>;
};

export default StatusRegister;
