import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserInfo } from '@firebase/auth';
import { BordButton, BordTextInput } from '../../../../../../components/BordDesignSystem';
import { NudosBreadcrumbButton } from '../../../../../../components/NudosComponents';
import useLogin from '../../../../../../hooks/useLogin';
import { segmentTrackEvent } from '../../../../../../utils/segment';
import { ICountryDetail } from '../../../../../../types/countries';
import BordPasswordInput from '../../../../../../components/BordDesignSystem/BordPaswordInput/BordPasswordInput';
import useSignStore from '../../../../state/useSignStore';
import {
  getEmailError,
  getPasswordConfirmationError,
  getPasswordError,
  getNewRegisteredData
} from '../../../../signUp.utils';
import TermsAndConditionsCheckmark from '../../../TermsAndConditionsCheckmark/TermsAndConditionsCheckmark';
import createFirebaseUser from '../../../../../../utils/createUser';
import { postOfSignUp } from '../../../../../../services/signUp.service';
import { displayErrorNotification } from '../../../../../../utils/displayNudosStandardNotifications';

const SignUpFormStep3 = ({ setFormStep }: IsignUpFormStep3Params) => {
  const { t } = useTranslation();
  const { loginEmailAndPassWord } = useLogin();
  const {
    userEmail,
    userPhoneCountry,
    userPassword,
    userPasswordConfirmation,
    setUserEmail,
    setUserPassword,
    setUserPasswordConfirmation
  } = useSignStore();
  const newRegisterData = getNewRegisteredData();

  const [emailExistError, setEmailExistError] = useState<string>();
  const [loading, setloading] = useState(false);
  const [firebasePasswordError, setFirebasePasswordError] = useState<string>();

  const [acceptTerms, setAcceptTems] = useState<boolean>(false);
  const [acceptPrivacyNotice, setAcceptPrivacyNotice] = useState<boolean>(false);
  const translationKey = 'authentication:SignUpForm:formFieldsStep3:';

  const emailError = getEmailError(userEmail) || emailExistError;
  const passwordError = getPasswordError(userPassword) || firebasePasswordError;
  const passwordConfirmationError = getPasswordConfirmationError(userPasswordConfirmation, userPassword);

  const isButtonDisabled = () => {
    if (!acceptTerms || !acceptPrivacyNotice) return true;
    if (!userEmail || !userPassword || !userPasswordConfirmation) return true;
    if (emailError || passwordError || passwordConfirmationError) return true;
    return false;
  };

  const changeTermsAcceptance = () => {
    setAcceptTems(!acceptTerms);
  };

  const changePrivacyNoticeAcceptance = () => setAcceptPrivacyNotice(!acceptPrivacyNotice);

  const handleClickReturn = () => {
    const updatedRegisterInformation = {};
    const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
    sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
    setFormStep(1);
  };

  const handleSignUp = async () => {
    if (isButtonDisabled()) return;
    if (!newRegisterData || !userEmail || !userPassword) return;
    setloading(true);
    const updatedRegisterInformation = {
      ...newRegisterData,
      email: userEmail,
      password: userPassword,
      confirmPassword: userPasswordConfirmation
    };
    const stringUpdatedRegisterInformation = JSON.stringify(updatedRegisterInformation);
    sessionStorage.setItem('newRegisterInformation', stringUpdatedRegisterInformation);
    const body = {
      name: newRegisterData.organizationName,
      businessName: newRegisterData.organizationBusinessName,
      document: newRegisterData.rfc?.toUpperCase(),
      countryId: newRegisterData.country?.id,
      numberOfEmployees: newRegisterData.numberOfEmployees,
      firstName: newRegisterData.firstName,
      lastName: newRegisterData.lastName,
      position: newRegisterData.position,
      email: userEmail || '',
      phone: newRegisterData.phone,
      registrationInfoId: +newRegisterData.id,
      countryPhoneId: userPhoneCountry?.id
    };
    try {
      const stringStoredUserFirebaseData = localStorage.getItem('userFirebaseData');
      const storedUserFirebaseData = stringStoredUserFirebaseData && JSON.parse(stringStoredUserFirebaseData);
      let firebaseData: UserInfo;
      if (!storedUserFirebaseData) {
        const userData: UserInfo = await createFirebaseUser(userEmail, userPassword);
        const stringUserData = JSON.stringify(userData);
        localStorage.setItem('userFirebaseData', stringUserData);
        firebaseData = userData;
      } else {
        firebaseData = storedUserFirebaseData;
      }
      const response = await postOfSignUp({ ...body, uid: firebaseData.uid });
      if (response) {
        localStorage.removeItem('userFirebaseData');
        sessionStorage.removeItem('newRegisterInformation');
        await loginEmailAndPassWord(userEmail, userPassword, setFirebasePasswordError, '/inactive');
        segmentTrackEvent({ signupThirdStepClick: { ContactMail: userEmail || '' } });
      }
    } catch (err: unknown) {
      if ((err as { error?: string })?.error === 'auth/email-already-in-use') {
        setEmailExistError(t(`${translationKey}emailAlreadyRegistered`));
      } else {
        displayErrorNotification();
      }
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    segmentTrackEvent({ signupThirdStepView: null });
  }, []);

  return (
    <div className="formStep formStep3">
      <BordTextInput
        className="formLine"
        label={t(`${translationKey}email`)}
        placeholder={'pedro@bord.co'}
        currentText={userEmail}
        setCurrentText={setUserEmail}
        style={{ width: '40rem' }}
        errorText={emailError}
        onBlur={() => setEmailExistError(undefined)}
      />
      <BordPasswordInput
        passwordClassName="formLine"
        label={t(`${translationKey}passwordLabel`)}
        placeholder={t(`${translationKey}passwordPlaceholder`)}
        setCurrentValue={setUserPassword}
        caption={t(`${translationKey}passwordInstructions`)}
        style={{ width: '40rem' }}
        errorText={passwordError}
      />
      <BordPasswordInput
        passwordClassName="formLine"
        label={t(`${translationKey}passwordConfirmationLabel`)}
        placeholder={t(`${translationKey}passwordConfirmationPlaceholder`)}
        setCurrentValue={setUserPasswordConfirmation}
        style={{ width: '40rem' }}
        errorText={passwordConfirmationError}
      />
      <div className="termsAndConditionsContainer formLine">
        <TermsAndConditionsCheckmark checked={acceptTerms} type="terms" onChangeState={changeTermsAcceptance} />
        <TermsAndConditionsCheckmark
          checked={acceptPrivacyNotice}
          type="warning"
          onChangeState={changePrivacyNoticeAcceptance}
        />
      </div>
      <div className="buttonsContainer">
        <NudosBreadcrumbButton returnText={t(`${translationKey}goBackButton`)} returnAction={handleClickReturn} />
        <BordButton
          label={t(`${translationKey}continueButton`)}
          disabled={isButtonDisabled()}
          customWidth="w-280"
          isLoading={loading}
          type="submit"
          onClick={handleSignUp}
        />
      </div>
    </div>
  );
};

export default SignUpFormStep3;

export interface IsignUpFormStep3Params {
  setFormStep: React.Dispatch<React.SetStateAction<number>>;
  setBusinessNameAlreadyRegisteredError: React.Dispatch<React.SetStateAction<string | undefined>>;
  countryPhone?: ICountryDetail;
}
