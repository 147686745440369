import { useTranslation } from 'react-i18next';
import { BordButton } from '../../BordDesignSystem';
import BordIlustrationIcon from '../../BordDesignSystem/BordIlustrationIcon/BordIlustrationIcon';
import './LogoutModal.scss';

const LogoutModalContent = ({
  onClickLogout,
  closeLogoutModal
}: {
  onClickLogout: () => void;
  closeLogoutModal: () => void;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:sidebar:logoutModal:';

  return (
    <div className="logoutCustomSection">
      <BordIlustrationIcon variant="logout" customWidth="7.2rem" />
      <div className="title">{t(`${i18nLanguageKey}title`)}</div>
      <div className="subtitle">{t(`${i18nLanguageKey}subtitle`)}</div>
      <div className="logoutButtonsContainer">
        <BordButton
          label={t(`${i18nLanguageKey}yes`)}
          modeButton="primary"
          bordButtonStyle="outlined"
          onClick={onClickLogout}
          customWidth="w-180"
        />
        <BordButton
          label={t(`${i18nLanguageKey}no`)}
          modeButton="primary"
          onClick={closeLogoutModal}
          customWidth="w-180"
        />
      </div>
    </div>
  );
};

export default LogoutModalContent;
