import { DTOupdateEmployeeData } from './DTO';
import { IorganizationOfficeData } from './account';
import { TstoragePlace } from './assignationFluxes';
import { IbankAccountInfo } from './cart';
import { IbillingData } from './checkout';
import { ICountryDetail } from './countries';
import { ICoordinates, IPhoneData, IdeliveryAddress } from './global';

export interface IShipmentsData {
  id: number;
  destinationCountryId: number;
  destinationPlace: TstoragePlace;
  receiverName: string;
  destinationCountry: ICountryDetail;
}
export interface IorderInListing {
  orderId: number;
  statusName: string;
  statusNameStringId: string | null;
  monthlyValue: string;
  period: string;
  shipments?: IShipmentsData[];
  deliveryAddress?: IOrderDeliveryAddress;
  saleValue: string;
  products: IProductProduct[];
  createdAt: string;
  multipleAddress: number | boolean;
  invoiceUrl?: null | string;
  country?: ICountryOrder;
  paymentType?: string;
  total?: number;
  exchangeRate: number;
  totalUsd: number;
  proofOfPaymentUrl?: null | string;
  currency?: string;
  paymentDate?: string;
  committedPaymentDate?: string;
  paymentTypeStringId?: string | null;
}

export interface ICountryOrder {
  id: number;
  code: string;
  name: string;
  flag: string;
}

export interface IOrderDeliveryAddress {
  address?: string;
  coordinates?: IOrderCoordinates;
  additionalReferences?: string;
  receiverInformation?: IOrderReceiverInformation;
  country?: string;
  city?: string;
}

export interface IOrderCoordinates {
  lat: string;
  lng: string;
}

export interface IOrderReceiverInformation {
  name: string;
  phone: string;
}

export interface IProductProduct {
  name: string;
  category: string;
  quantity: number;
}

export interface IorderDetailsItem {
  name?: string | null;
  quantity?: number | null;
  country?: string | null;
  countryName?: string | null;
  flag?: string | null;
  total?: number | null;
  totalUsd?: number | null;
}

export interface IshipmentDestinationUser {
  userId?: number | null;
  dataPhone?: IPhoneData | null;
}

export type TWrongAttributes = 'phoneNumber' | 'city' | 'address' | 'additionalReferences' | 'zipCode';

export interface IReports {
  message: string;
  wrongAttributes: TWrongAttributes[];
  status: 'OPEN' | 'CLOSED' | 'RESOLVED';
}

export interface IEditShipments {
  editOnlyOrder: boolean;
  id: number;
  platform: 'SOGA' | 'NODI';
}
export interface IshipmentGroupDetails {
  id?: number | null;
  orderId?: number | null;
  locationId?: number | null;
  country?: ICountryDetail | null;
  receiverName?: string | null;
  receiverPhone?: string | null;
  receiverPhoneCode?: string | null;
  receiverIdDocument?: string | null;
  destinationAddress?: string | null;
  destinationName?: string | null;
  destinationPlace?: string | null;
  city?: string | null;
  zipCode?: string | null;
  coordinates: ICoordinates;
  additionalReferences?: string | null;
  destinationCountryId: number;
  shipmentPrice: IshipmentPrice;
  shipments: IshipmentDetails[];
  user?: IshipmentDestinationUser | null;
}

export interface IshipmentPrice {
  shipmentValue?: string | null;
  shipmentValueUsd?: string | null;
}

export interface IshipmentDetails {
  shipmentValue?: number | null;
  shipmentValueUsd?: number | null;
  user?: IshipmentDestinationUser | null;
  countryName?: string | null;
  countryTimeZone?: number | null;
  flag?: string | null;
  productsInfo?: IshipmentProductInfo[] | null;
  countryNameStringId?: string | null;
  reports: IReports[];
  receiverPhoneCountryCode?: string;
  editShipments?: IEditShipments;
  shipmentId?: number | null;
  statusName?: string | null;
  statusNameStringId?: string | null;
  shipmentStatus?: number | null;
  statusUpdatedAt?: string | null;
  deliveryDate?: string | null;
  shipmentTrackingUrl?: string | null;
  shipmentTrackingCode?: string | null;
  estimatedDeliveryDate?: string | null;
}
export interface IshipmentProductInfo {
  saleId?: number | null;
  productId?: number | null;
  name?: string | null;
  brand?: string | null;
  brandImage?: string | null;
  image?: string | null;
  pills?: { [key: string]: string | null | undefined } | string | null;
  referenceModel?: string | null;
  serial?: string | null;
  warranty?: null | string;
}

export enum BILLING_METHODS {
  INTERNATIONAL_SALE = 'international_sale',
  LOCAL_SALE_COLOMBIA = 'local_sale_colombia',
  LOCAL_SALE_MEXICO = 'local_sale_mexico',
  LOCAL_SALE_PERU = 'local_sale_peru',
  LOCAL_SALE_USA = 'local_sale_usa',
  LOCAL_SALE_URUGUAY = 'local_sale_uruguay',
  LOCAL_SALE_TRADING_ENTITY_URUGUAY = 'local_sale_trading_entity_uruguay',
  LOCAL_SALE_FREE_ZONE_ENTITY_URUGUAY = 'local_sale_free_zone_entity_uruguay',
  LOCAL_SALE_ARGENTINA = 'local_sale_argentina',
  INTERNATIONAL_SALE_URUGUAY = 'international_sale_uruguay',
  INTERNATIONAL_SALE_MEXICO = 'international_sale_mexico'
}

export interface IBillingMethod {
  billingMethodId: number;
  billingMethodName: BILLING_METHODS;
  billingEntityCountryCode: string;
  destinationCountryCode: string;
  beneficiary: string;
  billingProvider: string;
  taxManagementName: string;
  taxManagementDescription: string;
}

export interface IorderDetails {
  orderId?: string | number | null;
  createdAt?: string | null;
  references?: number | null;
  shipmentsCount?: number | null;
  productsCount?: number | null;
  paymentMethod?: string | null;
  paymentMethodStringId?: string | null;
  paymentLink?: string | null;
  subtotal?: number | string | null;
  shipmentsSubtotal?: number | string | null;
  subtotalUsd?: number | string | null;
  commission?: number | string | null;
  commissionUsd?: string | string | null;
  taxValue?: number | string | null;
  taxValueUsd?: number | string | null;
  total?: number | string | null;
  totalUsd?: number | string | null;
  exchangeRate?: number | string | null;
  items?: IorderDetailsItem[] | null;
  proofOfPaymentUrl: string | null;
  invoiceUrl?: string | null;
  orderSummary?: string | null;
  globalStatus?: string | null;
  shimpentsInfo?: { [key: string]: IshipmentGroupDetails[] } | null; // the typo comes from backend so it cannot be changed without breaking the integration
  billingData?: IbillingData | null;
  billingCountryId?: number | null;
  currency: string;
  paymentDate?: string;
  committedPaymentDate?: string;
  bankAccountInfo?: IbankAccountInfo;
  globalStatusStringId?: string | null;
  totalTaxWithoutPrime?: number;
  totalTaxWithPrime?: number;
  totalWithoutPrimeNetOfTaxes?: number;
  totalWithPrimeNetOfTaxes?: number;
  billingMethod?: IBillingMethod;
  taxesToCalculate?: boolean;
}

export type TshipmentDestinationData = DTOupdateEmployeeData | IorganizationOfficeData;

export interface IupdateShipmentInformation {
  userInfo?: DTOupdateEmployeeData;
  officeInfo?: IorganizationOfficeData;
  onlyOrder: boolean;
}

export interface IactiveOrdersByEmployee {
  orderId?: number | null;
  isActive?: boolean | null;
}

export enum ORDER_SHIPMENT_STATUS {
  PENDING = 'Pendiente de confirmación',
  CONFIRMED = 'Confirmado',
  DELIVERY_PROCESS = 'Proceso de entrega',
  DELIVERED = 'Entregado',
  CANCELED = 'Cancelado'
}

export interface IUpdateConfirmEdition {
  reportId: number;
  status: 'OPEN' | 'CLOSED';
  shipmentId: number;
  haveReport: boolean;
}

export interface IShipmentsDataDetails {
  productId: number;
  locationId: number;
}

export interface ICartShippingInformation {
  shoppingCartId: number;
  shipmentsData: IShipmentsDataDetails[];
}

export interface ICalculateOfShipment {
  locationId: number;
  productsIds: number[];
  shipmentValue: number;
  locationPlace: string;
  receiverName?: string;
}
