const SquareNorthKorea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#1A47B8" rx="2" />
        <path fill="white" fillRule="evenodd" d="M0 3.2002H22V4.26686H0V3.2002Z" clipRule="evenodd" />
        <path fill="#F93939" fillRule="evenodd" d="M0 4.2666H22V11.7333H0V4.2666Z" clipRule="evenodd" />
        <path
          fill="white"
          d="M5.76163 10.6668C7.20809 10.6668 8.38067 9.47292 8.38067 8.00016C8.38067 6.5274 7.20809 5.3335 5.76163 5.3335C4.31517 5.3335 3.14258 6.5274 3.14258 8.00016C3.14258 9.47292 4.31517 10.6668 5.76163 10.6668Z"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M5.75992 9.04116L4.50278 9.71316L4.74373 8.28916L3.72754 7.28223L5.13135 7.07423L5.75992 5.7793L6.38849 7.0753L7.7923 7.28436L6.77611 8.29236L7.01706 9.71743"
          clipRule="evenodd"
        />
        <path fill="white" fillRule="evenodd" d="M0 11.7334H22V12.8001H0V11.7334Z" clipRule="evenodd" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareNorthKorea;
