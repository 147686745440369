import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordUserAddIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill={bordColorTheme.navyBlue[400]}
        d="m5.18007 15.2964-.37926-.6471.37926.6471Zm9.63983 0 .3793-.6471-.3793.6471ZM2.63348 19.422l-.51305.5471.51305-.5471Zm14.73302 0-.513-.5471.513.5471ZM20.25 4c0-.41421-.3358-.75-.75-.75s-.75.33579-.75.75h1.5Zm-1.5 5c0 .41421.3358.75.75.75s.75-.33579.75-.75h-1.5ZM22 7.25c.4142 0 .75-.33579.75-.75s-.3358-.75-.75-.75v1.5Zm-5-1.5c-.4142 0-.75.33579-.75.75s.3358.75.75.75v-1.5ZM5.55932 15.9434c2.71479-1.5912 6.16658-1.5912 8.88138 0l.7585-1.2941c-3.1832-1.8657-7.2152-1.8657-10.39839 0l.75851 1.2941Zm.52145 5.8066h7.83843v-1.5H6.08077v1.5Zm-2.93424-2.8751c-.38782-.3637-.40641-.5923-.39381-.7057.01794-.1615.13475-.3963.44975-.6998.64536-.6218 1.64663-1.1097 2.35685-1.526l-.75851-1.2941c-.54735.3209-1.82351.9541-2.63912 1.7399-.41548.4004-.82504.9411-.89981 1.6145-.08012.7215.24223 1.3874.85855 1.9654l1.0261-1.0942Zm11.29417-2.9315c.7102.4163 1.7115.9042 2.3568 1.526.315.3035.4318.5383.4498.6998.0126.1134-.006.342-.3938.7057l1.0261 1.0942c.6163-.578.9386-1.2439.8585-1.9654-.0748-.6734-.4843-1.2141-.8998-1.6145-.8156-.7858-2.0918-1.419-2.6391-1.7399l-.7585 1.2941Zm2.4128 2.9315c-.912.8553-1.8293 1.3751-2.9343 1.3751v1.5c1.6424 0 2.9103-.7962 3.9604-1.7809l-1.0261-1.0942ZM2.12043 19.9691c1.05008.9847 2.318 1.7809 3.96034 1.7809v-1.5c-1.10499 0-2.02229-.5198-2.93424-1.3751l-1.0261 1.0942ZM13.25 7c0 1.79493-1.4551 3.25-3.25 3.25v1.5c2.6234 0 4.75-2.12665 4.75-4.75h-1.5ZM10 10.25c-1.79493 0-3.25-1.45507-3.25-3.25h-1.5c0 2.62335 2.12665 4.75 4.75 4.75v-1.5ZM6.75 7c0-1.79493 1.45507-3.25 3.25-3.25v-1.5C7.37665 2.25 5.25 4.37665 5.25 7h1.5ZM10 3.75c1.7949 0 3.25 1.45507 3.25 3.25h1.5c0-2.62335-2.1266-4.75-4.75-4.75v1.5Zm8.75.25v2.5h1.5V4h-1.5Zm0 2.5V9h1.5V6.5h-1.5ZM22 5.75h-2.5v1.5H22v-1.5Zm-2.5 0H17v1.5h2.5v-1.5Z"
      />
    </svg>
  );
};

export default BordUserAddIcon;
