import { useTranslation } from 'react-i18next';
import './UnassignmentModuleStepper.scss';
import BordStepper from '../../../../../components/BordDesignSystem/BordStepper/BordStepper';

/**
 * @property  { number } currentStep - The current step of the checkout
 */
const UnassignmentModuleStepper = ({
  currentStep,
  requireLogistics
}: {
  currentStep: number;
  requireLogistics: boolean;
  includeQuoteAndBillingStep: boolean;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'services:unassignmentModule';
  const stepOrderNoSl: number = requireLogistics ? 2 : 1;
  const progressSteps = [
    {
      order: 0,
      showStep: true,
      stepContent: <div>{t(`${i18nLanguageKey}:steps:destination`)}</div>,
      title: 'Paso 1',
      subTitle: t(`${i18nLanguageKey}:steps:destination`),
      customStepStyles: undefined
    },
    {
      order: 1,
      showStep: requireLogistics,
      stepContent: <div>{t(`${i18nLanguageKey}:steps:address`)}</div>,
      title: 'Paso 2',
      subTitle: t(`${i18nLanguageKey}:steps:address`),
      customStepStyles: undefined
    },
    {
      order: stepOrderNoSl,
      showStep: true,
      stepContent: <div>{t(`${i18nLanguageKey}:steps:devices`)}</div>,
      title: `Paso ${stepOrderNoSl + 1}`,
      subTitle: t(`${i18nLanguageKey}:steps:devices`),
      customStepStyles: undefined
    },
    {
      order: 3,
      showStep: requireLogistics,
      stepContent: <div>{t(`${i18nLanguageKey}:steps:dates`)}</div>,
      title: `Paso 4`,
      subTitle: t(`${i18nLanguageKey}:steps:dates`),
      customStepStyles: undefined
    },
    {
      order: 4,
      showStep: requireLogistics,
      stepContent: <div>{t(`${i18nLanguageKey}:steps:price`)}</div>,
      title: `Paso 5`,
      subTitle: 'Facturación',
      customStepStyles: undefined
    },
    {
      order: 2,
      showStep: !requireLogistics,
      stepContent: <div>{t(`${i18nLanguageKey}:steps:price`)}</div>,
      title: `Paso 3`,
      subTitle: 'Resumen',
      customStepStyles: undefined
    }
  ];

  const definitiveSteps = progressSteps.filter(step => step?.showStep);

  return (
    <div className="UnassignmentModuleStepper">
      <BordStepper
        titleStepperCustomClass="titleStepperCustomClass"
        subTitleCustomClass="subTitleCustomClass"
        steps={definitiveSteps}
        stepperConectorWidth="w-170"
        currentStep={currentStep}
        isStepContent={false}
      />
    </div>
  );
};

export default UnassignmentModuleStepper;
