const SquareNauru = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#232C80" rx="2" />
        <path fill="#FFDA2C" fillRule="evenodd" d="M0 7.4668H22V8.53346H0V7.4668Z" clipRule="evenodd" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M7.8555 13.0455L6.3155 13.87L6.60883 12.1239L5.36426 10.8866L7.0855 10.6338L7.8555 9.04443L8.62445 10.6338L10.3457 10.8866L9.09902 12.1239L9.39445 13.869"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareNauru;
