const RoundVanuatu = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="black"
          d="M32 16C32 15.2927 31.9535 14.5962 31.8645 13.9131H13.9129C10.6441 10.6445 4.68602 4.68678 4.68627 4.68628C-1.56209 10.9346 -1.56209 21.0652 4.68627 27.3137L13.9131 18.087H31.8645C31.9535 17.4038 32 16.7073 32 16Z"
        />
        <path
          fill="white"
          d="M31.9954 16.3643C31.9947 16.3951 31.994 16.4261 31.9932 16.457 31.9939 16.426 31.9947 16.3951 31.9954 16.3643ZM31.9798 16.8013C31.9784 16.8318 31.9762 16.8623 31.9746 16.8928 31.9762 16.8623 31.9782 16.8318 31.9798 16.8013ZM31.9592 17.1499C31.956 17.1948 31.9528 17.2398 31.9492 17.2846 31.9528 17.2398 31.9559 17.1948 31.9592 17.1499ZM31.9053 14.2563C31.9111 14.3098 31.917 14.3633 31.9223 14.4169 31.917 14.3632 31.9111 14.3098 31.9053 14.2563ZM31.9746 15.1074C31.9763 15.1379 31.9784 15.1684 31.9798 15.1989 31.9783 15.1684 31.9763 15.1379 31.9746 15.1074ZM31.9932 15.5435C31.994 15.5743 31.9947 15.6053 31.9954 15.6362 31.9947 15.6052 31.994 15.5743 31.9932 15.5435ZM31.9492 14.7158C31.9528 14.7606 31.956 14.8056 31.9592 14.8505 31.9559 14.8056 31.9528 14.7606 31.9492 14.7158Z"
        />
        <path
          fill="#F93939"
          d="M31.8649 13.913C31.8721 13.9678 31.8785 14.0229 31.885 14.0778C30.9354 6.14774 24.1863 0 16.0004 0C11.582 0 7.58196 1.79093 4.68652 4.68649L13.913 13.913H31.8649V13.913Z"
        />
        <path
          fill="#6DA544"
          d="M31.8649 18.0867H13.913L4.68652 27.3133C7.58196 30.2088 11.582 31.9998 16.0004 31.9998C24.1863 31.9998 30.9355 25.852 31.885 17.9219C31.8785 17.9768 31.872 18.0319 31.8649 18.0867Z"
        />
        <path
          fill="white"
          d="M31.9223 17.5835C31.917 17.6371 31.9111 17.6906 31.9053 17.7441C31.9111 17.6906 31.9171 17.6372 31.9223 17.5835Z"
        />
        <path
          fill="black"
          d="M31.958 14.8501C31.9641 14.9357 31.9696 15.0214 31.9743 15.1073 31.9695 15.0214 31.9641 14.9357 31.958 14.8501ZM31.8848 14.0781C31.892 14.1374 31.8988 14.1969 31.9051 14.2564 31.8986 14.1969 31.8918 14.1375 31.8848 14.0781ZM31.9219 14.4165C31.9316 14.5158 31.9404 14.6154 31.9484 14.7153 31.9405 14.6154 31.9317 14.5158 31.9219 14.4165ZM31.9997 16C31.9997 16.1217 31.9978 16.2429 31.9951 16.3638 31.9978 16.2428 31.9997 16.1216 31.9997 16ZM31.9752 16.8926C31.9705 16.9785 31.965 17.0643 31.959 17.1498 31.965 17.0643 31.9704 16.9785 31.9752 16.8926ZM31.9484 17.2847C31.9404 17.3845 31.9316 17.4841 31.9219 17.5834 31.9317 17.4841 31.9405 17.3845 31.9484 17.2847ZM31.9051 17.7437C31.8988 17.8032 31.892 17.8625 31.8848 17.9219 31.8918 17.8626 31.8986 17.8032 31.9051 17.7437ZM31.9951 15.6362C31.9978 15.7572 31.9997 15.8785 31.9997 16 31.9997 15.8785 31.9978 15.7572 31.9951 15.6362ZM31.9805 15.1987C31.9861 15.3132 31.9903 15.4282 31.9936 15.5433 31.9903 15.4281 31.9862 15.3132 31.9805 15.1987ZM31.9926 16.4565C31.9894 16.5717 31.9851 16.6866 31.9795 16.8011 31.9852 16.6865 31.9894 16.5717 31.9926 16.4565Z"
        />
        <path
          fill="#FFDA2C"
          d="M31.9844 15.2987H13.3247L3.7399 5.71533C3.43009 6.04208 3.09428 6.49833 2.88659 6.82327L12.0528 15.9943L2.87891 25.1683C3.06053 25.4914 3.45622 25.9697 3.73403 26.2809L13.3249 16.69H31.9866C31.9965 16.4592 31.9997 16.2275 31.9997 15.9944C31.9996 15.682 31.991 15.4517 31.9844 15.2987Z"
        />
        <path
          fill="#FFDA2C"
          d="M3.88654 19.4107V18.0705C5.22391 18.0705 6.31184 16.9825 6.31184 15.6452C6.31184 14.699 5.54203 13.9293 4.59591 13.9293C3.95135 13.9293 3.42691 14.4537 3.42691 15.0984C3.42691 15.5105 3.76222 15.8459 4.17447 15.8459C4.40747 15.8459 4.59703 15.6562 4.59703 15.4232H5.93716C5.93716 16.3953 5.14641 17.186 4.17453 17.186C3.02335 17.1861 2.08691 16.2495 2.08691 15.0985C2.08691 13.7149 3.21254 12.5894 4.59597 12.5894C6.28109 12.5894 7.65209 13.9604 7.65209 15.6454C7.65203 17.7215 5.96284 19.4107 3.88654 19.4107Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundVanuatu;
