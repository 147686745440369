const SquareLiechtenstein = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V8H0V0Z" clipRule="evenodd" />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M4.19043 5.3335H8.38091V6.40016H4.19043V5.3335Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M5.24829 3.35143C4.92563 3.2661 4.55686 3.19997 4.1902 3.19997C3.14258 3.19997 3.14258 3.7333 3.14258 4.26663C3.14258 4.79997 4.1902 5.3333 4.1902 5.3333H8.38067C8.38067 5.3333 9.42829 4.79997 9.42829 4.26663C9.42829 3.7333 9.42829 3.19997 8.38067 3.19997C8.01401 3.19997 7.64524 3.2661 7.32258 3.35143C7.32991 3.3013 7.33305 3.25117 7.33305 3.19997C7.33305 2.61117 6.86372 2.1333 6.28544 2.1333C5.70715 2.1333 5.23782 2.61117 5.23782 3.19997C5.23782 3.25117 5.24096 3.3013 5.24829 3.35143Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareLiechtenstein;
