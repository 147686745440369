const RoundMongolia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M16.0001 32C17.9573 32 19.8321 31.648 21.5653 31.0048L22.261 16L21.5654 0.995188C19.8321 0.352063 17.9573 0 16.0001 0C14.043 0 12.1682 0.352063 10.4349 0.995188L9.73926 16L10.4349 31.0048C12.1682 31.648 14.043 32 16.0001 32Z"
        />
        <path
          fill="#A2001D"
          d="M0 15.9999C0 22.8794 4.342 28.7441 10.4348 31.0047V.995117C4.342 3.2558 0 9.12049 0 15.9999ZM21.5654.995117V31.0048C27.6582 28.7441 32.0002 22.8794 32.0002 15.9999 32.0002 9.12049 27.6582 3.2558 21.5654.995117Z"
        />
        <path
          fill="#FFDA2C"
          d="M7.65234 16H9.04366V21.5652H7.65234V16ZM2.08691 16H3.47823V21.5652H2.08691V16ZM5.56514 19.4784C6.33354 19.4784 6.95645 18.8555 6.95645 18.0871 6.95645 17.3187 6.33354 16.6958 5.56514 16.6958 4.79674 16.6958 4.17383 17.3187 4.17383 18.0871 4.17383 18.8555 4.79674 19.4784 5.56514 19.4784ZM5.56477 13.913C5.94895 13.913 6.26039 13.6015 6.26039 13.2174 6.26039 12.8332 5.94895 12.5217 5.56477 12.5217 5.18058 12.5217 4.86914 12.8332 4.86914 13.2174 4.86914 13.6015 5.18058 13.913 5.56477 13.913ZM4.17383 20.1741H6.95645V21.5654H4.17383V20.1741ZM4.17383 14.6089H6.95645V16.0002H4.17383V14.6089ZM5.56545 8.3479 6.0582 9.86415H7.65245L6.36277 10.8013 6.85514 12.3175 5.56545 11.3804 4.27583 12.3175 4.7682 10.8013 3.47852 9.86415H5.07277L5.56545 8.3479Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMongolia;
