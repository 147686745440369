import { IshipmentGroupDetails } from '../../../../../types/orders';
import OrderShipmentCard from '../OrderShipmentCard/OrderShipmentCard';
import './OrderShipmentGroupCardExpandedSection.scss';

const OrderShipmentGroupCardExpandedSection = ({
  shipmentGroupData,
  showExpandedSection,
  shipmentCardIndex
}: {
  shipmentGroupData: IshipmentGroupDetails;
  showExpandedSection: boolean;
  shipmentCardIndex: number;
}) => {
  const { shipments } = shipmentGroupData || {};
  const verticalPadding = 1.2;
  const expandedStyles = showExpandedSection
    ? { height: `max-content`, padding: `${verticalPadding}rem`, paddingTop: 0 }
    : undefined;

  return (
    <div className={`orderShipmentGroupCardExpandedSection`} style={expandedStyles}>
      {shipments?.map((shipment, i) => {
        return (
          <OrderShipmentCard
            key={`product${shipment.shipmentId || ''}-${i}`}
            shipmentData={shipment}
            totalProducts={shipmentCardIndex + 1}
          />
        );
      })}
    </div>
  );
};

export default OrderShipmentGroupCardExpandedSection;
