const RoundIsleOfMan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M21.9243 10.7256L20.7934 14.7613L17.4003 14.1348L15.2161 9.63257L9.3157 11.7264L8.85039 10.4151L7.30664 10.2249L8.46983 13.503L12.5304 12.4646L13.6843 15.7164L10.8773 19.859L15.6409 23.9219L14.738 24.9804L15.3451 26.4126L17.6025 23.7661L14.6729 20.7688L16.9121 18.1436L21.9032 18.5032L23.04 12.3463L24.4082 12.599L25.345 11.3571L21.9243 10.7256Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIsleOfMan;
