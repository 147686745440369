import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NudosLogisticServiceUserHeader,
  NudosLogisticsEmployeesDropdown,
  NudosLogisticsOfficeDropdown,
  NudosLogisticsWarehouseDropdown
} from '../../../../components/DesignSystem';
import NudosLogisticsGeneralContainer from '../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import { Iemployee } from '../../../../types/global';
import { UnassignmentSelectToolDestinationDropdown } from '../Components';
import {
  IlocationAddressForLogistics,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../../types/requestLogisticsModule';
import { ICountryDetail } from '../../../../types/countries';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import { segmentTrackEvent } from '../../../../utils/segment';
import './UnassignmentStep1SelectDestination.scss';

const UnassignmentStep1SelectDestination = ({
  selectedDestination,
  originData,
  destinationData,
  nudosWarehousesList,
  updateSelectedDestinationCallback,
  updateDestinationDataCallback,
  requireLogistics,
  showSkeletonLoader = false,
  loadingWarehousesList,
  filterCountries,
  originUrl,
  warrantyIsPossibleForSelectedTools
}: {
  showSkeletonLoader?: boolean;
  originUrl: string;
  selectedDestination?: TstoragePlace;
  originData?: Iemployee;
  destinationData?: TlogisticsOriginOrDestinationData;
  nudosWarehousesList: IwarehouseData[];
  updateSelectedDestinationCallback: (option: TstoragePlace) => void;
  updateDestinationDataCallback: (destinationData: TlogisticsOriginOrDestinationData) => void;
  requireLogistics: boolean;
  loadingWarehousesList?: boolean;
  filterCountries?: ICountryDetail[];
  warrantyIsPossibleForSelectedTools: boolean;
}) => {
  const { t } = useTranslation();
  const [specificDestinationDropdownIsFocused, setSpecificDestinationDropdownIsFocused] = useState(false);
  const originName = `${originData?.firstName || ''} ${originData?.lastName || ''}`;
  const originPhoto = originData?.photoProfile || '';
  const originFlagCountry = originData?.country?.code || '';
  const canOfferDiagnosticsAndWarrantyManagement = nudosWarehousesList.some(
    warehouse => warehouse?.country?.code === originData?.country?.code
  );
  const i18nLanguageKey = 'services:unassignmentModule';
  const destinationIsOffice = selectedDestination === 'office';
  const destinationIsUser = selectedDestination === 'user';
  const destinationIsWarehouse = selectedDestination === 'nudos';
  const destinationFlagCountry = destinationData?.country?.code || '';
  const warrantyOrDiagnostic = ['warranty', 'diagnostic']?.includes(selectedDestination || '');
  const deatinationWarrantyOrDiagnostic = warrantyOrDiagnostic ? (destinationData as IwarehouseData) : undefined;
  const countryWarrantyOrDiagnostic = deatinationWarrantyOrDiagnostic?.countryData?.code;

  const pendingDestination = destinationData ? 'pendingDestination' : undefined;

  const getWarehouseDestinationInformation = () => {
    switch (selectedDestination) {
      case 'warranty':
        return { cardName: 'Marca' };
      case 'diagnostic':
        return { cardName: 'Diagnóstico (Bodegas Nudos)' };
      case 'nudos':
        return {
          cardName: `Bodega ${(destinationData as IwarehouseData)?.country?.name || ''}`
        };
      default:
        return { cardName: '' };
    }
  };

  const getDestinationName = () => {
    if (!destinationData) return undefined;
    switch (selectedDestination) {
      case 'user':
        return `${(destinationData as Iemployee)?.firstName || ''} ${(destinationData as Iemployee)?.lastName || ''}`;
      case 'office':
        return `Oficina en ${(destinationData as IlocationAddressForLogistics)?.country?.name || 'País'}`;
      default:
        return getWarehouseDestinationInformation()?.cardName;
    }
  };

  const getDestinationPhoto = () => {
    switch (selectedDestination) {
      case 'user':
        return (destinationData as Iemployee)?.photoProfile?.replaceAll(' ', '%20') || '';
      default:
        return undefined;
    }
  };

  const updateSelectedDestinationCallbackWithFocusFunctionality = (destination: TstoragePlace) => {
    setSpecificDestinationDropdownIsFocused(true);
    updateSelectedDestinationCallback(destination);
  };

  const updateDestinationDataCallbackWithFocusFunctionality = (destinationData: TlogisticsOriginOrDestinationData) => {
    setSpecificDestinationDropdownIsFocused(false);
    updateDestinationDataCallback(destinationData);
  };

  const viewEvent = () => {
    segmentTrackEvent({
      nodiLogisticServices1DestinationView: {
        LogisticServiceOrigin: 'Employee',
        OriginCountry: originData?.country?.name || ''
      }
    });
  };

  useEffect(() => {
    viewEvent();
  }, []);

  return (
    <div className="UnassignmentStep1SelectDestination">
      <NudosLogisticsGeneralContainer title={t(`${i18nLanguageKey}:titleGeneralStepOne`)}>
        <div className="divisorLineContainer">
          <div className="grayLine" />
        </div>
        <div className="dropdownsContainer">
          <UnassignmentSelectToolDestinationDropdown
            selectedDestination={selectedDestination}
            optionSavingCallback={updateSelectedDestinationCallbackWithFocusFunctionality}
            canOfferDiagnosticsAndWarrantyManagement={canOfferDiagnosticsAndWarrantyManagement}
            withoutLogistics={!requireLogistics}
            loadingWarehousesList={loadingWarehousesList || !originData}
            warrantyIsPossibleForSelectedTools={warrantyIsPossibleForSelectedTools}
          />
          {destinationIsOffice && (
            <NudosLogisticsOfficeDropdown
              componentSize="w-280"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as IlocationAddressForLogistics}
              isFocused={specificDestinationDropdownIsFocused}
              filterCountries={filterCountries}
              countryOfOrigin={originData?.country?.code}
            />
          )}
          {destinationIsUser && (
            <NudosLogisticsEmployeesDropdown
              originUrl={originUrl}
              componentSize="w-280"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as Iemployee}
              originEmployee={originData}
              isFocused={specificDestinationDropdownIsFocused}
              filterCountries={filterCountries}
              showFlag
              nameCharactersLimit={28}
            />
          )}
          {destinationIsWarehouse && (
            <NudosLogisticsWarehouseDropdown
              componentSize="w-280"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as IwarehouseData}
              warehousesListProp={nudosWarehousesList}
              isFocused={specificDestinationDropdownIsFocused}
              countryOfOrigin={originData?.country?.code}
            />
          )}
        </div>
        <div className="selectedLocations">
          <NudosLogisticServiceUserHeader
            type={'origin'}
            photoUrl={originPhoto}
            name={originName}
            flagCountryCode={originFlagCountry}
            showSkeletonLoader={showSkeletonLoader}
            place="user"
          />
          <NudosLogisticServiceUserHeader
            customClassName={pendingDestination}
            type={'destination'}
            name={getDestinationName()}
            photoUrl={getDestinationPhoto()}
            flagCountryCode={destinationFlagCountry || countryWarrantyOrDiagnostic}
            showSkeletonLoader={showSkeletonLoader}
            place={selectedDestination}
          />
        </div>
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default UnassignmentStep1SelectDestination;
