import { useRef, useState } from 'react';
import { NudosStaticAlertInComponent, NudosTransfDetails } from '../../../../components/DesignSystem';
import NudosLogisticsGeneralContainer from '../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import { NudosHoverText } from '../../../../components/NudosComponents';
import NudosLogisticsKeepInMind from '../NudosUnassignmentKeepInMind/NudosLogisticsKeepInMind';
import { useLocation } from 'react-router-dom';
import { ItoolEssentialData } from '../../../../types/requestLogisticsModule';
import { TotalsDropdown } from '../TotalsDropdown';
import { ILogisticQuoteGeneralData } from '../../../../types/logisticsServicesModule.types';
import { TstoragePlace } from '../../../../types/assignationFluxes';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../../utils/unCamelCase';
import { INudosTransfDetailsData } from '../../../../types/cart';
import { getOrderRequiredDataByPaymentMethod } from '../../../../utils/productDefinitions';
import { PAYMENT_TYPES } from '../../../../types/checkout';
import { truncateText } from '../../../../utils/truncateText';
import './NewLogisticsVerifyData.scss';

const LIMIT_OF_CHARACTERS = 296;

const NewLogisticsVerifyData = ({
  type,
  collectionName = 'Sin información',
  deliveryName = 'Sin información',
  rejectedLayout = false,
  selectedDatesRange = 'Sin información',
  estimatedDelivery = 'Sin información',
  logisticServiceStatus,
  customContainerTitle,
  timeAlertText,
  bodyAlertComponent,
  emailToAssignee,
  quoteData,
  originPlace,
  destinationPlace,
  paymentSpecialData = {},
  countryName = '',
  estimatedDeliveryText = ''
}: {
  type: 'assignment' | 'unassignment';
  collectionName?: string;
  deliveryName?: string;
  rejectedLayout?: boolean;
  selectedDatesRange?: string;
  estimatedDelivery?: string;
  logisticServiceStatus?: string;
  customContainerTitle?: string;
  tools: ItoolEssentialData[];
  timeAlertText?: string | JSX.Element;
  bodyAlertComponent?: React.ReactNode;
  emailToAssignee?: string;
  quoteData?: ILogisticQuoteGeneralData;
  originPlace?: TstoragePlace;
  destinationPlace?: TstoragePlace;
  paymentSpecialData?: INudosTransfDetailsData;
  countryName?: string;
  estimatedDeliveryText?: string;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [showExpandedEmailToAssignee, setShowExpandedEmailToAssignee] = useState(false);

  const emailToAssigneeContainerDiv = useRef<HTMLDivElement>(null);
  const viewMoreText = capitalize(t('recurrentWords:viewMore'));
  const viewLessText = capitalize(t('recurrentWords:viewLess'));

  const searchParams = new URLSearchParams(search);
  const requireLogisticsJSON = searchParams?.get('logistics');
  const displayRequireLogistics = requireLogisticsJSON === 'false' ? false : true;
  const emailToAssigneeSectionExceedsAllowedHeight =
    emailToAssignee && emailToAssigneeContainerDiv.current?.clientHeight && emailToAssignee.length > LIMIT_OF_CHARACTERS
      ? emailToAssigneeContainerDiv.current?.clientHeight > 60
      : false;
  const expandedStyles = showExpandedEmailToAssignee ? 'expanded' : '';
  const emailToLongStyles = emailToAssigneeSectionExceedsAllowedHeight ? 'long' : '';
  const fieldValueCharactersLimit = 22;
  const isAssignment = type === 'assignment';
  const datesRangeTitle = isAssignment ? 'Estimado de entrega' : 'Estimado de recolección';
  const { paymentMethod, logisticsQuoteData } = paymentSpecialData;

  const paymentIsTranferARS = paymentMethod === PAYMENT_TYPES.TRANSFER_ARS;
  const newQuoteTotal = !paymentIsTranferARS ? Number(paymentSpecialData?.total) : logisticsQuoteData?.total || 0;
  const newQuoteCurrency = !paymentIsTranferARS
    ? paymentSpecialData?.currency || ''
    : logisticsQuoteData?.currency || '';

  const newQuote: ILogisticQuoteGeneralData | undefined = quoteData && {
    ...quoteData,
    total: newQuoteTotal,
    currency: newQuoteCurrency
  };

  const { requiresBankData } = getOrderRequiredDataByPaymentMethod(
    paymentMethod || '',
    paymentSpecialData?.billingMethod,
    true
  );

  const diplayNudosAlertARGMessage = paymentMethod === PAYMENT_TYPES.TRANSFER_ARS;
  const displayNudosTransfDetails = requiresBankData && !paymentSpecialData?.manualQuote;

  const expandEmailToAssigneeSection = () => setShowExpandedEmailToAssignee(!showExpandedEmailToAssignee);

  return (
    <div className="NewLogisticsVerifyData">
      <NudosLogisticsGeneralContainer
        customClassname="logisticServiceSummary"
        title={customContainerTitle}
        showIlustrationByVariantName={!displayRequireLogistics ? 'copy' : undefined}
      >
        <div className="w-full h-1 bg-stroke mt-16 mb-16" />
        <section className="informationSection">
          <div className={`informationLine ${!displayRequireLogistics ? 'justify-center' : ''} `}>
            <div className="informationField">
              <div className="fieldTitle">{capitalize(t('recurrentWords:origin'))}</div>
              <NudosHoverText
                onlyIfTruncated
                customClassName="fieldValue"
                text={collectionName?.toLocaleLowerCase()}
                charactersLimit={fieldValueCharactersLimit}
              />
            </div>
            <div className="informationField">
              <div className="fieldTitle">{t('recurrentWords:destination')}</div>
              <NudosHoverText
                onlyIfTruncated
                customClassName="fieldValue"
                text={deliveryName?.toLocaleLowerCase()}
                charactersLimit={fieldValueCharactersLimit}
              />
            </div>
            {logisticServiceStatus && (
              <div className="informationField">
                <div className="fieldTitle">Estado</div>
                <div className="status">
                  <div className="statusIndicator" />
                  {logisticServiceStatus}
                </div>
              </div>
            )}
          </div>
          {displayRequireLogistics && (
            <div className="informationLine">
              <div className="informationField">
                <div className="fieldTitle">{datesRangeTitle}</div>
                <NudosHoverText
                  onlyIfTruncated
                  customClassName="fieldValue"
                  text={selectedDatesRange}
                  charactersLimit={20}
                />
              </div>
              {!isAssignment && (
                <div className="informationField">
                  <div className="fieldTitle">Estimado de entrega</div>
                  <NudosHoverText
                    onlyIfTruncated
                    customClassName="fieldValue deliveryDaysRange"
                    text={estimatedDelivery}
                    charactersLimit={20}
                  />
                </div>
              )}
            </div>
          )}
        </section>
        {displayRequireLogistics && !rejectedLayout && (
          <NudosStaticAlertInComponent
            customClassname="noMargins"
            type="time"
            alertOptions={{
              title: timeAlertText
                ? timeAlertText
                : `Para entregas en ${countryName}, los tiempos son de ${estimatedDeliveryText}, contando a partir del momento en que se recolectan las herramientas`
            }}
          />
        )}
        {emailToAssignee && (
          <section className="emailToAssigneeSection">
            <div className="blueLineContainer">
              <div className="blueLine" />
            </div>
            <div className="emailSectionTitle">{t('services:logistics:endStep:attachedMessage')}</div>
            <div className={`emailContent ${expandedStyles} ${emailToLongStyles}`} ref={emailToAssigneeContainerDiv}>
              {showExpandedEmailToAssignee ? emailToAssignee : truncateText(emailToAssignee, LIMIT_OF_CHARACTERS)}
            </div>
            <div className="w-full flex items-center justify-center">
              {emailToAssigneeSectionExceedsAllowedHeight && (
                <button className="seeMoreButton" onClick={expandEmailToAssigneeSection}>
                  {showExpandedEmailToAssignee ? viewLessText : viewMoreText}
                </button>
              )}
            </div>
          </section>
        )}
        {displayRequireLogistics && !rejectedLayout && (
          <>
            <div className="blueLineContainer">
              <div className="blueLine" />
            </div>{' '}
            {newQuote && (
              <TotalsDropdown quoteData={newQuote} destinationPlace={destinationPlace} originPlace={originPlace} />
            )}
            {displayNudosTransfDetails && (
              <>
                <NudosTransfDetails specialData={paymentSpecialData} customWidth="auto" />
                <div className="blueLineContainer">
                  <div className="blueLine" />
                </div>
              </>
            )}
            {diplayNudosAlertARGMessage && (
              <div>
                <NudosStaticAlertInComponent
                  alertOptions={{
                    title:
                      'En la factura podrás consultar el monto a cancelar y los impuestos asociados al servicio en pesos argentinos al cambio del mercado'
                  }}
                  type="information"
                  color="blue"
                />
                <div className="blueLineContainer">
                  <div className="blueLine" />
                </div>{' '}
              </div>
            )}
            <NudosLogisticsKeepInMind />
            <div className="blueLineContainer">
              <div className="blueLine" />
            </div>
            <NudosStaticAlertInComponent
              customIconContainer="mb-auto"
              type="warning"
              color="blue"
              alertOptions={{
                title: '¡Importante!',
                subtitle: 'Las herramientas deben estar empacadas en cajas cerradas para poder ser recolectadas.'
              }}
              bodyAlertComponent={bodyAlertComponent}
            />
          </>
        )}
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default NewLogisticsVerifyData;
