import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useShopStore from '../../../../state/useShopContext';
import { useTranslation } from 'react-i18next';
import BordSearchBar from '../../../BordDesignSystem/BordSearchBar/BordSearchBar';
import { IProductItemList } from '../../../../types/catalogue';
import algoliaSearchService from '../../../../services/algolia.service';
import useStoreCountryState from '../../../../state/useStoreCountryState';
import useDebounce from '../../../../hooks/useDebounce';
import './EcommerceSearchProduct.scss';

function EcommerceSearchProduct() {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { storeCountry } = useStoreCountryState();
  const { setSearchResult, searchString, setSearchString } = useShopStore();

  const { search } = algoliaSearchService();
  const productDetailsOrigin = location?.pathname?.includes('/catalogue/product/');

  const debouncedSearchString = useDebounce(searchString, 500);

  const getProductName = async (q: string) => {
    const result = (await search(
      q,
      storeCountry ? storeCountry.countryCode : undefined
    )) as unknown as IProductItemList[];
    setSearchResult(result);
    push(`/catalogue/search${productDetailsOrigin ? `?origin=productDetails` : ''}`);
  };

  useEffect(() => {
    if (!debouncedSearchString) return;
    getProductName(debouncedSearchString);
  }, [debouncedSearchString]);

  return (
    <div className="ecommerceSearchProduct">
      <BordSearchBar
        handleChange={setSearchString}
        placeholder={t('nodi:headBar:search')}
        defaultValue={searchString}
        isFilled={!!searchString}
        includeMagnifierIcon={true}
        includeDropdownArrowIcon={false}
      />
    </div>
  );
}
export default EcommerceSearchProduct;
