import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BordDateRange, NudosStaticAlertInComponent } from '../../../../components/DesignSystem';
import NudosLogisticsGeneralContainer from '../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import { BordTextArea } from '../../../../components/BordDesignSystem';
import { segmentTrackEvent } from '../../../../utils/segment';
import './UnassignmentStep4Dates.scss';

interface IOutRange {
  startDate: Date;
  endDate: Date;
}

const UnassignmentStep4Dates = ({
  collectionDetailsText,
  updateCollectionDetailsCallback,
  type,
  handleCheckRange,
  aproximateDay,
  defaultDateRange,
  isValidRange
}: {
  collectionDetailsText?: string;
  updateCollectionDetailsCallback: (newCollectionDetails: string) => void;
  type?: 'local' | 'international';
  handleCheckRange?: (dateRange: IOutRange) => void;
  aproximateDay: number;
  rangeDays?: string;
  defaultDateRange?: IOutRange;
  isValidRange?: (state: boolean) => void;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'services:unassignmentModule';
  const internationalLogisticsText = t(`${i18nLanguageKey}:superiorAlertText:international`);
  const localLogisticsText = t(`${i18nLanguageKey}:superiorAlertText:locale`);
  const timeAlertText = type === 'local' ? localLogisticsText : internationalLogisticsText;

  const isMyRDateRange = (dateRange: IOutRange) => {
    if (handleCheckRange) {
      handleCheckRange(dateRange);
    }
  };

  const datesEvents = () => {
    segmentTrackEvent({ nodiLogisticServices3LocalDateView: null });
  };
  useEffect(() => {
    datesEvents();
  }, []);

  return (
    <div className="UnassignmentStep4Dates">
      <NudosLogisticsGeneralContainer title={t(`${i18nLanguageKey}:selectWeekTools`)}>
        <div className="blueLineContiainer">
          <div className="blueLine" />
        </div>
        <div className="containerRange">
          <BordDateRange
            startDay={aproximateDay}
            sendValueOut={isMyRDateRange}
            defaultDateRange={defaultDateRange}
            validRange={isValidRange}
          />
        </div>
        <div className="timeAlertContainer">
          <NudosStaticAlertInComponent
            type="time"
            alertOptions={{
              title: timeAlertText
            }}
            customIconContainer="customIconContainer"
            customTitleClass="customTitleClass"
          />
        </div>
        <div className="textAreaContainer">
          <BordTextArea
            label={t(`${i18nLanguageKey}:nudosTextArea:label`)}
            placeholder={t(`${i18nLanguageKey}:nudosTextArea:placeholder`)}
            captionText={t(`${i18nLanguageKey}:nudosTextArea:caption`)}
            value={collectionDetailsText || ''}
            setInputValue={updateCollectionDetailsCallback}
            customWidth="48rem"
            bordTextAreaContentClass="bordTextAreaContentClass"
            bordTextAreaLabelCustomClass="bordTextAreaLabelCustomClass"
            bordTextAreaCaptionCustomClass="bordTextAreaCaptionCustomClass"
            bordTextAreaValueCustomClass="bordTextAreaValueCustomClass"
            showZeroValue
          />
        </div>
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default UnassignmentStep4Dates;
