const RoundCameroon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M22.9567 1.58763C20.8532.5705 18.4933 0 16.0002 0c-2.4932 0-4.853.5705-6.95654 1.58763L7.65234 16l1.39132 14.4124C11.1472 31.4295 13.507 32 16.0002 32c2.4931 0 4.853-.5705 6.9565-1.5876L24.348 16 22.9567 1.58763Z"
        />
        <path
          fill="#FFDA2C"
          d="m15.9999 10.4348 1.3813 4.2512h4.4705l-3.6165 2.6278 1.3813 4.2514-3.6166-2.6276-3.6165 2.6276 1.3815-4.2514-3.6165-2.6278h4.4702l1.3813-4.2512Z"
        />
        <path
          fill="#496E2D"
          d="M9.0435 1.58813C3.69163 4.17601 0 9.65657 0 15.9999c0 6.3434 3.69163 11.824 9.0435 14.4119V1.58813Z"
        />
        <path
          fill="#FFDA2C"
          d="M22.9561 1.58813V30.4118c5.3518-2.5879 9.0435-8.0685 9.0435-14.4119 0-6.34326-3.6917-11.82389-9.0435-14.41177Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCameroon;
