import { Dot } from '../../../assets/images/components/NudosComponents';
import './NudosEllipseProgressTracker.scss';

/**
 * A react component to track steps, with each step as a dot.
 * @property {number} currentStep - Required, the current step, zero based.
 * @property {number} totalSteps - Optional, the total number of steps, one based. Default value is 3.
 * @property {function(clickedStep : number): void } - Optional, a callback to execute when clicking a step, to be used if the component is supposed to work as a button. The clicked step is passed as a param. Zero based.
 */
const NudosEllipseProgressTracker = ({ currentStep, totalSteps, handleClickStep }: InudosEllipseProgressTracker) => {
  const Arr = new Array(totalSteps ?? 3);
  Arr.fill(0);
  const dotsArr = Arr.map((ele, i) => {
    return ele + i;
  });

  return (
    <div className="nudosEllipseProgressTracker">
      {dotsArr.map(step => {
        const activeStyles = currentStep === step ? 'active' : '';
        const handleClick = handleClickStep ? () => handleClickStep(step) : undefined;
        const buttonStyles = handleClick ? 'cursor-pointer' : '';
        return <Dot key={`dot${step}`} className={`${activeStyles} ${buttonStyles}`} onClick={handleClick} />;
      })}
    </div>
  );
};

export default NudosEllipseProgressTracker;

export interface InudosEllipseProgressTracker {
  currentStep: number;
  totalSteps?: number;
  handleClickStep?: (clickedStep: number) => void;
}
