import { BordOneToneIcon, BordTooltip } from '../../BordDesignSystem';
import './PlatinumDiscountTooltip.scss';

const PlatinumDiscountTooltip = ({
  totalSaved,
  badgeValue,
  showFontBold = false,
  customTooltipStyles,
  textDiscount = 'Tienes un descuento total de'
}: IDiscountPlatinum): JSX.Element => {
  const formattedTotalSavedAmount = typeof totalSaved === 'number' ? `$${totalSaved} USD` : totalSaved;

  const tooltipData = {
    title: customTooltipStyles?.title || 'Descuentos en compras',
    description:
      customTooltipStyles?.description ||
      'Por ser cliente Platinum, obtienes un descuento de hasta 3% en todas tus compras para productos mayores a $170 USD.'
  };

  return (
    <div id="discountPlatinum">
      <BordTooltip
        position="bottom"
        customStyles="discountPlatinum"
        message={
          <div className="w-280">
            <div className="titleProduct">{tooltipData.title}</div>
            <div className="grayProduct">{tooltipData.description}</div>
          </div>
        }
      >
        <div className="platinumTextContainer">
          <BordOneToneIcon variant="zap" customWidth="1.4rem" />
          <span className="text-primary-bg-default text-bord-md">
            {textDiscount}{' '}
            {showFontBold ? <strong>{formattedTotalSavedAmount}</strong> : formattedTotalSavedAmount} por Platinum
          </span>
          {!!badgeValue && <div className="platinumItem">{badgeValue}</div>}
        </div>
      </BordTooltip>
    </div>
  );
};

export default PlatinumDiscountTooltip;

interface IDiscountPlatinum {
  totalSaved?: number | string;
  badgeValue?: string;
  showFontBold?: boolean;
  textDiscount?: string;
  customTooltipStyles?: {
    title?: string;
    description?: string;
  };
}
