import { useTranslation } from 'react-i18next';
import { IlogisticsDestinationData } from '../../types/global';
import { BordOneToneIcon } from '../BordDesignSystem';
import { BORD_ONE_TONE_ICONS, TbordOneToneIcon } from '../BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import { truncateText } from '../../utils/truncateText';
import './ToolLogisticsOrigenAndDestinationTag.scss';

/**
 * @property { IlogisticsDestinationData } origin - The data for the origin/current location of the tool
 * @property { number } isBuyAndHold - Number used as boolean to indicate when the tool is buy and hold
 * @property { IlogisticsDestinationData } destination - Optional, the data for the destination location of the tool (ie, the place where the location will be once the logistic service is delivered)
 * @property { boolean } logisticServiceIsConcluded - a boolean indicating if the logistic service is on course
 * @property { number } customCharactersLimit - the limit of characters before the text is truncated and show when hover
 */
const ToolLogisticsOrigenAndDestinationTag = ({
  origin,
  destination,
  logisticServiceIsConcluded,
  customCharactersLimit,
  assignedTo,
  isBuyAndHold,
  toolHasntBeenDelivered
}: {
  origin: IlogisticsDestinationData;
  destination?: IlogisticsDestinationData;
  logisticServiceIsConcluded?: boolean;
  customCharactersLimit?: number;
  assignedTo?: string | null;
  isBuyAndHold?: number;
  toolHasntBeenDelivered?: boolean;
}) => {
  const { t } = useTranslation();
  const i18nLanguageKey = 'nodi:toolsLogisticsOriginAndDestinationTag:';

  const getPlaceDisplayData = (locationData?: IlogisticsDestinationData) => {
    const country = locationData?.country?.name || '';
    const place = locationData?.place || '';
    const address = locationData?.address;
    const locationName = locationData?.locationName;
    const locationUserName = logisticServiceIsConcluded && !assignedTo ? undefined : locationData?.locationUserName;
    switch (place) {
      case 'store':
        return {
          icon: BORD_ONE_TONE_ICONS.shoppingCart,
          text: 'Tienda',
          address: 'Tienda'
        };
      case 'diagnostic':
        return {
          icon: BORD_ONE_TONE_ICONS.archive,
          text: t(`${i18nLanguageKey}diagnostic`),
          address: `${t(`${i18nLanguageKey}nudosWarehouse`)} ${country}`
        };
      case 'nudos':
        return {
          icon: BORD_ONE_TONE_ICONS.archive,
          text: t(`${i18nLanguageKey}nudosWarehouse`),
          address: `${t(`${i18nLanguageKey}nudosWarehouse`)} ${country}`
        };
      case 'warranty':
        return {
          icon: BORD_ONE_TONE_ICONS.shield,
          text: t(`${i18nLanguageKey}warranty`),
          address: t(`${i18nLanguageKey}brandAddress`)
        };
      case 'office':
        return {
          icon: BORD_ONE_TONE_ICONS.building3,
          text: `${t(`${i18nLanguageKey}offices`)} ${country}`,
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
      case 'other':
        return {
          icon: BORD_ONE_TONE_ICONS.mapPin,
          text: locationName || t(`${i18nLanguageKey}other`),
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
      case 'user':
        return {
          icon: BORD_ONE_TONE_ICONS.user,
          text: locationUserName || t(`${i18nLanguageKey}unassigned`),
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
      default:
        return {
          icon: BORD_ONE_TONE_ICONS.mapPin,
          text: t(`${i18nLanguageKey}unassigned`),
          address: address || t(`${i18nLanguageKey}noRegisteredAddress`)
        };
    }
  };

  const originDisplayData = getPlaceDisplayData(toolHasntBeenDelivered ? { place: 'store' } : origin);
  const destinationDisplayData = getPlaceDisplayData(toolHasntBeenDelivered ? origin : destination);
  const charactersLimit = customCharactersLimit ? customCharactersLimit : logisticServiceIsConcluded ? 35 : 15;
  const displayBuyAndHoldTag = !!isBuyAndHold && logisticServiceIsConcluded;

  return (
    <div className="ToolLogisticsOrigenAndDestinationTag">
      <div className="originSection">
        <div className="hoverElement">
          <div className="hoverElementTitle"> {originDisplayData?.text}</div>
          {originDisplayData?.address}
        </div>
        <BordOneToneIcon variant={originDisplayData?.icon as TbordOneToneIcon} customWidth="1.2rem" />
        {truncateText(originDisplayData?.text || '', charactersLimit)}
      </div>
      {(!logisticServiceIsConcluded || toolHasntBeenDelivered) && (
        <>
          <BordOneToneIcon variant={BORD_ONE_TONE_ICONS.arrowRight} customWidth="1.2rem" />
          <div className="destinationSection">
            <div className="hoverElement">
              <div className="hoverElementTitle"> {destinationDisplayData?.text}</div>
              {destinationDisplayData?.address}
            </div>
            <BordOneToneIcon variant={destinationDisplayData?.icon as TbordOneToneIcon} customWidth="1.2rem" />
            {truncateText(destinationDisplayData?.text || '', charactersLimit)}
          </div>
        </>
      )}
      {displayBuyAndHoldTag && <div className="buyAndHold">Buy & Hold</div>}
    </div>
  );
};

export default ToolLogisticsOrigenAndDestinationTag;
