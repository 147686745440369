import { BordFlag } from '../../../../../components/BordDesignSystem';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import { IorganizationBillingDataByCountry } from '../../../../../types/account';
import { DTOgetTaxationRelatedDefinitionsOptions } from '../../../../../types/checkout';
import BillingDataByCountryForm from '../BillingDataByCountryForm/BillingDataByCountryForm';
import './BillingDataByCountry.scss';

const BillingDataByCountry = ({
  countryBillingData,
  getOrganizationBillingData,
  loadingOrganizationBillingCountries,
  taxationRelatedDefinitionsOptions
}: {
  countryBillingData: IorganizationBillingDataByCountry;
  getOrganizationBillingData: () => Promise<void>;
  loadingOrganizationBillingCountries: boolean;
  taxationRelatedDefinitionsOptions?: DTOgetTaxationRelatedDefinitionsOptions;
}) => {
  const { countryData, billingEntities } = countryBillingData || {};
 
  return (
    <div className="billingDataByCountry">
      
      {billingEntities.map(billingEntity => {
        return (
          <>
            {countryData && (
              <div className="countryTitle">
                <BordFlag country={countryData.code as TcountryCode} standardSize={20} variant="circle" />
                <div className="countryName">{countryData?.name}</div>
              </div>
            )}
            <BillingDataByCountryForm
              key={`${billingEntity.id}`}
              initialBillingData={billingEntity}
              updateDataCallback={getOrganizationBillingData}
              showSkeletonLoader={loadingOrganizationBillingCountries}
              taxationRelatedDefinitionsOptions={taxationRelatedDefinitionsOptions}
            />
          </>
        );
      })}
    </div>
  );
};

export default BillingDataByCountry;
