const RoundTurksAndCaicos = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 16.0038 16 0.00175 16 0C24.8365 0 32 7.1635 32 16Z"
        />
        <path
          fill="white"
          d="M15.957 15.9998H15.9998C15.9998 15.9855 15.9998 15.9715 15.9998 15.957C15.9856 15.9713 15.9713 15.9856 15.957 15.9998Z"
        />
        <path
          fill="white"
          d="M16 8.34781C16 5.5325 16 3.68781 16 0H15.9973C7.16194 0.0015 0 7.16431 0 16H8.34781V11.2993L13.0485 16H15.9572C15.9715 15.9858 15.9858 15.9715 16.0001 15.9572C16.0001 14.8793 16.0001 13.9174 16.0001 13.0486L11.2992 8.34781H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51179 5.59223 2.08691 8.09466V16H6.26085V6.26098V6.26085H16C16 4.94441 16 3.69029 16 2.08691H8.09466Z"
        />
        <path
          fill="#F93939"
          d="M15.9998 14.0322L10.3152 8.34766H8.34766V8.34778L15.9997 15.9998H15.9998C15.9998 15.9998 15.9998 14.6431 15.9998 14.0322Z"
        />
        <path
          fill="#FFDA2C"
          d="M18.0869 8.34766V15.9998C18.0869 19.7273 22.9565 20.8694 22.9565 20.8694C22.9565 20.8694 27.826 19.7273 27.826 15.9998V8.34766H18.0869Z"
        />
        <path
          fill="#FF9811"
          d="M22.2611 11.1306C22.2611 11.899 21.6383 13.9132 20.8698 13.9132C20.1014 13.9132 19.4785 11.899 19.4785 11.1306C19.4785 10.3621 20.8698 9.73926 20.8698 9.73926C20.8698 9.73926 22.2611 10.3621 22.2611 11.1306Z"
        />
        <path
          fill="#A2001D"
          d="M25.9529 12.6459C26.1774 12.0981 26.352 11.2973 26.352 10.8987C26.352 10.2583 25.5172 9.73926 25.5172 9.73926C25.5172 9.73926 24.6824 10.2584 24.6824 10.8987C24.6824 11.2973 24.857 12.0981 25.0815 12.6459L24.5996 13.734C24.8829 13.8491 25.1925 13.9131 25.5172 13.9131C25.8419 13.9131 26.1515 13.8491 26.4347 13.734L25.9529 12.6459Z"
        />
        <path
          fill="#6DA544"
          d="M21.9134 15.9998C21.9134 15.9998 21.2178 17.3911 21.2178 18.7824H24.696C24.696 17.3911 24.0003 15.9998 24.0003 15.9998L22.9568 15.3042L21.9134 15.9998Z"
        />
        <path
          fill="#F93939"
          d="M24.0001 16.0002V15.6524C24.0001 15.0761 23.5329 14.6089 22.9566 14.6089C22.3803 14.6089 21.9131 15.0761 21.9131 15.6524V16.0002H24.0001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTurksAndCaicos;
