const RoundEcuador = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path fill="#FFDA2C" d="M0 16C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16L16 17.3913L0 16Z" />
        <path
          fill="#F93939"
          d="M2.14062 24C4.90719 28.7823 10.0774 32 15.9996 32C21.9218 32 27.0921 28.7823 29.8586 24L15.9996 22.9565L2.14062 24Z"
        />
        <path
          fill="#1A47B8"
          d="M29.859 24C31.2204 21.6466 32 18.9144 32 16H0C0 18.9144 0.779625 21.6466 2.141 24H29.859Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9998 21.5652C19.0733 21.5652 21.5649 19.0736 21.5649 16C21.5649 12.9264 19.0733 10.4348 15.9998 10.4348C12.9262 10.4348 10.4346 12.9264 10.4346 16C10.4346 19.0736 12.9262 21.5652 15.9998 21.5652Z"
        />
        <path
          fill="#3ECBF8"
          d="M15.9997 19.4783C14.0818 19.4783 12.5215 17.9179 12.5215 16V13.9131C12.5215 11.9951 14.0819 10.4348 15.9997 10.4348C17.9176 10.4348 19.478 11.9952 19.478 13.9131V16C19.478 17.9179 17.9177 19.4783 15.9997 19.4783Z"
        />
        <path
          fill="black"
          d="M21.5651 7.6523H17.3911C17.3911 6.88392 16.7682 6.26099 15.9998 6.26099C15.2314 6.26099 14.6085 6.88392 14.6085 7.6523H10.4346C10.4346 8.42074 11.1039 9.04361 11.8722 9.04361H11.8259C11.8259 9.81205 12.4488 10.4349 13.2172 10.4349C13.2172 11.2034 13.8401 11.8262 14.6085 11.8262H17.3911C18.1596 11.8262 18.7824 11.2034 18.7824 10.4349C19.5509 10.4349 20.1738 9.81205 20.1738 9.04361H20.1274C20.8958 9.04361 21.5651 8.42067 21.5651 7.6523Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEcuador;
