import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton, BordTextArea } from '../../../../components/BordDesignSystem';
import './AdditionalServicesCommentsModal.scss';

const AdditionalServicesCommentsModalContent = ({
  submitModalCallback,
  currentComment
}: {
  submitModalCallback: (modalData: string) => void;
  currentComment?: string;
}) => {
  const limitOfCharacters = 50;

  const { t } = useTranslation();
  const translationKey = 'services:logistics:additionalServicesCommentsModalContent:';
  const toolConfiguration = t(`${translationKey}toolConfiguration`);
  const textAreaPlaceholder = t(`${translationKey}textAreaPlaceholder`);
  const buttonText = t('recurrentWords:continue');
  const toolConfigurationCommentText = t(`${translationKey}toolConfigurationCommentText`, { value: limitOfCharacters });

  const [toolConfigurationComment, setToolConfigurationComment] = useState('');

  const updateToolConfigurationComment = (newComment: string) => setToolConfigurationComment(newComment);

  const submitModal = () => submitModalCallback(toolConfigurationComment);
  const insufficientLengthError =
    !!toolConfigurationComment && toolConfigurationComment.length < limitOfCharacters
      ? toolConfigurationCommentText
      : undefined;
  const isButtonDisabled = !toolConfigurationComment || !!insufficientLengthError;

  useEffect(() => {
    currentComment && setToolConfigurationComment(currentComment);
  }, [currentComment]);

  return (
    <div className="AdditionalServicesCommentsModalContent">
      <div className="containerTextArea">
        <BordTextArea
          label={toolConfiguration}
          placeholder={textAreaPlaceholder}
          value={toolConfigurationComment}
          maxLength={1000}
          errorText={insufficientLengthError}
          setInputValue={updateToolConfigurationComment}
          bordTextAreaContentClass="limitsTextArea"
        />
      </div>
      <BordButton customWidth="w-380" label={buttonText} onClick={submitModal} disabled={isButtonDisabled} />
    </div>
  );
};

export default AdditionalServicesCommentsModalContent;
