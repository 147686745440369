const SquareMalawi = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path fill="#249F58" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#151515" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M14.9205 4.26663C14.075 2.98023 12.6346 2.1333 11.0003 2.1333C9.36598 2.1333 7.92551 2.98023 7.08008 4.26663H14.9205Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMalawi;
