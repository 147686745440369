import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ToolSerial } from '../../../../../components';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import ProductPills from '../../../../../components/ProductPills/ProductPills';
import { IshipmentDetails, IshipmentProductInfo, ORDER_SHIPMENT_STATUS } from '../../../../../types/orders';
import { NudosRedirectionIcon } from '../../../../../components/DesignSystem';
import { BordPhoto } from '../../../../../components/BordDesignSystem';
import './ShipmentProductCard.scss';

const ShipmentProductCard = ({
  productData,
  shipmentData
}: {
  productData: IshipmentProductInfo;
  shipmentData: IshipmentDetails;
}) => {
  const { t } = useTranslation();
  const { id: orderId } = useParams<{ id: string }>();

  const i18nLanguageKey = 'nodi:orderDetails:shipmentProductCard:';
  const image = productData?.image || '';
  const name = productData?.name || `${t(`${i18nLanguageKey}noName`)}`;
  const pills = typeof productData?.pills === 'string' ? JSON.parse(productData.pills) : productData?.pills || {};
  const serial = productData?.serial || `${t(`${i18nLanguageKey}noSerialNumber`)}`;
  const redirectionsTranslationKey = `nodi:headBar:`;
  const shipmentIsConfirmed =
    !!shipmentData?.statusName &&
    ![ORDER_SHIPMENT_STATUS.PENDING, ORDER_SHIPMENT_STATUS.CANCELED].includes(
      shipmentData?.statusName as ORDER_SHIPMENT_STATUS
    );
  const redirectionToToolIsDeactivated = !productData?.productId || !shipmentIsConfirmed;

  const redirectionToToolDetailsData = {
    pathname: `/nodi/details/${productData?.productId}`,
    state: {
      customReturnPath: `/nodi/orders/details/${orderId}`,
      customNavigationTitle: t(`${redirectionsTranslationKey}headerTextBold:orders`),
      customNavigationSubtitle: t(`${redirectionsTranslationKey}headerTextRegular:productDetails`)
    }
  };

  const cantViewToolYetTooltip = (
    <div className="cantViewToolYetTooltip">{t(`${i18nLanguageKey}cantViewToolYetTooltip`)}</div>
  );

  return (
    <div className="shipmentProductCard">
      <div className="photoSection">
        <BordPhoto
          variant="square"
          size="s56"
          url={image}
          oneToneIconProps={{ variant: 'logoMaster', standardSize: 20 }}
          toolModelMarkerProps={{
            customSize: 3.2,
            isBordTool: productData?.referenceModel === 'CatalogProduct'
          }}
        />
      </div>

      <div className="nameBrandAndPillsSection">
        <div className="nameAndBrand">
          <NudosHoverText customClassName="name" onlyIfTruncated text={name} charactersLimit={35} />
          <NudosRedirectionIcon
            showHoverTooltip={!shipmentIsConfirmed}
            isDeactivated={redirectionToToolIsDeactivated}
            redirectionDataObject={redirectionToToolDetailsData}
            toolTipComponent={cantViewToolYetTooltip}
            bordOneToneIconProps={{ standardSize: 14 }}
          />
        </div>
        <ProductPills pills={pills} positionTooltip="top" />
      </div>

      <div className="toolSerialContainer">
        <ToolSerial serial={serial} charactersLimitForHover={18} />
      </div>

      <div className="containerWarranty">
        <div className="textWarranty">{`${t(`${i18nLanguageKey}warranty`)}`}</div>
        <div>{productData?.warranty || '-'}</div>
      </div>
    </div>
  );
};

export default ShipmentProductCard;
