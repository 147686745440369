import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { NudosEllipseProgressTracker } from '../../../../components/NudosComponents';
import SignUpFormStep1 from './Components/SignUpFormStep1/SignUpFormStep1';
import SignUpFormStep2 from './Components/SignUpFormStep2/SignUpFormStep2';
import SignUpFormStep3 from './Components/SignUpFormStep3/SignUpFormStep3';
import InviteCollaboratorModal from '../../../../components/InviteCollaboratorModal';
import SignUpFromInvitation from './Components/SignUpFromInvitation/SignUpFromInvitation';
import { getCountriesList } from '../../../../services/countries.service';
import { ICountryDetail } from '../../../../types/countries';
import BordLogo from '../../../../components/BordDesignSystem/BordLogo/BordLogo';
import LanguageSelectionButton from '../../../../components/SelectLanguageButton/SelectLanguageButton';
import { getDefaultLanguage } from '../../../../utils/getDefaultLanguage';
import BordTextButton from '../../../../components/BordDesignSystem/BordTextButton/BordTextButton';
import useSignStore from '../../state/useSignStore';
import './SignUpForm.scss';

const SignUpForm = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { existingOrganizationData } = useSignStore();

  const [formStep, setFormStep] = useState(0);
  const [businessNameAlreadyRegisteredError, setBusinessNameAlreadyRegisteredError] = useState<string>();
  const [openInviteCollaboratorModal, setOpenInviteCollaboratorModal] = useState(false);
  const [countriesOpenForRegistration, setCountriesOpenForRegistration] = useState<ICountryDetail[]>([]);
  const [countryPhone, setCountryPhone] = useState<ICountryDetail>();

  const emailParam = new URLSearchParams(search.replace('+', '{{i}}')).get('email')?.replace('{{i}}', '+');
  const registrationInfoIdParam = new URLSearchParams(search).get('registrationInfoId');

  const translationKey = 'authentication:SignUpForm:';
  const titleText = emailParam ? t(`${translationKey}invitationTitleText`) : t(`${translationKey}titleTextDefault`);
  const subTitleText = emailParam
    ? t(`${translationKey}invitationSubTitleText`)
    : t(`${translationKey}subTitleTextDefault`);
  const language = getDefaultLanguage();

  const getCountriesOpenForRegistration = async () => {
    const listOfCountriesOpenForRegistration = await getCountriesList('registry');
    setCountriesOpenForRegistration(listOfCountriesOpenForRegistration);
  };

  const goToLogin = () => {
    localStorage.removeItem('userFirebaseData');
    sessionStorage.removeItem('newRegisterInformation');
    push('/login');
  };

  const signUpSteps = [
    <SignUpFormStep1
      key="step1"
      setFormStep={setFormStep}
      businessNameAlreadyRegisteredError={businessNameAlreadyRegisteredError}
      setBusinessNameAlreadyRegisteredError={setBusinessNameAlreadyRegisteredError}
      setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
      countriesOpenForRegistration={countriesOpenForRegistration}
    />,
    <SignUpFormStep2
      key="step2"
      updatePhoneCountryCallback={setCountryPhone}
      setFormStep={setFormStep}
      countriesList={countriesOpenForRegistration}
      phoneCountry={countryPhone}
    />,
    <SignUpFormStep3
      key="step3"
      countryPhone={countryPhone}
      setFormStep={setFormStep}
      setBusinessNameAlreadyRegisteredError={setBusinessNameAlreadyRegisteredError}
    />
  ];

  useEffect(() => {
    getCountriesOpenForRegistration();
  }, []);

  return (
    <div id="signUpForm">
      <div className="contentsContainer">
        <LanguageSelectionButton width="4.4rem" currentLanguage={language} />
        {!emailParam && <NudosEllipseProgressTracker currentStep={formStep} />}

        <BordLogo customWidth="12rem" />

        <div className="title">
          <Trans i18nKey={titleText} components={{ 1: <span className="text-bord-red" /> }} />
        </div>

        <div className="subtitle">
          <Trans i18nKey={subTitleText} components={{ 1: <span className="text-bord-red" /> }} />
        </div>

        <div className="formSection">
          {!emailParam && signUpSteps[formStep]}
          {emailParam && (
            <SignUpFromInvitation emailParam={emailParam} registrationInfoIdParam={registrationInfoIdParam} />
          )}
        </div>

        <div className="loginInformation">
          <div className="question">{t(`${translationKey}loginRedirectionText`)}</div>
          <BordTextButton text={t(`${translationKey}loginRedirectionButton`)} onClick={goToLogin} />
        </div>
      </div>

      {openInviteCollaboratorModal && existingOrganizationData && (
        <InviteCollaboratorModal
          setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
          existingOrganizationData={existingOrganizationData}
        />
      )}
    </div>
  );
};

export default SignUpForm;
