const RoundMozambique = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M8.6479 22.261L4.68652 27.3133C7.58177 30.2089 11.5813 32.0001 15.9997 32.0001C22.6141 32.0001 28.29 27.9858 30.7267 22.261H8.6479Z"
        />
        <path
          fill="#496E2D"
          d="M30.7515 9.79706C28.3285 4.04138 22.6365 0 15.9997 0C11.5813 0 7.58177 1.79119 4.68652 4.68681L8.6934 9.79706H30.7515Z"
        />
        <path
          fill="black"
          d="M3.47852 11.7681V20.1738H31.4491C31.808 18.8431 32.0003 17.4441 32.0003 15.9999C32.0003 14.5348 31.8029 13.1161 31.434 11.7681H3.47852Z"
        />
        <path
          fill="#A2001D"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="#FFDA2C"
          d="M6.4756 11.8262L7.51154 15.0147H10.8644L8.15204 16.9854L9.18791 20.174L6.4756 18.2034L3.76316 20.174L4.79929 16.9854L2.08691 15.0147H5.43954L6.4756 11.8262Z"
        />
        <path fill="white" d="M3.44434 16H9.50834V18.7826H3.44434V16Z" />
        <path
          fill="black"
          d="M10.656 12.81L9.67223 11.8262L6.47548 15.0229L3.27873 11.8262L2.29492 12.81L5.49448 16.0039L2.29492 19.2035L3.27873 20.174L6.47548 16.983L9.67223 20.174L10.656 19.2035L7.45648 16.0039L10.656 12.81Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMozambique;
