const SquareSlovakia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path fill="#F93939" fillRule="evenodd" d="M0 10.6665H22V15.9998H0V10.6665Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M0 0H22V5.33333H0V0ZM10.4029 10.6667H10.4762V5.33333H3.14286V10.6667H3.21619C3.168 10.8395 3.14286 11.0187 3.14286 11.2C3.14286 12.672 6.80952 13.8667 6.80952 13.8667C6.80952 13.8667 10.4762 12.672 10.4762 11.2C10.4762 11.0187 10.451 10.8395 10.4029 10.6667Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M9.42852 10.6666V4.2666H4.19043V10.6666C4.19043 11.8453 6.80948 12.7999 6.80948 12.7999C6.80948 12.7999 9.42852 11.8453 9.42852 10.6666Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M6.2853 8.5335V7.46683H5.23768V6.40016H6.2853V5.3335H7.33291V6.40016H8.38053V7.46683H7.33291V8.5335H8.90434V9.60016H7.33291V10.6668H6.2853V9.60016H4.71387V8.5335H6.2853Z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M6.80948 12.8001C6.80948 12.8001 9.42852 11.8454 9.42852 10.6667C9.42852 9.48808 8.38091 9.60008 6.80948 10.6667C5.23805 9.60008 4.19043 9.48808 4.19043 10.6667C4.19043 11.8454 6.80948 12.8001 6.80948 12.8001Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSlovakia;
