import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={19} height={14} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M14.0744 0H3.92561C2.87927 0 1.89146 0.430769 1.15244 1.21154C0.409756 1.98846 0 3.02692 0 4.12692V9.86923C0 10.9692 0.409756 12.0077 1.15244 12.7885C1.89512 13.5692 2.87927 13.9962 3.92927 14H14.0744C15.1207 14 16.1085 13.5692 16.8512 12.7885C17.5939 12.0077 18 10.9731 18.0037 9.86923V4.12692C18.0037 3.02692 17.5939 1.98846 16.8512 1.20769C16.1085 0.430769 15.1207 0 14.0744 0ZM16.3902 6.18077V9.87308C16.3902 11.2154 15.3512 12.3077 14.0744 12.3077H3.92561C2.64878 12.3077 1.60976 11.2154 1.60976 9.87308V6.18077V5.79615H1.97561H16.0244H16.3902V6.18077ZM15.9841 4.10385H2.01585H1.56585L1.65366 3.64231C1.75976 3.09231 2.04512 2.59615 2.45854 2.24231C2.87195 1.88846 3.39146 1.69231 3.92195 1.69231H14.0671C14.6012 1.69231 15.1207 1.88846 15.5305 2.24231C15.9439 2.59615 16.2293 3.09231 16.3354 3.64231L16.4232 4.10385H15.9841Z"
      fill="#54A1FC"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CardIcon = memo(ForwardRef);
export default CardIcon;
