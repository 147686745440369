const RoundNorway = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M.550781 20.1738C1.47653 23.6088 3.51684 26.5859 6.26047 28.694V20.1738H.550781ZM14.6084 31.939C15.067 31.9785 15.5308 31.9999 15.9997 31.9999 23.3921 31.9999 29.6125 26.9861 31.4485 20.1738H14.6084V31.939ZM31.4485 11.8261C29.6125 5.01381 23.3921 0 15.9997 0 15.5308 0 15.067.021375 14.6084.060875V11.8261H31.4485ZM6.26047 3.30591C3.51684 5.41403 1.47653 8.3911.550781 11.826H6.26047V3.30591Z"
        />
        <path
          fill="#1A47B8"
          d="M31.8646 13.9131H12.5218H12.5218V0.380371C11.0431 0.708246 9.64131 1.23968 8.34781 1.9455V13.913V13.9131H0.135438C0.046375 14.5962 0 15.2927 0 16.0001C0 16.7074 0.046375 17.4039 0.135438 18.087H8.34775H8.34781V30.0546C9.64131 30.7603 11.0431 31.2919 12.5218 31.6197V18.0872V18.0871H31.8646C31.9535 17.4039 32 16.7074 32 16.0001C32 15.2927 31.9535 14.5962 31.8646 13.9131Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNorway;
