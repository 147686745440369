import { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import useShopStore from '../../state/useShopContext';
import useEcommerceControler from '../../views/ecommerce/ecommerce.controller';
import { ecommerceRoutes } from '../routes';
import useCheckoutStore from '../../views/ecommerce/checkout/state';
import useCartState from '../../state/useCartState';
import { formatOrgData } from '../../utils/orgFormatData';
import { ModalToSelectStoreCountry } from '../../views/ecommerce/Components';
import useStoreCountryState from '../../state/useStoreCountryState';
import { EcommerceSidebar } from '../../components/SideBar';
import { shouldHideSidebar } from '../../components/SideBar/sidebar.utils';
import { EcommerceHeader } from '../../components/BordHeader';
import './Ecommerce.scss';

const switchRoutes = (
  <Switch>
    {ecommerceRoutes.map((prop, key) => {
      if (prop.layout === 'catalogue') {
        return <Route path={`/${prop.layout}${prop.path}`} component={prop.component} key={key} exact />;
      }
      return null;
    })}
  </Switch>
);

const Ecommerce = () => {
  const { pathname } = useLocation();
  const { fillItems } = useShopStore();
  const { loadStep } = useCheckoutStore();
  const { storeCountry } = useStoreCountryState();
  const [openModalToStoreCountry, setOpenModalToStoreCountry] = useState<boolean>(false);
  const { preloadGlobalShippingCartUponReloading } = useCartState();
  const { getUserData, user, userLogin, replace } = useEcommerceControler();

  const { push } = useHistory();
  const formatOrgInfo = formatOrgData();

  //blockedCompany 332
  const blockMarketPlace = formatOrgInfo?.organizationId === 332;

  const hideSidebar = shouldHideSidebar(pathname);
  const moduleSidebarStyles = hideSidebar ? 'moduleWithoutSidebar' : 'moduleWithSidebar';

  const redirectErrorScreen = () => {
    if (blockMarketPlace) push('/nodi/not-found');
  };

  const checkSelectStoreCountry = () => {
    if (!storeCountry) setOpenModalToStoreCountry(true);
  };

  useEffect(() => {
    fillItems();
    loadStep();
    preloadGlobalShippingCartUponReloading();
    checkSelectStoreCountry();
  }, []);

  useEffect(() => {
    if (user)
      getUserData(userLogin).then(response => response?.organizationState !== 'active' && replace('/nodi/tools'));
  }, [user]);

  useEffect(() => {
    redirectErrorScreen();
  }, [blockMarketPlace]);

  return (
    <div id="ecommerceLayout" className={moduleSidebarStyles}>
      {openModalToStoreCountry && user && (
        <ModalToSelectStoreCountry closeModalExternally={() => setOpenModalToStoreCountry(false)} origin="ecommerce" />
      )}
      {!blockMarketPlace && (
        <>
          {user && <EcommerceSidebar />}
          {user && <EcommerceHeader />}
          <section id="content">{user && switchRoutes}</section>
        </>
      )}
    </div>
  );
};

export default Ecommerce;
