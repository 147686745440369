import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NudosBreadcrumbButton } from '../../../../components/NudosComponents';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { getLogisticServiceDetailsById } from '../../../../services/logisticsServicesModule.services';
import { LogisticsServiceDetailsHeader } from './Components/LogisticsServiceDetailsHeader/LogisticsServiceDetailsHeader';
import { IlogisticServiceDetailedData } from '../../../../types/logisticsServicesModule.types';
import LogisticServiceToolCard from './Components/LogisticServiceToolCard/LogisticServiceToolCard';
import LogisticServiceStepper from './Components/LogisticServiceStepper/LogisticServiceStepper';
import LogisticsDetailsSummary from './Components/LogisticsDetailsSummary/LogisticsDetailsSummary';
import { IreturnLocation } from '../../../../types/global';
import LogisticServiceNotifications from './Components/LogisticServiceNotifications/LogisticServiceNotifications';
import useSidebar from '../../../../hooks/useSidebar';
import './LogisticsServiceDetail.scss';

const LogisticsServiceDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { push, goBack } = useHistory();
  const { search, state }: { search: string; state: IreturnLocation } = useLocation();
  const { moduleSidebarStyles } = useSidebar();

  const [loading, setLoading] = useState<boolean>(true);
  const [logisticServiceData, setLogisticServiceData] = useState<IlogisticServiceDetailedData>();

  const params = new URLSearchParams(search);
  const origin = params.get('from');
  const productId = params.get('id');
  const { tools, status } = logisticServiceData || {};

  const logisticServiceText = t('nodi:headBar:headerTextBold:logisticService');
  const employeeText = t('nodi:orderDetails:getReturnText:employee-details');
  const toolsText = t('nodi:orderDetails:getReturnText:tool-details');

  const originTextFuction = () => {
    if (state?.returnText) return state?.returnText;
    if (origin === 'dashboard') return 'Dashboard';
    if (origin === 'tools') return toolsText;
    if (origin === 'tools-listing') return toolsText + 's';
    if (origin === 'employeeDetail') return employeeText;
    return logisticServiceText;
  };

  const returnText = originTextFuction();

  const returnAction = () => {
    if (state?.returnAction) return push(state?.returnAction);
    if (state?.returnText) return goBack();
    switch (origin) {
      case 'dashboard':
        push('/nodi/dashboard');
        break;
      case 'tools':
        push(`/nodi/details/${productId}`);
        break;
      case 'tools-listing':
        push(`/nodi/tools/`);
        break;
      case 'employeeDetail':
        push(`/nodi/employees/details/${productId}`);
        break;
      default:
        push('/nodi/logistics-services');
        break;
    }
  };

  const getLogisticServiceDetail = async () => {
    if (!id) return;
    setLoading(true);
    try {
      const response = await getLogisticServiceDetailsById(id);
      setLogisticServiceData(response);
      setLoading(false);
    } catch {
      displayErrorNotification();
      setLoading(false);
    }
  };

  useEffect(() => {
    getLogisticServiceDetail();
  }, []);

  return (
    <div id="logisticsServiceDetail" className={moduleSidebarStyles}>
      <LogisticsServiceDetailsHeader logisticServiceData={logisticServiceData} isLoading={loading} />
      <div className="logisticServiceInformation">
        <div className="leftContent">
          <LogisticServiceNotifications
            isLoading={loading}
            logisticServiceData={logisticServiceData}
            getLogisticServiceDetailCallback={getLogisticServiceDetail}
          />
          <LogisticsDetailsSummary
            loading={loading}
            logisticServiceData={logisticServiceData}
            setLoadingCallback={setLoading}
            getLogisticServiceDetailCallback={getLogisticServiceDetail}
          />
          <div className="whiteSpaceAtTheBottom w-10 h-10" />
        </div>
        <div className="rightContent">
          <div className="stepperContainer">
            <LogisticServiceStepper
              currentStep={status}
              manualQuote={logisticServiceData?.manualQuote}
              loading={loading}
            />
          </div>
          {tools?.map((tool, i) => (
            <LogisticServiceToolCard
              key={`tool${i}${tool.productId}`}
              logisticServiceId={logisticServiceData?.id}
              toolData={tool}
              isLoading={loading}
            />
          ))}
          {!tools &&
            [0, 1, 2, 3, 4, 5].map(tool => {
              return (
                <LogisticServiceToolCard
                  logisticServiceId={logisticServiceData?.id}
                  key={`toolCardSkeletonLoader${tool}`}
                  isLoading
                />
              );
            })}
          <div className="whiteSpaceAtTheBottom w-10 h-10" />
        </div>
      </div>
    </div>
  );
};

export default LogisticsServiceDetail;
