const RoundJersey = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M28.6939 25.7422L18.9517 16.0001H18.9516L28.6939 6.25777C28.2737 5.71177 27.8141 5.18646 27.314 4.68627C26.8138 4.18615 26.2886 3.72646 25.7425 3.3064L16.0003 13.0486L16.0003 13.0486L6.25808 3.3064C5.71214 3.72652 5.18677 4.18608 4.68658 4.68627C4.18639 5.18646 3.72677 5.71177 3.3067 6.25777L13.0489 15.9999L13.049 16L3.30664 25.7423C3.72683 26.2883 4.18645 26.8136 4.68652 27.3138C5.1867 27.8139 5.71195 28.2736 6.25802 28.6936L16.0003 18.9515L16.0003 18.9514L25.7425 28.6936C26.2885 28.2735 26.8138 27.8138 27.314 27.3137C27.8142 26.8135 28.2738 26.2882 28.6939 25.7422Z"
        />
        <path
          fill="#FFDA2C"
          d="M13.2178 4.86961L16.0004 5.56523L18.783 4.86961V2.50442L17.67 3.06092L16.0004 1.39136L14.3308 3.06092L13.2178 2.50442V4.86961Z"
        />
        <path
          fill="#F93939"
          d="M13.2178 4.86963V6.60888C13.2178 8.73888 16.0004 9.3915 16.0004 9.3915C16.0004 9.3915 18.783 8.73882 18.783 6.60888V4.86963H13.2178Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundJersey;
