import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmailValid } from '../../../../../../utils/formValidations';
import { BordTextInput } from '../../../../../../components/BordDesignSystem';
import './ModalShareInvoice.scss';
import { BordButton } from '../../../../../../components/BordDesignSystem';

const ModalShareInvoiceContent = ({
  emailShareInvoice,
  closeModalCallback,
  setEmailShareInvoice
}: {
  emailShareInvoice?: string;
  closeModalCallback: () => void;
  setEmailShareInvoice?: (email: string) => void;
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>();

  const i18nLanguageKey = 'designSystemComponents:modalShareInvoiceContent:';
  const modalTitle = t(`${i18nLanguageKey}modalTitle`);
  const modalSubtitle = t(`${i18nLanguageKey}modalSubtitle`);
  const nudosTextInputLabel = t(`${i18nLanguageKey}nudosTextInputLabel`);
  const nudosButtonText = t(`${i18nLanguageKey}nudosButtonText`);

  const handleSetEmailShareInvoice = () => {
    !!email && setEmailShareInvoice && setEmailShareInvoice(email);
    closeModalCallback();
  };

  const isButtonDisabled = () => {
    if (!email) return true;
    if (email.length < 6) return true;
    return !isEmailValid(email);
  };

  return (
    <div className="ModalShareInvoiceContent">
      <div className="modalTitle">{modalTitle}</div>
      <div className="modalSubtitle">{modalSubtitle}</div>
      <BordTextInput
        label={nudosTextInputLabel}
        isFilled={!!email}
        currentText={emailShareInvoice}
        placeholder={nudosTextInputLabel}
        setCurrentText={e => setEmail(e)}
        standardSize="w-280"
        className="emailInput"
      />
      <div className="containerButtonInvoice">
        <BordButton
          label={nudosButtonText}
          onClick={handleSetEmailShareInvoice}
          customWidth="w-380"
          disabled={isButtonDisabled()}
        />
      </div>
    </div>
  );
};

export default ModalShareInvoiceContent;
