import { useTranslation } from 'react-i18next';
import { BordPhoto } from '../../../../../../../components/BordDesignSystem';
import { IassignedTool } from '../../../../../../../types/global';
import './EmployeeAssignedToolsSummary.scss';

const EmployeeAssignedToolsSummary = ({ currentTools = [] }: { currentTools?: IassignedTool[] }) => {
  const { t } = useTranslation();

  const i18nLanguageKey = 'nodi:employee:employeeCard:';
  const toolsToShow = currentTools.slice(0, 2);
  const styles = { justifyContent: currentTools?.length ? 'flex-start' : 'center' };

  return (
    <div className="employeeAssignedToolsSummary" style={styles}>
      {toolsToShow.map(({ productId, image }) => {
        return (
          <BordPhoto
            key={productId}
            size="s32"
            variant="square"
            url={image}
            imageProps={{ style: { width: '90%', borderRadius: '0.4rem' } }}
          />
        );
      })}
      {!!currentTools?.length && currentTools.length > 1 && <div className="counter">+{currentTools?.length}</div>}
      {!currentTools?.length && <p className="noToolsText">{t(`${i18nLanguageKey}noTools`)}</p>}
    </div>
  );
};

export default EmployeeAssignedToolsSummary;
