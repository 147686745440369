import { useTranslation } from 'react-i18next';
import { TlogisticsUserHeaderType } from '../../../types/requestLogisticsModule';
import { NudosHoverText } from '../../NudosComponents';
import { capitalize } from '../../../utils/unCamelCase';
import { BordPhoto } from '../../BordDesignSystem';
import { TcountryCode } from '../../BordDesignSystem/BordFlag/BordFlag.types';
import { TstoragePlace } from '../../../types/assignationFluxes';
import { bordColorTheme } from '../../../styles/constants';
import { TbordOneToneIcon } from '../../BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import { getLocationIcon } from '../../../utils/global.utils';
import { LOCATION_PLACE_TYPE } from '../../../types/global';
import './NudosLogisticServiceUserHeader.scss';

/**
 * @property { 'origin' | 'destination' | 'customized' } type - The type of user header, can be one of 'origin' | 'destination' | 'customized'
 * @property { string } name
 * @property { string } photoUrl - In case the photoJSX prop is passed that one takes precedence
 * @property { string } flagCountryCode- If no value is provided no flag will be shown
 * @property { string } customTitle - In order for this to be shown it is required that the type prop be "customized"
 * @property { JSX.Element } photoJSX - A JSX element to show instead of the photo url. The styles for this componente are a responsability for componente that uses the NudosLogisticServiceUserHeader
 * @property { string } customClassName - A classname for customized styles
 */
const NudosLogisticServiceUserHeader = ({
  type,
  place = 'other',
  name,
  photoUrl,
  flagCountryCode,
  customTitle,
  showSkeletonLoader = false,
  customClassName
}: INudosLogisticServiceUserHeader) => {
  const { t } = useTranslation();
  const originText = capitalize(t('recurrentWords:origin'));
  const destinationText = capitalize(t('recurrentWords:destination'));

  const i18nLanguageKey = 'services:nudosLogisticServiceUserHeader';
  const photo = photoUrl?.replace(/\s/g, '%20') || '';
  const inactiveNameStyles = !name ? 'inactive' : '';
  const customStyleClassname = customClassName || '';
  const getNameText = () => {
    if (name) return name;
    if (type === 'origin') return t(`${i18nLanguageKey}:originHoverText`);
    if (type === 'destination') return t(`${i18nLanguageKey}:destinationHoverText`);
    return '';
  };

  const getTitle = () => {
    switch (type) {
      case 'origin':
        return originText;
      case 'destination':
        return destinationText;
      case 'customized':
        return customTitle || '';
      default:
        return '';
    }
  };

  if (showSkeletonLoader) return NudosLogisticServiceUserHeaderSkeletonLoader();
  return (
    <div className={`NudosLogisticServiceUserHeader ${customStyleClassname}`}>
      <div className="userTitle">{getTitle()}</div>
      <div className="userInformation">
        <BordPhoto
          size="s56"
          url={photo}
          imageProps={{
            style: {
              width: '100%',
              height: '100%',
              borderRadius: '1.1rem',
              objectFit: 'cover',
              objectPosition: 'center center'
            }
          }}
          flagProps={{
            country: flagCountryCode as TcountryCode,
            variant: 'circle',
            standardSize: 24
          }}
          oneToneIconProps={{
            variant: getLocationIcon({ place: place as LOCATION_PLACE_TYPE }) as TbordOneToneIcon,
            customWidth: '3.2rem',
            stroke: bordColorTheme.content.secondary
          }}
          style={{
            backgroundColor: bordColorTheme.layout.level2,
            borderColor: bordColorTheme.stroke.interactive
          }}
          className={inactiveNameStyles}
        />
        <NudosHoverText
          onlyIfTruncated
          customClassName={`userName ${inactiveNameStyles}`}
          text={getNameText()}
          charactersLimit={40}
        />
      </div>
    </div>
  );
};

export default NudosLogisticServiceUserHeader;

const NudosLogisticServiceUserHeaderSkeletonLoader = () => {
  return (
    <div className={`NudosLogisticServiceUserHeaderSkeletonLoader`}>
      <div className={`userTitleSkeleton animationLoader`} />
      <div className="userInformation">
        <div className="photoAndFlag">
          <div className="photo animationLoader" />
        </div>
        <div className="userNameSkeleton animationLoader" />
      </div>
    </div>
  );
};
interface INudosLogisticServiceUserHeader {
  type: TlogisticsUserHeaderType;
  place?: TstoragePlace;
  name?: string;
  photoUrl?: string;
  flagCountryCode?: string;
  showSkeletonLoader?: boolean;
  customTitle?: string;
  customClassName?: string;
}
