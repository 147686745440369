const RoundMalaysia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M15.3037 16.0001H31.9993C31.9993 14.5559 31.8068 13.157 31.4481 11.8262H15.3037V16.0001ZM15.3037 7.65221H29.651C28.6715 6.05396 27.4193 4.64127 25.9591 3.47827H15.3037V7.65221ZM15.9999 32C19.7654 32 23.2265 30.6985 25.9597 28.5217H6.04004C8.77323 30.6985 12.2343 32 15.9999 32ZM2.34797 24.3478H29.6512C30.4375 23.0648 31.0473 21.6622 31.4484 20.1738H.550781C.951844 21.6622 1.56166 23.0648 2.34797 24.3478Z"
        />
        <path fill="#1A47B8" d="M16 16C16 7.1635 16 6.12175 16 0C7.1635 0 0 7.1635 0 16H16Z" />
        <path
          fill="#FFDA2C"
          d="M10.6394 13.6956C8.45423 13.6956 6.68286 11.9242 6.68286 9.73913C6.68286 7.55407 8.4543 5.78263 10.6394 5.78263C11.3207 5.78263 11.9617 5.95494 12.5214 6.25819C11.6434 5.39963 10.4427 4.86963 9.11761 4.86963C6.4283 4.86963 4.24805 7.04982 4.24805 9.73919C4.24805 12.4286 6.4283 14.6088 9.11761 14.6088C10.4427 14.6088 11.6434 14.0788 12.5214 13.2202C11.9617 13.5233 11.3207 13.6956 10.6394 13.6956Z"
        />
        <path
          fill="#FFDA2C"
          d="M11.7546 6.95654L12.4571 8.42542L14.0434 8.05892L13.333 9.52386L14.6088 10.5355L13.0204 10.8936L13.0248 12.5218L11.7546 11.5032L10.4844 12.5218L10.4888 10.8936L8.90039 10.5355L10.1761 9.52386L9.4657 8.05892L11.0521 8.42542L11.7546 6.95654Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMalaysia;
