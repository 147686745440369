const RoundBulgaria = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M32 15.9999c0-1.9571-.352-3.8319-.9952-5.5652L16 9.73901.995188 10.4346C.352063 12.168 0 14.0428 0 15.9999c0 1.9571.352063 3.8319.995188 5.5652L16 22.2608l15.0048-.6957C31.648 19.8318 32 17.957 32 15.9999Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 32c6.8795 0 12.7442-4.342 15.0048-10.4348H.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
        <path
          fill="#fff"
          d="M.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0 9.12049 0 3.2558 4.342.995117 10.4348Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBulgaria;
