const SquareMontenegro = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#D4AF2C" rx="2" />
        <path fill="#AF010D" d="M1.04785 1.06689H20.95265V14.93359H1.04785z" />
        <path
          fill="#D4AF2C"
          fillRule="evenodd"
          d="M10.9997 5.09647L9.7771 4.2666L8.9631 5.09647H9.7771L8.9631 5.92634L7.33301 5.09647V9.2458L9.7771 8.4138L8.9631 10.0735H8.14806L8.9631 10.9034L9.7771 10.0735L10.9997 11.7333V5.09647ZM12.2222 4.2666L13.0362 5.09647H12.2222L13.0362 5.92634L14.6663 5.09647V9.2458L12.2222 8.4138L13.0362 10.0735H13.8513L13.0362 10.9034L12.2222 10.0735L10.9997 11.7333V5.09647L12.2222 4.2666Z"
          clipRule="evenodd"
        />
        <path
          fill="#1A47B8"
          fillRule="evenodd"
          d="M9.42871 6.93883C9.42871 6.0535 10.1264 5.3335 11.0001 5.3335C11.8676 5.3335 12.5716 6.05136 12.5716 6.93883V6.57296C12.5716 7.4583 12.0394 8.65936 11.3982 9.24176L11.0001 9.60016L10.602 9.24176C9.95566 8.6551 9.42871 7.46043 9.42871 6.57296V6.93883Z"
          clipRule="evenodd"
        />
        <path
          fill="#AE6A3E"
          fillRule="evenodd"
          d="M9.42871 6.3999H12.5716V7.46657H9.42871V6.3999Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMontenegro;
