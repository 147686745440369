const RoundSaoTomeAndPrince = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#6DA544"
          d="M9.73884 10.4348H31.0045C28.7438 4.342 22.8791 0 15.9997 0 11.5813 0 7.58177 1.79119 4.68652 4.68681L9.73884 10.4348ZM9.73884 21.5652H31.0045C28.7438 27.658 22.8791 32 15.9997 32 11.5813 32 7.58177 30.2088 4.68652 27.3132L9.73884 21.5652Z"
        />
        <path
          fill="#F93939"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="black"
          d="M20.3174 13.2173 21.0081 15.343H23.2432L21.435 16.6568 22.1257 18.7825 20.3174 17.4687 18.5092 18.7825 19.1998 16.6568 17.3916 15.343H19.6267L20.3174 13.2173ZM27.2735 13.2173 27.9642 15.343H30.1993L28.3911 16.6568 29.0817 18.7825 27.2735 17.4687 25.4652 18.7825 26.1559 16.6568 24.3477 15.343H26.5828L27.2735 13.2173Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSaoTomeAndPrince;
