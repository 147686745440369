import { useState } from 'react';
import { TdestinationAddress } from '../../../types/assignationFluxes';
import { ICountryDetail } from '../../../types/countries';
import LocationsSelectModal from './Components/LocationsSelectModal/LocationsSelectModal';
import LocationOfficeAddressModal from './Components/LocationOfficeAddressModal/LocationOfficeAddressModal';
import LocationOtherAddressModal from './Components/LocationOtherAddressModal/LocationOtherAddressModal';

const LocationModalContent = ({
  selectOption,
  handleChangeOption,
  listOfAllCountries,
  organizationOfficeAddress,
  officeAddressList,
  loadingAddress,
  productId,
  closeLocationModal,
  updateCardListData,
  defaultOtherAddress,
  officeDirect,
  defaultCountryCode,
  otherAddressDirect,
  refreshOfficeData,
  refresOtherAddressData,
  defaultCodeOfficeAddress,
  defaultCodeOtherAddress,
  updateOtherAddres,
  officePreSelection
}: {
  officePreSelection?: boolean;
  selectOption: number;
  handleChangeOption: (option: number) => void;
  listOfAllCountries: ICountryDetail[];
  organizationOfficeAddress?: TdestinationAddress;
  officeAddressList?: TdestinationAddress[];
  loadingAddress: boolean;
  productId?: string | number;
  closeLocationModal: () => void;
  updateCardListData: (type?: string, existId?: number) => void;
  defaultOtherAddress?: TdestinationAddress;
  officeDirect?: boolean;
  defaultCountryCode?: string;
  otherAddressDirect?: boolean;
  refreshOfficeData: (officeCode: string) => void;
  refresOtherAddressData: (otherCode: string) => void;
  defaultCodeOfficeAddress?: string;
  defaultCodeOtherAddress?: string;
  updateOtherAddres: (otherAddress: TdestinationAddress) => void;
}) => {
  const [temporalOption, setTemporalOption] = useState<number>(0);

  const handleOption = (option: number) => setTemporalOption(option);
  const actionTemporalOption = (temporalOption: number) => setTemporalOption(temporalOption);

  return (
    <div id="locationToolsFlux">
      {!selectOption && (
        <LocationsSelectModal
          productId={Number(productId)}
          handleOption={handleOption}
          temporalOption={temporalOption}
          handleChangeOption={handleChangeOption}
          loadingAddress={loadingAddress}
          updateCardListData={updateCardListData}
          closeLocationModal={closeLocationModal}
          organizationOfficeAddress={organizationOfficeAddress}
          defaultOtherAddress={defaultOtherAddress}
        />
      )}
      {selectOption === 1 && (
        <LocationOfficeAddressModal
          officePreSelection={officePreSelection}
          officeAddressList={officeAddressList || []}
          listOfAllCountries={listOfAllCountries}
          refreshOfficeData={refreshOfficeData}
          officeDirect={officeDirect}
          updateCardListData={updateCardListData}
          closeLocationModal={closeLocationModal}
          handleChangeOption={handleChangeOption}
          actionTemporalOption={actionTemporalOption}
          organizationOfficeAddress={organizationOfficeAddress}
          defaultCodeOfficeAddress={defaultCodeOfficeAddress || defaultCountryCode || ''}
        />
      )}
      {selectOption === 2 && (
        <LocationOtherAddressModal
          listOfAllCountries={listOfAllCountries}
          refresOtherAddressData={refresOtherAddressData}
          otherAddressDirect={otherAddressDirect}
          closeLocationModal={closeLocationModal}
          handleChangeOption={handleChangeOption}
          actionTemporalOption={actionTemporalOption}
          updateCardListData={updateCardListData}
          defaultCodeOtherAddress={defaultCodeOtherAddress || defaultCountryCode || ''}
          defaultOtherAddress={defaultOtherAddress}
          updateOtherAddres={updateOtherAddres}
        />
      )}
    </div>
  );
};
export default LocationModalContent;
