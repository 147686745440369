const SquareSwitzerland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M9.42899 6.4002H6.28613V9.6002H9.42899V12.8002H12.5718V9.6002H15.7147V6.4002H12.5718V3.2002H9.42899V6.4002Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSwitzerland;
