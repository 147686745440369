import { NOTIFICATION_CONTAINER, NOTIFICATION_TYPE, Store } from 'react-notifications-component';

/**
 * IMPORTANT! consider using it with the NudosNotification component as the value of the notification param
 * @property {JSX.Element} notification - the message to be displayed. It can be a string or a JSX element if more complex structure is required
 * @property {number} duration - the time in miliseconds that the notification will be shown. Default is 0, which means the notification will remain open until its clicked.
 * @property {string} text - the text to shown if the notification param is passed empty
 * @property {string} location - the location of the notification in the viewport. must be one of the following string values (default: "top-center"): "bottom-left" | "bottom-right" | "bottom-center" | "top-left" | "top-right" | "top-center" | "center" | "top-full" | "bottom-full"
 * @property {string} type - ONLY PASSED if notification value is empty and one of the default styles of the react-notifications-component library is desired. Must be one of the following strings: (alias) type NOTIFICATION_TYPE = "success" | "danger" | "info" | "default" | "warning"
 */
const activateNotify = (
  notification?: JSX.Element,
  duration?: number,
  text?: string,
  type?: NOTIFICATION_TYPE,
  location?: NOTIFICATION_CONTAINER
) => {
  Store.addNotification({
    content: notification,
    width: 364,
    insert: 'top',
    container: location || 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration || 0,
      onScreen: false
    },
    type,
    title: type,
    message: text
  });
};

export const responsiveConfig = { isMobile: true, breakpoint: 768 };

export default activateNotify;
