import { DASHBOARD_ONBOARDING_TYPES } from '../../types/account';
import { BordModal, ModalContent } from '../BordDesignSystem';
import BordWelcomeModalContent from './BordWelcomeModalContent';

const BordWelcomeModal = ({
  closeModalCallback,
  changedReleaseVideoInformation,
  loadingReleasedVideo
}: {
  closeModalCallback?: () => void;
  changedReleaseVideoInformation?: (rounte: DASHBOARD_ONBOARDING_TYPES) => void;
  loadingReleasedVideo?: boolean;
}) => {
  return (
    <BordModal
      modalContent={
        <ModalContent
          toCloseModal={closeModalCallback}
          customHeight="384px"
          customWidth="480px"
          excludeHeader
          CustomModalComponent={
            <BordWelcomeModalContent
              changedReleaseVideoInformation={changedReleaseVideoInformation}
              loadingReleasedVideo={loadingReleasedVideo}
            />
          }
        />
      }
    />
  );
};

export default BordWelcomeModal;
