import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IassigneeInfo } from '../../../../../types/global';
import ToolHistoryCard from './Components/ToolHistoryCard/ToolHistoryCard';
import { BordButton } from '../../../../../components/BordDesignSystem';
import './ToolLocationsHistory.scss';

const ToolLocationsHistory = ({
  previousAssignmentHistory,
  updateDataCallback,
  showSkeletonLoader,
  orderId
}: IassignationCardList) => {
  const { t } = useTranslation();
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [expandedStyles, setEpandedStyles] = useState('');
  const i18nLanguageKey = 'nodi:toolInternal:';

  const handleClickShowOrHideHistoryButton = () => {
    if (showHistory) {
      setEpandedStyles('');
      return setTimeout(() => setShowHistory(false), 1000);
    }
    setEpandedStyles('expanded');
    setShowHistory(true);
  };

  return (
    <div className={`ToolLocationsHistory ${expandedStyles}`}>
      <BordButton
        label={t(`${i18nLanguageKey}history`)}
        onClick={handleClickShowOrHideHistoryButton}
        modeButton="tertiary"
        buttonIconTwo={showHistory ? 'arrowHeadUp' : 'arrowHeadDown'}
      />
      {!showSkeletonLoader && (
        <div className="containerHistory">
          {showHistory &&
            previousAssignmentHistory &&
            previousAssignmentHistory.map((assignation, i) => {
              return (
                <ToolHistoryCard
                  key={`card${assignation.locationLogId || ''}-${i}`}
                  assignation={assignation}
                  updateDataCallback={updateDataCallback}
                  orderId={orderId}
                />
              );
            })}
          {<div className="marginAtBottom h-10 w-10" />}
        </div>
      )}
      {showSkeletonLoader && (
        <div className="containerHistory">
          {[0, 1, 2, 3, 4, 5].map(i => {
            return <div key={`skeletoCard-${i}`} className="tooHistoryCardSkeletonLoader animationLoader" />;
          })}
        </div>
      )}
      {!showSkeletonLoader && (!previousAssignmentHistory || previousAssignmentHistory.length <= 0) && showHistory && (
        <div className="noHistoryText"> {t(`${i18nLanguageKey}noHistoryText`)}</div>
      )}
    </div>
  );
};

export default ToolLocationsHistory;

export interface IassignationCardList {
  previousAssignmentHistory?: IassigneeInfo[];
  updateDataCallback: () => void;
  showSkeletonLoader?: boolean;
  orderId?: number;
}
