const SquareCuracao = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#1A47B8" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M5.23782 5.33353H4.70918C4.41622 5.33353 4.1902 5.57231 4.1902 5.86686C4.1902 6.1528 4.42255 6.4002 4.70918 6.4002H5.23782V6.93845C5.23782 7.23673 5.47233 7.46686 5.76163 7.46686C6.04246 7.46686 6.28544 7.23028 6.28544 6.93845V6.4002H6.81408C7.10703 6.4002 7.33305 6.16141 7.33305 5.86686C7.33305 5.58092 7.1007 5.33353 6.81408 5.33353H6.28544V4.79528C6.28544 4.497 6.05092 4.26686 5.76163 4.26686C5.48079 4.26686 5.23782 4.50344 5.23782 4.79528V5.33353ZM3.14258 3.2002H4.1902V4.26686H3.14258V3.2002Z"
          clipRule="evenodd"
        />
        <path fill="#FFDA2C" d="M22 10H0V12H22V10Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareCuracao;
