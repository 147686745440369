import { BordWelcomeIcon } from '../../assets/images/components/Banners/indext';
import { DASHBOARD_ONBOARDING_TYPES } from '../../types/account';
import { BordButton, BordPhoto } from '../BordDesignSystem';
import './BordWelcomeModal.scss';

const BordWelcomeModalContent = ({
  changedReleaseVideoInformation,
  loadingReleasedVideo
}: {
  changedReleaseVideoInformation?: (route: DASHBOARD_ONBOARDING_TYPES) => void;
  loadingReleasedVideo?: boolean;
}) => {
  return (
    <div className="bordWelcomeModal">
      <BordPhoto url={BordWelcomeIcon} className="bordWelcomeIcon" />
      <div className="titleModal">Te damos la bienvenida a Dash.bord</div>
      <div className="subtitleModal">
        Nos emociona presentarte un diseño renovado y más intuitivo. Sabemos que te encantará la nueva experiencia
        visual.
      </div>
      <div className="subtitleModal">✨ ¡Explora y descubre todo lo que hemos mejorado!</div>
      <BordButton
        label="Conocer Dash.bord"
        onClick={() => changedReleaseVideoInformation?.(DASHBOARD_ONBOARDING_TYPES.RELEASEVIDEO2024)}
        customClassName="closeModalButton"
        isLoading={loadingReleasedVideo}
      />
    </div>
  );
};

export default BordWelcomeModalContent;
