import { useEffect, useState } from 'react';
import { BordButton, BordTextInput } from '../../../../../components/BordDesignSystem';
import { NudosAddressSearchBar } from '../../../../../components/NudosComponents';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import { TdestinationAddress } from '../../../../../types/assignationFluxes';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { addOtherLogisticsAddress } from '../../../../../services/account';
import { IaddressFromGoogleMaps } from '../../../../../types/global';
import { ICountryDetail } from '../../../../../types/countries';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { useTranslation } from 'react-i18next';
import './LocationOtherAddressModal.scss';

const LocationOtherAddressModal = ({
  listOfAllCountries,
  refresOtherAddressData,
  updateCardListData,
  closeLocationModal,
  handleChangeOption,
  actionTemporalOption,
  defaultCodeOtherAddress,
  otherAddressDirect,
  updateOtherAddres,
  defaultOtherAddress
}: {
  listOfAllCountries: ICountryDetail[];
  refresOtherAddressData: (otherCode: string) => void;
  updateCardListData: (type?: string, existId?: number) => void;
  closeLocationModal: () => void;
  handleChangeOption: (option: number) => void;
  actionTemporalOption: (temporalOption: number) => void;
  defaultCodeOtherAddress: string;
  updateOtherAddres: (otherAddress: TdestinationAddress) => void;
  otherAddressDirect?: boolean;
  defaultOtherAddress?: TdestinationAddress;
}) => {
  const { t } = useTranslation();
  const orgInfo = formatOrgData();

  const [selectOtherCountry, setSelectOtherCountry] = useState<ICountryDetail>();
  const [otherLabelAddress, setOtherLabelAddress] = useState<string>();
  const [otherFullAddress, setOtherFullAddress] = useState<IaddressFromGoogleMaps>();
  const [otherZipCode, setOtherZipCode] = useState<string>();
  const [otherReferences, setOtherReferences] = useState<string>();
  const [saveLoadingOtherAddress, setSaveLoadingOtherAddress] = useState<boolean>(false);
  const [newOtherLocation, setNewOtherLocation] = useState<TdestinationAddress>();

  const i18nLanguageKey = 'nodi:tools:locationOtherAddressModal:';

  const addersErrorText =
    otherFullAddress && selectOtherCountry?.code !== otherFullAddress?.country
      ? `Esta dirección no pertenece a ${selectOtherCountry?.name || 'este país'}`
      : '';

  const otherButtonDisabled = () => {
    if (otherFullAddress && selectOtherCountry?.code !== otherFullAddress.country) return true;
    if (otherFullAddress && !newOtherLocation?.locationName) return true;
    if (!otherReferences) return true;
    return false;
  };

  const saveOtherAddress = async () => {
    setSaveLoadingOtherAddress(true);
    const body = {
      id: Number(orgInfo?.organizationId),
      address: otherFullAddress?.address || otherLabelAddress || '',
      additionalReferences: otherReferences || '',
      city: otherFullAddress?.city || defaultOtherAddress?.city || '',
      cityId: defaultOtherAddress?.cityId, // CAMBIAR-JC: Cambiar si alguna vez diseño implemente un select de ciudad en este modal
      zipCode: otherZipCode,
      coordinates: otherFullAddress?.coordinates,
      countryId: Number(selectOtherCountry?.id),
      locationName: newOtherLocation?.locationName || ''
    };
    try {
      const newAddress = await addOtherLogisticsAddress(Number(orgInfo?.organizationId), body);
      await refresOtherAddressData(selectOtherCountry?.code || '');
      setNewOtherLocation(undefined);
      setOtherFullAddress(undefined);
      setOtherZipCode(undefined);
      setOtherReferences(undefined);
      if (otherAddressDirect) {
        await updateCardListData('otherAddressEdit');
        closeLocationModal();
      } else {
        updateOtherAddres({ ...newAddress, code: newAddress?.country, locationId: newAddress.id });
        handleChangeOption(0);
        actionTemporalOption(2);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setSaveLoadingOtherAddress(false);
    }
  };

  const onlyAssignLocation = async () => {
    if (newOtherLocation) {
      if (otherAddressDirect) {
        await updateCardListData('otherAddressEdit', newOtherLocation.locationId);
        closeLocationModal();
      } else {
        updateOtherAddres(newOtherLocation);
        handleChangeOption(0);
        actionTemporalOption(2);
      }
    }
  };

  const saveAddresButtonAction = () => {
    if (newOtherLocation?.locationId) {
      onlyAssignLocation();
    } else {
      saveOtherAddress();
    }
  };
  const initialOtherCountry = () => {
    if (defaultCodeOtherAddress) {
      const defaultCountry = listOfAllCountries.find(element => element.code === defaultCodeOtherAddress);
      setSelectOtherCountry(defaultCountry);
    }
    if (defaultOtherAddress) {
      setNewOtherLocation(defaultOtherAddress);
      setOtherZipCode(defaultOtherAddress?.zipCode);
      setOtherReferences(defaultOtherAddress.additionalReference);
      setOtherLabelAddress(defaultOtherAddress?.address);
    }
  };

  const addNewLocation = (locationName: string) => {
    resetOtherAddress();
    setNewOtherLocation({
      additionalReference: '',
      address: '',
      city: '',
      locationName: locationName
    });
  };

  const resetOtherAddress = () => {
    setNewOtherLocation(undefined);
    setOtherFullAddress(undefined);
    setOtherLabelAddress(undefined);
    setOtherReferences(undefined);
    setOtherZipCode(undefined);
  };

  useEffect(() => {
    initialOtherCountry();
  }, [defaultOtherAddress]);

  return (
    <div id="locationOtherAddressModal">
      <div className="orangeTitle">{t(`nodi:tools:locationsSelectModal:otherDirection`)} </div>
      <div className="modalSubtitle">{t(`${i18nLanguageKey}modalSubtitle`)} </div>
      <div className="organizationName">{orgInfo?.businessName}</div>
      <div className="countrySection">
        <NudosCountryDropdown
          charactersLimit={20}
          currentValue={selectOtherCountry}
          countriesList={listOfAllCountries || []}
          updateCurrentValueCallback={setSelectOtherCountry}
          componentSize="w-180"
        />
        <BordTextInput
          label={t(`${i18nLanguageKey}nameAddress`)}
          isFilled={!!newOtherLocation}
          currentText={newOtherLocation?.address}
          placeholder={t(`${i18nLanguageKey}nameAddress`)}
          standardSize="w-180"
          setCurrentText={e => addNewLocation(e)}
        />
      </div>
      <NudosAddressSearchBar
        regionBias={selectOtherCountry?.code}
        label={t(`${i18nLanguageKey}addressBox:label`)}
        customPlaceholder={t(`${i18nLanguageKey}addressBox:customPlaceholder`)}
        hideDidntFindAddressToolTip
        defaultValueAddressName={otherLabelAddress}
        handleAddressSelection={e => setOtherFullAddress(e)}
        componentSize="w-380"
        errorText={addersErrorText}
      />
      <div className="extraAddressBox">
        <BordTextInput
          currentText={otherZipCode}
          setCurrentText={e => setOtherZipCode(e)}
          label={t(`${i18nLanguageKey}postalCode`)}
          isFilled={!!otherZipCode}
          placeholder={t(`${i18nLanguageKey}postalCode`)}
          standardSize="w-180"
        />
        <BordTextInput
          currentText={otherReferences}
          label={t(`${i18nLanguageKey}references:label`)}
          isFilled={!!otherReferences}
          placeholder={t(`${i18nLanguageKey}references:placeholder`)}
          setCurrentText={e => setOtherReferences(e)}
          standardSize="w-180"
        />
      </div>
      <BordButton
        label={t(`${i18nLanguageKey}nudosButton`)}
        disabled={saveLoadingOtherAddress || !selectOtherCountry?.id || !otherZipCode || otherButtonDisabled()}
        isLoading={saveLoadingOtherAddress}
        customWidth="w-380"
        onClick={saveAddresButtonAction}
      />
    </div>
  );
};

export default LocationOtherAddressModal;
