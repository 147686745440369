import { AxiosError, AxiosRequestConfig } from 'axios';
import { DTOgetOrganizationOfficeAddress, DTOupdateToolLocation, TstoragePlace } from '../types/assignationFluxes';
import { apiGet, apiPut } from './api';
import { Tany } from '../types/global';

const { REACT_APP_SUBSCRIPTION } = process.env;

export const getOrganizationOfficeAddress = async (
  organizationId: number | string,
  place: TstoragePlace
): Promise<DTOgetOrganizationOfficeAddress> => {
  return apiGet<AxiosRequestConfig, DTOgetOrganizationOfficeAddress>(
    `${REACT_APP_SUBSCRIPTION}/product-location/by-organization/${organizationId}/${place}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const uploadToolsLocationWithoutLogistics = async (
  products: number[] | string[],
  locationId: number | string
): Promise<string> => {
  const productIdsParams = JSON.stringify(products);
  return apiPut(`${REACT_APP_SUBSCRIPTION}/product/set-location/${locationId}/${productIdsParams}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateToolLocation = async (body: DTOupdateToolLocation): Promise<Tany> => {
  return apiPut<AxiosRequestConfig, Tany>(`${REACT_APP_SUBSCRIPTION}/product/own-location-address`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
