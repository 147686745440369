const RoundUnitedKingdom = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M3.30709 6.25903C2.05028 7.89422 1.10259 9.77891.550781 11.8263H8.87441L3.30709 6.25903ZM31.4485 11.8263C30.8967 9.77891 29.9489 7.89422 28.6922 6.25903L23.125 11.8263H31.4485ZM.550781 20.1743C1.10266 22.2217 2.05034 24.1064 3.30709 25.7415L8.87422 20.1743H.550781ZM25.7411 3.30764C24.1059 2.05083 22.2213 1.10314 20.1738.55127V8.87483L25.7411 3.30764ZM6.25879 28.6926C7.89398 29.9494 9.77866 30.8971 11.826 31.449V23.1255L6.25879 28.6926ZM11.826.55127C9.7786 1.10314 7.89391 2.05083 6.25879 3.30758L11.826 8.87477V.55127ZM20.1738 31.449C22.2212 30.8971 24.1059 29.9494 25.741 28.6927L20.1738 23.1255V31.449ZM23.125 20.1743 28.6922 25.7416C29.9489 24.1064 30.8967 22.2217 31.4485 20.1743H23.125Z"
        />
        <path
          fill="#F93939"
          d="M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2926 0 14.5962 0.0465 13.9131 0.135438V13.9129V13.913H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2926 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z"
        />
        <path
          fill="#F93939"
          d="M20.1738 20.1739 27.3136 27.3136C27.642 26.9854 27.9552 26.6422 28.2541 26.2865L22.1415 20.1738H20.1738V20.1739ZM11.8263 20.1738H11.8262L4.68652 27.3135C5.01477 27.6419 5.35796 27.9551 5.71371 28.254L11.8263 22.1413V20.1738ZM11.8263 11.8262V11.8261L4.68659 4.68628C4.35822 5.01453 4.04497 5.35772 3.74609 5.71347L9.85878 11.8262H11.8263V11.8262ZM20.1738 11.8262 27.3136 4.68629C26.9854 4.35791 26.6422 4.04466 26.2865 3.74585L20.1738 9.85854V11.8262Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundUnitedKingdom;
