import { FC } from 'react';
import { NudosHoverText } from '../../NudosComponents';
import { formatPills } from '../../../utils/productDetails';
import { ObjectKey } from '../../../types/global';
import DynamicsPills from '../../DynamicsPills/DynamicsPills';

import './productDescription.scss';

interface IHeaderDescription {
  name?: string;
  sku?: string;
  children?: React.ReactNode;
  pillsDataList?: ObjectKey<string>;
  customProductNameClass?: string;
  customHeaderDescriptionClass?: string;
  limitSku?: number;
  nameCharacterLimit?: number;
}

const ProductDescription: FC<IHeaderDescription> = ({
  name,
  sku,
  children,
  pillsDataList,
  customProductNameClass,
  customHeaderDescriptionClass,
  limitSku,
  nameCharacterLimit
}: IHeaderDescription) => {
  const pillsList = pillsDataList
    ? formatPills({ pillsList: pillsDataList, excludeItems: ['Cargo', 'Gama', 'Perfil'] })
    : undefined;

  return (
    <div className={`headerDescription ${customHeaderDescriptionClass || ''}`}>
      <NudosHoverText
        customClassName={`productName ${customProductNameClass}`}
        text={name || 'ProductName'}
        charactersLimit={nameCharacterLimit || 20}
        onlyIfTruncated
      />
      {!!pillsList && pillsList?.pills && pillsList?.pills?.length > 0 && (
        <div className="boxPills">
          {pillsList?.pills?.map((item, index) => {
            return (
              <DynamicsPills
                key={`product-description-${index}`}
                dataPills={{ hoverText: item.name || '', text: item?.value || '' }}
                elementsNumber={pillsList?.pills?.length || 0}
                disabledMaxWidth
              />
            );
          })}
        </div>
      )}
      <div className="boxSerialProduct">
        SKU: <NudosHoverText text={sku || 'no hay SKU'} charactersLimit={limitSku || 20} onlyIfTruncated />
      </div>
      {!!children && children}
    </div>
  );
};

export default ProductDescription;
