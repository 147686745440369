export const nudosBlue = '#54A1FC';
export const nudosLightBlue = '#D6E9FF';
export const nudosYellow200 = '#FFBB00';
export const nudosGreen200 = '#81BC3F';
export const nudosRed100 = '#FF0000';
export const nudosGray500 = '#999999';

export const bordColorTheme = {
  navyBlue: {
    400: '#C6C7CB',
    500: '#6F737D',
    600: '#626C82',
    700: '#262B39',
    800: '#1B202F',
    900: '#070F21'
  },
  cyan: {
    200: '#60DDC3',
    300: '#22CFAB',
    500: '#1B9F88',
    600: '#098381',
    700: '#077573',
    900: '#1C323B'
  },
  white: {
    50: '#FFF',
    100: '#F4F4F4',
    200: '#EBEBEB',
    300: '#E6E6E6',
    400: '#B4B4B4',
    default: '#FFF'
  },
  blue: {
    50: '#DFF2FF',
    400: '#23A5FF',
    500: '#4096F0',
    900: '#0057A7'
  },
  darkBlue: {
    900: '#052143'
  },
  green: {
    600: '#09A432',
    700: '00994F'
  },
  lightGreen: {
    50: 'EAF9F4',
    200: '74DAB5',
    300: '#4FD2A2'
  },
  red: {
    100: '#FFD7DC',
    300: '#FF6175',
    400: '#FC3D5F',
    700: 'DE072E',
    800: '452436'
  },
  redBright: {
    500: '#FC543D'
  },
  yellow: {
    500: '#F5DD28',
    600: '#D2CE60',
    700: '#DCC410',
    800: '#373822'
  },
  bordGradient: {
    cyan: '#22CFAB',
    blue: '#0057A7'
  },
  skeletonGradient: {
    color1: '#262B39',
    color2: '#3B4256'
  },
  layout: {
    backgroud: '#070F21',
    level1: '#1B202F',
    level2: '#262B39'
  },
  primary: {
    bgDefault: '#22CFAB',
    bgHover: '#60DDC3',
    bgSecondary: '#1C323B',
    fgDefault: '#070F21'
  },
  disabled: {
    bgPrimary: '#626C82',
    bgSecondary: '#262B39',
    fgPrimary: '#262B39',
    fgSecondary: '#626C82'
  },
  content: {
    secondary: '#C6C7CB',
    default: '#FFF'
  },
  stroke: {
    interactive: '#626C82',
    default: '#262B39'
  },
  bordBlue: {
    default: '#0057A7'
  },
  bordRed: {
    bg: '#452436',
    default: '#FF6175'
  },
  bordYellow: {
    bg: '#373822',
    default: '#F5DD28'
  },
  informative: {
    bgPrimary: '#23A5FF',
    fgPrimary: '#052143'
  },
  success: {
    default: '#00994F'
  },
  warning: {
    default: '#DCC410'
  },
  danger: {
    default: '#FC543D'
  }
};

export type TformComponentsStandardSizes = 'w-80' | 'w-180' | 'w-280' | 'w-380';
