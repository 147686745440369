const RoundBarbados = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M22.9565 1.58763C20.853.5705 18.4931 0 16 0s-4.853.5705-6.9565 1.58763L7.65219 16 9.0435 30.4124C11.147 31.4295 13.5069 32 16 32s4.853-.5705 6.9565-1.5876L24.3478 16 22.9565 1.58763Z"
        />
        <path
          fill="#1A47B8"
          d="M9.0435 1.58813C3.69163 4.17601 0 9.65657 0 15.9999c0 6.3434 3.69163 11.824 9.0435 14.4119V1.58813ZM22.9565 1.58813V30.4118C28.3084 27.8239 32 22.3433 32 15.9999c0-6.34326-3.6916-11.82389-9.0435-14.41177Z"
        />
        <path
          fill="#000"
          d="m20.8696 9.73909.9333.46661-.9333-.46661-.9334-.46662c-.0563.11256-1.2749 2.59213-1.474 6.37973h-1.4187V9.73909L16 8.34778l-1.0435 1.39131v5.91311h-1.4187c-.1991-3.7876-1.4177-6.26717-1.474-6.37973l-1.8667.93333c.0129.0256 1.2812 2.6072 1.2812 6.4899v1.0435h3.4783v5.913h2.0869v-5.913h3.4783v-1.0436c0-2.0074.3473-3.6626.6387-4.6978.3177-1.1287.6404-1.7879.6436-1.7945l-.9345-.46421Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBarbados;
