import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BordButton, BordOneToneIcon } from '../../../../components/BordDesignSystem';
import useLoadTools from '../../../../state/useStateSuccessLoadTools';
import segment, { segmentTrackEvent } from '../../../../utils/segment';
import useSidebar from '../../../../hooks/useSidebar';
import BordEmptyState from '../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import { TbordilustrationIcon } from '../../../../components/BordDesignSystem/BordIlustrationIcon/BordIlustration.types';
import { TformComponentsStandardSizes } from '../../../../styles/constants';

import './UploadExternalToolsSummary.scss';

const UploadExternalToolsSummary = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { moduleSidebarStyles } = useSidebar();

  const { uploadedToolsData, selectedReferences, setSelectedReferences, setCurrentStep, setUploadedToolsData } =
    useLoadTools();
  const { totalRecords = 0, recordsWithErrors = 0, errors } = uploadedToolsData || {};

  const uploadedProductsNames = selectedReferences ? selectedReferences?.map(reference => reference.name) : undefined;
  const successfullyLoadedTools = totalRecords - recordsWithErrors;
  const i18nLanguageKey = 'nodi:UploadExternalToolsModule:uploadToolSteps:summary:';
  const haveErrorsToShow = !!recordsWithErrors && errors && errors.length > 0;
  const allRecordsHaveErrors = totalRecords === recordsWithErrors;

  const getDisplayData = () => {
    if (allRecordsHaveErrors) {
      return {
        title: t(`${i18nLanguageKey}failureTitle`),
        variant: 'danger'
      };
    }
    if (recordsWithErrors) {
      return {
        title: t(`${i18nLanguageKey}partialSuccessTitle`),
        variant: 'warning'
      };
    }
    return {
      title: t(`${i18nLanguageKey}completeSuccessTitle`),
      variant: 'success',
      buttonOneProps: {
        label: 'Ir a inventario',
        customWidth: 'w-280',
        onClick: () => {
          push('/nodi/tools');
        }
      }
    };
  };

  const { title, variant, buttonOneProps } = getDisplayData();

  const cleanUploadToolsState = () => {
    setSelectedReferences([]);
    setCurrentStep(0);
    setUploadedToolsData(undefined);
  };

  useEffect(() => {
    if (!uploadedToolsData) push('/nodi/dashboard');
    segmentTrackEvent({ toolsUploadConfirmationView: { ProductNamesUploaded: uploadedProductsNames || [] } });
    if (successfullyLoadedTools > 0) segment.sessionEndingEvent();

    return () => cleanUploadToolsState();
  }, []);
  return (
    <div id="uploadExternalToolsSummary" className={`${moduleSidebarStyles}`}>
      <div className="boxUploadToolsSummary">
        <div className="containerUploadItems">
          <div className="centerEmptyState">
            <BordEmptyState
              title={title}
              ilustrationIconProps={{ variant: variant as TbordilustrationIcon, standardSize: 100 }}
              subTitle={`${successfullyLoadedTools}/${totalRecords}`}
              buttonOneProps={
                buttonOneProps
                  ? {
                      label: buttonOneProps.label,
                      customWidth: buttonOneProps.customWidth as TformComponentsStandardSizes,
                      onClick: buttonOneProps?.onClick
                    }
                  : undefined
              }
            />
          </div>
          {haveErrorsToShow && (
            <>
              <div className="blueLineContainer">
                <div className="blueLine" />
              </div>
              <div className="serialesContainer">
                <div className="boxSeriales">
                  <div className="titleErrors">No se pudieron cargar las siguientes herramientas</div>
                  <div className="serialesList">
                    <div className="boxSerialesList">
                      {errors &&
                        errors.length > 0 &&
                        errors.map((item, index) => {
                          return (
                            <div className="serialDetails" key={`errors-list-serial-${item.serial}-index-${index}`}>
                              <div className="iconSerial">
                                <BordOneToneIcon variant="barCode2" standardSize={12} />
                              </div>
                              <div className="serialText">{item?.serial}</div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="buttonsToolsContainer">
                <BordButton
                  label="Ir a inventario"
                  customHeight="42"
                  customWidth="w-280"
                  modeButton="primary"
                  bordButtonStyle="outlined"
                  onClick={() => push('/nodi/tools')}
                />
                <BordButton
                  label="Volver a intentarlo"
                  customHeight="42"
                  customWidth="w-280"
                  onClick={() => push('/nodi/upload-external-tools')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadExternalToolsSummary;
