import create from 'zustand';
import { IComingModule, ISubscriptions } from '../types/subscriptions';

export interface iState {
  stateSubscription: ISubscriptions;
  setStateSubscription: (stateSubscription: ISubscriptions) => void;
  showModuleTooltip: string | undefined;
  setShowModuleTooltip: (showModuleTooltip: string | undefined) => void;
  comingSoonTooltip: undefined | IComingModule;
  setComingSoonTooltip: (comingSoonTooltip: IComingModule | undefined) => void;
}

const useStateSubscriptions = create<iState>(set => ({
  stateSubscription: { levelSubscription: undefined, nudosCare: false, isPrime: false },
  showModuleTooltip: undefined,
  comingSoonTooltip: undefined,
  setStateSubscription: stateSubscription => set({ stateSubscription }),
  setShowModuleTooltip: showModuleTooltip => set({ showModuleTooltip }),
  setComingSoonTooltip: comingSoonTooltip => set({ comingSoonTooltip })
}));

export default useStateSubscriptions;
