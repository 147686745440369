const SquareAndorra = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#FFDA2C"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M0 0h7v16H0V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M15 0h7v16h-7V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M12.5044 10.5973c.6432-.1995 1.1146-.80856 1.1146-1.5307v-3.2c0-.89067-.704-1.6-1.5714-1.6H9.95238c-.86533 0-1.57143.71467-1.57143 1.6v3.2c0 .72747.46933 1.3333 1.11467 1.5317.19485.6614.79408 1.135 1.50438 1.135.7145 0 1.3095-.48 1.5044-1.136Z"
            clipRule="evenodd"
          />
          <path
            fill="#AF010D"
            fillRule="evenodd"
            d="M9.42857 5.33325h1.04763v1.6H9.42857v-1.6Zm2.09523 2.13334h1.0476v1.6h-1.0476v-1.6Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M11.5238 5.33325h1.0476v1.6h-1.0476v-1.6ZM9.42857 7.46659h1.04763v1.6H9.42857v-1.6Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAndorra;
