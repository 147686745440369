import { useEffect, useLayoutEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { NudosBreadcrumbButton } from '../../../../../components/NudosComponents';
import useShopStore from '../../../../../state/useShopContext';
import useCheckoutStore from '../../state';
import CheckoutSummary from '../CheckoutSummary';
import { ShippingReferencesList } from './components';
import {
  ICartShipping,
  IProductsCart,
  IProductShipping,
  IShipmentGroupedByDestinationLocationAndProductId,
  IShippingSummary,
  IShippingTotals,
  ISummaryItem
} from '../../../../../types/cart';
import useCartState from '../../../../../state/useCartState';
import { ICountryDetail } from '../../../../../types/countries';
import { getShipmentsPricingDataByDestination, groupShipmentsByLocationAndProductId } from '../../checkout.utils';
import { productsNumber, productsOnly } from '../../../../../utils/cart';
import { ShipmentSubtotalTooltip } from '../../../../../components/NudosPrimeOrFree';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import {
  costForPaidShipments,
  lowerLimitForFreeShipping,
  minimumShippingCost
} from '../../../../../utils/productDefinitions';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../../../utils/segment';
import useStoreCountryState from '../../../../../state/useStoreCountryState';
import { getShoppingCart } from '../../../../../services/cart.services';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { formatOrgData } from '../../../../../utils/orgFormatData';
import { calculateShipping } from '../../../../../services/orders';
import { ICalculateOfShipment, ICartShippingInformation, IShipmentsDataDetails } from '../../../../../types/orders';

import './Step2ShippingCart.scss';

const Step2ShippingCart = ({
  listOfCountriesOpenForBuying,
  completeListOfCountries,
  openModalInvalidCart
}: {
  listOfCountriesOpenForBuying?: ICountryDetail[];
  completeListOfCountries?: ICountryDetail[];
  openModalInvalidCart: () => void;
}) => {
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const { setStep } = useCheckoutStore();
  const { itemsCart } = useShopStore();
  const [loadingCart, setLoadingCard] = useState<boolean>(false);
  const { globalShippingCart, setGlobalShippingCart } = useCartState();
  const { storeCountry } = useStoreCountryState();
  const productsCart = productsOnly();
  const orgInfo = formatOrgData();
  const [globalShippingCartWasPreloaded, setGlobalShippingCartWasPreloaded] = useState(false);
  const [someCountryOfficeDataIsLoading, setSomeCountryOfficeDataIsLoading] = useState(false);
  const [totalsShipping, setTotalsShipping] = useState<IShippingTotals>({
    freeTotalFormat: '$0 USD',
    freeTotal: 0,
    primeTotalFormat: '$0 USD',
    primeTotal: 0,
    totalShippingDiscount: 0
  });
  const [summary, setSummary] = useState<IShippingSummary>();
  const [totalSaved, setTotalSaved] = useState<number>(0);
  const [firstMount, setFirsMount] = useState<boolean>(false);
  const [dataCalculation, setDataCalculation] = useState<ICalculateOfShipment[]>();
  const [loadingCalculate, setLoadingCalculate] = useState<boolean>(false);
  const shippingDataCalc = globalShippingCart?.shipments?.filter(item => item?.destinationLocationId);
  const sumOfShippingCosts =
    dataCalculation
      ?.filter(shipment => shipment?.locationId)
      .reduce((add, shipment) => add + shipment?.shipmentValue, 0) || 0;

  const { isPrime } = stateSubscription;
  const shipmentList = globalShippingCart?.shipments;
  const summaryFirstSectionListingElements = {
    [t('recurrentWords:referencesName')]: itemsCart?.references || '0',
    [t('recurrentWords:products')]: productsNumber() || '0',
    [t('recurrentWords:numberOfShipments')]: summary?.totalShippings || `${t('recurrentWords:toBeCalculated')}`
  };
  const productsEvent = shipmentList?.map(product => {
    const destinationIsOffice = !!product?.destinationIsOffice;
    const hasEmployeeData = !!product?.employee;
    return {
      sku: `${product?.sku}`,
      total: `${product?.unitPrice} USD`,
      country: `${product?.countryName}`,
      shipment: destinationIsOffice ? 'Oficina' : hasEmployeeData ? 'Empleado' : 'Bodega Nudos'
    };
  });

  /**
   * @returns The array of current shipping and commercial data for the product items with the provided productId in the desired country, or undefined if no product has the provided productId in that country
   */
  const getReferenceCurrentShipmentDataByCountry = (productId: number, countryId: number) =>
    globalShippingCart &&
    globalShippingCart.shipments?.filter(
      element => element?.productId === productId && element?.countryId === countryId
    );

  const calcTotalShippings = (shippings?: IShipmentGroupedByDestinationLocationAndProductId[]) => {
    if (shippings && shippings.length > 0) {
      let freePrice = 0;
      let primePrice = 0;
      for (let i = 0; i < shippings.length; i++) {
        const shippingElement = shippings[i];
        if (shippingElement) {
          if (shippingElement.unitPrice < lowerLimitForFreeShipping) {
            freePrice = freePrice + costForPaidShipments;
            primePrice = primePrice + costForPaidShipments;
          }
          if (shippingElement.unitPrice >= lowerLimitForFreeShipping) {
            freePrice = freePrice + minimumShippingCost;
          }
        }
      }
      const totalShippingDiscount = freePrice - primePrice;

      setTotalsShipping({
        freeTotalFormat: `${nudosFormatCurrency({ value: freePrice, maximumFractionDigits: 2 })} USD`,
        freeTotal: freePrice,
        primeTotalFormat: `${nudosFormatCurrency({ value: primePrice })} USD`,
        primeTotal: primePrice,
        totalShippingDiscount: totalShippingDiscount
      });
    } else {
      setTotalsShipping({
        freeTotalFormat: '$0 USD',
        freeTotal: 0,
        primeTotalFormat: '$0 USD',
        primeTotal: 0,
        totalShippingDiscount: 0
      });
    }
  };
  /**
   * Takes a product reference information (ie the data pertaining an specific sku by country) from the shopping cart and the current shipment data for each item of that reference in that country (if it exists) and assembles an array that combines the product data with its shipment information (it preserves the existing data if this information has already being assembled)
   * @param reference
   * @param referenceItemsCurrentShipmentData
   */

  const generateReferenceItemsWithShipmentDataByCountry = (
    reference: IProductsCart,
    referenceItemsCurrentShipmentData?: ICartShipping[]
  ) => {
    if (!reference?.quantity) return;
    const itemsShipmentData = Array(reference.quantity);
    for (let i = 0; i < reference.quantity; i++) {
      const itemHasShipmentData = referenceItemsCurrentShipmentData && i < referenceItemsCurrentShipmentData?.length;
      const itemCurrentReceiverInformation =
        itemHasShipmentData && referenceItemsCurrentShipmentData[i]?.receiverInformation;
      const itemCurrentDestinationLocationId =
        itemHasShipmentData && referenceItemsCurrentShipmentData[i]?.destinationLocationId;
      const itemCurrentEmployeeData =
        itemHasShipmentData && !!itemCurrentDestinationLocationId && referenceItemsCurrentShipmentData[i]?.employee;
      const itemCurrentDestinationIsOffice =
        itemHasShipmentData &&
        !!itemCurrentDestinationLocationId &&
        referenceItemsCurrentShipmentData[i]?.destinationIsOffice;
      const itemCurrentUuid = itemHasShipmentData && referenceItemsCurrentShipmentData[i]?.onlyShippingUid;
      itemsShipmentData[i] = {
        countryId: reference.countryId,
        productId: reference.productId,
        countryName: reference.countryName,
        brandName: reference.brandName,
        unitPrice: reference?.unitPrice,
        category: reference.category,
        onlyShippingUid: itemCurrentUuid || uuid(),
        employee: itemCurrentEmployeeData || undefined,
        destinationIsOffice: itemCurrentDestinationIsOffice || undefined,
        destinationLocationId: itemCurrentDestinationLocationId || undefined,
        receiverInformation: itemCurrentReceiverInformation || undefined,
        sku: reference.sku
      };
    }
    return itemsShipmentData;
  };

  /**
   * Takes the information of the shopping cart, separates it by country and then by references. For each individual item of every reference added to the shopping cart in every country it generates an object that combines its commercial and shipping information.
   * @returns an object with the shoppingCartId and the list of objects (with the shipment and product data) corresponding to every item in the shopping cart
   */
  const generateItemsListIncludingShipmentData = () => {
    if (!itemsCart || !itemsCart?.products || itemsCart?.products.length <= 0) return;
    let referencesList: ICartShipping[] = [];
    const countryId = Number(storeCountry?.id);
    const countryReferences = itemsCart?.products;
    for (const reference of countryReferences) {
      const referenceItemsCurrentShipmentData = getReferenceCurrentShipmentDataByCountry(
        reference?.productId,
        countryId
      );
      const referecenceItemsWithShipmentData = generateReferenceItemsWithShipmentDataByCountry(
        reference,
        referenceItemsCurrentShipmentData
      );
      if (referecenceItemsWithShipmentData && referecenceItemsWithShipmentData.length > 0) {
        referencesList = referencesList.concat(referecenceItemsWithShipmentData);
      }
    }
    if (!referencesList || referencesList.length <= 0 || !itemsCart?.shoppingCartId) return;
    const newGlobalShippingCart = {
      shoppingCartId: itemsCart.shoppingCartId,
      shipments: referencesList
    };
    setGlobalShippingCart({ ...newGlobalShippingCart });
    setGlobalShippingCartWasPreloaded(true);
  };

  const summaryAction = () => {
    const everyItemHasShipmentData = !globalShippingCart?.shipments?.find(element => !element?.destinationLocationId);
    const enabledContinue =
      globalShippingCartWasPreloaded && !someCountryOfficeDataIsLoading && everyItemHasShipmentData;
    const thisOrderTotalShipments = groupShipmentsByLocationAndProductId(globalShippingCart);
    const shipmentsPricingDataByDestination = getShipmentsPricingDataByDestination(
      thisOrderTotalShipments?.shippingsList
    );
    setSummary({
      enabledContinue,
      totalShippings: thisOrderTotalShipments?.totalShippings,
      shippingsList: thisOrderTotalShipments?.shippingsList || [],
      shipmentsPricingDataByDestination
    });
  };

  const mainAmountCalc = () => {
    if (isPrime) {
      const totalShipments = Number(sumOfShippingCosts || 0);
      const totalCart = Number(itemsCart?.total || 0);
      const totals = totalShipments + totalCart;
      return `${nudosFormatCurrency({ value: Number(totals), maximumFractionDigits: 2 })} USD`;
    }
    const totalShipments = Number(sumOfShippingCosts || 0);
    const totalCart = Number(itemsCart?.totalWithoutPrime || 0);
    const totals = totalShipments + totalCart;
    return `${nudosFormatCurrency({ value: Number(totals), maximumFractionDigits: 2 })} USD`;
  };

  const secondaryAmountCalc = () => {
    if (isPrime) {
      const totalCart = Number(itemsCart?.totalWithoutPrime || 0);
      const totals = totalCart + sumOfShippingCosts;
      return `${nudosFormatCurrency({ value: Number(totals), maximumFractionDigits: 2 })} USD`;
    }
    const totalCart = Number(itemsCart?.total || 0);
    const totals = totalCart + sumOfShippingCosts;
    return `${nudosFormatCurrency({ value: Number(totals), maximumFractionDigits: 2 })} USD`;
  };

  const summarySThirdSectionListingElementsOtherFormat: ISummaryItem[] = [
    {
      key: 'productsSubtotal',
      name: t(`nodi:checkout:step1ShoppingCart:productsSubtotal`),
      mainValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.total || 0 : itemsCart?.totalWithoutPrime || 0),
        maximumFractionDigits: 2
      })} USD`,
      secondaryValue: `${nudosFormatCurrency({
        value: Number(isPrime ? itemsCart?.totalWithoutPrime || 0 : itemsCart?.total || 0),
        maximumFractionDigits: 2
      })} USD`,
      showSecondaruValue: false,
      toCalculate: false,
      showGrayIcon: !isPrime,
      showYellowIcon: false
    },
    {
      key: 'deliverySubtotal',
      name: t(`nodi:checkout:step1ShoppingCart:deliverySubtotal`),
      mainValue:
        dataCalculation && dataCalculation?.length > 0
          ? `${nudosFormatCurrency({ value: sumOfShippingCosts, maximumFractionDigits: 2 })} USD`
          : t('recurrentWords:toBeCalculated'),
      toCalculate: summary?.totalShippings ? false : true,
      showSecondaruValue: false,
      secondaryValue: summary?.totalShippings ? totalsShipping[!isPrime ? 'primeTotalFormat' : 'freeTotalFormat'] : '',
      showYellowIcon: !!summary?.totalShippings && isPrime,
      showGrayIcon: !!summary?.totalShippings && !isPrime,
      tooltip: (
        <ShipmentSubtotalTooltip step={2} calcTop={-2} calcLeft={1} primeTotal={totalsShipping?.primeTotal || 0} />
      )
    },
    {
      key: 'productsTotal',
      name: 'Total a pagar',
      mainValue: `${mainAmountCalc()}`,
      secondaryValue: `${secondaryAmountCalc()}`,
      showSecondaruValue: isPrime,
      toCalculate: false,
      isBold: true,
      showYellowIcon: isPrime,
      showGrayIcon: !isPrime
    }
  ];

  const calcTotalSaved = () => {
    const totalCartSaved = itemsCart?.totalDiscountValue || 0;
    const totals = totalCartSaved;
    setTotalSaved(totals || 0);
  };

  const checkCart = async () => {
    setLoadingCard(true);
    try {
      const dataCart = await getShoppingCart(Number(orgInfo?.userId), Number(orgInfo?.organizationId));
      if (!!dataCart?.products && dataCart?.products?.length <= 0) {
        openModalInvalidCart();
      } else {
        setStep(2);
        segmentTrackEvent({
          [!isPrime ? 'freeCheckoutShippingClick' : 'primeCheckoutShippingClick']: {
            ProductList: JSON.stringify(productsEvent || '')
          }
        });
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingCard(false);
    }
  };

  const formatItems = (allItems: ICartShipping[]) => {
    const formatTools: IShipmentsDataDetails[] = [];
    for (let i = 0; i < allItems.length; i++) {
      const objetInList = allItems[i];
      const newObj = {
        productId: Number(objetInList?.productId),
        locationId: Number(objetInList?.destinationLocationId)
      };
      if (newObj && newObj?.locationId && newObj?.productId) formatTools.push(newObj);
    }

    return formatTools;
  };

  const requestToCalculateShippingCart = async (shippingDataCalc: ICartShipping[]) => {
    if (!globalShippingCart?.shoppingCartId) return;
    setLoadingCalculate(true);
    const toolsList = formatItems(shippingDataCalc);
    const bodyCalculateShippingCart: ICartShippingInformation = {
      shoppingCartId: Number(globalShippingCart?.shoppingCartId),
      shipmentsData: toolsList
    };
    try {
      const shippingDataCalculation = await calculateShipping(bodyCalculateShippingCart);
      setDataCalculation(shippingDataCalculation);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingCalculate(false);
    }
  };

  const callBackRequestForShippingCalculation = async (dataCart: IProductShipping) => {
    await requestToCalculateShippingCart(dataCart?.shipments);
  };

  useEffect(() => {
    calcTotalSaved();
  }, [itemsCart, totalsShipping]);

  useEffect(() => {
    calcTotalShippings(summary?.shippingsList);
  }, [summary?.shippingsList]);

  useLayoutEffect(() => {
    generateItemsListIncludingShipmentData();
  }, [listOfCountriesOpenForBuying]);

  useEffect(() => {
    if (globalShippingCart && globalShippingCart?.shipments?.length > 0) {
      summaryAction();
    }
  }, [globalShippingCart, globalShippingCartWasPreloaded, someCountryOfficeDataIsLoading]);

  useEffect(() => {
    segmentTrackEvent({
      [!isPrime ? 'freeCheckout2ShippingView' : 'primeCheckout2ShippingView']: null
    });
  }, []);

  useEffect(() => {
    if (!!shippingDataCalc && shippingDataCalc?.length > 0 && !firstMount) {
      setFirsMount(true);
      requestToCalculateShippingCart(shippingDataCalc);
    }
  }, [shippingDataCalc]);

  return (
    <div id="shippingCart">
      <div className="spaceInfo">
        <div className="countyContainers">
          <NudosBreadcrumbButton
            returnText={t(`recurrentWords:cart`)}
            returnAction={() => {
              setStep(0);
              segmentTrackEvent({
                [!isPrime ? 'freeCheckoutBackClick' : 'primeCheckoutBackClick']: {
                  ScreenName: 'Shipping'
                }
              });
            }}
          />
          <div className="countryBox">
            {globalShippingCart &&
              globalShippingCart?.shipments?.length &&
              itemsCart &&
              itemsCart?.products &&
              itemsCart?.products?.length > 0 && (
                <ShippingReferencesList
                  listOfCountriesOpenForBuying={listOfCountriesOpenForBuying || []}
                  globalShippingCartWasPreloaded={globalShippingCartWasPreloaded}
                  setSomeCountryOfficeDataIsLoading={setSomeCountryOfficeDataIsLoading}
                  completeListOfCountries={completeListOfCountries}
                  callBackRequestForShippingCalculation={callBackRequestForShippingCalculation}
                />
              )}
          </div>
        </div>
        <div className="totalContainer">
          <CheckoutSummary
            customClassName={'checkoutCartSummaryWidth'}
            buttonText={t('nodi:checkout:step2ShippingCart:mainButtonText')}
            isButtonDisabled={!summary?.enabledContinue}
            firstSectionListingElements={
              summaryFirstSectionListingElements
                ? summaryFirstSectionListingElements
                : { Orden: 1, [t('recurrentWords:shipments')]: summary?.totalShippings || 0 }
            }
            shipmentsList={summary?.shippingsList}
            shipmentsPricingDataByDestination={summary?.shipmentsPricingDataByDestination || {}}
            handleClickButton={() => {
              checkCart();
            }}
            listOfCountriesOpenForBuying={listOfCountriesOpenForBuying || []}
            checkoutStep={2}
            totalShippingDiscount={totalsShipping?.totalShippingDiscount || 0}
            totalSaved={totalSaved}
            itemsCart={itemsCart}
            heightLimitProductList={124}
            referencesList={productsCart && productsCart.length > 0 ? productsCart : []}
            thirdSectionListItems={summarySThirdSectionListingElementsOtherFormat}
            loadingButton={loadingCart || loadingCalculate}
            dataCalculation={dataCalculation}
          />
        </div>
      </div>
    </div>
  );
};

export default Step2ShippingCart;
