const RoundLiechtenstein = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0.695625 16 16 13.9131 16 13.9131L32 16Z"
        />
        <path fill="#1A47B8" d="M0 16C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16" />
        <path
          fill="#FFDA2C"
          d="M11.8263 11.1305C11.8263 9.97799 10.892 9.04361 9.73939 9.04361C9.2047 9.04361 8.71739 9.24486 8.34808 9.57542V8.34792H9.0437V6.95661H8.34808V6.26099H6.95677V6.95661H6.26114V8.34792H6.95677V9.57542C6.58745 9.24486 6.10014 9.04361 5.56545 9.04361C4.41289 9.04361 3.47852 9.97799 3.47852 11.1305C3.47852 11.7485 3.74733 12.3035 4.17414 12.6857V13.9132H11.1306V12.6857C11.5576 12.3035 11.8263 11.7485 11.8263 11.1305Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLiechtenstein;
