const RoundChile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M32 16.0001c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16 0-8.83651 16 0 16 0h16Z"
        />
        <path fill="#1A47B8" d="M0 16C0 7.1635 7.1635 0 16 0v16H0Z" />
        <path
          fill="#fff"
          d="m9.52449 5.56519 1.03611 3.18862h3.3526l-2.7123 1.97059 1.036 3.1887-2.71241-1.9707-2.71237 1.9707 1.036-3.1887-2.71238-1.97059h3.35269l1.03606-3.18862Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundChile;
