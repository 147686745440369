const SquareElSalvador = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 11H22V16H0V11ZM0 0H22V5H0V0Z" clipRule="evenodd" />
          <path
            fill="#1A47B8"
            d="M11.0001 9.5999C11.868 9.5999 12.5716 8.88356 12.5716 7.9999C12.5716 7.11625 11.868 6.3999 11.0001 6.3999C10.1323 6.3999 9.42871 7.11625 9.42871 7.9999C9.42871 8.88356 10.1323 9.5999 11.0001 9.5999Z"
          />
          <path
            fill="#FFDA2C"
            d="M11.0004 8.53346C11.2897 8.53346 11.5242 8.29468 11.5242 8.00013C11.5242 7.70558 11.2897 7.4668 11.0004 7.4668C10.7111 7.4668 10.4766 7.70558 10.4766 8.00013C10.4766 8.29468 10.7111 8.53346 11.0004 8.53346Z"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M8.38086 7.4668L9.42848 8.53346L10.9999 9.60013L12.5713 8.53346L13.619 7.4668L12.5713 9.60013H9.42848L8.38086 7.4668Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareElSalvador;
