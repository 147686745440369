const SquarePortugal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#F93939" rx="2" />
        <path fill="#249F58" fillRule="evenodd" d="M0 0H7V16H0V0Z" clipRule="evenodd" />
        <path
          fill="#FFDA2C"
          d="M7.33329 10.6666C9.06904 10.6666 10.4761 9.23391 10.4761 7.4666C10.4761 5.69929 9.06904 4.2666 7.33329 4.2666C5.59753 4.2666 4.19043 5.69929 4.19043 7.4666C4.19043 9.23391 5.59753 10.6666 7.33329 10.6666Z"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M9.42876 8.5335V5.3335H5.23828V8.5335C5.23828 9.1223 6.1759 9.60016 7.33352 9.60016C8.49114 9.60016 9.42876 9.1223 9.42876 8.5335Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          fillRule="evenodd"
          d="M6.28613 6.3999H8.38137V8.53324H6.28613V6.3999Z"
          clipRule="evenodd"
        />
        <path
          fill="#1A47B8"
          fillRule="evenodd"
          d="M6.28613 6.3999H7.33375V7.46657H6.28613V6.3999ZM7.33375 7.46657H8.38137V8.53324H7.33375V7.46657Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquarePortugal;
