import { productsOnly } from '../../../../utils/cart';
import NoItemsInCartSummary from './NoItemsInCartSummary/NoItemsInCartSummary';
import CartSummaryItems from './CartSummaryItems/CartSummaryItems';
import './HeaderShoppingCartSummary.scss';

const HeaderShoppingCartDetails = () => {
  const productsList = productsOnly();

  return (
    <div className="headerShoppingCartSummary">
      <div className="relative -top-4 h-4" />
      {!productsList?.length && <NoItemsInCartSummary />}
      {!!productsList?.length && <CartSummaryItems />}
    </div>
  );
};

export default HeaderShoppingCartDetails;
