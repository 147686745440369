const RoundParaguay = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0Z"
        />
        <path
          fill="#1A47B8"
          d="M16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32Z"
        />
        <path
          fill="#6DA544"
          d="M19.9349 11.3691L18.4592 12.8448C19.0886 13.4743 19.478 14.3438 19.478 15.3043C19.478 17.2253 17.9207 18.7826 15.9998 18.7826C14.0788 18.7826 12.5215 17.2253 12.5215 15.3043C12.5215 14.3438 12.9109 13.4743 13.5403 12.8448L12.0646 11.3691C11.0574 12.3761 10.4346 13.7675 10.4346 15.3043C10.4346 18.378 12.9262 20.8695 15.9998 20.8695C19.0733 20.8695 21.5649 18.3779 21.5649 15.3043C21.5649 13.7675 20.942 12.3761 19.9349 11.3691Z"
        />
        <path
          fill="#FFDA2C"
          d="M16 13.2173L16.5181 14.8117H18.1944L16.8382 15.7969L17.3563 17.3912L16 16.4059L14.6438 17.3912L15.1619 15.7969L13.8057 14.8117H15.482L16 13.2173Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundParaguay;
