import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordFigmaIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 2H8.5c-.92826 0-1.8185.36875-2.47487 1.02513C5.36875 3.6815 5 4.57174 5 5.5c0 .92826.36875 1.8185 1.02513 2.47487C6.6815 8.63125 7.57174 9 8.5 9M12 2v7m0-7h3.5c.4596 0 .9148.09053 1.3394.26642s.8105.4337 1.1355.75871c.325.325.5828.71084.7587 1.13548C18.9095 4.58525 19 5.04037 19 5.5c0 .45963-.0905.91475-.2664 1.33939-.1759.42464-.4337.81048-.7587 1.13548-.325.32501-.7109.58282-1.1355.75871S15.9596 9 15.5 9M12 9H8.5M12 9h3.5M12 9v7M8.5 9c-.92826 0-1.8185.36875-2.47487 1.0251C5.36875 10.6815 5 11.5717 5 12.5c0 .9283.36875 1.8185 1.02513 2.4749C6.6815 15.6313 7.57174 16 8.5 16m7-7c-.4596 0-.9148.09053-1.3394.26642s-.8105.4337-1.1355.75868c-.325.325-.5828.7109-.7587 1.1355-.1759.4246-.2664.8798-.2664 1.3394 0 .4596.0905.9148.2664 1.3394.1759.4246.4337.8105.7587 1.1355.325.325.7109.5828 1.1355.7587.4246.1759.8798.2664 1.3394.2664.4596 0 .9148-.0905 1.3394-.2664.4246-.1759.8105-.4337 1.1355-.7587.325-.325.5828-.7109.7587-1.1355.1759-.4246.2664-.8798.2664-1.3394 0-.4596-.0905-.9148-.2664-1.3394-.1759-.4246-.4337-.8105-.7587-1.1355-.325-.32498-.7109-.58279-1.1355-.75868S15.9596 9 15.5 9Zm-7 7c-.92826 0-1.8185.3687-2.47487 1.0251C5.36875 17.6815 5 18.5717 5 19.5c0 .9283.36875 1.8185 1.02513 2.4749C6.6815 22.6313 7.57174 23 8.5 23c.92826 0 1.8185-.3687 2.4749-1.0251C11.6313 21.3185 12 20.4283 12 19.5V16m-3.5 0H12"
      />
    </svg>
  );
};

export default BordFigmaIcon;
