const RoundAruba = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#3ECBF8"
          d="M16 32c4.8494 0 9.1946-2.1577 12.1288-5.5652H3.87119C6.80537 29.8423 11.1506 32 16 32ZM32 16c0-8.8365-7.1635-16-16-16C7.1635 0 0 7.1635 0 16c0 1.4442.1925 2.8431.551188 4.1739H31.4489C31.8075 18.8431 32 17.4442 32 16ZM1.27182 22.2609c.30844.7247.669 1.4217 1.07656 2.0869H29.6516c.4076-.6652.7682-1.3622 1.0767-2.0869H1.27182Z"
        />
        <path
          fill="#fff"
          d="m7.33231 10.0915-3.126-1.37964 3.126-1.37962L8.71188 4.2063l1.37952 3.12594 3.126 1.37962-3.126 1.37964-1.37952 3.1259-1.37957-3.1259Z"
        />
        <path
          fill="#F93939"
          d="m8.71187 5.9292.85194 1.93056 1.93059.85206-1.93059.852-.85194 1.93058-.85206-1.93058-1.93057-.852 1.93057-.85206.85206-1.93056Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAruba;
