import { useEffect, useState } from 'react';
import useStateSubscriptions from '../../state/useStateSubscriptions';
import { Trans } from 'react-i18next';
import './DisabledModuleTooltip.scss';
const DisabledModuleTooltip = () => {
  const { showModuleTooltip } = useStateSubscriptions();
  const [position, setPosition] = useState<{ x: number; y: number } | undefined>();

  useEffect(() => {
    if (showModuleTooltip) {
      const myElement = document.getElementById(showModuleTooltip);
      const positionIcon = myElement ? myElement.getBoundingClientRect() : undefined;
      setPosition({ x: positionIcon?.x || 0, y: positionIcon?.y || 0 });
    }
  }, [showModuleTooltip]);

  return (
    <>
      {position && (
        <div id="disabledModuleTooltip" style={{ top: `${(position?.y || 0) + 30}px` }}>
          <Trans i18nKey={'nodi:basicAccount:DisabledModuleTooltip'} components={{ 1: <br /> }} />
        </div>
      )}
    </>
  );
};
export default DisabledModuleTooltip;
