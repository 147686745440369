const RoundFrenchPolynesia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M1.94531 8.34781H30.0544C27.3408 3.37431 22.0646 0 15.9999 0 9.93519 0 4.659 3.37431 1.94531 8.34781ZM15.9999 31.9999C22.0646 31.9999 27.3408 28.6256 30.0544 23.6521H1.94531C4.659 28.6256 9.93519 31.9999 15.9999 31.9999Z"
        />
        <path
          fill="#FFDA2C"
          d="M21.5649 16C21.5649 19.0736 19.0733 20.8696 15.9998 20.8696C12.9262 20.8696 10.4346 19.0736 10.4346 16C10.4346 12.9264 12.9262 10.4348 15.9998 10.4348C19.0733 10.4348 21.5649 12.9264 21.5649 16Z"
        />
        <path
          fill="#1A47B8"
          d="M21.5649 16C21.5649 19.0736 19.0733 21.5652 15.9998 21.5652C12.9262 21.5652 10.4346 19.0736 10.4346 16"
        />
        <path
          fill="#F93939"
          d="M12.5215 14.6086H13.9128V17.3913H12.5215V14.6086ZM18.0869 14.6086H19.4782V17.3913H18.0869V14.6086ZM15.3037 12.5217H16.695V17.3913H15.3037V12.5217Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundFrenchPolynesia;
