const RoundNetherlands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9999 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.2558 27.658 9.12049 32 15.9999 32Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNetherlands;
