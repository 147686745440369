const RoundTunisia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M15.9998 23.652C20.226 23.652 23.652 20.226 23.652 15.9998C23.652 11.7737 20.226 8.34766 15.9998 8.34766C11.7737 8.34766 8.34766 11.7737 8.34766 15.9998C8.34766 20.226 11.7737 23.652 15.9998 23.652Z"
        />
        <path
          fill="#F93939"
          d="M16.9429 13.0737L18.2557 14.8828L20.3817 14.1934L19.0669 16.0009L20.3796 17.8099L18.2542 17.1179L16.9394 18.9254L16.9407 16.6904L14.8154 15.9984L16.9416 15.309L16.9429 13.0737Z"
        />
        <path
          fill="#F93939"
          d="M17.7389 20.5216C15.2416 20.5216 13.2171 18.4971 13.2171 15.9998C13.2171 13.5026 15.2416 11.4781 17.7389 11.4781C18.5175 11.4781 19.2502 11.6749 19.8898 12.0216C18.8864 11.0403 17.5142 10.4346 15.9998 10.4346C12.9263 10.4346 10.4346 12.9262 10.4346 15.9998C10.4346 19.0733 12.9263 21.5649 15.9998 21.5649C17.5142 21.5649 18.8864 20.9593 19.8898 19.9779C19.2502 20.3247 18.5175 20.5216 17.7389 20.5216Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTunisia;
