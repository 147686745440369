const RoundCentralAfricanRepublic = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#1A47B8"
          d="M30.0548 8.34779c-2.0675-3.78931-5.6227-6.64987-9.8807-7.797497l-.6956 7.797497h10.5763ZM1.94531 8.34779H12.5216L11.8259.550293C7.568 1.69792 4.01288 4.55854 1.94531 8.34779Z"
        />
        <path
          fill="#6DA544"
          d="M19.4785 16v7.6522h10.5763c1.2403-2.2732 1.9455-4.8803 1.9455-7.6522H19.4785ZM12.5218 16H0c0 2.7719.705188 5.379 1.94544 7.6522H12.5217V16h.0001Z"
        />
        <path
          fill="#FFDA2C"
          d="M1.94531 23.6521c2.06757 3.7893 5.62269 6.6499 9.88059 7.7975l.6957-6.8193-10.57629-.9782ZM20.1741 31.4496c4.258-1.1476 7.8132-4.0082 9.8807-7.7975l-10.5763.9781.6956 6.8194ZM19.4785 24.6302l10.5763-.9781H19.4785v.9781Z"
        />
        <path
          fill="#FFDA2C"
          d="M12.5216 23.6521H1.94531l10.57629.9781v-.9781Z"
        />
        <path
          fill="#F93939"
          d="M16.0001 0c-1.4442 0-2.8431.1925-4.1739.551188V31.4489c1.3308.3586 2.7297.5511 4.1739.5511s2.8431-.1925 4.1739-.5512V.551188C18.8432.1925 17.4443 0 16.0001 0Z"
        />
        <path
          fill="#FFDA2C"
          d="m8.60584 3.47827.43163 1.32856h1.39703l-1.13009.82113.43168 1.32856-1.13025-.82106-1.13018.82106.43181-1.32856-1.13013-.82113h1.39694l.43156-1.32856Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCentralAfricanRepublic;
