const LoaderOrganization = () => {
  return (
    <div id="companyForm">
      <div className="titleFormAccount">Información de la empresa</div>
      <div className="profileSectionSkeleton">
        <div className="imgSkeleton animationLoader" />
        <div>
          <div className="titleImgSkeleton animationLoader" />
          <div className="subTitleImgSkeleton animationLoader" />
        </div>
      </div>
      <div className="firstSection">
        <div className="fistSectionItemSkeleton animationLoader" />
        <div className="fistSectionItemSkeleton animationLoader" />
        <div className="fistSectionItemSkeleton animationLoader" />
      </div>
      <div className="w-380 h-40 animationLoader rounded-md" />
      <div className="lineForm" />
      <div>
        <div className="titleFormAccount">Información de cuenta</div>
        <div className="accountSectionSubTitleSkeleton animationLoader" />
        <div className="bordPlatformLevelSkeleton animationLoader" />
      </div>
      <div className="lineForm" />
      <div>
        <div className="titleFormAccount">Usuarios asociados a la cuenta</div>
        <div className="listOfInviteUserSkeleton">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="invitateUserItemSkeleton">
              <div className="title animationLoader" />
              <div className="item animationLoader" />
            </div>
          ))}
        </div>
        <div className="inviteUserButtonSkeleton animationLoader" />
      </div>
    </div>
  );
};
export default LoaderOrganization;
