const SquareIceland = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M6.28571 9.6H0V6.4H6.28571V0H9.42857V6.4H22V9.6H9.42857V16H6.28571V9.6Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M7.33333 7.46667H0V8.53333H7.33333V16H8.38095V8.53333H22V7.46667H8.38095V0H7.33333V7.46667Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareIceland;
