import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={14} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M9.6331 11.6836C9.70012 10.6132 9.73535 9.50418 9.73535 8.3678C9.73535 7.90741 9.72958 7.4515 9.71824 7.00083C9.71021 6.68189 9.60678 6.37193 9.41796 6.11476C8.7036 5.14181 8.1339 4.53623 7.19627 3.81233C6.93669 3.61191 6.61809 3.50321 6.29023 3.49606C5.96408 3.48896 5.6235 3.48535 5.25976 3.48535C4.15851 3.48535 3.26959 3.51843 2.35045 3.58147C1.56201 3.63555 0.935819 4.26325 0.88643 5.052C0.819406 6.12237 0.78418 7.23143 0.78418 8.3678C0.78418 9.50418 0.819406 10.6132 0.88643 11.6836C0.93582 12.4723 1.56201 13.1 2.35045 13.1541C3.26959 13.2171 4.15851 13.2502 5.25976 13.2502C6.36102 13.2502 7.24994 13.2171 8.16908 13.1541C8.95751 13.1 9.58371 12.4723 9.6331 11.6836Z"
      fill="white"
      stroke={props?.stroke || '#2C7152'}
    />
    <path
      d="M13.1135 8.94827C13.1806 7.8779 13.2158 6.76883 13.2158 5.63246C13.2158 5.17207 13.21 4.71616 13.1987 4.26549C13.1907 3.94654 13.0872 3.63658 12.8984 3.37941C12.184 2.40646 11.6143 1.80088 10.6767 1.07698C10.4171 0.876567 10.0985 0.767858 9.7707 0.760715C9.44455 0.753609 9.10398 0.75 8.74024 0.75C7.63898 0.75 6.75007 0.783082 5.83093 0.846127C5.04249 0.900207 4.41629 1.5279 4.3669 2.31665C4.29987 3.38702 4.26465 4.49608 4.26465 5.63246C4.26465 6.76883 4.29988 7.8779 4.3669 8.94827C4.41629 9.73701 5.04249 10.3647 5.83093 10.4188C6.75007 10.4818 7.63898 10.5149 8.74024 10.5149C9.84149 10.5149 10.7304 10.4818 11.6495 10.4188C12.438 10.3647 13.0642 9.73702 13.1135 8.94827Z"
      fill="white"
      stroke={props?.stroke || '#2C7152'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const CopyIcon = memo(ForwardRef);
export default CopyIcon;
