import { useTranslation } from 'react-i18next';
import { NudosLogisticsOriginAndDestinationCardsContainer } from '../../../../../components/DesignSystem';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { ICountryDetail } from '../../../../../types/countries';
import { Iemployee } from '../../../../../types/global';
import { TlogisticsOriginOrDestinationData } from '../../../../../types/requestLogisticsModule';
import './AssignmentModuleStep2ConfirmAddresses.scss';

const AssignmentModuleStep2ConfirmAddresses = ({
  originData,
  selectedOrigin,
  destinationData,
  listOfCountries,
  updateOriginDataCallback,
  updateDestinationDataCallback
}: {
  originData?: TlogisticsOriginOrDestinationData;
  selectedOrigin?: TstoragePlace;
  destinationData?: Iemployee;
  listOfCountries?: ICountryDetail[];
  updateOriginDataCallback: (originData: TlogisticsOriginOrDestinationData) => void;
  updateDestinationDataCallback: (destinationData: TlogisticsOriginOrDestinationData) => void;
}) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:addresses:';
  const title = t(`${translationKey}title`);
  return (
    <div className="AssignmentModuleStep2ConfirmAddresses">
      <NudosLogisticsOriginAndDestinationCardsContainer
        title={title}
        listOfCountries={listOfCountries}
        selectedOrigin={selectedOrigin}
        selectedDestination="user"
        destinationData={destinationData}
        originData={originData}
        updateOriginDataCallback={updateOriginDataCallback}
        updateDestinationDataCalback={updateDestinationDataCallback}
      />
    </div>
  );
};

export default AssignmentModuleStep2ConfirmAddresses;
