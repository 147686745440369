import { NudosLogisticsOriginAndDestinationCardsContainer } from '../../../../../components/DesignSystem';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { ICountryDetail } from '../../../../../types/countries';
import { TlogisticsOriginOrDestinationData } from '../../../../../types/requestLogisticsModule';
import './ToolsManagementStep2ConfirmLocations.scss';

const ToolsManagementStep2ConfirmLocations = ({
  selectedOrigin,
  selectedDestination,
  originData,
  destinationData,
  updateOriginDataCallback,
  updateDestinationDataCallback,
  listOfCountries
}: {
  selectedOrigin?: TstoragePlace;
  selectedDestination?: TstoragePlace;
  originData?: TlogisticsOriginOrDestinationData;
  destinationData?: TlogisticsOriginOrDestinationData;
  updateOriginDataCallback: (originData: TlogisticsOriginOrDestinationData) => void;
  updateDestinationDataCallback: (destinationData: TlogisticsOriginOrDestinationData) => void;
  listOfCountries?: ICountryDetail[];
}) => {
  return (
    <div className="ToolsManagementStep2ConfirmLocations">
      <NudosLogisticsOriginAndDestinationCardsContainer
        title="Confirma la información de recolección y entrega"
        listOfCountries={listOfCountries}
        selectedOrigin={selectedOrigin}
        selectedDestination={selectedDestination}
        originData={originData}
        destinationData={destinationData}
        updateOriginDataCallback={updateOriginDataCallback}
        updateDestinationDataCalback={updateDestinationDataCallback}
      />
    </div>
  );
};

export default ToolsManagementStep2ConfirmLocations;
