export type toastVariants = 'informative' | 'success' | 'warning' | 'danger';

export enum TOAST_VARIANTS {
  'informative' = 'info',
  'success' = 'checkCircle',
  'warning' = 'alertTriangleWarning',
  'danger' = 'xCircle'
}

export interface IBordToastProps {
  variant?: toastVariants;
  title?: string;
  description?: string;
  onClose?: () => void;
  action?: () => void;
  actionTwo?: () => void;
  hideAction?: boolean;
  actionLabel?: string;
  actionLabelTwo?: string;
  animationTime?: number;
  customJSXMessage?: JSX.Element;
}
