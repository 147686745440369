import { BordPhoto } from '../../../../../../components/BordDesignSystem';
import { TbordAvatarsVariant } from '../../../../../../components/BordDesignSystem/BordAvatar/BordAvatar.types';
import { TbordOneToneIcon } from '../../../../../../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import useLocationData from '../../../../../../hooks/useLocationData';
import { bordColorTheme } from '../../../../../../styles/constants';
import { LOCATION_PLACE_TYPE } from '../../../../../../types/global';
import { IShipmentsData } from '../../../../../../types/orders';

const MultipleShipmentsSummary = ({ shipmentsData }: { shipmentsData: IShipmentsData[] }) => {
  const shipmentsLimit = 3;
  const shipmentsToShow = shipmentsData.slice(0, shipmentsLimit);
  const excessShipments = shipmentsData?.length - 3;
  const additionalShipments = excessShipments > 0 ? excessShipments : 0;

  const getPhotoPlaceholder = (
    place: string,
    circularAvatarVariant?: TbordAvatarsVariant,
    oneToneIconVariant?: TbordOneToneIcon
  ) => {
    return place === 'user'
      ? {
          avatarProps: {
            variant: circularAvatarVariant,
            standardSize: 48 as const
          }
        }
      : {
          oneToneIconProps: {
            variant: oneToneIconVariant,
            standardSize: 24 as const
          }
        };
  };

  return (
    <div className="multipleShipments">
      <div className="shipmentsPhotos">
        {shipmentsToShow.map(shipment => {
          const { destinationPlace, destinationCountry, receiverName } = shipment || {};
          const { oneToneIconVariant, circularAvatarVariant } = useLocationData({
            place: destinationPlace as LOCATION_PLACE_TYPE,
            countryData: destinationCountry,
            locationName: receiverName
          });
          const photoPlaceholder = getPhotoPlaceholder(destinationPlace, circularAvatarVariant, oneToneIconVariant);
          const userDestination = destinationPlace === 'user';
          const backgroundColor = userDestination ? undefined : bordColorTheme.layout.level2;

          return (
            <BordPhoto
              key={shipment.id}
              size="s48"
              variant="circle"
              style={{ backgroundColor }}
              {...photoPlaceholder}
            />
          );
        })}
      </div>

      {!!additionalShipments && <div className="additionalShipmentsText">{`+${additionalShipments}`}</div>}
    </div>
  );
};

export default MultipleShipmentsSummary;
