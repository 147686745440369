import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordLinkIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.0002 13c.4295.5741.9774 1.0491 1.6065 1.3929.6292.3438 1.325.5482 2.0401.5994.7152.0512 1.433-.052 2.1047-.3026.6718-.2505 1.2818-.6427 1.7887-1.1497l3-3c.9108-.94305 1.4148-2.20606 1.4034-3.51704-.0114-1.31098-.5373-2.56505-1.4643-3.49209-.927-.92704-2.1811-1.45288-3.4921-1.46427-1.311-.0114-2.574.49258-3.517 1.40337l-1.72 1.71M14.0002 11c-.4295-.5742-.9774-1.04922-1.6066-1.39297-.6291-.34376-1.3249-.54818-2.0401-.5994-.71509-.05122-1.43289.05197-2.10465.30255-.67175.25059-1.28176.64272-1.78865 1.14982l-3 3c-.91079.943-1.41476 2.206-1.40337 3.517.01139 1.311.53724 2.565 1.46428 3.4921.92704.927 2.1811 1.4528 3.49208 1.4642 1.31099.0114 2.574-.4925 3.51701-1.4033l1.71-1.71"
      />
    </svg>
  );
};

export default BordLinkIcon;
