import { useTranslation } from 'react-i18next';
import { IconAccountGear, IconStar } from '../../../assets/DesignSystem/SVGComponents';
import {
  LS_ADDITIONAL_SERVICE_STATUS,
  LS_ADDITIONAL_SERVICE_STATUS_COLOR,
  LS_ADDITIONAL_SERVICE_TYPE
} from '../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../types/requestLogisticsModule';
import { BordOneToneIcon } from '../../BordDesignSystem';

export const getAdditionalServiceDisplayElements = (additionalServiceData: IadditionalServiceDataForToolLogistics) => {
  const { t } = useTranslation();
  const { name, status: statusData, comments } = additionalServiceData;
  const { statusName, statusNameStringId } = statusData || {};
  const translateKey = 'services:logistics:additionalServices:';
  const callToActionText =
    statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      ? t('recurrentWords:viewReasons')
      : t('recurrentWords:view');
  const statusWithDefault = statusName || LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE;
  const statusTranslateWithDefault = t(`${translateKey}${statusNameStringId}`) || t(`${translateKey}default`);

  switch (name) {
    case LS_ADDITIONAL_SERVICE_TYPE.WIPING:
      return {
        text: 'Wiping',
        status: statusTranslateWithDefault,
        comments,
        color: LS_ADDITIONAL_SERVICE_STATUS_COLOR[statusWithDefault],
        callToActionText,
        icon: <BordOneToneIcon variant="wiping" customWidth="1.4rem" />
      };
    case LS_ADDITIONAL_SERVICE_TYPE.BASIC_CONFIG:
      return {
        text: t(`${translateKey}basicConf`),
        status: statusTranslateWithDefault,
        comments,
        color: LS_ADDITIONAL_SERVICE_STATUS_COLOR[statusWithDefault],
        callToActionText,
        icon: <BordOneToneIcon variant="settings" customWidth="1.4rem" />
      };
    case LS_ADDITIONAL_SERVICE_TYPE.ADVANCED_CONFIG:
      return {
        text: t(`${translateKey}advanceConf`),
        status: statusTranslateWithDefault,
        comments,
        color: LS_ADDITIONAL_SERVICE_STATUS_COLOR[statusWithDefault],
        callToActionText,
        icon: <BordOneToneIcon variant="star" customWidth="1.4rem" />
      };
    default:
      return {
        text: 'Sin información',
        status: statusTranslateWithDefault,
        comments,
        color: LS_ADDITIONAL_SERVICE_STATUS_COLOR[LS_ADDITIONAL_SERVICE_STATUS.TO_COMPLETE],
        callToActionText,
        icon: <BordOneToneIcon variant="star" customWidth="1.4rem" />
      };
  }
};
