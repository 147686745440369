const RoundEquatorialGuinea = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#6DA544"
          d="M9.73884 10.4348H31.0045C28.7438 4.342 22.8791 0 15.9997 0C11.5813 0 7.58177 1.79119 4.68652 4.68681L9.73884 10.4348Z"
        />
        <path
          fill="#F93939"
          d="M9.73884 21.5652H31.0045C28.7438 27.658 22.8791 32 15.9997 32C11.5813 32 7.58177 30.2088 4.68652 27.3132L9.73884 21.5652Z"
        />
        <path
          fill="#1A47B8"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="#DEDDE0"
          d="M18.7822 13.2173V16.6955C18.7822 18.8255 21.5649 19.4782 21.5649 19.4782C21.5649 19.4782 24.3475 18.8255 24.3475 16.6955V13.2173H18.7822Z"
        />
        <path fill="#786145" d="M20.8691 16.0696H22.2605V18.087H20.8691V16.0696Z" />
        <path
          fill="#6DA544"
          d="M22.9568 15.3044C22.9568 14.536 22.3339 13.9131 21.5655 13.9131C20.797 13.9131 20.1741 14.536 20.1741 15.3044C19.79 15.3044 19.4785 15.6158 19.4785 16C19.4785 16.3842 19.79 16.6956 20.1741 16.6956H22.9568C23.341 16.6956 23.6524 16.3842 23.6524 16C23.6524 15.6158 23.341 15.3044 22.9568 15.3044Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundEquatorialGuinea;
