import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NudosSelectDropdown } from '../../../../../components/NudosComponents';
import { BordButton, BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import { IOrganization, IPersonal } from '../../../../../types/account';
import { ICountryDetail } from '../../../../../types/countries';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import useEcommerceControler from '../../../../ecommerce/ecommerce.controller';
import InviteCollaboratorModal from '../../../../../components/InviteCollaboratorModal';
import { ICoordinates, Tany } from '../../../../../types/global';
import { useTranslation } from 'react-i18next';
import { BordTextInput } from '../../../../../components/BordDesignSystem';
import BordPhotoUploader from '../../../../../components/BordDesignSystem/BordPhotoUploader/BordPhotoUploader';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import BordPlatformLevel from '../../../../../components/BordDesignSystem/BordPlatformLevel/BordPlatformLevel';
import { NudosCountryDropdown } from '../../../../../components/DesignSystem';
import './CompanyForm.scss';

interface iUpdateAddress {
  address?: string;
  additionalReferences?: string | null;
  country?: string;
  coordinates: ICoordinates;
  city: string;
}

interface IOrganizationInfo {
  image?: string;
  file?: Tany;
  numberOfEmployees: number | string;
  address?: iUpdateAddress;
}

interface ICompanyForm {
  organizationData?: IOrganization;
  updateOrganizationInfo?: (info: IOrganizationInfo) => void;
  personalData?: IPersonal;
  listOfCountries?: ICountryDetail[];
}

const BORD_CRISP_PLATINUM_ARTICLE_URL = 'https://bord.crisp.help/es/article/que-es-platinum-t7fwfh/';

const CompanyForm: FC<ICompanyForm> = ({
  organizationData,
  updateOrganizationInfo,
  personalData,
  listOfCountries
}: ICompanyForm) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { orgData } = useEcommerceControler();
  const { stateSubscription } = useStateSubscriptions();
  const [labelEmployees, setLabelEmployees] = useState<string>('');
  const [file, setFile] = useState<Tany>('');
  const [temporalUrl, setTemporalUrl] = useState<string>('');
  const [collaboratorEmail, setCollaboratorEmail] = useState<string>();
  const [openInviteCollaboratorModal, setOpenInviteCollaboratorModal] = useState(false);
  const [, setValue] = useState<ICountryDetail>();
  const i18nLanguageKey = 'nodi:account:companyForm:';

  const registrationInfoId = new URLSearchParams(search).get('registrationInfoId');
  const collaboratorEmailParam = new URLSearchParams(search).get('email');
  const userIsAdmin = personalData?.role?.toLowerCase().trim() === 'admin';
  const organizationAssociatedUsers = organizationData?.associatedUsers?.filter(
    associatedUser => associatedUser.email !== personalData?.email
  );
  const isPrime = stateSubscription.isPrime;
  const isAdmin = personalData?.role === 'admin';
  const renderBecomePlatinumContainer = isPrime ? '' : 'flex items-end gap-12';

  const numberOfEmployees = [
    t(`${i18nLanguageKey}formatEmployees:case1`),
    t(`${i18nLanguageKey}formatEmployees:case2`),
    t(`${i18nLanguageKey}formatEmployees:case3`),
    t(`${i18nLanguageKey}formatEmployees:case4`),
    t(`${i18nLanguageKey}formatEmployees:case5`)
  ].map(option => {
    return (
      <div
        key={`option-${option}`}
        className="optionContainer"
        onClick={() => {
          setLabelEmployees(option);
          saveOrganizationEmployees(option);
        }}
      >
        {option}
      </div>
    );
  });

  const formatEmployees = (numberOfEmployees: number) => {
    switch (numberOfEmployees) {
      case 1:
        return { label: t(`${i18nLanguageKey}formatEmployees:case1`) };
      case 2:
        return { label: t(`${i18nLanguageKey}formatEmployees:case2`) };
      case 3:
        return { label: t(`${i18nLanguageKey}formatEmployees:case3`) };
      case 4:
        return { label: t(`${i18nLanguageKey}formatEmployees:case4`) };
      case 5:
        return { label: t(`${i18nLanguageKey}formatEmployees:case5`) };
      default:
        return { label: '' };
    }
  };

  const idEmployees = (labelEmployees: string) => {
    switch (labelEmployees) {
      case t(`${i18nLanguageKey}formatEmployees:case1`):
        return { id: 1 };
      case t(`${i18nLanguageKey}formatEmployees:case2`):
        return { id: 2 };
      case t(`${i18nLanguageKey}formatEmployees:case3`):
        return { id: 3 };
      case t(`${i18nLanguageKey}formatEmployees:case4`):
        return { id: 4 };
      case t(`${i18nLanguageKey}formatEmployees:case5`):
        return { id: 5 };
      default:
        return { id: 5 };
    }
  };

  const setLabelSelect = () => {
    const numberOfEmployees = formatEmployees(organizationData?.numberOfEmployees || 0);
    setLabelEmployees(numberOfEmployees.label);
  };

  const saveOrganizationEmployees = (option: string) => {
    const bodyOrganization = {
      numberOfEmployees: idEmployees(option).id || 5
    };
    if (updateOrganizationInfo) {
      updateOrganizationInfo(bodyOrganization);
    }
  };

  const saveOrganizationData = async (myFile?: Tany) => {
    const bodyOrganization = {
      numberOfEmployees: idEmployees(labelEmployees).id || 5,
      file: myFile || file || null
    };
    if (updateOrganizationInfo) {
      updateOrganizationInfo(bodyOrganization);
    }
  };

  const isImageFileValid = (file: File) => {
    const isFormatCorrect = file.type === 'image/png' || file.type === 'image/jpeg';
    const isSizeValid = file.size / 1024 / 1024 < 2;
    if (!isFormatCorrect) {
      displayErrorNotification({ customJSXMessage: <>{t(`recurrentWords:isImageFileValid:format`)}</> });
      return false;
    }
    if (!isSizeValid) {
      displayErrorNotification({ customJSXMessage: <>{t(`recurrentWords:isImageFileValid:size`)}</> });
      return false;
    }
    return true;
  };

  const onChangeFile = (file: Tany) => {
    const validFile = isImageFileValid(file);
    if (validFile) {
      setFile(file);
      const base64File: string = URL.createObjectURL(file);
      setTemporalUrl(base64File);
      saveOrganizationData(file);
    }
  };

  const getCountryPlaceholder = (countryCode?: string) => {
    const countryData = listOfCountries?.find(country => country.code === countryCode);
    if (countryCode && countryCode !== 'other') {
      return (
        <div className="countryOptionContainer">
          <div className="flagContainer" style={{ backgroundImage: `url(${countryData?.flag || ''})` }} />
          <div className="countryName">{countryData?.name ? countryData.name.slice(0, 3) : ''}</div>
        </div>
      );
    }
    if (countryCode && countryCode === 'other') {
      return (
        <div className="countryOptionContainer">
          <div className="countryName">Otros</div>
        </div>
      );
    }
    return 'Otros';
  };

  useEffect(() => {
    if (organizationData?.numberOfEmployees && organizationData?.numberOfEmployees > 0) {
      setLabelSelect();
    }
  }, []);

  useEffect(() => {
    !!registrationInfoId && !!collaboratorEmailParam && setOpenInviteCollaboratorModal(true);
    !!collaboratorEmailParam && setCollaboratorEmail(collaboratorEmailParam);
  }, [registrationInfoId, collaboratorEmailParam]);

  return (
    <div id="companyForm">
      <div className="titleFormAccount">{t(`${i18nLanguageKey}titleFormAccount`)}</div>
      <div className="profileSection">
        <div className="photoContainer">
          <BordPhotoUploader
            uploadedFileHandler={onChangeFile}
            selectedImageUrl={temporalUrl || (organizationData?.image as string)}
            avatarPropsVariant="rectangularWorld"
            bordPhotoSize="s56"
          />
        </div>
        <div className="detailsImg">
          <div>
            <div className="titleImg">{t(`${i18nLanguageKey}titleImg`)}</div>
            <div className="subtitleImg">{t(`${i18nLanguageKey}subtitleImg`)}</div>
          </div>
        </div>
      </div>
      <div className="firstSection">
        <div className="nameInput countryAndTaxCode">
          <NudosCountryDropdown
            charactersLimit={20}
            displayLabel
            countriesList={listOfCountries || []}
            isDeactivated
            customPlaceholder={(getCountryPlaceholder(orgData?.country?.code) as string) || ''}
            componentSize="w-80"
            isPhoneIndicative={false}
            updateCurrentValueCallback={setValue}
          />
          <BordTextInput
            className="taxCode"
            label={t(`${i18nLanguageKey}taxIdentificationDocument`)}
            placeholder="RFC"
            currentText={organizationData?.document}
            disabled
            isFilled={false}
          />
        </div>
        <div className="nameInput">
          <BordTextInput
            disabled
            label={t(`${i18nLanguageKey}companyName`)}
            placeholder={t(`${i18nLanguageKey}companyName`)}
            currentText={organizationData?.name}
            isFilled={false}
            style={{
              width: '100%'
            }}
          />
        </div>
        <div className="nameInput">
          <BordTextInput
            label={t(`${i18nLanguageKey}businessName`)}
            placeholder={t(`${i18nLanguageKey}businessName`)}
            currentText={organizationData?.businessName}
            disabled
            isFilled={false}
            style={{
              width: '100%'
            }}
          />
        </div>
      </div>
      <div className="w-380 mt-20">
        <NudosSelectDropdown
          label={t(`${i18nLanguageKey}numberOfEmployees`)}
          selectOptions={numberOfEmployees}
          currentValueOrplaceholder={labelEmployees || t(`${i18nLanguageKey}numberOfEmployees`)}
          isFilled={true}
          isDeactivated={!userIsAdmin}
          customClosedSelectInputStyles={`customCloseInputStyles ${
            !userIsAdmin ? 'customDisableStyles' : 'customEnableStyles'
          }`}
        />
      </div>
      <div className="lineForm" />
      <div className="accountInformation">
        <div className="titleFormAccount mb-16">Información de cuenta</div>
        <div className="typeClientText">Tipo de cliente</div>
        <div className={renderBecomePlatinumContainer}>
          <BordPlatformLevel platformLevel={isPrime} style={{ width: '38rem' }} />
          {!isPrime && (
            <a className="becomePlatinumText" href={BORD_CRISP_PLATINUM_ARTICLE_URL} target="_blank" rel="noreferrer">
              <div>Conviértete en Platinum</div>
              <BordOneToneIcon variant="arrowRightCircle" standardSize={12} />
            </a>
          )}
        </div>
      </div>
      <div className="lineForm" />
      {isAdmin && (
        <section className="associatedUsersSection">
          <div className="titleFormAccount mb-10">{t(`${i18nLanguageKey}associatedUsersSection`)}</div>
          <div className="associatedUsersContainer">
            {organizationAssociatedUsers?.map((associatedUser, i) => {
              return (
                <BordTextInput
                  key={`associatedUser${associatedUser?.id}${i}`}
                  className="associatedUser"
                  currentText={associatedUser?.email || t(`${i18nLanguageKey}noData`)}
                  placeholder={associatedUser?.email || t(`${i18nLanguageKey}noData`)}
                  standardSize="w-180"
                  label={`${t(`${i18nLanguageKey}user`)} ${i + 1}`}
                  isFilled
                  disabled
                />
              );
            })}
          </div>
          <BordButton
            label={t(`${i18nLanguageKey}inviteUser`)}
            onClick={() => setOpenInviteCollaboratorModal(true)}
            modeButton="secondary"
            buttonIconTwo="plusCircle"
            style={{
              width: '14rem'
            }}
          />
        </section>
      )}
      {openInviteCollaboratorModal && (
        <InviteCollaboratorModal
          setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
          isAnInvitation
          existingOrganizationData={{
            organizationId: orgData.organizationId,
            id: registrationInfoId ? +registrationInfoId : undefined,
            collaboratorEmail: collaboratorEmail,
            name: 'Nudos'
          }}
        />
      )}
    </div>
  );
};

export default CompanyForm;
