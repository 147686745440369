import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { displayErrorNotification, displaySuccessNotification } from './displayNudosStandardNotifications';

const resetPassword = (email: string) => {
  if (email) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() =>
        displaySuccessNotification({
          customJSXMessage: <>Se ha enviado al correo {email || ''} un enlace para que actualices tu contraseña.</>
        })
      )
      .catch(error => {
        if (error) {
          displayErrorNotification();
        }
      });
  } else {
    displayErrorNotification({
      customJSXMessage: <>Debes escribir un correo electrónico válido para recuperar tu contraseña.</>
    });
  }
};

export default resetPassword;
