import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TdestinationAddress } from '../../../../../types/assignationFluxes';
import { uploadToolsLocationWithoutLogistics } from '../../../../../services/assignationFluxes.service';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { BordButton, BordRadioButton } from '../../../../../components/BordDesignSystem';
import { GenericLoader } from '../../../dashboard/components';
import BordTextButton from '../../../../../components/BordDesignSystem/BordTextButton/BordTextButton';
import { bordColorTheme } from '../../../../../styles/constants';
import { truncateText } from '../../../../../utils/truncateText';
import './LocationsSelectModal.scss';

const LocationsSelectModal = ({
  temporalOption,
  handleOption,
  loadingAddress,
  handleChangeOption,
  organizationOfficeAddress,
  defaultOtherAddress,
  updateCardListData,
  productId,
  closeLocationModal
}: {
  productId: number;
  temporalOption: number;
  handleOption: (option: number) => void;
  loadingAddress: boolean;
  handleChangeOption: (option: number) => void;
  organizationOfficeAddress?: TdestinationAddress;
  defaultOtherAddress?: TdestinationAddress;
  updateCardListData: () => void;
  closeLocationModal: () => void;
}) => {
  const { t } = useTranslation();

  const [loadingAddressOffice, setLoadingAddressOffice] = useState<boolean>(false);
  const [loadingOtherAddress, setLoadingOtherAddress] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:tools:locationsSelectModal:';
  const displayLoader = loadingAddress || loadingAddressOffice || loadingOtherAddress;

  const organizationOfficeAddressText = organizationOfficeAddress?.address
    ? t(`${i18nLanguageKey}detailBlue:edit`)
    : t(`${i18nLanguageKey}detailBlue:add`);

  const defaultOtherAddressText = defaultOtherAddress?.address
    ? t(`${i18nLanguageKey}detailBlue:edit`)
    : t(`${i18nLanguageKey}detailBlue:add`);

  const disabledFirstStep = () => !!(temporalOption === 1 && !organizationOfficeAddress?.locationId);

  const updateOfficeAddress = async () => {
    setLoadingAddressOffice(true);
    try {
      await uploadToolsLocationWithoutLogistics([productId], Number(organizationOfficeAddress?.locationId));
      await updateCardListData();
      closeLocationModal();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingAddressOffice(false);
    }
  };

  const disabledOtherFirstStep = () => temporalOption === 2 && !defaultOtherAddress?.locationId;

  const isContineButtonDisabled =
    !temporalOption ||
    (temporalOption === 2 ? loadingOtherAddress : loadingAddressOffice) ||
    (temporalOption === 2 ? disabledOtherFirstStep() : disabledFirstStep());

  const updateOtherAddress = async () => {
    setLoadingOtherAddress(true);
    try {
      await uploadToolsLocationWithoutLogistics([Number(productId)], Number(defaultOtherAddress?.locationId));
      await updateCardListData();
      closeLocationModal();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingOtherAddress(false);
    }
  };

  const continueButtonHandleClick = () => {
    if (temporalOption === 1) return updateOfficeAddress();
    if (temporalOption === 2) return updateOtherAddress();
    return handleChangeOption(temporalOption);
  };

  return (
    <div id="locationsSelectModal">
      <div className="locationTitle"> {t(`recurrentWords:addToolLocation`)} </div>
      <div className="locationSubtitle">Actualiza la ubicación de la herramienta </div>
      <div className="locationSelectOption">{t(`${i18nLanguageKey}locationSubtitle`)}</div>
      {displayLoader && <GenericLoader sizeLoader={12} borderWidth={1} percentageSpinner={78} />}
      {!displayLoader && (
        <div className="continerOptions">
          <BordRadioButton
            label={t(`${i18nLanguageKey}office`)}
            onClick={() => handleOption(1)}
            isButtonActive={temporalOption === 1}
            labelProps={{ style: { color: bordColorTheme.content.default } }}
          />
          <div className="detailAddress">
            {truncateText(organizationOfficeAddress?.address || '', 45)}
            <BordTextButton
              text={organizationOfficeAddressText}
              onClick={() => handleChangeOption(1)}
              textProps={{ style: { fontSize: 12 } }}
              rightIconProps={organizationOfficeAddress?.address ? { variant: 'edit', standardSize: 12 } : undefined}
            />
          </div>
          <BordRadioButton
            label={t(`${i18nLanguageKey}otherDirection`)}
            onClick={() => handleOption(2)}
            isButtonActive={temporalOption === 2}
            labelProps={{ style: { color: bordColorTheme.content.default } }}
          />
          <div className="detailAddress">
            {truncateText(defaultOtherAddress?.address || '', 45)}
            <BordTextButton
              text={defaultOtherAddressText}
              onClick={() => handleChangeOption(2)}
              textProps={{ style: { fontSize: 12 } }}
              rightIconProps={defaultOtherAddress?.address ? { variant: 'edit', standardSize: 12 } : undefined}
            />
          </div>
        </div>
      )}
      <BordButton
        label={t(`${i18nLanguageKey}nudosButton`)}
        isLoading={temporalOption === 2 ? loadingOtherAddress : loadingAddressOffice}
        disabled={isContineButtonDisabled}
        onClick={continueButtonHandleClick}
        customWidth="w-380"
        customClassName="buttonContainer"
      />
    </div>
  );
};

export default LocationsSelectModal;
