import { TstoragePlace } from '../types/assignationFluxes';

const locationFormat = (locationDestination: TstoragePlace) => {
  switch (locationDestination) {
    case 'user':
      return 'Employee';
    case 'office':
      return 'Office';
    case 'nudos':
      return 'Warehouse';
    case 'warranty':
      return 'Warranty';
    case 'diagnostic':
      return 'Diagnosis';
    default:
      return 'Other_Address';
  }
};

export { locationFormat };
