const SquareSuriname = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#0A6A30"
            fillRule="evenodd"
            d="M0 11.7333H22V16H0V11.7333ZM0 0H22V4.26667H0V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M0 11.7333H22V16H0V11.7333ZM0 0H22V4.26667H0V0Z"
            clipRule="evenodd"
            opacity="0.2"
          />
          <path fill="#AF010D" fillRule="evenodd" d="M0 5H22V11H0V5Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M11 9.14548L9.76367 10L9.99916 8.19036L9 6.90803L10.3818 6.64603L11 5L11.6182 6.64714L13 6.90913L11.9992 8.19147L12.2347 10"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSuriname;
