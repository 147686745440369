const RoundMalta = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path fill="#F93939" d="M16 0C24.8365 0 32 7.1635 32 16C32 24.8365 24.8365 32 16 32" />
        <path
          fill="#ACABB1"
          d="M11.13 6.26077V4.17383H9.04305V6.26077H6.95605V8.3477H9.04305V10.4347H11.13V8.3477H13.2169V6.26077H11.13Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMalta;
