import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  LoaderProductDetails,
  ModalCountryChangeAlert,
  SecondaryImages,
  SingleCountryAddToCartButton
} from '../Components';
import { NudosBreadcrumbButton } from '../../../components/NudosComponents';
import useCartState from '../../../state/useCartState';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import { useTranslation } from 'react-i18next';
import { segmentTrackEvent } from '../../../utils/segment';
import { getProductDetails } from '../../../services/services';
import { formatOrgData } from '../../../utils/orgFormatData';
import useStoreCountryState, { IStoreCountry } from '../../../state/useStoreCountryState';
import { displayErrorNotification, displaySuccessNotification } from '../../../utils/displayNudosStandardNotifications';
import { IProductsUpdate } from '../../../types/cart';
import { getShoppingCart, updateProductCart } from '../../../services/cart.services';
import useShopStore from '../../../state/useShopContext';
import useCheckoutStore from '../checkout/state';
import { getCountryDataFromCountryCode, getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { ICountryDetail } from '../../../types/countries';
import RedirectionToCheckoutView from '../../RedirectionToCheckoutView/RedirectionToCheckoutView';
import {
  ListOfAvailableCountries,
  ProductEstimatedDelivery,
  ProductPills,
  ProductSpecs,
  ProductTags
} from './Components';
import BordPhoto from '../../../components/BordDesignSystem/BordPhoto/BordPhoto';
import { bordColorTheme } from '../../../styles/constants';
import './InternalProductCatalog.scss';

interface IFormatPills {
  name: string;
  value?: string;
}

const InternalProductCatalog = () => {
  const { t } = useTranslation();
  const { replace, push } = useHistory();
  const { search, pathname } = useLocation();
  const orgInfo = formatOrgData();
  const { setStoreCountry, storeCountry } = useStoreCountryState();
  const { step: checkoutStep, setStep } = useCheckoutStore();
  const { stateSubscription } = useStateSubscriptions();
  const { itemsCart, setCart } = useShopStore();
  const { setGlobalShippingCart } = useCartState();
  const { state }: { state: { returnUrl: string; returnText: string } } = useLocation();
  const { id } = useParams<{ id: string }>();
  const [openChangeCountryModal, setOpenChangeCountryModal] = useState<IStoreCountry>();
  const [loadingSpecificCountry, setLoadingSpecificCountry] = useState<number>();
  const [loadingAndDeleteCart, setLoadingAndDeleteCart] = useState<boolean>(false);
  const [totalSaving, setTotalSaving] = useState<string>('0');
  const [aSuccessfulUploadWasPerformed, setASuccessfulUploadWasPerformed] = useState<boolean>(false);
  const [allCountries, setAllCountries] = useState<ICountryDetail[]>();
  const [formatPills, setFormatPills] = useState<IFormatPills[]>();
  const [tagsProfiles, setTagProfiles] = useState<IFormatPills[]>();

  const SWRKey =
    storeCountry && storeCountry?.countryCode
      ? `${id}?countryCode=${storeCountry?.countryCode}&organizationId=${orgInfo?.organizationId}`
      : '';

  const searchParams = new URLSearchParams(search);
  const { data: productDetailData, error: productDetailError } = useSWR(SWRKey, getProductDetails);
  const productLoading = !productDetailData && !productDetailError;
  const { isPrime } = stateSubscription;
  const specsList =
    productDetailData?.categoryName !== 'Accesorios' &&
    productDetailData?.specs &&
    Object.entries(productDetailData?.specs).length > 0
      ? productDetailData?.specs
      : undefined;

  const showSpecialElements = Number(productDetailData?.prices?.pricePrimeUsd || 0) >= 170;
  const { returnUrl, returnText } = state || {};
  const navigationCountryCode = searchParams.get('countryCode') || undefined;
  const navigationCountryDiffersFromLocalCountry =
    !!navigationCountryCode && !!storeCountry?.countryCode && navigationCountryCode !== storeCountry?.countryCode;
  const hasProductsSelected = !!itemsCart?.products?.length;
  const navigationCountryData = getCountryDataFromCountryCode(navigationCountryCode, allCountries);
  const productURL = pathname + search;
  const productDetailErrorAndNoData = productDetailError && !productDetailData;

  const changeCountryStoreValidation =
    productDetailErrorAndNoData &&
    !aSuccessfulUploadWasPerformed &&
    navigationCountryDiffersFromLocalCountry &&
    !hasProductsSelected &&
    navigationCountryData &&
    !loadingSpecificCountry;

  const redirectToConfirmationViewValidation =
    productDetailErrorAndNoData &&
    !aSuccessfulUploadWasPerformed &&
    navigationCountryDiffersFromLocalCountry &&
    hasProductsSelected &&
    !loadingAndDeleteCart;

  const URLCountryData = {
    id: navigationCountryData?.id || 0,
    countryFlag: navigationCountryData?.flag || '',
    countryName: navigationCountryData?.name || '',
    countryCode: navigationCountryData?.code || '',
    nameStringId: navigationCountryData?.nameStringId || ''
  };

  const changeStoreCountry = async () => await updateStoreCountryEmptyCart(URLCountryData, true);
  const resetCheckoutStep = () => checkoutStep !== 0 && setStep(0);

  const returnAction = () => {
    if (returnUrl) return replace(returnUrl);
    replace('/catalogue/home');
  };

  const setNewLanguageOnThePlatform = async (specialCountry?: boolean) => {
    const dataCart = await getShoppingCart(Number(orgInfo?.userId), Number(orgInfo?.organizationId));
    const { country } = dataCart;
    const newCountryData = specialCountry ? navigationCountryData : country;
    if (newCountryData) {
      setStoreCountry({
        countryCode: newCountryData?.code,
        countryFlag: newCountryData.flag,
        countryName: newCountryData.name,
        id: newCountryData.id,
        nameStringId: newCountryData?.nameStringId || '',
        storageEnabled: newCountryData?.storage
      });
      setOpenChangeCountryModal(undefined);
      !specialCountry && displaySuccessNotification({ customJSXMessage: <>Cambiaste el país de compra con éxito</> });
    }
  };

  const updateStoreCountryEmptyCart = async (country: IStoreCountry, specialCountry?: boolean) => {
    setLoadingSpecificCountry(country.id);
    const createCartBody: IProductsUpdate = {
      userId: Number(orgInfo?.userId),
      havePrime: true,
      organizationId: Number(orgInfo?.organizationId),
      products: [],
      countryCode: country?.countryCode || ''
    };
    try {
      await updateProductCart(createCartBody);
      await setNewLanguageOnThePlatform(specialCountry);
      resetCheckoutStep();
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingSpecificCountry(undefined);
    }
  };

  const updateStoreCountryCartWithProducts = async (specialCountry?: boolean) => {
    const newCountryData = specialCountry ? URLCountryData : openChangeCountryModal;
    const createCartBody: IProductsUpdate = {
      userId: Number(orgInfo?.userId),
      havePrime: true,
      organizationId: Number(orgInfo?.organizationId),
      products: [],
      countryCode: newCountryData?.countryCode || ''
    };
    if (itemsCart?.shoppingCartId && newCountryData) {
      setLoadingAndDeleteCart(true);
      try {
        const resetedCart = {
          shoppingCartId: null,
          userId: Number(orgInfo?.userId),
          organizationId: Number(orgInfo?.organizationId),
          references: null,
          numberOfProducts: null,
          total: null,
          products: null,
          totalDiscountValue: null,
          totalWithoutPrime: null
        };
        setCart(resetedCart);
        setGlobalShippingCart(undefined);
        localStorage.setItem('bagItems', JSON.stringify(resetedCart));
        localStorage.removeItem('currentOrderShippingData');
        localStorage.setItem('resetCheckoutSteps', 'true');
        await updateProductCart(createCartBody);
        await setNewLanguageOnThePlatform(specialCountry);
        resetCheckoutStep();
        push(productURL);
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoadingAndDeleteCart(false);
      }
    }
  };

  const generatePills = () => {
    const pills =
      typeof productDetailData?.pills === 'string' ? JSON.parse(productDetailData?.pills) : productDetailData?.pills;
    if (pills) {
      const myKeys = Object.keys(pills);
      const myPillsList = [];
      const tagProfilesList = [];
      if (myKeys.length > 0) {
        for (const key of myKeys) {
          if (!key.includes('Perfil') && !key.includes('Gama')) {
            const objetPill = { name: key, value: pills?.[key] };
            if (key === 'Cargo') {
              myPillsList.unshift(objetPill);
            } else {
              myPillsList.push(objetPill);
            }
          }
          if (key.includes('Perfil')) {
            const objetPill = { name: key, value: pills?.[key] };
            tagProfilesList.push(objetPill);
          }
        }
        if (myPillsList && myPillsList.length > 0) {
          setFormatPills([...[tagProfilesList[0]], ...myPillsList]);
        }
        if (tagProfilesList && tagProfilesList.length > 0) {
          setTagProfiles([...tagProfilesList]);
        }
      }
    }
  };

  useEffect(() => {
    if (!totalSaving) setTotalSaving(totalSaving);
  }, [totalSaving]);

  useEffect(() => {
    if (productDetailData) {
      segmentTrackEvent({
        catalogueProductView: {
          SKUId: productDetailData.sku || '',
          ProductName: productDetailData?.productName,
          TypeOfPlatform: isPrime ? 'Prime' : 'Free'
        }
      });
      setASuccessfulUploadWasPerformed(true);
    }
    generatePills();
  }, [productDetailData]);

  const openCountryChangeAlertModal = (openData: IStoreCountry) => {
    setOpenChangeCountryModal(openData);
  };

  useEffect(() => {
    getListOfCountriesByOfferedService(setAllCountries);
  }, []);

  if (productLoading) return <LoaderProductDetails />;

  if (productDetailError && !productDetailData && aSuccessfulUploadWasPerformed) push('/catalogue/home');

  if (redirectToConfirmationViewValidation) {
    return <RedirectionToCheckoutView openLinkAction={() => updateStoreCountryCartWithProducts(true)} />;
  }

  if (changeCountryStoreValidation) changeStoreCountry();

  if (productDetailErrorAndNoData && !aSuccessfulUploadWasPerformed && !navigationCountryDiffersFromLocalCountry)
    push('/catalogue/product-not-found');

  return (
    <>
      {productDetailData && (
        <main id="internalProductCatalog">
          {openChangeCountryModal && (
            <ModalCountryChangeAlert
              loadingModal={loadingAndDeleteCart}
              firstAction={() => {
                segmentTrackEvent({ catalogueChangeCountryNoClick: null });
                setOpenChangeCountryModal(undefined);
              }}
              secondAction={() => updateStoreCountryCartWithProducts()}
            />
          )}
          <section className="leftSection">
            <NudosBreadcrumbButton
              customClassName="mb-40 h-16"
              returnText={returnText || productDetailData?.categoryName || t('nodi:nudosModalContentTextBack')}
              returnAction={() => returnAction()}
            />
            <div className="nameSection">
              <div className="brandContainer">
                <BordPhoto
                  variant="circle"
                  size="s32"
                  url={productDetailData?.brandImage || ''}
                  oneToneIconProps={{ variant: 'bord', customWidth: '3.2rem', stroke: bordColorTheme.navyBlue[700] }}
                  imageProps={{ style: { height: 'auto', width: 'auto', maxHeight: '65%', maxWidth: '65%' } }}
                />
              </div>
              <div className="nameContainer">{productDetailData?.productName}</div>
            </div>
            <ProductPills pillsData={formatPills} />
            <ProductTags tagsProfiles={tagsProfiles} />
            <div className="descriptionSection">
              {productDetailData.longDescription ? productDetailData.longDescription : productDetailData.description}
            </div>
            <ProductEstimatedDelivery productDetailData={productDetailData} />
            <ProductSpecs specs={specsList} />
            {!!productDetailData?.availableCountries && productDetailData?.availableCountries.length > 0 && (
              <>
                <div className="lineGraySpace" />
                <ListOfAvailableCountries
                  availableCountries={productDetailData?.availableCountries}
                  showSpecialElements={showSpecialElements}
                  isPrime={isPrime}
                  skuId={productDetailData?.sku}
                  productName={productDetailData.productName}
                  loadingSpecificCountry={loadingSpecificCountry}
                  updateStoreCountryEmptyCart={updateStoreCountryEmptyCart}
                  openCountryChangeAlertModal={openCountryChangeAlertModal}
                />
              </>
            )}
          </section>

          <section className="rightSection">
            <SecondaryImages product={productDetailData} />
            <div className="containerPrices">
              {!!storeCountry && !!productDetailData && (
                <SingleCountryAddToCartButton
                  productInfo={productDetailData}
                  showSpecialElements={showSpecialElements}
                  setTotalSaving={totalSaving => setTotalSaving(totalSaving)}
                />
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
};

export default InternalProductCatalog;
