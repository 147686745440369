import BordOneToneIcon from '../../BordOneToneIcon/BordOneToneIcon';
import BordButton from '../../BordButton/BordButton';
import { IBordModalContent } from '../BordModal.types';
import BordBackButton from '../../BordBackButton/BordBackButton';
import './ModalContent.scss';

const ModalContent = ({
  CustomModalComponent,
  toCloseModal,
  customWidth = '',
  customHeight = '',
  leftAlignmentTitle,
  customHeaderClass,
  excludeHeader = false,
  title = '',
  subTitle = '',
  showButtonOne = false,
  showButtonTwo = false,
  sizeButtonOne = 'w-180',
  sizeButtonTwo = 'w-180',
  textButtonOne = 'Button Text',
  textButtonTwo = 'Button Text',
  modalSize = 'md',
  onClickButtonTwo,
  onClickBackButton,
  buttonOneProps,
  buttonTwoProps,
  customSubtitleClass,
  customModalContent,
  withoutHeaderCustomClass,
  noPadding,
  excludeCloseButton = false,
  stylesCustomHeaderProps
}: IBordModalContent) => {
  const defaultModalSize = 'md';
  const onCloseModal = () => toCloseModal && toCloseModal(false);

  const onClickButtonOne = () => onClickBackButton && onClickBackButton();
  const sizeStyles = {
    width: customWidth,
    maxWidth: customWidth,
    height: customHeight
  };
  return (
    <div
      className={`modalContent modalShow ${customModalContent || ''} ${modalSize ?? defaultModalSize}`}
      style={sizeStyles}
    >
      {!!onClickBackButton && (
        <div className="backContentButton" onClick={onClickButtonOne}>
          <BordBackButton buttonText="Back" />
        </div>
      )}
      {!excludeHeader && (
        <div className={`${customHeaderClass || ''} ${leftAlignmentTitle ? 'leftAlignmentTitle' : ''} contentHeader`} style={{
         ...stylesCustomHeaderProps
        }}>
          <div className={`${leftAlignmentTitle ? 'leftAlignmentTitle' : ''} text-center`}>
            <div className="title">{title ?? ''}</div>
            <div className={`subTitle ${customSubtitleClass || ''}`}>{subTitle ?? ''}</div>
          </div>
        </div>
      )}
      <div
        className={`${!excludeHeader ? 'customModalContainer' : 'withoutHeader'} ${withoutHeaderCustomClass || ''}`}
        style={{ padding: noPadding ? 0 : '' }}
      >
        {CustomModalComponent}
      </div>
      {(showButtonOne || showButtonTwo) && (
        <div className="buttonsContainer">
          {showButtonOne && (
            <BordButton customWidth={sizeButtonOne} modeButton="tertiary" label={textButtonOne} {...buttonOneProps} />
          )}
          {showButtonTwo && (
            <BordButton
              customWidth={sizeButtonTwo}
              label={textButtonTwo}
              onClick={onClickButtonTwo}
              {...buttonTwoProps}
            />
          )}
        </div>
      )}
      {!excludeCloseButton && toCloseModal && (
        <div className="exitContentButton" onClick={onCloseModal}>
          <BordOneToneIcon variant="x" customWidth="1.8rem" />
        </div>
      )}
    </div>
  );
};

export default ModalContent;
