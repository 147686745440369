import useStateSubscriptions from '../../state/useStateSubscriptions';
import './ModulesComingSoonTooltip.scss';
import { FC, useEffect, useState } from 'react';

interface IModulesComingSoonTooltip {
  title?: string;
  subtitle?: string;
  elementName?: string;
}

const ModulesComingSoonTooltip: FC<IModulesComingSoonTooltip> = ({
  title,
  subtitle,
  elementName
}: IModulesComingSoonTooltip) => {
  const { setComingSoonTooltip } = useStateSubscriptions();
  const [position, setPosition] = useState<{ x: number; y: number } | undefined>();

  useEffect(() => {
    if (elementName) {
      const myElement = document.getElementById(elementName);
      const positionIcon = myElement ? myElement.getBoundingClientRect() : undefined;
      setPosition({ x: Math.round(positionIcon?.x || 0) || 0, y: positionIcon?.y || 0 });
    }
  }, [elementName]);
  return (
    <>
      {position && (
        <div
          id="containerCommingTooltip"
          style={{ top: `${position?.y ? position?.y - 10 : 0}px`, left: `${position?.x ? position?.x : 0}px` }}
          onMouseLeave={() => {
            setComingSoonTooltip(undefined);
          }}
          onMouseOut={() => {
            setComingSoonTooltip(undefined);
          }}
          onMouseDown={() => {
            setComingSoonTooltip(undefined);
          }}
        >
          <div id="modulesComingSoonTooltip">
            <div className="commingTitle">{`${title || '¡Proximamente!'}`}</div>
            <div className="commingSubtitle">{`${
              subtitle || 'Podrás crear los workspaces para tus equipos en un solo lugar'
            }`}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModulesComingSoonTooltip;
