import { BordOneToneIcon, BordPhoto } from '../../../../../components/BordDesignSystem';
import '../../Components/EmployeeCard/EmployeeCard.scss';
import '../../../../../components/EmployeePills/EmployeePills.scss';

const SkeletonEmployeeCard = () => {
  return (
    <div className="employeeCard employeeCardSkeleton">
      <BordPhoto variant="square" size="s56" showSkeletonLoader />
      <div className="addressAndName">
        <div
          className="name animationLoader"
          style={{
            height: '2rem',
            borderRadius: '0.4rem'
          }}
        />
        <div className="address">
          <div className="icon animationLoader" style={{ width: '1.2rem', height: '1.2rem', borderRadius: '0.4rem' }} />
          <div
            className="addressText animationLoader"
            style={{ width: '8rem', height: '1.2rem', borderRadius: '0.4rem' }}
          />
        </div>
      </div>
      <div className="employeePills">
        <div className="pill">
          <div className="text animationLoader" style={{ width: '7rem', height: '1.2rem', borderRadius: '0.4rem' }} />
        </div>
        <div className="pill">
          <div className="text animationLoader" style={{ width: '4.8rem', height: '1.2rem', borderRadius: '0.4rem' }} />
        </div>
        <div className="pill">
          <div className="text animationLoader" style={{ width: '7rem', height: '1.2rem', borderRadius: '0.4rem' }} />
        </div>
      </div>
      <div
        className="assignedToolsSection animationLoader"
        style={{ width: '12rem', height: '4.8rem', borderRadius: '1.2rem' }}
      />
      <div className="threeDotsButton skeletonIcon">
        <BordOneToneIcon variant="arrowRightCircle" standardSize={20} />
      </div>
    </div>
  );
};

export default SkeletonEmployeeCard;
