const SquareBelarus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M3.14286 0 5.2381 1.6 3.14286 3.2V0Zm0 3.2L5.2381 4.8 3.14286 6.4V3.2Zm0 3.2L5.2381 8 3.14286 9.6V6.4Zm0 3.2 2.09524 1.6-2.09524 1.6V9.6Zm0 3.2 2.09524 1.6L3.14286 16v-3.2ZM2.09524 0 0 1.6l2.09524 1.6V0Zm0 3.2L0 4.8l2.09524 1.6V3.2Zm0 3.2L0 8l2.09524 1.6V6.4Zm0 3.2L0 11.2l2.09524 1.6V9.6Zm0 3.2L0 14.4 2.09524 16v-3.2Zm0-12.8h1.04762v2.13333H2.09524V0Zm0 13.8667h1.04762V16H2.09524v-2.1333Zm0-8.53337h1.04762v2.13334H2.09524V5.33333Zm0-2.13333h1.04762v1.06667H2.09524V3.2Zm0 8.5333h1.04762V12.8H2.09524v-1.0667Zm0-3.19997h1.04762v2.13337H2.09524V8.53333Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M5 12h17v4H5v-4Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBelarus;
