import create from 'zustand';

export interface IStoreCountry {
  id: number;
  countryFlag: string;
  countryName: string;
  countryCode: string;
  nameStringId: string;
  storageEnabled?: boolean;
}
export interface lenguageState {
  storeCountry: IStoreCountry | null;
  initialCountryTooltip: boolean;
  setStoreCountry: (lenguage: IStoreCountry) => void;
  setInitialCountryTooltip: (initialCountryTooltip: boolean) => void;
}

const useStoreCountryState = create<lenguageState>(set => ({
  storeCountry: null,
  setStoreCountry: storeCountry => set({ storeCountry }),
  initialCountryTooltip: false,
  setInitialCountryTooltip: initialCountryTooltip => set({ initialCountryTooltip })
}));

export default useStoreCountryState;
