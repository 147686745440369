import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { bordLoginMobile } from '../../assets/images/views/signIn';
import BordTextButton from '../../components/BordDesignSystem/BordTextButton/BordTextButton';
import { bordLandingPage } from '../../constants';
import usePlatform from '../../hooks/usePlatform';
import './MobileWarning.scss';

const MobileWarning = () => {
  const { isMobile } = usePlatform();
  const { push } = useHistory();

  useEffect(() => {
    if (!isMobile) push('/login');
  }, [isMobile]);

  return (
    <div className="mobileWarning">
      <div className="content">
        <div className="imageContainer">
          <img src={bordLoginMobile} />
        </div>

        <div className="explanation">Para registrarte te invitamos a abrir .bord desde tu computadora</div>

        <BordTextButton
          className="redirection"
          text={'www.bord.co'}
          onClick={() => (window.location.href = bordLandingPage)}
        />
      </div>
    </div>
  );
};

export default MobileWarning;
