const RoundJapan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M16.0004 22.9565C19.8424 22.9565 22.9569 19.8419 22.9569 16C22.9569 12.158 19.8424 9.04346 16.0004 9.04346C12.1585 9.04346 9.04395 12.158 9.04395 16C9.04395 19.8419 12.1585 22.9565 16.0004 22.9565Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundJapan;
