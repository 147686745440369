import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" ref={ref} {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      stroke="#383838"
      strokeLinecap="square"
      d="M28.8 13.858c0 10.66-10.667 17.056-12.8 17.056-2.133 0-12.8-6.396-12.8-17.056A12.798 12.798 0 0 1 16 1.067c7.068 0 12.8 5.728 12.8 12.791Z"
    />
    <path stroke="#383838" strokeLinecap="square" d="M16 18.123a4.267 4.267 0 1 0 0-8.533 4.267 4.267 0 0 0 0 8.533Z" />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconPin = memo(ForwardRef);
export default IconPin;
