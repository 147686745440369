const RoundKuwait = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M31.0048 21.5651C31.6479 19.8318 32 17.9571 32 16C32 14.0428 31.6479 12.1681 31.0048 10.4348L16 9.04346L0.995188 10.4348C0.352063 12.1681 0 14.0428 0 16C0 17.9571 0.352063 19.8318 0.995188 21.5651L16 22.9565L31.0048 21.5651Z"
        />
        <path
          fill="#F93939"
          d="M16 32C22.8794 32 28.7441 27.658 31.0047 21.5652H0.995117C3.25587 27.658 9.12049 32 16 32Z"
        />
        <path fill="#6DA544" d="M16 0C9.12049 0 3.25587 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 16 0Z" />
        <path
          fill="black"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C6.31722 25.6828 7.90909 24.091 10.4348 21.5653V10.4348L4.68628 4.68628Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundKuwait;
