const RoundVirginIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FCFCFC"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M18.7178 11.1778C18.7178 9.67707 17.5011 8.46045 16.0003 8.46045C14.4995 8.46045 13.2829 9.67707 13.2829 11.1778H7.30469C7.30469 12.6554 8.59181 13.8533 10.0694 13.8533H9.98037C9.98037 15.331 11.1781 16.529 12.6559 16.529C12.6559 17.8373 13.5956 18.9244 14.8365 19.1568L14.8296 19.1725H17.1711L17.1642 19.1568C18.4052 18.9243 19.3447 17.8373 19.3447 16.529C20.8225 16.529 22.0203 15.331 22.0203 13.8533H21.9312C23.4088 13.8533 24.6959 12.6554 24.6959 11.1778H18.7178Z"
        />
        <path
          fill="#FFDA2C"
          d="M14.806 18.895L13.1211 22.699C14.0101 23.0603 14.9818 23.2612 16.0006 23.2612C17.0194 23.2612 17.9911 23.0604 18.88 22.699L17.1952 18.895H14.806Z"
        />
        <path
          fill="white"
          d="M12.5215 12.5142V17.1948C12.5215 19.8572 15.9997 20.6731 15.9997 20.6731C15.9997 20.6731 19.478 19.8572 19.478 17.1948V12.5142H12.5215Z"
        />
        <path
          fill="#3ECBF8"
          d="M3.87967 16.1128 5.76086 21.3743 7.62248 16.1128H9.04323L6.32911 23.0693H5.19255L2.45898 16.1128H3.87967ZM25.7393 23.0693V16.1128H27.0913V23.0693H25.7393Z"
        />
        <path
          fill="#F93939"
          d="M13.9131 14.0259V19.698C14.3913 20.0499 14.9058 20.292 15.3044 20.447V14.0259H13.9131ZM18.0866 14.0259V19.698C17.6084 20.0499 17.0939 20.292 16.6953 20.447V14.0259H18.0866Z"
        />
        <path fill="#1A47B8" d="M12.5215 12.5142H19.478V14.7214H12.5215V12.5142Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundVirginIslands;
