import { useTranslation } from 'react-i18next';
import ConfigurationCommentsModalContent from './ConfigurationCommentsModalContent';
import { capitalize } from '../../../../../../utils/unCamelCase';
import BordModal from '../../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../../components/BordDesignSystem/BordModal/components/ModalContent';

const ConfigurationCommentsModal = ({
  closeModalCallback,
  comments
}: {
  closeModalCallback: () => void;
  comments: string;
}) => {
  const { t } = useTranslation();
  const translateKey = 'services:logistics:additionalServices:configurationModal';
  const subTitleText = t(`${translateKey}:subTitle`);

  return (
    <BordModal
      modalContent={
        <ModalContent
          title={capitalize(t('recurrentWords:configuration'))}
          subTitle={subTitleText}
          toCloseModal={closeModalCallback}
          CustomModalComponent={<ConfigurationCommentsModalContent comments={comments} />}
          showButtonTwo
          buttonTwoProps={{
            label: t('recurrentWords:close'),
            onClick: closeModalCallback
          }}
        />
      }
    />
  );
};

export default ConfigurationCommentsModal;
