const SquareBelize = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path
            fill="#fff"
            d="M11 12.8c2.6036 0 4.7143-2.1491 4.7143-4.80005 0-2.65097-2.1107-4.8-4.7143-4.8-2.60364 0-4.71429 2.14903-4.71429 4.8C6.28571 10.6509 8.39636 12.8 11 12.8Z"
          />
          <path
            fill="#CBCBCB"
            fillRule="evenodd"
            d="M8.90475 8.53345h4.19045v1.06666H8.90475V8.53345Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M8.38095 5.33327h1.04762v1.06666H8.38095V5.33327ZM10.4762 4.2666h1.0476v1.06667h-1.0476V4.2666Zm2.0952 1.06667h1.0476v1.06666h-1.0476V5.33327ZM13.619 7.4666h1.0477v1.06667H13.619V7.4666Zm-1.0476 2.13333h1.0476v1.06667h-1.0476V9.59993Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M11.5238 6.3999h1.0476v1.06667h-1.0476V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#AE6A3E"
            fillRule="evenodd"
            d="M11.5238 7.46655h1.0476v1.06667h-1.0476V7.46655ZM9.42856 6.3999h1.04764v1.06667H9.42856V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M9.42856 7.46655h1.04764v1.06667H9.42856V7.46655Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M10.4762 10.6666h1.0476v1.0666h-1.0476v-1.0666ZM8.38096 9.59989h1.04762v1.06671H8.38096V9.59989ZM7.33334 7.46655h1.04762v1.06667H7.33334V7.46655Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M0 13.8667h22V16H0v-2.1333ZM0 0h22v2.13333H0V0Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBelize;
