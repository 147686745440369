const RoundGrenada = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M6.98242 2.78262C9.55023 1.02725 12.6551 0 16.0004 0C19.3456 0 22.4505 1.02725 25.0183 2.78262L16.0004 3.47825L6.98242 2.78262Z"
        />
        <path fill="#1A47B8" d="M16.0003 15.9999L14.9791 14.9788L14.708 15.4644L16.0003 15.9999Z" />
        <path
          fill="#496E2D"
          d="M4.68628 4.68628C-1.56209 10.9347-1.56209 21.0653 4.68628 27.3138 7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628ZM27.3137 4.68628C33.5621 10.9347 33.5621 21.0653 27.3137 27.3138 24.7317 24.7317 22.2484 22.2484 16 16L27.3137 4.68628Z"
        />
        <path
          fill="#A2001D"
          d="M15.9998 21.5652C19.0733 21.5652 21.5649 19.0736 21.5649 16C21.5649 12.9264 19.0733 10.4348 15.9998 10.4348C12.9262 10.4348 10.4346 12.9264 10.4346 16C10.4346 19.0736 12.9262 21.5652 15.9998 21.5652Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9995 10.4348 17.247 14.2741H21.2843L18.0182 16.6474 19.2657 20.4869 15.9995 18.1141 12.7333 20.4869 13.981 16.6474 10.7148 14.2741H14.752L15.9995 10.4348ZM15.9995 0C15.9977 0 15.996.00015625 15.9941.00015625H16.0048C16.0031.00015625 16.0013 0 15.9995 0Z"
        />
        <path
          fill="#A2001D"
          d="M16.0057 0H15.9951C11.5792.0014375 7.58169 1.79163 4.6875 4.6855H27.3133C24.4191 1.79163 20.4215.0014375 16.0057 0ZM4.6875 27.3145C7.58288 30.2094 11.5825 31.9999 16.0003 31.9999 20.4181 31.9999 24.4178 30.2094 27.3131 27.3145H4.6875Z"
        />
        <path
          fill="#FFDA2C"
          d="M15.9995 1.73901 16.2586 2.53614H17.0967L16.4187 3.02883 16.6777 3.82595 15.9995 3.33333 15.3215 3.82595 15.5805 3.02883 14.9023 2.53614H15.7405L15.9995 1.73901ZM12.1206 1.73901 12.3797 2.53614H13.2178L12.5398 3.02883 12.7988 3.82595 12.1206 3.33333 11.4426 3.82595 11.7016 3.02883 11.0234 2.53614H11.8616L12.1206 1.73901ZM19.8794 1.73901 20.1385 2.53614H20.9766L20.2985 3.02883 20.5576 3.82595 19.8794 3.33333 19.2014 3.82595 19.4604 3.02883 18.7822 2.53614H19.6204L19.8794 1.73901ZM15.9995 28.1738 16.2586 28.971H17.0967L16.4187 29.4636 16.6777 30.2608 15.9995 29.7681 15.3215 30.2608 15.5805 29.4636 14.9023 28.971H15.7405L15.9995 28.1738ZM12.1206 28.1738 12.3797 28.971H13.2178L12.5398 29.4636 12.7988 30.2608 12.1206 29.7681 11.4426 30.2608 11.7016 29.4636 11.0234 28.971H11.8616L12.1206 28.1738ZM19.8794 28.1738 20.1385 28.971H20.9766L20.2985 29.4636 20.5576 30.2608 19.8794 29.7681 19.2014 30.2608 19.4604 29.4636 18.7822 28.971H19.6204L19.8794 28.1738ZM4.87519 15.7357C5.28613 16.385 5.093 17.2444 4.44369 17.6554 3.79444 18.0664 2.93494 17.8733 2.52394 17.224 1.77988 16.0485 2.21131 14.1287 2.21131 14.1287 2.21131 14.1287 4.13106 14.5601 4.87519 15.7357Z"
        />
        <path
          fill="#A2001D"
          d="M4.0716 17.7633C4.45578 17.7633 4.76723 17.4519 4.76723 17.0677C4.76723 16.6835 4.45578 16.3721 4.0716 16.3721C3.68742 16.3721 3.37598 16.6835 3.37598 17.0677C3.37598 17.4519 3.68742 17.7633 4.0716 17.7633Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGrenada;
