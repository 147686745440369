const SquareBotswana = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#3ECBF8" rx="2" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 9.6001h22v1.0667H0V9.6001Z"
          clipRule="evenodd"
        />
        <path
          fill="#151515"
          fillRule="evenodd"
          d="M0 6.3999h22v3.2H0v-3.2Z"
          clipRule="evenodd"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 5.33325h22v1.06667H0V5.33325Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBotswana;
