const RoundCambodia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M0 16c0 2.7719.705188 5.379 1.94544 7.6522L16 25.0435l14.0546-1.3913C31.2948 21.379 32 18.7719 32 16c0-2.7718-.7052-5.379-1.9454-7.65214L16 6.95654 1.94544 8.34786C.705188 10.621 0 13.2282 0 16Z"
        />
        <path
          fill="#1A47B8"
          d="M1.94531 8.34781H30.0544C27.3408 3.37431 22.0646 0 15.9999 0 9.93519 0 4.659 3.37431 1.94531 8.34781ZM15.9999 31.9999c6.0647 0 11.3409-3.3743 14.0545-8.3478H1.94531c2.71369 4.9735 7.98988 8.3478 14.05459 8.3478Z"
        />
        <path
          fill="#fff"
          d="M21.5656 19.1304v-2.0869h-1.3912v-2.7826l-1.3913-1.3914-1.3913 1.3914v-2.7827l-1.3914-1.3913-1.3913 1.3913v2.7827l-1.3913-1.3914-1.3913 1.3914v2.7826h-1.3912v2.0869H9.04395v2.087H22.9569v-2.087h-1.3913Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCambodia;
