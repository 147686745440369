import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    className="text-on-paper"
    width={21}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      id="paper"
      d="M18.582 15.834a93.28 93.28 0 0 0 .15-8.238 2.73 2.73 0 0 0-.524-1.547c-1.256-1.712-2.257-2.776-3.907-4.05a2.683 2.683 0 0 0-1.582-.551 82.77 82.77 0 0 0-1.813-.02c-1.939 0-3.503.06-5.121.17a2.747 2.747 0 0 0-2.556 2.568A93.3 93.3 0 0 0 3.049 10c0 2 .061 3.951.18 5.834a2.747 2.747 0 0 0 2.556 2.568c1.618.111 3.182.17 5.12.17a71.73 71.73 0 0 0 5.12-.17 2.747 2.747 0 0 0 2.557-2.568Z"
      fill="#fff"
      stroke="#C4C4C4"
    />
    <path id="middle-line" d="M8.182 10.023h5.447" stroke="#C4C4C4" strokeLinecap="round" />
    <path
      id="upper-lower-lines"
      d="M8.182 5.714h3.287m-3.287 8.46h5.43-5.43Z"
      stroke="#C4C4C4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const TextOnPaper = memo(ForwardRef);
export default TextOnPaper;
