import { Link } from 'react-router-dom';
import notFoundImage from '../../assets/images/views/NotFound/notFoundImage.webp';
import { handleClickForHelp } from '../../utils/global.utils';
import { BordButton } from '../../components/BordDesignSystem';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className="NotFound">
      <img className="notFoundImage" src={notFoundImage} />
      <div className="notFoundTitle">Lo sentimos, la página que buscas se perdió en el camino. </div>
      <div className="notFoundSubtitle">Si crees que se trata de un error, contacta a nuestro equipo de soporte</div>
      <div className="buttonsContainer">
        <BordButton
          label="Contactar a soporte"
          onClick={handleClickForHelp}
          modeButton="secondary"
          customWidth="w-180"
        />
        <Link to={'dashboard'}>
          <BordButton customClassName="notFoundButton" label="Volver al inicio" />
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
