import create from 'zustand';
import { DTOuploadProductsResponse, IuploadedProductReferenceData } from '../types/uploadExternalTools.types';

export interface iState {
  uploadedToolsData: DTOuploadProductsResponse | undefined;
  setUploadedToolsData: (body: DTOuploadProductsResponse | undefined) => void;
  selectedReferences: IuploadedProductReferenceData[];
  setSelectedReferences: (selectedReferences: IuploadedProductReferenceData[]) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
}

const useLoadTools = create<iState>(set => ({
  uploadedToolsData: undefined,
  setUploadedToolsData: uploadedToolsData => set({ uploadedToolsData }),
  selectedReferences: [],
  setSelectedReferences: selectedReferences => set({ selectedReferences }),
  currentStep: 0,
  setCurrentStep: currentStep => set({ currentStep })
}));

export default useLoadTools;
