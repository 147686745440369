const SquareNicaragua = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#3A99FF" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" opacity="0.4" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
          <path fill="#3A99FF" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" opacity="0.4" />
          <path
            fill="#00B731"
            fillRule="evenodd"
            d="M9.42871 9.5999L11.0001 6.3999L12.5716 9.5999H9.42871Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareNicaragua;
