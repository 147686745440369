import { useEffect, useState } from 'react';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { servicesLocationAddress } from '../../../services/account';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { ILocationAddress } from '../../../types/account';
import { TlogisticsOriginOrDestinationData } from '../../../types/requestLogisticsModule';
import { ICountryDetail } from '../../../types/countries';
import { getListOfCountriesByOfferedService } from '../../../utils/getCountries';
import { TformComponentsStandardSizes } from '../../BordDesignSystem/bordDesignSystem.types';
import { BordDropdown, BordFlag } from '../../BordDesignSystem';
import { TcountryCode } from '../../BordDesignSystem/BordFlag/BordFlag.types';
import LocationToolsFlux from '../../../views/Admin/logisticServices/LocationToolsFlux';

/**
 *@property { TcomponentSize } componentSize - It determines the width of the component
 *@property { (newValue: TlogisticsOriginOrDestinationData) => void } updateCurrentValueCallback - the callback function to update the current value of the office as it is stored in the parent component state
 *@property { ILocationAddress } currentValue - the data for the currently selected office
 *@property { boolean } isFocused - a boolean indicating if the component is focused or not. DEFAULT is false.
 *@property { ICountryDetail[] }  filterCountries - A list of country objects from which the office MUST BELONG (only offices from this countries will be shown)
 *@property { ICountryDetail }  currentCountryForExclusion - The data of the current country for excluding the office of this country
 */

const NudosLogisticsOfficeDropdown = ({
  componentSize,
  updateCurrentValueCallback,
  currentValue,
  isFocused,
  filterCountries,
  currentCountryForExclusion,
  countryOfOrigin
}: {
  componentSize?: TformComponentsStandardSizes;
  updateCurrentValueCallback: (newValue: TlogisticsOriginOrDestinationData) => void;
  currentValue?: ILocationAddress;
  isFocused?: boolean;
  filterCountries?: ICountryDetail[];
  currentCountryForExclusion?: ICountryDetail;
  countryOfOrigin?: string;
}) => {
  const orgId = getOrgData()?.organizationId;
  const [offices, setOffices] = useState<ILocationAddress[]>([]);
  const [allOrganizationOffices, setAllOrganizationOffices] = useState<ILocationAddress[]>([]);
  const [officeLoader, setOfficeLoader] = useState<boolean>(false);
  const [showNewOfficeModal, setShowNewOfficeModal] = useState<boolean>(false);
  const [totalCountriesAvailable, setTotalCountriesAvailable] = useState<ICountryDetail[]>();
  const [countriesForCreation, setCountriesForCreation] = useState<ICountryDetail[]>();

  const getCountriesForCreation = () => {
    const organizationsIds = allOrganizationOffices?.map(organizations => organizations?.country?.code);
    const countriesForCreation = totalCountriesAvailable?.filter(
      countries => !organizationsIds.includes(countries?.code)
    );
    setCountriesForCreation(countriesForCreation);
  };

  const selectAValidCountryByDefault = (countryOfOrigin: string, warehousesInValidCountries: ILocationAddress[]) => {
    if (countryOfOrigin) {
      const defaultCountry = warehousesInValidCountries.find(country => countryOfOrigin === country?.country?.code);
      if (defaultCountry) updateCurrentValueCallback(defaultCountry);
    }
  };

  const setValidOffices = (newOfficesList: ILocationAddress[]) => {
    const validCountries =
      filterCountries && filterCountries.length ? filterCountries.map(country => country.code) : undefined;
    const officesInValidCountries = validCountries
      ? newOfficesList.filter(office => validCountries.includes(office.country?.code || ''))
      : newOfficesList;
    const validOfficesExcludingCurrentCountryIfDesired = currentCountryForExclusion
      ? officesInValidCountries.filter(office => office?.country?.code !== currentCountryForExclusion?.code)
      : officesInValidCountries;
    setOffices(validOfficesExcludingCurrentCountryIfDesired);
    if (validOfficesExcludingCurrentCountryIfDesired) {
      selectAValidCountryByDefault(countryOfOrigin || '', validOfficesExcludingCurrentCountryIfDesired);
    }
  };

  const getOfficesByOrganization = async () => {
    if (!orgId) return;
    setOfficeLoader(true);
    try {
      const offices = await servicesLocationAddress(orgId);
      if (offices?.office) {
        setAllOrganizationOffices(offices.office);
        setValidOffices(offices.office);
      }
    } catch {
      displayErrorNotification();
    } finally {
      setOfficeLoader(false);
    }
  };

  const formDropdownOptionsCallback = (
    rawOptions: ILocationAddress[],
    clickOptionCallback?: (option: ILocationAddress) => void,
    searchString?: string
  ) => {
    const createOffice = (
      <div className="createOffice" key="createOfficeOption" onClick={() => setShowNewOfficeModal(true)}>
        Crear oficina
      </div>
    );

    const handleSelectOption = (option: ILocationAddress) => {
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };

    const getOptionText = () => {
      if (officeLoader) return 'Cargando ...';
      if (searchString) return 'No hay resultados para tu búsqueda';
      return 'No tienes oficinas creadas ';
    };

    if (rawOptions.length === 0)
      return [
        <div className="optionContainer" key="loading">
          <div className="optionText">{getOptionText}</div>
          {createOffice}
        </div>
      ];

    const officeOptions = rawOptions.map((rawOfficeData, i) => {
      const flagCountry = rawOfficeData?.country?.code as TcountryCode;
      return (
        <div
          className="optionContainer flex items-center"
          key={`office${i}${rawOfficeData?.id}`}
          onClick={() => handleSelectOption(rawOfficeData)}
        >
          <BordFlag variant="circle" country={flagCountry} standardSize={16} />
          <div className="truncate h-20 pt-5 pl-8" style={{ width: 'calc(100% - 1.6rem)' }}>{`Oficina ${
            rawOfficeData?.country?.name || ''
          }`}</div>
        </div>
      );
    });
    return [...officeOptions, createOffice];
  };

  const formSelectedOptionUICallback = (rawOfficeData: ILocationAddress) => {
    return (
      <div className="optionContainer min-h-20 flex items-center">{`Oficina ${
        rawOfficeData?.country?.name || ''
      }`}</div>
    );
  };

  const filterBySearchStringCallback = (search: string, rawOptions: ILocationAddress[]) => {
    return rawOptions.filter(rawOfficeData =>
      rawOfficeData?.country?.name?.toLowerCase().trim().includes(search.toLowerCase().trim())
    );
  };

  useEffect(() => {
    getCountriesForCreation();
  }, [allOrganizationOffices, totalCountriesAvailable]);

  useEffect(() => {
    getOfficesByOrganization();
  }, [orgId]);

  useEffect(() => {
    getListOfCountriesByOfferedService(setTotalCountriesAvailable);
  }, []);

  useEffect(() => {
    setValidOffices(allOrganizationOffices);
  }, [allOrganizationOffices, currentCountryForExclusion]);

  return (
    <div className="NudosLogisticsOfficeDropdown">
      {showNewOfficeModal && (
        <LocationToolsFlux
          preSelectOption={1}
          officePreSelection={false}
          listOfAllCountries={countriesForCreation || []}
          closeLocationModal={() => setShowNewOfficeModal(false)}
          updateCardListData={() => getOfficesByOrganization()}
        />
      )}

      <BordDropdown
        label="Oficinas"
        componentSize={componentSize}
        rawOptions={offices}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
        currentValue={currentValue}
        isFilled={!!currentValue}
        isFocused={isFocused}
        customPlaceholder="Selecciona la oficina"
      />
    </div>
  );
};

export default NudosLogisticsOfficeDropdown;
