const SquareSouthSudan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#151515" fillRule="evenodd" d="M0 0H22V4.26667H0V0Z" clipRule="evenodd" />
          <path fill="#249F58" fillRule="evenodd" d="M0 11.7334H22V16.0001H0V11.7334Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 5.3335H22V10.6668H0V5.3335Z" clipRule="evenodd" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0L12.5714 8L0 16V0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M4.9589 9.31421L3.56556 10.3734L3.58652 8.60275L2.16699 7.57875L3.82747 7.05395L4.34394 5.36328L5.34966 6.80861L7.08871 6.78728L6.04737 8.20595L6.60575 9.88275L4.9589 9.31421Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSouthSudan;
