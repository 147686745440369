import { useTranslation } from 'react-i18next';
import { IBillingMethod } from '../../../../../../types/orders';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { getOrderRequiredDataByPaymentMethod } from '../../../../../../utils/productDefinitions';
import { formatRoundedUpNumber } from '../../../../../../utils/formatNumbers';
import { PAYMENT_TYPES } from '../../../../../../types/checkout';
import { isValidLink } from '../../../../../../utils/formValidations';
import { IbankAccountInfo } from '../../../../../../types/cart';
import { TlogisticsStatus } from '../../../../../../types/logisticsServicesModule.types';
import { BordOneToneIcon, BordTooltip, BordUploaderButton } from '../../../../../../components/BordDesignSystem';
import { useState } from 'react';

import './PaymentMethodAndLink.scss';

interface IPaymentMethodAndLink {
  paymentMethod?: string | null;
  proofOfPaymentUrl?: string | null;
  paymentLink?: string | null;
  paymentDate?: string | null;
  committedPaymentDate?: string | null;
  exchangeRate?: string | null;
  paymentMethodStringId?: string | null;
  isPaymentReceived?: boolean;
  isLogisticService?: boolean;
  bankAccountInfo?: IbankAccountInfo;
  manualQuote?: boolean;
  quoteDocumentUrl?: string;
  billingMethod?: IBillingMethod;
  invoiceUrl?: string;
  status?: TlogisticsStatus | null;
  paymentType?: string;
}

const PaymentMethodAndLink = ({
  paymentMethodAndLink,
  uploadProofOfPaymentCallback,
  openPaymentInfoModal,
  isLogisticServiceFlux
}: {
  paymentMethodAndLink?: IPaymentMethodAndLink;
  uploadProofOfPaymentCallback: (file?: File) => Promise<void>;
  openPaymentInfoModal: () => void;
  isLogisticServiceFlux?: boolean;
}) => {
  const { t } = useTranslation();

  const {
    paymentMethod,
    proofOfPaymentUrl,
    paymentLink,
    paymentDate,
    committedPaymentDate,
    exchangeRate,
    paymentMethodStringId,
    isPaymentReceived,
    isLogisticService,
    manualQuote,
    quoteDocumentUrl,
    billingMethod,
    invoiceUrl,
    status,
    paymentType
  } = paymentMethodAndLink || {};

  const { requiresProofOfPayment, requiresPaymentLink, showLocalCurrency, requiresBankData } =
    getOrderRequiredDataByPaymentMethod(paymentMethod || '', billingMethod, isLogisticServiceFlux);

  const [deleteFile, setDeleteFile] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:';
  const recurrentWords = 'recurrentWords:';
  const paymentMethodText = paymentMethodStringId
    ? t(`services:paymentType:${paymentMethodStringId}`)
    : paymentMethod || t(`${i18nLanguageKey}paymentMethodValue`);

  const exchangeRateValue = exchangeRate ? formatRoundedUpNumber(+exchangeRate, 2) : t(`${i18nLanguageKey}values`);
  const displayExchangeRate = isLogisticService ? showLocalCurrency && !manualQuote : showLocalCurrency;
  const paymentMethodisARS = paymentMethod === PAYMENT_TYPES.TRANSFER_ARS;
  const paymentMethodisInternational = paymentMethod === PAYMENT_TYPES.INTERNATIONAL_CARD;
  const countryAdjustedExchangeRateValue = paymentMethodisARS ? 'al valor del mercado' : exchangeRateValue;
  const validPaymentLink = isValidLink(paymentLink || '') ? paymentLink : undefined;

  const exchangeRateText = `${t(`${i18nLanguageKey}exchangeRate`)} ${countryAdjustedExchangeRateValue}`;

  const isLogisticAndNoPaymentReceived = isLogisticService ? !isPaymentReceived : true;
  const displayPaymentLink = requiresPaymentLink && isLogisticAndNoPaymentReceived;
  const displayCoveredWithNudosCare = isLogisticServiceFlux && paymentType === 'Care';

  const displayLogisticPayed =
    requiresPaymentLink && isPaymentReceived && isLogisticService && !displayCoveredWithNudosCare;
  const requireQuoteToDisplayLink = manualQuote && !quoteDocumentUrl && !paymentMethodisARS;
  const requireInvoiceToDisplayLink = manualQuote && !invoiceUrl && paymentMethodisARS;
  const tootilValue = requireQuoteToDisplayLink ? 'apruebes la cotización' : 'la factura esté disponible';
  const disabledNudosLinkButton = isLogisticServiceFlux && (requireQuoteToDisplayLink || requireInvoiceToDisplayLink);
  const diplayBankAccountInfo = (requiresBankData || paymentMethodisARS) && status !== 'cancelado';
  const validLinkProofOfPayment = isValidLink(proofOfPaymentUrl || '') ? proofOfPaymentUrl : undefined;
  const validInvoiceLink = isValidLink(invoiceUrl || '');
  const disableVoucherAndSeePaymentLink = paymentMethodisARS && !validInvoiceLink; // Payments in ARS require a valid invoice, since the final value in local currency is indicated there. This was discussed with Juanita here https://bordlatam.slack.com/archives/C037QNL0NLQ/p1731616856621159
  const handleDownloadFile = () => {
    const newWindow = window.open(validLinkProofOfPayment || undefined, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className={`paymentMethodAndLink`}>
      <div className="paymentInfoTitle paymentMethodTitle">{t(`${i18nLanguageKey}paymentMethodTitle`)}</div>
      {!displayCoveredWithNudosCare && (
        <>
          <div className="contentPaymentMethodAndLink">
            <div className="flex items-start justify-between">
              <div>
                <div className="paymentMethod">{paymentMethodText}</div>
                {validPaymentLink && displayPaymentLink && (
                  <a className="activePaymentLink" href={validPaymentLink ?? ''} target="blank">
                    <span>Da clic aquí para pagar</span>
                    <BordOneToneIcon variant="arrowRightCircle" standardSize={12} />
                  </a>
                )}
                {!validPaymentLink && displayPaymentLink && (
                  <div className="paymentLinkPending">
                    <span>{paymentLink || t(`${i18nLanguageKey}paymentLinkPending`)}</span>
                    <BordOneToneIcon variant="clock" standardSize={12} />
                  </div>
                )}
                {displayLogisticPayed && (
                  <div className="paymentSuccess">
                    <span>{t(`${recurrentWords}paymentReceived`)}</span>
                    <BordOneToneIcon variant="checkCircle" standardSize={12} />
                  </div>
                )}
              </div>
              <div>
                {diplayBankAccountInfo && (
                  <div className="flex gap-1 items-center">
                    {disabledNudosLinkButton || disableVoucherAndSeePaymentLink ? (
                      <BordTooltip message={`Podrás ver los datos de pago cuando ${tootilValue}`}>
                        <div className="disabledLink">
                          <BordOneToneIcon variant="info" standardSize={12} />
                          <span>{t(`${i18nLanguageKey}nudosLinkTypeButtonText`)}</span>
                        </div>
                      </BordTooltip>
                    ) : (
                      <div className="enableLink" onClick={openPaymentInfoModal}>
                        <BordOneToneIcon variant="info" standardSize={12} />
                        <span>{t(`${i18nLanguageKey}nudosLinkTypeButtonText`)}</span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {committedPaymentDate && (
              <div className={paymentDate ? 'paymentAgreementSuccess' : 'paymentAgreementError'}>
                {!paymentDate && (
                  <div>
                    <BordOneToneIcon variant="info" standardSize={12} />
                  </div>
                )}
                <span>{`${paymentDate ? 'Pagado el ' : 'Acuerdo de pago para'} ${
                  formatShortDate(paymentDate || committedPaymentDate || '')?.textWithTimezone
                }`}</span>
              </div>
            )}
            {displayExchangeRate && <div className="exchangeRate">{exchangeRateText}</div>}
            {requiresProofOfPayment && !paymentMethodisInternational && (
              <div className="mt-8 paymentField proofOfPaymentButton">
                <BordUploaderButton
                  handleChangeFile={uploadProofOfPaymentCallback}
                  handleDownloadFile={() => handleDownloadFile()}
                  isFilled={!!proofOfPaymentUrl && !deleteFile}
                  handleDeleteFile={() => setDeleteFile(true)}
                  defaultFile={{ documentName: 'comprobante.pdf', url: proofOfPaymentUrl ?? '' }}
                  emptyBordUploaderButtonTitle={t(`${i18nLanguageKey}proofOfPaymentButton:buttonEmptyText`)}
                  emptyBordUploaderButtonSubtitle={t(`${i18nLanguageKey}proofOfPaymentButton:buttonFilledText`)}
                  disabledBordUploaderButton={disableVoucherAndSeePaymentLink}
                  bordUploaderButtonType={!!committedPaymentDate && !proofOfPaymentUrl ? 'error' : 'default'}
                  defaultWidthFull
                  acceptFileExtension={['.pdf']}
                />
              </div>
            )}
          </div>
        </>
      )}
      {displayCoveredWithNudosCare && (
        <div className="withCare">
          <span>Cubierto con Care</span>
          <BordOneToneIcon variant="checkCircle" standardSize={12} />
        </div>
      )}
    </div>
  );
};

export default PaymentMethodAndLink;
