const RoundDemocraticRepublicOfCongo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M30.223 8.66342c-.7393-1.43243-1.7084-2.77693-2.9091-3.97768-1.2008-1.20082-2.5454-2.16988-3.9778-2.90913L12.0643 12.0645 1.77637 23.3363c.73931 1.4324 1.70837 2.777 2.90912 3.9777 1.20075 1.2008 2.54531 2.1699 3.97769 2.9092L19.935 19.9352 30.223 8.66342Z"
        />
        <path
          fill="#F93939"
          d="M4.68542 27.3142c.67119.6712 1.38738 1.2698 2.13856 1.7969L29.1105 6.82447c-.527-.75119-1.1256-1.46737-1.7968-2.13856s-1.3873-1.26975-2.1385-1.79675L2.88867 25.1757c.52694.751 1.1255 1.4673 1.79675 2.1385Z"
        />
        <path
          fill="#3ECBF8"
          d="M4.68588 4.68575C-.362055 9.73368-1.33112 17.3148 1.77676 23.3364L23.3365 1.77668C17.3149-1.33113 9.73376-.361942 4.68588 4.68575ZM27.3146 27.3141c5.0479-5.0478 6.0169-12.629 2.9092-18.65053L8.66406 30.2233c6.02154 3.1078 13.60274 2.1388 18.65054-2.9092Z"
        />
        <path
          fill="#FFDA2C"
          d="m8.52664 4.86914.86338 2.65731H12.184L9.92364 9.1687l.86336 2.6573-2.26036-1.6424-2.2605 1.6424.8635-2.6573-2.2605-1.64225h2.794l.8635-2.65731Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundDemocraticRepublicOfCongo;
