const SquareBruneiDarussalam = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path
            fill="#FFDA2C"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m0 0 22 10.6667v4.2666L0 4.26667V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M0 3.19995 22 13.8666V16L0 5.33328V3.19995Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="m8.38096 7.46662 1.04761 1.06666V12.8l-2.09523-1.0667V9.59995L5.2381 7.46662l2.09524-2.13334 1.04762 1.06667v1.06667Zm1.04761 2.13333h3.14283v1.06665H9.42857V9.59995Zm4.19053-2.13333-1.0477 1.06666V12.8l2.0953-1.0667V9.59995l2.0952-2.13333-2.0952-2.13334-1.0476 1.06667v1.06667ZM9.42857 6.39995h3.14283L11 3.19995l-1.57143 3.2Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBruneiDarussalam;
