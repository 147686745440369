import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NudosHoverText } from '../../../../../components/NudosComponents';
import { IshipmentGroupDetails } from '../../../../../types/orders';
import { IElement } from '../../../../../types/global';
import { truncateText } from '../../../../../utils/truncateText';
import { ModalFluxUpdateShipmentInfo, OrderShipmentGroupCardExpandedSection } from '..';
import { updateConfirmEdition } from '../../../../../services/orders';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { segmentTrackEvent } from '../../../../../utils/segment';
import { getNameByPlace } from '../../../../../utils/getNameByPlace';
import { BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import './OrderShipmentGroupCard.scss';

/**
 * @property {number} shipmentCardIndex - The order of the card in its corresponding country list of shipments
 * @property {IshipmentDetails} shipmentId - The data for the shipment, including "shipmentId", "destinationName", "destinationAddress", "receiverName", "receiverPhone", "statusName", "shipmentTrackingCode", "shipmentTrackingUrl", "estimatedDeliveryDate" and "statusUpdatedAt"
 * @property {() => void} updateOrderDataFunction - A function to upload the OrderData
 */
const OrderShipmentGroupCard = ({
  shipmentCardIndex,
  shipmentGroupData,
  updateOrderDataFunction,
  getOrderDatawithoutLoader,
  globalStatusOrder
}: {
  shipmentCardIndex: number;
  shipmentGroupData: IshipmentGroupDetails;
  updateOrderDataFunction: () => void;
  countryName?: string | null;
  getOrderDatawithoutLoader: () => void;
  globalStatusOrder?: string | null | undefined;
}) => {
  const { destinationName, destinationPlace, destinationAddress, receiverName, receiverPhone, shipments, user } =
    shipmentGroupData;

  const { shipmentId, statusName, deliveryDate, reports, editShipments, countryName } = shipments[0]; //CAMBIAR-JC
  const { t } = useTranslation();
  const [showReceiverDataModal, setShowReceiverDataModal] = useState(false);
  const [showExpandedSection, setShowExpandedSection] = useState(false);
  const orderShipmentCardRef = useRef<HTMLDivElement>(null);
  const existReports = reports?.length > 0 && reports.find(report => report?.status === 'OPEN');
  const i18nLanguageKey = 'nodi:orderDetails:orderShipmentCard:';
  const completeReceiverData = receiverName && receiverPhone;
  const shipmentIsFinished =
    deliveryDate || statusName === 'Cancelado' || statusName === 'Entregado' || globalStatusOrder === 'Cancelada';
  const shipmentIsInDeliveryProcess = statusName === 'Proceso de entrega';
  const receiverText = completeReceiverData
    ? t(`${i18nLanguageKey}completeReceiverData`)
    : receiverName
    ? t(`${i18nLanguageKey}receiverName`)
    : t(`${i18nLanguageKey}receiverTextDefault`);
  const destinationIsNudos = destinationPlace === 'nudos';
  const shipmentInfoEditingIsAllowed = !shipmentIsFinished;
  const editAlert = existReports && shipmentInfoEditingIsAllowed;
  const receiverActiveStyles = shipmentInfoEditingIsAllowed && !editAlert ? 'active' : 'disabled';
  const expandedStyles = showExpandedSection ? 'expanded' : '';
  const renderContainerStyles = editAlert ? 'errorContainer' : '';
  const destinationNameByPlace = getNameByPlace({
    name: destinationName ?? '',
    place: destinationPlace ?? ''
  });
  const renderAlert = editAlert ? 'errorStyles' : !shipmentInfoEditingIsAllowed ? 'text-content-secondary' : '';

  const enableFormEditing = shipmentInfoEditingIsAllowed && !shipmentIsInDeliveryProcess;
  const externalEdition = !editAlert && editShipments?.platform === 'SOGA';
  const isWarehouse = destinationNameByPlace?.toUpperCase() === '.BORD WAREHOUSE';
  const closeReceiverDataModalCallback = () => setShowReceiverDataModal(false);
  const handleClickShowReceiverModal = () => shipmentInfoEditingIsAllowed && setShowReceiverDataModal(true);
  const handleCloseExpandedSection = () => setShowExpandedSection(false);
  const handleShowOrHideExpandedSection = () => setShowExpandedSection(!showExpandedSection);

  const onHandleClickAway = useCallback(
    (e: IElement) => {
      if (orderShipmentCardRef.current && !orderShipmentCardRef.current.contains(e.target))
        handleCloseExpandedSection();
    },
    [orderShipmentCardRef]
  );

  const confirmEdition = async () => {
    if (externalEdition) {
      try {
        await updateConfirmEdition({
          reportId: Number(editShipments?.id),
          status: 'CLOSED',
          shipmentId: Number(shipmentId),
          haveReport: false
        });
        await getOrderDatawithoutLoader();
      } catch (error) {
        displayErrorNotification();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', onHandleClickAway);
    return () => {
      window.removeEventListener('mousedown', onHandleClickAway);
    };
  }, [onHandleClickAway]);

  return (
    <div className={`orderShipmentGroupCard ${renderContainerStyles}`} ref={orderShipmentCardRef}>
      {showReceiverDataModal && (
        <ModalFluxUpdateShipmentInfo
          closeModalCallback={closeReceiverDataModalCallback}
          userId={user?.userId || undefined}
          shipmentExistingData={shipmentGroupData}
          updateOrderDataFunction={updateOrderDataFunction}
          shipmentInfoEditingIsAllowed={enableFormEditing}
        />
      )}
      <div className={`orderShipmentCardContractedSection ${expandedStyles}`}>
        <div
          className={`firstLineInformation`}
          style={{
            marginBottom: isWarehouse ? '0' : '0.8rem'
          }}
        >
          <div className={`shipmentId ${externalEdition ? 'cursor-pointer' : ''}`} onClick={() => confirmEdition()}>
            <span className="text">{t(`${i18nLanguageKey}shipmentGroupId`)}</span>{' '}
            <span className="text">{destinationName ? `${destinationNameByPlace} ` : 'Destino '}</span>
          </div>
          <div className="expandButton" onClick={handleShowOrHideExpandedSection}>
            <span>{t(`${i18nLanguageKey}expandButton`)}</span>
            <BordOneToneIcon variant={showExpandedSection ? 'arrowHeadUp' : 'arrowHeadDown'} standardSize={12} />
          </div>
        </div>

        {!isWarehouse && (
          <div className="secondLineInformation">
            <div className="destinationInfo">
              <NudosHoverText
                text={` ${destinationAddress || ''}` || ' Sin datos'}
                charactersLimit={40}
                onlyIfTruncated={true}
                customClassName="addressText"
              />
            </div>

            {!destinationIsNudos && !user?.userId && (
              <div className="receiver">
                <span className="greyText">{t(`${i18nLanguageKey}greyText`)}</span>
                <NudosHoverText
                  text={truncateText(receiverName || '', 40)}
                  charactersLimit={18}
                  onlyIfTruncated={true}
                />
              </div>
            )}

            {!destinationIsNudos && (
              <>
                {completeReceiverData ? (
                  <div
                    className={`updateReceiverButton  ${!renderAlert ? receiverActiveStyles : ''} ${renderAlert} `}
                    onClick={() => {
                      handleClickShowReceiverModal();
                      confirmEdition();
                      segmentTrackEvent({
                        nodiOrderEditClick: {
                          Country: countryName || '',
                          OrderStatus: statusName || ''
                        }
                      });
                    }}
                  >
                    {receiverText}
                    <BordOneToneIcon variant="edit" standardSize={12} />
                    {editAlert ? <span className="ml-2">⚠️</span> : ''}
                  </div>
                ) : (
                  <button
                    onClick={handleClickShowReceiverModal}
                    className={`updateReceiverButton ${receiverActiveStyles}`}
                  >
                    {receiverText}
                  </button>
                )}
              </>
            )}
          </div>
        )}
        {showExpandedSection && <div className="divider"></div>}
      </div>

      <OrderShipmentGroupCardExpandedSection
        shipmentGroupData={shipmentGroupData}
        showExpandedSection={showExpandedSection}
        shipmentCardIndex={shipmentCardIndex}
      />
    </div>
  );
};

const OrderShipmentCardSkeletonLoader = () => {
  return <div className="orderShipmentCardSkeletonLoader animationLoader" />;
};

export { OrderShipmentGroupCard, OrderShipmentCardSkeletonLoader };
