import { IPillsAndProfiles } from '../types/cart';
import { ObjectKey } from '../types/global';

interface IFormatPills {
  pillsList: ObjectKey<string>;
  excludeItems?: string[];
}

const formatPills = ({ pillsList, excludeItems }: IFormatPills) => {
  const pills = typeof pillsList === 'string' ? JSON.parse(pillsList) : pillsList;
  if (pills) {
    const myKeys = Object.keys(pills);
    const myPillsList = [];
    const tagProfilesList = [];
    if (myKeys.length > 0) {
      for (const key of myKeys) {
        const exludeKey = excludeItems?.includes(key);
        if (!key.includes('Perfil') && !key.includes('Gama') && !exludeKey) {
          const objetPill = { name: key, value: pills?.[key] };
          if (key === 'Cargo') {
            myPillsList.unshift(objetPill);
          } else {
            myPillsList.push(objetPill);
          }
        }
        if (key.includes('Perfil')) {
          const objetPill = { name: key, value: pills?.[key] };
          tagProfilesList.push(objetPill);
        }
      }
      const formattedPillList: IPillsAndProfiles = { pills: myPillsList, profiles: tagProfilesList };
      return formattedPillList;
    }
    return undefined;
  }
  return undefined;
};

export { formatPills };
