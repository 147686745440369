import { FC } from 'react';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import { BordOneToneIcon, BordTooltip } from '../../../../../../components/BordDesignSystem';
import { bordColorTheme } from '../../../../../../styles/constants';
import PlatinumDiscountTooltip from '../../../../../../components/NudosPrimeOrFree/DiscountPlatinum/PlatinumDiscountTooltip';
import './CareAndPlatinumSavingsInformation.scss';

const CareAndPlatinumSavingsInformation: FC<IInformationText> = ({ logisticServiceQuoteData }: IInformationText) => {
  const { careSavings, platinumSavings } = logisticServiceQuoteData || {};
  const borderBottomStyles = { borderBottom: careSavings ? '0.1rem' : undefined };

  if (!careSavings && !platinumSavings) return <></>;

  return (
    <div className="careAndPlatinumSavingsInformation" style={borderBottomStyles}>
      {!!careSavings && (
        <div className="careSavingsInformation">
          <BordOneToneIcon variant="zap" standardSize={14} stroke={bordColorTheme.primary.bgDefault} />
          Servicio cubierto por Care
          <BordTooltip
            position="bottomLeft"
            message={
              <div className="careTooltipContainer">
                Care
                <div className="titleProduct">
                  Care te ayuda a tener tu producto asegurado con servicios de logística y TI.
                </div>
                <div className="savingsContainer">
                  <div className="careSavingText">Total cubierto en este servicio </div>
                  <div className="careSavings">${careSavings}USD</div>
                </div>
              </div>
            }
          >
            <BordOneToneIcon
              variant="info"
              standardSize={12}
              className="cursor-pointer"
              stroke={bordColorTheme.primary.bgDefault}
            />
          </BordTooltip>
        </div>
      )}
      {!careSavings && (
        <PlatinumDiscountTooltip
          totalSaved={platinumSavings}
          badgeValue="15% off"
          showFontBold
          customTooltipStyles={{
            title: 'Descuentos en servicios logísticos',
            description:
              'Por se cliente Platinum, obtienes un descuento de hasta 15% en todos tus servicios logísticos.'
          }}
        />
      )}
    </div>
  );
};

export default CareAndPlatinumSavingsInformation;

interface IInformationText {
  logisticServiceQuoteData: ILogisticQuoteGeneralData;
}
