const RoundSouthAfrica = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="black"
          d="M4.68628 27.3138C-1.56209 21.0653 -1.56209 10.9347 4.68628 4.68628C4.68584 4.68697 13.9131 16 13.9131 16L4.68628 27.3138Z"
        />
        <path
          fill="#FFDA2C"
          d="M13.9132 15.9999L2.12216 8.03198C1.7566 8.66742 1.43423 9.33067 1.15723 10.0172L7.12654 16L1.15741 21.9831C1.43416 22.6691 1.75623 23.3319 2.12154 23.9669L13.9132 15.9999Z"
        />
        <path
          fill="#6DA544"
          d="M31.8642 13.9131H13.9123L4.68578 4.68652C3.69153 5.68077 2.82772 6.80521 2.12172 8.03215L10.0742 16L2.12109 23.9669C2.82709 25.1941 3.69128 26.319 4.68578 27.3135L13.9123 18.087H31.8642C31.9532 17.4038 31.9997 16.7073 31.9997 16C31.9997 15.2927 31.9532 14.5962 31.8642 13.9131Z"
        />
        <path
          fill="#1A47B8"
          d="M6.25879 28.6922C8.95685 30.7661 12.3342 31.9999 16.0002 31.9999C23.3926 31.9999 29.613 26.9861 31.449 20.1738H14.7772L6.25879 28.6922Z"
        />
        <path
          fill="#F93939"
          d="M31.449 11.8261C29.613 5.01381 23.3926 0 16.0002 0C12.3342 0 8.95685 1.23381 6.25879 3.30769L14.7772 11.8261H31.449Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSouthAfrica;
