const RoundGibraltar = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F9F9F9"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M16 32C22.8794 32 28.7441 27.658 31.0047 21.5652H.995117C3.25587 27.658 9.12049 32 16 32ZM22.2607 13.2175V11.1305H22.9563V9.73922H21.565V10.4349H20.1738V9.73922H18.7825V11.1305H19.4781V13.2175H18.0868V8.34804H18.7825V6.95679H17.3912V7.65229H16.6955V6.95679H15.3042V7.65229H14.6085V6.95679H13.2172V8.34804H13.9129V13.2175H12.5216V11.1305H13.2172V9.73922H11.8259V10.4349H10.4347V9.73922H9.04334V11.1305H9.73897V13.2175H8.34766V18.7827H23.652V13.2175H22.2607Z"
        />
        <path
          fill="#FFDA2C"
          d="M16.0003 18.0869C14.8478 18.0869 13.9134 19.0213 13.9134 20.1739C13.9134 21.0824 14.4943 21.8551 15.3047 22.1416V25.0435H13.2178V27.8261H16.696V22.1417C17.5065 21.8552 18.0873 21.0825 18.0873 20.1739C18.0873 19.0214 17.1529 18.0869 16.0003 18.0869ZM16.0003 20.8695C15.6161 20.8695 15.3047 20.558 15.3047 20.1739C15.3047 19.7897 15.6161 19.4783 16.0003 19.4783C16.3845 19.4783 16.696 19.7897 16.696 20.1739C16.696 20.558 16.3845 20.8695 16.0003 20.8695Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundGibraltar;
