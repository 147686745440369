import { FC, useState } from 'react';
import {
  CareAndPlatinumSavingsInformation,
  LogisticServiceToolsQuoteData,
  LogisticServiceQuoteTotals
} from './Components';
import { ILogisticQuoteGeneralData } from '../../../../types/logisticsServicesModule.types';
import { segmentTrackEvent } from '../../../../utils/segment';
import { TlogisticsOriginOrDestinationData } from '../../../../types/requestLogisticsModule';
import { IDetailsQuote, TstoragePlace } from '../../../../types/assignationFluxes';
import { generateLogisticsQuoteDataPdf } from '../../../../services/logisticsServicesModule.services';
import { displayErrorNotification } from '../../../../utils/displayNudosStandardNotifications';
import { GenericLoader } from '../../../Admin/dashboard/components';
import { IInvoicePaymentMethods } from '../../../../types/cart';
import { PAYMENT_TYPES } from '../../../../types/checkout';
import { BordButton, BordOneToneIcon } from '../../../../components/BordDesignSystem';
import './LogisticServicePricingQuote.scss';

interface ILogisticServicePricingQuote {
  logisticServiceQuoteData: ILogisticQuoteGeneralData;
  logisticsType?: 'assignment' | 'unassignment';
  originData?: TlogisticsOriginOrDestinationData;
  detinationData?: TlogisticsOriginOrDestinationData;
  destinationPlace?: TstoragePlace;
  originPlace?: TstoragePlace;
  detailsQuote?: IDetailsQuote;
  selectedPaymentMethod?: IInvoicePaymentMethods;
  quoteLoader?: boolean;
}

const LogisticServicePricingQuote: FC<ILogisticServicePricingQuote> = ({
  logisticServiceQuoteData,
  logisticsType,
  destinationPlace,
  originPlace,
  selectedPaymentMethod,
  quoteLoader = false
}: ILogisticServicePricingQuote) => {
  const [blueDownloadLoader, setBlueDownloadLoader] = useState<boolean>(false);
  const unassignmentType = logisticsType === 'unassignment';
  const paymentIsTranferARS = selectedPaymentMethod?.name === PAYMENT_TYPES.TRANSFER_ARS;

  const currencyIsDisplayedInUSD = selectedPaymentMethod?.currency?.code === 'USD';
  const hasPaymentMethodAndIsNotARS = selectedPaymentMethod && !paymentIsTranferARS && !currencyIsDisplayedInUSD;

  const logisticCurrency = hasPaymentMethodAndIsNotARS
    ? selectedPaymentMethod?.currency?.code
    : logisticServiceQuoteData?.currency || '';

  const totalValueLocalCurrency = logisticServiceQuoteData.total * Number(selectedPaymentMethod?.exchangeRate);
  const totalWithoutDiscountsLocalCurrency =
    logisticServiceQuoteData.totalWithoutDiscounts * Number(selectedPaymentMethod?.exchangeRate || 1);

  const totalValue = hasPaymentMethodAndIsNotARS ? totalValueLocalCurrency : logisticServiceQuoteData?.total;
  const totalWithoutDiscountsToShow = hasPaymentMethodAndIsNotARS
    ? totalWithoutDiscountsLocalCurrency
    : logisticServiceQuoteData?.totalWithoutDiscounts;
  const showCareSavings = !!logisticServiceQuoteData?.careSavings;

  const quoteWithTotalAndCurrency = logisticServiceQuoteData
    ? {
        ...logisticServiceQuoteData,
        total: +totalValue.toFixed(2) || 0,
        totalWithoutDiscounts: +totalWithoutDiscountsToShow.toFixed(2),
        currency: logisticCurrency
      }
    : logisticServiceQuoteData;

  const downLoadFile = async () => {
    if (!logisticServiceQuoteData) return;
    setBlueDownloadLoader(true);
    if (unassignmentType) segmentTrackEvent({ nodiLogisticServicesDownloadQuotationClick: null });
    try {
      const { url } = await generateLogisticsQuoteDataPdf(logisticServiceQuoteData);
      if (url) window.open(url, '_blank');
    } catch (error) {
      displayErrorNotification();
    } finally {
      setBlueDownloadLoader(false);
    }
  };

  if (!logisticServiceQuoteData) return <></>;

  return (
    <div className="LogisticServicePricingQuote">
      <div className="boxServicePrincingQuote">
        <div className="productsContainer">
          <div className="titlePrincingQuote">Revisa y aprueba la cotización</div>
          <div className="grayAlert">
            <div className="boxGrayAlert">
              <div className="grayAlertIcon">
                <BordOneToneIcon variant="alertTriangleWarning" standardSize={14} />
              </div>
              <div className="textGrayAlertIcon">
                <div className="styleGrayText">
                  Es posible que haya variaciones en la cotización, ya que el área logística realiza una revisión
                  exhaustiva antes de proceder con la facturación.
                </div>
              </div>
            </div>
          </div>

          <LogisticServiceToolsQuoteData
            logisticServiceQuoteData={logisticServiceQuoteData}
            destinationPlace={destinationPlace}
            originPlace={originPlace}
          />

          {(quoteLoader || !quoteWithTotalAndCurrency) && <GenericLoader sizeLoader={40} borderWidth={3} pb={14} />}
          {!quoteLoader && !!quoteWithTotalAndCurrency && (
            <LogisticServiceQuoteTotals
              logisticServiceQuoteData={quoteWithTotalAndCurrency}
              platformSubscription={logisticServiceQuoteData.platformSubscription}
              selectedPaymentMethod={selectedPaymentMethod}
            />
          )}

          <CareAndPlatinumSavingsInformation logisticServiceQuoteData={logisticServiceQuoteData} />
          {showCareSavings && (
            <div className="careAditionalCostsAlert">
              <BordOneToneIcon
                iconDivProps={{ className: 'warningIcon' }}
                standardSize={14}
                variant="alertTriangleWarning"
              />
              <div className="aditionalAlertText">
                En caso de que tu Care no cubra alguno de los servicios solicitados te lo haremos saber lo antes posible
              </div>
            </div>
          )}
        </div>

        <div className="dowloadFileContainer">
          <BordButton
            modeButton="secondary"
            label="Descargar"
            onClick={() => downLoadFile()}
            isLoading={blueDownloadLoader}
            customHeight="32"
            buttonIconTwo="download"
            customClassName={`downloadButton ${!selectedPaymentMethod ? 'noSelectedPaymentMethod' : ''}`}
            disabled={!selectedPaymentMethod}
          />
        </div>

        <div className="marginAtBottom w-20 h-40" />
      </div>
    </div>
  );
};

export default LogisticServicePricingQuote;
