const SquareTonga = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#AF010D" rx="2" />
          <path
            fill="#AF010D"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="white" fillRule="evenodd" d="M0 0H12V11H0V0Z" clipRule="evenodd" />
          <path
            fill="#AF010D"
            fillRule="evenodd"
            d="M7.33305 4.26663V2.1333H5.23782V4.26663H3.14258V6.39997H5.23782V8.5333H7.33305V6.39997H9.42829V4.26663H7.33305Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTonga;
