import './DisabledModule.scss';

const DisabledModule = () => {
  return (
    <div id="disabledModule">
      <div className="textDisabledModule">
        Suscríbete a la versión Lite
        <br /> para desbloquear el modulo.{' '}
      </div>
    </div>
  );
};

export default DisabledModule;
