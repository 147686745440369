const RoundMexico = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M31.9996 16C31.9996 9.65665 28.308 4.17565 22.9561 1.58765V30.4123C28.308 27.8244 31.9996 22.3434 31.9996 16Z"
        />
        <path
          fill="#6DA544"
          d="M0 16C0 22.3434 3.69156 27.8244 9.0435 30.4124V1.58765C3.69156 4.17565 0 9.65665 0 16ZM11.8262 16C11.8262 18.3051 13.6949 20.1739 16.0001 20.1739 18.3053 20.1739 20.174 18.3051 20.174 16V14.6086H11.8262V16Z"
        />
        <path
          fill="#FF9811"
          d="M21.5651 13.2175H17.3911C17.3911 12.4491 16.7682 11.8262 15.9998 11.8262C15.2314 11.8262 14.6085 12.4491 14.6085 13.2175H10.4346C10.4346 13.9859 11.1039 14.6088 11.8722 14.6088H11.8259C11.8259 15.3772 12.4488 16.0001 13.2172 16.0001C13.2172 16.7685 13.8401 17.3914 14.6085 17.3914H17.3911C18.1596 17.3914 18.7824 16.7685 18.7824 16.0001C19.5509 16.0001 20.1738 15.3772 20.1738 14.6088H20.1274C20.8958 14.6088 21.5651 13.9859 21.5651 13.2175Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMexico;
