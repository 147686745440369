const RoundTajikistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M1.58763 9.04341C0.5705 11.1469 0 13.5068 0 15.9999C0 18.493 0.5705 20.8529 1.58763 22.9564L16 24.3477L30.4124 22.9564C31.4295 20.8529 32 18.493 32 15.9999C32 13.5068 31.4295 11.1469 30.4124 9.04341L16 7.6521L1.58763 9.04341Z"
        />
        <path
          fill="#6DA544"
          d="M1.58789 22.9565C4.17583 28.3084 9.65639 32 15.9997 32C22.343 32 27.8236 28.3084 30.4115 22.9565H1.58789Z"
        />
        <path
          fill="#F93939"
          d="M1.58789 9.0435H30.4115C27.8236 3.69163 22.343 0 15.9997 0C9.65639 0 4.17583 3.69163 1.58789 9.0435Z"
        />
        <path
          fill="#FFDA2C"
          d="M13.2178 20.1741H18.783V17.8089L17.67 18.3654 16.0004 16.6958 14.3308 18.3654 13.2178 17.8089V20.1741ZM10.0337 17.3914 10.2928 18.1885H11.1309L10.4528 18.6812 10.7118 19.4783 10.0337 18.9857 9.35559 19.4783 9.61465 18.6812 8.93652 18.1885H9.77465L10.0337 17.3914ZM10.8364 14.6089 11.0955 15.406H11.9336L11.2555 15.8987 11.5145 16.6958 10.8364 16.2032 10.1583 16.6958 10.4174 15.8987 9.73926 15.406H10.5774L10.8364 14.6089ZM13.1323 12.5217 13.3914 13.3189H14.2295L13.5515 13.8115 13.8104 14.6087 13.1323 14.116 12.4543 14.6087 12.7133 13.8115 12.0352 13.3189H12.8733L13.1323 12.5217ZM21.9673 17.3914 21.7082 18.1885H20.8701L21.5482 18.6812 21.2892 19.4783 21.9673 18.9857 22.6454 19.4783 22.3864 18.6812 23.0645 18.1885H22.2264L21.9673 17.3914ZM21.1646 14.6089 20.9055 15.406H20.0674L20.7455 15.8987 20.4864 16.6958 21.1646 16.2032 21.8427 16.6958 21.5836 15.8987 22.2618 15.406H21.4236L21.1646 14.6089ZM18.8686 12.5217 18.6095 13.3189H17.7715L18.4495 13.8115 18.1905 14.6087 18.8686 14.116 19.5467 14.6087 19.2877 13.8115 19.9657 13.3189H19.1276L18.8686 12.5217ZM16.0005 11.4783 16.2596 12.2754H17.0977L16.4196 12.7681 16.6786 13.5652 16.0005 13.0726 15.3224 13.5652 15.5814 12.7681 14.9033 12.2754H15.7414L16.0005 11.4783Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTajikistan;
