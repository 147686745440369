import { Tany } from '../types/global';

export const debounce = (func: Tany) => {
  let timer: Tany;
  return function (...args: Tany) {
    const context: Tany = '';
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};
