const SquareSouthAfrica = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="white"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 10.6665H22V15.9998H0V10.6665Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 0H22V5.33333H0V0Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M8.39457 10.6667L2.09524 16.0352H0V0H2.09524L8.38095 5.33333H22V10.6667H8.39457Z"
            clipRule="evenodd"
          />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M8.06667 9.6L0.52381 16L0 16.0352V0H0.52381L8.06667 6.4H22V9.6H8.06667Z"
            clipRule="evenodd"
          />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 3.2002L6.28571 8.0002L0 12.8002V3.2002Z" clipRule="evenodd" />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M0 4.2666L4.97619 7.99994L0 11.7333V4.2666Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareSouthAfrica;
