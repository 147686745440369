const RoundSerbia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#1A47B8"
          d="M16 0C7.1635 0 0 7.1635 0 16C0 17.9571 0.352063 19.8319 0.995188 21.5652H31.0049C31.648 19.8319 32 17.9571 32 16C32 7.1635 24.8365 0 16 0Z"
        />
        <path
          fill="#F93939"
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348H31.0048C28.7441 4.342 22.8794 0 15.9999 0Z"
        />
        <path
          fill="#F93939"
          d="M4.1377 9.04346V17.0251C4.1377 21.5653 10.069 22.9565 10.069 22.9565C10.069 22.9565 16.0003 21.5653 16.0003 17.0251V9.04346H4.1377Z"
        />
        <path
          fill="#FFDA2C"
          d="M6.59082 10.4349H13.5474V7.6523L12.1561 8.34792 10.0691 6.26099 7.98213 8.34792 6.59082 7.6523V10.4349ZM14.6089 18.1365 10.0872 13.6147 5.56543 18.1365 7.04112 19.6122 10.0872 16.5661 13.1332 19.6122 14.6089 18.1365Z"
        />
        <path
          fill="white"
          d="M14.6089 13.913H12.4992C12.6178 13.7082 12.6868 13.4711 12.6868 13.2175C12.6868 12.449 12.0639 11.8262 11.2955 11.8262C10.7646 11.8262 10.3035 12.1235 10.0689 12.5607C9.83437 12.1236 9.3733 11.8262 8.84237 11.8262C8.07399 11.8262 7.45105 12.449 7.45105 13.2175C7.45105 13.4711 7.52005 13.7083 7.63868 13.913H5.56543C5.56543 14.6814 6.23474 15.3043 7.00305 15.3043H6.95674C6.95674 16.0727 7.57962 16.6956 8.34805 16.6956C8.34805 17.376 8.83668 17.9412 9.48199 18.0622L8.38574 20.5374C8.91112 20.7509 9.48524 20.8695 10.0872 20.8695C10.6892 20.8695 11.2634 20.7509 11.7887 20.5374L10.6925 18.0622C11.3378 17.9414 11.8264 17.376 11.8264 16.6956C12.5949 16.6956 13.2177 16.0727 13.2177 15.3043H13.1714C13.9397 15.3043 14.6089 14.6814 14.6089 13.913Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSerbia;
