const RoundKenya = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="black"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0Z"
        />
        <path
          fill="#496E2D"
          d="M16.0003 32C9.65689 32 4.17589 28.3085 1.58789 22.9565H30.4126C27.8246 28.3085 22.3436 32 16.0003 32Z"
        />
        <path
          fill="#A2001D"
          d="M31.2444 11.1304H0.755562C0.265437 12.6659 0 14.3019 0 15.9999C0 17.698 0.265437 19.3339 0.755562 20.8695H31.2444C31.7346 19.3339 32 17.698 32 15.9999C32 14.3019 31.7346 12.6659 31.2444 11.1304Z"
        />
        <path
          fill="white"
          d="M20.982 7.39385L19.0871 6.51929L15.9998 13.51L12.9124 6.51929L11.0176 7.39385L14.8506 16L11.0176 24.6062L12.9124 25.4808L15.9998 18.4901L19.0871 25.4808L20.982 24.6062L17.149 16L20.982 7.39385Z"
        />
        <path
          fill="white"
          d="M17.2868 8.21811C16.5533 7.41704 15.9998 6.95654 15.9998 6.95654C15.9998 6.95654 15.4464 7.41698 14.7129 8.21811V23.7819C15.4464 24.583 15.9998 25.0435 15.9998 25.0435C15.9998 25.0435 16.5533 24.5831 17.2868 23.7819V8.21811Z"
        />
        <path
          fill="#A2001D"
          d="M12.5215 11.2028V20.7973C13.2966 22.1707 14.2453 23.2965 14.9563 24.0428V7.95728C14.2453 8.70359 13.2965 9.8294 12.5215 11.2028ZM19.4778 11.2028C18.7026 9.82934 17.754 8.70359 17.043 7.95728V24.0428C17.7539 23.2965 18.7026 22.1707 19.4778 20.7973V11.2028Z"
        />
        <path
          fill="black"
          d="M19.4785 11.2026V20.7971C20.2632 19.4066 20.8698 17.7623 20.8698 15.9999 20.8698 14.2376 20.2632 12.5933 19.4785 11.2026ZM12.5222 11.2026V20.7971C11.7375 19.4066 11.1309 17.7623 11.1309 15.9999 11.1309 14.2376 11.7375 12.5933 12.5222 11.2026Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundKenya;
