const SquareEswatini = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path fill="#4769C1" fillRule="evenodd" d="M0 12.8H22V16H0V12.8ZM0 0H22V3.2H0V0Z" clipRule="evenodd" />
          <path fill="#BC0A33" fillRule="evenodd" d="M0 4.2666H22V11.7333H0V4.2666Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M4.19043 8.53324H17.8095V9.5999H4.19043V8.53324ZM6.28567 6.3999H16.7619V7.46657H6.28567V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="#CBCBCB"
            fillRule="evenodd"
            d="M16.7617 6.3999H18.857V7.46657H16.7617V6.3999Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M11.0004 10.6668C13.6038 10.6668 15.7147 8.00016 15.7147 8.00016C15.7147 8.00016 13.6038 5.3335 11.0004 5.3335C8.39709 5.3335 6.28613 8.00016 6.28613 8.00016C6.28613 8.00016 8.39709 10.6668 11.0004 10.6668Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M11.0004 10.6668C11.5242 10.6668 12.5718 8.00016 12.5718 8.00016C12.5718 8.00016 11.5242 5.3335 11.0004 5.3335C8.39709 5.3335 6.28613 8.00016 6.28613 8.00016C6.28613 8.00016 8.39709 10.6668 11.0004 10.6668Z"
            clipRule="evenodd"
          />
          <path
            fill="#CBCBCB"
            fillRule="evenodd"
            d="M8.38086 7.4668H9.42848V8.53346H8.38086V7.4668ZM10.4761 7.4668H11.5237V8.53346H10.4761V7.4668Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            stroke="#151515"
            d="M13.6191 7.4668H14.6668V8.53346H13.6191V7.4668Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareEswatini;
