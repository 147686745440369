const BordSearchIcon = () => {
  return (
    <svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.9197 3.02197H31.5408C26.7513 3.02193 22.9191 3.02189 19.8567 3.34469C16.7045 3.67696 14.0231 4.37815 11.685 6.0153C10.1034 7.12275 8.72773 8.49838 7.62028 10.08C5.98313 12.4181 5.28194 15.0995 4.94967 18.2518C4.62687 21.3142 4.62691 25.1464 4.62695 29.9358V30.2772C4.62691 35.0666 4.62687 38.8988 4.94967 41.9612C5.28194 45.1135 5.98313 47.7949 7.62028 50.133C8.72773 51.7146 10.1034 53.0902 11.685 54.1976C14.0231 55.8348 16.7045 56.536 19.8567 56.8682C22.7801 57.1764 26.4049 57.1903 30.8942 57.1909C31.1005 58.9622 30.8246 60.7691 30.1479 62.2545C29.9543 62.6793 29.8576 62.8918 29.6104 63.0508C29.3632 63.2098 29.0884 63.2098 28.5387 63.2098H22.6849C21.0228 63.2098 19.6755 64.5571 19.6755 66.2192C19.6755 67.8812 21.0228 69.2285 22.6849 69.2285H52.7788C54.4408 69.2285 55.7882 67.8812 55.7882 66.2192C55.7882 64.5571 54.4408 63.2098 52.7788 63.2098H46.9247C46.3751 63.2098 46.1002 63.2098 45.8531 63.0508C45.6059 62.8918 45.5092 62.6793 45.3156 62.2545C44.6389 60.7691 44.363 58.9622 44.5693 57.1909C49.0572 57.1903 52.681 57.1763 55.6037 56.8682C58.756 56.536 61.4374 55.8348 63.7755 54.1976C65.3571 53.0902 66.7327 51.7146 67.8402 50.133C69.4773 47.7949 70.1785 45.1135 70.5108 41.9612C70.8336 38.8988 70.8335 35.0666 70.8335 30.2772V29.9357C70.8335 25.1463 70.8336 21.3142 70.5108 18.2518C70.1785 15.0995 69.4773 12.4181 67.8402 10.08C66.7327 8.49839 65.3571 7.12275 63.7755 6.0153C61.4374 4.37815 58.756 3.67696 55.6037 3.34469C52.5413 3.02189 48.7091 3.02193 43.9197 3.02197Z"
        fill="url(#paint0_linear_3220_11640)"
      />
      <g filter="url(#filter0_bi_3220_11640)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.1763 12.5C22.1422 12.5 8.3334 26.3088 8.3334 43.3429C8.3334 60.377 22.1422 74.1858 39.1763 74.1858C46.3037 74.1858 52.8665 71.7682 58.0893 67.7083L63.7077 73.3266C62.7195 76.3132 63.4134 79.7344 65.7895 82.1105L77.5159 93.8369C80.8722 97.1932 86.3139 97.1932 89.6702 93.8369C93.0266 90.4806 93.0266 85.0389 89.6702 81.6825L77.9439 69.9562C75.5678 67.5801 72.1465 66.8861 69.16 67.8743L63.5416 62.256C67.6016 57.0332 70.0192 50.4704 70.0192 43.3429C70.0192 26.3088 56.2104 12.5 39.1763 12.5ZM16.0441 43.3429C16.0441 30.5674 26.4008 20.2107 39.1763 20.2107C51.9519 20.2107 62.3085 30.5674 62.3085 43.3429C62.3085 56.1185 51.9519 66.4751 39.1763 66.4751C26.4008 66.4751 16.0441 56.1185 16.0441 43.3429Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_3220_11640"
          x="3.4618"
          y="7.62782"
          width="93.5979"
          height="93.5984"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.43609" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3220_11640" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3220_11640" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.24812" />
          <feGaussianBlur stdDeviation="4.22256" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3220_11640" />
        </filter>
        <linearGradient
          id="paint0_linear_3220_11640"
          x1="37.7302"
          y1="3.02197"
          x2="37.4661"
          y2="62.1668"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BordSearchIcon;
