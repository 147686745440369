import { NudosToolAdditionalServices } from '../../../../../../../components/DesignSystem/LogisticsAdditionalServices';
import NudosToolAdditionalServicesZeroState from '../../../../../../../components/DesignSystem/LogisticsAdditionalServices/NudosToolAdditionalServices/NudosToolAdditionalServicesZeroState/NudosToolAdditionalServicesZeroState';
import { IadditionalServiceDataForToolLogistics } from '../../../../../../../types/requestLogisticsModule';
import './ToolStatusAdditionalServicesSection.scss';

const ToolStatusAdditionalServicesSection = ({
  logisticAdditionalServices,
  hasBeenInWarehouse
}: {
  logisticAdditionalServices: IadditionalServiceDataForToolLogistics[];
  hasBeenInWarehouse: boolean;
}) => {
  const doesntHaveAdditionalServices = !logisticAdditionalServices?.length;
  const showZeroState = doesntHaveAdditionalServices;

  return (
    <div className="toolStatusAdditionalServicesSection">
      <div className="statusSectionSubtitle">Estado del sistema</div>
      {!showZeroState && (
        <NudosToolAdditionalServices
          styling="toolDetails"
          logisticAdditionalServices={logisticAdditionalServices || []}
        />
      )}
      {showZeroState && <NudosToolAdditionalServicesZeroState hasBeenInWarehouse={hasBeenInWarehouse} />}
    </div>
  );
};

export default ToolStatusAdditionalServicesSection;
