import { useTranslation } from 'react-i18next';
import { IdateRange, IlogisticServiceDetailedData } from '../../../../../../types/logisticsServicesModule.types';
import { isValidLink } from '../../../../../../utils/formValidations';
import { getEstimatedDeliveryTimeText } from './LogisticServiceTrackingSection.utils';
import { formatShortDate } from '../../../../../../utils/formatDatesAndHours';
import { NudosLink } from '../../../../../../components/NudosComponents';

const LogisticServiceDefinitiveTrackingData = ({
  logisticServiceData
}: {
  logisticServiceData?: IlogisticServiceDetailedData;
}) => {
  const { t } = useTranslation();

  const {
    status,
    trackingLink,
    estimatedDeliveryTime,
    trackingCode,
    estimatedDates,
    deliveryDate,
    collection,
    delivery
  } = logisticServiceData || {};
  const { collect: collectConfirmedDates, delivery: deliveryConfirmedDates } = estimatedDates || {};

  const translationKey = 'nodi:logisticServices:logisticServiceTrackingSection:';
  const serviceIsDelivered = status === 'entregado';
  const serviceDestinationIsWarehouse = delivery?.place === 'nudos';
  const collectionTimeZone = collection?.country?.timeZone || undefined;
  const deliveryTimeZone = delivery?.country?.timeZone || undefined;
  const validTrackingLink = isValidLink(trackingLink || '');

  const dataCollectionTitle = t(`${translationKey}dataCollectionTitle`);
  const dataDeliveryTitle = t(`${translationKey}dataDeliveryTitle`);
  const trackingLinkText = t(`${translationKey}trackingLinkText`);
  const trackingCodeText = t(`${translationKey}trackingCodeText`);
  const businessDays = t(`recurrentWords:businessDays`);

  const noDataText = 'Sin datos';

  const estimatedDeliveryTimeText = getEstimatedDeliveryTimeText(businessDays, estimatedDeliveryTime);

  const serviceHasConfirmedDeliveryDates =
    deliveryConfirmedDates && !!Object.values(deliveryConfirmedDates || {}).filter(val => !!val).length;

  const serviceHasConfirmedCollectionDates =
    collectConfirmedDates && !!Object.values(collectConfirmedDates || {}).filter(val => !!val).length;

  const getDateText = (dateRange?: IdateRange, timeZone?: number) => {
    const sinceDate = dateRange?.since ? formatShortDate(dateRange.since, timeZone)?.onlyDate : undefined;
    const untilDate = dateRange?.until ? formatShortDate(dateRange.until, timeZone)?.onlyDate : undefined;
    if (sinceDate && untilDate) return `${sinceDate || ''} - ${untilDate || ''}`;
    if (untilDate) return `${untilDate || ''}`;
    if (sinceDate) return `${sinceDate || ''}`;
    return noDataText;
  };

  return (
    <div className="logisticServiceDefinitiveTrackingData">
      {serviceHasConfirmedCollectionDates && (
        <div className="dataField">
          <h3 className="dataTitle">{dataCollectionTitle}</h3>
          <div className="dataContent">{getDateText(collectConfirmedDates, collectionTimeZone)}</div>
        </div>
      )}
      {!serviceIsDelivered && serviceHasConfirmedDeliveryDates && (
        <div className="dataField">
          <h3 className="dataTitle">{dataDeliveryTitle}</h3>
          <div className="dataContent">{getDateText(deliveryConfirmedDates, deliveryTimeZone)}</div>
        </div>
      )}
      {!serviceIsDelivered && (!serviceHasConfirmedDeliveryDates || serviceDestinationIsWarehouse) && (
        <div className="dataField">
          <h3 className="dataTitle">{dataDeliveryTitle}</h3>
          <div className="dataContent">{estimatedDeliveryTimeText}</div>
        </div>
      )}
      {serviceIsDelivered && (
        <div className="dataField">
          <h3 className="dataTitle">{dataDeliveryTitle}</h3>
          <div className="dataContent">{getDateText({ since: deliveryDate })}</div>
        </div>
      )}
      <div className="dataField">
        <h3 className="dataTitle">{trackingLinkText}</h3>
        <div className="dataContent">
          {validTrackingLink && (
            <NudosLink
              linkUrl={trackingLink || undefined}
              maxWidth="160px"
              inactiveLinkText={trackingLink || noDataText}
            />
          )}
          {!validTrackingLink && trackingLink}
        </div>
      </div>
      <div className="dataField">
        <h3 className="dataTitle">{trackingCodeText}</h3>
        <div className="dataContent">{trackingCode || noDataText}</div>
      </div>
    </div>
  );
};

export default LogisticServiceDefinitiveTrackingData;
