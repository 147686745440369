import { ICountryDetail } from '../../../types/countries';
import { useTranslation } from 'react-i18next';
import BordUpdateBillingDataModalContent from './BordUpdateBillingDataModalContent';
import { IbillingData } from '../../../types/checkout';
import BordModal from '../../BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../BordDesignSystem/BordModal/components/ModalContent';
import './BordUpdateBillingDataModal.scss';

const BordUpdateBillingDataModal = ({
  initialData,
  billingCountryData,
  closeModalCallback,
  updateBillingData,
  orderOrLogisticId
}: {
  initialData: IbillingData;
  billingCountryData?: ICountryDetail;
  closeModalCallback: React.Dispatch<React.SetStateAction<boolean>>;
  updateBillingData: () => void;
  orderOrLogisticId: number | string;
}) => {
  const { t } = useTranslation();

  const i18nLanguageKey = 'nodi:orderDetails:updateOrderBillingDataFlux:';

  return (
    <div className="updateOrderBillingDataFlux relative">
      <BordModal
        modalContent={
          <ModalContent
            title={t(`${i18nLanguageKey}modalContentTitle`)}
            subTitle={t(`${i18nLanguageKey}modalSubtitle`)}
            toCloseModal={closeModalCallback}
            customHeight="58rem"
            customWidth="66rem"
            CustomModalComponent={
              <BordUpdateBillingDataModalContent
                initialData={initialData}
                closeModalCallback={closeModalCallback}
                billingCountryData={billingCountryData}
                updateBillingData={updateBillingData}
                orderOrLogisticId={orderOrLogisticId}
              />
            }
          />
        }
      />
    </div>
  );
};

export default BordUpdateBillingDataModal;
