const SquareVanuatu = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="white"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#249F58" fillRule="evenodd" d="M0 9.6001H22V16.0001H0V9.6001Z" clipRule="evenodd" />
          <path fill="#AF010D" fillRule="evenodd" d="M0 0H22V6.4H0V0Z" clipRule="evenodd" />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M9.64648 9.6L2.09524 16.0352H0V0H2.09524L9.6381 6.4H22V9.6H9.64648Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M9.32381 8.53333L0.52381 16L0 16.0352V0H0.52381L9.32381 7.46667H22V8.53333H9.32381Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M0 1.06689L8.38095 8.00023L0 14.9336V1.06689Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M1.73195 9.9703C1.3129 9.48283 1.04785 8.78096 1.04785 8.00016C1.04785 6.52816 1.98547 5.3335 3.14309 5.3335C4.30071 5.3335 5.23833 6.52816 5.23833 8.00016C5.23833 9.47216 4.30071 10.6668 3.14309 10.6668V9.60016C3.72138 9.60016 4.19071 8.88336 4.19071 8.00016C4.19071 7.11696 3.72138 6.40016 3.14309 6.40016C2.5648 6.40016 2.09547 7.11696 2.09547 8.00016C2.09547 8.49083 2.24004 8.92816 2.46633 9.22256L1.73299 9.96923L1.73195 9.9703Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareVanuatu;
