const SquareChile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="white"
            d="M19.9048 0H2.09524C0.93807 0 0 0.955126 0 2.13333V13.8667C0 15.0449 0.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 0.955126 21.0619 0 19.9048 0Z"
          />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H9V8H0V0Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M4.49124 5.57529L2.95124 6.39982L3.24457 4.65475L2 3.41742L3.72124 3.16355L4.49124 1.57422L5.26124 3.16355L6.98248 3.41955L5.73581 4.65475L6.03124 6.39982"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 8H22V16H0V8Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareChile;
