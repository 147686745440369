const RoundMacao = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M18.7826 15.3329C18.3899 15.3329 18.017 15.4112 17.6708 15.5358C17.8604 15.113 17.9876 14.6427 17.9876 14.1404C17.9876 12.1528 16.0001 10.6621 16.0001 10.6621C16.0001 10.6621 14.0125 12.1528 14.0125 14.1404C14.0125 14.6426 14.1397 15.113 14.3294 15.5358C13.9831 15.4113 13.6102 15.3329 13.2175 15.3329C11.2299 15.3329 9.73926 17.3204 9.73926 17.3204C9.73926 17.3204 11.2299 19.308 13.2175 19.308C14.3828 19.308 15.3767 18.625 16.0001 18.0597C16.6236 18.625 17.6174 19.308 18.7828 19.308C20.7703 19.308 22.261 17.3204 22.261 17.3204C22.261 17.3204 20.7702 15.3329 18.7826 15.3329Z"
        />
        <path
          fill="#FFDA2C"
          d="M16.0008 6.26099 16.4114 7.52449H17.74L16.6653 8.30549 17.0755 9.56899 16.0008 8.78799 14.9262 9.56899 15.3364 8.30549 14.2617 7.52449H15.5903L16.0008 6.26099ZM10.9485 8.47095 11.7383 9.18207 12.6588 8.6507 12.2265 9.62163 13.0163 10.3329 11.9593 10.2218 11.5271 11.1927 11.306 10.1531 10.249 10.042 11.1694 9.51051 10.9485 8.47095ZM7.25 12.1692 8.28963 12.3902 8.82106 11.4697 8.93219 12.5267 9.97169 12.7478 9.00081 13.1801 9.11194 14.237 8.40063 13.4473 7.42975 13.8795 7.96119 12.959 7.25 12.1692ZM21.0522 8.47095 20.2624 9.18207 19.3419 8.6507 19.7741 9.62163 18.9844 10.3329 20.0414 10.2218 20.4736 11.1927 20.6948 10.1531 21.7517 10.042 20.8313 9.51051 21.0522 8.47095ZM24.751 12.1692 23.7114 12.3902 23.18 11.4697 23.0688 12.5267 22.0293 12.7478 23.0002 13.1801 22.889 14.237 23.6004 13.4473 24.5712 13.8795 24.0398 12.959 24.751 12.1692Z"
        />
        <path
          fill="white"
          d="M16.0002 24.8733C17.579 24.8733 19.0343 24.3465 20.2016 23.4602H11.7988C12.9662 24.3465 14.4214 24.8733 16.0002 24.8733ZM9.62305 20.6992C9.84386 21.2046 10.1239 21.6779 10.4527 22.1123H21.5476C21.8765 21.678 22.1565 21.2046 22.3773 20.6992H9.62305Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundMacao;
