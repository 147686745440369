const RoundNamibia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M4.68634 27.3137C5.35752 27.9849 6.07365 28.5835 6.82484 29.1105L29.1104 6.82484C28.5835 6.07365 27.9849 5.35752 27.3136 4.68634C26.6425 4.01515 25.9263 3.41665 25.1751 2.88965L2.88965 25.1753C3.41659 25.9263 4.01515 26.6425 4.68634 27.3137Z"
        />
        <path
          fill="#1A47B8"
          d="M4.68668 4.68653C-0.361066 9.73428 -1.33007 17.315 1.77762 23.3364L23.3365 1.77759C17.3151 -1.33009 9.73431 -0.360968 4.68668 4.68653Z"
        />
        <path
          fill="#496E2D"
          d="M27.3139 27.3137C32.3615 22.2661 33.3305 14.6853 30.2229 8.66382L8.66406 30.2227C14.6854 33.3304 22.2662 32.3614 27.3139 27.3137Z"
        />
        <path
          fill="#FFDA2C"
          d="M13.2178 9.04357L11.4249 9.88688L12.3796 11.6233L10.4328 11.2509L10.1861 13.2174L8.83007 11.771L7.47401 13.2174L7.22738 11.2509L5.28051 11.6232L6.2352 9.88682L4.44238 9.04357L6.23526 8.20019L5.28051 6.46394L7.22732 6.83632L7.47407 4.86963L8.83007 6.31613L10.1861 4.86963L10.4328 6.83632L12.3796 6.46394L11.425 8.20025L13.2178 9.04357Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNamibia;
