const SquareUnitedStatesOfAmerica = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H9.42857V7.46667H0V0Z" clipRule="evenodd" />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M9.42857 0V1.06667H22V0H9.42857ZM9.42857 2.13333V3.2H22V2.13333H9.42857ZM9.42857 4.26667V5.33333H22V4.26667H9.42857ZM9.42857 6.4V7.46667H22V6.4H9.42857ZM0 8.53333V9.6H22V8.53333H0ZM0 10.6667V11.7333H22V10.6667H0ZM0 12.8V13.8667H22V12.8H0ZM0 14.9333V16H22V14.9333H0Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            fillRule="evenodd"
            d="M1.04785 1.06689V2.13356H2.09547V1.06689H1.04785ZM3.14309 1.06689V2.13356H4.19071V1.06689H3.14309ZM5.23833 1.06689V2.13356H6.28595V1.06689H5.23833ZM7.33357 1.06689V2.13356H8.38119V1.06689H7.33357ZM6.28595 2.13356V3.20023H7.33357V2.13356H6.28595ZM4.19071 2.13356V3.20023H5.23833V2.13356H4.19071ZM2.09547 2.13356V3.20023H3.14309V2.13356H2.09547ZM1.04785 3.20023V4.26689H2.09547V3.20023H1.04785ZM3.14309 3.20023V4.26689H4.19071V3.20023H3.14309ZM5.23833 3.20023V4.26689H6.28595V3.20023H5.23833ZM7.33357 3.20023V4.26689H8.38119V3.20023H7.33357ZM1.04785 5.33356V6.40023H2.09547V5.33356H1.04785ZM3.14309 5.33356V6.40023H4.19071V5.33356H3.14309ZM5.23833 5.33356V6.40023H6.28595V5.33356H5.23833ZM7.33357 5.33356V6.40023H8.38119V5.33356H7.33357ZM6.28595 4.26689V5.33356H7.33357V4.26689H6.28595ZM4.19071 4.26689V5.33356H5.23833V4.26689H4.19071ZM2.09547 4.26689V5.33356H3.14309V4.26689H2.09547Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareUnitedStatesOfAmerica;
