import { AxiosError, AxiosRequestConfig } from 'axios';
import { apiGet, apiPost, apiPut } from './api';
import { subscriptionAPI } from '../constants';
import {
  IupdateShipmentInformation,
  IorderDetails,
  IorderInListing,
  IactiveOrdersByEmployee,
  IUpdateConfirmEdition,
  ICartShippingInformation,
  ICalculateOfShipment
} from '../types/orders';
import { DTOgetModuleFilters } from '../types/DTO';

export const getOrderList = async (organizationId: number, filtersQuery?: string): Promise<IorderInListing[]> => {
  return apiGet<AxiosRequestConfig, IorderInListing[]>(
    `${subscriptionAPI}/order/by-organization/${organizationId}${filtersQuery}`
  )
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getOrdersFilters = async (organizationId: number | string): Promise<DTOgetModuleFilters> => {
  return apiGet(`${subscriptionAPI}/filters/order-listing/${organizationId}`)
    .then(response => Promise.resolve(response as DTOgetModuleFilters))
    .catch(error => Promise.reject(error));
};

export const getOrderDetails = async (orderId: number | string): Promise<IorderDetails> => {
  return apiGet(`${subscriptionAPI}/order/nodi/detail/${orderId}`)
    .then(response => Promise.resolve(response as IorderDetails))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateShipmentInformation = async (
  shipmentGroupId: number | string,
  body: IupdateShipmentInformation,
  organizationId?: number | string,
  userId?: number | string
): Promise<string> => {
  const updatingId = userId ? `?userId=${userId}` : `?organizationId=${organizationId}`;
  return apiPut(`${subscriptionAPI}/shipment/shipment-info/${shipmentGroupId}${updatingId}`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateProofOfPayment = async (orderId: number | string, formData: FormData): Promise<string> => {
  return apiPut<AxiosRequestConfig, FormData>(`${subscriptionAPI}/order/proof-of-payment/${orderId}`, formData)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateOrderBillingData = async (orderId: string | number, formData: FormData) => {
  return apiPut<AxiosRequestConfig, FormData>(`${subscriptionAPI}/order/billing-data/${orderId}`, formData)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const getActiveOrdersByEmployee = async (userId: string | number) => {
  return apiGet<AxiosRequestConfig, IactiveOrdersByEmployee[]>(`${subscriptionAPI}/shipment/active/${userId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const regenerateInvoice = async (orderId: string | number) => {
  return apiPost<AxiosRequestConfig, string>(`${subscriptionAPI}/order/invoice/regenerate-file/${orderId}/?type=pdf`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const updateConfirmEdition = async (body: IUpdateConfirmEdition): Promise<string> => {
  return apiPut(`${subscriptionAPI}/shipment/report/status`, body)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};

export const calculateShipping = async (
  cartShippingInformation: ICartShippingInformation
): Promise<ICalculateOfShipment[]> => {
  return apiPut(`${subscriptionAPI}/shipment/calculate-by-locations`, cartShippingInformation)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => Promise.reject(error));
};
