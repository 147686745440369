const SquareAustralia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path
            fill="#1A47B8"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m4.19048 12.2666 1.04761-.5333.52381-1.0667.52381 1.0667 1.04762.5333-1.04762.5334-.52381 1.0666L5.23809 12.8l-1.04761-.5334ZM14.6667 3.19995v1.06667h1.0476V3.19995h-1.0476Zm3.1428 1.06667v1.06666h1.0476V4.26662h-1.0476ZM13.619 6.39995v1.06667h1.0477V6.39995H13.619Zm3.1429 1.06667v1.06666h1.0476V7.46662h-1.0476Zm-1.0476 3.19998v1.0667h1.0476v-1.0667h-1.0476Z"
            clipRule="evenodd"
            opacity=".5"
          />
          <path
            fill="#fff"
            d="M9.42858 1.06665H2.09524c-.57858 0-1.04762.47756-1.04762 1.06667v5.33333c0 .5891.46904 1.06667 1.04762 1.06667h7.33334c.57862 0 1.04762-.47757 1.04762-1.06667V2.13332c0-.58911-.469-1.06667-1.04762-1.06667Z"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M5.2381 5.3333H2.09524V4.26663H5.2381V2.1333h1.04761v2.13333h3.14286V5.3333H6.28571v2.13333H5.2381V5.3333Zm2.61904 1.06667v1.06666h1.57143V6.39997H7.85714Zm0-4.26667v1.06667h1.57143V2.1333H7.85714Zm-5.7619 4.26667v1.06666h1.57143V6.39997H2.09524Zm0-4.26667v1.06667h1.57143V2.1333H2.09524Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAustralia;
