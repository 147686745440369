import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrgData } from '../../../../../utils/getLocalStorageData';
import { calculateLowerMultiple } from '../../../../../utils/calculateLowerMultiple';
import { nudosFormatCurrency } from '../../../../../utils/formatNumbers';
import useStateSubscriptions from '../../../../../state/useStateSubscriptions';
import { productsOnly } from '../../../../../utils/cart';
import { IProductsCart } from '../../../../../types/cart';
import { NudosLinkTypeButton } from '../../../../DesignSystem';
import './CartSummaryItems.scss';

const CartSummaryItems = () => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { stateSubscription } = useStateSubscriptions();
  const productsList = productsOnly();
  const { levelSubscription } = stateSubscription;

  const translationKey = 'nodi:headBar:headerShoppingCartSummary:';
  const limitOfLinesToShow = 3;
  const orgData = getOrgData();
  const isFree = levelSubscription === 'Free';

  const redirectCart = () => {
    if (orgData?.organizationState === 'active') {
      push('/catalogue/checkout');
    }
  };

  const calculatePrice = (reference: IProductsCart) => {
    if (reference && reference.unitPrice && reference.quantity) {
      const price = Number(reference.unitPrice || 0);
      const quantity = Number(reference.quantity || 0);
      const subtotal = price * quantity;
      const freeTotal = calculateLowerMultiple(subtotal);
      if (price >= 170 && isFree) {
        const freePrice = `${nudosFormatCurrency({ value: freeTotal, maximumFractionDigits: 2 })}`;
        return freePrice;
      }
      const primePrice = `${nudosFormatCurrency({ value: subtotal, maximumFractionDigits: 2 })}`;
      return primePrice;
    } else {
      return `$0 USD`;
    }
  };

  return (
    <div className="cartSummaryItems">
      <div className="title">{t(`${translationKey}titleAllItems`)}</div>
      <div className="w-full my-12 border-b border-solid border-stroke-interactive" />
      {productsList &&
        productsList.length &&
        productsList.map((item, index) => {
          if (index < limitOfLinesToShow) {
            return (
              <div key={`cartItem${item.productId}`} className="individualItem" onClick={() => redirectCart()}>
                <div className="nameContainer">{item.productName}</div>
                <div className="quantityContainer">{item.quantity}x</div>
                <div className="priceContainer">
                  {calculatePrice(item)}
                  <span>USD</span>
                </div>
              </div>
            );
          }
          return null;
        })}
      {productsList && productsList.length > limitOfLinesToShow && (
        <NudosLinkTypeButton
          text={t(`${translationKey}viewAllDetails`)}
          onClick={redirectCart}
          rightIconProps={{ variant: 'arrowRightCircle', standardSize: 12 }}
          textProps={{ style: { textDecorationLine: 'none' } }}
        />
      )}
    </div>
  );
};

export default CartSummaryItems;
