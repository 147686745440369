import { FC } from 'react';
import { InudoInfo } from '../../../../../types/global';
import { truncateText } from '../../../../../utils/truncateText';
import { useTranslation } from 'react-i18next';
import ProductPills from '../../../../../components/ProductPills/ProductPills';
import { ToolSerial } from '../../../../../components';
import { NudosCareTag } from '../../../../../components/NudosComponents';
import LoaderHeaderNodiInternalProductDetails from './LoaderHeaderNodiInternalProductDetails';
import { BordFlag, BordPhoto } from '../../../../../components/BordDesignSystem';
import BordStatus from '../../../../../components/BordDesignSystem/BordStatus/BordStatus';
import { TcountryCode } from '../../../../../components/BordDesignSystem/BordFlag/BordFlag.types';
import './HeaderNodiInternalProductDetails.scss';

interface IHeaderNodiInternalProductDetails {
  dataHeader?: InudoInfo;
  generalLoading?: boolean;
}

const HeaderNodiInternalProductDetails: FC<IHeaderNodiInternalProductDetails> = ({
  dataHeader,
  generalLoading
}: IHeaderNodiInternalProductDetails) => {
  const { t } = useTranslation();
  const i18nLanguage = 'nodi:toolInternal:NodiDetailsHeader:';
  const renderBordStatusLabel = dataHeader?.assignedTo
    ? t(`${i18nLanguage}negativeStatus`)
    : t(`${i18nLanguage}positiveStatus`);
  const renderBordStatusVariant = dataHeader?.assignedTo ? 'default' : 'success';
  const renderToolSerial = <ToolSerial serial={dataHeader?.serial ?? 'Sin serial'} charactersLimitForHover={25} />;

  return (
    <>
      {generalLoading ? (
        <LoaderHeaderNodiInternalProductDetails />
      ) : (
        <div id="headerNodiInternalProductDetails">
          <div className="boxHeaderInternalProduct">
            <div className="containerImg">
              <BordPhoto url={dataHeader?.imageUrl} size="s100" variant="square" />
            </div>
            <div className="containerDetails">
              <div className="centerItems">
                <div className="topDetails">
                  <div className="brandDetails">
                    <BordPhoto url={dataHeader?.brandImage} size="s32" variant="circle" />
                  </div>
                  <div className="textDetails">
                    {truncateText(dataHeader?.commercialName, 30)}
                    {dataHeader?.commercialName && dataHeader?.commercialName?.length > 30 && (
                      <div className="nameHover">{dataHeader?.commercialName}</div>
                    )}
                  </div>
                  <div>
                    <BordFlag country={dataHeader?.country?.code as TcountryCode} variant="square" standardSize={12} />
                  </div>
                  <BordStatus label={renderBordStatusLabel} variant={renderBordStatusVariant} />
                </div>
                <div className="downDetails">
                  <div className="containerPills">
                    <ProductPills pills={dataHeader?.pills} positionTooltip="bottom" />
                  </div>
                  {renderToolSerial}
                  <div className="containerNudosCare">
                    <NudosCareTag nudosCareType={dataHeader?.nudosCareName} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderNodiInternalProductDetails;
