const RoundBahrain = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M16 0c-3.211 0-6.2005.946563-8.70625 2.57488l4.11495 2.75806-5.14782 2.66662 5.14782 2.66664-5.14782 2.6666 5.14782 2.6663-5.14782 2.6666 5.14782 2.6666-5.14782 2.6668 5.14782 2.6667-4.11545 2.7589C9.79913 31.0533 12.7888 32 16 32c8.8365 0 16-7.1635 16-16 0-8.8365-7.1635-16-16-16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBahrain;
