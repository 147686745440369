const SquareTajikistan = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#249F58" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M12.5401 7.6799C12.5611 7.78337 12.5716 7.89004 12.5716 7.9999C12.5716 8.8831 11.8676 9.5999 11.0001 9.5999C10.1327 9.5999 9.42871 8.8831 9.42871 7.9999C9.42871 7.89004 9.43919 7.78337 9.46014 7.6799C9.60681 8.1663 10.2396 8.53324 11.0001 8.53324C11.7607 8.53324 12.3935 8.1663 12.5401 7.6799ZM11.0001 7.46657C11.2893 7.46657 11.5239 7.22764 11.5239 6.93324C11.5239 6.63884 11.2893 6.3999 11.0001 6.3999C10.711 6.3999 10.4763 6.63884 10.4763 6.93324C10.4763 7.22764 10.711 7.46657 11.0001 7.46657Z"
            clipRule="evenodd"
          />
          <path fill="#AF010D" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareTajikistan;
