import { DTOOrganizationDocumentAlreadyExistResponse } from '../../types/SignUp';
import BordModal from '../BordDesignSystem/BordModal/BordModal';
import ModalContent from '../BordDesignSystem/BordModal/components/ModalContent';
import InviteCollaboratorCustomModalComponent from './InviteCollaboratorCustomModalComponent';
import './InviteCollaboratorModal.scss';

const InviteCollaboratorModal = ({
  setOpenInviteCollaboratorModal,
  isAnInvitation,
  existingOrganizationData
}: IinviteCollaboratorModalParams) => {
  return (
    <div className="inviteCollaboratorModal relative">
      <BordModal
        modalContent={
          <ModalContent
            excludeHeader
            customWidth={isAnInvitation ? '58rem' : '48rem'}
            customHeight={isAnInvitation ? '44rem' : '38.4rem'}
            CustomModalComponent={
              <InviteCollaboratorCustomModalComponent
                isAnInvitation={!!isAnInvitation}
                setOpenInviteCollaboratorModal={setOpenInviteCollaboratorModal}
                existingOrganizationData={existingOrganizationData}
              />
            }
            toCloseModal={() => setOpenInviteCollaboratorModal(false)}
          />
        }
      />
    </div>
  );
};

export default InviteCollaboratorModal;

export interface IinviteCollaboratorModalParams {
  setOpenInviteCollaboratorModal: React.Dispatch<React.SetStateAction<boolean>>;
  existingOrganizationData: DTOOrganizationDocumentAlreadyExistResponse;
  isAnInvitation?: boolean;
  modalTitle?: string;
  companyName?: string;
}
