const SquarePhilippines = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#F93939" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V8H0V0Z" clipRule="evenodd" />
          <path fill="white" fillRule="evenodd" d="M0 0V16L10 7.98244L0 0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M4.485 8.485C5.355 8.43 6 8.235 6 8C6 7.766 5.355 7.57 4.485 7.515C4.43 6.645 4.235 6 4 6C3.766 6 3.57 6.645 3.515 7.515C2.645 7.57 2 7.765 2 8C2 8.234 2.645 8.43 3.515 8.485C3.569 9.355 3.765 10 4 10C4.234 10 4.43 9.355 4.485 8.485Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            d="M7.5 9C7.77614 9 8 8.55228 8 8 8 7.44772 7.77614 7 7.5 7 7.22386 7 7 7.44772 7 8 7 8.55228 7.22386 9 7.5 9ZM2.5 5C2.77614 5 3 4.77614 3 4.5 3 4.22386 2.77614 4 2.5 4 2.22386 4 2 4.22386 2 4.5 2 4.77614 2.22386 5 2.5 5ZM2.5 12C2.77614 12 3 11.7761 3 11.5 3 11.2239 2.77614 11 2.5 11 2.22386 11 2 11.2239 2 11.5 2 11.7761 2.22386 12 2.5 12Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquarePhilippines;
