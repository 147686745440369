const RoundNicaragua = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#3ECBF8"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0ZM16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32Z"
        />
        <path
          fill="#FFDA2C"
          d="M16.0004 11.1304C13.311 11.1304 11.1309 13.3106 11.1309 15.9999C11.1309 18.6893 13.311 20.8695 16.0004 20.8695C18.6898 20.8695 20.87 18.6893 20.87 15.9999C20.87 13.3106 18.6898 11.1304 16.0004 11.1304ZM16.0004 18.7826C14.4637 18.7826 13.2178 17.5368 13.2178 15.9999C13.2178 14.4631 14.4636 13.2173 16.0004 13.2173C17.5372 13.2173 18.783 14.4631 18.783 15.9999C18.783 17.5368 17.5372 18.7826 16.0004 18.7826Z"
        />
        <path fill="#1A47B8" d="M18.4101 16.6956L16.0002 16L13.5904 16.6956L12.7871 18.0869H19.2133L18.4101 16.6956Z" />
        <path fill="#3ECBF8" d="M16.0001 12.5217L14.3936 15.3044L16.0001 16L17.6067 15.3044L16.0001 12.5217Z" />
        <path fill="#6DA544" d="M13.5898 16.6957H18.4096L17.6063 15.3044H14.3932L13.5898 16.6957Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundNicaragua;
