import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <g clipPath="url(#clip0_6451_3516)">
      <path
        d="M2.34752 10.2082C1.58163 10.1331 0.937932 9.5417 0.831876 8.73753C0.710517 7.81732 0.643066 6.87128 0.643066 5.89746C0.643066 4.88925 0.710542 3.8972 0.840473 2.94739C0.943238 2.19619 1.52412 1.62918 2.23924 1.54989C3.44731 1.41596 4.70947 1.34375 6.00021 1.34375C7.28693 1.34375 8.5268 1.4203 9.73598 1.55228C10.4633 1.63165 11.0539 2.21045 11.1572 2.9745C11.2846 3.91697 11.3574 4.89797 11.3574 5.89746C11.3574 6.88904 11.2778 7.85306 11.1526 8.78904C11.0484 9.56768 10.4484 10.1619 9.70718 10.2415C8.50971 10.3698 7.27519 10.4512 6.00021 10.4512C5.96969 10.4512 5.93919 10.4511 5.9087 10.451C5.00966 11.5426 4.32037 12.0821 3.1156 12.6533V10.2833C2.92025 10.2647 2.72604 10.2455 2.53301 10.2265C2.47105 10.2205 2.40923 10.2143 2.34752 10.2082Z"
        fill={props?.fill || 'white'}
        stroke={props?.stroke || '#999999'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00056 6.30122C6.00056 5.99705 6.23492 5.81048 6.64841 5.51884C7.03194 5.24834 7.23424 4.89744 7.14425 4.4199C7.05427 3.94237 6.68046 3.54779 6.22805 3.4528C5.5179 3.3037 4.83447 3.89573 4.83447 4.66002"
        stroke={props?.stroke || '#999999'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 8.40588V8.17969" stroke={props?.stroke || '#999999'} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6451_3516">
        <rect width="12" height="12.6667" fill={props?.fill || 'white'} transform="translate(0 0.664062)" />
      </clipPath>
    </defs>
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const MenuFaqsIcon = memo(ForwardRef);
export default MenuFaqsIcon;
