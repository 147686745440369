const SquareBurundi = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path
            fill="#F93939"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#00B731"
            fillRule="evenodd"
            d="m0 0 10.4762 8.53333L0 16V0Zm22 0L11.5238 8.53333 22 16V0Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M7.49886 6.8864 0 1.6V0h1.57143l7.17305 5.056C9.36572 4.56107 10.1483 4.26667 11 4.26667c.8507 0 1.6343.2944 2.2555.78933L20.4286 0H22v1.6l-7.4989 5.2864c.1079.352.1656.72533.1656 1.1136 0 .38827-.0577.7616-.1656 1.1136L22 14.4V16h-1.5714l-7.1731-5.056c-.6223.4949-1.4038.7893-2.2555.7893-.8507 0-1.63428-.2944-2.25552-.7893L1.57143 16H0v-1.6l7.49886-5.2864c-.10791-.352-.16553-.72533-.16553-1.1136 0-.38827.05762-.7616.16553-1.1136Z"
            clipRule="evenodd"
          />
          <path
            fill="#AF010D"
            fillRule="evenodd"
            d="M10.4762 6.3999h1.0476v1.06667h-1.0476V6.3999Zm1.0476 2.13334h1.0476V9.5999h-1.0476V8.53324Zm-2.09521 0h1.04761V9.5999H9.42859V8.53324Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBurundi;
