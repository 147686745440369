import { ITwoToneIconProps } from '../BordTwoToneIcon.types';
import { bordColorTheme } from '../../../../styles/constants';

const BordTwoToneInventoryIcon = (props: ITwoToneIconProps) => {
  const { colorTones } = props;
  const { tone1, tone2, opacity1, opacity2 } = colorTones || {};
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill={tone1 || bordColorTheme.navyBlue[400]}
        d="m15.5 19.1945-.4974.5613.4974-.5613Zm2.0714 2.503-.6403.3904.6403-.3904Zm2.9286-2.503-.4974-.5613.4974.5613Zm-2.0714 2.503.6278.4103-.6278-.4103ZM18 15.25c-.4142 0-.75.3358-.75.75s.3358.75.75.75v-1.5Zm.009 1.5c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75v1.5ZM11.25 8c0 .41421.3358.75.75.75s.75-.33579.75-.75h-1.5Zm1.5-6c0-.41421-.3358-.75-.75-.75s-.75.33579-.75.75h1.5Zm2 14.0403c0-1.8243 1.4622-3.2903 3.25-3.2903v-1.5c-2.6304 0-4.75 2.1518-4.75 4.7903h1.5Zm1.2474 2.5929c-.8618-.7637-1.2474-1.5599-1.2474-2.5929h-1.5c0 1.5189.6144 2.7069 1.7526 3.7155l.9948-1.1226Zm-.9948 1.1226c.6445.5711 1.4514 1.5496 1.9285 2.3321l1.2807-.7808c-.5483-.8994-1.4492-1.9958-2.2144-2.6739l-.9948 1.1226Zm7.7474-3.7155c0-2.6385-2.1196-4.7903-4.75-4.7903v1.5c1.7878 0 3.25 1.466 3.25 3.2903h1.5Zm-1.7526 3.7155c1.1382-1.0086 1.7526-2.1966 1.7526-3.7155h-1.5c0 1.033-.3856 1.8292-1.2474 2.5929l.9948 1.1226Zm-.9948-1.1226c-.774.6859-1.644 1.8004-2.2019 2.6541l1.2557.8205c.5194-.7948 1.3053-1.7887 1.941-2.352l-.9948-1.1226Zm-3.0715 3.4547c.1914.3141.5422.6621 1.0623.6621.5115 0 .8644-.3382 1.063-.6422l-1.2557-.8205c-.0649.0993-.0177-.0373.1927-.0373.2188 0 .2728.1463.2184.0571l-1.2807.7808ZM18 16.75h.009v-1.5H18v1.5ZM12.75 8V2h-1.5v6h1.5Z"
        opacity={opacity1 || '.4'}
      />
      <path
        fill={tone2 || bordColorTheme.navyBlue[400]}
        d="m2 8-.69231-.28846L1.25 7.85V8H2Zm10.5 14.75c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75v1.5Zm8.75-12.25c0 .4142.3358.75.75.75s.75-.3358.75-.75h-1.5ZM22 8h.75v-.15l-.0577-.13846L22 8ZM3.30175 20.7447l.5206-.5398-.5206.5398ZM2.96154 5.69231l.69231.28846-.69231-.28846Zm18.07696 0 .6923-.28846-.6923.28846ZM4.8359 2.50385l-.41603-.62404.41603.62404ZM10 11.25c-.41421 0-.75.3358-.75.75s.33579.75.75.75v-1.5Zm2 1.5c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75v1.5ZM1.25 8v5.4286h1.5V8h-1.5Zm9.6389 14.75H12.5v-1.5h-1.6111v1.5ZM22.75 10.5V8h-1.5v2.5h1.5Zm-21.5 2.9286c0 1.9983-.00171 3.5753.1699 4.8061.17503 1.2554.54306 2.261 1.36125 3.0499l1.0412-1.0797c-.48356-.4663-.7664-1.0985-.91682-2.1773-.15382-1.1033-.15553-2.5568-.15553-4.599h-1.5Zm9.6389 7.8214c-2.11561 0-3.62952-.0015-4.78035-.1507-1.13197-.1467-1.79882-.4245-2.2862-.8944l-1.0412 1.0797c.81437.7853 1.84595 1.1352 3.13455 1.3023 1.26973.1646 2.89852.1631 4.9732.1631v-1.5ZM2 8.75h20v-1.5H2v1.5Zm.69231-.46154.96154-2.30769-1.38462-.57692-.96154 2.30769 1.38462.57692Zm19.99999-.57692-.9615-2.30769-1.3846.57692.9615 2.30769 1.3846-.57692ZM8.5 2.75h7v-1.5h-7v1.5Zm13.2308 2.65385c-.3666-.87981-.6636-1.59525-.9687-2.14959-.3139-.57041-.668-1.03181-1.182-1.37445l-.832 1.24808c.2418.1612.452.39917.6999.84959.2567.46649.519 1.09336.8982 2.00329l1.3846-.57692ZM15.5 2.75c.9858 0 1.6653.00103 2.1946.05859.5112.05557.8117.15809 1.0535.3193l.832-1.24808c-.5139-.34264-1.076-.49205-1.7233-.56243C17.2278 1.24897 16.4531 1.25 15.5 1.25v1.5ZM3.65385 5.98077c.37913-.90993.64144-1.5368.89817-2.00329.24788-.45042.45809-.68839.6999-.84959l-.83205-1.24808c-.51396.34264-.86807.80404-1.18199 1.37445-.30508.55434-.60206 1.26978-.96865 2.14959l1.38462.57692ZM8.5 1.25c-.95313 0-1.72775-.00103-2.35679.06738-.64728.07038-1.20937.21979-1.72334.56243l.83205 1.24808c.24182-.16121.54233-.26373 1.05344-.3193C6.8347 2.75103 7.51424 2.75 8.5 2.75v-1.5Zm1.5 11.5h2v-1.5h-2v1.5Z"
        opacity={opacity2}
      />
    </svg>
  );
};

export default BordTwoToneInventoryIcon;
