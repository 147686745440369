const RoundChad = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M16.0001 0c-1.9571 0-3.8319.352063-5.5652.995188L9.73926 16l.69564 15.0048c1.7333.6432 3.6081.9952 5.5652.9952 1.9572 0 3.832-.352 5.5652-.9952L22.261 16 21.5654.995188C19.8321.352063 17.9573 0 16.0001 0Z"
        />
        <path
          fill="#F93939"
          d="M32.0002 15.9999c0-6.87941-4.342-12.7441-10.4348-15.004783V31.0048c6.0928-2.2607 10.4348-8.1254 10.4348-15.0049Z"
        />
        <path
          fill="#1A47B8"
          d="M10.4348 31.0047V.995117C4.342 3.2558 0 9.12049 0 15.9999c0 6.8795 4.342 12.7442 10.4348 15.0048Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundChad;
