const RoundVaticanCity = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 0C24.8365 0 32 7.1635 32 16C32 24.8365 24.8365 32 16 32C16 31.3044 13.9131 16 13.9131 16L16 0Z"
        />
        <path fill="#FFDA2C" d="M16 32C7.1635 32 0 24.8365 0 16C0 7.1635 7.1635 0 16 0" />
        <path
          fill="#ACABB1"
          d="M22.1266 13.9229L25.1335 17.9007C24.6598 18.6181 24.6623 19.5846 25.2102 20.3094C25.9052 21.2289 27.214 21.4109 28.1335 20.7158C29.053 20.0208 29.235 18.712 28.5399 17.7926C27.992 17.0677 27.0628 16.8017 26.2434 17.0619L21.5571 10.8623L20.4472 11.7013L18.7822 12.9597L20.4602 15.1794L22.1266 13.9229ZM26.4555 18.496C26.762 18.2642 27.1984 18.325 27.43 18.6314C27.6617 18.9379 27.6011 19.3742 27.2946 19.6059C26.9882 19.8375 26.5519 19.777 26.3202 19.4704C26.0884 19.1639 26.1491 18.7276 26.4555 18.496Z"
        />
        <path
          fill="#FFDA2C"
          d="M27.284 15.1795L28.962 12.9598L27.2971 11.7013L26.1873 10.8623L21.501 17.0619C20.6817 16.8017 19.7524 17.0678 19.2045 17.7926C18.5094 18.7121 18.6914 20.0208 19.6108 20.7158C20.5303 21.4108 21.8391 21.2289 22.5341 20.3094C23.082 19.5846 23.0845 18.6181 22.6108 17.9007L25.6177 13.9229L27.284 15.1795ZM21.4242 19.4706C21.1926 19.7771 20.7563 19.8377 20.4498 19.6061C20.1433 19.3743 20.0827 18.938 20.3144 18.6315C20.546 18.3251 20.9823 18.2644 21.2889 18.4961C21.5953 18.7278 21.6559 19.1641 21.4242 19.4706Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundVaticanCity;
