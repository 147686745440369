import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { formatOrgData } from './orgFormatData';

interface IsendSentryErrors {
  error: AxiosError;
  requestURL: string;
  data?: unknown;
  requestMethod: 'GET' | 'DELETE' | 'POST' | 'PUT';
}

const sendSentryError = ({ error, requestURL, data, requestMethod }: IsendSentryErrors) => {
  const orgData = formatOrgData();
  Sentry.withScope(scope => {
    scope.setTags({
      request: requestURL,
      orgId: orgData?.organizationId
    });
    scope.setExtra('requestMethod', requestMethod);
    scope.setUser({ userId: orgData?.userId });
    scope.setLevel('error');
    scope.setExtra('payload', data ? JSON.stringify(data) : '');
    scope.setExtra('errorData', error?.response?.data || error?.response || 'Error Desconocido');
    scope.setExtra('extraInformation', requestURL);
    Sentry.captureException(error);
  });
};

export { sendSentryError };
