import { useEffect, useState } from 'react';
import { getOrderList, getOrdersFilters } from '../../../services/orders';
import useStateOrders from '../../../state/useStateOrders';
import { IorderInListing } from '../../../types/orders';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import useEcommerceControler from '../../ecommerce/ecommerce.controller';
import { LoaderCard, OrderListingCard } from './components';
import OrdersFilters from './components/OrdersFilters/OrdersFilters';
import { ImoduleFilter } from '../../../types/global';
import ZeroOrders from './components/ZeroOrders/ZeroOrders';
import './orderList.scss';

const OrderList = () => {
  const { setOrderNumbers, ordersFilters, setOrdersFilters } = useStateOrders();
  const [ordersData, setOrdersData] = useState<IorderInListing[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const { orgData } = useEcommerceControler();
  const getDataOrders = async (filters?: ImoduleFilter[]) => {
    setLoading(true);
    try {
      const filtersParam = filters && filters.length > 0 && JSON.stringify(filters);
      const filterQuery = filtersParam ? `?filters=${filtersParam}` : '';
      const myOrderData = await getOrderList(orgData.organizationId, filterQuery);
      if (myOrderData) {
        setOrderNumbers(myOrderData.length);
        setOrdersData(myOrderData);
      }
      setLoading(false);
    } catch (error) {
      displayErrorNotification();
      setLoading(false);
    }
  };

  const ordersFound = ordersData && ordersData.length > 0;
  const filtersApplied = ordersFilters && ordersFilters.length > 0;

  useEffect(() => {
    getDataOrders(ordersFilters);
  }, [ordersFilters]);

  return (
    <div id="orderList" className="moduleWithSidebar">
      <OrdersFilters
        ordersFilters={ordersFilters || []}
        setOrdersFilters={setOrdersFilters}
        getOrdersFiltersRequestFunction={getOrdersFilters}
      />
      <div className="containerOrderList">
        <div className="containerOrderCards">
          {loading && <LoaderCard />}
          {!loading &&
            ordersFound &&
            ordersData.map((item, index) => {
              return <OrderListingCard dataOrderCard={item} key={`key-order-card-${item.orderId}-index-${index}`} />;
            })}
          {!loading && !ordersFound && <ZeroOrders hasFiltersApplied={filtersApplied} />}
        </div>
      </div>
    </div>
  );
};
export default OrderList;
