import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { BordButton } from '../../../../components/BordDesignSystem';
import BordModal from '../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import { IconTimeClock } from '../../../../assets/DesignSystem/SVGComponents';
import './TimeOutModal.scss';

interface ITimeOutModal {
  resetSteps?: () => void;
}

const TimeOutModal: FC<ITimeOutModal> = ({ resetSteps }: ITimeOutModal) => {
  const { push } = useHistory();

  return (
    <BordModal
      modalContent={
        <ModalContent
          customHeight="220px"
          customWidth="466px"
          excludeHeader
          CustomModalComponent={
            <div id="timeOutModal">
              <div className="boxTimeOutModal">
                <div className="clockIconBox">
                  <div className="blueContainer">
                    <IconTimeClock className="scaleIcon" />
                  </div>
                </div>
                <div className="timeOutText">
                  Tiempo de confirmación excedido. Solicita de nuevo tu
                  <br /> servicio logístico para continuar.
                </div>
                <div className="containerButtons">
                  <BordButton
                    customClassName="mr-2"
                    customWidth="w-180"
                    modeButton="secondary"
                    label="Volver al inicio"
                    onClick={() => {
                      push('/nodi/dashboard');
                    }}
                  />
                  <BordButton
                    customWidth="w-180"
                    label="Repetir servicio"
                    onClick={() => {
                      if (resetSteps) {
                        resetSteps();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
      }
    />
  );
};

export default TimeOutModal;
