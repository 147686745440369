import { TnudosCare } from '../../../types/global';
import './NudosCareTag.scss';

const NudosCareTag = ({
  nudosCareType,
  customWidth,
  customHeight,
  customFontSize,
  customClass,
  freeBG,
  freeFont
}: InudosCareTag) => {
  const getNudosCareText = () => {
    if (nudosCareType === 'Free') return 'No Care';
    if (nudosCareType === 'Log') return 'Logistic Care';
    if (nudosCareType === 'Basic') return 'Basic Care';
    if (nudosCareType === 'Full') return 'Full Care';
    return 'No Care';
  };
  const getBackgroundColor = () => {
    if (nudosCareType === 'Free') return freeBG || '#262B39';
    if (nudosCareType === 'Log') return '#272300';
    if (nudosCareType === 'Basic') return '#272300';
    if (nudosCareType === 'Full') return '#272300';
    return '#262B39';
  };
  const getFontColor = () => {
    if (nudosCareType === 'Free') return freeFont || '#626C82';
    if (nudosCareType === 'Log') return '#F5DD28';
    if (nudosCareType === 'Basic') return '#F5DD28';
    if (nudosCareType === 'Full') return '#F5DD28';
    return '#626C82';
  };

  const nudosCareTagStyles = {
    backgroundColor: getBackgroundColor(),
    color: getFontColor(),
    width: customWidth,
    height: customHeight,
    fontSize: customFontSize
  };

  return (
    <div className={`nudosCareTag ${customClass || ''}`} style={nudosCareTagStyles}>
      {getNudosCareText()}
    </div>
  );
};

export default NudosCareTag;

interface InudosCareTag {
  nudosCareType?: TnudosCare | null;
  customWidth?: string;
  customHeight?: string;
  customFontSize?: string;
  customClass?: string;
  freeBG?: string;
  freeFont?: string;
}
