const RoundUnitedArabEmirates = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="black"
          d="M9.04395 21.5654L10.4353 31.0052C12.1685 31.6484 14.0433 32.0002 16.0004 32.0002C22.8798 32.0002 28.7446 27.6582 31.0052 21.5654H9.04395Z"
        />
        <path
          fill="#6DA544"
          d="M9.04395 10.4348L10.4353 0.995062C12.1685 0.351875 14.0433 0 16.0004 0C22.8798 0 28.7446 4.342 31.0052 10.4348H9.04395Z"
        />
        <path
          fill="#A2001D"
          d="M0 15.9999C0 22.8794 4.34206 28.7441 10.4348 31.0047V0.995117C4.34206 3.2558 0 9.12049 0 15.9999Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundUnitedArabEmirates;
