import { useState } from 'react';
import {
  IlocationAddressForLogistics,
  IwarehouseData,
  TlogisticsOriginOrDestinationData
} from '../../../../../types/requestLogisticsModule';
import { TstoragePlace } from '../../../../../types/assignationFluxes';
import { Iemployee } from '../../../../../types/global';
import { ICountryDetail } from '../../../../../types/countries';
import NudosLogisticsGeneralContainer from '../../../../../components/DesignSystem/NudosLogisticsGeneralContainer/NudosLogisticsGeneralContainer';
import {
  NudosLogisticServiceUserHeader,
  NudosLogisticsEmployeesDropdown,
  NudosLogisticsOfficeDropdown,
  NudosLogisticsWarehouseDropdown
} from '../../../../../components/DesignSystem';
import './ToolsManagementStep1SelectDestination.scss';

enum DestinationType {
  origin = 'origin',
  destination = 'destination'
}

const ToolsManagementStep1SelectDestination = ({
  selectedDestination,
  originData,
  originUrl,
  destinationData,
  nudosWarehousesList,
  updateDestinationDataCallback,
  showSkeletonLoader = false,
  filterCountriesForLogistics
}: {
  originUrl: string;
  showSkeletonLoader?: boolean;
  selectedDestination?: TstoragePlace;
  originData?: IlocationAddressForLogistics;
  destinationData?: TlogisticsOriginOrDestinationData;
  nudosWarehousesList: IwarehouseData[];
  updateDestinationDataCallback: (destinationData: TlogisticsOriginOrDestinationData) => void;
  requireLogistics: boolean;
  loadingWarehousesList?: boolean;
  filterCountriesForLogistics?: ICountryDetail[];
}) => {
  const [specificDestinationDropdownIsFocused, setSpecificDestinationDropdownIsFocused] = useState(false);
  const originCountry = originData?.countryData || originData?.country;
  const originFlagCountry = originCountry?.code || undefined;
  const originIsOffice = originData?.place === 'office';
  const originIsWarehouse = originData?.place === 'nudos';

  const destinationIsOffice = selectedDestination === 'office';
  const destinationIsUser = selectedDestination === 'user';
  const destinationIsWarehouse = selectedDestination === 'nudos';
  const destinationFlagCountry = destinationData?.country?.code || undefined;
  const pendingDestination = destinationData ? 'pendingDestination' : undefined;

  const getWarehouseSufixName = (type: DestinationType): string => {
    if (type === DestinationType.destination) {
      return (destinationData as IwarehouseData)?.country?.name || '';
    }
    return originData?.countryData?.name ?? '';
  };

  const getWarehouseDestinationInformation = (place: TstoragePlace, type: DestinationType) => {
    switch (place) {
      case 'warranty':
        return { cardName: 'Marca' };
      case 'diagnostic':
        return { cardName: 'Diagnóstico (Bodegas Nudos)' };
      case 'nudos':
        return {
          cardName: `Bodega ${getWarehouseSufixName(type)}`
        };
      default:
        return { cardName: '' };
    }
  };

  const getFormattedName = (
    place: TstoragePlace,
    type: DestinationType,
    data?: TlogisticsOriginOrDestinationData | null
  ) => {
    if (!data) return undefined;
    const dataAsOffice = data as IlocationAddressForLogistics;
    switch (place) {
      case 'user':
        return `${(data as Iemployee)?.firstName || ''} ${(data as Iemployee)?.lastName || ''}`;
      case 'office':
        return `Oficina en ${dataAsOffice?.countryData?.name || dataAsOffice?.country?.name || 'País'}`;
      case 'other':
        return dataAsOffice?.locationName || 'Otra dirección';
      default:
        return getWarehouseDestinationInformation(place, type)?.cardName;
    }
  };

  const getPhoto = (place: TstoragePlace, data?: TlogisticsOriginOrDestinationData | null) => {
    switch (place) {
      case 'user':
        return (data as Iemployee)?.photoProfile?.replaceAll(' ', '%20') || '';
      default:
        return undefined;
    }
  };

  const updateDestinationDataCallbackWithFocusFunctionality = (destinationData: TlogisticsOriginOrDestinationData) => {
    setSpecificDestinationDropdownIsFocused(false);
    updateDestinationDataCallback(destinationData);
  };

  return (
    <div className="ToolsManagementStep1SelectDestination">
      <NudosLogisticsGeneralContainer title="Elige el destino de las herramientas">
        <div className="dropdownsContainer">
          {destinationIsOffice && (
            <NudosLogisticsOfficeDropdown
              componentSize="w-380"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as IlocationAddressForLogistics}
              isFocused={specificDestinationDropdownIsFocused}
              filterCountries={filterCountriesForLogistics}
              currentCountryForExclusion={originIsOffice && originCountry ? originCountry : undefined}
            />
          )}
          {destinationIsUser && (
            <NudosLogisticsEmployeesDropdown
              originUrl={originUrl}
              componentSize="w-380"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as Iemployee}
              isFocused={specificDestinationDropdownIsFocused}
              filterCountries={filterCountriesForLogistics}
              showFlag
            />
          )}
          {destinationIsWarehouse && (
            <NudosLogisticsWarehouseDropdown
              componentSize="w-380"
              updateCurrentValueCallback={updateDestinationDataCallbackWithFocusFunctionality}
              currentValue={destinationData as IwarehouseData}
              warehousesListProp={nudosWarehousesList}
              isFocused={specificDestinationDropdownIsFocused}
              currentCountryForExclusion={originIsWarehouse && originCountry ? originCountry : undefined}
              countryOfOrigin={originCountry?.code}
            />
          )}
        </div>
        <div className="divider" />
        <div className="selectedLocations">
          <NudosLogisticServiceUserHeader
            type={'origin'}
            photoUrl={getPhoto(originData?.place || '', originData)}
            name={getFormattedName(originData?.place || '', DestinationType.origin, originData)}
            flagCountryCode={originFlagCountry}
            showSkeletonLoader={showSkeletonLoader}
            place={originData?.place || undefined}
          />
          <NudosLogisticServiceUserHeader
            customClassName={pendingDestination}
            type={'destination'}
            name={getFormattedName(selectedDestination || '', DestinationType.destination, destinationData)}
            photoUrl={getPhoto(selectedDestination || '', destinationData)}
            flagCountryCode={destinationFlagCountry}
            showSkeletonLoader={showSkeletonLoader}
            place={selectedDestination}
          />
        </div>
      </NudosLogisticsGeneralContainer>
    </div>
  );
};

export default ToolsManagementStep1SelectDestination;
