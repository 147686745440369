enum RegisterEvents {
  loginView = 'Login_View',
  loginClick = 'Login_Click',
  loginAccessRequestClick = 'Login_Access_Request_Click',
  nodiLoginNoAccountView = 'Nodi_Login_No_Account_View',
  nodiLoginAccessRequestNoAccountClick = 'Nodi_Login_Access_Request_No_Account_Click', // no esta el evento
  loginRecoverPasswordClick = 'Login_Recover_Password_Click',
  nodiLoginPasswordView = 'Nodi_Login_Password_View',
  loginPasswordClick = 'Login_Password_Click',
  signupFirstStepClick = 'Signup_First_Step_Click',
  signupFirstStepView = 'Signup_First_Step_View',
  signupSecondStepClick = 'Signup_Second_Step_Click',
  signupSecondStepView = 'Signup_Second_Step_View',
  signupThirdStepView = 'Signup_Third_Step_View',
  signupThirdStepClick = 'Signup_Third_Step_Click',
  signupWaitingView = 'Signup_Waiting_View',
  signupRejectedView = 'Signup_Rejected_View',
  generalSignUpCompleted = 'Nodi_General_SignUpCompleted'
}

enum OnboardingEvents {
  nodiOnboardingFree0View = 'Nodi_Onboarding_Free_0_View',
  nodiFreeOnboardingClick = 'Nodi_Free_Onboarding_Click',
  nodiOnboardingFreeBannerView = 'Nodi_Onboarding_Free_Banner_View',
  nodiOnboardingFree1View = 'Nodi_Onboarding_Free_1_View',
  nodiOnboardingFree2View = 'Nodi_Onboarding_Free_2_View',
  nodiOnboardingFree3View = 'Nodi_Onboarding_Free_3_View',
  nodiOnboardingFree4View = 'Nodi_Onboarding_Free_4_View',
  nodiOnboardingFree5View = 'Nodi_Onboarding_Free_5_View',
  nodiOnboardingFree6View = 'Nodi_Onboarding_Free_6_View',
  nodiOnboardingFree7View = 'Nodi_Onboarding_Free_7_View',
  nodiOnboardingPrime0View = 'Nodi_Onboarding_Prime_0_View',
  nodiPrimeOnboardingClick = 'Nodi_Prime_Onboarding_Click',
  nodiOnboardingPrime1View = 'Nodi_Onboarding_Prime_1_View',
  nodiOnboardingPrime2View = 'Nodi_Onboarding_Prime_2_View',
  nodiOnboardingPrime3View = 'Nodi_Onboarding_Prime_3_View',
  nodiOnboardingPrime4View = 'Nodi_Onboarding_Prime_4_View',
  nodiOnboardingPrime5View = 'Nodi_Onboarding_Prime_5_View',
  nodiOnboardingPrime6View = 'Nodi_Onboarding_Prime_6_View',
  nodiOnboardingPrime7View = 'Nodi_Onboarding_Prime_7_View'
}

enum PlatformPrimeAndFreeEvents {
  nodiFreeCsvModalNodiPrimeAdvertiseView = 'Nodi_Free_Csv_Modal_NodiPrimeAdvertise_View',
  nodiFreeCsvModalNodiPrimeAdvertiseClick = 'Nodi_Free_Csv_Modal_NodiPrimeAdvertise_Click',
  nodiFreeCsvToolClick = 'Nodi_Free_Csv_Tool_Click',
  nodiFreeUploadToolBannerNodiPrimeAdvertiseClick = 'Nodi_Free_UploadTool_Banner_NodiPrimeAdvertise_Click',
  nodiFreeUploadToolModalNodiPrimeAdvertiseView = 'Nodi_Free_UploadTool_Modal_NodiPrimeAdvertise_View',
  nodiFreeUploadToolModalNodiPrimeAdvertiseClick = 'Nodi_Free_UploadTool_Modal_NodiPrimeAdvertise_Click',
  nodiFreeUploadToolToastNodiPrimeAdvertiseClick = 'Nodi_Free_UploadTool_Toast_NodiPrimeAdvertise_Click', // no esta
  nodiFreeUploadToolToast10NodiPrimeAdvertiseView = 'Nodi_Free_UploadTool_Toast>10_NodiPrimeAdvertise_View'
}

enum UnassignEvents {
  nodiLogisticServices0ChooseView = 'Nodi_LogisticServices_0_Choose_View',
  nodiLogisticServicesHireClick = 'Nodi_LogisticServices_Hire_Click', // No esta
  nodiLogisticServicesUpdateClick = 'Nodi_LogisticServices_Update_Click', // No esta
  nodiLogisticServices1DestinationView = 'Nodi_Logistic_Services_1_Destination_View',
  nodiLogisticServicesDestinationClick = 'Nodi_LogisticServices_Destination_Click',
  nodiLogisticServices2ToolView = 'Nodi_Logistic_Services_2_Tool_View',
  nodiLogisticServicesToolQuote = 'Nodi_LogisticServices_Tool_Quote',
  nodiLogisticServices3LocalDateView = 'Nodi_LogisticServices_3_LocalDate_View',
  nodiLogisticServicesLocalDateClick = 'Nodi_LogisticServices_LocalDate_Click',
  nodiLogisticServices4QuotationView = 'Nodi_LogisticServices_4_Quotation_View',
  nodiLogisticServicesDownloadQuotationClick = 'Nodi_LogisticServices_DownloadQuotation_Click',
  nodiLogisticServicesMoreInfoClick = 'Nodi_LogisticServices_MoreInfo_Click',
  nodiLogisticServicesRejectQuotationClick = 'Nodi_LogisticServices_RejectQuotation_Click',
  nodiLogisticServicesAproveQuotationClick = 'Nodi_LogisticServices_AproveQuotation_Click',
  nodiLogisticServices512ModalResumeRejectedView = 'Nodi_LogisticServices_5.1.2_Modal_ResumeRejected_View', // no esta
  nodiLogisticServicesRejectQuoteModalCloseClick = 'Nodi_LogisticServices_RejectQuote_Modal_Close_Click', // no esta
  nodiLogisticServicesRejectQuoteModalContinueSkipClick = 'Nodi_LogisticServices_RejectQuote_Modal_Continue_Skip_Click', // no esta
  nodiLogisticServicesRejectQuoteModalContinueClick = 'Nodi_LogisticServices_RejectQuote_Modal_Continue_Click', // no esta
  nodiLogisticServices51ResumeAprovedView = 'Nodi_LogisticServices_5.1_ResumeAproved_View',
  nodiLogisticServices52ResumeRejectedView = 'Nodi_LogisticServices_5.2_ResumeRejected_View',
  nodiLogisticServicesRejectQuoteRepeatClick = 'Nodi_LogisticServices_RejectQuote_Repeat_Click', // No esta
  nodiLogisticServices6TimeOutView = 'Nodi_LogisticServices_6_TimeOut_View'
}

enum DashboardEvents {
  dashboardView = 'Dashboard_View',
  nodiFreeCsvDashboardClick = 'Nodi_Free_Csv_Dashboard_Click'
}

enum SideBarEvents {
  faqsButtonClick = 'Nodi_Faqs_Click'
}

enum EmployeesEvents {
  employeesView = 'Employees_View',
  nodiFreeMassiveEmployeeUploadModalNodiPrimeAdvertiseClick = 'Nodi_Free_MassiveEmployeeUpload_Modal_NodiPrimeAdvertise_Click',
  employeesNewClick = 'Employees_New_Click',
  employeesDetailView = 'Employees_Detail_View',
  employeesDetailDisableClick = 'Employees_Detail_Disable_Click',
  employeesDetailUnassignClick = 'Employees_Detail_Unassign_Click',
  employeesDetailAssignClick = 'Employees_Detail_Assign_Click',
  alertNewEmployeeView = 'Alert_New_Employee_View',
  alertEditEmployeeView = 'Alert_Edit_Employee_View',
  signupNewColaboratorView = 'Signup_New_Colaborator_View'
}

enum InventoryEvents {
  toolsView = 'Tools_View',
  toolsManagementClick = 'Tools_Management_Click',
  toolsUploadView = 'Tools_Upload_View',
  toolsUploadInformationView = 'Tools_Upload_Information_View',
  toolsConfirmInformationView = 'Tools_Confirm_Information_View',
  toolsUploadConfirmationView = 'Tools_Upload_Confirmation_View'
}

enum CheckoutEvents {
  storeFilterEquipment = 'Nodi_Store_Filter_EquipmentProfile_Click',
  storeFilterCountry = 'Nodi_Store_Filter_Country_Click',
  storeFilterBrand = 'Nodi_Store_Filter_Brand_Click',
  storeFilterProcessor = 'Nodi_Store_Filter_Processor_Click',
  storeFilterMemory = 'Nodi_Store_Filter_Memory_Click',
  storeFilterStorage = 'Nodi_Store_Filter_Storage_Click',
  storeFilterCategory = 'Nodi_Store_Category_Click',
  marketPlaceProductCardClick = 'Nodi_MarketPlace_ProductCard_Click',

  freeToolTipDiscountPrimeHover = 'Nodi_Free_toolTip_discountPrime_Hover',
  freeToolTipShipmentOrder = 'Nodi_Free_toolTip_shipmentOrder',
  freeToolTipDiscountTotalHover = 'Nodi_Free_discountTotal_Hover',
  freeCheckoutBackClick = 'Nodi_Free_Checkout_Back_Click',
  freeCheckoutStoreClick = 'Nodi_Free_Checkout_Store_Click',
  freeCatalogueProductView = 'Nodi_Free_Catalogue_product_View',
  freeProductBannerClick = 'Nodi_Free_Product_Banner_Click',
  freeProductKartClick = 'Nodi_Free_Catalogue_Product_Kart_Click',
  freeCheckout1KartView = 'Nodi_Free_Checkout_1_Kart_View',
  freeCheckoutQuoteClick = 'Nodi_Free_Checkout_Quote_Click',
  freeCheckoutKartClick = 'Nodi_Free_Checkout_Kart_Click',
  freeCheckout2ShippingView = 'Nodi_Free_Checkout_2_Shipping_View',
  freeCheckoutShippingClick = 'Nodi_Free_Checkout_Shipping_Click',
  freeCheckoutBuyAndHoldModalView = 'Nodi_Free_Checkout_Buy&Hold_Modal_View',
  free3CheckoutBillingAndPaymentView = 'Nodi_Free_3_Checkout_Billing&Payment_View',
  freeBillingAndPaymentClick = 'Nodi_Free_Billing&Payment_Click',
  free4CheckoutResumeView = 'Nodi_Free_4_Checkout_Resume_View',

  primeToolTipDiscountPrimeHover = 'Nodi_Prime_toolTip_discountPrime_Hover',
  primeToolTipShipmentOrder = 'Nodi_Prime_toolTip_shipmentOrder',
  primeDiscountTotalHover = 'Nodi_Prime_discountTotal_Hover',
  primeCheckoutBackClick = 'Nodi_Prime_Checkout_Back_Click',
  primeCheckoutStoreClick = 'Nodi_Prime_Checkout_Store_Click',
  primeCatalogueProductView = 'Nodi_Prime_Catalogue_product_View',
  primeProductBannerClick = 'Nodi_Prime_Product_Banner_Click',
  primeCatalogueProductKartClick = 'Nodi_Prime_Catalogue_Product_Kart_Click',
  primeCheckout1KartView = 'Nodi_Prime_Checkout_1_Kart_View',
  primeCheckoutQuoteClick = 'Nodi_Prime_Checkout_Quote_Click',
  primeCheckoutKartClick = 'Nodi_Prime_Checkout_Kart_Click',
  primeCheckout2ShippingView = 'Nodi_Prime_Checkout_2_Shipping_View',
  primeCheckoutShippingClick = 'Nodi_Prime_Checkout_Shipping_Click',
  prime3CheckoutBillingAndPaymentView = 'Nodi_Prime_3_Checkout_Billing&Payment_View',
  primeBillingAndPaymentClick = 'Nodi_Prime_Billing&Payment_Click',
  prime4CheckoutResumeView = 'Nodi_Prime_4_Checkout_Resume_View',

  generalOrderCompleted = 'Nodi_General_OrderCompleted'
}

enum ActiveOrdersEvents {
  nodiOrderEditClick = 'Nodi_Order_Edit_Click',
  nodiOrderEditModalAvailableView = 'Nodi_Order_Edit_Modal_Available_View',
  nodiOrderEditModalNotAvailableView = 'Nodi_Order_Edit_Modal_NotAvailable_View',
  nodiOrderEditModalNotAvailableClick = 'Nodi_Order_Edit_Modal_NotAvailable_Click',
  nodiOrderEditModalAvailableClick = 'Nodi_Order_Edit_Modal_Available_Click',
  nodiOrderInnerCardBannerSupportClick = 'Nodi_Order_InnerCard_Banner_Support_Click',
  nodiOrderEditModalNotAvailableSupportClick = 'Nodi_Order_Edit_Modal_NotAvailable_Support_Click'
}

enum LogisticsServiceReportEvents {
  logiticServiceEditClick = 'Nodi_LogiticService_Edit_Click',
  logisticServicesEditAutomatedModalView = 'Nodi_LogisticServices_Edit_Automated_Modal_View',
  logisticServicesEditAutomatedModalSaveClick = 'Nodi_LogisticServices_Edit_Automated_Modal_Save_Click',
  logisticServicesEditAutomatedModalCancelClick = 'Nodi_LogisticServices_Edit_Automated_Modal_Cancel_Click',
  logisticServicesEditManualModalView = 'Nodi_LogisticServices_Edit_Manual_Modal_View',
  logisticServicesEditManualModalSaveClick = 'Nodi_LogisticServices_Edit_Manual_Modal_Save_Click',
  logisticServicesSupportManualModalClick = 'Nodi_LogisticServices_Support_Manual_Modal_Click',
  logiticServiceEditSupportClick = 'Nodi_LogiticService_Edit_Support_Click',

  logisticServiceProblemReportInProcessView = 'Nodi_SL_ProblemReport_InProcess_View',
  logisticServiceProblemInProcessCloseClick = 'Nodi_SL_ProblemReport_InProcess_Close_Click',
  logisticServiceProblemInProcessSupportClick = 'Nodi_SL_ProblemReport_InProcess_Support_Click',
  logisticServiceProblemInProcessOpenClick = 'Nodi_SL_ProblemReport_InProcess_Open_Click',
  dashboardSLProblemReportInProcessOpenClick = 'Nodi_Dashboard_SL_ProblemReport_InProcess_Open_Click',

  logisticServiceProblemDelayedView = 'Nodi_SL_ProblemReport_Delayed_View',
  logisticServiceProblemDelayedCloseClick = 'Nodi_SL_ProblemReport_Delayed_Close_Click',
  logisticServiceProblemDelayedSupportClick = 'Nodi_SL_ProblemReport_Delayed_Support_Click',
  logisticServiceProblemDelayedOpenClick = 'Nodi_SL_ProblemReport_Delayed_Open_Click',
  dashboardSLProblemReportDelayedOpenClick = 'Nodi_Dashboard_SL_ProblemReport_Delayed_Open_Click'
}

enum EcommerceEvents {
  catalogueCountryView = 'Nodi_Catalogue_Country_View',
  catalogueView = 'Nodi_Catalogue_View',
  catalogueCountrySupportClick = 'Nodi_Catalogue_Country_Support_Click',
  catalogueExternalProductKartClick = 'Nodi_Catalogue_External_Product_Kart_Click',
  catalogueProductCardClick = 'Nodi_Catalogue_Product_Card_Click',
  catalogueProductView = 'Nodi_Catalogue_product_View',
  internalProductStoresClick = 'Nodi_Internal_Product_Stores_Click',
  internalProductKartClick = 'Nodi_Internal_Product_Kart_Click',
  catalogueChangeCountryView = 'Nodi_Catalogue_change_Country_View',
  catalogueChangeCountryNoClick = 'Nodi_Catalogue_change_Country_No_Click',
  catalogueChangeCountryYesClick = 'Nodi_Catalogue_change_Country_YES_Click',
  catalogueFreeProductBannerClick = 'Nodi_Catalogue_Free_Product_Banner_Click'
}

enum LogisticsServicesEvents {
  generalOffCompleted = 'Nodi_General_OffCompleted',
  generalOnCompleted = 'Nodi_General_OnCompleted'
}
export {
  InventoryEvents,
  RegisterEvents,
  OnboardingEvents,
  UnassignEvents,
  DashboardEvents,
  EmployeesEvents,
  PlatformPrimeAndFreeEvents,
  CheckoutEvents,
  ActiveOrdersEvents,
  LogisticsServiceReportEvents,
  SideBarEvents,
  EcommerceEvents,
  LogisticsServicesEvents
};
