import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BordButton } from '../../../../components/BordDesignSystem';
import { getDefaultLanguage } from '../../../../utils/getDefaultLanguage';
import { emailView, emailViewEn } from '../../../../assets/images/views/RequestLogistics';
import { TYPE_OF_LANGUAGES } from '../../../../constants';

import './ExampleEmailModal.scss';

interface IExampleEmailModal {
  closeModal?: () => void;
}

type LanguageType = keyof typeof TYPE_OF_LANGUAGES;

const ExampleEmailModal: FC<IExampleEmailModal> = ({ closeModal }: IExampleEmailModal) => {
  const { t } = useTranslation();
  const closeText = t('recurrentWords:close');

  const locale = getDefaultLanguage() as LanguageType;

  const renderEmailByLanguage: Record<LanguageType, JSX.Element> = {
    [TYPE_OF_LANGUAGES.esMX]: <img className="limitsEmailView" src={emailView} />,
    [TYPE_OF_LANGUAGES.enUS]: <img className="limitsEmailView" src={emailViewEn} />
  };

  return (
    <div id="exampleEmailModal">
      <div className="boxExampleModal">
        <div className="previewExample">{renderEmailByLanguage[locale]}</div>
        <div className="closeExampleButton">
          <div className="butonBaseClose">
            <BordButton
              customWidth="w-280"
              customHeight="42"
              customClassName="baseStyle"
              label={closeText}
              onClick={() => {
                if (closeModal) {
                  closeModal();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExampleEmailModal;
