import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={11} height={10} fill={props.fill} xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M5.24069 6.97154L2.27379 3.91591C2.13074 3.76859 2.13074 3.52962 2.27379 3.3823L2.61988 3.02587C2.76278 2.87871 2.99422 2.87855 3.13742 3.02524L5.49976 5.44695L7.86226 3.0254C8.00546 2.87855 8.2369 2.87887 8.37979 3.02603L8.72588 3.38245C8.86893 3.52977 8.86893 3.76875 8.72588 3.91607L5.75883 6.97154C5.61579 7.11886 5.38373 7.11886 5.24069 6.97154Z"
      fill={props?.fill || '#383838'}
    />
  </svg>
);

const ForwardRef = forwardRef(SvgComponent);
const SimpleArrow = memo(ForwardRef);
export default SimpleArrow;
