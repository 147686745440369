const SquareMozambique = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#151515" rx="2" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#249F58" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" opacity="0.35" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M0 4H22V5.14286H0V4ZM0 10.8571H22V12H0V10.8571Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 0V16L11 7.98244L0 0Z" clipRule="evenodd" />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M3.50053 9.14548L1.95478 10L2.24921 8.19036L1 6.90803L2.72765 6.64603L3.50053 5L4.27234 6.64714L6 6.90913L4.74869 8.19147L5.04522 10"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M2 6.42909L2.42926 6L5 8.57212L4.57316 9L2 6.4303V6.42909Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M5 6.42909L4.57212 6L2 8.57212L2.42909 9L4.99879 6.4303L5 6.42909Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMozambique;
