import { useTranslation } from 'react-i18next';
import SelectProductCard from '../SelectProductCard/SelectProductCard';
import { BordButton, BordOneToneIcon } from '../../../../../components/BordDesignSystem';
import GenericFilter from '../../../../../components/genericFilter';
import { ItoolEssentialData } from '../../../../../types/requestLogisticsModule';
import { DTOgetModuleFilters, IQueryTools } from '../../../../../types/DTO';
import { useEffect, useState } from 'react';
import { GenericLoader } from '../../../../Admin/dashboard/components';
import BordSearchBar from '../../../../../components/BordDesignSystem/BordSearchBar/BordSearchBar';
import BordEmptyState from '../../../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';

import './SelectToolsForAssignmentModal.scss';

interface IIOptions {
  value: number | string;
  name: string;
  isChecked: boolean;
}

const SelectToolsForAssignmentModalCustomComponent = ({
  toolsSelectionCallback,
  toolsAvailableToAssignAccordingToAppliedFilters,
  selectedToolsToAssign,
  closeModalCallback,
  filtersData,
  handleChangeExtraQueryData,
  selectLocationTool,
  refreshLoading,
  loadingFilters
}: {
  toolsSelectionCallback: (newToolsToAssign: ItoolEssentialData[]) => void;
  toolsAvailableToAssignAccordingToAppliedFilters?: ItoolEssentialData[];
  selectedToolsToAssign: ItoolEssentialData[];
  closeModalCallback: () => void;
  filtersData?: DTOgetModuleFilters;
  handleChangeExtraQueryData?: (query: IQueryTools) => void;
  selectLocationTool?: ItoolEssentialData;
  refreshLoading?: boolean;
  loadingFilters?: boolean;
}) => {
  const { t } = useTranslation();

  const [hasActiveFilters, setHasActiveFilters] = useState(false);
  const [toolStatusFilterOptions, setToolStatusFilterOptions] = useState<IIOptions[]>([]);
  const [openToolStatusFilter, setOpenToolStatusFilter] = useState<boolean>(false);

  const [filterTypeOptions, setFilterTypeOptions] = useState<IIOptions[]>([]);
  const [openTypeFilter, setOpenTypeFilter] = useState<boolean>(false);

  const [filterCountryOptions, setFilterCountryOptions] = useState<IIOptions[]>([]);
  const [openCountryFilter, setOpenCountryFilter] = useState<boolean>(false);

  const [filterLocationsOptions, setFilterLocationsOptions] = useState<IIOptions[]>([]);
  const [openLocationsFilters, setOpenLocationsFilters] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>();

  const translationKey = 'services:logistics:toolAssignmentModal:';
  const placeHolder = t(`${translationKey}placeHolder`);
  const filterOptionTwo = t(`${translationKey}filterOptions:optionTwo`);
  const filterOptionThree = t(`${translationKey}filterOptions:optionThree`);
  const filterOptionFour = t(`${translationKey}filterOptions:optionFour`);
  const alertMessageDisable = t(`${translationKey}alertMessageDisable`);
  const notFoundText = t('recurrentWords:noSearchOption');

  const updateTools = (tool: ItoolEssentialData) => {
    const searchProductId = selectedToolsToAssign.find(element => element.productId === tool.productId);
    if (!searchProductId) {
      const actualToolsList = selectedToolsToAssign || [];
      const newToolsList: ItoolEssentialData[] = [...actualToolsList, tool];
      toolsSelectionCallback(newToolsList);
    }
  };

  const updateDeleteTools = (tool: ItoolEssentialData) => {
    const filterToolsList = selectedToolsToAssign.filter(element => element.productId !== tool.productId);
    toolsSelectionCallback(filterToolsList);
  };

  const checkSelectTool = (tool: ItoolEssentialData) => {
    if (selectedToolsToAssign && selectedToolsToAssign.length > 0) {
      const searchSelectProduct = selectedToolsToAssign.find(element => element.productId === tool.productId);
      if (searchSelectProduct) return true;
      return false;
    }
    return false;
  };

  const formatGenericList = (items: string[]) => {
    const genericList: IIOptions[] = [];
    for (const item of items) {
      const newItem = {
        value: item,
        name: item,
        isChecked: false
      };
      genericList.push(newItem);
    }
    return genericList;
  };

  const checkIfHasActiveFilters = () => {
    const hasStatusFilters = toolStatusFilterOptions.some(filter => filter.isChecked);
    const hasTypeFilters = filterTypeOptions.some(filter => filter.isChecked);
    const hasCountryFilters = filterCountryOptions.some(filter => filter.isChecked);
    const hasLocationFilters = filterLocationsOptions.some(filter => filter.isChecked);
    setHasActiveFilters(hasStatusFilters || hasTypeFilters || hasCountryFilters || hasLocationFilters);
  };

  const getWipingFilters = () => {
    const wipingFilterOptions = [
      { value: 'with', name: 'Con Wiping', isChecked: false },
      { value: 'without', name: 'Sin Wiping', isChecked: false }
    ];
    setToolStatusFilterOptions(wipingFilterOptions);
  };

  const resetIndividualFilter = (
    filterOptions: IIOptions[],
    setFilter: React.Dispatch<React.SetStateAction<IIOptions[]>>
  ) => {
    const resetedFilter = filterOptions.map(filter => {
      return { ...filter, isChecked: false };
    });
    setFilter(resetedFilter);
  };

  const resetAllFilters = () => {
    resetIndividualFilter(toolStatusFilterOptions, setToolStatusFilterOptions);
    resetIndividualFilter(filterTypeOptions, setFilterTypeOptions);
    resetIndividualFilter(filterCountryOptions, setFilterCountryOptions);
    resetIndividualFilter(filterLocationsOptions, setFilterLocationsOptions);
  };

  const getOptionFilters = () => {
    if (filtersData) {
      if (filtersData?.Tipo_de_producto) {
        const typeFilterList = formatGenericList(filtersData?.Tipo_de_producto);
        setFilterTypeOptions([...typeFilterList]);
      }
      if (filtersData?.País) {
        const countriesFilterList = formatGenericList(filtersData?.País);
        setFilterCountryOptions([...countriesFilterList]);
      }
      if (filtersData?.Ubicación) {
        const locationsFilterList = formatGenericList(filtersData?.Ubicación);
        setFilterLocationsOptions([...locationsFilterList]);
      }
    }
  };

  const simpleFilterFormat = (filters: IIOptions[]) => {
    if (filters && filters.length) {
      const filtersList: string[] = [];
      for (let i = 0; i < filters.length; i++) {
        const myFilter = filters[i].value;
        filtersList.push(String(`"${myFilter}"`));
      }
      const myStringList = filtersList.toString();
      return myStringList;
    }
    return '';
  };

  const filtersActiveStyle = hasActiveFilters ? 'active' : '';

  const filtersButtonAction = () => !selectLocationTool && resetAllFilters();

  const handleQuery = () => {
    if (handleChangeExtraQueryData) {
      const filterTypeCheck = filterTypeOptions.filter(element => element.isChecked);
      const filterCountryCheck = filterCountryOptions.filter(element => element.isChecked);
      const filterLocationCheck = filterLocationsOptions.filter(element => element.isChecked);
      const filterStatusCheck = toolStatusFilterOptions.filter(filter => filter.isChecked);

      const categoryQuery =
        filterTypeCheck?.length > 0
          ? `{"name":"productCategory","includes":"in","values":[${simpleFilterFormat(filterTypeCheck)}]}`
          : '';
      const countryQuery =
        filterCountryCheck?.length > 0
          ? `{"name":"country","includes":"in","values":[${simpleFilterFormat(filterCountryCheck)}]}`
          : '';
      const locationQuery =
        filterLocationCheck?.length > 0
          ? `{"name":"location","includes":"in","values":[${simpleFilterFormat(filterLocationCheck)}]}`
          : '';
      const statusQuery =
        filterStatusCheck.length > 0
          ? `{"name":"wiping","includes":"in","values":[${simpleFilterFormat(filterStatusCheck)}]}`
          : '';

      const queryBase = [categoryQuery, countryQuery, locationQuery, statusQuery].filter(query => !!query).join(',');
      handleChangeExtraQueryData({ extraQuery: queryBase || '', stringQuery: searchString || '' });
    }
  };

  const filterTools = (tools: ItoolEssentialData[]) => {
    if (tools && tools.length) {
      if (selectLocationTool) {
        const newToolsList = tools.filter(element => element.locationId === selectLocationTool.locationId);
        return newToolsList;
      } else {
        return tools;
      }
    }
    return [];
  };

  const generateLocation = () => {
    if (selectLocationTool) {
      if (selectLocationTool?.place === 'office') {
        return `Oficinas ${selectLocationTool?.city}`;
      }
      if (selectLocationTool?.place === 'nudos') {
        return `${t('recurrentWords:warehouse')} ${selectLocationTool?.city}`;
      }
      return selectLocationTool?.city || selectLocationTool?.country?.name || '';
    }
    return '';
  };

  useEffect(() => {
    getOptionFilters();
  }, []);

  useEffect(() => {
    if (filtersData) {
      getWipingFilters();
    }
  }, [filtersData]);

  useEffect(() => {
    handleQuery();
    checkIfHasActiveFilters();
  }, [searchString, filterTypeOptions, filterCountryOptions, filterLocationsOptions, toolStatusFilterOptions]);

  return (
    <div className="SelectToolsForAssignmentModalCustomComponent">
      <div id="modalSelectTools">
        <div className="containerModalElements">
          <div className="titleModalTools">{t(`${translationKey}title`)}</div>
          <div className="blueLineContainer">
            <div className="blueLine" />
          </div>
          <div className="containerSearchBarModalTools">
            <div className="containerSearchBarLimit">
              <BordSearchBar
                handleChange={e => {
                  setSearchString(e);
                }}
                customInputClass="sizeContainerText"
                componentSize="w-380"
                placeholder={placeHolder}
              />
            </div>

            <div className="boxSelectFilter">
              <div
                className={`selectToolsFiltersButton ${filtersActiveStyle}`}
                onClick={filtersButtonAction}
                style={selectLocationTool ? { opacity: '0.2', cursor: 'not-allowed' } : undefined}
              >
                <BordOneToneIcon
                  className="filterIcon"
                  variant={hasActiveFilters ? 'trash2' : 'filterMailCircle'}
                  standardSize={12}
                />
              </div>
              <div className="toolStatusFilter">
                {toolStatusFilterOptions && (
                  <GenericFilter
                    filterName="Wiping"
                    openFilter={openToolStatusFilter}
                    setOpenFilter={setOpenToolStatusFilter}
                    optionsList={toolStatusFilterOptions}
                    setNewList={setToolStatusFilterOptions}
                    positionList={10}
                    disabledFilter={!!selectLocationTool || loadingFilters}
                  />
                )}
              </div>
              <div className="typefilter">
                <GenericFilter
                  filterName={filterOptionTwo}
                  openFilter={openTypeFilter}
                  setOpenFilter={setOpenTypeFilter}
                  optionsList={filterTypeOptions}
                  setNewList={setFilterTypeOptions}
                  positionList={10}
                  disabledFilter={!!selectLocationTool || loadingFilters}
                />
              </div>
              <div className="countyFilter">
                <GenericFilter
                  filterName={filterOptionThree}
                  openFilter={openCountryFilter}
                  setOpenFilter={setOpenCountryFilter}
                  optionsList={filterCountryOptions}
                  setNewList={setFilterCountryOptions}
                  positionList={10}
                  disabledFilter={!!selectLocationTool || loadingFilters}
                />
              </div>
              <div className="locationFilter">
                <GenericFilter
                  filterName={filterOptionFour}
                  openFilter={openLocationsFilters}
                  setOpenFilter={setOpenLocationsFilters}
                  optionsList={filterLocationsOptions}
                  setNewList={setFilterLocationsOptions}
                  positionList={10}
                  disabledFilter={!!selectLocationTool || loadingFilters}
                />
              </div>
            </div>
          </div>
          <div className="alertModalSelectTools">
            <div className="boxSuperioAlert">
              <div className="containerClockIcon">
                <BordOneToneIcon variant="info" customWidth="14" />
              </div>
              <div className="textBlueALert">
                {selectLocationTool
                  ? t(`${translationKey}alertMessageActive`, {
                      value: `${generateLocation()}`
                    })
                  : alertMessageDisable}{' '}
              </div>
            </div>
          </div>
          <div className="bodyModalSelectTools">
            {refreshLoading || loadingFilters ? (
              <div className="h-full w-full">
                <GenericLoader sizeLoader={40} borderWidth={5} />
              </div>
            ) : (
              <div className="boxSelectTools">
                {filterTools(toolsAvailableToAssignAccordingToAppliedFilters || []) &&
                  filterTools(toolsAvailableToAssignAccordingToAppliedFilters || []).length > 0 &&
                  filterTools(toolsAvailableToAssignAccordingToAppliedFilters || []).map((item, index) => {
                    if (item?.locationId) {
                      return (
                        <div className="containerSelectProduct" key={`select-product-${item}-${index}`}>
                          <SelectProductCard
                            toolData={item}
                            actionSelect={() => updateTools(item)}
                            actionDeselected={() => updateDeleteTools(item)}
                            isSelected={checkSelectTool(item)}
                          />
                        </div>
                      );
                    }
                  })}
                {filterTools(toolsAvailableToAssignAccordingToAppliedFilters || []).length === 0 && (
                  <div className="noResultsFound">
                    <BordEmptyState
                      title={notFoundText}
                      subTitle="Intenta probando con otros términos o adquiere nuevas herramientas en .bord Store"
                      customClassNameSubTitle="customClassNameSubTitle"
                      ilustrationIconProps={{
                        variant: 'search',
                        customWidth: '10rem'
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="floatButtonModalSelectTools">
            <BordButton
              customWidth="w-380"
              customHeight="42"
              disabled={selectedToolsToAssign && selectedToolsToAssign?.length === 0}
              label="Seleccionar herramientas"
              onClick={() => closeModalCallback()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectToolsForAssignmentModalCustomComponent;
