import { useTranslation } from 'react-i18next';
import BordStepper from '../../../../../components/BordDesignSystem/BordStepper/BordStepper';
import './AssignmentModuleStepper.scss';

/**
 * @property  { number } currentStep - The current step of the checkout
 */
const AssignmentModuleStepper = ({
  currentStep,
  requireLogistics
}: {
  currentStep: number;
  requireLogistics: boolean;
  includeQuoteAndBillingStep: boolean;
}) => {
  const { t } = useTranslation();

  const orderZero = t('recurrentWords:devices');
  const addressesText = t('recurrentWords:addresses');
  const datesText = t('recurrentWords:dates');
  const emailText = t('recurrentWords:email');

  const stepOrderNoLs: number = requireLogistics ? 3 : 1;

  const progressSteps = [
    {
      order: 0,
      showStep: true,
      stepContent: <div>{orderZero}</div>,
      title: 'Paso 1',
      subTitle: orderZero,
      customStepStyles: undefined
    },
    {
      order: 1,
      showStep: requireLogistics,
      stepContent: <div>{addressesText}</div>,
      title: 'Paso 2',
      subTitle: addressesText,
      customStepStyles: undefined
    },
    {
      order: 2,
      showStep: requireLogistics,
      stepContent: <div>{datesText}</div>,
      title: 'Paso 3',
      subTitle: datesText,
      customStepStyles: undefined
    },
    {
      order: stepOrderNoLs,
      showStep: true,
      stepContent: <div>{emailText}</div>,
      title: `Paso ${stepOrderNoLs + 1}`,
      subTitle: emailText,
      customStepStyles: undefined
    },
    {
      order: 4,
      showStep: requireLogistics,
      stepContent: <div>Facturación</div>,
      title: `Paso ${5}`,
      subTitle: 'Facturación',
      customStepStyles: undefined
    },
    {
      order: 2,
      showStep: !requireLogistics,
      title: 'Paso 3',
      subTitle: 'Resumen',
      customStepStyles: undefined
    }
  ];

  const definitiveSteps = progressSteps.filter(step => step?.showStep);

  return (
    <div className="AssignmentModuleStepper">
      <BordStepper
        steps={definitiveSteps}
        currentStep={currentStep}
        titleStepperCustomClass="titleStepperCustomClass"
        subTitleCustomClass="subTitleCustomClass"
        stepperConectorWidth="w-170"
        isStepContent={false}
      />
    </div>
  );
};

export default AssignmentModuleStepper;
