const RoundIvoryCoast = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16.0001 32C17.9573 32 19.8321 31.648 21.5653 31.0048L22.261 16L21.5654 0.995188C19.8321 0.352063 17.9573 0 16.0001 0C14.043 0 12.1682 0.352063 10.4349 0.995188L9.73926 16L10.4349 31.0048C12.1682 31.648 14.043 32 16.0001 32Z"
        />
        <path
          fill="#FF9811"
          d="M0 15.9999C0 22.8794 4.342 28.7441 10.4348 31.0047V0.995117C4.342 3.2558 0 9.12049 0 15.9999Z"
        />
        <path
          fill="#6DA544"
          d="M21.5654 0.995117V31.0048C27.6582 28.7441 32.0002 22.8794 32.0002 15.9999C32.0002 9.12049 27.6582 3.2558 21.5654 0.995117Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIvoryCoast;
