import { ComponentProps } from 'react';

const BordWarehousesImage = ({ backgroundPathProps }: { backgroundPathProps?: ComponentProps<'path'> }) => {
  const { fill: backgroundShapeFill } = backgroundPathProps || {};

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 536 496">
      <g>
        <g opacity="0.2">
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0715 0H12.7635C9.67862 0 6.85015 1.11629 4.64377 2.97433C1.80803 5.36403 0 8.98145 0 13.0282C0 16.1761 1.09319 19.0627 2.91577 21.3154C3.43599 21.9585 4.01623 22.5505 4.64559 23.0821C6.85197 24.9402 9.68044 26.0564 12.7654 26.0564C16.7307 26.0564 20.274 24.2112 22.615 21.3154C24.4357 19.0627 25.5307 16.1761 25.5307 13.0282V2.50845C25.5307 1.1236 24.4303 0 23.0733 0H23.0715Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0715 277H12.7635C9.67862 277 6.85015 278.116 4.64377 279.974C1.80803 282.364 0 285.981 0 290.028C0 293.176 1.09319 296.063 2.91577 298.315C3.43599 298.959 4.01623 299.55 4.64559 300.082C6.85197 301.94 9.68044 303.056 12.7654 303.056C16.7307 303.056 20.274 301.211 22.615 298.315C24.4357 296.063 25.5307 293.176 25.5307 290.028V279.508C25.5307 278.124 24.4303 277 23.0733 277H23.0715Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.282 0H290.974C287.89 0 285.061 1.11629 282.855 2.97433C280.019 5.36403 278.211 8.98145 278.211 13.0282C278.211 16.1761 279.304 19.0627 281.127 21.3154C281.647 21.9585 282.227 22.5505 282.857 23.0821C285.063 24.9402 287.891 26.0564 290.976 26.0564C294.942 26.0564 298.485 24.2112 300.826 21.3154C302.647 19.0627 303.742 16.1761 303.742 13.0282V2.50845C303.742 1.1236 302.641 0 301.284 0H301.282Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.282 277H290.974C287.89 277 285.061 278.116 282.855 279.974C280.019 282.364 278.211 285.981 278.211 290.028C278.211 293.176 279.304 296.063 281.127 298.315C281.647 298.959 282.227 299.55 282.857 300.082C285.063 301.94 287.891 303.056 290.976 303.056C294.942 303.056 298.485 301.211 300.826 298.315C302.647 296.063 303.742 293.176 303.742 290.028V279.508C303.742 278.124 302.641 277 301.284 277H301.282Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 0H40.5847C37.4998 0 34.6713 1.11629 32.4649 2.97433C29.6274 5.36402 27.8193 8.97963 27.8193 13.0264C27.8193 16.1743 28.9125 19.0609 30.7351 21.3136C31.2553 21.9567 31.8356 22.5486 32.4649 23.0803C34.6713 24.9383 37.4998 26.0546 40.5847 26.0546C44.55 26.0546 48.0933 24.2094 50.4343 21.3136C52.2551 19.0609 53.3501 16.1743 53.3501 13.0264V2.50845C53.3501 1.12359 52.2496 0 50.8927 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 277H40.5847C37.4998 277 34.6713 278.116 32.4649 279.974C29.6274 282.364 27.8193 285.98 27.8193 290.026C27.8193 293.174 28.9125 296.061 30.7351 298.314C31.2553 298.957 31.8356 299.549 32.4649 300.08C34.6713 301.938 37.4998 303.055 40.5847 303.055C44.55 303.055 48.0933 301.209 50.4343 298.314C52.2551 296.061 53.3501 293.174 53.3501 290.026V279.508C53.3501 278.124 52.2496 277 50.8927 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 0H318.796C315.711 0 312.882 1.11629 310.676 2.97433C307.838 5.36402 306.03 8.97963 306.03 13.0264C306.03 16.1743 307.123 19.0609 308.946 21.3136C309.466 21.9567 310.047 22.5486 310.676 23.0803C312.882 24.9383 315.711 26.0546 318.796 26.0546C322.761 26.0546 326.304 24.2094 328.645 21.3136C330.466 19.0609 331.561 16.1743 331.561 13.0264V2.50845C331.561 1.12359 330.461 0 329.104 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 277H318.796C315.711 277 312.882 278.116 310.676 279.974C307.838 282.364 306.03 285.98 306.03 290.026C306.03 293.174 307.123 296.061 308.946 298.314C309.466 298.957 310.047 299.549 310.676 300.08C312.882 301.938 315.711 303.055 318.796 303.055C322.761 303.055 326.304 301.209 328.645 298.314C330.466 296.061 331.561 293.174 331.561 290.026V279.508C331.561 278.124 330.461 277 329.104 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 0H68.405C65.3201 0 62.4916 1.11629 60.2852 2.97433C57.4477 5.36402 55.6396 8.97963 55.6396 13.0264C55.6396 16.1743 56.7328 19.0609 58.5554 21.3136C59.0756 21.9567 59.6559 22.5486 60.2852 23.0803C62.4916 24.9383 65.3201 26.0546 68.405 26.0546C72.3703 26.0546 75.9136 24.2094 78.2546 21.3136C80.0754 19.0609 81.1704 16.1743 81.1704 13.0264V2.50845C81.1704 1.12359 80.0699 0 78.713 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 277H68.405C65.3201 277 62.4916 278.116 60.2852 279.974C57.4477 282.364 55.6396 285.98 55.6396 290.026C55.6396 293.174 56.7328 296.061 58.5554 298.314C59.0756 298.957 59.6559 299.549 60.2852 300.08C62.4916 301.938 65.3201 303.055 68.405 303.055C72.3703 303.055 75.9136 301.209 78.2546 298.314C80.0754 296.061 81.1704 293.174 81.1704 290.026V279.508C81.1704 278.124 80.0699 277 78.713 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 0H346.617C343.532 0 340.704 1.11629 338.497 2.97433C335.66 5.36402 333.852 8.97963 333.852 13.0264C333.852 16.1743 334.945 19.0609 336.767 21.3136C337.288 21.9567 337.868 22.5486 338.497 23.0803C340.704 24.9383 343.532 26.0546 346.617 26.0546C350.582 26.0546 354.126 24.2094 356.467 21.3136C358.287 19.0609 359.382 16.1743 359.382 13.0264V2.50845C359.382 1.12359 358.282 0 356.925 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 277H346.617C343.532 277 340.704 278.116 338.497 279.974C335.66 282.364 333.852 285.98 333.852 290.026C333.852 293.174 334.945 296.061 336.767 298.314C337.288 298.957 337.868 299.549 338.497 300.08C340.704 301.938 343.532 303.055 346.617 303.055C350.582 303.055 354.126 301.209 356.467 298.314C358.287 296.061 359.382 293.174 359.382 290.026V279.508C359.382 278.124 358.282 277 356.925 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 0H96.2244C93.1394 0 90.311 1.11629 88.1046 2.97433C85.267 5.36402 83.459 8.97963 83.459 13.0264C83.459 16.1743 84.5522 19.0609 86.3748 21.3136C86.895 21.9567 87.4752 22.5486 88.1046 23.0803C90.311 24.9383 93.1394 26.0546 96.2244 26.0546C100.19 26.0546 103.733 24.2094 106.074 21.3136C107.895 19.0609 108.99 16.1743 108.99 13.0264V2.50845C108.99 1.12359 107.889 0 106.532 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 277H96.2244C93.1394 277 90.311 278.116 88.1046 279.974C85.267 282.364 83.459 285.98 83.459 290.026C83.459 293.174 84.5522 296.061 86.3748 298.314C86.895 298.957 87.4752 299.549 88.1046 300.08C90.311 301.938 93.1394 303.055 96.2244 303.055C100.19 303.055 103.733 301.209 106.074 298.314C107.895 296.061 108.99 293.174 108.99 290.026V279.508C108.99 278.124 107.889 277 106.532 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 0H374.435C371.35 0 368.522 1.11629 366.316 2.97433C363.478 5.36402 361.67 8.97963 361.67 13.0264C361.67 16.1743 362.763 19.0609 364.586 21.3136C365.106 21.9567 365.686 22.5486 366.316 23.0803C368.522 24.9383 371.35 26.0546 374.435 26.0546C378.401 26.0546 381.944 24.2094 384.285 21.3136C386.106 19.0609 387.201 16.1743 387.201 13.0264V2.50845C387.201 1.12359 386.1 0 384.743 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 277H374.435C371.35 277 368.522 278.116 366.316 279.974C363.478 282.364 361.67 285.98 361.67 290.026C361.67 293.174 362.763 296.061 364.586 298.314C365.106 298.957 365.686 299.549 366.316 300.08C368.522 301.938 371.35 303.055 374.435 303.055C378.401 303.055 381.944 301.209 384.285 298.314C386.106 296.061 387.201 293.174 387.201 290.026V279.508C387.201 278.124 386.1 277 384.743 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 0H124.045C120.96 0 118.131 1.11629 115.925 2.97433C113.087 5.36402 111.279 8.97963 111.279 13.0264C111.279 16.1743 112.372 19.0609 114.195 21.3136C114.715 21.9567 115.296 22.5486 115.925 23.0803C118.131 24.9383 120.96 26.0546 124.045 26.0546C128.01 26.0546 131.553 24.2094 133.894 21.3136C135.715 19.0609 136.81 16.1743 136.81 13.0264V2.50845C136.81 1.12359 135.71 0 134.353 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 277H124.045C120.96 277 118.131 278.116 115.925 279.974C113.087 282.364 111.279 285.98 111.279 290.026C111.279 293.174 112.372 296.061 114.195 298.314C114.715 298.957 115.296 299.549 115.925 300.08C118.131 301.938 120.96 303.055 124.045 303.055C128.01 303.055 131.553 301.209 133.894 298.314C135.715 296.061 136.81 293.174 136.81 290.026V279.508C136.81 278.124 135.71 277 134.353 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 0H402.257C399.172 0 396.343 1.11629 394.137 2.97433C391.299 5.36402 389.491 8.97963 389.491 13.0264C389.491 16.1743 390.584 19.0609 392.407 21.3136C392.927 21.9567 393.507 22.5486 394.137 23.0803C396.343 24.9383 399.172 26.0546 402.257 26.0546C406.222 26.0546 409.765 24.2094 412.106 21.3136C413.927 19.0609 415.022 16.1743 415.022 13.0264V2.50845C415.022 1.12359 413.921 0 412.565 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 277H402.257C399.172 277 396.343 278.116 394.137 279.974C391.299 282.364 389.491 285.98 389.491 290.026C389.491 293.174 390.584 296.061 392.407 298.314C392.927 298.957 393.507 299.549 394.137 300.08C396.343 301.938 399.172 303.055 402.257 303.055C406.222 303.055 409.765 301.209 412.106 298.314C413.927 296.061 415.022 293.174 415.022 290.026V279.508C415.022 278.124 413.921 277 412.565 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 0H151.866C148.781 0 145.953 1.11629 143.746 2.97433C140.909 5.36402 139.101 8.97963 139.101 13.0264C139.101 16.1743 140.194 19.0609 142.016 21.3136C142.537 21.9567 143.117 22.5486 143.746 23.0803C145.953 24.9383 148.781 26.0546 151.866 26.0546C155.831 26.0546 159.375 24.2094 161.716 21.3136C163.536 19.0609 164.631 16.1743 164.631 13.0264V2.50845C164.631 1.12359 163.531 0 162.174 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 277H151.866C148.781 277 145.953 278.116 143.746 279.974C140.909 282.364 139.101 285.98 139.101 290.026C139.101 293.174 140.194 296.061 142.016 298.314C142.537 298.957 143.117 299.549 143.746 300.08C145.953 301.938 148.781 303.055 151.866 303.055C155.831 303.055 159.375 301.209 161.716 298.314C163.536 296.061 164.631 293.174 164.631 290.026V279.508C164.631 278.124 163.531 277 162.174 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 0H430.077C426.992 0 424.164 1.11629 421.957 2.97433C419.12 5.36402 417.312 8.97963 417.312 13.0264C417.312 16.1743 418.405 19.0609 420.227 21.3136C420.748 21.9567 421.328 22.5486 421.957 23.0803C424.164 24.9383 426.992 26.0546 430.077 26.0546C434.042 26.0546 437.586 24.2094 439.926 21.3136C441.747 19.0609 442.842 16.1743 442.842 13.0264V2.50845C442.842 1.12359 441.742 0 440.385 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 277H430.077C426.992 277 424.164 278.116 421.957 279.974C419.12 282.364 417.312 285.98 417.312 290.026C417.312 293.174 418.405 296.061 420.227 298.314C420.748 298.957 421.328 299.549 421.957 300.08C424.164 301.938 426.992 303.055 430.077 303.055C434.042 303.055 437.586 301.209 439.926 298.314C441.747 296.061 442.842 293.174 442.842 290.026V279.508C442.842 278.124 441.742 277 440.385 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 0H179.684C176.599 0 173.771 1.11629 171.565 2.97433C168.727 5.36402 166.919 8.97963 166.919 13.0264C166.919 16.1743 168.012 19.0609 169.835 21.3136C170.355 21.9567 170.935 22.5486 171.565 23.0803C173.771 24.9383 176.599 26.0546 179.684 26.0546C183.65 26.0546 187.193 24.2094 189.534 21.3136C191.355 19.0609 192.45 16.1743 192.45 13.0264V2.50845C192.45 1.12359 191.349 0 189.992 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 277H179.684C176.599 277 173.771 278.116 171.565 279.974C168.727 282.364 166.919 285.98 166.919 290.026C166.919 293.174 168.012 296.061 169.835 298.314C170.355 298.957 170.935 299.549 171.565 300.08C173.771 301.938 176.599 303.055 179.684 303.055C183.65 303.055 187.193 301.209 189.534 298.314C191.355 296.061 192.45 293.174 192.45 290.026V279.508C192.45 278.124 191.349 277 189.992 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 0H457.896C454.811 0 451.983 1.11629 449.776 2.97433C446.939 5.36402 445.131 8.97963 445.131 13.0264C445.131 16.1743 446.224 19.0609 448.047 21.3136C448.567 21.9567 449.147 22.5486 449.776 23.0803C451.983 24.9383 454.811 26.0546 457.896 26.0546C461.862 26.0546 465.405 24.2094 467.746 21.3136C469.567 19.0609 470.662 16.1743 470.662 13.0264V2.50845C470.662 1.12359 469.561 0 468.204 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 277H457.896C454.811 277 451.983 278.116 449.776 279.974C446.939 282.364 445.131 285.98 445.131 290.026C445.131 293.174 446.224 296.061 448.047 298.314C448.567 298.957 449.147 299.549 449.776 300.08C451.983 301.938 454.811 303.055 457.896 303.055C461.862 303.055 465.405 301.209 467.746 298.314C469.567 296.061 470.662 293.174 470.662 290.026V279.508C470.662 278.124 469.561 277 468.204 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 0H207.506C204.421 0 201.592 1.11629 199.386 2.97433C196.548 5.36402 194.74 8.97963 194.74 13.0264C194.74 16.1743 195.833 19.0609 197.656 21.3136C198.176 21.9567 198.756 22.5486 199.386 23.0803C201.592 24.9383 204.421 26.0546 207.506 26.0546C211.471 26.0546 215.014 24.2094 217.355 21.3136C219.176 19.0609 220.271 16.1743 220.271 13.0264V2.50845C220.271 1.12359 219.171 0 217.814 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 277H207.506C204.421 277 201.592 278.116 199.386 279.974C196.548 282.364 194.74 285.98 194.74 290.026C194.74 293.174 195.833 296.061 197.656 298.314C198.176 298.957 198.756 299.549 199.386 300.08C201.592 301.938 204.421 303.055 207.506 303.055C211.471 303.055 215.014 301.209 217.355 298.314C219.176 296.061 220.271 293.174 220.271 290.026V279.508C220.271 278.124 219.171 277 217.814 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 0H485.717C482.632 0 479.803 1.11629 477.597 2.97433C474.759 5.36402 472.951 8.97963 472.951 13.0264C472.951 16.1743 474.044 19.0609 475.867 21.3136C476.387 21.9567 476.967 22.5486 477.597 23.0803C479.803 24.9383 482.632 26.0546 485.717 26.0546C489.682 26.0546 493.225 24.2094 495.566 21.3136C497.387 19.0609 498.482 16.1743 498.482 13.0264V2.50845C498.482 1.12359 497.381 0 496.025 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 277H485.717C482.632 277 479.803 278.116 477.597 279.974C474.759 282.364 472.951 285.98 472.951 290.026C472.951 293.174 474.044 296.061 475.867 298.314C476.387 298.957 476.967 299.549 477.597 300.08C479.803 301.938 482.632 303.055 485.717 303.055C489.682 303.055 493.225 301.209 495.566 298.314C497.387 296.061 498.482 293.174 498.482 290.026V279.508C498.482 278.124 497.381 277 496.025 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 0H235.326C232.241 0 229.413 1.11629 227.206 2.97433C224.369 5.36402 222.561 8.97963 222.561 13.0264C222.561 16.1743 223.654 19.0609 225.476 21.3136C225.997 21.9567 226.577 22.5486 227.206 23.0803C229.413 24.9383 232.241 26.0546 235.326 26.0546C239.291 26.0546 242.835 24.2094 245.176 21.3136C246.996 19.0609 248.091 16.1743 248.091 13.0264V2.50845C248.091 1.12359 246.991 0 245.634 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 277H235.326C232.241 277 229.413 278.116 227.206 279.974C224.369 282.364 222.561 285.98 222.561 290.026C222.561 293.174 223.654 296.061 225.476 298.314C225.997 298.957 226.577 299.549 227.206 300.08C229.413 301.938 232.241 303.055 235.326 303.055C239.291 303.055 242.835 301.209 245.176 298.314C246.996 296.061 248.091 293.174 248.091 290.026V279.508C248.091 278.124 246.991 277 245.634 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 0H513.538C510.453 0 507.624 1.11629 505.418 2.97433C502.581 5.36402 500.772 8.97963 500.772 13.0264C500.772 16.1743 501.866 19.0609 503.688 21.3136C504.208 21.9567 504.789 22.5486 505.418 23.0803C507.624 24.9383 510.453 26.0546 513.538 26.0546C517.503 26.0546 521.046 24.2094 523.387 21.3136C525.208 19.0609 526.303 16.1743 526.303 13.0264V2.50845C526.303 1.12359 525.203 0 523.846 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 277H513.538C510.453 277 507.624 278.116 505.418 279.974C502.581 282.364 500.772 285.98 500.772 290.026C500.772 293.174 501.866 296.061 503.688 298.314C504.208 298.957 504.789 299.549 505.418 300.08C507.624 301.938 510.453 303.055 513.538 303.055C517.503 303.055 521.046 301.209 523.387 298.314C525.208 296.061 526.303 293.174 526.303 290.026V279.508C526.303 278.124 525.203 277 523.846 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 0H263.147C260.062 0 257.234 1.11629 255.027 2.97433C252.19 5.36402 250.382 8.97963 250.382 13.0264C250.382 16.1743 251.475 19.0609 253.298 21.3136C253.818 21.9567 254.398 22.5486 255.027 23.0803C257.234 24.9383 260.062 26.0546 263.147 26.0546C267.112 26.0546 270.656 24.2094 272.997 21.3136C274.818 19.0609 275.913 16.1743 275.913 13.0264V2.50845C275.913 1.12359 274.812 0 273.455 0Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 277H263.147C260.062 277 257.234 278.116 255.027 279.974C252.19 282.364 250.382 285.98 250.382 290.026C250.382 293.174 251.475 296.061 253.298 298.314C253.818 298.957 254.398 299.549 255.027 300.08C257.234 301.938 260.062 303.055 263.147 303.055C267.112 303.055 270.656 301.209 272.997 298.314C274.818 296.061 275.913 293.174 275.913 290.026V279.508C275.913 278.124 274.812 277 273.455 277Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0715 27.8846H12.7635C9.67862 27.8846 6.85015 29.0009 4.64377 30.859C1.80803 33.2487 0 36.8643 0 40.911C0 44.0589 1.09319 46.9456 2.91577 49.1982C3.43599 49.8413 4.01623 50.4333 4.64559 50.9649C6.85197 52.823 9.68044 53.9393 12.7654 53.9393C16.7307 53.9393 20.274 52.094 22.615 49.1982C24.4357 46.9456 25.5307 44.0589 25.5307 40.911V30.3913C25.5307 29.0064 24.4303 27.8828 23.0733 27.8828L23.0715 27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0715 304.885H12.7635C9.67862 304.885 6.85015 306.001 4.64377 307.859C1.80803 310.249 0 313.864 0 317.911C0 321.059 1.09319 323.946 2.91577 326.198C3.43599 326.841 4.01623 327.433 4.64559 327.965C6.85197 329.823 9.68044 330.939 12.7654 330.939C16.7307 330.939 20.274 329.094 22.615 326.198C24.4357 323.946 25.5307 321.059 25.5307 317.911V307.391C25.5307 306.006 24.4303 304.883 23.0733 304.883L23.0715 304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.282 27.8846H290.974C287.89 27.8846 285.061 29.0009 282.855 30.859C280.019 33.2487 278.211 36.8643 278.211 40.911C278.211 44.0589 279.304 46.9456 281.127 49.1982C281.647 49.8413 282.227 50.4333 282.857 50.9649C285.063 52.823 287.891 53.9393 290.976 53.9393C294.942 53.9393 298.485 52.094 300.826 49.1982C302.647 46.9456 303.742 44.0589 303.742 40.911V30.3913C303.742 29.0064 302.641 27.8828 301.284 27.8828L301.282 27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.282 304.885H290.974C287.89 304.885 285.061 306.001 282.855 307.859C280.019 310.249 278.211 313.864 278.211 317.911C278.211 321.059 279.304 323.946 281.127 326.198C281.647 326.841 282.227 327.433 282.857 327.965C285.063 329.823 287.891 330.939 290.976 330.939C294.942 330.939 298.485 329.094 300.826 326.198C302.647 323.946 303.742 321.059 303.742 317.911V307.391C303.742 306.006 302.641 304.883 301.284 304.883L301.282 304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 27.8846H40.5847C37.4998 27.8846 34.6713 29.0009 32.4649 30.859C29.6274 33.2487 27.8193 36.8643 27.8193 40.911C27.8193 44.0589 28.9125 46.9456 30.7351 49.1982C31.2553 49.8413 31.8356 50.4333 32.4649 50.9649C34.6713 52.823 37.4998 53.9393 40.5847 53.9393C44.55 53.9393 48.0933 52.094 50.4343 49.1982C52.2551 46.9456 53.3501 44.0589 53.3501 40.911V30.3913C53.3501 29.0064 52.2496 27.8828 50.8927 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 304.885H40.5847C37.4998 304.885 34.6713 306.001 32.4649 307.859C29.6274 310.249 27.8193 313.864 27.8193 317.911C27.8193 321.059 28.9125 323.946 30.7351 326.198C31.2553 326.841 31.8356 327.433 32.4649 327.965C34.6713 329.823 37.4998 330.939 40.5847 330.939C44.55 330.939 48.0933 329.094 50.4343 326.198C52.2551 323.946 53.3501 321.059 53.3501 317.911V307.391C53.3501 306.006 52.2496 304.883 50.8927 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 27.8846H318.796C315.711 27.8846 312.882 29.0009 310.676 30.859C307.838 33.2487 306.03 36.8643 306.03 40.911C306.03 44.0589 307.123 46.9456 308.946 49.1982C309.466 49.8413 310.047 50.4333 310.676 50.9649C312.882 52.823 315.711 53.9393 318.796 53.9393C322.761 53.9393 326.304 52.094 328.645 49.1982C330.466 46.9456 331.561 44.0589 331.561 40.911V30.3913C331.561 29.0064 330.461 27.8828 329.104 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 304.885H318.796C315.711 304.885 312.882 306.001 310.676 307.859C307.838 310.249 306.03 313.864 306.03 317.911C306.03 321.059 307.123 323.946 308.946 326.198C309.466 326.841 310.047 327.433 310.676 327.965C312.882 329.823 315.711 330.939 318.796 330.939C322.761 330.939 326.304 329.094 328.645 326.198C330.466 323.946 331.561 321.059 331.561 317.911V307.391C331.561 306.006 330.461 304.883 329.104 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 27.8846H68.405C65.3201 27.8846 62.4916 29.0009 60.2852 30.859C57.4477 33.2487 55.6396 36.8643 55.6396 40.911C55.6396 44.0589 56.7328 46.9456 58.5554 49.1982C59.0756 49.8413 59.6559 50.4333 60.2852 50.9649C62.4916 52.823 65.3201 53.9393 68.405 53.9393C72.3703 53.9393 75.9136 52.094 78.2546 49.1982C80.0754 46.9456 81.1704 44.0589 81.1704 40.911V30.3913C81.1704 29.0064 80.0699 27.8828 78.713 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 304.885H68.405C65.3201 304.885 62.4916 306.001 60.2852 307.859C57.4477 310.249 55.6396 313.864 55.6396 317.911C55.6396 321.059 56.7328 323.946 58.5554 326.198C59.0756 326.841 59.6559 327.433 60.2852 327.965C62.4916 329.823 65.3201 330.939 68.405 330.939C72.3703 330.939 75.9136 329.094 78.2546 326.198C80.0754 323.946 81.1704 321.059 81.1704 317.911V307.391C81.1704 306.006 80.0699 304.883 78.713 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 27.8846H346.617C343.532 27.8846 340.704 29.0009 338.497 30.859C335.66 33.2487 333.852 36.8643 333.852 40.911C333.852 44.0589 334.945 46.9456 336.767 49.1982C337.288 49.8413 337.868 50.4333 338.497 50.9649C340.704 52.823 343.532 53.9393 346.617 53.9393C350.582 53.9393 354.126 52.094 356.467 49.1982C358.287 46.9456 359.382 44.0589 359.382 40.911V30.3913C359.382 29.0064 358.282 27.8828 356.925 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 304.885H346.617C343.532 304.885 340.704 306.001 338.497 307.859C335.66 310.249 333.852 313.864 333.852 317.911C333.852 321.059 334.945 323.946 336.767 326.198C337.288 326.841 337.868 327.433 338.497 327.965C340.704 329.823 343.532 330.939 346.617 330.939C350.582 330.939 354.126 329.094 356.467 326.198C358.287 323.946 359.382 321.059 359.382 317.911V307.391C359.382 306.006 358.282 304.883 356.925 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 27.8846H96.2244C93.1394 27.8846 90.311 29.0009 88.1046 30.859C85.267 33.2487 83.459 36.8643 83.459 40.911C83.459 44.0589 84.5522 46.9456 86.3748 49.1982C86.895 49.8413 87.4752 50.4333 88.1046 50.9649C90.311 52.823 93.1394 53.9393 96.2244 53.9393C100.19 53.9393 103.733 52.094 106.074 49.1982C107.895 46.9456 108.99 44.0589 108.99 40.911V30.3913C108.99 29.0064 107.889 27.8828 106.532 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 304.885H96.2244C93.1394 304.885 90.311 306.001 88.1046 307.859C85.267 310.249 83.459 313.864 83.459 317.911C83.459 321.059 84.5522 323.946 86.3748 326.198C86.895 326.841 87.4752 327.433 88.1046 327.965C90.311 329.823 93.1394 330.939 96.2244 330.939C100.19 330.939 103.733 329.094 106.074 326.198C107.895 323.946 108.99 321.059 108.99 317.911V307.391C108.99 306.006 107.889 304.883 106.532 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 27.8846H374.435C371.35 27.8846 368.522 29.0009 366.316 30.859C363.478 33.2487 361.67 36.8643 361.67 40.911C361.67 44.0589 362.763 46.9456 364.586 49.1982C365.106 49.8413 365.686 50.4333 366.316 50.9649C368.522 52.823 371.35 53.9393 374.435 53.9393C378.401 53.9393 381.944 52.094 384.285 49.1982C386.106 46.9456 387.201 44.0589 387.201 40.911V30.3913C387.201 29.0064 386.1 27.8828 384.743 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 304.885H374.435C371.35 304.885 368.522 306.001 366.316 307.859C363.478 310.249 361.67 313.864 361.67 317.911C361.67 321.059 362.763 323.946 364.586 326.198C365.106 326.841 365.686 327.433 366.316 327.965C368.522 329.823 371.35 330.939 374.435 330.939C378.401 330.939 381.944 329.094 384.285 326.198C386.106 323.946 387.201 321.059 387.201 317.911V307.391C387.201 306.006 386.1 304.883 384.743 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 27.8827H124.045C120.96 27.8827 118.131 28.999 115.925 30.857C113.087 33.2467 111.279 36.8623 111.279 40.9091C111.279 44.057 112.372 46.9436 114.195 49.1963C114.715 49.8394 115.296 50.4313 115.925 50.963C118.131 52.821 120.96 53.9373 124.045 53.9373C128.01 53.9373 131.553 52.0921 133.894 49.1963C135.715 46.9436 136.81 44.057 136.81 40.9091V30.3893C136.81 29.0045 135.71 27.8809 134.353 27.8809V27.8827Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 304.883H124.045C120.96 304.883 118.131 305.999 115.925 307.857C113.087 310.247 111.279 313.862 111.279 317.909C111.279 321.057 112.372 323.944 114.195 326.196C114.715 326.839 115.296 327.431 115.925 327.963C118.131 329.821 120.96 330.937 124.045 330.937C128.01 330.937 131.553 329.092 133.894 326.196C135.715 323.944 136.81 321.057 136.81 317.909V307.389C136.81 306.004 135.71 304.881 134.353 304.881V304.883Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 27.8846H402.257C399.172 27.8846 396.343 29.0009 394.137 30.859C391.299 33.2487 389.491 36.8643 389.491 40.911C389.491 44.0589 390.584 46.9456 392.407 49.1982C392.927 49.8413 393.507 50.4333 394.137 50.9649C396.343 52.823 399.172 53.9393 402.257 53.9393C406.222 53.9393 409.765 52.094 412.106 49.1982C413.927 46.9456 415.022 44.0589 415.022 40.911V30.3913C415.022 29.0064 413.921 27.8828 412.565 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 304.885H402.257C399.172 304.885 396.343 306.001 394.137 307.859C391.299 310.249 389.491 313.864 389.491 317.911C389.491 321.059 390.584 323.946 392.407 326.198C392.927 326.841 393.507 327.433 394.137 327.965C396.343 329.823 399.172 330.939 402.257 330.939C406.222 330.939 409.765 329.094 412.106 326.198C413.927 323.946 415.022 321.059 415.022 317.911V307.391C415.022 306.006 413.921 304.883 412.565 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 27.8846H151.866C148.781 27.8846 145.953 29.0009 143.746 30.859C140.909 33.2487 139.101 36.8643 139.101 40.911C139.101 44.0589 140.194 46.9456 142.016 49.1982C142.537 49.8413 143.117 50.4333 143.746 50.9649C145.953 52.823 148.781 53.9393 151.866 53.9393C155.831 53.9393 159.375 52.094 161.716 49.1982C163.536 46.9456 164.631 44.0589 164.631 40.911V30.3913C164.631 29.0064 163.531 27.8828 162.174 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 304.885H151.866C148.781 304.885 145.953 306.001 143.746 307.859C140.909 310.249 139.101 313.864 139.101 317.911C139.101 321.059 140.194 323.946 142.016 326.198C142.537 326.841 143.117 327.433 143.746 327.965C145.953 329.823 148.781 330.939 151.866 330.939C155.831 330.939 159.375 329.094 161.716 326.198C163.536 323.946 164.631 321.059 164.631 317.911V307.391C164.631 306.006 163.531 304.883 162.174 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 27.8846H430.077C426.992 27.8846 424.164 29.0009 421.957 30.859C419.12 33.2487 417.312 36.8643 417.312 40.911C417.312 44.0589 418.405 46.9456 420.227 49.1982C420.748 49.8413 421.328 50.4333 421.957 50.9649C424.164 52.823 426.992 53.9393 430.077 53.9393C434.042 53.9393 437.586 52.094 439.926 49.1982C441.747 46.9456 442.842 44.0589 442.842 40.911V30.3913C442.842 29.0064 441.742 27.8828 440.385 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 304.885H430.077C426.992 304.885 424.164 306.001 421.957 307.859C419.12 310.249 417.312 313.864 417.312 317.911C417.312 321.059 418.405 323.946 420.227 326.198C420.748 326.841 421.328 327.433 421.957 327.965C424.164 329.823 426.992 330.939 430.077 330.939C434.042 330.939 437.586 329.094 439.926 326.198C441.747 323.946 442.842 321.059 442.842 317.911V307.391C442.842 306.006 441.742 304.883 440.385 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 27.8846H179.684C176.599 27.8846 173.771 29.0009 171.565 30.859C168.727 33.2487 166.919 36.8643 166.919 40.911C166.919 44.0589 168.012 46.9456 169.835 49.1982C170.355 49.8413 170.935 50.4333 171.565 50.9649C173.771 52.823 176.599 53.9393 179.684 53.9393C183.65 53.9393 187.193 52.094 189.534 49.1982C191.355 46.9456 192.45 44.0589 192.45 40.911V30.3913C192.45 29.0064 191.349 27.8828 189.992 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 304.885H179.684C176.599 304.885 173.771 306.001 171.565 307.859C168.727 310.249 166.919 313.864 166.919 317.911C166.919 321.059 168.012 323.946 169.835 326.198C170.355 326.841 170.935 327.433 171.565 327.965C173.771 329.823 176.599 330.939 179.684 330.939C183.65 330.939 187.193 329.094 189.534 326.198C191.355 323.946 192.45 321.059 192.45 317.911V307.391C192.45 306.006 191.349 304.883 189.992 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 27.8846H457.896C454.811 27.8846 451.983 29.0009 449.776 30.859C446.939 33.2487 445.131 36.8643 445.131 40.911C445.131 44.0589 446.224 46.9456 448.047 49.1982C448.567 49.8413 449.147 50.4333 449.776 50.9649C451.983 52.823 454.811 53.9393 457.896 53.9393C461.862 53.9393 465.405 52.094 467.746 49.1982C469.567 46.9456 470.662 44.0589 470.662 40.911V30.3913C470.662 29.0064 469.561 27.8828 468.204 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 304.885H457.896C454.811 304.885 451.983 306.001 449.776 307.859C446.939 310.249 445.131 313.864 445.131 317.911C445.131 321.059 446.224 323.946 448.047 326.198C448.567 326.841 449.147 327.433 449.776 327.965C451.983 329.823 454.811 330.939 457.896 330.939C461.862 330.939 465.405 329.094 467.746 326.198C469.567 323.946 470.662 321.059 470.662 317.911V307.391C470.662 306.006 469.561 304.883 468.204 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 27.8846H207.506C204.421 27.8846 201.592 29.0009 199.386 30.859C196.548 33.2487 194.74 36.8643 194.74 40.911C194.74 44.0589 195.833 46.9456 197.656 49.1982C198.176 49.8413 198.756 50.4333 199.386 50.9649C201.592 52.823 204.421 53.9393 207.506 53.9393C211.471 53.9393 215.014 52.094 217.355 49.1982C219.176 46.9456 220.271 44.0589 220.271 40.911V30.3913C220.271 29.0064 219.171 27.8828 217.814 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 304.885H207.506C204.421 304.885 201.592 306.001 199.386 307.859C196.548 310.249 194.74 313.864 194.74 317.911C194.74 321.059 195.833 323.946 197.656 326.198C198.176 326.841 198.756 327.433 199.386 327.965C201.592 329.823 204.421 330.939 207.506 330.939C211.471 330.939 215.014 329.094 217.355 326.198C219.176 323.946 220.271 321.059 220.271 317.911V307.391C220.271 306.006 219.171 304.883 217.814 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 27.8846H485.717C482.632 27.8846 479.803 29.0009 477.597 30.859C474.759 33.2487 472.951 36.8643 472.951 40.911C472.951 44.0589 474.044 46.9456 475.867 49.1982C476.387 49.8413 476.967 50.4333 477.597 50.9649C479.803 52.823 482.632 53.9393 485.717 53.9393C489.682 53.9393 493.225 52.094 495.566 49.1982C497.387 46.9456 498.482 44.0589 498.482 40.911V30.3913C498.482 29.0064 497.381 27.8828 496.025 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 304.885H485.717C482.632 304.885 479.803 306.001 477.597 307.859C474.759 310.249 472.951 313.864 472.951 317.911C472.951 321.059 474.044 323.946 475.867 326.198C476.387 326.841 476.967 327.433 477.597 327.965C479.803 329.823 482.632 330.939 485.717 330.939C489.682 330.939 493.225 329.094 495.566 326.198C497.387 323.946 498.482 321.059 498.482 317.911V307.391C498.482 306.006 497.381 304.883 496.025 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 27.8827H235.326C232.241 27.8827 229.413 28.999 227.206 30.857C224.369 33.2467 222.561 36.8623 222.561 40.9091C222.561 44.057 223.654 46.9436 225.476 49.1963C225.997 49.8394 226.577 50.4313 227.206 50.963C229.413 52.821 232.241 53.9373 235.326 53.9373C239.291 53.9373 242.835 52.0921 245.176 49.1963C246.996 46.9436 248.091 44.057 248.091 40.9091V30.3893C248.091 29.0045 246.991 27.8809 245.634 27.8809V27.8827Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 304.883H235.326C232.241 304.883 229.413 305.999 227.206 307.857C224.369 310.247 222.561 313.862 222.561 317.909C222.561 321.057 223.654 323.944 225.476 326.196C225.997 326.839 226.577 327.431 227.206 327.963C229.413 329.821 232.241 330.937 235.326 330.937C239.291 330.937 242.835 329.092 245.176 326.196C246.996 323.944 248.091 321.057 248.091 317.909V307.389C248.091 306.004 246.991 304.881 245.634 304.881V304.883Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 27.8846H513.538C510.453 27.8846 507.624 29.0009 505.418 30.859C502.581 33.2487 500.772 36.8643 500.772 40.911C500.772 44.0589 501.866 46.9456 503.688 49.1982C504.208 49.8413 504.789 50.4333 505.418 50.9649C507.624 52.823 510.453 53.9393 513.538 53.9393C517.503 53.9393 521.046 52.094 523.387 49.1982C525.208 46.9456 526.303 44.0589 526.303 40.911V30.3913C526.303 29.0064 525.203 27.8828 523.846 27.8828V27.8846Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 304.885H513.538C510.453 304.885 507.624 306.001 505.418 307.859C502.581 310.249 500.772 313.864 500.772 317.911C500.772 321.059 501.866 323.946 503.688 326.198C504.208 326.841 504.789 327.433 505.418 327.965C507.624 329.823 510.453 330.939 513.538 330.939C517.503 330.939 521.046 329.094 523.387 326.198C525.208 323.946 526.303 321.059 526.303 317.911V307.391C526.303 306.006 525.203 304.883 523.846 304.883V304.885Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 27.8827H263.147C260.062 27.8827 257.234 28.999 255.027 30.857C252.19 33.2467 250.382 36.8623 250.382 40.9091C250.382 44.057 251.475 46.9436 253.298 49.1963C253.818 49.8394 254.398 50.4313 255.027 50.963C257.234 52.821 260.062 53.9373 263.147 53.9373C267.112 53.9373 270.656 52.0921 272.997 49.1963C274.818 46.9436 275.913 44.057 275.913 40.9091V30.3893C275.913 29.0045 274.812 27.8809 273.455 27.8809V27.8827Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 304.883H263.147C260.062 304.883 257.234 305.999 255.027 307.857C252.19 310.247 250.382 313.862 250.382 317.909C250.382 321.057 251.475 323.944 253.298 326.196C253.818 326.839 254.398 327.431 255.027 327.963C257.234 329.821 260.062 330.937 263.147 330.937C267.112 330.937 270.656 329.092 272.997 326.196C274.818 323.944 275.913 321.057 275.913 317.909V307.389C275.913 306.004 274.812 304.881 273.455 304.881V304.883Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 55.7655H12.7634C9.67848 55.7655 6.85002 56.8818 4.64364 58.7398C1.80608 61.1295 -0.00195312 64.7451 -0.00195312 68.7919C-0.00195312 71.9398 1.09123 74.8264 2.91382 77.0791C3.43404 77.7222 4.01428 78.3141 4.64364 78.8458C6.85002 80.7038 9.67848 81.8201 12.7634 81.8201C16.7287 81.8201 20.272 79.9749 22.613 77.0791C24.4338 74.8264 25.5288 71.9398 25.5288 68.7919V58.2721C25.5288 56.8873 24.4283 55.7637 23.0714 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 332.766H12.7634C9.67848 332.766 6.85002 333.882 4.64364 335.74C1.80608 338.13 -0.00195312 341.745 -0.00195312 345.792C-0.00195312 348.94 1.09123 351.826 2.91382 354.079C3.43404 354.722 4.01428 355.314 4.64364 355.846C6.85002 357.704 9.67848 358.82 12.7634 358.82C16.7287 358.82 20.272 356.975 22.613 354.079C24.4338 351.826 25.5288 348.94 25.5288 345.792V335.272C25.5288 333.887 24.4283 332.764 23.0714 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 55.7655H290.975C287.89 55.7655 285.062 56.8818 282.856 58.7398C280.018 61.1295 278.21 64.7451 278.21 68.7919C278.21 71.9398 279.303 74.8264 281.126 77.0791C281.646 77.7222 282.226 78.3141 282.856 78.8458C285.062 80.7038 287.89 81.8201 290.975 81.8201C294.941 81.8201 298.484 79.9749 300.825 77.0791C302.646 74.8264 303.741 71.9398 303.741 68.7919V58.2721C303.741 56.8873 302.64 55.7637 301.283 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 332.766H290.975C287.89 332.766 285.062 333.882 282.856 335.74C280.018 338.13 278.21 341.745 278.21 345.792C278.21 348.94 279.303 351.826 281.126 354.079C281.646 354.722 282.226 355.314 282.856 355.846C285.062 357.704 287.89 358.82 290.975 358.82C294.941 358.82 298.484 356.975 300.825 354.079C302.646 351.826 303.741 348.94 303.741 345.792V335.272C303.741 333.887 302.64 332.764 301.283 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 55.7655H40.5847C37.4998 55.7655 34.6713 56.8818 32.4649 58.7398C29.6274 61.1295 27.8193 64.7451 27.8193 68.7919C27.8193 71.9398 28.9125 74.8264 30.7351 77.0791C31.2553 77.7222 31.8356 78.3141 32.4649 78.8458C34.6713 80.7038 37.4998 81.8201 40.5847 81.8201C44.55 81.8201 48.0933 79.9749 50.4343 77.0791C52.2551 74.8264 53.3501 71.9398 53.3501 68.7919V58.2721C53.3501 56.8873 52.2496 55.7637 50.8927 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 332.766H40.5847C37.4998 332.766 34.6713 333.882 32.4649 335.74C29.6274 338.13 27.8193 341.745 27.8193 345.792C27.8193 348.94 28.9125 351.826 30.7351 354.079C31.2553 354.722 31.8356 355.314 32.4649 355.846C34.6713 357.704 37.4998 358.82 40.5847 358.82C44.55 358.82 48.0933 356.975 50.4343 354.079C52.2551 351.826 53.3501 348.94 53.3501 345.792V335.272C53.3501 333.887 52.2496 332.764 50.8927 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 55.7655H318.796C315.711 55.7655 312.882 56.8818 310.676 58.7398C307.838 61.1295 306.03 64.7451 306.03 68.7919C306.03 71.9398 307.123 74.8264 308.946 77.0791C309.466 77.7222 310.047 78.3141 310.676 78.8458C312.882 80.7038 315.711 81.8201 318.796 81.8201C322.761 81.8201 326.304 79.9749 328.645 77.0791C330.466 74.8264 331.561 71.9398 331.561 68.7919V58.2721C331.561 56.8873 330.461 55.7637 329.104 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 332.766H318.796C315.711 332.766 312.882 333.882 310.676 335.74C307.838 338.13 306.03 341.745 306.03 345.792C306.03 348.94 307.123 351.826 308.946 354.079C309.466 354.722 310.047 355.314 310.676 355.846C312.882 357.704 315.711 358.82 318.796 358.82C322.761 358.82 326.304 356.975 328.645 354.079C330.466 351.826 331.561 348.94 331.561 345.792V335.272C331.561 333.887 330.461 332.764 329.104 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 55.7655H68.405C65.3201 55.7655 62.4916 56.8818 60.2852 58.7398C57.4477 61.1295 55.6396 64.7451 55.6396 68.7919C55.6396 71.9398 56.7328 74.8264 58.5554 77.0791C59.0756 77.7222 59.6559 78.3141 60.2852 78.8458C62.4916 80.7038 65.3201 81.8201 68.405 81.8201C72.3703 81.8201 75.9136 79.9749 78.2546 77.0791C80.0754 74.8264 81.1704 71.9398 81.1704 68.7919V58.2721C81.1704 56.8873 80.0699 55.7637 78.713 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 332.766H68.405C65.3201 332.766 62.4916 333.882 60.2852 335.74C57.4477 338.13 55.6396 341.745 55.6396 345.792C55.6396 348.94 56.7328 351.826 58.5554 354.079C59.0756 354.722 59.6559 355.314 60.2852 355.846C62.4916 357.704 65.3201 358.82 68.405 358.82C72.3703 358.82 75.9136 356.975 78.2546 354.079C80.0754 351.826 81.1704 348.94 81.1704 345.792V335.272C81.1704 333.887 80.0699 332.764 78.713 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 55.7655H346.617C343.532 55.7655 340.704 56.8818 338.497 58.7398C335.66 61.1295 333.852 64.7451 333.852 68.7919C333.852 71.9398 334.945 74.8264 336.767 77.0791C337.288 77.7222 337.868 78.3141 338.497 78.8458C340.704 80.7038 343.532 81.8201 346.617 81.8201C350.582 81.8201 354.126 79.9749 356.467 77.0791C358.287 74.8264 359.382 71.9398 359.382 68.7919V58.2721C359.382 56.8873 358.282 55.7637 356.925 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 332.766H346.617C343.532 332.766 340.704 333.882 338.497 335.74C335.66 338.13 333.852 341.745 333.852 345.792C333.852 348.94 334.945 351.826 336.767 354.079C337.288 354.722 337.868 355.314 338.497 355.846C340.704 357.704 343.532 358.82 346.617 358.82C350.582 358.82 354.126 356.975 356.467 354.079C358.287 351.826 359.382 348.94 359.382 345.792V335.272C359.382 333.887 358.282 332.764 356.925 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 55.7655H96.2244C93.1394 55.7655 90.311 56.8818 88.1046 58.7398C85.267 61.1295 83.459 64.7451 83.459 68.7919C83.459 71.9398 84.5522 74.8264 86.3748 77.0791C86.895 77.7222 87.4752 78.3141 88.1046 78.8458C90.311 80.7038 93.1394 81.8201 96.2244 81.8201C100.19 81.8201 103.733 79.9749 106.074 77.0791C107.895 74.8264 108.99 71.9398 108.99 68.7919V58.2721C108.99 56.8873 107.889 55.7637 106.532 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 332.766H96.2244C93.1394 332.766 90.311 333.882 88.1046 335.74C85.267 338.13 83.459 341.745 83.459 345.792C83.459 348.94 84.5522 351.826 86.3748 354.079C86.895 354.722 87.4752 355.314 88.1046 355.846C90.311 357.704 93.1394 358.82 96.2244 358.82C100.19 358.82 103.733 356.975 106.074 354.079C107.895 351.826 108.99 348.94 108.99 345.792V335.272C108.99 333.887 107.889 332.764 106.532 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 55.7655H374.435C371.35 55.7655 368.522 56.8818 366.316 58.7398C363.478 61.1295 361.67 64.7451 361.67 68.7919C361.67 71.9398 362.763 74.8264 364.586 77.0791C365.106 77.7222 365.686 78.3141 366.316 78.8458C368.522 80.7038 371.35 81.8201 374.435 81.8201C378.401 81.8201 381.944 79.9749 384.285 77.0791C386.106 74.8264 387.201 71.9398 387.201 68.7919V58.2721C387.201 56.8873 386.1 55.7637 384.743 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 332.766H374.435C371.35 332.766 368.522 333.882 366.316 335.74C363.478 338.13 361.67 341.745 361.67 345.792C361.67 348.94 362.763 351.826 364.586 354.079C365.106 354.722 365.686 355.314 366.316 355.846C368.522 357.704 371.35 358.82 374.435 358.82C378.401 358.82 381.944 356.975 384.285 354.079C386.106 351.826 387.201 348.94 387.201 345.792V335.272C387.201 333.887 386.1 332.764 384.743 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 55.7655H124.045C120.96 55.7655 118.131 56.8818 115.925 58.7398C113.087 61.1295 111.279 64.7451 111.279 68.7919C111.279 71.9398 112.372 74.8264 114.195 77.0791C114.715 77.7222 115.296 78.3141 115.925 78.8458C118.131 80.7038 120.96 81.8201 124.045 81.8201C128.01 81.8201 131.553 79.9749 133.894 77.0791C135.715 74.8264 136.81 71.9398 136.81 68.7919V58.2721C136.81 56.8873 135.71 55.7637 134.353 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 332.766H124.045C120.96 332.766 118.131 333.882 115.925 335.74C113.087 338.13 111.279 341.745 111.279 345.792C111.279 348.94 112.372 351.826 114.195 354.079C114.715 354.722 115.296 355.314 115.925 355.846C118.131 357.704 120.96 358.82 124.045 358.82C128.01 358.82 131.553 356.975 133.894 354.079C135.715 351.826 136.81 348.94 136.81 345.792V335.272C136.81 333.887 135.71 332.764 134.353 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 55.7655H402.257C399.172 55.7655 396.343 56.8818 394.137 58.7398C391.299 61.1295 389.491 64.7451 389.491 68.7919C389.491 71.9398 390.584 74.8264 392.407 77.0791C392.927 77.7222 393.507 78.3141 394.137 78.8458C396.343 80.7038 399.172 81.8201 402.257 81.8201C406.222 81.8201 409.765 79.9749 412.106 77.0791C413.927 74.8264 415.022 71.9398 415.022 68.7919V58.2721C415.022 56.8873 413.921 55.7637 412.565 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 332.766H402.257C399.172 332.766 396.343 333.882 394.137 335.74C391.299 338.13 389.491 341.745 389.491 345.792C389.491 348.94 390.584 351.826 392.407 354.079C392.927 354.722 393.507 355.314 394.137 355.846C396.343 357.704 399.172 358.82 402.257 358.82C406.222 358.82 409.765 356.975 412.106 354.079C413.927 351.826 415.022 348.94 415.022 345.792V335.272C415.022 333.887 413.921 332.764 412.565 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 55.7655H151.866C148.781 55.7655 145.953 56.8818 143.746 58.7398C140.909 61.1295 139.101 64.7451 139.101 68.7919C139.101 71.9398 140.194 74.8264 142.016 77.0791C142.537 77.7222 143.117 78.3141 143.746 78.8458C145.953 80.7038 148.781 81.8201 151.866 81.8201C155.831 81.8201 159.375 79.9749 161.716 77.0791C163.536 74.8264 164.631 71.9398 164.631 68.7919V58.2721C164.631 56.8873 163.531 55.7637 162.174 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 332.766H151.866C148.781 332.766 145.953 333.882 143.746 335.74C140.909 338.13 139.101 341.745 139.101 345.792C139.101 348.94 140.194 351.826 142.016 354.079C142.537 354.722 143.117 355.314 143.746 355.846C145.953 357.704 148.781 358.82 151.866 358.82C155.831 358.82 159.375 356.975 161.716 354.079C163.536 351.826 164.631 348.94 164.631 345.792V335.272C164.631 333.887 163.531 332.764 162.174 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 55.7655H430.077C426.992 55.7655 424.164 56.8818 421.957 58.7398C419.12 61.1295 417.312 64.7451 417.312 68.7919C417.312 71.9398 418.405 74.8264 420.227 77.0791C420.748 77.7222 421.328 78.3141 421.957 78.8458C424.164 80.7038 426.992 81.8201 430.077 81.8201C434.042 81.8201 437.586 79.9749 439.926 77.0791C441.747 74.8264 442.842 71.9398 442.842 68.7919V58.2721C442.842 56.8873 441.742 55.7637 440.385 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 332.766H430.077C426.992 332.766 424.164 333.882 421.957 335.74C419.12 338.13 417.312 341.745 417.312 345.792C417.312 348.94 418.405 351.826 420.227 354.079C420.748 354.722 421.328 355.314 421.957 355.846C424.164 357.704 426.992 358.82 430.077 358.82C434.042 358.82 437.586 356.975 439.926 354.079C441.747 351.826 442.842 348.94 442.842 345.792V335.272C442.842 333.887 441.742 332.764 440.385 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 55.7655H179.684C176.599 55.7655 173.771 56.8818 171.565 58.7398C168.727 61.1295 166.919 64.7451 166.919 68.7919C166.919 71.9398 168.012 74.8264 169.835 77.0791C170.355 77.7222 170.935 78.3141 171.565 78.8458C173.771 80.7038 176.599 81.8201 179.684 81.8201C183.65 81.8201 187.193 79.9749 189.534 77.0791C191.355 74.8264 192.45 71.9398 192.45 68.7919V58.2721C192.45 56.8873 191.349 55.7637 189.992 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 332.766H179.684C176.599 332.766 173.771 333.882 171.565 335.74C168.727 338.13 166.919 341.745 166.919 345.792C166.919 348.94 168.012 351.826 169.835 354.079C170.355 354.722 170.935 355.314 171.565 355.846C173.771 357.704 176.599 358.82 179.684 358.82C183.65 358.82 187.193 356.975 189.534 354.079C191.355 351.826 192.45 348.94 192.45 345.792V335.272C192.45 333.887 191.349 332.764 189.992 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 55.7655H457.896C454.811 55.7655 451.983 56.8818 449.776 58.7398C446.939 61.1295 445.131 64.7451 445.131 68.7919C445.131 71.9398 446.224 74.8264 448.047 77.0791C448.567 77.7222 449.147 78.3141 449.776 78.8458C451.983 80.7038 454.811 81.8201 457.896 81.8201C461.862 81.8201 465.405 79.9749 467.746 77.0791C469.567 74.8264 470.662 71.9398 470.662 68.7919V58.2721C470.662 56.8873 469.561 55.7637 468.204 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 332.766H457.896C454.811 332.766 451.983 333.882 449.776 335.74C446.939 338.13 445.131 341.745 445.131 345.792C445.131 348.94 446.224 351.826 448.047 354.079C448.567 354.722 449.147 355.314 449.776 355.846C451.983 357.704 454.811 358.82 457.896 358.82C461.862 358.82 465.405 356.975 467.746 354.079C469.567 351.826 470.662 348.94 470.662 345.792V335.272C470.662 333.887 469.561 332.764 468.204 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 55.7655H207.506C204.421 55.7655 201.592 56.8818 199.386 58.7398C196.548 61.1295 194.74 64.7451 194.74 68.7919C194.74 71.9398 195.833 74.8264 197.656 77.0791C198.176 77.7222 198.756 78.3141 199.386 78.8458C201.592 80.7038 204.421 81.8201 207.506 81.8201C211.471 81.8201 215.014 79.9749 217.355 77.0791C219.176 74.8264 220.271 71.9398 220.271 68.7919V58.2721C220.271 56.8873 219.171 55.7637 217.814 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 332.766H207.506C204.421 332.766 201.592 333.882 199.386 335.74C196.548 338.13 194.74 341.745 194.74 345.792C194.74 348.94 195.833 351.826 197.656 354.079C198.176 354.722 198.756 355.314 199.386 355.846C201.592 357.704 204.421 358.82 207.506 358.82C211.471 358.82 215.014 356.975 217.355 354.079C219.176 351.826 220.271 348.94 220.271 345.792V335.272C220.271 333.887 219.171 332.764 217.814 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 55.7655H485.717C482.632 55.7655 479.803 56.8818 477.597 58.7398C474.759 61.1295 472.951 64.7451 472.951 68.7919C472.951 71.9398 474.044 74.8264 475.867 77.0791C476.387 77.7222 476.967 78.3141 477.597 78.8458C479.803 80.7038 482.632 81.8201 485.717 81.8201C489.682 81.8201 493.225 79.9749 495.566 77.0791C497.387 74.8264 498.482 71.9398 498.482 68.7919V58.2721C498.482 56.8873 497.381 55.7637 496.025 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 332.766H485.717C482.632 332.766 479.803 333.882 477.597 335.74C474.759 338.13 472.951 341.745 472.951 345.792C472.951 348.94 474.044 351.826 475.867 354.079C476.387 354.722 476.967 355.314 477.597 355.846C479.803 357.704 482.632 358.82 485.717 358.82C489.682 358.82 493.225 356.975 495.566 354.079C497.387 351.826 498.482 348.94 498.482 345.792V335.272C498.482 333.887 497.381 332.764 496.025 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 55.7655H235.326C232.241 55.7655 229.413 56.8818 227.206 58.7398C224.369 61.1295 222.561 64.7451 222.561 68.7919C222.561 71.9398 223.654 74.8264 225.476 77.0791C225.997 77.7222 226.577 78.3141 227.206 78.8458C229.413 80.7038 232.241 81.8201 235.326 81.8201C239.291 81.8201 242.835 79.9749 245.176 77.0791C246.996 74.8264 248.091 71.9398 248.091 68.7919V58.2721C248.091 56.8873 246.991 55.7637 245.634 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 332.766H235.326C232.241 332.766 229.413 333.882 227.206 335.74C224.369 338.13 222.561 341.745 222.561 345.792C222.561 348.94 223.654 351.826 225.476 354.079C225.997 354.722 226.577 355.314 227.206 355.846C229.413 357.704 232.241 358.82 235.326 358.82C239.291 358.82 242.835 356.975 245.176 354.079C246.996 351.826 248.091 348.94 248.091 345.792V335.272C248.091 333.887 246.991 332.764 245.634 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 55.7655H513.538C510.453 55.7655 507.624 56.8818 505.418 58.7398C502.581 61.1295 500.772 64.7451 500.772 68.7919C500.772 71.9398 501.866 74.8264 503.688 77.0791C504.208 77.7222 504.789 78.3141 505.418 78.8458C507.624 80.7038 510.453 81.8201 513.538 81.8201C517.503 81.8201 521.046 79.9749 523.387 77.0791C525.208 74.8264 526.303 71.9398 526.303 68.7919V58.2721C526.303 56.8873 525.203 55.7637 523.846 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 332.766H513.538C510.453 332.766 507.624 333.882 505.418 335.74C502.581 338.13 500.772 341.745 500.772 345.792C500.772 348.94 501.866 351.826 503.688 354.079C504.208 354.722 504.789 355.314 505.418 355.846C507.624 357.704 510.453 358.82 513.538 358.82C517.503 358.82 521.046 356.975 523.387 354.079C525.208 351.826 526.303 348.94 526.303 345.792V335.272C526.303 333.887 525.203 332.764 523.846 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 55.7655H263.147C260.062 55.7655 257.234 56.8818 255.027 58.7398C252.19 61.1295 250.382 64.7451 250.382 68.7919C250.382 71.9398 251.475 74.8264 253.298 77.0791C253.818 77.7222 254.398 78.3141 255.027 78.8458C257.234 80.7038 260.062 81.8201 263.147 81.8201C267.112 81.8201 270.656 79.9749 272.997 77.0791C274.818 74.8264 275.913 71.9398 275.913 68.7919V58.2721C275.913 56.8873 274.812 55.7637 273.455 55.7637V55.7655Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 332.766H263.147C260.062 332.766 257.234 333.882 255.027 335.74C252.19 338.13 250.382 341.745 250.382 345.792C250.382 348.94 251.475 351.826 253.298 354.079C253.818 354.722 254.398 355.314 255.027 355.846C257.234 357.704 260.062 358.82 263.147 358.82C267.112 358.82 270.656 356.975 272.997 354.079C274.818 351.826 275.913 348.94 275.913 345.792V335.272C275.913 333.887 274.812 332.764 273.455 332.764V332.766Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 83.6483H12.7634C9.67848 83.6483 6.85002 84.7646 4.64364 86.6226C1.80608 89.0123 -0.00195312 92.6279 -0.00195312 96.6747C-0.00195312 99.8226 1.09123 102.709 2.91382 104.962C3.43404 105.605 4.01428 106.197 4.64364 106.729C6.85002 108.587 9.67848 109.703 12.7634 109.703C16.7287 109.703 20.272 107.858 22.613 104.962C24.4338 102.709 25.5288 99.8226 25.5288 96.6747V86.1549C25.5288 84.7701 24.4283 83.6465 23.0714 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 360.648H12.7634C9.67848 360.648 6.85002 361.765 4.64364 363.623C1.80608 366.012 -0.00195312 369.628 -0.00195312 373.675C-0.00195312 376.823 1.09123 379.709 2.91382 381.962C3.43404 382.605 4.01428 383.197 4.64364 383.729C6.85002 385.587 9.67848 386.703 12.7634 386.703C16.7287 386.703 20.272 384.858 22.613 381.962C24.4338 379.709 25.5288 376.823 25.5288 373.675V363.155C25.5288 361.77 24.4283 360.646 23.0714 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 83.6483H290.975C287.89 83.6483 285.062 84.7646 282.856 86.6226C280.018 89.0123 278.21 92.6279 278.21 96.6747C278.21 99.8226 279.303 102.709 281.126 104.962C281.646 105.605 282.226 106.197 282.856 106.729C285.062 108.587 287.89 109.703 290.975 109.703C294.941 109.703 298.484 107.858 300.825 104.962C302.646 102.709 303.741 99.8226 303.741 96.6747V86.1549C303.741 84.7701 302.64 83.6465 301.283 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 360.648H290.975C287.89 360.648 285.062 361.765 282.856 363.623C280.018 366.012 278.21 369.628 278.21 373.675C278.21 376.823 279.303 379.709 281.126 381.962C281.646 382.605 282.226 383.197 282.856 383.729C285.062 385.587 287.89 386.703 290.975 386.703C294.941 386.703 298.484 384.858 300.825 381.962C302.646 379.709 303.741 376.823 303.741 373.675V363.155C303.741 361.77 302.64 360.646 301.283 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 83.6483H40.5847C37.4998 83.6483 34.6713 84.7646 32.4649 86.6226C29.6274 89.0123 27.8193 92.6279 27.8193 96.6747C27.8193 99.8226 28.9125 102.709 30.7351 104.962C31.2553 105.605 31.8356 106.197 32.4649 106.729C34.6713 108.587 37.4998 109.703 40.5847 109.703C44.55 109.703 48.0933 107.858 50.4343 104.962C52.2551 102.709 53.3501 99.8226 53.3501 96.6747V86.1549C53.3501 84.7701 52.2496 83.6465 50.8927 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 360.648H40.5847C37.4998 360.648 34.6713 361.765 32.4649 363.623C29.6274 366.012 27.8193 369.628 27.8193 373.675C27.8193 376.823 28.9125 379.709 30.7351 381.962C31.2553 382.605 31.8356 383.197 32.4649 383.729C34.6713 385.587 37.4998 386.703 40.5847 386.703C44.55 386.703 48.0933 384.858 50.4343 381.962C52.2551 379.709 53.3501 376.823 53.3501 373.675V363.155C53.3501 361.77 52.2496 360.646 50.8927 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 83.6483H318.796C315.711 83.6483 312.882 84.7646 310.676 86.6226C307.838 89.0123 306.03 92.6279 306.03 96.6747C306.03 99.8226 307.123 102.709 308.946 104.962C309.466 105.605 310.047 106.197 310.676 106.729C312.882 108.587 315.711 109.703 318.796 109.703C322.761 109.703 326.304 107.858 328.645 104.962C330.466 102.709 331.561 99.8226 331.561 96.6747V86.1549C331.561 84.7701 330.461 83.6465 329.104 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 360.648H318.796C315.711 360.648 312.882 361.765 310.676 363.623C307.838 366.012 306.03 369.628 306.03 373.675C306.03 376.823 307.123 379.709 308.946 381.962C309.466 382.605 310.047 383.197 310.676 383.729C312.882 385.587 315.711 386.703 318.796 386.703C322.761 386.703 326.304 384.858 328.645 381.962C330.466 379.709 331.561 376.823 331.561 373.675V363.155C331.561 361.77 330.461 360.646 329.104 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 83.6483H68.405C65.3201 83.6483 62.4916 84.7646 60.2852 86.6226C57.4477 89.0123 55.6396 92.6279 55.6396 96.6747C55.6396 99.8226 56.7328 102.709 58.5554 104.962C59.0756 105.605 59.6559 106.197 60.2852 106.729C62.4916 108.587 65.3201 109.703 68.405 109.703C72.3703 109.703 75.9136 107.858 78.2546 104.962C80.0754 102.709 81.1704 99.8226 81.1704 96.6747V86.1549C81.1704 84.7701 80.0699 83.6465 78.713 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 360.648H68.405C65.3201 360.648 62.4916 361.765 60.2852 363.623C57.4477 366.012 55.6396 369.628 55.6396 373.675C55.6396 376.823 56.7328 379.709 58.5554 381.962C59.0756 382.605 59.6559 383.197 60.2852 383.729C62.4916 385.587 65.3201 386.703 68.405 386.703C72.3703 386.703 75.9136 384.858 78.2546 381.962C80.0754 379.709 81.1704 376.823 81.1704 373.675V363.155C81.1704 361.77 80.0699 360.646 78.713 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 83.6483H346.617C343.532 83.6483 340.704 84.7646 338.497 86.6226C335.66 89.0123 333.852 92.6279 333.852 96.6747C333.852 99.8226 334.945 102.709 336.767 104.962C337.288 105.605 337.868 106.197 338.497 106.729C340.704 108.587 343.532 109.703 346.617 109.703C350.582 109.703 354.126 107.858 356.467 104.962C358.287 102.709 359.382 99.8226 359.382 96.6747V86.1549C359.382 84.7701 358.282 83.6465 356.925 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 360.648H346.617C343.532 360.648 340.704 361.765 338.497 363.623C335.66 366.012 333.852 369.628 333.852 373.675C333.852 376.823 334.945 379.709 336.767 381.962C337.288 382.605 337.868 383.197 338.497 383.729C340.704 385.587 343.532 386.703 346.617 386.703C350.582 386.703 354.126 384.858 356.467 381.962C358.287 379.709 359.382 376.823 359.382 373.675V363.155C359.382 361.77 358.282 360.646 356.925 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 83.6483H96.2244C93.1394 83.6483 90.311 84.7646 88.1046 86.6226C85.267 89.0123 83.459 92.6279 83.459 96.6747C83.459 99.8226 84.5522 102.709 86.3748 104.962C86.895 105.605 87.4752 106.197 88.1046 106.729C90.311 108.587 93.1394 109.703 96.2244 109.703C100.19 109.703 103.733 107.858 106.074 104.962C107.895 102.709 108.99 99.8226 108.99 96.6747V86.1549C108.99 84.7701 107.889 83.6465 106.532 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 360.648H96.2244C93.1394 360.648 90.311 361.765 88.1046 363.623C85.267 366.012 83.459 369.628 83.459 373.675C83.459 376.823 84.5522 379.709 86.3748 381.962C86.895 382.605 87.4752 383.197 88.1046 383.729C90.311 385.587 93.1394 386.703 96.2244 386.703C100.19 386.703 103.733 384.858 106.074 381.962C107.895 379.709 108.99 376.823 108.99 373.675V363.155C108.99 361.77 107.889 360.646 106.532 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 83.6483H374.435C371.35 83.6483 368.522 84.7646 366.316 86.6226C363.478 89.0123 361.67 92.6279 361.67 96.6747C361.67 99.8226 362.763 102.709 364.586 104.962C365.106 105.605 365.686 106.197 366.316 106.729C368.522 108.587 371.35 109.703 374.435 109.703C378.401 109.703 381.944 107.858 384.285 104.962C386.106 102.709 387.201 99.8226 387.201 96.6747V86.1549C387.201 84.7701 386.1 83.6465 384.743 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 360.648H374.435C371.35 360.648 368.522 361.765 366.316 363.623C363.478 366.012 361.67 369.628 361.67 373.675C361.67 376.823 362.763 379.709 364.586 381.962C365.106 382.605 365.686 383.197 366.316 383.729C368.522 385.587 371.35 386.703 374.435 386.703C378.401 386.703 381.944 384.858 384.285 381.962C386.106 379.709 387.201 376.823 387.201 373.675V363.155C387.201 361.77 386.1 360.646 384.743 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 83.6483H124.045C120.96 83.6483 118.131 84.7646 115.925 86.6226C113.087 89.0123 111.279 92.6279 111.279 96.6747C111.279 99.8226 112.372 102.709 114.195 104.962C114.715 105.605 115.296 106.197 115.925 106.729C118.131 108.587 120.96 109.703 124.045 109.703C128.01 109.703 131.553 107.858 133.894 104.962C135.715 102.709 136.81 99.8226 136.81 96.6747V86.1549C136.81 84.7701 135.71 83.6465 134.353 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 360.648H124.045C120.96 360.648 118.131 361.765 115.925 363.623C113.087 366.012 111.279 369.628 111.279 373.675C111.279 376.823 112.372 379.709 114.195 381.962C114.715 382.605 115.296 383.197 115.925 383.729C118.131 385.587 120.96 386.703 124.045 386.703C128.01 386.703 131.553 384.858 133.894 381.962C135.715 379.709 136.81 376.823 136.81 373.675V363.155C136.81 361.77 135.71 360.646 134.353 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 83.6483H402.257C399.172 83.6483 396.343 84.7646 394.137 86.6226C391.299 89.0123 389.491 92.6279 389.491 96.6747C389.491 99.8226 390.584 102.709 392.407 104.962C392.927 105.605 393.507 106.197 394.137 106.729C396.343 108.587 399.172 109.703 402.257 109.703C406.222 109.703 409.765 107.858 412.106 104.962C413.927 102.709 415.022 99.8226 415.022 96.6747V86.1549C415.022 84.7701 413.921 83.6465 412.565 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 360.648H402.257C399.172 360.648 396.343 361.765 394.137 363.623C391.299 366.012 389.491 369.628 389.491 373.675C389.491 376.823 390.584 379.709 392.407 381.962C392.927 382.605 393.507 383.197 394.137 383.729C396.343 385.587 399.172 386.703 402.257 386.703C406.222 386.703 409.765 384.858 412.106 381.962C413.927 379.709 415.022 376.823 415.022 373.675V363.155C415.022 361.77 413.921 360.646 412.565 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 83.6483H151.866C148.781 83.6483 145.953 84.7646 143.746 86.6226C140.909 89.0123 139.101 92.6279 139.101 96.6747C139.101 99.8226 140.194 102.709 142.016 104.962C142.537 105.605 143.117 106.197 143.746 106.729C145.953 108.587 148.781 109.703 151.866 109.703C155.831 109.703 159.375 107.858 161.716 104.962C163.536 102.709 164.631 99.8226 164.631 96.6747V86.1549C164.631 84.7701 163.531 83.6465 162.174 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 360.648H151.866C148.781 360.648 145.953 361.765 143.746 363.623C140.909 366.012 139.101 369.628 139.101 373.675C139.101 376.823 140.194 379.709 142.016 381.962C142.537 382.605 143.117 383.197 143.746 383.729C145.953 385.587 148.781 386.703 151.866 386.703C155.831 386.703 159.375 384.858 161.716 381.962C163.536 379.709 164.631 376.823 164.631 373.675V363.155C164.631 361.77 163.531 360.646 162.174 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 83.6483H430.077C426.992 83.6483 424.164 84.7646 421.957 86.6226C419.12 89.0123 417.312 92.6279 417.312 96.6747C417.312 99.8226 418.405 102.709 420.227 104.962C420.748 105.605 421.328 106.197 421.957 106.729C424.164 108.587 426.992 109.703 430.077 109.703C434.042 109.703 437.586 107.858 439.926 104.962C441.747 102.709 442.842 99.8226 442.842 96.6747V86.1549C442.842 84.7701 441.742 83.6465 440.385 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 360.648H430.077C426.992 360.648 424.164 361.765 421.957 363.623C419.12 366.012 417.312 369.628 417.312 373.675C417.312 376.823 418.405 379.709 420.227 381.962C420.748 382.605 421.328 383.197 421.957 383.729C424.164 385.587 426.992 386.703 430.077 386.703C434.042 386.703 437.586 384.858 439.926 381.962C441.747 379.709 442.842 376.823 442.842 373.675V363.155C442.842 361.77 441.742 360.646 440.385 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 83.6483H179.684C176.599 83.6483 173.771 84.7646 171.565 86.6226C168.727 89.0123 166.919 92.6279 166.919 96.6747C166.919 99.8226 168.012 102.709 169.835 104.962C170.355 105.605 170.935 106.197 171.565 106.729C173.771 108.587 176.599 109.703 179.684 109.703C183.65 109.703 187.193 107.858 189.534 104.962C191.355 102.709 192.45 99.8226 192.45 96.6747V86.1549C192.45 84.7701 191.349 83.6465 189.992 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 360.648H179.684C176.599 360.648 173.771 361.765 171.565 363.623C168.727 366.012 166.919 369.628 166.919 373.675C166.919 376.823 168.012 379.709 169.835 381.962C170.355 382.605 170.935 383.197 171.565 383.729C173.771 385.587 176.599 386.703 179.684 386.703C183.65 386.703 187.193 384.858 189.534 381.962C191.355 379.709 192.45 376.823 192.45 373.675V363.155C192.45 361.77 191.349 360.646 189.992 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 83.6483H457.896C454.811 83.6483 451.983 84.7646 449.776 86.6226C446.939 89.0123 445.131 92.6279 445.131 96.6747C445.131 99.8226 446.224 102.709 448.047 104.962C448.567 105.605 449.147 106.197 449.776 106.729C451.983 108.587 454.811 109.703 457.896 109.703C461.862 109.703 465.405 107.858 467.746 104.962C469.567 102.709 470.662 99.8226 470.662 96.6747V86.1549C470.662 84.7701 469.561 83.6465 468.204 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 360.648H457.896C454.811 360.648 451.983 361.765 449.776 363.623C446.939 366.012 445.131 369.628 445.131 373.675C445.131 376.823 446.224 379.709 448.047 381.962C448.567 382.605 449.147 383.197 449.776 383.729C451.983 385.587 454.811 386.703 457.896 386.703C461.862 386.703 465.405 384.858 467.746 381.962C469.567 379.709 470.662 376.823 470.662 373.675V363.155C470.662 361.77 469.561 360.646 468.204 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 83.6483H207.506C204.421 83.6483 201.592 84.7646 199.386 86.6226C196.548 89.0123 194.74 92.6279 194.74 96.6747C194.74 99.8226 195.833 102.709 197.656 104.962C198.176 105.605 198.756 106.197 199.386 106.729C201.592 108.587 204.421 109.703 207.506 109.703C211.471 109.703 215.014 107.858 217.355 104.962C219.176 102.709 220.271 99.8226 220.271 96.6747V86.1549C220.271 84.7701 219.171 83.6465 217.814 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 360.648H207.506C204.421 360.648 201.592 361.765 199.386 363.623C196.548 366.012 194.74 369.628 194.74 373.675C194.74 376.823 195.833 379.709 197.656 381.962C198.176 382.605 198.756 383.197 199.386 383.729C201.592 385.587 204.421 386.703 207.506 386.703C211.471 386.703 215.014 384.858 217.355 381.962C219.176 379.709 220.271 376.823 220.271 373.675V363.155C220.271 361.77 219.171 360.646 217.814 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 83.6483H485.717C482.632 83.6483 479.803 84.7646 477.597 86.6226C474.759 89.0123 472.951 92.6279 472.951 96.6747C472.951 99.8226 474.044 102.709 475.867 104.962C476.387 105.605 476.967 106.197 477.597 106.729C479.803 108.587 482.632 109.703 485.717 109.703C489.682 109.703 493.225 107.858 495.566 104.962C497.387 102.709 498.482 99.8226 498.482 96.6747V86.1549C498.482 84.7701 497.381 83.6465 496.025 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 360.648H485.717C482.632 360.648 479.803 361.765 477.597 363.623C474.759 366.012 472.951 369.628 472.951 373.675C472.951 376.823 474.044 379.709 475.867 381.962C476.387 382.605 476.967 383.197 477.597 383.729C479.803 385.587 482.632 386.703 485.717 386.703C489.682 386.703 493.225 384.858 495.566 381.962C497.387 379.709 498.482 376.823 498.482 373.675V363.155C498.482 361.77 497.381 360.646 496.025 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 83.6483H235.326C232.241 83.6483 229.413 84.7646 227.206 86.6226C224.369 89.0123 222.561 92.6279 222.561 96.6747C222.561 99.8226 223.654 102.709 225.476 104.962C225.997 105.605 226.577 106.197 227.206 106.729C229.413 108.587 232.241 109.703 235.326 109.703C239.291 109.703 242.835 107.858 245.176 104.962C246.996 102.709 248.091 99.8226 248.091 96.6747V86.1549C248.091 84.7701 246.991 83.6465 245.634 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 360.648H235.326C232.241 360.648 229.413 361.765 227.206 363.623C224.369 366.012 222.561 369.628 222.561 373.675C222.561 376.823 223.654 379.709 225.476 381.962C225.997 382.605 226.577 383.197 227.206 383.729C229.413 385.587 232.241 386.703 235.326 386.703C239.291 386.703 242.835 384.858 245.176 381.962C246.996 379.709 248.091 376.823 248.091 373.675V363.155C248.091 361.77 246.991 360.646 245.634 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 83.6483H513.538C510.453 83.6483 507.624 84.7646 505.418 86.6226C502.581 89.0123 500.772 92.6279 500.772 96.6747C500.772 99.8226 501.866 102.709 503.688 104.962C504.208 105.605 504.789 106.197 505.418 106.729C507.624 108.587 510.453 109.703 513.538 109.703C517.503 109.703 521.046 107.858 523.387 104.962C525.208 102.709 526.303 99.8226 526.303 96.6747V86.1549C526.303 84.7701 525.203 83.6465 523.846 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 360.648H513.538C510.453 360.648 507.624 361.765 505.418 363.623C502.581 366.012 500.772 369.628 500.772 373.675C500.772 376.823 501.866 379.709 503.688 381.962C504.208 382.605 504.789 383.197 505.418 383.729C507.624 385.587 510.453 386.703 513.538 386.703C517.503 386.703 521.046 384.858 523.387 381.962C525.208 379.709 526.303 376.823 526.303 373.675V363.155C526.303 361.77 525.203 360.646 523.846 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 83.6483H263.147C260.062 83.6483 257.234 84.7646 255.027 86.6226C252.19 89.0123 250.382 92.6279 250.382 96.6747C250.382 99.8226 251.475 102.709 253.298 104.962C253.818 105.605 254.398 106.197 255.027 106.729C257.234 108.587 260.062 109.703 263.147 109.703C267.112 109.703 270.656 107.858 272.997 104.962C274.818 102.709 275.913 99.8226 275.913 96.6747V86.1549C275.913 84.7701 274.812 83.6465 273.455 83.6465V83.6483Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 360.648H263.147C260.062 360.648 257.234 361.765 255.027 363.623C252.19 366.012 250.382 369.628 250.382 373.675C250.382 376.823 251.475 379.709 253.298 381.962C253.818 382.605 254.398 383.197 255.027 383.729C257.234 385.587 260.062 386.703 263.147 386.703C267.112 386.703 270.656 384.858 272.997 381.962C274.818 379.709 275.913 376.823 275.913 373.675V363.155C275.913 361.77 274.812 360.646 273.455 360.646V360.648Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 111.533H12.7634C9.67848 111.533 6.85002 112.649 4.64364 114.507C1.80608 116.897 -0.00195312 120.513 -0.00195312 124.559C-0.00195312 127.707 1.09123 130.594 2.91382 132.847C3.43404 133.49 4.01428 134.082 4.64364 134.613C6.85002 136.471 9.67848 137.588 12.7634 137.588C16.7287 137.588 20.272 135.742 22.613 132.847C24.4338 130.594 25.5288 127.707 25.5288 124.559V114.04C25.5288 112.655 24.4283 111.531 23.0714 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 388.533H12.7634C9.67848 388.533 6.85002 389.649 4.64364 391.507C1.80608 393.897 -0.00195312 397.513 -0.00195312 401.559C-0.00195312 404.707 1.09123 407.594 2.91382 409.847C3.43404 410.49 4.01428 411.082 4.64364 411.613C6.85002 413.471 9.67848 414.588 12.7634 414.588C16.7287 414.588 20.272 412.742 22.613 409.847C24.4338 407.594 25.5288 404.707 25.5288 401.559V391.04C25.5288 389.655 24.4283 388.531 23.0714 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 111.533H290.975C287.89 111.533 285.062 112.649 282.856 114.507C280.018 116.897 278.21 120.513 278.21 124.559C278.21 127.707 279.303 130.594 281.126 132.847C281.646 133.49 282.226 134.082 282.856 134.613C285.062 136.471 287.89 137.588 290.975 137.588C294.941 137.588 298.484 135.742 300.825 132.847C302.646 130.594 303.741 127.707 303.741 124.559V114.04C303.741 112.655 302.64 111.531 301.283 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 388.533H290.975C287.89 388.533 285.062 389.649 282.856 391.507C280.018 393.897 278.21 397.513 278.21 401.559C278.21 404.707 279.303 407.594 281.126 409.847C281.646 410.49 282.226 411.082 282.856 411.613C285.062 413.471 287.89 414.588 290.975 414.588C294.941 414.588 298.484 412.742 300.825 409.847C302.646 407.594 303.741 404.707 303.741 401.559V391.04C303.741 389.655 302.64 388.531 301.283 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 111.533H40.5847C37.4998 111.533 34.6713 112.649 32.4649 114.507C29.6274 116.897 27.8193 120.513 27.8193 124.559C27.8193 127.707 28.9125 130.594 30.7351 132.847C31.2553 133.49 31.8356 134.082 32.4649 134.613C34.6713 136.471 37.4998 137.588 40.5847 137.588C44.55 137.588 48.0933 135.742 50.4343 132.847C52.2551 130.594 53.3501 127.707 53.3501 124.559V114.04C53.3501 112.655 52.2496 111.531 50.8927 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 388.533H40.5847C37.4998 388.533 34.6713 389.649 32.4649 391.507C29.6274 393.897 27.8193 397.513 27.8193 401.559C27.8193 404.707 28.9125 407.594 30.7351 409.847C31.2553 410.49 31.8356 411.082 32.4649 411.613C34.6713 413.471 37.4998 414.588 40.5847 414.588C44.55 414.588 48.0933 412.742 50.4343 409.847C52.2551 407.594 53.3501 404.707 53.3501 401.559V391.04C53.3501 389.655 52.2496 388.531 50.8927 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 111.533H318.796C315.711 111.533 312.882 112.649 310.676 114.507C307.838 116.897 306.03 120.513 306.03 124.559C306.03 127.707 307.123 130.594 308.946 132.847C309.466 133.49 310.047 134.082 310.676 134.613C312.882 136.471 315.711 137.588 318.796 137.588C322.761 137.588 326.304 135.742 328.645 132.847C330.466 130.594 331.561 127.707 331.561 124.559V114.04C331.561 112.655 330.461 111.531 329.104 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 388.533H318.796C315.711 388.533 312.882 389.649 310.676 391.507C307.838 393.897 306.03 397.513 306.03 401.559C306.03 404.707 307.123 407.594 308.946 409.847C309.466 410.49 310.047 411.082 310.676 411.613C312.882 413.471 315.711 414.588 318.796 414.588C322.761 414.588 326.304 412.742 328.645 409.847C330.466 407.594 331.561 404.707 331.561 401.559V391.04C331.561 389.655 330.461 388.531 329.104 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 111.533H68.405C65.3201 111.533 62.4916 112.649 60.2852 114.507C57.4477 116.897 55.6396 120.513 55.6396 124.559C55.6396 127.707 56.7328 130.594 58.5554 132.847C59.0756 133.49 59.6559 134.082 60.2852 134.613C62.4916 136.471 65.3201 137.588 68.405 137.588C72.3703 137.588 75.9136 135.742 78.2546 132.847C80.0754 130.594 81.1704 127.707 81.1704 124.559V114.04C81.1704 112.655 80.0699 111.531 78.713 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 388.533H68.405C65.3201 388.533 62.4916 389.649 60.2852 391.507C57.4477 393.897 55.6396 397.513 55.6396 401.559C55.6396 404.707 56.7328 407.594 58.5554 409.847C59.0756 410.49 59.6559 411.082 60.2852 411.613C62.4916 413.471 65.3201 414.588 68.405 414.588C72.3703 414.588 75.9136 412.742 78.2546 409.847C80.0754 407.594 81.1704 404.707 81.1704 401.559V391.04C81.1704 389.655 80.0699 388.531 78.713 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 111.533H346.617C343.532 111.533 340.704 112.649 338.497 114.507C335.66 116.897 333.852 120.513 333.852 124.559C333.852 127.707 334.945 130.594 336.767 132.847C337.288 133.49 337.868 134.082 338.497 134.613C340.704 136.471 343.532 137.588 346.617 137.588C350.582 137.588 354.126 135.742 356.467 132.847C358.287 130.594 359.382 127.707 359.382 124.559V114.04C359.382 112.655 358.282 111.531 356.925 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 388.533H346.617C343.532 388.533 340.704 389.649 338.497 391.507C335.66 393.897 333.852 397.513 333.852 401.559C333.852 404.707 334.945 407.594 336.767 409.847C337.288 410.49 337.868 411.082 338.497 411.613C340.704 413.471 343.532 414.588 346.617 414.588C350.582 414.588 354.126 412.742 356.467 409.847C358.287 407.594 359.382 404.707 359.382 401.559V391.04C359.382 389.655 358.282 388.531 356.925 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 111.533H96.2244C93.1394 111.533 90.311 112.649 88.1046 114.507C85.267 116.897 83.459 120.513 83.459 124.559C83.459 127.707 84.5522 130.594 86.3748 132.847C86.895 133.49 87.4752 134.082 88.1046 134.613C90.311 136.471 93.1394 137.588 96.2244 137.588C100.19 137.588 103.733 135.742 106.074 132.847C107.895 130.594 108.99 127.707 108.99 124.559V114.04C108.99 112.655 107.889 111.531 106.532 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 388.533H96.2244C93.1394 388.533 90.311 389.649 88.1046 391.507C85.267 393.897 83.459 397.513 83.459 401.559C83.459 404.707 84.5522 407.594 86.3748 409.847C86.895 410.49 87.4752 411.082 88.1046 411.613C90.311 413.471 93.1394 414.588 96.2244 414.588C100.19 414.588 103.733 412.742 106.074 409.847C107.895 407.594 108.99 404.707 108.99 401.559V391.04C108.99 389.655 107.889 388.531 106.532 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 111.533H374.435C371.35 111.533 368.522 112.649 366.316 114.507C363.478 116.897 361.67 120.513 361.67 124.559C361.67 127.707 362.763 130.594 364.586 132.847C365.106 133.49 365.686 134.082 366.316 134.613C368.522 136.471 371.35 137.588 374.435 137.588C378.401 137.588 381.944 135.742 384.285 132.847C386.106 130.594 387.201 127.707 387.201 124.559V114.04C387.201 112.655 386.1 111.531 384.743 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 388.533H374.435C371.35 388.533 368.522 389.649 366.316 391.507C363.478 393.897 361.67 397.513 361.67 401.559C361.67 404.707 362.763 407.594 364.586 409.847C365.106 410.49 365.686 411.082 366.316 411.613C368.522 413.471 371.35 414.588 374.435 414.588C378.401 414.588 381.944 412.742 384.285 409.847C386.106 407.594 387.201 404.707 387.201 401.559V391.04C387.201 389.655 386.1 388.531 384.743 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 111.533H124.045C120.96 111.533 118.131 112.649 115.925 114.507C113.087 116.897 111.279 120.513 111.279 124.559C111.279 127.707 112.372 130.594 114.195 132.847C114.715 133.49 115.296 134.082 115.925 134.613C118.131 136.471 120.96 137.588 124.045 137.588C128.01 137.588 131.553 135.742 133.894 132.847C135.715 130.594 136.81 127.707 136.81 124.559V114.04C136.81 112.655 135.71 111.531 134.353 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 388.533H124.045C120.96 388.533 118.131 389.649 115.925 391.507C113.087 393.897 111.279 397.513 111.279 401.559C111.279 404.707 112.372 407.594 114.195 409.847C114.715 410.49 115.296 411.082 115.925 411.613C118.131 413.471 120.96 414.588 124.045 414.588C128.01 414.588 131.553 412.742 133.894 409.847C135.715 407.594 136.81 404.707 136.81 401.559V391.04C136.81 389.655 135.71 388.531 134.353 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 111.533H402.257C399.172 111.533 396.343 112.649 394.137 114.507C391.299 116.897 389.491 120.513 389.491 124.559C389.491 127.707 390.584 130.594 392.407 132.847C392.927 133.49 393.507 134.082 394.137 134.613C396.343 136.471 399.172 137.588 402.257 137.588C406.222 137.588 409.765 135.742 412.106 132.847C413.927 130.594 415.022 127.707 415.022 124.559V114.04C415.022 112.655 413.921 111.531 412.565 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 388.533H402.257C399.172 388.533 396.343 389.649 394.137 391.507C391.299 393.897 389.491 397.513 389.491 401.559C389.491 404.707 390.584 407.594 392.407 409.847C392.927 410.49 393.507 411.082 394.137 411.613C396.343 413.471 399.172 414.588 402.257 414.588C406.222 414.588 409.765 412.742 412.106 409.847C413.927 407.594 415.022 404.707 415.022 401.559V391.04C415.022 389.655 413.921 388.531 412.565 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 111.533H151.866C148.781 111.533 145.953 112.649 143.746 114.507C140.909 116.897 139.101 120.513 139.101 124.559C139.101 127.707 140.194 130.594 142.016 132.847C142.537 133.49 143.117 134.082 143.746 134.613C145.953 136.471 148.781 137.588 151.866 137.588C155.831 137.588 159.375 135.742 161.716 132.847C163.536 130.594 164.631 127.707 164.631 124.559V114.04C164.631 112.655 163.531 111.531 162.174 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 388.533H151.866C148.781 388.533 145.953 389.649 143.746 391.507C140.909 393.897 139.101 397.513 139.101 401.559C139.101 404.707 140.194 407.594 142.016 409.847C142.537 410.49 143.117 411.082 143.746 411.613C145.953 413.471 148.781 414.588 151.866 414.588C155.831 414.588 159.375 412.742 161.716 409.847C163.536 407.594 164.631 404.707 164.631 401.559V391.04C164.631 389.655 163.531 388.531 162.174 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 111.533H430.077C426.992 111.533 424.164 112.649 421.957 114.507C419.12 116.897 417.312 120.513 417.312 124.559C417.312 127.707 418.405 130.594 420.227 132.847C420.748 133.49 421.328 134.082 421.957 134.613C424.164 136.471 426.992 137.588 430.077 137.588C434.042 137.588 437.586 135.742 439.926 132.847C441.747 130.594 442.842 127.707 442.842 124.559V114.04C442.842 112.655 441.742 111.531 440.385 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 388.533H430.077C426.992 388.533 424.164 389.649 421.957 391.507C419.12 393.897 417.312 397.513 417.312 401.559C417.312 404.707 418.405 407.594 420.227 409.847C420.748 410.49 421.328 411.082 421.957 411.613C424.164 413.471 426.992 414.588 430.077 414.588C434.042 414.588 437.586 412.742 439.926 409.847C441.747 407.594 442.842 404.707 442.842 401.559V391.04C442.842 389.655 441.742 388.531 440.385 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 111.533H179.684C176.599 111.533 173.771 112.649 171.565 114.507C168.727 116.897 166.919 120.513 166.919 124.559C166.919 127.707 168.012 130.594 169.835 132.847C170.355 133.49 170.935 134.082 171.565 134.613C173.771 136.471 176.599 137.588 179.684 137.588C183.65 137.588 187.193 135.742 189.534 132.847C191.355 130.594 192.45 127.707 192.45 124.559V114.04C192.45 112.655 191.349 111.531 189.992 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 388.533H179.684C176.599 388.533 173.771 389.649 171.565 391.507C168.727 393.897 166.919 397.513 166.919 401.559C166.919 404.707 168.012 407.594 169.835 409.847C170.355 410.49 170.935 411.082 171.565 411.613C173.771 413.471 176.599 414.588 179.684 414.588C183.65 414.588 187.193 412.742 189.534 409.847C191.355 407.594 192.45 404.707 192.45 401.559V391.04C192.45 389.655 191.349 388.531 189.992 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 111.533H457.896C454.811 111.533 451.983 112.649 449.776 114.507C446.939 116.897 445.131 120.513 445.131 124.559C445.131 127.707 446.224 130.594 448.047 132.847C448.567 133.49 449.147 134.082 449.776 134.613C451.983 136.471 454.811 137.588 457.896 137.588C461.862 137.588 465.405 135.742 467.746 132.847C469.567 130.594 470.662 127.707 470.662 124.559V114.04C470.662 112.655 469.561 111.531 468.204 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 388.533H457.896C454.811 388.533 451.983 389.649 449.776 391.507C446.939 393.897 445.131 397.513 445.131 401.559C445.131 404.707 446.224 407.594 448.047 409.847C448.567 410.49 449.147 411.082 449.776 411.613C451.983 413.471 454.811 414.588 457.896 414.588C461.862 414.588 465.405 412.742 467.746 409.847C469.567 407.594 470.662 404.707 470.662 401.559V391.04C470.662 389.655 469.561 388.531 468.204 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 111.533H207.506C204.421 111.533 201.592 112.649 199.386 114.507C196.548 116.897 194.74 120.513 194.74 124.559C194.74 127.707 195.833 130.594 197.656 132.847C198.176 133.49 198.756 134.082 199.386 134.613C201.592 136.471 204.421 137.588 207.506 137.588C211.471 137.588 215.014 135.742 217.355 132.847C219.176 130.594 220.271 127.707 220.271 124.559V114.04C220.271 112.655 219.171 111.531 217.814 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 388.533H207.506C204.421 388.533 201.592 389.649 199.386 391.507C196.548 393.897 194.74 397.513 194.74 401.559C194.74 404.707 195.833 407.594 197.656 409.847C198.176 410.49 198.756 411.082 199.386 411.613C201.592 413.471 204.421 414.588 207.506 414.588C211.471 414.588 215.014 412.742 217.355 409.847C219.176 407.594 220.271 404.707 220.271 401.559V391.04C220.271 389.655 219.171 388.531 217.814 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 111.533H485.717C482.632 111.533 479.803 112.649 477.597 114.507C474.759 116.897 472.951 120.513 472.951 124.559C472.951 127.707 474.044 130.594 475.867 132.847C476.387 133.49 476.967 134.082 477.597 134.613C479.803 136.471 482.632 137.588 485.717 137.588C489.682 137.588 493.225 135.742 495.566 132.847C497.387 130.594 498.482 127.707 498.482 124.559V114.04C498.482 112.655 497.381 111.531 496.025 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 388.533H485.717C482.632 388.533 479.803 389.649 477.597 391.507C474.759 393.897 472.951 397.513 472.951 401.559C472.951 404.707 474.044 407.594 475.867 409.847C476.387 410.49 476.967 411.082 477.597 411.613C479.803 413.471 482.632 414.588 485.717 414.588C489.682 414.588 493.225 412.742 495.566 409.847C497.387 407.594 498.482 404.707 498.482 401.559V391.04C498.482 389.655 497.381 388.531 496.025 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 111.533H235.326C232.241 111.533 229.413 112.649 227.206 114.507C224.369 116.897 222.561 120.513 222.561 124.559C222.561 127.707 223.654 130.594 225.476 132.847C225.997 133.49 226.577 134.082 227.206 134.613C229.413 136.471 232.241 137.588 235.326 137.588C239.291 137.588 242.835 135.742 245.176 132.847C246.996 130.594 248.091 127.707 248.091 124.559V114.04C248.091 112.655 246.991 111.531 245.634 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 388.533H235.326C232.241 388.533 229.413 389.649 227.206 391.507C224.369 393.897 222.561 397.513 222.561 401.559C222.561 404.707 223.654 407.594 225.476 409.847C225.997 410.49 226.577 411.082 227.206 411.613C229.413 413.471 232.241 414.588 235.326 414.588C239.291 414.588 242.835 412.742 245.176 409.847C246.996 407.594 248.091 404.707 248.091 401.559V391.04C248.091 389.655 246.991 388.531 245.634 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 111.533H513.538C510.453 111.533 507.624 112.649 505.418 114.507C502.581 116.897 500.772 120.513 500.772 124.559C500.772 127.707 501.866 130.594 503.688 132.847C504.208 133.49 504.789 134.082 505.418 134.613C507.624 136.471 510.453 137.588 513.538 137.588C517.503 137.588 521.046 135.742 523.387 132.847C525.208 130.594 526.303 127.707 526.303 124.559V114.04C526.303 112.655 525.203 111.531 523.846 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 388.533H513.538C510.453 388.533 507.624 389.649 505.418 391.507C502.581 393.897 500.772 397.513 500.772 401.559C500.772 404.707 501.866 407.594 503.688 409.847C504.208 410.49 504.789 411.082 505.418 411.613C507.624 413.471 510.453 414.588 513.538 414.588C517.503 414.588 521.046 412.742 523.387 409.847C525.208 407.594 526.303 404.707 526.303 401.559V391.04C526.303 389.655 525.203 388.531 523.846 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 111.533H263.147C260.062 111.533 257.234 112.649 255.027 114.507C252.19 116.897 250.382 120.513 250.382 124.559C250.382 127.707 251.475 130.594 253.298 132.847C253.818 133.49 254.398 134.082 255.027 134.613C257.234 136.471 260.062 137.588 263.147 137.588C267.112 137.588 270.656 135.742 272.997 132.847C274.818 130.594 275.913 127.707 275.913 124.559V114.04C275.913 112.655 274.812 111.531 273.455 111.531V111.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 388.533H263.147C260.062 388.533 257.234 389.649 255.027 391.507C252.19 393.897 250.382 397.513 250.382 401.559C250.382 404.707 251.475 407.594 253.298 409.847C253.818 410.49 254.398 411.082 255.027 411.613C257.234 413.471 260.062 414.588 263.147 414.588C267.112 414.588 270.656 412.742 272.997 409.847C274.818 407.594 275.913 404.707 275.913 401.559V391.04C275.913 389.655 274.812 388.531 273.455 388.531V388.533Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 139.414H12.7634C9.67848 139.414 6.85002 140.53 4.64364 142.388C1.80608 144.778 -0.00195312 148.394 -0.00195312 152.44C-0.00195312 155.588 1.09123 158.475 2.91382 160.728C3.43404 161.371 4.01428 161.963 4.64364 162.494C6.85002 164.352 9.67848 165.469 12.7634 165.469C16.7287 165.469 20.272 163.623 22.613 160.728C24.4338 158.475 25.5288 155.588 25.5288 152.44V141.921C25.5288 140.536 24.4283 139.412 23.0714 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 416.414H12.7634C9.67848 416.414 6.85002 417.53 4.64364 419.388C1.80608 421.778 -0.00195312 425.394 -0.00195312 429.44C-0.00195312 432.588 1.09123 435.475 2.91382 437.728C3.43404 438.371 4.01428 438.963 4.64364 439.494C6.85002 441.352 9.67848 442.469 12.7634 442.469C16.7287 442.469 20.272 440.623 22.613 437.728C24.4338 435.475 25.5288 432.588 25.5288 429.44V418.921C25.5288 417.536 24.4283 416.412 23.0714 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 139.414H290.975C287.89 139.414 285.062 140.53 282.856 142.388C280.018 144.778 278.21 148.394 278.21 152.44C278.21 155.588 279.303 158.475 281.126 160.728C281.646 161.371 282.226 161.963 282.856 162.494C285.062 164.352 287.89 165.469 290.975 165.469C294.941 165.469 298.484 163.623 300.825 160.728C302.646 158.475 303.741 155.588 303.741 152.44V141.921C303.741 140.536 302.64 139.412 301.283 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 416.414H290.975C287.89 416.414 285.062 417.53 282.856 419.388C280.018 421.778 278.21 425.394 278.21 429.44C278.21 432.588 279.303 435.475 281.126 437.728C281.646 438.371 282.226 438.963 282.856 439.494C285.062 441.352 287.89 442.469 290.975 442.469C294.941 442.469 298.484 440.623 300.825 437.728C302.646 435.475 303.741 432.588 303.741 429.44V418.921C303.741 417.536 302.64 416.412 301.283 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 139.414H40.5847C37.4998 139.414 34.6713 140.53 32.4649 142.388C29.6274 144.778 27.8193 148.394 27.8193 152.44C27.8193 155.588 28.9125 158.475 30.7351 160.728C31.2553 161.371 31.8356 161.963 32.4649 162.494C34.6713 164.352 37.4998 165.469 40.5847 165.469C44.55 165.469 48.0933 163.623 50.4343 160.728C52.2551 158.475 53.3501 155.588 53.3501 152.44V141.921C53.3501 140.536 52.2496 139.412 50.8927 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 416.414H40.5847C37.4998 416.414 34.6713 417.53 32.4649 419.388C29.6274 421.778 27.8193 425.394 27.8193 429.44C27.8193 432.588 28.9125 435.475 30.7351 437.728C31.2553 438.371 31.8356 438.963 32.4649 439.494C34.6713 441.352 37.4998 442.469 40.5847 442.469C44.55 442.469 48.0933 440.623 50.4343 437.728C52.2551 435.475 53.3501 432.588 53.3501 429.44V418.921C53.3501 417.536 52.2496 416.412 50.8927 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 139.414H318.796C315.711 139.414 312.882 140.53 310.676 142.388C307.838 144.778 306.03 148.394 306.03 152.44C306.03 155.588 307.123 158.475 308.946 160.728C309.466 161.371 310.047 161.963 310.676 162.494C312.882 164.352 315.711 165.469 318.796 165.469C322.761 165.469 326.304 163.623 328.645 160.728C330.466 158.475 331.561 155.588 331.561 152.44V141.921C331.561 140.536 330.461 139.412 329.104 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 416.414H318.796C315.711 416.414 312.882 417.53 310.676 419.388C307.838 421.778 306.03 425.394 306.03 429.44C306.03 432.588 307.123 435.475 308.946 437.728C309.466 438.371 310.047 438.963 310.676 439.494C312.882 441.352 315.711 442.469 318.796 442.469C322.761 442.469 326.304 440.623 328.645 437.728C330.466 435.475 331.561 432.588 331.561 429.44V418.921C331.561 417.536 330.461 416.412 329.104 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 139.414H68.405C65.3201 139.414 62.4916 140.53 60.2852 142.388C57.4477 144.778 55.6396 148.394 55.6396 152.44C55.6396 155.588 56.7328 158.475 58.5554 160.728C59.0756 161.371 59.6559 161.963 60.2852 162.494C62.4916 164.352 65.3201 165.469 68.405 165.469C72.3703 165.469 75.9136 163.623 78.2546 160.728C80.0754 158.475 81.1704 155.588 81.1704 152.44V141.921C81.1704 140.536 80.0699 139.412 78.713 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 416.414H68.405C65.3201 416.414 62.4916 417.53 60.2852 419.388C57.4477 421.778 55.6396 425.394 55.6396 429.44C55.6396 432.588 56.7328 435.475 58.5554 437.728C59.0756 438.371 59.6559 438.963 60.2852 439.494C62.4916 441.352 65.3201 442.469 68.405 442.469C72.3703 442.469 75.9136 440.623 78.2546 437.728C80.0754 435.475 81.1704 432.588 81.1704 429.44V418.921C81.1704 417.536 80.0699 416.412 78.713 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 139.414H346.617C343.532 139.414 340.704 140.53 338.497 142.388C335.66 144.778 333.852 148.394 333.852 152.44C333.852 155.588 334.945 158.475 336.767 160.728C337.288 161.371 337.868 161.963 338.497 162.494C340.704 164.352 343.532 165.469 346.617 165.469C350.582 165.469 354.126 163.623 356.467 160.728C358.287 158.475 359.382 155.588 359.382 152.44V141.921C359.382 140.536 358.282 139.412 356.925 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 416.414H346.617C343.532 416.414 340.704 417.53 338.497 419.388C335.66 421.778 333.852 425.394 333.852 429.44C333.852 432.588 334.945 435.475 336.767 437.728C337.288 438.371 337.868 438.963 338.497 439.494C340.704 441.352 343.532 442.469 346.617 442.469C350.582 442.469 354.126 440.623 356.467 437.728C358.287 435.475 359.382 432.588 359.382 429.44V418.921C359.382 417.536 358.282 416.412 356.925 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 139.414H96.2244C93.1394 139.414 90.311 140.53 88.1046 142.388C85.267 144.778 83.459 148.394 83.459 152.44C83.459 155.588 84.5522 158.475 86.3748 160.728C86.895 161.371 87.4752 161.963 88.1046 162.494C90.311 164.352 93.1394 165.469 96.2244 165.469C100.19 165.469 103.733 163.623 106.074 160.728C107.895 158.475 108.99 155.588 108.99 152.44V141.921C108.99 140.536 107.889 139.412 106.532 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 416.414H96.2244C93.1394 416.414 90.311 417.53 88.1046 419.388C85.267 421.778 83.459 425.394 83.459 429.44C83.459 432.588 84.5522 435.475 86.3748 437.728C86.895 438.371 87.4752 438.963 88.1046 439.494C90.311 441.352 93.1394 442.469 96.2244 442.469C100.19 442.469 103.733 440.623 106.074 437.728C107.895 435.475 108.99 432.588 108.99 429.44V418.921C108.99 417.536 107.889 416.412 106.532 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 139.414H374.435C371.35 139.414 368.522 140.53 366.316 142.388C363.478 144.778 361.67 148.394 361.67 152.44C361.67 155.588 362.763 158.475 364.586 160.728C365.106 161.371 365.686 161.963 366.316 162.494C368.522 164.352 371.35 165.469 374.435 165.469C378.401 165.469 381.944 163.623 384.285 160.728C386.106 158.475 387.201 155.588 387.201 152.44V141.921C387.201 140.536 386.1 139.412 384.743 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 416.414H374.435C371.35 416.414 368.522 417.53 366.316 419.388C363.478 421.778 361.67 425.394 361.67 429.44C361.67 432.588 362.763 435.475 364.586 437.728C365.106 438.371 365.686 438.963 366.316 439.494C368.522 441.352 371.35 442.469 374.435 442.469C378.401 442.469 381.944 440.623 384.285 437.728C386.106 435.475 387.201 432.588 387.201 429.44V418.921C387.201 417.536 386.1 416.412 384.743 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 139.414H124.045C120.96 139.414 118.131 140.53 115.925 142.388C113.087 144.778 111.279 148.394 111.279 152.44C111.279 155.588 112.372 158.475 114.195 160.728C114.715 161.371 115.296 161.963 115.925 162.494C118.131 164.352 120.96 165.469 124.045 165.469C128.01 165.469 131.553 163.623 133.894 160.728C135.715 158.475 136.81 155.588 136.81 152.44V141.921C136.81 140.536 135.71 139.412 134.353 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 416.414H124.045C120.96 416.414 118.131 417.53 115.925 419.388C113.087 421.778 111.279 425.394 111.279 429.44C111.279 432.588 112.372 435.475 114.195 437.728C114.715 438.371 115.296 438.963 115.925 439.494C118.131 441.352 120.96 442.469 124.045 442.469C128.01 442.469 131.553 440.623 133.894 437.728C135.715 435.475 136.81 432.588 136.81 429.44V418.921C136.81 417.536 135.71 416.412 134.353 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 139.414H402.257C399.172 139.414 396.343 140.53 394.137 142.388C391.299 144.778 389.491 148.394 389.491 152.44C389.491 155.588 390.584 158.475 392.407 160.728C392.927 161.371 393.507 161.963 394.137 162.494C396.343 164.352 399.172 165.469 402.257 165.469C406.222 165.469 409.765 163.623 412.106 160.728C413.927 158.475 415.022 155.588 415.022 152.44V141.921C415.022 140.536 413.921 139.412 412.565 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 416.414H402.257C399.172 416.414 396.343 417.53 394.137 419.388C391.299 421.778 389.491 425.394 389.491 429.44C389.491 432.588 390.584 435.475 392.407 437.728C392.927 438.371 393.507 438.963 394.137 439.494C396.343 441.352 399.172 442.469 402.257 442.469C406.222 442.469 409.765 440.623 412.106 437.728C413.927 435.475 415.022 432.588 415.022 429.44V418.921C415.022 417.536 413.921 416.412 412.565 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 139.414H151.866C148.781 139.414 145.953 140.53 143.746 142.388C140.909 144.778 139.101 148.394 139.101 152.44C139.101 155.588 140.194 158.475 142.016 160.728C142.537 161.371 143.117 161.963 143.746 162.494C145.953 164.352 148.781 165.469 151.866 165.469C155.831 165.469 159.375 163.623 161.716 160.728C163.536 158.475 164.631 155.588 164.631 152.44V141.921C164.631 140.536 163.531 139.412 162.174 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 416.414H151.866C148.781 416.414 145.953 417.53 143.746 419.388C140.909 421.778 139.101 425.394 139.101 429.44C139.101 432.588 140.194 435.475 142.016 437.728C142.537 438.371 143.117 438.963 143.746 439.494C145.953 441.352 148.781 442.469 151.866 442.469C155.831 442.469 159.375 440.623 161.716 437.728C163.536 435.475 164.631 432.588 164.631 429.44V418.921C164.631 417.536 163.531 416.412 162.174 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 139.414H430.077C426.992 139.414 424.164 140.53 421.957 142.388C419.12 144.778 417.312 148.394 417.312 152.44C417.312 155.588 418.405 158.475 420.227 160.728C420.748 161.371 421.328 161.963 421.957 162.494C424.164 164.352 426.992 165.469 430.077 165.469C434.042 165.469 437.586 163.623 439.926 160.728C441.747 158.475 442.842 155.588 442.842 152.44V141.921C442.842 140.536 441.742 139.412 440.385 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 416.414H430.077C426.992 416.414 424.164 417.53 421.957 419.388C419.12 421.778 417.312 425.394 417.312 429.44C417.312 432.588 418.405 435.475 420.227 437.728C420.748 438.371 421.328 438.963 421.957 439.494C424.164 441.352 426.992 442.469 430.077 442.469C434.042 442.469 437.586 440.623 439.926 437.728C441.747 435.475 442.842 432.588 442.842 429.44V418.921C442.842 417.536 441.742 416.412 440.385 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 139.414H179.684C176.599 139.414 173.771 140.53 171.565 142.388C168.727 144.778 166.919 148.394 166.919 152.44C166.919 155.588 168.012 158.475 169.835 160.728C170.355 161.371 170.935 161.963 171.565 162.494C173.771 164.352 176.599 165.469 179.684 165.469C183.65 165.469 187.193 163.623 189.534 160.728C191.355 158.475 192.45 155.588 192.45 152.44V141.921C192.45 140.536 191.349 139.412 189.992 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 416.414H179.684C176.599 416.414 173.771 417.53 171.565 419.388C168.727 421.778 166.919 425.394 166.919 429.44C166.919 432.588 168.012 435.475 169.835 437.728C170.355 438.371 170.935 438.963 171.565 439.494C173.771 441.352 176.599 442.469 179.684 442.469C183.65 442.469 187.193 440.623 189.534 437.728C191.355 435.475 192.45 432.588 192.45 429.44V418.921C192.45 417.536 191.349 416.412 189.992 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 139.414H457.896C454.811 139.414 451.983 140.53 449.776 142.388C446.939 144.778 445.131 148.394 445.131 152.44C445.131 155.588 446.224 158.475 448.047 160.728C448.567 161.371 449.147 161.963 449.776 162.494C451.983 164.352 454.811 165.469 457.896 165.469C461.862 165.469 465.405 163.623 467.746 160.728C469.567 158.475 470.662 155.588 470.662 152.44V141.921C470.662 140.536 469.561 139.412 468.204 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 416.414H457.896C454.811 416.414 451.983 417.53 449.776 419.388C446.939 421.778 445.131 425.394 445.131 429.44C445.131 432.588 446.224 435.475 448.047 437.728C448.567 438.371 449.147 438.963 449.776 439.494C451.983 441.352 454.811 442.469 457.896 442.469C461.862 442.469 465.405 440.623 467.746 437.728C469.567 435.475 470.662 432.588 470.662 429.44V418.921C470.662 417.536 469.561 416.412 468.204 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 139.414H207.506C204.421 139.414 201.592 140.53 199.386 142.388C196.548 144.778 194.74 148.394 194.74 152.44C194.74 155.588 195.833 158.475 197.656 160.728C198.176 161.371 198.756 161.963 199.386 162.494C201.592 164.352 204.421 165.469 207.506 165.469C211.471 165.469 215.014 163.623 217.355 160.728C219.176 158.475 220.271 155.588 220.271 152.44V141.921C220.271 140.536 219.171 139.412 217.814 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 416.414H207.506C204.421 416.414 201.592 417.53 199.386 419.388C196.548 421.778 194.74 425.394 194.74 429.44C194.74 432.588 195.833 435.475 197.656 437.728C198.176 438.371 198.756 438.963 199.386 439.494C201.592 441.352 204.421 442.469 207.506 442.469C211.471 442.469 215.014 440.623 217.355 437.728C219.176 435.475 220.271 432.588 220.271 429.44V418.921C220.271 417.536 219.171 416.412 217.814 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 139.414H485.717C482.632 139.414 479.803 140.53 477.597 142.388C474.759 144.778 472.951 148.394 472.951 152.44C472.951 155.588 474.044 158.475 475.867 160.728C476.387 161.371 476.967 161.963 477.597 162.494C479.803 164.352 482.632 165.469 485.717 165.469C489.682 165.469 493.225 163.623 495.566 160.728C497.387 158.475 498.482 155.588 498.482 152.44V141.921C498.482 140.536 497.381 139.412 496.025 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 416.414H485.717C482.632 416.414 479.803 417.53 477.597 419.388C474.759 421.778 472.951 425.394 472.951 429.44C472.951 432.588 474.044 435.475 475.867 437.728C476.387 438.371 476.967 438.963 477.597 439.494C479.803 441.352 482.632 442.469 485.717 442.469C489.682 442.469 493.225 440.623 495.566 437.728C497.387 435.475 498.482 432.588 498.482 429.44V418.921C498.482 417.536 497.381 416.412 496.025 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 139.414H235.326C232.241 139.414 229.413 140.53 227.206 142.388C224.369 144.778 222.561 148.394 222.561 152.44C222.561 155.588 223.654 158.475 225.476 160.728C225.997 161.371 226.577 161.963 227.206 162.494C229.413 164.352 232.241 165.469 235.326 165.469C239.291 165.469 242.835 163.623 245.176 160.728C246.996 158.475 248.091 155.588 248.091 152.44V141.921C248.091 140.536 246.991 139.412 245.634 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 416.414H235.326C232.241 416.414 229.413 417.53 227.206 419.388C224.369 421.778 222.561 425.394 222.561 429.44C222.561 432.588 223.654 435.475 225.476 437.728C225.997 438.371 226.577 438.963 227.206 439.494C229.413 441.352 232.241 442.469 235.326 442.469C239.291 442.469 242.835 440.623 245.176 437.728C246.996 435.475 248.091 432.588 248.091 429.44V418.921C248.091 417.536 246.991 416.412 245.634 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 139.414H513.538C510.453 139.414 507.624 140.53 505.418 142.388C502.581 144.778 500.772 148.394 500.772 152.44C500.772 155.588 501.866 158.475 503.688 160.728C504.208 161.371 504.789 161.963 505.418 162.494C507.624 164.352 510.453 165.469 513.538 165.469C517.503 165.469 521.046 163.623 523.387 160.728C525.208 158.475 526.303 155.588 526.303 152.44V141.921C526.303 140.536 525.203 139.412 523.846 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 416.414H513.538C510.453 416.414 507.624 417.53 505.418 419.388C502.581 421.778 500.772 425.394 500.772 429.44C500.772 432.588 501.866 435.475 503.688 437.728C504.208 438.371 504.789 438.963 505.418 439.494C507.624 441.352 510.453 442.469 513.538 442.469C517.503 442.469 521.046 440.623 523.387 437.728C525.208 435.475 526.303 432.588 526.303 429.44V418.921C526.303 417.536 525.203 416.412 523.846 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 139.414H263.147C260.062 139.414 257.234 140.53 255.027 142.388C252.19 144.778 250.382 148.394 250.382 152.44C250.382 155.588 251.475 158.475 253.298 160.728C253.818 161.371 254.398 161.963 255.027 162.494C257.234 164.352 260.062 165.469 263.147 165.469C267.112 165.469 270.656 163.623 272.997 160.728C274.818 158.475 275.913 155.588 275.913 152.44V141.921C275.913 140.536 274.812 139.412 273.455 139.412V139.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 416.414H263.147C260.062 416.414 257.234 417.53 255.027 419.388C252.19 421.778 250.382 425.394 250.382 429.44C250.382 432.588 251.475 435.475 253.298 437.728C253.818 438.371 254.398 438.963 255.027 439.494C257.234 441.352 260.062 442.469 263.147 442.469C267.112 442.469 270.656 440.623 272.997 437.728C274.818 435.475 275.913 432.588 275.913 429.44V418.921C275.913 417.536 274.812 416.412 273.455 416.412V416.414Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 167.297H12.7634C9.67848 167.297 6.85002 168.413 4.64364 170.271C1.80608 172.661 -0.00195312 176.276 -0.00195312 180.323C-0.00195312 183.471 1.09123 186.358 2.91382 188.61C3.43404 189.253 4.01428 189.845 4.64364 190.377C6.85002 192.235 9.67848 193.351 12.7634 193.351C16.7287 193.351 20.272 191.506 22.613 188.61C24.4338 186.358 25.5288 183.471 25.5288 180.323V169.803C25.5288 168.419 24.4283 167.295 23.0714 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 444.297H12.7634C9.67848 444.297 6.85002 445.413 4.64364 447.271C1.80608 449.661 -0.00195312 453.276 -0.00195312 457.323C-0.00195312 460.471 1.09123 463.358 2.91382 465.61C3.43404 466.253 4.01428 466.845 4.64364 467.377C6.85002 469.235 9.67848 470.351 12.7634 470.351C16.7287 470.351 20.272 468.506 22.613 465.61C24.4338 463.358 25.5288 460.471 25.5288 457.323V446.803C25.5288 445.419 24.4283 444.295 23.0714 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 167.297H290.975C287.89 167.297 285.062 168.413 282.856 170.271C280.018 172.661 278.21 176.276 278.21 180.323C278.21 183.471 279.303 186.358 281.126 188.61C281.646 189.253 282.226 189.845 282.856 190.377C285.062 192.235 287.89 193.351 290.975 193.351C294.941 193.351 298.484 191.506 300.825 188.61C302.646 186.358 303.741 183.471 303.741 180.323V169.803C303.741 168.419 302.64 167.295 301.283 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 444.297H290.975C287.89 444.297 285.062 445.413 282.856 447.271C280.018 449.661 278.21 453.276 278.21 457.323C278.21 460.471 279.303 463.358 281.126 465.61C281.646 466.253 282.226 466.845 282.856 467.377C285.062 469.235 287.89 470.351 290.975 470.351C294.941 470.351 298.484 468.506 300.825 465.61C302.646 463.358 303.741 460.471 303.741 457.323V446.803C303.741 445.419 302.64 444.295 301.283 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 167.297H40.5847C37.4998 167.297 34.6713 168.413 32.4649 170.271C29.6274 172.661 27.8193 176.276 27.8193 180.323C27.8193 183.471 28.9125 186.358 30.7351 188.61C31.2553 189.253 31.8356 189.845 32.4649 190.377C34.6713 192.235 37.4998 193.351 40.5847 193.351C44.55 193.351 48.0933 191.506 50.4343 188.61C52.2551 186.358 53.3501 183.471 53.3501 180.323V169.803C53.3501 168.419 52.2496 167.295 50.8927 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 444.297H40.5847C37.4998 444.297 34.6713 445.413 32.4649 447.271C29.6274 449.661 27.8193 453.276 27.8193 457.323C27.8193 460.471 28.9125 463.358 30.7351 465.61C31.2553 466.253 31.8356 466.845 32.4649 467.377C34.6713 469.235 37.4998 470.351 40.5847 470.351C44.55 470.351 48.0933 468.506 50.4343 465.61C52.2551 463.358 53.3501 460.471 53.3501 457.323V446.803C53.3501 445.419 52.2496 444.295 50.8927 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 167.297H318.796C315.711 167.297 312.882 168.413 310.676 170.271C307.838 172.661 306.03 176.276 306.03 180.323C306.03 183.471 307.123 186.358 308.946 188.61C309.466 189.253 310.047 189.845 310.676 190.377C312.882 192.235 315.711 193.351 318.796 193.351C322.761 193.351 326.304 191.506 328.645 188.61C330.466 186.358 331.561 183.471 331.561 180.323V169.803C331.561 168.419 330.461 167.295 329.104 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 444.297H318.796C315.711 444.297 312.882 445.413 310.676 447.271C307.838 449.661 306.03 453.276 306.03 457.323C306.03 460.471 307.123 463.358 308.946 465.61C309.466 466.253 310.047 466.845 310.676 467.377C312.882 469.235 315.711 470.351 318.796 470.351C322.761 470.351 326.304 468.506 328.645 465.61C330.466 463.358 331.561 460.471 331.561 457.323V446.803C331.561 445.419 330.461 444.295 329.104 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 167.297H68.405C65.3201 167.297 62.4916 168.413 60.2852 170.271C57.4477 172.661 55.6396 176.276 55.6396 180.323C55.6396 183.471 56.7328 186.358 58.5554 188.61C59.0756 189.253 59.6559 189.845 60.2852 190.377C62.4916 192.235 65.3201 193.351 68.405 193.351C72.3703 193.351 75.9136 191.506 78.2546 188.61C80.0754 186.358 81.1704 183.471 81.1704 180.323V169.803C81.1704 168.419 80.0699 167.295 78.713 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 444.297H68.405C65.3201 444.297 62.4916 445.413 60.2852 447.271C57.4477 449.661 55.6396 453.276 55.6396 457.323C55.6396 460.471 56.7328 463.358 58.5554 465.61C59.0756 466.253 59.6559 466.845 60.2852 467.377C62.4916 469.235 65.3201 470.351 68.405 470.351C72.3703 470.351 75.9136 468.506 78.2546 465.61C80.0754 463.358 81.1704 460.471 81.1704 457.323V446.803C81.1704 445.419 80.0699 444.295 78.713 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 167.297H346.617C343.532 167.297 340.704 168.413 338.497 170.271C335.66 172.661 333.852 176.276 333.852 180.323C333.852 183.471 334.945 186.358 336.767 188.61C337.288 189.253 337.868 189.845 338.497 190.377C340.704 192.235 343.532 193.351 346.617 193.351C350.582 193.351 354.126 191.506 356.467 188.61C358.287 186.358 359.382 183.471 359.382 180.323V169.803C359.382 168.419 358.282 167.295 356.925 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 444.297H346.617C343.532 444.297 340.704 445.413 338.497 447.271C335.66 449.661 333.852 453.276 333.852 457.323C333.852 460.471 334.945 463.358 336.767 465.61C337.288 466.253 337.868 466.845 338.497 467.377C340.704 469.235 343.532 470.351 346.617 470.351C350.582 470.351 354.126 468.506 356.467 465.61C358.287 463.358 359.382 460.471 359.382 457.323V446.803C359.382 445.419 358.282 444.295 356.925 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 167.297H96.2244C93.1394 167.297 90.311 168.413 88.1046 170.271C85.267 172.661 83.459 176.276 83.459 180.323C83.459 183.471 84.5522 186.358 86.3748 188.61C86.895 189.253 87.4752 189.845 88.1046 190.377C90.311 192.235 93.1394 193.351 96.2244 193.351C100.19 193.351 103.733 191.506 106.074 188.61C107.895 186.358 108.99 183.471 108.99 180.323V169.803C108.99 168.419 107.889 167.295 106.532 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 444.297H96.2244C93.1394 444.297 90.311 445.413 88.1046 447.271C85.267 449.661 83.459 453.276 83.459 457.323C83.459 460.471 84.5522 463.358 86.3748 465.61C86.895 466.253 87.4752 466.845 88.1046 467.377C90.311 469.235 93.1394 470.351 96.2244 470.351C100.19 470.351 103.733 468.506 106.074 465.61C107.895 463.358 108.99 460.471 108.99 457.323V446.803C108.99 445.419 107.889 444.295 106.532 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 167.297H374.435C371.35 167.297 368.522 168.413 366.316 170.271C363.478 172.661 361.67 176.276 361.67 180.323C361.67 183.471 362.763 186.358 364.586 188.61C365.106 189.253 365.686 189.845 366.316 190.377C368.522 192.235 371.35 193.351 374.435 193.351C378.401 193.351 381.944 191.506 384.285 188.61C386.106 186.358 387.201 183.471 387.201 180.323V169.803C387.201 168.419 386.1 167.295 384.743 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 444.297H374.435C371.35 444.297 368.522 445.413 366.316 447.271C363.478 449.661 361.67 453.276 361.67 457.323C361.67 460.471 362.763 463.358 364.586 465.61C365.106 466.253 365.686 466.845 366.316 467.377C368.522 469.235 371.35 470.351 374.435 470.351C378.401 470.351 381.944 468.506 384.285 465.61C386.106 463.358 387.201 460.471 387.201 457.323V446.803C387.201 445.419 386.1 444.295 384.743 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 167.297H124.045C120.96 167.297 118.131 168.413 115.925 170.271C113.087 172.661 111.279 176.276 111.279 180.323C111.279 183.471 112.372 186.358 114.195 188.61C114.715 189.253 115.296 189.845 115.925 190.377C118.131 192.235 120.96 193.351 124.045 193.351C128.01 193.351 131.553 191.506 133.894 188.61C135.715 186.358 136.81 183.471 136.81 180.323V169.803C136.81 168.419 135.71 167.295 134.353 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 444.297H124.045C120.96 444.297 118.131 445.413 115.925 447.271C113.087 449.661 111.279 453.276 111.279 457.323C111.279 460.471 112.372 463.358 114.195 465.61C114.715 466.253 115.296 466.845 115.925 467.377C118.131 469.235 120.96 470.351 124.045 470.351C128.01 470.351 131.553 468.506 133.894 465.61C135.715 463.358 136.81 460.471 136.81 457.323V446.803C136.81 445.419 135.71 444.295 134.353 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 167.297H402.257C399.172 167.297 396.343 168.413 394.137 170.271C391.299 172.661 389.491 176.276 389.491 180.323C389.491 183.471 390.584 186.358 392.407 188.61C392.927 189.253 393.507 189.845 394.137 190.377C396.343 192.235 399.172 193.351 402.257 193.351C406.222 193.351 409.765 191.506 412.106 188.61C413.927 186.358 415.022 183.471 415.022 180.323V169.803C415.022 168.419 413.921 167.295 412.565 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 444.297H402.257C399.172 444.297 396.343 445.413 394.137 447.271C391.299 449.661 389.491 453.276 389.491 457.323C389.491 460.471 390.584 463.358 392.407 465.61C392.927 466.253 393.507 466.845 394.137 467.377C396.343 469.235 399.172 470.351 402.257 470.351C406.222 470.351 409.765 468.506 412.106 465.61C413.927 463.358 415.022 460.471 415.022 457.323V446.803C415.022 445.419 413.921 444.295 412.565 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 167.297H151.866C148.781 167.297 145.953 168.413 143.746 170.271C140.909 172.661 139.101 176.276 139.101 180.323C139.101 183.471 140.194 186.358 142.016 188.61C142.537 189.253 143.117 189.845 143.746 190.377C145.953 192.235 148.781 193.351 151.866 193.351C155.831 193.351 159.375 191.506 161.716 188.61C163.536 186.358 164.631 183.471 164.631 180.323V169.803C164.631 168.419 163.531 167.295 162.174 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 444.297H151.866C148.781 444.297 145.953 445.413 143.746 447.271C140.909 449.661 139.101 453.276 139.101 457.323C139.101 460.471 140.194 463.358 142.016 465.61C142.537 466.253 143.117 466.845 143.746 467.377C145.953 469.235 148.781 470.351 151.866 470.351C155.831 470.351 159.375 468.506 161.716 465.61C163.536 463.358 164.631 460.471 164.631 457.323V446.803C164.631 445.419 163.531 444.295 162.174 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 167.297H430.077C426.992 167.297 424.164 168.413 421.957 170.271C419.12 172.661 417.312 176.276 417.312 180.323C417.312 183.471 418.405 186.358 420.227 188.61C420.748 189.253 421.328 189.845 421.957 190.377C424.164 192.235 426.992 193.351 430.077 193.351C434.042 193.351 437.586 191.506 439.926 188.61C441.747 186.358 442.842 183.471 442.842 180.323V169.803C442.842 168.419 441.742 167.295 440.385 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 444.297H430.077C426.992 444.297 424.164 445.413 421.957 447.271C419.12 449.661 417.312 453.276 417.312 457.323C417.312 460.471 418.405 463.358 420.227 465.61C420.748 466.253 421.328 466.845 421.957 467.377C424.164 469.235 426.992 470.351 430.077 470.351C434.042 470.351 437.586 468.506 439.926 465.61C441.747 463.358 442.842 460.471 442.842 457.323V446.803C442.842 445.419 441.742 444.295 440.385 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 167.297H179.684C176.599 167.297 173.771 168.413 171.565 170.271C168.727 172.661 166.919 176.276 166.919 180.323C166.919 183.471 168.012 186.358 169.835 188.61C170.355 189.253 170.935 189.845 171.565 190.377C173.771 192.235 176.599 193.351 179.684 193.351C183.65 193.351 187.193 191.506 189.534 188.61C191.355 186.358 192.45 183.471 192.45 180.323V169.803C192.45 168.419 191.349 167.295 189.992 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 444.297H179.684C176.599 444.297 173.771 445.413 171.565 447.271C168.727 449.661 166.919 453.276 166.919 457.323C166.919 460.471 168.012 463.358 169.835 465.61C170.355 466.253 170.935 466.845 171.565 467.377C173.771 469.235 176.599 470.351 179.684 470.351C183.65 470.351 187.193 468.506 189.534 465.61C191.355 463.358 192.45 460.471 192.45 457.323V446.803C192.45 445.419 191.349 444.295 189.992 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 167.297H457.896C454.811 167.297 451.983 168.413 449.776 170.271C446.939 172.661 445.131 176.276 445.131 180.323C445.131 183.471 446.224 186.358 448.047 188.61C448.567 189.253 449.147 189.845 449.776 190.377C451.983 192.235 454.811 193.351 457.896 193.351C461.862 193.351 465.405 191.506 467.746 188.61C469.567 186.358 470.662 183.471 470.662 180.323V169.803C470.662 168.419 469.561 167.295 468.204 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 444.297H457.896C454.811 444.297 451.983 445.413 449.776 447.271C446.939 449.661 445.131 453.276 445.131 457.323C445.131 460.471 446.224 463.358 448.047 465.61C448.567 466.253 449.147 466.845 449.776 467.377C451.983 469.235 454.811 470.351 457.896 470.351C461.862 470.351 465.405 468.506 467.746 465.61C469.567 463.358 470.662 460.471 470.662 457.323V446.803C470.662 445.419 469.561 444.295 468.204 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 167.297H207.506C204.421 167.297 201.592 168.413 199.386 170.271C196.548 172.661 194.74 176.276 194.74 180.323C194.74 183.471 195.833 186.358 197.656 188.61C198.176 189.253 198.756 189.845 199.386 190.377C201.592 192.235 204.421 193.351 207.506 193.351C211.471 193.351 215.014 191.506 217.355 188.61C219.176 186.358 220.271 183.471 220.271 180.323V169.803C220.271 168.419 219.171 167.295 217.814 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 444.297H207.506C204.421 444.297 201.592 445.413 199.386 447.271C196.548 449.661 194.74 453.276 194.74 457.323C194.74 460.471 195.833 463.358 197.656 465.61C198.176 466.253 198.756 466.845 199.386 467.377C201.592 469.235 204.421 470.351 207.506 470.351C211.471 470.351 215.014 468.506 217.355 465.61C219.176 463.358 220.271 460.471 220.271 457.323V446.803C220.271 445.419 219.171 444.295 217.814 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 167.297H485.717C482.632 167.297 479.803 168.413 477.597 170.271C474.759 172.661 472.951 176.276 472.951 180.323C472.951 183.471 474.044 186.358 475.867 188.61C476.387 189.253 476.967 189.845 477.597 190.377C479.803 192.235 482.632 193.351 485.717 193.351C489.682 193.351 493.225 191.506 495.566 188.61C497.387 186.358 498.482 183.471 498.482 180.323V169.803C498.482 168.419 497.381 167.295 496.025 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 444.297H485.717C482.632 444.297 479.803 445.413 477.597 447.271C474.759 449.661 472.951 453.276 472.951 457.323C472.951 460.471 474.044 463.358 475.867 465.61C476.387 466.253 476.967 466.845 477.597 467.377C479.803 469.235 482.632 470.351 485.717 470.351C489.682 470.351 493.225 468.506 495.566 465.61C497.387 463.358 498.482 460.471 498.482 457.323V446.803C498.482 445.419 497.381 444.295 496.025 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 167.297H235.326C232.241 167.297 229.413 168.413 227.206 170.271C224.369 172.661 222.561 176.276 222.561 180.323C222.561 183.471 223.654 186.358 225.476 188.61C225.997 189.253 226.577 189.845 227.206 190.377C229.413 192.235 232.241 193.351 235.326 193.351C239.291 193.351 242.835 191.506 245.176 188.61C246.996 186.358 248.091 183.471 248.091 180.323V169.803C248.091 168.419 246.991 167.295 245.634 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 444.297H235.326C232.241 444.297 229.413 445.413 227.206 447.271C224.369 449.661 222.561 453.276 222.561 457.323C222.561 460.471 223.654 463.358 225.476 465.61C225.997 466.253 226.577 466.845 227.206 467.377C229.413 469.235 232.241 470.351 235.326 470.351C239.291 470.351 242.835 468.506 245.176 465.61C246.996 463.358 248.091 460.471 248.091 457.323V446.803C248.091 445.419 246.991 444.295 245.634 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 167.297H513.538C510.453 167.297 507.624 168.413 505.418 170.271C502.581 172.661 500.772 176.276 500.772 180.323C500.772 183.471 501.866 186.358 503.688 188.61C504.208 189.253 504.789 189.845 505.418 190.377C507.624 192.235 510.453 193.351 513.538 193.351C517.503 193.351 521.046 191.506 523.387 188.61C525.208 186.358 526.303 183.471 526.303 180.323V169.803C526.303 168.419 525.203 167.295 523.846 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 444.297H513.538C510.453 444.297 507.624 445.413 505.418 447.271C502.581 449.661 500.772 453.276 500.772 457.323C500.772 460.471 501.866 463.358 503.688 465.61C504.208 466.253 504.789 466.845 505.418 467.377C507.624 469.235 510.453 470.351 513.538 470.351C517.503 470.351 521.046 468.506 523.387 465.61C525.208 463.358 526.303 460.471 526.303 457.323V446.803C526.303 445.419 525.203 444.295 523.846 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 167.297H263.147C260.062 167.297 257.234 168.413 255.027 170.271C252.19 172.661 250.382 176.276 250.382 180.323C250.382 183.471 251.475 186.358 253.298 188.61C253.818 189.253 254.398 189.845 255.027 190.377C257.234 192.235 260.062 193.351 263.147 193.351C267.112 193.351 270.656 191.506 272.997 188.61C274.818 186.358 275.913 183.471 275.913 180.323V169.803C275.913 168.419 274.812 167.295 273.455 167.295V167.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 444.297H263.147C260.062 444.297 257.234 445.413 255.027 447.271C252.19 449.661 250.382 453.276 250.382 457.323C250.382 460.471 251.475 463.358 253.298 465.61C253.818 466.253 254.398 466.845 255.027 467.377C257.234 469.235 260.062 470.351 263.147 470.351C267.112 470.351 270.656 468.506 272.997 465.61C274.818 463.358 275.913 460.471 275.913 457.323V446.803C275.913 445.419 274.812 444.295 273.455 444.295V444.297Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 195.178H12.7634C9.67848 195.178 6.85002 196.294 4.64364 198.152C1.80608 200.542 -0.00195312 204.157 -0.00195312 208.204C-0.00195312 211.352 1.09123 214.239 2.91382 216.491C3.43404 217.134 4.01428 217.726 4.64364 218.258C6.85002 220.116 9.67848 221.232 12.7634 221.232C16.7287 221.232 20.272 219.387 22.613 216.491C24.4338 214.239 25.5288 211.352 25.5288 208.204V197.684C25.5288 196.299 24.4283 195.176 23.0714 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 472.178H12.7634C9.67848 472.178 6.85002 473.294 4.64364 475.152C1.80608 477.542 -0.00195312 481.157 -0.00195312 485.204C-0.00195312 488.352 1.09123 491.239 2.91382 493.491C3.43404 494.134 4.01428 494.726 4.64364 495.258C6.85002 497.116 9.67848 498.232 12.7634 498.232C16.7287 498.232 20.272 496.387 22.613 493.491C24.4338 491.239 25.5288 488.352 25.5288 485.204V474.684C25.5288 473.299 24.4283 472.176 23.0714 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 195.178H290.975C287.89 195.178 285.062 196.294 282.856 198.152C280.018 200.542 278.21 204.157 278.21 208.204C278.21 211.352 279.303 214.239 281.126 216.491C281.646 217.134 282.226 217.726 282.856 218.258C285.062 220.116 287.89 221.232 290.975 221.232C294.941 221.232 298.484 219.387 300.825 216.491C302.646 214.239 303.741 211.352 303.741 208.204V197.684C303.741 196.299 302.64 195.176 301.283 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 472.178H290.975C287.89 472.178 285.062 473.294 282.856 475.152C280.018 477.542 278.21 481.157 278.21 485.204C278.21 488.352 279.303 491.239 281.126 493.491C281.646 494.134 282.226 494.726 282.856 495.258C285.062 497.116 287.89 498.232 290.975 498.232C294.941 498.232 298.484 496.387 300.825 493.491C302.646 491.239 303.741 488.352 303.741 485.204V474.684C303.741 473.299 302.64 472.176 301.283 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 195.178H40.5847C37.4998 195.178 34.6713 196.294 32.4649 198.152C29.6274 200.542 27.8193 204.157 27.8193 208.204C27.8193 211.352 28.9125 214.239 30.7351 216.491C31.2553 217.134 31.8356 217.726 32.4649 218.258C34.6713 220.116 37.4998 221.232 40.5847 221.232C44.55 221.232 48.0933 219.387 50.4343 216.491C52.2551 214.239 53.3501 211.352 53.3501 208.204V197.684C53.3501 196.299 52.2496 195.176 50.8927 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 472.178H40.5847C37.4998 472.178 34.6713 473.294 32.4649 475.152C29.6274 477.542 27.8193 481.157 27.8193 485.204C27.8193 488.352 28.9125 491.239 30.7351 493.491C31.2553 494.134 31.8356 494.726 32.4649 495.258C34.6713 497.116 37.4998 498.232 40.5847 498.232C44.55 498.232 48.0933 496.387 50.4343 493.491C52.2551 491.239 53.3501 488.352 53.3501 485.204V474.684C53.3501 473.299 52.2496 472.176 50.8927 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 195.178H318.796C315.711 195.178 312.882 196.294 310.676 198.152C307.838 200.542 306.03 204.157 306.03 208.204C306.03 211.352 307.123 214.239 308.946 216.491C309.466 217.134 310.047 217.726 310.676 218.258C312.882 220.116 315.711 221.232 318.796 221.232C322.761 221.232 326.304 219.387 328.645 216.491C330.466 214.239 331.561 211.352 331.561 208.204V197.684C331.561 196.299 330.461 195.176 329.104 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 472.178H318.796C315.711 472.178 312.882 473.294 310.676 475.152C307.838 477.542 306.03 481.157 306.03 485.204C306.03 488.352 307.123 491.239 308.946 493.491C309.466 494.134 310.047 494.726 310.676 495.258C312.882 497.116 315.711 498.232 318.796 498.232C322.761 498.232 326.304 496.387 328.645 493.491C330.466 491.239 331.561 488.352 331.561 485.204V474.684C331.561 473.299 330.461 472.176 329.104 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 195.178H68.405C65.3201 195.178 62.4916 196.294 60.2852 198.152C57.4477 200.542 55.6396 204.157 55.6396 208.204C55.6396 211.352 56.7328 214.239 58.5554 216.491C59.0756 217.134 59.6559 217.726 60.2852 218.258C62.4916 220.116 65.3201 221.232 68.405 221.232C72.3703 221.232 75.9136 219.387 78.2546 216.491C80.0754 214.239 81.1704 211.352 81.1704 208.204V197.684C81.1704 196.299 80.0699 195.176 78.713 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 472.178H68.405C65.3201 472.178 62.4916 473.294 60.2852 475.152C57.4477 477.542 55.6396 481.157 55.6396 485.204C55.6396 488.352 56.7328 491.239 58.5554 493.491C59.0756 494.134 59.6559 494.726 60.2852 495.258C62.4916 497.116 65.3201 498.232 68.405 498.232C72.3703 498.232 75.9136 496.387 78.2546 493.491C80.0754 491.239 81.1704 488.352 81.1704 485.204V474.684C81.1704 473.299 80.0699 472.176 78.713 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 195.178H346.617C343.532 195.178 340.704 196.294 338.497 198.152C335.66 200.542 333.852 204.157 333.852 208.204C333.852 211.352 334.945 214.239 336.767 216.491C337.288 217.134 337.868 217.726 338.497 218.258C340.704 220.116 343.532 221.232 346.617 221.232C350.582 221.232 354.126 219.387 356.467 216.491C358.287 214.239 359.382 211.352 359.382 208.204V197.684C359.382 196.299 358.282 195.176 356.925 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 472.178H346.617C343.532 472.178 340.704 473.294 338.497 475.152C335.66 477.542 333.852 481.157 333.852 485.204C333.852 488.352 334.945 491.239 336.767 493.491C337.288 494.134 337.868 494.726 338.497 495.258C340.704 497.116 343.532 498.232 346.617 498.232C350.582 498.232 354.126 496.387 356.467 493.491C358.287 491.239 359.382 488.352 359.382 485.204V474.684C359.382 473.299 358.282 472.176 356.925 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 195.178H96.2244C93.1394 195.178 90.311 196.294 88.1046 198.152C85.267 200.542 83.459 204.157 83.459 208.204C83.459 211.352 84.5522 214.239 86.3748 216.491C86.895 217.134 87.4752 217.726 88.1046 218.258C90.311 220.116 93.1394 221.232 96.2244 221.232C100.19 221.232 103.733 219.387 106.074 216.491C107.895 214.239 108.99 211.352 108.99 208.204V197.684C108.99 196.299 107.889 195.176 106.532 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 472.178H96.2244C93.1394 472.178 90.311 473.294 88.1046 475.152C85.267 477.542 83.459 481.157 83.459 485.204C83.459 488.352 84.5522 491.239 86.3748 493.491C86.895 494.134 87.4752 494.726 88.1046 495.258C90.311 497.116 93.1394 498.232 96.2244 498.232C100.19 498.232 103.733 496.387 106.074 493.491C107.895 491.239 108.99 488.352 108.99 485.204V474.684C108.99 473.299 107.889 472.176 106.532 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 195.178H374.435C371.35 195.178 368.522 196.294 366.316 198.152C363.478 200.542 361.67 204.157 361.67 208.204C361.67 211.352 362.763 214.239 364.586 216.491C365.106 217.134 365.686 217.726 366.316 218.258C368.522 220.116 371.35 221.232 374.435 221.232C378.401 221.232 381.944 219.387 384.285 216.491C386.106 214.239 387.201 211.352 387.201 208.204V197.684C387.201 196.299 386.1 195.176 384.743 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 472.178H374.435C371.35 472.178 368.522 473.294 366.316 475.152C363.478 477.542 361.67 481.157 361.67 485.204C361.67 488.352 362.763 491.239 364.586 493.491C365.106 494.134 365.686 494.726 366.316 495.258C368.522 497.116 371.35 498.232 374.435 498.232C378.401 498.232 381.944 496.387 384.285 493.491C386.106 491.239 387.201 488.352 387.201 485.204V474.684C387.201 473.299 386.1 472.176 384.743 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 195.178H124.045C120.96 195.178 118.131 196.294 115.925 198.152C113.087 200.542 111.279 204.157 111.279 208.204C111.279 211.352 112.372 214.239 114.195 216.491C114.715 217.134 115.296 217.726 115.925 218.258C118.131 220.116 120.96 221.232 124.045 221.232C128.01 221.232 131.553 219.387 133.894 216.491C135.715 214.239 136.81 211.352 136.81 208.204V197.684C136.81 196.299 135.71 195.176 134.353 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 472.178H124.045C120.96 472.178 118.131 473.294 115.925 475.152C113.087 477.542 111.279 481.157 111.279 485.204C111.279 488.352 112.372 491.239 114.195 493.491C114.715 494.134 115.296 494.726 115.925 495.258C118.131 497.116 120.96 498.232 124.045 498.232C128.01 498.232 131.553 496.387 133.894 493.491C135.715 491.239 136.81 488.352 136.81 485.204V474.684C136.81 473.299 135.71 472.176 134.353 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 195.178H402.257C399.172 195.178 396.343 196.294 394.137 198.152C391.299 200.542 389.491 204.157 389.491 208.204C389.491 211.352 390.584 214.239 392.407 216.491C392.927 217.134 393.507 217.726 394.137 218.258C396.343 220.116 399.172 221.232 402.257 221.232C406.222 221.232 409.765 219.387 412.106 216.491C413.927 214.239 415.022 211.352 415.022 208.204V197.684C415.022 196.299 413.921 195.176 412.565 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 472.178H402.257C399.172 472.178 396.343 473.294 394.137 475.152C391.299 477.542 389.491 481.157 389.491 485.204C389.491 488.352 390.584 491.239 392.407 493.491C392.927 494.134 393.507 494.726 394.137 495.258C396.343 497.116 399.172 498.232 402.257 498.232C406.222 498.232 409.765 496.387 412.106 493.491C413.927 491.239 415.022 488.352 415.022 485.204V474.684C415.022 473.299 413.921 472.176 412.565 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 195.178H151.866C148.781 195.178 145.953 196.294 143.746 198.152C140.909 200.542 139.101 204.157 139.101 208.204C139.101 211.352 140.194 214.239 142.016 216.491C142.537 217.134 143.117 217.726 143.746 218.258C145.953 220.116 148.781 221.232 151.866 221.232C155.831 221.232 159.375 219.387 161.716 216.491C163.536 214.239 164.631 211.352 164.631 208.204V197.684C164.631 196.299 163.531 195.176 162.174 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 472.178H151.866C148.781 472.178 145.953 473.294 143.746 475.152C140.909 477.542 139.101 481.157 139.101 485.204C139.101 488.352 140.194 491.239 142.016 493.491C142.537 494.134 143.117 494.726 143.746 495.258C145.953 497.116 148.781 498.232 151.866 498.232C155.831 498.232 159.375 496.387 161.716 493.491C163.536 491.239 164.631 488.352 164.631 485.204V474.684C164.631 473.299 163.531 472.176 162.174 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 195.178H430.077C426.992 195.178 424.164 196.294 421.957 198.152C419.12 200.542 417.312 204.157 417.312 208.204C417.312 211.352 418.405 214.239 420.227 216.491C420.748 217.134 421.328 217.726 421.957 218.258C424.164 220.116 426.992 221.232 430.077 221.232C434.042 221.232 437.586 219.387 439.926 216.491C441.747 214.239 442.842 211.352 442.842 208.204V197.684C442.842 196.299 441.742 195.176 440.385 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 472.178H430.077C426.992 472.178 424.164 473.294 421.957 475.152C419.12 477.542 417.312 481.157 417.312 485.204C417.312 488.352 418.405 491.239 420.227 493.491C420.748 494.134 421.328 494.726 421.957 495.258C424.164 497.116 426.992 498.232 430.077 498.232C434.042 498.232 437.586 496.387 439.926 493.491C441.747 491.239 442.842 488.352 442.842 485.204V474.684C442.842 473.299 441.742 472.176 440.385 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 195.178H179.684C176.599 195.178 173.771 196.294 171.565 198.152C168.727 200.542 166.919 204.157 166.919 208.204C166.919 211.352 168.012 214.239 169.835 216.491C170.355 217.134 170.935 217.726 171.565 218.258C173.771 220.116 176.599 221.232 179.684 221.232C183.65 221.232 187.193 219.387 189.534 216.491C191.355 214.239 192.45 211.352 192.45 208.204V197.684C192.45 196.299 191.349 195.176 189.992 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 472.178H179.684C176.599 472.178 173.771 473.294 171.565 475.152C168.727 477.542 166.919 481.157 166.919 485.204C166.919 488.352 168.012 491.239 169.835 493.491C170.355 494.134 170.935 494.726 171.565 495.258C173.771 497.116 176.599 498.232 179.684 498.232C183.65 498.232 187.193 496.387 189.534 493.491C191.355 491.239 192.45 488.352 192.45 485.204V474.684C192.45 473.299 191.349 472.176 189.992 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 195.178H457.896C454.811 195.178 451.983 196.294 449.776 198.152C446.939 200.542 445.131 204.157 445.131 208.204C445.131 211.352 446.224 214.239 448.047 216.491C448.567 217.134 449.147 217.726 449.776 218.258C451.983 220.116 454.811 221.232 457.896 221.232C461.862 221.232 465.405 219.387 467.746 216.491C469.567 214.239 470.662 211.352 470.662 208.204V197.684C470.662 196.299 469.561 195.176 468.204 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 472.178H457.896C454.811 472.178 451.983 473.294 449.776 475.152C446.939 477.542 445.131 481.157 445.131 485.204C445.131 488.352 446.224 491.239 448.047 493.491C448.567 494.134 449.147 494.726 449.776 495.258C451.983 497.116 454.811 498.232 457.896 498.232C461.862 498.232 465.405 496.387 467.746 493.491C469.567 491.239 470.662 488.352 470.662 485.204V474.684C470.662 473.299 469.561 472.176 468.204 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 195.178H207.506C204.421 195.178 201.592 196.294 199.386 198.152C196.548 200.542 194.74 204.157 194.74 208.204C194.74 211.352 195.833 214.239 197.656 216.491C198.176 217.134 198.756 217.726 199.386 218.258C201.592 220.116 204.421 221.232 207.506 221.232C211.471 221.232 215.014 219.387 217.355 216.491C219.176 214.239 220.271 211.352 220.271 208.204V197.684C220.271 196.299 219.171 195.176 217.814 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 472.178H207.506C204.421 472.178 201.592 473.294 199.386 475.152C196.548 477.542 194.74 481.157 194.74 485.204C194.74 488.352 195.833 491.239 197.656 493.491C198.176 494.134 198.756 494.726 199.386 495.258C201.592 497.116 204.421 498.232 207.506 498.232C211.471 498.232 215.014 496.387 217.355 493.491C219.176 491.239 220.271 488.352 220.271 485.204V474.684C220.271 473.299 219.171 472.176 217.814 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 195.178H485.717C482.632 195.178 479.803 196.294 477.597 198.152C474.759 200.542 472.951 204.157 472.951 208.204C472.951 211.352 474.044 214.239 475.867 216.491C476.387 217.134 476.967 217.726 477.597 218.258C479.803 220.116 482.632 221.232 485.717 221.232C489.682 221.232 493.225 219.387 495.566 216.491C497.387 214.239 498.482 211.352 498.482 208.204V197.684C498.482 196.299 497.381 195.176 496.025 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 472.178H485.717C482.632 472.178 479.803 473.294 477.597 475.152C474.759 477.542 472.951 481.157 472.951 485.204C472.951 488.352 474.044 491.239 475.867 493.491C476.387 494.134 476.967 494.726 477.597 495.258C479.803 497.116 482.632 498.232 485.717 498.232C489.682 498.232 493.225 496.387 495.566 493.491C497.387 491.239 498.482 488.352 498.482 485.204V474.684C498.482 473.299 497.381 472.176 496.025 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 195.178H235.326C232.241 195.178 229.413 196.294 227.206 198.152C224.369 200.542 222.561 204.157 222.561 208.204C222.561 211.352 223.654 214.239 225.476 216.491C225.997 217.134 226.577 217.726 227.206 218.258C229.413 220.116 232.241 221.232 235.326 221.232C239.291 221.232 242.835 219.387 245.176 216.491C246.996 214.239 248.091 211.352 248.091 208.204V197.684C248.091 196.299 246.991 195.176 245.634 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 472.178H235.326C232.241 472.178 229.413 473.294 227.206 475.152C224.369 477.542 222.561 481.157 222.561 485.204C222.561 488.352 223.654 491.239 225.476 493.491C225.997 494.134 226.577 494.726 227.206 495.258C229.413 497.116 232.241 498.232 235.326 498.232C239.291 498.232 242.835 496.387 245.176 493.491C246.996 491.239 248.091 488.352 248.091 485.204V474.684C248.091 473.299 246.991 472.176 245.634 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 195.178H513.538C510.453 195.178 507.624 196.294 505.418 198.152C502.581 200.542 500.772 204.157 500.772 208.204C500.772 211.352 501.866 214.239 503.688 216.491C504.208 217.134 504.789 217.726 505.418 218.258C507.624 220.116 510.453 221.232 513.538 221.232C517.503 221.232 521.046 219.387 523.387 216.491C525.208 214.239 526.303 211.352 526.303 208.204V197.684C526.303 196.299 525.203 195.176 523.846 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 472.178H513.538C510.453 472.178 507.624 473.294 505.418 475.152C502.581 477.542 500.772 481.157 500.772 485.204C500.772 488.352 501.866 491.239 503.688 493.491C504.208 494.134 504.789 494.726 505.418 495.258C507.624 497.116 510.453 498.232 513.538 498.232C517.503 498.232 521.046 496.387 523.387 493.491C525.208 491.239 526.303 488.352 526.303 485.204V474.684C526.303 473.299 525.203 472.176 523.846 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 195.178H263.147C260.062 195.178 257.234 196.294 255.027 198.152C252.19 200.542 250.382 204.157 250.382 208.204C250.382 211.352 251.475 214.239 253.298 216.491C253.818 217.134 254.398 217.726 255.027 218.258C257.234 220.116 260.062 221.232 263.147 221.232C267.112 221.232 270.656 219.387 272.997 216.491C274.818 214.239 275.913 211.352 275.913 208.204V197.684C275.913 196.299 274.812 195.176 273.455 195.176V195.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 472.178H263.147C260.062 472.178 257.234 473.294 255.027 475.152C252.19 477.542 250.382 481.157 250.382 485.204C250.382 488.352 251.475 491.239 253.298 493.491C253.818 494.134 254.398 494.726 255.027 495.258C257.234 497.116 260.062 498.232 263.147 498.232C267.112 498.232 270.656 496.387 272.997 493.491C274.818 491.239 275.913 488.352 275.913 485.204V474.684C275.913 473.299 274.812 472.176 273.455 472.176V472.178Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 223.062H12.7634C9.67848 223.062 6.85002 224.179 4.64364 226.037C1.80608 228.426 -0.00195312 232.042 -0.00195312 236.089C-0.00195312 239.237 1.09123 242.123 2.91382 244.376C3.43404 245.019 4.01428 245.611 4.64364 246.143C6.85002 248.001 9.67848 249.117 12.7634 249.117C16.7287 249.117 20.272 247.272 22.613 244.376C24.4338 242.123 25.5288 239.237 25.5288 236.089V225.569C25.5288 224.184 24.4283 223.061 23.0714 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 223.062H290.975C287.89 223.062 285.062 224.179 282.856 226.037C280.018 228.426 278.21 232.042 278.21 236.089C278.21 239.237 279.303 242.123 281.126 244.376C281.646 245.019 282.226 245.611 282.856 246.143C285.062 248.001 287.89 249.117 290.975 249.117C294.941 249.117 298.484 247.272 300.825 244.376C302.646 242.123 303.741 239.237 303.741 236.089V225.569C303.741 224.184 302.64 223.061 301.283 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 223.062H40.5847C37.4998 223.062 34.6713 224.179 32.4649 226.037C29.6274 228.426 27.8193 232.042 27.8193 236.089C27.8193 239.237 28.9125 242.123 30.7351 244.376C31.2553 245.019 31.8356 245.611 32.4649 246.143C34.6713 248.001 37.4998 249.117 40.5847 249.117C44.55 249.117 48.0933 247.272 50.4343 244.376C52.2551 242.123 53.3501 239.237 53.3501 236.089V225.569C53.3501 224.184 52.2496 223.061 50.8927 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 223.062H318.796C315.711 223.062 312.882 224.179 310.676 226.037C307.838 228.426 306.03 232.042 306.03 236.089C306.03 239.237 307.123 242.123 308.946 244.376C309.466 245.019 310.047 245.611 310.676 246.143C312.882 248.001 315.711 249.117 318.796 249.117C322.761 249.117 326.304 247.272 328.645 244.376C330.466 242.123 331.561 239.237 331.561 236.089V225.569C331.561 224.184 330.461 223.061 329.104 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 223.062H68.405C65.3201 223.062 62.4916 224.179 60.2852 226.037C57.4477 228.426 55.6396 232.042 55.6396 236.089C55.6396 239.237 56.7328 242.123 58.5554 244.376C59.0756 245.019 59.6559 245.611 60.2852 246.143C62.4916 248.001 65.3201 249.117 68.405 249.117C72.3703 249.117 75.9136 247.272 78.2546 244.376C80.0754 242.123 81.1704 239.237 81.1704 236.089V225.569C81.1704 224.184 80.0699 223.061 78.713 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 223.062H346.617C343.532 223.062 340.704 224.179 338.497 226.037C335.66 228.426 333.852 232.042 333.852 236.089C333.852 239.237 334.945 242.123 336.767 244.376C337.288 245.019 337.868 245.611 338.497 246.143C340.704 248.001 343.532 249.117 346.617 249.117C350.582 249.117 354.126 247.272 356.467 244.376C358.287 242.123 359.382 239.237 359.382 236.089V225.569C359.382 224.184 358.282 223.061 356.925 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 223.062H96.2244C93.1394 223.062 90.311 224.179 88.1046 226.037C85.267 228.426 83.459 232.042 83.459 236.089C83.459 239.237 84.5522 242.123 86.3748 244.376C86.895 245.019 87.4752 245.611 88.1046 246.143C90.311 248.001 93.1394 249.117 96.2244 249.117C100.19 249.117 103.733 247.272 106.074 244.376C107.895 242.123 108.99 239.237 108.99 236.089V225.569C108.99 224.184 107.889 223.061 106.532 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 223.062H374.435C371.35 223.062 368.522 224.179 366.316 226.037C363.478 228.426 361.67 232.042 361.67 236.089C361.67 239.237 362.763 242.123 364.586 244.376C365.106 245.019 365.686 245.611 366.316 246.143C368.522 248.001 371.35 249.117 374.435 249.117C378.401 249.117 381.944 247.272 384.285 244.376C386.106 242.123 387.201 239.237 387.201 236.089V225.569C387.201 224.184 386.1 223.061 384.743 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 223.062H124.045C120.96 223.062 118.131 224.179 115.925 226.037C113.087 228.426 111.279 232.042 111.279 236.089C111.279 239.237 112.372 242.123 114.195 244.376C114.715 245.019 115.296 245.611 115.925 246.143C118.131 248.001 120.96 249.117 124.045 249.117C128.01 249.117 131.553 247.272 133.894 244.376C135.715 242.123 136.81 239.237 136.81 236.089V225.569C136.81 224.184 135.71 223.061 134.353 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 223.062H402.257C399.172 223.062 396.343 224.179 394.137 226.037C391.299 228.426 389.491 232.042 389.491 236.089C389.491 239.237 390.584 242.123 392.407 244.376C392.927 245.019 393.507 245.611 394.137 246.143C396.343 248.001 399.172 249.117 402.257 249.117C406.222 249.117 409.765 247.272 412.106 244.376C413.927 242.123 415.022 239.237 415.022 236.089V225.569C415.022 224.184 413.921 223.061 412.565 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 223.062H151.866C148.781 223.062 145.953 224.179 143.746 226.037C140.909 228.426 139.101 232.042 139.101 236.089C139.101 239.237 140.194 242.123 142.016 244.376C142.537 245.019 143.117 245.611 143.746 246.143C145.953 248.001 148.781 249.117 151.866 249.117C155.831 249.117 159.375 247.272 161.716 244.376C163.536 242.123 164.631 239.237 164.631 236.089V225.569C164.631 224.184 163.531 223.061 162.174 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 223.062H430.077C426.992 223.062 424.164 224.179 421.957 226.037C419.12 228.426 417.312 232.042 417.312 236.089C417.312 239.237 418.405 242.123 420.227 244.376C420.748 245.019 421.328 245.611 421.957 246.143C424.164 248.001 426.992 249.117 430.077 249.117C434.042 249.117 437.586 247.272 439.926 244.376C441.747 242.123 442.842 239.237 442.842 236.089V225.569C442.842 224.184 441.742 223.061 440.385 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 223.062H179.684C176.599 223.062 173.771 224.179 171.565 226.037C168.727 228.426 166.919 232.042 166.919 236.089C166.919 239.237 168.012 242.123 169.835 244.376C170.355 245.019 170.935 245.611 171.565 246.143C173.771 248.001 176.599 249.117 179.684 249.117C183.65 249.117 187.193 247.272 189.534 244.376C191.355 242.123 192.45 239.237 192.45 236.089V225.569C192.45 224.184 191.349 223.061 189.992 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 223.062H457.896C454.811 223.062 451.983 224.179 449.776 226.037C446.939 228.426 445.131 232.042 445.131 236.089C445.131 239.237 446.224 242.123 448.047 244.376C448.567 245.019 449.147 245.611 449.776 246.143C451.983 248.001 454.811 249.117 457.896 249.117C461.862 249.117 465.405 247.272 467.746 244.376C469.567 242.123 470.662 239.237 470.662 236.089V225.569C470.662 224.184 469.561 223.061 468.204 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 223.062H207.506C204.421 223.062 201.592 224.179 199.386 226.037C196.548 228.426 194.74 232.042 194.74 236.089C194.74 239.237 195.833 242.123 197.656 244.376C198.176 245.019 198.756 245.611 199.386 246.143C201.592 248.001 204.421 249.117 207.506 249.117C211.471 249.117 215.014 247.272 217.355 244.376C219.176 242.123 220.271 239.237 220.271 236.089V225.569C220.271 224.184 219.171 223.061 217.814 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 223.062H485.717C482.632 223.062 479.803 224.179 477.597 226.037C474.759 228.426 472.951 232.042 472.951 236.089C472.951 239.237 474.044 242.123 475.867 244.376C476.387 245.019 476.967 245.611 477.597 246.143C479.803 248.001 482.632 249.117 485.717 249.117C489.682 249.117 493.225 247.272 495.566 244.376C497.387 242.123 498.482 239.237 498.482 236.089V225.569C498.482 224.184 497.381 223.061 496.025 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 223.062H235.326C232.241 223.062 229.413 224.179 227.206 226.037C224.369 228.426 222.561 232.042 222.561 236.089C222.561 239.237 223.654 242.123 225.476 244.376C225.997 245.019 226.577 245.611 227.206 246.143C229.413 248.001 232.241 249.117 235.326 249.117C239.291 249.117 242.835 247.272 245.176 244.376C246.996 242.123 248.091 239.237 248.091 236.089V225.569C248.091 224.184 246.991 223.061 245.634 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 223.062H513.538C510.453 223.062 507.624 224.179 505.418 226.037C502.581 228.426 500.772 232.042 500.772 236.089C500.772 239.237 501.866 242.123 503.688 244.376C504.208 245.019 504.789 245.611 505.418 246.143C507.624 248.001 510.453 249.117 513.538 249.117C517.503 249.117 521.046 247.272 523.387 244.376C525.208 242.123 526.303 239.237 526.303 236.089V225.569C526.303 224.184 525.203 223.061 523.846 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 223.062H263.147C260.062 223.062 257.234 224.179 255.027 226.037C252.19 228.426 250.382 232.042 250.382 236.089C250.382 239.237 251.475 242.123 253.298 244.376C253.818 245.019 254.398 245.611 255.027 246.143C257.234 248.001 260.062 249.117 263.147 249.117C267.112 249.117 270.656 247.272 272.997 244.376C274.818 242.123 275.913 239.237 275.913 236.089V225.569C275.913 224.184 274.812 223.061 273.455 223.061V223.062Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M23.0714 250.945H12.7634C9.67848 250.945 6.85002 252.061 4.64364 253.92C1.80608 256.309 -0.00195312 259.925 -0.00195312 263.972C-0.00195312 267.119 1.09123 270.006 2.91382 272.259C3.43404 272.902 4.01428 273.494 4.64364 274.025C6.85002 275.884 9.67848 277 12.7634 277C16.7287 277 20.272 275.155 22.613 272.259C24.4338 270.006 25.5288 267.119 25.5288 263.972V253.452C25.5288 252.067 24.4283 250.943 23.0714 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M301.283 250.945H290.975C287.89 250.945 285.062 252.061 282.856 253.92C280.018 256.309 278.21 259.925 278.21 263.972C278.21 267.119 279.303 270.006 281.126 272.259C281.646 272.902 282.226 273.494 282.856 274.025C285.062 275.884 287.89 277 290.975 277C294.941 277 298.484 275.155 300.825 272.259C302.646 270.006 303.741 267.119 303.741 263.972V253.452C303.741 252.067 302.64 250.943 301.283 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M50.8927 250.945H40.5847C37.4998 250.945 34.6713 252.061 32.4649 253.92C29.6274 256.309 27.8193 259.925 27.8193 263.972C27.8193 267.119 28.9125 270.006 30.7351 272.259C31.2553 272.902 31.8356 273.494 32.4649 274.025C34.6713 275.884 37.4998 277 40.5847 277C44.55 277 48.0933 275.155 50.4343 272.259C52.2551 270.006 53.3501 267.119 53.3501 263.972V253.452C53.3501 252.067 52.2496 250.943 50.8927 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M329.104 250.945H318.796C315.711 250.945 312.882 252.061 310.676 253.92C307.838 256.309 306.03 259.925 306.03 263.972C306.03 267.119 307.123 270.006 308.946 272.259C309.466 272.902 310.047 273.494 310.676 274.025C312.882 275.884 315.711 277 318.796 277C322.761 277 326.304 275.155 328.645 272.259C330.466 270.006 331.561 267.119 331.561 263.972V253.452C331.561 252.067 330.461 250.943 329.104 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M78.713 250.945H68.405C65.3201 250.945 62.4916 252.061 60.2852 253.92C57.4477 256.309 55.6396 259.925 55.6396 263.972C55.6396 267.119 56.7328 270.006 58.5554 272.259C59.0756 272.902 59.6559 273.494 60.2852 274.025C62.4916 275.884 65.3201 277 68.405 277C72.3703 277 75.9136 275.155 78.2546 272.259C80.0754 270.006 81.1704 267.119 81.1704 263.972V253.452C81.1704 252.067 80.0699 250.943 78.713 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M356.925 250.945H346.617C343.532 250.945 340.704 252.061 338.497 253.92C335.66 256.309 333.852 259.925 333.852 263.972C333.852 267.119 334.945 270.006 336.767 272.259C337.288 272.902 337.868 273.494 338.497 274.025C340.704 275.884 343.532 277 346.617 277C350.582 277 354.126 275.155 356.467 272.259C358.287 270.006 359.382 267.119 359.382 263.972V253.452C359.382 252.067 358.282 250.943 356.925 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M106.532 250.945H96.2244C93.1394 250.945 90.311 252.061 88.1046 253.92C85.267 256.309 83.459 259.925 83.459 263.972C83.459 267.119 84.5522 270.006 86.3748 272.259C86.895 272.902 87.4752 273.494 88.1046 274.025C90.311 275.884 93.1394 277 96.2244 277C100.19 277 103.733 275.155 106.074 272.259C107.895 270.006 108.99 267.119 108.99 263.972V253.452C108.99 252.067 107.889 250.943 106.532 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M384.743 250.945H374.435C371.35 250.945 368.522 252.061 366.316 253.92C363.478 256.309 361.67 259.925 361.67 263.972C361.67 267.119 362.763 270.006 364.586 272.259C365.106 272.902 365.686 273.494 366.316 274.025C368.522 275.884 371.35 277 374.435 277C378.401 277 381.944 275.155 384.285 272.259C386.106 270.006 387.201 267.119 387.201 263.972V253.452C387.201 252.067 386.1 250.943 384.743 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M134.353 250.945H124.045C120.96 250.945 118.131 252.061 115.925 253.92C113.087 256.309 111.279 259.925 111.279 263.972C111.279 267.119 112.372 270.006 114.195 272.259C114.715 272.902 115.296 273.494 115.925 274.025C118.131 275.884 120.96 277 124.045 277C128.01 277 131.553 275.155 133.894 272.259C135.715 270.006 136.81 267.119 136.81 263.972V253.452C136.81 252.067 135.71 250.943 134.353 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M412.565 250.945H402.257C399.172 250.945 396.343 252.061 394.137 253.92C391.299 256.309 389.491 259.925 389.491 263.972C389.491 267.119 390.584 270.006 392.407 272.259C392.927 272.902 393.507 273.494 394.137 274.025C396.343 275.884 399.172 277 402.257 277C406.222 277 409.765 275.155 412.106 272.259C413.927 270.006 415.022 267.119 415.022 263.972V253.452C415.022 252.067 413.921 250.943 412.565 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M162.174 250.945H151.866C148.781 250.945 145.953 252.061 143.746 253.92C140.909 256.309 139.101 259.925 139.101 263.972C139.101 267.119 140.194 270.006 142.016 272.259C142.537 272.902 143.117 273.494 143.746 274.025C145.953 275.884 148.781 277 151.866 277C155.831 277 159.375 275.155 161.716 272.259C163.536 270.006 164.631 267.119 164.631 263.972V253.452C164.631 252.067 163.531 250.943 162.174 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M440.385 250.945H430.077C426.992 250.945 424.164 252.061 421.957 253.92C419.12 256.309 417.312 259.925 417.312 263.972C417.312 267.119 418.405 270.006 420.227 272.259C420.748 272.902 421.328 273.494 421.957 274.025C424.164 275.884 426.992 277 430.077 277C434.042 277 437.586 275.155 439.926 272.259C441.747 270.006 442.842 267.119 442.842 263.972V253.452C442.842 252.067 441.742 250.943 440.385 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M189.992 250.945H179.684C176.599 250.945 173.771 252.061 171.565 253.92C168.727 256.309 166.919 259.925 166.919 263.972C166.919 267.119 168.012 270.006 169.835 272.259C170.355 272.902 170.935 273.494 171.565 274.025C173.771 275.884 176.599 277 179.684 277C183.65 277 187.193 275.155 189.534 272.259C191.355 270.006 192.45 267.119 192.45 263.972V253.452C192.45 252.067 191.349 250.943 189.992 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M468.204 250.945H457.896C454.811 250.945 451.983 252.061 449.776 253.92C446.939 256.309 445.131 259.925 445.131 263.972C445.131 267.119 446.224 270.006 448.047 272.259C448.567 272.902 449.147 273.494 449.776 274.025C451.983 275.884 454.811 277 457.896 277C461.862 277 465.405 275.155 467.746 272.259C469.567 270.006 470.662 267.119 470.662 263.972V253.452C470.662 252.067 469.561 250.943 468.204 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M217.814 250.945H207.506C204.421 250.945 201.592 252.061 199.386 253.92C196.548 256.309 194.74 259.925 194.74 263.972C194.74 267.119 195.833 270.006 197.656 272.259C198.176 272.902 198.756 273.494 199.386 274.025C201.592 275.884 204.421 277 207.506 277C211.471 277 215.014 275.155 217.355 272.259C219.176 270.006 220.271 267.119 220.271 263.972V253.452C220.271 252.067 219.171 250.943 217.814 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M496.025 250.945H485.717C482.632 250.945 479.803 252.061 477.597 253.92C474.759 256.309 472.951 259.925 472.951 263.972C472.951 267.119 474.044 270.006 475.867 272.259C476.387 272.902 476.967 273.494 477.597 274.025C479.803 275.884 482.632 277 485.717 277C489.682 277 493.225 275.155 495.566 272.259C497.387 270.006 498.482 267.119 498.482 263.972V253.452C498.482 252.067 497.381 250.943 496.025 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M245.634 250.945H235.326C232.241 250.945 229.413 252.061 227.206 253.92C224.369 256.309 222.561 259.925 222.561 263.972C222.561 267.119 223.654 270.006 225.476 272.259C225.997 272.902 226.577 273.494 227.206 274.025C229.413 275.884 232.241 277 235.326 277C239.291 277 242.835 275.155 245.176 272.259C246.996 270.006 248.091 267.119 248.091 263.972V253.452C248.091 252.067 246.991 250.943 245.634 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M523.846 250.945H513.538C510.453 250.945 507.624 252.061 505.418 253.92C502.581 256.309 500.772 259.925 500.772 263.972C500.772 267.119 501.866 270.006 503.688 272.259C504.208 272.902 504.789 273.494 505.418 274.025C507.624 275.884 510.453 277 513.538 277C517.503 277 521.046 275.155 523.387 272.259C525.208 270.006 526.303 267.119 526.303 263.972V253.452C526.303 252.067 525.203 250.943 523.846 250.943V250.945Z"
          />
          <path
            fill={backgroundShapeFill || '#262B39'}
            d="M273.455 250.945H263.147C260.062 250.945 257.234 252.061 255.027 253.92C252.19 256.309 250.382 259.925 250.382 263.972C250.382 267.119 251.475 270.006 253.298 272.259C253.818 272.902 254.398 273.494 255.027 274.025C257.234 275.884 260.062 277 263.147 277C267.112 277 270.656 275.155 272.997 272.259C274.818 270.006 275.913 267.119 275.913 263.972V253.452C275.913 252.067 274.812 250.943 273.455 250.943V250.945Z"
          />
        </g>
        <path
          fill="#F5DD28"
          d="M298.898 156.51L235.236 168.884C216.188 172.587 200.03 182.721 188.588 196.607C173.87 214.44 166.95 238.457 171.702 262.903C175.398 281.918 185.538 298.044 199.438 309.458C203.408 312.723 207.674 315.595 212.193 318.045C228 326.616 246.782 329.977 265.83 326.274C290.318 321.514 310.035 306.113 321.088 285.812C329.682 270.025 333.061 251.271 329.365 232.256L317.011 168.705C315.385 160.336 307.282 154.88 298.88 156.513L298.898 156.51ZM299.311 238.098C301.61 249.926 299.52 261.604 294.167 271.442C287.29 284.086 275.012 293.671 259.77 296.634C247.921 298.937 236.226 296.858 226.376 291.519C223.566 290.001 220.904 288.193 218.423 286.165C209.774 279.049 203.465 269.022 201.162 257.176C198.205 241.96 202.508 227.007 211.679 215.907C218.812 207.266 228.858 200.961 240.725 198.655C267.816 193.389 294.054 211.054 299.311 238.098Z"
        />
        <path
          fill="#FF6175"
          d="M310.179 156.538L246.672 168.882C227.671 172.576 211.555 182.702 200.146 196.581C185.47 214.406 178.577 238.418 183.329 262.863C187.025 281.878 197.149 298.008 211.022 309.427C214.984 312.693 219.241 315.567 223.751 318.02C239.525 326.596 258.264 329.966 277.266 326.272C301.694 321.524 321.359 306.133 332.376 285.839C340.943 270.057 344.306 251.306 340.61 232.291L328.257 168.74C326.63 160.371 318.543 154.912 310.161 156.541L310.179 156.538ZM310.629 238.119C312.928 249.947 310.849 261.622 305.512 271.457C298.657 284.098 286.412 293.676 271.207 296.631C259.387 298.929 247.719 296.845 237.889 291.502C235.084 289.983 232.428 288.174 229.952 286.145C221.319 279.025 215.021 268.997 212.718 257.151C209.761 241.935 214.048 226.985 223.191 215.89C230.304 207.253 240.324 200.954 252.162 198.653C279.187 193.399 305.372 211.075 310.629 238.119Z"
        />
        <path
          fill="#0057A7"
          d="M321.794 156.525L258.221 168.883C239.2 172.58 223.065 182.71 211.642 196.592C196.949 214.42 190.044 238.434 194.796 262.88C198.492 281.895 208.622 298.022 222.507 309.44C226.473 312.705 230.733 315.578 235.247 318.03C251.036 326.604 269.793 329.97 288.815 326.273C313.269 321.519 332.956 306.124 343.988 285.827C352.567 270.042 355.936 251.291 352.24 232.276L339.887 168.725C338.26 160.355 330.167 154.898 321.776 156.529L321.794 156.525ZM322.229 238.109C324.528 249.938 322.444 261.614 317.099 271.45C310.236 284.092 297.976 293.673 282.755 296.632C270.923 298.932 259.243 296.85 249.405 291.509C246.598 289.99 243.939 288.182 241.461 286.153C232.821 279.035 226.518 269.007 224.216 257.161C221.258 241.945 225.552 226.994 234.707 215.896C241.829 207.259 251.86 200.956 263.71 198.653C290.764 193.394 316.972 211.065 322.229 238.109Z"
        />
        <path
          fill="#22CFAB"
          d="M333.247 156.516L269.629 168.882C250.594 172.582 234.448 182.714 223.016 196.598C208.31 214.428 201.398 238.444 206.149 262.89C209.845 281.905 219.981 298.032 233.873 309.448C237.841 312.713 242.104 315.585 246.621 318.036C262.418 326.609 281.188 329.972 300.223 326.272C324.694 321.515 344.396 306.117 355.438 285.818C364.025 270.032 367.399 251.28 363.703 232.264L351.35 168.713C349.723 160.344 341.625 154.888 333.229 156.52L333.247 156.516ZM333.67 238.102C335.97 249.931 333.883 261.607 328.534 271.444C321.664 284.088 309.395 293.671 294.163 296.631C282.323 298.933 270.636 296.852 260.791 291.512C257.982 289.994 255.322 288.186 252.843 286.157C244.198 279.04 237.892 269.013 235.59 257.167C232.632 241.951 236.931 226.999 246.093 215.9C253.221 207.261 263.26 200.958 275.118 198.652C302.191 193.39 328.414 211.058 333.67 238.102Z"
        />
      </g>
    </svg>
  );
};

export default BordWarehousesImage;
