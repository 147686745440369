import { useState } from 'react';
import { EmployeeDestinationModal } from '../../../../../../../components/DestinationModals';
import { updateEmployeeData } from '../../../../../../../services/employees.service';
import { DTOupdateEmployeeData } from '../../../../../../../types/DTO';
import { Iemployee } from '../../../../../../../types/global';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { ICountryDetail } from '../../../../../../../types/countries';
import BordModal from '../../../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './ShipToEmployeeModalFlux.scss';

/**
 * @property { React.Dispatch<React.SetStateAction<boolean>> } setShowShipToEmployeeModalFlux - a react state updater function for determing if the modal is open or closed
 * @property { Iemployee } employeeData -- The employee data with which the modal will preloaded. This is the employee whose information will be updated as part of the modal callback
 * @property { (employeeUserId: string | number) => Promise<void> } finishEmployeeDestinationFluxCallbackFn - a callback function to execute upon the end of the handleClickModalButton, right before the modal is closed
 */
const ShipToEmployeeModalFlux = ({
  setShowShipToEmployeeModalFlux,
  employeeData,
  finishEmployeeDestinationFluxCallbackFn,
  completeListOfCountries
}: {
  setShowShipToEmployeeModalFlux: React.Dispatch<React.SetStateAction<boolean>>;
  employeeData: Iemployee;
  finishEmployeeDestinationFluxCallbackFn: (employeeUserId: string | number) => Promise<void>;
  completeListOfCountries?: ICountryDetail[];
}) => {
  const [loading, setLoading] = useState(false);

  const handleClickModalButton = async (modalData: DTOupdateEmployeeData) => {
    if (!modalData || !employeeData.userId || !employeeData.country?.id || !modalData?.countryPhoneId) return;
    setLoading(true);
    try {
      await updateEmployeeData(employeeData.userId, modalData);
      finishEmployeeDestinationFluxCallbackFn && finishEmployeeDestinationFluxCallbackFn(employeeData?.userId);
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const closeModalCallback = () => setShowShipToEmployeeModalFlux(false);

  return (
    <div className="shipToEmployeeModalFlux">
      <BordModal
        modalContent={
          <ModalContent
            customHeight="580px"
            customWidth="680px"
            title="Información empleado"
            subTitle="Agrega o valida  la información del empleado para el envío"
            customSubtitleClass="subtitleClass"
            CustomModalComponent={
              <EmployeeDestinationModal
                buttonText="Guardar"
                employeeData={employeeData}
                clickButtonCallback={handleClickModalButton}
                isButtonLoading={loading}
                listOfCountries={completeListOfCountries}
              />
            }
            toCloseModal={closeModalCallback}
          />
        }
      />
    </div>
  );
};

export default ShipToEmployeeModalFlux;
