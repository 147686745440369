import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { HorizontalFilters } from '../../../components';
import { getCategories, getProductList } from '../../../services/services';
import useShopStore from '../../../state/useShopContext';
import { GenericFetchError } from '../../../constants';
import useEcommerceControler from '../ecommerce.controller';
import SkeletonFilters from '../../../components/SkeletonFilters';
import { ObjectKey, TtoolCategories, valueKey } from '../../../types/global';
import { SkeletonProductEcommerceCard, ProductEcommerceCard } from './Components';
import { formatOrgData } from '../../../utils/orgFormatData';
import useStateSubscriptions from '../../../state/useStateSubscriptions';
import useStoreCountryState from '../../../state/useStoreCountryState';
import { IProductItemList } from '../../../types/catalogue';
import { segmentTrackEvent } from '../../../utils/segment';
import BordEmptyState from '../../../components/BordDesignSystem/BordEmptyState/BordEmptyState';
import { useTranslation } from 'react-i18next';
import { handleClickForHelp } from '../../../utils/global.utils';
import { bordColorTheme } from '../../../styles/constants';
import { TbordTwoToneIcon } from '../../../components/BordDesignSystem/BordTwoToneIcon/BordTwoToneIcon.types';
import './ListOfProducts.scss';

export interface ICategoryDetails {
  name?: TtoolCategories;
  nameStringId?: string;
  variantImage?: TbordTwoToneIcon;
}

let allowLoadingMore = true;
let listeningIsValidating = false;

const ListOfProducts = () => {
  const { stateSubscription } = useStateSubscriptions();
  const { levelSubscription } = stateSubscription;
  const { storeCountry } = useStoreCountryState();
  const { ecommerceFilters, setPillsFilters, setCategoryFilters, setLoadingFilterPills } = useShopStore();
  const { formSinglePillFilterUI } = useEcommerceControler();
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);
  const [categoryDetails, setCategoryDetails] = useState<ICategoryDetails>();
  const intitialPagination = page === 1;

  const [productsInTheList, setProductsInTheList] = useState<IProductItemList[]>([]);

  const hidenProductContainer = !!productsInTheList && productsInTheList?.length <= 0;

  const divRef = useRef<HTMLDivElement>(null);
  const itemLimit = 16;
  const freePlan = levelSubscription === 'Free';
  const options = {
    revalidateOnFocus: false,
    revalidateIfStale: false
  };

  const params =
    ecommerceFilters && ecommerceFilters.length > 0
      ? `?filters=${JSON.stringify([
          ...ecommerceFilters,
          { name: 'country', includes: 'in', values: [storeCountry?.countryCode || ''] }
        ])}`
      : '';
  const orgInfo = formatOrgData();

  const { data, isValidating, error } = useSWR(
    params && itemLimit && page && storeCountry
      ? `/catalog/items-by-organization/${orgInfo?.organizationId}${params}&limit=${itemLimit}&page=${page}`
      : '',
    getProductList,
    options
  );

  listeningIsValidating = isValidating;

  const { data: filters, error: errorFilerts } = useSWR('/category', getCategories, options);

  const loadingList = !data && !error && intitialPagination;
  const loadingHasMore = !data && !error && !intitialPagination;

  const creatingAndFormattingFilters = () => {
    if (intitialPagination) {
      setLoadingFilterPills(true);
      if (data?.pillsFilters) {
        const pillsFilters = Object.entries(data.pillsFilters).map(pillFilter => {
          const [pillFilterName] = pillFilter;
          const pillFilterObject: ObjectKey<valueKey[]> = {};
          pillFilterObject[`${pillFilterName}`] = formSinglePillFilterUI(pillFilter);
          return pillFilterObject;
        });
        setPillsFilters(pillsFilters);
        setLoadingFilterPills(false);
      }
    }
  };

  useEffect(() => {
    creatingAndFormattingFilters();
  }, [data]);

  const resetPage = () => {
    if (storeCountry) {
      setPage(0);
      allowLoadingMore = true;
    }
  };

  useEffect(() => {
    if (data?.items) {
      setProductsInTheList(prevItems => [...prevItems, ...data.items]);
      if (data.items.length < 16) {
        allowLoadingMore = false;
      }
    }
  }, [data]);

  const handleScroll = () => {
    const div = divRef.current;
    const addScrollAndHeight = Number(div?.scrollTop) + Number(div?.clientHeight) + 5;
    const isValidForPagination =
      div && addScrollAndHeight >= div.scrollHeight && allowLoadingMore && !loadingHasMore && !listeningIsValidating;
    if (isValidForPagination) {
      setPage(prevPageIndex => prevPageIndex + 1);
    }
  };

  const eventProductList = () => {
    segmentTrackEvent({
      catalogueView: { TypeOfPlatform: freePlan ? 'Free' : 'Prime', CountryName: storeCountry?.countryName || '' }
    });
  };

  const geterateVariantImg = (name: TtoolCategories) => {
    switch (name) {
      case 'Laptops':
        return 'laptopCharging';
      case 'Laptops con DEP':
        return 'macLaptopSettings';
      case 'Laptops CTOs':
        return 'laptopSettings';
      case 'Monitores':
        return 'computer';
      case 'Mobiliario':
        return 'computerDesk';
      default:
        return 'headset';
    }
  };

  const takeNameFromCategory = ({ name, nameStringId }: ICategoryDetails) => {
    if (name && nameStringId) {
      const variantImage = geterateVariantImg(name);
      setCategoryDetails({ name, nameStringId, variantImage });
    }
  };

  useEffect(() => {
    eventProductList();
  }, []);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener('scroll', handleScroll);
      return () => div.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    filters && setCategoryFilters(filters);
  }, [filters]);

  useEffect(() => {
    resetPage();
  }, [storeCountry, params]);

  useEffect(() => {
    if (page === 0) {
      setProductsInTheList([]);
      setPage(1);
    }
  }, [page]);

  return (
    <section id="ecommerce-home">
      {!!filters && (
        <HorizontalFilters
          filters={filters}
          name="category"
          resetPage={() => {
            resetPage();
          }}
          takeNameFromCategory={takeNameFromCategory}
        />
      )}
      {!filters && !errorFilerts && <SkeletonFilters />}
      <div id="product-list-container" className="listOfProducts" ref={divRef}>
        {loadingList && (
          <div className="containerMyProductList">
            <SkeletonProductEcommerceCard />
          </div>
        )}
        <div className={'containerMyProductList'} style={{ paddingBottom: `${hidenProductContainer ? '0rem' : ''}` }}>
          {!!productsInTheList &&
            productsInTheList?.length > 0 &&
            productsInTheList?.map((item, index) => {
              return (
                <ProductEcommerceCard
                  dataProductCard={item}
                  key={`product-ecommerce-card-${item?.productId}-index${index}`}
                />
              );
            })}
          {loadingHasMore && <SkeletonProductEcommerceCard numberCards={8} />}{' '}
        </div>
        {intitialPagination && data?.items?.length === 0 && (
          <div className="withoutResults">
            <BordEmptyState
              title={`No hay ${
                categoryDetails?.nameStringId
                  ? t(`services:filters:category:${categoryDetails?.nameStringId}`)
                  : categoryDetails?.name
              } disponibles para ${storeCountry?.countryName}`}
              subTitle="¿Necesitas alguna en específico? Cuéntanos y la encontraremos para ti"
              twoToneIconProps={{
                variant: categoryDetails?.variantImage || 'laptopCharging',
                customWidth: '10rem',
                colorTones: {
                  tone1: bordColorTheme.primary.bgDefault,
                  tone2: bordColorTheme.white.default,
                  opacity1: '1',
                  opacity2: '1'
                }
              }}
              buttonOneProps={{
                label: 'Solicitar producto',
                buttonIconTwo: 'plus',
                customWidth: 'w-280',
                onClick: handleClickForHelp
              }}
              customClassNameSubTitle="subtitleButtonText"
            />
          </div>
        )}
        {intitialPagination && error && <GenericFetchError />}
      </div>
    </section>
  );
};

export default ListOfProducts;
