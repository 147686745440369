import { Store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next';
import activateNotify from './activeNotify';
import { BordToast } from '../components/BordDesignSystem';
import { toastVariants } from '../components/BordDesignSystem/BordToast/BordToast.type';

/**
 * IMPORTANT: line heights must be in the html structure of the customJSXmessage. If a string is to be passed it should be within <></>
 * @property {JSX.Elemen} customJSXMessage - A JSXElement to display as message. To passed a string include it in a fragment <></>
 * @property {string} button1Text - The text to show on the first button
 * @property {()=>void} button1Action - The callback to execute onClick the first button
 * @property {string} button2Text - The text to show on the second button
 * @property {()=>void} button2Action - The callback to execute onClick the second button
 * @property {number} duration - Optional: The time the notification must be displayed, in milliseconds. By default the notification is active until the user clicks on it to close it
 */
const displaySuccessNotification = (params?: IdisplayNudosNotificationParams) => {
  Store.removeAllNotifications();
  const {
    button1Text,
    button2Text,
    duration,
    customJSXMessage,
    button1Action,
    button2Action,
    variant = 'informative',
    hideAction = true
  } = params || {};
  const notificationHasActions = !!button1Text || !!button2Text;
  const notificationDuration = notificationHasActions ? duration : 8000;

  activateNotify(
    <BordToast
      variant={variant}
      customJSXMessage={customJSXMessage}
      actionLabel={button1Text}
      action={button1Action}
      actionLabelTwo={button2Text}
      actionTwo={button2Action}
      hideAction={hideAction}
    />,
    notificationDuration
  );
};

/**
 * IMPORTANT: if no customJSXMessage is passed, the message shown is the genericErrorMessage
 * IMPORTANT: line heights must be in the html structure of the customJSXmessage. If a string is to be passed it should be within <></>
 * @property {JSX.Elemen} customJSXMessage - A JSXElement to display as message. To passed a string include it in a fragment <></>.
 * @property {string} button1Text - The text to show on the first button
 * @property {()=>void} button1Action - The callback to execute onClick the first button
 * @property {string} button2Text - The text to show on the second button
 * @property {()=>void} button2Action - The callback to execute onClick the second button
 * @property {number} duration - Optional: The time the notification must be displayed, in milliseconds. By default the notification is active until the user clicks on it to close it
 */
const displayErrorNotification = (params?: IdisplayNudosNotificationParams) => {
  Store.removeAllNotifications();

  activateNotify(
    <BordToast
      variant="danger"
      customJSXMessage={params?.customJSXMessage || <DefaultErrorMessage />}
      actionLabel={params?.button1Text}
      action={params?.button1Action}
      actionTwo={params?.button2Action}
      actionLabelTwo={params?.button2Text}
      hideAction={params?.hideAction}
    />,
    params?.duration
  );
};

const DefaultErrorMessage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="toastTitle">{t('recurrentWords:genericErrorMessageTitle')}</div>
      <div className="toastDescription">{t('recurrentWords:genericErrorMessageDescription')}</div>
    </div>
  );
};

export { displaySuccessNotification, displayErrorNotification };

export interface IdisplayNudosNotificationParams {
  customJSXMessage?: JSX.Element;
  duration?: number;
  button1Text?: string;
  button1Action?: () => void;
  button2Text?: string;
  button2Action?: () => void;
  variant?: toastVariants;
  hideAction?: boolean;
}
