const RoundBritishVirginIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#1A47B8"
          d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16C0 16.0038 16 .00175 16 0c8.8365 0 16 7.1635 16 16Z"
        />
        <path
          fill="#fff"
          d="M15.957 16.0001h.0428v-.0428c-.0142.0143-.0285.0285-.0428.0428Z"
        />
        <path
          fill="#fff"
          d="M16 8.34781V0h-.0027C7.16194.0015 0 7.16431 0 16h8.34781v-4.7007L13.0485 16h2.9087c.0143-.0142.0286-.0285.0429-.0428v-2.9086l-4.7009-4.70079H16Z"
        />
        <path
          fill="#F93939"
          d="M8.09466 2.08691C5.59223 3.51185 3.51179 5.59223 2.08691 8.09466V16h4.17394V6.26085H16V2.08691H8.09466Z"
        />
        <path
          fill="#FFDA2C"
          d="m22.9564 16-4.3246 4.3246c.8105 1.5631 2.4423 2.6319 4.3246 2.6319 1.8823 0 3.5141-1.0688 4.3246-2.6319L22.9564 16Z"
        />
        <path
          fill="#F93939"
          d="m15.9998 14.0325-5.6846-5.6846H8.34766v.00013L15.9997 16h.0001v-1.9675Z"
        />
        <path
          fill="#6DA544"
          d="M18.0869 8.3479v7.6522c.0001 3.7274 4.8696 4.8695 4.8696 4.8695s4.8695-1.1421 4.8695-4.8695V8.3479h-9.7391Z"
        />
        <path fill="#fff" d="M21.9131 11.8262H24v5.5652h-2.0869v-5.5652Z" />
        <path
          fill="#A2001D"
          d="M22.9566 12.8695c.5763 0 1.0435-.4672 1.0435-1.0435s-.4672-1.0435-1.0435-1.0435-1.0435.4672-1.0435 1.0435.4672 1.0435 1.0435 1.0435Z"
        />
        <path
          fill="#FFDA2C"
          d="M19.4785 9.73901h1.3913v1.39129h-1.3913V9.73901ZM19.4785 12.8694h1.3913v1.3913h-1.3913v-1.3913ZM19.4785 16h1.3913v1.3913h-1.3913V16ZM25.0439 9.73901h1.3914v1.39129h-1.3914V9.73901ZM25.0439 12.8694h1.3914v1.3913h-1.3914v-1.3913ZM25.0439 16h1.3914v1.3913h-1.3914V16Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBritishVirginIslands;
