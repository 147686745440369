import create from 'zustand';

export interface iState {
  refreshNodiDetail: boolean;
  setRefreshNodiDetail: (status: boolean) => void;
}

const useAssignationsStates = create<iState>(set => ({
  refreshNodiDetail: false,
  setRefreshNodiDetail: refreshNodiDetail => set({ refreshNodiDetail })
}));

export default useAssignationsStates;
