import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { getOrgData } from '../../../utils/getLocalStorageData';
import { Iemployee } from '../../../types/global';
import { TlogisticsOriginOrDestinationData } from '../../../types/requestLogisticsModule';
import { getEmployeesListing } from '../../../services/employees.service';
import { ICountryDetail } from '../../../types/countries';
import { NudosHoverText } from '../../NudosComponents';
import { TformComponentsStandardSizes } from '../../BordDesignSystem/bordDesignSystem.types';
import { BordDropdown, BordFlag, BordPhoto } from '../../BordDesignSystem';
import { TcountryCode } from '../../BordDesignSystem/BordFlag/BordFlag.types';
import './NudosLogisticsEmployeesDropdown.scss';

/**
 *@property { TcomponentSize } componentSize - It determines the width of the component
 *@property { (newValue: TlogisticsOriginOrDestinationData) => void } updateCurrentValueCallback - the callback function to update the current selected employee as it is stored in the parent component state as the destinationData
 *@property { Iemployee } currentValue - the data for the currently selected employee
 *@property { Iemployee } originEmployee - the data of the employee already that already has been selected as origin, so it must be excluded from the options
 *@property { boolean } isFocused - a boolean indicating if the component is focused or not. DEFAULT is false.
 *@property {}  filterCountries - A list of country objects from which the employee MUST BELONG (only employees from this countries will be shown)
 */
const NudosLogisticsEmployeesDropdown = ({
  componentSize,
  updateCurrentValueCallback,
  currentValue,
  originEmployee,
  originUrl,
  isFocused,
  filterCountries,
  showFlag,
  nameCharactersLimit
}: {
  componentSize?: TformComponentsStandardSizes;
  originUrl: string;
  updateCurrentValueCallback: (newValue: TlogisticsOriginOrDestinationData) => void;
  currentValue?: Iemployee;
  originEmployee?: Iemployee;
  isFocused?: boolean;
  filterCountries?: ICountryDetail[];
  showFlag?: boolean;
  nameCharactersLimit?: number;
}) => {
  const { t } = useTranslation();
  const orgId = getOrgData()?.organizationId;
  const { search } = useLocation();
  const [employees, setEmployees] = useState<Iemployee[]>([]);
  const [employeesLoader, setEmployeesLoader] = useState<boolean>(false);
  const originIsCreate = new URLSearchParams(search).get('from') === 'create';
  const i18nLanguageKey = 'designSystemComponents:nudosLogisticsEmployeesDropdown';

  const logisticDetailsRedirection = {
    pathname: `/nodi/employees/create`,
    state: {
      originUrl: originUrl,
      originText: t(`${i18nLanguageKey}:logisticDetailsRedirection:state:originText`)
    }
  };

  const getEmployeesByOrganization = async () => {
    if (!orgId) return;
    setEmployeesLoader(true);
    try {
      const employees = await getEmployeesListing(orgId);
      if (!employees) return;
      const activeEmployees = employees?.filter(
        employee => !!employee?.active && (!originEmployee || employee?.userId !== originEmployee?.userId)
      );
      const validCountries =
        filterCountries && filterCountries.length ? filterCountries.map(country => country.code) : undefined;
      const employeesInValidCountries = validCountries
        ? activeEmployees.filter(employee => validCountries?.includes(employee?.country?.code || ''))
        : activeEmployees;
      setEmployees(employeesInValidCountries);
      originIsCreate && updateCurrentValueCallback(employees[0]);
    } catch {
      displayErrorNotification();
    } finally {
      setEmployeesLoader(false);
    }
  };

  const formDropdownOptionsCallback = (
    rawOptions: Iemployee[],
    clickOptionCallback?: (option: Iemployee) => void,
    searchString?: string
  ) => {
    const createEmployee = (
      <Link to={logisticDetailsRedirection} key="createEmployeeOption">
        <div className="createEmployeeLink">{t(`${i18nLanguageKey}:createEmployeeLink`)}</div>
      </Link>
    );
    const handleSelectOption = (option: Iemployee) => {
      updateCurrentValueCallback(option);
      clickOptionCallback && clickOptionCallback(option);
    };

    const getOptionText = () => {
      if (employeesLoader) return `${t('recurrentWords:loading')}...`;
      if (searchString) return t('recurrentWords:noSearchOption');
      return t('recurrentWords:youNotHaveEmployeesCreated');
    };

    if (rawOptions.length === 0)
      return [
        <div className="optionContainer" key="loading">
          <div className="optionText">{getOptionText()}</div>
          {!employeesLoader && createEmployee}
        </div>
      ];

    const employeeOptions = rawOptions.map((rawEmployeeData, i) => {
      const employeeFullName = `${rawEmployeeData?.firstName || ''} ${rawEmployeeData?.lastName || ''}`;
      const employeePhoto = rawEmployeeData?.photoProfile?.replaceAll(' ', '%20');
      const flagCountryCode = (rawEmployeeData?.country?.code || undefined) as TcountryCode;
      const charactersLimit = nameCharactersLimit || 38;
      return (
        <div
          className="optionContainer employeeOption"
          key={`employee${i}${rawEmployeeData?.id}`}
          onClick={() => handleSelectOption(rawEmployeeData)}
        >
          <BordPhoto
            size="s20"
            url={employeePhoto}
            avatarProps={{
              variant: 'rectangularBordMascot',
              customWidth: '2rem'
            }}
            imageProps={{ style: { width: '100%' } }}
          />
          <NudosHoverText
            customClassName="employeeName"
            text={employeeFullName}
            charactersLimit={charactersLimit}
            onlyIfTruncated
          />
          {showFlag && <BordFlag variant="square" standardSize={20} country={flagCountryCode} />}
        </div>
      );
    });
    return [...employeeOptions, createEmployee];
  };

  const formSelectedOptionUICallback = (rawEmployeeData: Iemployee) => {
    const employeeFullName = `${rawEmployeeData?.firstName || ''} ${rawEmployeeData?.lastName || ''}`;
    return <div className="optionContainer">{employeeFullName}</div>;
  };

  const filterBySearchStringCallback = (search: string, rawOptions: Iemployee[]) => {
    return rawOptions.filter(rawEmployeeData => {
      const employeeFullName = `${rawEmployeeData?.firstName || ''} ${rawEmployeeData?.lastName || ''}`;
      return (
        rawEmployeeData?.firstName?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        rawEmployeeData?.lastName?.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        employeeFullName?.toLocaleLowerCase().trim().includes(search.toLocaleLowerCase().trim()) ||
        rawEmployeeData?.country?.name?.toLocaleLowerCase().trim().includes(search.toLocaleLowerCase().trim())
      );
    });
  };

  useEffect(() => {
    getEmployeesByOrganization();
  }, [orgId]);

  return (
    <div className="NudosLogisticsEmployeesDropdown">
      <BordDropdown
        label="Empleados"
        componentSize={componentSize}
        rawOptions={employees}
        formDropdownOptionsCallback={formDropdownOptionsCallback}
        formSelectedOptionUICallback={formSelectedOptionUICallback}
        filterBySearchStringCallback={filterBySearchStringCallback}
        currentValue={currentValue}
        isFilled={!!currentValue}
        isFocused={isFocused}
        customPlaceholder={t(`${i18nLanguageKey}:nudosSearchbarDropdown:placeHolder`)}
      />
    </div>
  );
};

export default NudosLogisticsEmployeesDropdown;
