const SquareVaticanCity = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 0H8.38095V16H0V0Z" clipRule="evenodd" />
          <path fill="white" fillRule="evenodd" d="M8.38086 0H16.7618V16H8.38086V0Z" clipRule="evenodd" />
          <path stroke="#CBCBCB" d="M10.4766 6.3999L13.6194 9.5999" />
          <path stroke="#FF6C2D" d="M12.0479 8V10.6667" opacity="0.6" />
          <path
            fill="#D4AF2C"
            fillRule="evenodd"
            d="M10.4766 6.39994H13.6194L12.048 4.2666L10.4766 6.39994Z"
            clipRule="evenodd"
          />
          <path stroke="#D4AF2C" d="M13.6194 6.3999L10.4766 9.5999" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareVaticanCity;
