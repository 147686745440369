const SquareCanada = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path
            fill="#F93939"
            d="M11.2353 12H10.7647L10.8824 10.2703C10.8757 10.1792 10.8281 10.1552 10.6471 10.1622L8.76471 10.3784C8.76471 10.3784 9.11765 9.94595 9.11765 9.72973 9.11765 9.51351 7 8.10811 7 8.10811 7 8.10811 7.47059 8 7.58824 7.89189 7.70588 7.78378 7.11765 6.59459 7.11765 6.59459 7.11765 6.59459 8.31321 7.02703 8.41176 6.91892 8.51032 6.81081 8.64706 6.37838 8.64706 6.37838 8.64706 6.37838 9.58824 7.35135 9.82353 7.35135 10.0588 7.35135 9.35294 4.97297 9.35294 4.97297 9.35294 4.97297 9.94118 5.40541 10.1765 5.40541 10.4118 5.40541 11 4 11 4 11 4 11.5882 5.40541 11.7059 5.40541 11.8235 5.40541 12.6471 4.97297 12.6471 4.97297 12.6471 4.97297 12.0588 7.24324 12.1765 7.35135 12.2941 7.45946 13.3529 6.37838 13.3529 6.37838 13.3529 6.37838 13.4706 6.81081 13.5882 6.91892 13.7059 7.02703 14.8824 6.59459 14.8824 6.59459 14.8824 6.59459 14.2941 7.78378 14.4118 7.89189 14.5294 8 15 8.10811 15 8.10811 15 8.10811 12.8824 9.51351 12.8824 9.72973 12.8824 9.94595 13.1176 10.3784 13.1176 10.3784L11.3529 10.1622C11.2111 10.1248 11.1619 10.1532 11.1176 10.2703L11.2353 12ZM17 0H22V16H17zM0 0H5V16H0z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareCanada;
