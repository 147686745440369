import { convertSimpleDateToDateTimeZulu } from './formatDatesAndHours';

interface IOutRange {
  startDate: Date;
  endDate: Date;
}

const formatDateFromDateRange = (stateDate?: IOutRange, utcTimeOffset?: number | null) => {
  if (!stateDate) return '';
  const startDate = stateDate.startDate.toISOString();
  if (!startDate) return '';
  return convertSimpleDateToDateTimeZulu(startDate.split('T')[0], utcTimeOffset || undefined);
};

export { formatDateFromDateRange };
