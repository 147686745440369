import { LOGISTIC_SERVICE_TYPE } from '../../../../../../../../constants';
import { IFees } from '../../../../../../../../types/logisticsServicesModule.types';

const BuyAndHoldRedistributionTooltip = ({
  feesWithoutDiscount,
  chargedFees
}: {
  feesWithoutDiscount: IFees;
  chargedFees: IFees;
}) => {
  const { redistributionTypeBetweenLocations } = chargedFees || {};

  const destinationIsWarehouseCity =
    redistributionTypeBetweenLocations === LOGISTIC_SERVICE_TYPE.SAME_CITY_REDISTRIBUTION;

  const platinumDiscount =
    +feesWithoutDiscount.redistributionWithoutBuyAndHoldDiscount - +chargedFees.redistributionWithoutBuyAndHoldDiscount;

  const warehouseCityRedistributionText = destinationIsWarehouseCity
    ? 'Buy & Hold te cubre el servicio logístico dentro de la capital.'
    : 'Buy & Hold te cubre el 50% servicio logístico a nivel nacional.';

  return (
    <div className="buyAndHoldRedistributionTooltip w-280 flex flex-col p-3">
      <span className="font-bold text-bord-sm text-content">Servicios logísticos</span>

      <span className="text-bord-sm text-content-secondary font-normal mt-4 border-b border-solid border-stroke-interactive pb-8 mb-8">
        {warehouseCityRedistributionText}
      </span>

      <div className="mb-4 flex justify-between text-content-secondary">
        Precio del servicio
        <div className="text-content">${feesWithoutDiscount.redistributionWithoutBuyAndHoldDiscount} USD</div>
      </div>

      {!!platinumDiscount && (
        <div className="mb-4 flex justify-between text-content-secondary">
          Descuento por Platinum
          <div className="text-content">${platinumDiscount} USD</div>
        </div>
      )}

      <div className="mb-4 flex justify-between text-content-secondary">
        Descuento por Buy & Hold
        <div className="text-content">
          ${chargedFees?.redistributionWithoutBuyAndHoldDiscount - chargedFees?.redistribution} USD
        </div>
      </div>

      <div className="flex justify-between text-content-secondary">
        Total a pagar
        <div className="text-content font-bold">${chargedFees?.redistribution} USD</div>
      </div>
    </div>
  );
};

export default BuyAndHoldRedistributionTooltip;
