import { useState } from 'react';
import useLogin from '../../hooks/useLogin';
import useSignStore from '../signUp/state/useSignStore';
import { displayErrorNotification } from '../../utils/displayNudosStandardNotifications';
import { verifyPhoneAndEmail } from '../../services/register';
import { emailAndPhoneValidationResponseTexts } from '../../utils/backendConstants';
import { useTranslation } from 'react-i18next';

const useSignInController = () => {
  const { t } = useTranslation();
  const { loginEmailAndPassWord } = useLogin();
  const { setEmail } = useSignStore();
  const [error, setError] = useState<string>('');

  const translationKey = 'authentication:signIn:';

  const verifyEmail = async (
    email: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setLoginStep: React.Dispatch<React.SetStateAction<number>>,
    handleError: (error: string) => void
  ) => {
    const validateParams = {
      email,
      phone: '-validationNotRequired-'
    };
    setEmail(validateParams.email || '');
    setLoading(true);
    try {
      const response = await verifyPhoneAndEmail(validateParams);
      const emailExists = [
        emailAndPhoneValidationResponseTexts.emailExists,
        emailAndPhoneValidationResponseTexts.phoneAndEmailExists
      ].includes(response);
      if (!emailExists) {
        handleError(t(`${translationKey}emailNotRegistered`));
      } else {
        setLoginStep(1);
      }
    } catch (err) {
      displayErrorNotification();
    } finally {
      setLoading(false);
      setError('');
    }
  };

  const onSubmit = async (
    data: { email: string; password: string },
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setPasswordError: React.Dispatch<React.SetStateAction<string | undefined>>
  ) => {
    setLoading(true);
    try {
      await loginEmailAndPassWord(data.email, data.password, setPasswordError);
    } catch (err) {
      setPasswordError;
      displayErrorNotification();
    } finally {
      setLoading(false);
      setError('');
    }
  };

  return {
    verifyEmail,
    onSubmit,
    error
  };
};

type FormValues = { countryCode: string; email: string; password: string };

export default useSignInController;
export type { FormValues };
