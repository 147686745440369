import { BillingData } from './cart';
import { ICountryDetail } from './countries';
import { ICoordinates, IPhoneData, IreceiverInformation } from './global';

export interface IPersonal {
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
  photoProfile?: string;
  role?: string;
  countryCode?: string;
  phoneData?: IPhoneData;
}

export interface IReleaseVideoInformation {
  id?: number;
  organizationId?: number;
  onboardingTypeId?: number;
  lastStepSeen?: number;
  isCompleted?: boolean;
  completionDate?: string;
}

export enum DASHBOARD_ONBOARDING_TYPES {
  RELEASEVIDEO2024 = '2024ReleaseVideo',
  BETAPHASEBANNER = 'betaPhaseBanner'
}

export interface IReleaseVideoBody {
  onboardingName?: string;
  lastStepSeen?: number;
  isCompleted?: boolean;
}
interface iUpdateAddress {
  address?: string;
  additionalReferences?: string | null;
  country?: string;
  coordinates: ICoordinates;
  city: string;
}
export interface IOrganization {
  name: string;
  businessName: string;
  numberOfEmployees: number;
  associatedUsers: IorganizationAssociatedUser[];
  document: string;
  image: string;
  address?: iUpdateAddress;
  phoneData?: IPhoneData;
}

export interface IorganizationAssociatedUser {
  email?: string | null;
  firstName?: string | null;
  id?: number | null;
  lastName?: string | null;
  uid?: string | null;
}

export interface IListLocationAddress {
  office: ILocationAddress[];
  others: { [key: string]: ILocationAddress[] };
}

export interface IListDefaultLocationAddress {
  offices: ILocationAddress[];
  others: ILocationAddress[];
}
export interface ILocationAddress {
  id?: number | null;
  userId?: number | null;
  organizationId?: number | null;
  locationName?: string | null;
  locationId?: number;
  place?: string | null;
  address?: string | null;
  additionalReferences?: string | null;
  coordinates?: ICoordinates | null;
  city?: string | null;
  cityId?: number | null;
  country?: ICountryDetail | null; // In request that bring many locations (offices, other addreses) country is IcountryDetail, in the request that brings one location by its id country is the two letter code for the country, eg: "mx"
  countryId?: string | number | null;
  countryData?: ICountryDetail;
  createdAt?: string | null;
  updatedAt?: string | null;
  deletedAt?: string | null;
  active?: boolean;
  zipCode?: string;
  firstName?: string;
  lastName?: string;
  personalId?: string;
  phoneData?: IPhoneData;
}

export interface IaddLogisticAddress {
  id?: number | null;
  address: string;
  additionalReferences?: string;
  coordinates?: ICoordinates | null;
  city: string;
  cityId?: number | null;
  zipCode?: string;
  countryId: number;
  countryCode?: string;
  locationName: string;
  active?: boolean;
}

export interface ILocationCountryAddress {
  address?: string;
  city?: string;
  cityId?: number | null;
  coordinates?: ICoordinates | null;
  countryId?: number;
  additionalReferences?: string;
  zipCode?: string;
  countryCode?: string; // CAMBIAR-JC: This is for a hotfix, the definitive correction should be made in the context of a refactor that resolves the differences in countryIds between the different microservices
}

export interface IorganizationOfficeData {
  locationId?: number;
  zipCode?: string | null;
  additionalReferences?: string | null;
  address?: string | null;
  coordinates?: ICoordinates;
  city?: string;
  cityId?: number | null;
  country?: {
    id: number;
    code: string;
    name: string;
    flag: string;
  };
  shipmentInfo?: IreceiverInformation;
}

export interface IWarehouse {
  locationId: number;
  city: string;
  country: {
    id: number;
    code: string;
    name: string;
    flag: string;
    timeZone: string;
  };
  coordinates?: ICoordinates | null;
}

export interface ICountryLocations {
  office: IorganizationOfficeData;
  warehouse?: IWarehouse;
}

export interface ILanguagePreferences {
  preferenceId?: string;
  userId?: string;
  language: {
    code: 'es' | 'en';
    label: string;
  };
}

export interface IorganizationBillingDataByCountry {
  countryData: ICountryDetail;
  billingEntities: BillingData[];
  canCreateNew: boolean;
}
export interface DTOgetBillingDataByOrganizationResponse {
  [key: string]: IorganizationBillingDataByCountry; // The key should be the country ISO TWO LETTER CODE
}

export interface ILastBillingData {
  billingDataId: number;
}

export type TbilingDataByCountryMap = Map<string, IorganizationBillingDataByCountry>;
