import { FC, useState } from 'react';
import { NudosStaticAlertInComponent } from '../../../../../components/DesignSystem';
import ExampleEmailModal from '../../../Components/ExampleEmailModal/ExampleEmailModal';
import { useTranslation } from 'react-i18next';
import { BordTextArea, BordModal, BordUploaderButton } from '../../../../../components/BordDesignSystem';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import { Iemployee } from '../../../../../types/global';
import './AssignmentModuleStep4SendEmail.scss';

interface IDocumentDetail {
  documentName: string;
  documentFile: File;
}
interface IAssignmentModuleStep4SendEmail {
  destinationEmployeeData?: Iemployee;
  handleEmployeeMessage?: (message: string) => void;
  defaultEmployeeMessage?: string;
  handleChangeOptionalFile?: (documentDetails: IDocumentDetail | undefined) => void;
  defaultFile?: IDocumentDetail;
}

const AssignmentModuleStep4SendEmail: FC<IAssignmentModuleStep4SendEmail> = ({
  destinationEmployeeData,
  handleEmployeeMessage,
  defaultEmployeeMessage,
  handleChangeOptionalFile
}: IAssignmentModuleStep4SendEmail) => {
  const { t } = useTranslation();
  const translationKey = 'services:logistics:email:';
  const whatWriteText = t(`${translationKey}whatWriteText`);
  const exampleEmail = t(`${translationKey}exampleEmail`);
  const alertOptionText = t(`${translationKey}alertOptionText`);

  const [showExampleModal, setShowExampleModal] = useState<boolean>(false);

  const employeeName = `${destinationEmployeeData?.firstName || ''} ${destinationEmployeeData?.lastName || ''}`;
  const closeExampleModal = () => setShowExampleModal(false);

  const handleChangeEmployeeMessage = (message: string) => {
    if (handleEmployeeMessage) handleEmployeeMessage(message);
  };

  const titleText = t(`${translationKey}title`, {
    employeeName
  });
  const textareaLabel = t(`${translationKey}textareaLabel`);
  const textareaPlaceholder = t(`${translationKey}textareaPlaceholder`, {
    employeeName
  });

  return (
    <>
      {showExampleModal && (
        <BordModal
          clickOutsideCallback={() => setShowExampleModal(false)}
          closeModalCallback={closeExampleModal}
          modalContent={<ModalContent CustomModalComponent={<ExampleEmailModal closeModal={closeExampleModal} />} />}
        />
      )}
      <div id="assignmentModuleStep4SendEmail">
        <div className="boxAssignmentModuleStep4SendEmail">
          <div className="titleSendEmail">{titleText}</div>
          <div className="containerLine">
            <div className="mailGrayLine" />
          </div>
          <div className="textAreaSendMail">
            <div className="boxTextAreaSendMail">
              <BordTextArea
                label={textareaLabel}
                customWidth="w-480"
                value={defaultEmployeeMessage || ''}
                maxLength={1000}
                placeholder={textareaPlaceholder}
                setInputValue={e => handleChangeEmployeeMessage(e)}
                bordTextAreaInputCustomClass="bordTextAreaInputCustomClass"
              />
            </div>
          </div>
          <div className="fileSendEmail">
            <BordUploaderButton
              defaultFile={{
                documentName: ''
              }}
              customWidth="w-480"
              handleChangeFile={() => handleChangeOptionalFile}
              label="Sube el documento (opcional)"
              acceptFileExtension={['.pdf']}
              emptyBordUploaderButtonTitle="la carta para firmar recibido"
              emptyBordUploaderButtonSubtitle="Formatos aceptados:PDF Peso maximo:2MB"
            />
          </div>

          <div className="inferiorAlertEmail">
            <div className="boxAlertEmail">
              <NudosStaticAlertInComponent
                customClassname="staticComponent"
                type="bulb"
                customTitleClass="titleStaticComponent"
                alertOptions={{
                  title: alertOptionText
                }}
              />
            </div>
          </div>
          <div className="containerEmailExample">
            <div className="boxEmailExample" onClick={() => setShowExampleModal(true)}>
              <div className="infoText">¿No sabes qué escribir? Mira un</div>
              <div className="blueText">&nbsp;ejemplo del correo</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentModuleStep4SendEmail;
