import SecurityLogoutModalContent from './SecurityLogoutModalContent';
import BordModal from '../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './SecurityLogoutModal.scss';

const SecurityLogoutModal = ({ closeModalCallback }: { closeModalCallback: () => void }) => {
  return (
    <div className="SecurityLogoutModal">
      <BordModal
        modalContent={
          <ModalContent
            customHeight="34rem"
            customWidth="48rem"
            excludeHeader
            CustomModalComponent={<SecurityLogoutModalContent closeModalCallback={closeModalCallback} />}
          />
        }
      />
    </div>
  );
};

export default SecurityLogoutModal;
