const RoundLaos = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M30.4126 9.0435C27.8246 3.69156 22.3436 0 16.0003 0 9.65689 0 4.17589 3.69156 1.58789 9.0435L16.0003 10.4348 30.4126 9.0435ZM1.58789 22.9565C4.17589 28.3084 9.65689 32 16.0003 32 22.3436 32 27.8246 28.3084 30.4126 22.9565L16.0003 21.5652 1.58789 22.9565Z"
        />
        <path
          fill="#1A47B8"
          d="M30.4124 9.04346H1.58763C0.5705 11.1469 0 13.5068 0 16C0 18.4931 0.5705 20.853 1.58763 22.9565H30.4123C31.4296 20.853 32 18.4931 32 16C32 13.5068 31.4296 11.1469 30.4124 9.04346Z"
        />
        <path
          fill="white"
          d="M15.9998 21.5652C19.0733 21.5652 21.5649 19.0736 21.5649 16C21.5649 12.9264 19.0733 10.4348 15.9998 10.4348C12.9262 10.4348 10.4346 12.9264 10.4346 16C10.4346 19.0736 12.9262 21.5652 15.9998 21.5652Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLaos;
