import {
  IIssueReported,
  ILogisticReports,
  IlogisticServiceDetailedData,
  LS_ADDITIONAL_SERVICE_STATUS,
  LS_MANUAL_QUOTE_STATUS,
  LS_STATUS
} from '../../../../../../../types/logisticsServicesModule.types';
import { getOrderRequiredDataByPaymentMethod } from '../../../../../../../utils/productDefinitions';
import { Trans, useTranslation } from 'react-i18next';
import { reportReason } from '../../../../../../../constants/logisticServices.constants';
import { PAYMENT_TYPES } from '../../../../../../../types/checkout';

const LogisticServiceNotificationState = ({
  isLoading,
  logisticServiceData
}: {
  isLoading?: boolean;
  logisticServiceData?: IlogisticServiceDetailedData;
}) => {
  const { t } = useTranslation();

  const translationKey = 'nodi:logisticServices:logisticServiceNotifications:';
  const originTranslation = t('recurrentWords:origin');
  const destinationTranslation = t('recurrentWords:destination');
  const waitingQuoteText = t(`${translationKey}waitingQuoteText`);
  const externalProblemInProgressState = t(`${translationKey}externalProblemInProgressState`);
  const externalProblemState = t(`${translationKey}externalProblemState`);
  const theQuoteIsAvailable = t(`${translationKey}theQuoteIsAvailable`);

  const {
    addressIssue,
    status,
    quoteDocumentUrl,
    reports,
    manualQuote,
    manualQuoteStatus,
    reportNumberOfTries,
    isCancelledAutomatically,
    tools,
    paymentLink,
    paymentMethod,
    billingMethod,
    isPaymentReceived,
    proofOfPaymentUrl,
    invoiceUrl
  } = logisticServiceData || {};

  const isToConfirmStatus = status === LS_STATUS?.TO_CONFIRM;
  const originIssueReportedData: IIssueReported | undefined =
    addressIssue?.origin.editedBy !== 'user'
      ? addressIssue?.origin?.issueReported?.[addressIssue?.origin?.issueReported?.length - 1]
      : undefined;

  const destinationIssueReportedData: IIssueReported | undefined =
    addressIssue?.destination?.editedBy !== 'user'
      ? addressIssue?.destination?.issueReported?.[addressIssue?.destination?.issueReported?.length - 1]
      : undefined;

  const originFilterIssuesOtherThanCity = originIssueReportedData?.wrongFields?.find(
    field => field.addressWrongField.name !== 'Número de teléfono'
  );

  const destinationFilterIssuesOtherThanCity = destinationIssueReportedData?.wrongFields?.find(
    field => field.addressWrongField.name !== 'Número de teléfono'
  );

  const originHasOnlyPhoneIssues = !destinationIssueReportedData && !originFilterIssuesOtherThanCity;
  const destinationHasOnlyePhoneIssues = !originIssueReportedData && !destinationFilterIssuesOtherThanCity;

  const displayApproveOrRejectLSNotification =
    !!manualQuote &&
    !isLoading &&
    !!quoteDocumentUrl &&
    isToConfirmStatus &&
    manualQuoteStatus !== LS_MANUAL_QUOTE_STATUS?.APPROVED;
  const bothHaveOnlyPhoneIssues =
    !!originIssueReportedData &&
    !!destinationIssueReportedData &&
    !originFilterIssuesOtherThanCity &&
    !destinationFilterIssuesOtherThanCity;

  const onlyPhoneIssue = bothHaveOnlyPhoneIssues || originHasOnlyPhoneIssues || destinationHasOnlyePhoneIssues;
  const originAddressChangedByOps = addressIssue?.origin?.editedBy === 'nudos';
  const destinationAddressChangedByOps = addressIssue?.destination?.editedBy === 'nudos';
  const originViewedChanges = addressIssue?.origin?.viewedChanges;
  const destinationViewedChanges = addressIssue?.destination?.viewedChanges;
  const isCancelledAutomaticallyByReports = isCancelledAutomatically && !!reports?.length;
  const originChangedNotViewed = originAddressChangedByOps && !originViewedChanges;
  const destinationChangedNotViewed = destinationAddressChangedByOps && !destinationViewedChanges;
  const addressChangedByOpsNotViewed = originChangedNotViewed || destinationChangedNotViewed;

  const displayReportsBanner =
    reports?.some(report => report?.currentStatus === 'OPEN') || isCancelledAutomaticallyByReports;

  const sortedReports = reports?.sort((date1, date2) => {
    const getLastLogDate = (report: ILogisticReports) => new Date(report?.logs?.[report.logs.length - 1]?.date || 0);
    return getLastLogDate(date2).getTime() - getLastLogDate(date1).getTime();
  });

  const latestReport = sortedReports?.[0];

  const someToolHasFailedAdditionalServices = !!tools?.some(
    tool =>
      !!tool?.logisticAdditionalServices?.some(
        service => service?.status?.statusName === LS_ADDITIONAL_SERVICE_STATUS.NOT_COMPLETED
      )
  );

  const originIssueReported = addressIssue?.origin?.issueReported?.length;
  const originAddressEditedByUser = addressIssue?.origin?.editedBy === 'user';
  const destinationIssueReported = addressIssue?.destination?.issueReported?.length;
  const destinationAddressEditedByUser = addressIssue?.destination?.editedBy === 'user';

  const displayOriginIssueText = !!originIssueReported && !originAddressEditedByUser;
  const displayDestinationIssueText = !!destinationIssueReported && !destinationAddressEditedByUser;
  const displayAddressIssue = displayOriginIssueText || displayDestinationIssueText;
  const displayAddressIssueOnlyPhone = displayAddressIssue && onlyPhoneIssue;

  const { requiresPaymentLink, requiresBankData } = getOrderRequiredDataByPaymentMethod(
    paymentMethod || '',
    billingMethod,
    true
  );

  const placeValue =
    originAddressChangedByOps || (displayOriginIssueText && displayDestinationIssueText)
      ? 'ambas ubicaciones'
      : displayOriginIssueText
      ? originTranslation
      : destinationTranslation;

  const failureToMeetDeadlinesState = (
    <Trans i18nKey={t(`${translationKey}failureToMeetDeadlinesState`)} components={{ 1: `${reportNumberOfTries}` }} />
  );
  const employeeNotCooperateState = (
    <Trans i18nKey={t(`${translationKey}employeeNotCooperateState`)} components={{ 1: `${reportNumberOfTries}` }} />
  );
  const addressIssueState = <Trans i18nKey={t(`${translationKey}addresIssueState`)} components={{ 1: placeValue }} />;
  const addressChangedNotViewedState = (
    <Trans i18nKey={t(`${translationKey}addressChangedNotViewedState`)} components={{ 1: placeValue }} />
  );

  const latestReportIsInProgress = latestReport?.reasonGlobalStatus === 'IN_PROGRESS';

  const findReportByReason = (reasons: string | string[]) =>
    reports?.find(report => {
      if (!report?.reason) return;
      const reasonMatch = Array.isArray(reasons) ? reasons?.includes(report?.reason) : report?.reason === reasons;
      return reasonMatch;
    });

  const withoutReasonReport = findReportByReason(reportReason?.WITHOUT_REASON);
  const employeeNotWillingReport = findReportByReason(reportReason?.EMPLOYEE_NOT_WILLING);
  const failureToMeetDeadlines = findReportByReason([
    reportReason?.EMPLOYEE_NOT_DATE_DELIVERY,
    reportReason?.EMPLOYEE_NOT_DATE_RECEIVER
  ]);
  const employeeDoesNotCooperate = findReportByReason(reportReason?.EMPLOYEE_DOES_NOT_COOPERATE);
  const holidayReport = findReportByReason(reportReason?.HOLIDAY_REPORT);
  const externalProblem = findReportByReason([
    reportReason?.ORIGIN_POINT_ADVERSE,
    reportReason?.DESTINATION_POINT_ADVERSE
  ]);
  const awaitResponseReport = findReportByReason(reportReason?.AWAIT_RESPONSE);
  const employeeDoesNotAnswerReport = findReportByReason([
    reportReason?.UNRESPONSIVE_DELIVERY_EMPLOYEE,
    reportReason?.UNRESPONSIVE_RECEIVING_EMPLOYEE
  ]);

  const displayNoInvoiceState =
    paymentMethod === PAYMENT_TYPES.TRANSFER_ARS && isToConfirmStatus && !invoiceUrl && !isPaymentReceived;
  const displayInvoiceReadySate =
    paymentMethod === PAYMENT_TYPES.TRANSFER_ARS && isToConfirmStatus && invoiceUrl && !isPaymentReceived;
  const displayisPaymentReceivedStatus =
    paymentMethod === PAYMENT_TYPES.TRANSFER_ARS && isPaymentReceived && isToConfirmStatus;

  const getLogisticServiceState = () => {
    if (isLoading) return null;
    if (displayApproveOrRejectLSNotification) return null;
    if (requiresPaymentLink && isToConfirmStatus && quoteDocumentUrl)
      return !paymentLink
        ? '⚠️ Espera el link de pago para realizar el pago'
        : '⚠️ Realiza el pago desde el link de pago';
    if (displayNoInvoiceState) return '⚠️ Espera la factura para realizar el pago';
    if (displayInvoiceReadySate) return '⚠️ Factura disponible, sube el comprobante de pago';
    if (displayisPaymentReceivedStatus) return '✅ Comprobante de pago recibido';
    if (requiresBankData && isToConfirmStatus)
      return !proofOfPaymentUrl ? '⚠️ Sube el comprobante de pago' : '✅ Comprobante de pago recibido';
    if (manualQuote && isToConfirmStatus) return quoteDocumentUrl ? theQuoteIsAvailable : waitingQuoteText;
    if (paymentLink && isPaymentReceived && isToConfirmStatus) return '✅ Pago recibido';
    if (displayReportsBanner && latestReport) {
      switch (latestReport) {
        case awaitResponseReport:
          return t(`${translationKey}awaitResponseReportText`);
        case employeeDoesNotAnswerReport:
          return t(`${translationKey}employeeDoesNotAnswerText`);
        case failureToMeetDeadlines:
          return failureToMeetDeadlinesState;
        case employeeDoesNotCooperate:
        case employeeNotWillingReport:
          return employeeNotCooperateState;
        case holidayReport:
          return t(`${translationKey}holidayReportText`);
        case withoutReasonReport:
          return t(`${translationKey}withoutReasonReportText`);
        case externalProblem:
          return latestReportIsInProgress ? externalProblemInProgressState : externalProblemState;
      }
    }
    if (status === LS_STATUS?.CANCELLED) return null;
    if (someToolHasFailedAdditionalServices) return '⚠️ No se pudo realizar uno de los servicios solicitados';
    if (addressChangedByOpsNotViewed) return addressChangedNotViewedState;
    if (displayAddressIssueOnlyPhone) return `⚠️ Hay problemas con el número de teléfono de ${placeValue}`;
    if (displayAddressIssue) return addressIssueState;
    return null;
  };
  const logisticServiceState = getLogisticServiceState();
  return logisticServiceState ? <div className="state">{logisticServiceState}</div> : <></>;
};

export default LogisticServiceNotificationState;
