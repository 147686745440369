const SquareBrazil = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#249F58" rx="2" />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="m11 2.1333 7.8572 5.86667L11 13.8666 3.14288 7.99997"
          clipRule="evenodd"
        />
        <path
          fill="#1A47B8"
          d="M11 11.7333c2.025 0 3.6666-1.6715 3.6666-3.73336 0-2.06187-1.6416-3.73334-3.6666-3.73334-2.02506 0-3.66669 1.67147-3.66669 3.73334 0 2.06186 1.64163 3.73336 3.66669 3.73336Z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M9.42859 9.6001v1.0667h1.04761V9.6001H9.42859Zm2.09521 0v1.0667h1.0476V9.6001h-1.0476ZM8.38098 6.3999s2.37182.42667 4.08152 1.1712l2.2042.96214"
          clipRule="evenodd"
        />
        <path
          stroke="#fff"
          d="M8.38098 6.3999s2.37182.42667 4.08152 1.1712l2.2042.96214"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareBrazil;
