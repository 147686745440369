const SquareGuyana = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#249F58" rx="2" />
          <path fill="white" fillRule="evenodd" d="M0 16V0H3.14286L22 6.4V9.6L3.14286 16H0Z" clipRule="evenodd" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 0L22 8L0 16V0Z" clipRule="evenodd" />
          <path fill="#151515" fillRule="evenodd" d="M0 16V0H1.04762L11.5238 8L1.04762 16H0Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 0L10.4762 8L0 16V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareGuyana;
