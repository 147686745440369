const RoundSpain = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M0 16C0 17.9571 0.352063 19.8318 0.995188 21.5651L16 22.9565L31.0048 21.5651C31.6479 19.8318 32 17.9571 32 16C32 14.0428 31.6479 12.1681 31.0048 10.4348L16 9.04346L0.995188 10.4348C0.352063 12.1681 0 14.0428 0 16H0Z"
        />
        <path
          fill="#F93939"
          d="M31.0047 10.4348C28.7441 4.34206 22.8794 0 15.9999 0 9.12049 0 3.2558 4.34206.995117 10.4348H31.0047ZM.995117 21.5652C3.2558 27.6579 9.12049 32 15.9999 32 22.8794 32 28.7441 27.6579 31.0047 21.5652H.995117Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSpain;
