const RoundAlbania = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#000"
          d="M25.0435 11.8756h-5.7954c.3578-.3746.5779-.8818.5779-1.4408 0-1.15252-.9343-2.0869-2.0869-2.0869-.7261 0-1.3653.371-1.7391.93356-.3738-.56256-1.0131-.93356-1.7391-.93356-1.1526 0-2.087.93438-2.087 2.0869 0 .559.2202 1.0662.578 1.4408H6.95654c0 1.5368 1.33857 2.7826 2.87525 2.7826h-.09275c0 1.5368 1.24586 2.7826 2.78266 2.7826 0 .4978.1312.9644.3602 1.3684l-2.3081 2.3082 1.7709 1.7708 2.512-2.512c.0983.0361.2002.065.3053.0847l-1.518 3.4272L16 26.4348l2.3559-2.5468-1.5179-3.4272c.1052-.0197.2069-.0486.3053-.0847l2.512 2.5119 1.7709-1.7708-2.3082-2.3082c.229-.4039.3602-.8706.3602-1.3684 1.5368 0 2.7827-1.2457 2.7827-2.7826h-.0927c1.5367.0001 2.8753-1.2456 2.8753-2.7824Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAlbania;
