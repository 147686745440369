import { useTranslation } from 'react-i18next';
import { LOCATION_PLACE_TYPE } from '../types/global';
import { ICountryDetail } from '../types/countries';
import { getCountryName } from '../utils/getCountries';
import { TbordOneToneIcon } from '../components/BordDesignSystem/BordOneToneIcon/BordOneToneIcon.types';
import { TbordAvatarsVariant } from '../components/BordDesignSystem/BordAvatar/BordAvatar.types';

const useLocationData = ({
  place,
  countryData,
  locationName
}: {
  place?: LOCATION_PLACE_TYPE;
  countryData?: ICountryDetail;
  locationName?: string;
}) => {
  const { t } = useTranslation();
  const { NUDOS, OFFICE, OTHER, USER, WARRANTY, DIAGNOSTIC, STORE } = LOCATION_PLACE_TYPE;
  const translationKey = 'recurrentWords:';
  const countryName = countryData ? getCountryName(countryData) : '';

  const getLocationData = () => {
    switch (place) {
      case NUDOS: {
        return {
          oneToneIconVariant: 'archive',
          locationText: `${t(`${translationKey}warehouse`)} ${countryName}`.trim()
        };
      }
      case OFFICE: {
        return {
          oneToneIconVariant: 'building3',
          locationText: `${t(`${translationKey}office`)} ${countryName}`.trim()
        };
      }
      case OTHER: {
        return { oneToneIconVariant: 'mapPin', locationText: locationName || t(`${translationKey}otherAddress`) };
      }
      case USER: {
        return {
          oneToneIconVariant: 'user',
          locationText: locationName || t(`${translationKey}employee`),
          circularAvatarVariant: 'circularBordMascot',
          squareAvatarVariant: 'rectangularBordMascot'
        };
      }
      case WARRANTY: {
        return { oneToneIconVariant: 'shield', locationText: t(`${translationKey}warranty`) };
      }
      case DIAGNOSTIC: {
        return { oneToneIconVariant: 'tool', locationText: t(`${translationKey}diagnostic`) };
      }
      case STORE: {
        return { oneToneIconVariant: 'bord', locationText: `${t(`${translationKey}store`)} ${countryName}`.trim() };
      }
      default: {
        return {};
      }
    }
  };

  const locationData = getLocationData();
  return locationData as IuseLocationData;
};

export default useLocationData;

export interface IuseLocationData {
  oneToneIconVariant?: TbordOneToneIcon;
  locationText?: string;
  circularAvatarVariant?: TbordAvatarsVariant;
  squareAvatarVariant?: TbordAvatarsVariant;
}
