const successPromise = async (time?: number) => {
  const simulationSuccessPromise = new Promise(resolve => {
    setTimeout(() => {
      resolve({ success: true });
    }, time || 10000);
  });
  return await simulationSuccessPromise;
};

const errorPromise = async (time?: number) => {
  const simulationSuccessPromise = new Promise(resolve => {
    setTimeout(() => {
      resolve({ error: true });
    }, time || 10000);
  });
  throw await simulationSuccessPromise;
};

export { successPromise, errorPromise };
