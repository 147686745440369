const RoundSolomonIslands = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M27.3141 27.3141C33.3148 21.3135 33.5518 11.7332 28.0274 5.44824L16 15.9999L5.44824 28.0274C11.7332 33.5518 21.3134 33.3148 27.3141 27.3141Z"
        />
        <path
          fill="#1A47B8"
          d="M4.68584 4.68587C10.6865 -1.31481 20.2668 -1.55175 26.5517 3.97256L15.9999 16.0002L3.97259 26.5518C-1.55178 20.2668 -1.31478 10.6865 4.68584 4.68587Z"
        />
        <path
          fill="#FFDA2C"
          d="M26.5518 3.97241L3.97266 26.5516C4.20091 26.8113 4.43791 27.0661 4.68597 27.314C4.93397 27.562 5.18878 27.799 5.44853 28.0274L28.0275 5.44816C27.7993 5.18841 27.5622 4.93366 27.3142 4.6856C27.0663 4.43772 26.8115 4.20066 26.5518 3.97241Z"
        />
        <path
          fill="white"
          d="M6.74125 5.5647 7.08669 6.62763H8.20425L7.30013 7.28444 7.64544 8.34738 6.74125 7.69044 5.83707 8.34738 6.18251 7.28444 5.27832 6.62763H6.39594L6.74125 5.5647ZM12.4502 5.5647 12.7957 6.62763H13.9132L13.0091 7.28444 13.3544 8.34738 12.4502 7.69044 11.5461 8.34738 11.8915 7.28444 10.9873 6.62763H12.1049L12.4502 5.5647ZM6.74125 11.1301 7.08669 12.1931H8.20425L7.30013 12.8499 7.64544 13.9129 6.74125 13.2559 5.83707 13.9129 6.18251 12.8499 5.27832 12.1931H6.39594L6.74125 11.1301ZM12.4502 11.1301 12.7957 12.1931H13.9132L13.0091 12.8499 13.3544 13.9129 12.4502 13.2559 11.5461 13.9129 11.8915 12.8499 10.9873 12.1931H12.1049L12.4502 11.1301ZM9.59581 8.34741 9.94125 9.41035H11.0587L10.1546 10.0672 10.5 11.1302 9.59581 10.4732 8.69162 11.1302 9.037 10.0672 8.13281 9.41035H9.25044L9.59581 8.34741Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSolomonIslands;
