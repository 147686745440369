const SquareFiji = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <rect width="22" height="16" fill="#3ECBF8" rx="2" />
        <path
          fill="white"
          fillRule="evenodd"
          d="M18.8572 10.6668V6.9335C18.8572 6.04283 18.1532 5.3335 17.2858 5.3335H15.1906C14.3252 5.3335 13.6191 6.04816 13.6191 6.9335V10.6668C13.6191 10.861 14.6668 12.8002 16.2382 12.8002C17.8096 12.8002 18.8572 10.8556 18.8572 10.6668Z"
          clipRule="evenodd"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M16.762 8.5335V9.72603H18.8572V10.7522H16.762V12.8002H15.7144V10.7522H13.6191V9.72603H15.7144V8.5335H13.6191V5.3335H18.8572V8.5335H16.762Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA2C"
          fillRule="evenodd"
          d="M14.667 6.3999H17.8098V7.46657H14.667V6.3999Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          d="M9.4288 1.06689H2.09547C1.51689 1.06689 1.04785 1.54446 1.04785 2.13356V7.4669C1.04785 8.056 1.51689 8.53356 2.09547 8.53356H9.4288C10.0074 8.53356 10.4764 8.056 10.4764 7.4669V2.13356C10.4764 1.54446 10.0074 1.06689 9.4288 1.06689Z"
        />
        <path
          fill="#F93939"
          fillRule="evenodd"
          d="M5.23856 5.3333H2.0957V4.26663H5.23856V2.1333H6.28618V4.26663H9.42904V5.3333H6.28618V7.46663H5.23856V5.3333ZM7.85761 6.39997V7.46663H9.42904V6.39997H7.85761ZM7.85761 2.1333V3.19997H9.42904V2.1333H7.85761ZM2.0957 6.39997V7.46663H3.66713V6.39997H2.0957ZM2.0957 2.1333V3.19997H3.66713V2.1333H2.0957Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareFiji;
