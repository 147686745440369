import { SyntheticEvent, useRef } from 'react';
import { DeleteSquareXButton, UploadIcon } from '../../../assets/images/components/NudosComponents';
import { Tany, TfunctionOneToVoid } from '../../../types/global';
import './NudosFileInput.scss';

/**
 * @property { TfunctionOneToVoid } uploadedFileHandler - REQUIRED, The callback function that accepts the file as a param and execute the desired action with it
 * @property { string | JSX.Element } buttonText - REQUIRED, The text to show on the upload button
 * @property { string } label -  Optional, A label for the file upload button
 * @property { boolean } isFilled -  Optional, a boolean indicating if the button is filled, which in turn will affect its styles
 * @property { string } errorText -  Optional, a string to show below the upload button as an error text in red color
 * @property { boolean } isDisabled -  Optional, a boolean indicating if the upload button is disabled and consequently wont serve its upload function
 * @property { boolean } errorStyles -  Optional, a boolean indicating if the component should display the default error styles
 * @property { string } color -  Optional, a color in the palette, oncly accepted value is "orange", default is "white"
 * @property { boolean } resetSelectionWhenClickingWhenFilled -  Optional, a boolean indicating if the behaviour of the button when clicking is executing the uploadedFileHandler with undefined as param (if passed true), or open again the file selection input (default behaviour)
 * @property { boolean } iconLast -  Optional, a boolean indicating if the upload and x button icons should be before the text (if passed undefined or false) or after the button text (if passed true)
 * @property { string } customWidth -  Optional, a string with a valid value for the width css property ("120px"), default is max-content
 * @property { string } customHeight -  Optional, a string with a valid value for the height css property ("40px"), default is 38px
 * @property { boolean } shouldAttractAttention -  Optional, a boolean indicating if the button should display an animation attracting attention
 */
const NudosFileInput = ({
  uploadedFileHandler,
  buttonText,
  label,
  isFilled,
  errorText,
  isDisabled,
  errorStyles,
  color,
  resetSelectionWhenClickingWhenFilled,
  iconLast,
  customWidth,
  customHeight,
  shouldAttractAttention
}: InudosFileInput) => {
  const hiddenFileinput = useRef<HTMLInputElement>(null);

  const handleClick = (event: SyntheticEvent) => {
    event.preventDefault();
    if (isFilled && resetSelectionWhenClickingWhenFilled) {
      if (hiddenFileinput.current?.value) hiddenFileinput.current.value = '';
      return uploadedFileHandler(undefined);
    }
    if (hiddenFileinput.current !== null) hiddenFileinput.current.click();
  };

  const sizeStyle = customWidth || customHeight ? { width: customWidth, height: customHeight } : undefined;

  const handleChange = (event: Tany) => {
    const uploadedFile = event.target.files[0];
    uploadedFileHandler(uploadedFile);
  };

  const attentionAtractionStyles = shouldAttractAttention ? 'attentionAnimation' : '';

  const buttonIcon = (
    <>
      {(isFilled || (errorText && errorText.length > 0)) && (
        <span className="iconContainer">
          <DeleteSquareXButton className="buttonIcon deleteSquareXButton" />
        </span>
      )}
      {!isFilled && !(errorText && errorText.length > 0) && (
        <span className="iconContainer">
          <UploadIcon className="buttonIcon uploadIcon" />
        </span>
      )}
    </>
  );

  return (
    <section
      className={`nudosFileInput ${isDisabled ? 'disabled' : ''} ${
        (errorText && errorText.length > 0) || errorStyles ? 'error' : ''
      } ${isFilled ? `filled ${color === 'orange' ? 'orangeFilled' : ''}` : ''}`}
    >
      {label && <div className="label">{label}</div>}
      <div className={`uploaderButton ${attentionAtractionStyles}`} onClick={handleClick} style={sizeStyle}>
        {!iconLast && buttonIcon}
        {<div className="buttonTextContainer">{buttonText}</div>}
        {iconLast && buttonIcon}
      </div>
      {errorText && <div className="errorText">{errorText}</div>}
      {!isDisabled && <input type="file" className="hidden" ref={hiddenFileinput} onChange={handleChange} />}
    </section>
  );
};

export default NudosFileInput;

export interface InudosFileInput {
  uploadedFileHandler: TfunctionOneToVoid;
  buttonText: string | JSX.Element;
  label?: string;
  isFilled?: boolean;
  errorText?: string;
  isDisabled?: boolean;
  errorStyles?: boolean;
  color?: string;
  resetSelectionWhenClickingWhenFilled?: boolean;
  iconLast?: boolean;
  customWidth?: string;
  customHeight?: string;
  shouldAttractAttention?: boolean;
}
