import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BodyAlert, NewLogisticsVerifyData } from '../../../Components';
import {
  IlocationAddressForLogistics,
  TlogisticsOriginOrDestinationData,
  IOutRange,
  IPrincipalBodyAlert
} from '../../../../../types/requestLogisticsModule';
import { Iemployee } from '../../../../../types/global';
import { ItoolDataForRequiringLogistics, TstoragePlace } from '../../../../../types/assignationFluxes';
import useRequireLogisticsSummaryData from '../../../../../state/useRequireLogisticsSummaryData';
import { useTranslation } from 'react-i18next';
import './ToolsManagementStep6DataVerification.scss';

const ToolsManagementStep6DataVerification: FC<IToolsManagementStep6DataVerification> = ({
  originData,
  destinationData,
  dateRange,
  toolsData,
  selectedOrigin,
  selectedDestination,
  type,
  emailToAssignee,
  estimatedDeliveryTimeByCountry,
  selectedDatesAreForCollectionNotDelivery
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { setEstimatedCollection, setSuperiorAlert, setInferiorAlert } = useRequireLogisticsSummaryData();
  const [timeAlertMessage, setTimeAlertMessage] = useState<string | JSX.Element>('');
  const [alertMessage, setAlertMessage] = useState<IPrincipalBodyAlert>();

  const assignmentSummaryText = t('services:logistics:endStep:assignmentSummary');

  const searchParams = new URLSearchParams(search);
  const requireLogisticsJSON = searchParams?.get('logistics');
  const requireLogistics = requireLogisticsJSON === 'false' ? false : true;
  const noDataText = 'Sin información';

  const logisticsServiceSummaryTitle = requireLogistics
    ? 'Revisa los datos del servicio para proceder a cotizar'
    : assignmentSummaryText;

  const getFormattedName = (place: string, locationData?: TlogisticsOriginOrDestinationData) => {
    if (!locationData) return noDataText;
    const dataAsEmployee = locationData as Iemployee;
    const dataAsOfficeOrOtherAddress = locationData as IlocationAddressForLogistics;
    const dataCountry = dataAsOfficeOrOtherAddress?.countryData || dataAsOfficeOrOtherAddress?.country;
    switch (place) {
      case 'diagnostic':
        return 'Diagnóstico';
      case 'nudos':
        return `Bodegas Nudos ${dataCountry?.name || ''}`;
      case 'warranty':
        return 'Marca';
      case 'office':
        return `Oficinas ${dataCountry?.name || ''}`;
      case 'other':
        return dataAsOfficeOrOtherAddress?.locationName || 'Otra dirección';
      case 'start':
        return dataAsOfficeOrOtherAddress?.city || 'Sin asignar ';
      case 'user':
        return `${dataAsEmployee?.firstName || ''} ${dataAsEmployee?.lastName}`;
      default:
        return 'Sin asignar ';
    }
  };

  const formatDateRange = () => {
    if (dateRange) {
      const startDate = dateRange.startDate;
      const formatStartDay = startDate.getDate();
      const startDay = formatStartDay <= 9 ? `0${formatStartDay}` : formatStartDay;
      const formatStartMonth = startDate.getMonth() + 1;
      const startMonth = formatStartMonth <= 9 ? `0${formatStartMonth}` : formatStartMonth;
      const startYear = startDate.getFullYear().toString();
      const endDate = dateRange.endDate;
      const formatEndDay = endDate.getDate();
      const endDay = formatEndDay <= 9 ? `0${formatEndDay}` : formatEndDay;
      const formatEndMonth = endDate.getMonth() + 1;
      const endMonth = formatEndMonth <= 9 ? `0${formatEndMonth}` : formatEndMonth;
      const endYear = endDate.getFullYear().toString();
      const formatDate = `${startDay}/${startMonth}/${startYear.substring(
        2,
        4
      )} - ${endDay}/${endMonth}/${endYear.substring(2, 4)}`;
      return formatDate;
    }
  };

  const setAlertText = () => {
    if (selectedOrigin === 'diagnostic' || selectedOrigin === 'nudos' || selectedOrigin === 'warranty') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            {
              titleList: 'El empleado que recibe las herramientas debe:',
              subList: [
                'Completar un formulario especificando los elementos recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(alertComponent);
      return setInferiorAlert(alertComponent);
    }
    if (type === 'locale') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Las cajas no serán manipuladas ni abiertas durante el servicio.' },
            {
              titleList: 'Quienes entregan y reciben las herramientas deben:',
              subList: [
                'Completar un formulario especificando los elementos entregados y recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(alertComponent);
      return setInferiorAlert(alertComponent);
    }
    if (type === 'international') {
      const alertComponent: IPrincipalBodyAlert = {
        data: {
          title: 'Asegúrate de cumplir con estos pasos para poder realizar tu servicio:',
          principalList: [
            { titleList: 'Las herramientas deben ser embaladas en cajas selladas.' },
            { titleList: 'Por razones de seguridad, se inspeccionan las cajas antes de enviarlas al país de destino.' },
            {
              titleList: 'Quienes entregan y reciben las herramientas deben:',
              subList: [
                'Completar un formulario especificando los elementos entregados y recibidos.',
                'Adjuntar fotos del paquete como comprobante.'
              ]
            }
          ]
        }
      };
      setAlertMessage(alertComponent);
      return setInferiorAlert(alertComponent);
    }
  };

  const setSuperiorAlertText = () => {
    const warehouseOrGenericText =
      'Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio. ';
    const otherLocationsText = (
      <div className="assignmentSummaryTimeAlert">
        <div>
          Los tiempos dentro del servicio son estimados, podrás ver los tiempos reales una vez se active el servicio.
        </div>
        <div>
          Estaremos en contacto con el punto de recolección días antes para asegurarnos que llegue(n) la herramienta(s)
          en las fechas solicitadas.
        </div>
      </div>
    );
    // This sets the text of the alert for this component
    if (type === 'locale') {
      const textWithTime = `Para entregas en ${
        originData?.country?.name || 'este país'
      }, los tiempos estimados son de ${estimatedDeliveryTimeByCountry} días hábiles, contando a partir del momento en que se recolectan las herramientas.`;
      setTimeAlertMessage(estimatedDeliveryTimeByCountry ? textWithTime : warehouseOrGenericText);
    } else {
      const textWithTime = `Para entregas internacionales 🌎, los tiempos estimados son de ${
        estimatedDeliveryTimeByCountry || '10-15'
      } días hábiles, contando a partir del momento en que se recolectan las herramientas.`;
      setTimeAlertMessage(textWithTime);
    }
    // This sets the text of the alert for the NewLogisticServiceSummary
    switch (selectedOrigin) {
      case 'nudos':
      case 'diagnostic':
      case 'warranty':
        return setSuperiorAlert(warehouseOrGenericText);
      default:
        setSuperiorAlert(otherLocationsText);
    }
  };

  useEffect(() => {
    setSuperiorAlertText();
    setAlertText();
  }, []);

  useEffect(() => {
    setEstimatedCollection(formatDateRange());
  }, []);

  return (
    <div className="ToolsManagementStep6DataVerification">
      <NewLogisticsVerifyData
        type={selectedDatesAreForCollectionNotDelivery ? 'unassignment' : 'assignment'} // In the NewLogisticsVerifyData when type is unassignment the dates shown are the collection ones, thats the reason behind this validation
        tools={toolsData || []}
        collectionName={getFormattedName(selectedOrigin || '', originData)}
        selectedDatesRange={formatDateRange()}
        deliveryName={getFormattedName(selectedDestination || '', destinationData)}
        customContainerTitle={logisticsServiceSummaryTitle}
        bodyAlertComponent={alertMessage && <BodyAlert data={alertMessage.data} />}
        timeAlertText={timeAlertMessage}
        emailToAssignee={emailToAssignee}
        estimatedDelivery={estimatedDeliveryTimeByCountry}
      />
    </div>
  );
};

export default ToolsManagementStep6DataVerification;

interface IToolsManagementStep6DataVerification {
  originData?: TlogisticsOriginOrDestinationData;
  destinationData?: TlogisticsOriginOrDestinationData;
  dateRange?: IOutRange;
  toolsData?: ItoolDataForRequiringLogistics[];
  estimatedDelivery?: string;
  selectedOrigin?: TstoragePlace;
  selectedDestination?: TstoragePlace;
  type?: 'locale' | 'international';
  emailToAssignee?: string;
  estimatedDeliveryTimeByCountry?: string;
  selectedDatesAreForCollectionNotDelivery: boolean;
}
