import { FC } from 'react';
import { ILogisticQuoteGeneralData } from '../../../../../../types/logisticsServicesModule.types';
import { formatCost } from '../../../../../../utils/formatNumbers';
import LogisticServiceFeeLine from '../LogisticServiceFeeLine/LogisticServiceFeeLine';
import { TplatformSubscription } from '../../../../../../types/global';
import { IInvoicePaymentMethods } from '../../../../../../types/cart';

import './LogisticServiceQuoteTotals.scss';

interface ITotalDescription {
  logisticServiceQuoteData: ILogisticQuoteGeneralData;
  platformSubscription?: TplatformSubscription;
  selectedPaymentMethod?: IInvoicePaymentMethods;
}

const LogisticServiceQuoteTotals: FC<ITotalDescription> = ({
  logisticServiceQuoteData,
  selectedPaymentMethod
}: ITotalDescription) => {
  const iSelectPaymentMethod = !!selectedPaymentMethod;

  const {
    subtotalIfCareIsNotApplied,
    subtotalWithDiscounts,
    total,
    currency,
    totalWithoutDiscounts,
    careSavings,
    paymentCommission,
    subtotal
  } = logisticServiceQuoteData || {};

  const totalLS = iSelectPaymentMethod ? total : subtotalWithDiscounts;
  const totalCurrency = currency === 'ARS' ? 'USD' : currency;

  const strikeThroughValue = iSelectPaymentMethod ? totalWithoutDiscounts : subtotal;

  const formatFee = (value: number, currency?: string) => `$${formatCost(value)} ${currency || 'USD'}`;

  return (
    <div className="logisticServiceQuoteTotals">
      <LogisticServiceFeeLine title={`Subtotal`} value={formatFee(subtotalIfCareIsNotApplied)} />

      {!!careSavings && (
        <LogisticServiceFeeLine
          className="careSavingsLine"
          title={`Total cubierto por Care`}
          value={formatFee(careSavings)}
        />
      )}

      {!!paymentCommission && !!iSelectPaymentMethod && (
        <LogisticServiceFeeLine
          title={`Comisión de pago`}
          value={formatFee(paymentCommission)}
          tooltipProps={{
            tooltipContent: (
              <div className="w-280 flex flex-col p-3">
                <span className="font-bold text-bord-sm text-content">Comisión de pago</span>
                <span className="text-bord-sm text-content-secondary font-normal mt-4">
                  Se aplica una comisión adicional para cubrir los costos de procesamiento de pagos con tarjeta.
                </span>
              </div>
            ),
            tooltipPosition: 'bottomRight'
          }}
        />
      )}

      <LogisticServiceFeeLine
        className="totalLine"
        showStrikeThroughValue={totalWithoutDiscounts - total > 0}
        title={`Total a pagar`}
        value={formatFee(totalLS, totalCurrency)}
        strikeThroughValue={formatFee(strikeThroughValue, totalCurrency)}
      />
    </div>
  );
};

export default LogisticServiceQuoteTotals;
