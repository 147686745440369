import { ComponentProps } from 'react';
import { bordColorTheme } from '../../../../styles/constants';

const BordTwitterIcon = (props: ComponentProps<'svg'>) => {
  const { stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        stroke={stroke || bordColorTheme.navyBlue[400]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23 3.00005c-.9576.67547-2.0179 1.19211-3.14 1.53-.6023-.69249-1.4027-1.18331-2.293-1.40608-.8903-.22276-1.8275-.16673-2.6849.16053-.8574.32726-1.5937.90995-2.1091 1.66926-.5155.75932-.7853 1.65862-.773 2.57629v1c-1.7574.04556-3.49873-.34419-5.06899-1.13456C5.36074 6.60513 4.01032 5.43868 3 4.00005 3 4.00005-1 13 8 17c-2.05947 1.398-4.51284 2.099-7 2 9 5 20 0 20-11.49995-.0009-.27855-.0277-.55641-.08-.83 1.0206-1.00651 1.7408-2.27729 2.08-3.67Z"
      />
    </svg>
  );
};

export default BordTwitterIcon;
