const RoundHaiti = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#A2001D"
          d="M32 16C32 24.8365 24.8365 32 16 32C7.1635 32 0 24.8365 0 16C0 7.1635 16 0 16 0C16 0 32 7.1635 32 16Z"
        />
        <path fill="#1A47B8" d="M0 16C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16" />
        <path fill="white" d="M21.5649 20.174L15.9998 19.4784L10.4346 20.174V11.8262H21.5649V20.174Z" />
        <path
          fill="#1A47B8"
          d="M16.0004 19.4783C17.5372 19.4783 18.783 18.2325 18.783 16.6957C18.783 15.1589 17.5372 13.9131 16.0004 13.9131C14.4636 13.9131 13.2178 15.1589 13.2178 16.6957C13.2178 18.2325 14.4636 19.4783 16.0004 19.4783Z"
        />
        <path
          fill="#A2001D"
          d="M15.9997 18.0868C16.7681 18.0868 17.391 17.4639 17.391 16.6955C17.391 15.9271 16.7681 15.3042 15.9997 15.3042C15.2313 15.3042 14.6084 15.9271 14.6084 16.6955C14.6084 17.4639 15.2313 18.0868 15.9997 18.0868Z"
        />
        <path fill="#6DA544" d="M13.9131 13.2173H18.087L16 15.3043L13.9131 13.2173Z" />
        <path fill="#FFDA2C" d="M15.3047 14.6086H16.696V18.7826H15.3047V14.6086Z" />
        <path fill="#6DA544" d="M18.2258 18.3652H13.7736L10.4346 20.174H21.5649L18.2258 18.3652Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundHaiti;
