import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} fill="none" ref={ref} {...props}>
    <g clipPath="url(#clip0_7841_25880)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.55 5.33733C11.55 9.33493 7.54995 11.7333 6.74995 11.7333C5.94995 11.7333 1.94995 9.33493 1.94995 5.33733C1.95059 4.06478 2.45661 2.84458 3.35674 1.94505C4.25686 1.04552 5.4774 0.540315 6.74995 0.540527C9.40035 0.540527 11.55 2.68853 11.55 5.33733Z"
        fill="white"
        stroke={props?.stroke || '#CCCCCC'}
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75002 6.9365C7.17423 6.9365 7.58108 6.76804 7.88111 6.46816C8.18115 6.16827 8.34981 5.76151 8.35002 5.3373C8.35002 4.91296 8.18145 4.50599 7.8814 4.20593C7.58134 3.90588 7.17437 3.7373 6.75002 3.7373C6.32568 3.7373 5.91871 3.90588 5.61865 4.20593C5.3186 4.50599 5.15002 4.91296 5.15002 5.3373C5.15024 5.76151 5.3189 6.16827 5.61894 6.46816C5.91897 6.76804 6.32582 6.9365 6.75002 6.9365Z"
        stroke={props?.stroke || '#CCCCCC'}
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_7841_25880">
        <rect width="12" height="12" fill="white" transform="translate(0.75 0.140625)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconLocation = memo(ForwardRef);
export default IconLocation;
