const SquareAruba = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#3ECBF8" rx="2" />
          <path
            fill="#3ECBF8"
            d="M19.9048 0H2.09524C.93807 0 0 .955126 0 2.13333V13.8667C0 15.0449.93807 16 2.09524 16H19.9048C21.0619 16 22 15.0449 22 13.8667V2.13333C22 .955126 21.0619 0 19.9048 0Z"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M4.69857 4.78397C5.61 4.7253 6.28571 4.5173 6.28571 4.26663c0-.2496-.67571-.45866-1.58714-.51733-.05762-.928-.2619-1.616-.50809-1.616-.24515 0-.45048.688-.5081 1.616-.91143.05867-1.58714.26667-1.58714.51733 0 .2496.67571.45867 1.58714.51734.05762.928.26191 1.616.5081 1.616.24514 0 .45047-.688.50809-1.616Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M4.69857 4.7841c.91143-.05867 1.58714-.26667 1.58714-.51734 0-.2496-.67571-.45866-1.58714-.51733-.05762-.928-.2619-1.616-.50809-1.616-.24515 0-.45048.688-.5081 1.616-.91143.05867-1.58714.26667-1.58714.51733 0 .2496.67571.45867 1.58714.51734.05762.928.26191 1.616.5081 1.616.24514 0 .45047-.688.50809-1.616Zm.03143.53333.49238-.50133c-.07857 1.27786-.34048 2.11733-1.0319 2.11733-.69143 0-.95334-.84053-1.03086-2.11627l.49028.4992c-1.25295-.07893-2.07847-.3456-2.07847-1.0496 0-.704.82552-.97066 2.07847-1.0496l-.49028.4992c.07752-1.27573.33943-2.11626 1.03086-2.11626.69142 0 .95333.84053 1.03085 2.11626l-.49028-.4992c1.25295.07894 2.07847.3456 2.07847 1.0496 0 .704-.82552.97067-2.07847 1.0496L4.73 5.31743Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M0 10h22v1H0v-1Zm0 2h22v1H0v-1Z"
            clipRule="evenodd"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h22v16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="#fff" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareAruba;
