const SquareVenezuela = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#1A47B8" rx="2" />
          <path
            fill="#BCC9F0"
            fillRule="evenodd"
            d="M6 8.66667H7.11111V10H6V8.66667ZM8.22222 7.33333H9.33333V8.66667H8.22222V7.33333ZM12.6667 7.33333H13.7778V8.66667H12.6667V7.33333ZM14.8889 8.66667H16V10H14.8889V8.66667ZM10.4444 6H11.5556V7.33333H10.4444V6Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path fill="#FFDA2C" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareVenezuela;
