const RoundTurkey = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="white"
          d="M15.3453 13.0737L16.6581 14.8828L18.7841 14.1934L17.4693 16.0009L18.7819 17.8099L16.6566 17.1179L15.3418 18.9254L15.3431 16.6904L13.2178 15.9984L15.3439 15.309L15.3453 13.0737Z"
        />
        <path
          fill="white"
          d="M11.7623 20.5216C9.26507 20.5216 7.24057 18.4971 7.24057 15.9998C7.24057 13.5026 9.26507 11.4781 11.7623 11.4781C12.5409 11.4781 13.2736 11.675 13.9132 12.0216C12.9098 11.0402 11.5374 10.4346 10.0232 10.4346C6.94957 10.4346 4.45801 12.9262 4.45801 15.9998C4.45801 19.0733 6.94963 21.5649 10.0232 21.5649C11.5376 21.5649 12.9098 20.9593 13.9132 19.9779C13.2736 20.3246 12.5409 20.5216 11.7623 20.5216Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTurkey;
