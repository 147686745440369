const RoundSuriname = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#A2001D"
          d="M31.0047 10.4348H0.995125C0.352063 12.1682 0 14.0429 0 16C0 17.9571 0.352063 19.8319 0.995125 21.5652H31.0048C31.648 19.8319 32 17.9571 32 16C32 14.0429 31.648 12.1682 31.0047 10.4348Z"
        />
        <path
          fill="#6DA544"
          d="M16.0464 32.0002C21.5265 32.0002 26.3625 29.2445 29.2461 25.0437H2.84668C5.7303 29.2445 10.5662 32.0002 16.0464 32.0002ZM16.0464.00024414C21.5265.00024414 26.3625 2.75599 29.2461 6.95674H2.84668C5.7303 2.75599 10.5662.00024414 16.0464.00024414Z"
        />
        <path
          fill="#FFDA2C"
          d="M16.0001 10.4348L17.3814 14.6861H21.8517L18.2352 17.3138L19.6166 21.5652L16.0001 18.9378L12.3835 21.5652L13.765 17.3138L10.1484 14.6861H14.6188L16.0001 10.4348Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSuriname;
