import { FC } from 'react';
import { BordDateRange } from '../../../../../components/DesignSystem';
import { BordOneToneIcon, BordTextArea } from '../../../../../components/BordDesignSystem';
import { IOutRange } from '../../../../../types/requestLogisticsModule';

import './ToolsManagementStep4SelectDate.scss';

interface IToolsManagementStep4SelectDate {
  type: 'local' | 'international';
  startDay: number;
  selectedDatesAreForCollectionNotDelivery: boolean;
  handleCheckRange?: (dateRange: IOutRange) => void;
  isValidRange?: (state: boolean) => void;
  defaultDateRange?: IOutRange;
  updateLogisticsCommentsCallback?: (text: string) => void;
  logisticsComments?: string;
  estimatedDeliveryTimeByCountry?: string;
}

const ToolsManagementStep4SelectDate: FC<IToolsManagementStep4SelectDate> = ({
  type,
  startDay,
  selectedDatesAreForCollectionNotDelivery,
  handleCheckRange,
  isValidRange,
  defaultDateRange,
  updateLogisticsCommentsCallback,
  logisticsComments,
  estimatedDeliveryTimeByCountry
}: IToolsManagementStep4SelectDate) => {
  const isMyRDateRange = (dateRange: IOutRange) => {
    if (handleCheckRange) {
      handleCheckRange(dateRange);
    }
  };

  const internationalLogisticsText = `Para entregas internacionales 🌎, los tiempos estimados son de ${
    estimatedDeliveryTimeByCountry || '10-15'
  } días hábiles, contando a partir del momento en que se recolectan las herramientas.`;
  const collectionText =
    'La recolección se podrá realizar cualquier día de la semana elegida. En el siguiente paso podrás visualizar las fechas estimadas de entrega.';
  const deliveryText =
    'La entrega se podrá realizar cualquier dia del rango seleccionado. En caso de querer un dia en especifico escribelo en los comentarios';
  const localLogisticsText = selectedDatesAreForCollectionNotDelivery ? collectionText : deliveryText;
  const timeAlertText = type === 'international' ? internationalLogisticsText : localLogisticsText;

  const weekToSelectText = selectedDatesAreForCollectionNotDelivery
    ? 'Elige la semana en la que quieres que recojamos las herramientas'
    : 'Elige la semana en la que quieres que se realice la entrega';

  const logisticsCommentsLabel = selectedDatesAreForCollectionNotDelivery
    ? 'Detalles de la recolección (opcional)'
    : 'Detalles la entrega (opcional)';

  const typeOfServiceText = selectedDatesAreForCollectionNotDelivery ? 'recolección' : 'entrega';

  return (
    <div className="ToolsManagementStep4SelectDate">
      <div className="boxSelectDate">
        <div className="titleDate">{weekToSelectText}</div>
        <div className="basicLine">
          <div className="boxBasicLine" />
        </div>
        <div className="containerRange">
          <div className="boxRangeItem">
            <BordDateRange
              sendValueOut={isMyRDateRange}
              startDay={startDay}
              defaultDateRange={defaultDateRange}
              validRange={isValidRange}
            />
          </div>
        </div>

        <div className="containerAlert">
          <div className="containerBlueAlert">
            <div className="containerIcon">
              <BordOneToneIcon variant="info" standardSize={20} />
            </div>
            <div className="blueAlertText">{timeAlertText}</div>
          </div>
        </div>
        <div className="containerTextArea">
          <div className="boxTextArea">
            <BordTextArea
              label={logisticsCommentsLabel}
              value={logisticsComments || ''}
              maxLength={1000}
              placeholder={`Escribe tus preferencias para la ${typeOfServiceText} o cualquier información importante, como disponibilidad de días o instrucciones específicas.`}
              captionText="Haremos todo lo posible para cumplir con tus solicitudes y necesidades en la medida de nuestras capacidades."
              setInputValue={e => updateLogisticsCommentsCallback && updateLogisticsCommentsCallback(e)}
              customWidth="w-480"
              bordTextAreaContentClass="bordTextAreaContentClass"
              bordTextAreaLabelCustomClass="bordTextAreaLabelCustomClass"
              bordTextAreaCaptionCustomClass="bordTextAreaCaptionCustomClass"
              showZeroValue
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolsManagementStep4SelectDate;
