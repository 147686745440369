import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" ref={ref} {...props}>
    <path
      className="shield"
      fill="#fff"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.91 8.213c0-4.225 1.772-6.035 2.363-6.639h9.453c.59.604 2.363 2.414 2.363 6.639 0 4.828-4.655 6.609-7.018 7.212C5.708 14.822.91 13.041.91 8.213Z"
    />
    <path
      className="checkMark"
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m5.145 8.643 2.077 2.143C8.2 7.976 9.015 6.744 10.86 5.07"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconShield = memo(ForwardRef);
export default IconShield;
