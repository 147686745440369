import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModuleFilters, {
  TgetFiltersList
} from '../../../../../../components/NudosComponents/ModuleFilters/ModuleFilters';
import useEmployeeListingState from '../../../../../../state/useEmployeeListingState';
import { getFiltersEmployees } from '../../../../../../services/employees.service';
import './EmployeesFilters.scss';

const EmployeeFilters = () => {
  const { t } = useTranslation();
  const { employeesFilters, setEmployeesFilters } = useEmployeeListingState();

  const [openEmployeeStatus, setOpenEmployeeStatus] = useState<boolean>(false);
  const [openCountry, setOpenCountry] = useState<boolean>(false);
  const [openCity, setOpenCity] = useState<boolean>(false);
  const [openArea, setOpenArea] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:employee:containerFilters:';

  const employeesFilterMapping = {
    employeeStatus: 'employeeStatus',
    country: 'country',
    city: 'city',
    area: 'area'
  };

  const getEmployeeFiltersList: TgetFiltersList = (
    formModuleFilters,
    handleClickFilterOption,
    getFilterActiveStatus
  ) => [
    {
      title: t(`${i18nLanguageKey}employeeEquipmentFilter`),
      openFunction: setOpenEmployeeStatus,
      openStatus: openEmployeeStatus,
      rawSelectOptions: formModuleFilters('employeeStatus'),
      handleChangeSelectedOption: handleClickFilterOption('employeeStatus', 'name'),
      isFilterActive: getFilterActiveStatus('employeeStatus')
    },
    {
      title: t(`${i18nLanguageKey}employeeCountryFilter`),
      openFunction: setOpenCountry,
      openStatus: openCountry,
      rawSelectOptions: formModuleFilters('country'),
      handleChangeSelectedOption: handleClickFilterOption('country', 'name'),
      isFilterActive: getFilterActiveStatus('country')
    },
    {
      title: t(`${i18nLanguageKey}employeeCityFilter`),
      openFunction: setOpenCity,
      openStatus: openCity,
      rawSelectOptions: formModuleFilters('city'),
      handleChangeSelectedOption: handleClickFilterOption('city', 'name'),
      isFilterActive: getFilterActiveStatus('city')
    },
    {
      title: t(`${i18nLanguageKey}employeeAreaFilter`),
      openFunction: setOpenArea,
      openStatus: openArea,
      rawSelectOptions: formModuleFilters('area'),
      handleChangeSelectedOption: handleClickFilterOption('area', 'name'),
      isFilterActive: getFilterActiveStatus('area')
    }
  ];

  return (
    <div className="employeesFilters">
      <ModuleFilters
        moduleFilters={employeesFilters}
        setModuleFilters={setEmployeesFilters}
        getModuleFiltersRequestFunction={getFiltersEmployees}
        getFiltersList={getEmployeeFiltersList}
        moduleFiltersTitlesMapping={employeesFilterMapping}
      />
    </div>
  );
};

export default EmployeeFilters;
