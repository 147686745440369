import { wippingImage } from '../../../../assets/images/views/RequestLogistics';
import { LS_ADDITIONAL_SERVICE_STATUS, LS_STATUS } from '../../../../types/logisticsServicesModule.types';
import { IadditionalServiceDataForToolLogistics } from '../../../../types/requestLogisticsModule';
import { formatShortDate } from '../../../../utils/formatDatesAndHours';
import { capitalize } from '../../../../utils/unCamelCase';
import { BordOneToneIcon, BordPhoto, BordStatus } from '../../../BordDesignSystem';
import { STATUS_VARIANTS } from '../../../BordDesignSystem/BordStatus/BordStatus.type';
import NudosLinkTypeButton from '../../NudosLinkTypeButton/NudosLinkTypeButton';
import './NudosWipingStatusHoverData.scss';

const NudosWipingStatusHoverData = ({ wipingData }: { wipingData?: IadditionalServiceDataForToolLogistics }) => {
  const { status } = wipingData || {};
  const { createdAt, statusName, imageUrl } = status || {};

  const haveStatus = !!status?.statusId;
  const lsWasCancelled = wipingData?.logisticServiceStatus === LS_STATUS.CANCELLED;
  const noDataText = 'Sin datos';

  const downloadEvidence = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (event) event.stopPropagation();
    imageUrl && window.open(imageUrl, 'blank');
  };

  const getBordStatusVariant = () => {
    if ([LS_ADDITIONAL_SERVICE_STATUS?.COMPLETED].includes(statusName as LS_ADDITIONAL_SERVICE_STATUS))
      return STATUS_VARIANTS.success;
    if ([LS_ADDITIONAL_SERVICE_STATUS?.NOT_COMPLETED].includes(statusName as LS_ADDITIONAL_SERVICE_STATUS))
      return STATUS_VARIANTS.danger;
    return STATUS_VARIANTS.default;
  };

  return (
    <div className="nudosWipingStatusHoverData">
      <div className="descriptionSection">
        <BordPhoto url={wippingImage} className="wippingImageStyle" size="s48" />
        <div className="descriptionContainer">
          <div className="serviceName">Wiping</div>
          <div className="serviceDescription">
            Limpieza del hardware y restauración de software (reinstalación sistema operativo)
          </div>
        </div>
      </div>

      {haveStatus && !lsWasCancelled ? (
        <div className="statusSection">
          <div className="statusField">
            <div className="statusTitle">Fecha</div>
            <div className="statusContent">{formatShortDate(createdAt || '')?.onlyDate || noDataText}</div>
          </div>

          <div className="statusField">
            <div className="statusTitle">Estado</div>
            <BordStatus renderJSX={<>{capitalize(statusName || '')}</>} variant={getBordStatusVariant()} />
          </div>

          <div className="statusField">
            <div className="statusTitle">Evidencia</div>
            <NudosLinkTypeButton
              className="statusContent"
              text="Consultar"
              isDisabled={!imageUrl}
              textProps={{ style: { fontSize: 12 } }}
              onClick={(e?: React.MouseEvent<HTMLDivElement>) => downloadEvidence(e)}
            />
          </div>
        </div>
      ) : (
        <div className="statusSection">
          <div className="textRequestService">
            <BordOneToneIcon variant="info" standardSize={14} />
            <span>Puedes solicitar este servicio en el siguiente paso.</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default NudosWipingStatusHoverData;
