const RoundTonga = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#F93939"
          d="M10.4347 8.34792V6.26099H8.34768V8.34792H6.26074V10.4349H8.34768V12.5219H10.4347V10.4349H12.5216V8.34792H10.4347Z"
        />
        <path
          fill="#F93939"
          d="M16 0V16C7.1635 16.0001 3.65156 16 0 16.0001C0 24.8365 7.1635 32 16 32C24.8365 32 32 24.8365 32 16C32 7.1635 24.8365 0 16 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundTonga;
