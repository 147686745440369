import { useState } from 'react';
import { NudosPopover } from '../../../../../../../components/NudosComponents';
import { BordCheckBox, BordOneToneIcon } from '../../../../../../../components/BordDesignSystem';
import { IassigneeInfo } from '../../../../../../../types/global';
import { DTOpostManualAdjustmentAndComments } from '../../../../../../../types/tools.types';
import { displayErrorNotification } from '../../../../../../../utils/displayNudosStandardNotifications';
import { postManualAdjustmentAndComments } from '../../../../../../../services/tools.service';
import { useTranslation } from 'react-i18next';
import ManualAdjustmentModal from '../ManualAdjustmentModal/ManualAdjustmentModal';
import './ManualAdjustmentSection.scss';
import BordTooltip from '../../../../../../../components/BordDesignSystem/BordTooltip/BordTooltip';

const ManualAdjustmentSection = ({
  assignee,
  updateDataCallback
}: {
  assignee: IassigneeInfo;
  updateDataCallback?: () => void;
}) => {
  const { t } = useTranslation();
  const { locationLogId, manualAdjustment, comments } = assignee;
  const [showConfirmManualAdjustmentPopover, setShowConfirmManualAdjustmentPopover] = useState(false);
  const [showManualAdjustmentModal, setShowManualAdjustmentModal] = useState(false);
  const [loadingInManualAdjustmentModal, setLoadingInManualAdjustmentModal] = useState(false);

  const manualAdjustmentCommentsText = comments
    ? t('nodi:toolInternal:manualAdjustmentCommentsText:comments')
    : t('nodi:toolInternal:manualAdjustmentCommentsText:default');
  const commentsActiveStyle = manualAdjustment ? 'active' : '';

  const openManualAdjustmentModal = () => setShowManualAdjustmentModal(true);
  const closeManualAdjustmentModal = () => setShowManualAdjustmentModal(false);
  const handleCloseManualAdjustmentPopover = () => setShowConfirmManualAdjustmentPopover(false);
  const handleOpenManualAdjustmentPopover = () => setShowConfirmManualAdjustmentPopover(true);

  const handleChangeManualAdjustmentOrComments = async (
    locationLogId: string | number,
    body: DTOpostManualAdjustmentAndComments
  ) => {
    if (!locationLogId) return;
    setLoadingInManualAdjustmentModal(true);
    try {
      await postManualAdjustmentAndComments(locationLogId, body);
      updateDataCallback && updateDataCallback();
      closeManualAdjustmentModal();
    } catch (error) {
      displayErrorNotification();
    } finally {
      handleCloseManualAdjustmentPopover();
      setLoadingInManualAdjustmentModal(false);
    }
  };

  const handleUncheckManualAdjustment = () => {
    if (!locationLogId) return;
    handleChangeManualAdjustmentOrComments(locationLogId, {
      comments: comments || undefined,
      manualAdjustment: false
    });
  };

  const handleClickManualAdjustment = () => {
    if (!manualAdjustment) return openManualAdjustmentModal();
    return handleOpenManualAdjustmentPopover();
  };

  const handleClickEditComments = () => {
    if (manualAdjustment) return openManualAdjustmentModal();
  };
  return (
    <div className="manualAdjustmentSection">
      <div className="manualAdjustmentButtonContainer">
        <BordCheckBox label={''} isButtonActive={!!manualAdjustment} handleClickButton={handleClickManualAdjustment} />
        {t('nodi:toolInternal:manualAdjustmentSection')}
        <BordTooltip
          position="top"
          message="Movimiento realizado para organizar el inventario y asignaciones. No es un movimiento real"
        >
          <BordOneToneIcon variant="info" customWidth="1rem" />
        </BordTooltip>
        {showConfirmManualAdjustmentPopover && (
          <NudosPopover
            topPopover={'-75'}
            rigthPopover={'75'}
            positionArrow={'bottomRigth'}
            firstButtonText="Sí, eliminar"
            secondButtonText="No, mantener"
            callBackFirstButton={handleUncheckManualAdjustment}
            callBackSecondButton={handleCloseManualAdjustmentPopover}
            changeOpenStatusFunction={handleCloseManualAdjustmentPopover}
            widthPopover={280}
            defaultWidthFirstButton={93}
            defaultWidthSecondButton={93}
          >
            <div className="popoverText">¿Estás seguro que quieres eliminar el ajuste manual?</div>
          </NudosPopover>
        )}
      </div>
      <div className={`comments ${commentsActiveStyle}`} onClick={handleClickEditComments}>
        {manualAdjustmentCommentsText}
      </div>
      {showManualAdjustmentModal && (
        <ManualAdjustmentModal
          closeModalCallback={closeManualAdjustmentModal}
          locationLogId={locationLogId || ''}
          loadingInManualAdjustmentModal={loadingInManualAdjustmentModal}
          modalSubmitCallback={handleChangeManualAdjustmentOrComments}
          preloadedData={comments || ''}
        />
      )}
    </div>
  );
};

export default ManualAdjustmentSection;
