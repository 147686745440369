const RoundIndia = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="white"
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        />
        <path
          fill="#FF9811"
          d="M16.0003 0C9.65689 0 4.17589 3.69156 1.58789 9.0435H30.4126C27.8246 3.69156 22.3436 0 16.0003 0Z"
        />
        <path
          fill="#6DA544"
          d="M16.0003 32C22.3436 32 27.8246 28.3085 30.4126 22.9565H1.58789C4.17589 28.3085 9.65689 32 16.0003 32Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9998 21.5652C19.0733 21.5652 21.5649 19.0736 21.5649 16C21.5649 12.9264 19.0733 10.4348 15.9998 10.4348C12.9262 10.4348 10.4346 12.9264 10.4346 16C10.4346 19.0736 12.9262 21.5652 15.9998 21.5652Z"
        />
        <path
          fill="white"
          d="M15.9997 19.4782C17.9207 19.4782 19.478 17.921 19.478 16C19.478 14.079 17.9207 12.5217 15.9997 12.5217C14.0787 12.5217 12.5215 14.079 12.5215 16C12.5215 17.921 14.0787 19.4782 15.9997 19.4782Z"
        />
        <path
          fill="#1A47B8"
          d="M16.0003 11.7078L17.0733 14.1414L19.7173 13.8538L18.1463 15.9999L19.7173 18.146L17.0733 17.8584L16.0003 20.292L14.9272 17.8584L12.2832 18.1459L13.8542 15.9999L12.2832 13.8538L14.9272 14.1414L16.0003 11.7078Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundIndia;
