const RoundSintMaarten = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M14.609 17.3913C14.609 17.3913 4.6959 4.68406 4.68652 4.68631C7.5819 1.79088 11.582 0 16.0003 0C24.8368 0 32.0003 7.1635 32.0003 16L14.609 17.3913Z"
        />
        <path
          fill="#1A47B8"
          d="M14.609 16C14.609 16 4.6959 27.3159 4.68652 27.3137C7.5819 30.2091 11.582 32 16.0003 32C24.8368 32 32.0003 24.8365 32.0003 16H14.609Z"
        />
        <path
          fill="white"
          d="M4.68628 4.68628C-1.56209 10.9347 -1.56209 21.0653 4.68628 27.3138C7.26834 24.7317 9.75166 22.2484 16 16L4.68628 4.68628Z"
        />
        <path
          fill="#FFDA2C"
          d="M1.75395 16C1.7452 16.1149 1.73926 16.2306 1.73926 16.3477 1.73926 18.845 3.76376 20.8695 6.26101 20.8695 8.75826 20.8695 10.7828 18.845 10.7828 16.3477 10.7828 16.2306 10.7768 16.1148 10.7681 16H1.75395ZM6.26143 13.913C7.02983 13.913 7.65274 13.2901 7.65274 12.5217 7.65274 11.7533 7.02983 11.1304 6.26143 11.1304 5.49303 11.1304 4.87012 11.7533 4.87012 12.5217 4.87012 13.2901 5.49303 13.913 6.26143 13.913Z"
        />
        <path
          fill="#F93939"
          d="M3.13086 12.1738V16.6956C3.13086 19.0918 6.2613 19.826 6.2613 19.826C6.2613 19.826 9.39173 19.0918 9.39173 16.6956V12.1738H3.13086Z"
        />
        <path
          fill="#3ECBF8"
          d="M6.26217 18.37C5.68142 18.161 4.52148 17.6269 4.52148 16.6956V13.5652H7.9998V16.6956C7.9998 17.6299 6.83998 18.1627 6.26217 18.37Z"
        />
        <path
          fill="#F3F3F3"
          d="M6.9569 15.3045V14.6088L6.26127 14.261L5.56559 14.6088V15.3045L5.21777 15.6523V17.0436H7.30477V15.6523L6.9569 15.3045Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundSintMaarten;
