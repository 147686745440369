import { AxiosError, AxiosRequestConfig } from 'axios';
import { usersAPI } from '../constants';
import { apiGet, apiPut } from './api';

interface IBlueTutoriel {
  showTutorial: boolean;
}

const blueTutoriel = async (userId: string | number): Promise<IBlueTutoriel> => {
  return apiGet<AxiosRequestConfig, IBlueTutoriel>(`${usersAPI}/user/tutorial/${userId}`)
    .then(response => Promise.resolve(response))
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

const updateStatusTutoriel = async (userId: string | number): Promise<string> => {
  return apiPut<string, string>(`${usersAPI}/user/tutorial/button/${userId} `)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error: AxiosError) => {
      Promise.reject(error);
      throw error;
    });
};

export { blueTutoriel, updateStatusTutoriel };
