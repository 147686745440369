import { v4 as uuid } from 'uuid';
import BordBadge from '../BordDesignSystem/BordBadge/BordBadge';
import BordTooltip from '../BordDesignSystem/BordTooltip/BordTooltip';
import './ProductPills.scss';

const ProductPills = ({
  pills,
  isLoading = false,
  customHeight,
  positionTooltip = 'bottom'
}: {
  pills: { [key: string]: string | null | undefined };
  isLoading?: boolean;
  customHeight?: number;
  positionTooltip?: 'top' | 'bottom' | 'left' | 'right';
}) => {
  const profileAndLinePills = ['Gama', 'Cargo', 'Perfil', 'Perfil 1', 'Perfil 2', 'Perfil 3', 'Perfil 4', 'Perfil 5'];
  const pillsArr = pills ? Object.entries(pills) : [];
  const specsPills = pillsArr
    .filter(([key, value]) => !!value && !!key && !profileAndLinePills.includes(key))
    .slice(0, 3);

  if (isLoading) return <ProductPillsSkeletonLoader />;
  return (
    <div className="productPills" style={{ height: customHeight ? `${customHeight}px` : '' }}>
      {specsPills?.map(([key, value]) => {
        return (
          <BordTooltip
            key={key}
            position={positionTooltip}
            message={`${key ? key : 'Propiedad'}: ${value ? value : 'Valor'}`}
          >
            <BordBadge key={uuid()} label={value && value.length > 14 ? `${value?.slice(0, 14)}...` : value ?? ''} />
          </BordTooltip>
        );
      })}
    </div>
  );
};

export default ProductPills;

const ProductPillsSkeletonLoader = () => {
  return (
    <div className="productPillsSkeletonLoader">
      {[0, 1, 2].map(pill => {
        return <div key={`pill${pill}`} className="pill animationLoader" />;
      })}
    </div>
  );
};
