const BordStoreIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 49">
      <path
        fill="url(#a)"
        fillRule="evenodd"
        d="M19.936 38.85c1.294.353 2.657.542 4.064.542 1.21 0 2.387-.14 3.516-.404.563-1.057.82-1.893.912-2.56.125-.904-.039-1.593-.3-2.234-.132-.322-.313-.678-.485-1.018a10.24 10.24 0 0 1-.527-1.173c-.335-.938-.422-2.003.205-3.205.456-.875 1.118-1.383 1.911-1.634.609-.192 1.454-.227 1.99-.25 1.18-.054 2.465-.151 4.041-1.284 1.408-1.012 2.824-1.296 4.122-1.162a15.324 15.324 0 0 0-3.139-9.794 5.296 5.296 0 0 0-2.391 1.733c-1.422 1.841-2.947 2.974-4.495 3.429-1.577.464-3.065.188-4.254-.582-1.76-1.14-1.988-2.732-2.137-3.775-.081-.544-.18-1.055-.326-1.326-.122-.225-.344-.496-.896-.764-1.387-.673-2.173-1.904-2.445-3.23a5.587 5.587 0 0 1-.104-.788c-5.61 1.84-9.798 6.82-10.49 12.86.946.552 2.028.925 3.197.925 1.166 0 2.17.055 3.014.213.846.158 1.623.437 2.254.954 1.313 1.075 1.504 2.759 1.504 4.492 0 1.783.004 2.534.1 3.174.091.614.27 1.14.737 2.479.27.772.644 1.87.623 3.053-.008.442-.07.887-.201 1.328ZM5.19 23.72a19.08 19.08 0 0 0-.003.279c0 10.39 8.423 18.812 18.813 18.812a18.881 18.881 0 0 0 4.062-.44c7.602-1.672 13.478-7.939 14.57-15.744A18.972 18.972 0 0 0 42.813 24C42.813 13.61 34.39 5.188 24 5.188h-.03c-.069 0-.138 0-.206.002-10.188.125-18.427 8.35-18.574 18.532Z"
        clipRule="evenodd"
      />
      <g filter="url(#b)">
        <path
          fill="url(#c)"
          fillRule="evenodd"
          d="M29.272 27.195a5.27 5.27 0 0 1 10.455 0H29.272Zm-2.647 0a7.903 7.903 0 0 1 15.75 0h1.956c.649 0 1.24 0 1.712.07.52.075 1.074.257 1.486.76.408.499.484 1.076.47 1.601-.015.485-.114 1.078-.225 1.739l-1.606 9.582c-.254 1.514-.46 2.738-.742 3.695-.293.995-.701 1.82-1.436 2.456-.738.639-1.605.916-2.62 1.046-.97.124-2.182.124-3.671.124h-6.397c-1.49 0-2.703 0-3.673-.124-1.014-.13-1.882-.407-2.62-1.046-.734-.637-1.142-1.461-1.435-2.456-.283-.957-.488-2.181-.742-3.695l-1.595-9.52-.011-.062c-.11-.66-.21-1.254-.224-1.739-.015-.525.06-1.102.469-1.6.412-.504.966-.686 1.486-.762.473-.07 1.063-.069 1.712-.069h1.956Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <linearGradient
          id="a"
          x1="24"
          x2="24.03"
          y1="5.187"
          y2="49.262"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="34.5"
          x2="34.523"
          y1="19.951"
          y2="53.123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#22CFAB" />
          <stop offset="1" stopColor="#1B202F" />
        </linearGradient>
        <filter
          id="b"
          width="36.744"
          height="38.062"
          x="16.128"
          y="15.079"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.436" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1398_14007"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1398_14007"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3.248" />
          <feGaussianBlur stdDeviation="4.223" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.92549 0 0 0 0 0.854902 0 0 0 1 0" />
          <feBlend in2="shape" result="effect2_innerShadow_1398_14007" />
        </filter>
      </defs>
    </svg>
  );
};

export default BordStoreIcon;
