import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BordModal, ModalContent } from '../../../components/BordDesignSystem';
import { TdestinationAddress } from '../../../types/assignationFluxes';
import { formatOrgData } from '../../../utils/orgFormatData';
import { IListDefaultLocationAddress, ILocationAddress } from '../../../types/account';
import { servicesDefaultLocationAddress, servicesLocationAddress } from '../../../services/account';
import { displayErrorNotification } from '../../../utils/displayNudosStandardNotifications';
import { ICountryDetail } from '../../../types/countries';
import LocationModalContent from './LocationToolsFluxContent';
import './LocationToolsFlux.scss';

const LocationToolsFlux = ({
  listOfAllCountries,
  defaultCountryCode,
  productId,
  closeLocationModal,
  updateCardListData,
  officeDirect,
  otherAddressDirect,
  preSelectOption,
  officePreSelection = true
}: {
  listOfAllCountries: ICountryDetail[];
  defaultCountryCode?: string;
  productId?: string | number;
  closeLocationModal: () => void;
  updateCardListData: (type?: string, existId?: number) => void;
  officeDirect?: boolean;
  otherAddressDirect?: boolean;
  preSelectOption?: number;
  officePreSelection?: boolean;
}) => {
  const orgInfo = formatOrgData();
  const { t } = useTranslation();

  const [defaultCodeOfficeAddress, setDefaultCodeOfficeAddress] = useState<string>();
  const [defaultCodeOtherAddress, setDefaultCodeOtherAddress] = useState<string>();

  const [selectOption, setSelectOption] = useState<number>(0);
  const [organizationOfficeAddress, setOrganizationOfficeAddress] = useState<TdestinationAddress>();
  const [defaultOtherAddress, setDefaultOtherAddress] = useState<TdestinationAddress>();
  const [officeAddressList, setOfficeAddressList] = useState<TdestinationAddress[]>();
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);

  const i18nLanguageKey = 'nodi:tools:locationToolsFlux:';
  const modalContentTitle =
    selectOption === 1
      ? t(`${i18nLanguageKey}selectOption1`)
      : selectOption === 2
      ? t(`${i18nLanguageKey}selectOption2`)
      : t(`${i18nLanguageKey}selectOptionDefault`);

  const handleChangeOption = (option: number) => setSelectOption(option);

  const formatWhitLocationName = (data: { [key: string]: ILocationAddress[] }) => {
    const listLocationAllCountries = Object.values(data);
    const newListLocationAllCountries: ILocationAddress[] = [];
    if (listLocationAllCountries.length > 0) {
      for (const listLocationCountry of listLocationAllCountries) {
        const resultListLocationCountry = listLocationCountry as ILocationAddress[];
        if (resultListLocationCountry.length > 0) {
          for (const locationCountry of resultListLocationCountry) {
            if (locationCountry.locationName && locationCountry.zipCode) {
              newListLocationAllCountries.push(locationCountry);
            }
          }
        }
      }
      const formatedList = formatListAdress(newListLocationAllCountries);
      return formatedList;
    }
    return [];
  };

  const formatListAdress = (listOfficeAddress: ILocationAddress[]) => {
    const newFormat: TdestinationAddress[] = [];
    for (const item of listOfficeAddress) {
      const newItems = {
        locationId: item.id || undefined,
        address: item.address || '',
        additionalReference: item.additionalReferences || '',
        country: item.city || '',
        organizationId: item?.organizationId || 0,
        city: item.city || '',
        cityId: item?.cityId,
        code: item?.country?.code || undefined,
        zipCode: item?.zipCode,
        locationName: item?.locationName,
        coordinates: item?.coordinates
      };
      newFormat.push(newItems);
    }
    return newFormat;
  };

  const getOfficeAddress = async (officeCountryCode: string, otherCountryCode: string) => {
    try {
      const data = await servicesLocationAddress(Number(orgInfo?.organizationId));
      const defaultLocations: IListDefaultLocationAddress = await servicesDefaultLocationAddress(
        Number(orgInfo?.organizationId)
      );
      if (data?.office && data.office.length > 0) {
        const formatedList = formatListAdress(data?.office);
        setOfficeAddressList(formatedList);
        const defaultOfficeCode = officeCountryCode;
        if (defaultOfficeCode && formatedList && formatedList.length > 0) {
          defaultOffice(formatedList, defaultOfficeCode);
        } else if (
          !defaultOfficeCode &&
          defaultLocations &&
          defaultLocations?.offices &&
          defaultLocations?.offices?.length > 0
        ) {
          const formatOfficeLocation = formatListAdress(defaultLocations?.offices);
          setOrganizationOfficeAddress(formatOfficeLocation[0]);
        }
      }
      if (
        defaultLocations &&
        defaultLocations?.others &&
        defaultLocations?.others?.length > 0 &&
        data?.others &&
        Object.values(data?.others).length > 0
      ) {
        const formatOtherLocation = formatListAdress(defaultLocations?.others);
        const listWhitLocationName = formatWhitLocationName(data.others);
        const defaultCountrySelection = formatOtherLocation.find(element => element.code === otherCountryCode);
        const defaultCountrySelectionWhiteLocationName = listWhitLocationName.find(
          element => element.locationId === defaultCountrySelection?.locationId
        );

        if (defaultCountrySelectionWhiteLocationName) {
          setDefaultOtherAddress(defaultCountrySelectionWhiteLocationName);
        }
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingAddress(false);
    }
  };

  const defaultOffice = (officeList: TdestinationAddress[], countryCode: string) => {
    const defaultCode = countryCode;
    if (officeList && defaultCode) {
      const filterCountry = officeList.find(element => element.code === defaultCode);
      if (filterCountry) {
        setOrganizationOfficeAddress(filterCountry);
      }
    }
  };

  const refreshOfficeData = async (officeCode: string) => {
    await getOfficeAddress(officeCode, defaultCodeOtherAddress || '');
  };

  const refresOtherAddressData = async (otherCode: string) => {
    await getOfficeAddress(defaultCodeOfficeAddress || '', otherCode);
  };

  const updateOtherAddres = (otherAddress: TdestinationAddress) => {
    setDefaultCodeOtherAddress(otherAddress.code);
    setDefaultOtherAddress(otherAddress);
  };

  useEffect(() => {
    if (preSelectOption) setSelectOption(preSelectOption);
  }, []);

  useEffect(() => {
    if (officeDirect) {
      setSelectOption(1);
    }
  }, [officeDirect]);

  useEffect(() => {
    if (otherAddressDirect) {
      setSelectOption(2);
    }
  }, [otherAddressDirect]);

  useEffect(() => {
    //first call and assignment
    setDefaultCodeOfficeAddress(defaultCountryCode);
    setDefaultCodeOtherAddress(defaultCountryCode);
    getOfficeAddress(defaultCountryCode || '', defaultCountryCode || '');
  }, []);

  return (
    <BordModal
      closeModalCallback={closeLocationModal}
      modalContent={
        <ModalContent
          toCloseModal={closeLocationModal}
          customHeight="480px"
          customWidth="580px"
          excludeHeader
          modalContentTitle={modalContentTitle}
          onClickBackButton={selectOption ? () => handleChangeOption(0) : undefined}
          CustomModalComponent={
            <LocationModalContent
              officePreSelection={officePreSelection}
              selectOption={selectOption}
              handleChangeOption={handleChangeOption}
              listOfAllCountries={listOfAllCountries}
              organizationOfficeAddress={organizationOfficeAddress}
              officeAddressList={officeAddressList}
              loadingAddress={loadingAddress}
              productId={productId || ''}
              updateCardListData={updateCardListData}
              closeLocationModal={closeLocationModal}
              defaultOtherAddress={defaultOtherAddress}
              officeDirect={officeDirect}
              defaultCountryCode={defaultCountryCode}
              otherAddressDirect={otherAddressDirect}
              refreshOfficeData={refreshOfficeData}
              refresOtherAddressData={refresOtherAddressData}
              defaultCodeOfficeAddress={defaultCodeOfficeAddress}
              defaultCodeOtherAddress={defaultCodeOtherAddress}
              updateOtherAddres={updateOtherAddres}
            />
          }
        />
      }
    />
  );
};

export default LocationToolsFlux;
