const RoundCostaRica = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#fff"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M31.0048 10.4348H.995188C.352063 12.1681 0 14.0429 0 16s.352063 3.8319.995188 5.5652H31.0049C31.648 19.8319 32 17.9571 32 16s-.352-3.8319-.9952-5.5652Z"
        />
        <path
          fill="#1A47B8"
          d="M15.9999 0C11.1505 0 6.80528 2.15775 3.87109 5.56519H28.1287C25.1945 2.15775 20.8493 0 15.9999 0ZM28.1287 26.4348H3.87109C6.80528 29.8423 11.1505 32 15.9999 32c4.8494 0 9.1946-2.1577 12.1288-5.5652Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundCostaRica;
