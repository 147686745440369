import { FC, useEffect, useState } from 'react';
import { getSpeiBankData } from '../../../../../services/checkout';
import { displayErrorNotification } from '../../../../../utils/displayNudosStandardNotifications';
import { NudosTransfDetails } from '../../../../../components/DesignSystem';
import { IBank } from '../../../../../types/checkout';
import useCartState from '../../../../../state/useCartState';
import { useTranslation } from 'react-i18next';
import { INudosTransfDetailsData } from '../../../../../types/cart';
import BordModal from '../../../../../components/BordDesignSystem/BordModal/BordModal';
import ModalContent from '../../../../../components/BordDesignSystem/BordModal/components/ModalContent';
import './DetailsPaymentModal.scss';

interface IDetailsPaymentModal {
  closeModal: () => void;
  paymentMethod: string;
  banckAccountData: INudosTransfDetailsData | undefined;
}

const DetailsPaymentModal: FC<IDetailsPaymentModal> = ({
  closeModal,
  paymentMethod,
  banckAccountData
}: IDetailsPaymentModal) => {
  const { t } = useTranslation();
  const { dataFinishCart } = useCartState();

  const [SPEIBankData, setSPEIBankData] = useState<IBank>();
  const [loading, setLoading] = useState<boolean>(true);

  const orderId = dataFinishCart?.orderId || banckAccountData?.orderId;
  const i18nLanguageKey = 'nodi:orderDetails:orderPaymentDetails:detailsPaymentModal:';
  const isPaid = !!SPEIBankData?.isPaid;

  const getInfoBank = async () => {
    if (paymentMethod === 'Transf. MXN') {
      if (!orderId) return;
      setLoading(true);
      try {
        const data = await getSpeiBankData(orderId);
        setSPEIBankData(data);
      } catch (error) {
        displayErrorNotification();
      } finally {
        setLoading(false);
      }
    } else setLoading(false);
  };

  useEffect(() => {
    getInfoBank();
  }, []);

  return (
    <BordModal
      clickOutsideCallback={closeModal}
      closeModalCallback={closeModal}
      modalContent={
        <ModalContent
          toCloseModal={closeModal}
          title={t(`${i18nLanguageKey}modalContentTitle`)}
          subTitle="Realiza el pago a los datos indicados a continuación"
          showButtonTwo
          customWidth="68rem"
          customHeight="58rem"
          buttonTwoProps={{
            label: t(`${i18nLanguageKey}buttonText`),
            disabled: loading,
            onClick: closeModal,
            customWidth: 'w-380'
          }}
          CustomModalComponent={
            <div className="detailsPaymentModal">
              {!isPaid && <NudosTransfDetails specialData={banckAccountData} />}
              {isPaid && <div className="isPaidMessage">{t(`${i18nLanguageKey}isPaidMessage`)}</div>}
            </div>
          }
        />
      }
    />
  );
};

export default DetailsPaymentModal;
