import { Switch, Route, Redirect } from 'react-router-dom';
import useEcommerceControler from '../views/ecommerce/ecommerce.controller';
import { authRoutes } from './routes';

const switchRoutes = (
  <Switch>
    {authRoutes.map((prop, key) => {
      return <Route path={`${prop.path}`} component={prop.component} key={key} exact />;
    })}
    <Redirect from="" to="/login" />
  </Switch>
);

const Auth = () => {
  const { user } = useEcommerceControler();
  return <div id="auth-layout">{user && switchRoutes}</div>;
};

export default Auth;
