const RoundUganda = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#FFDA2C"
          d="M27.9246 26.6665C30.4585 23.8358 32 20.0981 32 15.9999C32 11.9016 30.4584 8.16395 27.9245 5.3332L16 4.17383L4.0755 5.3332C1.54156 8.16395 0 11.9016 0 15.9999C0 20.0981 1.5415 23.8358 4.07544 26.6665L16 27.826L27.9246 26.6665Z"
        />
        <path
          fill="black"
          d="M4.0752 5.33331H27.9243C24.9946 2.06037 20.738 0 15.9997 0C11.2614 0 7.00482 2.06037 4.0752 5.33331Z"
        />
        <path
          fill="#F93939"
          d="M0 15.9999L16 17.3912L32 15.9999C32 14.1296 31.6781 12.3348 31.0882 10.6665H0.91175C0.321938 12.3348 0 14.1296 0 15.9999H0Z"
        />
        <path
          fill="black"
          d="M0.91175 21.3333H31.0884C31.6781 19.6651 32 17.8702 32 16H0C0 17.8702 0.321938 19.6651 0.91175 21.3333Z"
        />
        <path
          fill="#F93939"
          d="M27.9243 26.6665H4.0752C7.00482 29.9395 11.2614 31.9999 15.9998 31.9999C20.7381 31.9999 24.9947 29.9395 27.9243 26.6665Z"
        />
        <path
          fill="white"
          d="M21.3394 15.9998C21.3394 18.9487 18.9492 21.3389 16.0003 21.3389C13.0513 21.3389 10.6611 18.9487 10.6611 15.9998C10.6611 13.0508 13.0513 10.6606 16.0003 10.6606C18.9492 10.6606 21.3394 13.0508 21.3394 15.9998Z"
        />
        <path
          fill="black"
          d="M17.9558 16.2722L15.9999 15.4246C15.9999 15.4246 16.4575 13.9057 16.4819 13.8017C16.4997 13.7258 16.5092 13.6466 16.5092 13.5652C16.5092 13.277 16.3924 13.0162 16.2035 12.8274L16.6954 12.3355C16.3807 12.0209 15.9459 11.8262 15.4656 11.8262C14.9854 11.8262 14.5506 12.0208 14.2359 12.3355L14.7277 12.8274C14.5389 13.0162 14.4221 13.2771 14.4221 13.5652C14.4221 13.6706 14.4381 13.7722 14.4671 13.8681L13.7266 14.6086H15.0726C15.0726 14.6086 14.5161 15.4445 14.2122 16.1309C13.9084 16.8173 14.2164 17.6552 14.9186 17.9674L15.3246 18.1478L15.9999 18.7825V19.4782L15.3042 20.1738H16.6956V18.7825L17.3304 18.1477H18.6434C18.6497 18.1346 18.6563 18.1219 18.6623 18.1085C18.9742 17.4064 18.658 16.5842 17.9558 16.2722Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundUganda;
