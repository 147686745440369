const SquareKenya = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#151515" rx="2" />
          <path fill="white" fillRule="evenodd" d="M0 10.6665H22V11.7332H0V10.6665Z" clipRule="evenodd" />
          <path fill="#0A6A30" fillRule="evenodd" d="M0 11.7334H22V16.0001H0V11.7334Z" clipRule="evenodd" />
          <path fill="#F93939" fillRule="evenodd" d="M0 5.3335H22V10.6668H0V5.3335Z" clipRule="evenodd" />
          <path fill="white" fillRule="evenodd" d="M0 4.2666H22V5.33327H0V4.2666Z" clipRule="evenodd" />
          <path
            fill="white"
            fillRule="evenodd"
            d="M13.0051 6.3084C13.0805 6.3084 13.5289 5.59373 13.5289 4.7084C13.5289 3.8252 13.0805 3.1084 13.0051 3.1084C12.9317 3.1084 12.4813 3.8252 12.4813 4.7084C12.4813 5.59373 12.9317 6.3084 13.0051 6.3084ZM9.92613 5.84013C9.87375 5.89347 9.0566 5.71213 8.44479 5.086C7.83194 4.46093 7.65175 3.63107 7.70518 3.57773C7.75756 3.5244 8.5726 3.70573 9.18651 4.33186C9.80041 4.95693 9.97956 5.7868 9.92718 5.8412L9.92613 5.84013ZM11.8946 10.6753C11.947 10.6252 12.7641 10.7959 13.377 11.3793C13.9898 11.966 14.17 12.7415 14.1177 12.7916C14.0653 12.8417 13.2481 12.6711 12.6342 12.0876C12.0214 11.5009 11.8412 10.7255 11.8946 10.6753ZM9.92613 10.6753C9.87375 10.6252 9.0566 10.7959 8.44479 11.3793C7.83194 11.966 7.65175 12.7415 7.70518 12.7916C7.75756 12.8417 8.5726 12.6711 9.18651 12.0876C9.80041 11.5009 9.97956 10.7255 9.92718 10.6753H9.92613Z"
            clipRule="evenodd"
          />
          <path
            fill="#F93939"
            fillRule="evenodd"
            d="M10.9999 12.8002C11.3739 12.8002 13.619 10.6509 13.619 8.0002C13.619 5.34953 11.3739 3.2002 10.9999 3.2002C10.6259 3.2002 8.38086 5.34953 8.38086 8.0002C8.38086 10.6509 10.6259 12.8002 10.9999 12.8002Z"
            clipRule="evenodd"
          />
          <path
            fill="#151515"
            fillRule="evenodd"
            d="M8.90467 9.5999C8.978 9.5999 9.42848 8.8831 9.42848 7.9999C9.42848 7.1167 8.978 6.3999 8.90467 6.3999C8.83134 6.3999 8.38086 7.1167 8.38086 7.9999C8.38086 8.8831 8.83134 9.5999 8.90467 9.5999ZM13.0951 9.5999C13.1685 9.5999 13.619 8.8831 13.619 7.9999C13.619 7.1167 13.1685 6.3999 13.0951 6.3999C13.0218 6.3999 12.5713 7.1167 12.5713 7.9999C12.5713 8.8831 13.0218 9.5999 13.0951 9.5999Z"
            clipRule="evenodd"
          />
          <path
            fill="white"
            d="M11.5242 7.9999C11.5242 7.11625 11.2897 6.3999 11.0004 6.3999C10.7111 6.3999 10.4766 7.11625 10.4766 7.9999C10.4766 8.88356 10.7111 9.5999 11.0004 9.5999C11.2897 9.5999 11.5242 8.88356 11.5242 7.9999Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareKenya;
