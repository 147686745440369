const RoundBangladesh = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#496E2D"
          d="M16 32c8.8366 0 16-7.1634 16-16 0-8.83656-7.1634-16-16-16C7.16344 0 0 7.16344 0 16c0 8.8366 7.16344 16 16 16Z"
        />
        <path
          fill="#F93939"
          d="M12.5217 22.9565c3.842 0 6.9565-3.1146 6.9565-6.9565 0-3.842-3.1145-6.95654-6.9565-6.95654-3.84192 0-6.95645 3.11454-6.95645 6.95654 0 3.8419 3.11453 6.9565 6.95645 6.9565Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundBangladesh;
