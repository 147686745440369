import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BordButton } from '../../../../components/BordDesignSystem';
import { segmentTrackEvent } from '../../../../utils/segment';
import useStoreCountryState from '../../../../state/useStoreCountryState';
import BordModal from '../../../../components/BordDesignSystem/BordModal/BordModal';
import { BordWarning } from '../../../../assets/DesignSystem/images';

import './ModalCountryChangeAlert.scss';

interface IModalCountryChangeAlert {
  loadingModal?: boolean;
  firstAction?: () => void;
  secondAction?: () => void;
}

const ModalCountryChangeAlert: FC<IModalCountryChangeAlert> = ({
  firstAction,
  secondAction,
  loadingModal
}: IModalCountryChangeAlert) => {
  const { storeCountry } = useStoreCountryState();
  const { t } = useTranslation();
  const translations = 'ecommerce:modalCountryChangeAlert:';

  const modalEvent = () => {
    segmentTrackEvent({ catalogueChangeCountryView: { CountryName: storeCountry?.countryName || '' } });
  };

  useEffect(() => {
    modalEvent();
  }, []);

  return (
    <>
      <BordModal
        modalContent={
          <div id="modalCountryChangeAlert">
            <div className="warningIconContainer">
              <div className="boxWarnigIcon">
                <img src={BordWarning} />
              </div>
            </div>
            <div className="titleChangeCountryText">Estás a punto de cambiar de país</div>
            <div className="changeCountryText">
              <Trans
                i18nKey={`${translations}text`}
                components={{
                  1: <br />
                }}
              />{' '}
            </div>
            <div className="buttonsChangeCountryContainer">
              <BordButton
                label={t(`${translations}button`)}
                disabled={loadingModal}
                modeButton="tertiary"
                customHeight="42"
                customClassName="customPaddingButton"
                onClick={() => {
                  if (firstAction) {
                    firstAction();
                  }
                }}
              />
              <BordButton
                label={t(`${translations}button2`)}
                customHeight="42"
                customClassName="confirmButton"
                isLoading={loadingModal}
                onClick={() => {
                  if (secondAction) {
                    segmentTrackEvent({ catalogueChangeCountryYesClick: null });
                    secondAction();
                  }
                }}
              />
            </div>
          </div>
        }
      />
    </>
  );
};

export default ModalCountryChangeAlert;
