const SquareIraq = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="white" rx="2" />
          <path fill="#151515" fillRule="evenodd" d="M0 11H22V16H0V11Z" clipRule="evenodd" />
          <path
            fill="#249F58"
            fillRule="evenodd"
            d="M10.4766 8.53324H11.5242V9.06124C11.5242 9.37057 11.7589 9.5999 12.048 9.5999C12.3288 9.5999 12.5718 9.35884 12.5718 9.06124V6.9279C12.5718 6.6367 12.8149 6.3999 13.0957 6.3999C13.3848 6.3999 13.6195 6.6303 13.6195 6.9279V8.53324H15.1804C15.4758 8.53324 15.7147 8.7807 15.7147 9.06657C15.7147 9.36097 15.4821 9.5999 15.1804 9.5999H6.82042C6.52499 9.5999 6.28613 9.35244 6.28613 9.06657C6.28613 8.84044 6.42232 8.64737 6.62137 8.5695C6.64756 8.50124 6.68737 8.43617 6.74289 8.3807L8.23051 6.86604C8.43375 6.6591 8.77004 6.66657 8.96909 6.86817C9.17442 7.07617 9.1807 7.40684 8.97328 7.61804L8.07442 8.53324H9.42899V8.00524C9.42899 7.70657 9.67204 7.46657 9.9528 7.46657C10.2419 7.46657 10.4766 7.69484 10.4766 8.00524V8.53324Z"
            clipRule="evenodd"
          />
          <path fill="#F93939" fillRule="evenodd" d="M0 0H22V5H0V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareIraq;
