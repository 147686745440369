const RoundLibya = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="black"
          d="M1.58763 9.04341C0.5705 11.1469 0 13.5067 0 15.9999C0 18.4931 0.5705 20.8529 1.58763 22.9564L16 24.3477L30.4124 22.9564C31.4295 20.8529 32 18.4931 32 15.9999C32 13.5067 31.4295 11.1469 30.4124 9.04341L16 7.6521L1.58763 9.04341Z"
        />
        <path
          fill="#496E2D"
          d="M1.58789 22.9565C4.17577 28.3085 9.65633 32 15.9997 32C22.343 32 27.8236 28.3085 30.4115 22.9565H1.58789Z"
        />
        <path
          fill="#F93939"
          d="M1.58789 9.0435H30.4115C27.8236 3.69156 22.343 0 15.9997 0C9.65633 0 4.17577 3.69156 1.58789 9.0435Z"
        />
        <path
          fill="white"
          d="M19.7242 13.0742L21.037 14.8833L23.1631 14.1939L21.8482 16.0014L23.1608 17.8104L21.0356 17.1184L19.7207 18.9259L19.7221 16.6908L17.5967 15.9988L19.7228 15.3095L19.7242 13.0742Z"
        />
        <path
          fill="white"
          d="M16.1412 20.5218C13.644 20.5218 11.6195 18.4973 11.6195 16.0001C11.6195 13.5028 13.644 11.4783 16.1412 11.4783C16.9199 11.4783 17.6525 11.6752 18.2922 12.0218C17.2887 11.0405 15.9164 10.4348 14.4021 10.4348C11.3285 10.4348 8.83691 12.9264 8.83691 16C8.83691 19.0736 11.3285 21.5652 14.4021 21.5652C15.9165 21.5652 17.2887 20.9595 18.2922 19.9782C17.6525 20.3249 16.9199 20.5218 16.1412 20.5218Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H32V32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundLibya;
