import { SVGProps, Ref, forwardRef, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="0 0 17 16" ref={ref} {...props}>
    <g stroke="#54A1FC">
      <path fill="#fff" d="M8.502 15.143a7.143 7.143 0 1 0 0-14.286 7.143 7.143 0 0 0 0 14.286Z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M8.5 5.157v2.857l1.714 1.715" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgComponent);
const IconTimeClock = memo(ForwardRef);
export default IconTimeClock;
