const RoundAngola = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
      <g>
        <path
          fill="#F93939"
          d="M0 16C0 7.1635 7.1635 0 16 0c8.8365 0 16 7.1635 16 16-.6956 0-16 2.0869-16 2.0869L0 16Z"
        />
        <path fill="#000" d="M32 16c0 8.8365-7.1635 16-16 16-8.8365 0-16-7.1635-16-16" />
        <path
          fill="#FFDA2C"
          d="m13.8062 12.7258 1.3563.9844-.5168 1.5942 1.3553-.986 1.3565.9844-.5188-1.5937 1.3552-.9859-1.6759.001-.519-1.5937-.5169 1.5942-1.6759.0011Z"
        />
        <path
          fill="#FFDA2C"
          d="M20 9.07182c-1.2608-.72793-2.6394-1.0715-4-1.06912v2.0865c1.0057-.0018 2.0246.2519 2.9565.7901 2.8236 1.6302 3.7946 5.2537 2.1643 8.0773-1.6302 2.8237-5.2537 3.7946-8.0773 2.1643-.8227-.4749-1.4867-1.1198-1.9762-1.864l-1.74229 1.1503C9.98732 21.414 10.8871 22.2857 12 22.9282c3.8203 2.2056 8.7226.8921 10.9281-2.9282 2.2057-3.8202.8922-8.7226-2.9281-10.92818Z"
        />
        <path
          fill="#FFDA2C"
          d="M11.3869 14.6086c-.5531 1.0112-.1818 2.2794.8294 2.8325l6.778 3.7038c-.4609.8426-.2161 1.8681.6265 2.3291l1.8309 1.0015c.8426.461 1.8995.1515 2.3604-.6911l1.0016-1.8309-13.4268-7.3449Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RoundAngola;
