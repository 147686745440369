const SquareMoldova = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16">
      <g clipPath="url(#a)">
        <g clipPath="url(#b)">
          <rect width="22" height="16" fill="#FFDA2C" rx="2" />
          <path fill="#1A47B8" fillRule="evenodd" d="M0 0H8V16H0V0Z" clipRule="evenodd" />
          <path
            fill="#AE6A3E"
            fillRule="evenodd"
            d="M9 5.125H14V10.75H9V5.125ZM9 10.75L11.5 13L14 10.75H9ZM11 4H12V5.125H11V4Z"
            clipRule="evenodd"
          />
          <path fill="#AF010D" fillRule="evenodd" d="M10 6H13V9H10V6Z" clipRule="evenodd" />
          <path
            fill="#1A47B8"
            fillRule="evenodd"
            d="M10 9H13V10.2H10V9ZM10 10.2L11.5 11L13 10.2H10Z"
            clipRule="evenodd"
          />
          <path
            fill="#FFDA2C"
            fillRule="evenodd"
            d="M11.5 7C11.776 7 12 7.321 12 7.7575V9.2425C12 9.6625 11.768 10 11.5 10C11.224 10 11 9.679 11 9.2425V7.7575C11 7.3375 11.232 7 11.5 7Z"
            clipRule="evenodd"
          />
          <path fill="#AF010D" fillRule="evenodd" d="M15 0H23V16H15V0Z" clipRule="evenodd" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="white" d="M0 0H22V16H0z" />
        </clipPath>
        <clipPath id="b">
          <rect width="22" height="16" fill="white" rx="2" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SquareMoldova;
