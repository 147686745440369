import { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BordStepper from '../../../../../components/BordDesignSystem/BordStepper/BordStepper';
import './ShipmentStatusTracker.scss';

/**
 * @property {string} shipmentStatus - The name of the shipment status, must be one of "Pendiente de confirmación", "Confirmado", "Proceso de entrega", "Entregado" or "Cancelado". If none one of those values the component behaves as if the status was "Pendiente de confirmación"
 */
const ShipmentStatusTracker = ({ shipmentStatus }: { shipmentStatus?: string | null }) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isCanceled, setIsCanceled] = useState(false);
  const i18nLanguageKey = 'nodi:orderDetails:shipmentStatusTracker:';

  const getCurrentStatusStep = (shipmentStatus: string) => {
    if (shipmentStatus === 'Pendiente de confirmación' || shipmentStatus === 'Cancelado') return setCurrentStep(0);
    if (shipmentStatus === 'Confirmado') return setCurrentStep(1);
    if (shipmentStatus === 'Proceso de entrega') return setCurrentStep(2);
    if (shipmentStatus === 'Entregado') return setCurrentStep(4);
    return setCurrentStep(0);
  };

  const shipmentSteps = [
    {
      order: 0,
      stepContent: isCanceled ? (
        <span className="text-xss text-content">{t('recurrentWords:canceled')}</span>
      ) : (
        <span className="text-xss text-content">{t(`${i18nLanguageKey}order0`)}</span>
      )
    },
    { order: 1, stepContent: <span className="text-xss text-content">{t(`${i18nLanguageKey}order1`)}</span> },
    { order: 2, stepContent: <span className="text-xss text-content">{t(`${i18nLanguageKey}order2`)}</span> },
    { order: 3, stepContent: <span className="text-xss text-content">{t(`${i18nLanguageKey}order3`)}</span> }
  ];

  useLayoutEffect(() => {
    shipmentStatus && getCurrentStatusStep(shipmentStatus);
    if (shipmentStatus === 'Cancelado') setIsCanceled(true);
  }, [shipmentStatus]);

  return (
    <div className="shipmentStatusTracker">
      {!isCanceled && (
        <BordStepper
          currentStep={currentStep as number}
          steps={shipmentSteps}
          stepperConectorWidth="min-w-156 w-full"
        />
      )}
    </div>
  );
};

const ShipmentStatusTrackerSkeletonLoader = () => {
  return (
    <div className="shipmentStatusTrackerSkeletonLoader">
      <div className="stepSkeleton stepSkeleton1 animationLoader" />
      <div className="stepSkeleton stepSkeleton2 animationLoader" />
      <div className="stepSkeleton stepSkeleton3 animationLoader" />
      <div className="stepSkeleton stepSkeleton4 animationLoader" />
    </div>
  );
};

export { ShipmentStatusTracker, ShipmentStatusTrackerSkeletonLoader };
