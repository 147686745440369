import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCountriesList } from '../../../../services/countries.service';
import { getEmployeeById, updateEmployeeData, updateUserProfilePhoto } from '../../../../services/employees.service';
import { ICountryDetail } from '../../../../types/countries';
import { Iemployee, INewEmployeeInfo, IreturnLocation } from '../../../../types/global';
import {
  displayErrorNotification,
  displaySuccessNotification
} from '../../../../utils/displayNudosStandardNotifications';
import segment, { segmentTrackEvent } from '../../../../utils/segment';
import NewEmployeeForm from '../Components/NewEmployeeForm';
import urlParameter from '../../../../utils/urlParameter';
import { BordBackButton } from '../../../../components/BordDesignSystem';
import useSidebar from '../../../../hooks/useSidebar';
import './EditEmployeeInfo.scss';

const EditEmployeeInfo = () => {
  const { replace, push, goBack } = useHistory();
  const { id: userId } = useParams<{ id: string }>();
  const { state }: { state: IreturnLocation } = useLocation();
  const { t } = useTranslation();
  const { moduleSidebarStyles } = useSidebar();

  const originProducList = urlParameter('from') === 'productsList';
  const [currentEmployeeData, setCurrentEmployeeData] = useState<Iemployee>();
  const [loading, setLoading] = useState(false);
  const [loadingEmployeeData, setLoadingEmployeeData] = useState(false);
  const [countriesList, setCountriesList] = useState<ICountryDetail[]>();

  const returnAction = () => {
    if (state?.returnAction) return push(state?.returnAction);
    if (originProducList) return goBack();
    return replace(`/nodi/employees/details/${userId}`);
  };

  const getReturnText = () => {
    if (state?.returnText) return state.returnText;
    return t('nodi:orderDetails:getReturnText:employee-details');
  };

  const getEmployee = async () => {
    if (!userId) return;
    setLoadingEmployeeData(true);
    try {
      const response = await getEmployeeById(userId);
      if (response) {
        setCurrentEmployeeData(response);
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoadingEmployeeData(false);
    }
  };

  const handleEditEmployee = async (employee: INewEmployeeInfo) => {
    if (!userId || !employee || !employee?.country?.id) return;
    setLoading(true);
    const formData = new FormData();
    employee.uploadedPhoto && formData.append('file', employee.uploadedPhoto);
    try {
      if (employee.uploadedPhoto) {
        const updatedPhotoUrl = await updateUserProfilePhoto(userId, formData);
        if (updatedPhotoUrl) {
          const body = {
            firstName: employee.firstName,
            lastName: employee.lastName,
            personalId: employee?.personalId,
            city: employee.city,
            cityId: employee.cityId || undefined,
            area: employee.area,
            position: employee.position,
            address: { ...employee.address, cityId: employee.cityId },
            photoProfileUrl: updatedPhotoUrl?.url,
            countryId: employee?.country?.id,
            countryPhoneId: employee?.countryPhone?.id || null,
            phone: employee?.countryPhone?.id ? employee?.phone : null
          };
          const updateDataResponse = await updateEmployeeData(userId, body);
          if (updateDataResponse) {
            displaySuccessNotification({ customJSXMessage: <>Datos guardados correctamente</> });
            segmentTrackEvent({
              alertEditEmployeeView: {
                EmployeeArea: employee?.area,
                EmployeeCity: employee?.city,
                EmployeeName: `${employee?.firstName} ${employee?.lastName}`
              }
            });
            segment.sessionEndingEvent();
            returnAction();
          }
        }
      }
      if (!employee.uploadedPhoto) {
        const body = {
          firstName: employee.firstName,
          lastName: employee.lastName,
          personalId: employee?.personalId,
          city: employee.city,
          cityId: employee.cityId || undefined,
          area: employee.area,
          position: employee.position,
          address: { ...employee.address, cityId: employee.cityId },
          photoProfileUrl: employee.photoProfile || undefined,
          countryId: employee?.country?.id,
          countryPhoneId: employee?.countryPhone?.id || null,
          phone: employee?.countryPhone?.id ? employee?.phone : null
        };
        const updateDataResponse = await updateEmployeeData(userId, body);
        if (updateDataResponse) {
          displaySuccessNotification({ customJSXMessage: <>Datos guardados correctamente</> });
          segmentTrackEvent({
            alertEditEmployeeView: {
              EmployeeArea: employee?.area,
              EmployeeCity: employee?.city,
              EmployeeName: `${employee?.firstName} ${employee?.lastName}`
            }
          });
          segment.sessionEndingEvent();
          returnAction();
        }
      }
    } catch (error) {
      displayErrorNotification();
    } finally {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      const dataCountries = await getCountriesList();
      setCountriesList(dataCountries);
    } catch {
      displayErrorNotification();
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getEmployee();
  }, [userId]);

  return (
    <div className={`editEmployeeInfo ${moduleSidebarStyles}`}>
      <BordBackButton buttonText={getReturnText()} onClick={() => returnAction()} />
      <NewEmployeeForm
        countriesList={countriesList}
        handleSubmitCustomCallback={handleEditEmployee}
        isButtonLoading={loading}
        currentEmployeeData={currentEmployeeData}
        showSkeletonLoader={loadingEmployeeData || !countriesList || !currentEmployeeData}
      />
    </div>
  );
};

export default EditEmployeeInfo;
